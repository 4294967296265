import React from "react";
import './layer-btn.css';
import { CgMenuGridR } from "react-icons/cg";
import {IconContext} from "react-icons";
import { Link } from "react-router-dom";
import { BsBuilding, BsPinMapFill } from "react-icons/bs";
import { BiNetworkChart } from "react-icons/bi";
import {IoCloudUploadOutline} from "react-icons/io5"
import { IoMdGrid } from "react-icons/io";
import { RiFocus3Line } from "react-icons/ri"
import { MdAddRoad, MdAltRoute } from "react-icons/md";
import { HiViewGridAdd } from "react-icons/hi";

export default class LayerMenuComponent extends React.Component{

	constructor() {
		super()
		this.state ={
			showOptions: false,
		}
		this.hideComponent = this.hideComponent.bind(this);
	}

	hideComponent(name){
			  this.setState({ showOptions: !this.state.showOptions });
	}
	render(){
		const { showOptions } = this.state;

		return(
			<div>
				{showOptions ? <>
				<div id='layer-options-1' className="map-layer-options2">
				<div className='map-layer-option-util-rail' onClick={() => this.hideComponent("showOptions")}>
						<Link to="/hotspot-analysis" style={{ textDecoration: 'none' }}>
						<IconContext.Provider value={{ color: "#5B5B5B", size:'1.6em'}}>
							<div>
			 					<RiFocus3Line />
							</div>
						</IconContext.Provider>
						<p className='label'>Hotspot Analysis</p>
						</Link>
					</div>
					<div className='map-layer-option-util-rail' onClick={() => this.hideComponent("showOptions")}>
						<Link to="/co2-emission" style={{ textDecoration: 'none' }}>
						<IconContext.Provider value={{ color: "#5B5B5B", size:'1.6em'}}>
							<div>
			 					<IoCloudUploadOutline />
							</div>
						</IconContext.Provider>
						<p className='label'>Co<sub>2</sub> Emissions</p>
						</Link>
					</div>
					<div className='map-layer-option-util-rail' onClick={() => this.hideComponent("showOptions")}>
						<Link to="/drop-a-point-tiles" style={{ textDecoration: 'none' }}>
						<IconContext.Provider value={{ color: "#5B5B5B", size:'1.6em'}}>
							<div>
			 					<IoMdGrid />
							</div>
						</IconContext.Provider>
						<p className='label'>ÖV Quality Analysis</p>
						</Link>
					</div>
					<div className='map-layer-option-util-rail' onClick={() => this.hideComponent("showOptions")}>
						<Link to="/menu" style={{ textDecoration: 'none' }}>
						<IconContext.Provider value={{ color: "#5B5B5B", size:'1.6em'}}>
							<div>
			 					<HiViewGridAdd />
							</div>
						</IconContext.Provider>
						<p className='label'>&#10148; Explore all views</p>
						</Link>
					</div>
				</div>
				<div id='layer-options-2' className='map-layer-options'>
					<div className='map-layer-option-util-road' onClick={() => this.hideComponent("showOptions")}>
						<Link to="/drop-a-point" style={{ textDecoration: 'none' }}>
						<IconContext.Provider value={{ color: "#5B5B5B", size:'1.6em'}}>
							<div>
			 					<BsPinMapFill />
							</div>
						</IconContext.Provider>
						<p className='label'>Point Analysis</p>
						</Link>
					</div>
					<div className='map-layer-option-recommendation' onClick={() => this.hideComponent("showOptions")}>
						<Link to="/bus-potential" style={{ textDecoration: 'none' }}>
						<IconContext.Provider value={{ color: "#5B5B5B", size:'1.6em'}}>
							<div>
			 					<BsBuilding />
							</div>
						</IconContext.Provider>
						<p className='label'>B-to-B Potential</p>
						</Link>
					</div>
					<div className='map-layer-option-car' onClick={() => this.hideComponent("showOptions")}>
						<Link to="/potential-routes" style={{ textDecoration: 'none' }}>
						<IconContext.Provider value={{ color: "#5B5B5B", size:'1.6em'}}>
                        	<div>
                         		<MdAddRoad />
                        	</div>
                    	</IconContext.Provider>
						<p className='label'>Potential of routes</p>
						</Link>
					</div>
					<div className='map-layer-option-train' onClick={() => this.hideComponent("showOptions")}>
						<Link to="/od-insights" style={{ textDecoration: 'none' }}>
						<IconContext.Provider value={{ color: "#5B5B5B", size:'1.6em'}}>
                        	<div>
                         		<BiNetworkChart />
                        	</div>
                    	</IconContext.Provider>
						<p className='label'>Insights in Modal Split</p>
						</Link>
					</div>
				</div>
				</> : <></>}
				<div className='map-layer-button'>
                    <div className='map-layer-button-inner' onClick={() => this.hideComponent("showOptions")}>
					<IconContext.Provider value={{ color: "#5B5B5B", size:'1.8em'}}>
                        <div>
                         <CgMenuGridR />
                        </div>
                    </IconContext.Provider>
                    </div>
			</div>
		</div>
		)
	}
}