/* eslint-disable react/style-prop-object */
import React from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { RiFullscreenFill, RiFullscreenExitFill } from "react-icons/ri";
import { IconContext } from "react-icons";
import { BsLayersHalf } from "react-icons/bs";
import { Form, Button } from 'react-bootstrap';
import Select from 'react-select';
import { IoTrashOutline } from "react-icons/io5";
import { BiTargetLock } from "react-icons/bi";

import axios from 'axios';

import '../../components/layer-tyles.css';
import '../css/employer-analyzer.css';
import RecommendationNavBarComponent from "../../components/RecommendationNavBarComponent";

import { useNavigate } from 'react-router-dom';
import { createContext, useContext } from 'react';

const NavigateContext = createContext();

function NavigateProvider({ children }) {
  const navigate = useNavigate();
  return (
    <NavigateContext.Provider value={navigate}>
      {children}
    </NavigateContext.Provider>
  );
}

const _ = require('lodash');

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
}

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const URL = process.env.REACT_APP_URL_SERVER_BACK;

let general_routes_info=[];

let URL_stations = {
    'type': 'FeatureCollection',
    'features': [
    ]
};

let URL_status_quo = {
    'type': 'FeatureCollection',
    'features': [
        
    ]
};

let URL_evaluated = {
    'type': 'FeatureCollection',
    'features': []
}

let line_route = {
    'type': 'FeatureCollection',
    'features': []
}
  
let ind_1=  [
    "ch:23016:20302:30",
    "fl:21:4070:0:2",
    "fl:21:4067:0:1",
    "fl:21:5023:0:2",
    "fl:21:101:0:1",
    "8101236"
  ]

  let ind_2=  [
    "ch:23016:20302:30",
    [
      9.491839113085973,
      47.20725641515156
    ],
    "8574425",
    "fl:21:1371:0:5",
    "8101236"
  ]

  let ind_1_wf=  [
    "ch:23016:20302:30",
    "fl:21:4070:0:2",
    "fl:21:4067:0:1",
    "fl:21:5023:0:2",
    "fl:21:101:0:1"
  ]

  let ind_2_wf=  [
    "ch:23016:20302:30",
    [
      9.491839113085973,
      47.20725641515156
    ],
    "8574425",
    "fl:21:1371:0:5"
  ]

  let ind_1_properties = [
    {
        "type": "Feature",
        "properties": {
            "new_station": true,
            "circle_id": "ch:23016:20302:30",
            "station_id": "ch:23016:20302:30",
            "station_name": "Buchs Bahnhof"
        },
        "geometry": {
            "coordinates": [
                9.47814253037012,
                47.168530166291
            ],
            "type": "Point"
        }
    },
    {
        "type": "Feature",
        "properties": {
            "new_station": true,
            "circle_id": "fl:21:4070:0:2",
            "station_id": "fl:21:4070:0:2",
            "station_name": "Schaan Ivoclar"
        },
        "geometry": {
            "coordinates": [
                9.5091433908294,
                47.1719622705506
            ],
            "type": "Point"
        }
    },
    {
        "type": "Feature",
        "properties": {
            "new_station": true,
            "circle_id": "fl:21:4067:0:1",
            "station_id": "fl:21:4067:0:1",
            "station_name": "Schaan Hilcona"
        },
        "geometry": {
            "coordinates": [
                9.50852355328327,
                47.1755345990096
            ],
            "type": "Point"
        }
    },
    {
        "type": "Feature",
        "properties": {
            "new_station": true,
            "circle_id": "fl:21:5023:0:2",
            "station_id": "fl:21:5023:0:2",
            "station_name": "Schaan Industriestrasse"
        },
        "geometry": {
            "coordinates": [
                9.51297919709313,
                47.1777877902899
            ],
            "type": "Point"
        }
    },
    {
        "type": "Feature",
        "properties": {
            "new_station": true,
            "circle_id": "fl:21:101:0:1",
            "station_id": "fl:21:101:0:1",
            "station_name": "Schaan Hilti"
        },
        "geometry": {
            "coordinates": [
                9.52384881203248,
                47.180834636728
            ],
            "type": "Point"
        }
    },
    {
        "type": "Feature",
        "properties": {
            "new_station": true,
            "circle_id": "8101236",
            "station_id": "8101236",
            "station_name": "Feldkirch"
        },
        "geometry": {
            "coordinates": [
                9.6041042995269,
                47.2409786035422
            ],
            "type": "Point"
        }
    }
]

let ind_2_properties = [
    {
        "type": "Feature",
        "properties": {
            "new_station": true,
            "circle_id": "ch:23016:20302:30",
            "station_id": "ch:23016:20302:30",
            "station_name": "Buchs Bahnhof"
        },
        "geometry": {
            "coordinates": [
                9.47814253037012,
                47.168530166291
            ],
            "type": "Point"
        }
    },
    {
        "type": "Feature",
        "properties": {
            "new_station": true,
            "circle_id": 1,
            "station_id": null,
            "station_name": "New station (VAT)"
        },
        "geometry": {
            "coordinates": [
                9.49145340078124,
                47.206741164858244
            ],
            "type": "Point"
        }
    },
    {
        "type": "Feature",
        "properties": {
            "new_station": true,
            "circle_id": "8574425",
            "station_id": "8574425",
            "station_name": "Eschen, Sportpark"
        },
        "geometry": {
            "coordinates": [
                9.53387401060465,
                47.2056974487738
            ],
            "type": "Point"
        }
    },
    {
        "type": "Feature",
        "properties": {
            "new_station": true,
            "circle_id": "fl:21:1371:0:5",
            "station_id": "fl:21:1371:0:5",
            "station_name": "Nendeln Bahnhof"
        },
        "geometry": {
            "coordinates": [
                9.54189596609296,
                47.2006135147724
            ],
            "type": "Point"
        }
    },
    {
        "type": "Feature",
        "properties": {
            "new_station": true,
            "circle_id": "8101236",
            "station_id": "8101236",
            "station_name": "Feldkirch"
        },
        "geometry": {
            "coordinates": [
                9.6041042995269,
                47.2409786035422
            ],
            "type": "Point"
        }
    }
]

let ind_1_wf_properties = [
    {
        "type": "Feature",
        "properties": {
            "new_station": true,
            "circle_id": "ch:23016:20302:30",
            "station_id": "ch:23016:20302:30",
            "station_name": "Buchs Bahnhof"
        },
        "geometry": {
            "coordinates": [
                9.47814253037012,
                47.168530166291
            ],
            "type": "Point"
        }
    },
    {
        "type": "Feature",
        "properties": {
            "new_station": true,
            "circle_id": "fl:21:4070:0:2",
            "station_id": "fl:21:4070:0:2",
            "station_name": "Schaan Ivoclar"
        },
        "geometry": {
            "coordinates": [
                9.5091433908294,
                47.1719622705506
            ],
            "type": "Point"
        }
    },
    {
        "type": "Feature",
        "properties": {
            "new_station": true,
            "circle_id": "fl:21:4067:0:1",
            "station_id": "fl:21:4067:0:1",
            "station_name": "Schaan Hilcona"
        },
        "geometry": {
            "coordinates": [
                9.50852355328327,
                47.1755345990096
            ],
            "type": "Point"
        }
    },
    {
        "type": "Feature",
        "properties": {
            "new_station": true,
            "circle_id": "fl:21:5023:0:2",
            "station_id": "fl:21:5023:0:2",
            "station_name": "Schaan Industriestrasse"
        },
        "geometry": {
            "coordinates": [
                9.51297919709313,
                47.1777877902899
            ],
            "type": "Point"
        }
    },
    {
        "type": "Feature",
        "properties": {
            "new_station": true,
            "circle_id": "fl:21:101:0:1",
            "station_id": "fl:21:101:0:1",
            "station_name": "Schaan Hilti"
        },
        "geometry": {
            "coordinates": [
                9.52384881203248,
                47.180834636728
            ],
            "type": "Point"
        }
    }
]

let ind_2_wf_properties = [
    {
        "type": "Feature",
        "properties": {
            "new_station": true,
            "circle_id": "ch:23016:20302:30",
            "station_id": "ch:23016:20302:30",
            "station_name": "Buchs Bahnhof"
        },
        "geometry": {
            "coordinates": [
                9.47814253037012,
                47.168530166291
            ],
            "type": "Point"
        }
    },
    {
        "type": "Feature",
        "properties": {
            "new_station": true,
            "circle_id": 1,
            "station_id": null,
            "station_name": "New station (VAT)"
        },
        "geometry": {
            "coordinates": [
                9.49145340078124,
                47.206741164858244
            ],
            "type": "Point"
        }
    },
    {
        "type": "Feature",
        "properties": {
            "new_station": true,
            "circle_id": "8574425",
            "station_id": "8574425",
            "station_name": "Eschen, Sportpark"
        },
        "geometry": {
            "coordinates": [
                9.53387401060465,
                47.2056974487738
            ],
            "type": "Point"
        }
    },
    {
        "type": "Feature",
        "properties": {
            "new_station": true,
            "circle_id": "fl:21:1371:0:5",
            "station_id": "fl:21:1371:0:5",
            "station_name": "Nendeln Bahnhof"
        },
        "geometry": {
            "coordinates": [
                9.54189596609296,
                47.2006135147724
            ],
            "type": "Point"
        }
    }
]

let ind_zurich = [
    "8509411:0:5",
    [
      9.498358510516454,
      47.06890602688787
    ],
    "fl:21:380:0:1",
    "fl:21:4070:0:2",
    "fl:21:101:0:1",
    "8574425",
    "8588955"
  ]

let ind_zurich_properties = [
    {
        "type": "Feature",
        "properties": {
            "new_station": true,
            "circle_id": "8509411:0:5",
            "station_id": "8509411:0:5",
            "station_name": "Sargans Bahnhof"
        },
        "geometry": {
            "coordinates": [
                9.44492283115876,
                47.0457142775743
            ],
            "type": "Point"
        }
    },
    {
        "type": "Feature",
        "properties": {
            "new_station": true,
            "circle_id": 3,
            "station_id": null,
            "station_name": "New station"
        },
        "geometry": {
            "coordinates": [
                9.498297260996168,
                47.06887239703485
            ],
            "type": "Point"
        }
    },
    {
        "type": "Feature",
        "properties": {
            "new_station": true,
            "circle_id": "fl:21:380:0:1",
            "station_id": "fl:21:380:0:1",
            "station_name": "Vaduz Post"
        },
        "geometry": {
            "coordinates": [
                9.52171980480882,
                47.1386944979073
            ],
            "type": "Point"
        }
    },
    {
        "type": "Feature",
        "properties": {
            "new_station": true,
            "circle_id": "fl:21:4070:0:2",
            "station_id": "fl:21:4070:0:2",
            "station_name": "Schaan Ivoclar"
        },
        "geometry": {
            "coordinates": [
                9.5091433908294,
                47.1719622705506
            ],
            "type": "Point"
        }
    },
    {
        "type": "Feature",
        "properties": {
            "new_station": true,
            "circle_id": "fl:21:101:0:1",
            "station_id": "fl:21:101:0:1",
            "station_name": "Schaan Hilti"
        },
        "geometry": {
            "coordinates": [
                9.52384881203248,
                47.180834636728
            ],
            "type": "Point"
        }
    },
    {
        "type": "Feature",
        "properties": {
            "new_station": true,
            "circle_id": "8574425",
            "station_id": "8574425",
            "station_name": "Eschen, Sportpark"
        },
        "geometry": {
            "coordinates": [
                9.53387401060465,
                47.2056974487738
            ],
            "type": "Point"
        }
    },
    {
        "type": "Feature",
        "properties": {
            "new_station": true,
            "circle_id": "8588955",
            "station_id": "8588955",
            "station_name": "Haag (Rheintal), Rheinstrasse"
        },
        "geometry": {
            "coordinates": [
                9.49141963027126,
                47.2098350330042
            ],
            "type": "Point"
        }
    }
]

let stop_add_stations = false;

class HideElementsComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            hideNavs: false,
            mapa: this.props.map,
        }
    }

    hideComponent() {
        let newHideNavs = !this.state.hideNavs;
        this.setState({ hideNavs: newHideNavs })
        this.props.updateField("hideNavs", newHideNavs);
    }

    render() {

        const { hideNavs } = this.state;

        return (
            <div>
                {hideNavs ? <>
                    <div className='map-close-navs'>
                        <div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
                            <IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
                                <div>
                                    <RiFullscreenExitFill />
                                </div>
                            </IconContext.Provider>
                        </div>
                    </div>
                </> : <>
                    <div className='map-close-navs'>
                        <div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
                            <IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
                                <div>
                                    <RiFullscreenFill />
                                </div>
                            </IconContext.Provider>
                        </div>
                    </div>
                </>}
            </div>
        )
    }
}

//Layer Tyles Selection - Side Menu
class LayerTylesComponent extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            showOptions: false,
            mapa: this.props.map,
        }
        this.hideComponent = this.hideComponent.bind(this);
        this.handleTyleEvent = this.handleTyleEvent.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.map !== prevProps.map) {
            this.setState({ mapa: this.props.map })
        }
    }

    hideComponent() {
        this.setState({ showOptions: !this.state.showOptions });
    }

    handleTyleEvent(event) {
        switch (event) {
            case "default":
                this.props.handleTyle("mapbox://styles/mapbox/light-v10");
                break;
            case "outdoors":
                this.props.handleTyle("mapbox://styles/mapbox/outdoors-v11");
                break;
            case "satellite":
                this.props.handleTyle("mapbox://styles/mapbox/satellite-streets-v11");
                break;
            case "streets":
                this.props.handleTyle("mapbox://styles/mapbox/streets-v11");
                break;
            case "dark":
                this.props.handleTyle("mapbox://styles/mapbox/dark-v10");
                break;
            default:
                // eslint-disable-next-line no-unused-expressions
                null;
        }
        this.props.handleCoord(this.state.mapa.getCenter(), this.state.mapa.getZoom());
    }

    render() {
        const { showOptions } = this.state;

        return (
            <div>
                {showOptions ? <>
                    <div id='layer-options' className='map-layer-options-tyles'>
                        <div className='map-layer-option-dark' onClick={() => this.handleTyleEvent("dark")}>
                            <IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
                                <div>
                                    <img src={require('../../assets/img/Dark.png')} alt="Default tyle" className='img-tyle' />
                                </div>
                            </IconContext.Provider>
                            <p className='label'>Dark</p>
                        </div>
                        <div className='map-layer-option-streets' onClick={() => this.handleTyleEvent("streets")}>
                            <IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
                                <div>
                                    <img src={require('../../assets/img/Streets.png')} alt="Default tyle" className='img-tyle' />
                                </div>
                            </IconContext.Provider>
                            <p className='label'>Streets</p>
                        </div>
                        <div className='map-layer-option-satellite' onClick={() => this.handleTyleEvent("satellite")}>
                            <IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
                                <div>
                                    <img src={require('../../assets/img/Satellite.png')} alt="Default tyle" className='img-tyle' />
                                </div>
                            </IconContext.Provider>
                            <p className='label'>Satellite</p>
                        </div>
                        <div className='map-layer-option-outdoors' onClick={() => this.handleTyleEvent("outdoors")}>
                            <IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
                                <div>
                                    <img src={require('../../assets/img/Outdoors.png')} alt="Default tyle" className='img-tyle' />
                                </div>
                            </IconContext.Provider>
                            <p className='label'>Outdoors</p>
                        </div>
                        <div className='map-layer-option-default' onClick={() => this.handleTyleEvent("default")}>
                            <IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
                                <div>
                                    <img src={require('../../assets/img/White.png')} alt="Default tyle" className='img-tyle' />
                                </div>
                            </IconContext.Provider>
                            <p className='label'>Default</p>
                        </div>
                    </div>
                </> : <></>}
                <div className='map-layer-tyles'>
                    <div className='map-layer-tyles-inner' onClick={() => this.hideComponent("showOptions")}>
                        <IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
                            <div>
                                <BsLayersHalf />
                            </div>
                        </IconContext.Provider>
                    </div>
                </div>
            </div>
        )
    }
}

class BtnsComponent extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            mapa: this.props.map,
            canReset: false,
            total_trips: 0,
            green_trips: 0,
            yellow_trips: 0,
            red_trips: 0,

            percentage_trips_green: 0,
            percentage_trips_red: 0,
            percentage_trips_yellow: 0,

            total_trips_status: 0,
            green_trips_status: 0,
            yellow_trips_status: 0,
            red_trips_status: 0,

            percentage_trips_green_status: 0,
            percentage_trips_red_status: 0,
            percentage_trips_yellow_status: 0,

            status_quo: this.get_status_quo_stats(),
            evaluated: {},

            array_new_lines: [],
            switch_first_mile: false,
            switch_status_quo: true,
            routes_info: {},

            predefined: null,
            predefined_options: [
                {
                    'label': 'Industry Bus 1',
                    'value': 'ind-1',
                },
                {
                    'label': 'Industry Bus 2',
                    'value': 'ind-2',
                },
                {
                    'label': 'Industry Bus 1&2',
                    'value': 'ind-1-2',
                },
                {
                    'label': 'Industry Bus 1 (w/o Feldkirch)',
                    'value': 'ind-1-wf',
                },
                {
                    'label': 'Industry Bus 2 (w/o Feldkirch)',
                    'value': 'ind-2-wf',
                },
                {
                    'label': 'Industry Bus 1&2 (w/o Feldkirch)',
                    'value': 'ind-1-2-wf',
                },
                {
                    'label': 'Industry Bus Zurich',
                    'value': 'ind-zurich',
                }
            ],
        }
        this.get_status_quo();
    }

    componentDidMount() {
        this.handleEvaluate = this.handleEvaluate.bind(this);
        this.handleReset = this.handleReset.bind(this);
    }

    get_status_quo(){
        fetch(URL + 'bus_ai/status_quo', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "companies":['Thyssenkrupp Presta AG', 'Hilti AG','IVOCLAR'],
                "threshold_duration_percentage_green": [
                    0,
                    150
                  ],
                  "threshold_duration_percentage_yellow": [
                    150,
                    200
                  ],
                  "threshold_duration_percentage_red": [
                    200,
                    1000
                  ],
                  "max_difference_green": 15,
                  "max_changes_green": 2,
                  "max_changes_yellow": 5,
                  "min_changes_red": 5,
                  "traffic_mode": "best_guess",
                  "recompute":true,             
                  "first_mile":false
                }),
        })
            .then((res) => res.json())
            .then((json) => {
                URL_status_quo = json;
                this.state.mapa.getSource('data-current-tiles-ov').setData(URL_status_quo);
            })
      }
    
      handleStateSwitchStatusQuo(){
        let currentState = !this.state.switch_status_quo
        this.setState({ switch_status_quo: currentState })
        if(currentState){
            this.props.map.setLayoutProperty('data-current-tiles-ov', 'visibility', 'none');
            this.props.map.setLayoutProperty('data-current-tiles-ov-labels', 'visibility', 'none');
            this.props.map.setLayoutProperty('data-evaluated-tiles-ov', 'visibility', 'visible');
            this.props.map.setLayoutProperty('data-evaluated-tiles-ov-labels', 'visibility', 'visible');
        } else
        {
            this.props.map.setLayoutProperty('data-current-tiles-ov', 'visibility', 'visible');
            this.props.map.setLayoutProperty('data-current-tiles-ov-labels', 'visibility', 'visible');
            this.props.map.setLayoutProperty('data-evaluated-tiles-ov', 'visibility', 'none');
            this.props.map.setLayoutProperty('data-evaluated-tiles-ov-labels', 'visibility', 'none');
        }
      }

      handleStateSwitchFirstMile(){
        let currentState = !this.state.switch_first_mile
        this.setState({ switch_first_mile: currentState })
      }

    get_status_quo_stats(){
        fetch(URL + 'bus_ai/status_quo_stats', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "companies":['Thyssenkrupp Presta AG', 'Hilti AG','IVOCLAR'],
                "threshold_duration_percentage_green": [
                    0,
                    150
                  ],
                  "threshold_duration_percentage_yellow": [
                    150,
                    200
                  ],
                  "threshold_duration_percentage_red": [
                    200,
                    1000
                  ],
                  "max_difference_green": 15,
                  "max_changes_green": 2,
                  "max_changes_yellow": 5,
                  "min_changes_red": 5,
                  "traffic_mode": "best_guess",
                  "recompute":true,             
                  "first_mile":false
                }),
        })
        
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    total_trips_status: json.total,
            green_trips_status:  json['#55a630'],
            yellow_trips_status: json['#fdc700'],
            red_trips_status: json['#d30d0d'],

            percentage_trips_green_status: ((json['#55a630']/json.total)*100).toFixed(0),
            percentage_trips_red_status: ((json['#d30d0d']/json.total)*100).toFixed(0),
            percentage_trips_yellow_status: ((json['#fdc700']/json.total)*100).toFixed(0),
                })
                return json;
            })
      }

    async getOptionsEmployers() {
        const res = await axios.get(URL + 'big_employer_analysis/employers')
        const data = res.data
        const options = Object.keys(data).map(d => ({
            "value": d,
            "label": d
        }))
        this.setState({ employers: options, all_employers_data: data });
    }

    componentDidUpdate(prevProps) {
        if (this.props.map !== prevProps.map) {
            this.setState({ mapa: this.props.map });
        } else if (this.props.array_new_lines !== prevProps.array_new_lines) {
            this.setState({ array_new_lines: this.props.array_new_lines });
        }
    }

    handleReset() {
        stop_add_stations = false;
        general_routes_info = [];
        this.props.updateFields({ array_new_lines: [] });
        this.setState({ canReset: false, total_trips: 0, routes_info: {},
            green_trips: 0, yellow_trips: 0, red_trips: 0, evaluated: {}, predefined: null,
            percentage_trips_green: 0, percentage_trips_red: 0, percentage_trips_yellow: 0, 
            status_quo: this.get_status_quo_stats(), array_new_lines: [], switch_state: true, switch_status_quo: true })

            URL_stations = {
                'type': 'FeatureCollection',
                'features': [
                ]
            };

            URL_evaluated = {
                'type': 'FeatureCollection',
                'features': [
                ]
            };

            line_route = {
                'type': 'FeatureCollection',
                'features': [
                ]
            };

            this.props.map.setLayoutProperty('data-current-tiles-ov', 'visibility', 'visible');
            this.props.map.setLayoutProperty('data-current-tiles-ov-labels', 'visibility', 'visible');
            this.props.map.setLayoutProperty('data-evaluated-tiles-ov', 'visibility', 'none');
            this.props.map.setLayoutProperty('data-evaluated-tiles-ov-labels', 'visibility', 'none');
            this.state.mapa.getSource('data-new-line').setData(URL_stations);
            this.state.mapa.getSource('data-evaluated-tiles-ov').setData(URL_evaluated);
            this.state.mapa.getSource('data-new-line-route').setData(line_route);
    }

    handleEvaluate() {
        stop_add_stations = true;
        this.setState({ canReset: true })
        let array_stations = []
        for (let i = 0; i < URL_stations.features.length; i++) {
            if(URL_stations.features[i].properties.station_id===null){
                array_stations.push(URL_stations.features[i].geometry.coordinates)
            } else{
                array_stations.push(URL_stations.features[i].properties.circle_id)
            }
        }

        let routes_info = {};
        fetch(URL + 'bus_ai/return_new_line', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({routes: [array_stations] }),
        })
            .then((res) => res.json())
            .then((json) => {
                routes_info = json;
                line_route.features = json;
                this.state.mapa.getSource('data-new-line-route').setData(line_route);
                general_routes_info = routes_info
                console.log(general_routes_info)
                this.setState({routes_info: routes_info})
            }).then(()=>{
                fetch(URL + 'bus_ai/origins_new_line', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        "companies":['Thyssenkrupp Presta AG', 'Hilti AG','IVOCLAR'],
    "threshold_duration_percentage_green": [
        0,
        150
      ],
      "threshold_duration_percentage_yellow": [
        150,
        200
      ],
      "threshold_duration_percentage_red": [
        200,
        1000
      ],
      "max_difference_green": 15,
      "max_changes_green": 2,
      "max_changes_yellow": 5,
      "min_changes_red": 5,
      "traffic_mode": "best_guess",
      "recompute":true, 
                          "first_mile":false,
                          "routes_info": routes_info
                        }),
                })
                .then((res) => res.json())
                .then((json)=>{
                    URL_evaluated = json;
                    this.state.mapa.getSource('data-evaluated-tiles-ov').setData(URL_evaluated);
                }).then(()=>{
                    fetch(URL + 'bus_ai/new_line_stats', {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            "companies":['Thyssenkrupp Presta AG', 'Hilti AG','IVOCLAR'],
                            "threshold_duration_percentage_green": [
                                0,
                                150
                              ],
                              "threshold_duration_percentage_yellow": [
                                150,
                                200
                              ],
                              "threshold_duration_percentage_red": [
                                200,
                                1000
                              ],
                              "max_difference_green": 15,
                              "max_changes_green": 2,
                              "max_changes_yellow": 5,
                              "min_changes_red": 5,
                              "traffic_mode": "best_guess",
                              "recompute":true,                         
                              "first_mile":false,
                              "routes_info": routes_info
                            }),
                    })
                    .then((res) => res.json())
                    .then((json)=>{
                        this.setState({
                            evaluated: json,
                            total_trips: json.total,
                    green_trips:  json['#55a630'],
                    yellow_trips: json['#fdc700'],
                    red_trips: json['#d30d0d'],
        
                    percentage_trips_green: ((json['#55a630']/json.total)*100).toFixed(0),
                    percentage_trips_red: ((json['#d30d0d']/json.total)*100).toFixed(0),
                    percentage_trips_yellow: ((json['#fdc700']/json.total)*100).toFixed(0),
                        })
                    })
                })
            })

        this.props.map.setLayoutProperty('data-current-tiles-ov', 'visibility', 'none');
            this.props.map.setLayoutProperty('data-current-tiles-ov-labels', 'visibility', 'none');
            this.props.map.setLayoutProperty('data-evaluated-tiles-ov', 'visibility', 'visible');
            this.props.map.setLayoutProperty('data-evaluated-tiles-ov-labels', 'visibility', 'visible');
        //TODO
    }

    handleEvaluatePredefined(array) {
        stop_add_stations = true;
        this.setState({ canReset: true });

        let routes_info = {};
        fetch(URL + 'bus_ai/return_new_line', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({routes: array }),
        })
            .then((res) => res.json())
            .then((json) => {
                routes_info = json;
                line_route.features = json;
                this.state.mapa.getSource('data-new-line-route').setData(line_route);
                general_routes_info = routes_info
                console.log(general_routes_info)
                this.setState({routes_info: routes_info})
            }).then(()=>{
                fetch(URL + 'bus_ai/origins_new_line', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        "companies":['Thyssenkrupp Presta AG', 'Hilti AG','IVOCLAR'],
                        "threshold_duration_percentage_green": [
                            0,
                            150
                          ],
                          "threshold_duration_percentage_yellow": [
                            150,
                            200
                          ],
                          "threshold_duration_percentage_red": [
                            200,
                            1000
                          ],
                          "max_difference_green": 15,
                          "max_changes_green": 2,
                          "max_changes_yellow": 5,
                          "min_changes_red": 5,
                          "traffic_mode": "best_guess",
                          "recompute":true, 
                          "first_mile":false,
                          "routes_info": routes_info
                        }),
                })
                .then((res) => res.json())
                .then((json)=>{
                    URL_evaluated = json;
                    this.state.mapa.getSource('data-evaluated-tiles-ov').setData(URL_evaluated);
                }).then(()=>{
                    fetch(URL + 'bus_ai/new_line_stats', {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            "companies":['Thyssenkrupp Presta AG', 'Hilti AG','IVOCLAR'],
                            "threshold_duration_percentage_green": [
                                0,
                                150
                              ],
                              "threshold_duration_percentage_yellow": [
                                150,
                                200
                              ],
                              "threshold_duration_percentage_red": [
                                200,
                                1000
                              ],
                              "max_difference_green": 15,
                              "max_changes_green": 2,
                              "max_changes_yellow": 5,
                              "min_changes_red": 5,
                              "traffic_mode": "best_guess",
                              "recompute":true, 
                              "first_mile":false,
                              "routes_info": routes_info
                            }),
                    })
                    .then((res) => res.json())
                    .then((json)=>{
                        this.setState({
                            evaluated: json,
                            total_trips: json.total,
                    green_trips:  json['#55a630'],
                    yellow_trips: json['#fdc700'],
                    red_trips: json['#d30d0d'],
        
                    percentage_trips_green: ((json['#55a630']/json.total)*100).toFixed(0),
                    percentage_trips_red: ((json['#d30d0d']/json.total)*100).toFixed(0),
                    percentage_trips_yellow: ((json['#fdc700']/json.total)*100).toFixed(0),
                        })
                    })
                })
            })

        this.props.map.setLayoutProperty('data-current-tiles-ov', 'visibility', 'none');
            this.props.map.setLayoutProperty('data-current-tiles-ov-labels', 'visibility', 'none');
            this.props.map.setLayoutProperty('data-evaluated-tiles-ov', 'visibility', 'visible');
            this.props.map.setLayoutProperty('data-evaluated-tiles-ov-labels', 'visibility', 'visible');
        //TODO
    }

    nearestStation(array){
        if(!this.state.canReset){
            fetch(URL + 'bus_ai/return_closest_station/'+array[0]+'/'+array[1])
            .then((res) => res.json())
            .then((json) =>{
                
                  for (let i = 0; i < URL_stations.features.length; i++) {
                        let feature = URL_stations.features[i];
                        if(feature.geometry.coordinates[0]===array[0] && feature.geometry.coordinates[1]===array[1])
                        {
                        feature.properties.circle_id =  json.stop_id;
                        feature.properties.station_id =  json.stop_id;
                        feature.properties.station_name =  json.stop_name;
        
                        // Adding a small change to the coordinates
                        feature.geometry.coordinates[0] = json.stop_lon;  // Adjust longitude
                        feature.geometry.coordinates[1] = json.stop_lat;  // Adjust latitude
                    
                        // Put it back in the same position (which is automatic since objects are updated by reference)
                        URL_stations.features[i] = feature;
                    }
                }
                  let new_stations = URL_stations.features;
                  this.props.updateFields({ array_new_lines: new_stations })
                  this.state.mapa.getSource('data-new-line').setData(URL_stations);
            }
            )
        }
    }

    handlePredefined(event){
        this.setState({ predefined: event });

        if(event.value==='ind-1'){
            this.handleEvaluatePredefined([ind_1])
            this.props.updateFields({ array_new_lines: ind_1_properties })
        } else if(event.value==='ind-2'){
            this.handleEvaluatePredefined([ind_2])
            this.props.updateFields({ array_new_lines: ind_2_properties })
        } else if (event.value==='ind-1-2'){
            this.handleEvaluatePredefined([ind_1, ind_2])
        } else if(event.value==='ind-zurich'){
            this.handleEvaluatePredefined([ind_zurich])
            this.props.updateFields({ array_new_lines: ind_zurich_properties })
        }if(event.value==='ind-1-wf'){
            this.handleEvaluatePredefined([ind_1_wf])
            this.props.updateFields({ array_new_lines: ind_1_wf_properties })
        } else if(event.value==='ind-2-wf'){
            this.handleEvaluatePredefined([ind_2_wf])
            this.props.updateFields({ array_new_lines: ind_2_wf_properties })
        } else if (event.value==='ind-1-2-wf'){
            this.handleEvaluatePredefined([ind_1_wf, ind_2_wf])
        }
    }

    static contextType = NavigateContext;

    handleClick = () => {        
                this.context('/sankey-diagram', { state: {
                    "companies":['Thyssenkrupp Presta AG', 'Hilti AG','IVOCLAR'],
                    "threshold_duration_percentage_green": [
                        0,
                        150
                      ],
                      "threshold_duration_percentage_yellow": [
                        150,
                        200
                      ],
                      "threshold_duration_percentage_red": [
                        200,
                        1000
                      ],
                      "max_difference_green": 15,
                      "max_changes_green": 2,
                      "max_changes_yellow": 5,
                      "min_changes_red": 5,
                      "traffic_mode": "best_guess",
                      "recompute":true, 
                      "first_mile":false,
                      "routes_info": general_routes_info,
                      "colors":true
            } });
      }


    render() {
        const { green_trips } = this.state;
        const { red_trips } = this.state;
        const { yellow_trips } = this.state;

        const { percentage_trips_green } = this.state;
        const { percentage_trips_red } = this.state;
        const { percentage_trips_yellow } = this.state;

        const { green_trips_status } = this.state;
        const { red_trips_status } = this.state;
        const { yellow_trips_status } = this.state;

        const { percentage_trips_green_status } = this.state;
        const { percentage_trips_red_status } = this.state;
        const { percentage_trips_yellow_status } = this.state;

        const selectStyles = {
            menuList: styles => {
                return {
                    ...styles,
                    maxHeight: 150,
                };
            }
        };

        return (
            <div>
                 <div className="switch-change-div" style={{ top: '0px', left: '250px' }}>
                    <div className="switch-change-div-inner">
                                                    <Button className="co2-3dview-btn" variant="info" onClick={this.handleClick}>Sankey Diagram</Button>
                </div>
                </div>
                {(this.state.canReset) ? <>
                   

                    <div className="switch-change-div" style={{ top: '0px', left: '500px' }}>
                    <div className="switch-change-div-inner">
                        <div className="column-33">
                            <p className="label">Status Quo
                                (Today)
                            </p>
                        </div>
                        <div className="column-33">
                            <label className="switch">
                                <input type="checkbox" onChange={this.handleStateSwitchStatusQuo.bind(this)} value={this.state.switch_status_quo} defaultChecked={this.state.switch_status_quo}/>
                                <span className="slider round"></span>
                            </label>
                        </div>
                        <div className="column-33">
                            <p className="label">Evaluated Route</p>
                        </div>
                    </div>
                </div>
                
                <div className="map-data-from" style={{ top: "210px" }}>
								<div className="map-data-from-inner">
									<div className="row">
										<p>Data computed based on our inhouse routing algorithm </p>
									</div>
								</div>
							</div>
                        <div className="b2b-bus-btn-tile-potential-resp-1">
                            <div className="headquaters-btn-tile-potential-resp-1-inner">
                                <div className="row-parameters-3">
                                    <h3>{((!this.state.switch_status_quo)? percentage_trips_green_status: percentage_trips_green)}% Trips</h3>
                                    <h4>{numberWithCommas(((!this.state.switch_status_quo)? green_trips_status: green_trips))} Trips</h4>
                                    <h5>Great ÖV Quality</h5>
                                </div>
                            </div>
                        </div>
                        <div className="b2b-bus-btn-tile-potential-resp-2">
                            <div className="headquaters-btn-tile-potential-resp-2-inner">
                                <div className="row-parameters-3">
                                    <h3>{((!this.state.switch_status_quo)? percentage_trips_yellow_status: percentage_trips_yellow)}% Trips</h3>
                                    <h4>{numberWithCommas(((!this.state.switch_status_quo)? yellow_trips_status: yellow_trips))} Trips</h4>
                                    <h5>Decent ÖV Quality</h5>
                                </div>
                            </div>
                        </div>
                        <div className="b2b-bus-btn-tile-potential-resp-3">
                            <div className="headquaters-btn-tile-potential-resp-3-inner">
                                <div className="row-parameters-3">
                                    <h3>{((!this.state.switch_status_quo)? percentage_trips_red_status: percentage_trips_red)}% Trips</h3>
                                    <h4>{numberWithCommas(((!this.state.switch_status_quo)? red_trips_status: red_trips))} Trips</h4>
                                    <h5>Bad ÖV Quality</h5>
                                </div>
                            </div>
                        </div>
                </> : <>
                <div className="map-data-from" style={{ top: "210px" }}>
								<div className="map-data-from-inner">
									<div className="row">
										<p>Data computed based on our inhouse routing algorithm </p>
									</div>
								</div>
							</div>
                        <div className="b2b-bus-btn-tile-potential-resp-1">
                            <div className="headquaters-btn-tile-potential-resp-1-inner">
                                <div className="row-parameters-3">
                                    <h3>{(percentage_trips_green_status)}% Trips</h3>
                                    <h4>{numberWithCommas(green_trips_status)} Trips</h4>
                                    <h5>Great ÖV Quality</h5>
                                </div>
                            </div>
                        </div>
                        <div className="b2b-bus-btn-tile-potential-resp-2">
                            <div className="headquaters-btn-tile-potential-resp-2-inner">
                                <div className="row-parameters-3">
                                    <h3>{(percentage_trips_yellow_status)}% Trips</h3>
                                    <h4>{numberWithCommas(yellow_trips_status)} Trips</h4>
                                    <h5>Decent ÖV Quality</h5>
                                </div>
                            </div>
                        </div>
                        <div className="b2b-bus-btn-tile-potential-resp-3">
                            <div className="headquaters-btn-tile-potential-resp-3-inner">
                                <div className="row-parameters-3">
                                    <h3>{(percentage_trips_red_status)}% Trips</h3>
                                    <h4>{numberWithCommas(red_trips_status)} Trips</h4>
                                    <h5>Bad ÖV Quality</h5>
                                </div>
                            </div>
                        </div>
                </>}
                
                <div className="modal-no-headquaters-b2b-bus">
                        <div className="modal-no-headquaters-b2b-bus-inner">
                        <div className="row-parameters" style={{ height: 'auto'}}>
                            <div className="column"><h2>B2B: Liechtenstein</h2></div>
                            <div className="column"><p>Modify the timetable and visualize the impact on the region</p></div>

                        </div>
                        <div className="row-parameters" style={{ height: 'auto'}}>
                        <div className="column-1-50">
                                <Form onClick={this.handleEvaluate.bind(this)}>
                                    <Button className="generate-btn" variant="info" disabled={!(this.state.array_new_lines.length > 1) || this.state.canReset}>Evaluate Mobility Effects</Button>
                                </Form>
                            </div>
                            <div className="column-2-50">
                                <Form onClick={this.handleReset.bind(this)}>
                                    <Button className="reset-btn" variant="info" disabled={!this.state.canReset}>Reset to Status Quo</Button>
                                </Form>
                            </div>
                        </div>
                        </div>
                    </div>
                {this.state.array_new_lines.length > 0 ? <>
                    <div className="modal-selected-headquaters" style={{top: '230px'}}>
                        <div className="modal-selected-headquaters-inner">
                            <div className="fixed-box-headquaters">
                                <div className="timeline-headquaters" >
                                    <div>
                                        {this.state.array_new_lines.map(selected_headquaters => (
                                            <div className="container right" key={selected_headquaters.properties.circle_id}>
                                                <div className="content">
                                                    <div className="row" style={{display: 'flex', justifyContent: 'space-between'}}>
                                                        <div className="column" >
                                                            <p className="headquarters-employer" style={{marginTop: '18px'}}>{(selected_headquaters['properties']['station_name'])}</p>
                                                        </div>
                                                        <div className="column" >
                                                        <div className="row" style={{display: 'flex', paddingTop: '10px'}}>
                                                        <div className="deleteElement" onClick={() => this.nearestStation(selected_headquaters.geometry.coordinates)}>
                                                                <IconContext.Provider value={{ color: "#065c4f", size: '1.6em' }}>
                                                                    <div>
                                                                        <BiTargetLock />
                                                                    </div>
                                                                </IconContext.Provider>
                                                            </div>
                                                            <div className="deleteElement" onClick={() => this.props.deleteElem(selected_headquaters.properties.circle_id )}>
                                                                <IconContext.Provider value={{ color: "#ab0f2e", size: '1.6em' }}>
                                                                    <div>
                                                                        <IoTrashOutline />
                                                                    </div>
                                                                </IconContext.Provider>
                                                            </div>
                                                            
                                                            </div>
                                                            </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </> : <>
                    <div className="modal-no-headquaters-b2b-bus" style={{ top: '230px' }}>
                        <div className="modal-no-headquaters-b2b-bus-inner" style={{ height: '100px' }}>
                            <p className="no-data"><i>Please click on the map to start planning your routing</i></p>
                            <Select className='kanton' placeholder="Select a predefined route..." isClearable={true} options={this.state.predefined_options} onChange={this.handlePredefined.bind(this)} styles={selectStyles} value={this.state.predefined} />
                        </div>
                    </div>
                </>}
            </div>
        )
    }
}

//Map - B2B Bus AI
class MapB2BBus extends React.Component {

    // Set up states for updating map 
    constructor(props) {
        super(props);
        this.state = {
            lng: 9.541216530575952,
			lat: 47.15982225722226,
			zoom: 10.5,
			mapita: 10.5,
            tyle: 'mapbox://styles/mapbox/streets-v11',
            hideNavs: false,
            array_new_lines: []
        }
    }

    //Update several fields
    updateFields(list) {
        this.setState(list);
    }

    //Update map on component update
    updateField(card, value) {
        this.setState({
            ...this.state,
            [card]: value
        });
    }

    //Update style
    handleTyle(value) {
        this.setState({ tyle: value }, this.componentDidMount);
    }

    //Update coordinates
    handleCoord(center, zoom) {
        const { lng, lat } = center;
        this.setState({ zoom: zoom, lat: lat, lng: lng })
    }

    //Delete element on the array of selected stations
    deleteElem(id_elem) {

        URL_stations.features = URL_stations.features.filter(feature => feature.properties.circle_id !== id_elem)

      let new_stations = URL_stations.features;
      this.updateFields({ array_new_lines: new_stations })
      this.state.mapita.getSource('data-new-line').setData(URL_stations);
    }

    componentDidMount() {
        // Set up map 
        const map = new mapboxgl.Map({
            container: this.mapContainer,
            style: this.state.tyle,
            center: [this.state.lng, this.state.lat],
            zoom: this.state.zoom,
        });


        map.on('load', () => {
            // Add zoom and rotation controls to the map.
            map.addControl(new mapboxgl.NavigationControl({ showCompass: false, showZoom: false }), 'top-left');
            map.addControl(new MapboxGeocoder({ accessToken: mapboxgl.accessToken, mapboxgl: mapboxgl, marker: false }), 'top-left');

            //Circles - Stations
            //Add shapes to the map
            map.addSource('data-current-tiles-ov', {
                'type': 'geojson',
                'data': URL_status_quo,
                'generateId': true
            });

            map.addLayer({
                'id': 'data-current-tiles-ov',
                'type': 'fill',
                'source': 'data-current-tiles-ov',
                'layout': {
                    'visibility': "visible"
                },
                'paint': {
                    "fill-color": ['get', 'color'],
                    'fill-opacity': 0.3
                }
            });

            map.addLayer({
                'id': 'data-current-tiles-ov-labels',
                'type': 'symbol',
                'source': 'data-current-tiles-ov',
                "minzoom": 10.5,
                'layout': {
                    'text-field': ['get', 'trips'],
                    'text-font': [
                        'Open Sans Bold',
                        'Arial Unicode MS Bold'
                    ],
                    'text-size': 15,
                    'text-transform': 'uppercase',
                    'text-letter-spacing': 0.05,
                    'text-offset': [0, 1],
                    'visibility': 'visible'
                }
            });

            map.addSource('data-evaluated-tiles-ov', {
                'type': 'geojson',
                'data': URL_evaluated,
                'generateId': true
            });

            map.addLayer({
                'id': 'data-evaluated-tiles-ov',
                'type': 'fill',
                'source': 'data-evaluated-tiles-ov',
                'layout': {
                    'visibility': "visible"
                },
                'paint': {
                    "fill-color": ['get', 'color'],
                    'fill-opacity': 0.3
                }
            });

            map.addLayer({
                'id': 'data-evaluated-tiles-ov-labels',
                'type': 'symbol',
                'source': 'data-evaluated-tiles-ov',
                "minzoom": 10.5,
                'layout': {
                    'text-field': ['get', 'trips'],
                    'text-font': [
                        'Open Sans Bold',
                        'Arial Unicode MS Bold'
                    ],
                    'text-size': 15,
                    'text-transform': 'uppercase',
                    'text-letter-spacing': 0.05,
                    'text-offset': [0, 1],
                    'visibility': 'visible'
                }
            });

            map.addSource('data-new-line', {
                'type': 'geojson',
                'data': URL_stations,
                'generateId': true
            });

            map.addLayer({
				'id': 'data-new-line',
				'type': 'circle',
				'source': 'data-new-line',
				'layout': {
				},
				'paint': {
					'circle-radius': 4,
					"circle-stroke-width": 1,
					"circle-stroke-color": '#E32D8C',
					'circle-color': '#E32D8C',
					"circle-opacity": 1,
				},
			});

            map.addSource('data-new-line-route', {
                'type': 'geojson',
                'data': line_route,
                'generateId': true
            });

            map.addLayer({
				'id': 'data-new-line-route',
				'type': 'line',
				'source': 'data-new-line-route',
				layout: {
                    'line-join': 'round',
                    'line-cap': 'round'
                  },
                  paint: {
                    'line-color': '#E32D8C',
                    'line-width': 5
                  }
			});

        this.setState({
            mapita: map,
            zoom: map.getZoom(),
        });

        // Create a popup, but don't add it to the map yet.
        const popupHover = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false
        });

        let num_station = 0;

        map.on('click', (e) => {
            if(!stop_add_stations){
                URL_stations.features.push({
                    "type": "Feature",
                    "properties": {
                        new_station: true,
                        circle_id: ++num_station,
                        station_id: null,
                        station_name: 'New station',
                    },
                    "geometry": {
                      "coordinates": [
                        e.lngLat.lng, e.lngLat.lat
                      ],
                      "type": "Point"
                    }
                  })
    
                  let new_stations = URL_stations.features
                  console.log(new_stations)
                  this.setState({ array_new_lines: new_stations });
                  map.getSource('data-new-line').setData(URL_stations);    
            }
		});

    })
}

    render() {
        const { hideNavs } = this.state;

        return (
            <div>
                <NavigateProvider>
                <div ref={el => this.mapContainer = el} style={{ width: '100%', height: '100vh' }}>
                </div>
                <RecommendationNavBarComponent />
                <HideElementsComponent map={this.state.mapita} updateField={(card, field, value) => this.updateField(card, field, value)} />
                <div style={{ display: hideNavs ? 'none' : 'block' }}>
                        <BtnsComponent map={this.state.mapita} updateFields={(list) => this.updateFields(list)} array_new_lines={this.state.array_new_lines} deleteElem={(id_elem) => this.deleteElem(id_elem)} updateField={(card, field, value) => this.updateField(card, field, value)}/>
                    </div>
                <LayerTylesComponent map={this.state.mapita} tyle={this.state.tyle} handleTyle={(value) => this.handleTyle(value)} handleCoord={(value1, value2) => this.handleCoord(value1, value2)} />
                </NavigateProvider>
            </div>
        )
    }
}

export default  MapB2BBus;