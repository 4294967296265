/* eslint-disable react/style-prop-object */
import React from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import { IconContext } from "react-icons";
import { IoTrashOutline } from "react-icons/io5";
import axios from 'axios';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { BsLayersHalf } from "react-icons/bs";
import { Form, Button } from 'react-bootstrap';
import Chart from 'chart.js/auto';
import { Line } from 'react-chartjs-2';
import Xarrow from "react-xarrows";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import '../../components/layer-tyles.css';
import '../css/map-tabs-boostrap-graphs.css';
import '../css/map-potential-routes.css';
import RecommendationNavBarComponent from "../../components/RecommendationNavBarComponent";
import { RiFullscreenFill, RiFullscreenExitFill } from "react-icons/ri";
import MappSimulation from "./Map-Simulation";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const URL = process.env.REACT_APP_URL_SERVER_BACK;

function numberWithCommas(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

let URL_lines = {
	'type': 'FeatureCollection',
	'features': [
		{
			'type': 'Feature',
			'geometry': {
				"type": "LineString",
				"coordinates": [
				]
			}
		}
	]
};

let URL_points = {
	'type': 'FeatureCollection',
	'features': [
		{
			'type': 'Feature',
			'geometry': {
				"type": "LineString",
				"coordinates": [
				]
			}
		}
	]
};

let generated_line = "";

class HideElementsComponent extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			hideNavs: false,
			mapa: this.props.map,
		}
	}

	hideComponent() {
		let newHideNavs = !this.state.hideNavs;
		this.setState({ hideNavs: newHideNavs })
		this.props.updateField("hideNavs", newHideNavs);
	}

	render() {

		const { hideNavs } = this.state;

		return (
			<div>
				{hideNavs ? <>
					<div className='map-close-navs'>
						<div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
								<div>
									<RiFullscreenExitFill />
								</div>
							</IconContext.Provider>
						</div>
					</div>
				</> : <>
					<div className='map-close-navs'>
						<div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
								<div>
									<RiFullscreenFill />
								</div>
							</IconContext.Provider>
						</div>
					</div>
				</>}
			</div>
		)
	}
}

//Layer Tyles Selection - Side Menu
class LayerTylesComponent extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			showOptions: false,
			mapa: this.props.map,
		}
		this.hideComponent = this.hideComponent.bind(this);
		this.handleTyleEvent = this.handleTyleEvent.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (this.props.map !== prevProps.map) {
			this.setState({ mapa: this.props.map })
		}
	}

	hideComponent(name) {
		switch (name) {
			case "showOptions":
				this.setState({ showOptions: !this.state.showOptions });
				break;
			default:
				// eslint-disable-next-line no-unused-expressions
				null;
		}
	}

	handleTyleEvent(event) {
		switch (event) {
			case "default":
				this.props.handleTyle("mapbox://styles/mapbox/light-v10");
				break;
			case "outdoors":
				this.props.handleTyle("mapbox://styles/mapbox/outdoors-v11");
				break;
			case "satellite":
				this.props.handleTyle("mapbox://styles/mapbox/satellite-streets-v11");
				break;
			case "streets":
				this.props.handleTyle("mapbox://styles/mapbox/streets-v11");
				break;
			case "dark":
				this.props.handleTyle("mapbox://styles/mapbox/dark-v10");
				break;
			default:
				// eslint-disable-next-line no-unused-expressions
				null;
		}
		this.props.handleCoord(this.state.mapa.getCenter(), this.state.mapa.getZoom());
	}

	render() {
		const { showOptions } = this.state;

		return (
			<div>
				{showOptions ? <>
					<div id='layer-options' className='map-layer-options-tyles'>
						<div className='map-layer-option-dark' onClick={() => this.handleTyleEvent("dark")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/Dark.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Dark</p>
						</div>
						<div className='map-layer-option-streets' onClick={() => this.handleTyleEvent("streets")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/Streets.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Streets</p>
						</div>
						<div className='map-layer-option-satellite' onClick={() => this.handleTyleEvent("satellite")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/Satellite.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Satellite</p>
						</div>
						<div className='map-layer-option-outdoors' onClick={() => this.handleTyleEvent("outdoors")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/Outdoors.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Outdoors</p>
						</div>
						<div className='map-layer-option-default' onClick={() => this.handleTyleEvent("default")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/White.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Default</p>
						</div>
					</div>
				</> : <></>}
				<div className='map-layer-tyles'>
					<div className='map-layer-tyles-inner' onClick={() => this.hideComponent("showOptions")}>
						<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
							<div>
								<BsLayersHalf />
							</div>
						</IconContext.Provider>
					</div>
				</div>
			</div>
		)
	}
}

class PotentialCorridorsInfo extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			
			mapa: this.props.map,
			showRoutes: this.props.showRoutes,
			resp_data_insights: '',
			resp_station_insights: '',
			selected_station_select_react: ({
				"value": "",
				"label": ''
			}),
			value_od_dates: ({
				"value": "2022-08",
				"label": '2022-08'
			}),
			dates_trips_: this.getOptionsDate(URL + 'db_od_trips'),
			value_agency: ({
				"value": "",
				"label": ""
			}),
			day_of_week: 'weekdays',
			isGenerated: false,
			array_selected_stations: this.props.array_selected_stations,
			selected_route_agency: this.props.selected_route_agency,
			selected_stations: [],
			selected_options_agency: [],
			selected_option_line: [],
			aggregation: 'plz',
			origin_destination: 'origins',
			routes_list: [],
			loading: false,
			hasAgency: false,
			resp_evaluate_company: '',
			num_passengers: 'No data available'
		}
	}

	loadOptions = (inputValue, callback) => {
		setTimeout(() => {
			fetch(URL + 'potential_routes/search_engine', {
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({ query: inputValue })
			})
				.then((resp) => {
					return resp.json();
				})
				.then((data) => {
					const tempArray = [];
					if (data) {
						if (data.length) {
							data.forEach((element) => {
								tempArray.push(element);
							});
						}
					}
					callback(tempArray);
				})
				.catch((error) => {
					console.log(error, "catch the hoop");
				});
		}, 1000);
	};

	componentDidMount() {
		this.showRoutes = this.showRoutes.bind(this);
		this.deleteElem = this.deleteElem.bind(this);
		this.stationInsightsHandler = this.stationInsightsHandler.bind(this);
		this.handleMonthYear = this.handleMonthYear.bind(this);
		this.handleDayOfWeek = this.handleDayOfWeek.bind(this);
		this.handleOptionLineRoute = this.handleOptionLineRoute.bind(this);
		this.handleOptionAgency = this.handleOptionAgency.bind(this);
		this.handleTypeofTable = this.handleTypeofTable.bind(this);
		this.handleFullScreenAnalysis = this.handleFullScreenAnalysis.bind(this);
		this.resetPlatform = this.resetPlatform.bind(this);
		this.handleEvaluateCompany = this.handleEvaluateCompany.bind(this);
	}

	async getOptionRouteLines(agency_id) {
		fetch(URL + 'potential_routes/search_engine/routes', {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ agency_id: agency_id, query: "a" })
		})
			.then((res) => res.json())
			.then((json) => {
				this.setState({ routes_list: json });
			})
	}

	async getOptionsDate(url) {
		const res = await axios.get(url)
		const data = res.data
		const options = data.map(d => ({
			"value": d._id.date.toString().split('T')[0].slice(0, -3),
			"label": d._id.date.toString().split('T')[0].slice(0, -3)
		}))
		this.setState({ dates_trips_: options });
		//this.setState({value_od_dates: options[options.length-1]});
	}

	getOptions() {
		if (this.state.array_selected_stations.length > 0) {
			const data = this.state.array_selected_stations
			const options = data.map(d => ({
				"value": d.stop_id,
				"label": d['Stop name']
			}))
			this.setState({ selected_stations: options })
		}
		else {
			this.setState({ selected_stations: [] })
		}
	}

	handleEvaluateCompany() {

		let body = {
			"agency_id": this.state.selected_options_agency.value,
			"day_of_week": this.state.day_of_week,
			"filter_level": this.state.aggregation
		}


		this.setState({ loading: true })
		fetch(URL + 'potential_routes/transport_company', {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(body)
		})
			.then((res) => res.json())
			.then((json) => {
				this.setState({ resp_evaluate_company: json[0] });
			}).then(() => this.setState({ loading: false }))
	}

	async stationInsightsHandler(event) {
		this.fetchHandler(event);
		this.fetchHandler(event);
	}

	async fetchHandler(event) {
		let id_stationSelected = event.value;
		let stop_list = [];

		if (this.state.selected_station_select_react.label === '') {
			this.setState({ selected_station_select_react: event });
		}

		this.state.array_selected_stations.map(selected_stations => (
			stop_list.push(selected_stations.stop_id)
		))

		fetch(URL + 'potential_routes/insights_per_station', {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ date: this.state.value_od_dates.value + "-01", stop_list: stop_list, selected_station_id: id_stationSelected, day_of_week: this.state.day_of_week, filter_level: this.state.aggregation })
		})
			.then((res) => res.json())
			.then((json) => {
				this.setState({ resp_station_insights: json });
			})
	}

	componentDidUpdate(prevProps) {
		if (this.props.map !== prevProps.map) {
			this.setState({ mapa: this.props.map });
		}
		if (this.props.array_selected_stations !== prevProps.array_selected_stations) {
			this.setState({ array_selected_stations: this.props.array_selected_stations });
		}
		if (this.props.showRoutes !== prevProps.showRoutes) {
			this.setState({ showRoutes: this.props.showRoutes });
		}
		if (this.props.selected_route_agency !== prevProps.selected_route_agency) {
			this.setState({ selected_route_agency: this.props.selected_route_agency });
			this.handleOptionLineRoute(this.props.selected_route_agency);
		}
	}

	handleFullScreenAnalysis() {
		let fullscreen = !this.state.fullScreen
		this.setState({ fullScreen: fullscreen })
		if (!fullscreen) {
			this.setState({ resp_station_insights: '' })
		}
	}

	//TODO
	async handleFetchDataRoute() {
		let stop_list = [];
		this.state.array_selected_stations.map(selected_stations => (
			stop_list.push(selected_stations.stop_id)
		))

		this.setState({ showRoutes: true })
			fetch(URL + "potential_routes/routes/" + this.state.selected_option_line.value)
				.then((res) => res.json())
				.then((json) => {
					let array_complete = []
					json.features[0].properties.stop_name.map((stop, index) =>
						array_complete.push({
							'Stop name': stop,
							'stop_id': json.features[0].properties.stops[index]
						})
					);
					this.setState({ array_selected_stations: array_complete })
				}
				).then(() => {
					this.state.mapa.getSource('data-lines-routes').setData(URL + "potential_routes/routes_template/empty");
					this.state.mapa.setLayoutProperty('data-circles-stations', 'visibility', 'visible');
					this.state.mapa.getSource('data-circles-stations').setData(URL + "potential_corridors/stations_route/" + this.state.selected_option_line.value);
				})
				.then(() => {

					let stop_list = [];
					let data;
					this.state.array_selected_stations.map(selected_stations => (
						stop_list.push(selected_stations.stop_id)
					))

					fetch(URL + 'potential_routes/checked_geometries', {
						method: 'POST',
						headers: {
							'Accept': 'application/json',
							'Content-Type': 'application/json'
						},
						body: JSON.stringify({ stop_list: stop_list, filter_level: this.state.aggregation })
					}).then((res) => res.json())
						.then((json) => {
							data = json;
						}).then(() => {
							this.state.mapa.getSource('data-swiss-tile-plz').setData(data);
						})
						.then(() => {
							fetch(URL + 'potential_routes/evaluate_route', {
								method: 'POST',
								headers: {
									'Accept': 'application/json',
									'Content-Type': 'application/json'
								},
								body: JSON.stringify({ date: this.state.value_od_dates.value + "-01", stop_list: stop_list, day_of_week: this.state.day_of_week, filter_level: this.state.aggregation })
							})
								.then((res) => res.json())
								.then((json) => {
									data = json;
									this.setState({ resp_data_insights: json });
					
								}).then(() => { this.state.mapa.getSource('data-lines-generated-routes').setData(data) })
								.then(()=>{
									fetch(URL + 'potential_corridors/origin_destination', {
										method: 'POST',
										headers: {
											'Accept': 'application/json',
											'Content-Type': 'application/json'
										},
										body: JSON.stringify({
											date: this.state.value_od_dates.value + "-01", route_id: this.state.selected_option_line.value,
											day_of_week: this.state.day_of_week, filter_level: (this.state.aggregation==='tile'? 'tiles':this.state.aggregation), aggregation: this.state.origin_destination
										})
									})
										.then((res) => res.json())
										.then((json) => {
											data = json;
										}).then(() => { this.state.mapa.getSource('data-swiss-origin-destination').setData(data) })
										.then(() => {
											fetch(URL + 'potential_corridors/insights', {
												method: 'POST',
												headers: {
													'Accept': 'application/json',
													'Content-Type': 'application/json'
												},
												body: JSON.stringify({
													date: this.state.value_od_dates.value + "-01", route_id: this.state.selected_option_line.value,
													day_of_week: this.state.day_of_week, filter_level: (this.state.aggregation==='tile'? 'tiles':this.state.aggregation), aggregation: this.state.origin_destination
												})
											})
												.then((res) => res.json())
												.then((json)=>{
													this.setState({num_passengers: numberWithCommas((json[0].counts).toFixed(0))})
												})
										})
								})
						})
				})

		this.state.mapa.setLayoutProperty('data-circles-stations', 'visibility', 'none');
		this.state.mapa.getSource('data-lines-routes').setData(URL + "potential_routes/routes_template/empty");
		this.setState({ isGenerated: true })
	}

	async handleFecthDataRouteDateChanged(date_month_year) {
		let stop_list = [];
		let data;
		this.state.array_selected_stations.map(selected_stations => (
			stop_list.push(selected_stations.stop_id)
		))
		fetch(URL + 'potential_routes/evaluate_route', {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ date: date_month_year + "-01", stop_list: stop_list, day_of_week: this.state.day_of_week })
		})
			.then((res) => res.json())
			.then((json) => {
				data = json;
				this.setState({ resp_data_insights: json });

			}).then(() => { this.state.mapa.getSource('data-lines-generated-routes').setData(data); this.getOptions() })
			.then(() => {
				fetch(URL + 'potential_routes/checked_geometries', {
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({ stop_list: stop_list, filter_level: this.state.aggregation })
				}).then((res) => res.json())
					.then((json) => {
						data = json;
					}).then(() => { this.state.mapa.getSource('data-swiss-tile-plz').setData(data) });
			})

		this.state.mapa.getSource('data-circles-stations').setData(URL + "potential_routes/routes_template/empty");
		this.state.mapa.getSource('data-lines-routes').setData(URL + "potential_routes/routes_template/empty");
	}

	async stationInsightsHandlerDateChanged(event, date_month_year) {
		this.fetchHandlerDateChanged(event, date_month_year);
		this.fetchHandlerDateChanged(event, date_month_year);
	}

	async fetchHandlerDateChanged(event, date_month_year) {
		let id_stationSelected = event.value;
		let stop_list = [];

		if (this.state.selected_station_select_react.label === '') {
			this.setState({ selected_station_select_react: event });
		}

		this.state.array_selected_stations.map(selected_stations => (
			stop_list.push(selected_stations.stop_id)
		))
		fetch(URL + 'potential_routes/insights_per_station', {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ date: date_month_year + "-01", stop_list: stop_list, selected_station_id: id_stationSelected, day_of_week: this.state.day_of_week, filter_level: this.state.aggregation })
		})
			.then((res) => res.json())
			.then((json) => {
				this.setState({ resp_station_insights: json });
			})
	}

	showRoutes() {
		if (this.state.array_selected_stations.length > 0) {
			this.setState({ showRoutes: true })
		}
		else {
			this.setState({ showRoutes: false })
		}
	}

	deleteElem(id_elem) {
		let elem = this.props.deleteElem(id_elem);

		//Lines - Routes
		//Add shapes to the map
		this.state.mapa.setFeatureState({
			source: 'data-circles-stations',
			id: elem.circle_map_id,
		}, {
			clicked: false
		});
		this.showRoutes();
	}

	resetPlatform() {
		this.state.mapa.getSource('data-lines-routes').setData(URL_lines);
		this.state.mapa.getSource('data-swiss-tile-plz').setData(URL_lines);
		this.state.mapa.getSource('data-circles-stations').setData(URL_lines);
		this.state.mapa.getSource('data-swiss-origin-destination').setData(URL_lines);

		//this.state.mapa.getSource('data-circles-stations').setData(URL_points);
		this.state.mapa.getSource('data-lines-generated-routes').setData(URL + "potential_routes/routes_template/empty");
		this.state.array_selected_stations.forEach(element => this.state.mapa.setFeatureState({
			source: 'data-circles-stations',
			id: element.circle_map_id,
			generateId: true
		}, {
			clicked: false
		}));
		this.props.updateFields({ array_selected_stations: [] })
		this.setState({ isGenerated: false, hasAgency: false, showRoutes: false })
		this.setState({
			selected_station_select_react: ({
				"value": "",
				"label": ''
			}),
			selected_stations: [],
			selected_options_agency: [],
			selected_option_line: [],
			aggregation: 'plz',
			origin_destination: 'origins',
			routes_list: [],
			loading: false,
			hasAgency: false,
			resp_evaluate_company: '',
			num_passengers: 'No data available'
		})
	}

	handleMonthYear(event) {
		this.setState({ value_od_dates: event });
		if (this.state.array_selected_stations.length !== 0 && this.state.isGenerated) {
			this.handleFecthDataRouteDateChanged(event.value)
		}
		if (this.state.selected_station_select_react.value !== '') {
			this.stationInsightsHandlerDateChanged(this.state.selected_station_select_react, event.value)
		}
	}

	//TODO
	handleOptionLineRoute(event) {
		this.setState({ selected_option_line: event })
		if (event != null) {
			this.setState({ showRoutes: true })
			fetch(URL + "potential_routes/routes/" + event.value)
				.then((res) => res.json())
				.then((json) => {
					let array_complete = []
					json.features[0].properties.stop_name.map((stop, index) =>
						array_complete.push({
							'Stop name': stop,
							'stop_id': json.features[0].properties.stops[index]
						})
					);
					this.setState({ array_selected_stations: array_complete })
				}
				).then(() => {
					this.state.mapa.getSource('data-lines-routes').setData(URL + "potential_routes/routes_template/empty");
					this.state.mapa.setLayoutProperty('data-circles-stations', 'visibility', 'visible');
					this.state.mapa.getSource('data-circles-stations').setData(URL + "potential_corridors/stations_route/" + event.value);
				})
				.then(() => {

					let stop_list = [];
					let data;
					this.state.array_selected_stations.map(selected_stations => (
						stop_list.push(selected_stations.stop_id)
					))

					fetch(URL + 'potential_routes/checked_geometries', {
						method: 'POST',
						headers: {
							'Accept': 'application/json',
							'Content-Type': 'application/json'
						},
						body: JSON.stringify({ stop_list: stop_list, filter_level: this.state.aggregation })
					}).then((res) => res.json())
						.then((json) => {
							data = json;
						}).then(() => {
							this.state.mapa.getSource('data-swiss-tile-plz').setData(data);
						})
						.then(() => {
							fetch(URL + 'potential_routes/evaluate_route', {
								method: 'POST',
								headers: {
									'Accept': 'application/json',
									'Content-Type': 'application/json'
								},
								body: JSON.stringify({ date: this.state.value_od_dates.value + "-01", stop_list: stop_list, day_of_week: this.state.day_of_week, filter_level: this.state.aggregation })
							})
								.then((res) => res.json())
								.then((json) => {
									data = json;
									this.setState({ resp_data_insights: json });
					
								}).then(() => { this.state.mapa.getSource('data-lines-generated-routes').setData(data) })
								.then(()=>{
									fetch(URL + 'potential_corridors/origin_destination', {
										method: 'POST',
										headers: {
											'Accept': 'application/json',
											'Content-Type': 'application/json'
										},
										body: JSON.stringify({
											date: this.state.value_od_dates.value + "-01", route_id: event.value,
											day_of_week: this.state.day_of_week, filter_level: (this.state.aggregation==='tile'? 'tiles':this.state.aggregation), aggregation: this.state.origin_destination
										})
									})
										.then((res) => res.json())
										.then((json) => {
											data = json;
										}).then(() => { this.state.mapa.getSource('data-swiss-origin-destination').setData(data) })
										.then(() => {
											fetch(URL + 'potential_corridors/insights', {
												method: 'POST',
												headers: {
													'Accept': 'application/json',
													'Content-Type': 'application/json'
												},
												body: JSON.stringify({
													date: this.state.value_od_dates.value + "-01", route_id: event.value,
													day_of_week: this.state.day_of_week, filter_level: (this.state.aggregation==='tile'? 'tiles':this.state.aggregation), aggregation: this.state.origin_destination
												})
											})
												.then((res) => res.json())
												.then((json)=>{
													this.setState({num_passengers: numberWithCommas((json[0].counts).toFixed(0))})
												})
										})
								})
						})
				})
		}
		else {
			if (this.state.hasAgency) {
				let selected_agencies_ids = this.state.selected_options_agency.value;
				let data;
				this.setState({ showRoutes: false })

				this.getOptionRouteLines(selected_agencies_ids)

				fetch(URL + 'potential_routes/routes_template/routes', {
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({ "agency_id": selected_agencies_ids })
				})
					.then((res) => res.json())
					.then((json) => {
						data = json;
					}).then(() => { URL_points = data }).then(() => { this.state.mapa.getSource('data-lines-routes').setData(data); this.getOptions() })
				this.state.mapa.getSource('data-lines-generated-routes').setData(URL_lines);
				this.state.mapa.getSource('data-swiss-tile-plz').setData(URL_lines);
				this.state.mapa.getSource('data-swiss-origin-destination').setData(URL_lines);
				this.state.mapa.setLayoutProperty('data-circles-stations', 'visibility', 'none');
			}
		}
	}

	handleOptionAgency(event) {
		if (event === null) {
			this.setState({ hasAgency: false })
			this.state.mapa.getSource('data-lines-routes').setData(URL_lines);
			this.setState({ selected_options_agency: event });
		} else {
			this.setState({ hasAgency: true })
			this.state.mapa.setLayoutProperty('data-circles-stations', 'visibility', 'none');
			this.setState({ selected_options_agency: event })

			let selected_agencies_ids = event.value;
			let data;

			this.getOptionRouteLines(selected_agencies_ids)

			fetch(URL + 'potential_routes/routes_template/routes', {
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({ "agency_id": selected_agencies_ids })
			})
				.then((res) => res.json())
				.then((json) => {
					data = json;
				}).then(() => { URL_points = data }).then(() => { this.state.mapa.getSource('data-lines-routes').setData(data); this.getOptions() })
		}
	}

	nameStation(id_elem) {
		const target = this.state.array_selected_stations.find(e => e.stop_id === id_elem);
		return target['Stop name'];
	}

	handleDayOfWeek(event) {
		this.setState({ day_of_week: event.target.attributes.getNamedItem('id').value });

		if (this.state.array_selected_stations.length !== 0 && this.state.isGenerated) {
			this.handleFecthDataRouteDayOfWeekChanged(event.target.attributes.getNamedItem('id').value)
		}
		if (this.state.selected_station_select_react.value !== '') {
			this.stationInsightsHandlerDayOfWeekChanged(event.target.attributes.getNamedItem('id').value)
		}
	}

	handleAggregation(event) {
		this.setState({ aggregation: event.target.attributes.getNamedItem('id').value });
	}

	handleOriginDestination(event) {
		this.setState({ origin_destination: event.target.attributes.getNamedItem('id').value });
	}

	handleTypeofTable(event) {
		this.setState({ selected_type_table_current: event });
	}

	async handleFecthDataRouteDayOfWeekChanged(day_of_week) {
		let stop_list = [];
		let data;
		this.state.array_selected_stations.map(selected_stations => (
			stop_list.push(selected_stations.stop_id)
		))
		fetch(URL + 'potential_routes/evaluate_route', {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ date: this.state.value_od_dates.value + "-01", stop_list: stop_list, day_of_week: day_of_week, filter_level: this.state.aggregation })
		})
			.then((res) => res.json())
			.then((json) => {
				data = json;
				this.setState({ resp_data_insights: json });

			}).then(() => { this.state.mapa.getSource('data-lines-generated-routes').setData(data); this.getOptions() })
		this.state.mapa.getSource('data-circles-stations').setData(URL + "potential_routes/routes_template/empty");
		this.state.mapa.getSource('data-lines-routes').setData(URL + "potential_routes/routes_template/empty");
	}

	async stationInsightsHandlerDayOfWeekChanged(date_month_year) {
		this.fetchHandlerDayOfWeekChanged(date_month_year);
		this.fetchHandlerDayOfWeekChanged(date_month_year);
	}

	async fetchHandlerDayOfWeekChanged(day_of_week) {
		let id_stationSelected = this.state.selected_station_select_react.value;
		let stop_list = [];

		if (this.state.selected_station_select_react.label === '') {
			this.setState({ selected_station_select_react: this.state.selected_station_select_react });
		}

		this.state.array_selected_stations.map(selected_stations => (
			stop_list.push(selected_stations.stop_id)
		))
		fetch(URL + 'potential_routes/insights_per_station', {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ date: this.state.value_od_dates.value + "-01", stop_list: stop_list, selected_station_id: id_stationSelected, day_of_week: day_of_week, filter_level: this.state.aggregation })
		})
			.then((res) => res.json())
			.then((json) => {
				this.setState({ resp_station_insights: json });
			})
	}

	render() {

		const { showRoutes } = this.state;
		const { value_od_dates } = this.state;
		const { selected_options_agency } = this.state;
		const { selected_option_line } = this.state;
		const { array_selected_stations } = this.state;
		const { loading } = this.state;

		const selectDate = {
			menuList: styles => {
				return {
					...styles,
					maxHeight: 150,
					borderWidth: 0,
					boxShadow: '0 0 10px 2px rgb(0 0 0 / 10%)',
				};
			}
		};

		return (
			<div>
				<div className="modal-potential-corridors-buttons">
					<div className="modal-potential-corridors-buttons-inner">
						<p className='label' htmlFor="Filter based on">Filters based on</p>
						<div className="row">
							<Form onChange={this.handleDayOfWeek.bind(this)}>
								<div className="column-1-eq">
									<Form.Check inline label=" Weekdays" name="type_analysis" type='radio' id='weekdays' defaultChecked={true} />
								</div>
								<div className="column-2-eq">
									<Form.Check inline label=" Weekends" name="type_analysis" type='radio' id='weekend' />
								</div>
							</Form>
						</div>
						<p className='label' htmlFor="Aggregate by">Aggregate by</p>
						<div className="row">
							<Form onChange={this.handleAggregation.bind(this)}>
								<div className="column-1-eq">
									<Form.Check inline label=" 1Km*1Km Tile" name="type_aggre" type='radio' id='tile'/>
								</div>
								<div className="column-2-eq">
									<Form.Check inline label=" Postal Code" name="type_aggre" type='radio' id='plz' defaultChecked={true}/>
								</div>
							</Form>
						</div>
						<p className='label' htmlFor="Aggregate by">Show {this.state.aggregation === 'plz' ? 'Postal Codes' : '1Km Tiles'} that are</p>
						<div className="row">
							<Form onChange={this.handleOriginDestination.bind(this)}>
								<div className="column-1-eq">
									<Form.Check inline label=" Origin" name="type_aggre" type='radio' id='origins' defaultChecked={true} />
								</div>
								<div className="column-2-eq">
									<Form.Check inline label=" Destination" name="type_aggre" type='radio' id='destinations' />
								</div>
							</Form>
						</div>
						<div className="row">
							<div className="column-1-eqr">
								<Form onClick={this.handleFetchDataRoute.bind(this)}>
									<Button variant="info" className="generate-btn" disabled={(array_selected_stations.length <= 0)}>Evaluate route</Button>
								</Form>
							</div>
							<div className="column-2-eqr">
								<Form onClick={this.resetPlatform.bind(this)}>
									<Button variant="info" className="reset-btn">Reset</Button>
								</Form>
							</div>
						</div>
					</div>
				</div>
				<div className="modal-potential-routes-filter-agency">
					<div className="modal-potential-routes-filter-agency-inner" style={{ height: '150px' }}>
						<div className='column-total'>
							<h1>Potential of Corridors</h1>
							<div className="row">
								<h4>Filter by agency</h4>
								<div className="row">
									<AsyncSelect noOptionsMessage={({ inputValue }) => !inputValue ? "Write an agency name" : "No results found"} className='agency' placeholder='Select an agency...' styles={selectDate} cacheOptions loadOptions={this.loadOptions} value={selected_options_agency} onChange={this.handleOptionAgency.bind(this)} isOptionDisabled={() => this.state.selected_options_agency.length >= 4} />
								</div>
							</div>
							<div className="row">
								<h4>Filter by line/route</h4>
								<div className="row">
									<Select className='route' isClearable={true} noOptionsMessage={({ inputValue }) => !inputValue ? "Write an agency name on the upper field" : "No results found"} placeholder='Select a route...' options={this.state.routes_list} styles={selectDate} value={selected_option_line} onChange={this.handleOptionLineRoute.bind(this)} />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="map-month-year-picker">
					<Select className='dates' options={this.state.dates_trips_} onChange={this.handleMonthYear.bind(this)} styles={selectDate} defaultValue={value_od_dates} />
				</div>
				{
				showRoutes ? 
				<>
				<div>
					<div className="modal-potential-corridors-insights">
					<div className="modal-potential-corridors-insights-inner">
					<h1>Number of passenger daily</h1>
					<p className="label">{this.state.num_passengers}</p>
					</div>
					</div>
				</div>
				</>
				: <></> }
			</div >
			
		)
	}
}

//Map - Potential of existing and new routes
class MappPotentialCorridors extends React.Component {

	// Set up states for updating map 
	constructor(props) {
		super(props);
		this.state = {
			lng: 8.536524,
			lat: 46.844411,
			zoom: 7,
			mapita: 9,
			tyle: 'mapbox://styles/mapbox/light-v10',
			month_year: '2022-08',
			hasOriginDestination: false,
			array_selected_stations: [],
			showRoutes: false,
			hideNavs: false,
			selected_route_agency: ''
		}
		this.handleTyle = this.handleTyle.bind(this);
		this.deleteElem = this.deleteElem.bind(this);
	}

	//Update several fields
	updateFields(list) {
		this.setState(list);
	}

	//Update map on component update
	updateField(card, value) {
		this.setState({
			...this.state,
			[card]: value
		});
	}

	//Update coordinates
	handleCoord(center, zoom) {
		const { lng, lat } = center;
		this.setState({ zoom: zoom, lat: lat, lng: lng })
	}

	//Update style
	handleTyle(value) {
		this.setState({ tyle: value }, this.componentDidMount);
	}

	//Delete element on the array of selected stations
	deleteElem(id_elem) {

		const target = this.state.array_selected_stations.find(e => e.stop_id === id_elem);
		const index = this.state.array_selected_stations.indexOf(target);
		if (index > -1) { // only splice array when item is found
			let array_without_elem = this.state.array_selected_stations
			array_without_elem.splice(index, 1) // 2nd parameter means remove one item only
			this.setState({ array_selected_stations: array_without_elem });
		}
		return target;
	}

	componentDidMount() {
		// Set up map 
		const map = new mapboxgl.Map({
			container: this.mapContainer,
			style: this.state.tyle,
			center: [this.state.lng, this.state.lat],
			zoom: this.state.zoom,
		});

		map.on('load', () => {
			// Add zoom and rotation controls to the map.
			map.addControl(new mapboxgl.NavigationControl({ showCompass: false, showZoom: false }), 'top-left');
			map.addControl(new MapboxGeocoder({ accessToken: mapboxgl.accessToken, mapboxgl: mapboxgl, marker: false }), 'top-left');


			// data-swiss-origin-destination
			map.addSource('data-swiss-origin-destination', {
				'type': 'geojson',
				'data': URL_lines,
				'generateId': true

			});

			map.addLayer({
				'id': 'data-swiss-origin-destination',
				'type': 'fill',
				'source': 'data-swiss-origin-destination',
				'layout': {
				},
				'paint': {
					'fill-color': '#05a100',
					'fill-opacity': 0.4
				}
			});

			// Add a black outline around the polygon
			map.addLayer({
				'id': 'outline-data-swiss-origin-destination',
				'type': 'line',
				'source': 'data-swiss-origin-destination',
				'layout': {
				},
				'paint': {
					'line-color': '#023b00',
					'line-width': 1
				}
			});

			//Add shape to the map
			// Geometry for plz and tiles
			map.addSource('data-swiss-tile-plz', {
				'type': 'geojson',
				'data': URL_lines,
				'generateId': true

			});

			map.addLayer({
				'id': 'data-swiss-tile-plz',
				'type': 'fill',
				'source': 'data-swiss-tile-plz',
				'layout': {
				},
				'paint': {
					'fill-color': '#027F80',
					'fill-opacity': 0.4
				}
			});

			// Add a black outline around the polygon
			map.addLayer({
				'id': 'outline-data-swiss-tile-plz',
				'type': 'line',
				'source': 'data-swiss-tile-plz',
				'layout': {
				},
				'paint': {
					'line-color': '#434343',
					'line-width': 1
				}
			});

			//Lines - Routes
			//Add shapes to the map
			map.addSource('data-lines-routes', {
				'type': 'geojson',
				'data': URL_lines,
				'generateId': true

			});
			map.addLayer({
				'id': 'data-lines-routes',
				'type': 'line',
				'source': 'data-lines-routes',
				'layout': {
				},
				'paint': {
					'line-color': [
						'case',
						['boolean', ['feature-state', 'clicked'], false],
						'#eb34b1', // if selected true, paint in pink
						['get', 'color'] // else paint
					],
					'line-width': 3
				},
			});

			//Lines - Generated Route
			//Add shapes to the map
			map.addSource('data-lines-generated-routes', {
				'type': 'geojson',
				'data': {
					"type": "Feature",
					"properties": {},
					"geometry": generated_line
				},
				'generateId': true

			});
			map.addLayer({
				'id': 'data-lines-generated-routes',
				'type': 'line',
				'source': 'data-lines-generated-routes',
				'layout': {
				},
				'paint': {
					'line-color': '#eb34b1',
					'line-width': 3
				},
			});

			//Circles - Stations
			map.addSource('data-circles-stations', {
				'type': 'geojson',
				'data': URL_points,
				'generateId': true

			});

			map.addLayer({
				'id': 'data-circles-stations',
				'type': 'circle',
				'source': 'data-circles-stations',
				'layout': {
				},
				'paint': {
					'circle-radius': 7,
					"circle-stroke-width": 1,
					"circle-stroke-color": [
						'case',
						['boolean', ['feature-state', 'clicked'], false],
						'#eb9334', // if selected true, paint in pink
						'#eb34b1' // else paint
					],
					'circle-color': [
						'case',
						['boolean', ['feature-state', 'clicked'], false],
						'#eb9334', // if selected true, paint in pink
						'#eb34b1' // else paint
					],
					"circle-opacity": [
						'case',
						['boolean', ['feature-state', 'clicked'], false],
						1, // if selected true, paint in pink
						0.5 // else paint
					],
				},
			});

			map.addLayer({
				'id': 'swiss-origin-destination-labels',
				'type': 'symbol',
				'source': 'data-swiss-origin-destination',
				"minzoom": 10,
				'layout': {
					'text-field': ['get', 'count'],
					'text-font': [
						'Open Sans Bold',
						'Arial Unicode MS Bold'
					],
					'text-size': 15,
					'text-transform': 'uppercase',
					'text-letter-spacing': 0.05,
					'text-offset': [0, 1],
					'visibility': 'visible'
				},
				'paint': {
					"text-color": "#023b00"
				  }
			});
		});

		this.setState({
			mapita: map,
			zoom: map.getZoom(),
		});

		//ROUTES FOR COMPANIES

		// Change the cursor to a pointer when the mouse is over the places layer.
		map.on('mouseenter', 'data-lines-routes', (e) => {
			map.getCanvas().style.cursor = 'pointer';
		});

		// Change it back to a pointer when it leaves.
		map.on('mouseleave', 'data-lines-routes', () => {
			map.getCanvas().style.cursor = '';
		});

		//Click function - Add to Array
		map.on('click', 'data-lines-routes', (e) => {
			map.getCanvas().style.cursor = 'pointer';

			let dict = {
				"label": e.features[0].properties.route_short_name,
				"value": e.features[0].properties.route_id
			}

			this.updateFields({ selected_route_agency: dict })
		});

		// Create a popup, but don't add it to the map yet.
		const popupHover = new mapboxgl.Popup({
			closeButton: false,
			closeOnClick: false
		});

		// Change the cursor to a pointer when the mouse is over the places layer.
		map.on('mouseenter', 'data-circles-stations', (e) => {
			map.getCanvas().style.cursor = 'pointer';
			let stop_name = e.features[0].properties['Stop name']

			let html = '<h4 style="margin-block-start:0px; margin-block-end:0px;">Stop Name</h4><p style="margin-block-start:0px; margin-block-end: 0em;">' + stop_name + '</p>';
			popupHover
				.setLngLat(e.lngLat)
				.setHTML(html).addTo(map);
		});

		//Click function - Add to Array
		map.on('click', 'data-circles-stations', (e) => {
			map.getCanvas().style.cursor = 'pointer';
			let circleID = e.features[0].id;

			map.setFeatureState({
				source: 'data-circles-stations',
				id: circleID,
			}, {
				clicked: true
			});

			let current_array = this.state.array_selected_stations;

			let elem_id = e.features[0].properties.stop_id;
			const target = this.state.array_selected_stations.find(e => e.stop_id === elem_id);
			const index = this.state.array_selected_stations.indexOf(target);
			if (index === -1) {
				let elems = e.features[0].properties
				elems['circle_map_id'] = circleID
				current_array.push(elems)
				if (current_array.length > 1) {
					this.updateFields({ showRoutes: true, array_selected_stations: current_array })
				}
				else {
					this.updateFields({ showRoutes: false, array_selected_stations: current_array })
				}
			}
		});

		// Change it back to a pointer when it leaves.
		map.on('mouseleave', 'data-circles-stations', () => {
			map.getCanvas().style.cursor = '';
			popupHover.remove();
		});
	}

	render() {
		const { hideNavs } = this.state;

		return (
			<div>
				<div ref={el => this.mapContainer = el} style={{ width: '100%', height: '100vh' }}>
				</div>
				<RecommendationNavBarComponent />
				<HideElementsComponent map={this.state.mapita} updateField={(card, field, value) => this.updateField(card, field, value)} />
				<div style={{ display: hideNavs ? 'none' : 'block' }}>
					<PotentialCorridorsInfo map={this.state.mapita} array_selected_stations={this.state.array_selected_stations} showRoutes={this.state.showRoutes} deleteElem={(id_elem) => this.deleteElem(id_elem)} updateFields={(list) => this.updateFields(list)} selected_route_agency={this.state.selected_route_agency} />
				</div>
				<LayerTylesComponent map={this.state.mapita} tyle={this.state.tyle} handleTyle={(value) => this.handleTyle(value)} handleCoord={(value1, value2) => this.handleCoord(value1, value2)} />
			</div>
		)
	}
}

export default MappPotentialCorridors;
