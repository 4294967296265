import React from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '../css/map-drop-point.css';
import { Form, Button } from 'react-bootstrap';
import { IconContext } from "react-icons";
import { BsLayersHalf } from "react-icons/bs";
import RecommendationNavBarComponent from "../../components/RecommendationNavBarComponent";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Slider from 'rc-slider';
import { RiFullscreenFill, RiFullscreenExitFill } from "react-icons/ri";


mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const URL = process.env.REACT_APP_URL_SERVER_BACK;

let no_data_modal = false;
let generatedTiles = false;
let color_1km = 'color'

const _ = require('lodash');

function numberWithCommas(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
}

class HideElementsComponent extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			hideNavs : false,
			mapa: this.props.map,
		}
	}

	hideComponent() {
		let newHideNavs = !this.state.hideNavs;
		this.setState({hideNavs: newHideNavs})
		this.props.updateField("hideNavs", newHideNavs);
	}

	render() {

		const { hideNavs } = this.state;

		return (
			<div>
				{hideNavs ? <>
					<div className='map-close-navs'>
						<div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
								<div>
									<RiFullscreenExitFill />
								</div>
							</IconContext.Provider>
						</div>
					</div>
				</> : <>
					<div className='map-close-navs'>
						<div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
								<div>
									<RiFullscreenFill />
								</div>
							</IconContext.Provider>
						</div>
					</div>
				</>}
			</div>
		)
	}
}

const lines_geojson = {
	'type': 'FeatureCollection',
	'features': [
		{
			'type': 'Feature',
			'geometry': {
				"type": "LineString",
				"coordinates": [
				]
			}
		}
	]
};

const point_geojson = {
	'type': 'FeatureCollection',
	'features': [
		{
			'type': 'Feature',
			'geometry': {
				'type': 'Point',
				'coordinates': [9.140218, 47.437432]
			}
		}
	]
};

//Layer Tyles Selection - Side Menu
class LayerTylesComponent extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			showOptions: false,
			mapa: this.props.map,
		}
		this.hideComponent = this.hideComponent.bind(this);
		this.handleTyleEvent = this.handleTyleEvent.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (this.props.map !== prevProps.map) {
			this.setState({ mapa: this.props.map })
		}
	}

	hideComponent(name) {
		this.setState({ showOptions: !this.state.showOptions });
	}

	handleTyleEvent(event) {
		switch (event) {
			case "default":
				this.props.handleTyle("mapbox://styles/mapbox/light-v10");
				break;
			case "outdoors":
				this.props.handleTyle("mapbox://styles/mapbox/outdoors-v11");
				break;
			case "satellite":
				this.props.handleTyle("mapbox://styles/mapbox/satellite-streets-v11");
				break;
			case "streets":
				this.props.handleTyle("mapbox://styles/mapbox/streets-v11");
				break;
			case "dark":
				this.props.handleTyle("mapbox://styles/mapbox/dark-v10");
				break;
			default:
				// eslint-disable-next-line no-unused-expressions
				null;
		}
		this.props.handleCoord(this.state.mapa.getCenter(), this.state.mapa.getZoom());
	}

	render() {
		const { showOptions } = this.state;

		return (
			<div>
				{showOptions ? <>
					<div id='layer-options' className='map-layer-options-tyles'>
						<div className='map-layer-option-dark' onClick={() => this.handleTyleEvent("dark")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/Dark.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Dark</p>
						</div>
						<div className='map-layer-option-streets' onClick={() => this.handleTyleEvent("streets")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/Streets.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Streets</p>
						</div>
						<div className='map-layer-option-satellite' onClick={() => this.handleTyleEvent("satellite")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/Satellite.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Satellite</p>
						</div>
						<div className='map-layer-option-outdoors' onClick={() => this.handleTyleEvent("outdoors")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/Outdoors.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Outdoors</p>
						</div>
						<div className='map-layer-option-default' onClick={() => this.handleTyleEvent("default")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/White.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Default</p>
						</div>
					</div>
				</> : <></>}
				<div className='map-layer-tyles'>
					<div className='map-layer-tyles-inner' onClick={() => this.hideComponent("showOptions")}>
						<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
							<div>
								<BsLayersHalf />
							</div>
						</IconContext.Provider>
					</div>
				</div>
			</div>
		)
	}
}

class BtnsComponent extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			mapa: this.props.map,
			generatedTyles: false,
			loading: false,

			distance_min_train_station: 0,
			distance_max_train_station: 5,
			min_num_trips_per_tile: 0,
			max_num_trips_per_tile: 350,
			distance_min_buhler: 0,
			distance_max_buhler: 100,

			green_cat_bike: 5000,
			yellow_cat_bike: 10000,
			selection_distribution: 'general',

			green_pkm: 0,
			green_trips: 0,
			red_pkm: 0,
			red_trips: 0,
			yellow_pkm: 0,
			yellow_trips: 0,
			percentage_pkm_red: 0,
			percentage_pkm_yellow: 0,
			percentage_pkm_green: 0,
			percentage_trips_green: 0,
			percentage_trips_yellow: 0,
			percentage_trips_red: 0,

			distance_min_destination: 0,
			distance_max_destination: 100,
			total_pkm: 0,
			current_pkm: 0,
			percentage_pkm: 0,

			cat1_num_changes: 2,
			cat2_num_changes: 2,
			cat3_num_changes: 1,
			lastMileOffer: 'yes',
			cat1_min_percentage: 200,
			cat1_max_percentage: 1000,
			cat2_min_percentage: 130,
			cat2_max_percentage: 200,
			cat3_min_percentage: 0,
			cat3_max_percentage: 130,
			bike_radius: 5000,
			max_min_than_car: 10,
			minutes_in_train: 20,
			selectedPKm: 'trips'
		}
	}

	componentDidMount() {
		this.resetView = this.resetView.bind(this);

		this.handleCat1NumChanges = this.handleCat1NumChanges.bind(this);
		this.handleCat2NumChanges = this.handleCat2NumChanges.bind(this);
		this.handleCat3NumChanges = this.handleCat3NumChanges.bind(this);
		this.handleBikeRadius = this.handleBikeRadius.bind(this);
		this.handleCat1MinPercentage = this.handleCat1MinPercentage.bind(this);
		this.handleCat1MaxPercentage = this.handleCat1MaxPercentage.bind(this);
		this.handleCat2MinPercentage = this.handleCat2MinPercentage.bind(this);
		this.handleCat2MaxPercentage = this.handleCat2MaxPercentage.bind(this);
		this.handleCat3MinPercentage = this.handleCat3MinPercentage.bind(this);
		this.handleCat3MaxPercentage = this.handleCat3MaxPercentage.bind(this);
		this.handleMaxMinutesCar = this.handleMaxMinutesCar.bind(this);

		this.handleDistanceBuhler = this.handleDistanceBuhler.bind(this);
		this.handleDistanceTrainStation = this.handleDistanceTrainStation.bind(this);
		this.handleVisualization = this.handleVisualization.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (this.props.map !== prevProps.map) {
			this.setState({ mapa: this.props.map });
		}
		if (this.props.coord !== prevProps.coord) {
			this.setState({ coord: this.props.coord });
		}
	}

	handleVisualization(event) {
		this.setState({ selectedPKm: event.target.attributes.getNamedItem('id').value })
	}

	handle1kmTiles(min_dist_station, max_dist_station, min_dist_buhler, max_dist_buhler, min_num_trips, max_num_trips) {

		let url_1km_tile = 'ov_quality/tile1km';
		let url_total_vs_filter = 'ov_quality/total_vs_filter';
		let url_100m_tile = 'ov_quality/tile100m';

		let data;
		let body = { 
			coordinates: [point_geojson.features[0].geometry.coordinates[0], point_geojson.features[0].geometry.coordinates[1]], 
			distance_to_station: [min_dist_station, max_dist_station], 
			distance_to_origin: [min_dist_buhler, max_dist_buhler], 
			trips_per_tile: [min_num_trips, max_num_trips],
			threshold_duration_percentage_green: [this.state.cat3_min_percentage, this.state.cat3_max_percentage], 
			threshold_duration_percentage_yellow: [this.state.cat2_min_percentage, this.state.cat2_max_percentage], 
			threshold_duration_percentage_red: [this.state.cat1_min_percentage, this.state.cat1_max_percentage], 
			max_difference_green: this.state.max_min_than_car, 
			max_changes_green: this.state.cat3_num_changes, 
			max_changes_yellow: this.state.cat2_num_changes, 
			min_changes_red: this.state.cat1_num_changes, 
			short_bike_radius: this.state.green_cat_bike, 
			medium_bike_radius: this.state.yellow_cat_bike, 
			minutes_in_train: this.state.minutes_in_train * 60 }

		fetch(URL + url_1km_tile, {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(body)
		})
			.then((res) => res.json())
			.then((json) => {
				data = json;
			}).then(() => {
				this.props.map.getSource('data-swiss-1km-tile').setData(data);
				this.props.map.setPaintProperty('swiss-1km-tile', 'fill-color', ['get', color_1km]);
				this.props.map.setPaintProperty('outline-swiss-1km-tile', 'line-color', ['get', color_1km]);
			})
			.then(() => {
				fetch(URL + url_total_vs_filter, {
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(body)
				}).then((res) => res.json())
					.then((json) => {
						data = json;
					}).then(() => {
						this.setState({
							total_pkm: numberWithCommas((data.total_pkm).toFixed(0)), current_pkm: numberWithCommas((data.current_pkm).toFixed(0)), percentage_pkm: (((data.current_pkm / data.total_pkm) * 100).toFixed(0)), data_1km: data
						});

						if (this.state.selection_distribution === 'general') {

							this.setState({
								green_pkm: ((data.green_pkm).toFixed(0)), green_trips: ((data.green_trips).toFixed(0)), red_pkm: ((data.red_pkm).toFixed(0)), red_trips: ((data.red_trips).toFixed(0)), yellow_pkm: ((data.yellow_pkm).toFixed(0)), yellow_trips: ((data.yellow_trips).toFixed(0)),
								percentage_pkm_green: (((data.green_pkm / data.current_pkm) * 100).toFixed(0)), percentage_pkm_yellow: (((data.yellow_pkm / data.current_pkm) * 100).toFixed(0)), percentage_pkm_red: (((data.red_pkm / data.current_pkm) * 100).toFixed(0)),
								percentage_trips_green: (((data.green_trips / data.current_trips) * 100).toFixed(0)), percentage_trips_red: (((data.red_trips / data.current_trips) * 100).toFixed(0)), percentage_trips_yellow: (((data.yellow_trips / data.current_trips) * 100).toFixed(0))
							});
						}
						else if (this.state.selection_distribution === 'bike') {
							this.setState({
								green_pkm: ((data.bike_green_pkm).toFixed(0)), green_trips: ((data.bike_green_trips).toFixed(0)), red_pkm: ((data.bike_red_pkm).toFixed(0)), red_trips: ((data.bike_red_trips).toFixed(0)), yellow_pkm: ((data.bike_yellow_pkm).toFixed(0)), yellow_trips: ((data.bike_yellow_trips).toFixed(0)),
								percentage_pkm_green: (((data.bike_green_pkm / data.current_pkm) * 100).toFixed(0)), percentage_pkm_yellow: (((data.bike_yellow_pkm / data.current_pkm) * 100).toFixed(0)), percentage_pkm_red: (((data.bike_red_pkm / data.current_pkm) * 100).toFixed(0)),
								percentage_trips_green: (((data.bike_green_trips / data.current_trips) * 100).toFixed(0)), percentage_trips_red: (((data.bike_red_trips / data.current_trips) * 100).toFixed(0)), percentage_trips_yellow: (((data.bike_yellow_trips / data.current_trips) * 100).toFixed(0))
							});
						}
						else if (this.state.selection_distribution === 'lastmile') {
							this.setState({
								green_pkm: ((data.last_mile_green_pkm).toFixed(0)), green_trips: ((data.last_mile_green_trips).toFixed(0)), red_pkm: ((data.last_mile_red_pkm).toFixed(0)), red_trips: ((data.last_mile_red_trips).toFixed(0)), yellow_pkm: ((data.last_mile_yellow_pkm).toFixed(0)), yellow_trips: ((data.last_mile_yellow_trips).toFixed(0)),
								percentage_pkm_green: (((data.last_mile_green_pkm / data.current_pkm) * 100).toFixed(0)), percentage_pkm_yellow: (((data.last_mile_yellow_pkm / data.current_pkm) * 100).toFixed(0)), percentage_pkm_red: (((data.last_mile_red_pkm / data.current_pkm) * 100).toFixed(0)),
								percentage_trips_green: (((data.last_mile_green_trips / data.current_trips) * 100).toFixed(0)), percentage_trips_red: (((data.last_mile_red_trips / data.current_trips) * 100).toFixed(0)), percentage_trips_yellow: (((data.last_mile_yellow_trips / data.current_trips) * 100).toFixed(0))
							});
						}
						else if (this.state.selection_distribution === 'working') {
							this.setState({
								green_pkm: ((data.working_green_pkm).toFixed(0)), green_trips: ((data.working_green_trips).toFixed(0)), red_pkm: ((data.working_red_pkm).toFixed(0)), red_trips: ((data.working_red_trips).toFixed(0)), yellow_pkm: ((data.working_yellow_pkm).toFixed(0)), yellow_trips: ((data.working_yellow_trips).toFixed(0)),
								percentage_pkm_green: (((data.working_green_pkm / data.current_pkm) * 100).toFixed(0)), percentage_pkm_yellow: (((data.working_yellow_pkm / data.current_pkm) * 100).toFixed(0)), percentage_pkm_red: (((data.working_red_pkm / data.current_pkm) * 100).toFixed(0)),
								percentage_trips_green: (((data.working_green_trips / data.current_trips) * 100).toFixed(0)), percentage_trips_red: (((data.working_red_trips / data.current_trips) * 100).toFixed(0)), percentage_trips_yellow: (((data.working_yellow_trips / data.current_trips) * 100).toFixed(0))
							});
						}
					})
					.then(() => { this.setState({ loading: false, generatedTyles: true }) })
			})
			.then(() => {
				fetch(URL + url_100m_tile, {
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(body)
				})
					.then((res) => res.json())
					.then((json) => {
						data = json;
					}).then(() => {
						this.state.mapa.getSource('data-swiss-100m-tile').setData(data)
					})
			})
			.then(() => {
				this.state.mapa.setLayoutProperty('swiss-1km-tile', 'visibility', 'visible');
				this.state.mapa.setLayoutProperty('outline-swiss-1km-tile', 'visibility', 'visible');
				this.state.mapa.setLayoutProperty('swiss-1km-tile-labels', 'visibility', 'visible');
				this.props.map.setLayoutProperty('swiss-100m-tile', 'visibility', 'visible');
				this.props.map.setLayoutProperty('outline-swiss-100m-tile', 'visibility', 'visible');
				generatedTiles = true;
			});
	}

	handleTilesGenerator() {
		this.setState({ loading: true });

		this.handle1kmTiles(this.state.distance_min_train_station * 1000, this.state.distance_max_train_station * 1000, this.state.distance_min_buhler * 1000, this.state.distance_max_buhler * 1000, parseInt(this.state.min_num_trips_per_tile), this.state.max_num_trips_per_tile)

		this.setState({ generatedTyles1Km: true });
	}

	resetView() {
		this.state.mapa.getSource('data-swiss-1km-tile').setData(lines_geojson);
		this.state.mapa.getSource('data-swiss-100m-tile').setData(lines_geojson);

		this.setState({ generatedTyles: false })
		no_data_modal = false;
		generatedTiles = false;
	}

	handleMonthYear(event) {
		this.setState({ value_od_dates: event });
	}

	handleNumTripsPerTile(event) {
		this.setState({ min_num_trips_per_tile: event[0], max_num_trips_per_tile: event[1] });
	}

	handleDistanceBuhler(event) {
		this.setState({ distance_min_destination: event[0], distance_max_destination: event[1] });
	}

	handleDistanceTrainStation(event) {
		this.setState({ distance_min_train_station: event[0], distance_max_train_station: event[1] });
	}

	handleMaxMinutesCar(event) {
		this.setState({ max_min_than_car: Number(event.target.value) })
	}

	handleBikeRadius(event) {
		this.setState({ bike_radius: Number(event.target.value) });
	}

	handleCat1NumChanges(event) {
		this.setState({ cat1_num_changes: Number(event.target.value) })
	}

	handleCat2NumChanges(event) {
		this.setState({ cat2_num_changes: Number(event.target.value) })
	}

	handleCat3NumChanges(event) {
		this.setState({ cat3_num_changes: Number(event.target.value) })
	}

	handleCat1MinPercentage(event) {
		this.setState({ cat1_min_percentage: Number(event.target.value) })
	}

	handleCat1MaxPercentage(event) {
		this.setState({ cat1_max_percentage: Number(event.target.value) })
	}

	handleCat2MinPercentage(event) {
		this.setState({ cat2_min_percentage: Number(event.target.value) })
	}

	handleGreenCatBike(event) {
		this.setState({ green_cat_bike: Number(event.target.value) })
	}

	handleYellowCatBike(event) {
		this.setState({ yellow_cat_bike: Number(event.target.value) })
	}

	handleCat2MaxPercentage(event) {
		this.setState({ cat2_max_percentage: Number(event.target.value) })
	}

	handleCat3MinPercentage(event) {
		this.setState({ cat3_min_percentage: Number(event.target.value) })
	}

	handleCat3MaxPercentage(event) {
		this.setState({ cat3_max_percentage: Number(event.target.value) })
	}

	handleMinInTrains(event) {
		this.setState({ minutes_in_train: Number(event.target.value) })
	}

	handleDistribution(event) {
		this.setState({ selection_distribution: event.target.attributes.getNamedItem('id').value })

		let data_1km = this.state.data_1km;
		let data_bus = this.state.data_bus;

		if (this.state.generatedBusRoute) {
			if (event.target.attributes.getNamedItem('id').value === 'general') {
				this.setState({
					green_pkm: ((data_bus.green_pkm).toFixed(0)), green_trips: ((data_bus.green_trips).toFixed(0)), red_pkm: ((data_bus.red_pkm).toFixed(0)), red_trips: ((data_bus.red_trips).toFixed(0)), yellow_pkm: ((data_bus.yellow_pkm).toFixed(0)), yellow_trips: ((data_bus.yellow_trips).toFixed(0)),
					current_percentage_pkm_green: (((data_bus.green_pkm / data_bus.current_pkm) * 100).toFixed(0)), current_percentage_pkm_yellow: (((data_bus.yellow_pkm / data_bus.current_pkm) * 100).toFixed(0)), current_percentage_pkm_red: (((data_bus.red_pkm / data_bus.current_pkm) * 100).toFixed(0)),
					current_percentage_trips_green: (((data_bus.green_trips / data_bus.current_trips) * 100).toFixed(0)), current_percentage_trips_red: (((data_bus.red_trips / data_bus.current_trips) * 100).toFixed(0)), current_percentage_trips_yellow: (((data_bus.yellow_trips / data_bus.current_trips) * 100).toFixed(0))
				});
			}
			else if (event.target.attributes.getNamedItem('id').value === 'bike') {
				this.setState({
					green_pkm: ((data_bus.bike_green_pkm).toFixed(0)), green_trips: ((data_bus.bike_green_trips).toFixed(0)), red_pkm: ((data_bus.bike_red_pkm).toFixed(0)), red_trips: ((data_bus.bike_red_trips).toFixed(0)), yellow_pkm: ((data_bus.bike_yellow_pkm).toFixed(0)), yellow_trips: ((data_bus.bike_yellow_trips).toFixed(0)),
					current_percentage_pkm_green: (((data_bus.bike_green_pkm / data_bus.current_pkm) * 100).toFixed(0)), current_percentage_pkm_yellow: (((data_bus.bike_yellow_pkm / data_bus.current_pkm) * 100).toFixed(0)), current_percentage_pkm_red: (((data_bus.bike_red_pkm / data_bus.current_pkm) * 100).toFixed(0)),
					current_percentage_trips_green: (((data_bus.bike_green_trips / data_bus.current_trips) * 100).toFixed(0)), current_percentage_trips_red: (((data_bus.bike_red_trips / data_bus.current_trips) * 100).toFixed(0)), current_percentage_trips_yellow: (((data_bus.bike_yellow_trips / data_bus.current_trips) * 100).toFixed(0))
				});
			}
			else if (event.target.attributes.getNamedItem('id').value === 'lastmile') {
				this.setState({
					green_pkm: ((data_bus.last_mile_green_pkm).toFixed(0)), green_trips: ((data_bus.last_mile_green_trips).toFixed(0)), red_pkm: ((data_bus.last_mile_red_pkm).toFixed(0)), red_trips: ((data_bus.last_mile_red_trips).toFixed(0)), yellow_pkm: ((data_bus.last_mile_yellow_pkm).toFixed(0)), yellow_trips: ((data_bus.last_mile_yellow_trips).toFixed(0)),
					current_percentage_pkm_green: (((data_bus.last_mile_green_pkm / data_bus.current_pkm) * 100).toFixed(0)), current_percentage_pkm_yellow: (((data_bus.last_mile_yellow_pkm / data_bus.current_pkm) * 100).toFixed(0)), current_percentage_pkm_red: (((data_bus.last_mile_red_pkm / data_bus.current_pkm) * 100).toFixed(0)),
					current_percentage_trips_green: (((data_bus.last_mile_green_trips / data_bus.current_trips) * 100).toFixed(0)), current_percentage_trips_red: (((data_bus.last_mile_red_trips / data_bus.current_trips) * 100).toFixed(0)), current_percentage_trips_yellow: (((data_bus.last_mile_yellow_trips / data_bus.current_trips) * 100).toFixed(0))
				});
			}
			else if (event.target.attributes.getNamedItem('id').value === 'working') {
				this.setState({
					green_pkm: ((data_bus.working_green_pkm).toFixed(0)), green_trips: ((data_bus.working_green_trips).toFixed(0)), red_pkm: ((data_bus.working_red_pkm).toFixed(0)), red_trips: ((data_bus.working_red_trips).toFixed(0)), yellow_pkm: ((data_bus.working_yellow_pkm).toFixed(0)), yellow_trips: ((data_bus.working_yellow_trips).toFixed(0)),
					current_percentage_pkm_green: (((data_bus.working_green_pkm / data_bus.current_pkm) * 100).toFixed(0)), current_percentage_pkm_yellow: (((data_bus.working_yellow_pkm / data_bus.current_pkm) * 100).toFixed(0)), current_percentage_pkm_red: (((data_bus.working_red_pkm / data_bus.current_pkm) * 100).toFixed(0)),
					current_percentage_trips_green: (((data_bus.working_green_trips / data_bus.current_trips) * 100).toFixed(0)), current_percentage_trips_red: (((data_bus.working_red_trips / data_bus.current_trips) * 100).toFixed(0)), current_percentage_trips_yellow: (((data_bus.working_yellow_trips / data_bus.current_trips) * 100).toFixed(0))
				});
			}
		}
		if (event.target.attributes.getNamedItem('id').value === 'general') {
			this.setState({
				green_pkm: ((data_1km.green_pkm).toFixed(0)), green_trips: ((data_1km.green_trips).toFixed(0)), red_pkm: ((data_1km.red_pkm).toFixed(0)), red_trips: ((data_1km.red_trips).toFixed(0)), yellow_pkm: ((data_1km.yellow_pkm).toFixed(0)), yellow_trips: ((data_1km.yellow_trips).toFixed(0)),
				percentage_pkm_green: (((data_1km.green_pkm / data_1km.current_pkm) * 100).toFixed(0)), percentage_pkm_yellow: (((data_1km.yellow_pkm / data_1km.current_pkm) * 100).toFixed(0)), percentage_pkm_red: (((data_1km.red_pkm / data_1km.current_pkm) * 100).toFixed(0)),
				percentage_trips_green: (((data_1km.green_trips / data_1km.current_trips) * 100).toFixed(0)), percentage_trips_red: (((data_1km.red_trips / data_1km.current_trips) * 100).toFixed(0)), percentage_trips_yellow: (((data_1km.yellow_trips / data_1km.current_trips) * 100).toFixed(0))
			});
			color_1km = 'color'
		}
		else if (event.target.attributes.getNamedItem('id').value === 'bike') {
			this.setState({
				green_pkm: ((data_1km.bike_green_pkm).toFixed(0)), green_trips: ((data_1km.bike_green_trips).toFixed(0)), red_pkm: ((data_1km.bike_red_pkm).toFixed(0)), red_trips: ((data_1km.bike_red_trips).toFixed(0)), yellow_pkm: ((data_1km.bike_yellow_pkm).toFixed(0)), yellow_trips: ((data_1km.bike_yellow_trips).toFixed(0)),
				percentage_pkm_green: (((data_1km.bike_green_pkm / data_1km.current_pkm) * 100).toFixed(0)), percentage_pkm_yellow: (((data_1km.bike_yellow_pkm / data_1km.current_pkm) * 100).toFixed(0)), percentage_pkm_red: (((data_1km.bike_red_pkm / data_1km.current_pkm) * 100).toFixed(0)),
				percentage_trips_green: (((data_1km.bike_green_trips / data_1km.current_trips) * 100).toFixed(0)), percentage_trips_red: (((data_1km.bike_red_trips / data_1km.current_trips) * 100).toFixed(0)), percentage_trips_yellow: (((data_1km.bike_yellow_trips / data_1km.current_trips) * 100).toFixed(0))
			});
			color_1km = 'color_bike'
		}
		else if (event.target.attributes.getNamedItem('id').value === 'lastmile') {
			this.setState({
				green_pkm: ((data_1km.last_mile_green_pkm).toFixed(0)), green_trips: ((data_1km.last_mile_green_trips).toFixed(0)), red_pkm: ((data_1km.last_mile_red_pkm).toFixed(0)), red_trips: ((data_1km.last_mile_red_trips).toFixed(0)), yellow_pkm: ((data_1km.last_mile_yellow_pkm).toFixed(0)), yellow_trips: ((data_1km.last_mile_yellow_trips).toFixed(0)),
				percentage_pkm_green: (((data_1km.last_mile_green_pkm / data_1km.current_pkm) * 100).toFixed(0)), percentage_pkm_yellow: (((data_1km.last_mile_yellow_pkm / data_1km.current_pkm) * 100).toFixed(0)), percentage_pkm_red: (((data_1km.last_mile_red_pkm / data_1km.current_pkm) * 100).toFixed(0)),
				percentage_trips_green: (((data_1km.last_mile_green_trips / data_1km.current_trips) * 100).toFixed(0)), percentage_trips_red: (((data_1km.last_mile_red_trips / data_1km.current_trips) * 100).toFixed(0)), percentage_trips_yellow: (((data_1km.last_mile_yellow_trips / data_1km.current_trips) * 100).toFixed(0))
			});
			color_1km = 'color_last_mile'
		}
		else if (event.target.attributes.getNamedItem('id').value === 'working') {
			this.setState({
				green_pkm: ((data_1km.working_green_pkm).toFixed(0)), green_trips: ((data_1km.working_green_trips).toFixed(0)), red_pkm: ((data_1km.working_red_pkm).toFixed(0)), red_trips: ((data_1km.working_red_trips).toFixed(0)), yellow_pkm: ((data_1km.working_yellow_pkm).toFixed(0)), yellow_trips: ((data_1km.working_yellow_trips).toFixed(0)),
				percentage_pkm_green: (((data_1km.working_green_pkm / data_1km.current_pkm) * 100).toFixed(0)), percentage_pkm_yellow: (((data_1km.working_yellow_pkm / data_1km.current_pkm) * 100).toFixed(0)), percentage_pkm_red: (((data_1km.working_red_pkm / data_1km.current_pkm) * 100).toFixed(0)),
				percentage_trips_green: (((data_1km.working_green_trips / data_1km.current_trips) * 100).toFixed(0)), percentage_trips_red: (((data_1km.working_red_trips / data_1km.current_trips) * 100).toFixed(0)), percentage_trips_yellow: (((data_1km.working_yellow_trips / data_1km.current_trips) * 100).toFixed(0))
			});
			color_1km = 'color_working'
		}

		this.props.map.setPaintProperty('swiss-1km-tile', 'fill-color', ['get', color_1km]);
		this.props.map.setPaintProperty('outline-swiss-1km-tile', 'line-color', ['get', color_1km]);

	}


	render() {
		const { loading } = this.state;
		const { generatedTyles } = this.state;

		const { percentage_pkm_red } = this.state;
		const { percentage_pkm_yellow } = this.state;
		const { percentage_pkm_green } = this.state;
		const { percentage_trips_red } = this.state;
		const { percentage_trips_yellow } = this.state;
		const { percentage_trips_green } = this.state;

		const { red_pkm } = this.state;
		const { green_pkm } = this.state;
		const { yellow_pkm } = this.state;

		const { green_trips } = this.state;
		const { red_trips } = this.state;
		const { yellow_trips } = this.state;

		const { selectedPKm } = this.state;
		const { selection_distribution } = this.state;


		if (loading) { // if your component doesn't have to wait for an async action, remove this block 
			return (
				<div className="bg-loader">
					<div className="pos-center-brain-loader">
						<div className="circle-loading-brain">
						</div>
						<svg className='brain-loader' x="0px" y="0px" viewBox="0 0 496 496">
							<path d="M40,449.472V364.28L58.424,352H88v-16H53.576L24,355.72v93.752C14.704,452.784,8,461.584,8,472c0,13.232,10.768,24,24,24				s24-10.768,24-24C56,461.584,49.288,452.784,40,449.472z M32,480c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8s8,3.584,8,8				C40,476.416,36.416,480,32,480z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M46.528,320H88v-16H46.528c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24				C34.416,336,43.216,329.288,46.528,320z M16,312c0-4.416,3.584-8,8-8s8,3.584,8,8c0,4.416-3.584,8-8,8S16,316.416,16,312z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M24,272c10.416,0,19.216-6.712,22.528-16H88v-16H46.528c-3.312-9.296-12.112-16-22.528-16c-13.232,0-24,10.768-24,24				S10.768,272,24,272z M24,240c4.416,0,8,3.584,8,8s-3.584,8-8,8s-8-3.584-8-8C16,243.584,19.584,240,24,240z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M56 272h32v16H56Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M56 208h32v16H56Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M0 176h88v16H0Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M60.688,160H88v-16H67.312L48,124.688v-14.16C57.288,107.216,64,98.416,64,88c0-13.232-10.768-24-24-24S16,74.768,16,88				c0,10.416,6.704,19.216,16,22.528v20.784L60.688,160z M40,80c4.416,0,8,3.584,8,8s-3.584,8-8,8s-8-3.584-8-8S35.584,80,40,80z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M144 67.312L144 88L160 88L160 60.688L131.312 32L0 32L0 48L124.688 48z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M176 35.312L176 88L192 88L192 28.688L163.312 0L0 0L0 16L156.688 16z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M208 56h16v32H208Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M304 0h16v88H304Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M240,46.528V88h16V59.312l16,16V88h16V68.688L262.392,43.08C268.192,38.696,272,31.816,272,24c0-13.232-10.768-24-24-24				s-24,10.768-24,24C224,34.416,230.704,43.216,240,46.528z M248,16c4.416,0,8,3.584,8,8s-3.584,8-8,8s-8-3.584-8-8				S243.584,16,248,16z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M352,51.312L371.312,32h78.16c2.416,6.776,7.752,12.112,14.528,14.528v110.16L444.688,176H408v16h43.312L480,163.312				V46.528c9.288-3.312,16-12.112,16-22.528c0-13.232-10.768-24-24-24c-10.416,0-19.216,6.712-22.528,16h-84.784L336,44.688V88h16				V51.312z M472,16c4.416,0,8,3.584,8,8s-3.584,8-8,8c-4.416,0-8-3.584-8-8S467.584,16,472,16z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M414.08,97.392l17.92,17.92v9.376L412.688,144H408v16h11.312L448,131.312v-22.624L419.312,80h-4.784				c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24C401.904,112,410.416,105.968,414.08,97.392z				 M392,96c-4.416,0-8-3.584-8-8s3.584-8,8-8c4.416,0,8,3.584,8,8S396.416,96,392,96z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M408 208h88v16H408Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M480,449.472V268.688L451.312,240H408v16h36.688L464,275.312v41.376L451.312,304H408v16h36.688L464,339.312v110.16				c-9.296,3.312-16,12.112-16,22.528c0,13.232,10.768,24,24,24s24-10.768,24-24C496,461.584,489.288,452.784,480,449.472z M472,480				c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8c4.416,0,8,3.584,8,8C480,476.416,476.416,480,472,480z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M408 272h32v16H408Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M448,364.688L419.312,336H408v16h4.688L432,371.312V416h-80v-8h-16v24h96v12.688L412.688,464h-14.16				c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24c10.416,0,19.216-6.712,22.528-16h20.784				L448,451.312V364.688z M376,480c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8c4.416,0,8,3.584,8,8C384,476.416,380.416,480,376,480z				" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M160,408h-16v8h-41.472c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24				c10.416,0,19.216-6.712,22.528-16H160V408z M80,432c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8s8,3.584,8,8				C88,428.416,84.416,432,80,432z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M304 408h16v88H304Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M272 408h16v88H272Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M240,420.688l-16,16V408h-16v41.472c-9.296,3.312-16,12.112-16,22.528c0,13.232,10.768,24,24,24s24-10.768,24-24				c0-7.816-3.808-14.696-9.608-19.08L256,427.312V408h-16V420.688z M216,480c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8s8,3.584,8,8				C224,476.416,220.416,480,216,480z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M192 408L176 408L176 428.688L152 452.688L152 496L168 496L168 459.312L192 435.312z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M336,392h16v-16h24v-24h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16				v-16h-16v-24h-24v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-3.312				L120,140.688V144h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v24h24v16h16v-16h16				v16h16v-16h16v16h16v-16h16v16h16v-16h16v16h16v-16h16v16h16v-16h16V392z M136,360V147.312L147.312,136H360v224H136z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M318.888,211.048l13.96-13.96l-33.936-33.936l-13.96,13.96c-4.16-2.184-8.488-3.976-12.952-5.384V152h-48v19.728				c-4.472,1.408-8.8,3.2-12.952,5.384l-13.96-13.96l-33.936,33.936l13.96,13.96c-2.176,4.16-3.976,8.488-5.384,12.952H152v48				h19.728c1.408,4.472,3.2,8.8,5.384,12.952l-13.96,13.96l33.936,33.936l13.96-13.96c4.16,2.176,8.48,3.976,12.952,5.384V344h48				v-19.728c4.472-1.408,8.8-3.2,12.952-5.384l13.96,13.96l33.936-33.936l-13.96-13.96c2.176-4.152,3.976-8.48,5.384-12.952H344v-48				h-19.728C322.864,219.528,321.072,215.2,318.888,211.048z M328,256h-16.224l-1.416,6.224c-1.6,7.016-4.368,13.672-8.216,19.792				l-3.408,5.408l11.496,11.496l-11.312,11.312l-11.496-11.496l-5.408,3.408c-6.12,3.848-12.776,6.616-19.792,8.216L256,311.776V328				h-16v-16.224l-6.224-1.416c-7.016-1.6-13.672-4.368-19.792-8.216l-5.408-3.408l-11.496,11.496l-11.312-11.312l11.496-11.496				l-3.408-5.408c-3.848-6.12-6.616-12.776-8.216-19.792L184.224,256H168v-16h16.224l1.416-6.224				c1.6-7.016,4.368-13.672,8.216-19.792l3.408-5.408l-11.496-11.496l11.312-11.312l11.496,11.488l5.408-3.408				c6.12-3.848,12.776-6.616,19.792-8.216l6.224-1.408V168h16v16.224l6.224,1.416c7.016,1.6,13.672,4.368,19.792,8.216l5.408,3.408				l11.496-11.488l11.312,11.312l-11.496,11.496l3.408,5.408c3.848,6.12,6.616,12.776,8.216,19.792l1.416,6.216H328V256z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M248,208c-22.056,0-40,17.944-40,40c0,22.056,17.944,40,40,40c22.056,0,40-17.944,40-40C288,225.944,270.056,208,248,208				z M248,272c-13.232,0-24-10.768-24-24s10.768-24,24-24s24,10.768,24,24S261.232,272,248,272z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
						</svg>
					</div>
				</div>
			)
		}
		else {
			return (
				<div>
					<div className="map-btn-drop-point-ov-quality">
						<div className="map-btn-drop-point-ov-quality-inner">
							<h1>Compute the ÖV Quality <br /> for an specific Point</h1>
							<p className="label">Place the circle on the desired coordinates. Click on <i>Analyze ÖV Quality</i> to visualize the öv quality of the origin tiles of the point (set as destination).</p>
						</div>
					</div>
					<div className="map-btn-drop-point-ov-quality-tiles-analysis">
						<div className="map-btn-drop-point-ov-quality-tiles-analysis-inner">
							<div><h2>Tile ÖV Quality Analysis</h2></div>
							<Tabs
								defaultActiveKey="category3"
								id="uncontrolled-tab-example"
								className="mb-3">
								<Tab eventKey="category3" title="Green">
									<div className="row-parameters-2">
										<div className="column-1-40"><p className="label-2">Max. percentage longer than a car trip:</p></div>
										<div className="column-2-60"><input type="number" value={this.state.cat3_max_percentage} onChange={this.handleCat3MaxPercentage.bind(this)} /></div>
									</div>
									<p className="label">OR</p>
									<div className="row-parameters-2">
										<div className="column-1-40"><p className="label-2">Max. time in minutes longer than a car trip:</p></div>
										<div className="column-2-60"><input type="number" value={this.state.max_min_than_car} onChange={this.handleMaxMinutesCar.bind(this)} /></div>
									</div>
									<hr />
									<div className="row-parameters">
										<div className="column-1-40"><p className="label-2">Max. number of öv changes:</p></div>
										<div className="column-2-60"><input type="number" value={this.state.cat3_num_changes} onChange={this.handleCat3NumChanges.bind(this)} /></div>
									</div>
								</Tab>
								<Tab eventKey="category2" title="Yellow">
									<div className="row-parameters-2">
										<div className="column-1-40"><p className="label-2">Min. percentage longer than a car trip:</p></div>
										<div className="column-2-60"><input type="number" value={this.state.cat2_min_percentage} onChange={this.handleCat2MinPercentage.bind(this)} /></div>
									</div>
									<div className="row-parameters-2">
										<div className="column-1-40"><p className="label-2">Max. percentage longer than a car trip:</p></div>
										<div className="column-2-60"><input type="number" value={this.state.cat2_max_percentage} onChange={this.handleCat2MaxPercentage.bind(this)} /></div>
									</div>
									<hr />
									<div className="row-parameters">
										<div className="column-1-40"><p className="label-2">Max. number of öv changes:</p></div>
										<div className="column-2-60"><input type="number" value={this.state.cat2_num_changes} onChange={this.handleCat2NumChanges.bind(this)} /></div>
									</div>
								</Tab>
								<Tab eventKey="category1" title="Red">
									<div className="row-parameters-2">
										<div className="column-1-40"><p className="label-2">Min. percentage longer than a car trip:</p></div>
										<div className="column-2-60"><input type="number" value={this.state.cat1_min_percentage} onChange={this.handleCat1MinPercentage.bind(this)} /></div>
									</div>
									<hr />
									<div className="row-parameters">
										<div className="column-1-40"><p className="label-2">Max. number of öv changes:</p></div>
										<div className="column-2-60"><input type="number" value={this.state.cat1_num_changes} onChange={this.handleCat1NumChanges.bind(this)} /></div>
									</div>
								</Tab>
								<Tab eventKey="lastmile" title="Last Mile">
									<p className="label-2">Would you like to analyze last mile offer? </p>
									<p className="label">By enabling this feature, each trip will be calculated to the nearest train station to the destination. From there, a direct bus will be used to complete the journey to the destination. The fastest car travel time for the final mile will also be considered.</p>
									<hr />
									<p className="label-2">Distance in meters to destination:</p>
									<input type="number" value={this.state.bike_radius} onChange={this.handleBikeRadius.bind(this)} />
								</Tab>
								<Tab eventKey="bicycle" title="Cycling Distance">
									<p className="label">The <i>Green Radius</i> represents the maximum distance covered by the 'Green Category', which indicates the best locations for performing last mile trips by bike. The <i>Yellow Radius</i> signifies the maximum distance for the 'Yellow Category', representing good but not optimal locations for biking the last mile.</p>
									<hr />
									<div className="row-parameters-2">
										<div className="column-1-40"><p className="label-2">Green radius:</p></div>
										<div className="column-2-60"><input type="number" value={this.state.green_cat_bike} onChange={this.handleGreenCatBike.bind(this)} /></div>
									</div>
									<div className="row-parameters-2">
										<div className="column-1-40"><p className="label-2">Yellow Radius:</p></div>
										<div className="column-2-60"><input type="number" value={this.state.yellow_cat_bike} onChange={this.handleYellowCatBike.bind(this)} /></div>
									</div>
								</Tab>
								<Tab eventKey="working" title="Working Time">
									<p className="label-2">Enable your employees to work during the trip</p>
									<p className="label">By activating this feature, tiles with train travel times longer than the specified number will be highlighted in green. This helps identify origin points that provide sufficient travel time, allowing employees to work while on the train.</p>

									<div className="row-parameters-2">
										<div className="column-1-40"><p className="label-2">Minimun number of minutes:</p></div>
										<div className="column-2-60"><input type="number" value={this.state.minutes_in_train} onChange={this.handleMinInTrains.bind(this)} /></div>
									</div>
								</Tab>
							</Tabs>
						</div>
					</div>
					<div className="map-btn-drop-point-ov-quality-sliders">
						<div className="map-btn-drop-point-ov-quality-sliders-inner">
							<h2>Filter by distance <br /> of nearest train station</h2>
							<div className="slider-distance">
								<Slider range draggableTrack min={0} max={5} step={0.01} defaultValue={[this.state.distance_min_train_station, this.state.distance_max_train_station]} onChange={_.debounce(this.handleDistanceTrainStation.bind(this), 33)} />
							</div>
							<p className='label'>Filter trips that are between {this.state.distance_min_train_station}km to {this.state.distance_max_train_station}km <br /> to the nearest train station</p>
							<h2>Filter by geodesic distance <br /> to destination</h2>
							<div className="slider-distance">
								<Slider range draggableTrack min={0} max={100} step={0.5} defaultValue={[this.state.distance_min_destination, this.state.distance_max_destination]} onChange={_.debounce(this.handleDistanceBuhler.bind(this), 33)} />
							</div>
							<p className='label'>Filter trips that are between {this.state.distance_min_destination}km to {this.state.distance_max_destination}km <br /> to tile of destination</p>
							<h2>Filter by number of trips per tile</h2>
							<div className="slider-distance">
								<Slider range draggableTrack min={0} max={350} step={1} defaultValue={[this.state.min_num_trips_per_tile, this.state.max_num_trips_per_tile]} onChange={_.debounce(this.handleNumTripsPerTile.bind(this), 33)} />
							</div>
							<p className='label'>Filter trips that have between {this.state.min_num_trips_per_tile} to {this.state.max_num_trips_per_tile}<br /> trips per tile</p>
							<div className="row">
								<div className="column-1-eq">
									<Form onClick={this.handleTilesGenerator.bind(this)}>
										<Button className="generate-btn" variant="info" disabled={(generatedTyles)}>Analyze ÖV Quality</Button>
									</Form>
								</div>
								<div className="column-2-eq">
									<Form onClick={this.resetView.bind(this)}>
										<Button className="reset-btn" variant="info" disabled={(!generatedTyles)}>Reset coordinates</Button>
									</Form>
								</div>
							</div>
						</div>
					</div>
					{generatedTyles ?
						<>
						<div className="map-btn-drop-point-info-display">
							<div className="map-btn-drop-point-info-display-inner">
								<h2>Visualize contribution percentage by</h2>
								<Form onChange={this.handleVisualization.bind(this)}>
									<div className="row">
										<div className="column-1-eq">
											<Form.Check inline label=" Passenger Km" name="type_analysis" type='radio' id='pkm' defaultChecked={(selectedPKm === 'pkm')} />
										</div>
										<div className="column-2-eq">
											<Form.Check inline label=" Number of Trips" name="type_analysis" type='radio' id='trips' defaultChecked={(selectedPKm === 'trips')} />
										</div>
									</div>
								</Form>
							</div>
						</div>
						<div className="map-btn-drop-point-info-category">
							<div className="map-btn-drop-point-info-category-inner">
								<h2>Enable distribution by</h2>
								<Form onChange={this.handleDistribution.bind(this)}>
									<div className="row">
										<div className="column42-eq">
											<Form.Check inline label=" Status Quo" name="type_analysis" type='radio' id='general' defaultChecked={(selection_distribution === 'general')} />
										</div>
										<div className="column41-eq">
											<Form.Check inline label=" Bicycle" name="type_analysis" type='radio' id='bike' defaultChecked={(selection_distribution === 'bike')} />
										</div>

										<div className="column42-eq">
											<Form.Check inline label=" Last Mile" name="type_analysis" type='radio' id='lastmile' defaultChecked={(selection_distribution === 'lastmile')} />
										</div>
										<div className="column42-eq">
											<Form.Check inline label=" Working time" name="type_analysis" type='radio' id='working' defaultChecked={(selection_distribution === 'working')} />
										</div>
									</div>
								</Form>
							</div>
						</div>
						{(selectedPKm === 'pkm') ?
							<>
								<div className="map-btn-tile-potential-resp-1">
									<div className="map-btn-tile-potential-resp-1-inner">
										<div className="row-parameters-3">
											<h3>{percentage_pkm_green}% PKm</h3>
											<h4>{numberWithCommas(green_pkm)} PKm</h4>
											<h5>Great ÖV Quality</h5>
										</div>
									</div>
								</div>
								<div className="map-btn-tile-potential-resp-2">
									<div className="map-btn-tile-potential-resp-2-inner">
										<div className="row-parameters-3">
											<h3>{percentage_pkm_yellow}% PKm</h3>
											<h4>{numberWithCommas(yellow_pkm)} PKm</h4>
											<h5>Decent ÖV Quality</h5>
										</div>
									</div>
								</div>
								<div className="map-btn-tile-potential-resp-3">
									<div className="map-btn-tile-potential-resp-3-inner">
										<div className="row-parameters-3">
											<h3>{percentage_pkm_red}% PKm</h3>
											<h4>{numberWithCommas(red_pkm)} PKm</h4>
											<h5>Bad ÖV Quality</h5>
										</div>
									</div>
								</div>
							</>
							:
							<>
								<div className="map-btn-tile-potential-resp-1">
									<div className="map-btn-tile-potential-resp-1-inner">
										<div className="row-parameters-3">
											<h3>{percentage_trips_green}% Trips</h3>
											<h4>{numberWithCommas(green_trips)} Trips</h4>
											<h5>Great ÖV Quality</h5>
										</div>
									</div>
								</div>
								<div className="map-btn-tile-potential-resp-2">
									<div className="map-btn-tile-potential-resp-2-inner">
										<div className="row-parameters-3">
											<h3>{percentage_trips_yellow}% Trips</h3>
											<h4>{numberWithCommas(yellow_trips)} Trips</h4>
											<h5>Decent ÖV Quality</h5>
										</div>
									</div>
								</div>
								<div className="map-btn-tile-potential-resp-3">
									<div className="map-btn-tile-potential-resp-3-inner">
										<div className="row-parameters-3">
											<h3>{percentage_trips_red}% Trips</h3>
											<h4>{numberWithCommas(red_trips)} Trips</h4>
											<h5>Bad ÖV Quality</h5>
										</div>
									</div>
								</div>
							</>
						}
					</>
					: <></>
					}
					{no_data_modal ?
						<>
							<div className="modal-alert">
								<div className="modal-alert-inner">
									<h1>No data available</h1>
									<p>Please click on <i>Reset matrix</i> and try a new point within Switzerland</p>
								</div>
							</div>
						</>
						: <></>
					}
				</div>
			)
		}
	}
}

class MappDropPointTiles extends React.Component {

	// Set up states for updating map 
	constructor(props) {
		super(props);
		this.state = {
			lng: 9.048515,
			lat: 47.358582,
			zoom: 8,
			mapita: 9,
			tyle: 'mapbox://styles/mapbox/satellite-streets-v11',
			hideNavs: false
		}
	}

	//Update several fields
	updateFields(list) {
		this.setState(list);
	}

	//Update map on component update
	updateField(card, value) {
		this.setState({
            ...this.state,
            [card]: value
        });
	}

	//Update style
	handleTyle(value) {
		this.setState({ tyle: value }, this.componentDidMount);
	}

	//Update coordinates
	handleCoord(center, zoom) {
		const { lng, lat } = center;
		this.setState({ zoom: zoom, lat: lat, lng: lng })
	}

	componentDidMount() {
		// Set up map 
		const map = new mapboxgl.Map({
			container: this.mapContainer,
			style: this.state.tyle,
			center: [this.state.lng, this.state.lat],
			zoom: this.state.zoom,
		});

		map.on('load', () => {

			// Add zoom and rotation controls to the map.
			map.addControl(new mapboxgl.NavigationControl({ showCompass: false, showZoom: false }), 'top-left');
			map.addControl(new MapboxGeocoder({ accessToken: mapboxgl.accessToken, mapboxgl: mapboxgl, marker: false }), 'top-left');

			// Add circle 
			map.addSource('point', {
				'type': 'geojson',
				'data': point_geojson
			});

			map.addLayer({
				'id': 'point',
				'type': 'circle',
				'source': 'point',
				'paint': {
					'circle-radius': 10,
					'circle-color': '#e32a8d' // blue color
				}
			});

			map.addSource('data-swiss-1km-tile', {
				'type': 'geojson',
				'data': lines_geojson,
				'generateId': true
			});

			map.addSource('data-swiss-100m-tile', {
				'type': 'geojson',
				'data': lines_geojson,
				'generateId': true
			});

			//Tyle visualization - 1km

			map.addLayer({
				'id': 'swiss-1km-tile',
				'type': 'fill',
				'source': 'data-swiss-1km-tile',
				'layout': {},
				'paint': {
					'fill-color': ['get', color_1km],
					'fill-opacity': 0.4
				}
			});

			map.addLayer({
				'id': 'outline-swiss-1km-tile',
				'type': 'line',
				'source': 'data-swiss-1km-tile',
				'layout': {},
				'paint': {
					'line-color': ['get', color_1km],
					'line-width': 1
				}
			});

			map.addLayer({
				'id': 'swiss-1km-tile-labels',
				'type': 'symbol',
				'source': 'data-swiss-1km-tile',
				"minzoom": 11,
				'layout': {
					'text-field': ['get', 'total_trips_weekdays_tiles'],
					'text-font': [
						'Open Sans Bold',
						'Arial Unicode MS Bold'
					],
					'text-size': 14,
					'text-transform': 'uppercase',
					'text-letter-spacing': 0.05,
					'text-offset': [0, 1],
					'visibility': 'visible'
				}
			});


			//Tyle visualization - 100m

			map.addLayer({
				'id': 'swiss-100m-tile',
				'type': 'fill',
				'source': 'data-swiss-100m-tile',
				'layout': {},
				'paint': {
					'fill-color': '#9E2E6A',
					'fill-opacity': 0.4
				}
			});

			map.addLayer({
				'id': 'outline-swiss-100m-tile',
				'type': 'line',
				'source': 'data-swiss-100m-tile',
				'layout': {},
				'paint': {
					'line-color': '#9E2E6A',
					'line-width': 1
				}
			});
	
		});

		this.setState({
			mapita: map,
			zoom: map.getZoom(),
			center: map.getCenter()
		});


		//On click move the point
		map.on('click', (event) => {
			const coords = event.lngLat;
			if (!generatedTiles) {
				// Print the coordinates of where the point had
				// finished being dragged to on the map.

				map.getCanvas().style.cursor = '';

				// Unbind mouse/touch events
				map.off('mousemove', onMove);
				map.off('touchmove', onMove);

				point_geojson.features[0].geometry.coordinates = [coords.lng, coords.lat];
				map.getSource('point').setData(point_geojson);
			}
		});

		map.on('click', 'swiss-1km-tile', (e) => {

			let resp_data = e.features[0].properties

			let total_trips_weekdays_tiles = resp_data.total_trips_weekdays_tiles
			let duration_car_text = resp_data.duration_car_text
			let duration_train_text = resp_data.duration_train_text
			let n_trains = resp_data.n_trains
			let geodesic_distance_m = (resp_data.geodesic_distance_m).toFixed(0)

			let time_in_trains = (resp_data.time_in_trains / 60).toFixed(0);
			let time_in_bus = (resp_data.time_in_bus / 60).toFixed(0);

			let html = '<table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle;"><tbody><tr><th>Total trips during weekdays per day</th></tr><tr><td>' + total_trips_weekdays_tiles + '</td></tr><tr><th>Duration in car</th></tr><tr><td>' + duration_car_text + '</td><tr><th>Duration in public transport</th></tr><tr><td>' + duration_train_text + '</td><tr><th>Number of öv vehicles</th></tr><tr><td>' + n_trains + '</td><tr><th>Distance in meters</th></tr><tr><td>' + numberWithCommas(geodesic_distance_m) + '</td></tr><th>Time in a train</th></tr><tr><td>' + time_in_trains + ' min</td><tr><th>Time in a bus</th></tr><tr><td>' + time_in_bus + ' min</td></tr></tbody></table>';

			new mapboxgl.Popup()
				.setLngLat(e.lngLat)
				.setHTML(html).addTo(map);
		});

		// When the cursor enters a feature in
		// the point layer, prepare for dragging.
		map.on('mouseenter', 'point', () => {
			map.setPaintProperty('point', 'circle-color', '#9e2e6a');
			map.getCanvas().style.cursor = 'move';
		});

		map.on('mouseleave', 'point', () => {
			map.setPaintProperty('point', 'circle-color', '#9e2e6a');
			map.getCanvas().style.cursor = '';
		});

		function onMove(e) {
			const coords = e.lngLat;
			// Set a UI indicator for dragging.
			map.getCanvas().style.cursor = 'grabbing';

			// Update the Point feature in `geojson` coordinates
			// and call setData to the source layer `point` on it.
			point_geojson.features[0].geometry.coordinates = [coords.lng, coords.lat];
			map.getSource('point').setData(point_geojson);
		}

		function onUp(e) {
			const coords = e.lngLat;
			// Print the coordinates of where the point had
			// finished being dragged to on the map.

			map.getCanvas().style.cursor = '';

			// Unbind mouse/touch events
			map.off('mousemove', onMove);
			map.off('touchmove', onMove);

			point_geojson.features[0].geometry.coordinates = [coords.lng, coords.lat];
			map.getSource('point').setData(point_geojson);
		}

		map.on('mousedown', 'point', (e) => {
			// Prevent the default map drag behavior.
			e.preventDefault();

			map.getCanvas().style.cursor = 'grab';

			map.on('mousemove', onMove);
			map.once('mouseup', onUp);
		});

		map.on('touchstart', 'point', (e) => {
			if (e.points.length !== 1) return;

			// Prevent the default map drag behavior.
			e.preventDefault();

			map.on('touchmove', onMove);
			map.once('touchend', onUp);
		});

		// Fullscreen map 
		//map.addControl(new mapboxgl.FullscreenControl());
	}

	render() {
		const { hideNavs } = this.state;

		return (
			<div>
				<div ref={el => this.mapContainer = el} style={{ width: '100%', height: '100vh' }}>
				</div>
				<RecommendationNavBarComponent />
				<HideElementsComponent map={this.state.mapita} updateField={(card, field, value) => this.updateField(card, field, value)}/>
				<div style={{ display: hideNavs ? 'none' : 'block' }}>
				<BtnsComponent map={this.state.mapita} updateFields={(list) => this.updateFields(list)} coord={this.state.coord} />
					</div>
				<LayerTylesComponent map={this.state.mapita} tyle={this.state.tyle} handleTyle={(value) => this.handleTyle(value)} handleCoord={(value1, value2) => this.handleCoord(value1, value2)} />
			</div>
		)
	}
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => (
	<MappDropPointTiles
		{...props}
	/>
); 