import React from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';

import { Form, Button } from 'react-bootstrap';
import axios from 'axios';
import Select from 'react-select';
import { useParams } from "react-router-dom";
import Chart from 'chart.js/auto';
import { Pie } from 'react-chartjs-2';
import RecommendationNavBarComponent from "../../components/RecommendationNavBarComponent";
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { RiFullscreenFill, RiFullscreenExitFill } from "react-icons/ri";
import { IconContext } from "react-icons";
import {BiExit} from "react-icons/bi";
import { Link } from "react-router-dom";

import '../css/marketing.css';
import 'mapbox-gl/dist/mapbox-gl.css'

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const URL = process.env.REACT_APP_URL_SERVER_BACK;

const _ = require('lodash');

let generated_info = false;
function numberWithCommas(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const lines_geojson = {
	'type': 'FeatureCollection',
	'features': [
		{
			'type': 'Feature',
			'geometry': {
				"type": "LineString",
				"coordinates": [
				]
			}
		}
	]
};

const point_geojson = {
	'type': 'FeatureCollection',
	'features': [
		{
			'type': 'Feature',
			'geometry': {
				'type': 'Point',
				'coordinates': [8.251313, 46.924143]
			}
		}
	]
};

class HideElementsComponent extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			hideNavs : false,
			mapa: this.props.map,
		}
	}

	hideComponent() {
		let newHideNavs = !this.state.hideNavs;
		this.setState({hideNavs: newHideNavs})
		this.props.updateField("hideNavs", newHideNavs);
	}

	render() {

		const { hideNavs } = this.state;

		return (
			<div>
				{hideNavs ? <>
					<div className='map-close-navs'>
						<div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
								<div>
									<RiFullscreenExitFill />
								</div>
							</IconContext.Provider>
						</div>
					</div>
				</> : <>
					<div className='map-close-navs'>
						<div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
								<div>
									<RiFullscreenFill />
								</div>
							</IconContext.Provider>
						</div>
					</div>
				</>}
			</div>
		)
	}
}

class MainMenu extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			
		}
	}


	render() {
		return (
			<div>
				    <Link to="/menu" style={{ textDecoration: 'none' }}>
					<div className='map-close-navs'>
						<div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
								<div>
									<BiExit />
								</div>
							</IconContext.Provider>
						</div>
					</div>
					</Link>
			</div>
		)
	}
}

class BtnsComponent extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			mapa: this.props.map,
			generatedTyles: false,
			loading: false,
			value_od_dates: ({
				"value": "2022-08",
				"label": '2022-08'
			}),
			dates_trips_: this.getOptionsDate(URL + 'db_od_trips'),
            type_division_: [
                {
                    "value": "plz",
                    "label": 'Postal Code'
                }, {
                    "value": "1km_tiles",
                    "label": '1Km Tiles'
                }
            ],
            value_type_division_:(
                {
                    "value": "1km_tiles",
                    "label": '1Km Tiles'
                }
            ),
            top_origins_list: [],
			coord: this.props.coord,
            data_graph: [],
            isGraphData: false
		}
		this.handleFetchDataCoordParam()
	}

	componentDidMount() {
		this.handleFetchDataCoord = this.handleFetchDataCoord.bind(this);
		this.handleFetchDataCoordParam = this.handleFetchDataCoordParam.bind(this);
		this.resetView = this.resetView.bind(this);
		this.percentageTypeHandler = this.percentageTypeHandler.bind(this);
		this.calculateBtnHandler = this.calculateBtnHandler.bind(this);
		this.handleReturnToTable = this.handleReturnToTable.bind(this);
        this.handleTypeDivision = this.handleTypeDivision.bind(this);
        this.handleMonthYear = this.handleMonthYear.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (this.props.map !== prevProps.map) {
			this.setState({ mapa: this.props.map });
		}
		if (this.props.coord !== prevProps.coord) {
			this.setState({ coord: this.props.coord });
		}
	}

	handleReturnToTable(){
		this.setState({isGraphData: false})
	}

	calculateBtnHandler() {
		this.demoAsyncCall(this.state.value_od_dates.value, point_geojson.features[0].geometry.coordinates[0], point_geojson.features[0].geometry.coordinates[1], this.state.value_type_division_.value);
	}

	percentageTypeHandler(event) {
		this.setState({ percentage_type: event.target.attributes.getNamedItem('id').value })
		if (this.state.generatedTyles) {
			this.demoAsyncCall(this.state.value_od_dates.value, point_geojson.features[0].geometry.coordinates[0], point_geojson.features[0].geometry.coordinates[1], this.state.min_distance_km, this.state.max_distance_km, event.target.attributes.getNamedItem('id').value, this.state.percentage, this.state.n_trips, this.state.min_distance_to_train, this.state.max_distance_to_train);
		}
	}

	async getOptionsDate(url) {
		const res = await axios.get(url)
		const data = res.data
		const options = data.map(d => ({
			"value": d._id.date.toString().split('T')[0].slice(0, -3),
			"label": d._id.date.toString().split('T')[0].slice(0, -3)
		}))
		this.setState({ dates_trips_: options });
		//this.setState({ value_od_dates: options[options.length - 1] });
	}

	demoAsyncCall(date, lng, lat, filtering_scope) {
		let data;
		fetch(URL + 'microscope/top_origins_geometries/10/'+date+'/'+lng+','+lat+'/'+filtering_scope)
			.then((res) => res.json())
			.then((json) => {
				data = json;
			}).then(() => {
					this.props.map.getSource('data-swiss-destinations').setData(data);
			}).then(() => {this.setState({generatedTyles: true})})
            .then(() =>{
                this.props.map.setLayoutProperty('swiss-destinations', 'visibility', 'visible');
            }).then(() => {
                fetch(URL + 'microscope/top_origins_list/10/'+date+'/'+lng+','+lat+'/'+filtering_scope)
			    .then((res) => res.json())
			    .then((json) => {
				    data = json;
                }).then(() =>{
					console.log(data)
                    this.setState({top_origins_list: data,  loading: false})
                })
            })
	}

	handleFetchDataCoord() {
		this.setState({ loading: true });
		this.demoAsyncCall(this.state.value_od_dates.value, point_geojson.features[0].geometry.coordinates[0], point_geojson.features[0].geometry.coordinates[1], this.state.value_type_division_.value);
		this.setState({ generatedTyles: !this.state.generatedTyles });
		generated_info = true;

	}

	handleFetchDataCoordParam() {
		if(this.state.coord['*'] !== ""){
			this.setState({ loading: true });
            this.demoAsyncCall(this.state.value_od_dates.value, point_geojson.features[0].geometry.coordinates[0], point_geojson.features[0].geometry.coordinates[1], this.state.value_type_division_.value);
			this.setState({ generatedTyles: !this.state.generatedTyles });
			generated_info = true;

			point_geojson.features[0].geometry.coordinates = [parseFloat(this.state.coord['*'].split(',')[0]), parseFloat(this.state.coord['*'].split(',')[1])];
			//this.props.map.getSource('point').setData(point_geojson);
		}
	}

	resetView() {
		this.state.mapa.getSource('data-swiss-destinations').setData(lines_geojson);
		this.state.mapa.getSource('data-concentric-circle').setData(lines_geojson);
		this.state.mapa.getSource('data-swiss-tyle').setData(lines_geojson);
		this.props.map.setLayoutProperty('data-concentric-circle', 'visibility', 'none');
		this.props.map.setLayoutProperty('data-concentric-circle-labels', 'visibility', 'none');
		this.props.map.setLayoutProperty('data-swiss-tyle-labels', 'visibility', 'none');

		this.setState({ generatedTyles: false, isGraphData: false })
		generated_info = false;
	}

	handleMonthYear(event) {
		this.setState({ value_od_dates: event });
	}

    handleTypeDivision(event){
        this.setState({ value_type_division_: event})
    }

    fetchDetails(id){
        fetch(URL + 'areas_to_focus/tile/'+id)
        .then((res) => res.json())
        .then((json) => {
            const tyle_corner = {
                center: [((json.features[0].geometry.coordinates[0][1][0]+json.features[0].geometry.coordinates[0][2][0])/2), ((json.features[0].geometry.coordinates[0][2][1]+json.features[0].geometry.coordinates[0][3][1])/2)],
                zoom: 14.5,
                pitch: 0,
                bearing: 0
            }
            this.props.handleCoordMoveToTyle(((json.features[0].geometry.coordinates[0][1][0]+json.features[0].geometry.coordinates[0][2][0])/2), ((json.features[0].geometry.coordinates[0][2][1]+json.features[0].geometry.coordinates[0][3][1])/2), 14.5, json)
            this.props.map.flyTo({
                ...tyle_corner, // Fly to the selected target
                duration: 2000, // Animate over 2 seconds
                essential: false // This animation is considered essential with
                //respect to prefers-reduced-motion
                })
        }).then(
            fetch(URL + 'microscope/demographics/'+this.state.value_type_division_.value+'/'+id)
            .then((res) => res.json())
            .then((json) => {
                this.setState({data_graph: json[0], isGraphData: true})
            })
        );
    }

	render() {
		const { loading } = this.state;
		const { generatedTyles } = this.state;
		const { value_od_dates } = this.state;
        const { value_type_division_ } = this.state;

        const {data_graph} = this.state;


        const listTopDestination = this.state.top_origins_list.map((origin, index) =>
        <tr className="data" key={index} onClick={() => this.fetchDetails(origin?.id)}>
            <td className="table-data">{origin?.index}</td>
            <td className="table-data">{(origin?.PLZ_name)}</td>
            <td className="table-data">{origin?.PLZ}</td>
            <td className="table-data">{(origin?.total_pkm_weekdays !== null ? numberWithCommas(origin?.total_pkm_weekdays?.toFixed(0)) : 'No data')}</td>
            <td className="table-data">{(origin?.modal_split_weekdays !== null ? (origin?.modal_split_weekdays*100)?.toFixed(1)+'%': 'No data')}</td>
        </tr>
    );

		const selectDate = {
			menuList: styles => {
				return {
					...styles,
					maxHeight: 150,
					borderWidth: 0,
					boxShadow: '0 0 10px 2px rgb(0 0 0 / 10%)',
				};
			}
		};

        const data_sex = {
            labels: ['Female', 'Male'],
            datasets: [
              {
                label: 'Gender Distribution',
                data: [data_graph['F_sex'], data_graph['M_sex']],
                backgroundColor: [
                  'rgba(255, 99, 132, 0.2)',
                  'rgba(54, 162, 235, 0.2)',
                  'rgba(255, 206, 86, 0.2)',
                  'rgba(75, 192, 192, 0.2)',
                  'rgba(153, 102, 255, 0.2)',
                  'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                  'rgba(255, 99, 132, 1)',
                  'rgba(54, 162, 235, 1)',
                  'rgba(255, 206, 86, 1)',
                  'rgba(75, 192, 192, 1)',
                  'rgba(153, 102, 255, 1)',
                  'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
              },
            ],
          };

        const data_age = {
            labels: ['0-17_age', '18-24_age', '25-44_age', '45-64_age', '65-74_age', '75+_age'],
            datasets: [
              {
                label: 'Age Distribution',
                data: [data_graph['0-17_age'], data_graph['18-24_age'], data_graph['25-44_age'], data_graph['45-64_age'], data_graph['65-74_age'], data_graph['75+_age']],
                backgroundColor: [
                  'rgba(255, 99, 132, 0.2)',
                  'rgba(54, 162, 235, 0.2)',
                  'rgba(255, 206, 86, 0.2)',
                  'rgba(75, 192, 192, 0.2)',
                  'rgba(153, 102, 255, 0.2)',
                  'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                  'rgba(255, 99, 132, 1)',
                  'rgba(54, 162, 235, 1)',
                  'rgba(255, 206, 86, 1)',
                  'rgba(75, 192, 192, 1)',
                  'rgba(153, 102, 255, 1)',
                  'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
              },
            ],
          };

        const data_household = {
            labels: ['1_household_size', '2_household_size', '3-4_household_size', '5+_household_size'],
            datasets: [
              {
                label: 'Household Size Distribution',
                data: [data_graph['1_household_size'], data_graph['2_household_size'], data_graph['3-4_household_size'], data_graph['5+_household_size']],
                backgroundColor: [
                  'rgba(255, 99, 132, 0.2)',
                  'rgba(54, 162, 235, 0.2)',
                  'rgba(255, 206, 86, 0.2)',
                  'rgba(75, 192, 192, 0.2)',
                  'rgba(153, 102, 255, 0.2)',
                  'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                  'rgba(255, 99, 132, 1)',
                  'rgba(54, 162, 235, 1)',
                  'rgba(255, 206, 86, 1)',
                  'rgba(75, 192, 192, 1)',
                  'rgba(153, 102, 255, 1)',
                  'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
              },
            ],
        }

        const data_language = {
            labels: ['french_language', 'german_language', 'italian_language', 'other_language', 'romansh_language'],
            datasets: [
              {
                label: 'Language Distribution',
                data: [data_graph['french_language'], data_graph['german_language'], data_graph['italian_language'], data_graph['other_language'], data_graph['romansh_language']],
                backgroundColor: [
                  'rgba(255, 99, 132, 0.2)',
                  'rgba(54, 162, 235, 0.2)',
                  'rgba(255, 206, 86, 0.2)',
                  'rgba(75, 192, 192, 0.2)',
                  'rgba(153, 102, 255, 0.2)',
                  'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                  'rgba(255, 99, 132, 1)',
                  'rgba(54, 162, 235, 1)',
                  'rgba(255, 206, 86, 1)',
                  'rgba(75, 192, 192, 1)',
                  'rgba(153, 102, 255, 1)',
                  'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
              },
            ],
        }

        const data_car = {
            labels: ['Yes', 'No'],
            datasets: [
              {
                label: 'Car Ownership Distribution',
                data: [data_graph['True_car_ownership'], data_graph['False_car_ownership']],
                backgroundColor: [
                  'rgba(255, 99, 132, 0.2)',
                  'rgba(54, 162, 235, 0.2)',
                  'rgba(255, 206, 86, 0.2)',
                  'rgba(75, 192, 192, 0.2)',
                  'rgba(153, 102, 255, 0.2)',
                  'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                  'rgba(255, 99, 132, 1)',
                  'rgba(54, 162, 235, 1)',
                  'rgba(255, 206, 86, 1)',
                  'rgba(75, 192, 192, 1)',
                  'rgba(153, 102, 255, 1)',
                  'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
              },
            ],
        }

        const data_mobility = {
            labels: ['car_mobility', 'car & ga_mobility', 'car & ht_mobility', 'car & va_mobility', 'car & va & ht_mobility', 'ga_mobility', 'ht_mobility', 'nothing_mobility', 'va_mobility', 'va & ht_mobility'],
            datasets: [
              {
                label: 'Mobility Services Distribution',
                data: [data_graph['car_mobility'], data_graph['car & ga_mobility'], data_graph['car & ht_mobility'], data_graph['car & va_mobility'], data_graph['car & va & ht_mobility'], data_graph['ga_mobility'], data_graph['ht_mobility'], data_graph['nothing_mobility'], data_graph['va_mobility'], data_graph['va & ht_mobility']],
                backgroundColor: [
                  'rgba(255, 99, 132, 0.2)',
                  'rgba(54, 162, 235, 0.2)',
                  'rgba(255, 206, 86, 0.2)',
                  'rgba(75, 192, 192, 0.2)',
                  'rgba(153, 102, 255, 0.2)',
                  'rgba(255, 159, 64, 0.2)',
                  'rgba(255, 99, 132, 0.2)',
                  'rgba(54, 162, 235, 0.2)',
                  'rgba(255, 206, 86, 0.2)',
                  'rgba(153, 102, 255, 0.2)'
                ],
                borderColor: [
                  'rgba(255, 99, 132, 1)',
                  'rgba(54, 162, 235, 1)',
                  'rgba(255, 206, 86, 1)',
                  'rgba(75, 192, 192, 1)',
                  'rgba(153, 102, 255, 1)',
                  'rgba(255, 159, 64, 1)',
                  'rgba(255, 99, 132, 1)',
                  'rgba(54, 162, 235, 1)',
                  'rgba(255, 206, 86, 1)',
                  'rgba(153, 102, 255, 1)'
                ],
                borderWidth: 1,
              },
            ],
        }

		if (loading) { // if your component doesn't have to wait for an async action, remove this block 
			return (
				<div className="bg-loader">
					<div className="pos-center-brain-loader">
						<div className="circle-loading-brain">
						</div>
						<svg className='brain-loader' x="0px" y="0px" viewBox="0 0 496 496">
							<path d="M40,449.472V364.28L58.424,352H88v-16H53.576L24,355.72v93.752C14.704,452.784,8,461.584,8,472c0,13.232,10.768,24,24,24				s24-10.768,24-24C56,461.584,49.288,452.784,40,449.472z M32,480c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8s8,3.584,8,8				C40,476.416,36.416,480,32,480z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M46.528,320H88v-16H46.528c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24				C34.416,336,43.216,329.288,46.528,320z M16,312c0-4.416,3.584-8,8-8s8,3.584,8,8c0,4.416-3.584,8-8,8S16,316.416,16,312z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M24,272c10.416,0,19.216-6.712,22.528-16H88v-16H46.528c-3.312-9.296-12.112-16-22.528-16c-13.232,0-24,10.768-24,24				S10.768,272,24,272z M24,240c4.416,0,8,3.584,8,8s-3.584,8-8,8s-8-3.584-8-8C16,243.584,19.584,240,24,240z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M56 272h32v16H56Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M56 208h32v16H56Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M0 176h88v16H0Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M60.688,160H88v-16H67.312L48,124.688v-14.16C57.288,107.216,64,98.416,64,88c0-13.232-10.768-24-24-24S16,74.768,16,88				c0,10.416,6.704,19.216,16,22.528v20.784L60.688,160z M40,80c4.416,0,8,3.584,8,8s-3.584,8-8,8s-8-3.584-8-8S35.584,80,40,80z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M144 67.312L144 88L160 88L160 60.688L131.312 32L0 32L0 48L124.688 48z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M176 35.312L176 88L192 88L192 28.688L163.312 0L0 0L0 16L156.688 16z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M208 56h16v32H208Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M304 0h16v88H304Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M240,46.528V88h16V59.312l16,16V88h16V68.688L262.392,43.08C268.192,38.696,272,31.816,272,24c0-13.232-10.768-24-24-24				s-24,10.768-24,24C224,34.416,230.704,43.216,240,46.528z M248,16c4.416,0,8,3.584,8,8s-3.584,8-8,8s-8-3.584-8-8				S243.584,16,248,16z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M352,51.312L371.312,32h78.16c2.416,6.776,7.752,12.112,14.528,14.528v110.16L444.688,176H408v16h43.312L480,163.312				V46.528c9.288-3.312,16-12.112,16-22.528c0-13.232-10.768-24-24-24c-10.416,0-19.216,6.712-22.528,16h-84.784L336,44.688V88h16				V51.312z M472,16c4.416,0,8,3.584,8,8s-3.584,8-8,8c-4.416,0-8-3.584-8-8S467.584,16,472,16z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M414.08,97.392l17.92,17.92v9.376L412.688,144H408v16h11.312L448,131.312v-22.624L419.312,80h-4.784				c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24C401.904,112,410.416,105.968,414.08,97.392z				 M392,96c-4.416,0-8-3.584-8-8s3.584-8,8-8c4.416,0,8,3.584,8,8S396.416,96,392,96z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M408 208h88v16H408Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M480,449.472V268.688L451.312,240H408v16h36.688L464,275.312v41.376L451.312,304H408v16h36.688L464,339.312v110.16				c-9.296,3.312-16,12.112-16,22.528c0,13.232,10.768,24,24,24s24-10.768,24-24C496,461.584,489.288,452.784,480,449.472z M472,480				c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8c4.416,0,8,3.584,8,8C480,476.416,476.416,480,472,480z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M408 272h32v16H408Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M448,364.688L419.312,336H408v16h4.688L432,371.312V416h-80v-8h-16v24h96v12.688L412.688,464h-14.16				c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24c10.416,0,19.216-6.712,22.528-16h20.784				L448,451.312V364.688z M376,480c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8c4.416,0,8,3.584,8,8C384,476.416,380.416,480,376,480z				" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M160,408h-16v8h-41.472c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24				c10.416,0,19.216-6.712,22.528-16H160V408z M80,432c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8s8,3.584,8,8				C88,428.416,84.416,432,80,432z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M304 408h16v88H304Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M272 408h16v88H272Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M240,420.688l-16,16V408h-16v41.472c-9.296,3.312-16,12.112-16,22.528c0,13.232,10.768,24,24,24s24-10.768,24-24				c0-7.816-3.808-14.696-9.608-19.08L256,427.312V408h-16V420.688z M216,480c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8s8,3.584,8,8				C224,476.416,220.416,480,216,480z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M192 408L176 408L176 428.688L152 452.688L152 496L168 496L168 459.312L192 435.312z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M336,392h16v-16h24v-24h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16				v-16h-16v-24h-24v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-3.312				L120,140.688V144h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v24h24v16h16v-16h16				v16h16v-16h16v16h16v-16h16v16h16v-16h16v16h16v-16h16v16h16v-16h16V392z M136,360V147.312L147.312,136H360v224H136z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M318.888,211.048l13.96-13.96l-33.936-33.936l-13.96,13.96c-4.16-2.184-8.488-3.976-12.952-5.384V152h-48v19.728				c-4.472,1.408-8.8,3.2-12.952,5.384l-13.96-13.96l-33.936,33.936l13.96,13.96c-2.176,4.16-3.976,8.488-5.384,12.952H152v48				h19.728c1.408,4.472,3.2,8.8,5.384,12.952l-13.96,13.96l33.936,33.936l13.96-13.96c4.16,2.176,8.48,3.976,12.952,5.384V344h48				v-19.728c4.472-1.408,8.8-3.2,12.952-5.384l13.96,13.96l33.936-33.936l-13.96-13.96c2.176-4.152,3.976-8.48,5.384-12.952H344v-48				h-19.728C322.864,219.528,321.072,215.2,318.888,211.048z M328,256h-16.224l-1.416,6.224c-1.6,7.016-4.368,13.672-8.216,19.792				l-3.408,5.408l11.496,11.496l-11.312,11.312l-11.496-11.496l-5.408,3.408c-6.12,3.848-12.776,6.616-19.792,8.216L256,311.776V328				h-16v-16.224l-6.224-1.416c-7.016-1.6-13.672-4.368-19.792-8.216l-5.408-3.408l-11.496,11.496l-11.312-11.312l11.496-11.496				l-3.408-5.408c-3.848-6.12-6.616-12.776-8.216-19.792L184.224,256H168v-16h16.224l1.416-6.224				c1.6-7.016,4.368-13.672,8.216-19.792l3.408-5.408l-11.496-11.496l11.312-11.312l11.496,11.488l5.408-3.408				c6.12-3.848,12.776-6.616,19.792-8.216l6.224-1.408V168h16v16.224l6.224,1.416c7.016,1.6,13.672,4.368,19.792,8.216l5.408,3.408				l11.496-11.488l11.312,11.312l-11.496,11.496l3.408,5.408c3.848,6.12,6.616,12.776,8.216,19.792l1.416,6.216H328V256z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M248,208c-22.056,0-40,17.944-40,40c0,22.056,17.944,40,40,40c22.056,0,40-17.944,40-40C288,225.944,270.056,208,248,208				z M248,272c-13.232,0-24-10.768-24-24s10.768-24,24-24s24,10.768,24,24S261.232,272,248,272z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
						</svg>
					</div>
				</div>
			)
		}
		else {
			return (
				<div>
					{this.state.generatedTyles ?
						<>
							<div className="map-top-origins">
								<div className="map-top-origins-inner">
									<h1>List of Top Origin Contributors</h1>
                                    <table className='table-center-marketing'>
                                    <thead>
                        <tr className="title">
                            <th className="table-title-marketing">Id</th>
                            <th className="table-title-marketing">Municipality</th>
                            <th className="table-title-marketing">PLZ</th>
                            <th className="table-title-marketing">Total PKm Weekdays</th>
                            <th className="table-title-marketing">Modal Split Weekdays</th>
                        </tr>
                    </thead>
                    <tbody>
							{listTopDestination}
                        </tbody>
                                    </table>
								</div>
							</div>
						</>
						: <></>
					}
                    {this.state.isGraphData ?
						<>
							<div className="map-graph-value">
								<div className="map-graph-inner">
								<Form onClick={this.handleReturnToTable.bind(this)}>
									<Button variant="info" className="reset-btn">Return to main table</Button>
								</Form>
                                <Tabs
								    defaultActiveKey="sex"
								    id="uncontrolled-tab-example"
								    className="mb-3"
							    >
                                    <Tab eventKey="sex" title="Gender">
                                        <Pie data={data_sex}/>
								    </Tab>
                                    <Tab eventKey="age" title="Age">
                                        <Pie data={data_age}/>
								    </Tab>
                                    <Tab eventKey="household" title="Household size">
                                        <Pie data={data_household}/>
								    </Tab>
                                    <Tab eventKey="language" title="Language">
                                        <Pie data={data_language}/>
								    </Tab>
                                    <Tab eventKey="car" title="Car Ownership">
                                        <Pie data={data_car}/>
								    </Tab>
                                    <Tab eventKey="mobility" title="Mobility Service">
                                    <Pie data={data_mobility}/>
								    </Tab>
                                    </Tabs>
								</div>
							</div>
						</>
						: <></>
					}
					<div className="map-modal-initial-description">
						<div className="map-modal-initial-description-inner">
							<h1>Compute Demographics of a Point</h1>
							<p className="label">Place the circle on the desired coordinates. Click on <i>Generate matrix</i> to visualize the all destinations of selected coordinate</p>
							<div className="row">
                            <p className='label-title'>Select a date</p>
                            <Select className='dates' options={this.state.dates_trips_} onChange={this.handleMonthYear.bind(this)} styles={selectDate} defaultValue={value_od_dates} />
                            <p className='label-title'>Type of division</p>
                            <Select className='dates' options={this.state.type_division_} onChange={this.handleTypeDivision.bind(this)} styles={selectDate} defaultValue={value_type_division_} />
								<br/>
                                <div className="column-1-eq">
									<Form onClick={this.handleFetchDataCoord.bind(this)}>
										<Button className="generate-btn" variant="info" disabled={(generatedTyles)}>Generate matrix</Button>
									</Form>
								</div>
								<div className="column-2-eq">
									<Form onClick={this.resetView.bind(this)}>
										<Button className="reset-btn" variant="info" disabled={(!generatedTyles)}>Reset matrix</Button>
									</Form>
								</div>
							</div>
						</div>
					</div>
				</div>
			)
		}
	}
}

class MappMarketing extends React.Component {

	// Set up states for updating map 
	constructor(props) {
		super(props);
		this.state = {
			lng: 8.251313,
			lat: 46.924143,
			zoom: 7,
			mapita: 9,
			tyle: 'mapbox://styles/mapbox/light-v10',
			coord: this.props.params,
			hideNavs: false
		}
	}

	//Update several fields
	updateFields(list) {
		this.setState(list);
	}

	//Update map on component update
	updateField(card, value) {
		this.setState({
			...this.state,
			[card]: value
		});
	}

	//Update style
	handleTyle(value) {
		this.setState({ tyle: value }, this.componentDidMount);
	}

	//Update coordinates
	handleCoord(center, zoom) {
		const { lng, lat } = center;
		this.setState({ zoom: zoom, lat: lat, lng: lng })
	}

    //Update coordinates
    handleCoordMoveToTyle(lng, lat, zoom){
        this.setState({zoom: zoom, lat: lat, lng: lng})
    }

	componentDidMount() {
		// Set up map 
		const map = new mapboxgl.Map({
			container: this.mapContainer,
			style: this.state.tyle,
			center: [this.state.lng, this.state.lat],
			zoom: this.state.zoom,
		});

		map.on('load', () => {

            map.addControl( new MapboxGeocoder({ accessToken: mapboxgl.accessToken, mapboxgl: mapboxgl, marker: false}), 'top-left' );

			//Circles concentric
			//Add shapes to the map
			map.addSource('data-concentric-circle', {
				'type': 'geojson',
				'data': lines_geojson,
				'generateId': true

			});
			map.addLayer({
				'id': 'data-concentric-circle',
				'type': 'fill',
				'source': 'data-concentric-circle',
				'layout': {
					// Make the layer visible by default.
					'visibility': 'visible'
				},
				'paint': {
					'fill-color': [
						'match', ['get', 'percentile'],
						'0-5', '#602437',
						'5-15', '#8a2846',
						'15-50', '#e05780',
						'50++', '#ffc2d4',
						'black'],
					'fill-opacity': 0.5,
				},
			});

			map.addLayer({
				'id': 'data-concentric-circle-labels',
				'type': 'symbol',
				'source': 'data-concentric-circle',
				"minzoom": 10,
				'layout': {
					'text-field': ['get', 'percentile'],
					'text-font': [
						'Open Sans Bold',
						'Arial Unicode MS Bold'
					],
					'text-size': 15,
					'text-transform': 'uppercase',
					'text-letter-spacing': 0.05,
					'text-offset': [0, 1],
					'visibility': 'visible'
				}
			});

			//Tyle visualization
			//Add shapes to the map
			map.addSource('data-swiss-tyle', {
				'type': 'geojson',
				'data': lines_geojson,
				'generateId': true
			});

			map.addLayer({
				'id': 'data-swiss-tyle',
				'type': 'fill',
				'source': 'data-swiss-tyle',
				'layout': {},
				'paint': {
					'fill-color': '#53b8ae',
					'fill-opacity': 0.4
				}
			});

			// Add a black outline around the polygon
			map.addLayer({
				'id': 'outline-data-swiss-tyle',
				'type': 'line',
				'source': 'data-swiss-tyle',
				'layout': {},
				'paint': {
					'line-color': '#53b8ae',
					'line-width': 1
				}
			});

			map.addLayer({
				'id': 'data-swiss-tyle-labels',
				'type': 'symbol',
				'source': 'data-swiss-tyle',
				"minzoom": 10,
				'layout': {
					'text-field': ['get', 'total_trips_weekdays_tiles'],
					'text-font': [
						'Open Sans Bold',
						'Arial Unicode MS Bold'
					],
					'text-size': 15,
					'text-transform': 'uppercase',
					'text-letter-spacing': 0.05,
					'text-offset': [0, 1],
					'visibility': 'visible'
				}
			});

			// Add circle 
			map.addSource('point', {
				'type': 'geojson',
				'data': point_geojson
			});

			map.addLayer({
				'id': 'point',
				'type': 'circle',
				'source': 'point',
				'paint': {
					'circle-radius': 10,
					'circle-color': '#027f80' // blue color
				}
			});

			// add lines to the map
			map.addSource('data-swiss-destinations', {
				'type': 'geojson',
				'data': lines_geojson,
				'generateId': true,
				'lineMetrics': true
			});

			map.addLayer({
				'id': 'swiss-destinations',
				'type': 'fill',
				'source': 'data-swiss-destinations',
				'layout': {
					// Make the layer visible by default.
					'visibility': 'visible'
				},
				'paint': {
					'fill-color': '#e32a8d',
					'fill-opacity': 0.4
				}
			});

			map.addLayer({
				'id': 'swiss-destinations',
				'type': 'line',
				'source': 'data-swiss-destinations',
				'layout': {},
				'paint': {
					'line-color': '#e32a8d',
					'line-width': 1
				}
			});

			map.addLayer({
				'id': 'dswiss-destinations-labels',
				'type': 'symbol',
				'source': 'data-swiss-destinations',
				"minzoom": 10,
				'layout': {
					'text-field': ['get', 'index'],
					'text-font': [
						'Open Sans Bold',
						'Arial Unicode MS Bold'
					],
					'text-size': 15,
					'text-transform': 'uppercase',
					'text-letter-spacing': 0.05,
					'text-offset': [0, 1],
					'visibility': 'visible'
				}
			});

		});

		this.setState({
			mapita: map,
			zoom: map.getZoom(),
			center: map.getCenter()
		});

		//On click move the point
		map.on('click', (event) => {
			const coords = event.lngLat;
			if (!generated_info) {
				// Print the coordinates of where the point had
				// finished being dragged to on the map.

				map.getCanvas().style.cursor = '';

				// Unbind mouse/touch events
				map.off('mousemove', onMove);
				map.off('touchmove', onMove);

				point_geojson.features[0].geometry.coordinates = [coords.lng, coords.lat];
				map.getSource('point').setData(point_geojson);
			}
		});

		// When the cursor enters a feature in
		// the point layer, prepare for dragging.
		map.on('mouseenter', 'point', () => {
			map.setPaintProperty('point', 'circle-color', '#024242');
			map.getCanvas().style.cursor = 'move';
		});

		map.on('mouseleave', 'point', () => {
			map.setPaintProperty('point', 'circle-color', '#027f80');
			map.getCanvas().style.cursor = '';
		});

		function onMove(e) {
			const coords = e.lngLat;
			// Set a UI indicator for dragging.
			map.getCanvas().style.cursor = 'grabbing';

			// Update the Point feature in `geojson` coordinates
			// and call setData to the source layer `point` on it.
			point_geojson.features[0].geometry.coordinates = [coords.lng, coords.lat];
			map.getSource('point').setData(point_geojson);
		}

		function onUp(e) {
			const coords = e.lngLat;
			// Print the coordinates of where the point had
			// finished being dragged to on the map.

			map.getCanvas().style.cursor = '';

			// Unbind mouse/touch events
			map.off('mousemove', onMove);
			map.off('touchmove', onMove);

			point_geojson.features[0].geometry.coordinates = [coords.lng, coords.lat];
			map.getSource('point').setData(point_geojson);
		}

		map.on('mousedown', 'point', (e) => {
			// Prevent the default map drag behavior.
			e.preventDefault();

			map.getCanvas().style.cursor = 'grab';

			map.on('mousemove', onMove);
			map.once('mouseup', onUp);
		});

		map.on('touchstart', 'point', (e) => {
			if (e.points.length !== 1) return;

			// Prevent the default map drag behavior.
			e.preventDefault();

			map.on('touchmove', onMove);
			map.once('touchend', onUp);
		});

		// Fullscreen map 
		//map.addControl(new mapboxgl.FullscreenControl());
	}

	render() {
		const { hideNavs } = this.state;

		return (
			<div>
				<div ref={el => this.mapContainer = el} style={{ width: '100%', height: '100vh' }}>
				</div>
                <RecommendationNavBarComponent/>
				<MainMenu/>
				<div style={{ display: hideNavs ? 'none' : 'block' }}>
					<BtnsComponent handleCoordMoveToTyle={(value1, value2, value3) => this.handleCoordMoveToTyle(value1, value2, value3)} map={this.state.mapita} updateFields={(list) => this.updateFields(list)} coord={this.state.coord}/>
					</div>
			</div>
		)
	}
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => (
    <MappMarketing
        {...props}
        params={useParams()}
    />
); 