import React from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '../css/map-tile-comparison.css';
import { Form, Button } from 'react-bootstrap';
import { IconContext } from "react-icons";
import { BsLayersHalf } from "react-icons/bs";
import RecommendationNavBarComponent from "../../components/RecommendationNavBarComponent";
import { RiFullscreenFill, RiFullscreenExitFill } from "react-icons/ri";
import { ImEqualizer2 } from "react-icons/im";
import Chart from 'chart.js/auto';
import { Line, Pie } from 'react-chartjs-2';
import Select from 'react-select';
import Slider from 'rc-slider';

//Interactable graph library
import 'chartjs-plugin-dragdata';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const URL = process.env.REACT_APP_URL_SERVER_BACK;
const URL_FRONT = process.env.REACT_APP_URL_SERVER_FRONT;

let generatedTiles = false;
let color_rgb = ['255, 99, 132', '255, 182, 99', '5, 191, 191', '154, 99, 255', '138, 201, 38']
let color_hex = ['#FF6384', '#FFB663', '#05BFBF', '#9A63FF', '#8ac926']

const _ = require('lodash');

function numberWithCommas(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

class HideElementsComponent extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			hideNavs: false,
			mapa: this.props.map,
		}
	}

	hideComponent() {
		let newHideNavs = !this.state.hideNavs;
		this.setState({ hideNavs: newHideNavs })
		this.props.updateField("hideNavs", newHideNavs);
	}

	render() {

		const { hideNavs } = this.state;

		return (
			<div>
				{hideNavs ? <>
					<div className='map-close-navs'>
						<div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
								<div>
									<RiFullscreenExitFill />
								</div>
							</IconContext.Provider>
						</div>
					</div>
				</> : <>
					<div className='map-close-navs'>
						<div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
								<div>
									<RiFullscreenFill />
								</div>
							</IconContext.Provider>
						</div>
					</div>
				</>}
			</div>
		)
	}
}

const lines_geojson = {
	'type': 'FeatureCollection',
	'features': [
		{
			'type': 'Feature',
			'geometry': {
				"type": "LineString",
				"coordinates": [
				]
			}
		}
	]
};

const point_geojson = {
	'type': 'FeatureCollection',
	'features': [
		{
			'type': 'Feature',
			'geometry': {
				'type': 'Point',
				'coordinates': [9.248677891126201, 47.41466848384309]
			}
		}
	]
};

//Layer Tyles Selection - Side Menu
class LayerTylesComponent extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			showOptions: false,
			mapa: this.props.map,
		}
		this.hideComponent = this.hideComponent.bind(this);
		this.handleTyleEvent = this.handleTyleEvent.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (this.props.map !== prevProps.map) {
			this.setState({ mapa: this.props.map })
		}
	}

	hideComponent(name) {
		this.setState({ showOptions: !this.state.showOptions });
	}

	handleTyleEvent(event) {
		switch (event) {
			case "default":
				this.props.handleTyle("mapbox://styles/mapbox/light-v10");
				break;
			case "outdoors":
				this.props.handleTyle("mapbox://styles/mapbox/outdoors-v11");
				break;
			case "satellite":
				this.props.handleTyle("mapbox://styles/mapbox/satellite-streets-v11");
				break;
			case "streets":
				this.props.handleTyle("mapbox://styles/mapbox/streets-v11");
				break;
			case "dark":
				this.props.handleTyle("mapbox://styles/mapbox/dark-v10");
				break;
			default:
				// eslint-disable-next-line no-unused-expressions
				null;
		}
		this.props.handleCoord(this.state.mapa.getCenter(), this.state.mapa.getZoom());
	}

	render() {
		const { showOptions } = this.state;

		return (
			<div>
				{showOptions ? <>
					<div id='layer-options' className='map-layer-options-tyles'>
						<div className='map-layer-option-dark' onClick={() => this.handleTyleEvent("dark")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/Dark.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Dark</p>
						</div>
						<div className='map-layer-option-streets' onClick={() => this.handleTyleEvent("streets")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/Streets.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Streets</p>
						</div>
						<div className='map-layer-option-satellite' onClick={() => this.handleTyleEvent("satellite")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/Satellite.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Satellite</p>
						</div>
						<div className='map-layer-option-outdoors' onClick={() => this.handleTyleEvent("outdoors")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/Outdoors.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Outdoors</p>
						</div>
						<div className='map-layer-option-default' onClick={() => this.handleTyleEvent("default")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/White.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Default</p>
						</div>
					</div>
				</> : <></>}
				<div className='map-layer-tyles'>
					<div className='map-layer-tyles-inner' onClick={() => this.hideComponent("showOptions")}>
						<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
							<div>
								<BsLayersHalf />
							</div>
						</IconContext.Provider>
					</div>
				</div>
			</div>
		)
	}
}

class BtnsComponent extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			mapa: this.props.map,
			generatedTyles: false,
			loading: false,
			initialResults: false,
			name_tile: '',
			tile_num: 0,
			tiles_analysis: [],
			dates: [],
			dataset: [],
			response: [],
			response_satellite: [],
			type_categories_: [
				{ value: 'line_ms_pkm', label: 'Modal Split (PKm)' },
				{ value: 'line_pkm_trains', label: 'PKm for Trains' },
				{ value: 'line_pkm_cars', label: 'PKm for Cars' },
				{ value: 'line_num_trips_train', label: 'Num. trips for Trains' },
				{ value: 'line_num_trips_car', label: 'Num. trips for Cars' },
				// { value: 'line_ms_trips', label: 'Modal Split (Trips)' },
				// { value: 'satellite', label: 'Satellite Recognition' }
			],
			selected_category: {},
			selected_categories: [],
			details_categories_: [
				{ value: 'line_ms_pkm', label: 'Modal Split (PKm)' },
				{ value: 'line_pkm_trains', label: 'PKm for Trains' },
				{ value: 'line_pkm_cars', label: 'PKm for Cars' },
				{ value: 'line_num_trips_train', label: 'Num. trips for Trains' },
				{ value: 'line_num_trips_car', label: 'Num. trips for Cars' },

				{ value: 'line_travel_time_over_months', label: 'Travel Time over Months' },

				{ value: 'circle_sex', label: 'Gender Distribution' },
				{ value: 'circle_age', label: 'Age Distribution' },
				{ value: 'circle_household_size', label: 'Household Size Distribution' },
				{ value: 'circle_language', label: 'Primary Language Distribution' },
				{ value: 'circle_car_ownership', label: 'Car Ownership Distribution' },
				{ value: 'circle_mobility', label: 'Mobility Distribution' },
				{ value: 'circle_level_of_employment', label: 'Level of Employment Distribution' },
				{ value: 'circle_household_income', label: 'Household Income Distribution' },
				{ value: 'circle_position_in_bus', label: 'Position in Business Distribution' },
			],
			title_line_graph: '',
			percentage: [],
			switch_state: false,
			dates_interactable: [],
			dataset_graph_interactable: [],
			interactable_min_graph: 0,
			interactable_max_graph: 0,
			min_y_axis_graph: 0,
			max_y_axis_graph: 0,
			trains_pkm_general: 1000000,
			cars_pkm_general: 1000000,
			distance_station_km: 0.85,
			dict_with_interactable_graphs: {},
			showFilters: false,
			disable_station: true,
			disable_trains_pkm: true,
			disable_cars_pkm: true,
			move_graph: 10,
			labelCircleTile: [],
			dataCircleTile: []
		};
	};

	componentDidMount() {
		//TODO
	}

	componentDidUpdate(prevProps) {
		if (this.props.map !== prevProps.map) {
			this.setState({ mapa: this.props.map });
		}
		if (this.props.coord !== prevProps.coord) {
			this.setState({ coord: this.props.coord });
		}
		if (this.props.tileid_exploration !== prevProps.tileid_exploration) {
			this.setState({ tileid_exploration: this.props.tileid_exploration });
			this.handleSelectedCategory(this.state.selected_categories[0])
		}
	}

	handleVisualizationExplorer() {
		let percentage_0_1 = []

		for (let i = 0; i < this.state.percentage.length; i++) {
			percentage_0_1.push(Math.floor(1 / this.state.percentage.length));
		}

		let dict_with_graphs = Object.assign([], this.state.dict_with_interactable_graphs);

		for (let i = 0; i < dict_with_graphs.length; i++) {
			if (dict_with_graphs[i].label.includes('ms')) {
				for (let j = 0; j < dict_with_graphs[i].data.length; j++) {
					dict_with_graphs[i].data[j] = (dict_with_graphs[i].data[j] / 100);
				}
			}
		}

		let body = {
			'data_graph': dict_with_graphs, "pctg": percentage_0_1
		}

		if (!this.state.disable_cars_pkm) {
			body['car_pkm'] = this.state.cars_pkm_general;
		}

		if (!this.state.disable_trains_pkm) {
			body['train_pkm'] = this.state.trains_pkm_general;
		}

		if (!this.state.disable_station) {
			body['distance_train'] = this.state.distance_station_km * 1000;
		}

		fetch(URL + 'tile_embeddings/master_filter', {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(body)
		})
			.then((res) => res.json())
			.then((json) => {
				this.props.map.getSource('data-w-and-l-exploration').setData(json);
			}).then(() => {
				let dict_with_graphs = this.state.dict_with_interactable_graphs;

				for (let i = 0; i < dict_with_graphs.length; i++) {
					if (dict_with_graphs[i].label.includes('ms')) {
						for (let j = 0; j < dict_with_graphs[i].data.length; j++) {
							dict_with_graphs[i].data[j] = (dict_with_graphs[i].data[j] * 100);
						}
					}
				}
			})

		const tyle_corner = {
			center: [8.256558521989486, 46.91826025282468],
			zoom: 8,
			pitch: 0,
			bearing: 0
		}

		this.props.map.flyTo({
			...tyle_corner, // Fly to the selected target
			duration: 2000, // Animate over 2 seconds
			essential: false // This animation is considered essential with
			//respect to prefers-reduced-motion
		})

		this.setState({ showFilters: false })
	}

	handleVisualization() {

		this.setState({ selected_category: this.state.selected_categories[0] })

		switch (this.state.selected_categories[0].value) {
			case 'line_ms_pkm':
				this.setState({ title_line_graph: 'Percentage of Trains\' Modal Split (PKm) per Month' });
				break;
			case 'line_ms_trips':
				this.setState({ title_line_graph: 'Percentage of Trains\' Modal Split (Trips) per Month' });
				break;
			case 'line_pkm_trains':
				this.setState({ title_line_graph: 'Trains\' Passenger Kilometers' });
				break;
			case 'line_pkm_cars':
				this.setState({ title_line_graph: 'Cars\' Passenger Kilometers' });
				break;
			case 'line_num_trips_train':
				this.setState({ title_line_graph: 'Number of trips by train' });
				break;
			case 'line_num_trips_car':
				this.setState({ title_line_graph: 'Number of trips by car' });
				break;
			case 'line_travel_time_over_months':
				this.setState({ title_line_graph: 'Travel time to main ODs' });
				break;
			default:
				this.setState({ title_line_graph: 'undefined' });
				break;
		}

		let tileID = {};

		fetch(URL + 'tile_od_agg/coordinates_to_tiles', {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				"coordinates": [
					[point_geojson.features[0].geometry.coordinates[0], point_geojson.features[0].geometry.coordinates[1]]
				]
			})
		})
			.then((res) => res.json())
			.then((json) => {
				this.props.map.getSource('data-swiss-1km-tile').setData(json);
				this.setState({ name_tile: json.features[0].properties.name })
				this.setState({ tile_num: json.features[0].properties.tileId })
				tileID = json.features[0].properties.tileId
			}).then(() => {
				this.setState({ initialResults: true });
				generatedTiles = true;
			}).then(() => {

				let percentage_0_1 = []

				for (let i = 0; i < this.state.percentage.length; i++) {
					percentage_0_1.push(this.state.percentage[i] / 100)
				}


				fetch(URL + 'tile_embeddings/filter', {
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({ "field": this.state.array_fields, "pctg": percentage_0_1, "tileId": tileID })
				}).then((res) => res.json())
					.then((json) => {
						this.setState({ tiles_analysis: json.origin })
					}).then(() => {
						if (this.state.selected_categories[0]?.value?.includes('line')) {
							this.props.map.getSource('data-1km-satellite').setData(lines_geojson);

							fetch(URL + 'tile_embeddings/plot_' + this.state.selected_categories[0].value,
								{
									method: 'POST',
									headers: {
										'Accept': 'application/json',
										'Content-Type': 'application/json'
									},
									body: JSON.stringify({ 'tileIds': this.state.tiles_analysis })
								})
								.then((res) => res.json())
								.then((json) => {
									this.setState({ response: json });
									let current_dataset = []
									let current_dates = []

									for (let i = 0; i < json.length; i++) {
										current_dataset.push({
											label: json[i].origin,
											data: json[i]['values'],
											borderColor: 'rgb(' + color_rgb[i] + ')',
											backgroundColor: 'rgba(' + color_rgb[i] + ', 0.5)',
											hidden: (json[i].origin !== tileID)
										})
									}

									current_dates = json[0].date
									this.setState({ dataset: current_dataset, dates: current_dates });
								})
						}
					})
			})
	}

	handleSelectedCategory(event) {
		if (event.value?.includes('line')) {
			this.props.map.getSource('data-1km-satellite').setData(lines_geojson);

			let tiles = this.state.tiles_analysis;

			if (this.state.switch_state) {
				tiles = [this.props.tileid_exploration];
				this.setState({ tile_num: this.props.tileid_exploration, name_tile: this.props.tileid_name });
			}

			switch (event.value) {
				case 'line_ms_pkm':
					this.setState({ title_line_graph: 'Percentage of Trains\' Modal Split (PKm) per Month' });
					break;
				case 'line_ms_trips':
					this.setState({ title_line_graph: 'Percentage of Trains\' Modal Split (Trips) per Month' });
					break;
				case 'line_pkm_trains':
					this.setState({ title_line_graph: 'Trains\' Passenger Kilometers' });
					break;
				case 'line_pkm_cars':
					console.log('entro')
					this.setState({ title_line_graph: 'Cars\' Passenger Kilometers' });
					break;
				case 'line_num_trips_train':
					this.setState({ title_line_graph: 'Number of trips by train' });
					break;
				case 'line_num_trips_car':
					this.setState({ title_line_graph: 'Number of trips by car' });
					break;
				case 'line_travel_time_over_months':
						this.setState({ title_line_graph: 'Travel time to main ODs' });
						break;
				default:
					this.setState({ title_line_graph: 'undefined' });
					break;
			}

			fetch(URL + 'tile_embeddings/plot_' + event.value, {
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({ 'tileIds': tiles })
			})
				.then((res) => res.json())
				.then((json) => {
					this.setState({ response: json });
					let current_dataset = []
					let current_dates = []

					for (let i = 0; i < json.length; i++) {
						current_dataset.push({
							label: json[i].origin,
							data: json[i]['values'],
							borderColor: 'rgb(' + color_rgb[i] + ')',
							backgroundColor: 'rgba(' + color_rgb[i] + ', 0.5)',
							hidden: (json[i].origin !== this.state.tile_num)
						})
					}

					current_dates = json[0].date
					this.setState({ dataset: current_dataset, dates: current_dates });
				})
		}
		else if(event.value?.includes('circle')){
			let tile = this.state.tiles_analysis[0];

			if (this.state.switch_state) {
				tile = this.props.tileid_exploration;
				this.setState({ tile_num: this.props.tileid_exploration, name_tile: this.props.tileid_name });
			}

			fetch(URL + 'microscope/demographics/1km_tiles/' + tile)
				.then((res) => res.json())
				.then((json) => {
					let tileinfo = json[0];
					let circle_cat = event.value.split('circle')[1];

					let temp_labelCircleTile = [];
					let temp_dataCircleTile = [];

					for (const [key, value] of Object.entries(tileinfo)) {
						if(key.includes(circle_cat)){
							temp_labelCircleTile.push(key.split(circle_cat)[0])
							temp_dataCircleTile.push(value)
						}
					  }

					this.setState({labelCircleTile: temp_labelCircleTile, dataCircleTile: temp_dataCircleTile})
				})
		}

		this.props.map.getSource('data-1km-satellite').setData(lines_geojson);
		const tyle_corner = {
			center: point_geojson.features[0].geometry.coordinates,
			zoom: 14.5,
			pitch: 0,
			bearing: 0
		}
		this.props.map.flyTo({
			...tyle_corner, // Fly to the selected target
			duration: 2000, // Animate over 2 seconds
			essential: false // This animation is considered essential with
			//respect to prefers-reduced-motion
		})

		this.setState({ selected_category: event, initialResults: true })
	}

	fetchTileSatellite(label, color) {
		fetch(URL + 'areas_to_focus/tile/' + label)
			.then((res) => res.json())
			.then((json) => {
				const tyle_corner = {
					center: [((json.features[0].geometry.coordinates[0][1][0] + json.features[0].geometry.coordinates[0][2][0]) / 2), ((json.features[0].geometry.coordinates[0][2][1] + json.features[0].geometry.coordinates[0][3][1]) / 2)],
					zoom: 14.5,
					pitch: 0,
					bearing: 0
				}
				this.props.map.flyTo({
					...tyle_corner, // Fly to the selected target
					duration: 2000, // Animate over 2 seconds
					essential: false // This animation is considered essential with
					//respect to prefers-reduced-motion
				})
				this.props.map.getSource('data-1km-satellite').setData(URL + 'areas_to_focus/tile/' + label);
				if (color.includes('#')) {
					this.props.map.setPaintProperty('swiss-1km-satellite', 'fill-color', color);
					this.props.map.setPaintProperty('outline-swiss-1km-satellite', 'line-color', color)
				} else {
					let c = color.split('(')[1].slice(0, -1)
					let index = color_rgb.indexOf(c)
					let colorHex = color_hex[index];

					this.props.map.setPaintProperty('swiss-1km-satellite', 'fill-color', colorHex);
					this.props.map.setPaintProperty('outline-swiss-1km-satellite', 'line-color', colorHex);
				}
			})
	}

	handleMode() {
		let currentState = this.state.switch_state
		this.setState({ switch_state: !currentState })

		if (!currentState) {
			this.handleReset('restart');
			this.props.map.setLayoutProperty('point', 'visibility', 'none');
			this.props.map.setLayoutProperty('swiss-1km-tile', 'visibility', 'none');
			this.props.map.setLayoutProperty('outline-swiss-1km-tile', 'visibility', 'none');
		} else {
			this.handleResetExplorer('restart');
			this.props.map.setLayoutProperty('point', 'visibility', 'visible');
			this.props.map.setLayoutProperty('swiss-1km-tile', 'visibility', 'visible');
			this.props.map.setLayoutProperty('outline-swiss-1km-tile', 'visibility', 'visible');
		}
	}

	handleResetExplorer(event) {
		this.props.map.getSource('data-w-and-l-exploration').setData(lines_geojson);

		this.setState({
			generatedTyles: false, loading: false, initialResults: false, name_tile: '', tile_num: 0,
			tiles_analysis: [], dates: [], response: [], selected_category: {}, selected_categories: [],
			title_line_graph: '', percentage: [], dates_interactable: [], dataset_graph_interactable: [],
			interactable_min_graph: 0, interactable_max_graph: 0, total_pkm_general: 1000000, distance_station_km: 0.85,
			dict_with_interactable_graphs: {}, showFilters: false, disable_station: true, disable_pkm: true
		})

		generatedTiles = false;

		let tyle_corner = {
			center: [8.256558521989486, 46.91826025282468],
			zoom: 8,
			pitch: 0,
			bearing: 0
		}

		if (event === 'restart') {
			tyle_corner = {
				center: point_geojson.features[0].geometry.coordinates,
				zoom: 14,
				pitch: 0,
				bearing: 0
			}

		}

		this.props.map.flyTo({
			...tyle_corner, // Fly to the selected target
			duration: 2000, // Animate over 2 seconds
			essential: false // This animation is considered essential with
			//respect to prefers-reduced-motion
		})
	}

	handleReset(event) {
		this.state.mapa.getSource('data-1km-satellite').setData(lines_geojson);

		this.setState({
			generatedTyles: false, loading: false, initialResults: false, name_tile: '', tile_num: 0,
			dates: [], response_satellite: [], dataset: [], selected_categories: [],
			length_selected_categories: 0, title_line_graph: '', selected_category: {}, percentage: []
		})

		generatedTiles = false;

		let tyle_corner = {
			center: [point_geojson.features[0].geometry.coordinates[0], point_geojson.features[0].geometry.coordinates[1]],
			zoom: 14,
			pitch: 0,
			bearing: 0
		}

		if (event === 'restart') {
			tyle_corner = {
				center: [8.256558521989486, 46.91826025282468],
				zoom: 8,
				pitch: 0,
				bearing: 0
			}

		}

		this.props.map.flyTo({
			...tyle_corner, // Fly to the selected target
			duration: 2000, // Animate over 2 seconds
			essential: false // This animation is considered essential with
			//respect to prefers-reduced-motion
		})
	}

	handleSelectedCategoriesExplorer(event) {
		this.setState({ selected_categories: event })

		let new_array_fields = [];
		let new_percentage = [];

		for (let i = 0; i < event.length; i++) {
			let elem = event[i].value;
			if (elem.includes('line_')) {
				elem = elem.split('line_')[1];
			}
			new_array_fields.push(elem)
			new_percentage.push(Math.floor(100 / event.length));
		}

		let elemGraph = event[event.length - 1].value;
		if (elemGraph.includes('line_')) {
			elemGraph = elemGraph.split('line_')[1];
		}

		fetch(URL + 'tile_embeddings/chart_filter_settings/' + elemGraph)
			.then((res) => res.json())
			.then((json) => {

				let current_dataset = []
				let current_dates = []

				let max = json[0].max;
				let min = json[0].min;
				let dataset = json[0].mean_array;

				let no_multiple = false;
				for (let i = 0; i < this.state.dict_with_interactable_graphs.length; i++) {
					if (this.state.dict_with_interactable_graphs[i]?.label === elemGraph) {
						dataset = this.state.dict_with_interactable_graphs[i].data;
						no_multiple = true;
					}
				}

				if (elemGraph.includes('ms')) {
					max = max * 100;
					min = min * 100;

					if (!no_multiple) {
						for (let i = 0; i < dataset.length; i++) {
							dataset[i] = dataset[i] * 100;
						}
					}
				}

				for (let i = 0; i < json.length; i++) {
					current_dataset.push({
						label: 'Defined curve',
						data: dataset,
						borderColor: 'rgb(227, 42, 141)',
						backgroundColor: 'rgba(227, 42, 141, 0.5)',
					})
				}

				current_dates = json[0].range

				this.setState({
					dataset_graph_interactable: current_dataset, dates_interactable: current_dates,
					interactable_min_graph: min, interactable_max_graph: max, min_y_axis_graph: min,
					max_y_axis_graph: max, move_graph: 10
				});

				return dataset
			}).then((dataset) => {
				let new_dict = [];
				let old_dict = this.state.dict_with_interactable_graphs;

				for (let i = 0; i < new_array_fields.length; i++) {
					let array_to_push = [];
					let label = new_array_fields[i].toString();

					if (old_dict[i]?.label === new_array_fields[i]) {
						array_to_push = old_dict[i].data;
					} else if (new_array_fields[i] === elemGraph) {
						array_to_push = dataset;
					}

					new_dict.push({ 'label': label, 'data': array_to_push })

				}
				this.setState({ dict_with_interactable_graphs: new_dict })
			}).then(() => {

				switch (event[event.length - 1].value) {
					case 'line_ms_pkm':
						this.setState({ title_line_graph: 'Percentage of Trains\' Modal Split (PKm) per Month' });
						break;
					case 'line_ms_trips':
						this.setState({ title_line_graph: 'Percentage of Trains\' Modal Split (Trips) per Month' });
						break;
					case 'line_pkm_trains':
						this.setState({ title_line_graph: 'Trains\' Passenger Kilometers' });
						break;
					case 'line_pkm_cars':
						this.setState({ title_line_graph: 'Cars\' Passenger Kilometers' });
						break;
					case 'line_num_trips_train':
						this.setState({ title_line_graph: 'Number of trips by train' });
						break;
					case 'line_num_trips_car':
						this.setState({ title_line_graph: 'Number of trips by car' });
						break;
					case 'line_travel_time_over_months':
						this.setState({ title_line_graph: 'Travel time to main ODs' });
						break;
					default:
						this.setState({ title_line_graph: 'undefined' });
						break;
				}
			})

		this.setState({ array_fields: new_array_fields, percentage: new_percentage })
	}

	distanceToTrainHandler(event) {
		this.setState({ distance_station_km: event })
	}

	schemaTrainsPKmDisable() {
		if (this.state.disable_trains_pkm) {
			if (!this.state.disable_cars_pkm) {
				this.setState({ disable_cars_pkm: !this.state.disable_cars_pkm });
			}
		}
		this.setState({ disable_trains_pkm: !this.state.disable_trains_pkm });
	}

	schemaCarsPKmDisable() {
		if (this.state.disable_cars_pkm) {
			if (!this.state.disable_trains_pkm) {
				this.setState({ disable_trains_pkm: !this.state.disable_trains_pkm });
			}
		}
		this.setState({ disable_cars_pkm: !this.state.disable_cars_pkm });
	}

	schemaDistanceTrainDisable() {
		this.setState({ disable_station: !this.state.disable_station });
	}

	rangeY_Axis(event) {
		this.setState({ min_y_axis_graph: event[0], max_y_axis_graph: event[1] })
	}

	handleGraphMove(event) {
		let graph_data = this.state.dataset_graph_interactable[this.state.dataset_graph_interactable.length - 1].data;
		let min = this.state.interactable_min_graph;
		let max = this.state.interactable_max_graph;

		for (let i = 0; i < graph_data.length; i++) {
			console.log(event, this.state.move_graph, event < this.state.move_graph)
			if (event <= 0) {
				event = 0
			}

			if (event < this.state.move_graph) {
				if (graph_data[i] - ((max - min) * (event / 100)) < min) {
					graph_data[i] = min;
				} else {
					graph_data[i] = graph_data[i] - ((max - min) * (event / 100))
				}
			} else {
				if (graph_data[i] + ((max - min) * (event / 100)) > max) {
					graph_data[i] = max;
				} else {
					graph_data[i] = graph_data[i] + ((max - min) * (event / 100))
				}
			}
		}
		this.setState({ move_graph: event });
	}

	handleSelectedCategories(event) {
		this.setState({ selected_categories: event })

		let new_array_fields = [];
		let new_percentage = [];

		for (let i = 0; i < event.length; i++) {
			let elem = event[i].value;
			if (elem.includes('line_')) {
				elem = elem.split('line_')[1];
			}
			new_array_fields.push(elem)
			new_percentage.push(Math.floor(100 / event.length));
		}
		this.setState({ array_fields: new_array_fields, percentage: new_percentage })
	}

	hideComponentFilter() {
		this.setState({ showFilters: !this.state.showFilters });
	}

	handlePercentageChange(pct) {
		let newArray = this.state.percentage;
		let elem = pct.target.id;

		if (elem.includes('line_')) {
			elem = elem.split('line_')[1];
		}

		let current_position = this.state.array_fields.indexOf(elem);
		newArray[current_position] = pct.target.value;
		this.setState({ percentage: newArray });
	}

	totalPKmHandler(event) {
		this.setState({ total_pkm_general: event })
	}

	render() {
		const { loading } = this.state;
		const { initialResults } = this.state;
		const { name_tile } = this.state;
		const { tile_num } = this.state;
		const { dates } = this.state;
		const { dataset } = this.state;
		const { dates_interactable } = this.state;
		const { dataset_graph_interactable } = this.state;
		const { showFilters } = this.state;
		const { labelCircleTile } = this.state;
		const { dataCircleTile } = this.state;

		const listTilesSatellites = this.state.tiles_analysis?.map((tile, index) =>
			<tr key={index} onClick={() => this.fetchTileSatellite(tile, '#8432c2')} >
				<td className="table-data">{tile}</td>
			</tr>
		);

		const listCategories = this.state.selected_categories?.map((cat, index) =>
			<tr key={index}>
				<td className="table-data">{cat.label}</td>
				<td className="table-data"><input type="number" id={cat.value} value={this.state.percentage[index]} onChange={this.handlePercentageChange.bind(this)} /></td>
			</tr>
		);

		const selectStyles = {
			menuList: styles => {
				return {
					...styles,
					maxHeight: 150,
				};
			}
		};

		const dataLine = {
			labels: dates,
			datasets: dataset
		};

		const dataCircle = {
            labels: labelCircleTile,
            datasets: [
              {
				label: this.state.selected_category.value,
                data: dataCircleTile,
                backgroundColor: [
                  'rgba(255, 99, 132, 0.2)',
                  'rgba(54, 162, 235, 0.2)',
                  'rgba(255, 206, 86, 0.2)',
                  'rgba(75, 192, 192, 0.2)',
                  'rgba(153, 102, 255, 0.2)',
				  'rgba(255, 65, 132, 0.2)',
                  'rgba(255, 159, 64, 0.2)',
				  'rgba(105, 255, 82, 0.2)',
				  'rgba(0, 82, 143, 0.2)',
				  'rgba(3, 97, 97, 0.2)',
                ],
                borderColor: [
                  'rgba(255, 99, 132, 1)',
                  'rgba(54, 162, 235, 1)',
                  'rgba(255, 206, 86, 1)',
                  'rgba(75, 192, 192, 1)',
                  'rgba(153, 102, 255, 1)',
				  'rgba(255, 65, 132, 1)',
                  'rgba(255, 159, 64, 1)',
				  'rgba(105, 255, 82, 1)',
				  'rgba(0, 82, 143, 1)',
				  'rgba(3, 97, 97, 1)',
                ],
                borderWidth: 1,
              },
            ],
          };

		const options = {
			xAxes: [{
				type: 'time',
				ticks: {
					autoSkip: true,
					maxTicksLimit: 10
				}
			}],
			plugins: {
				title: {
					display: true,
					text: this.state.title_line_graph,
				},
				legend: {
					position: 'bottom',
					onClick: (e, legendItem, legend) => {
						let index = legendItem.datasetIndex;
						// get the label of the clicked legend item
						let label = legendItem.text;
						// get the chart instance
						let chart = legend.chart;
						// get the dataset meta data
						let meta = chart.getDatasetMeta(index);
						// toggle the visibility of the dataset
						meta.hidden = meta.hidden === null ? !chart.data.datasets[index].hidden : null;
						// update the chart to reflect the changes
						chart.update();
						if (meta.hidden !== null) {
							this.fetchTileSatellite(label, legendItem.strokeStyle);
						}
					}
				},
			}
		};

		//Interactable Graph
		const interactable_dataset = {
			labels: dates_interactable,
			datasets: dataset_graph_interactable
		};

		const options_interactable = {
			xAxes: [{
				type: 'time',
				ticks: {
					autoSkip: true,
					maxTicksLimit: 10
				}
			}],
			scales: {
				y: {
					min: this.state.min_y_axis_graph,
					max: this.state.max_y_axis_graph
				}
			},
			plugins: {
				dragData: !this.state.initialResults,
				title: {
					display: true,
					text: this.state.title_line_graph,
				},
				legend: {
					display: false
				}
			}
		};

		if (loading) { // if your component doesn't have to wait for an async action, remove this block 
			return (
				<div className="bg-loader">
					<div className="pos-center-brain-loader">
						<div className="circle-loading-brain">
						</div>
						<svg className='brain-loader' x="0px" y="0px" viewBox="0 0 496 496">
							<path d="M40,449.472V364.28L58.424,352H88v-16H53.576L24,355.72v93.752C14.704,452.784,8,461.584,8,472c0,13.232,10.768,24,24,24				s24-10.768,24-24C56,461.584,49.288,452.784,40,449.472z M32,480c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8s8,3.584,8,8				C40,476.416,36.416,480,32,480z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M46.528,320H88v-16H46.528c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24				C34.416,336,43.216,329.288,46.528,320z M16,312c0-4.416,3.584-8,8-8s8,3.584,8,8c0,4.416-3.584,8-8,8S16,316.416,16,312z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M24,272c10.416,0,19.216-6.712,22.528-16H88v-16H46.528c-3.312-9.296-12.112-16-22.528-16c-13.232,0-24,10.768-24,24				S10.768,272,24,272z M24,240c4.416,0,8,3.584,8,8s-3.584,8-8,8s-8-3.584-8-8C16,243.584,19.584,240,24,240z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M56 272h32v16H56Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M56 208h32v16H56Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M0 176h88v16H0Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M60.688,160H88v-16H67.312L48,124.688v-14.16C57.288,107.216,64,98.416,64,88c0-13.232-10.768-24-24-24S16,74.768,16,88				c0,10.416,6.704,19.216,16,22.528v20.784L60.688,160z M40,80c4.416,0,8,3.584,8,8s-3.584,8-8,8s-8-3.584-8-8S35.584,80,40,80z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M144 67.312L144 88L160 88L160 60.688L131.312 32L0 32L0 48L124.688 48z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M176 35.312L176 88L192 88L192 28.688L163.312 0L0 0L0 16L156.688 16z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M208 56h16v32H208Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M304 0h16v88H304Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M240,46.528V88h16V59.312l16,16V88h16V68.688L262.392,43.08C268.192,38.696,272,31.816,272,24c0-13.232-10.768-24-24-24				s-24,10.768-24,24C224,34.416,230.704,43.216,240,46.528z M248,16c4.416,0,8,3.584,8,8s-3.584,8-8,8s-8-3.584-8-8				S243.584,16,248,16z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M352,51.312L371.312,32h78.16c2.416,6.776,7.752,12.112,14.528,14.528v110.16L444.688,176H408v16h43.312L480,163.312				V46.528c9.288-3.312,16-12.112,16-22.528c0-13.232-10.768-24-24-24c-10.416,0-19.216,6.712-22.528,16h-84.784L336,44.688V88h16				V51.312z M472,16c4.416,0,8,3.584,8,8s-3.584,8-8,8c-4.416,0-8-3.584-8-8S467.584,16,472,16z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M414.08,97.392l17.92,17.92v9.376L412.688,144H408v16h11.312L448,131.312v-22.624L419.312,80h-4.784				c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24C401.904,112,410.416,105.968,414.08,97.392z				 M392,96c-4.416,0-8-3.584-8-8s3.584-8,8-8c4.416,0,8,3.584,8,8S396.416,96,392,96z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M408 208h88v16H408Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M480,449.472V268.688L451.312,240H408v16h36.688L464,275.312v41.376L451.312,304H408v16h36.688L464,339.312v110.16				c-9.296,3.312-16,12.112-16,22.528c0,13.232,10.768,24,24,24s24-10.768,24-24C496,461.584,489.288,452.784,480,449.472z M472,480				c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8c4.416,0,8,3.584,8,8C480,476.416,476.416,480,472,480z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M408 272h32v16H408Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M448,364.688L419.312,336H408v16h4.688L432,371.312V416h-80v-8h-16v24h96v12.688L412.688,464h-14.16				c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24c10.416,0,19.216-6.712,22.528-16h20.784				L448,451.312V364.688z M376,480c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8c4.416,0,8,3.584,8,8C384,476.416,380.416,480,376,480z				" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M160,408h-16v8h-41.472c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24				c10.416,0,19.216-6.712,22.528-16H160V408z M80,432c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8s8,3.584,8,8				C88,428.416,84.416,432,80,432z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M304 408h16v88H304Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M272 408h16v88H272Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M240,420.688l-16,16V408h-16v41.472c-9.296,3.312-16,12.112-16,22.528c0,13.232,10.768,24,24,24s24-10.768,24-24				c0-7.816-3.808-14.696-9.608-19.08L256,427.312V408h-16V420.688z M216,480c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8s8,3.584,8,8				C224,476.416,220.416,480,216,480z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M192 408L176 408L176 428.688L152 452.688L152 496L168 496L168 459.312L192 435.312z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M336,392h16v-16h24v-24h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16				v-16h-16v-24h-24v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-3.312				L120,140.688V144h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v24h24v16h16v-16h16				v16h16v-16h16v16h16v-16h16v16h16v-16h16v16h16v-16h16v16h16v-16h16V392z M136,360V147.312L147.312,136H360v224H136z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M318.888,211.048l13.96-13.96l-33.936-33.936l-13.96,13.96c-4.16-2.184-8.488-3.976-12.952-5.384V152h-48v19.728				c-4.472,1.408-8.8,3.2-12.952,5.384l-13.96-13.96l-33.936,33.936l13.96,13.96c-2.176,4.16-3.976,8.488-5.384,12.952H152v48				h19.728c1.408,4.472,3.2,8.8,5.384,12.952l-13.96,13.96l33.936,33.936l13.96-13.96c4.16,2.176,8.48,3.976,12.952,5.384V344h48				v-19.728c4.472-1.408,8.8-3.2,12.952-5.384l13.96,13.96l33.936-33.936l-13.96-13.96c2.176-4.152,3.976-8.48,5.384-12.952H344v-48				h-19.728C322.864,219.528,321.072,215.2,318.888,211.048z M328,256h-16.224l-1.416,6.224c-1.6,7.016-4.368,13.672-8.216,19.792				l-3.408,5.408l11.496,11.496l-11.312,11.312l-11.496-11.496l-5.408,3.408c-6.12,3.848-12.776,6.616-19.792,8.216L256,311.776V328				h-16v-16.224l-6.224-1.416c-7.016-1.6-13.672-4.368-19.792-8.216l-5.408-3.408l-11.496,11.496l-11.312-11.312l11.496-11.496				l-3.408-5.408c-3.848-6.12-6.616-12.776-8.216-19.792L184.224,256H168v-16h16.224l1.416-6.224				c1.6-7.016,4.368-13.672,8.216-19.792l3.408-5.408l-11.496-11.496l11.312-11.312l11.496,11.488l5.408-3.408				c6.12-3.848,12.776-6.616,19.792-8.216l6.224-1.408V168h16v16.224l6.224,1.416c7.016,1.6,13.672,4.368,19.792,8.216l5.408,3.408				l11.496-11.488l11.312,11.312l-11.496,11.496l3.408,5.408c3.848,6.12,6.616,12.776,8.216,19.792l1.416,6.216H328V256z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M248,208c-22.056,0-40,17.944-40,40c0,22.056,17.944,40,40,40c22.056,0,40-17.944,40-40C288,225.944,270.056,208,248,208				z M248,272c-13.232,0-24-10.768-24-24s10.768-24,24-24s24,10.768,24,24S261.232,272,248,272z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
						</svg>
					</div>
				</div>
			)
		}
		else {
			return (
				<div>
					<div className="switch-change-div">
						<div className="switch-change-div-inner">
							<div className="column-33">
								<p className="label">Individual Tile Mode</p>
							</div>
							<div className="column-33">
								<label className="switch">
									<input type="checkbox" onChange={this.handleMode.bind(this)} value={this.state.switch_state} />
									<span className="slider round"></span>
								</label>
							</div>
							<div className="column-33">
								<p className="label">Exploration Mode</p>
							</div>
						</div>
					</div>
					{!this.state.switch_state ?
						<>
							<div className="modal-tile-comparison">
								<div className="modal-tile-comparison-inner">
									<h1>Tile Comparison</h1>
									<p className="label">Analyze trends on a tile label and undertand what drives modal split. <br /><b>First, select one or more categories to analyze</b></p>
									<div className="row-padding">
										<Select className="basic-multi-select" menuPosition="fixed" isDisabled={initialResults} isMulti options={this.state.type_categories_} onChange={this.handleSelectedCategories.bind(this)} styles={selectStyles} value={this.state.selected_categories} />
									</div>
									{(this.state.selected_categories.length > 0)
										?
										<table>
											<thead>
												<tr>
													<th className="table-tittle">Category</th>
													<th className="table-tittle">Priorization (%)</th>
												</tr>
											</thead>
											<tbody>
												{listCategories}
											</tbody>
										</table>
										:
										<></>
									}

									{
										initialResults ?
											<Form onClick={this.handleReset.bind(this)}>
												<Button className="reset-btn" variant="info">Select a new tile</Button>
											</Form>
											:
											<Form onClick={this.handleVisualization.bind(this)}>
												<Button className="generate-btn" variant="info" disabled={this.state.selected_categories.length <= 0}>Analyze Tile</Button>
											</Form>}
								</div>
							</div>
							{
								initialResults ?
									<>
										<div className="modal-tile-comparison-categories">
											<div className="modal-tile-comparison-categories-inner">
												<h1 style={{ marginBottom: '0px' }}>{name_tile}</h1>
												<p className="label" style={{ fontWeight: 400, fontSize: '10px', marginTop: '0px' }}>Tile number: {tile_num}</p>
												<Select className='categories' isClearable={false} styles={selectStyles} options={this.state.details_categories_} defaultValue={this.state.selected_category} onChange={this.handleSelectedCategory.bind(this)} />
											</div>
										</div>

										{this.state.selected_category?.value?.includes('line') ?
											<>
												<div className="modal-tile-comparison-results">
													<div className="modal-tile-comparison-results-inner">
														<div style={{ height: '50px' }}>
															{dataset.length > 0
																? <Line data={dataLine} options={options} />
																:
																<><p className="label" >No data that correlates was found</p>													</>
															}

														</div>
													</div>
												</div>
											</>
											: <></>}

										{this.state.selected_category.value === 'satellite' ?
											<>
												<div className="modal-tile-comparison-results" >
													<div className="modal-tile-comparison-results-inner" style={{ height: '110px' }}>
														<table>
															<thead>
																<tr>
																	<th className="table-tittle">Tile ID</th>
																</tr>
															</thead>
															<tbody>
																{listTilesSatellites}
															</tbody>
														</table>
													</div>
												</div>
											</>
											: <></>}

										{this.state.selected_category?.value?.includes('circle') ?
											<>
												<div className="modal-tile-comparison-results" >
													<div className="modal-tile-comparison-results-inner" style={{ height: '480px' }}>
														<div style={{ height: '50px' }}>
															{dataset.length > 0
																? 
																<div>
																	<Pie data={dataCircle}/>
																</div>
																:
																<>
																<p className="label" >No data that correlates was found</p>
																</>
															}
														</div>
													</div>
												</div>
											</>
											: <></>}

									</>
									:
									<></>
							}
						</>
						:
						<>
							<div className="tile-comparison-filter-analyzer">
								<div className="tile-comparison-filter-analyzer-inner" onClick={() => this.hideComponentFilter()}>
									<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
										<div>
											<ImEqualizer2 />
										</div>
									</IconContext.Provider>
								</div>
							</div>

							{initialResults ?
								<>
									<div className="modal-tile-comparison-categories">
										<div className="modal-tile-comparison-categories-inner">
											<h1 style={{ marginBottom: '0px' }}>{name_tile}</h1>
											<p className="label" style={{ fontWeight: 400, fontSize: '10px', marginTop: '0px' }}>Tile number: {tile_num}</p>
											<Select className='categories' isClearable={false} styles={selectStyles} options={this.state.details_categories_} defaultValue={this.state.selected_category} onChange={this.handleSelectedCategory.bind(this)} />
										</div>
									</div>

									{this.state.selected_category?.value?.includes('line') ?
										<>
											<div className="modal-tile-comparison-results">
												<div className="modal-tile-comparison-results-inner">
													<div style={{ height: '50px' }}>
														{dataset.length > 0
															? <Line data={dataLine} options={options} />
															:
															<><p className="label" >No data that correlates was found</p>													</>
														}

													</div>
												</div>
											</div>
										</>
										: <></>}

{this.state.selected_category?.value?.includes('circle') ?
											<>
												<div className="modal-tile-comparison-results" >
													<div className="modal-tile-comparison-results-inner" style={{ height: '480px' }}>
														<div style={{ height: '50px' }}>
															{dataset.length > 0
																? 
																<div>
																	<Pie data={dataCircle}/>
																</div>
																:
																<>
																<p className="label" >No data that correlates was found</p>
																</>
															}
														</div>
													</div>
												</div>
											</>
											: <></>}
								</> :
								<></>

							}

							<div className="modal-tile-comparison">
								<div className="modal-tile-comparison-inner">
									<h1>Tile Comparison - Exploration Mode</h1>
									<p className="label">Analyze trends on a tile label and undertand what drives modal split. <br /><b>First, select one or more categories to analyze</b></p>
									<div className="row-padding">
										<Select className="basic-multi-select" menuPosition="fixed" isDisabled={initialResults} isMulti options={this.state.type_categories_} onChange={this.handleSelectedCategoriesExplorer.bind(this)} styles={selectStyles} value={this.state.selected_categories} />
									</div>
									{(this.state.selected_categories.length > 0) ?
										<>
											<div style={{ height: '220px' }}>
												<Line data={interactable_dataset} options={options_interactable} />
											</div>
											<div className="row-input">
												<p className="label"><b>Define y-axis graph min and max value</b></p>
												<div className="slider-definition">
													<Slider min={this.state.interactable_min_graph} max={this.state.interactable_max_graph} step={1} range value={[this.state.min_y_axis_graph, this.state.max_y_axis_graph]} onChange={_.debounce(this.rangeY_Axis.bind(this), 33)} />
												</div>
												<p className='label'>The Y-Axis is between {numberWithCommas(this.state.min_y_axis_graph.toFixed(0))}{this.state.selected_categories[this.state.selected_categories.length - 1]?.value.includes('ms') ? '%' : ''} and {numberWithCommas(this.state.max_y_axis_graph.toFixed(0))}{this.state.selected_categories[this.state.selected_categories.length - 1]?.value.includes('ms') ? '%' : ''}</p>
												<p className="label"><b>Move the graph on the Y-Axis</b></p>
												<div className="slider-definition">
													<Slider min={0} max={50} step={1} value={this.state.move_graph} onChange={_.debounce(this.handleGraphMove.bind(this), 33)} />
												</div>
											</div>
										</>
										: <></>
									}
									{
										initialResults ?
											<Form onClick={this.handleResetExplorer.bind(this)}>
												<Button className="reset-btn" variant="info">Restart explorer</Button>
											</Form>
											:
											<Form onClick={this.handleVisualizationExplorer.bind(this)}>
												<Button className="generate-btn" variant="info" disabled={this.state.selected_categories.length <= 0}>Analyze Tile</Button>
											</Form>}
								</div>
							</div>

							{showFilters ?
								<>
									<div className="modal-tile-comparision-additional-filters">
										<div className="modal-tile-comparision-additional-filters-inner">
											<h1>Filter by Trains' PKm</h1>
											<div className="row-input">
												<div className="column-1-80">
													<Slider min={0} max={15000000} step={100} disabled={this.state.disable_trains_pkm} defaultValue={this.state.trains_pkm_general} onChange={_.debounce(this.schemaTrainsPKmDisable.bind(this), 33)} />
												</div>
												<div className="column-2-20">
													<Form>
														<Form.Check onChange={this.schemaTrainsPKmDisable.bind(this)}
															type="switch"
															id="custom-switch"
															label="Disable"
															checked={this.state.disable_trains_pkm}
														/>
													</Form>
												</div>
											</div>
											<div className="row-input">
												<p className='label'>Showing tiles with minimum {numberWithCommas(Number(this.state.trains_pkm_general))} Trains' PKm per month</p>
											</div>
											<h1>Filter by Cars' PKm</h1>
											<div className="row-input">
												<div className="column-1-80">
													<Slider min={0} max={15000000} step={100} disabled={this.state.disable_cars_pkm} defaultValue={this.state.cars_pkm_general} onChange={_.debounce(this.schemaCarsPKmDisable.bind(this), 33)} />
												</div>
												<div className="column-2-20">
													<Form>
														<Form.Check onChange={this.schemaCarsPKmDisable.bind(this)}
															type="switch"
															id="custom-switch"
															label="Disable"
															checked={this.state.disable_cars_pkm}
														/>
													</Form>
												</div>
											</div>
											<div className="row-input">
												<p className='label'>Showing tiles with minimum {numberWithCommas(Number(this.state.cars_pkm_general))} Cars' PKm per month</p>
											</div>
											<h1>Filter by distance to the train station</h1>
											<div className="row-input">
												<div className="column-1-80">
													<Slider min={0} max={35} step={0.01} disabled={this.state.disable_station} defaultValue={this.state.distance_station_km} onChange={_.debounce(this.distanceToTrainHandler.bind(this), 33)} />
												</div>
												<div className="column-2-20">
													<Form>
														<Form.Check onChange={this.schemaDistanceTrainDisable.bind(this)}
															type="switch"
															id="custom-switch"
															label="Disable"
															defaultChecked={this.state.disable_station}
														/>
													</Form>
												</div>
											</div>
											<div className="row-input">
												<p className='label'>Show trips that start at minimum {this.state.distance_station_km}km <br /> from the nearest train station</p>
											</div>
										</div>
									</div>
								</>
								: <></>
							}
						</>
					}

				</div>
			)
		}
	}
}

class MappTileComparision extends React.Component {

	// Set up states for updating map 
	constructor(props) {
		super(props);
		this.state = {
			lng: 9.248677891126201,
			lat: 47.41466848384309,
			zoom: 12,
			mapita: 9,
			tileid_exploration: 0,
			tileid_name: 0,
			tyle: 'mapbox://styles/mapbox/satellite-streets-v11',
			hideNavs: false
		}
	}

	//Update several fields
	updateFields(list) {
		this.setState(list);
	}

	//Update map on component update
	updateField(card, value) {
		this.setState({
			...this.state,
			[card]: value
		});
	}

	//Update style
	handleTyle(value) {
		this.setState({ tyle: value }, this.componentDidMount);
	}

	//Update coordinates
	handleCoord(center, zoom) {
		const { lng, lat } = center;
		this.setState({ zoom: zoom, lat: lat, lng: lng })
	}

	componentDidMount() {
		// Set up map 
		const map = new mapboxgl.Map({
			container: this.mapContainer,
			style: this.state.tyle,
			center: [this.state.lng, this.state.lat],
			zoom: this.state.zoom,
		});

		map.on('load', () => {

			// Add zoom and rotation controls to the map.
			map.addControl(new mapboxgl.NavigationControl({ showCompass: false, showZoom: false }), 'top-left');
			map.addControl(new MapboxGeocoder({ accessToken: mapboxgl.accessToken, mapboxgl: mapboxgl, marker: false }), 'top-left');

			map.addSource('data-swiss-1km-tile', {
				'type': 'geojson',
				'data': {
					"type": "FeatureCollection",
					"features": [
						{
							"type": "Feature",
							"geometry": {
								"type": "Polygon",
								"coordinates": [
									[
										[
											9.240824,
											47.413758
										],
										[
											9.240824,
											47.422542
										],
										[
											9.254378,
											47.422542
										],
										[
											9.254378,
											47.413758
										],
										[
											9.240824,
											47.413758
										]
									]
								]
							},
							"properties": {
								"tileId": 48913525,
								"PLZ": 9200,
								"name": "Gossau SG",
								"color": "#FF006E"
							}
						}
					]
				},
				'generateId': true
			});

			//Tyle visualization - 1km

			map.addLayer({
				'id': 'swiss-1km-tile',
				'type': 'fill',
				'source': 'data-swiss-1km-tile',
				'layout': {},
				'paint': {
					'fill-color': '#51aea1',
					'fill-opacity': 0.2
				}
			});

			map.addLayer({
				'id': 'outline-swiss-1km-tile',
				'type': 'line',
				'source': 'data-swiss-1km-tile',
				'layout': {},
				'paint': {
					'line-color': '#51aea1',
					'line-width': 1
				}
			});

			//Tyle visualization - 1km

			map.addSource('data-1km-satellite', {
				'type': 'geojson',
				'data': lines_geojson,
				'generateId': true
			});

			map.addLayer({
				'id': 'swiss-1km-satellite',
				'type': 'fill',
				'source': 'data-1km-satellite',
				'layout': {},
				'paint': {
					'fill-color': '#8432c2',
					'fill-opacity': 0.2
				}
			});

			map.addLayer({
				'id': 'outline-swiss-1km-satellite',
				'type': 'line',
				'source': 'data-1km-satellite',
				'layout': {},
				'paint': {
					'line-color': '#8432c2',
					'line-width': 1
				}
			});

			// Add circle 
			map.addSource('point', {
				'type': 'geojson',
				'data': point_geojson
			});

			map.addLayer({
				'id': 'point',
				'type': 'circle',
				'source': 'point',
				'paint': {
					'circle-radius': 10,
					'circle-color': '#e32a8d' // blue color
				}
			});

			//Winners and losers view - Tiles - Exploration view
			map.addSource('data-w-and-l-exploration', {
				'type': 'geojson',
				'data': lines_geojson,
				'generateId': true
			});

			map.addLayer({
				'id': 'data-w-and-l-exploration',
				'type': 'fill',
				'source': 'data-w-and-l-exploration',
				'layout': {
					'visibility': 'visible'
				},
				'paint': {
					'fill-color': '#E32A8D',
					'fill-opacity': 0.15
				}
			});

			map.addLayer({
				'id': 'outline-w-and-l-exploration',
				'type': 'line',
				'source': 'data-w-and-l-exploration',
				'layout': {
					'visibility': 'visible'
				},
				'paint': {
					'line-color': '#E32A8D',
					'line-width': 2
				}
			});

		});

		this.setState({
			mapita: map,
			zoom: map.getZoom(),
			center: map.getCenter()
		});


		//On click move the point
		map.on('click', (event) => {
			const coords = event.lngLat;
			if (!generatedTiles) {
				// Print the coordinates of where the point had
				// finished being dragged to on the map.

				map.getCanvas().style.cursor = '';

				// Unbind mouse/touch events
				map.off('mousemove', onMove);
				map.off('touchmove', onMove);

				point_geojson.features[0].geometry.coordinates = [coords.lng, coords.lat];
				map.getSource('point').setData(point_geojson);

				fetch(URL + 'tile_od_agg/coordinates_to_tiles', {
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						"coordinates": [
							[coords.lng, coords.lat]
						]
					})
				})
					.then((res) => res.json())
					.then((json) => {
						map.getSource('data-swiss-1km-tile').setData(json);
					})
			}
		});


		// When the cursor enters a feature in
		// the point layer, prepare for dragging.
		map.on('mouseenter', 'point', () => {
			map.setPaintProperty('point', 'circle-color', '#9e2e6a');
			map.getCanvas().style.cursor = 'move';
		});

		map.on('mouseleave', 'point', () => {
			map.setPaintProperty('point', 'circle-color', '#9e2e6a');
			map.getCanvas().style.cursor = '';
		});

		function onMove(e) {
			const coords = e.lngLat;
			// Set a UI indicator for dragging.
			map.getCanvas().style.cursor = 'grabbing';

			// Update the Point feature in `geojson` coordinates
			// and call setData to the source layer `point` on it.
			point_geojson.features[0].geometry.coordinates = [coords.lng, coords.lat];
			map.getSource('point').setData(point_geojson);
		}

		function onUp(e) {
			const coords = e.lngLat;
			// Print the coordinates of where the point had
			// finished being dragged to on the map.

			map.getCanvas().style.cursor = '';

			// Unbind mouse/touch events
			map.off('mousemove', onMove);
			map.off('touchmove', onMove);

			point_geojson.features[0].geometry.coordinates = [coords.lng, coords.lat];
			map.getSource('point').setData(point_geojson);
		}

		map.on('mousedown', 'point', (e) => {
			// Prevent the default map drag behavior.
			e.preventDefault();

			map.getCanvas().style.cursor = 'grab';

			map.on('mousemove', onMove);
			map.once('mouseup', onUp);
		});

		map.on('touchstart', 'point', (e) => {
			if (e.points.length !== 1) return;

			// Prevent the default map drag behavior.
			e.preventDefault();

			map.on('touchmove', onMove);
			map.once('touchend', onUp);
		});

		//1Km Tile
		map.on('mouseenter', 'swiss-1km-satellite', () => {
			map.getCanvas().style.cursor = 'pointer';
		});

		map.on('mouseleave', 'swiss-1km-satellite', () => {
			map.getCanvas().style.cursor = '';
		});

		map.on('click', 'swiss-1km-satellite', (e) => {
			window.open(URL_FRONT + 'tile-analysis/' + e.features[0].properties.tileId, "_blank", "noreferrer");
		})

		// Change the cursor to a pointer when the mouse is over the places layer.
		map.on('mouseenter', 'data-w-and-l-exploration', () => {
			map.getCanvas().style.cursor = 'pointer';
		});

		// Change it back to a pointer when it leaves.
		map.on('mouseleave', 'data-w-and-l-exploration', () => {
			map.getCanvas().style.cursor = '';
		});

		map.on('click', 'data-w-and-l-exploration', (e) => {
			this.setState({ tileid_exploration: e.features[0].properties.tileId, tileid_name: e.features[0].properties.name })
		})

		// Fullscreen map 
		//map.addControl(new mapboxgl.FullscreenControl());
	}

	render() {
		const { hideNavs } = this.state;

		return (
			<div>
				<div ref={el => this.mapContainer = el} style={{ width: '100%', height: '100vh' }}>
				</div>
				<RecommendationNavBarComponent />
				<HideElementsComponent map={this.state.mapita} updateField={(card, field, value) => this.updateField(card, field, value)} />
				<div style={{ display: hideNavs ? 'none' : 'block' }}>
					<BtnsComponent map={this.state.mapita} updateFields={(list) => this.updateFields(list)} coord={this.state.coord} tileid_exploration={this.state.tileid_exploration} tileid_name={this.state.tileid_name} />
				</div>
				<LayerTylesComponent map={this.state.mapita} tyle={this.state.tyle} handleTyle={(value) => this.handleTyle(value)} handleCoord={(value1, value2) => this.handleCoord(value1, value2)} />
			</div>
		)
	}
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => (
	<MappTileComparision
		{...props}
	/>
); 