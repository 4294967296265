import React from "react";
import { FaCarSide, FaCity } from "react-icons/fa";
import { IconContext } from "react-icons";
import { Link } from "react-router-dom";
import { BsBuilding, BsPinMapFill } from "react-icons/bs";
import { BiNetworkChart, BiShapePolygon, BiSolidBuildingHouse, BiMapPin } from "react-icons/bi";
import { IoCloudUploadOutline } from "react-icons/io5"
import { IoMdGrid } from "react-icons/io";
import { RiFocus3Line, RiDragDropLine } from "react-icons/ri"
import { MdAddRoad, MdInsights, MdKeyboardDoubleArrowRight, MdKeyboardDoubleArrowLeft, MdOutlineStackedBarChart } from "react-icons/md";
import { FaRankingStar } from "react-icons/fa6";
import RecommendationNavBarComponent from "../components/RecommendationNavBarComponent";
import { GiRailway } from "react-icons/gi";
import { TbTablePlus } from "react-icons/tb";
import { ImDelicious } from "react-icons/im";
import { IoIosTrain } from "react-icons/io";

import './css/menu.css'

//Menu - Main view
class Menu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            current_page: 0,
            max_page: 1
        }
    }

    moveLeft(){
        let the_state =this.state.current_page;
        if(the_state>0){
            this.setState({current_page: the_state-1})
        }
    }

    moveRight(){
        let the_state =this.state.current_page;
        if(the_state<this.state.max_page){
            this.setState({current_page: the_state+1})
        }
    }
    
    render() {
        return (
            <div className="main-div-menu">
                <div className="main-div-menu-inner">
                    <div className="row-1">
                        <div className='image-main-menu'>
                            <img src={require('../assets/img/logo-navbar.png')} alt="Logo ÖV42 by 42hacks" className='logo-img' />
                            <p className="powered-by-main-menu">Powered by <img src={require('../assets/img/swisscom-logo-navbar.png')} alt="Swisscom" className='image-swisscom-main-menu' /> <span className="swisscom-font"></span></p>
                            <p className="title-welcome">Welcome to our Mobility Insights' Platform </p>
                        </div>
                    </div>
                    {
                        this.state.current_page===0 ?
                        <>
                         <div className="row">
                        <div className="column-20">
                            <Link to="/drop-a-point" style={{ textDecoration: 'none' }}>
                                <div className="item-menu">
                                    <div className="item-menu-inner">
                                        <IconContext.Provider value={{ color: "#e32a8d", size: '2.2em' }}>
                                            <div>
                                                <BsPinMapFill />
                                            </div>
                                        </IconContext.Provider>
                                        <p className="title">Point Analysis</p>
                                        <p className="label">Enables users to select any location in Switzerland and obtain in-depth insights into top travel destinations from that point, assisting in informed transportation planning and infrastructure development decisions.</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="column-20">
                            <Link to="/bus-potential" style={{ textDecoration: 'none' }}>
                                <div className="item-menu">
                                    <div className="item-menu-inner">
                                        <IconContext.Provider value={{ color: "#e32a8d", size: '2.2em' }}>
                                            <div>
                                                <BsBuilding />
                                            </div>
                                        </IconContext.Provider>
                                        <p className="title">B-to-B Potential</p>
                                        <p className="label">Utilizes AI algorithms to plan efficient routes for businesses in high-demand areas, saving resources for operators, enhancing passenger experience, and promoting sustainable mobility solutions.</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="column-20">
                            <Link to="/potential-routes" style={{ textDecoration: 'none' }}>
                                <div className="item-menu">
                                    <div className="item-menu-inner">
                                        <IconContext.Provider value={{ color: "#e32a8d", size: '2.2em' }}>
                                            <div>
                                                <MdAddRoad />
                                            </div>
                                        </IconContext.Provider>
                                        <p className="title">Potential of routes</p>
                                        <p className="label">Enables users to aggregate data for identifying and optimizing new or existing transit lines, supporting the expansion of public transportation services and improved connectivity throughout Switzerland.</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="column-20">
                            <Link to="/od-insights" style={{ textDecoration: 'none' }}>
                                <div className="item-menu">
                                    <div className="item-menu-inner">
                                        <IconContext.Provider value={{ color: "#e32a8d", size: '2.2em' }}>
                                            <div>
                                                <BiNetworkChart />
                                            </div>
                                        </IconContext.Provider>
                                        <p className="title">Insights in Modal Split</p>
                                        <p className="label">A comprehensive analysis of Swiss travel data at municipality and postal code levels, revealing transportation trends, passenger pkms, and modal splits' evolution through time.</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="column-20">
                            <Link to="/hotspot-analysis" style={{ textDecoration: 'none' }}>
                                <div className="item-menu">
                                    <div className="item-menu-inner">
                                        <IconContext.Provider value={{ color: "#e32a8d", size: '2.2em' }}>
                                            <div>
                                                <RiFocus3Line />
                                            </div>
                                        </IconContext.Provider>
                                        <p className="title">Hotspot Analysis</p>
                                        <p className="label">Delivers detailed traffic density insights for 1km by 1km and 100m by 100m areas in Switzerland, allowing traffic planners to pinpoint congestion sources by category such as hospitals, malls, and schools.</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="column-20">
                            <Link to="/co2-emission" style={{ textDecoration: 'none' }}>
                                <div className="item-menu">
                                    <div className="item-menu-inner">
                                        <IconContext.Provider value={{ color: "#e32a8d", size: '2.2em' }}>
                                            <div>
                                                <IoCloudUploadOutline />
                                            </div>
                                        </IconContext.Provider>
                                        <p className="title">CO<sub>2</sub> Emissions</p>
                                        <p className="label">Granular visualization and tracking of the environmental impact of transportation by Kanton, municipality, and postal code, based on traffic, number of habitants, and commuters trips.</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="column-20">
                            <Link to="/tile-analysis" style={{ textDecoration: 'none' }}>
                                <div className="item-menu">
                                    <div className="item-menu-inner">
                                        <IconContext.Provider value={{ color: "#e32a8d", size: '2.2em' }}>
                                            <div>
                                                <IoMdGrid />
                                            </div>
                                        </IconContext.Provider>
                                        <p className="title">Tile Analysis</p>
                                        <p className="label">Visualize 1kmx1km tiles to a deeper level. Understand how many people are inside the tile, how are they position in the space (on a granular level of 100mx100m tiles), and visualize the type of buildings where they commute.</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="column-20">
                            <Link to="/winners-losers" style={{ textDecoration: 'none' }}>
                                <div className="item-menu">
                                    <div className="item-menu-inner">
                                        <IconContext.Provider value={{ color: "#027f80", size: '2.2em' }}>
                                            <div>
                                                <FaRankingStar />
                                            </div>
                                        </IconContext.Provider>
                                        <p className="title">Winners vs Losers<sub><i>Beta</i></sub></p>
                                        <p className="label">Evalate Switzerland's winners and losers in Modal Split, based on passenger Km, distance to the main train station,  whether they have public bike infrastructure or no,and the type of trend it follows. </p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="column-20">
                            <Link to="/roads" style={{ textDecoration: 'none' }}>
                                <div className="item-menu">
                                    <div className="item-menu-inner">
                                        <IconContext.Provider value={{ color: "#027f80", size: '2.2em' }}>
                                            <div>
                                                <FaCarSide />
                                            </div>
                                        </IconContext.Provider>
                                        <p className="title">Roads Loads<sub><i>Beta</i></sub></p>
                                        <p className="label">Provides detailed traffic density insights for roads of varying classifications (primary to tertiary) in Switzerland, allowing users to identify traffic sources and gain valuable mobility insights.</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="column-20">
                            <Link to="/marketing" style={{ textDecoration: 'none' }}>
                                <div className="item-menu">
                                    <div className="item-menu-inner">
                                        <IconContext.Provider value={{ color: "#027f80", size: '2.2em' }}>
                                            <div>
                                                <MdInsights />
                                            </div>
                                        </IconContext.Provider>
                                        <p className="title">Marketing<sub><i>Beta</i></sub></p>
                                        <p className="label">Unveils comprehensive commuters' demographics for any chosen point on the map, revealing age, gender, income, household size, and education levels. Enhances planning precision for targeted strategies.</p>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="column-20">
                            <Link to='/employer-analyzer' style={{ textDecoration: 'none' }}>
                                <div className="item-menu">
                                    <div className="item-menu-inner">
                                        <IconContext.Provider value={{ color: "#027f80", size: '2.2em' }}>
                                            <div>
                                                <FaCity />
                                            </div>
                                        </IconContext.Provider>
                                        <p className="title">Employer Analyzer<sub><i>Beta</i></sub></p>
                                        <p className="label">Enable the selection of a company, one to all of its headquarters, to analyze how employees commute with a tile resolution of up to 100x100 meters. It provides an ÖV Quality analysis based on the selected headquarters.</p>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="column-20">
                            <Link to='/regional-demand' style={{ textDecoration: 'none' }}>
                                <div className="item-menu">
                                    <div className="item-menu-inner">
                                        <IconContext.Provider value={{ color: "#027f80", size: '2.2em' }}>
                                            <div>
                                                <BiShapePolygon />
                                            </div>
                                        </IconContext.Provider>
                                        <p className="title">Regional Demand<sub><i>Beta</i></sub></p>
                                        <p className="label">Select polygons as origin and destination zones, visualizing the demand for cars and trains within them over time. It provides valuable insights for traffic management and infrastructure planning.</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                        </>
                        :
                        <>
                         <div className="row">
                        <div className="column-20">
                            <Link to="/potential-corridors" style={{ textDecoration: 'none' }}>
                                <div className="item-menu">
                                    <div className="item-menu-inner">
                                        <IconContext.Provider value={{ color: "#027f80", size: '2.2em' }}>
                                            <div>
                                                <GiRailway />
                                            </div>
                                        </IconContext.Provider>
                                        <p className="title">Potential of Corridors<sub><i>Beta</i></sub></p>
                                        <p className="label">Enable pinpoint any location along a train route using postal codes and 1km tiles, revealing rich insights into passenger origins when commuting to an specific public transport' route.</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="column-20">
                            <Link to="/potential-employers-3d" style={{ textDecoration: 'none' }}>
                                <div className="item-menu">
                                    <div className="item-menu-inner">
                                        <IconContext.Provider value={{ color: "#027f80", size: '2.2em' }}>
                                            <div>
                                                <MdOutlineStackedBarChart />
                                            </div>
                                        </IconContext.Provider>
                                        <p className="title">Potential Employers<sub><i>Beta</i></sub></p>
                                        <p className="label">Reveals the potential, in a 3D mapping visualization, of 1Km tiles or individual companies to ease traffic in Switzerland. Measures based on last mile optimization, working time, behaviour change, bicycle culture and more.</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="column-20">
                            <Link to="/regional-analysis" style={{ textDecoration: 'none' }}>
                                <div className="item-menu">
                                    <div className="item-menu-inner">
                                        <IconContext.Provider value={{ color: "#027f80", size: '2.2em' }}>
                                            <div>
                                                <RiDragDropLine />
                                            </div>
                                        </IconContext.Provider>
                                        <p className="title">Regional Analysis<sub><i>Beta</i></sub></p>
                                        <p className="label">Delve into a user-selected region with a thorough examination. Uncover details on available public transport, frequent trip destinations and origins, evolving modal split over time, and total trip counts. </p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="column-20">
                            <Link to="/5-in-5" style={{ textDecoration: 'none' }}>
                                <div className="item-menu">
                                    <div className="item-menu-inner">
                                        <IconContext.Provider value={{ color: "#027f80", size: '2.2em' }}>
                                            <div>
                                                <TbTablePlus />
                                            </div>
                                        </IconContext.Provider>
                                        <p className="title">5-in-5 Dashboard<sub><i>Beta</i></sub></p>
                                        <p className="label"> Uncover the essential metrics required to redirect 5% of traffic in Switzerland and identify the pivotal companies driving this change. Aggregate data based on factors such as the number of employees, generated trips, etc.</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="column-20">
                            <Link to="/miv-hotspots" style={{ textDecoration: 'none' }}>
                                <div className="item-menu">
                                    <div className="item-menu-inner">
                                        <IconContext.Provider value={{ color: "#027f80", size: '2.2em' }}>
                                            <div>
                                                <IoIosTrain />
                                            </div>
                                        </IconContext.Provider>
                                        <p className="title">Dashboard MIV Hotspots<sub><i>Beta</i></sub></p>
                                        <p className="label">Pinpoint the top Motorized Individual Vehicle (MIV) Hotspots and quantify their annual contribution to Switzerland's overall traffic. Uncover details on the impacted region, category type, and canton affected.</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="column-20">
                            <Link to="/zones-categorization" style={{ textDecoration: 'none' }}>
                                <div className="item-menu">
                                    <div className="item-menu-inner">
                                        <IconContext.Provider value={{ color: "#027f80", size: '2.2em' }}>
                                            <div>
                                                <BiSolidBuildingHouse />
                                            </div>
                                        </IconContext.Provider>
                                        <p className="title">Zone Categorization<sub><i>Beta</i></sub></p>
                                        <p className="label">Tailor your exploration by selecting zones categorized by building types. Uncover commuting patterns, dissected across diverse categories like shopping centers, working areas, residential zones, schools, and more. </p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="column-20">
                            <Link to="/heatmap-3d" style={{ textDecoration: 'none' }}>
                                <div className="item-menu">
                                    <div className="item-menu-inner">
                                        <IconContext.Provider value={{ color: "#027f80", size: '2.2em' }}>
                                            <div>
                                                <IoMdGrid />
                                            </div>
                                        </IconContext.Provider>
                                        <p className="title">Demo: Event Analyzer<sub><i>Beta</i></sub></p>
                                        <p className="label"> Users can engage in a 3D exploration, observing the fluctuations in spectator attendance before, during, and after an event. This insightful tool aids in identifying critical hours for public transport demand.</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="column-20">
                            <Link to="/tile-comparison" style={{ textDecoration: 'none' }}>
                                <div className="item-menu">
                                    <div className="item-menu-inner">
                                        <IconContext.Provider value={{ color: "#027f80", size: '2.2em' }}>
                                            <div>
                                                <ImDelicious />
                                            </div>
                                        </IconContext.Provider>
                                        <p className="title">Tile Comparison<sub><i>Beta</i></sub></p>
                                        <p className="label">Choose a single tile, and our AI algorithm swiftly identifies up to 10 similar tiles based on selected categories like modal split, trip count, passenger-kilometers (pkm), demographics, and building types.</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="column-20" style={{width: '100%'}}>
                            <Link to="/tiles-selector" style={{ textDecoration: 'none' }}>
                                <div className="item-menu" style={{paddingLeft: '38%'}}>
                                    <div className="item-menu-inner">
                                        <IconContext.Provider value={{ color: "#027f80", size: '2.2em' }}>
                                            <div>
                                                <BiMapPin />
                                            </div>
                                        </IconContext.Provider>
                                        <p className="title">Tile Selector<sub><i>Beta</i></sub></p>
                                        <p className="label">Handpick up to five tiles, delving into detailed insights such as modal split, trip count, and passenger-kilometers (pkm) over time patterns. This customizable feature provides understanding of the transportation dynamics.</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                        </>
                    }
                   

                    <div className="row" style={{height: '20px'}}>
                        <div className="column-5" style={{marginLeft: '45%', float: 'left'}}>
                            <div className='map-layer-button-inner' onClick={() => this.moveLeft()}>
                                <IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
                                    <div>
                                    <MdKeyboardDoubleArrowLeft />
                                    </div>
                                </IconContext.Provider>
                            </div>
                        </div>

                        <div className="column-5" style={{marginRight: '45%', float: 'right'}}>
                            <div className='map-layer-button-inner' onClick={() => this.moveRight()}>
                                <IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
                                    <div>
                                    <MdKeyboardDoubleArrowRight />
                                    </div>
                                </IconContext.Provider>
                            </div>
                        </div>
                    </div>
                </div>
                <RecommendationNavBarComponent />
            </div>

        )
    }
}

export default Menu;