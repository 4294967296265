import React from "react";
import './layer-btn.css';
import { CgMenuGridR } from "react-icons/cg";
import { IconContext } from "react-icons";
import { Link } from "react-router-dom";
import { BsBuilding, BsPinMapFill } from "react-icons/bs";
import { BiNetworkChart } from "react-icons/bi";
import { IoCloudUploadOutline } from "react-icons/io5"
import { IoMdGrid } from "react-icons/io";
import { RiFocus3Line } from "react-icons/ri"
import { MdOutlineStackedBarChart } from "react-icons/md";
import { HiViewGridAdd } from "react-icons/hi";

export default class Austria_LayerMenuComponent extends React.Component {

	constructor() {
		super()
		this.state = {
			showOptions: false,
		}
		this.hideComponent = this.hideComponent.bind(this);
	}

	hideComponent(name) {
		this.setState({ showOptions: !this.state.showOptions });
	}
	render() {
		const { showOptions } = this.state;

		return (
			<div>
				{showOptions ? <>
					<div id='layer-options-1' className="map-layer-options2">
						<div className='map-layer-option-util-rail' onClick={() => this.hideComponent("showOptions")}>
							<Link to="/austria/potential-employers-3D" style={{ textDecoration: 'none' }}>
								<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
									<div>
										<MdOutlineStackedBarChart />
									</div>
								</IconContext.Provider>
								<p className='label'>Potential Employers</p>
							</Link>
						</div>
						<div className='map-layer-option-util-rail' onClick={() => this.hideComponent("showOptions")}>
							<Link to="/austria/menu" style={{ textDecoration: 'none' }}>
								<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
									<div>
										<HiViewGridAdd />
									</div>
								</IconContext.Provider>
								<p className='label'>&#10148; Explore all views</p>
							</Link>
						</div>
					</div>
					<div id='layer-options-2' className='map-layer-options'>
						<div className='map-layer-option-car' onClick={() => this.hideComponent("showOptions")}>
							<Link to="/austria/drop-a-point" style={{ textDecoration: 'none' }}>
							<IconContext.Provider value={{ color: "#5B5B5B", size:'1.6em'}}>
							<div>
			 					<BsPinMapFill />
							</div>
						</IconContext.Provider>
						<p className='label'>Point Analysis</p>
							</Link>
						</div>
						<div className='map-layer-option-train' onClick={() => this.hideComponent("showOptions")}>
							<Link to="/austria/od-insights" style={{ textDecoration: 'none' }}>
								<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
									<div>
										<BiNetworkChart />
									</div>
								</IconContext.Provider>
								<p className='label'>Insights in Modal Split</p>
							</Link>
						</div>
					</div>
				</> : <></>}
				<div className='map-layer-button'>
					<div className='map-layer-button-inner' onClick={() => this.hideComponent("showOptions")}>
						<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
							<div>
								<CgMenuGridR />
							</div>
						</IconContext.Provider>
					</div>
				</div>
			</div>
		)
	}
}