import React from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import { IconContext } from "react-icons";
import RecommendationNavBarComponent from "../../components/RecommendationNavBarComponent";
import axios from 'axios';
import Select from 'react-select';
import '../css/map-routes.css';
import { BiExit } from "react-icons/bi";
import { Link } from "react-router-dom";
import { HeatMapGrid } from 'react-grid-heatmap'

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const URL = process.env.REACT_APP_URL_SERVER_BACK;


function numberWithCommas(x) {
    if (typeof x !== "undefined") {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
    }
    else {
        return 0
    }
}


class MainMenu extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        return (
            <div>
                <Link to="/menu" style={{ textDecoration: 'none' }}>
                    <div className='map-close-navs'>
                        <div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
                            <IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
                                <div>
                                    <BiExit />
                                </div>
                            </IconContext.Provider>
                        </div>
                    </div>
                </Link>
            </div>
        )
    }
}


//Dashboard OD Matrix
class DashboardODMatrix extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            day_week_options: [{ label: 'Weekdays', value: 'total_trips_weekdays' }, { value: 'total_trips_weekend', label: 'Weekend' }],
            day_week: { label: 'Weekdays', value: 'total_trips_weekdays' },
            response_data: [[0]], 
            response_x: ['loading'], 
            response_y: ['loading']
        }
        this.handleData('total_trips_weekdays');        
    }

    handleData(during){
        fetch(URL + 'tile_potential/arbeitsmarktregionen_matrix', {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ "first_year":2019, "second_year":2023, "during": during })
		})
        .then((res) => res.json())
        .then((json) => {
            this.setState({ response_data: json.array, response_x: json.x, response_y: json.y  });
            console.log(json)
        })
    }

    handleWeekday(event){
        this.setState({day_week: event});
        this.handleData(event.value);
    }

    render() {
        const selectStyles = {
            menuList: styles => {
                return {
                    ...styles,
                    maxHeight: 100,
                };
            }
        };

        const {response_data} = this.state;
        const {response_x} = this.state;
        const {response_y} = this.state;

        return (
            <div>
                <div className="dashboard-od-matrix">
                    <div className="dashboard-od-matrix-inner">
                        <div className="modal-od-matrix">
                            <div className="modal-od-matrix-inner">
                                <h1>Origin/Destination Potential</h1>
                                <div className="row">
                                    <div className="column-1-50" style={{ width: '150px', paddingRight: '10px' }}>
                                        <p className="label">From</p>
                                        <Select className='employers' isDisabled={true} defaultValue={({ value: '2019', label: '2019' })} styles={selectStyles} />
                                    </div>
                                    <div className="column-1-50" style={{ width: '150px', paddingRight: '10px' }}>
                                        <p className="label">To</p>
                                        <Select className='employers' isDisabled={true} defaultValue={({ value: '2023', label: '2023' })} styles={selectStyles} />
                                    </div>
                                    <div className="column-1-50" style={{ width: '150px', paddingRight: '10px' }}>
                                        <p className="label">Aggregate by</p>
                                        <Select className='employers' isDisabled={true} defaultValue={({ value: 'Year', label: 'Year' })} styles={selectStyles} />
                                    </div>
                                    <div className="column-1-50" style={{ width: '150px', paddingRight: '10px' }}>
                                        <p className="label">During</p>
                                        <Select className='employers' onChange={this.handleWeekday.bind(this)} options={this.state.day_week_options} defaultValue={this.state.day_week} styles={selectStyles} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-od-matrix" style={{ top: '200px' }}>
                            <div className="modal-od-matrix-inner" style={{ width: '90vw', marginLeft: '4vw', height: '65vh', overflow: 'scroll' }}>
                                <HeatMapGrid
                                    data={(response_x.length >1 ? response_data : [[0]])}
                                    xLabels={(response_x.length >1 ? response_x : ['loading'])}
                                    yLabels={(response_x.length >1 ? response_y : ['loading'])}
                                    // Render cell with tooltip
                                    cellRender={(x, y, value) => (
                                        <div title={`${x} -> ${y}: ${value}`}>{value}</div>
                                    )}
                                    xLabelsStyle={() => ({
                                        fontSize: '.5rem',
                                        textTransform: 'uppercase',
                                        color: '#777'
                                    })}
                                    yLabelsStyle={() => ({
                                        fontSize: '.5rem',
                                        textTransform: 'uppercase',
                                        color: '#777'
                                    })}
                                    cellStyle={(_x, _y, ratio) => ({
                                        background: `rgb(12, 160, 44, ${ratio})`,
                                        fontSize: '.8rem',
                                        color: `rgb(0, 0, 0, ${ratio / 2 + 0.4})`
                                    })}
                                    cellHeight='3.0rem'
                                    xLabelsPos='top'
                                    yLabelsPos='left'
                                    xLabelsClassName="xLabels"
                                    yLabelsClassName="yLabels"
                                    square
                                    fixed
                                />                            
                                </div>
                        </div>
                        <RecommendationNavBarComponent />
                        <MainMenu />
                    </div>
                </div>
            </div>
        )
    }
}

export default DashboardODMatrix;