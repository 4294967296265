import React from "react";
import './recommendation-navbar.css';

const NavBarComponent = () => {
	  return (
		<div>
		<div className='map-navbar'>
			<div className='map-navbar-inner'>
				<div className='map-nabar-logo'>
						<img src={require('../assets/img/logo-navbar.png')} alt="Logo ÖV42 by 42hacks" className='logo-img'/>
						<p className="powered-by-navbar">Powered by <img src={require('../assets/img/swisscom-logo-navbar.png')} alt="Swisscom" className='image-swisscom-navbar'/> <span className="swisscom-font"></span></p>
				</div>
			</div>
		</div>
	</div>
	  );
  };
  
  export default NavBarComponent;