
import React from "react";
import { Map } from "react-map-gl";
import DeckGL, { GeoJsonLayer, PolygonLayer } from "deck.gl";
import { IconContext } from "react-icons";
import axios from 'axios';
import RecommendationNavBarComponentHilti from "../../../components/RecommendationNavBarComponentHilti";
import { RiFullscreenFill, RiFullscreenExitFill } from "react-icons/ri";
import { Form, Button } from 'react-bootstrap';

import { createContext, useContext } from 'react';
import { useParams, useLocation } from "react-router-dom";

import '../../css/map-recommendation-deckgl.css';
import '../../css/employer-analyzer.css';

const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const URL = process.env.REACT_APP_URL_SERVER_BACK;

let data_body = ''

const LocationContext = createContext();

function LocationProvider({ children }) {
  const location = useLocation();
  data_body = location.state;
  return (
    <LocationContext.Provider value={location}>
      {children}
    </LocationContext.Provider>
  );
}

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
}

// function isMaskOn(selection_distribution, f){
//   let cat_tile = ''
//   let isMask = true

//   if(selection_distribution==='general'){
//     cat_tile = f.properties.fill_pattern
//   }else if(selection_distribution==='lastmile'){
//     cat_tile = f.properties.last_mile_fill_pattern
//   }else if(selection_distribution==='bike'){
//     cat_tile = f.properties.bike_fill_pattern
//   }else if(selection_distribution==='working'){
//     cat_tile = f.properties.working_fill_pattern
//   }

//   if(cat_tile==='catEmpty' || cat_tile==='catGreen' || cat_tile==='catYellow' || cat_tile==='catRed' ){
//     isMask =  true
//   }else{
//     isMask = false
//   }
//   return isMask;
// }

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? [
    parseInt(result[1], 16),
    parseInt(result[2], 16),
    parseInt(result[3], 16)
  ] : null;
}

function getColors(selection_distribution, f, isSimpliedMap) {

  let cat_tile = ''
  let color_tile = []

  if (selection_distribution === 'general') {
    cat_tile = f.properties.fill_pattern
    color_tile = hexToRgb(f.properties.color)
  } else if (selection_distribution === 'lastmile') {
    cat_tile = f.properties.last_mile_fill_pattern
    color_tile = hexToRgb(f.properties.color_last_mile)
  } else if (selection_distribution === 'bike') {
    cat_tile = f.properties.bike_fill_pattern
    color_tile = hexToRgb(f.properties.color_bike)
  } else if (selection_distribution === 'working') {
    cat_tile = f.properties.working_fill_pattern
    color_tile = hexToRgb(f.properties.color_working)
  } else if (selection_distribution === 'combine') {
    cat_tile = f.properties.combined_fill_pattern
    color_tile = hexToRgb(f.properties.color_combined)
  } else if (selection_distribution === 'optimized_connection') {
    cat_tile = f.properties.optimized_connection_fill_pattern
    color_tile = hexToRgb(f.properties.color_optimized_connection)
  }

  if (!isSimpliedMap) {
    if (cat_tile === 'catEmpty') {
      color_tile = [148, 148, 148]
    } else if (cat_tile === 'catGreen') {
      color_tile = [20, 138, 3]
    } else if (cat_tile === 'catGreenYellow') {
      color_tile = [144, 195, 7]
    } else if (cat_tile === 'catYellow') {
      color_tile = [253, 230, 20]
    } else if (cat_tile === 'catYellowRed') {
      color_tile = [255, 130, 0]
    } else if (cat_tile === 'catRed') {
      color_tile = [221, 4, 0]
    } else if (cat_tile === 'catTriple') {
      color_tile = [0, 186, 158]
    } else if (cat_tile === 'catGreenRed') {
      color_tile = [106, 76, 255]
    } else {
      color_tile = [148, 148, 148]
    }
  }


  return color_tile
}

// function getPattern(f, selection_distribution) {

//   let cat_tile = ''
//   let color_tile = []

//   if(selection_distribution==='general'){
//     cat_tile = f.properties.fill_pattern
//   }else if(selection_distribution==='lastmile'){
//     cat_tile = f.properties.last_mile_fill_pattern
//   }else if(selection_distribution==='bike'){
//     cat_tile = f.properties.bike_fill_pattern
//   }else if(selection_distribution==='working'){
//     cat_tile = f.properties.working_fill_pattern
//   }

//   if(cat_tile==='catEmpty'){
//     color_tile = 'cat0'
//   }else if(cat_tile==='catGreen'){
//     color_tile = 'cat1'
//   }else if(cat_tile==='catGreenYellow'){
//     color_tile = 'cat2'
//   }else if(cat_tile==='catYellow'){
//     color_tile = 'cat3'
//   }else if(cat_tile==='catYellowRed'){
//     color_tile = 'cat4'
//   }else if(cat_tile==='catRed'){
//     color_tile = 'cat5'
//   }else if(cat_tile==='catTriple'){
//     color_tile = 'cat6'
//   }else if(cat_tile==='catGreenRed'){
//     color_tile = 'cat7'
//   }else{
//     color_tile = 'cat0'
//   }

//   return color_tile
// }

class HideElementsComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hideNavs: false,
      mapa: this.props.map,
    }
  }

  hideComponent() {
    let newHideNavs = !this.state.hideNavs;
    this.setState({ hideNavs: newHideNavs })
    this.props.updateField("hideNavs", newHideNavs);
  }

  render() {

    const { hideNavs } = this.state;

    return (
      <div>
        {hideNavs ? <>
          <div className='map-close-navs'>
            <div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
              <IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
                <div>
                  <RiFullscreenExitFill />
                </div>
              </IconContext.Provider>
            </div>
          </div>
        </> : <>
          <div className='map-close-navs'>
            <div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
              <IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
                <div>
                  <RiFullscreenFill />
                </div>
              </IconContext.Provider>
            </div>
          </div>
        </>}
      </div>
    )
  }
}


class Hilti_MapEmployerAnalyzer_3D extends React.Component {
  static contextType = LocationContext;

  constructor(props) {
    super(props);

    this.state = {
      hideNavs: false,
      params: this.props.params,
      data_body: data_body,
      selection_distribution: 'general',
      isSimpliedMap: true,
      current_co2_per_day: 0,
      current_pkm: 0,
      current_trips: 0,
      total_co2_per_day: 0,
      total_pkm: 0,
      total_trips: 0,
      current_modal_split: 0,
      total_modal_split: 0,
      data: this.handleData(),
      doneQuery: false,
      selectedPKm: 'pkm',

      response_total: [],
      percentage_pkm_green: 0,
      percentage_pkm_yellow: 0,
      percentage_pkm_red: 0,
      percentage_trips_green: 0,
      percentage_trips_red: 0,
      percentage_trips_yellow: 0,
      green_pkm: 0,
      green_trips: 0,
      yellow_pkm: 0,
      yellow_trips: 0,
      red_pkm: 0,
      red_trips: 0,
      traffic_mode: 'best_guess'
    }
    this.handleTotalsFetch();
  }

  handleTotalsFetch() {
    let data = ''

    if (data_body !== '') {
    fetch(URL + 'big_employer_analysis/total', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data_body)
    }).then((res) => res.json())
      .then((json) => {
        data = json;
      }).then(() => {

        this.setState({
          response_total: data,
        });

        let response_total = this.state.response_total;

        if (this.state.selection_distribution === 'general') {
          this.setState({
            total_trips: response_total.total_trips,
            total_pkm: (response_total.total_pkm).toFixed(0),
            green_pkm: (response_total.green_pkm).toFixed(0),
            green_trips: response_total.green_trips,
            yellow_pkm: (response_total.yellow_pkm).toFixed(0),
            yellow_trips: response_total.yellow_trips,
            red_pkm: (response_total.red_pkm).toFixed(0),
            red_trips: response_total.red_trips,

            percentage_pkm_green: (((response_total.green_pkm / response_total.total_pkm) * 100).toFixed(0)),
            percentage_pkm_yellow: (((response_total.yellow_pkm / response_total.total_pkm) * 100).toFixed(0)),
            percentage_pkm_red: (((response_total.red_pkm / response_total.total_pkm) * 100).toFixed(0)),
            percentage_trips_green: (((response_total.green_trips / response_total.total_trips) * 100).toFixed(0)),
            percentage_trips_red: (((response_total.red_trips / response_total.total_trips) * 100).toFixed(0)),
            percentage_trips_yellow: (((response_total.yellow_trips / response_total.total_trips) * 100).toFixed(0))
          });
        } else if (this.state.selection_distribution === 'bike') {
          this.setState({
            total_trips: response_total.total_trips,
            total_pkm: (response_total.total_pkm).toFixed(0),
            green_pkm: (response_total.bike_green_pkm).toFixed(0),
            green_trips: response_total.bike_green_trips,
            yellow_pkm: (response_total.bike_yellow_pkm).toFixed(0),
            yellow_trips: response_total.bike_yellow_trips,
            red_pkm: (response_total.bike_red_pkm).toFixed(0),
            red_trips: response_total.bike_red_trips,

            percentage_pkm_green: (((response_total.bike_green_pkm / response_total.total_pkm) * 100).toFixed(0)),
            percentage_pkm_yellow: (((response_total.bike_yellow_pkm / response_total.total_pkm) * 100).toFixed(0)),
            percentage_pkm_red: (((response_total.bike_red_pkm / response_total.total_pkm) * 100).toFixed(0)),
            percentage_trips_green: (((response_total.bike_green_trips / response_total.total_trips) * 100).toFixed(0)),
            percentage_trips_red: (((response_total.bike_red_trips / response_total.total_trips) * 100).toFixed(0)),
            percentage_trips_yellow: (((response_total.bike_yellow_trips / response_total.total_trips) * 100).toFixed(0))
          });
        }
        else if (this.state.selection_distribution === 'lastmile') {
          this.setState({
            total_trips: response_total.total_trips,
            total_pkm: (response_total.total_pkm).toFixed(0),
            green_pkm: (response_total.last_mile_green_pkm).toFixed(0),
            green_trips: response_total.last_mile_green_trips,
            yellow_pkm: (response_total.last_mile_yellow_pkm).toFixed(0),
            yellow_trips: response_total.last_mile_yellow_trips,
            red_pkm: (response_total.last_mile_red_pkm).toFixed(0),
            red_trips: response_total.last_mile_red_trips,

            percentage_pkm_green: (((response_total.last_mile_green_pkm / response_total.total_pkm) * 100).toFixed(0)),
            percentage_pkm_yellow: (((response_total.last_mile_yellow_pkm / response_total.total_pkm) * 100).toFixed(0)),
            percentage_pkm_red: (((response_total.last_mile_red_pkm / response_total.total_pkm) * 100).toFixed(0)),
            percentage_trips_green: (((response_total.last_mile_green_trips / response_total.total_trips) * 100).toFixed(0)),
            percentage_trips_red: (((response_total.last_mile_red_trips / response_total.total_trips) * 100).toFixed(0)),
            percentage_trips_yellow: (((response_total.last_mile_yellow_trips / response_total.total_trips) * 100).toFixed(0))
          });
        } else if (this.state.selection_distribution === 'working') {
          this.setState({
            total_trips: response_total.total_trips,
            total_pkm: (response_total.total_pkm).toFixed(0),
            green_pkm: (response_total.working_green_pkm).toFixed(0),
            green_trips: response_total.working_green_trips,
            yellow_pkm: (response_total.working_yellow_pkm).toFixed(0),
            yellow_trips: response_total.working_yellow_trips,
            red_pkm: (response_total.working_red_pkm).toFixed(0),
            red_trips: response_total.working_red_trips,

            percentage_pkm_green: (((response_total.working_green_pkm / response_total.total_pkm) * 100).toFixed(0)),
            percentage_pkm_yellow: (((response_total.working_yellow_pkm / response_total.total_pkm) * 100).toFixed(0)),
            percentage_pkm_red: (((response_total.working_red_pkm / response_total.total_pkm) * 100).toFixed(0)),
            percentage_trips_green: (((response_total.working_green_trips / response_total.total_trips) * 100).toFixed(0)),
            percentage_trips_red: (((response_total.working_red_trips / response_total.total_trips) * 100).toFixed(0)),
            percentage_trips_yellow: (((response_total.working_yellow_trips / response_total.total_trips) * 100).toFixed(0))
          });
        } else if (this.state.selection_distribution === 'combine') {
          this.setState({
            total_trips: response_total.total_trips,
            total_pkm: (response_total.total_pkm).toFixed(0),
            green_pkm: (response_total.combined_green_pkm).toFixed(0),
            green_trips: response_total.combined_green_trips,
            yellow_pkm: (response_total.combined_yellow_pkm).toFixed(0),
            yellow_trips: response_total.combined_yellow_trips,
            red_pkm: (response_total.combined_red_pkm).toFixed(0),
            red_trips: response_total.combined_red_trips,

            percentage_pkm_green: (((response_total.combined_green_pkm / response_total.total_pkm) * 100).toFixed(0)),
            percentage_pkm_yellow: (((response_total.combined_yellow_pkm / response_total.total_pkm) * 100).toFixed(0)),
            percentage_pkm_red: (((response_total.combined_red_pkm / response_total.total_pkm) * 100).toFixed(0)),
            percentage_trips_green: (((response_total.combined_green_trips / response_total.total_trips) * 100).toFixed(0)),
            percentage_trips_red: (((response_total.combined_red_trips / response_total.total_trips) * 100).toFixed(0)),
            percentage_trips_yellow: (((response_total.combined_yellow_trips / response_total.total_trips) * 100).toFixed(0))
          });
        } else if (this.state.selection_distribution === 'optimized_connection') {
          this.setState({
            total_trips: response_total.total_trips,
            total_pkm: (response_total.total_pkm).toFixed(0),
            green_pkm: (response_total.optimized_connection_green_pkm).toFixed(0),
            green_trips: response_total.optimized_connection_green_trips,
            yellow_pkm: (response_total.optimized_connection_yellow_pkm).toFixed(0),
            yellow_trips: response_total.optimized_connection_yellow_trips,
            red_pkm: (response_total.optimized_connection_red_pkm).toFixed(0),
            red_trips: response_total.optimized_connection_red_trips,

            percentage_pkm_green: (((response_total.optimized_connection_green_pkm / response_total.total_pkm) * 100).toFixed(0)),
            percentage_pkm_yellow: (((response_total.optimized_connection_yellow_pkm / response_total.total_pkm) * 100).toFixed(0)),
            percentage_pkm_red: (((response_total.optimized_connection_red_pkm / response_total.total_pkm) * 100).toFixed(0)),
            percentage_trips_green: (((response_total.optimized_connection_green_trips / response_total.total_trips) * 100).toFixed(0)),
            percentage_trips_red: (((response_total.optimized_connection_red_trips / response_total.total_trips) * 100).toFixed(0)),
            percentage_trips_yellow: (((response_total.optimized_connection_yellow_trips / response_total.total_trips) * 100).toFixed(0))
          });
        }
      })
    }
  }

  componentDidMount() {
    this.getTooltip = this.getTooltip.bind(this);
    if (data_body !== '' && !this.state.doneQuery) {
      setInterval(this.handleData(), 100);
      setInterval(this.handleTotalsFetch(), 100);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.map !== prevProps.map) {
      this.setState({ mapa: this.props.map });
    }
  }

  handleData() {
    if (data_body !== '') {
      fetch(URL + 'big_employer_analysis/3D.geojson', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data_body)
      }).then((res) => res.json())
        .then((json) => {
          this.setState({ data: json, doneQuery: true });
        })
    }
  }

  initialview() {
    return {
      longitude: 9.524259,
      latitude: 47.179846,
      zoom: 8.6,
      maxZoom: 16,
      pitch: 45,
      bearing: 0
    };

  }

  handleVisualization(event) {
    this.setState({ selectedPKm: event.target.attributes.getNamedItem('id').value });

  }

  _renderLayers(selection_distribution, isSimpliedMap) {

    const layer = [
      new GeoJsonLayer({
        id: selection_distribution + isSimpliedMap.toString(),
        data: this.state.data,
        stroked: false,
        extruded: true,
        filled: true,
        pickable: true,
        wireframe: true,
        getLineColor: [0, 0, 0],
        getLineWidth: 10,
        getFillColor: f => getColors(selection_distribution, f, isSimpliedMap),
        getElevation: f => f.properties.trips * 15,
        lineBillboard: true,
        elevationScale: 100,
        opacity: 0.7,

        // props added by FillStyleExtension
        // fillPatternMask: f => Boolean(isMaskOn(selection_distribution, f)),
        // fillPatternAtlas: 'https://raw.githubusercontent.com/DianisCaro99/f/main/spritesheet.png',
        // fillPatternMapping: 'https://raw.githubusercontent.com/DianisCaro99/f/main/spritesheet_final.json',
        // getFillPattern: f => getPattern(f, selection_distribution),
        // getFillPatternScale: 10,
        // getFillPatternOffset: [0, 0],
        // Define extensions
        // extensions: [new FillStyleExtension({pattern: (true)})]
      })
    ];
    return layer;
  }

  handleAggregation(event) {
    this.setState({ selection_distribution: event.target.attributes.getNamedItem('id').value });

    let response_total = this.state.response_total;

    if (event.target.attributes.getNamedItem('id').value === 'general') {
      this.setState({
        total_trips: response_total.total_trips,
        total_pkm: (response_total.total_pkm).toFixed(0),
        green_pkm: (response_total.green_pkm).toFixed(0),
        green_trips: response_total.green_trips,
        yellow_pkm: (response_total.yellow_pkm).toFixed(0),
        yellow_trips: response_total.yellow_trips,
        red_pkm: (response_total.red_pkm).toFixed(0),
        red_trips: response_total.red_trips,

        percentage_pkm_green: (((response_total.green_pkm / response_total.total_pkm) * 100).toFixed(0)),
        percentage_pkm_yellow: (((response_total.yellow_pkm / response_total.total_pkm) * 100).toFixed(0)),
        percentage_pkm_red: (((response_total.red_pkm / response_total.total_pkm) * 100).toFixed(0)),
        percentage_trips_green: (((response_total.green_trips / response_total.total_trips) * 100).toFixed(0)),
        percentage_trips_red: (((response_total.red_trips / response_total.total_trips) * 100).toFixed(0)),
        percentage_trips_yellow: (((response_total.yellow_trips / response_total.total_trips) * 100).toFixed(0))
      });
    } else if (event.target.attributes.getNamedItem('id').value === 'bike') {
      this.setState({
        total_trips: response_total.total_trips,
        total_pkm: (response_total.total_pkm).toFixed(0),
        green_pkm: (response_total.bike_green_pkm).toFixed(0),
        green_trips: response_total.bike_green_trips,
        yellow_pkm: (response_total.bike_yellow_pkm).toFixed(0),
        yellow_trips: response_total.bike_yellow_trips,
        red_pkm: (response_total.bike_red_pkm).toFixed(0),
        red_trips: response_total.bike_red_trips,

        percentage_pkm_green: (((response_total.bike_green_pkm / response_total.total_pkm) * 100).toFixed(0)),
        percentage_pkm_yellow: (((response_total.bike_yellow_pkm / response_total.total_pkm) * 100).toFixed(0)),
        percentage_pkm_red: (((response_total.bike_red_pkm / response_total.total_pkm) * 100).toFixed(0)),
        percentage_trips_green: (((response_total.bike_green_trips / response_total.total_trips) * 100).toFixed(0)),
        percentage_trips_red: (((response_total.bike_red_trips / response_total.total_trips) * 100).toFixed(0)),
        percentage_trips_yellow: (((response_total.bike_yellow_trips / response_total.total_trips) * 100).toFixed(0))
      });
    }
    else if (event.target.attributes.getNamedItem('id').value === 'lastmile') {
      this.setState({
        total_trips: response_total.total_trips,
        total_pkm: (response_total.total_pkm).toFixed(0),
        green_pkm: (response_total.last_mile_green_pkm).toFixed(0),
        green_trips: response_total.last_mile_green_trips,
        yellow_pkm: (response_total.last_mile_yellow_pkm).toFixed(0),
        yellow_trips: response_total.last_mile_yellow_trips,
        red_pkm: (response_total.last_mile_red_pkm).toFixed(0),
        red_trips: response_total.last_mile_red_trips,

        percentage_pkm_green: (((response_total.last_mile_green_pkm / response_total.total_pkm) * 100).toFixed(0)),
        percentage_pkm_yellow: (((response_total.last_mile_yellow_pkm / response_total.total_pkm) * 100).toFixed(0)),
        percentage_pkm_red: (((response_total.last_mile_red_pkm / response_total.total_pkm) * 100).toFixed(0)),
        percentage_trips_green: (((response_total.last_mile_green_trips / response_total.total_trips) * 100).toFixed(0)),
        percentage_trips_red: (((response_total.last_mile_red_trips / response_total.total_trips) * 100).toFixed(0)),
        percentage_trips_yellow: (((response_total.last_mile_yellow_trips / response_total.total_trips) * 100).toFixed(0))
      });
    } else if (event.target.attributes.getNamedItem('id').value === 'working') {
      this.setState({
        total_trips: response_total.total_trips,
        total_pkm: (response_total.total_pkm).toFixed(0),
        green_pkm: (response_total.working_green_pkm).toFixed(0),
        green_trips: response_total.working_green_trips,
        yellow_pkm: (response_total.working_yellow_pkm).toFixed(0),
        yellow_trips: response_total.working_yellow_trips,
        red_pkm: (response_total.working_red_pkm).toFixed(0),
        red_trips: response_total.working_red_trips,

        percentage_pkm_green: (((response_total.working_green_pkm / response_total.total_pkm) * 100).toFixed(0)),
        percentage_pkm_yellow: (((response_total.working_yellow_pkm / response_total.total_pkm) * 100).toFixed(0)),
        percentage_pkm_red: (((response_total.working_red_pkm / response_total.total_pkm) * 100).toFixed(0)),
        percentage_trips_green: (((response_total.working_green_trips / response_total.total_trips) * 100).toFixed(0)),
        percentage_trips_red: (((response_total.working_red_trips / response_total.total_trips) * 100).toFixed(0)),
        percentage_trips_yellow: (((response_total.working_yellow_trips / response_total.total_trips) * 100).toFixed(0))
      });
    } else if (event.target.attributes.getNamedItem('id').value === 'combine') {
      this.setState({
        total_trips: response_total.total_trips,
        total_pkm: (response_total.total_pkm).toFixed(0),
        green_pkm: (response_total.combined_green_pkm).toFixed(0),
        green_trips: response_total.combined_green_trips,
        yellow_pkm: (response_total.combined_yellow_pkm).toFixed(0),
        yellow_trips: response_total.combined_yellow_trips,
        red_pkm: (response_total.combined_red_pkm).toFixed(0),
        red_trips: response_total.combined_red_trips,

        percentage_pkm_green: (((response_total.combined_green_pkm / response_total.total_pkm) * 100).toFixed(0)),
        percentage_pkm_yellow: (((response_total.combined_yellow_pkm / response_total.total_pkm) * 100).toFixed(0)),
        percentage_pkm_red: (((response_total.combined_red_pkm / response_total.total_pkm) * 100).toFixed(0)),
        percentage_trips_green: (((response_total.combined_green_trips / response_total.total_trips) * 100).toFixed(0)),
        percentage_trips_red: (((response_total.combined_red_trips / response_total.total_trips) * 100).toFixed(0)),
        percentage_trips_yellow: (((response_total.combined_yellow_trips / response_total.total_trips) * 100).toFixed(0))
      });
    } else if (event.target.attributes.getNamedItem('id').value === 'optimized_connection') {
      this.setState({
        total_trips: response_total.total_trips,
        total_pkm: (response_total.total_pkm).toFixed(0),
        green_pkm: (response_total.optimized_connection_green_pkm).toFixed(0),
        green_trips: response_total.optimized_connection_green_trips,
        yellow_pkm: (response_total.optimized_connection_yellow_pkm).toFixed(0),
        yellow_trips: response_total.optimized_connection_yellow_trips,
        red_pkm: (response_total.optimized_connection_red_pkm).toFixed(0),
        red_trips: response_total.optimized_connection_red_trips,

        percentage_pkm_green: (((response_total.optimized_connection_green_pkm / response_total.total_pkm) * 100).toFixed(0)),
        percentage_pkm_yellow: (((response_total.optimized_connection_yellow_pkm / response_total.total_pkm) * 100).toFixed(0)),
        percentage_pkm_red: (((response_total.optimized_connection_red_pkm / response_total.total_pkm) * 100).toFixed(0)),
        percentage_trips_green: (((response_total.optimized_connection_green_trips / response_total.total_trips) * 100).toFixed(0)),
        percentage_trips_red: (((response_total.optimized_connection_red_trips / response_total.total_trips) * 100).toFixed(0)),
        percentage_trips_yellow: (((response_total.optimized_connection_yellow_trips / response_total.total_trips) * 100).toFixed(0))
      });
    }
  }

  handleIsSimpliedMap() {
    this.setState({ isSimpliedMap: !this.state.isSimpliedMap });
  }

  getTooltip({ object }) {
    return (
      object && {
        html: `\
        <div><b>Destination</b></div>
      <div>${object.properties.employer}</div>
      <div><i>${object.properties.destination}</i></div>
      <div><b>Duration in trains</b></div>
      <div>${object.properties.duration_train_text}</div>
      <div><b>Duration in cars</b></div>
      <div>${object.properties.duration_car_text}</div>
      <div><b>Number of trips</b></div>
      <div>${object.properties.trips}</div>
      <div><b>Passenger Km</b></div>
      <div>${(object.properties.pkm).toFixed(0)} PKm</div>
      `
      }
    );
  }

  updateField(card, value) {
    this.setState({
      ...this.state,
      [card]: value
    });
  }

  render() {
    const { viewState, controller = true } = this.props;
    const { hideNavs } = this.state;
    const { selection_distribution } = this.state;
    const { isSimpliedMap } = this.state;
    const { selectedPKm } = this.state;

    const { percentage_trips_green } = this.state;
    const { percentage_trips_red } = this.state;
    const { percentage_trips_yellow } = this.state;
    const { green_trips } = this.state;
    const { red_trips } = this.state;
    const { yellow_trips } = this.state;

    const { percentage_pkm_green } = this.state;
    const { percentage_pkm_red } = this.state;
    const { percentage_pkm_yellow } = this.state;
    const { green_pkm } = this.state;
    const { red_pkm } = this.state;
    const { yellow_pkm } = this.state;

    return (
      <div>
        <LocationProvider>
          <DeckGL
            layers={this._renderLayers(this.state.selection_distribution, this.state.isSimpliedMap)}
            initialViewState={this.initialview()}
            viewState={viewState}
            controller={controller}
            getTooltip={this.getTooltip}
          >
            <Map reuseMaps mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN} mapStyle={'mapbox://styles/mapbox/light-v10'} />
            <RecommendationNavBarComponentHilti />
            <div style={{ display: hideNavs ? 'none' : 'block' }}>
                {(selectedPKm === 'trips') ? <>
                  <div className="headquaters-btn-tile-potential-resp-1">
                    <div className="headquaters-btn-tile-potential-resp-1-inner">
                      <div className="row-parameters-3">
                        <h3>{percentage_trips_green}% Trips</h3>
                        <h4>{numberWithCommas(green_trips)} Trips</h4>
                        <h5>Great ÖV Quality</h5>
                      </div>
                    </div>
                  </div>
                  <div className="headquaters-btn-tile-potential-resp-2">
                    <div className="headquaters-btn-tile-potential-resp-2-inner">
                      <div className="row-parameters-3">
                        <h3>{percentage_trips_yellow}% Trips</h3>
                        <h4>{numberWithCommas(yellow_trips)} Trips</h4>
                        <h5>Decent ÖV Quality</h5>
                      </div>
                    </div>
                  </div>
                  <div className="headquaters-btn-tile-potential-resp-3">
                    <div className="headquaters-btn-tile-potential-resp-3-inner">
                      <div className="row-parameters-3">
                        <h3>{percentage_trips_red}% Trips</h3>
                        <h4>{numberWithCommas(red_trips)} Trips</h4>
                        <h5>Bad ÖV Quality</h5>
                      </div>
                    </div>
                  </div>
                </> : <>
                  <div className="headquaters-btn-tile-potential-resp-1">
                    <div className="headquaters-btn-tile-potential-resp-1-inner">
                      <div className="row-parameters-3">
                        <h3>{percentage_pkm_green}% PKm</h3>
                        <h4>{numberWithCommas(green_pkm)} PKm</h4>
                        <h5>Great ÖV Quality</h5>
                      </div>
                    </div>
                  </div>
                  <div className="headquaters-btn-tile-potential-resp-2">
                    <div className="headquaters-btn-tile-potential-resp-2-inner">
                      <div className="row-parameters-3">
                        <h3>{percentage_pkm_yellow}% PKm</h3>
                        <h4>{numberWithCommas(yellow_pkm)} PKm</h4>
                        <h5>Decent ÖV Quality</h5>
                      </div>
                    </div>
                  </div>
                  <div className="headquaters-btn-tile-potential-resp-3">
                    <div className="headquaters-btn-tile-potential-resp-3-inner">
                      <div className="row-parameters-3">
                        <h3>{percentage_pkm_red}% PKm</h3>
                        <h4>{numberWithCommas(red_pkm)} PKm</h4>
                        <h5>Bad ÖV Quality</h5>
                      </div>
                    </div>
                  </div>
                </>
                }
                <div className="headquaters-visualization-percentage-modal">
                  <div className="headquaters-visualization-percentage-modal-inner">
                    <h2>Visualize contribution percentage by</h2>
                    <Form onChange={this.handleVisualization.bind(this)}>
                      <div className="row">
                        <div className="column-1-eq">
                          <Form.Check inline label=" Passenger Km" name="type_analysis" type='radio' id='pkm' defaultChecked={(selectedPKm === 'pkm')} />
                        </div>
                        <div className="column-2-eq">
                          <Form.Check inline label=" Number of Trips" name="type_analysis" type='radio' id='trips' defaultChecked={(selectedPKm === 'trips')} />
                        </div>
                      </div>
                    </Form>
                  </div>
                </div> <div className="headquaters-visualization-type-modal">
                        <div className="headquaters-visualization-type-modal-inner" style={{width: '550px'}}>
                            <h2>Enable distribution by</h2>
                            <Form onChange={this.handleAggregation.bind(this)}>
                                <div className="row">
                                    <div className="column-headquarter-2" style={{width: '18%'}}>
                                        <Form.Check inline label=" Status Quo" name="type_analysis" type='radio' id='general' defaultChecked={(selection_distribution === 'general')} />
                                    </div>
                                    <div className="column-headquarter-3" style={{width: '16%'}}>
                                        <Form.Check inline label=" Last-Mile" name="type_analysis" type='radio' id='lastmile' defaultChecked={(selection_distribution === 'lastmile')} />
                                    </div>
                                    <div className="column-headquarter-4"  style={{width: '14%'}}>
                                        <Form.Check inline label=" Bicycle" name="type_analysis" type='radio' id='bike' defaultChecked={(selection_distribution === 'bike')} />
                                    </div>
                                    <div className="column-headquarter-4"  style={{width: '15%'}}>
                                        <Form.Check inline label=" Working" name="type_analysis" type='radio' id='working' defaultChecked={(selection_distribution === 'working')} />
                                    </div>
                                    <div className="column-headquarter-5"  style={{width: '17%'}}>
                                        <Form.Check inline label=" Combined" name="type_analysis" type='radio' id='combine' defaultChecked={(selection_distribution === 'optimized_connection')} />
                                    </div>
                                    <div className="column-headquarter-5"  style={{width: '18%'}}>
                                        <Form.Check inline label=" Schedule" name="type_analysis" type='radio' id='optimized_connection' defaultChecked={(selection_distribution === 'optimized_connection')} />
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                  <div className="headquaters-visualization-color-scheme-modal-3D">
                    <div className="headquaters-visualization-color-scheme-modal-3D-inner">
                      <h2>Map Convection</h2>
                      <div className="row-parameters-2">
                        <div className="column-30"><div className="square" style={{ backgroundColor: "#148A03" }}><p className="label-2">Good ÖV Quality</p></div></div>
                        <div className="column-30"><div className="square" style={{ backgroundColor: "#FDE614" }}><p className="label-2">Decent ÖV Quality</p></div></div>
                        <div className="column-30"><div className="square" style={{ backgroundColor: "#DD0400" }}><p className="label-2">Bad ÖV Quality</p></div></div>
                      </div>
                      <div className="row-parameters-4">
                        <div className="square" style={{ backgroundColor: "#949494" }}><p className="label-50-2">No enough data to get ÖV Quality</p></div>
                      </div>
                    </div>
                  </div>
              </div>
          </DeckGL>
          <HideElementsComponent map={this.state.mapita} updateField={(card, field, value) => this.updateField(card, field, value)} />
        </LocationProvider>
      </div>
    );
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => (
  // eslint-disable-next-line react/jsx-pascal-case
  <Hilti_MapEmployerAnalyzer_3D
    {...props}
    params={useParams()}
  />
); 
