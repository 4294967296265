import React from "react";
import { Button } from 'react-bootstrap';

import './css/login.css';

const AccessDenied = () => {

    return (
        <div className="main-div">
            <div className="main-div-inner">
                <div className="modal-access-denied">
                    <div className="modal-access-denied-inner">
                        <img src={require('../assets/img/logo-navbar.png')} alt="Logo ÖV42 by 42hacks" className='image-logo-access-denied' />
                        <p className="access-denied-text">
                            Hey there. Welcome to the ÖV42 waiting list!
                            <br/>
                            <br/>
                            <br/> We're super excited to have you on our community. Currently, someone from our team is reviewing your petition to join our platform. You will be notified via your registered email once the process is complete. 
                            <br/>
                            <br/>
                            <br/> We look forward to having you on board soon!
                            <br/> Thank you once again,
                            <br/> <i>ÖV42 Team</i>
                            <br/>
                            <br/>
                        </p>
                        <div className="row">
                        <a href="https://www.42hacks.com/greenmobility" rel="noopener noreferrer">
                        <Button className='btn-main-page'>Get to know our project</Button>
</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccessDenied;