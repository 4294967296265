import React from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import axios from 'axios';
import {Form} from 'react-bootstrap';
import Slider from 'rc-slider';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '../css/mapbox-gl-geocoder.css';
import 'rc-slider/assets/index.css';
import '../css/map-access.css';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const URL = process.env.REACT_APP_URL_SERVER_BACK;

const _ = require('lodash');

// eslint-disable-next-line no-extend-native
String.prototype.format = function () {
	// store arguments in an array
	let args = arguments;
	// use replace to iterate over the string
	// select the match and check if related argument is present
	// if yes, replace the match with the argument
	return this.replace(/{([0-9]+)}/g, function (match, index) {
	  // check if the argument is present
	  return typeof args[index] == 'undefined' ? match : args[index];
	});
  };
//Trip Analyzer Component - Dropdown Menu and Radio Btn
class DistanceAnalyzerComponent extends React.Component{

	constructor(props) {
		super(props)
    	this.state = {
			mapa:  this.props.map,
			type:"start",
			distance_kms: 5000,
			default_kms:5
    	}
	
		this.tripChangeHandler = this.tripChangeHandler.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (this.props.map !== prevProps.map) {
		  this.setState({mapa: this.props.map})
		}
	  }
	
	handleSubmit(event) {
		console.log('key: ', event.target.attributes.getNamedItem('id').value);
		this.setState({type: event.target.attributes.getNamedItem('id').value});
		let url=URL+'distance-nearest-station/{0},{1}'.format(event.target.attributes.getNamedItem('id').value,this.state.distance_kms)
		this.state.mapa.getSource('data-swiss-access').setData(url);	
	}

	tripChangeHandler(event) {
		console.log(event, this.state.type)
		let event_in_km = event*1000;
		this.setState({distance_kms: event_in_km});
		let url=URL+'distance-nearest-station/{0},{1}'.format(
			this.state.type,event_in_km);
		this.state.mapa.getSource('data-swiss-access').setData(url);	

	}

	render(){
		return(
			<div>
				<div>
				<div className='map-navbar'>
                    <div className='map-navbar-inner'>
                        <div className='map-nabar-logo'>
                            <img src={require('../../assets/img/logo-navbar.png')} alt="Logo ÖV42 by 42hacks" className='logo-img'/>
                        </div>
                    </div>
				</div>
			</div>
				<div className="map-card-distance-analyzer">
					<div className="map-card-distance-analyzer-inner">
						<h1>Accessibility to train stations</h1>
							<p className='label' for="origin">Distance to:</p>
							<Slider min={3} max={20} defaultValue={this.state.default_kms} onChange={_.debounce(this.tripChangeHandler.bind(this),33)}/>
							<Form onChange={this.handleSubmit}>
							<Form.Check inline label=" Starting station" name="type_analysis" type='radio' id='start' defaultChecked={true}/>
							<Form.Check inline label=" Ending station" name="type_analysis" type='radio' id='end' />
							</Form>
					</div>
				</div>
			</div>
		)
	}
}

class MappAccessibility extends React.Component{

	// Set up states for updating map 
	constructor(props){
		super(props);
		this.state = {
			lng:8.251313,
			lat: 46.924143,
			zoom: 7,
			mapita:9,
		}
	}

	async getOptions(url){
		const res = await axios.get(url)
		const data = res.data
		let hashmap = new Map();

		data.forEach(d => {
			hashmap.set(d.id,d.name)
		});

		return hashmap
	}

	componentDidMount() {

		// Set up map 
		const map = new mapboxgl.Map({
			container: this.mapContainer,
			style: 'mapbox://styles/mapbox/light-v10',
			center: [this.state.lng, this.state.lat],
			zoom: this.state.zoom, 
		});
		map.on('load', () => {

			// Add zoom and rotation controls to the map.
			map.addControl(new mapboxgl.NavigationControl({showCompass: false, showZoom: false}), 'top-left');
			map.addControl( new MapboxGeocoder({ accessToken: mapboxgl.accessToken, mapboxgl: mapboxgl, marker: false}), 'top-left' );

			// add lines to the map
			map.addSource('data-swiss-access', {
				'type': 'geojson',
				'data': URL+"distance-nearest-station/end,5000",
				'generateId': true,
				'lineMetrics': true
			});
			map.addLayer({
				'id': 'swiss-access',
				'type': 'line',
				'source': 'data-swiss-access',
				'layout': {},
				'paint': {
				'line-color': '#a71e34',
				'line-opacity': 0.30,
				'line-width': 1.3,
				}
				});
			
		});

		this.setState({
			mapita: map,
		  }, () => console.log(this.state));
		
		// Fullscreen map 
		//map.addControl(new mapboxgl.FullscreenControl());

	}

	render(){
		return(
			<div>
				<div ref={el => this.mapContainer = el} style={{width:'100%',height:'100vh'}}>
				</div>
				<DistanceAnalyzerComponent map = {this.state.mapita} zoom ={this.state.zoom}/>
			</div>
		)
	}
}

export default MappAccessibility;
