import React from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '../css/map-drop-point.css';
import { Form, Button } from 'react-bootstrap';
import { IconContext } from "react-icons";
import { BsLayersHalf } from "react-icons/bs";
import RecommendationNavBarComponent from "../../components/RecommendationNavBarComponent";
import { RiFullscreenFill, RiFullscreenExitFill } from "react-icons/ri";
import Chart from 'chart.js/auto';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ExportExcel from "../../utils/ExportExcel";
import { Line } from 'react-chartjs-2';
import { VscGraphLine } from "react-icons/vsc";
import Slider from 'rc-slider';
import axios from 'axios';
import Select from 'react-select';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const URL = process.env.REACT_APP_URL_SERVER_BACK;

let generatedTiles = false;
let hideLines = true;
let hideDestination = false;

let tile_empty = {
    'type': 'FeatureCollection',
    'features': [
        {
            'type': 'Feature',
            'geometry': {
                "type": "LineString",
                "coordinates": [
                ]
            }
        }
    ]
};
let tile_1km_geojson = tile_empty;

const _ = require('lodash');

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
}

class HideElementsComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            hideNavs: false,
            mapa: this.props.map,
        }
    }

    hideComponent() {
        let newHideNavs = !this.state.hideNavs;
        this.setState({ hideNavs: newHideNavs })
        this.props.updateField("hideNavs", newHideNavs);
    }

    render() {

        const { hideNavs } = this.state;

        return (
            <div>
                {hideNavs ? <>
                    <div className='map-close-navs'>
                        <div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
                            <IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
                                <div>
                                    <RiFullscreenExitFill />
                                </div>
                            </IconContext.Provider>
                        </div>
                    </div>
                </> : <>
                    <div className='map-close-navs'>
                        <div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
                            <IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
                                <div>
                                    <RiFullscreenFill />
                                </div>
                            </IconContext.Provider>
                        </div>
                    </div>
                </>}
            </div>
        )
    }
}

const lines_geojson = {
    'type': 'FeatureCollection',
    'features': [
        {
            'type': 'Feature',
            'geometry': {
                "type": "LineString",
                "coordinates": [
                ]
            }
        }
    ]
};

const point_geojson = {
    'type': 'FeatureCollection',
    'features': [
        {
            'type': 'Feature',
            'geometry': {
                'type': 'Point',
                'coordinates': [8.251313, 46.924143]
            }
        }
    ]
};

//Layer Tyles Selection - Side Menu
class LayerTylesComponent extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            showOptions: false,
            mapa: this.props.map,
        }
        this.hideComponent = this.hideComponent.bind(this);
        this.handleTyleEvent = this.handleTyleEvent.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.map !== prevProps.map) {
            this.setState({ mapa: this.props.map })
        }
    }

    hideComponent(name) {
        this.setState({ showOptions: !this.state.showOptions });
    }

    handleTyleEvent(event) {
        switch (event) {
            case "default":
                this.props.handleTyle("mapbox://styles/mapbox/light-v10");
                break;
            case "outdoors":
                this.props.handleTyle("mapbox://styles/mapbox/outdoors-v11");
                break;
            case "satellite":
                this.props.handleTyle("mapbox://styles/mapbox/satellite-streets-v11");
                break;
            case "streets":
                this.props.handleTyle("mapbox://styles/mapbox/streets-v11");
                break;
            case "dark":
                this.props.handleTyle("mapbox://styles/mapbox/dark-v10");
                break;
            default:
                // eslint-disable-next-line no-unused-expressions
                null;
        }
        this.props.handleCoord(this.state.mapa.getCenter(), this.state.mapa.getZoom());
    }

    render() {
        const { showOptions } = this.state;

        return (
            <div>
                {showOptions ? <>
                    <div id='layer-options' className='map-layer-options-tyles'>
                        <div className='map-layer-option-dark' onClick={() => this.handleTyleEvent("dark")}>
                            <IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
                                <div>
                                    <img src={require('../../assets/img/Dark.png')} alt="Default tyle" className='img-tyle' />
                                </div>
                            </IconContext.Provider>
                            <p className='label'>Dark</p>
                        </div>
                        <div className='map-layer-option-streets' onClick={() => this.handleTyleEvent("streets")}>
                            <IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
                                <div>
                                    <img src={require('../../assets/img/Streets.png')} alt="Default tyle" className='img-tyle' />
                                </div>
                            </IconContext.Provider>
                            <p className='label'>Streets</p>
                        </div>
                        <div className='map-layer-option-satellite' onClick={() => this.handleTyleEvent("satellite")}>
                            <IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
                                <div>
                                    <img src={require('../../assets/img/Satellite.png')} alt="Default tyle" className='img-tyle' />
                                </div>
                            </IconContext.Provider>
                            <p className='label'>Satellite</p>
                        </div>
                        <div className='map-layer-option-outdoors' onClick={() => this.handleTyleEvent("outdoors")}>
                            <IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
                                <div>
                                    <img src={require('../../assets/img/Outdoors.png')} alt="Default tyle" className='img-tyle' />
                                </div>
                            </IconContext.Provider>
                            <p className='label'>Outdoors</p>
                        </div>
                        <div className='map-layer-option-default' onClick={() => this.handleTyleEvent("default")}>
                            <IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
                                <div>
                                    <img src={require('../../assets/img/White.png')} alt="Default tyle" className='img-tyle' />
                                </div>
                            </IconContext.Provider>
                            <p className='label'>Default</p>
                        </div>
                    </div>
                </> : <></>}
                <div className='map-layer-tyles'>
                    <div className='map-layer-tyles-inner' onClick={() => this.hideComponent("showOptions")}>
                        <IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
                            <div>
                                <BsLayersHalf />
                            </div>
                        </IconContext.Provider>
                    </div>
                </div>
            </div>
        )
    }
}

class GraphComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            mapa: this.props.map,
            graphParam: this.props.graphParam,
            take_od: 0,
            respModalPKM: [],
            respModalTrips: [],
            showGraph: false,
            key_array: [],
            datasetMSPKm: [],
            datasetMSTrips: [],
            excelData: []
        }
    }

    componentDidMount() {
        this.handleFetchDataModalPKM = this.handleFetchDataModalPKM.bind(this);
        this.handleFetchDataModalTrips = this.handleFetchDataModalTrips.bind(this);
        this.handleGraphComponent = this.handleGraphComponent.bind(this);
        this.handleFetchDataExcel = this.handleFetchDataExcel.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.map !== prevProps.map) {
            this.setState({ mapa: this.props.map });
        }
        if (this.props.graphParam !== prevProps.graphParam) {
            this.setState({ graphParam: this.props.graphParam });
            if (this.props.graphParam.length===0) {
                this.setState({ respModalPKM: [],
                    respModalTrips: [],
                    showGraph: false,
                    key_array: [],
                    datasetMSPKm: [],
                    datasetMSTrips: [],
                    excelData: []  })
            } else {
                this.setState({ showGraph: true })
                this.handleFetchDataModalPKM(this.props.graphParam);
                this.handleFetchDataModalTrips(this.props.graphParam);
                this.handleFetchDataExcel(this.props.graphParam);
            }
        }
    }

    async handleFetchDataModalTrips(param) {
        fetch(URL + 'tile_od_agg/modal_split_trips',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({tile_list: param})
            })
            .then((res) => res.json())
            .then((json) => {
                let keys = Object.keys(json)
                this.setState({key_array: keys})

                let current_MS = []
                for(var key_index = 0; key_index < keys.length; key_index++){

                    let arrayCurrentMS = json[keys[key_index]]['modal_split_trains']

                    for (var i = 0; i < json[keys[key_index]]['modal_split_trains'].length; i++) {
                        arrayCurrentMS[i] = (Number(json[keys[key_index]]['modal_split_trains'].at(i)) * 100);
                    }

                    console.log()
                    current_MS.push({
                        label: keys[key_index],
                        data: arrayCurrentMS,
                        backgroundColor: [
                            'rgba('+json[keys[key_index]]['color']+', 0.2)'
                        ],
                        borderColor: [
                            'rgb('+json[keys[key_index]]['color']+')'
                        ],
                        borderWidth: 1
                    })
                }

                this.setState({ datasetMSTrips: current_MS });
                return json
            })
            .then((json) => {
                this.setState({ respModalTrips: json });
            })
    }

    async handleFetchDataModalPKM(param) {
        fetch(URL + 'tile_od_agg/modal_split_pkm',
        {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({tile_list: param})
        })
            .then((res) => res.json())
            .then((json) => {
                let keys = Object.keys(json)
                this.setState({key_array: keys})

                let current_MS = []
                for(var key_index = 0; key_index < keys.length; key_index++){

                    let arrayCurrentMS = json[keys[key_index]]['modal_split']

                    for (var i = 0; i < json[keys[key_index]]['modal_split'].length; i++) {
                        arrayCurrentMS[i] = (Number(json[keys[key_index]]['modal_split'].at(i)) * 100);
                    }

                    console.log(arrayCurrentMS)

                    current_MS.push({
                        label: keys[key_index],
                        data: arrayCurrentMS,
                        backgroundColor: [
                            'rgba('+json[keys[key_index]]['color']+', 0.2)'
                        ],
                        borderColor: [
                            'rgb('+json[keys[key_index]]['color']+')'
                        ],
                        borderWidth: 1
                    })
                }

                this.setState({ datasetMSPKm: current_MS });
                return json
            })
            .then((json) => {
                this.setState({ respModalPKM: json });
            })
    }

    fetchDetails(url){
        let coords = url.split(/[/]+/).pop()

        console.log(coords)

        const tyle_corner = {
            center: [coords.split(",")[0], coords.split(",")[1]],
            zoom: 14.5,
            pitch: 0,
            bearing: 0
        }
        this.props.map.flyTo({
            ...tyle_corner, // Fly to the selected target
            duration: 2000, // Animate over 2 seconds
            essential: false // This animation is considered essential with
            //respect to prefers-reduced-motion
        })
    }

    handleFetchDataExcel(param){
        fetch(URL + 'tile_od_agg/return_agg_table',
        {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({tile_list: param})
        })
        .then((res) => res.json())
        .then((json) => {
            let valuesCorrect = []

            for (var i = 0; i < json.tileId.length; i++) {
                valuesCorrect.push({
                    "tileId": json.tileId[i],
                    "modal_split_before": json['modal_split_before (april 2019- june 2019)'][i],
                    "modal_split_after": json['modal_split_after (april 2023- june 2023)'][i],
                    "modal_split_ratio": json['Modal Split Ratio (percentage)'][i],
                    "average_pkm_per_month_before": json['average pkm per month before (april 2019- june 2019)'][i],
                    "average_pkm_per_month_after": json['average pkm per month after (april 2023- june 2023)'][i],
                    "PKM difference": json['PKM difference'][i],
                    "total_pkm_last_12_months": json['total_pkm_last_12_months'][i],
                    "train_pkm_last_12_months": json['train_pkm_last_12_months'][i],
                    "car_pkm_last_12_months": json['car_pkm_last_12_months'][i],
                    "tile_url": json['tile_url'][i],
                    "PLZ_name": json['PLZ name'][i],
                    "Gemeinde name": json['Gemeinde name'][i],
                })
            }

            this.setState({excelData: valuesCorrect})
        })
    }

    handleGraphComponent() {
        this.setState({ showGraph: !this.state.showGraph });
    }

    render() {
        const { respModalPKM } = this.state;
        const { respModalTrips } = this.state;
        const { datasetMSPKm } = this.state;
        const { datasetMSTrips } = this.state;
        const { showGraph } = this.state;

        const dataBar_ModalPKM = {
            labels: respModalPKM[this.state.key_array[0]]?.date,
            datasets: datasetMSPKm
        };

        const dataBar_ModalTrips = {
            labels: respModalTrips[this.state.key_array[0]]?.date,
            datasets: datasetMSTrips
        }

        const options = {
            xAxes: [{
                type: 'time',
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 10
                }
            }],
            plugins: {
                title: {
                    display: true,
                    text: 'Percentage of Trains\' Modal Split (PKm) per Month'
                },
                legend: {
                    position: 'bottom',
                },
            },
        };

        const options_trips = {
            xAxes: [{
                type: 'time',
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 10
                }
            }],
            plugins: {
                title: {
                    display: true,
                    text: 'Percentage of Trains\' Modal Split (Trips) per Month'
                },
                legend: {
                    position: 'bottom',
                },
            },
        };

        const listTopOrigins = this.state.excelData?.map((data_point, index) =>
        <tr className="data" key={index} onClick={() => this.fetchDetails(data_point?.tile_url)}>
            <td className="table-data">{data_point?.PLZ_name} ~ {(data_point?.tileId)}</td>
            <td className="table-data">{numberWithCommas((data_point?.modal_split_before*100).toFixed(0))}</td>
            <td className="table-data">{numberWithCommas((data_point?.modal_split_after*100).toFixed(0))}</td>
            <td className="table-data">{(data_point?.modal_split_ratio).toFixed(0)}%</td>
            <td className="table-data">{numberWithCommas((data_point?.average_pkm_per_month_before).toFixed(0))}</td>
            <td className="table-data">{numberWithCommas((data_point?.average_pkm_per_month_after).toFixed(0))}</td>
        </tr>
    );

        return (
            <div>
                <div className='map-graph-button-tile-selector'>
                    <div className='map-graph-button-tile-selector-inner' onClick={() => this.handleGraphComponent("showGraph")}>
                        <IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
                            <div>
                                <VscGraphLine />
                            </div>
                        </IconContext.Provider>
                    </div>
                </div>
                {(showGraph && this.state.graphParam.length>0) ? <>
                    <div className="modal-graph-tile-selector">
                        <div className="modal-graph-tile-selector-inner">
                            <Tabs
                                defaultActiveKey="modalsplitpkm"
                                id="uncontrolled-tab-example"
                                className="mb-3"
                            >
                                <Tab eventKey="modalsplitpkm" title="Trains' Modal Split (PKm)">
                                    <Line data={dataBar_ModalPKM} options={options} />
                                </Tab>
                                <Tab eventKey="modalsplittrips" title="Trains' Modal Split (Trips)">
                                    <Line data={dataBar_ModalTrips} options={options_trips} />
                                </Tab>
                                <Tab eventKey="ExcelView" title="Ranking based on Modal Split">
								<div className="tile-selector-excel-inner">
									<h1>List of Top Origin Improving Tiles</h1>
                                    <table className='table-center-marketing'>
                                    <thead>
                        <tr className="title">
                            <th className="table-title-marketing">PLZ Name</th>
                            <th className="table-title-marketing">ModalSplit 2019</th>
                            <th className="table-title-marketing">ModalSplit 2023</th>
                            <th className="table-title-marketing">Modal Split Ratio</th>
                            <th className="table-title-marketing">Average PKm 2019</th>
                            <th className="table-title-marketing">Average PKm  2023</th>
                        </tr>
                    </thead>
                    <tbody>
							{listTopOrigins}
                        </tbody>
                                    </table>
								</div>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </> : <></>}
            </div>
        )
    }
}

class BtnsComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            mapa: this.props.map,
            generatedTyles: false,
            loading: false,
            //TODO
            array_selected_tiles: this.props.array_selected_tiles,
            min_distance_km: 0,
			max_distance_km: 400,
            n_trips: 0,
            value_od_dates: ({
				"value": "2022-08",
				"label": '2022-08'
			}),
			dates_trips_: this.getOptionsDate(URL + 'db_od_trips'),
            tilesIds: this.props.tilesIds
        }
    }

    async getOptionsDate(url) {
		const res = await axios.get(url)
		const data = res.data
		const options = data.map(d => ({
			"value": d._id.date.toString().split('T')[0].slice(0, -3),
			"label": d._id.date.toString().split('T')[0].slice(0, -3)
		}))
		this.setState({ dates_trips_: options });
	}

    distanceSliderHandler(event) {
		this.setState({ min_distance_km: event[0], max_distance_km: event[1] });
	}

    numTripsHandler(event) {
		this.setState({ n_trips: event });
	}

    componentDidMount() {
        this.resetView = this.resetView.bind(this);
        this.handleVisualization = this.handleVisualization.bind(this);
        this.distanceSliderHandler = this.distanceSliderHandler.bind(this);
        this.numTripsHandler = this.numTripsHandler.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.map !== prevProps.map) {
            this.setState({ mapa: this.props.map });
        }
        if (this.props.tilesIds !== prevProps.tilesIds) {
            this.setState({ tilesIds: this.props.tilesIds });
        }
    }

    

    handleVisualization() {        
        fetch(URL+'tile_od_agg/return_tile_ods', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 
                "tile_list": this.props.tilesIds,
                "date":this.state.value_od_dates.value, 
                "gt_n_trips": this.state.n_trips,
                "distance_range": [this.state.min_distance_km * 1000, this.state.max_distance_km * 1000]
            })
        })
        .then((res) => res.json())
    .then((json) => {
        this.state.mapa.getSource('data-swiss-destinations').setData(json);
        this.state.mapa.setLayoutProperty('swiss-destinations', 'visibility', 'none');
        this.state.mapa.getSource('point').setData(point_geojson);

    })
        .then(()=>{
            fetch(URL+'tile_od_agg/return_tile_geometries', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ 
                    "tile_list": this.props.tilesIds, 
                    "date":this.state.value_od_dates.value,
                    "gt_n_trips": this.state.n_trips,
                    "distance_range": [this.state.min_distance_km * 1000, this.state.max_distance_km * 1000]
                })
            })
            .then((res) => res.json())
        .then((json) => {
            this.state.mapa.getSource('data-swiss-tyle').setData(json);
            this.props.map.setLayoutProperty('data-swiss-tyle', 'visibility', 'visible');
			this.props.map.setLayoutProperty('outline-data-swiss-tyle', 'visibility', 'visible');
            this.props.map.setLayoutProperty('data-swiss-tyle-labels', 'visibility', 'visible');    

            this.setState({ generatedTyles: true })
            generatedTiles = true;

        }).then(() => this.props.updateFields({graphParam: this.props.tilesIds}))
        })

    }

    deleteElem(id_elem) {
		let elem = this.props.deleteElem(id_elem);

		//Lines - Routes
		//Add shapes to the map
		this.state.mapa.setFeatureState({
			source: 'data-circles-stations',
			id: elem.circle_map_id,
		}, {
			clicked: false
		});
		this.showRoutes();
	}

    handleHideDestination() {
		let temp = !hideDestination;
		hideDestination = temp;
		if (temp) {
			this.props.map.setLayoutProperty('data-swiss-tyle', 'visibility', 'none');
			this.props.map.setLayoutProperty('outline-data-swiss-tyle', 'visibility', 'none');
            this.props.map.setLayoutProperty('data-swiss-tyle-labels', 'visibility', 'none');
		}
		else {
			this.props.map.setLayoutProperty('data-swiss-tyle', 'visibility', 'visible');
			this.props.map.setLayoutProperty('outline-data-swiss-tyle', 'visibility', 'visible');
            this.props.map.setLayoutProperty('data-swiss-tyle-labels', 'visibility', 'visible');
		}
	}

	handleHideLines() {
		let temp = !hideLines;
		hideLines = temp;
		if (temp) {
			this.state.mapa.setLayoutProperty('swiss-destinations', 'visibility', 'none');
		}
		else {
			this.state.mapa.setLayoutProperty('swiss-destinations', 'visibility', 'visible');
		}
	}

    resetView() {

        this.state.mapa.getSource('data-swiss-destinations').setData(lines_geojson);
		this.state.mapa.getSource('data-swiss-origin').setData(lines_geojson);
		this.state.mapa.getSource('data-swiss-tyle').setData(lines_geojson);

		this.props.map.setLayoutProperty('data-swiss-tyle', 'visibility', 'none');
		this.props.map.setLayoutProperty('outline-data-swiss-tyle', 'visibility', 'none');
        this.props.map.setLayoutProperty('data-swiss-tyle-labels', 'visibility', 'none');
        this.state.mapa.setLayoutProperty('swiss-destinations', 'visibility', 'none');


		this.props.updateFields({ graphParam: [], tilesIds: [], array_selected_tiles: [] })
		this.setState({ generatedTyles: false })
        generatedTiles = false;

        const tyle_corner = {
            center: [8.251313, 46.924143],
            zoom: 7,
            pitch: 0,
            bearing: 0
        }

        this.props.map.flyTo({
            ...tyle_corner, // Fly to the selected target
            duration: 2000, // Animate over 2 seconds
            essential: false // This animation is considered essential with
            //respect to prefers-reduced-motion
        })
    }

    handleMonthYear(event) {
		this.setState({ value_od_dates: event });
	}

    render() {
        const { loading } = this.state;
        const { generatedTyles } = this.state;
		const { value_od_dates } = this.state;

        const selectDate = {
			menuList: styles => {
				return {
					...styles,
					maxHeight: 150,
					borderWidth: 0,
					boxShadow: '0 0 10px 2px rgb(0 0 0 / 10%)',
				};
			}
		};

        if (loading) { // if your component doesn't have to wait for an async action, remove this block 
            return (
                <div className="bg-loader">
                    <div className="pos-center-brain-loader">
                        <div className="circle-loading-brain">
                        </div>
                        <svg className='brain-loader' x="0px" y="0px" viewBox="0 0 496 496">
                            <path d="M40,449.472V364.28L58.424,352H88v-16H53.576L24,355.72v93.752C14.704,452.784,8,461.584,8,472c0,13.232,10.768,24,24,24				s24-10.768,24-24C56,461.584,49.288,452.784,40,449.472z M32,480c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8s8,3.584,8,8				C40,476.416,36.416,480,32,480z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M46.528,320H88v-16H46.528c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24				C34.416,336,43.216,329.288,46.528,320z M16,312c0-4.416,3.584-8,8-8s8,3.584,8,8c0,4.416-3.584,8-8,8S16,316.416,16,312z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M24,272c10.416,0,19.216-6.712,22.528-16H88v-16H46.528c-3.312-9.296-12.112-16-22.528-16c-13.232,0-24,10.768-24,24				S10.768,272,24,272z M24,240c4.416,0,8,3.584,8,8s-3.584,8-8,8s-8-3.584-8-8C16,243.584,19.584,240,24,240z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M56 272h32v16H56Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M56 208h32v16H56Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M0 176h88v16H0Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M60.688,160H88v-16H67.312L48,124.688v-14.16C57.288,107.216,64,98.416,64,88c0-13.232-10.768-24-24-24S16,74.768,16,88				c0,10.416,6.704,19.216,16,22.528v20.784L60.688,160z M40,80c4.416,0,8,3.584,8,8s-3.584,8-8,8s-8-3.584-8-8S35.584,80,40,80z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M144 67.312L144 88L160 88L160 60.688L131.312 32L0 32L0 48L124.688 48z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M176 35.312L176 88L192 88L192 28.688L163.312 0L0 0L0 16L156.688 16z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M208 56h16v32H208Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M304 0h16v88H304Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M240,46.528V88h16V59.312l16,16V88h16V68.688L262.392,43.08C268.192,38.696,272,31.816,272,24c0-13.232-10.768-24-24-24				s-24,10.768-24,24C224,34.416,230.704,43.216,240,46.528z M248,16c4.416,0,8,3.584,8,8s-3.584,8-8,8s-8-3.584-8-8				S243.584,16,248,16z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M352,51.312L371.312,32h78.16c2.416,6.776,7.752,12.112,14.528,14.528v110.16L444.688,176H408v16h43.312L480,163.312				V46.528c9.288-3.312,16-12.112,16-22.528c0-13.232-10.768-24-24-24c-10.416,0-19.216,6.712-22.528,16h-84.784L336,44.688V88h16				V51.312z M472,16c4.416,0,8,3.584,8,8s-3.584,8-8,8c-4.416,0-8-3.584-8-8S467.584,16,472,16z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M414.08,97.392l17.92,17.92v9.376L412.688,144H408v16h11.312L448,131.312v-22.624L419.312,80h-4.784				c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24C401.904,112,410.416,105.968,414.08,97.392z				 M392,96c-4.416,0-8-3.584-8-8s3.584-8,8-8c4.416,0,8,3.584,8,8S396.416,96,392,96z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M408 208h88v16H408Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M480,449.472V268.688L451.312,240H408v16h36.688L464,275.312v41.376L451.312,304H408v16h36.688L464,339.312v110.16				c-9.296,3.312-16,12.112-16,22.528c0,13.232,10.768,24,24,24s24-10.768,24-24C496,461.584,489.288,452.784,480,449.472z M472,480				c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8c4.416,0,8,3.584,8,8C480,476.416,476.416,480,472,480z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M408 272h32v16H408Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M448,364.688L419.312,336H408v16h4.688L432,371.312V416h-80v-8h-16v24h96v12.688L412.688,464h-14.16				c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24c10.416,0,19.216-6.712,22.528-16h20.784				L448,451.312V364.688z M376,480c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8c4.416,0,8,3.584,8,8C384,476.416,380.416,480,376,480z				" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M160,408h-16v8h-41.472c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24				c10.416,0,19.216-6.712,22.528-16H160V408z M80,432c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8s8,3.584,8,8				C88,428.416,84.416,432,80,432z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M304 408h16v88H304Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M272 408h16v88H272Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M240,420.688l-16,16V408h-16v41.472c-9.296,3.312-16,12.112-16,22.528c0,13.232,10.768,24,24,24s24-10.768,24-24				c0-7.816-3.808-14.696-9.608-19.08L256,427.312V408h-16V420.688z M216,480c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8s8,3.584,8,8				C224,476.416,220.416,480,216,480z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M192 408L176 408L176 428.688L152 452.688L152 496L168 496L168 459.312L192 435.312z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M336,392h16v-16h24v-24h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16				v-16h-16v-24h-24v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-3.312				L120,140.688V144h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v24h24v16h16v-16h16				v16h16v-16h16v16h16v-16h16v16h16v-16h16v16h16v-16h16v16h16v-16h16V392z M136,360V147.312L147.312,136H360v224H136z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M318.888,211.048l13.96-13.96l-33.936-33.936l-13.96,13.96c-4.16-2.184-8.488-3.976-12.952-5.384V152h-48v19.728				c-4.472,1.408-8.8,3.2-12.952,5.384l-13.96-13.96l-33.936,33.936l13.96,13.96c-2.176,4.16-3.976,8.488-5.384,12.952H152v48				h19.728c1.408,4.472,3.2,8.8,5.384,12.952l-13.96,13.96l33.936,33.936l13.96-13.96c4.16,2.176,8.48,3.976,12.952,5.384V344h48				v-19.728c4.472-1.408,8.8-3.2,12.952-5.384l13.96,13.96l33.936-33.936l-13.96-13.96c2.176-4.152,3.976-8.48,5.384-12.952H344v-48				h-19.728C322.864,219.528,321.072,215.2,318.888,211.048z M328,256h-16.224l-1.416,6.224c-1.6,7.016-4.368,13.672-8.216,19.792				l-3.408,5.408l11.496,11.496l-11.312,11.312l-11.496-11.496l-5.408,3.408c-6.12,3.848-12.776,6.616-19.792,8.216L256,311.776V328				h-16v-16.224l-6.224-1.416c-7.016-1.6-13.672-4.368-19.792-8.216l-5.408-3.408l-11.496,11.496l-11.312-11.312l11.496-11.496				l-3.408-5.408c-3.848-6.12-6.616-12.776-8.216-19.792L184.224,256H168v-16h16.224l1.416-6.224				c1.6-7.016,4.368-13.672,8.216-19.792l3.408-5.408l-11.496-11.496l11.312-11.312l11.496,11.488l5.408-3.408				c6.12-3.848,12.776-6.616,19.792-8.216l6.224-1.408V168h16v16.224l6.224,1.416c7.016,1.6,13.672,4.368,19.792,8.216l5.408,3.408				l11.496-11.488l11.312,11.312l-11.496,11.496l3.408,5.408c3.848,6.12,6.616,12.776,8.216,19.792l1.416,6.216H328V256z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M248,208c-22.056,0-40,17.944-40,40c0,22.056,17.944,40,40,40c22.056,0,40-17.944,40-40C288,225.944,270.056,208,248,208				z M248,272c-13.232,0-24-10.768-24-24s10.768-24,24-24s24,10.768,24,24S261.232,272,248,272z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div>
                    <div className="map-month-year-picker">
						<Select className='dates' options={this.state.dates_trips_} onChange={this.handleMonthYear.bind(this)} styles={selectDate} defaultValue={value_od_dates} />
					</div>
                    <div className="map-btn-drop-point-ov-quality">
                        <div className="map-btn-drop-point-ov-quality-inner">
                            <h1>Tiles Selector</h1>
                            <p className="label">Click on a location and select the tile that encapsules this place. Add up to 6 different tiles to enable a comparision between each of them: Modal Split based on PKm and number of trips + Modal Split win based on historical data.</p>
                            <div className="row">
                                <div className="column-1-50">
                                    <Form onClick={this.handleVisualization.bind(this)}>
                                        <Button variant="info" disabled={generatedTyles} className="generate-btn">Evaluate tiles</Button>
                                    </Form>
                                </div>
                                <div className="column-2-50">
                                    <Form onClick={this.resetView.bind(this)}>
                                        <Button variant="info" disabled={!generatedTyles} className="reset-btn">Reset tiles</Button>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-btn-tile-selector-filters">
                        <div className="modal-btn-tile-selector-filters-inner">
                        <h1>Filter by distance in Km</h1>
								<div className="slider-distance">
									<Slider range min={0} max={400} step={0.5} defaultValue={[this.state.min_distance_km, this.state.max_distance_km]} onChange={_.debounce(this.distanceSliderHandler.bind(this), 33)} disabled={!this.state.generatedTyles}/>
								</div>
								<p className='label'>Show trips between {this.state.min_distance_km}km and {this.state.max_distance_km}km</p>
                                <div className="row">
									<div className="column-1-40">
										<Form onChange={this.handleHideLines.bind(this)}>
											<Form.Check
												disabled={!this.state.generatedTyles}
												type="switch"
												id="custom-switch"
												label="Show line routes"
											/>
										</Form>
									</div>
									<div className="column-2-60">
										<Form onChange={this.handleHideDestination.bind(this)}>
											<Form.Check
												disabled={!this.state.generatedTyles}
												type="switch"
												id="custom-switch"
												label="Show tiles destination"
												defaultChecked
											/>
										</Form>
									</div>
								</div>
                                <h1>Filter by number of trips <br /> within a tyle</h1>
								<div className="slider-distance">
									<Slider min={0} max={60} step={1} defaultValue={this.state.n_trips} onChange={_.debounce(this.numTripsHandler.bind(this), 33)} disabled={!this.state.generatedTyles} />
								</div>
                                <p className='label'>Filter tiles that have more than  <br /> {this.state.n_trips} trips per day</p>
                        </div>
                    </div>
                    {/* <div className="drop-point-hotspot-params">
                        <div className="drop-point-hotspot-params-inner">
                            <h1>Current selected tiles</h1>
                            {hasResponse ? <>
                                <div className="fixed-box-modal">
                                    <div className="timeline" >
                                        <div>
                                            {this.state.array_selected_tiles.map(selected_tiles => (
                                                <div className="container right" key={selected_tiles.stop_id}>
                                                    <div className="content">
                                                        <div className="row">
                                                            <div className="column-1">
                                                                <p>{selected_tiles['Ortschaftsname']}</p>
                                                            </div>
                                                            <div className="column-2">
                                                                <div className="deleteElement" onClick={() => this.deleteElem(selected_tiles.tileId)}>
                                                                    <IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
                                                                        <div>
                                                                            <IoTrashOutline />
                                                                        </div>
                                                                    </IconContext.Provider>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </> : <>
                                <p className="no-data">Please select at least two (2) tiles and click on <i>Evaluate</i></p>
                            </>}
                        </div>
                    </div> */}
                </div>
            )
        }
    }
}

class MappTileSelector extends React.Component {

    // Set up states for updating map 
    constructor(props) {
        super(props);
        this.state = {
            lng: 8.251313,
            lat: 46.924143,
            zoom: 7,
            mapita: 9,
            tyle: 'mapbox://styles/mapbox/satellite-streets-v11',
            hideNavs: false,
            graphParam: [],
            array_selected_tiles: [], 
            tilesIds: []
        }
    }

    //Update several fields
    updateFields(list) {
        this.setState(list);
    }

    //Update map on component update
    updateField(card, value) {
        this.setState({
            ...this.state,
            [card]: value
        });
    }

    //Update style
    handleTyle(value) {
        this.setState({ tyle: value }, this.componentDidMount);
    }

    //Update coordinates
    handleCoord(center, zoom) {
        const { lng, lat } = center;
        this.setState({ zoom: zoom, lat: lat, lng: lng })
    }


	//Delete element on the array of selected tiles
	deleteElem(id_elem) {

		const target = this.state.array_selected_tiles.find(e => e.stop_id === id_elem);
		const index = this.state.array_selected_tiles.indexOf(target);
		if (index > -1) { // only splice array when item is found
			let array_without_elem = this.state.array_selected_tiles
			array_without_elem.splice(index, 1) // 2nd parameter means remove one item only
			this.setState({ array_selected_tiles: array_without_elem });
		}
		return target;
	}

    //Update coordinates
    handleCoordMoveToTyle(lng, lat, zoom) {
        this.setState({ zoom: zoom, lat: lat, lng: lng })
    }

    componentDidMount() {
        // Set up map 
        const map = new mapboxgl.Map({
            container: this.mapContainer,
            style: this.state.tyle,
            center: [this.state.lng, this.state.lat],
            zoom: this.state.zoom,
        });

        map.on('load', () => {

            // Add zoom and rotation controls to the map.
            map.addControl(new mapboxgl.NavigationControl({ showCompass: false, showZoom: false }), 'top-left');
            map.addControl(new MapboxGeocoder({ accessToken: mapboxgl.accessToken, mapboxgl: mapboxgl, marker: false }), 'top-left');

            // Add circle 
            map.addSource('point', {
                'type': 'geojson',
                'data': point_geojson
            });

            map.addLayer({
                'id': 'point',
                'type': 'circle',
                'source': 'point',
                'paint': {
                    'circle-radius': 10,
                    'circle-color': '#e32a8d' // blue color
                }
            });

            // add lines to the map
			map.addSource('data-swiss-destinations', {
				'type': 'geojson',
				'data': lines_geojson,
				'generateId': true,
				'lineMetrics': true
			});

			map.addLayer({
				'id': 'swiss-destinations',
				'type': 'line',
				'source': 'data-swiss-destinations',
				'layout': {
					// Make the layer visible by default.
					'visibility': 'visible'
				},
				'paint': {
					'line-color': '#027f80',
					'line-width': 2,
				}
			});

            //Tyle visualization
			//Add shapes to the map
			map.addSource('data-swiss-tyle', {
				'type': 'geojson',
				'data': lines_geojson,
				'generateId': true
			});

			map.addLayer({
				'id': 'data-swiss-tyle',
				'type': 'fill',
				'source': 'data-swiss-tyle',
				'layout': {},
				'paint': {
					'fill-color': '#53b8ae',
					'fill-opacity': 0.4
				}
			});

			// Add a black outline around the polygon
			map.addLayer({
				'id': 'outline-data-swiss-tyle',
				'type': 'line',
				'source': 'data-swiss-tyle',
				'layout': {},
				'paint': {
					'line-color': '#53b8ae',
					'line-width': 1
				}
			});

			map.addLayer({
				'id': 'data-swiss-tyle-labels',
				'type': 'symbol',
				'source': 'data-swiss-tyle',
				"minzoom": 11,
				'layout': {
					'text-field': ['get', 'total_trips_weekdays_tiles'],
					'text-font': [
						'Open Sans Bold',
						'Arial Unicode MS Bold'
					],
					'text-size': 15,
					'text-transform': 'uppercase',
					'text-letter-spacing': 0.05,
					'text-offset': [0, 1],
					'visibility': 'visible'
				}
			});

                        //Add shapes to the map - origins
                        map.addSource('data-swiss-origin', {
                            'type': 'geojson',
                            'data': tile_1km_geojson,
                            'generateId': true
                        });
            
                        map.addLayer({
                            'id': 'data-swiss-origin',
                            'type': 'fill',
                            'source': 'data-swiss-origin',
                            'layout': {
                                'visibility': 'visible'
                            },
                            'paint': {
                                'fill-color': ['get', 'color'],
                                'fill-opacity': 0.15
                            }
                        });
            
                        // Add a black outline around the polygon
                        map.addLayer({
                            'id': 'outline-data-swiss-origin',
                            'type': 'line',
                            'source': 'data-swiss-origin',
                            'layout': {
                                'visibility': 'visible'
                            },
                            'paint': {
                                'line-color': ['get', 'color'],
                                'line-width': 1
                            }
                        });


		});

        this.setState({
            mapita: map,
            zoom: map.getZoom(),
            center: map.getCenter()
        });

        map.on('click', 'data-swiss-tyle', (e) => {
            let num_passengers_car_wd = e.features[0].properties.car_trips_weekdays_tiles;
			let num_passengers_car_we = e.features[0].properties.car_trips_weekends_tiles;
			let num_passengers_train_wd = e.features[0].properties.train_trips_weekdays_tiles;
			let num_passengers_train_we = e.features[0].properties.train_trips_weekends_tiles;
			let num_passengers_we_total = e.features[0].properties.total_trips_weekends_tiles;
			let num_passengers_wd_total = e.features[0].properties.total_trips_weekdays_tiles;

			let num_passengers_car_wd_str = '';
			let num_passengers_car_we_str = '';
			let num_passengers_train_wd_str = '';
			let num_passengers_train_we_str = '';
			let num_passengers_we_total_str = '';
			let num_passengers_wd_total_str = '';

			(typeof num_passengers_car_wd == 'number' & num_passengers_car_wd > 0) ? num_passengers_car_wd = (num_passengers_car_wd).toFixed(0) : num_passengers_car_wd = '-';
			(num_passengers_car_wd !== '-') ? num_passengers_car_wd_str = numberWithCommas(num_passengers_car_wd) : num_passengers_car_wd_str = '-';

			(typeof num_passengers_car_we == 'number' & num_passengers_car_we > 0) ? num_passengers_car_we = (num_passengers_car_we).toFixed(0) : num_passengers_car_we = '-';
			(num_passengers_car_we !== '-') ? num_passengers_car_we_str = numberWithCommas(num_passengers_car_we) : num_passengers_car_we_str = '-';

			(typeof num_passengers_train_wd == 'number' & num_passengers_train_wd > 0) ? num_passengers_train_wd = (num_passengers_train_wd).toFixed(0) : num_passengers_train_wd = '-';
			(num_passengers_train_wd !== '-') ? num_passengers_train_wd_str = numberWithCommas(num_passengers_train_wd) : num_passengers_train_wd_str = '-';

			(typeof num_passengers_train_we == 'number' & num_passengers_train_we > 0) ? num_passengers_train_we = (num_passengers_train_we).toFixed(0) : num_passengers_train_we = '-';
			(num_passengers_train_we !== '-') ? num_passengers_train_we_str = numberWithCommas(num_passengers_train_we) : num_passengers_train_we_str = '-';

			(typeof num_passengers_wd_total == 'number' & num_passengers_wd_total > 0) ? num_passengers_wd_total = (num_passengers_wd_total).toFixed(0) : num_passengers_wd_total = '-';
			if (num_passengers_wd_total < (Number(num_passengers_train_wd) + Number(num_passengers_car_wd)) & num_passengers_wd_total !== '-') {
				num_passengers_wd_total = Number(num_passengers_train_wd) + Number(num_passengers_car_wd);
			}
			(num_passengers_wd_total !== '-') ? num_passengers_wd_total_str = numberWithCommas(num_passengers_wd_total) : num_passengers_wd_total_str = '-';

			(typeof num_passengers_we_total == 'number' & num_passengers_we_total > 0) ? num_passengers_we_total = (num_passengers_we_total).toFixed(0) : num_passengers_we_total = '-';
			(num_passengers_we_total !== '-') ? num_passengers_we_total_str = numberWithCommas(num_passengers_we_total) : num_passengers_we_total_str = '-';

			let html = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Passengers per day</span></p><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle;"><thead><tr><th></th><th>&#x1f46a; &#128664;</th><th>&#x1f46a; &#x1f686;</th><th>Total</th></tr></thead><tbody><tr><th>Weekdays</th><td>' + num_passengers_car_wd_str + '</td><td>' + num_passengers_train_wd_str + '</td><td>' + num_passengers_wd_total_str + '</td></tr><tr><th>Weekends</th><td>' + num_passengers_car_we_str + '</td><td>' + num_passengers_train_we_str + '</td><td>' + num_passengers_we_total_str + '</td></tr></tbody></table>';
			new mapboxgl.Popup()
				.setLngLat(e.lngLat)
				.setHTML(html).addTo(map);
        });

        //On click move the point
        map.on('click', (event) => {
            const coords = event.lngLat;
            this.setState({ coords: coords })
            
            if (!(generatedTiles) && this.state.array_selected_tiles?.length < 7) 
            {
                // Print the coordinates of where the point had
                // finished being dragged to on the map.

                map.getCanvas().style.cursor = '';

                // Unbind mouse/touch events
                map.off('mousemove', onMove);
                map.off('touchmove', onMove);

                point_geojson.features[0].geometry.coordinates = [coords.lng, coords.lat];

                let current_coordinates = this.state.array_selected_tiles
                current_coordinates.push([coords.lng, coords.lat])

                fetch(URL+'tile_od_agg/coordinates_to_tiles', {
                    method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({ coordinates: current_coordinates })
                })
                .then((res) => res.json())
			.then((json) => {
                map.getSource('data-swiss-origin').setData(json);
                map.getSource('point').setData(point_geojson);

                const currentTilesIds = json.features.map(d => (
                    d.properties.tileId
                ))

                this.setState({tilesIds: currentTilesIds})
            })
                .then(()=>{
                    console.log(current_coordinates)
                    this.setState({array_selected_tiles: current_coordinates})
                })

            }
        });

        // When the cursor enters a feature in
        // the point layer, prepare for dragging.
        map.on('mouseenter', 'point', () => {
            map.setPaintProperty('point', 'circle-color', '#9e2e6a');
            map.getCanvas().style.cursor = 'move';
        });

        map.on('mouseleave', 'point', () => {
            map.setPaintProperty('point', 'circle-color', '#9e2e6a');
            map.getCanvas().style.cursor = '';
        });

        function onMove(e) {
            const coords = e.lngLat;
            // Set a UI indicator for dragging.
            map.getCanvas().style.cursor = 'grabbing';

            // Update the Point feature in `geojson` coordinates
            // and call setData to the source layer `point` on it.
            point_geojson.features[0].geometry.coordinates = [coords.lng, coords.lat];
            map.getSource('point').setData(point_geojson);
        }

        function onUp(e) {
            const coords = e.lngLat;
            // Print the coordinates of where the point had
            // finished being dragged to on the map.

            map.getCanvas().style.cursor = '';

            // Unbind mouse/touch events
            map.off('mousemove', onMove);
            map.off('touchmove', onMove);

            point_geojson.features[0].geometry.coordinates = [coords.lng, coords.lat];
            map.getSource('point').setData(point_geojson);
        }

        map.on('mousedown', 'point', (e) => {
            // Prevent the default map drag behavior.
            e.preventDefault();

            map.getCanvas().style.cursor = 'grab';

            map.on('mousemove', onMove);
            map.once('mouseup', onUp);
        });

        map.on('touchstart', 'point', (e) => {
            if (e.points.length !== 1) return;

            // Prevent the default map drag behavior.
            e.preventDefault();

            map.on('touchmove', onMove);
            map.once('touchend', onUp);
        });
    }

    render() {
        const { hideNavs } = this.state;

        return (
            <div>
                <div ref={el => this.mapContainer = el} style={{ width: '100%', height: '100vh' }}>
                </div>
                <RecommendationNavBarComponent />
                <HideElementsComponent map={this.state.mapita} updateField={(card, field, value) => this.updateField(card, field, value)} />
                <div style={{ display: hideNavs ? 'none' : 'block' }}>
                        <GraphComponent map={this.state.mapita} graphParam={this.state.graphParam} />
                        <BtnsComponent map={this.state.mapita} updateField={(field, value) => this.updateField(field, value)} updateFields={(list) => this.updateFields(list)} handleCoordMoveToTyle={(value1, value2, value3) => this.handleCoordMoveToTyle(value1, value2, value3)} array_selected_tiles ={this.state.array_selected_tiles} tilesIds={this.state.tilesIds}/>
                    </div>
                <LayerTylesComponent map={this.state.mapita} tyle={this.state.tyle} handleTyle={(value) => this.handleTyle(value)} handleCoord={(value1, value2) => this.handleCoord(value1, value2)} />
            </div>
        )
    }
}

export default MappTileSelector; 