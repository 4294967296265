import React from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '../css/mapbox-gl-geocoder.css';
import '../css/map-cars.css';
import RecommendationNavBarComponent from "../../components/RecommendationNavBarComponent";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const URL = process.env.REACT_APP_URL_SERVER_BACK;


class MappUtilizationRail extends React.Component{

	// Set up states for updating map 
	constructor(props){
		super(props);
		this.state = {
			lng:8.251313,
			lat: 46.924143,
			zoom: 7,
			mapita:9,
		}
	}

	componentDidMount() {

		// Set up map 
		const map = new mapboxgl.Map({
			container: this.mapContainer,
			style: 'mapbox://styles/mapbox/light-v10',
			center: [this.state.lng, this.state.lat],
			zoom: this.state.zoom, 
		});
		map.on('load', () => {

			// Add zoom and rotation controls to the map.
			map.addControl(new mapboxgl.NavigationControl({showCompass: false, showZoom: false}), 'top-left');
			map.addControl( new MapboxGeocoder({ accessToken: mapboxgl.accessToken, mapboxgl: mapboxgl, marker: false}), 'top-left' );

			// add lines to the map
			map.addSource('data-swiss-utilization-rail', {
				'type': 'geojson',
				'data': URL+"rail-utilization",
				'generateId': true,
				'lineMetrics': true
			});
			map.addLayer({
				'id': 'swiss-utilization-rail',
				'type': 'line',
				'source': 'data-swiss-utilization-rail',
				'layout': {},
				'paint': {
				'line-color': ['get', 'color'],
				'line-width': 3,
				// 'line-gradient' must be specified using an expression
				// with the special 'line-progress' property
				}
				});
			
		});

		this.setState({
			mapita: map,
		  }, () => console.log(this.state));

			
	// When a click event occurs on a feature in the places layer, open a popup at the
	// location of the feature, with description HTML from its properties.
	map.on('click', 'swiss-utilization-rail', (e) => {
		// Copy coordinates array.
		const coordinates = e.features[0].geometry.coordinates.slice();
		//console.log(this.state.kantons.includes('value: '+e));
		// Ensure that if the map is zoomed out such that multiple
		// copies of the feature are visible, the popup appears
		// over the copy being pointed to.
		  while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
		  coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
		  }

			let num_counts = (e.features[0].properties.counts/1).toFixed(0);

				new mapboxgl.Popup()
		  		.setLngLat(e.lngLat)
		  		.setHTML('<p><span style="font-weight: bold;">Number of passsengers per day: </span>'+num_counts+'</p>')
		  		.addTo(map);

		});
		
		// Change the cursor to a pointer when the mouse is over the places layer.
		map.on('mouseenter', 'swiss-utilization-rail', () => {
		map.getCanvas().style.cursor = 'pointer';
		});
		
		// Change it back to a pointer when it leaves.
		map.on('mouseleave', 'swiss-utilization-rail', () => {
		map.getCanvas().style.cursor = '';
		});
				
		// Fullscreen map 
		//map.addControl(new mapboxgl.FullscreenControl());

	}

	render(){
		return(
			<div>
				<div ref={el => this.mapContainer = el} style={{width:'100%',height:'100vh'}}>
				</div>
				<RecommendationNavBarComponent/>
			</div>
		)
	}
}

export default MappUtilizationRail;
