import React from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '../../css/map-drop-point.css';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';
import Chart from 'chart.js/auto';
import { IconContext } from "react-icons";
import { BsLayersHalf } from "react-icons/bs";
import Select from 'react-select';
import AustriaRecommendationNavBarComponent from "../../../components/AustriaRecommendationNavBarComponent";
import Slider from 'rc-slider';
import { useParams } from "react-router-dom";
import { RiFullscreenFill, RiFullscreenExitFill } from "react-icons/ri";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ExportExcel from "../../../utils/ExportExcel";
import { Line } from 'react-chartjs-2';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const URL = process.env.REACT_APP_URL_SERVER_BACK;

let no_data_modal = false;
let hideLines = false;
let hideCircles = false;
let hideDestination = false;
let color_1km = 'color'

const _ = require('lodash');

let generated_info = false;
function numberWithCommas(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
}

const lines_geojson = {
	'type': 'FeatureCollection',
	'features': [
		{
			'type': 'Feature',
			'geometry': {
				"type": "LineString",
				"coordinates": [
				]
			}
		}
	]
};

const point_geojson = {
	'type': 'FeatureCollection',
	'features': [
		{
			'type': 'Feature',
			'geometry': {
				'type': 'Point',
				'coordinates': [15.412271347574572, 47.01177684340048]
			}
		}
	]
};

class HideElementsComponent extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			hideNavs: false,
			mapa: this.props.map,
		}
	}

	hideComponent() {
		let newHideNavs = !this.state.hideNavs;
		this.setState({ hideNavs: newHideNavs })
		this.props.updateField("hideNavs", newHideNavs);
	}

	render() {

		const { hideNavs } = this.state;

		return (
			<div>
				{hideNavs ? <>
					<div className='map-close-navs'>
						<div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
								<div>
									<RiFullscreenExitFill />
								</div>
							</IconContext.Provider>
						</div>
					</div>
				</> : <>
					<div className='map-close-navs'>
						<div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
								<div>
									<RiFullscreenFill />
								</div>
							</IconContext.Provider>
						</div>
					</div>
				</>}
			</div>
		)
	}
}

//Layer Tyles Selection - Side Menu
class LayerTylesComponent extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			showOptions: false,
			mapa: this.props.map,
		}
		this.hideComponent = this.hideComponent.bind(this);
		this.handleTyleEvent = this.handleTyleEvent.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (this.props.map !== prevProps.map) {
			this.setState({ mapa: this.props.map })
		}
	}

	hideComponent(name) {
		this.setState({ showOptions: !this.state.showOptions });
	}

	handleTyleEvent(event) {
		switch (event) {
			case "default":
				this.props.handleTyle("mapbox://styles/mapbox/light-v10");
				break;
			case "outdoors":
				this.props.handleTyle("mapbox://styles/mapbox/outdoors-v11");
				break;
			case "satellite":
				this.props.handleTyle("mapbox://styles/mapbox/satellite-streets-v11");
				break;
			case "streets":
				this.props.handleTyle("mapbox://styles/mapbox/streets-v11");
				break;
			case "dark":
				this.props.handleTyle("mapbox://styles/mapbox/dark-v10");
				break;
			default:
				// eslint-disable-next-line no-unused-expressions
				null;
		}
		this.props.handleCoord(this.state.mapa.getCenter(), this.state.mapa.getZoom());
	}

	render() {
		const { showOptions } = this.state;

		return (
			<div>
				{showOptions ? <>
					<div id='layer-options' className='map-layer-options-tyles'>
						<div className='map-layer-option-dark' onClick={() => this.handleTyleEvent("dark")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../../assets/img/Dark.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Dark</p>
						</div>
						<div className='map-layer-option-streets' onClick={() => this.handleTyleEvent("streets")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../../assets/img/Streets.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Streets</p>
						</div>
						<div className='map-layer-option-satellite' onClick={() => this.handleTyleEvent("satellite")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../../assets/img/Satellite.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Satellite</p>
						</div>
						<div className='map-layer-option-outdoors' onClick={() => this.handleTyleEvent("outdoors")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../../assets/img/Outdoors.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Outdoors</p>
						</div>
						<div className='map-layer-option-default' onClick={() => this.handleTyleEvent("default")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../../assets/img/White.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Default</p>
						</div>
					</div>
				</> : <></>}
				<div className='map-layer-tyles'>
					<div className='map-layer-tyles-inner' onClick={() => this.hideComponent("showOptions")}>
						<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
							<div>
								<BsLayersHalf />
							</div>
						</IconContext.Provider>
					</div>
				</div>
			</div>
		)
	}
}

class BtnsComponent extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			mapa: this.props.map,
			generatedTyles: false,
			loading: false,
			selection_distribution: [true, false, false, false],
			selectedPKm: 'trips',

			dates_trips_: [{
				"value": '2023-09-18',
				"label": '2023-09-18'
			},
			{
				"value": '2023-09-19',
				"label": '2023-09-19'
			},
			{
				"value": '2023-09-20',
				"label": '2023-09-20'
			},
			{
				"value": '2023-09-21',
				"label": '2023-09-21'
			},
			{
				"value": '2023-09-22',
				"label": '2023-09-22'
			},
			{
				"value": '2023-09-23',
				"label": '2023-09-23'
			},
			{
				"value": '2023-09-24',
				"label": '2023-09-24'
			}],

			value_od_dates: ({
				"value": '2023-09-18',
				"label": '2023-09-18'
			}),


			min_distance_km: 0,
			max_distance_km: 525,
			percentage: 100,
			percentage_type: 'trips',
			n_trips: 30,
			coord: this.props.coord,
			min_distance_to_train: 0,
			max_distance_to_train: 35,
			total_pkm: 0,
			current_pkm: 0,
			total_trips: 0,
			current_trips: 0,
			percentage_pkm: 100,
			percentage_trips: 100,

			green_pkm: 0,
			green_trips: 0,
			red_pkm: 0,
			red_trips: 0,
			yellow_pkm: 0,
			yellow_trips: 0,
			percentage_pkm_red: 0,
			percentage_pkm_yellow: 0,
			percentage_pkm_green: 0,
			percentage_trips_green: 0,
			percentage_trips_yellow: 0,
			percentage_trips_red: 0,
			current_percentage_pkm_red: 0,
			current_percentage_pkm_yellow: 0,
			current_percentage_pkm_green: 0,
			current_percentage_trips_green: 0,
			current_percentage_trips_yellow: 0,
			current_percentage_trips_red: 0,

			percentage_pkm_statusquo_green: 0,
			percentage_pkm_statusquo_yellow: 0,
			percentage_pkm_statusquo_red: 0,

			percentage_trips_statusquo_green: 0,
			percentage_trips_statusquo_yellow: 0,
			percentage_trips_statusquo_red: 0,
			geometries: []
		}
		this.handleFetchDataParam()
	}

	handleFetchDataParam() {
		if (this.props.coord['*'] !== "") {
			this.setState({ loading: true });
			this.setState({ generatedTyles: true });

			this.demoAsyncCall(this.state.value_od_dates.value, parseFloat(this.props.coord['*'].split(',')[0]), parseFloat(this.props.coord['*'].split(',')[1]), this.state.min_distance_km, this.state.max_distance_km, this.state.n_trips, Number(this.props.coord['*'].split(',')[4]), Number(this.props.coord['*'].split(',')[5]), this.props.coord['*'].split(',')[6], [true, false, false, false]);
			this.setState({ selectedPKm: this.props.coord['*'].split(',')[6], min_distance_to_train: Number(this.props.coord['*'].split(',')[4]), max_distance_to_train: Number(this.props.coord['*'].split(',')[5]) });

			generated_info = true;
			hideLines = false;
			hideCircles = false;
			hideDestination = false;

			point_geojson.features[0].geometry.coordinates = [parseFloat(this.props.coord['*'].split(',')[0]), parseFloat(this.props.coord['*'].split(',')[1])];
			//this.props.map.getSource('point').setData(point_geojson);
		}
	}

	componentDidMount() {
		this.resetView = this.resetView.bind(this);
		this.distanceSliderHandler = this.distanceSliderHandler.bind(this);
		this.handleHideLines = this.handleHideLines.bind(this);
		this.handleSquareDestinations = this.handleSquareDestinations.bind(this);
		this.handleHideCircles = this.handleHideCircles.bind(this);
		this.percentageSliderHandler = this.percentageSliderHandler.bind(this);
		this.numTripsHandler = this.numTripsHandler.bind(this);
		this.calculateBtnHandler = this.calculateBtnHandler.bind(this);
		this.distanceToTrainHandler = this.distanceToTrainHandler.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (this.props.map !== prevProps.map) {
			this.setState({ mapa: this.props.map });
		}
		if (this.props.coord !== prevProps.coord) {
			this.setState({ coord: this.props.coord });
		}
	}

	calculateBtnHandler() {
		this.demoAsyncCall(this.state.value_od_dates.value, point_geojson.features[0].geometry.coordinates[0], point_geojson.features[0].geometry.coordinates[1], this.state.min_distance_km, this.state.max_distance_km, this.state.n_trips, this.state.min_distance_to_train, this.state.max_distance_to_train, this.state.selectedPKm, this.state.selection_distribution);
	}

	distanceSliderHandler(event) {
		console.log(event)
		this.setState({ min_distance_km: event[0], max_distance_km: event[1] });
	}

	percentageSliderHandler(event) {
		this.setState({ percentage: event });
	}

	demoAsyncCall(date, lng, lat, min_distance_km, max_distance_km, n_trips, min_distance_to_train, max_distance_to_train, metric_trips_pkm, selected_options) {
		let data;
		this.setState({ loading: true });
		let options = []

		if (selected_options[0]) {
			options.push('color')
		}
		if (selected_options[1]) {
			options.push('color_last_mile')
		}
		if (selected_options[2]) {
			options.push('color_bike')
		}
		if (selected_options[3]) {
			options.push('color_working')
		}

		const counts = {};
		selected_options.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });

		fetch(URL + 'geohash_od/drop_a_point/{0}/{1},{2}/{3},{4}/{5}/trips'.format(date, lng, lat, min_distance_km, max_distance_km, n_trips))
			.then((res) => res.json())
			.then((json) => {
				data = json;
			}).then(() => {
				console.log(data)
				if (data[0]?.hasOwnProperty('error')) {
					no_data_modal = true;
				} else {
					this.props.map.getSource('data-swiss-destinations').setData(data)
				}
			}).then(() => {
				fetch(URL + 'geohash_od/drop_a_point_geometries', {
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						"date": date,
						"coordinate": [lng, lat],
						"destination": "all",
						"distance": [min_distance_km, max_distance_km],
						"gt_metric": n_trips,
						"metric": metric_trips_pkm,
						"threshold_duration_percentage_green": [
							0,
							130
						],
						"threshold_duration_percentage_yellow": [
							130,
							200
						],
						"threshold_duration_percentage_red": [
							200,
							1000
						],
						"max_difference_green": 10,
						"max_changes_green": 1,
						"max_changes_yellow": 2,
						"min_changes_red": 2,
						"short_bike_radius": 5,
						"medium_bike_radius": 10,
						"minutes_in_train": 1200,
						"maximum_percentage_difference": 100,
						"options": options
					})
				})
					.then((res) => res.json())
					.then((json) => {
						data = json;
					}).then(() => { 
						this.setState({geometries: data})
						this.state.mapa.getSource('data-swiss-tyle').setData(data) })
			})
			.then(() => {
				fetch(URL + 'geohash_od/concentric_circles/{0}/{1},{2}'.format(date, lng, lat))
					.then((res) => res.json())
					.then((json) => {
						data = json;
					}).then(() => { this.state.mapa.getSource('data-concentric-circle').setData(data) })
			})
			.then(() => {
				fetch(URL + 'geohash_od/total_vs_filter', {
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						"date": date,
						"coordinate": [lng, lat],
						"destination": "all",
						"distance": [min_distance_km, max_distance_km],
						"gt_metric": n_trips,
						"metric": metric_trips_pkm,
						"threshold_duration_percentage_green": [
							0,
							130
						],
						"threshold_duration_percentage_yellow": [
							130,
							200
						],
						"threshold_duration_percentage_red": [
							200,
							1000
						],
						"max_difference_green": 10,
						"max_changes_green": 1,
						"max_changes_yellow": 2,
						"min_changes_red": 2,
						"short_bike_radius": 5,
						"medium_bike_radius": 10,
						"minutes_in_train": 1200,
						"maximum_percentage_difference": 100,
						"options": options
					})
				})
					.then((res) => res.json())
					.then((data) => {
						this.setState({
							total_pkm: numberWithCommas((data.total_pkm).toFixed(0)), total_trips: numberWithCommas((data.total_trips).toFixed(0)), current_pkm: numberWithCommas((data.current_pkm).toFixed(0)),
							current_trips: numberWithCommas((data.current_trips).toFixed(0)), percentage_trips: (((data.current_trips / data.total_trips) * 100).toFixed(0)),
							percentage_pkm: (((data.current_pkm / data.total_pkm) * 100).toFixed(0)), data_1km: data,
							current_modal_split_pkm: (data.current_modal_split_pkm * 100).toFixed(0), current_modal_split_trips: (data.current_modal_split_trips * 100).toFixed(0),
							modal_split_green_pkm: (data.modal_split_green_pkm * 100).toFixed(0), modal_split_green_trips: (data.modal_split_green_trips * 100).toFixed(0),
							modal_split_yellow_pkm: (data.modal_split_yellow_pkm * 100).toFixed(0), modal_split_yellow_trips: (data.modal_split_yellow_trips * 100).toFixed(0),
							modal_split_red_pkm: (data.modal_split_red_pkm * 100).toFixed(0), modal_split_red_trips: (data.modal_split_red_trips * 100).toFixed(0),
							potential_trips: data.potential_trips, potential_pkm: data.potential_pkm, num_employees: data.n_employees, n_companies: data.n_companies, trip_distribution: data.trip_distribution
						});

						this.setState({
							green_pkm: ((data.green_pkm).toFixed(0)), green_trips: ((data.green_trips).toFixed(0)), red_pkm: ((data.red_pkm).toFixed(0)), red_trips: ((data.red_trips).toFixed(0)), yellow_pkm: ((data.yellow_pkm).toFixed(0)), yellow_trips: ((data.yellow_trips).toFixed(0)),
							percentage_pkm_green: (((data.green_pkm / data.current_pkm) * 100).toFixed(0)), percentage_pkm_yellow: (((data.yellow_pkm / data.current_pkm) * 100).toFixed(0)), percentage_pkm_red: (((data.red_pkm / data.current_pkm) * 100).toFixed(0)),
							percentage_trips_green: (((data.green_trips / data.current_trips) * 100).toFixed(0)), percentage_trips_red: (((data.red_trips / data.current_trips) * 100).toFixed(0)), percentage_trips_yellow: (((data.yellow_trips / data.current_trips) * 100).toFixed(0))
						});

						this.props.map.setPaintProperty('data-swiss-tyle', 'fill-color', ['get', 'color_combined']);
						this.props.map.setPaintProperty('outline-data-swiss-tyle', 'line-color', ['get', 'color_combined']);
					})
					.then(() => {
						if (hideLines) {
							this.props.map.setLayoutProperty('swiss-destinations', 'visibility', 'none');
						} else {
							this.props.map.setLayoutProperty('swiss-destinations', 'visibility', 'visible');
						}

						if (hideCircles) {
							this.props.map.setLayoutProperty('data-concentric-circle', 'visibility', 'none');
							this.props.map.setLayoutProperty('data-concentric-circle-labels', 'visibility', 'none');
						} else {
							this.props.map.setLayoutProperty('data-concentric-circle', 'visibility', 'visible');
							this.props.map.setLayoutProperty('data-concentric-circle-labels', 'visibility', 'visible');
						}

						if (hideDestination) {
							this.props.map.setLayoutProperty('data-swiss-tyle-labels', 'visibility', 'none');
							this.props.map.setLayoutProperty('data-swiss-tyle', 'visibility', 'none');
							this.props.map.setLayoutProperty('outline-data-swiss-tyle', 'visibility', 'none');
						} else {
							this.props.map.setLayoutProperty('data-swiss-tyle-labels', 'visibility', 'visible');
							this.props.map.setLayoutProperty('data-swiss-tyle', 'visibility', 'visible');
							this.props.map.setLayoutProperty('outline-data-swiss-tyle', 'visibility', 'visible');
						}
					}).then(() => {
						fetch(URL + 'geohash_od/total_vs_filter', {
							method: 'POST',
							headers: {
								'Accept': 'application/json',
								'Content-Type': 'application/json'
							},
							body: JSON.stringify({
								"date": date,
								"coordinate": [lng, lat],
								"destination": "all",
								"distance": [min_distance_km, max_distance_km],
								"gt_metric": n_trips,
								"metric": metric_trips_pkm,
								"threshold_duration_percentage_green": [
									0,
									130
								],
								"threshold_duration_percentage_yellow": [
									130,
									200
								],
								"threshold_duration_percentage_red": [
									200,
									1000
								],
								"max_difference_green": 10,
								"max_changes_green": 1,
								"max_changes_yellow": 2,
								"min_changes_red": 2,
								"short_bike_radius": 5000,
								"medium_bike_radius": 10000,
								"minutes_in_train": 1200,
								"maximum_percentage_difference": 100,
								"options": ['color']
							})
						})
							.then((res) => res.json())
							.then((data) => {
								this.setState({
									percentage_pkm_statusquo_green: (((data.green_pkm / data.current_pkm) * 100).toFixed(0)),
									percentage_pkm_statusquo_yellow: (((data.yellow_pkm / data.current_pkm) * 100).toFixed(0)),
									percentage_pkm_statusquo_red: (((data.red_pkm / data.current_pkm) * 100).toFixed(0)),

									percentage_trips_statusquo_green: (((data.green_trips / data.current_trips) * 100).toFixed(0)),
									percentage_trips_statusquo_yellow: (((data.yellow_trips / data.current_trips) * 100).toFixed(0)),
									percentage_trips_statusquo_red: (((data.red_trips / data.current_trips) * 100).toFixed(0))
								});
							}).then(() => {

								const tyle_corner = {
									center: [lng, lat],
									zoom: 11,
									pitch: 0,
									bearing: 0
								}

								this.props.map.flyTo({
									...tyle_corner, // Fly to the selected target
									duration: 2000, // Animate over 2 seconds
									essential: false // This animation is considered essential with
									//respect to prefers-reduced-motion
								})

								this.setState({ generatedTyles: true, loading: false });
							})
					})
			});
	}

	distanceToTrainHandler(event) {
		this.setState({ min_distance_to_train: event[0], max_distance_to_train: event[1] })
	}

	handleFetchDataCoord() {
		this.setState({ loading: true });
		this.demoAsyncCall(this.state.value_od_dates.value, point_geojson.features[0].geometry.coordinates[0], point_geojson.features[0].geometry.coordinates[1], this.state.min_distance_km, this.state.max_distance_km, this.state.n_trips, this.state.min_distance_to_train, this.state.max_distance_to_train, this.state.selectedPKm, this.state.selection_distribution);

		const tyle_corner = {
			center: [point_geojson.features[0].geometry.coordinates[0], point_geojson.features[0].geometry.coordinates[1]],
			zoom: 11,
			pitch: 0,
			bearing: 0
		}

		this.props.map.flyTo({
			...tyle_corner, // Fly to the selected target
			duration: 2000, // Animate over 2 seconds
			essential: false // This animation is considered essential with
			//respect to prefers-reduced-motion
		})

		this.setState({ generatedTyles: !this.state.generatedTyles });
		generated_info = true;
		hideLines = false;
		hideDestination = false;
		hideCircles = false;
	}

	resetView() {
		this.state.mapa.getSource('data-swiss-destinations').setData(lines_geojson);
		this.state.mapa.getSource('data-concentric-circle').setData(lines_geojson);
		this.state.mapa.getSource('data-swiss-tyle').setData(lines_geojson);
		this.props.map.setLayoutProperty('data-concentric-circle', 'visibility', 'none');
		this.props.map.setLayoutProperty('data-concentric-circle-labels', 'visibility', 'none');
		this.props.map.setLayoutProperty('data-swiss-tyle-labels', 'visibility', 'none');


		this.setState({ generatedTyles: false })

		no_data_modal = false;
		generated_info = false;
	}

	handleMonthYear(event) {
		this.setState({ value_od_dates: event });
	}

	handleHideCircles() {
		let temp = !hideCircles;
		hideCircles = temp;
		if (temp) {
			this.props.map.setLayoutProperty('data-concentric-circle', 'visibility', 'none');
			this.props.map.setLayoutProperty('data-concentric-circle-labels', 'visibility', 'none');
		}
		else {
			this.props.map.setLayoutProperty('data-concentric-circle', 'visibility', 'visible');
			this.props.map.setLayoutProperty('data-concentric-circle-labels', 'visibility', 'visible');
		}
	}

	handleHideLines() {
		let temp = !hideLines;
		hideLines = temp;
		if (temp) {
			this.state.mapa.setLayoutProperty('swiss-destinations', 'visibility', 'none');
		}
		else {
			this.state.mapa.setLayoutProperty('swiss-destinations', 'visibility', 'visible');
		}
	}

	handleVisualization(event) {
		this.setState({ selectedPKm: event.target.attributes.getNamedItem('id').value })
		this.demoAsyncCall(this.state.value_od_dates.value, point_geojson.features[0].geometry.coordinates[0], point_geojson.features[0].geometry.coordinates[1], this.state.min_distance_km, this.state.max_distance_km, this.state.n_trips, this.state.min_distance_to_train, this.state.max_distance_to_train, event.target.attributes.getNamedItem('id').value, this.state.selection_distribution);

	}

	handleSquareDestinations() {
		let temp = !hideDestination;
		hideDestination = temp;
		if (temp) {
			this.props.map.setLayoutProperty('data-swiss-tyle-labels', 'visibility', 'none');
			this.props.map.setLayoutProperty('data-swiss-tyle', 'visibility', 'none');
			this.props.map.setLayoutProperty('outline-data-swiss-tyle', 'visibility', 'none');
		} else {
			this.props.map.setLayoutProperty('data-swiss-tyle-labels', 'visibility', 'visible');
			this.props.map.setLayoutProperty('data-swiss-tyle', 'visibility', 'visible');
			this.props.map.setLayoutProperty('outline-data-swiss-tyle', 'visibility', 'visible');
		}
	}

	numTripsHandler(event) {
		this.setState({ n_trips: event });
	}

	handleDistribution(event) {

		let current_selection_distribution = this.state.selection_distribution;

		if (event.target.attributes.getNamedItem('id').value === 'general') {
			current_selection_distribution[0] = (!current_selection_distribution[0])

			if (current_selection_distribution[0]) {
				current_selection_distribution = [true, false, false, false]
			} else {
				current_selection_distribution = [false, true, true, true]
			}
		} else if (event.target.attributes.getNamedItem('id').value === 'lastmile') {
			current_selection_distribution[1] = (!current_selection_distribution[1])
		} else if (event.target.attributes.getNamedItem('id').value === 'bike') {
			current_selection_distribution[2] = (!current_selection_distribution[2])
		} else if (event.target.attributes.getNamedItem('id').value === 'working') {
			current_selection_distribution[3] = (!current_selection_distribution[3])
		}

		this.setState({ selection_distribution: current_selection_distribution })
		this.demoAsyncCall(this.state.value_od_dates.value, point_geojson.features[0].geometry.coordinates[0], point_geojson.features[0].geometry.coordinates[1], this.state.min_distance_km, this.state.max_distance_km, this.state.n_trips, this.state.min_distance_to_train, this.state.max_distance_to_train, this.state.selectedPKm, current_selection_distribution);
	}

	getExcel() {
		var all = [];
		var length = this.state.geometries?.features?.length
		if (length !== 0 || length !== 'undefined') {
			for (var i = 0; i < length; i++) {
				console.log(this.state.geometries.features[i])
				all.push({
					"GeoHash": this.state.geometries.features[i].properties['geohash_id'],
					"KG": this.state.geometries.features[i].properties['KG'],
					"Car PKm": this.state.geometries.features[i].properties['car_pkm'],
					"Car Trips": this.state.geometries.features[i].properties['car_trips'],
					"Train PKm": this.state.geometries.features[i].properties['train_pkm'],
					"Train Trips": this.state.geometries.features[i].properties['train_trips'],
					"Walking PKm": this.state.geometries.features[i].properties['walking_pkm'],
					"Walking Trips": this.state.geometries.features[i].properties['walking_trips'],
					"Total PKm": this.state.geometries.features[i].properties['total_pkm'],
					"Total Trips": this.state.geometries.features[i].properties['total_trips'],
								})
			}
		}
		return all;
	}

	render() {
		const { loading } = this.state;
		const { generatedTyles } = this.state;
		const { value_od_dates } = this.state;
		const { current_pkm } = this.state;
		const { total_pkm } = this.state;
		const { current_trips } = this.state;
		const { total_trips } = this.state;
		const { percentage_pkm } = this.state;
		const { percentage_trips } = this.state;
		const { selection_distribution } = this.state;
		const { selectedPKm } = this.state;

		const { percentage_pkm_red } = this.state;
		const { percentage_pkm_yellow } = this.state;
		const { percentage_pkm_green } = this.state;
		const { percentage_trips_red } = this.state;
		const { percentage_trips_yellow } = this.state;
		const { percentage_trips_green } = this.state;

		const { percentage_pkm_statusquo_green } = this.state;
		const { percentage_pkm_statusquo_yellow } = this.state;
		const { percentage_pkm_statusquo_red } = this.state;

		const { percentage_trips_statusquo_green } = this.state;
		const { percentage_trips_statusquo_yellow } = this.state;
		const { percentage_trips_statusquo_red } = this.state;

		const selectDate = {
			menuList: styles => {
				return {
					...styles,
					maxHeight: 150,
					borderWidth: 0,
					boxShadow: '0 0 10px 2px rgb(0 0 0 / 10%)',
				};
			}
		};

		const ExcelExportData = this.getExcel();


		if (loading) { // if your component doesn't have to wait for an async action, remove this block 
			return (
				<div className="bg-loader">
					<div className="pos-center-brain-loader">
						<div className="circle-loading-brain">
						</div>
						<svg className='brain-loader' x="0px" y="0px" viewBox="0 0 496 496">
							<path d="M40,449.472V364.28L58.424,352H88v-16H53.576L24,355.72v93.752C14.704,452.784,8,461.584,8,472c0,13.232,10.768,24,24,24				s24-10.768,24-24C56,461.584,49.288,452.784,40,449.472z M32,480c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8s8,3.584,8,8				C40,476.416,36.416,480,32,480z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M46.528,320H88v-16H46.528c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24				C34.416,336,43.216,329.288,46.528,320z M16,312c0-4.416,3.584-8,8-8s8,3.584,8,8c0,4.416-3.584,8-8,8S16,316.416,16,312z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M24,272c10.416,0,19.216-6.712,22.528-16H88v-16H46.528c-3.312-9.296-12.112-16-22.528-16c-13.232,0-24,10.768-24,24				S10.768,272,24,272z M24,240c4.416,0,8,3.584,8,8s-3.584,8-8,8s-8-3.584-8-8C16,243.584,19.584,240,24,240z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M56 272h32v16H56Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M56 208h32v16H56Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M0 176h88v16H0Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M60.688,160H88v-16H67.312L48,124.688v-14.16C57.288,107.216,64,98.416,64,88c0-13.232-10.768-24-24-24S16,74.768,16,88				c0,10.416,6.704,19.216,16,22.528v20.784L60.688,160z M40,80c4.416,0,8,3.584,8,8s-3.584,8-8,8s-8-3.584-8-8S35.584,80,40,80z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M144 67.312L144 88L160 88L160 60.688L131.312 32L0 32L0 48L124.688 48z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M176 35.312L176 88L192 88L192 28.688L163.312 0L0 0L0 16L156.688 16z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M208 56h16v32H208Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M304 0h16v88H304Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M240,46.528V88h16V59.312l16,16V88h16V68.688L262.392,43.08C268.192,38.696,272,31.816,272,24c0-13.232-10.768-24-24-24				s-24,10.768-24,24C224,34.416,230.704,43.216,240,46.528z M248,16c4.416,0,8,3.584,8,8s-3.584,8-8,8s-8-3.584-8-8				S243.584,16,248,16z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M352,51.312L371.312,32h78.16c2.416,6.776,7.752,12.112,14.528,14.528v110.16L444.688,176H408v16h43.312L480,163.312				V46.528c9.288-3.312,16-12.112,16-22.528c0-13.232-10.768-24-24-24c-10.416,0-19.216,6.712-22.528,16h-84.784L336,44.688V88h16				V51.312z M472,16c4.416,0,8,3.584,8,8s-3.584,8-8,8c-4.416,0-8-3.584-8-8S467.584,16,472,16z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M414.08,97.392l17.92,17.92v9.376L412.688,144H408v16h11.312L448,131.312v-22.624L419.312,80h-4.784				c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24C401.904,112,410.416,105.968,414.08,97.392z				 M392,96c-4.416,0-8-3.584-8-8s3.584-8,8-8c4.416,0,8,3.584,8,8S396.416,96,392,96z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M408 208h88v16H408Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M480,449.472V268.688L451.312,240H408v16h36.688L464,275.312v41.376L451.312,304H408v16h36.688L464,339.312v110.16				c-9.296,3.312-16,12.112-16,22.528c0,13.232,10.768,24,24,24s24-10.768,24-24C496,461.584,489.288,452.784,480,449.472z M472,480				c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8c4.416,0,8,3.584,8,8C480,476.416,476.416,480,472,480z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M408 272h32v16H408Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M448,364.688L419.312,336H408v16h4.688L432,371.312V416h-80v-8h-16v24h96v12.688L412.688,464h-14.16				c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24c10.416,0,19.216-6.712,22.528-16h20.784				L448,451.312V364.688z M376,480c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8c4.416,0,8,3.584,8,8C384,476.416,380.416,480,376,480z				" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M160,408h-16v8h-41.472c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24				c10.416,0,19.216-6.712,22.528-16H160V408z M80,432c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8s8,3.584,8,8				C88,428.416,84.416,432,80,432z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M304 408h16v88H304Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M272 408h16v88H272Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M240,420.688l-16,16V408h-16v41.472c-9.296,3.312-16,12.112-16,22.528c0,13.232,10.768,24,24,24s24-10.768,24-24				c0-7.816-3.808-14.696-9.608-19.08L256,427.312V408h-16V420.688z M216,480c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8s8,3.584,8,8				C224,476.416,220.416,480,216,480z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M192 408L176 408L176 428.688L152 452.688L152 496L168 496L168 459.312L192 435.312z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M336,392h16v-16h24v-24h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16				v-16h-16v-24h-24v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-3.312				L120,140.688V144h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v24h24v16h16v-16h16				v16h16v-16h16v16h16v-16h16v16h16v-16h16v16h16v-16h16v16h16v-16h16V392z M136,360V147.312L147.312,136H360v224H136z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M318.888,211.048l13.96-13.96l-33.936-33.936l-13.96,13.96c-4.16-2.184-8.488-3.976-12.952-5.384V152h-48v19.728				c-4.472,1.408-8.8,3.2-12.952,5.384l-13.96-13.96l-33.936,33.936l13.96,13.96c-2.176,4.16-3.976,8.488-5.384,12.952H152v48				h19.728c1.408,4.472,3.2,8.8,5.384,12.952l-13.96,13.96l33.936,33.936l13.96-13.96c4.16,2.176,8.48,3.976,12.952,5.384V344h48				v-19.728c4.472-1.408,8.8-3.2,12.952-5.384l13.96,13.96l33.936-33.936l-13.96-13.96c2.176-4.152,3.976-8.48,5.384-12.952H344v-48				h-19.728C322.864,219.528,321.072,215.2,318.888,211.048z M328,256h-16.224l-1.416,6.224c-1.6,7.016-4.368,13.672-8.216,19.792				l-3.408,5.408l11.496,11.496l-11.312,11.312l-11.496-11.496l-5.408,3.408c-6.12,3.848-12.776,6.616-19.792,8.216L256,311.776V328				h-16v-16.224l-6.224-1.416c-7.016-1.6-13.672-4.368-19.792-8.216l-5.408-3.408l-11.496,11.496l-11.312-11.312l11.496-11.496				l-3.408-5.408c-3.848-6.12-6.616-12.776-8.216-19.792L184.224,256H168v-16h16.224l1.416-6.224				c1.6-7.016,4.368-13.672,8.216-19.792l3.408-5.408l-11.496-11.496l11.312-11.312l11.496,11.488l5.408-3.408				c6.12-3.848,12.776-6.616,19.792-8.216l6.224-1.408V168h16v16.224l6.224,1.416c7.016,1.6,13.672,4.368,19.792,8.216l5.408,3.408				l11.496-11.488l11.312,11.312l-11.496,11.496l3.408,5.408c3.848,6.12,6.616,12.776,8.216,19.792l1.416,6.216H328V256z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M248,208c-22.056,0-40,17.944-40,40c0,22.056,17.944,40,40,40c22.056,0,40-17.944,40-40C288,225.944,270.056,208,248,208				z M248,272c-13.232,0-24-10.768-24-24s10.768-24,24-24s24,10.768,24,24S261.232,272,248,272z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
						</svg>
					</div>
				</div>
			)
		}
		else {
			return (
				<div>
					{this.state.generatedTyles ?
						<>
							<div className="map-potential-btn-drop-point-info-display" style={{ top: "90px" }}>
								<div className="map-potential-btn-drop-point-info-display-inner">
									<h2>Visualize contribution percentage by</h2>
									<Form onChange={this.handleVisualization.bind(this)}>
										<div className="row">
											<div className="column-1-eq">
												<Form.Check inline label=" Passenger Km" name="type_analysis" type='radio' id='pkm' checked={(selectedPKm === 'pkm')} />
											</div>
											<div className="column-2-eq">
												<Form.Check inline label=" Number of Trips" name="type_analysis" type='radio' id='trips' checked={(selectedPKm === 'trips')} />
											</div>
										</div>
									</Form>
								</div>
							</div>
							<div className="map-potential-btn-drop-point-info-category" style={{ top: "160px" }}>
								<div className="map-potential-btn-drop-point-info-category-inner">
									<h2>Simulate Green Mobility Potential</h2>
									<Form onChange={this.handleDistribution.bind(this)}>
										<div className="row">
											<div className="column-headquarters-2">
												<Form.Check inline label="Status Quo" name="type_analysis" type='checkbox' id='general' defaultChecked={(selection_distribution[0])} />
											</div>
											<div className="column-headquarters-3">
												<Form.Check inline label="Last-Mile Optimization" name="type_analysis" type='checkbox' id='lastmile' defaultChecked={(selection_distribution[1])} />
											</div>
											<div className="column-headquarters-1">
												<Form.Check inline label="Bicycle" name="type_analysis" type='checkbox' id='bike' defaultChecked={(selection_distribution[2])} />
											</div>
											<div className="column-headquarters-4">
												<Form.Check inline label="Working Time" name="type_analysis" type='checkbox' id='working' defaultChecked={(selection_distribution[3])} />
											</div>
										</div>
									</Form>
								</div>
							</div>
							{(selectedPKm === 'pkm') ?
								<>
									{(selection_distribution[0] === true && selection_distribution[1] === false
										&& selection_distribution[2] === false && selection_distribution[3] === false)
										?
										<>
											<div>
											</div>
											<div className="map-potential-btn-tile-potential2D-resp-1" style={{ top: "230px" }}>
												<div className="map-btn-tile-potential2D-resp-1-inner">
													<div className="row-parameters-3">
														<h3>{percentage_pkm_green}%</h3>
														<h4>of trips currently have a great car alternative</h4>
														<br />
														<br />
														<h4>Select measures to compute potential improvements</h4>
													</div>
												</div>
											</div>
											<div className="map-potential-btn-tile-potential2D-resp-2" style={{ top: "410px" }}>
												<div className="map-btn-tile-potential2D-resp-2-inner">
													<div className="row-parameters-3">
														<h3>{percentage_pkm_yellow}%</h3>
														<h4>of PKm currently have a decent car alternative</h4>
														<br />
														<br />
														<h4>Select measures to compute potential improvements</h4>
													</div>
												</div>
											</div>
											<div className="map-potential-btn-tile-potential2D-resp-3" style={{ top: "410px" }}>
												<div className="map-btn-tile-potential2D-resp-3-inner">
													<div className="row-parameters-3">
														<h3>{percentage_pkm_red}%</h3>
														<h4>of PKm currently have a bad car alternative</h4>
														<br />
														<br />
														<h4>Select measures to compute potential improvements</h4>
													</div>
												</div>
											</div>
										</>
										:
										<>
											<div className="map-potential-btn-tile-potential2D-resp-1" style={{ top: "230px" }}>
												<div className="map-btn-tile-potential2D-resp-1-inner">
													<div className="row-parameters-3">
														<h3>{percentage_pkm_statusquo_green}%</h3>
														<h4>of PKm currently have a decent car alternative</h4>
														<h3>{percentage_pkm_green}%</h3>
														<h4>of PKm would have a great car alternative with selected measures</h4>
													</div>
												</div>
											</div>
											<div className="map-potential-btn-tile-potential2D-resp-2" style={{ top: "410px" }}>
												<div className="map-btn-tile-potential2D-resp-2-inner">
													<div className="row-parameters-3">
														<h3>{percentage_pkm_statusquo_yellow}%</h3>
														<h4>of PKm currently have a decent car alternative</h4>
														<h3>{percentage_pkm_yellow}%</h3>
														<h4>of PKm would have a decent car alternative with selected measures</h4>
													</div>
												</div>
											</div>
											<div className="map-potential-btn-tile-potential2D-resp-3" style={{ top: "410px" }}>
												<div className="map-btn-tile-potential2D-resp-3-inner">
													<div className="row-parameters-3">
														<h3>{percentage_pkm_statusquo_red}%</h3>
														<h4>of PKm currently have a bad car alternative</h4>
														<h3>{percentage_pkm_red}%</h3>
														<h4>of PKm would still have a bad car alternative with selected measures</h4>
													</div>
												</div>
											</div>
										</>
									}
								</>
								:
								<>
									{(selection_distribution[0] === true && selection_distribution[1] === false
										&& selection_distribution[2] === false && selection_distribution[3] === false)
										?
										<>
											<div className="map-potential-btn-tile-potential2D-resp-1" style={{ top: "230px" }}>
												<div className="map-btn-tile-potential2D-resp-1-inner">
													<div className="row-parameters-3">
														<h3>{percentage_trips_green}%</h3>
														<h4>of trips currently have a great car alternative</h4>
														<br />
														<br />
														<h4>Select measures to compute potential improvements</h4>
													</div>
												</div>
											</div>
											<div className="map-potential-btn-tile-potential2D-resp-2" style={{ top: "410px" }}>
												<div className="map-btn-tile-potential2D-resp-2-inner">
													<div className="row-parameters-3">
														<h3>{percentage_trips_yellow}%</h3>
														<h4>of trips currently have a decent car alternative</h4>
														<br />
														<br />
														<h4>Select measures to compute potential improvements</h4>
													</div>
												</div>
											</div>
											<div className="map-potential-btn-tile-potential2D-resp-3" style={{ top: "410px" }}>
												<div className="map-btn-tile-potential2D-resp-3-inner">
													<div className="row-parameters-3">
														<h3>{percentage_trips_red}%</h3>
														<h4>of trips currently have a bad car alternative</h4>
														<br />
														<br />
														<h4>Select measures to compute potential improvements</h4>
													</div>
												</div>
											</div>
										</>
										:
										<>
											<div className="map-potential-btn-tile-potential2D-resp-1" style={{ top: "230px" }}>
												<div className="map-btn-tile-potential2D-resp-1-inner">
													<div className="row-parameters-3">
														<h3>{percentage_trips_statusquo_green}%</h3>
														<h4>of trips currently have a great car alternative</h4>
														<h3>{percentage_trips_green}%</h3>
														<h4>of trips would have a great car alternative with selected measures</h4>
													</div>
												</div>
											</div>
											<div className="map-potential-btn-tile-potential2D-resp-2" style={{ top: "410px" }}>
												<div className="map-btn-tile-potential2D-resp-2-inner">
													<div className="row-parameters-3">
														<h3>{percentage_trips_statusquo_yellow}%</h3>
														<h4>of trips currently have a decent car alternative</h4>
														<h3>{percentage_trips_yellow}%</h3>
														<h4>of trips would have a decent car alternative with selected measures</h4>
													</div>
												</div>
											</div>
											<div className="map-potential-btn-tile-potential2D-resp-3" style={{ top: "410px" }}>
												<div className="map-btn-tile-potential2D-resp-3-inner">
													<div className="row-parameters-3">
														<h3>{percentage_trips_statusquo_red}%</h3>
														<h4>of trips currently have a bad car alternative</h4>
														<h3>{percentage_trips_red}%</h3>
														<h4>of trips would still have a bad car alternative with selected measures</h4>
													</div>
												</div>
											</div>
										</>
									}
								</>
							}
							<div className="map-potential-btn-tile-potential2D-resp-1" style={{ left: "160px", top: "230px" }}>
								<div className="map-btn-tile-potential2D-resp-1-inner" style={{ height: "70px", width: "300px" }}>
									<div className="row-parameters-3">
										<h4>Trips with a great car alternative</h4>
										<div style={{ height: '20px', paddingTop: '10px' }}>
											<div className="column-1-eq" style={{
												width: ((selectedPKm === 'pkm' && (selection_distribution[0] === true && selection_distribution[1] === false
													&& selection_distribution[2] === false && selection_distribution[3] === false) && Number(this.state.percentage_pkm_green) < 17) || (selectedPKm === 'trips' && (selection_distribution[0] === true && selection_distribution[1] === false
														&& selection_distribution[2] === false && selection_distribution[3] === false) && Number(this.state.percentage_trips_green) < 17) || (selectedPKm === 'pkm' && (selection_distribution[0] === false) && Number(this.state.percentage_pkm_statusquo_green) < 17) || (selectedPKm === 'trips' && (selection_distribution[0] === false) && Number(this.state.percentage_trips_statusquo_green) < 17)) ? "22%" : ((selection_distribution[0] === true && selection_distribution[1] === false
															&& selection_distribution[2] === false && selection_distribution[3] === false) ? ((selectedPKm === 'pkm') ? (Number(this.state.percentage_pkm_green) + 5) : (Number(this.state.percentage_trips_green) + 5)) : ((selectedPKm === 'pkm') ? (Number(this.state.percentage_pkm_statusquo_green) + 5) : (Number(this.state.percentage_trips_statusquo_green) + 5))) + "%"
											}}>
												<h4 style={{ fontSize: "25px", fontWeight: '800' }}>{((selection_distribution[0] === true && selection_distribution[1] === false
													&& selection_distribution[2] === false && selection_distribution[3] === false) ? ((selectedPKm === 'pkm') ? this.state.percentage_pkm_green : this.state.percentage_trips_green) : ((selectedPKm === 'pkm') ? this.state.percentage_pkm_statusquo_green : this.state.percentage_trips_statusquo_green))}%</h4></div>
											<div className="column-2-eq" style={{
												width: ((selectedPKm === 'pkm' && (selection_distribution[0] === true && selection_distribution[1] === false
													&& selection_distribution[2] === false && selection_distribution[3] === false) && Number(this.state.percentage_pkm_green) < 17) || (selectedPKm === 'trips' && (selection_distribution[0] === true && selection_distribution[1] === false
														&& selection_distribution[2] === false && selection_distribution[3] === false) && Number(this.state.percentage_trips_green) < 17) || (selectedPKm === 'pkm' && (selection_distribution[0] === false) && Number(this.state.percentage_pkm_statusquo_green) < 17) || (selectedPKm === 'trips' && (selection_distribution[0] === false) && Number(this.state.percentage_trips_statusquo_green) < 17)) ? "78%" : ((selection_distribution[0] === true && selection_distribution[1] === false
															&& selection_distribution[2] === false && selection_distribution[3] === false) ? ((selectedPKm === 'pkm') ? 95 - this.state.percentage_pkm_green : 95 - this.state.percentage_trips_green) : ((selectedPKm === 'pkm') ? 95 - this.state.percentage_pkm_statusquo_green : 95 - this.state.percentage_trips_statusquo_green)) + "%"
											}}>
												<h4 style={{ fontSize: "25px", textAlign: 'left', fontWeight: '800' }}>{((selection_distribution[0] === true && selection_distribution[1] === false
													&& selection_distribution[2] === false && selection_distribution[3] === false) ? ('0') : ((selectedPKm === 'pkm') ? this.state.percentage_pkm_green - this.state.percentage_pkm_statusquo_green : this.state.percentage_trips_green - this.state.percentage_trips_statusquo_green))}%</h4></div>
										</div>
										<div class="miniBar" style={{ height: '15px', backgroundColor: '#E7F6F1', }}>
											<div class="miniBarProgress1" style={{
												backgroundColor: '#158D62', width: ((selection_distribution[0] === true && selection_distribution[1] === false
													&& selection_distribution[2] === false && selection_distribution[3] === false) ? ((selectedPKm === 'pkm') ? this.state.percentage_pkm_green : this.state.percentage_trips_green) : ((selectedPKm === 'pkm') ? this.state.percentage_pkm_statusquo_green : this.state.percentage_trips_statusquo_green)) + "%"
											}}></div>
											<div class="miniBarProgress2" style={{
												backgroundColor: '#00BD50', left: ((selection_distribution[0] === true && selection_distribution[1] === false
													&& selection_distribution[2] === false && selection_distribution[3] === false) ? ((selectedPKm === 'pkm') ? this.state.percentage_pkm_green : this.state.percentage_trips_green) : ((selectedPKm === 'pkm') ? this.state.percentage_pkm_statusquo_green : this.state.percentage_trips_statusquo_green)) + "%",
												width: ((selection_distribution[0] === true && selection_distribution[1] === false
													&& selection_distribution[2] === false && selection_distribution[3] === false) ? 0 : ((selectedPKm === 'pkm') ? this.state.percentage_pkm_green - this.state.percentage_pkm_statusquo_green : this.state.percentage_trips_green - this.state.percentage_trips_statusquo_green)) + "%"
											}}></div>

										</div>
										<div>
											<div className="column-1-eq" style={{
												width: ((selectedPKm === 'pkm' && (selection_distribution[0] === true && selection_distribution[1] === false
													&& selection_distribution[2] === false && selection_distribution[3] === false) && Number(this.state.percentage_pkm_green) < 17) || (selectedPKm === 'trips' && (selection_distribution[0] === true && selection_distribution[1] === false
														&& selection_distribution[2] === false && selection_distribution[3] === false) && Number(this.state.percentage_trips_green) < 17) || (selectedPKm === 'pkm' && (selection_distribution[0] === false) && Number(this.state.percentage_pkm_statusquo_green) < 17) || (selectedPKm === 'trips' && (selection_distribution[0] === false) && Number(this.state.percentage_trips_statusquo_green) < 17)) ? "22%" : ((selection_distribution[0] === true && selection_distribution[1] === false
															&& selection_distribution[2] === false && selection_distribution[3] === false) ? ((selectedPKm === 'pkm') ? (Number(this.state.percentage_pkm_green) + 5) : (Number(this.state.percentage_trips_green) + 5)) : ((selectedPKm === 'pkm') ? (Number(this.state.percentage_pkm_statusquo_green) + 5) : (Number(this.state.percentage_trips_statusquo_green) + 5))) + "%"
											}}>
												<h4 style={{
													fontSize: "9px"
												}}>Status Quo</h4></div>
											<div className="column-2-eq" style={{
												width: ((selectedPKm === 'pkm' && (selection_distribution[0] === true && selection_distribution[1] === false
													&& selection_distribution[2] === false && selection_distribution[3] === false) && Number(this.state.percentage_pkm_green) < 17) || (selectedPKm === 'trips' && (selection_distribution[0] === true && selection_distribution[1] === false
														&& selection_distribution[2] === false && selection_distribution[3] === false) && Number(this.state.percentage_trips_green) < 17) || (selectedPKm === 'pkm' && (selection_distribution[0] === false) && Number(this.state.percentage_pkm_statusquo_green) < 17) || (selectedPKm === 'trips' && (selection_distribution[0] === false) && Number(this.state.percentage_trips_statusquo_green) < 17)) ? "78%" : ((selection_distribution[0] === true && selection_distribution[1] === false
															&& selection_distribution[2] === false && selection_distribution[3] === false) ? ((selectedPKm === 'pkm') ? 95 - this.state.percentage_pkm_green : 95 - this.state.percentage_trips_green) : ((selectedPKm === 'pkm') ? 95 - this.state.percentage_pkm_statusquo_green : 95 - this.state.percentage_trips_statusquo_green)) + "%"
											}}><h4 style={{
												fontSize: "9px", textAlign: 'left'
											}}>Simulated Potential</h4></div>
										</div>
									</div>
								</div>
							</div>
							<div className="map-potential-btn-tile-potential2D-resp-1" style={{ left: "160px", top: "330px" }}>
								<div className="map-btn-tile-potential2D-resp-1-inner" style={{ height: "50px", width: "300px" }}>
									<div className="row-parameters-3">
										<h4>Out of the {(selectedPKm === 'pkm' ? percentage_pkm_green : percentage_trips_green)}% {(selectedPKm === 'pkm' ? 'PKms' : 'trips')} with a great car alternative</h4>
										<div style={{ height: '15px' }}>
											<div className="column-1-eq"><h4 style={{ fontSize: "15px", fontWeight: '800' }}>{((selectedPKm === 'pkm') ? this.state.modal_split_green_pkm : this.state.modal_split_green_trips)}%</h4></div>
											<div className="column-2-eq"><h4 style={{ fontSize: "15px", textAlign: 'right', fontWeight: '800' }}>{((selectedPKm === 'pkm') ? 100 - this.state.modal_split_green_pkm : 100 - this.state.modal_split_green_trips)}%</h4></div>
										</div>
										<div class="miniBar" style={{ height: '10px' }}>
											<div class="miniBarProgress1" style={{ width: ((selectedPKm === 'pkm') ? this.state.modal_split_green_pkm : this.state.modal_split_green_trips) + "%" }}></div>
										</div>
										<div>
											<div className="column-1-eq"><h4 style={{ fontSize: "9px" }}>are already car-free</h4></div>
											<div className="column-2-eq"><h4 style={{ fontSize: "9px", textAlign: 'right' }}>are still car-based</h4></div>
										</div>
									</div>
								</div>
							</div>
							<div className="map-drop-point-pkm-value" style={{ top: '590px' }}>
								<div className="map-drop-point-pkm-value-inner">
									<h1>Showing {current_pkm} PKm per day</h1>
									<h3>Total {total_pkm} PKm per day</h3>
									<h4>{percentage_pkm}% of the PKm per day</h4>
									<hr />
									<h1>Showing {current_trips} trips per day</h1>
									<h3>Total {total_trips} trips per day</h3>
									<h4>{percentage_trips}% of the trips per day</h4>
								</div>
							</div>
						</>
						: <></>
					}
					<div className="map-month-year-picker">
						<Select className='dates' options={this.state.dates_trips_} onChange={this.handleMonthYear.bind(this)} styles={selectDate} defaultValue={value_od_dates} />
					</div>
					<div className="map-btn-drop-point">
						<div className="map-btn-drop-point-inner">
							<h1>Compute Origin-Destination <br /> Matrix for a Point</h1>
							<p className="label">Place the circle on the desired coordinates. Click on <i>Generate matrix</i> to visualize the all destinations of selected coordinate</p>
							<div className="row">
								<div className="column-1-eq">
									<Form onClick={this.handleFetchDataCoord.bind(this)}>
										<Button className="generate-btn" variant="info" disabled={(generatedTyles)}>Generate matrix</Button>
									</Form>
								</div>
								<div className="column-2-eq">
									<Form onClick={this.resetView.bind(this)}>
										<Button className="reset-btn" variant="info" disabled={(!generatedTyles)}>Reset matrix</Button>
									</Form>
								</div>
							</div>
						</div>
						<div className="slider-modal">
							<div className="slider-modal-inner">
								<h1>Filter by distance in Km</h1>
								<div className="slider-distance">
									<Slider range min={0} max={525} step={0.5} value={[this.state.min_distance_km, this.state.max_distance_km]} onChange={_.debounce(this.distanceSliderHandler.bind(this), 33)} />
								</div>
								<p className='label'>Show trips between {this.state.min_distance_km}km and {this.state.max_distance_km}km</p>
								<div className="row-parameters">
									<div className="column-33">
										<Form onChange={this.handleHideLines.bind(this)}>
											<Form.Check
												disabled={!generated_info}
												type="switch"
												id="custom-switch"
												label="Route (Lines)"
												defaultChecked
											/>
										</Form>
									</div>
									<div className="column-33">
										<Form onChange={this.handleHideCircles.bind(this)}>
											<Form.Check
												disabled={!generated_info}
												type="switch"
												id="custom-switch"
												label="Heatmap"
												defaultChecked
											/>
										</Form>
									</div>
									<div className="column-33">
										<Form onChange={this.handleSquareDestinations.bind(this)}>
											<Form.Check
												disabled={!generated_info}
												type="switch"
												id="custom-switch"
												label="Destinations"
												defaultChecked
											/>
										</Form>
									</div>
								</div>
							</div>
						</div>

						<div className="slider-2-modal">
							<div className="slider-2-modal-inner" style={{ height: '130px' }}>
								<h1>Filter by number of trips <br /> within a tile</h1>
								<div className="slider-distance">
									<Slider min={5} max={60} step={1} defaultValue={this.state.n_trips} onChange={_.debounce(this.numTripsHandler.bind(this), 33)} disabled={!this.state.generatedTyles} />
								</div>
								<p className='label-2'>Filter tiles that have more than  <br /> {this.state.n_trips} trips per day</p>
								{!this.state.generatedTyles ?
									<div className="row">
										<Form onClick={this.calculateBtnHandler.bind(this)}>
											<Button className="generate-btn" variant="info" disabled={!this.state.generatedTyles}>Calculate</Button>
										</Form>

									</div>
									:
									<div className="row">
										<div className="column-1-50">
											<Form onClick={this.calculateBtnHandler.bind(this)}>
												<Button className="generate-btn" variant="info" disabled={!this.state.generatedTyles}>Calculate</Button>
											</Form>
										</div>
										<div className="column-2-50">
											<ExportExcel excelData={ExcelExportData} fileName={'Drop a point - Tile'} />
										</div>
									</div>
								}

							</div>
						</div>
					</div>
					{no_data_modal ?
						<>
							<div className="modal-alert">
								<div className="modal-alert-inner">
									<h1>No data available</h1>
									<p>Please click on <i>Reset matrix</i> and try a new point within Austria</p>
								</div>
							</div>
						</>
						: <></>
					}
				</div>
			)
		}
	}
}

class Austria_MappDropPoint extends React.Component {

	// Set up states for updating map 
	constructor(props) {
		super(props);
		this.state = {
			lng: 13.42176,
			lat: 47.64365,
			zoom: 6.5,
			mapita: 9,
			tyle: 'mapbox://styles/mapbox/satellite-streets-v11',
			coord: this.props.params,
			hideNavs: false,
		}
	}

	//Update several fields
	updateFields(list) {
		this.setState(list);
	}

	//Update map on component update
	updateField(card, value) {
		this.setState({
			...this.state,
			[card]: value
		});
	}

	//Update style
	handleTyle(value) {
		this.setState({ tyle: value }, this.componentDidMount);
	}

	//Update coordinates
	handleCoord(center, zoom) {
		const { lng, lat } = center;
		this.setState({ zoom: zoom, lat: lat, lng: lng })
	}

	componentDidMount() {
		// Set up map 
		const map = new mapboxgl.Map({
			container: this.mapContainer,
			style: this.state.tyle,
			center: [this.state.lng, this.state.lat],
			zoom: this.state.zoom,
		});

		map.on('load', () => {

			// Add zoom and rotation controls to the map.
			map.addControl(new mapboxgl.NavigationControl({ showCompass: false, showZoom: false }), 'top-left');
			map.addControl(new MapboxGeocoder({ accessToken: mapboxgl.accessToken, mapboxgl: mapboxgl, marker: false }), 'top-left');

			//Circles concentric
			//Add shapes to the map
			map.addSource('data-concentric-circle', {
				'type': 'geojson',
				'data': lines_geojson,
				'generateId': true

			});
			map.addLayer({
				'id': 'data-concentric-circle',
				'type': 'fill',
				'source': 'data-concentric-circle',
				'layout': {
					// Make the layer visible by default.
					'visibility': 'visible'
				},
				'paint': {
					'fill-color': [
						'match', ['get', 'percentile'],
						'0-5', '#602437',
						'5-15', '#8a2846',
						'15-50', '#e05780',
						'50++', '#ffc2d4',
						'black'],
					'fill-opacity': 0.5,
				},
			});

			map.addLayer({
				'id': 'data-concentric-circle-labels',
				'type': 'symbol',
				'source': 'data-concentric-circle',
				"minzoom": 10,
				'layout': {
					'text-field': ['get', 'percentile'],
					'text-font': [
						'Open Sans Bold',
						'Arial Unicode MS Bold'
					],
					'text-size': 15,
					'text-transform': 'uppercase',
					'text-letter-spacing': 0.05,
					'text-offset': [0, 1],
					'visibility': 'visible'
				}
			});

			// add lines to the map
			map.addSource('data-swiss-destinations', {
				'type': 'geojson',
				'data': lines_geojson,
				'generateId': true,
				'lineMetrics': true
			});

			map.addLayer({
				'id': 'swiss-destinations',
				'type': 'line',
				'source': 'data-swiss-destinations',
				'layout': {
					// Make the layer visible by default.
					'visibility': 'visible'
				},
				'paint': {
					'line-color': '#027f80',
					'line-width': 2,
				}
			});

			//Tyle visualization
			//Add shapes to the map
			map.addSource('data-swiss-tyle', {
				'type': 'geojson',
				'data': lines_geojson,
				'generateId': true
			});

			map.addLayer({
				'id': 'data-swiss-tyle',
				'type': 'fill',
				'source': 'data-swiss-tyle',
				'layout': {},
				'paint': {
					'fill-color': ['get', 'color_combined'],
					'fill-opacity': 0.4
				}
			});

			// Add a black outline around the polygon
			map.addLayer({
				'id': 'outline-data-swiss-tyle',
				'type': 'line',
				'source': 'data-swiss-tyle',
				'layout': {},
				'paint': {
					'line-color': ['get', 'color_combined'],
					'line-width': 1
				}
			});

			map.addLayer({
				'id': 'data-swiss-tyle-labels',
				'type': 'symbol',
				'source': 'data-swiss-tyle',
				"minzoom": 10,
				'layout': {
					'text-field': ['get', 'total_trips'],
					'text-font': [
						'Open Sans Bold',
						'Arial Unicode MS Bold'
					],
					'text-size': 15,
					'text-transform': 'uppercase',
					'text-letter-spacing': 0.05,
					'text-offset': [0, 1],
					'visibility': 'visible'
				}
			});

			// Add circle 
			map.addSource('point', {
				'type': 'geojson',
				'data': point_geojson
			});

			map.addLayer({
				'id': 'point',
				'type': 'circle',
				'source': 'point',
				'paint': {
					'circle-radius': 10,
					'circle-color': '#e32a8d' // blue color
				}
			});

		});

		this.setState({
			mapita: map,
			zoom: map.getZoom(),
			center: map.getCenter()
		});

		// When a click event occurs on a feature in the places layer, open a popup at the
		// location of the feature, with description HTML from its properties.
		map.on('click', 'data-concentric-circle', (e) => {
			let total_trips_weekdays = numberWithCommas((e.features[0].properties.total_trips).toFixed(0));
			let car_trips_weekdays = numberWithCommas((e.features[0].properties.car_trips).toFixed(0));
			let train_trips_weekdays = numberWithCommas((e.features[0].properties.train_trips).toFixed(0));
			let walking_trips_weekdays = numberWithCommas((e.features[0].properties.walking_trips).toFixed(0));

			let total_pkm_weekdays = numberWithCommas((e.features[0].properties.total_pkm).toFixed(0));
			let car_pkm_weekdays = numberWithCommas((e.features[0].properties.car_pkm).toFixed(0));
			let train_pkm_weekdays = numberWithCommas((e.features[0].properties.train_pkm).toFixed(0));
			let walking_pkm_weekdays = numberWithCommas((e.features[0].properties.walking_pkm).toFixed(0));

			let percentile = e.features[0].properties.percentile
			if (percentile === '0-5') {
				percentile = '0km to 5km'
			} else if (percentile === '5-15') {
				percentile = '5km to 15km'
			} else if (percentile === '15-50') {
				percentile = '15km to 50km'
			} else if (percentile === '50++') {
				percentile = '50+km'
			}

			let html = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Within this range of distance: </span><br/>' + percentile + '</p><p style="margin-block-end: 0em; margin-top:1em"><span style="font-weight: bold;">Number trips</span><br/></p><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle;"><thead><tr><th>Cars &#128664;</th><th>Trains &#x1f686;</th><th>Walking &#128694;</th><th>Total</th></tr></thead><tbody><tr><td>' + car_trips_weekdays + '</td><td>' + train_trips_weekdays + '</td><td>' + walking_trips_weekdays + '</td><td>' + total_trips_weekdays + '</td></tr></tbody></table><p style="margin-block-end: 0em; margin-top:1em"><span style="font-weight: bold;">Passenger Km</span><br/></p><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle;"><thead><tr><th>Cars &#128664;</th><th>Trains &#x1f686;</th><th>Walking &#128694;</th><th>Total</th></tr></thead><tbody><tr><td>' + car_pkm_weekdays + '</td><td>' + train_pkm_weekdays + '</td><td>' + walking_pkm_weekdays + '</td><td>' + total_pkm_weekdays + '</td></tr></tbody></table>';
			new mapboxgl.Popup()
				.setLngLat(e.lngLat)
				.setHTML(html).addTo(map);
		});

		//Tyle information
		// When a click event occurs on a feature in the places layer, open a popup at the
		// location of the feature, with description HTML from its properties.
		map.on('click', 'data-swiss-tyle', (e) => {

			console.log(e.features[0].properties)
			let num_passengers_car_wd = e.features[0].properties.car_trips;
			let num_passengers_train_wd = e.features[0].properties.train_trips;
			let num_passengers_walking_wd = e.features[0].properties.walking_trips;
			let num_passengers_wd_total = e.features[0].properties.total_trips;

			let num_passengers_car_wd_str = '';
			let num_passengers_train_wd_str = '';
			let num_passengers_wd_total_str = '';
			let num_passengers_walking_wd_str = '';

			(typeof num_passengers_car_wd == 'number' & num_passengers_car_wd > 0) ? num_passengers_car_wd = (num_passengers_car_wd).toFixed(0) : num_passengers_car_wd = '-';
			(num_passengers_car_wd !== '-') ? num_passengers_car_wd_str = numberWithCommas(num_passengers_car_wd) : num_passengers_car_wd_str = '-';

			(typeof num_passengers_train_wd == 'number' & num_passengers_train_wd > 0) ? num_passengers_train_wd = (num_passengers_train_wd).toFixed(0) : num_passengers_train_wd = '-';
			(num_passengers_train_wd !== '-') ? num_passengers_train_wd_str = numberWithCommas(num_passengers_train_wd) : num_passengers_train_wd_str = '-';

			(typeof num_passengers_walking_wd == 'number' & num_passengers_walking_wd > 0) ? num_passengers_walking_wd = (num_passengers_walking_wd).toFixed(0) : num_passengers_walking_wd = '-';
			(num_passengers_walking_wd !== '-') ? num_passengers_walking_wd_str = numberWithCommas(num_passengers_walking_wd) : num_passengers_walking_wd_str = '-';

			(typeof num_passengers_wd_total == 'number' & num_passengers_wd_total > 0) ? num_passengers_wd_total = (num_passengers_wd_total).toFixed(0) : num_passengers_wd_total = '-';
			if (num_passengers_wd_total < (Number(num_passengers_train_wd) + Number(num_passengers_car_wd)) & num_passengers_wd_total !== '-') {
				num_passengers_wd_total = Number(num_passengers_train_wd) + Number(num_passengers_car_wd);
			}
			(num_passengers_wd_total !== '-') ? num_passengers_wd_total_str = numberWithCommas(num_passengers_wd_total) : num_passengers_wd_total_str = '-';

			let html_trips = '<table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle;"><tbody><tr><th>Duration in car</th></tr><tr><td>' + e.features[0].properties.duration_car_text + '</td></tr><tr><th>Duration in public transport</th></tr><tr><td>' + e.features[0].properties.duration_train_text + '</td></tr><tr><th>Duration of last-mile</th></tr><tr><td>' + e.features[0].properties.duration_last_mile_text + '</td></tr><tr><th style="color:#5c5b5b; font-size:10px">Number of trains: ' + (e.features[0].properties.n_trains === 0 ? 'None' : e.features[0].properties.n_trains) + '</th></tr></tbody></table>';
			let html_name = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">' + e.features[0].properties.KG + '</span></p>'
			let html = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Passengers per day</span></p><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle;"><thead><tr><th>&#x1f46a; &#128664;</th><th>&#x1f46a; &#x1f686;</th><th>&#x1f46a;  &#x1f6b6;</th><th>Total</th></tr></thead><tbody><tr><td>' + num_passengers_car_wd_str + '</td><td>' + num_passengers_train_wd_str + '</td><td>' + num_passengers_walking_wd_str + '</td><td>' + num_passengers_wd_total_str + '</td></tr></tbody></table>';
			new mapboxgl.Popup()
				.setLngLat(e.lngLat)
				.setHTML(html_name + html + html_trips).addTo(map);

		});

		//On click move the point
		map.on('click', (event) => {
			const coords = event.lngLat;
			if (!generated_info) {
				// Print the coordinates of where the point had
				// finished being dragged to on the map.

				map.getCanvas().style.cursor = '';

				// Unbind mouse/touch events
				map.off('mousemove', onMove);
				map.off('touchmove', onMove);

				point_geojson.features[0].geometry.coordinates = [coords.lng, coords.lat];
				map.getSource('point').setData(point_geojson);
			}
		});

		// Change the cursor to a pointer when the mouse is over the places layer.
		map.on('mouseenter', 'data-swiss-tyle', () => {
			map.getCanvas().style.cursor = 'pointer';

		});

		// Change it back to a pointer when it leaves.
		map.on('mouseleave', 'data-swiss-tyle', () => {
			map.getCanvas().style.cursor = '';
		});

		// When the cursor enters a feature in
		// the point layer, prepare for dragging.
		map.on('mouseenter', 'point', () => {
			map.setPaintProperty('point', 'circle-color', '#9e2e6a');
			map.getCanvas().style.cursor = 'move';
		});

		map.on('mouseleave', 'point', () => {
			map.setPaintProperty('point', 'circle-color', '#9e2e6a');
			map.getCanvas().style.cursor = '';
		});

		function onMove(e) {
			const coords = e.lngLat;
			// Set a UI indicator for dragging.
			map.getCanvas().style.cursor = 'grabbing';

			// Update the Point feature in `geojson` coordinates
			// and call setData to the source layer `point` on it.
			point_geojson.features[0].geometry.coordinates = [coords.lng, coords.lat];
			map.getSource('point').setData(point_geojson);
		}

		function onUp(e) {
			const coords = e.lngLat;
			// Print the coordinates of where the point had
			// finished being dragged to on the map.

			map.getCanvas().style.cursor = '';

			// Unbind mouse/touch events
			map.off('mousemove', onMove);
			map.off('touchmove', onMove);

			point_geojson.features[0].geometry.coordinates = [coords.lng, coords.lat];
			map.getSource('point').setData(point_geojson);
		}

		map.on('mousedown', 'point', (e) => {
			// Prevent the default map drag behavior.
			e.preventDefault();

			map.getCanvas().style.cursor = 'grab';

			map.on('mousemove', onMove);
			map.once('mouseup', onUp);
		});

		map.on('touchstart', 'point', (e) => {
			if (e.points.length !== 1) return;

			// Prevent the default map drag behavior.
			e.preventDefault();

			map.on('touchmove', onMove);
			map.once('touchend', onUp);
		});

		// Fullscreen map 
		//map.addControl(new mapboxgl.FullscreenControl());
	}

	render() {
		const { hideNavs } = this.state;

		return (
			<div>
				<div ref={el => this.mapContainer = el} style={{ width: '100%', height: '100vh' }}>
				</div>
				<AustriaRecommendationNavBarComponent />
				<HideElementsComponent map={this.state.mapita} updateField={(card, field, value) => this.updateField(card, field, value)} />
				<div style={{ display: hideNavs ? 'none' : 'block' }}>
					<BtnsComponent map={this.state.mapita} updateFields={(list) => this.updateFields(list)} coord={this.state.coord} />
				</div>
				<LayerTylesComponent map={this.state.mapita} tyle={this.state.tyle} handleTyle={(value) => this.handleTyle(value)} handleCoord={(value1, value2) => this.handleCoord(value1, value2)} />
			</div>
		)
	}
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => (
	// eslint-disable-next-line react/jsx-pascal-case
	<Austria_MappDropPoint
		{...props}
		params={useParams()}
	/>
); 