import React from "react";
import { IconContext } from "react-icons";
import { Link } from "react-router-dom";
import { BsPinMapFill, BsFillBuildingsFill } from "react-icons/bs";
import { BiNetworkChart,} from "react-icons/bi";
import { MdOutlineStackedBarChart } from "react-icons/md";
import { TbTablePlus } from "react-icons/tb";
import { IoIosTrain } from "react-icons/io";
import { SlGraph } from "react-icons/sl";

import AustriaRecommendationNavBarComponent from "../components/AustriaRecommendationNavBarComponent";

import './css/menu.css'


//Menu - Main view
class Austria_Menu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            current_page: 0,
            max_page: 1
        }
    }

    moveLeft(){
        let the_state =this.state.current_page;
        if(the_state>0){
            this.setState({current_page: the_state-1})
        }
    }

    moveRight(){
        let the_state =this.state.current_page;
        if(the_state<this.state.max_page){
            this.setState({current_page: the_state+1})
        }
    }
    
    render() {
        return (
            <div className="main-div-menu">
                <div className="main-div-menu-inner">
                    <div className="row-1">
                        <div className='image-main-menu'>
                            <img src={require('../assets/img/logo-navbar.png')} alt="Logo ÖV42 by 42hacks" className='logo-img' />
                            <p className="powered-by-main-menu">Powered by <img src={require('../assets/img/invenium-logo-navbar.png')} alt="invenium" className='image-swisscom-main-menu' /> <span className="swisscom-font"></span></p>
                            <p className="title-welcome">Welcome to our Mobility Insights' Platform </p>
                        </div>
                    </div>
                         <div className="row" style={{ justifyContent: 'center'}}>
                         <div className="column-33" style={{ width: '33%' }}>
                            <Link to="/austria/od-insights" style={{ textDecoration: 'none' }}>
                            <div className="centered-item-menu" >
                                    <div className="item-menu-inner">
                                        <IconContext.Provider value={{ color: "#e32a8d", size: '2.2em' }}>
                                            <div>
                                                <BiNetworkChart />
                                            </div>
                                        </IconContext.Provider>
                                        <p className="title">Insights in Modal Split</p>
                                        <p className="label">A comprehensive analysis of Austria's travel data at municipality and postal code levels, revealing transportation trends, passenger pkms, and modal splits' evolution through time.</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="column-33" style={{ width: '33%' }}>
                            <Link to="/austria/drop-a-point" style={{ textDecoration: 'none' }}>
                                <div className="centered-item-menu">
                                    <div className="item-menu-inner">
                                        <IconContext.Provider value={{ color: "#e32a8d", size: '2.2em' }}>
                                            <div>
                                                <BsPinMapFill />
                                            </div>
                                        </IconContext.Provider>
                                        <p className="title">Point Analysis</p>
                                        <p className="label">Enables users to select any location in Austria and obtain in-depth insights into top travel destinations from that point, assisting in informed transportation planning and infrastructure development decisions.</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="column-33" style={{ width: '33%' }}>
                            <Link to="/austria/mobility-trends" style={{ textDecoration: 'none' }}>
                                <div className="centered-item-menu">
                                    <div className="item-menu-inner">
                                        <IconContext.Provider value={{ color: "#e32a8d", size: '2.2em' }}>
                                            <div>
                                                <SlGraph />
                                            </div>
                                        </IconContext.Provider>
                                        <p className="title">Mobility Trends</p>
                                        <p className="label">Enables users to select any location in Austria and obtain in-depth insights into top travel destinations from that point, assisting in informed transportation planning and infrastructure development decisions.</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        </div>
                        <div className="row" style={{ justifyContent: 'center'}}>
                        <div className="column-1-50-m">
                            <Link to="/austria/traffic-3d" style={{ textDecoration: 'none' }}>
                            <div className="centered-item-menu">
                                    <div className="item-menu-inner">
                                        <IconContext.Provider value={{ color: "#027f80", size: '2.2em' }}>
                                            <div>
                                                <MdOutlineStackedBarChart />
                                            </div>
                                        </IconContext.Provider>
                                        <p className="title">Traffic Heatmap<sub><i>Beta</i></sub></p>
                                        <p className="label">Visualize the traffic hotspots arrangement in Austria based on a heatmap categorization. Analyze how the distribution change based on passenger kilometers (pKm) or number of daily trips.</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="column-2-50-m">
                            <Link to="/austria/potential-employers-3d" style={{ textDecoration: 'none' }}>
                            <div className="centered-item-menu">
                                    <div className="item-menu-inner">
                                        <IconContext.Provider value={{ color: "#027f80", size: '2.2em' }}>
                                            <div>
                                                <BsFillBuildingsFill />
                                            </div>
                                        </IconContext.Provider>
                                        <p className="title">Potential Employers<sub><i>Beta</i></sub></p>
                                        <p className="label">Reveals the traffic, in a 3D mapping visualization, of 1Km tiles or individual companies to ease traffic in Austria. Measures based on last mile optimization, working time, behaviour change, bicycle culture and more.</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="row" style={{ justifyContent: 'center'}}>
                    <div className="column-1-50-m">
                    <Link to="/austria/5-in-5" style={{ textDecoration: 'none' }}>
                            <div className="centered-item-menu">
                                    <div className="item-menu-inner">
                                    <IconContext.Provider value={{ color: "#027f80", size: '2.2em' }}>
                                            <div>
                                                <TbTablePlus />
                                            </div>
                                        </IconContext.Provider>
                                        <p className="title">5-in-5 Dashboard<sub><i>Beta</i></sub></p>
                                        <p className="label"> Uncover the essential metrics required to redirect 5% of traffic in Switzerland and identify the pivotal companies driving this change. Aggregate data based on factors such as the number of employees, generated trips, etc.</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="column-2-50-m">
                        <Link to="/austria/miv-hotspots" style={{ textDecoration: 'none' }}>
                                <div className="centered-item-menu">
                                    <div className="item-menu-inner">
                                        <IconContext.Provider value={{ color: "#027f80", size: '2.2em' }}>
                                            <div>
                                                <IoIosTrain />
                                            </div>
                                        </IconContext.Provider>
                                        <p className="title">Dashboard MIV Hotspots<sub><i>Beta</i></sub></p>
                                        <p className="label">Pinpoint the top Motorized Individual Vehicle (MIV) Hotspots and quantify their annual contribution to Austria's overall traffic. Uncover details on the impacted region, category type, and canton affected.</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                   
</div>
                </div>
                <AustriaRecommendationNavBarComponent />
            </div>

        )
    }
}

export default Austria_Menu;