import React from "react";
import './layer-btn.css';
import { CgMenuGridR } from "react-icons/cg";
import { IconContext } from "react-icons";
import { Link } from "react-router-dom";

export default class Hilti_LayerMenuComponent extends React.Component {

	constructor() {
		super()
		this.state = {
			showOptions: false,
		}
	}

	render() {

		return (
			<div>
				<div className='map-layer-button'>
					<div className='map-layer-button-inner'>
					<Link to="/hilti/menu" style={{ textDecoration: 'none' }}>

						<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
							<div>
								<CgMenuGridR />
							</div>
						</IconContext.Provider>
						</Link>

					</div>
				</div>
			</div>
		)
	}
}