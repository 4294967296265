/* eslint-disable react/style-prop-object */
import React from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { Form, Button } from 'react-bootstrap';

import '../../components/layer-tyles.css';
import '../css/map-potential-routes.css';
import '../css/map-simulation.css'
import RecommendationNavBarComponent from "../../components/RecommendationNavBarComponent";

const _ = require('lodash');
let isPause = false;

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const URL = process.env.REACT_APP_URL_SERVER_BACK;

class BtnsComponent extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			mapa: this.props.map
		}
	}

	componentDidMount() {
		this.handlePausePlayBtn = this.handlePausePlayBtn.bind(this);
    }

    componentDidUpdate(prevProps) {
		if(this.props.map !== prevProps.map) {
		  this.setState({mapa: this.props.map});
		}
	}

    handlePausePlayBtn(){
        isPause = !isPause
    }


	render() {
        const {isPause} = this.state;
            return (
                <div>
                    <div className="modal-pause-play-slider">
                        <div className="modal-pause-play-slider-inner">
                            <Form onClick={this.handlePausePlayBtn.bind(this)}>
								<Button id="pause-play" className="generate-btn" variant="info">{isPause ? 'Play': 'Pause'}</Button>
							</Form>
                        </div>
                    </div>
                </div>
            )
        }
	}

//Map - Time to Öv
class MappSimulation extends React.Component {

    // Set up states for updating map 
    constructor(props) {
        super(props);
        this.state = {
            lng: 9.048515,
			lat: 47.358582,
			zoom: 9,
			mapita: 9,
            tyle: 'mapbox://styles/mapbox/light-v10',
            month_year: '2022-08',
            current_station: 0,
            current_selected_id: -1
        }
        this.handleTyle = this.handleTyle.bind(this);
    }

    //Update several fields
    updateFields(list) {
        this.setState(list);
    }

    //Update map on component update
    updateField(card, value) {
        this.setState({
            ...this.state,
            [card]: value
        });
    }

    //Update coordinates
    handleCoord(center, zoom) {
        const { lng, lat } = center;
        this.setState({ zoom: zoom, lat: lat, lng: lng })
    }

    //Update style
    handleTyle(value) {
        this.setState({ tyle: value }, this.componentDidMount);
    }

    componentDidMount() {
        // Set up map 
        const map = new mapboxgl.Map({
            container: this.mapContainer,
            style: this.state.tyle,
            center: [this.state.lng, this.state.lat],
            zoom: this.state.zoom,
        });

        const pausePlayButton = document.getElementById('pause-play');

        map.on('load', () => {
            // Add zoom and rotation controls to the map.
            map.addControl(new mapboxgl.NavigationControl({ showCompass: false, showZoom: false }), 'top-left');
            map.addControl(new MapboxGeocoder({ accessToken: mapboxgl.accessToken, mapboxgl: mapboxgl, marker: false }), 'top-left');

            let time_current ='00:00:00'

            function AddMinutesToDate() {
                //Add 5 minutes
                let time = new Date("1979-12-17T"+time_current);
                time.setMinutes(time.getMinutes() + 5)
                var hours = time.getHours();
                var minutes = time.getMinutes();
                if(hours<=9)
                {
                    hours = '0'+hours
                }
                if(minutes<=9)
                {
                    minutes = '0'+minutes
                }
                var str_time = hours+':'+minutes+':00'
                time_current = str_time
                return str_time
           }

            // Add a source and layer displaying a point which will be animated in a circle.
            map.addSource('point', {
                'type': 'geojson',
                'data': URL+'simulation/public_transport_time_series/'+time_current,
            });

            map.addLayer({
                'id': 'point',
                'source': 'point',
                'type': 'circle',
                'paint': {
                    'circle-radius': 4,
                    'circle-color': '#007cbf'
                }
            });

            function advanceTime(){
                AddMinutesToDate();
                let current_url = URL+'simulation/public_transport_time_series/'+time_current;
                map.getSource('point').setData(current_url);
            }

            function animateMarker(timestamp) {
                // Update the data to a new position based on the animation timestamp. The
                // divisor in the expression `timestamp / 1000` controls the animation speed.
                setInterval(advanceTime(), 2000)
                 
                // Request the next frame of the animation.
                requestAnimationFrame(animateMarker);
            }

            // click the button to pause or play
            pausePlayButton.addEventListener('click', () => {
                pausePlayButton.classList.toggle('pause');
            if (pausePlayButton.classList.contains('pause')) {
                cancelAnimationFrame();
            } else {
                animateMarker(0);
            }
        });
                 
            // Start the animation.
            animateMarker(0);
        });

        this.setState({
            mapita: map,
            zoom: map.getZoom(),
        });

    }

    render() {
        return (
            <div>
                <div ref={el => this.mapContainer = el} style={{ width: '100%', height: '100vh' }}>
                </div>
                <RecommendationNavBarComponent />
                <BtnsComponent map={this.state.mapita} updateFields={(list) => this.updateFields(list)} />
            </div>
        )
    }
}

export default MappSimulation;