import React from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import Slider from 'rc-slider';
import '../css/map-recommendation.css';
import { IconContext } from "react-icons";
import axios from 'axios';
import { VscGraphLine } from "react-icons/vsc";
import Select from 'react-select';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import Chart from 'chart.js/auto';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { BsLayersHalf } from "react-icons/bs";
import { Form, Button } from 'react-bootstrap';
import { Line } from 'react-chartjs-2';
import { RiFullscreenFill, RiFullscreenExitFill } from "react-icons/ri";
import RecommendationNavBarComponent from "../../components/RecommendationNavBarComponent";
import ExportExcel from "../../utils/ExportExcel";

import '../../components/layer-tyles.css';
import '../css/mapbox-gl-geocoder.css';
import '../css/map-recommendation-deckgl.css';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const URL = process.env.REACT_APP_URL_SERVER_BACK;

const _ = require('lodash');

function numberWithCommas(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

let URL_line = URL + "train_vs_cars/2020-06,bfs/days,0,0,0,-2000,2000,0,290000,500,182000,0,100,0,0,1,4";
let URL_line_Aus = URL + "train_vs_cars/austria/1";

let URL_origin = URL + "heatmap_t_vs_c/plz_geojson/2020-06,bfs/empty,empty";
let URL_destination = URL + "heatmap_t_vs_c/plz_heatmap/2020-06,bfs/empty,empty";
//Layer Tyles Selection - Side Menu
class LayerTylesComponent extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			showOptions: false,
			mapa: this.props.map,
		}
		this.hideComponent = this.hideComponent.bind(this);
		this.handleTyleEvent = this.handleTyleEvent.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (this.props.map !== prevProps.map) {
			this.setState({ mapa: this.props.map })
		}
	}

	hideComponent(name) {
		switch (name) {
			case "showOptions":
				this.setState({ showOptions: !this.state.showOptions });
				break;
			default:
				// eslint-disable-next-line no-unused-expressions
				null;
		}
	}

	handleTyleEvent(event) {
		switch (event) {
			case "default":
				this.props.handleTyle("mapbox://styles/mapbox/light-v10");
				break;
			case "outdoors":
				this.props.handleTyle("mapbox://styles/mapbox/outdoors-v11");
				break;
			case "satellite":
				this.props.handleTyle("mapbox://styles/mapbox/satellite-streets-v11");
				break;
			case "streets":
				this.props.handleTyle("mapbox://styles/mapbox/streets-v11");
				break;
			case "dark":
				this.props.handleTyle("mapbox://styles/mapbox/dark-v10");
				break;
			default:
				// eslint-disable-next-line no-unused-expressions
				null;
		}
		this.props.handleCoord(this.state.mapa.getCenter(), this.state.mapa.getZoom());
	}

	render() {
		const { showOptions } = this.state;

		return (
			<div>
				{showOptions ? <>
					<div id='layer-options' className='map-layer-options-tyles'>
						<div className='map-layer-option-dark' onClick={() => this.handleTyleEvent("dark")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/Dark.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Dark</p>
						</div>
						<div className='map-layer-option-streets' onClick={() => this.handleTyleEvent("streets")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/Streets.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Streets</p>
						</div>
						<div className='map-layer-option-satellite' onClick={() => this.handleTyleEvent("satellite")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/Satellite.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Satellite</p>
						</div>
						<div className='map-layer-option-outdoors' onClick={() => this.handleTyleEvent("outdoors")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/Outdoors.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Outdoors</p>
						</div>
						<div className='map-layer-option-default' onClick={() => this.handleTyleEvent("default")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/White.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Default</p>
						</div>
					</div>
				</> : <></>}
				<div className='map-layer-tyles'>
					<div className='map-layer-tyles-inner' onClick={() => this.hideComponent("showOptions")}>
						<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
							<div>
								<BsLayersHalf />
							</div>
						</IconContext.Provider>
					</div>
				</div>
			</div>
		)
	}
}

class HideElementsComponent extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			hideNavs : false,
			mapa: this.props.map,
		}
	}

	hideComponent() {
		let newHideNavs = !this.state.hideNavs;
		this.setState({hideNavs: newHideNavs})
		this.props.updateField("hideNavs", newHideNavs);
	}

	render() {

		const { hideNavs } = this.state;

		return (
			<div>
				{hideNavs ? <>
					<div className='map-close-navs'>
						<div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
								<div>
									<RiFullscreenExitFill />
								</div>
							</IconContext.Provider>
						</div>
					</div>
				</> : <>
					<div className='map-close-navs'>
						<div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
								<div>
									<RiFullscreenFill />
								</div>
							</IconContext.Provider>
						</div>
					</div>
				</>}
			</div>
		)
	}
}

let abortController = new AbortController();
let signal = abortController.signal;

class GraphComponent extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			mapa: this.props.map,
			hasInfo: this.props.hasInfo,
			graphParam: this.props.graphParam,
			take_od: 0,
			respWvsW: this.handleFetchDataWsW(this.props.graphParam),
			respModalPKM: this.handleFetchDataModalPKM(this.props.graphParam),
			respModalTrips: this.handleFetchDataModalTrips(this.props.graphParam),
			respTraveledKm: this.handleFetchDataTraveledKm(this.props.graphParam, 0),
			respCO2: this.handleFetchDataCO2(this.props.graphParam, 0),
			type: 'cars',
			respTopOD: this.handleTopODContributors(this.props.graphParam, 'cars', this.props.month_year),
			origin_name: '',
			destination_name: '',
		}
	}

	componentDidMount() {
		this.handleFetchDataWsW = this.handleFetchDataWsW.bind(this);
		this.handleFetchDataModalPKM = this.handleFetchDataModalPKM.bind(this);
		this.handleFetchDataModalTrips = this.handleFetchDataModalTrips.bind(this);
		this.handleFetchDataTraveledKm = this.handleFetchDataTraveledKm.bind(this);
		this.handleFetchDataCO2 = this.handleFetchDataCO2.bind(this);
		this.handleAggregation = this.handleAggregation.bind(this);
		this.handleGraphComponent = this.handleGraphComponent.bind(this);
		this.handleTopODContributors = this.handleTopODContributors.bind(this);
		this.schemaType = this.schemaType.bind(this);

	}

	componentDidUpdate(prevProps) {
		if (this.props.map !== prevProps.map) {
			this.setState({ mapa: this.props.map });
		}
		if (this.props.hasInfo !== prevProps.hasInfo) {
			this.setState({ hasInfo: this.props.hasInfo });
		}
		if (this.props.month_year !== prevProps.month_year) {
			this.handleTopODContributors(this.props.graphParam, this.state.type, this.props.month_year);
		}
		if (this.props.graphParam !== prevProps.graphParam) {
			abortController.abort();
			abortController = new AbortController();
			signal = abortController.signal;
			this.setState({ graphParam: this.props.graphParam });
			this.handleFetchDataWsW(this.props.graphParam);
			this.handleFetchDataModalPKM(this.props.graphParam);
			this.handleFetchDataModalTrips(this.props.graphParam);
			this.handleFetchDataTraveledKm(this.props.graphParam, this.aggregation);
			this.handleFetchDataCO2(this.props.graphParam, this.aggregation);
			this.handleTopODContributors(this.props.graphParam, this.state.type, this.props.month_year);
		}
	}

	async handleFetchDataWsW(param) {
		if (typeof param !== "undefined") {
			fetch(URL + 'graph_weekdays_vs_weekends/' + param, { signal })
				.then((res) => res.json())
				.then((json) => {
					this.setState({ respWvsW: json });
					this.handleName(json['destination'], 'destination');
					this.handleName(json['origin'], 'origin');
				})
		}
	}

	async handleFetchDataModalTrips(param) {
			fetch(URL + 'graph_modal_split_trips/' + param, { signal })
				.then((res) => res.json())
				.then((json) => {
					for (var i = 0; i < json.modal_split_trains.length; i++) {
						json.modal_split_trains[i] = json.modal_split_trains[i] * 100
					}
					return json
				})
				.then((json) => {
					this.setState({ respModalTrips: json });
					this.handleName(json['destination'], 'destination');
					this.handleName(json['origin'], 'origin');
				})
	}

	async handleFetchDataModalPKM(param) {
			fetch(URL + 'graph_modal_split_pkm/' + param, { signal })
				.then((res) => res.json())
				.then((json) => {
					for (var i = 0; i < json.modal_split.length; i++) {
						json.modal_split[i] = json.modal_split[i] * 100
					}
					return json
				})
				.then((json) => {
					this.setState({ respModalPKM: json });
					this.handleName(json['destination'], 'destination');
					this.handleName(json['origin'], 'origin');
				})
	}

	async handleName(id, startEndPoint) {
		if (typeof id !== "undefined" && id !== 'empty') {
			let division = this.state.graphParam.split(',')[0]
			fetch(URL + 'division_name/{0}/{1}'.format(division, id))
				.then((res) => res.json())
				.then((res) => { (startEndPoint === 'origin' ? this.setState({ origin_name: res[0] }) : this.setState({ destination_name: res[0] })) })
		}
	}

	async handleFetchDataCO2(param, aggregationParam) {
			let take_od = 0
				if (typeof aggregationParam === 'undefined') {
					take_od = this.state.take_od;
				}
				else {
					take_od = aggregationParam;
				}
			fetch(URL + 'graph_co2_emissions/' + param + ',' + take_od, { signal })
				.then((res) => res.json())
				.then((json) => {
					this.setState({ respCO2: json });
					this.handleName(json['destination'], 'destination');
					this.handleName(json['origin'], 'origin');
				})
	}

	async handleTopODContributors(param, type, date) {
			fetch(URL + 'graph_top_destinations/' + param + ',' + type + ',' + date, { signal })
				.then((res) => res.json())
				.then((json) => {
					this.setState({ respTopOD: json });
				})
	}

	async handleFetchDataTraveledKm(param, aggregationParam) {
			let take_od = 0
				if (typeof aggregationParam === 'undefined') {
					take_od = this.state.take_od;
				}
				else {
					take_od = aggregationParam;
				}
			fetch(URL + 'graph_traveled_km/' + param + ',' + take_od, { signal })
				.then((res) => res.json())
				.then((json) => {
					this.setState({ respTraveledKm: json });
					this.handleName(json['destination'], 'destination');
					this.handleName(json['origin'], 'origin');
				})
	}

	handleAggregation() {
		let aggregation = 0;
		if (this.state.take_od === 1) { this.setState({ take_od: aggregation }); }
		else {
			aggregation = 1;
			this.setState({ take_od: aggregation });
		}

		this.handleFetchDataTraveledKm(this.state.graphParam, aggregation);
	}

	handleGraphComponent(name) {
		this.setState({ showGraph: !this.state.showGraph });
	}

	getExcelPKm() {
		var all = [];
		var length = this.state.respTraveledKm['date']?.length
		if (length !== 0 || length !== 'undefined') {
			for (var i = 0; i < length; i++) {
				all.push({
					"Date": this.state.respTraveledKm['date'][i],
					"Passenger Km Car": this.state.respTraveledKm['traveled_km_cars'][i],
					"Passenger Km Train": this.state.respTraveledKm['traveled_km_trains'][i]
				})
			}
		}
		return all;
	}

	getExcelModalTrips() {
		var all = [];
		var length = this.state.respModalTrips['date']?.length
		if (length !== 0 || length !== 'undefined') {
			for (var i = 0; i < length; i++) {
				all.push({
					"Date": this.state.respModalTrips['date'][i],
					"Modal Split Train": (this.state.respModalTrips['modal_split_trains'][i]) + '%',
				})
			}
		}
		return all;
	}

	getExcelModalPKM() {
		var all = [];
		var length = this.state.respModalPKM['date']?.length
		if (length !== 0 || length !== 'undefined') {
			for (var i = 0; i < length; i++) {
				all.push({
					"Date": this.state.respModalPKM['date'][i],
					"Modal Split Train": (this.state.respModalPKM['modal_split'][i]) + '%',
				})
			}
		}
		return all;
	}

	getExcelWvsW() {
		var all = [];
		var length = this.state.respWvsW['date']?.length
		if (length !== 0 || length !== 'undefined') {
			for (var i = 0; i < length; i++) {
				all.push({
					"Date": this.state.respWvsW['date'][i],
					"Total trips during weekdays": this.state.respWvsW['total_trips_weekdays'][i],
					"Total trips during weekends": this.state.respWvsW['total_trips_weekend'][i],
				})
			}
		}
		return all;
	}

	getExcelCo2() {
		var all = [];
		var length = this.state.respCO2['date']?.length
		if (length !== 0 || length !== 'undefined') {
			for (var i = 0; i < length; i++) {
				all.push({
					"Date": this.state.respCO2['date'][i],
					"Tons of CO2 emitted by cars": this.state.respCO2['tons_of_co2_car_pkm'][i],
					"Tons of CO2 emitted by trains": this.state.respCO2['tons_of_co2_train_pkm'][i],
				})
			}
		}
		return all;
	}

	schemaType(event) {
		this.setState({ type: event.target.attributes.getNamedItem('id').value })
		this.handleTopODContributors(this.state.graphParam, event.target.attributes.getNamedItem('id').value, this.props.month_year);
	}

	render() {
		const { respWvsW } = this.state;
		const { respModalPKM } = this.state;
		const { respModalTrips } = this.state;
		const { respTraveledKm } = this.state;
		const { showGraph } = this.state;
		const { origin_name } = this.state;
		const { destination_name } = this.state;
		const { respCO2 } = this.state;

		const ExcelExportDataPKm = this.getExcelPKm();
		const ExcelExportDataModalTrips = this.getExcelModalTrips();
		const ExcelExportDataModalPKM = this.getExcelModalPKM();
		const ExcelExportDataWvsW = this.getExcelWvsW();
		const ExcelExportDataCO2 = this.getExcelCo2();

		const listODTrips = this.state.respTopOD?.origin?.map((trip, index) =>
			<tr key={index}>
				<td className="table-data-od">{this.state.respTopOD?.origin[index]}~{this.state.respTopOD?.destination[index]}</td>
				<td className="table-data-od">{this.state.respTopOD?.origin_name[index]}</td>
				<td className="table-data-od">{this.state.respTopOD?.destination_name[index]}</td>
				<td className="table-data-od">{numberWithCommas(this.state.respTopOD?.pkm_cars[index]?.toFixed(0))}</td>
				<td className="table-data-od">{numberWithCommas(this.state.respTopOD?.pkm_trains[index]?.toFixed(0))}</td>
			</tr>
		);


		const dataBar_WvsW = {
			labels: respWvsW['date'],
			datasets: [{
				label: 'Weekdays',
				data: respWvsW['total_trips_weekdays'],
				backgroundColor: [
					'rgba(2, 128, 6,0.2)'
				],
				borderColor: [
					'rgb(2, 128, 6)'

				],
				borderWidth: 1
			},
			{
				label: 'Weekends',
				data: respWvsW['total_trips_weekend'],
				backgroundColor: [
					'rgba(128, 2, 63, 0.2)'
				],
				borderColor: [
					'rgb(128, 2, 63)'
				],
				borderWidth: 1
			},
			]
		};

		const dataBar_ModalPKM = {
			labels: respModalPKM['date'],
			datasets: [{
				label: 'Modal Split for Trains',
				data: respModalPKM['modal_split'],
				backgroundColor: [
					'rgba(17, 148, 209, 0.2)'
				],
				borderColor: [
					'rgb(17, 148, 209)'
				],
				borderWidth: 1
			},
			]
		};

		const dataBar_ModalTrips = {
			labels: respModalTrips['date'],
			datasets: [{
				label: 'Modal Split for Trains',
				data: respModalTrips['modal_split_trains'],
				backgroundColor: [
					'rgba(221, 138, 230, 0.2)'
				],
				borderColor: [
					'rgb(221, 138, 230)'
				],
				borderWidth: 1
			},
			]
		}

		const dataBar_TraveledKm = {
			labels: respTraveledKm['date'],
			datasets: [{
				label: 'Traveled Km by Trains',
				data: respTraveledKm['traveled_km_trains'],
				backgroundColor: [
					'rgba(247, 137, 72, 0.2)'
				],
				borderColor: [
					'rgb(247, 137, 72)'
				],
				borderWidth: 1
			},
			{
				label: 'Traveled Km by Cars',
				data: respTraveledKm['traveled_km_cars'],
				backgroundColor: [
					'rgba(2,127,128,0.2)'
				],
				borderColor: [
					'rgb(2,127,128)'
				],
				borderWidth: 1
			},
			]
		};

		const options = {
			xAxes: [{
				type: 'time',
				ticks: {
					autoSkip: true,
					maxTicksLimit: 10
				}
			}],
			plugins: {
				title: {
					display: true,
					text: 'Percentage of Trains\' Modal Split (PKm) per Month'
				},
				legend: {
					position: 'bottom',
				},
			},
		};

		const options_trips = {
			xAxes: [{
				type: 'time',
				ticks: {
					autoSkip: true,
					maxTicksLimit: 10
				}
			}],
			plugins: {
				title: {
					display: true,
					text: 'Percentage of Trains\' Modal Split (Trips) per Month'
				},
				legend: {
					position: 'bottom',
				},
			},
		};

		const kmPerDay = {
			xAxes: [{
				type: 'time',
				ticks: {
					autoSkip: true,
					maxTicksLimit: 10
				}
			}],
			plugins: {
				legend: {
					position: 'bottom',
				},
				title: {
					display: true,
					text: 'Km per Month'
				}
			},
		};

		const numTripsPerDay = {
			xAxes: [{
				type: 'time',
				ticks: {
					autoSkip: true,
					maxTicksLimit: 10
				}
			}],
			plugins: {
				legend: {
					position: 'bottom',
				},
				title: {
					display: true,
					text: 'Number of Trips per Month'
				}
			},
		};

		const dataBar_CO2 = {
			labels: respCO2['date'],
			datasets: [{
				label: 'Tons of CO2 Emitted by Cars',
				data: respCO2['tons_of_co2_car_pkm'],
				backgroundColor: [
					'rgba(227,42,141,0.2)'
				],
				borderColor: [
					'rgb(227,42,141)'
				],
				borderWidth: 1
			},
			{
				label: 'Tons of CO2 Emitted by Trains',
				data: respCO2['tons_of_co2_train_pkm'],
				backgroundColor: [
					'rgba(31, 43, 177,0.2)'
				],
				borderColor: [
					'rgb(31, 43, 177)'
				],
				borderWidth: 1
			},
			]
		};

		const CO2_options = {
			xAxes: [{
				type: 'time',
				ticks: {
					autoSkip: true,
					maxTicksLimit: 10
				}
			}],
			plugins: {
				legend: {
					position: 'bottom',
				},
				title: {
					display: true,
					text: 'Tons of CO2 Emitted per Month'
				}
			},
		};

		return (
			<div>
				<div className='map-3d-button-modal'>
					<div className='map-3d-button-modal-inner' onClick={() => this.handleGraphComponent("showGraph")}>
						<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
							<div>
								<VscGraphLine />
							</div>
						</IconContext.Provider>
					</div>
				</div>
				{showGraph ? <>
					<div className="modal-accordion">
						<div className="modal-accordion-inner">
							<Tabs
								defaultActiveKey="Traveledkm"
								id="uncontrolled-tab-example"
								className="mb-3"
							>
								<Tab eventKey="Traveledkm" title="Passenger Km">
										{respTraveledKm['origin'] !== 'empty' && respTraveledKm['destination'] === 'empty' ? <>
											<h4>Origin: {origin_name}</h4>
										</> : <>
										</>}
										{respTraveledKm['destination'] !== 'empty' && respTraveledKm['origin'] === 'empty' ? <>
											<h4>Destination: {destination_name}</h4>
										</> : <>
										</>}
										{respTraveledKm['destination'] !== 'empty' && respTraveledKm['origin'] !== 'empty' ? <>
											<h4>Origin: {origin_name} ~ Destination: {destination_name}</h4>
										</> : <>
										</>}
										<Line data={dataBar_TraveledKm} options={kmPerDay} />
										<ExportExcel excelData={ExcelExportDataPKm} fileName={'Insights OD: PassengerKm'} />
								</Tab>
								<Tab eventKey="modalsplitpkm" title="Modal Split (PKm)">
										{respModalPKM['origin'] !== 'empty' && respModalPKM['destination'] === 'empty' ? <>
											<h4>Origin: {origin_name}</h4>
										</> : <>
										</>}
										{respModalPKM['destination'] !== 'empty' && respModalPKM['origin'] === 'empty' ? <>
											<h4>Destination: {destination_name}</h4>
										</> : <>
										</>}
										{respModalPKM['destination'] !== 'empty' && respModalPKM['origin'] !== 'empty' ? <>
											<h4>Origin: {origin_name} ~ Destination: {destination_name}</h4>
										</> : <>
										</>}
										<Line data={dataBar_ModalPKM} options={options} />
										<ExportExcel excelData={ExcelExportDataModalPKM} fileName={'Insights OD: Modal Split (PKm)'} />
								</Tab>
								<Tab eventKey="modalsplittrips" title="Modal Split (Trips)">
										{respModalTrips['origin'] !== 'empty' && respModalTrips['destination'] === 'empty' ? <>
											<h4>Origin: {origin_name}</h4>
										</> : <>
										</>}
										{respModalTrips['destination'] !== 'empty' && respModalTrips['origin'] === 'empty' ? <>
											<h4>Destination: {destination_name}</h4>
										</> : <>
										</>}
										{respModalTrips['destination'] !== 'empty' && respModalTrips['origin'] !== 'empty' ? <>
											<h4>Origin: {origin_name} ~ Destination: {destination_name}</h4>
										</> : <>
										</>}
										<Line data={dataBar_ModalTrips} options={options_trips} />
										<ExportExcel excelData={ExcelExportDataModalTrips} fileName={'Insights OD: Modal Split (Trips)'} />
								</Tab>
								<Tab eventKey="weekdayvsweekends" title="Number of Trips">
										{respWvsW['origin'] !== 'empty' && respWvsW['destination'] === 'empty' ? <>
											<h4>Origin: {origin_name}</h4>
										</> : <>
										</>}
										{respWvsW['destination'] !== 'empty' && respWvsW['origin'] === 'empty' ? <>
											<h4>Destination: {destination_name}</h4>
										</> : <>
										</>}
										{respWvsW['destination'] !== 'empty' && respWvsW['origin'] !== 'empty' ? <>
											<h4>Origin: {origin_name} ~ Destination: {destination_name}</h4>
										</> : <>
										</>}
										<Line data={dataBar_WvsW} options={numTripsPerDay} />
										<ExportExcel excelData={ExcelExportDataWvsW} fileName={'Insights OD: Trips Weekdays vs Weekends'} />
								</Tab>
								<Tab eventKey="co2" title="CO2 Emissions">
										{respCO2['origin'] !== 'empty' && respCO2['destination'] === 'empty' ? <>
											<h4>Origin: {origin_name}</h4>
										</> : <>
										</>}
										{respCO2['destination'] !== 'empty' && respCO2['origin'] === 'empty' ? <>
											<h4>Destination: {destination_name}</h4>
										</> : <>
										</>}
										{respCO2['destination'] !== 'empty' && respCO2['origin'] !== 'empty' ? <>
											<h4>Origin: {origin_name} ~ Destination: {destination_name}</h4>
										</> : <>
										</>}
										<Line data={dataBar_CO2} options={CO2_options} />
										<ExportExcel excelData={ExcelExportDataCO2} fileName={'Insights OD: CO2 Emission'} />
								</Tab>
								<Tab eventKey="toplocations" title="Top ODs">
										{(respModalTrips['destination'] !== 'empty' && respModalTrips['origin'] !== 'empty')
											?
											<><p className="no-data">Not possible to compute a calculation, when origin and destination are selected</p></>
											:
											<>
												<div className="row">
												<Form onChange={this.schemaType.bind(this)}>
													<div className="column-3">
														<Form.Check inline label=" Evaluate based on Cars Pkm" name="type" type='radio' id='cars' defaultChecked={true} />
													</div>
													<div className="column-3">
														<Form.Check inline label=" Evaluate based on Trains PKm" name="type" type='radio' id='trains' />
													</div>
												</Form>
												</div>
												
												<div className="row">
												<table className="top-od">
													<thead>
														<tr key='headers'>
															<th className="table-title-od">Identifier</th>
															<th className="table-title-od">Origin</th>
															<th className="table-title-od">Destination</th>
															<th className="table-title-od">PKm Cars</th>
															<th className="table-title-od">PKm Trains</th>
														</tr>
													</thead>
													<tbody>
														{listODTrips}
													</tbody>
												</table>
												</div>
											</>
										}
								</Tab>
							</Tabs>
						</div>
					</div>
				</> : <></>}
			</div>
		)
	}
}

//Mobility Insights Analyzer Component - Dropdown Menu and Radio Btn
class MobilityInsightsAnalyzerComponent extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			mapa: this.props.map,
			division: 'bfs',
			day_week: 'days',
			low_distance_km: 0,
			high_distance_km: 290,
			low_bound_diff: -40,
			high_bound_diff: 40,
			low_num_trips: 500,
			high_num_trips: 10000,
			low_num_trains: 1,
			high_num_trains: 4,
			low_modal: 0,
			high_modal: 100,
			origin: 0,
			destination: 0,
			selectOptions: [
				{ value: '0', label: 'All' },
				{ value: '1', label: 'Keep it that way' },
				{ value: '2', label: 'Marketing strategy' },
				{ value: '3', label: 'Point-to-point' },
				{ value: '4', label: 'Close line' },
				{ value: '5', label: 'Reduce connection time' },
				{ value: '6', label: 'Requires further analysis' }
			],
			heatmapPreference_: [
				{ value: '0', label: 'Origin' },
				{ value: '1', label: 'Destination' }
			],
			isDiffOrgDes: true,
			recommendation_filter: '0',
			zoom: this.props.zoom,
			municipalities_: this.getOptions(URL + 'municipalities-list'),
			plz_: this.getOptionsPLZ(URL + 'plz-list'),
			db_od_trips_: this.getOptionsDate(URL + 'db_od_trips'),
			operators_: this.getOptionsOperators(URL + 'operators-list'),
			isBFS: true,
			current_operator: 0,
			value_od_trips: ({
				"value": "2020-06",
				"label": '2020-06'
			}),
			valueClear: ({
				"value": "",
				"label": ''
			})
		}

	}

	componentDidMount() {
		this.handleChange = this.handleChange.bind(this);
		this.handleChangeRecommendation = this.handleChangeRecommendation.bind(this);
		this.schemaTypeSelectionHandle = this.schemaTypeSelectionHandle.bind(this);
		this.handleChangeOrigin = this.handleChangeOrigin.bind(this);
		this.handleMonthYear = this.handleMonthYear.bind(this);
		this.modalSplitHandler = this.modalSplitHandler.bind(this);
		this.handleChangeOperator = this.handleChangeOperator.bind(this);
		this.heatmapRestart = this.heatmapRestart.bind(this);
		this.schemaDayWeekHandle = this.schemaDayWeekHandle.bind(this);
		this.numTrainsHandler = this.numTrainsHandler.bind(this);
		this.handleVisibleLayers = this.handleVisibleLayers.bind(this);
	}

	async getOptions(url) {
		const res = await axios.get(url)
		const data = res.data
		const options = data.map(d => ({
			"value": d.id,
			"label": d.name
		}))
		this.setState({ municipalities_: options })
	}

	async getOptionsPLZ(url) {
		const res = await axios.get(url)
		const data = res.data
		const options = data.map(d => ({
			"value": d._id.PLZ,
			"label": d._id.Name + " (" + d._id.PLZ + ")"
		}))
		this.setState({ plz_: options })
	}

	async getOptionsDate(url) {
		const res = await axios.get(url)
		const data = res.data
		const options = data.map(d => ({
			"value": d._id.date.toString().split('T')[0].slice(0, -3),
			"label": d._id.date.toString().split('T')[0].slice(0, -3)
		}))
		this.setState({ db_od_trips_: options });
		this.setState({ value_od_trips: options[options.length - 1] });
	}

	async getOptionsOperators(url) {
		const res = await axios.get(url)
		const data = res.data
		const options = data.map(d => ({
			"value": d.index,
			"label": d.name
		}))
		this.setState({ operators_: options });
	}

	componentDidUpdate(prevProps) {
		if (this.props.map !== prevProps.map) {
			this.setState({ mapa: this.props.map })
		}
	}

	handleChange(e) {
		let adaptedValues = this.adaptValues(this.state.low_bound_diff, this.state.high_bound_diff, this.state.low_distance_km, this.state.high_distance_km, this.state.high_num_trips, this.state.division, this.state.high_num_trains)

		URL_line = URL + 'train_vs_cars/{0}/{1},{2},{3},{4},{5},{6},{7},{8},{9},{10},{11},{12},{13},{14},{15},{16}'.format(this.state.value_od_trips.value + ',' + this.state.division, this.state.day_week, this.state.recommendation_filter, this.state.origin, this.state.destination, adaptedValues[0], adaptedValues[1], adaptedValues[2], adaptedValues[3], this.state.low_num_trips, adaptedValues[4], this.state.low_modal, this.state.high_modal, adaptedValues[5], this.state.current_operator, this.state.low_num_trains, adaptedValues[6]);
		this.state.mapa.getSource('data-swiss-recommendation').setData(URL_line);
	};

	timeDifferenceHandler(event) {
		this.setState({ low_bound_diff: event[0], high_bound_diff: event[1] })
		let adaptedValues = this.adaptValues(event[0], event[1], this.state.low_distance_km, this.state.high_distance_km, this.state.high_num_trips, this.state.division, this.state.high_num_trains)

		URL_line = URL + 'train_vs_cars/{0}/{1},{2},{3},{4},{5},{6},{7},{8},{9},{10},{11},{12},{13},{14},{15},{16}'.format(this.state.value_od_trips.value + ',' + this.state.division, this.state.day_week, this.state.recommendation_filter, this.state.origin, this.state.destination, adaptedValues[0], adaptedValues[1], adaptedValues[2], adaptedValues[3], this.state.low_num_trips, adaptedValues[4], this.state.low_modal, this.state.high_modal, adaptedValues[5], this.state.current_operator, this.state.low_num_trains, adaptedValues[6]);
		this.state.mapa.getSource('data-swiss-recommendation').setData(URL_line);
	}

	distanceKilometerHandler(event) {
		this.setState({ low_distance_km: event[0], high_distance_km: event[1] })
		let adaptedValues = this.adaptValues(this.state.low_bound_diff, this.state.high_bound_diff, event[0], event[1], this.state.high_num_trips, this.state.division, this.state.high_num_trains)

		URL_line = URL + 'train_vs_cars/{0}/{1},{2},{3},{4},{5},{6},{7},{8},{9},{10},{11},{12},{13},{14},{15},{16}'.format(this.state.value_od_trips.value + ',' + this.state.division, this.state.day_week, this.state.recommendation_filter, this.state.origin, this.state.destination, adaptedValues[0], adaptedValues[1], adaptedValues[2], adaptedValues[3], this.state.low_num_trips, adaptedValues[4], this.state.low_modal, this.state.high_modal, adaptedValues[5], this.state.current_operator, this.state.low_num_trains, adaptedValues[6]);
		this.state.mapa.getSource('data-swiss-recommendation').setData(URL_line);
	}

	handleChangeRecommendation(event) {
		let recommendations = 0;
		event == null ? recommendations = 0 : recommendations = event.value;

		let adaptedValues = this.adaptValues(this.state.low_bound_diff, this.state.high_bound_diff, this.state.low_distance_km, this.state.high_distance_km, this.state.high_num_trips, this.state.division, this.state.high_num_trains)

		URL_line = URL + 'train_vs_cars/{0}/{1},{2},{3},{4},{5},{6},{7},{8},{9},{10},{11},{12},{13},{14},{15},{16}'.format(this.state.value_od_trips.value + ',' + this.state.division, this.state.day_week, recommendations, this.state.origin, this.state.destination, adaptedValues[0], adaptedValues[1], adaptedValues[2], adaptedValues[3], this.state.low_num_trips, adaptedValues[4], this.state.low_modal, this.state.high_modal, adaptedValues[5], this.state.current_operator, this.state.low_num_trains, adaptedValues[6]);
		this.state.mapa.getSource('data-swiss-recommendation').setData(URL_line);

		this.setState({ recommendation_filter: recommendations })
	}

	handleChangeOrigin(event) {
		let origin = 0;
		event == null ? origin = 0 : origin = event.value;
		let adaptedValues = this.adaptValues(this.state.low_bound_diff, this.state.high_bound_diff, this.state.low_distance_km, this.state.high_distance_km, this.state.high_num_trips, this.state.division, this.state.high_num_trains)

		let URL_line = URL + 'train_vs_cars/{0}/{1},{2},{3},{4},{5},{6},{7},{8},{9},{10},{11},{12},{13},{14},{15},{16}'.format(this.state.value_od_trips.value + ',' + this.state.division, this.state.day_week, this.state.recommendation_filter, origin, this.state.destination, adaptedValues[0], adaptedValues[1], adaptedValues[2], adaptedValues[3], this.state.low_num_trips, adaptedValues[4], this.state.low_modal, this.state.high_modal, adaptedValues[5], this.state.current_operator, this.state.low_num_trains, adaptedValues[6]);

		this.state.mapa.getSource('data-swiss-recommendation').setData(URL_line);
		let month_year = this.state.value_od_trips.value;
		this.setState({ origin: origin });

		let currentDestination = 'empty';
		let hasInfo = false;
		if (origin !== 0 && this.state.destination !== 0) {
			if (this.state.isBFS) {
				URL_origin = URL + "heatmap_t_vs_c/plz_geojson/" + month_year + ',' + this.state.division + "/" + origin + "," + this.state.destination;
				this.state.mapa.getSource('data-swiss-origin').setData(URL_origin);
			} else {
				URL_origin = URL + "heatmap_t_vs_c/tiles_geojson/" + month_year + ',' + this.state.division + "/" + origin + "," + this.state.destination;
				this.state.mapa.getSource('data-swiss-origin').setData(URL_origin);
			}
			currentDestination = this.state.destination;
		}
		else {
			URL_origin = URL + "heatmap_t_vs_c/plz_geojson/2020-06,bfs/empty,empty";
			this.state.mapa.getSource('data-swiss-origin').setData(URL_origin);
			URL_destination = URL + "heatmap_t_vs_c/plz_heatmap/2020-06,bfs/empty,empty";
			this.state.mapa.getSource('data-swiss-destination').setData(URL_destination);
			currentDestination = 'empty';
		}
		let paramOrigin = origin;
		let paramDestination = this.state.destination;
		if (paramOrigin === 0) {
			paramOrigin = 'empty';
		}
		if (paramDestination === 0) {
			paramDestination = 'empty';
		}

		(this.state.destination === origin) ? this.setState({ isDiffOrgDes: false }) : this.setState({ isDiffOrgDes: true });

		(this.state.destination === 0 && origin === 0) ? hasInfo = false : hasInfo = true;
		this.props.updateFields({ hasOriginDestination: hasInfo, graphParam: '{0},{1},{2}'.format(this.state.division, paramOrigin, paramDestination), currentDestination: currentDestination, origin: origin, destination: this.state.destination, day_week: this.state.day_week })
	}

	handleChangeDestination(event) {
		let destination = 0;
		event == null ? destination = 0 : destination = event.value;

		let adaptedValues = this.adaptValues(this.state.low_bound_diff, this.state.high_bound_diff, this.state.low_distance_km, this.state.high_distance_km, this.state.high_num_trips, this.state.division, this.state.high_num_trains)

		URL_line = URL + 'train_vs_cars/{0}/{1},{2},{3},{4},{5},{6},{7},{8},{9},{10},{11},{12},{13},{14},{15},{16}'.format(this.state.value_od_trips.value + ',' + this.state.division, this.state.day_week, this.state.recommendation_filter, this.state.origin, destination, adaptedValues[0], adaptedValues[1], adaptedValues[2], adaptedValues[3], this.state.low_num_trips, adaptedValues[4], this.state.low_modal, this.state.high_modal, adaptedValues[5], this.state.current_operator, this.state.low_num_trains, adaptedValues[6]);
		this.state.mapa.getSource('data-swiss-recommendation').setData(URL_line);
		let month_year = this.state.value_od_trips.value;
		this.setState({ destination: destination });

		let currentDestination = '';
		let hasInfo = false;
		if (destination !== 0 && this.state.origin !== 0) {
			if (this.state.isBFS) {
				URL_origin = URL + "heatmap_t_vs_c/plz_geojson/" + month_year + ',' + this.state.division + "/" + this.state.origin + "," + destination;
				this.state.mapa.getSource('data-swiss-origin').setData(URL_origin);
			} else {
				URL_origin = URL + "heatmap_t_vs_c/tiles_geojson/" + month_year + ',' + this.state.division + "/" + this.state.origin + "," + destination;
				this.state.mapa.getSource('data-swiss-origin').setData(URL_origin);
			}
			currentDestination = destination;
		}
		else {
			URL_origin = URL + "heatmap_t_vs_c/plz_geojson/2020-06,bfs/empty,empty";
			this.state.mapa.getSource('data-swiss-origin').setData(URL_origin);
			URL_destination = URL + "heatmap_t_vs_c/plz_heatmap/2020-06,bfs/empty,empty";
			this.state.mapa.getSource('data-swiss-destination').setData(URL_destination);
			currentDestination = 'empty';
		}

		let paramOrigin = this.state.origin;
		let paramDestination = destination;
		if (paramOrigin === 0) {
			paramOrigin = 'empty';
		}
		if (paramDestination === 0) {
			paramDestination = 'empty';
		}

		(this.state.origin === destination) ? this.setState({ isDiffOrgDes: false }) : this.setState({ isDiffOrgDes: true });

		(destination === 0 && this.state.origin === 0) ? hasInfo = false : hasInfo = true;

		this.props.updateFields({ hasOriginDestination: hasInfo, graphParam: '{0},{1},{2}'.format(this.state.division, paramOrigin, paramDestination), currentDestination: currentDestination, origin: this.state.origin, destination: destination, day_week: this.state.day_week })
	}

	heatmapRestart(event) {
		if (this.state.isBFS) {
			URL_origin = URL + "heatmap_t_vs_c/plz_geojson/" + this.state.value_od_trips.value + ',' + this.state.division + "/" + this.state.origin + "," + this.state.destination;
			this.state.mapa.getSource('data-swiss-origin').setData(URL_origin);
			URL_destination = URL + "heatmap_t_vs_c/plz_heatmap/2020-06,bfs/empty,empty";
			this.state.mapa.getSource('data-swiss-destination').setData(URL_destination);

		} else {
			URL_origin = URL + "heatmap_t_vs_c/tiles_geojson/" + this.state.value_od_trips.value + ',' + this.state.division + "/" + this.state.origin + "," + this.state.destination;
			this.state.mapa.getSource('data-swiss-origin').setData(URL_origin);
			URL_destination = URL + "heatmap_t_vs_c/plz_heatmap/2020-06,bfs/empty,empty";
			this.state.mapa.getSource('data-swiss-destination').setData(URL_destination);
		}
	}

	numTripsHandler(event) {
		this.setState({ low_num_trips: event[0], high_num_trips: event[1] })

		let adaptedValues = this.adaptValues(this.state.low_bound_diff, this.state.high_bound_diff, this.state.low_distance_km, this.state.high_distance_km, event[1], this.state.division, this.state.high_num_trains)

		URL_line = URL + 'train_vs_cars/{0}/{1},{2},{3},{4},{5},{6},{7},{8},{9},{10},{11},{12},{13},{14},{15},{16}'.format(this.state.value_od_trips.value + ',' + this.state.division, this.state.day_week, this.state.recommendation_filter, this.state.origin, this.state.destination, adaptedValues[0], adaptedValues[1], adaptedValues[2], adaptedValues[3], event[0], adaptedValues[4], this.state.low_modal, this.state.high_modal, adaptedValues[5], this.state.current_operator, this.state.low_num_trains, adaptedValues[6]);
		this.state.mapa.getSource('data-swiss-recommendation').setData(URL_line);
	}

	numTrainsHandler(event) {
		this.setState({ low_num_trains: event[0], high_num_trains: event[1] });
		let adaptedValues = this.adaptValues(this.state.low_bound_diff, this.state.high_bound_diff, this.state.low_distance_km, this.state.high_distance_km, this.state.high_num_trips, this.state.division, event[1])

		URL_line = URL + 'train_vs_cars/{0}/{1},{2},{3},{4},{5},{6},{7},{8},{9},{10},{11},{12},{13},{14},{15},{16}'.format(this.state.value_od_trips.value + ',' + this.state.division, this.state.day_week, this.state.recommendation_filter, this.state.origin, this.state.destination, adaptedValues[0], adaptedValues[1], adaptedValues[2], adaptedValues[3], this.state.low_num_trips, adaptedValues[4], this.state.low_modal, this.state.high_modal, adaptedValues[5], this.state.current_operator, event[0], event[1]);
		this.state.mapa.getSource('data-swiss-recommendation').setData(URL_line);
	}

	modalSplitHandler(event) {
		this.setState({ low_modal: event[0], high_modal: event[1] })

		let adaptedValues = this.adaptValues(this.state.low_bound_diff, this.state.high_bound_diff, this.state.low_distance_km, this.state.high_distance_km, this.state.high_num_trips, this.state.division, this.state.high_num_trains)

		URL_line = URL + 'train_vs_cars/{0}/{1},{2},{3},{4},{5},{6},{7},{8},{9},{10},{11},{12},{13},{14},{15},{16}'.format(this.state.value_od_trips.value + ',' + this.state.division, this.state.day_week, this.state.recommendation_filter, this.state.origin, this.state.destination, adaptedValues[0], adaptedValues[1], adaptedValues[2], adaptedValues[3], this.state.low_num_trips, adaptedValues[4], event[0], event[1], adaptedValues[5], this.state.current_operator, this.state.low_num_trains, adaptedValues[6]);
		this.state.mapa.getSource('data-swiss-recommendation').setData(URL_line);
	}

	adaptValues(time_low, time_high, distance_low, distance_high, num_trips, filtering_scope, num_trains) {
		let URL_MAP_TIME_LOW = time_low;
		if (URL_MAP_TIME_LOW === -40) {
			URL_MAP_TIME_LOW = -2000;
		}
		let URL_MAP_TIME_HIGH = time_high;
		if (URL_MAP_TIME_HIGH === 40) {
			URL_MAP_TIME_HIGH = 2000;
		}
		let URL_MAP_HIGH_NUM_TRIPS = num_trips;
		if (URL_MAP_HIGH_NUM_TRIPS === 10000) {
			URL_MAP_HIGH_NUM_TRIPS = 182000;
		}

		let FILTERING_SCOPE = 0;
		if (filtering_scope === 'plz') {
			FILTERING_SCOPE = 1;
		}

		let NUM_TRAINS = num_trains
		if (num_trains === 4) {
			NUM_TRAINS = 10;
		}

		let URL_MAP_DISTANCE_LOW = (Number(distance_low) * Number(1000)).toString();
		let URL_MAP_DISTANCE_HIGH = (Number(distance_high) * Number(1000)).toString();

		return [URL_MAP_TIME_LOW, URL_MAP_TIME_HIGH, URL_MAP_DISTANCE_LOW, URL_MAP_DISTANCE_HIGH, URL_MAP_HIGH_NUM_TRIPS, FILTERING_SCOPE, NUM_TRAINS];
	}

	handleVisibleLayers(event) {
		if (event.target.attributes.getNamedItem('id').value === 'swiss') {
			this.props.map.setLayoutProperty('swiss-recommendation', 'visibility', 'visible');
			this.props.map.setLayoutProperty('swiss-recommendation-aus', 'visibility', 'none');
		}
		else {
			this.props.map.setLayoutProperty('swiss-recommendation', 'visibility', 'visible');
			this.props.map.setLayoutProperty('swiss-recommendation-aus', 'visibility', 'visible');
		}
	}

	schemaTypeSelectionHandle(event) {
		this.setState({ division: event.target.attributes.getNamedItem('id').value })
		this.setState({ origin: 0 })
		this.setState({ destination: 0 })

		let operator_change = this.state.current_operator;
		if (event.target.attributes.getNamedItem('id').value === 'bfs') {
			this.clear()
			operator_change = 0;
		}

		this.state.mapa.getSource('data-swiss-origin').setData(URL + "heatmap_t_vs_c/plz_geojson/april-2022/empty,empty");
		this.state.mapa.getSource('data-swiss-destination').setData(URL + "heatmap_t_vs_c/plz_heatmap/april-2022/empty,empty");

		this.selection = event.target.attributes.getNamedItem('id').value;

		(event.target.attributes.getNamedItem('id').value === 'plz') ? this.setState({ isBFS: false }) : this.setState({ isBFS: true });

		let adaptedValues = this.adaptValues(this.state.low_bound_diff, this.state.high_bound_diff, this.state.low_distance_km, this.state.high_distance_km, this.state.high_num_trips, event.target.attributes.getNamedItem('id').value, this.state.high_num_trains)
		URL_line = URL + 'train_vs_cars/{0}/{1},{2},{3},{4},{5},{6},{7},{8},{9},{10},{11},{12},{13},{14},{15},{16}'.format(this.state.value_od_trips.value + ',' + event.target.attributes.getNamedItem('id').value, this.state.day_week, this.state.recommendation_filter, 0, 0, adaptedValues[0], adaptedValues[1], adaptedValues[2], adaptedValues[3], this.state.low_num_trips, adaptedValues[4], this.state.low_modal, this.state.high_modal, adaptedValues[5], operator_change, this.state.low_num_trains, adaptedValues[6]);
		this.state.mapa.getSource('data-swiss-recommendation').setData(URL_line);

		let paramOrigin = this.state.origin;
		let paramDestination = this.state.destination;

		if (paramOrigin === 0) {
			paramOrigin = 'empty';
		}
		if (paramDestination === 0) {
			paramDestination = 'empty';
		}
		this.props.updateFields({ hasOriginDestination: false, graphParam: '{0},{1},{2}'.format(event.target.attributes.getNamedItem('id').value, paramOrigin, paramDestination), selection: event.target.attributes.getNamedItem('id').value, day_week: this.state.day_week })
	}

	clear() {
		this.setState({ valueClear: null })
		this.setState({ current_operator: 0 })
	}

	handleMonthYear(event) {
		this.setState({ value_od_trips: event })
		this.props.updateField("month_year", event.value);

		let adaptedValues = this.adaptValues(this.state.low_bound_diff, this.state.high_bound_diff, this.state.low_distance_km, this.state.high_distance_km, this.state.high_num_trips, this.state.division, this.state.high_num_trains)

		URL_line = URL + 'train_vs_cars/{0}/{1},{2},{3},{4},{5},{6},{7},{8},{9},{10},{11},{12},{13},{14},{15},{16}'.format(event.value + ',' + this.state.division, this.state.day_week, this.state.recommendation_filter, this.state.origin, this.state.destination, adaptedValues[0], adaptedValues[1], adaptedValues[2], adaptedValues[3], this.state.low_num_trips, adaptedValues[4], this.state.low_modal, this.state.high_modal, adaptedValues[5], this.state.current_operator, this.state.low_num_trains, adaptedValues[6]);
		this.state.mapa.getSource('data-swiss-recommendation').setData(URL_line);
	}

	handleChangeOperator(event) {
		let operator = 0;
		event == null ? operator = 0 : operator = event.value;

		let adaptedValues = this.adaptValues(this.state.low_bound_diff, this.state.high_bound_diff, this.state.low_distance_km, this.state.high_distance_km, this.state.high_num_trips, this.state.division, this.state.high_num_trains)

		URL_line = URL + 'train_vs_cars/{0}/{1},{2},{3},{4},{5},{6},{7},{8},{9},{10},{11},{12},{13},{14},{15},{16}'.format(this.state.value_od_trips.value + ',' + this.state.division, this.state.day_week, this.state.recommendation_filter, this.state.origin, this.state.destination, adaptedValues[0], adaptedValues[1], adaptedValues[2], adaptedValues[3], this.state.low_num_trips, adaptedValues[4], this.state.low_modal, this.state.high_modal, adaptedValues[5], operator, this.state.low_num_trains, adaptedValues[6]);
		this.state.mapa.getSource('data-swiss-recommendation').setData(URL_line);

		this.setState({ current_operator: operator })
		this.setState({ valueClear: event })
	}

	schemaDayWeekHandle(event) {
		this.setState({ day_week: event.target.attributes.getNamedItem('id').value })

		let adaptedValues = this.adaptValues(this.state.low_bound_diff, this.state.high_bound_diff, this.state.low_distance_km, this.state.high_distance_km, this.state.high_num_trips, event.target.attributes.getNamedItem('id').value, this.state.high_num_trains)

		if (this.state.origin !== 0 && this.state.destination !== 0) {
			if (this.state.isBFS) {
				URL_origin = URL + "heatmap_t_vs_c/plz_geojson/" + this.state.value_od_trips.value + ',' + this.state.division + "/" + this.state.origin + "," + this.state.destination;
				this.state.mapa.getSource('data-swiss-origin').setData(URL_origin);
				URL_destination = URL + "heatmap_t_vs_c/plz_heatmap/2020-06,bfs/empty,empty";
				this.state.mapa.getSource('data-swiss-destination').setData(URL_destination);

			} else {
				URL_origin = URL + "heatmap_t_vs_c/tiles_geojson/" + this.state.value_od_trips.value + ',' + this.state.division + "/" + this.state.origin + "," + this.state.destination;
				this.state.mapa.getSource('data-swiss-origin').setData(URL_origin);
				URL_destination = URL + "heatmap_t_vs_c/plz_heatmap/2020-06,bfs/empty,empty";
				this.state.mapa.getSource('data-swiss-destination').setData(URL_destination);
			}
		}
		else {
			URL_line = URL + 'train_vs_cars/{0}/{1},{2},{3},{4},{5},{6},{7},{8},{9},{10},{11},{12},{13},{14},{15},{16}'.format(this.state.value_od_trips.value + ',' + this.state.division, event.target.attributes.getNamedItem('id').value, this.state.recommendation_filter, this.state.origin, this.state.destination, adaptedValues[0], adaptedValues[1], adaptedValues[2], adaptedValues[3], this.state.low_num_trips, adaptedValues[4], this.state.low_modal, this.state.high_modal, adaptedValues[5], this.state.current_operator, this.state.low_num_trains, adaptedValues[6]);
			this.state.mapa.getSource('data-swiss-recommendation').setData(URL_line);
		}

		//this.props.updateFields({hasOriginDestination: false, graphParam: '{0},{1},{2}'.format(this.state.division, this.state.origin, this.state.destination), selection: this.state.division, day_week: event.target.attributes.getNamedItem('id').value})
	}

	render() {

		const selectStyles = {
			menuList: styles => {
				return {
					...styles,
					maxHeight: 100
				};
			}
		};

		const selectDate = {
			menuList: styles => {
				return {
					...styles,
					maxHeight: 150,
					borderWidth: 0,
					boxShadow: '0 0 10px 2px rgb(0 0 0 / 10%)',
				};
			}
		};

		const selectStylesTypeRecommendation = {
			menuList: styles => {
				return {
					...styles,
					maxHeight: 150
				};
			}
		};

		return (
			<div>
				<div className="map-card-insight-analyzer-modal">
					<div className="map-card-insight-analyzer-modal-inner">
						<h1>Mobility Insights Analyzer</h1>
						<div className="map-card-insight-analyzer-modal-inner-scroll">
							<div>
								<p className='label' htmlFor="Recommendation type">Type of Recommendation</p>
								<Select className='locations' isClearable={true} options={this.state.selectOptions} isDisabled={this.state.isBFS} onChange={this.handleChangeRecommendation.bind(this)} styles={selectStylesTypeRecommendation} />
							</div>
							<div>
								<p className='label' htmlFor="Operators provider">Operator</p>
								<Select className='operators' isClearable={true} options={this.state.operators_} value={this.state.valueClear} isDisabled={this.state.isBFS} onChange={this.handleChangeOperator.bind(this)} styles={selectStylesTypeRecommendation} />
							</div>
							<div>
								<p className='label'>Type of division</p>
								<Form onChange={this.schemaTypeSelectionHandle.bind(this)}>
									<Form.Check inline label=" Municipalities" name="type_analysis" type='radio' id='bfs' defaultChecked={true} />
									<Form.Check inline label=" Postal code" name="type_analysis" type='radio' id='plz' />
								</Form>
							</div>
							<div>
								<p className='label'>Time difference: Trains vs Cars</p>
								<div className='insight-analyzer-modal-slider-inner'>
									<Slider range draggableTrack min={-40} max={40} step={1} defaultValue={[this.state.low_bound_diff, this.state.high_bound_diff]} onChange={_.debounce(this.timeDifferenceHandler.bind(this), 33)} />
								</div>
								{(Number(this.state.low_bound_diff) < 0 && Number(this.state.high_bound_diff) > 0) &&
									<p className='label-selected-value'>Showing trips where trains are <span>{this.state.low_bound_diff.toString().replace(/-/g, '')}</span>min slower <br /> but <span>{this.state.high_bound_diff}</span>min faster than cars</p>
								}
								{(Number(this.state.low_bound_diff) >= 0 && Number(this.state.high_bound_diff) > 0) &&
									<p className='label-selected-value'>Showing trips where trains are between <span>{this.state.low_bound_diff.toString().replace(/-/g, '')}</span>min and <span>{this.state.high_bound_diff}</span>min faster than cars</p>
								}
								{(Number(this.state.low_bound_diff) < 0 && Number(this.state.high_bound_diff) <= 0) &&
									<p className='label-selected-value'>Showing trips where trains are between <span>{this.state.low_bound_diff.toString().replace(/-/g, '')}</span>min and <span>{this.state.high_bound_diff}</span>min slower than cars</p>
								}
							</div>
							<div>
								<p className='label'>Distance in km</p>
								<div className='insight-analyzer-modal-slider-inner'>
									<Slider range draggableTrack min={0} max={400} step={5} defaultValue={[this.state.low_distance_km, this.state.high_distance_km]} onChange={_.debounce(this.distanceKilometerHandler.bind(this), 33)} />
								</div>
								<p className='label-selected-value'>Showing routes with a distance<br /> between <span>{this.state.low_distance_km}km</span> and <span>{this.state.high_distance_km} </span>km</p>
							</div>
							<div>
								<p className='label'>Number of trips</p>
								<div className='insight-analyzer-modal-slider-inner'>
									<Slider range draggableTrack min={0} max={10000} step={10} defaultValue={[this.state.low_num_trips, this.state.high_num_trips]} onChange={_.debounce(this.numTripsHandler.bind(this), 33)} />
								</div>
								<p className='label-selected-value'>Showing number of trips<br /> between <span>{numberWithCommas(this.state.low_num_trips)}</span> and <span>{this.state.high_num_trips === 10000 ? "10,000+" : "" + numberWithCommas(this.state.high_num_trips)} </span> per day</p>
							</div>
							<div>
								<p className='label'>Modal split for trains</p>
								<div className='insight-analyzer-modal-slider-inner'>
									<Slider range draggableTrack min={0} max={100} step={1} defaultValue={[this.state.low_modal, this.state.high_modal]} onChange={_.debounce(this.modalSplitHandler.bind(this), 33)} />
								</div>
								<p className='label-selected-value'>Showing trips with train's modal split <br /> between <span>{this.state.low_modal}%</span> and <span>{this.state.high_modal}%</span></p>
							</div>
							<div>
								<p className='label'>Number of trains</p>
								<div className='insight-analyzer-modal-slider-inner'>
									<Slider range draggableTrack min={1} max={4} step={1} defaultValue={[this.state.low_num_trains, this.state.high_num_trains]} onChange={_.debounce(this.numTrainsHandler.bind(this), 33)} />
								</div>
								<p className='label-selected-value'>Showing trips with number of trains<br /> between <span>{numberWithCommas(this.state.low_num_trains)}</span> and <span>{this.state.high_num_trains === 4 ? "4+" : "" + numberWithCommas(this.state.high_num_trains)} </span> per day</p>
							</div>
							<div>
								<p className='label' htmlFor="Heatmap Restart">Heatmap Restart</p>
								<Form onClick={this.heatmapRestart.bind(this)}>
									<Button variant="info" disabled={this.state.isDiffOrgDes} className="generate-btn">Go back to origin</Button>
								</Form>
							</div>
						</div>
					</div>
				</div>

				<div className='mobility-insights-origin-destionation'>
					<div className='mobility-insights-origin-destionation-inner'>
						<h1>Search for an Specific Trip</h1>
						{(this.state.isBFS) &&
							<div>
								<div>
									<p className='label' htmlFor="Origin">Origin</p>
									<Select className='locations' isClearable={true} options={this.state.municipalities_} onChange={this.handleChangeOrigin.bind(this)} styles={selectStyles} />
								</div>
								<div>
									<p className='label' htmlFor="Destination">Destination</p>
									<Select className='locations' isClearable={true} options={this.state.municipalities_} onChange={this.handleChangeDestination.bind(this)} styles={selectStyles} />
								</div>
							</div>
						}
						{(!this.state.isBFS) &&
							<div>
								<div>
									<p className='label' htmlFor="Origin">Origin</p>
									<Select className='locations' isClearable={true} options={this.state.plz_} onChange={this.handleChangeOrigin.bind(this)} styles={selectStyles} />
								</div>
								<div>
									<p className='label' htmlFor="Destination">Destination</p>
									<Select className='locations' isClearable={true} options={this.state.plz_} onChange={this.handleChangeDestination.bind(this)} styles={selectStyles} />
								</div>
							</div>
						}
					</div>
				</div>

				<div className="modal-weekdays-weekends">
					<div className="modal-weekdays-weekends-inner">
						<p className='label' htmlFor="Filter based on">Filters based on</p>
						<div className="row">
							<Form onChange={this.schemaDayWeekHandle.bind(this)}>
								<div className="column">
									<Form.Check inline label=" Weekdays" name="type_analysis" type='radio' id='days' defaultChecked={true} />
								</div>
								<div className="column">
									<Form.Check inline label=" Weekends" name="type_analysis" type='radio' id='ends' />
								</div>
							</Form>
						</div>
					</div>
				</div>

				<div className="modal-austria">
					<div className="modal-austria-inner">
						<p className='label' htmlFor="Filter based on">Enable Austria data</p>
						<div className="row">
							<Form onChange={this.handleVisibleLayers.bind(this)}>
								<div className="column">
									<Form.Check inline label=" Just Switzerland" name="type_analysis" type='radio' id='swiss' defaultChecked={true} />
								</div>
								<div className="column">
									<Form.Check inline label=" Both countries" name="type_analysis" type='radio' id='aus' />
								</div>
							</Form>
						</div>
					</div>
				</div>

				<div className="map-month-year-picker">
					<Select className='dates' options={this.state.db_od_trips_} onChange={this.handleMonthYear.bind(this)} styles={selectDate} defaultValue={this.state.value_od_trips} />
				</div>
			</div>
		)
	}
}

//Map - Mobility insights
class MappRecommendationHeatmap extends React.Component {

	// Set up states for updating map 
	constructor(props) {
		super(props);
		this.state = {
			lng: 8.251313,
			lat: 46.924143,
			zoom: 7,
			mapita: 9,
			tyle: 'mapbox://styles/mapbox/light-v10',
			selection: 'bfs',
			day_week: 'days',
			month_year: '2020-06',
			hasOriginDestination: false,
			graphParam: 'bfs,empty,empty',
			currentDestination: 'empty',
			origin: 0,
			destination: 0,
			hideNavs : false
		}
		this.handleTyle = this.handleTyle.bind(this);
		URL_line = URL + "train_vs_cars/2020-06,bfs/days,0,0,0,-2000,2000,0,290000,500,182000,0,100,0,0,1,4";
	}

	//Update several fields
	updateFields(list) {
		this.setState(list);
	}

	//Update style
	handleTyle(value) {
		this.setState({ tyle: value }, this.componentDidMount);
	}

	//Update map on component update
	updateField(card, value) {
		this.setState({
			...this.state,
			[card]: value
		});
	}

	//Update coordinates
	handleCoord(center, zoom) {
		const { lng, lat } = center;
		this.setState({ zoom: zoom, lat: lat, lng: lng })
	}

	componentDidMount() {
		// Set up map 
		const map = new mapboxgl.Map({
			container: this.mapContainer,
			style: this.state.tyle,
			center: [this.state.lng, this.state.lat],
			zoom: this.state.zoom,
		});

		map.on('load', () => {
			// Add zoom and rotation controls to the map.
			map.addControl(new mapboxgl.NavigationControl({ showCompass: false, showZoom: false }), 'top-left');
			map.addControl(new MapboxGeocoder({ accessToken: mapboxgl.accessToken, mapboxgl: mapboxgl, marker: false }), 'top-left');

			//Origin heat maps
			//Add shapes to the map
			map.addSource('data-swiss-origin', {
				'type': 'geojson',
				'data': URL_origin,
				'generateId': true

			});
			map.addLayer({
				'id': 'data-swiss-origin',
				'type': 'fill',
				'source': 'data-swiss-origin',
				'layout': {
				},
				'paint': {
					'fill-color': [
						'case',
						['boolean', ['feature-state', 'clicked'], false],
						'#eb34b1', // if selected true, paint in pink
						['get', 'color'] // else paint
					],
					'fill-opacity': [
						'case',
						['boolean', ['feature-state', 'clicked'], false],
						1, // if selected true, paint in pink
						0.4, // else paint
					],
				},
			});

			// Add a black outline around the polygon.
			map.addLayer({
				'id': 'outline-origin',
				'type': 'line',
				'source': 'data-swiss-origin',
				'layout': {},
				'paint': {
					'line-color': '#55565b',
					'line-width': 1
				}
			});

			//Origin - Label percentage
			map.addLayer({
				'id': 'origin-labels',
				'type': 'symbol',
				'source': 'data-swiss-origin',
				"minzoom": 10,
				'layout': {
					'text-field': ['get', 'percentage'],
					'text-font': [
						'Open Sans Bold',
						'Arial Unicode MS Bold'
					],
					'text-size': 10,
					'text-transform': 'uppercase',
					'text-letter-spacing': 0.05,
					'text-offset': [0, 1]
				}
			});

			//Destination heat maps
			//Add shapes to the map
			map.addSource('data-swiss-destination', {
				'type': 'geojson',
				'data': URL_destination,
				'generateId': true

			});
			map.addLayer({
				'id': 'data-swiss-destination',
				'type': 'fill',
				'source': 'data-swiss-destination',
				'layout': {},
				'paint': {
					'fill-color': ['get', 'color'],
					'fill-opacity': 0.4
				}
			});

			// Add a black outline around the polygon.
			map.addLayer({
				'id': 'outline-destination',
				'type': 'line',
				'source': 'data-swiss-destination',
				'layout': {},
				'paint': {
					'line-color': '#55565b',
					'line-width': 1
				}
			});

			//Destination - Label percentage
			map.addLayer({
				'id': 'destination-labels',
				'type': 'symbol',
				'source': 'data-swiss-destination',
				"minzoom": 10,
				'layout': {
					'text-field': ['get', 'percentage'],
					'text-font': [
						'Open Sans Bold',
						'Arial Unicode MS Bold'
					],
					'text-size': 10,
					'text-transform': 'uppercase',
					'text-letter-spacing': 0.05,
					'text-offset': [0, 1]
				}
			});


			// add lines to the map
			map.addSource('data-swiss-recommendation', {
				'type': 'geojson',
				'data': URL_line,
				'generateId': true,
				'lineMetrics': true
			});
			map.addLayer({
				'id': 'swiss-recommendation',
				'type': 'line',
				'source': 'data-swiss-recommendation',
				'layout': {
					// Make the layer visible by default.
					'visibility': 'visible'
				},
				'paint': {
					'line-color': ['get', 'color'],
					'line-width': ['get', 'line_width'],
				}
			});

			// add lines to the map
			map.addSource('data-swiss-recommendation-aus', {
				'type': 'geojson',
				'data': URL_line_Aus,
				'generateId': true,
				'lineMetrics': true
			});
			map.addLayer({
				'id': 'swiss-recommendation-aus',
				'type': 'line',
				'source': 'data-swiss-recommendation-aus',
				'layout': {
					// Make the layer visible by default.
					'visibility': 'none'
				},
				'paint': {
					'line-color': '#027f80',
					'line-opacity': 0.5,
					'line-width': 1,
				}
			});

		});

		this.setState({
			mapita: map,
			zoom: map.getZoom(),
		});

		// When a click event occurs on a feature in the places layer, open a popup at the
		// location of the feature, with description HTML from its properties.
		map.on('click', 'swiss-recommendation', (e) => {

			let c0 = e.features[0].properties._c0;
			fetch(URL + "train_vs_cars/detail_updated/" + this.state.month_year + ',' + this.state.selection + "/" + c0)
				.then((res) => res.json())
				.then((json) => {
					if (json.hasOwnProperty('properties')) {
						// Copy coordinates array.
						const coordinates = json.geometry.coordinates.slice();
						while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
							coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
						}

						let geodesic_distance = json.properties.geodesic_distance_m / 1000;

						let num_passengers_car_wd = json.properties.car_trips_weekdays;
						let num_passengers_car_we = json.properties.car_trips_weekend;
						let num_passengers_train_wd = json.properties.train_trips_weekdays;
						let num_passengers_train_we = json.properties.train_trips_weekend;
						let num_passengers_we_total = json.properties.total_trips_weekend;
						let num_passengers_wd_total = json.properties.total_trips_weekdays;
						let num_passengers_wd_bus = json.properties.bus_trips_weekdays;

						let num_passengers_car_wd_str = '';
						let num_passengers_car_we_str = '';
						let num_passengers_train_wd_str = '';
						let num_passengers_train_we_str = '';
						let num_passengers_we_total_str = '';
						let num_passengers_wd_total_str = '';
						let num_passengers_wd_bus_str = '';

						(typeof num_passengers_car_wd == 'number' & num_passengers_car_wd > 0) ? num_passengers_car_wd = (num_passengers_car_wd).toFixed(0) : num_passengers_car_wd = '-';
						(num_passengers_car_wd !== '-') ? num_passengers_car_wd_str = numberWithCommas(num_passengers_car_wd) : num_passengers_car_wd_str = '-';

						(typeof num_passengers_car_we == 'number' & num_passengers_car_we > 0) ? num_passengers_car_we = (num_passengers_car_we).toFixed(0) : num_passengers_car_we = '-';
						(num_passengers_car_we !== '-') ? num_passengers_car_we_str = numberWithCommas(num_passengers_car_we) : num_passengers_car_we_str = '-';

						(typeof num_passengers_train_wd == 'number' & num_passengers_train_wd > 0) ? num_passengers_train_wd = (num_passengers_train_wd).toFixed(0) : num_passengers_train_wd = '-';
						(num_passengers_train_wd !== '-') ? num_passengers_train_wd_str = numberWithCommas(num_passengers_train_wd) : num_passengers_train_wd_str = '-';

						(typeof num_passengers_train_we == 'number' & num_passengers_train_we > 0) ? num_passengers_train_we = (num_passengers_train_we).toFixed(0) : num_passengers_train_we = '-';
						(num_passengers_train_we !== '-') ? num_passengers_train_we_str = numberWithCommas(num_passengers_train_we) : num_passengers_train_we_str = '-';

						(typeof num_passengers_wd_total == 'number' & num_passengers_wd_total > 0) ? num_passengers_wd_total = (num_passengers_wd_total).toFixed(0) : num_passengers_wd_total = '-';
						if (num_passengers_wd_total < (Number(num_passengers_train_wd) + Number(num_passengers_car_wd)) & num_passengers_wd_total !== '-') {
							num_passengers_wd_total = Number(num_passengers_train_wd) + Number(num_passengers_car_wd);
						}
						(num_passengers_wd_total !== '-') ? num_passengers_wd_total_str = numberWithCommas(num_passengers_wd_total) : num_passengers_wd_total_str = '-';

						(typeof num_passengers_we_total == 'number' & num_passengers_we_total > 0) ? num_passengers_we_total = (num_passengers_we_total).toFixed(0) : num_passengers_we_total = '-';
						(num_passengers_we_total !== '-') ? num_passengers_we_total_str = numberWithCommas(num_passengers_we_total) : num_passengers_we_total_str = '-';

						(typeof num_passengers_wd_bus == 'number' & num_passengers_wd_bus > 0) ? num_passengers_wd_bus = (num_passengers_wd_bus).toFixed(0) : num_passengers_wd_bus = '-';
						(num_passengers_wd_bus !== '-') ? num_passengers_wd_bus_str = numberWithCommas(num_passengers_wd_bus) : num_passengers_wd_bus_str = '-';

						//Passenger Km (PKm) based on geodesic distance
						let num_passengers_car_wd_km = (num_passengers_car_wd !== '-') ? numberWithCommas((num_passengers_car_wd * geodesic_distance).toFixed(0)) : '-';
						let num_passengers_car_we_km = (num_passengers_car_we !== '-') ? numberWithCommas((num_passengers_car_we * geodesic_distance).toFixed(0)) : '-';
						let num_passengers_train_wd_km = (num_passengers_train_wd !== '-') ? numberWithCommas((num_passengers_train_wd * geodesic_distance).toFixed(0)) : '-';
						let num_passengers_train_we_km = (num_passengers_train_we !== '-') ? numberWithCommas((num_passengers_train_we * geodesic_distance).toFixed(0)) : '-';
						let num_passengers_we_total_km = (num_passengers_we_total !== '-') ? numberWithCommas((num_passengers_we_total * geodesic_distance).toFixed(0)) : '-';
						let num_passengers_wd_total_km = (num_passengers_wd_total !== '-') ? numberWithCommas((num_passengers_wd_total * geodesic_distance).toFixed(0)) : '-';

						let num_passengers_bus_wd_km = (num_passengers_wd_bus !== '-') ? numberWithCommas((num_passengers_wd_bus * geodesic_distance).toFixed(0)) : '-';

						let html_table_pkm = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">PKm per day</span></p><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle;"><thead><tr><th></th><th>&#x1f46a; &#128664;</th><th>&#x1f46a; &#x1f686;</th><th>&#x1f46a; &#128652;</th><th>Total</th></tr></thead><tbody><tr><th>Weekdays</th><td>' + num_passengers_car_wd_km + '</td><td>' + num_passengers_train_wd_km + '</td><td>' + num_passengers_bus_wd_km + '</td><td>' + num_passengers_wd_total_km + '</td></tr><tr><th>Weekends</th><td>' + num_passengers_car_we_km + '</td><td>' + num_passengers_train_we_km + '</td><td>-</td><td>' + num_passengers_we_total_km + '</td></tr></tbody></table>';

						let end_station = json.properties.destination_name;
						let start_station = json.properties.origin_name;

						let html_add = ''
						let operators = ''
						if (typeof json.properties.duration_car_s == 'number') {
							let time_car = (json.properties.duration_car_s / 60).toFixed(1);
							let time_train = (json.properties.train_travel_time_min).toFixed(1);
							let stops = json.properties.n_stops_total.toFixed(0);
							let num_trains = json.properties.n_trains.toFixed(1);
							let waiting_min = json.properties.waiting_time_min.toFixed(1);
							operators = json.properties.operators;
							let difference = (time_car - time_train).toFixed(1);
							let color;
							(difference >= 0) ? color = '#409326' : color = '#C31A1A';
							html_add = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Travel time</span></p><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle;"><thead><tr><th>&#128664;</th><th>&#x1f686;</th><th>&#128337;</th></tr></thead><tbody><tr><td>' + time_car + 'min </td><td>' + time_train + 'min </td><td><span style="color: ' + color + '; font-weight: bold;">' + difference + 'min </span></td></tr></tbody></table><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle;"><tbody><tr><td><span style="color: gray; font-size: 11px;">Stops: ' + stops + '</span></td><td><span style="color: gray; font-size: 11px;">Trains: ' + num_trains + '</span></td><td><span style="color: gray; font-size: 11px;">Waiting time: ' + waiting_min + 'min </span></td></tr></tbody></table>';
						}

						let html_operators = '';
						let html_id_plz = '';
						let html_recommendation = ''
						if (this.state.selection === 'plz') {
							html_id_plz = '<h4 style="margin-block-start:0px; margin-block-end:0px;"> Postal code: ' + json.properties.origin + ' ~ ' + json.properties.destination + '</h4>';
							if (operators !== null) {
								html_operators = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Operators</span></p>' + operators + '</p>';
							}
							let recommendation = json.properties.recommendation;
							html_recommendation = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Recommendation</span></p>' + recommendation + '</p>';
						}

						let origin_lat = json.geometry.coordinates[0][1];
						let origin_long = json.geometry.coordinates[0][0];
						let destination_lat = json.geometry.coordinates[1][1];
						let destination_long = json.geometry.coordinates[1][0];
						let google_maps_url = 'https://www.google.com/maps/dir/' + origin_lat + ',' + origin_long + '/' + destination_lat + ',' + destination_long;

						let html_button = '<a href="' + google_maps_url + '" target="_blank"><button style="background-color: #0c8a8a;color: white;border: 1px solid #e4e4e4;padding: 8px;border-radius: 6px;cursor: pointer;">Google Maps Route</button></a>';
						let html_modal = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Modal Split</span></p><p style="margin-block-end: 0em;"><span style="font-weight: semibold;">No data available</span></p>';

						let cars_percentage = 0;
						let trains_percentage = 0;
						let bus_percentage = 0;

						cars_percentage = (Number(json.properties.modal_split_cars_weekdays) * 100).toFixed(0);
						trains_percentage = (Number(json.properties.modal_split_trains_weekdays) * 100).toFixed(0);
						bus_percentage = (Number(json.properties.modal_split_bus_weekdays) * 100).toFixed(0);

						let other_percentage = (Number(100) - (Number(cars_percentage) + Number(trains_percentage) + Number(bus_percentage))).toFixed(0);

						if ((num_passengers_wd_total !== '-') & (num_passengers_car_wd !== '-') & (num_passengers_train_wd !== '-')) {
							html_modal = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Modal Split</span></p><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle; width: 100%;"><thead><tr><th></th><th style="width:50%; text-align: start; color:#F78948">Car</th><th style="float:right; position:relative, width:50%; color:#BF3A8C;">Bus</th><th style="width:50%; text-align: end;"><span style="float:right; position:relative, width:50%; color:#0fabab;">Trains</span></th></tr></thead><tbody><tr><th></th><th style="width:50%; text-align: start; font-weight: normal;">' + cars_percentage + '%</th><th style="width:50%; text-align: end;"><span style="float:right; position:relative, width:50%; font-weight: normal;">' + bus_percentage + '%</span></th><th style="width:50%; text-align: end;"><span style="float:right; position:relative, width:50%; font-weight: normal;">' + trains_percentage + '%</span></th></tr></tbody></table><span class="block" style="display: block;height: 20px;color: #fff;font-size: 1em;float: left;background-color: #F78948;position: relative;overflow: hidden;width:' + cars_percentage + '%"></span><span class="block" style="display:block; height:20px; color:#fff; font-size:1em; float:left; background-color:#2f3338; position:relative; overflow:hidden; width:' + other_percentage + '%;"></span><span class="block" style="display:block; height:20px; color:#fff; font-size:1em; float:left; background-color:#BF3A8C; position:relative; overflow:hidden; width:' + bus_percentage + '%;"></span><span class="block" style="display:block; height:20px; color:#fff; font-size:1em; float:left; background-color:#0fabab; position:relative; overflow:hidden; width:' + trains_percentage + '%;"></span>';
						}

						let html = '<h3 style="margin-block-start:0px; margin-block-end:0px;">' + start_station + ' ~ ' + end_station + '</h3>' + html_id_plz + '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Passengers per day</span></p><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle;"><thead><tr><th></th><th>&#x1f46a; &#128664;</th><th>&#x1f46a; &#x1f686;</th><th>&#x1f46a; &#128652;</th><th>Total</th></tr></thead><tbody><tr><th>Weekdays</th><td>' + num_passengers_car_wd_str + '</td><td>' + num_passengers_train_wd_str + '</td><td>' + num_passengers_wd_bus_str + '</td><td>' + num_passengers_wd_total_str + '</td></tr><tr><th>Weekends</th><td>' + num_passengers_car_we_str + '</td><td>' + num_passengers_train_we_str + '</td><td>-</td><td>' + num_passengers_we_total_str + '</td></tr></tbody></table>';
						new mapboxgl.Popup()
							.setLngLat(e.lngLat)
							.setHTML(html + html_table_pkm + html_modal + html_add + html_recommendation + html_operators + html_button).addTo(map);

					}
					else {
						fetch(URL + "train_vs_cars/detail/" + this.state.month_year + ',' + this.state.selection + "/" + c0)
							.then((res) => res.json())
							.then((json) => {

								console.log(json)

								// Copy coordinates array.
								const coordinates = json.geometry.coordinates.slice();
								// Ensure that if the map is zoomed out such that multiple
								// copies of the feature are visible, the popup appears
								// over the copy being pointed to.
								while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
									coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
								}

								let geodesic_distance = json.properties.geodesic_distance_m / 1000;

								let num_passengers_car_wd = json.properties.car_trips_weekdays;
								let num_passengers_car_we = json.properties.car_trips_weekend;
								let num_passengers_train_wd = json.properties.train_trips_weekdays;
								let num_passengers_train_we = json.properties.train_trips_weekend;
								let num_passengers_we_total = json.properties.total_trips_weekend;
								let num_passengers_wd_total = json.properties.total_trips_weekdays;

								let num_passengers_car_wd_str = '';
								let num_passengers_car_we_str = '';
								let num_passengers_train_wd_str = '';
								let num_passengers_train_we_str = '';
								let num_passengers_we_total_str = '';
								let num_passengers_wd_total_str = '';

								(typeof num_passengers_car_wd == 'number' & num_passengers_car_wd > 0) ? num_passengers_car_wd = (num_passengers_car_wd).toFixed(0) : num_passengers_car_wd = '-';
								(num_passengers_car_wd !== '-') ? num_passengers_car_wd_str = numberWithCommas(num_passengers_car_wd) : num_passengers_car_wd_str = '-';

								(typeof num_passengers_car_we == 'number' & num_passengers_car_we > 0) ? num_passengers_car_we = (num_passengers_car_we).toFixed(0) : num_passengers_car_we = '-';
								(num_passengers_car_we !== '-') ? num_passengers_car_we_str = numberWithCommas(num_passengers_car_we) : num_passengers_car_we_str = '-';

								(typeof num_passengers_train_wd == 'number' & num_passengers_train_wd > 0) ? num_passengers_train_wd = (num_passengers_train_wd).toFixed(0) : num_passengers_train_wd = '-';
								(num_passengers_train_wd !== '-') ? num_passengers_train_wd_str = numberWithCommas(num_passengers_train_wd) : num_passengers_train_wd_str = '-';

								(typeof num_passengers_train_we == 'number' & num_passengers_train_we > 0) ? num_passengers_train_we = (num_passengers_train_we).toFixed(0) : num_passengers_train_we = '-';
								(num_passengers_train_we !== '-') ? num_passengers_train_we_str = numberWithCommas(num_passengers_train_we) : num_passengers_train_we_str = '-';


								(typeof num_passengers_wd_total == 'number' & num_passengers_wd_total > 0) ? num_passengers_wd_total = (num_passengers_wd_total).toFixed(0) : num_passengers_wd_total = '-';

								if ((num_passengers_wd_total < (Number(num_passengers_train_wd) + Number(num_passengers_car_wd))) & num_passengers_wd_total !== '-') {
									num_passengers_wd_total = Number(num_passengers_train_wd) + Number(num_passengers_car_wd);
								}
								(num_passengers_wd_total !== '-') ? num_passengers_wd_total_str = numberWithCommas(num_passengers_wd_total) : num_passengers_wd_total_str = '-';

								(typeof num_passengers_we_total == 'number' & num_passengers_we_total > 0) ? num_passengers_we_total = (num_passengers_we_total).toFixed(0) : num_passengers_we_total = '-';
								(num_passengers_we_total !== '-') ? num_passengers_we_total_str = numberWithCommas(num_passengers_we_total) : num_passengers_we_total_str = '-';


								//Passenger Km (PKm) based on geodesic distance
								let num_passengers_car_wd_km = (num_passengers_car_wd !== '-') ? numberWithCommas((num_passengers_car_wd * geodesic_distance).toFixed(0)) : '-';
								let num_passengers_car_we_km = (num_passengers_car_we !== '-') ? numberWithCommas((num_passengers_car_we * geodesic_distance).toFixed(0)) : '-';
								let num_passengers_train_wd_km = (num_passengers_train_wd !== '-') ? numberWithCommas((num_passengers_train_wd * geodesic_distance).toFixed(0)) : '-';
								let num_passengers_train_we_km = (num_passengers_train_we !== '-') ? numberWithCommas((num_passengers_train_we * geodesic_distance).toFixed(0)) : '-';
								let num_passengers_we_total_km = (num_passengers_we_total !== '-') ? numberWithCommas((num_passengers_we_total * geodesic_distance).toFixed(0)) : '-';
								let num_passengers_wd_total_km = (num_passengers_wd_total !== '-') ? numberWithCommas((num_passengers_wd_total * geodesic_distance).toFixed(0)) : '-';

								let html_table_pkm = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">PKm per day</span></p><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle;"><thead><tr><th></th><th>&#x1f46a; &#128664;</th><th>&#x1f46a; &#x1f686;</th><th>Total</th></tr></thead><tbody><tr><th>Weekdays</th><td>' + num_passengers_car_wd_km + '</td><td>' + num_passengers_train_wd_km + '</td><td>' + num_passengers_wd_total_km + '</td></tr><tr><th>Weekends</th><td>' + num_passengers_car_we_km + '</td><td>' + num_passengers_train_we_km + '</td><td>' + num_passengers_we_total_km + '</td></tr></tbody></table>';

								let end_station = json.properties.destination_name;
								let start_station = json.properties.origin_name;

								let html_add = ''
								let operators = ''
								if (typeof json.properties.duration_car_s == 'number') {
									let time_car = (json.properties.duration_car_s / 60).toFixed(1);
									let time_train = (json.properties.train_travel_time_min).toFixed(1);
									let stops = json.properties.n_stops_total.toFixed(0);
									let num_trains = json.properties.n_trains.toFixed(1);
									let waiting_min = json.properties.waiting_time_min.toFixed(1);
									operators = json.properties.operators;
									let difference = (time_car - time_train).toFixed(1);
									let color;
									(difference >= 0) ? color = '#409326' : color = '#C31A1A';
									html_add = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Travel time</span></p><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle;"><thead><tr><th>&#128664;</th><th>&#x1f686;</th><th>&#128337;</th></tr></thead><tbody><tr><td>' + time_car + 'min </td><td>' + time_train + 'min </td><td><span style="color: ' + color + '; font-weight: bold;">' + difference + 'min </span></td></tr></tbody></table><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle;"><tbody><tr><td><span style="color: gray; font-size: 11px;">Stops: ' + stops + '</span></td><td><span style="color: gray; font-size: 11px;">Trains: ' + num_trains + '</span></td><td><span style="color: gray; font-size: 11px;">Waiting time: ' + waiting_min + 'min </span></td></tr></tbody></table>';
								}

								let html_operators = '';
								let html_id_plz = '';
								let html_recommendation = ''
								if (this.state.selection === 'plz') {
									html_id_plz = '<h4 style="margin-block-start:0px; margin-block-end:0px;"> Postal code: ' + json.properties.origin + ' ~ ' + json.properties.destination + '</h4>';
									if (operators !== null) {
										html_operators = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Operators</span></p>' + operators + '</p>';
									}
									let recommendation = json.properties.recommendation;
									html_recommendation = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Recommendation</span></p>' + recommendation + '</p>';
								}

								let origin_lat = json.geometry.coordinates[0][1];
								let origin_long = json.geometry.coordinates[0][0];
								let destination_lat = json.geometry.coordinates[1][1];
								let destination_long = json.geometry.coordinates[1][0];
								let google_maps_url = 'https://www.google.com/maps/dir/' + origin_lat + ',' + origin_long + '/' + destination_lat + ',' + destination_long;

								let html_button = '<a href="' + google_maps_url + '" target="_blank"><button style="background-color: #0c8a8a;color: white;border: 1px solid #e4e4e4;padding: 8px;border-radius: 6px;cursor: pointer;">Google Maps Route</button></a>';
								let html_modal = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Modal Split</span></p><p style="margin-block-end: 0em;"><span style="font-weight: semibold;">No data available</span></p>';

								let cars_percentage = 0;
								let trains_percentage = 0;
								if (this.state.day_week === 'days') {
									cars_percentage = (Number(json.properties.modal_split_cars_weekdays) * 100).toFixed(0);
									trains_percentage = (Number(json.properties.modal_split_trains_weekdays) * 100).toFixed(0);
								}
								else {
									cars_percentage = (Number(json.properties.modal_split_cars_weekends) * 100).toFixed(0);
									trains_percentage = (Number(json.properties.modal_split_trains_weekends) * 100).toFixed(0);
								}

								let other_percentage = (Number(100) - (Number(cars_percentage) + Number(trains_percentage))).toFixed(0);

								if ((num_passengers_wd_total !== '-') & (num_passengers_car_wd !== '-') & (num_passengers_train_wd !== '-')) {
									html_modal = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Modal Split</span></p><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle; width: 100%;"><thead><tr><th></th><th style="width:50%; text-align: start;">Car/Bus</th><th style="width:50%; text-align: end;"><span style="float:right; position:relative, width:50%">Trains</span></th></tr></thead><tbody><tr><th></th><th style="width:50%; text-align: start; font-weight: normal;">' + cars_percentage + '%</th><th style="width:50%; text-align: end;"><span style="float:right; position:relative, width:50%; font-weight: normal;">' + trains_percentage + '%</span></th></tr></tbody></table><span class="block" style="display: block;height: 20px;color: #fff;font-size: 1em;float: left;background-color: #F78948;position: relative;overflow: hidden;width:' + cars_percentage + '%"></span><span class="block" style="display:block; height:20px; color:#fff; font-size:1em; float:left; background-color:#2f3338; position:relative; overflow:hidden; width:' + other_percentage + '%;"></span><span class="block" style="display:block; height:20px; color:#fff; font-size:1em; float:left; background-color:#0fabab; position:relative; overflow:hidden; width:' + trains_percentage + '%;"></span>';
								}

								let html = '<h3 style="margin-block-start:0px; margin-block-end:0px;">' + start_station + ' ~ ' + end_station + '</h3>' + html_id_plz + '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Passengers per day</span></p><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle;"><thead><tr><th></th><th>&#x1f46a; &#128664;</th><th>&#x1f46a; &#x1f686;</th><th>Total</th></tr></thead><tbody><tr><th>Weekdays</th><td>' + num_passengers_car_wd_str + '</td><td>' + num_passengers_train_wd_str + '</td><td>' + num_passengers_wd_total_str + '</td></tr><tr><th>Weekends</th><td>' + num_passengers_car_we_str + '</td><td>' + num_passengers_train_we_str + '</td><td>' + num_passengers_we_total_str + '</td></tr></tbody></table>';
								new mapboxgl.Popup()
									.setLngLat(e.lngLat)
									.setHTML(html + html_table_pkm + html_modal + html_add + html_recommendation + html_operators + html_button).addTo(map);
							})
					}

				})
		});

		// When a click event occurs on a feature in the places layer, open a popup at the
		// location of the feature, with description HTML from its properties.
		map.on('click', 'swiss-recommendation-aus', (e) => {

			fetch(URL + "train_vs_cars/detail/2022-06,plz/" + e.features[0].properties._c0)
				.then((res) => res.json())
				.then((json) => {
					// Copy coordinates array.
					const coordinates = json.geometry.coordinates.slice();
					// Ensure that if the map is zoomed out such that multiple
					// copies of the feature are visible, the popup appears
					// over the copy being pointed to.
					while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
						coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
					}

					let geodesic_distance = json.properties.geodesic_distance_m / 1000;

					let num_passengers_car_wd = json.properties.car_trips_weekdays;
					let num_passengers_car_we = json.properties.car_trips_weekend;
					let num_passengers_train_wd = json.properties.train_trips_weekdays;
					let num_passengers_train_we = json.properties.train_trips_weekend;
					let num_passengers_we_total = json.properties.total_trips_weekend;
					let num_passengers_wd_total = json.properties.total_trips_weekdays;

					let num_passengers_car_wd_str = '';
					let num_passengers_car_we_str = '';
					let num_passengers_train_wd_str = '';
					let num_passengers_train_we_str = '';
					let num_passengers_we_total_str = '';
					let num_passengers_wd_total_str = '';

					(typeof num_passengers_car_wd == 'number' & num_passengers_car_wd > 0) ? num_passengers_car_wd = (num_passengers_car_wd).toFixed(0) : num_passengers_car_wd = '-';
					(num_passengers_car_wd !== '-') ? num_passengers_car_wd_str = numberWithCommas(num_passengers_car_wd) : num_passengers_car_wd_str = '-';

					(typeof num_passengers_car_we == 'number' & num_passengers_car_we > 0) ? num_passengers_car_we = (num_passengers_car_we).toFixed(0) : num_passengers_car_we = '-';
					(num_passengers_car_we !== '-') ? num_passengers_car_we_str = numberWithCommas(num_passengers_car_we) : num_passengers_car_we_str = '-';

					(typeof num_passengers_train_wd == 'number' & num_passengers_train_wd > 0) ? num_passengers_train_wd = (num_passengers_train_wd).toFixed(0) : num_passengers_train_wd = '-';
					(num_passengers_train_wd !== '-') ? num_passengers_train_wd_str = numberWithCommas(num_passengers_train_wd) : num_passengers_train_wd_str = '-';

					(typeof num_passengers_train_we == 'number' & num_passengers_train_we > 0) ? num_passengers_train_we = (num_passengers_train_we).toFixed(0) : num_passengers_train_we = '-';
					(num_passengers_train_we !== '-') ? num_passengers_train_we_str = numberWithCommas(num_passengers_train_we) : num_passengers_train_we_str = '-';

					(typeof num_passengers_wd_total == 'number' & num_passengers_wd_total > 0) ? num_passengers_wd_total = (num_passengers_wd_total).toFixed(0) : num_passengers_wd_total = '-';
					if (num_passengers_wd_total < (Number(num_passengers_train_wd) + Number(num_passengers_car_wd)) & num_passengers_wd_total !== '-') {
						num_passengers_wd_total = Number(num_passengers_train_wd) + Number(num_passengers_car_wd);
					}
					(num_passengers_wd_total !== '-') ? num_passengers_wd_total_str = numberWithCommas(num_passengers_wd_total) : num_passengers_wd_total_str = '-';

					(typeof num_passengers_we_total == 'number' & num_passengers_we_total > 0) ? num_passengers_we_total = (num_passengers_we_total).toFixed(0) : num_passengers_we_total = '-';
					(num_passengers_we_total !== '-') ? num_passengers_we_total_str = numberWithCommas(num_passengers_we_total) : num_passengers_we_total_str = '-';


					//Passenger Km (PKm) based on geodesic distance
					let num_passengers_car_wd_km = (num_passengers_car_wd !== '-') ? numberWithCommas((num_passengers_car_wd * geodesic_distance).toFixed(0)) : '-';
					let num_passengers_car_we_km = (num_passengers_car_we !== '-') ? numberWithCommas((num_passengers_car_we * geodesic_distance).toFixed(0)) : '-';
					let num_passengers_train_wd_km = (num_passengers_train_wd !== '-') ? numberWithCommas((num_passengers_train_wd * geodesic_distance).toFixed(0)) : '-';
					let num_passengers_train_we_km = (num_passengers_train_we !== '-') ? numberWithCommas((num_passengers_train_we * geodesic_distance).toFixed(0)) : '-';
					let num_passengers_we_total_km = (num_passengers_we_total !== '-') ? numberWithCommas((num_passengers_we_total * geodesic_distance).toFixed(0)) : '-';
					let num_passengers_wd_total_km = (num_passengers_wd_total !== '-') ? numberWithCommas((num_passengers_wd_total * geodesic_distance).toFixed(0)) : '-';

					let html_table_pkm = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">PKm per day</span></p><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle;"><thead><tr><th></th><th>&#x1f46a; &#128664;</th><th>&#x1f46a; &#x1f686;</th><th>Total</th></tr></thead><tbody><tr><th>Weekdays</th><td>' + num_passengers_car_wd_km + '</td><td>' + num_passengers_train_wd_km + '</td><td>' + num_passengers_wd_total_km + '</td></tr><tr><th>Weekends</th><td>' + num_passengers_car_we_km + '</td><td>' + num_passengers_train_we_km + '</td><td>' + num_passengers_we_total_km + '</td></tr></tbody></table>';

					let end_station = json.properties.destination_name;
					let start_station = json.properties.origin_name;

					let html_add = ''
					let operators = ''
					if (typeof json.properties.duration_car_s == 'number') {
						let time_car = (json.properties.duration_car_s / 60).toFixed(1);
						let time_train = (json.properties.train_travel_time_min).toFixed(1);
						let stops = json.properties.n_stops_total.toFixed(0);
						let num_trains = json.properties.n_trains.toFixed(1);
						let waiting_min = json.properties.waiting_time_min.toFixed(1);
						operators = json.properties.operators;
						let difference = (time_car - time_train).toFixed(1);
						let color;
						(difference >= 0) ? color = '#409326' : color = '#C31A1A';
						html_add = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Travel time</span></p><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle;"><thead><tr><th>&#128664;</th><th>&#x1f686;</th><th>&#128337;</th></tr></thead><tbody><tr><td>' + time_car + 'min </td><td>' + time_train + 'min </td><td><span style="color: ' + color + '; font-weight: bold;">' + difference + 'min </span></td></tr></tbody></table><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle;"><tbody><tr><td><span style="color: gray; font-size: 11px;">Stops: ' + stops + '</span></td><td><span style="color: gray; font-size: 11px;">Trains: ' + num_trains + '</span></td><td><span style="color: gray; font-size: 11px;">Waiting time: ' + waiting_min + 'min </span></td></tr></tbody></table>';
					}

					let html_operators = '';
					let html_id_plz = '';
					let html_recommendation = ''
					if ('plz' === 'plz') {
						html_id_plz = '<h4 style="margin-block-start:0px; margin-block-end:0px;"> Postal code: ' + json.properties.origin + ' ~ ' + json.properties.destination + '</h4>';
						if (operators !== null) {
							html_operators = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Operators</span></p>' + operators + '</p>';
						}
						let recommendation = json.properties.recommendation;
						html_recommendation = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Recommendation</span></p>' + recommendation + '</p>';
					}

					let origin_lat = json.geometry.coordinates[0][1];
					let origin_long = json.geometry.coordinates[0][0];
					let destination_lat = json.geometry.coordinates[1][1];
					let destination_long = json.geometry.coordinates[1][0];
					let google_maps_url = 'https://www.google.com/maps/dir/' + origin_lat + ',' + origin_long + '/' + destination_lat + ',' + destination_long;

					let html_button = '<a href="' + google_maps_url + '" target="_blank"><button style="background-color: #0c8a8a;color: white;border: 1px solid #e4e4e4;padding: 8px;border-radius: 6px;cursor: pointer;">Google Maps Route</button></a>';
					let html_modal = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Modal Split</span></p><p style="margin-block-end: 0em;"><span style="font-weight: semibold;">No data available</span></p>';

					let cars_percentage = 0;
					let trains_percentage = 0;
					if (this.state.day_week === 'days') {
						cars_percentage = (Number(json.properties.modal_split_cars_weekdays) * 100).toFixed(0);
						trains_percentage = (Number(json.properties.modal_split_trains_weekdays) * 100).toFixed(0);
					}
					else {
						cars_percentage = (Number(json.properties.modal_split_cars_weekends) * 100).toFixed(0);
						trains_percentage = (Number(json.properties.modal_split_trains_weekends) * 100).toFixed(0);
					}

					let other_percentage = (Number(100) - (Number(cars_percentage) + Number(trains_percentage))).toFixed(0);

					if ((num_passengers_wd_total !== '-') & (num_passengers_car_wd !== '-') & (num_passengers_train_wd !== '-')) {
						html_modal = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Modal Split</span></p><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle; width: 100%;"><thead><tr><th></th><th style="width:50%; text-align: start;">Car/Bus</th><th style="width:50%; text-align: end;"><span style="float:right; position:relative, width:50%">Trains</span></th></tr></thead><tbody><tr><th></th><th style="width:50%; text-align: start; font-weight: normal;">' + cars_percentage + '%</th><th style="width:50%; text-align: end;"><span style="float:right; position:relative, width:50%; font-weight: normal;">' + trains_percentage + '%</span></th></tr></tbody></table><span class="block" style="display: block;height: 20px;color: #fff;font-size: 1em;float: left;background-color: #F78948;position: relative;overflow: hidden;width:' + cars_percentage + '%"></span><span class="block" style="display:block; height:20px; color:#fff; font-size:1em; float:left; background-color:#2f3338; position:relative; overflow:hidden; width:' + other_percentage + '%;"></span><span class="block" style="display:block; height:20px; color:#fff; font-size:1em; float:left; background-color:#0fabab; position:relative; overflow:hidden; width:' + trains_percentage + '%;"></span>';
					}

					let html = '<h3 style="margin-block-start:0px; margin-block-end:0px;">' + start_station + ' ~ ' + end_station + '</h3>' + html_id_plz + '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Passengers per day</span></p><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle;"><thead><tr><th></th><th>&#x1f46a; &#128664;</th><th>&#x1f46a; &#x1f686;</th><th>Total</th></tr></thead><tbody><tr><th>Weekdays</th><td>' + num_passengers_car_wd_str + '</td><td>' + num_passengers_train_wd_str + '</td><td>' + num_passengers_wd_total_str + '</td></tr><tr><th>Weekends</th><td>' + num_passengers_car_we_str + '</td><td>' + num_passengers_train_we_str + '</td><td>' + num_passengers_we_total_str + '</td></tr></tbody></table>';
					new mapboxgl.Popup()
						.setLngLat(e.lngLat)
						.setHTML(html + html_table_pkm + html_button).addTo(map);
				})
		});

		// Change the cursor to a pointer when the mouse is over the places layer.
		map.on('mouseenter', 'swiss-recommendation', () => {
			map.getCanvas().style.cursor = 'pointer';

		});

		// Change it back to a pointer when it leaves.
		map.on('mouseleave', 'swiss-recommendation', () => {
			map.getCanvas().style.cursor = '';
		});

		// DESTINATION LAYER POP-UP
		map.on('click', 'data-swiss-destination', (e) => {
			let counts_json = JSON.parse(e.features[0].properties.counts)

			let num_passengers_car_wd = counts_json.weekdays?.others;
			let num_passengers_wd_total = counts_json.weekdays?.total;
			let num_passengers_train_wd = counts_json.weekdays?.train;

			let num_passengers_car_we = counts_json.weekend?.others;
			let num_passengers_train_we = counts_json.weekend?.train;
			let num_passengers_we_total = counts_json.weekend?.total;

			let num_passengers_car_wd_str = '';
			let num_passengers_car_we_str = '';
			let num_passengers_train_wd_str = '';
			let num_passengers_train_we_str = '';
			let num_passengers_we_total_str = '';
			let num_passengers_wd_total_str = '';


			(typeof num_passengers_car_wd == 'number' & num_passengers_car_wd > 0) ? num_passengers_car_wd = (num_passengers_car_wd).toFixed(0) : num_passengers_car_wd = '-';
			(num_passengers_car_wd !== '-') ? num_passengers_car_wd_str = numberWithCommas(num_passengers_car_wd) : num_passengers_car_wd_str = '-';

			(typeof num_passengers_car_we == 'number' & num_passengers_car_we > 0) ? num_passengers_car_we = (num_passengers_car_we).toFixed(0) : num_passengers_car_we = '-';
			(num_passengers_car_we !== '-') ? num_passengers_car_we_str = numberWithCommas(num_passengers_car_we) : num_passengers_car_we_str = '-';

			(typeof num_passengers_train_wd == 'number' & num_passengers_train_wd > 0) ? num_passengers_train_wd = (num_passengers_train_wd).toFixed(0) : num_passengers_train_wd = '-';
			(num_passengers_train_wd !== '-') ? num_passengers_train_wd_str = numberWithCommas(num_passengers_train_wd) : num_passengers_train_wd_str = '-';

			(typeof num_passengers_train_we == 'number' & num_passengers_train_we > 0) ? num_passengers_train_we = (num_passengers_train_we).toFixed(0) : num_passengers_train_we = '-';
			(num_passengers_train_we !== '-') ? num_passengers_train_we_str = numberWithCommas(num_passengers_train_we) : num_passengers_train_we_str = '-';

			(typeof num_passengers_wd_total == 'number' & num_passengers_wd_total > 0) ? num_passengers_wd_total = (num_passengers_wd_total).toFixed(0) : num_passengers_wd_total = '-';
			if (num_passengers_wd_total < (Number(num_passengers_train_wd) + Number(num_passengers_car_wd)) & num_passengers_wd_total !== '-') {
				num_passengers_wd_total = Number(num_passengers_train_wd) + Number(num_passengers_car_wd);
			}
			(num_passengers_wd_total !== '-') ? num_passengers_wd_total_str = numberWithCommas(num_passengers_wd_total) : num_passengers_wd_total_str = '-';

			(typeof num_passengers_we_total == 'number' & num_passengers_we_total > 0) ? num_passengers_we_total = (num_passengers_we_total).toFixed(0) : num_passengers_we_total = '-';
			(num_passengers_we_total !== '-') ? num_passengers_we_total_str = numberWithCommas(num_passengers_we_total) : num_passengers_we_total_str = '-';

			let html = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Passengers per day</span></p><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle;"><thead><tr><th></th><th>&#x1f46a; &#128664;</th><th>&#x1f46a; &#x1f686;</th><th>Total</th></tr></thead><tbody><tr><th>Weekdays</th><td>' + num_passengers_car_wd_str + '</td><td>' + num_passengers_train_wd_str + '</td><td>' + num_passengers_wd_total_str + '</td></tr><tr><th>Weekends</th><td>' + num_passengers_car_we_str + '</td><td>' + num_passengers_train_we_str + '</td><td>' + num_passengers_we_total_str + '</td></tr></tbody></table>';
			new mapboxgl.Popup()
				.setLngLat(e.lngLat)
				.setHTML(html).addTo(map);
		});

		// Create a popup, but don't add it to the map yet.
		const popupHover = new mapboxgl.Popup({
			closeButton: false,
			closeOnClick: false
		});

		// Change the cursor to a pointer when the mouse is over the places layer.
		map.on('mouseenter', 'data-swiss-destination', (e) => {
			map.getCanvas().style.cursor = 'pointer';
			let counts_json = JSON.parse(e.features[0].properties.counts)

			let num_passengers_car_wd = counts_json.weekdays?.others;
			let num_passengers_wd_total = counts_json.weekdays?.total;
			let num_passengers_train_wd = counts_json.weekdays?.train;

			let num_passengers_car_we = counts_json.weekend?.others;
			let num_passengers_train_we = counts_json.weekend?.train;
			let num_passengers_we_total = counts_json.weekend?.total;

			let num_passengers_car_wd_str = '';
			let num_passengers_car_we_str = '';
			let num_passengers_train_wd_str = '';
			let num_passengers_train_we_str = '';
			let num_passengers_we_total_str = '';
			let num_passengers_wd_total_str = '';

			(typeof num_passengers_car_wd == 'number' & num_passengers_car_wd > 0) ? num_passengers_car_wd = (num_passengers_car_wd).toFixed(0) : num_passengers_car_wd = '-';
			(num_passengers_car_wd !== '-') ? num_passengers_car_wd_str = numberWithCommas(num_passengers_car_wd) : num_passengers_car_wd_str = '-';

			(typeof num_passengers_car_we == 'number' & num_passengers_car_we > 0) ? num_passengers_car_we = (num_passengers_car_we).toFixed(0) : num_passengers_car_we = '-';
			(num_passengers_car_we !== '-') ? num_passengers_car_we_str = numberWithCommas(num_passengers_car_we) : num_passengers_car_we_str = '-';

			(typeof num_passengers_train_wd == 'number' & num_passengers_train_wd > 0) ? num_passengers_train_wd = (num_passengers_train_wd).toFixed(0) : num_passengers_train_wd = '-';
			(num_passengers_train_wd !== '-') ? num_passengers_train_wd_str = numberWithCommas(num_passengers_train_wd) : num_passengers_train_wd_str = '-';

			(typeof num_passengers_train_we == 'number' & num_passengers_train_we > 0) ? num_passengers_train_we = (num_passengers_train_we).toFixed(0) : num_passengers_train_we = '-';
			(num_passengers_train_we !== '-') ? num_passengers_train_we_str = numberWithCommas(num_passengers_train_we) : num_passengers_train_we_str = '-';

			(typeof num_passengers_wd_total == 'number' & num_passengers_wd_total > 0) ? num_passengers_wd_total = (num_passengers_wd_total).toFixed(0) : num_passengers_wd_total = '-';
			if (num_passengers_wd_total < (Number(num_passengers_train_wd) + Number(num_passengers_car_wd)) & num_passengers_wd_total !== '-') {
				num_passengers_wd_total = Number(num_passengers_train_wd) + Number(num_passengers_car_wd);
			}
			(num_passengers_wd_total !== '-') ? num_passengers_wd_total_str = numberWithCommas(num_passengers_wd_total) : num_passengers_wd_total_str = '-';

			(typeof num_passengers_we_total == 'number' & num_passengers_we_total > 0) ? num_passengers_we_total = (num_passengers_we_total).toFixed(0) : num_passengers_we_total = '-';
			(num_passengers_we_total !== '-') ? num_passengers_we_total_str = numberWithCommas(num_passengers_we_total) : num_passengers_we_total_str = '-';

			let html = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Passengers per day</span></p><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle;"><thead><tr><th></th><th>&#x1f46a; &#128664;</th><th>&#x1f46a; &#x1f686;</th><th>Total</th></tr></thead><tbody><tr><th>Weekdays</th><td>' + num_passengers_car_wd_str + '</td><td>' + num_passengers_train_wd_str + '</td><td>' + num_passengers_wd_total_str + '</td></tr><tr><th>Weekends</th><td>' + num_passengers_car_we_str + '</td><td>' + num_passengers_train_we_str + '</td><td>' + num_passengers_we_total_str + '</td></tr></tbody></table>';
			popupHover
				.setLngLat(e.lngLat)
				.setHTML(html).addTo(map);
		});

		// Change it back to a pointer when it leaves.
		map.on('mouseleave', 'data-swiss-destination', () => {
			map.getCanvas().style.cursor = '';
			popupHover.remove();
		});

		let polygonID = null;
		// ORIGIN LAYER POP-UP
		map.on('click', 'data-swiss-origin', (e) => {
			map.getCanvas().style.cursor = 'pointer';
			if (e.features.length > 0) {
				if (typeof polygonID === 'number') {
					map.removeFeatureState({
						source: "data-swiss-origin",
						id: polygonID
					});
				}
				polygonID = e.features[0].id;

				map.setFeatureState({
					source: 'data-swiss-origin',
					id: polygonID,
				}, {
					clicked: true
				});
			}

			let origin = 0;
			if (this.state.selection === 'plz') { origin = e.features[0].properties.tileId; }
			else { origin = e.features[0].properties.PLZ; }

			if (this.state.origin === this.state.destination && this.state.origin !== 0) {
				map.getSource('data-swiss-origin').setData(URL + "heatmap_t_vs_c/plz_geojson/2020-06,bfs/empty,empty");
			}

			let month_year = this.state.month_year
			if (this.state.selection === 'plz') {
				map.getSource('data-swiss-destination').setData(URL + "heatmap_t_vs_c/tiles_heatmap/" + month_year + ',' + this.state.selection + "/" + origin + "," + this.state.currentDestination);
			}
			else {
				map.getSource('data-swiss-destination').setData(URL + "heatmap_t_vs_c/plz_heatmap/" + month_year + ',' + this.state.selection + "/" + origin + "," + this.state.currentDestination);
			}


		});

		// Change the cursor to a pointer when the mouse is over the places layer.
		map.on('mouseenter', 'data-swiss-origin', () => {
			map.getCanvas().style.cursor = 'pointer';
		});

		// Change it back to a pointer when it leaves.
		map.on('mouseleave', 'data-swiss-origin', () => {
			map.getCanvas().style.cursor = '';
		});

	}

	render() {
		const { hideNavs } = this.state;

		return (
			<div>
				<div ref={el => this.mapContainer = el} style={{ width: '100%', height: '100vh' }}>
				</div>
				<RecommendationNavBarComponent />
				<HideElementsComponent map={this.state.mapita} updateField={(card, field, value) => this.updateField(card, field, value)}/>
				<div style={{ display: hideNavs ? 'none' : 'block' }}>
					<MobilityInsightsAnalyzerComponent map={this.state.mapita} zoom={this.state.zoom} {...this.state} updateField={(card, field, value) => this.updateField(card, field, value)} updateFields={(list) => this.updateFields(list)} />
					<GraphComponent map={this.state.mapita} hasInfo={this.state.hasOriginDestination} selection={this.state.selection} graphParam={this.state.graphParam} month_year={this.state.month_year} />
					</div>
				<LayerTylesComponent map={this.state.mapita} tyle={this.state.tyle} handleTyle={(value) => this.handleTyle(value)} handleCoord={(value1, value2) => this.handleCoord(value1, value2)} />
			</div>
		)
	}
}

export default MappRecommendationHeatmap;