import React from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import axios from 'axios';
import { IconContext } from "react-icons";
import { BsLayersHalf } from "react-icons/bs";
import RecommendationNavBarComponent from "../../components/RecommendationNavBarComponent";
import Slider from 'rc-slider';
import { Button, Form } from 'react-bootstrap';
import '../css/map-areas2focus.css';
import Select from 'react-select';
import { RiFullscreenFill, RiFullscreenExitFill } from "react-icons/ri";

//Sorting filter

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const URL = process.env.REACT_APP_URL_SERVER_BACK;
const URL_FRONT = process.env.REACT_APP_URL_SERVER_FRONT;

const _ = require('lodash');

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
}

let tile_empty = {
  'type': 'FeatureCollection',
  'features': [
    {
      'type': 'Feature',
      'geometry': {
        "type": "LineString",
        "coordinates": [
        ]
      }
    }
  ]
};
let top_tile_geojson = tile_empty;
let bottom_tile_geojson = tile_empty;

class HideElementsComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hideNavs: false,
      mapa: this.props.map,
    }
  }

  hideComponent() {
    let newHideNavs = !this.state.hideNavs;
    this.setState({ hideNavs: newHideNavs })
    this.props.updateField("hideNavs", newHideNavs);
  }

  render() {

    const { hideNavs } = this.state;

    return (
      <div>
        {hideNavs ? <>
          <div className='map-close-navs'>
            <div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
              <IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
                <div>
                  <RiFullscreenExitFill />
                </div>
              </IconContext.Provider>
            </div>
          </div>
        </> : <>
          <div className='map-close-navs'>
            <div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
              <IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
                <div>
                  <RiFullscreenFill />
                </div>
              </IconContext.Provider>
            </div>
          </div>
        </>}
      </div>
    )
  }
}

class MapTraffic3D extends React.Component {

  // Set up states for updating map 
  constructor(props) {
    super(props);
    this.state = {
      lng:  9.377221475764886,
      lat: 47.424063106639615,
      zoom: 15.2,
      mapita: 15.2,
      tyle: 'mapbox://styles/mapbox/light-v11',
      coord: [],
      hideNavs: false
    }
  }

  //Update several fields
  updateFields(list) {
    this.setState(list);
  }

  //Update map on component update
  updateField(card, value) {
    this.setState({
      ...this.state,
      [card]: value
    });
  }

  //Update style
  handleTyle(value) {
    this.setState({ tyle: value }, this.componentDidMount);
  }

  //Update coordinates
  handleCoord(center, zoom) {
    const { lng, lat } = center;
    this.setState({ zoom: zoom, lat: lat, lng: lng })
  }

  //Update coordinates
  handleCoordMoveToTyle(lng, lat, zoom) {
    this.setState({ zoom: zoom, lat: lat, lng: lng })
  }

  componentDidMount() {
    // Set up map 
    const map = new mapboxgl.Map({
      container: this.mapContainer,
      style: this.state.tyle,
      center: [this.state.lng, this.state.lat],
      zoom: this.state.zoom,
      projection: 'naturalEarth',
      pitch: 45,
      bearing: -17.6,
    });

    map.on('style.load', () => {
      // Insert the layer beneath any symbol layer.
      const layers = map.getStyle().layers;
      const labelLayerId = layers.find(
        (layer) => layer.type === 'symbol' && layer.layout['text-field']
      ).id;

      // The 'building' layer in the Mapbox Streets
      // vector tileset contains building height data
      // from OpenStreetMap.
     
    });

    map.on('load', () => {

      // Add zoom and rotation controls to the map.
      map.addControl(new mapboxgl.NavigationControl({ showCompass: false, showZoom: false }), 'top-left');
      map.addControl(new MapboxGeocoder({ accessToken: mapboxgl.accessToken, mapboxgl: mapboxgl, marker: false }), 'top-left');


    })

    this.setState({
      mapita: map,
      zoom: map.getZoom(),
      center: map.getCenter()
    });

  }

  render() {
    const { hideNavs } = this.state;

    return (
      <div>
        <div ref={el => this.mapContainer = el} style={{ width: '100%', height: '100vh' }}>
        </div>
        <RecommendationNavBarComponent />
        <HideElementsComponent map={this.state.mapita} updateField={(card, field, value) => this.updateField(card, field, value)} />
        {hideNavs ?
          <></>
          :
          <>

          </>
        }
      </div>
    )
  }
}

export default MapTraffic3D;
