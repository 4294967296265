import React from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '../css/map-drop-point.css';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';
import Chart from 'chart.js/auto';
import { IconContext } from "react-icons";
import { BsLayersHalf } from "react-icons/bs";
import Select from 'react-select';
import RecommendationNavBarComponent from "../../components/RecommendationNavBarComponent";
import Slider from 'rc-slider';
import { useParams } from "react-router-dom";
import { RiFullscreenFill, RiFullscreenExitFill } from "react-icons/ri";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ExportExcel from "../../utils/ExportExcel";
import { Line } from 'react-chartjs-2';
import { VscGraphLine } from "react-icons/vsc";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const URL = process.env.REACT_APP_URL_SERVER_BACK;

const _ = require('lodash');

let generated_info = false;
function numberWithCommas(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
}

const lines_geojson = {
	'type': 'FeatureCollection',
	'features': [
		{
			'type': 'Feature',
			'geometry': {
				"type": "LineString",
				"coordinates": [
				]
			}
		}
	]
};

const point_geojson = {
	'type': 'FeatureCollection',
	'features': [
		{
			'type': 'Feature',
			'geometry': {
				'type': 'Point',
				'coordinates': [9.352465, 47.414421]
			}
		}
	]
};

class HideElementsComponent extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			hideNavs: false,
			mapa: this.props.map,
		}
	}

	hideComponent() {
		let newHideNavs = !this.state.hideNavs;
		this.setState({ hideNavs: newHideNavs })
		this.props.updateField("hideNavs", newHideNavs);
	}

	render() {

		const { hideNavs } = this.state;

		return (
			<div>
				{hideNavs ? <div className='map-close-navs'>
					<div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
						<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
							<div>
								<RiFullscreenExitFill />
							</div>
						</IconContext.Provider>
					</div>
				</div> : <div className='map-close-navs'>
					<div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
						<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
							<div>
								<RiFullscreenFill />
							</div>
						</IconContext.Provider>
					</div>
				</div>}
			</div>
		)
	}
}

//Layer Tyles Selection - Side Menu
class LayerTylesComponent extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			showOptions: false,
			mapa: this.props.map,
		}
		this.hideComponent = this.hideComponent.bind(this);
		this.handleTyleEvent = this.handleTyleEvent.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (this.props.map !== prevProps.map) {
			this.setState({ mapa: this.props.map })
		}
	}

	hideComponent(name) {
		this.setState({ showOptions: !this.state.showOptions });
	}

	handleTyleEvent(event) {
		switch (event) {
			case "default":
				this.props.handleTyle("mapbox://styles/mapbox/light-v10");
				break;
			case "outdoors":
				this.props.handleTyle("mapbox://styles/mapbox/outdoors-v11");
				break;
			case "satellite":
				this.props.handleTyle("mapbox://styles/mapbox/satellite-streets-v11");
				break;
			case "streets":
				this.props.handleTyle("mapbox://styles/mapbox/streets-v11");
				break;
			case "dark":
				this.props.handleTyle("mapbox://styles/mapbox/dark-v10");
				break;
			default:
				// eslint-disable-next-line no-unused-expressions
				null;
		}
		this.props.handleCoord(this.state.mapa.getCenter(), this.state.mapa.getZoom());
	}

	render() {
		const { showOptions } = this.state;

		return (
			<div>
				{showOptions ? <div id='layer-options' className='map-layer-options-tyles'>
					<div className='map-layer-option-dark' onClick={() => this.handleTyleEvent("dark")}>
						<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
							<div>
								<img src={require('../../assets/img/Dark.png')} alt="Default tyle" className='img-tyle' />
							</div>
						</IconContext.Provider>
						<p className='label'>Dark</p>
					</div>
					<div className='map-layer-option-streets' onClick={() => this.handleTyleEvent("streets")}>
						<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
							<div>
								<img src={require('../../assets/img/Streets.png')} alt="Default tyle" className='img-tyle' />
							</div>
						</IconContext.Provider>
						<p className='label'>Streets</p>
					</div>
					<div className='map-layer-option-satellite' onClick={() => this.handleTyleEvent("satellite")}>
						<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
							<div>
								<img src={require('../../assets/img/Satellite.png')} alt="Default tyle" className='img-tyle' />
							</div>
						</IconContext.Provider>
						<p className='label'>Satellite</p>
					</div>
					<div className='map-layer-option-outdoors' onClick={() => this.handleTyleEvent("outdoors")}>
						<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
							<div>
								<img src={require('../../assets/img/Outdoors.png')} alt="Default tyle" className='img-tyle' />
							</div>
						</IconContext.Provider>
						<p className='label'>Outdoors</p>
					</div>
					<div className='map-layer-option-default' onClick={() => this.handleTyleEvent("default")}>
						<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
							<div>
								<img src={require('../../assets/img/White.png')} alt="Default tyle" className='img-tyle' />
							</div>
						</IconContext.Provider>
						<p className='label'>Default</p>
					</div>
				</div> : <></>}
				<div className='map-layer-tyles'>
					<div className='map-layer-tyles-inner' onClick={() => this.hideComponent("showOptions")}>
						<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
							<div>
								<BsLayersHalf />
							</div>
						</IconContext.Provider>
					</div>
				</div>
			</div>
		)
	}
}

class BtnsComponent extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			mapa: this.props.map,
			generatedTyles: false,
			loading: false,
			value_od_dates: ({
				"value": "2022-08",
				"label": '2022-08'
			}),
			dates_trips_: this.getOptionsDate(URL + 'db_od_trips'),
			min_distance_km: 0,
			max_distance_km: 400,
			percentage: 100,
			percentage_type: 'trips',
			n_trips: 0,
			coord: this.props.coord,
			min_distance_to_train: 0,
			max_distance_to_train: 35,
			total_pkm: 0,
			current_pkm: 0,
			total_trips: 0,
			current_trips: 0,
			percentage_pkm: 100,
			percentage_trips: 100,
			zonen_id: this.props.zonen_id,
			no_data_modal: this.props.no_data_modal,
			reload_data: this.props.reload_data
		}
	}

	componentDidMount() {
		this.handleFetchDataCoord = this.handleFetchDataCoord.bind(this);
		this.resetView = this.resetView.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (this.props.map !== prevProps.map) {
			this.setState({ mapa: this.props.map });
		}
		if (this.props.coord !== prevProps.coord) {
			this.setState({ coord: this.props.coord });
		}
		if (this.props.zonen_id !== prevProps.zonen_id) {
			this.setState({ zonen_id: this.props.zonen_id })
		}
		if (this.props.no_data_modal !== prevProps.no_data_modal) {
			this.setState({ no_data_modal: this.props.no_data_modal })
		}
		if (this.props.reload_data !== prevProps.reload_data){
			if(this.props.reload_data){
				this.demoAsyncCall(this.props.zonen_id);
				this.props.updateFields({reload_data: false});
			}
		}
	}

	async getOptionsDate(url) {
		const res = await axios.get(url)
		const data = res.data
		const options = data.map(d => ({
			"value": d._id.date.toString().split('T')[0].slice(0, -3),
			"label": d._id.date.toString().split('T')[0].slice(0, -3)
		}))
		this.setState({ dates_trips_: options });
		//this.setState({ value_od_dates: options[options.length - 1] });
	}

	demoAsyncCall(zonen_id) {
		fetch(URL + 'ai_zonen/ods', {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ origin: zonen_id }),
		})
			.then((res) => res.json())
			.then((json) => {
				this.props.map.getSource('data-swiss-destinations').setData(json);
				this.setState({ loading: false });
			})

	}

	handleFetchDataCoord() {
		this.setState({ loading: true });
		this.demoAsyncCall(this.state.zonen_id);
		this.setState({ generatedTyles: !this.state.generatedTyles });
		generated_info = true;

	}

	resetView() {
		this.state.mapa.getSource('data-swiss-destinations').setData(lines_geojson);
		this.state.mapa.getSource('data-swiss-origin').setData(lines_geojson);

		this.props.updateFields({ graphParam: '' })
		this.props.updateFields({ zonen_id: 0 })

		this.setState({ generatedTyles: false })

		this.props.updateFields({ no_data_modal: false });
		generated_info = false;
	}

	render() {
		const { loading } = this.state;
		const { generatedTyles } = this.state;

		if (loading) { // if your component doesn't have to wait for an async action, remove this block 
			return (
				<div className="bg-loader">
					<div className="pos-center">
						<div className="loader"></div>
					</div>
				</div>
			)
		}
		else {
			return (
				<div>
					<div className="map-btn-drop-point">
						<div className="map-btn-drop-point-inner">
							<h1>Category Zones: Origin-Destination</h1>
							<p className="label">Place the circle on the desired coordinates. Click on <i>Generate matrix</i> to visualize the all destinations of selected coordinate</p>
							<div className="row">
								<div className="column-1-eq">
									<Form onClick={this.handleFetchDataCoord.bind(this)}>
										<Button className="generate-btn" variant="info" disabled={(generatedTyles || this.state.zonen_id === 0)}>Generate matrix</Button>
									</Form>
								</div>
								<div className="column-2-eq">
									<Form onClick={this.resetView.bind(this)}>
										<Button className="reset-btn" variant="info" disabled={(!generatedTyles)}>Reset matrix</Button>
									</Form>
								</div>
							</div>
						</div>
					</div>
					{true ?
						<div className="map-btn-drop-point" style={{ top: '270px' }}>
							<div className="map-btn-drop-point-inner" style={{ height: '250px' }}>
								<h1 style={{ marginBottom: '5px' }}>Color scheme</h1>
								<div className="row-parameters-2">
									<div className="column-30"><div className="square" style={{ backgroundColor: "#cc3399" }}><p className="label-2">Verkehrzonen</p></div></div>
									<div className="column-30"><div className="square" style={{ backgroundColor: "#fa5d20" }}><p className="label-2">Weitere</p></div></div>
									<div className="column-30"><div className="square" style={{ backgroundColor: "#ff9320" }}><p className="label-2">Tourismus- und Freizeitzonen</p></div></div>
									<div className="column-30"><div className="square" style={{ backgroundColor: "#ffb013" }}><p className="label-2">Spitaler</p></div></div>
									<div className="column-30"><div className="square" style={{ backgroundColor: "#3399cc" }}><p className="label-2">Arbeitzonen</p></div></div>
									<div className="column-30"><div className="square" style={{ backgroundColor: "#4cc9f0" }}><p className="label-2">Zonen für öffentliche Nutzungen</p></div></div>
									<div className="column-30"><div className="square" style={{ backgroundColor: "#99cc33" }}><p className="label-2">Mischzonen</p></div></div>
									<div className="column-30"><div className="square" style={{ backgroundColor: "#25a244" }}><p className="label-2">Eingeschränkte Bauzonen</p></div></div>
									<div className="column-30"><div className="square" style={{ backgroundColor: "#b67be6" }}><p className="label-2">Wohnzonen</p></div></div>
									<div className="column-30"><div className="square" style={{ backgroundColor: "#720AB7" }}><p className="label-2">Ausbildung</p></div></div>
									<div className="column-30"><div className="square" style={{ backgroundColor: "#7a0044" }}><p className="label-2">Bahnhof</p></div></div>
									<div className="column-30"><div className="square" style={{ backgroundColor: "#d00000" }}><p className="label-2">Einkaufen</p></div></div>

								</div>

							</div>
						</div>
						: <></>
					}
					{this.state.no_data_modal ?
						<div className="map-btn-drop-point" style={{ top: '550px' }}>
							<div className="map-btn-drop-point-inner" style={{ height: '30px' }}>
								<h1 style={{ fontSize: '12px' }}>No data available</h1>
							</div>
						</div>
						: <></>
					}
				</div>
			)
		}
	}
}

class MappDropPointZones extends React.Component {

	// Set up states for updating map 
	constructor(props) {
		super(props);
		this.state = {
			lng: 9.352465,
			lat: 47.414421,
			zoom: 10,
			mapita: 9,
			tyle: 'mapbox://styles/mapbox/satellite-streets-v11',
			coord: this.props.params,
			hideNavs: false,
			graphParam: '',
			zonen_id: 0,
			no_data_modal: false,
			reload_data: false
		}
	}

	//Update several fields
	updateFields(list) {
		this.setState(list);
	}

	//Update map on component update
	updateField(card, value) {
		this.setState({
			...this.state,
			[card]: value
		});
	}

	//Update style
	handleTyle(value) {
		this.setState({ tyle: value }, this.componentDidMount);
	}

	//Update coordinates
	handleCoord(center, zoom) {
		const { lng, lat } = center;
		this.setState({ zoom: zoom, lat: lat, lng: lng })

		if(this.state.zonen_id!==0){
			this.setState({reload_data: true})
		}
	}

	componentDidMount() {
		// Set up map 
		const map = new mapboxgl.Map({
			container: this.mapContainer,
			style: this.state.tyle,
			center: [this.state.lng, this.state.lat],
			zoom: this.state.zoom,
		});

		map.on('load', () => {

			// Add zoom and rotation controls to the map.
			map.addControl(new mapboxgl.NavigationControl({ showCompass: false, showZoom: false }), 'top-left');
			map.addControl(new MapboxGeocoder({ accessToken: mapboxgl.accessToken, mapboxgl: mapboxgl, marker: false }), 'top-left');

			// Add circle 
			map.addSource('point', {
				'type': 'geojson',
				'data': point_geojson
			});

			map.addLayer({
				'id': 'point',
				'type': 'circle',
				'source': 'point',
				'paint': {
					'circle-radius': 10,
					'circle-color': '#e32a8d' // blue color
				}
			});

			// add lines to the map - Destination
			map.addSource('data-swiss-destinations', {
				'type': 'geojson',
				'data': lines_geojson,
				'generateId': true,
				'lineMetrics': true
			});

			map.addLayer({
				'id': 'swiss-destinations',
				'type': 'fill',
				'source': 'data-swiss-destinations',
				'paint': {
					'fill-color': ['get', 'color'],
					'fill-opacity': 0.15
				}
			});


			map.addLayer({
				'id': 'swiss-destinations-outline',
				'type': 'line',
				'source': 'data-swiss-destinations',
				'layout': {
					// Make the layer visible by default.
					'visibility': 'visible'
				},
				'paint': {
					'line-color': ['get', 'color'],
					'line-width': 2,
				}
			});

			// add lines to the map - Origin
			map.addSource('data-swiss-origin', {
				'type': 'geojson',
				'data': lines_geojson,
				'generateId': true,
				'lineMetrics': true
			});

			map.addLayer({
				'id': 'swiss-origin',
				'type': 'fill',
				'source': 'data-swiss-origin',
				'paint': {
					'fill-color': ['get', 'color'],
					'fill-opacity': 0.15
				}
			});

			map.addLayer({
				'id': 'swiss-origin-outline',
				'type': 'line',
				'source': 'data-swiss-origin',
				'layout': {
					// Make the layer visible by default.
					'visibility': 'visible'
				},
				'paint': {
					'line-color': ['get', 'color'],
					'line-width': 2,
				}
			});

		});

		this.setState({
			mapita: map,
			zoom: map.getZoom(),
			center: map.getCenter()
		});

		map.on('click', 'swiss-destinations', (e) => {
			let html = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Zone Category: </span>' + e.features[0].properties.category + '</p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Total of trips per day: </span>' + e.features[0].properties.total_trips_weekdays + '</p>';
			new mapboxgl.Popup()
				.setLngLat(e.lngLat)
				.setHTML(html).addTo(map);

		});

		//On click move the point
		map.on('click', (event) => {
			const coords = event.lngLat;
			if (!generated_info) {
				// Print the coordinates of where the point had
				// finished being dragged to on the map.

				map.getCanvas().style.cursor = '';

				// Unbind mouse/touch events
				map.off('mousemove', onMove);
				map.off('touchmove', onMove);

				point_geojson.features[0].geometry.coordinates = [coords.lng, coords.lat];
				map.getSource('point').setData(point_geojson);

				this.setState({ no_data_modal: false })

				fetch(URL + 'ai_zonen/drop_a_point', {
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({ coordinate: [coords.lng, coords.lat] }),
				})
					.then((res) => res.json())
					.then((json) => {
						if (json.features.length === 0) {
							//TODO Modal + Delete previous Layer
							this.setState({ zonen_id: 0 })
							map.getSource('data-swiss-origin').setData(lines_geojson);
							this.setState({ no_data_modal: true })
						}
						else {
							//TODO Layer
							//Make a zoom
							this.setState({ zonen_id: json.features[0].properties.zonen_id })
							map.getSource('data-swiss-origin').setData(json);
							map.setZoom(16);
							this.setState({ no_data_modal: false });
						}
					})
			}
		});

		// When the cursor enters a feature in
		// the point layer, prepare for dragging.
		map.on('mouseenter', 'point', () => {
			map.setPaintProperty('point', 'circle-color', '#9e2e6a');
			map.getCanvas().style.cursor = 'move';
		});

		map.on('mouseleave', 'point', () => {
			map.setPaintProperty('point', 'circle-color', '#9e2e6a');
			map.getCanvas().style.cursor = '';
		});

		function onMove(e) {
			const coords = e.lngLat;
			// Set a UI indicator for dragging.
			map.getCanvas().style.cursor = 'grabbing';

			// Update the Point feature in `geojson` coordinates
			// and call setData to the source layer `point` on it.
			point_geojson.features[0].geometry.coordinates = [coords.lng, coords.lat];
			map.getSource('point').setData(point_geojson);
		}

		function onUp(e) {
			const coords = e.lngLat;
			// Print the coordinates of where the point had
			// finished being dragged to on the map.

			map.getCanvas().style.cursor = '';

			// Unbind mouse/touch events
			map.off('mousemove', onMove);
			map.off('touchmove', onMove);

			point_geojson.features[0].geometry.coordinates = [coords.lng, coords.lat];
			map.getSource('point').setData(point_geojson);
		}

		map.on('mousedown', 'point', (e) => {
			// Prevent the default map drag behavior.
			e.preventDefault();

			map.getCanvas().style.cursor = 'grab';

			map.on('mousemove', onMove);
			map.once('mouseup', onUp);
		});

		map.on('touchstart', 'point', (e) => {
			if (e.points.length !== 1) return;

			// Prevent the default map drag behavior.
			e.preventDefault();

			map.on('touchmove', onMove);
			map.once('touchend', onUp);
		});

		// Fullscreen map 
		//map.addControl(new mapboxgl.FullscreenControl());
	}

	render() {
		const { hideNavs } = this.state;

		return (
			<div>
				<div ref={el => this.mapContainer = el} style={{ width: '100%', height: '100vh' }}>
				</div>
				<RecommendationNavBarComponent />
				<HideElementsComponent map={this.state.mapita} updateField={(card, field, value) => this.updateField(card, field, value)} />
				<div style={{ display: hideNavs ? 'none' : 'block' }}>
					<BtnsComponent map={this.state.mapita} updateFields={(list) => this.updateFields(list)} coord={this.state.coord} zonen_id={this.state.zonen_id} no_data_modal={this.state.no_data_modal} reload_data={this.state.reload_data}/>
				</div>
				<LayerTylesComponent map={this.state.mapita} tyle={this.state.tyle} handleTyle={(value) => this.handleTyle(value)} handleCoord={(value1, value2) => this.handleCoord(value1, value2)} />
			</div>
		)
	}
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => (
	<MappDropPointZones
		{...props}
		params={useParams()}
	/>
); 