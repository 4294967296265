/* eslint-disable react/style-prop-object */
import React from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import { IconContext } from "react-icons";
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { BsLayersHalf } from "react-icons/bs";
import RecommendationNavBarComponent from "../../components/RecommendationNavBarComponent";
import { RiFullscreenFill, RiFullscreenExitFill } from "react-icons/ri";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';
import Select from 'react-select';
import Slider from 'rc-slider';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Chart from 'chart.js/auto';
import { Line } from 'react-chartjs-2';
import { useParams } from "react-router-dom";
import { VscGraphLine } from "react-icons/vsc";

import '../../components/layer-tyles.css';
import '../css/regional-demand.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const URL = process.env.REACT_APP_URL_SERVER_BACK;
const URL_FRONT = process.env.REACT_APP_URL_SERVER_FRONT;

const delay = ms => new Promise(res => setTimeout(res, ms));

const _ = require('lodash');
let current_mode = 'draw_polygon';

let draw = new MapboxDraw({
	displayControlsDefault: false,
	// Select which mapbox-gl-draw control buttons to add to the map.
	controls: {
		polygon: (current_mode === 'draw_polygon' ? true : false),
		trash: (current_mode ? true : false)
	},
	// Set mapbox-gl-draw to draw by default.
	// The user does not have to click the polygon control button first.
	defaultMode: current_mode
});

function numberWithCommas(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

let URL_lines = {
	'type': 'FeatureCollection',
	'features': [
		{
			'type': 'Feature',
			'geometry': {
				"type": "LineString",
				"coordinates": [
				]
			}
		}
	]
};

class HideElementsComponent extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			hideNavs: false,
			mapa: this.props.map,
		}
	}

	hideComponent() {
		let newHideNavs = !this.state.hideNavs;
		this.setState({ hideNavs: newHideNavs })
		this.props.updateField("hideNavs", newHideNavs);
	}

	render() {

		const { hideNavs } = this.state;

		return (
			<div>
				{hideNavs ? <>
					<div className='map-close-navs'>
						<div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
								<div>
									<RiFullscreenExitFill />
								</div>
							</IconContext.Provider>
						</div>
					</div>
				</> : <>
					<div className='map-close-navs'>
						<div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
								<div>
									<RiFullscreenFill />
								</div>
							</IconContext.Provider>
						</div>
					</div>
				</>}
			</div>
		)
	}
}

//Layer Tyles Selection - Side Menu
class LayerTylesComponent extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			showOptions: false,
			mapa: this.props.map,
		}
		this.hideComponent = this.hideComponent.bind(this);
		this.handleTyleEvent = this.handleTyleEvent.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (this.props.map !== prevProps.map) {
			this.setState({ mapa: this.props.map })
		}
	}

	hideComponent(name) {
		switch (name) {
			case "showOptions":
				this.setState({ showOptions: !this.state.showOptions });
				break;
			default:
				// eslint-disable-next-line no-unused-expressions
				null;
		}
	}

	handleTyleEvent(event) {
		switch (event) {
			case "default":
				this.props.handleTyle("mapbox://styles/mapbox/light-v10");
				break;
			case "outdoors":
				this.props.handleTyle("mapbox://styles/mapbox/outdoors-v11");
				break;
			case "satellite":
				this.props.handleTyle("mapbox://styles/mapbox/satellite-streets-v11");
				break;
			case "streets":
				this.props.handleTyle("mapbox://styles/mapbox/streets-v11");
				break;
			case "dark":
				this.props.handleTyle("mapbox://styles/mapbox/dark-v10");
				break;
			default:
				// eslint-disable-next-line no-unused-expressions
				null;
		}
		this.props.handleCoord(this.state.mapa.getCenter(), this.state.mapa.getZoom());
	}

	render() {
		const { showOptions } = this.state;

		return (
			<div>
				{showOptions ? <>
					<div id='layer-options' className='map-layer-options-tyles'>
						<div className='map-layer-option-dark' onClick={() => this.handleTyleEvent("dark")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/Dark.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Dark</p>
						</div>
						<div className='map-layer-option-streets' onClick={() => this.handleTyleEvent("streets")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/Streets.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Streets</p>
						</div>
						<div className='map-layer-option-satellite' onClick={() => this.handleTyleEvent("satellite")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/Satellite.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Satellite</p>
						</div>
						<div className='map-layer-option-outdoors' onClick={() => this.handleTyleEvent("outdoors")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/Outdoors.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Outdoors</p>
						</div>
						<div className='map-layer-option-default' onClick={() => this.handleTyleEvent("default")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/White.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Default</p>
						</div>
					</div>
				</> : <></>}
				<div className='map-layer-tyles'>
					<div className='map-layer-tyles-inner' onClick={() => this.hideComponent("showOptions")}>
						<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
							<div>
								<BsLayersHalf />
							</div>
						</IconContext.Provider>
					</div>
				</div>
			</div>
		)
	}
}

class BtnModal extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			mapa: this.props.map,
			origin_geojson: this.props.origin_geojson,
			current_stage: this.props.current_stage,
			isGeometrySelected: this.props.isGeometrySelected,
			dates_trips_: this.getOptionsDate(URL + 'db_od_trips'),
			routes_types_: this.getOptionsRouteTypes(),
			current_route_type: ({
				"value": 0,
				"label": "ALL"
			}),
			response_od_flow: '',
			coord: this.props.coord,
			loading: false,
			value_od_dates: ({
				"value": "2022-08",
				"label": '2022-08'
			}),
			value_filtering_scope: ({
				"value": "plz",
				"label": 'Postal Codes'
			}),
			data_filtering_scope_: [
				{ value: 'bfs', label: 'Municipalities' },
				{ value: 'plz', label: 'Postal Codes' },
				{ value: 'tiles', label: 'Tiles' }
			],
			respWvsW: {},
			respModalPKM: {},
			respModalTrips: {},
			respTraveledKm: {},
			respCO2: {},
			respTopOD: [],
			respTiles: [],
			respCategories: [],
			top_by: 'total',
			min_pkm: 10000,
			within: 'within',

			hideStationsOV: false,
			hideStationsBike: false,
			hideRoutes: false,
			hideHotspots: true,
			hideRoads: true,
			hidePostAuto: true,
			current_tile: '',
			od_origin: '',
			od_destination: '',
			hidePrincipalGeometry: false,
			showGraph: true
		}
		this.handleFetchDataCoordParam()
	}

	handleGraphComponent() {
		this.setState({ showGraph: !this.state.showGraph });
	}

	async handleFetchDataCoordParam() {
		if (this.state.coord['*'] !== "") {

			fetch(URL + 'regional_overview/return_predefined_polygons/' + this.state.coord['*'])
				.then((res) => res.json())
				.then(async (json) => {
					await delay(5000);
					this.setState({ loading: true });
					this.setState({ origin_geojson: json.features[0] })
					this.props.updateFields({ origin_geojson: json.features[0] })
					this.handleEvaluateRegion(this.state.value_od_dates, this.state.value_filtering_scope, this.state.top_by, this.state.within, json.features[0])
					draw.changeMode('draw_polygon', { controls: { polygon: false, trash: false } });
				})

		}
	}

	async getOptionsDate(url) {
		const res = await axios.get(url)
		const data = res.data
		const options = data.map(d => ({
			"value": d._id.date.toString().split('T')[0].slice(0, -3),
			"label": d._id.date.toString().split('T')[0].slice(0, -3)
		}))
		this.setState({ dates_trips_: options });
		//this.setState({ value_od_dates: options[options.length - 1] });
	}

	async getOptionsRouteTypes() {
		const res = await axios.get(URL + "regional_overview/routes_types")
		const data = res.data
		const options = data.map(d => ({
			"value": d.Code,
			"label": d.Description
		}))
		this.setState({ routes_types_: options });
	}

	async handleTopODContributors(date, origin, filtering_scope, top_by, within) {
		fetch(URL + 'regional_overview/graph_top_destinations', {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ filtering_scope: filtering_scope, geometry: origin, date: date, tob_by: top_by, within: within, n_top: 100 })
		}).then((res) => res.json())
			.then((json) => {
				this.setState({ respTopOD: json });
			}).then(() => {
				fetch(URL + 'regional_overview/hotspots', {
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						"pkm": this.state.min_pkm,
						"date": date,
						"geometry": this.state.origin_geojson.geometry,
						"filtering_scope": 'tiles',
						"within": within
					})
				}).then((res) => res.json())
					.then((json) => {
						this.setState({ respTiles: json.features })
					}).then(()=>{
						fetch(URL + 'regional_overview/categories', {
							method: 'POST',
							headers: {
								'Accept': 'application/json',
								'Content-Type': 'application/json'
							},
							body: JSON.stringify({ filtering_scope: filtering_scope, geometry: origin, date: date, tob_by: top_by, within: within, n_top: 100 })
						}).then((res) => res.json())
							.then((json) => {
								this.setState({ respCategories: json });
							})
					})
			})
	}

	componentDidUpdate(prevProps) {
		if (this.props.map !== prevProps.map) {
			this.setState({ mapa: this.props.map });
		}
		if (this.props.origin_geojson !== prevProps.origin_geojson) {
			this.setState({ origin_geojson: this.props.origin_geojson });
		}
		if (this.props.current_stage !== prevProps.current_stage) {
			this.setState({ current_stage: this.props.current_stage });
		}
		if (this.props.isGeometrySelected !== prevProps.isGeometrySelected) {
			this.setState({ isGeometrySelected: this.props.isGeometrySelected });
		}
		if (this.props.current_stage === 'final' && (this.props.origin_geojson !== prevProps.origin_geojson || this.props.filtering_scope !== prevProps.filtering_scope)) {
			this.handleFetchData('od_analyzer/graph_weekdays_vs_weekends', this.props.origin_geojson.geometry, this.props.destination_geojson.geometry, this.state.value_filtering_scope.value, this.state.within)
			this.handleFetchData('od_analyzer/graph_modal_split_pkm', this.props.origin_geojson.geometry, this.props.destination_geojson.geometry, this.state.value_filtering_scope.value, this.state.within)
			this.handleFetchData('od_analyzer/graph_modal_split_trips', this.props.origin_geojson.geometry, this.props.destination_geojson.geometry, this.state.value_filtering_scope.value, this.state.within)
			this.handleFetchData('od_analyzer/graph_traveled_km', this.props.origin_geojson.geometry, this.props.destination_geojson.geometry, this.state.value_filtering_scope.value, this.state.within)
			this.handleFetchData('od_analyzer/graph_co2_emissions', this.props.origin_geojson.geometry, this.props.destination_geojson.geometry, this.state.value_filtering_scope.value, this.state.within)
			this.handleTopODContributors(this.state.value_od_dates.value, this.props.origin_geojson.geometry, this.state.value_filtering_scope.value, this.state.top_by, this.state.within)
		}
		else if (this.props.origin_geojson !== prevProps.origin_geojson || this.props.filtering_scope !== prevProps.filtering_scope) {
			this.setState({ origin_geojson: this.props.origin_geojson, destination_geojson: this.props.destination_geojson, filtering_scope: this.props.filtering_scope });
		}
	}

	handleMonthYear(event) {
		this.setState({ value_od_dates: event });
	}

	handleRoutesType(event) {
		this.setState({ current_route_type: event });

		fetch(URL + 'regional_overview/pt_routes', {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				"geometry": this.state.origin_geojson.geometry,
				"date": this.state.value_od_dates.value,
				"filtering_scope": this.state.value_filtering_scope.value,
				"route_type": event.value
			})
		}).then((res) => res.json())
			.then((json) => {
				this.props.map.getSource('data-regional-routes').setData(json);
			})
	}

	handleFilteringScope(event) {
		this.setState({ value_filtering_scope: event });
		this.props.updateFields({ value_filtering_scope: event.value })

		if (this.state.current_stage !== 'final') {
			if (!(this.state.origin_geojson === [])) {
				let body = {
					"geometry": this.state.origin_geojson.geometry,
					"date": this.state.value_od_dates.value,
					"filtering_scope": event.value
				}

				fetch(URL + 'regional_overview/geometry', {
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(body)
				}).then((res) => res.json())
					.then((json) => {
						this.props.map.getSource('data-origin-polygon').setData(json);
					})
			}
		}
	}

	handleOriginSelection() {
		let body = {
			"geometry": this.state.origin_geojson.geometry,
			"date": this.state.value_od_dates.value,
			"filtering_scope": this.state.value_filtering_scope.value
		}

		fetch(URL + 'regional_overview/geometry', {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(body)
		}).then((res) => res.json())
			.then((json) => {
				this.props.map.getSource('data-origin-polygon').setData(json);
			}).then(() => {
				draw.deleteAll()
				this.props.updateFields({ current_stage: 'destination', isGeometrySelected: false })
				current_mode = 'draw_polygon'
				draw.changeMode('draw_polygon', { controls: { polygon: false, trash: false } });
			})
	}

	async handleFetchData(url, origin, filtering_scope, within) {
		fetch(URL + url, {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ filtering_scope: filtering_scope, geometry: origin, within: within })
		}).then((res) => res.json())
			.then((json) => {
				if (url.split('/')[1] === 'graph_weekdays_vs_weekends') {
					this.setState({ respWvsW: json })
				} else if (url.split('/')[1] === 'graph_modal_split_pkm') {
					this.setState({ respModalPKM: json })
				} else if (url.split('/')[1] === 'graph_modal_split_trips') {
					this.setState({ respModalTrips: json })
				} else if (url.split('/')[1] === 'graph_traveled_km') {
					this.setState({ respTraveledKm: json })
				} else if (url.split('/')[1] === 'graph_co2_emissions') {
					this.setState({ respCO2: json })
				}
			})
	}

	componentDidMount() {
		this.handleOriginSelection = this.handleOriginSelection.bind(this);
		this.handleDeleteOrigin = this.handleDeleteOrigin.bind(this);
		this.handleFilteringScope = this.handleFilteringScope.bind(this);
		this.handleFetchData = this.handleFetchData.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleDeleteOrigin() {
		this.props.map.getSource('data-origin-polygon').setData(URL_lines);
		draw.deleteAll()
		this.props.updateFields({ current_stage: 'origin', isGeometrySelected: false })
		current_mode = 'draw_polygon'
		draw.changeMode('draw_polygon', { controls: { polygon: true, trash: false } });
	}

	handleSubmit(event) {
		this.handleEvaluateRegion(this.state.value_od_dates, this.state.value_filtering_scope, this.state.top_by, this.state.within, this.state.origin_geojson)
	}

	handleEvaluateRegion(value_od_dates, value_filtering_scope, top_by, within_param, origin_geojson) {

		let body = {
			"geometry": origin_geojson.geometry,
			"date": value_od_dates.value,
			"filtering_scope": value_filtering_scope.value,
			"within": within_param
		}
		this.setState({ loading: true });

		fetch(URL + 'regional_overview/geometry', {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(body)
		}).then((res) => res.json())
			.then((json) => {
				this.props.map.getSource('data-origin-polygon').setData(URL_lines);
				this.props.map.getSource('data-geometries-polygon').setData(json);
			}).then(() => {
				draw.deleteAll()
				this.props.updateFields({ current_stage: 'final' })
				current_mode = 'default'
			}).then(() => {
				this.props.updateFields({ value_filtering_scope: value_filtering_scope.value })
				fetch(URL + 'regional_overview/od_flow', {
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(body)
				}).then((res) => res.json())
					.then((json) => {
						this.handleFetchData('regional_overview/graph_weekdays_vs_weekends', origin_geojson.geometry, value_filtering_scope.value, within_param)
						this.handleFetchData('regional_overview/graph_modal_split_pkm', origin_geojson.geometry, value_filtering_scope.value, within_param)
						this.handleFetchData('regional_overview/graph_modal_split_trips', origin_geojson.geometry, value_filtering_scope.value, within_param)
						this.handleFetchData('regional_overview/graph_traveled_km', origin_geojson.geometry, value_filtering_scope.value, within_param)
						this.handleFetchData('regional_overview/graph_co2_emissions', origin_geojson.geometry, value_filtering_scope.value, within_param)
						this.handleTopODContributors(value_od_dates.value, origin_geojson.geometry, value_filtering_scope.value, top_by, within_param)

						this.setState({ response_od_flow: json, current_stage: 'final' })
					}).then(() => {
						fetch(URL + 'regional_overview/publi_bike_stations', {
							method: 'POST',
							headers: {
								'Accept': 'application/json',
								'Content-Type': 'application/json'
							},
							body: JSON.stringify(body)
						}).then((res) => res.json())
							.then((json) => {
								this.props.map.getSource('data-circles-bikes-stations').setData(json);
							}).then(() => {
								fetch(URL + 'regional_overview/pt_stations', {
									method: 'POST',
									headers: {
										'Accept': 'application/json',
										'Content-Type': 'application/json'
									},
									body: JSON.stringify(body)
								}).then((res) => res.json())
									.then((json) => {
										this.props.map.getSource('data-circles-ov-stations').setData(json);
									})
							}).then(() => {
								fetch(URL + 'regional_overview/pt_routes', {
									method: 'POST',
									headers: {
										'Accept': 'application/json',
										'Content-Type': 'application/json'
									},
									body: JSON.stringify({
										"geometry": origin_geojson.geometry,
										"date": value_od_dates.value,
										"filtering_scope": value_filtering_scope.value,
										"route_type": this.state.current_route_type.value
									})
								}).then((res) => res.json())
									.then((json) => {
										this.props.map.getSource('data-regional-routes').setData(json);
									})
							}).then(() => {
								let body = {
									"pkm": this.state.min_pkm,
									"date": value_od_dates.value,
									"geometry": origin_geojson.geometry,
									"filtering_scope": 'tiles',
									"within": within_param
								}
								fetch(URL + 'regional_overview/hotspots', {
									method: 'POST',
									headers: {
										'Accept': 'application/json',
										'Content-Type': 'application/json'
									},
									body: JSON.stringify(body)
								}).then((res) => res.json())
									.then((json) => {
										this.setState({ respTiles: json.features })
										this.props.map.getSource('data-swiss-top-tyles').setData(json);
									}).then(() => {
										let body = {
											"date": value_od_dates.value,
											"geometry": origin_geojson.geometry,
											"filtering_scope": value_filtering_scope.value
										}

										fetch(URL + 'regional_overview/roads', {
											method: 'POST',
											headers: {
												'Accept': 'application/json',
												'Content-Type': 'application/json'
											},
											body: JSON.stringify(body)
										}).then((res) => res.json())
											.then((json) => {
												this.props.map.getSource('data-regional-roads').setData(json);
											}).then(() => {
												fetch(URL + 'regional_overview/routes_types', {
													method: 'POST',
													headers: {
														'Accept': 'application/json',
														'Content-Type': 'application/json'
													},
													body: JSON.stringify({
														"geometry": origin_geojson.geometry
													})
												}).then((res) => res.json())
													.then((json) => {
														const options = json.map(d => ({
															"value": d.Code,
															"label": d.Description
														}))

														this.setState({ routes_types_: options })
													}).then(() => {

														fetch(URL + 'regional_overview/post_auto_station_usage', {
															method: 'POST',
															headers: {
																'Accept': 'application/json',
																'Content-Type': 'application/json'
															},
															body: JSON.stringify({
																"geometry": origin_geojson.geometry
															})
														}).then((res) => res.json())
															.then((json) => {
																this.props.map.getSource('data-post-auto').setData(json);
															}).then(() => {
																fetch(URL + 'regional_overview/top_ods_geometries', {
																	method: 'POST',
																	headers: {
																		'Accept': 'application/json',
																		'Content-Type': 'application/json'
																	},
																	body: JSON.stringify({
																		filtering_scope: value_filtering_scope.value,
																		geometry: origin_geojson.geometry,
																		date: value_od_dates.value,
																		tob_by: top_by,
																		within: within_param,
																		n_top: 100
																	})
																}).then((res) => res.json())
																	.then((json) => {
																		this.props.map.getSource('data-regional-top-ods').setData(json);
																		this.setState({ loading: false })
																		console.log(this.state.origin_geojson)
																	})
															})

													})
											})

									})
							})
					})
			})
	}

	handleWithIn(event) {
		this.setState({ within: event.target.attributes.getNamedItem('id').value })
	}

	handleReset() {
		current_mode = 'draw_polygon'
		draw.changeMode('draw_polygon', { controls: { polygon: true, trash: false } });

		this.setState({
			response_od_flow: '', loading: false, value_od_dates: ({
				"value": "2022-08",
				"label": '2022-08'
			}),
			value_filtering_scope: ({
				"value": "plz",
				"label": 'Postal Codes'
			}),
			respWvsW: {},
			respModalPKM: {},
			respModalTrips: {},
			respTraveledKm: {},
			respCO2: {},
			top_by: 'total',
			hideStationsOV: false,
			hideStationsBike: false,
			hideRoutes: false,
			hideHotspots: true,
			hideRoads: true,
			hidePostAuto: true,
			min_pkm: 10000,
			within: 'within',
			respTopOD: [],
			respTiles: [],
			current_tile: '',
			od_origin: '',
			od_destination: '',
			hidePrincipalGeometry: false
		});

		this.props.updateFields({ origin_geojson: [], isGeometrySelected: false, current_stage: 'origin', value_filtering_scope: "plz" })

		draw.deleteAll();

		this.getOptionsRouteTypes();
		this.props.map.getSource('data-geometries-polygon').setData(URL_lines);
		this.props.map.getSource('data-swiss-top-tyles').setData(URL_lines);

		this.props.map.getSource('data-circles-ov-stations').setData(URL_lines);
		this.props.map.getSource('data-circles-bikes-stations').setData(URL_lines);
		this.props.map.getSource('data-origin-polygon').setData(URL_lines);
		this.props.map.getSource('data-regional-routes').setData(URL_lines);
		this.props.map.getSource('data-post-auto').setData(URL_lines)
		this.props.map.getSource('selected-regional-top-od').setData(URL_lines);

		current_mode = 'draw_polygon'
		draw.changeMode('draw_polygon', { controls: { polygon: true, trash: false } });
	}

	schemaType(event) {
		this.setState({ top_by: event.target.attributes.getNamedItem('id').value })
		this.handleTopODContributors(this.state.value_od_dates.value, this.props.origin_geojson.geometry, this.state.value_filtering_scope.value, event.target.attributes.getNamedItem('id').value, this.state.within)
	}

	totalPKmHandler(event) {
		this.setState({ min_pkm: event })

		let body = {
			"pkm": event,
			"date": this.state.value_od_dates.value,
			"geometry": this.state.origin_geojson.geometry,
			"filtering_scope": 'tiles',
			"within": this.state.within
		}

		fetch(URL + 'regional_overview/hotspots', {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(body)
		}).then((res) => res.json())
			.then((json) => {
				this.setState({ respTiles: json.features })
				this.props.map.getSource('data-swiss-top-tyles').setData(json);
			})
	}

	handleStationOV() {
		let temp = !this.state.hideStationsOV;
		this.setState({ hideStationsOV: temp })
		if (temp) {
			this.state.mapa.setLayoutProperty('data-circles-ov-stations', 'visibility', 'none');
		}
		else {
			this.state.mapa.setLayoutProperty('data-circles-ov-stations', 'visibility', 'visible');
		}
	}

	handlePrincipalGeometry() {
		let temp = !this.state.hidePrincipalGeometry;
		this.setState({ hidePrincipalGeometry: temp })
		if (temp) {
			this.state.mapa.setLayoutProperty('data-geometries-polygon', 'visibility', 'none');
			this.state.mapa.setLayoutProperty('outline-geometries-polygon', 'visibility', 'none');
		}
		else {
			this.state.mapa.setLayoutProperty('data-geometries-polygon', 'visibility', 'visible');
			this.state.mapa.setLayoutProperty('outline-geometries-polygon', 'visibility', 'visible');
		}
	}

	handleStationBike() {
		let temp = !this.state.hideStationsBike;
		this.setState({ hideStationsBike: temp })
		if (temp) {
			this.state.mapa.setLayoutProperty('data-circles-bikes-stations', 'visibility', 'none');
		}
		else {
			this.state.mapa.setLayoutProperty('data-circles-bikes-stations', 'visibility', 'visible');
		}
	}

	handleRoutes() {
		let temp = !this.state.hideRoutes;
		this.setState({ hideRoutes: temp })
		if (temp) {
			this.state.mapa.setLayoutProperty('data-regional-routes', 'visibility', 'none');
		}
		else {
			this.state.mapa.setLayoutProperty('data-regional-routes', 'visibility', 'visible');
		}
	}

	handleHotspots() {
		let temp = !this.state.hideHotspots;
		this.setState({ hideHotspots: temp })
		if (temp) {
			this.state.mapa.setLayoutProperty('outline-data-swiss-top-tyles', 'visibility', 'none');
			this.state.mapa.setLayoutProperty('data-swiss-top-tyles', 'visibility', 'none');
		}
		else {
			this.state.mapa.setLayoutProperty('outline-data-swiss-top-tyles', 'visibility', 'visible');
			this.state.mapa.setLayoutProperty('data-swiss-top-tyles', 'visibility', 'visible');
		}
	}

	handleRoads() {
		let temp = !this.state.hideRoads;
		this.setState({ hideRoads: temp })
		if (temp) {
			this.state.mapa.setLayoutProperty('data-regional-roads', 'visibility', 'none');
		}
		else {
			this.state.mapa.setLayoutProperty('data-regional-roads', 'visibility', 'visible');
		}
	}

	handlePostAuto() {
		let temp = !this.state.hidePostAuto;
		this.setState({ hidePostAuto: temp })
		if (temp) {
			this.state.mapa.setLayoutProperty('data-post-auto', 'visibility', 'none');
		}
		else {
			this.state.mapa.setLayoutProperty('data-post-auto', 'visibility', 'visible');
		}
	}

	highlightTiles(tileId) {
		if (this.state.current_tile !== tileId) {
			fetch(URL + 'areas_to_focus/tile/' + tileId)
				.then((res) => res.json())
				.then((json) => {
					this.setState({ current_tile: tileId })
					this.props.map.getSource('selected-hotspot').setData(json);
				})
		} else {
			this.setState({ current_tile: '' })
			this.props.map.getSource('selected-hotspot').setData(URL_lines);
		}
	}

	highlightRoute(origin, destination) {
		if (this.state.od_origin !== origin || this.state.od_destination !== destination) {
			fetch(URL + 'regional_overview/od_route/' + origin + ',' + destination)
				.then((res) => res.json())
				.then((json) => {
					this.setState({ od_origin: origin, od_destination: destination })
					this.props.map.getSource('selected-hotspot').setData(json);
				})
		} else {
			this.setState({ od_origin: '', od_destination: '' })
			this.props.map.getSource('selected-hotspot').setData(URL_lines);
		}
	}

	render() {

		const { isGeometrySelected } = this.state;
		const { value_filtering_scope } = this.state;
		const { value_od_dates } = this.state;
		const { loading } = this.state;

		const listTiles = this.state.respTiles?.map((tile, index) =>
			<tr key={index} onClick={() => this.highlightTiles(tile?.properties.tileId)}>
				<td className="table-data-regional-od">{tile?.properties.tileId}</td>
				<td className="table-data-regional-od">{tile?.properties.name}</td>
				<td className="table-data-regional-od">{tile?.properties.category}</td>
				<td className="table-data-regional-od">{tile?.properties.PLZ}</td>
				<td className="table-data-regional-od">{numberWithCommas(tile?.properties.total_pkm?.toFixed(0))}</td>
				<td className="table-data-regional-od">{tile?.properties.percentage?.toFixed(2)}%</td>
				<td className="table-data-regional-od">{tile?.properties.cum_percentage?.toFixed(2)}%</td>
			</tr>
		);

		const listCategories  = this.state.respCategories?.map((tile, index) =>
		<tr key={index}>
			<td className="table-data-regional-od">{tile?.category}</td>
			<td className="table-data-regional-od">{numberWithCommas(tile?.total_pkm.toFixed(0))}</td>
			<td className="table-data-regional-od">{tile?.percentage?.toFixed(2)}%</td>
		</tr>
	);

		const listODTrips = this.state.respTopOD?.origin?.map((trip, index) =>
			<tr key={index} onClick={() => this.highlightRoute(this.state.respTopOD?.origin[index], this.state.respTopOD?.destination[index])}>
				<td className="table-data-regional-od">{this.state.respTopOD?.origin_name[index]}</td>
				<td className="table-data-regional-od">{this.state.respTopOD?.destination_name[index]}</td>
				<td className="table-data-regional-od">{numberWithCommas(this.state.respTopOD?.pkm_cars[index]?.toFixed(0))}</td>
				<td className="table-data-regional-od">{numberWithCommas(this.state.respTopOD?.pkm_trains[index]?.toFixed(0))}</td>
				<td className="table-data-regional-od">{numberWithCommas(this.state.respTopOD?.pkm_total[index]?.toFixed(0))}</td>
				<td className="table-data-regional-od">{numberWithCommas(this.state.respTopOD?.modal_split[index]?.toFixed(0))}%</td>
				<td className="table-data-regional-od">{this.state.respTopOD?.percentage[index]?.toFixed(2)}%</td>
				<td className="table-data-regional-od">{this.state.respTopOD?.cum_percentage[index]?.toFixed(2)}%</td>
			</tr>
		);

		const selectDate = {
			menuList: styles => {
				return {
					...styles,
					maxHeight: 150,
					borderWidth: 0,
					boxShadow: '0 0 10px 2px rgb(0 0 0 / 10%)',
				};
			}
		};

		const { respWvsW } = this.state;
		const { respModalPKM } = this.state;
		const { respModalTrips } = this.state;
		const { respTraveledKm } = this.state;
		const { respCO2 } = this.state;
		const { current_stage } = this.state;
		const { current_route_type } = this.state;
		const { within } = this.state;
		const { showGraph } = this.state;

		const dataBar_WvsW = {
			labels: respWvsW['date'],
			datasets: [{
				label: 'Weekdays',
				data: respWvsW['total_trips_weekdays'],
				backgroundColor: [
					'rgba(2, 128, 6,0.2)'
				],
				borderColor: [
					'rgb(2, 128, 6)'

				],
				borderWidth: 1
			},
			{
				label: 'Weekends',
				data: respWvsW['total_trips_weekend'],
				backgroundColor: [
					'rgba(128, 2, 63, 0.2)'
				],
				borderColor: [
					'rgb(128, 2, 63)'
				],
				borderWidth: 1
			},
			]
		};

		const dataBar_ModalPKM = {
			labels: respModalPKM['date'],
			datasets: [{
				label: 'Modal Split for Trains',
				data: respModalPKM['modal_split'],
				backgroundColor: [
					'rgba(17, 148, 209, 0.2)'
				],
				borderColor: [
					'rgb(17, 148, 209)'
				],
				borderWidth: 1
			},
			]
		};

		const dataBar_ModalTrips = {
			labels: respModalTrips['date'],
			datasets: [{
				label: 'Modal Split for Trains',
				data: respModalTrips['modal_split_trains'],
				backgroundColor: [
					'rgba(221, 138, 230, 0.2)'
				],
				borderColor: [
					'rgb(221, 138, 230)'
				],
				borderWidth: 1
			},
			]
		}

		const dataBar_TraveledKm = {
			labels: respTraveledKm['date'],
			datasets: [{
				label: 'Traveled Km by Trains',
				data: respTraveledKm['traveled_km_trains'],
				backgroundColor: [
					'rgba(247, 137, 72, 0.2)'
				],
				borderColor: [
					'rgb(247, 137, 72)'
				],
				borderWidth: 1
			},
			{
				label: 'Traveled Km by Cars',
				data: respTraveledKm['traveled_km_cars'],
				backgroundColor: [
					'rgba(2,127,128,0.2)'
				],
				borderColor: [
					'rgb(2,127,128)'
				],
				borderWidth: 1
			},
			]
		};

		const options = {
			xAxes: [{
				type: 'time',
				ticks: {
					autoSkip: true,
					maxTicksLimit: 10
				}
			}],
			plugins: {
				title: {
					display: true,
					text: 'Percentage of Trains\' Modal Split (PKm) per Month'
				},
				legend: {
					position: 'bottom',
				},
			},
		};

		const options_trips = {
			xAxes: [{
				type: 'time',
				ticks: {
					autoSkip: true,
					maxTicksLimit: 10
				}
			}],
			plugins: {
				title: {
					display: true,
					text: 'Percentage of Trains\' Modal Split (Trips) per Month'
				},
				legend: {
					position: 'bottom',
				},
			},
		};

		const kmPerDay = {
			xAxes: [{
				type: 'time',
				ticks: {
					autoSkip: true,
					maxTicksLimit: 10
				}
			}],
			plugins: {
				legend: {
					position: 'bottom',
				},
				title: {
					display: true,
					text: 'Km per Month'
				}
			},
		};

		const numTripsPerDay = {
			xAxes: [{
				type: 'time',
				ticks: {
					autoSkip: true,
					maxTicksLimit: 10
				}
			}],
			plugins: {
				legend: {
					position: 'bottom',
				},
				title: {
					display: true,
					text: 'Number of Trips per Month'
				}
			},
		};

		const dataBar_CO2 = {
			labels: respCO2['date'],
			datasets: [{
				label: 'Tons of CO2 Emitted by Cars',
				data: respCO2['tons_of_co2_car_pkm'],
				backgroundColor: [
					'rgba(227,42,141,0.2)'
				],
				borderColor: [
					'rgb(227,42,141)'
				],
				borderWidth: 1
			},
			{
				label: 'Tons of CO2 Emitted by Trains',
				data: respCO2['tons_of_co2_train_pkm'],
				backgroundColor: [
					'rgba(31, 43, 177,0.2)'
				],
				borderColor: [
					'rgb(31, 43, 177)'
				],
				borderWidth: 1
			},
			]
		};

		const CO2_options = {
			xAxes: [{
				type: 'time',
				ticks: {
					autoSkip: true,
					maxTicksLimit: 10
				}
			}],
			plugins: {
				legend: {
					position: 'bottom',
				},
				title: {
					display: true,
					text: 'Tons of CO2 Emitted per Month'
				}
			},
		};

		if (loading) { // if your component doesn't have to wait for an async action, remove this block 
			return (
				<div className="bg-loader">
					<div className="pos-center-brain-loader">
						<div className="circle-loading-brain">
						</div>
						<svg className='brain-loader' x="0px" y="0px" viewBox="0 0 496 496">
							<path d="M40,449.472V364.28L58.424,352H88v-16H53.576L24,355.72v93.752C14.704,452.784,8,461.584,8,472c0,13.232,10.768,24,24,24				s24-10.768,24-24C56,461.584,49.288,452.784,40,449.472z M32,480c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8s8,3.584,8,8				C40,476.416,36.416,480,32,480z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M46.528,320H88v-16H46.528c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24				C34.416,336,43.216,329.288,46.528,320z M16,312c0-4.416,3.584-8,8-8s8,3.584,8,8c0,4.416-3.584,8-8,8S16,316.416,16,312z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M24,272c10.416,0,19.216-6.712,22.528-16H88v-16H46.528c-3.312-9.296-12.112-16-22.528-16c-13.232,0-24,10.768-24,24				S10.768,272,24,272z M24,240c4.416,0,8,3.584,8,8s-3.584,8-8,8s-8-3.584-8-8C16,243.584,19.584,240,24,240z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M56 272h32v16H56Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M56 208h32v16H56Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M0 176h88v16H0Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M60.688,160H88v-16H67.312L48,124.688v-14.16C57.288,107.216,64,98.416,64,88c0-13.232-10.768-24-24-24S16,74.768,16,88				c0,10.416,6.704,19.216,16,22.528v20.784L60.688,160z M40,80c4.416,0,8,3.584,8,8s-3.584,8-8,8s-8-3.584-8-8S35.584,80,40,80z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M144 67.312L144 88L160 88L160 60.688L131.312 32L0 32L0 48L124.688 48z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M176 35.312L176 88L192 88L192 28.688L163.312 0L0 0L0 16L156.688 16z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M208 56h16v32H208Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M304 0h16v88H304Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M240,46.528V88h16V59.312l16,16V88h16V68.688L262.392,43.08C268.192,38.696,272,31.816,272,24c0-13.232-10.768-24-24-24				s-24,10.768-24,24C224,34.416,230.704,43.216,240,46.528z M248,16c4.416,0,8,3.584,8,8s-3.584,8-8,8s-8-3.584-8-8				S243.584,16,248,16z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M352,51.312L371.312,32h78.16c2.416,6.776,7.752,12.112,14.528,14.528v110.16L444.688,176H408v16h43.312L480,163.312				V46.528c9.288-3.312,16-12.112,16-22.528c0-13.232-10.768-24-24-24c-10.416,0-19.216,6.712-22.528,16h-84.784L336,44.688V88h16				V51.312z M472,16c4.416,0,8,3.584,8,8s-3.584,8-8,8c-4.416,0-8-3.584-8-8S467.584,16,472,16z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M414.08,97.392l17.92,17.92v9.376L412.688,144H408v16h11.312L448,131.312v-22.624L419.312,80h-4.784				c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24C401.904,112,410.416,105.968,414.08,97.392z				 M392,96c-4.416,0-8-3.584-8-8s3.584-8,8-8c4.416,0,8,3.584,8,8S396.416,96,392,96z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M408 208h88v16H408Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M480,449.472V268.688L451.312,240H408v16h36.688L464,275.312v41.376L451.312,304H408v16h36.688L464,339.312v110.16				c-9.296,3.312-16,12.112-16,22.528c0,13.232,10.768,24,24,24s24-10.768,24-24C496,461.584,489.288,452.784,480,449.472z M472,480				c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8c4.416,0,8,3.584,8,8C480,476.416,476.416,480,472,480z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M408 272h32v16H408Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M448,364.688L419.312,336H408v16h4.688L432,371.312V416h-80v-8h-16v24h96v12.688L412.688,464h-14.16				c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24c10.416,0,19.216-6.712,22.528-16h20.784				L448,451.312V364.688z M376,480c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8c4.416,0,8,3.584,8,8C384,476.416,380.416,480,376,480z				" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M160,408h-16v8h-41.472c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24				c10.416,0,19.216-6.712,22.528-16H160V408z M80,432c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8s8,3.584,8,8				C88,428.416,84.416,432,80,432z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M304 408h16v88H304Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M272 408h16v88H272Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M240,420.688l-16,16V408h-16v41.472c-9.296,3.312-16,12.112-16,22.528c0,13.232,10.768,24,24,24s24-10.768,24-24				c0-7.816-3.808-14.696-9.608-19.08L256,427.312V408h-16V420.688z M216,480c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8s8,3.584,8,8				C224,476.416,220.416,480,216,480z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M192 408L176 408L176 428.688L152 452.688L152 496L168 496L168 459.312L192 435.312z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M336,392h16v-16h24v-24h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16				v-16h-16v-24h-24v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-3.312				L120,140.688V144h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v24h24v16h16v-16h16				v16h16v-16h16v16h16v-16h16v16h16v-16h16v16h16v-16h16v16h16v-16h16V392z M136,360V147.312L147.312,136H360v224H136z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M318.888,211.048l13.96-13.96l-33.936-33.936l-13.96,13.96c-4.16-2.184-8.488-3.976-12.952-5.384V152h-48v19.728				c-4.472,1.408-8.8,3.2-12.952,5.384l-13.96-13.96l-33.936,33.936l13.96,13.96c-2.176,4.16-3.976,8.488-5.384,12.952H152v48				h19.728c1.408,4.472,3.2,8.8,5.384,12.952l-13.96,13.96l33.936,33.936l13.96-13.96c4.16,2.176,8.48,3.976,12.952,5.384V344h48				v-19.728c4.472-1.408,8.8-3.2,12.952-5.384l13.96,13.96l33.936-33.936l-13.96-13.96c2.176-4.152,3.976-8.48,5.384-12.952H344v-48				h-19.728C322.864,219.528,321.072,215.2,318.888,211.048z M328,256h-16.224l-1.416,6.224c-1.6,7.016-4.368,13.672-8.216,19.792				l-3.408,5.408l11.496,11.496l-11.312,11.312l-11.496-11.496l-5.408,3.408c-6.12,3.848-12.776,6.616-19.792,8.216L256,311.776V328				h-16v-16.224l-6.224-1.416c-7.016-1.6-13.672-4.368-19.792-8.216l-5.408-3.408l-11.496,11.496l-11.312-11.312l11.496-11.496				l-3.408-5.408c-3.848-6.12-6.616-12.776-8.216-19.792L184.224,256H168v-16h16.224l1.416-6.224				c1.6-7.016,4.368-13.672,8.216-19.792l3.408-5.408l-11.496-11.496l11.312-11.312l11.496,11.488l5.408-3.408				c6.12-3.848,12.776-6.616,19.792-8.216l6.224-1.408V168h16v16.224l6.224,1.416c7.016,1.6,13.672,4.368,19.792,8.216l5.408,3.408				l11.496-11.488l11.312,11.312l-11.496,11.496l3.408,5.408c3.848,6.12,6.616,12.776,8.216,19.792l1.416,6.216H328V256z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M248,208c-22.056,0-40,17.944-40,40c0,22.056,17.944,40,40,40c22.056,0,40-17.944,40-40C288,225.944,270.056,208,248,208				z M248,272c-13.232,0-24-10.768-24-24s10.768-24,24-24s24,10.768,24,24S261.232,272,248,272z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
						</svg>
					</div>
				</div>
			)
		}
		else {
			return (
				<div>
					{showGraph && current_stage === 'final' ?
						<>
							<div className="modal-regional-demand-graph">
								<div className="modal-regional-demand-graph-inner">
									<Tabs
										defaultActiveKey="modalsplittrips"
										id="uncontrolled-tab-example"
										className="mb-3"
									>
										<Tab eventKey="traveledkm" title="PKm">
											<Line data={dataBar_TraveledKm} options={kmPerDay} />
										</Tab>
										<Tab eventKey="modalsplitpkm" title="Modal Split (PKm)">
											<Line data={dataBar_ModalPKM} options={options} />
										</Tab>
										<Tab eventKey="modalsplittrips" title="Modal Split (Trips)">
											<Line data={dataBar_ModalTrips} options={options_trips} />
										</Tab>
										<Tab eventKey="weekdayvsweekends" title="Num. Trips">
											<Line data={dataBar_WvsW} options={numTripsPerDay} />
										</Tab>
										<Tab eventKey="co2" title="CO2">
											<Line data={dataBar_CO2} options={CO2_options} />
										</Tab>
										<Tab eventKey="topOD" title="Top ODs">
											<div className="topOD-scroll">
												<div className="row">
													<Form onChange={this.schemaType.bind(this)}>
														<div className="column-33">
															<Form.Check inline label=" Sort by Cars Pkm" name="type" type='radio' id='cars' />
														</div>
														<div className="column-33">
															<Form.Check inline label=" Sort by Trains PKm" name="type" type='radio' id='trains' />
														</div>
														<div className="column-33">
															<Form.Check inline label=" Sort by Total PKm" name="type" type='radio' id='total' defaultChecked={true} />
														</div>
													</Form>
												</div>
												<div className="row">
													<table className="top-od-regional">
														<thead>
															<tr key='headers'>
																<th className="table-title-regional-od">Origin</th>
																<th className="table-title-regional-od">Destination</th>
																<th className="table-title-regional-od">PKm Cars</th>
																<th className="table-title-regional-od">PKm Trains</th>
																<th className="table-title-regional-od">PKm Total</th>
																<th className="table-title-regional-od">ModalSplit</th>
																<th className="table-title-regional-od">Individual(%)</th>
																<th className="table-title-regional-od">Cumulative(%)</th>
															</tr>
														</thead>
														<tbody>
															{listODTrips}
														</tbody>
													</table>
												</div>
											</div>
										</Tab>
										<Tab eventKey="toptiles" title="Hotspots">
											<div className="topOD-scroll">
												<div className="row">
													<table className="top-od-regional">
														<thead>
															<tr key='headers'>
																<th className="table-title-regional-od">Identifier</th>
																<th className="table-title-regional-od">PLZ Name</th>
																<th className="table-title-regional-od">Category</th>
																<th className="table-title-regional-od">PLZ Number</th>
																<th className="table-title-regional-od">Total PKm</th>
																<th className="table-title-regional-od">Individual (%)</th>
																<th className="table-title-regional-od">Cumulative (%)</th>
															</tr>
														</thead>
														<tbody>
															{listTiles}
														</tbody>
													</table>
												</div>
											</div>
										</Tab>
										<Tab eventKey="categories" title="Categories">
											<div className="topOD-scroll">
												<div className="row">
													<table className="top-od-regional-categories">
														<thead>
															<tr key='headers'>
																<th className="table-title-regional-od">Category Name</th>
																<th className="table-title-regional-od">Total PKm per Month</th>
																<th className="table-title-regional-od">Individual Contribution(%)</th>
															</tr>
														</thead>
														<tbody>
															{listCategories}
														</tbody>
													</table>
												</div>
											</div>
										</Tab>
									</Tabs>
								</div>
							</div>
						</>
						:
						<></>
					}
					{this.state.response_od_flow !== '' ?
						<>
							<div className="modal-regional-analysis-filters">
								<div className="modal-regional-analysis-filters-inner">
									<p className="label"><b>Filter by route type</b></p>
									<Select className='dates' options={this.state.routes_types_} onChange={this.handleRoutesType.bind(this)} styles={selectDate} defaultValue={current_route_type} />
									<p className="label"><b>Filter based on PKm</b></p>
									<div className="slider-distance">
										<Slider min={0} max={1500000} step={100} defaultValue={this.state.min_pkm} onChange={_.debounce(this.totalPKmHandler.bind(this), 33)} />
									</div>
									<p className='label-2'>Showing tiles with minimum {numberWithCommas(Number(this.state.min_pkm))} PKm per month</p>
									<p className="label"><b>Show/Hide Layers</b></p>

									<Form onChange={this.handlePrincipalGeometry.bind(this)}>
										<Form.Check
											value={!this.state.hidePrincipalGeometry}
											type="switch"
											id="custom-switch"
											label="Principal Geometry"
											defaultChecked
										/>
									</Form>

									<Form onChange={this.handleStationBike.bind(this)}>
										<Form.Check
											value={!this.state.hideStationsBike}
											type="switch"
											id="custom-switch"
											label="PubliBike Stations"
											defaultChecked
										/>
									</Form>

									<Form onChange={this.handleStationOV.bind(this)}>
										<Form.Check
											value={!this.state.hideStationsOV}
											type="switch"
											id="custom-switch"
											label="ÖV (Public Transport) Stations"
											defaultChecked
										/>
									</Form>

									<Form onChange={this.handleRoutes.bind(this)}>
										<Form.Check
											value={!this.state.hideRoutes}
											type="switch"
											id="custom-switch"
											label="ÖV (Public Transport) Routes"
											defaultChecked
										/>
									</Form>

									<Form onChange={this.handleHotspots.bind(this)}>
										<Form.Check
											value={!this.state.hideHotspots}
											type="switch"
											id="custom-switch"
											label="Tile Hotspots (1Km x 1Km)"
										/>
									</Form>

									<Form onChange={this.handleRoads.bind(this)}>
										<Form.Check
											value={!this.state.hideRoads}
											type="switch"
											id="custom-switch"
											label="Roads Traffic"
										/>
									</Form>

									<Form onChange={this.handlePostAuto.bind(this)}>
										<Form.Check
											value={!this.state.hidePostAuto}
											type="switch"
											id="custom-switch"
											label="PostAuto Stations"
										/>
									</Form>
								</div>
							</div>

							<div className='map-graph-button-regional-analysis'>
					<div className='map-graph-button-regional-analysis-inner' onClick={() => this.handleGraphComponent("showGraph")}>
						<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
							<div>
								<VscGraphLine />
							</div>
						</IconContext.Provider>
					</div>
				</div>

							<div className="modal-regional-demand-result">
								<div className="modal-regional-demand-result-inner">
									<h1>Regional Analysis per month</h1>
									<div className="row-parameters">
										<div className="column-60-per">
											<p className="label"><b>Trains Trips weekdays</b></p>
										</div>
										<div className="column-40-per">
											<p className="label"><b>{numberWithCommas(this.state.response_od_flow.train_trips_weekdays.toFixed(0))}</b></p>
										</div>
									</div>
									<div className="row-parameters">
										<div className="column-60-per">
											<p className="label"><b>Cars Trips weekdays</b></p>
										</div>
										<div className="column-40-per">
											<p className="label"><b>{numberWithCommas(this.state.response_od_flow.car_trips_weekdays.toFixed(0))}</b></p>
										</div>
									</div>
									<div className="row-parameters">
										<div className="column-60-per">
											<p className="label"><b>Total Trips weekdays</b></p>
										</div>
										<div className="column-40-per">
											<p className="label"><b>{numberWithCommas(this.state.response_od_flow.total_trips_weekdays.toFixed(0))}</b></p>
										</div>
									</div>
									<div className="row-parameters">
										<div className="column-60-per">
											<p className="label"><b>Trains Trips weekend</b></p>
										</div>
										<div className="column-40-per">
											<p className="label"><b>{numberWithCommas(this.state.response_od_flow.train_trips_weekend.toFixed(0))}</b></p>
										</div>
									</div>
									<div className="row-parameters">
										<div className="column-60-per">
											<p className="label"><b>Cars Trips weekend</b></p>
										</div>
										<div className="column-40-per">
											<p className="label"><b>{numberWithCommas(this.state.response_od_flow.car_trips_weekend.toFixed(0))}</b></p>
										</div>
									</div>
									<div className="row-parameters">
										<div className="column-60-per">
											<p className="label"><b>Total Trips weekend</b></p>
										</div>
										<div className="column-40-per">
											<p className="label"><b>{numberWithCommas(this.state.response_od_flow.total_trips_weekend.toFixed(0))}</b></p>
										</div>
									</div>
									<div className="row-parameters">
										<div className="column-60-per">
											<p className="label"><b>Total PKm</b></p>
										</div>
										<div className="column-40-per">
											<p className="label"><b>{numberWithCommas(this.state.response_od_flow.total_pkm_weekdays.toFixed(0))}</b></p>
										</div>
									</div>
								</div>
							</div>
						</>
						:
						<></>
					}
					<div className="modal-regional-analysis-type">
						<div className="modal-regional-analysis-type-inner">
							<h1>Regional Analysis</h1>
							<p className="label"><b>Select division scope</b></p>
							<Select className='filtering_scope' options={this.state.data_filtering_scope_} onChange={this.handleFilteringScope.bind(this)} styles={selectDate} defaultValue={value_filtering_scope} />
							<p className="label"><b>Select a month/year</b></p>
							<Select className='dates' options={this.state.dates_trips_} onChange={this.handleMonthYear.bind(this)} styles={selectDate} defaultValue={value_od_dates} />
							<p className="label"><b>Trips considered</b></p>
							<Form onChange={this.handleWithIn.bind(this)}>
								<div className="column-40">
									<Form.Check inline label="Within region" name="type_analysis" type='radio' id='within' defaultChecked={(within === 'within')} />
								</div>
								<div className="column-40">
									<Form.Check inline label="Outside region" name="type_analysis" type='radio' id='outside' defaultChecked={(within === 'outside')} />
								</div>
								<div className="column-20">
									<Form.Check inline label="Both" name="type_analysis" type='radio' id='both' defaultChecked={(within === 'both')} />
								</div>
							</Form>
						</div>
					</div>
					{this.state.current_stage === 'origin' ?
						<div className="modal-regional-analysis-step">
							<div className="modal-regional-analysis-step-inner">
								<p className="label">On the map, draw a polygon that will represent the <b>region you would like to evaluate</b>. Double-click when you draw the final vertex to complete the polygon.</p>
								<div className="row-parameters">
									{isGeometrySelected ?
										<>
											<div className="column-1-50">
												<Form onClick={this.handleDeleteOrigin.bind(this)}>
													<Button className="reset-btn" variant="info">Delete geometry</Button>
												</Form>
											</div>
											<div className="column-2-50">
												<Form onClick={this.handleSubmit.bind(this)}>
													<Button className="generate-btn" variant="info">Evaluate region</Button>
												</Form>
											</div>
										</>
										:
										<>
											<Form onClick={this.handleSubmit.bind(this)}>
												<Button className="generate-btn" variant="info" disabled={(!isGeometrySelected)}>Evaluate region</Button>
											</Form>
										</>
									}
								</div>
							</div>
						</div>
						:
						<>
							<div className="modal-regional-analysis-step">
								<div className="modal-regional-analysis-step-inner">
									<p className="label">On the map, draw a polygon that will represent the <b>region you would like to evaluate</b>. Double-click when you draw the final vertex to complete the polygon.</p>
									<div className="row-parameters">
										{this.state.response_od_flow !== '' ?
											<>
												<div className="column-1-50">
													<Form onClick={this.handleReset.bind(this)}>
														<Button className="reset-btn" variant="info">Restart the view</Button>
													</Form>
												</div>
												<div className="column-2-50">
													<Form onClick={this.handleSubmit.bind(this)}>
														<Button className="generate-btn" variant="info">Evaluate region</Button>
													</Form>
												</div>
											</>
											:
											<>
												<Form onClick={this.handleSubmit.bind(this)}>
													<Button className="generate-btn" variant="info">Evaluate region</Button>
												</Form>
											</>
										}
									</div>
								</div>
							</div>
						</>
					}
				</div>
			)
		}
	}
}

//Map - MappRegionalAnalysis
class MappRegionalAnalysis extends React.Component {

	// Set up states for updating map 
	constructor(props) {
		super(props);
		this.state = {
			lng: 8.536524,
			lat: 46.844411,
			zoom: 7,
			mapita: 9,
			tyle: 'mapbox://styles/mapbox/light-v10',
			hideNavs: false,
			current_stage: 'origin',
			origin_geojson: [],
			isGeometrySelected: false,
			value_filtering_scope: 'plz',
			coord: this.props.params,
		}
	}

	//Update several fields
	updateFields(list) {
		this.setState(list);
	}

	//Update map on component update
	updateField(card, value) {
		this.setState({
			...this.state,
			[card]: value
		});
	}

	//Update coordinates
	handleCoord(center, zoom) {
		const { lng, lat } = center;
		this.setState({ zoom: zoom, lat: lat, lng: lng })
	}

	//Update style
	handleTyle(value) {
		this.setState({ tyle: value }, this.componentDidMount);
	}

	componentDidMount() {
		// Set up map 
		const map = new mapboxgl.Map({
			container: this.mapContainer,
			style: this.state.tyle,
			center: [this.state.lng, this.state.lat],
			zoom: this.state.zoom,
		});

		map.on('load', () => {

			//Origin Polygon
			map.addSource('data-origin-polygon', {
				'type': 'geojson',
				'data': URL_lines,
				'generateId': true

			});

			map.addLayer({
				'id': 'data-origin-polygon',
				'type': 'fill',
				'source': 'data-origin-polygon',
				'layout': {
				},
				'paint': {
					'fill-color': '#008cff',
					'fill-opacity': 0.3
				}
			});

			map.addLayer({
				'id': 'outline-origin-polygon',
				'type': 'line',
				'source': 'data-origin-polygon',
				'layout': {},
				'paint': {
					'line-color': '#454545',
					'line-width': 0.8
				}
			});

			//Geometries Polygon
			map.addSource('data-geometries-polygon', {
				'type': 'geojson',
				'data': URL_lines,
				'generateId': true

			});

			map.addLayer({
				'id': 'data-geometries-polygon',
				'type': 'fill',
				'source': 'data-geometries-polygon',
				'layout': {
				},
				'paint': {
					'fill-color': '#4cadfc',
					'fill-opacity': 0.1
				}
			});

			map.addLayer({
				'id': 'outline-geometries-polygon',
				'type': 'line',
				'source': 'data-geometries-polygon',
				'layout': {},
				'paint': {
					'line-color': '#008cff',
					'line-width': 1
				}
			});

			// Routes
			map.addSource('data-regional-routes', {
				'type': 'geojson',
				'data': URL_lines,
				'generateId': true
			});

			map.addLayer({
				'id': 'data-regional-routes',
				'type': 'line',
				'source': 'data-regional-routes',
				'paint': {
					'line-color': [
						'case',
						['boolean', ['feature-state', 'clicked'], false],
						'#eb34b1', // else paint
						[
							'case',
							['<=', ['number', ['get', 'route_type']], 500],
							'#bf6702',
							'#026101'
						] // if selected true, paint in pink
					],
					'line-width': [
						"interpolate", ["linear"], ["zoom"],
						// zoom is 5 (or less) -> circle radius will be 1px
						9.2, 1,
						// zoom is 10 (or greater) -> circle radius will be 5px
						9.62, 3
					]
				}
			});

			//PostAuto stations (temporal)
			map.addSource('data-post-auto', {
				'type': 'geojson',
				'data': URL_lines,
				'generateId': true

			});

			map.addLayer({
				'id': 'data-post-auto',
				'type': 'circle',
				'source': 'data-post-auto',
				'layout': {
					'visibility': 'none'
				},
				'paint': {
					'circle-radius': [
						"interpolate", ["linear"], ["zoom"],
						// zoom is 5 (or less) -> circle radius will be 1px
						9.92, 3,
						// zoom is 10 (or greater) -> circle radius will be 5px
						10.3, 5
					],
					"circle-stroke-width": 1,
					"circle-stroke-color": '#fc7b03',
					'circle-color': '#fc7b03',
					"circle-opacity": 0.7,
				},
			});

			//ÖV stations (bus and trains)
			map.addSource('data-circles-ov-stations', {
				'type': 'geojson',
				'data': URL_lines,
				'generateId': true

			});

			map.addLayer({
				'id': 'data-circles-ov-stations',
				'type': 'circle',
				'source': 'data-circles-ov-stations',
				'layout': {
				},
				'paint': {
					'circle-radius': [
						"interpolate", ["linear"], ["zoom"],
						// zoom is 5 (or less) -> circle radius will be 1px
						9.92, 3,
						// zoom is 10 (or greater) -> circle radius will be 5px
						10.3, 5
					],
					"circle-stroke-width": 1,
					"circle-stroke-color": [
						'case',
						['==', ['string', ['get', 'Verkehrsmitteltext']], 'Zug'],
						'#038001',
						['==', ['string', ['get', 'Verkehrsmitteltext']], 'Bus'],
						'#fc7b03',
						'#010e80'
					],
					'circle-color': [
						'case',
						['==', ['string', ['get', 'Verkehrsmitteltext']], 'Zug'],
						'#038001',
						['==', ['string', ['get', 'Verkehrsmitteltext']], 'Bus'],
						'#fc7b03',
						'#010e80'
					],
					"circle-opacity": 0.7,
				},
			});

			//Publi Bike stations
			map.addSource('data-circles-bikes-stations', {
				'type': 'geojson',
				'data': URL_lines,
				'generateId': true

			});
			map.addLayer({
				'id': 'data-circles-bikes-stations',
				'type': 'circle',
				'source': 'data-circles-bikes-stations',
				'layout': {
				},
				'paint': {
					'circle-radius': [
						"interpolate", ["linear"], ["zoom"],
						// zoom is 5 (or less) -> circle radius will be 1px
						9.92, 3,
						// zoom is 10 (or greater) -> circle radius will be 5px
						10.3, 5
					],
					"circle-stroke-width": 1,
					'circle-color': '#b300ff',
					"circle-stroke-color": '#b300ff',
					"circle-opacity": 0.7,
				},
			});

			//Add shapes to the map (Top tiles - 1kmx1km)
			map.addSource('data-swiss-top-tyles', {
				'type': 'geojson',
				'data': URL_lines,
				'generateId': true
			});

			map.addLayer({
				'id': 'data-swiss-top-tyles',
				'type': 'fill',
				'source': 'data-swiss-top-tyles',
				'layout': {
					'visibility': 'none'
				},
				'paint': {
					'fill-color': '#2a3deb',
					'fill-opacity': 0.15
				}
			});

			map.addLayer({
				'id': 'outline-data-swiss-top-tyles',
				'type': 'line',
				'source': 'data-swiss-top-tyles',
				'layout': {
					'visibility': 'none'
				},
				'paint': {
					'line-color': '#2a3deb',
					'line-width': 1
				}
			});

			// Roads
			map.addSource('data-regional-roads', {
				'type': 'geojson',
				'data': URL_lines,
				'generateId': true
			});

			map.addLayer({
				'id': 'data-regional-roads',
				'type': 'line',
				'source': 'data-regional-roads',
				'layout': {
					'visibility': 'none'
				},
				'paint': {
					'line-color': '#a60f02',
					'line-width': ['get', 'stroke-width']
				}
			});

			// TopOD
			map.addSource('data-regional-top-ods', {
				'type': 'geojson',
				'data': URL_lines,
				'generateId': true
			});

			map.addLayer({
				'id': 'data-regional-top-ods',
				'type': 'line',
				'source': 'data-regional-top-ods',
				'layout': {
					'visibility': 'none'
				},
				'paint': {
					'line-color': '#871433',
					'line-width': [
						"interpolate", ["linear"], ["zoom"],
						// zoom is 5 (or less) -> circle radius will be 1px
						9.2, 1,
						// zoom is 10 (or greater) -> circle radius will be 5px
						9.62, 3
					]
				}
			});

			// Selected TopOD
			map.addSource('selected-regional-top-od', {
				'type': 'geojson',
				'data': URL_lines,
				'generateId': true
			});

			map.addLayer({
				'id': 'selected-regional-top-od',
				'type': 'line',
				'source': 'selected-regional-top-od',
				'paint': {
					'line-color': '#eb34b1',
					'line-width': [
						"interpolate", ["linear"], ["zoom"],
						// zoom is 5 (or less) -> circle radius will be 1px
						9.2, 1,
						// zoom is 10 (or greater) -> circle radius will be 5px
						9.62, 3
					]
				}
			});

			//Selected Tile
			map.addSource('selected-hotspot', {
				'type': 'geojson',
				'data': URL_lines,
				'generateId': true

			});

			map.addLayer({
				'id': 'selected-hotspot',
				'type': 'fill',
				'source': 'selected-hotspot',
				'layout': {
				},
				'paint': {
					'fill-color': '#eb34b1',
					'fill-opacity': 0.5
				}
			});

			map.addLayer({
				'id': 'outline-selected-hotspot',
				'type': 'line',
				'source': 'selected-hotspot',
				'layout': {},
				'paint': {
					'line-color': '#eb34b1',
					'line-width': 1
				}
			});

			map.addControl(draw, 'bottom-right')
			// Add zoom and rotation controls to the map.
			map.addControl(new mapboxgl.NavigationControl({ showCompass: false, showZoom: false }), 'top-left');
			map.addControl(new MapboxGeocoder({ accessToken: mapboxgl.accessToken, mapboxgl: mapboxgl, marker: false }), 'top-left');

		});

		this.setState({
			mapita: map,
			zoom: map.getZoom(),
		});

		map.on('mouseenter', 'selected-hotspot', () => {
			map.getCanvas().style.cursor = 'pointer';
		});

		// Change it back to a pointer when it leaves.
		map.on('mouseleave', 'selected-hotspot', () => {
			map.getCanvas().style.cursor = '';
		});

		map.on('click', 'selected-hotspot', (e) => {
			let resp_data = ''
			let coords = e.lngLat

			fetch(URL + 'areas_to_focus/tile_traffic/' + e.features[0].properties.tileId)
				.then((res) => res.json())
				.then((json) => {
					resp_data = json[0]
					//let date = (resp_data.date).split('T')[0]

					let total_trips_weekdays_tiles = resp_data.total_trips_weekdays_tiles;
					let total_trips_weekends_tiles = resp_data.total_trips_weekends_tiles;
					let train_trips_weekdays_tiles = resp_data.train_trips_weekdays_tiles;
					let train_trips_weekends_tiles = resp_data.train_trips_weekends_tiles;
					let car_trips_weekdays_tiles = resp_data.car_trips_weekdays_tiles;
					let car_trips_weekends_tiles = resp_data.car_trips_weekends_tiles;

					let car_pkm = resp_data.car_pkm;
					let train_pkm = resp_data.train_pkm;
					let total_pkm = resp_data.total_pkm;

					let total_trips_weekdays_tiles_str = '';
					let total_trips_weekends_tiles_str = '';
					let train_trips_weekdays_tiles_str = '';
					let train_trips_weekends_tiles_str = '';
					let car_trips_weekdays_tiles_str = '';
					let car_trips_weekends_tiles_str = '';

					let car_pkm_str = '';
					let train_pkm_str = '';
					let total_pkm_str = '';

					(typeof total_trips_weekdays_tiles == 'number' & total_trips_weekdays_tiles > 0) ? total_trips_weekdays_tiles = (total_trips_weekdays_tiles).toFixed(0) : total_trips_weekdays_tiles = '-';
					(total_trips_weekdays_tiles !== '-') ? total_trips_weekdays_tiles_str = numberWithCommas(total_trips_weekdays_tiles) : total_trips_weekdays_tiles_str = '-';

					(typeof total_trips_weekends_tiles == 'number' & total_trips_weekends_tiles > 0) ? total_trips_weekends_tiles = (total_trips_weekends_tiles).toFixed(0) : total_trips_weekends_tiles = '-';
					(total_trips_weekends_tiles !== '-') ? total_trips_weekends_tiles_str = numberWithCommas(total_trips_weekends_tiles) : total_trips_weekends_tiles_str = '-';

					(typeof train_trips_weekdays_tiles == 'number' & train_trips_weekdays_tiles > 0) ? train_trips_weekdays_tiles = (train_trips_weekdays_tiles).toFixed(0) : train_trips_weekdays_tiles = '-';
					(train_trips_weekdays_tiles !== '-') ? train_trips_weekdays_tiles_str = numberWithCommas(train_trips_weekdays_tiles) : train_trips_weekdays_tiles_str = '-';

					(typeof train_trips_weekends_tiles == 'number' & train_trips_weekends_tiles > 0) ? train_trips_weekends_tiles = (train_trips_weekends_tiles).toFixed(0) : train_trips_weekends_tiles = '-';
					(train_trips_weekends_tiles !== '-') ? train_trips_weekends_tiles_str = numberWithCommas(train_trips_weekends_tiles) : train_trips_weekends_tiles_str = '-';

					(typeof car_trips_weekdays_tiles == 'number' & car_trips_weekdays_tiles > 0) ? car_trips_weekdays_tiles = (car_trips_weekdays_tiles).toFixed(0) : car_trips_weekdays_tiles = '-';
					(car_trips_weekdays_tiles !== '-') ? car_trips_weekdays_tiles_str = numberWithCommas(car_trips_weekdays_tiles) : car_trips_weekdays_tiles_str = '-';

					(typeof car_trips_weekends_tiles == 'number' & car_trips_weekends_tiles > 0) ? car_trips_weekends_tiles = (car_trips_weekends_tiles).toFixed(0) : car_trips_weekends_tiles = '-';
					(car_trips_weekends_tiles !== '-') ? car_trips_weekends_tiles_str = numberWithCommas(car_trips_weekends_tiles) : car_trips_weekends_tiles_str = '-';

					(typeof car_pkm == 'number' & car_pkm > 0) ? car_pkm = (car_pkm).toFixed(0) : car_pkm = '-';
					(car_pkm !== '-') ? car_pkm_str = numberWithCommas(car_pkm) : car_pkm_str = '-';

					(typeof train_pkm == 'number' & train_pkm > 0) ? train_pkm = (train_pkm).toFixed(0) : train_pkm = '-';
					(train_pkm !== '-') ? train_pkm_str = numberWithCommas(train_pkm) : train_pkm_str = '-';

					(typeof total_pkm == 'number' & total_pkm > 0) ? total_pkm = (total_pkm).toFixed(0) : total_pkm = '-';
					(total_pkm !== '-') ? total_pkm_str = numberWithCommas(total_pkm) : total_pkm_str = '-';

					let html_modal = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Modal Split</span></p><p style="margin-block-end: 0em;"><span style="font-weight: semibold;">No data available</span></p>';

					let cars_percentage = (Number(resp_data.modal_split_weekdays_cars) * 100).toFixed(0);
					let trains_percentage = (Number(resp_data.modal_split_weekdays_trains) * 100).toFixed(0);

					let avg_trip = resp_data.avg_trip_length.toFixed(1);

					let other_percentage = (Number(100) - (Number(cars_percentage) + Number(trains_percentage))).toFixed(0);

					if ((total_trips_weekdays_tiles !== '-') & (car_trips_weekdays_tiles !== '-') & (train_trips_weekdays_tiles !== '-')) {
						html_modal = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Modal Split</span></p><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle; width: 100%;"><thead><tr><th></th><th style="width:50%; text-align: start;">Car/Bus</th><th style="width:50%; text-align: end;"><span style="float:right; position:relative, width:50%">Trains</span></th></tr></thead><tbody><tr><th></th><th style="width:50%; text-align: start; font-weight: normal;">' + cars_percentage + '%</th><th style="width:50%; text-align: end;"><span style="float:right; position:relative, width:50%; font-weight: normal;">' + trains_percentage + '%</span></th></tr></tbody></table><span class="block" style="display: block;height: 20px;color: #fff;font-size: 1em;float: left;background-color: #F78948;position: relative;overflow: hidden;width:' + cars_percentage + '%"></span><span class="block" style="display:block; height:20px; color:#fff; font-size:1em; float:left; background-color:#2f3338; position:relative; overflow:hidden; width:' + other_percentage + '%;"></span><span class="block" style="display:block; height:20px; color:#fff; font-size:1em; float:left; background-color:#0fabab; position:relative; overflow:hidden; width:' + trains_percentage + '%;"></span>';
					}

					let html = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Average Trip Length: </span>' + avg_trip + 'km </p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Trips per day</span></p><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle;"><thead><tr><th></th><th>&#x1f46a; &#128664;</th><th>&#x1f46a; &#x1f686;</th><th>Total</th></tr></thead><tbody><tr><th>Weekdays</th><td>' + car_trips_weekdays_tiles_str + '</td><td>' + train_trips_weekdays_tiles_str + '</td><td>' + total_trips_weekdays_tiles_str + '</td></tr><tr><th>Weekends</th><td>' + car_trips_weekends_tiles_str + '</td><td>' + train_trips_weekends_tiles_str + '</td><td>' + total_trips_weekends_tiles_str + '</td></tr></tbody></table><span style="font-weight: bold;">Passenger Km per month</span><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle;"><thead><tr><th>Cars</th><th>Trains</th><th>Total</th></tr></thead><tbody><tr><td>' + car_pkm_str + ' </td><td>' + train_pkm_str + ' </td><td><span>' + total_pkm_str + ' </span></td></tr></tbody></table>'

					let details_url = URL_FRONT + 'winners-losers-detail/' + coords.lng + ',' + coords.lat;
					let html_button = '<a href="' + details_url + '" target="_blank"><button style="background-color: #0c8a8a;color: white;border: 1px solid #e4e4e4;padding: 8px;border-radius: 6px;cursor: pointer; padding-top:10px;">See Details</button></a>';


					new mapboxgl.Popup()
						.setLngLat(e.lngLat)
						.setHTML(html + html_modal + html_button).addTo(map);
				})

		});

		map.on('draw.create', (e) => {
			const data = draw.getAll();
			if (data.features.length === 1) {

				let body = {
					"geometry": data.features[0].geometry,
					"date": '2022-08',
					"filtering_scope": this.state.value_filtering_scope
				}

				fetch(URL + 'od_analyzer/geometry', {
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(body)
				}).then((res) => res.json())
					.then((json) => {
						current_mode = 'default'
						if (this.state.current_stage === 'origin') {
							this.setState({ origin_geojson: data.features[0], isGeometrySelected: true })
							map.getSource('data-origin-polygon').setData(json);
						}
					})


			}
		});

		map.on('draw.delete', (e) => {
			const data = draw.getAll();
			draw.deleteAll()
			if (data.features.length === 0) {
				if (this.state.current_stage === 'origin') {
					this.setState({ origin_geojson: [], isGeometrySelected: false })
				}
				map.getSource('data-origin-polygon').setData(URL_lines);
				current_mode = 'draw_polygon'
			}
		});

		map.on('mouseenter', 'data-post-auto', () => {
			map.getCanvas().style.cursor = 'pointer';
		});

		// Change it back to a pointer when it leaves.
		map.on('mouseleave', 'data-post-auto', () => {
			map.getCanvas().style.cursor = '';
		});

		map.on('click', 'data-post-auto', (e) => {
			let html = '<h3 style="margin-block-end: 0em;"><span style="font-weight: bold;">' + e.features[0].properties['stop_name'] + '</span><br/></h3><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Place: </span><br/>' + e.features[0].properties['LOCALITYNAME'] + ' </p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Average daily passengers: </span><br/>' + e.features[0].properties['Einsteiger'] + ' </p>';
			new mapboxgl.Popup()
				.setLngLat(e.lngLat)
				.setHTML(html).addTo(map);
		})

		map.on('mouseenter', 'data-circles-bikes-stations', () => {
			map.getCanvas().style.cursor = 'pointer';
		});

		// Change it back to a pointer when it leaves.
		map.on('mouseleave', 'data-circles-bikes-stations', () => {
			map.getCanvas().style.cursor = '';
		});

		map.on('click', 'data-circles-bikes-stations', (e) => {

			let html_capacity = ''

			if ("capacity" in e.features[0].properties) {
				html_capacity = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Capacity: </span><br/>' + e.features[0].properties['capacity'] + ' bikes</p>'
			}

			let html = '<h3 style="margin-block-end: 0em;"><span style="font-weight: bold;">' + e.features[0].properties['station_name'] + '</span><br/></h3><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Address: </span><br/>' + e.features[0].properties['address'] + ', ' + e.features[0].properties['network'] + ' </p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Date of installment: </span><br/>' + (e.features[0].properties['integration_date'].toString().split('T')[0].slice(0, -3)) + ' </p>';
			new mapboxgl.Popup()
				.setLngLat(e.lngLat)
				.setHTML(html + html_capacity).addTo(map);
		})

		map.on('mouseenter', 'data-regional-routes', () => {
			map.getCanvas().style.cursor = 'pointer';
		});

		// Change it back to a pointer when it leaves.
		map.on('mouseleave', 'data-regional-routes', () => {
			map.getCanvas().style.cursor = '';
		});

		let previousID = ''

		map.on('click', 'data-regional-routes', (e) => {

			if (previousID !== '') {
				map.setFeatureState({
					source: 'data-regional-routes',
					id: previousID,
					generateId: true
				}, {
					clicked: false
				});
			}


			map.getCanvas().style.cursor = 'pointer';
			let circleID = e.features[0].id;

			previousID = circleID

			map.setFeatureState({
				source: 'data-regional-routes',
				id: circleID,
			}, {
				clicked: true
			});

			let id = e.features[0].properties.route_id;
			fetch(URL + 'regional_overview/route_pop_up', {
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					"route_id": id
				}),
			})
				.then((res) => res.json())
				.then((json) => {
					let html = '<h3 style="margin-block-end: 0em;"><span style="font-weight: bold;">' + json.features[0].properties['route_short_name'] + '</span><br/></h3><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Agency name: </span>' + (json.features[0].properties['agency_name']) + ' </p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Type of route: </span><br/>' + (json.features[0].properties['route_type_description']) + ' </p>';

					new mapboxgl.Popup()
						.setLngLat(e.lngLat)
						.setHTML(html).addTo(map);
				})


		})

		map.on('mouseenter', 'data-circles-ov-stations', () => {
			map.getCanvas().style.cursor = 'pointer';
		});

		// Change it back to a pointer when it leaves.
		map.on('mouseleave', 'data-circles-ov-stations', () => {
			map.getCanvas().style.cursor = '';
		});

		map.on('click', 'data-circles-ov-stations', (e) => {
			let type_stop = (e.features[0].properties['Verkehrsmitteltext'] === "null" ? 'Not specified' : e.features[0].properties['Verkehrsmitteltext'])

			let html = '<h3 style="margin-block-end: 0em;"><span style="font-weight: bold;">' + e.features[0].properties['stop_name'] + ' (' + e.features[0].properties['OPUIC'] + ') </span><br/></h3><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Place: </span><br/>' + e.features[0].properties['MUNICIPALITY NAME'] + ', ' + e.features[0].properties['CANTON ABBREVIATION'] + ' </p>';
			let html_details = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Type of stop: </span><br/>' + type_stop + ' </p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Operator: </span><br/>' + e.features[0].properties['BO_DESIGNATION_DE'] + ' </p>';
			new mapboxgl.Popup()
				.setLngLat(e.lngLat)
				.setHTML(html + html_details).addTo(map);
		})

		map.on('draw.update', (e) => {
			const data = draw.getAll();

			if (data.features.length === 1) {
				if (!(data.features[0].geometry === [])) {
					let body = {
						"geometry": data.features[0].geometry,
						"date": '2022-08',
						"filtering_scope": this.state.value_filtering_scope
					}

					fetch(URL + 'od_analyzer/geometry', {
						method: 'POST',
						headers: {
							'Accept': 'application/json',
							'Content-Type': 'application/json'
						},
						body: JSON.stringify(body)
					}).then((res) => res.json())
						.then((json) => {
							current_mode = 'default'
							if (e.type === 'draw.update') {
								if (this.state.current_stage === 'origin') {
									this.setState({ origin_geojson: data.features[0], isGeometrySelected: true })
									map.getSource('data-origin-polygon').setData(json);
								}
							}
						})
				}
				else {
					if (this.state.current_stage === 'origin') {
						this.setState({ origin_geojson: [], isGeometrySelected: false })
						map.getSource('data-origin-polygon').setData(URL_lines);
					}
					current_mode = 'draw_polygon'
				}
			} else if (data.features.length === 0) {
				if (this.state.current_stage === 'origin') {
					this.setState({ origin_geojson: [], isGeometrySelected: false })
					map.getSource('data-origin-polygon').setData(URL_lines);
				}
				current_mode = 'draw_polygon'
			}
		});
	}

	render() {
		const { hideNavs } = this.state;

		return (
			<div>
				<div ref={el => this.mapContainer = el} style={{ width: '100%', height: '100vh' }}>
				</div>
				<RecommendationNavBarComponent />
				<HideElementsComponent map={this.state.mapita} updateField={(card, field, value) => this.updateField(card, field, value)} />
				<div style={{ display: hideNavs ? 'none' : 'block' }}>
					<BtnModal coord={this.state.coord} map={this.state.mapita} updateFields={(list) => this.updateFields(list)} origin_geojson={this.state.origin_geojson} destination_geojson={this.state.destination_geojson} current_stage={this.state.current_stage} isGeometrySelected={this.state.isGeometrySelected} value_filtering_scope={this.state.value_filtering_scope} />
				</div>
				<LayerTylesComponent map={this.state.mapita} tyle={this.state.tyle} handleTyle={(value) => this.handleTyle(value)} handleCoord={(value1, value2) => this.handleCoord(value1, value2)} />
			</div>
		)
	}
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => (
	<MappRegionalAnalysis
		{...props}
		params={useParams()}
	/>
); 