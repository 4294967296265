import React from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import Select from 'react-select';
import { IconContext } from "react-icons";
import { BiTrip, BiSolidTraffic } from "react-icons/bi";
import { FaCar } from "react-icons/fa";
import { MdCo2, MdFlightTakeoff } from "react-icons/md";
import { IoBusSharp } from "react-icons/io5";
import Chart from 'chart.js/auto';
import { Line, Bar, Pie } from 'react-chartjs-2';
import { Form, Button } from 'react-bootstrap';
import { Table, Column, HeaderCell, Cell } from 'rsuite-table';
import ProgressBar from "@ramonak/react-progress-bar";
import { useParams } from "react-router-dom";
import 'rsuite-table/dist/css/rsuite-table.css'; // or 'rsuite-table/dist/css/rsuite-table.css'
import { Link } from "react-router-dom";
import DatePicker from "react-multi-date-picker";
import InputIcon from "react-multi-date-picker/components/input_icon"

import "react-multi-date-picker/styles/colors/teal.css"
import '../css/co2_cockpit.css'

function subtractMonth(date) {
    let d = date.getDate();
    date.setMonth(date.getMonth() - 1);
    if (date.getDate() != d) {
        date.setDate(0);
    }
    return date;
}

const lines_geojson = {
    'type': 'FeatureCollection',
    'features': [
        {
            'type': 'Feature',
            'geometry': {
                "type": "LineString",
                "coordinates": [
                ]
            }
        }
    ]
};

const URL = process.env.REACT_APP_URL_SERVER_BACK;
const URL_FRONT = process.env.REACT_APP_URL_SERVER_FRONT;

function numberWithCommas(x) {
    if (typeof x !== "undefined") {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");

    }
    else {
        return 0
    }
}

//Report Screen CO2 Cockpit
class CO2_Report_Screen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            lng: 8.251313,
            lat: 46.924143,
            zoom: 7,
            map: 9,
            mapco2: 9,
            maphotspots: 9,
            maptraffic: 9,
            mapContainerCatchmentAreas: 9,
            tyle_street: 'mapbox://styles/mapbox/streets-v12',
            tyle: 'mapbox://styles/mapbox/light-v11',
            name_place: '',
            respWvsW: this.handleFetchDataWsW(this.props.params['*']),
            respTraveledKm: this.handleFetchDataTraveledKm(this.props.params['*']),
            respCO2: this.handleFetchDataCO2(this.props.params['*']),

            param: this.props.params['*'],

            current_traffic_aggregation: ({
                label: 'City & Catchment Area Traffic',
                value: 'by'
            }),
            options_traffic_aggregation_: [
                {
                    label: 'City Traffic',
                    value: 'in'
                },
                {
                    label: 'City & Catchment Area Traffic',
                    value: 'by'
                }
            ],
            fligths_mallorca: 0,
            emission_total: 0,
            emission_change_percentage: 0,
            pkm_change_percentage: 0,
            pkm_total: 0,
            trips_zurich_munich: 0,
            potential_cars_reduce_in_city: 0,
            shift_to_public_transport: 0,
            current_mobility_pattern: 'trips',
            respTrafficPattern: this.handleFetchDataTrafficPattern(this.props.params['*']),
            car_co2_emissions: 0,
            train_co2_emissions: 0,
            total_co2_emissions: 1,
            emissions_by_traffic: 0,
            main_contributors: [],
            loading: false,
            co2_by: false,
            switch_state: false,
            loading_5in5: true,
            dataList: [],
            sortColumn: '',
            sortType: '',
            current_year_month: new Date(2022, 9),
            current_year: new Date(2022, 0),
            month_labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
        }
        this.handleData(this.props.params['*'], 2022, 10, 'by')
        this.handleMobilityEmission(this.props.params['*'], 2022, 10, 'by')
        this.fetchInitialData('sum_100')
    }

    handleData(bfs, year, month, measure) {
        fetch(URL + 'co2_cockpit/dashboard_summary/{0}/{1}/{2}/{3}'.format(bfs, year, month, measure))
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    // name_place: json.location + " (" + json.kanton + ")",
                                     name_place: json.location,
                    fligths_mallorca: numberWithCommas(json.data.emissions.fligths_mallorca.toFixed(0)),
                    emission_total: numberWithCommas(json.data.emissions.total.toFixed(0)),
                    emission_change_percentage: numberWithCommas((json.data.emissions.change_percentage * 100).toFixed(0)),
                    pkm_change_percentage: numberWithCommas((json.data.passenger_km.change_percentage * 100).toFixed(0)),
                    pkm_total: numberWithCommas(json.data.passenger_km.total.toFixed(0)),
                    trips_zurich_munich: numberWithCommas(json.data.passenger_km.trips_zurich_munich.toFixed(0)),
                    potential_cars_reduce_in_city: numberWithCommas(json.data.potential.cars_reduce_in_city.toFixed(0)),
                    shift_to_public_transport: numberWithCommas(json.data.potential.shift_to_public_transport.toFixed(0))
                });
            }).then(() => {

                let tyle_corner = {
                    center: [8.72139, 47.50062],
                    zoom: 12,
                    pitch: 0,
                    bearing: 0
                }
                this.state.map.flyTo({
                    ...tyle_corner, // Fly to the selected target
                    duration: 2000, // Animate over 2 seconds
                    essential: false // This animation is considered essential with
                    //respect to prefers-reduced-motion
                })
                //this.state.maphotspots.getSource('data-traffic-municipality-selected').setData(URL + 'co2_cockpit/traffic_map/' + bfs + '/' + year + '/' + month);
                this.state.map.getSource('data-municipality-selected').setData(URL + '/co2_cockpit/emissions_map/' + bfs + '/' + year + '/' + month + '/in');
            })
    }

    handleMobilityEmission(bfs, year, month, measure) {
        this.setState({ loading: true })
        fetch(URL + 'co2_cockpit/emissions_overview/{0}/{1}/{2}/{3}'.format(bfs, year, month, measure))
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    car_co2_emissions: json.car_co2_emissions,
                    train_co2_emissions: json.train_co2_emissions,
                    total_co2_emissions: json.total_co2_emissions,
                    main_contributors: json.main_contributors
                })

                if (measure === 'in') {
                    this.setState({ emissions_by_traffic: json.emissions_by_traffic })
                }
            }).then(() => {
                this.setState({ loading: false })
            })
    }

    componentDidUpdate(prevProps, prevState) {
        if ((this.state.co2_by !== prevState.co2_by && prevState.co2_by === true)) {
            const maptraffic = new mapboxgl.Map({
                container: this.mapContainerTraffic,
                style: this.state.tyle,
                center: [8.72139, 47.50062],
                zoom: 12,
            });

            maptraffic.on('load', () => {
                // Add zoom and rotation controls to the map.
                maptraffic.addControl(new mapboxgl.NavigationControl({ showCompass: false, showZoom: false }), 'top-left');

                let URL_traffic = URL + 'co2_cockpit/emissions_map/' + this.props.params['*'] + '/' + this.state.current_year.getFullYear() + '/' + this.state.current_year.getMonth() + '/in';

                if (!this.state.selectMonth) {
                    URL_traffic = URL + 'co2_cockpit/emissions_map/' + this.props.params['*'] + '/' + this.state.current_year_month.getFullYear() + '/' + this.state.current_year_month.getMonth() + '/in';
                }

                maptraffic.addSource('data-municipality-selected', {
                    'type': 'geojson',
                    'data': URL_traffic,
                    'generateId': true
                });

                // Add a black outline around the polygon
                maptraffic.addLayer({
                    'id': 'outline-data-municipality-selected',
                    'type': 'line',
                    'source': 'data-municipality-selected',
                    'layout': {},
                    'paint': {
                        'line-color': ['get', 'color'],
                        'line-width': 2
                    }
                });

                maptraffic.on('click', 'outline-data-municipality-selected', (e) => {
                    let html = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">CO<sub>2</sub> Emissions: </span><br/>' + numberWithCommas(e.features[0].properties.co2_emissions.toFixed(0)) + '</p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">PKm: </span><br/>' + numberWithCommas(e.features[0].properties.pkm.toFixed(0)) + '</p>';
                    new mapboxgl.Popup()
                        .setLngLat(e.lngLat)
                        .setHTML(html).addTo(maptraffic);
                })

            });

            this.setState({
                maptraffic: maptraffic
            });
        }
    }

    componentDidMount() {
        // Set up map 
        const map = new mapboxgl.Map({
            container: this.mapContainer,
            style: this.state.tyle,
            center: [this.state.lng, this.state.lat],
            zoom: this.state.zoom,
            interactive: false

        });

        let URL_traffic = URL + 'co2_cockpit/emissions_map/' + this.props.params['*'] + '/' + this.state.current_year.getFullYear() + '/' + this.state.current_year.getMonth() + '/in';

        if (!this.state.selectMonth) {
            URL_traffic = URL + 'co2_cockpit/emissions_map/' + this.props.params['*'] + '/' + this.state.current_year_month.getFullYear() + '/' + this.state.current_year_month.getMonth() + '/in';
        }

        map.on('load', () => {
            // Add zoom and rotation controls to the map.
            map.addControl(new mapboxgl.NavigationControl({ showCompass: false, showZoom: false }), 'top-left');



            map.addSource('data-municipality-selected', {
                'type': 'geojson',
                'data': URL_traffic,
                'generateId': true
            });

            // Add a black outline around the polygon
            map.addLayer({
                'id': 'outline-data-municipality-selected',
                'type': 'line',
                'source': 'data-municipality-selected',
                'layout': {},
                'paint': {
                    'line-color': ['get', 'color'],
                    'line-width': 2.5
                }
            });
        });

        const maptraffic = new mapboxgl.Map({
            container: this.mapContainerTraffic,
            style: this.state.tyle,
            center: [8.72139, 47.50062],
            zoom: 12,
        });

        maptraffic.on('load', () => {
            // Add zoom and rotation controls to the map.
            maptraffic.addControl(new mapboxgl.NavigationControl({ showCompass: false, showZoom: false }), 'top-left');

            maptraffic.addSource('data-municipality-selected', {
                'type': 'geojson',
                'data': URL_traffic,
                'generateId': true
            });

            // Add a black outline around the polygon
            maptraffic.addLayer({
                'id': 'outline-data-municipality-selected',
                'type': 'line',
                'source': 'data-municipality-selected',
                'layout': {},
                'paint': {
                    'line-color': ['get', 'color'],
                    'line-width': 2
                }
            });
        });

        maptraffic.on('click', 'outline-data-municipality-selected', (e) => {
            let html = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">CO<sub>2</sub> Emissions: </span><br/>' + numberWithCommas(e.features[0].properties.co2_emissions.toFixed(0)) + '</p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">PKm: </span><br/>' + numberWithCommas(e.features[0].properties.pkm.toFixed(0)) + '</p>';
            new mapboxgl.Popup()
                .setLngLat(e.lngLat)
                .setHTML(html).addTo(maptraffic);
        })

        const mapco2 = new mapboxgl.Map({
            container: this.mapContainerCo2,
            style: this.state.tyle,
            center: [8.72139, 47.50062],
            zoom: 12,
            interactive: false

        });

        mapco2.on('load', () => {
            // Add zoom and rotation controls to the map.
            mapco2.addControl(new mapboxgl.NavigationControl({ showCompass: false, showZoom: false }), 'top-left');

            mapco2.addSource('data-municipality-selected', {
                'type': 'geojson',
                'data': lines_geojson,
                'generateId': true
            });

            mapco2.addLayer({
                'id': 'data-municipality-selected',
                'type': 'fill',
                'source': 'data-municipality-selected',
                'layout': {},
                'paint': {
                    'fill-color': '#53b8ae',
                    'fill-opacity': 0.4
                }
            });

            // Add a black outline around the polygon
            mapco2.addLayer({
                'id': 'outline-data-municipality-selected',
                'type': 'line',
                'source': 'data-municipality-selected',
                'layout': {},
                'paint': {
                    'line-color': '#53b8ae',
                    'line-width': 1
                }
            });
        });

        const mapContainerCatchmentAreas = new mapboxgl.Map({
            container: this.mapContainerCatchmentAreas,
            style: this.state.tyle,
            center: [8.919172, 47.495863],
            zoom: 8.5,
            pitch: 0
        });

        mapContainerCatchmentAreas.on('load', () => {
            // Add zoom and rotation controls to the map.

            mapContainerCatchmentAreas.addSource('data-main-geopolygon', {
                'type': 'geojson',
                'data': {
                    "type": "Feature",
                    "properties": {
                        "UUID": "{EBBF5595-6BFC-4B52-95B2-A05F0B3B01D8}",
                        "DATUM_AEND": "2021-10-26",
                        "DATUM_ERST": "2008-11-24",
                        "ERSTELL_J": 2000,
                        "ERSTELL_M": "1",
                        "REVISION_J": 2022,
                        "REVISION_M": "1",
                        "GRUND_AEND": "Verbessert",
                        "HERKUNFT": "AV",
                        "HERKUNFT_J": 2021,
                        "HERKUNFT_M": "4",
                        "OBJEKTART": "Gemeindegebiet",
                        "BEZIRKSNUM": 110,
                        "SEE_FLAECH": null,
                        "REVISION_Q": "2021_Aufbau",
                        "NAME": "Winterthur",
                        "KANTONSNUM": 1,
                        "ICC": "CH",
                        "EINWOHNERZ": 114220,
                        "BFS_NUMMER": 230,
                        "GEM_TEIL": "0",
                        "GEM_FLAECH": 6807,
                        "SHN": "CH01100230"
                    },
                    "geometry": {
                        "type": "Polygon",
                        "coordinates": [
                            [
                                [8.775837130205947, 47.54413633377509, 461.231],
                                [8.779442164178523, 47.53598682179209, 480.508],
                                [8.785445679935409, 47.5342332880213, 477.553],
                                [8.770690370831911, 47.52937759667828, 468.682],
                                [8.76714172300934, 47.52085961320101, 458.982],
                                [8.786756450257542, 47.51165127347839, 489.982],
                                [8.796922529403973, 47.51299904731398, 510.449],
                                [8.799456120337227, 47.51072188619494, 517.866],
                                [8.780629673816351, 47.50484952077538, 466.219],
                                [8.778887459056271, 47.50246715947834, 466.14],
                                [8.783661413461408, 47.49948666514666, 524.034],
                                [8.780984201038297, 47.498266730333796, 560.423],
                                [8.79219907869699, 47.492219476683516, 556.089],
                                [8.805791619364507, 47.49217989253879, 551.275],
                                [8.809941332471105, 47.48795525455323, 560.944],
                                [8.807165482194318, 47.486120868691614, 566.235],
                                [8.809346327475145, 47.48329974558796, 583.833],
                                [8.804620226403541, 47.48205177924597, 601.489],
                                [8.809456159132003, 47.47576173907302, 573.044],
                                [8.800652756919831, 47.474381205161016, 554.136],
                                [8.791774523331606, 47.465634234262026, 519.784],
                                [8.771900456297066, 47.459086213518766, 491.666],
                                [8.758477648614619, 47.46219534977271, 479.501],
                                [8.753614384223784, 47.46874664562803, 473.671],
                                [8.748733672173667, 47.46157811036743, 468.242],
                                [8.735691742543342, 47.46344668565059, 461.883],
                                [8.732839111513506, 47.458047519158534, 457.911],
                                [8.729012681902212, 47.461631937533404, 454.414],
                                [8.717898116321198, 47.461180996591864, 573.889],
                                [8.715510173413417, 47.45035933987086, 611.202],
                                [8.709623771431728, 47.44982684158329, 504.171],
                                [8.7055392811865, 47.45141907415331, 470.822],
                                [8.707814740975417, 47.458257337959836, 458.762],
                                [8.702360997598158, 47.45853200338706, 543.842],
                                [8.706275449228254, 47.46685588219064, 454.704],
                                [8.694122911270405, 47.466488008498914, 490.598],
                                [8.700086078475294, 47.47002247181957, 525.151],
                                [8.681593459841892, 47.486819883114855, 628.059],
                                [8.674520450886959, 47.482167476899896, 564.464],
                                [8.668264986108314, 47.48305898153363, 571.742],
                                [8.658017863626446, 47.495370288346, 591.557],
                                [8.658933444485623, 47.51531590722535, 398.99],
                                [8.677103811390838, 47.517846854234065, 413.919],
                                [8.687592905287401, 47.531515282710494, 443.762],
                                [8.701103585369978, 47.53287091825659, 426.435],
                                [8.698353613588809, 47.52539160658157, 434.262],
                                [8.700990096620792, 47.521735581352374, 445.656],
                                [8.71071994143503, 47.524303770164416, 454.335],
                                [8.730760201542358, 47.520430819710135, 523.288],
                                [8.737018767023509, 47.52247873291714, 506.85],
                                [8.736781239866293, 47.52969331635094, 453.639],
                                [8.750693694816787, 47.53789247943893, 498.423],
                                [8.753337891109059, 47.547365134683375, 502.404],
                                [8.775837130205947, 47.54413633377509, 461.231]
                            ]
                        ]
                    }
                },
                'generateId': true
            });

            mapContainerCatchmentAreas.addLayer({
                'id': 'data-main-geopolygon',
                'type': 'fill',
                'source': 'data-main-geopolygon',
                'layout': {},
                'paint': {
                    'fill-color': '#53b8ae',
                    'fill-opacity': 0.4
                }
            });

            mapContainerCatchmentAreas.addLayer({
                'id': 'outline-data-main-geopolygon',
                'type': 'line',
                'source': 'data-main-geopolygon',
                'layout': {},
                'paint': {
                    'line-color': '#53b8ae',
                    'line-width': 1
                }
            });

            mapContainerCatchmentAreas.addSource('data-red-geopolygon', {
                'type': 'geojson',
                'data': {
                    "type": "Feature",
                    "properties": {
                    },
                    "geometry": {
                        "type": "Polygon",
                        "coordinates": [
                            [
                                [8.57368719319341, 47.42003454961547, 424.997],
                                [8.573013796239964, 47.41143671838474, 427.803],
                                [8.59729137637101, 47.40630706619764, 428.276],
                                [8.593790016151273, 47.402220376905866, 429.628],
                                [8.596382039209875, 47.39663072488339, 440.481],
                                [8.588293626142411, 47.395795966464014, 461.523],
                                [8.583170931240234, 47.38830489052962, 557.283],
                                [8.586972237103707, 47.39016920361926, 525.313],
                                [8.593085104492356, 47.385647597467035, 562.904],
                                [8.589554907328115, 47.38375876284973, 592.839],
                                [8.60605071507696, 47.368723645980026, 638.063],
                                [8.616663679048822, 47.36827826665994, 587.773],
                                [8.614715953566161, 47.36150750249194, 640.846],
                                [8.622579152867406, 47.35982619056818, 640.966],
                                [8.625452972857316, 47.35466305689518, 656.023],
                                [8.602630688255696, 47.35072469897901, 562.309],
                                [8.586306843882054, 47.352880229574865, 502.167],
                                [8.565643794410349, 47.34683699255806, 415.42],
                                [8.523729671614747, 47.32416055752967, 440.375],
                                [8.503182938721395, 47.320218575049815, 748.394],
                                [8.497739114702872, 47.34520534125419, 789.269],
                                [8.48206285974115, 47.35954006129172, 721.283],
                                [8.470546398628928, 47.361656723482554, 701.057],
                                [8.464280399926379, 47.36727417919092, 608.094],
                                [8.470236484707788, 47.373950759344226, 560.079],
                                [8.448018323083023, 47.380247525965785, 583.041],
                                [8.458434130438532, 47.38230388065435, 539.833],
                                [8.46727779003116, 47.39065019105477, 437.285],
                                [8.464895969062193, 47.39508084270995, 405.188],
                                [8.473022615495287, 47.395063021564454, 396.102],
                                [8.465170770214105, 47.39828772171044, 392.906],
                                [8.467714108855994, 47.40299909453172, 391.291],
                                [8.478161214279586, 47.40387375171605, 392.602],
                                [8.477261650229215, 47.4119371068779, 483.613],
                                [8.468768631230281, 47.41224714563835, 463.602],
                                [8.469143474649684, 47.41692613706719, 504.476],
                                [8.477841298522538, 47.42211438680464, 522.47],
                                [8.490199201049114, 47.42233492216988, 466.173],
                                [8.485804272509595, 47.43102620790227, 439.16],
                                [8.502078136007388, 47.434665158109944, 440.7],
                                [8.512728832480969, 47.4308479695371, 441.494],
                                [8.543120833758474, 47.43288294367855, 465.163],
                                [8.555362994242069, 47.43066069282045, 440.509],
                                [8.557842448886612, 47.42553823317414, 428.854],
                                [8.55420306159382, 47.420800355197564, 427.182],
                                [8.563246291129962, 47.415646102650385, 424.818],
                                [8.57368719319341, 47.42003454961547, 424.997]
                            ],

                        ]
                    }
                },
                'generateId': true
            });

            mapContainerCatchmentAreas.addLayer({
                'id': 'data-red-geopolygon',
                'type': 'fill',
                'source': 'data-red-geopolygon',
                'layout': {},
                'paint': {
                    'fill-color': '#b02727',
                    'fill-opacity': 0.4
                }
            });

            mapContainerCatchmentAreas.addLayer({
                'id': 'outline-data-red-geopolygon',
                'type': 'line',
                'source': 'data-red-geopolygon',
                'layout': {},
                'paint': {
                    'line-color': '#b02727',
                    'line-width': 1
                }
            });

            mapContainerCatchmentAreas.addSource('data-yellow-geopolygon', {
                'type': 'geojson',
                'data': {
                    "type": "Feature",
                    "properties": {
                    },
                    "geometry": {
                        "type": "Polygon",
                        "coordinates": [
                            [
                                [8.887602009247232, 47.528387500099406, 547.11],
                                [8.866517303159911, 47.53047055690741, 537.378],
                                [8.87198330425416, 47.53363807086252, 527.235],
                                [8.86843034161667, 47.54078133346592, 504.259],
                                [8.880410101282212, 47.546007830055075, 461.947],
                                [8.876125990291127, 47.54587716604592, 473.119],
                                [8.86996814422378, 47.552425348080554, 416.711],
                                [8.87231291805978, 47.55665115619304, 399.531],
                                [8.863805383308225, 47.56104867885161, 406.947],
                                [8.865065857663788, 47.56775383355327, 383.977],
                                [8.855918940962002, 47.56943066037828, 382.88],
                                [8.85097783633127, 47.56360760717527, 388.342],
                                [8.839199302103385, 47.56534972369414, 380.655],
                                [8.841095719494724, 47.57572411775985, 380.973],
                                [8.882158632526469, 47.57884842596957, 387.931],
                                [8.915695270035231, 47.58655884418913, 389.891],
                                [8.921421067385864, 47.579039640872146, 391.704],
                                [8.920986367610821, 47.57099591808804, 392.636],
                                [8.932392189867961, 47.56527681873777, 442.51],
                                [8.937529571812735, 47.566644268427005, 459.735],
                                [8.945828704417044, 47.555043586388926, 563.859],
                                [8.92983530444847, 47.5493981346827, 557.857],
                                [8.923257458839897, 47.52917591040232, 488.565],
                                [8.911657124040463, 47.52924526099782, 486.038],
                                [8.907448044327786, 47.54046546521098, 453.811],
                                [8.8920366164486, 47.5358726702459, 446.334],
                                [8.887602009247232, 47.528387500099406, 547.11]
                            ],
                            [
                                [8.635020499848471, 47.52703995196392, 586.234],
                                [8.642537955822469, 47.53667009485147, 493.908],
                                [8.653081164835882, 47.542138878513136, 594.75],
                                [8.646110735836835, 47.54522384244291, 609.18],
                                [8.650389220546991, 47.54586584870024, 588.824],
                                [8.646552260157298, 47.55451907964016, 493.113],
                                [8.650625503231073, 47.55518372381846, 527.369],
                                [8.648713482213354, 47.558307130910215, 507.652],
                                [8.655889765350915, 47.557598734812366, 506.866],
                                [8.660515959263527, 47.562195205160776, 534.482],
                                [8.677408741004287, 47.55812794222392, 451.708],
                                [8.68166133630767, 47.549698004453155, 467.745],
                                [8.687572538029041, 47.55242992596475, 430.915],
                                [8.691756699515624, 47.548743335769444, 434.083],
                                [8.691617077137035, 47.54189860561022, 420.63],
                                [8.695718865368017, 47.542763623977955, 421.55],
                                [8.6979686057964, 47.53716631074977, 426.071],
                                [8.697853595748875, 47.5328378571508, 425.241],
                                [8.687592905287401, 47.531515282710494, 443.762],
                                [8.677103811390838, 47.517846854234065, 413.919],
                                [8.667959347724143, 47.51589729886904, 397.417],
                                [8.64060632783031, 47.52113711630747, 381.755],
                                [8.635020499848471, 47.52703995196392, 586.234]
                            ],
                            [
                                [8.592787807613151, 47.43580851556091, 467.281],
                                [8.569667704111172, 47.441554141640594, 431.244],
                                [8.554637034283202, 47.44044494840261, 420.967],
                                [8.545941694623648, 47.44753349082276, 419.678],
                                [8.548695142109118, 47.45334722609548, 421.761],
                                [8.545197430626933, 47.45501232516917, 421.224],
                                [8.551068957912422, 47.46333818841534, 421.122],
                                [8.563307590887796, 47.46909612787549, 431.738],
                                [8.558657460333244, 47.47500411016705, 436.636],
                                [8.574088864344766, 47.473515335164215, 505.477],
                                [8.577866696105483, 47.48045269506812, 542.402],
                                [8.593025565637474, 47.47346755154186, 519.67],
                                [8.597566378342108, 47.46694801559493, 520.774],
                                [8.606330230475558, 47.470998713356366, 575.227],
                                [8.604203641274658, 47.47629183125262, 578.587],
                                [8.610636761990552, 47.481313172509715, 522.596],
                                [8.61711855407822, 47.478770834310694, 525.893],
                                [8.617119469717666, 47.47256077075779, 513.827],
                                [8.62540163959065, 47.47397399016082, 582.652],
                                [8.626211370883189, 47.466316274637414, 591.113],
                                [8.63254681238012, 47.46427878801551, 595.428],
                                [8.628467158900543, 47.45911999013972, 509.462],
                                [8.612175098826258, 47.45759321992319, 526.977],
                                [8.606178276365684, 47.4521251648163, 460.106],
                                [8.606892251278516, 47.44390437375567, 450.61],
                                [8.603063620604992, 47.43920140020825, 482.571],
                                [8.592787807613151, 47.43580851556091, 467.281]
                            ]
                        ]
                    }
                },
                'generateId': true
            });

            mapContainerCatchmentAreas.addLayer({
                'id': 'data-yellow-geopolygon',
                'type': 'fill',
                'source': 'data-yellow-geopolygon',
                'layout': {},
                'paint': {
                    'fill-color': '#e67f09',
                    'fill-opacity': 0.4
                }
            });

            mapContainerCatchmentAreas.addLayer({
                'id': 'outline-data-yellow-geopolygon',
                'type': 'line',
                'source': 'data-yellow-geopolygon',
                'layout': {},
                'paint': {
                    'line-color': '#e67f09',
                    'line-width': 1
                }
            });

            mapContainerCatchmentAreas.addSource('data-green-geopolygon', {
                'type': 'geojson',
                'data': {
                    "type": "Feature",
                    "properties": {
                    },
                    "geometry": {
                        "type": "Polygon",
                        "coordinates": [
                            [
                                [9.425776066438182, 47.407270656856, 1041.813],
                                [9.397447077838576, 47.400584860083555, 993.637],
                                [9.371679078543762, 47.407709906871965, 738.925],
                                [9.358574406020722, 47.40204133543335, 690.93],
                                [9.327758854854672, 47.401256688927376, 588.602],
                                [9.3019899179089, 47.395155063657455, 702.044],
                                [9.294153580405165, 47.39639884200006, 721.872],
                                [9.296796309059081, 47.3993622561339, 675.003],
                                [9.291517329034951, 47.403902985140554, 654.605],
                                [9.297667003102344, 47.40819898740733, 653.865],
                                [9.297334559405341, 47.41945148990664, 768.777],
                                [9.308258421907665, 47.4210910810971, 701.488],
                                [9.303487303630405, 47.41616997373729, 687.758],
                                [9.308724335588213, 47.41395352381356, 632.166],
                                [9.348958954765788, 47.42686109400089, 559.624],
                                [9.361255690043368, 47.43424122451056, 555.0],
                                [9.358744928179162, 47.44348483002708, 550.341],
                                [9.367664203139451, 47.444050178767675, 626.788],
                                [9.36537036729552, 47.44730028572368, 571.416],
                                [9.37094102863986, 47.44927477043176, 637.58],
                                [9.376120376342044, 47.4472626203583, 704.394],
                                [9.374309253444837, 47.4519982862302, 634.867],
                                [9.380753283179839, 47.453073561289926, 691.193],
                                [9.381275451354988, 47.44837698840116, 771.212],
                                [9.390268754874702, 47.44950785516215, 758.476],
                                [9.388778793543276, 47.445008960214416, 736.128],
                                [9.393676588470429, 47.442262837311226, 668.107],
                                [9.397507759636909, 47.4516338224453, 656.548],
                                [9.429548034544249, 47.45043026358164, 496.592],
                                [9.431492043145361, 47.4445781689771, 507.215],
                                [9.428484455708412, 47.44166521757889, 530.995],
                                [9.435244622237855, 47.433602052300614, 572.98],
                                [9.432522080260512, 47.429512419185606, 633.977],
                                [9.435037489454078, 47.42083356752505, 809.346],
                                [9.425776066438182, 47.407270656856, 1041.813]
                            ],
                            [
                                [9.163526711007481, 47.66030152528385, 398.946],
                                [9.170633364293067, 47.65481892120837, 402.12],
                                [9.181759250520356, 47.65594044612749, 396.989],
                                [9.181262642049578, 47.652988372138125, 396.333],
                                [9.191088897070937, 47.64774007421586, 395.46],
                                [9.204480360445173, 47.64535394954197, 395.46],
                                [9.204451927757374, 47.637851306324684, 414.227],
                                [9.198541785605734, 47.63163598836919, 470.66],
                                [9.187589961446502, 47.63121519010691, 473.684],
                                [9.181948387309982, 47.62083648092013, 531.9],
                                [9.17351491377252, 47.62192058837107, 535.679],
                                [9.176230850437722, 47.62584446842796, 517.275],
                                [9.171773083344435, 47.628681914310874, 512.348],
                                [9.173973628973538, 47.631580164294625, 502.807],
                                [9.165802968219536, 47.635542401498476, 499.777],
                                [9.158684604629194, 47.625737521577804, 536.898],
                                [9.164625829458052, 47.621698622966754, 535.16],
                                [9.155507871681934, 47.62234468207182, 535.837],
                                [9.14010666489463, 47.63323572607975, 532.247],
                                [9.149059236999554, 47.64640725986762, 450.348],
                                [9.159812272757614, 47.65128076963351, 405.775],
                                [9.158878937568039, 47.65597756094957, 398.41],
                                [9.163526711007481, 47.66030152528385, 398.946]
                            ],
                            [
                                [9.062562324707331, 47.44624092845776, 533.374],
                                [9.05041779275941, 47.452858779157516, 557.357],
                                [9.027237834121431, 47.45773751662588, 566.804],
                                [9.029495863069624, 47.46665782275751, 561.327],
                                [9.008223251676341, 47.47236640312805, 520.955],
                                [9.011976775242355, 47.475344311032735, 516.561],
                                [9.003591273934425, 47.4811912196625, 507.247],
                                [9.012488118264198, 47.489971018577855, 534.268],
                                [9.028318727976103, 47.488669329663686, 548.898],
                                [9.04028606538226, 47.495761985574944, 699.587],
                                [9.055828391033119, 47.48741023287851, 664.06],
                                [9.062657654748206, 47.492463932057156, 680.029],
                                [9.082365757073976, 47.49523609876501, 765.986],
                                [9.08977098031985, 47.48282252132476, 619.56],
                                [9.097730869787535, 47.48063351706608, 598.549],
                                [9.09251612562486, 47.475504278866, 635.541],
                                [9.064122656382327, 47.46829952452654, 647.385],
                                [9.080475283202027, 47.45765109239706, 506.756],
                                [9.062562324707331, 47.44624092845776, 533.374]
                            ],
                            [
                                [8.796922529403973, 47.51299904731398, 510.449],
                                [8.774737625622842, 47.514949148581074, 459.584],
                                [8.774304008333955, 47.51880273845908, 460.07],
                                [8.76714172300934, 47.52085961320101, 458.982],
                                [8.76800308511932, 47.525567987505006, 467.421],
                                [8.792661708574808, 47.53579220409022, 470.561],
                                [8.800484837688957, 47.54289339627432, 450.639],
                                [8.808521055650552, 47.54318850388415, 444.97],
                                [8.805625939071268, 47.546159412906555, 443.027],
                                [8.81595056641216, 47.55350655224467, 412.235],
                                [8.829915187659752, 47.55308080658473, 407.836],
                                [8.840844929086535, 47.53267117346035, 545.842],
                                [8.874034753556103, 47.52937059631808, 527.822],
                                [8.87103510693104, 47.525441366383944, 543.199],
                                [8.869596867278142, 47.524433843176276, 544.719],
                                [8.868095071676436, 47.52395946429506, 543.2],
                                [8.863293541438505, 47.527041172855675, 547.261],
                                [8.84806191553416, 47.5253034409107, 574.231],
                                [8.846868141744102, 47.51915183513841, 619.534],
                                [8.853477769191786, 47.51595218359018, 641.592],
                                [8.844991381459481, 47.51009692273239, 569.318],
                                [8.84051055310339, 47.513132456143005, 628.167],
                                [8.826693617062265, 47.5115277883124, 572.404],
                                [8.829813042604949, 47.5173719805319, 579.151],
                                [8.824652651603845, 47.51918884744987, 577.545],
                                [8.813039525780802, 47.51376922131173, 576.408],
                                [8.796922529403973, 47.51299904731398, 510.449]
                            ]
                        ]
                    }
                },
                'generateId': true
            });

            mapContainerCatchmentAreas.addLayer({
                'id': 'data-green-geopolygon',
                'type': 'fill',
                'source': 'data-green-geopolygon',
                'layout': {},
                'paint': {
                    'fill-color': '#42b027',
                    'fill-opacity': 0.4
                }
            });

            mapContainerCatchmentAreas.addLayer({
                'id': 'outline-data-green-geopolygon',
                'type': 'line',
                'source': 'data-green-geopolygon',
                'layout': {},
                'paint': {
                    'line-color': '#42b027',
                    'line-width': 1
                }
            });

            mapContainerCatchmentAreas.addControl(new mapboxgl.FullscreenControl());

        });

        this.setState({
            map: map,
            zoom: map.getZoom(),
            mapco2: mapco2,
            // maphotspots: maphotspots,
            maptraffic: maptraffic,
            mapContainerCatchmentAreas: mapContainerCatchmentAreas
        });
    }


    handleMonthYear(event) {
        console.log(event, new Date(event.year, (event.month.number - 1)));

        this.setState({ current_year_month: new Date(event.year, (event.month.number - 1)) });
        //this.state.maphotspots.getSource('data-traffic-municipality-selected').setData(URL + 'co2_cockpit/traffic_map/' + this.props.params['*'] + '/'+event.month.number+'/' + event.year);
        this.state.map.getSource('data-municipality-selected').setData(URL + 'co2_cockpit/emissions_map/{0}/{1}/{2}/in'.format(this.props.params['*'], event.year, event.month.number));
        this.handleData(this.props.params['*'], event.year, event.month.number, this.state.current_traffic_aggregation.value)
    }

    handleYear(event) {
        console.log(event, new Date(event.year, 0));


        this.setState({ current_year: new Date(event.year, 0) });
        //this.state.maphotspots.getSource('data-traffic-municipality-selected').setData(URL + 'co2_cockpit/traffic_map/' + this.props.params['*'] + '/all/' + event.year);
        this.state.map.getSource('data-municipality-selected').setData(URL + 'co2_cockpit/emissions_map/{0}/{1}/{2}/in'.format(this.props.params['*'], event.year, 'all'));
        this.handleData(this.props.params['*'], event.year, 'all', this.state.current_traffic_aggregation.value)
    }

    handleTrafficAggregation(event) {
        this.setState({ current_traffic_aggregation: event });
        if (this.state.selectMonth) {
            this.handleData(this.props.params['*'], this.state.current_year.getFullYear(), 'all', event.value)
        } else {
            this.handleData(this.props.params['*'], this.state.current_year_month.getFullYear(), this.state.current_year_month.getMonth(), event.value)
        }
    }

    async handleFetchDataWsW(param) {
        if (typeof param !== "undefined") {
            fetch(URL + 'graph_weekdays_vs_weekends/bfs,' + param + ',empty')
                .then((res) => res.json())
                .then((json) => {
                    this.setState({ respWvsW: json });
                })
        }
    }

    async handleFetchDataTraveledKm(param) {
        fetch(URL + 'graph_traveled_km/bfs,' + param + ',empty,0')
            .then((res) => res.json())
            .then((json) => {
                this.setState({ respTraveledKm: json });
            })
    }

    async handleFetchDataCO2(param) {
        fetch(URL + 'graph_co2_emissions/bfs,' + param + ',empty,0')
            .then((res) => res.json())
            .then((json) => {
                this.setState({ respCO2: json });
            })
    }

    async handleFetchDataTrafficPattern(param) {
        fetch(URL + 'co2_cockpit/traffic_chart/' + param)
            .then((res) => res.json())
            .then((json) => {
                let arr = []
                for (const [key, value] of Object.entries(json[0])) {
                    arr.push(value)
                }
                this.setState({ respTrafficPattern: arr });
            })
    }

    changeToPKm() {
        this.setState({ current_mobility_pattern: 'pkm' })
    }

    changeToTrips() {
        this.setState({ current_mobility_pattern: 'trips' })
    }

    changeToCo2() {
        this.setState({ current_mobility_pattern: 'co2' })
    }

    changeToBy() {
        this.setState({ co2_by: true })
        this.handleMobilityEmission(this.props.params['*'], 2022, 10, 'by')
    }

    changeToIn() {
        this.setState({ co2_by: false })
        this.handleMobilityEmission(this.props.params['*'], 2022, 10, 'in')

    }

    changeToYear() {
        this.setState({ selectMonth: true })
        this.state.map.getSource('data-municipality-selected').setData(URL + 'co2_cockpit/emissions_map/{0}/{1}/{2}/in'.format(this.props.params['*'], this.state.current_year_month.getFullYear(), 'all'));
        this.handleData(this.props.params['*'], this.state.current_year_month.getFullYear(), 'all', this.state.current_traffic_aggregation.value)

    }

    changeToMonth() {
        this.setState({ selectMonth: false })
        this.state.map.getSource('data-municipality-selected').setData(URL + 'co2_cockpit/emissions_map/{0}/{1}/{2}/in'.format(this.props.params['*'], this.state.current_year_month.getFullYear(), (this.state.current_year_month.getMonth() + 1)));
        this.handleData(this.props.params['*'], this.state.current_year_month.getFullYear(), (this.state.current_year_month.getMonth() + 1), this.state.current_traffic_aggregation.value)
    }

    handleTypeEmployer() {
        let currentState = !this.state.switch_state
        this.setState({ switch_state: currentState })
        this.fetchInitialData((currentState ? 'max' : 'sum_100'))
    }

    fetchInitialData(filter) {
        this.setState({ loading_5in5: true })
        let commuter_effect_trips = 0;
        let commuter_effect_pkm = 0;

        let body = {
            "filter": filter + '_all',
            "conversion_rates": { "behavior": 0.3, "last_mile": 0.3, "bike": 0.3, "working": 0.3, "car_pooling": 0.15 },
            "active_options": ['behavior', 'last_mile', 'bike', 'working', 'car_pooling'],
            "n_employees": 100,
            "distance_to_station_range": [0, 35000],
            "kanton": 'BFS' + this.props.params['*'],
            "page": 1,
            "metric": 'pkm',
            "type": '_employeers',
            "n_measures": 100,
            "query": ''
        }


        fetch(URL + 'tile_potential_employer/top_100_dashboard_pages', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body),
        })
            .then((res) => res.json())
            .then((json) => {
                commuter_effect_trips = json.commuter_effect_trips;
                commuter_effect_pkm = json.commuter_effect_pkm;
                this.setState({
                    commuter_effect_trips: json.commuter_effect_trips, commuter_effect_pkm: json.commuter_effect_pkm
                })

            }).then(() => {
                body = {
                    "filter": filter + '_all',
                    "conversion_rates": { "behavior": 0.3, "last_mile": 0.3, "bike": 0.3, "working": 0.3, "car_pooling": 0.15 },
                    "active_options": ['behavior', 'last_mile', 'bike', 'working', 'car_pooling'],
                    "n_employees": 100,
                    "distance_to_station_range": [0, 35000],
                    "kanton": 'BFS' + this.props.params['*'],
                    "page": 1,
                    "metric": 'pkm',
                    "type": '_employeers',
                    "n_measures": 100,
                    "query": '',
                    "commuter_effect_trips": commuter_effect_trips,
                    "commuter_effect_pkm": commuter_effect_pkm,
                }

                fetch(URL + 'tile_potential_employer/top_100_dashboard', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(body),
                })
                    .then((res) => res.json())
                    .then((json) => {
                        this.setState({ dataList: json, loading_5in5: false })
                    })
            })



    }

    render() {

        const { respWvsW } = this.state;
        const { respTraveledKm } = this.state;
        const { current_mobility_pattern } = this.state;
        const { respTrafficPattern } = this.state;
        const { respCO2 } = this.state;

        const dataBar_WvsW = {
            labels: respWvsW['date'],
            datasets: [{
                label: 'Weekends',
                data: respWvsW['total_trips_weekend'],
                backgroundColor: [
                    'rgba(128, 2, 63, 0.2)'
                ],
                borderColor: [
                    'rgb(128, 2, 63)'
                ],
                borderWidth: 1,
                fill: true,
                tension: 0.4,
                pointRadius: 1
            }, {
                label: 'Weekdays',
                data: respWvsW['total_trips_weekdays'],
                backgroundColor: [
                    'rgba(2, 128, 6,0.2)'
                ],
                borderColor: [
                    'rgb(2, 128, 6)'

                ],
                borderWidth: 1,
                fill: true,
                tension: 0.4,
                pointRadius: 1
            },

            ]
        };

        const dataBar_TrafficPattern = {
            labels: ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00',
                '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'],
            datasets: [{
                label: 'Traffic index',
                data: respTrafficPattern,
                backgroundColor: [
                    'rgba(209, 73, 91, 0.2)'
                ],
                borderColor: [
                    'rgb(209, 73, 91)'
                ],
                borderWidth: 1,
                fill: true,
                tension: 0.4,
                pointRadius: 1
            }
            ]
        }

        const dataBar_TraveledKm = {
            labels: respTraveledKm['date'],
            datasets: [{
                label: 'Traveled Km by Trains',
                data: respTraveledKm['traveled_km_trains'],
                backgroundColor: [
                    'rgba(247, 137, 72, 0.2)'
                ],
                borderColor: [
                    'rgb(247, 137, 72)'
                ],
                borderWidth: 1,
                fill: true,
                tension: 0.4,
                pointRadius: 1
            },
            {
                label: 'Traveled Km by Cars',
                data: respTraveledKm['traveled_km_cars'],
                backgroundColor: [
                    'rgba(2,127,128,0.2)'
                ],
                borderColor: [
                    'rgb(2,127,128)'
                ],
                borderWidth: 1,
                fill: true,
                tension: 0.4,
                pointRadius: 1
            },
            ]
        };

        const dataPieMS = {
            labels: ['Car', 'Walk', 'Rail', 'Cycle', 'Bus'],
            datasets: [
                {
                    label: 'Age Distribution',
                    data: ['41.4', '27.6', '16.3', '9.02', '5.65'],
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)',
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)',
                    ],
                    borderWidth: 1,
                },
            ],
        }

        const dataNumTripsWeekdays = {
            labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            datasets: [{
                label: '2019',
                data: [null, null, null, 4310566.00079453,
                    4579296.007943988,
                    4111550.001883507,
                    4158907.005376458,
                    4056678.013197899,
                    4634490.98871839,
                    5653314.964653611,
                    5740461.945917487,
                    4601287.006094217],
                backgroundColor: [
                    'rgba(0, 145, 110, 0.2)'
                ],
                borderColor: [
                    'rgb(0, 145, 110)'
                ],
                borderWidth: 1
            },
            {
                label: '2020',
                data: [5429348.988760471,
                    4811407.001438141,
                    4244257.990406394,
                    3163094.9904186726,
                    3883589.013488531,
                    4793547.004597425,
                    4766740.980033517,
                    4357360.021998167,
                    5177934.996013761,
                    4848432.999906778,
                    4543922.999959588,
                    4089959.976774931,
                ],
                backgroundColor: [
                    'rgba(61, 52, 139,0.2)'
                ],
                borderColor: [
                    'rgb(61, 52, 139)'
                ],
                borderWidth: 1
            },
            {
                label: '2021',
                data: [3142231.9932882786,
                    3306241.0015559196,
                    4597249.021257877,
                    4370945.028030276,
                    4308045.025812507,
                    5564569.961306095,
                    4961704.990653396,
                    5185973.016171336,
                    5567029.02941072,
                    5171166.974964738,
                    5570080.013681769,
                    5224485.953377843,
                ],
                backgroundColor: [
                    'rgba(243, 91, 4,0.2)'
                ],
                borderColor: [
                    'rgb(243, 91, 4)'
                ],
                borderWidth: 1
            },
            {
                label: '2022',
                data: [4725850.003699422,
                    4572608.017165661,
                    5958092.962977171,
                    5297099.045166492,
                    6338676.038053751,
                    6334240.001603723,
                    5055774.0,
                    5733423.0,
                    5919893.0,
                    5307961.0,
                    5831964.0,
                    5277038.0,
                ],
                backgroundColor: [
                    'rgba(17, 138, 178,0.2)'
                ],
                borderColor: [
                    'rgb(17, 138, 178)'
                ],
                borderWidth: 1
            },
            {
                label: '2023',
                data: [5323608.0,
                    4883085.0,
                    5748251.999999999,
                    5360716.0,
                    5346386.0,
                    6032384.0,
                    5466032.0,
                    6036340.0,
                    5579913.999999999,
                    5801354.0
                ],
                backgroundColor: [
                    'rgba(239, 71, 111,0.2)'
                ],
                borderColor: [
                    'rgb(239, 71, 111)'
                ],
                borderWidth: 1
            },
            ]
        };

        const optionsNumTripsWeekdays = {
            scales: {
                x: {
                    ticks: {
                        //autoSkip: true,
                        //maxTicksLimit: 20,
                        display: true
                    },
                    grid: {
                        display: false
                    }
                },
            },
            plugins: {
                legend: {
                    position: 'bottom',
                },
            },
            maintainAspectRatio: false
        };

        const trafficPattern = {
            scales: {
                x: {
                    ticks: {
                        //autoSkip: true,
                        //maxTicksLimit: 20,
                        display: true
                    },
                    grid: {
                        display: false
                    }
                },
            },
            plugins: {
                legend: {
                    position: 'bottom',
                },
            },
            maintainAspectRatio: false
        };

        const kmPerDay = {
            scales: {
                x: {
                    ticks: {
                        //autoSkip: true,
                        //maxTicksLimit: 20,
                        display: true
                    },
                    grid: {
                        display: false
                    }
                },
            },
            plugins: {
                legend: {
                    position: 'bottom',
                },
                title: {
                    display: true,
                    text: 'Km per Month'
                }
            },
            maintainAspectRatio: false
        };

        const numTripsPerDay = {
            scales: {
                x: {
                    ticks: {
                        //autoSkip: true,
                        //maxTicksLimit: 20,
                        display: true
                    },
                    grid: {
                        display: false
                    }
                },
            },
            plugins: {
                legend: {
                    position: 'bottom',
                },
                title: {
                    display: true,
                    text: 'Number of Trips per Month'
                }
            },
            maintainAspectRatio: false
        };

        const selectDate = {
            menuList: styles => {
                return {
                    ...styles,
                    maxHeight: 150,
                    borderWidth: 0,
                    boxShadow: '0 0 10px 2px rgb(0 0 0 / 10%)',
                };
            }
        };

        const dataBar_CO2 = {
            labels: respCO2['date'],
            datasets: [{
                label: 'Tons of CO2 Emitted by Cars',
                data: respCO2['tons_of_co2_car_pkm'],
                backgroundColor: [
                    'rgba(227,42,141,0.2)'
                ],
                borderColor: [
                    'rgb(227,42,141)'
                ],
                borderWidth: 1,
                fill: true,
                tension: 0.4,
                pointRadius: 1

            },
            {
                label: 'Tons of CO2 Emitted by Trains',
                data: respCO2['tons_of_co2_train_pkm'],
                backgroundColor: [
                    'rgba(31, 43, 177,0.2)'
                ],
                borderColor: [
                    'rgb(31, 43, 177)'
                ],
                borderWidth: 1,
                fill: true,
                tension: 0.4,
                pointRadius: 1

            },
            ]
        };

        const CO2_options = {
            scales: {
                x: {
                    ticks: {
                        //autoSkip: true,
                        //maxTicksLimit: 20,
                        display: true
                    },
                    grid: {
                        display: false
                    }
                },
            },
            plugins: {
                legend: {
                    position: 'bottom',
                },
                title: {
                    display: true,
                    text: 'Tons of CO2 Emitted per Month'
                }
            },
        };



        const handleSortColumn = (sortColumn, sortType) => {
            this.setState({ loading: true, sortColumn: sortColumn, sortType: sortType });
            this.setState({ loading: false });

            console.log(sortColumn, sortType);
            let data = this.state.dataList;
            if (sortColumn && sortType) {
                return data.sort((a, b) => {
                    let x = a[sortColumn];
                    let y = b[sortColumn];
                    if (typeof x === 'string') {
                        x = x.charCodeAt();
                    }
                    if (typeof y === 'string') {
                        y = y.charCodeAt();
                    }
                    if (sortType === 'asc') {
                        return x - y;
                    } else {
                        return y - x;
                    }
                });
            }
            this.setState({ dataList: data })
        };

        return (
            <div style={{ backgroundColor: '#e5b3b7' }}>
                <div className="initial-section-resume" style={{ height: '40px', paddingBottom: '2%', paddingTop: '2%', paddingLef: '5%', marginLeft: '2%', marginRight: '2%' , boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}>
                    <div className="column" style={{ float: 'left', width: '60%', textAlign: 'left' }}>
                        <h1 style={{ color: '#52171d' }}>{this.state.name_place} <span style={{ fontWeight: '400' }}>Mobility Emissions Dashboard</span></h1>
                    </div>
                </div>
                <div className="header-map" style={{ marginLeft: '2%', marginRight: '2%', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}>
                    <div ref={el => this.mapContainer = el} style={{ width: '100%', height: '30vh' }}>
                    </div>
                </div>
                <div className="initial-section-resume" style={{ height: '40vh', borderRadius: '0px 0px 30px 30px', marginLeft: '2%', marginRight: '2%', marginBottom: '3%', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}>

                    <div className="row" style={{ marginBottom: '2%', height: '10vh' }}>

                        <div className="column" style={{ float: 'right', width: '100%', textAlign: 'left' }}>
                            <div className="row">
                                <div className="column" style={{ float: 'left', width: '30%', textAlign: 'left' }}>
                                    <Select className='categories' placeholder="Traffic Aggregation" isClearable={false} styles={selectDate} options={this.state.options_traffic_aggregation_} onChange={this.handleTrafficAggregation.bind(this)} value={this.state.current_traffic_aggregation} />
                                </div>
                                <div className="column" style={{ float: 'left', width: '20%', paddingLeft: '2%', textAlign: 'left' }}>
                                    {!(this.state.selectMonth) ?
                                        <DatePicker
                                            containerClassName="custom-container"
                                            onlyMonthPicker
                                            format="MM/YYYY"
                                            className="teal"
                                            render={<InputIcon />}
                                            value={this.state.current_year_month}
                                            minDate={new Date("2019-04")}
                                            maxDate={subtractMonth(new Date())}
                                            onChange={this.handleMonthYear.bind(this)}
                                            highlightToday={false}
                                        />
                                        :

                                        <DatePicker
                                            onlyYearPicker
                                            containerClassName="custom-container"
                                            //value={'2019'}
                                            format='2022'
                                            className="teal"
                                            render={<InputIcon />}
                                            minDate={new Date("2019-01-01")}
                                            value={this.state.current_year}
                                            maxDate={subtractMonth(new Date())}
                                            onChange={this.handleYear.bind(this)}
                                            highlightToday={false}
                                        />
                                    }

                                </div>
                                <div className="column" style={{ float: 'left', width: '5%', paddingLeft: '1%' }}>
                                    <Form onClick={this.changeToMonth.bind(this)}>
                                        <Button variant="info" className="text-only-btn" disabled={!(this.state.selectMonth)}>Month</Button>
                                    </Form>
                                </div>
                                <div className="column" style={{ float: 'left', width: '5%', paddingRight: '20%' }}>
                                    <Form onClick={this.changeToYear.bind(this)}>
                                        <Button variant="info" className="text-only-btn" disabled={this.state.selectMonth}>Year</Button>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{ textAlign: 'left' }}>
                        {/* <div className="column" style={{ float: 'left', width: '30%', paddingLeft: '2%', paddingRight: '1.5%', textAlign: 'left' }}>
                            <div className="row" style={{ paddingBottom: '5%' }}>
                                <div className="column" style={{ float: 'left', width: '15%', textAlign: 'left' }}>
                                    <IconContext.Provider value={{ color: "#545454", size: '3em' }}>
                                        <BiTrip />
                                    </IconContext.Provider>
                                </div>
                                <div className="column" style={{ float: 'left', width: '85%', textAlign: 'left' }}>
                                    <h2 style={{ paddingTop: '5px' }}>  Passenger Kilometers</h2>
                                    <p className="label-small" style={{ paddingBottom: '5%' }}>
                                        {
                                            !(this.state.selectMonth) ?
                                                "(" + this.state.month_labels[this.state.current_year_month.getMonth()] + ", " + this.state.current_year_month.getFullYear() + " Aggreation)"
                                                :
                                                "(Yearly Aggreation " + this.state.current_year.getFullYear() + ")"
                                        }
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <h1 className="number" style={{ fontSize: '60px' }}>{this.state.pkm_total}</h1>
                                <h3>Kilometers traveled daily</h3>
                            </div>
                            <div className="row" style={{ paddingTop: '5%', paddingBottom: '5%' }}>
                                {this.state.pkm_change_percentage.toString().includes('-') ?
                                    <h3 style={{ color: '#02800f' }}>
                                        {this.state.pkm_change_percentage}% than last
                                        {
                                            !(this.state.selectMonth) ?
                                                " month"
                                                :
                                                " year"
                                        }
                                    </h3>
                                    : this.state.pkm_change_percentage === 0
                                        ? <h3 style={{ color: '#800202' }}>
                                            {this.state.pkm_change_percentage}% than last
                                            {
                                                !(this.state.selectMonth) ?
                                                    " month"
                                                    :
                                                    " year"
                                            }</h3>
                                        : <h3 style={{ color: '#800202' }}>
                                            {'+' + this.state.pkm_change_percentage}% than last
                                            {
                                                !(this.state.selectMonth) ?
                                                    " month"
                                                    :
                                                    " year"
                                            }</h3>
                                }
                            </div>
                            <hr />
                            <div className="row">
                                <div className="column" style={{ float: 'left', width: '10%', textAlign: 'left' }}>
                                    <IconContext.Provider value={{ color: "#545454", size: '2em' }}>
                                        <FaCar />
                                    </IconContext.Provider>
                                </div>
                                <div className="column" style={{ float: 'left', width: '90%', textAlign: 'left' }}>
                                    <h3 style={{ fontWeight: '500' }}>{this.state.trips_zurich_munich} trips Zurich - Munich</h3>
                                </div>
                            </div>
                        </div>
                        <div className="column" style={{ float: 'left', width: '30%', paddingLeft: '1.5%', paddingRight: '1.5%', textAlign: 'left' }}>
                            <div className="row" style={{ paddingBottom: '5%' }}>
                                <div className="column" style={{ float: 'left', width: '15%', textAlign: 'left' }}>
                                    <IconContext.Provider value={{ color: "#545454", size: '3em' }}>
                                        <MdCo2 />
                                    </IconContext.Provider>
                                </div>
                                <div className="column" style={{ float: 'left', width: '85%', textAlign: 'left' }}>
                                    <h2 style={{ paddingTop: '5px' }}>  Emissions</h2>
                                    <p className="label-small" style={{ paddingBottom: '5%' }}>
                                        {
                                            !(this.state.selectMonth) ?
                                                "(" + this.state.month_labels[this.state.current_year_month.getMonth()] + ", " + this.state.current_year_month.getFullYear() + " Aggreation)"
                                                :
                                                "(Yearly Aggreation " + this.state.current_year.getFullYear() + ")"
                                        }
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <h1 className="number" style={{ fontSize: '60px' }}>{this.state.emission_total}</h1>
                                <h3>Tons CO<sub>2</sub> emitted daily</h3>
                            </div>
                            <div className="row" style={{ paddingTop: '5%', paddingBottom: '5%' }}>
                                {this.state.emission_change_percentage.toString().includes('-') ?
                                    <h3 style={{ color: '#02800f' }}>
                                        {this.state.emission_change_percentage}% than last
                                        {
                                            !(this.state.selectMonth) ?
                                                " month"
                                                :
                                                " year"
                                        }</h3>
                                    : this.state.emission_change_percentage === 0
                                        ? <h3 style={{ color: '#800202' }}>
                                            {this.state.emission_change_percentage}% than last
                                            {
                                                !(this.state.selectMonth) ?
                                                    " month"
                                                    :
                                                    " year"
                                            }</h3>
                                        : <h3 style={{ color: '#800202' }}>
                                            {'+' + this.state.emission_change_percentage}% than last
                                            {
                                                !(this.state.selectMonth) ?
                                                    " month"
                                                    :
                                                    " year"
                                            }</h3>
                                }
                            </div>
                            <hr />
                            <div className="row">
                                <div className="column" style={{ float: 'left', width: '10%', textAlign: 'left' }}>
                                    <IconContext.Provider value={{ color: "#545454", size: '2em' }}>
                                        <MdFlightTakeoff />
                                    </IconContext.Provider>
                                </div>
                                <div className="column" style={{ float: 'left', width: '90%', textAlign: 'left' }}>
                                    <h3 style={{ fontWeight: '500' }}>{this.state.fligths_mallorca} Flights to Mallorca</h3>
                                </div>
                            </div>
                        </div>
                        <div className="column" style={{ float: 'left', width: '30%', paddingLeft: '1.5%', paddingRight: '2%', textAlign: 'left' }}>
                            <div className="row" style={{ paddingBottom: '5%' }}>
                                <div className="column" style={{ float: 'left', width: '15%', textAlign: 'left' }}>
                                    <IconContext.Provider value={{ color: "#545454", size: '3em' }}>
                                        <IoBusSharp />
                                    </IconContext.Provider>
                                </div>
                                <div className="column" style={{ float: 'left', width: '85%', textAlign: 'left' }}>
                                    <h2 style={{ paddingTop: '5px' }}>  Potential</h2>
                                    <p className="label-small" style={{ paddingBottom: '5%' }}>
                                        {
                                            !(this.state.selectMonth) ?
                                                "(" + this.state.month_labels[this.state.current_year_month.getMonth()] + ", " + this.state.current_year_month.getFullYear() + " Aggreation)"
                                                :
                                                "(Yearly Aggreation " + this.state.current_year.getFullYear() + ")"
                                        }
                                    </p>
                                </div>
                            </div>
                            <div className="row" style={{ paddingBottom: '78px' }}>
                                <h1 className="number" style={{ fontSize: '60px' }}>{this.state.shift_to_public_transport}</h1>
                                <h3>trips that can be shift to ÖV daily</h3>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="column" style={{ float: 'left', width: '10%', textAlign: 'left' }}>
                                    <IconContext.Provider value={{ color: "#545454", size: '2em' }}>
                                        <BiSolidTraffic />
                                    </IconContext.Provider>
                                </div>
                                <div className="column" style={{ float: 'left', width: '90%', textAlign: 'left' }}>
                                    <h3 style={{ fontWeight: '500' }}>{this.state.potential_cars_reduce_in_city} cars reduce in the city</h3>
                                </div>
                            </div>
                        </div> */}
                        <div className="column" style={{ float: 'left', width: '30%', paddingLeft: '2%', paddingRight: '1.5%', textAlign: 'left' }}>
                            <div className="row" style={{ paddingBottom: '5%' }}>
                                <div className="column" style={{ float: 'left', width: '15%', textAlign: 'left' }}>
                                    <IconContext.Provider value={{ color: "#545454", size: '3em' }}>
                                        <BiTrip />
                                    </IconContext.Provider>
                                </div>
                                <div className="column" style={{ float: 'left', width: '85%', textAlign: 'left' }}>
                                    <h2 style={{ paddingTop: '5px' }}>  Passenger Kilometers</h2>
                                    <p className="label-small" style={{ paddingBottom: '5%' }}>
                                        {
                                            !(this.state.selectMonth) ?
                                                "(" + this.state.month_labels[this.state.current_year_month.getMonth()] + ", " + this.state.current_year_month.getFullYear() + " Aggreation)"
                                                :
                                                "(Yearly Aggreation " + this.state.current_year.getFullYear() + ")"
                                        }
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <h1 className="number" style={{ fontSize: '60px' }}>{this.state.pkm_total}</h1>
                                <h3>Kilometers traveled daily</h3>
                            </div>
                            <div className="row" style={{ paddingTop: '5%', paddingBottom: '5%' }}>
                                {this.state.pkm_change_percentage.toString().includes('-') ?
                                    <h3 style={{ color: '#02800f' }}>
                                        {this.state.pkm_change_percentage}% than last
                                        {
                                            !(this.state.selectMonth) ?
                                                " month"
                                                :
                                                " year"
                                        }
                                    </h3>
                                    : this.state.pkm_change_percentage === 0
                                        ? <h3 style={{ color: '#800202' }}>
                                            {this.state.pkm_change_percentage}% than last
                                            {
                                                !(this.state.selectMonth) ?
                                                    " month"
                                                    :
                                                    " year"
                                            }</h3>
                                        : <h3 style={{ color: '#800202' }}>
                                            {'+' + this.state.pkm_change_percentage}% than last
                                            {
                                                !(this.state.selectMonth) ?
                                                    " month"
                                                    :
                                                    " year"
                                            }</h3>
                                }
                            </div>
                            <hr />
                            <div className="row">
                                <div className="column" style={{ float: 'left', width: '10%', textAlign: 'left' }}>
                                    <IconContext.Provider value={{ color: "#545454", size: '2em' }}>
                                        <FaCar />
                                    </IconContext.Provider>
                                </div>
                                <div className="column" style={{ float: 'left', width: '90%', textAlign: 'left' }}>
                                    <h3 style={{ fontWeight: '500' }}>{this.state.trips_zurich_munich} trips Zurich - Munich</h3>
                                </div>
                            </div>
                        </div>
                        <div className="column" style={{ float: 'left', width: '30%', paddingLeft: '1.5%', paddingRight: '1.5%', textAlign: 'left' }}>
                            <div className="row" style={{ paddingBottom: '5%' }}>
                                <div className="column" style={{ float: 'left', width: '15%', textAlign: 'left' }}>
                                    <IconContext.Provider value={{ color: "#545454", size: '3em' }}>
                                        <MdCo2 />
                                    </IconContext.Provider>
                                </div>
                                <div className="column" style={{ float: 'left', width: '85%', textAlign: 'left' }}>
                                    <h2 style={{ paddingTop: '5px' }}>  Emissions</h2>
                                    <p className="label-small" style={{ paddingBottom: '5%' }}>
                                        {
                                            !(this.state.selectMonth) ?
                                                "(" + this.state.month_labels[this.state.current_year_month.getMonth()] + ", " + this.state.current_year_month.getFullYear() + " Aggreation)"
                                                :
                                                "(Yearly Aggreation " + this.state.current_year.getFullYear() + ")"
                                        }
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <h1 className="number" style={{ fontSize: '60px' }}>{this.state.emission_total}</h1>
                                <h3>Tons CO<sub>2</sub> emitted daily</h3>
                            </div>
                            <div className="row" style={{ paddingTop: '5%', paddingBottom: '5%' }}>
                                {this.state.emission_change_percentage.toString().includes('-') ?
                                    <h3 style={{ color: '#02800f' }}>
                                        {this.state.emission_change_percentage}% than last
                                        {
                                            !(this.state.selectMonth) ?
                                                " month"
                                                :
                                                " year"
                                        }</h3>
                                    : this.state.emission_change_percentage === 0
                                        ? <h3 style={{ color: '#800202' }}>
                                            {this.state.emission_change_percentage}% than last
                                            {
                                                !(this.state.selectMonth) ?
                                                    " month"
                                                    :
                                                    " year"
                                            }</h3>
                                        : <h3 style={{ color: '#800202' }}>
                                            {'+' + this.state.emission_change_percentage}% than last
                                            {
                                                !(this.state.selectMonth) ?
                                                    " month"
                                                    :
                                                    " year"
                                            }</h3>
                                }
                            </div>
                            <hr />
                            <div className="row">
                                <div className="column" style={{ float: 'left', width: '10%', textAlign: 'left' }}>
                                    <IconContext.Provider value={{ color: "#545454", size: '2em' }}>
                                        <MdFlightTakeoff />
                                    </IconContext.Provider>
                                </div>
                                <div className="column" style={{ float: 'left', width: '90%', textAlign: 'left' }}>
                                    <h3 style={{ fontWeight: '500' }}>{this.state.fligths_mallorca} Flights to Mallorca</h3>
                                </div>
                            </div>
                        </div>
                        <div className="column" style={{ float: 'left', width: '30%', paddingLeft: '1.5%', paddingRight: '2%', textAlign: 'left' }}>
                            <div className="row" style={{ paddingBottom: '5%' }}>
                                <div className="column" style={{ float: 'left', width: '15%', textAlign: 'left' }}>
                                    <IconContext.Provider value={{ color: "#545454", size: '3em' }}>
                                        <IoBusSharp />
                                    </IconContext.Provider>
                                </div>
                                <div className="column" style={{ float: 'left', width: '85%', textAlign: 'left' }}>
                                    <h2 style={{ paddingTop: '5px' }}>  Potential</h2>
                                    <p className="label-small" style={{ paddingBottom: '5%' }}>
                                        {
                                            !(this.state.selectMonth) ?
                                                "(" + this.state.month_labels[this.state.current_year_month.getMonth()] + ", " + this.state.current_year_month.getFullYear() + " Aggreation)"
                                                :
                                                "(Yearly Aggreation " + this.state.current_year.getFullYear() + ")"
                                        }
                                    </p>
                                </div>
                            </div>
                            <div className="row" style={{ paddingBottom: '78px' }}>
                                <h1 className="number" style={{ fontSize: '60px' }}>{this.state.shift_to_public_transport}</h1>
                                <h3>trips that can be shift to ÖV daily</h3>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="column" style={{ float: 'left', width: '10%', textAlign: 'left' }}>
                                    <IconContext.Provider value={{ color: "#545454", size: '2em' }}>
                                        <BiSolidTraffic />
                                    </IconContext.Provider>
                                </div>
                                <div className="column" style={{ float: 'left', width: '90%', textAlign: 'left' }}>
                                    <h3 style={{ fontWeight: '500' }}>{this.state.potential_cars_reduce_in_city} cars reduce in the city</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-mobility-patterns" style={{ height: '600px', marginBottom: '3%',  marginLeft: '2%', marginRight: '2%',borderRadius: '30px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}>
                    <div className="row">
                        <div className="column" style={{ float: 'left', width: '100%', textAlign: 'left' }}>
                            <div className="row" style={{ textAlign: 'left', paddingBottom: '1%' }}>
                                <h2>Catchment Area Traffic</h2>
                                <h3>Car trips to {this.state.name_place}</h3>
                                <h3>  {
                                    !(this.state.selectMonth) ?
                                        "Based on " + this.state.month_labels[this.state.current_year_month.getMonth()] + ", " + this.state.current_year_month.getFullYear()
                                        :
                                        "Based on the year " + this.state.current_year.getFullYear()
                                }
                                </h3>
                            </div>
                            <div className="column" style={{ float: 'left', width: '33%', textAlign: 'center', height: '100px' }}>
                                <h1>{numberWithCommas(this.state.total_co2_emissions.toFixed(0))} Tons</h1>
                                <h3>Total CO<sub>2</sub> emissions</h3>
                            </div>

                            <div className="column" style={{ float: 'left', width: '33%', textAlign: 'center', height: '100px' }}>
                                <h1>{numberWithCommas(this.state.car_co2_emissions.toFixed(0))} Tons</h1>
                                <h3>Car CO<sub>2</sub> emissions</h3>
                            </div>

                            <div className="column" style={{ float: 'left', width: '33%', textAlign: 'center', height: '100px' }}>
                                <h1>{numberWithCommas(this.state.train_co2_emissions.toFixed(0))} Tons</h1>
                                <h3>Trains CO<sub>2</sub> emissions</h3>
                            </div>
                        </div>
                    </div>

                    <div className="row">

                        <div className="column" style={{ float: 'left', width: '60%', textAlign: 'left', height: '400px' }}>
                            <><Table
                                loading={this.state.loading}
                                defaultExpandAllRows={false}
                                rowKey="origin"
                                autoHeight={false}
                                height={320}
                                fillHeight={true}
                                data={this.state.main_contributors}
                                sortColumn={this.state.sortColumn}
                                sortType={this.state.sortType}
                                onSortColumn={handleSortColumn}
                            >
                                <Column flexGrow={1} minWidth={30} fullText>
                                    <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}>Origin</HeaderCell>
                                    <Cell style={{ fontSize: '12px' }} dataKey="origin_name">
                                        {(rowData) => {
                                            return <>{rowData.origin_name}</>
                                        }}
                                    </Cell>
                                </Column>

                                <Column flexGrow={1} minWidth={30} fullText sortable>
                                    <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}>Destination</HeaderCell>
                                    <Cell style={{ fontSize: '12px' }} dataKey="destination_name">
                                        {(rowData) => {
                                            return <>{rowData.destination_name}</>
                                        }}
                                    </Cell>
                                </Column>

                                <Column flexGrow={1.5} minWidth={150} sortable>
                                    <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}>Total Trips (Weekdays)</HeaderCell>
                                    <Cell style={{ fontSize: '12px' }} dataKey="total_trips_weekdays">
                                        {(rowData) => {
                                            return <>{numberWithCommas(rowData.total_trips_weekdays.toFixed(0))}</>
                                        }}
                                    </Cell>
                                </Column>

                                <Column flexGrow={1} minWidth={30} sortable>
                                    <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}>Total PKm</HeaderCell>
                                    <Cell style={{ fontSize: '12px' }} dataKey="total_pkm">
                                        {(rowData) => {
                                            return <>{numberWithCommas(rowData.total_pkm.toFixed(0))}</>
                                        }}
                                    </Cell>
                                </Column>

                                <Column flexGrow={1} minWidth={30} sortable>
                                    <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}>Car PKm</HeaderCell>
                                    <Cell style={{ fontSize: '12px' }} dataKey="car_pkm">
                                        {(rowData) => {
                                            return <>{numberWithCommas(rowData.car_pkm.toFixed(0))}</>
                                        }}
                                    </Cell>
                                </Column>

                                <Column flexGrow={1} minWidth={30} sortable>
                                    <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}>Train PKm</HeaderCell>
                                    <Cell style={{ fontSize: '12px' }} dataKey="train_pkm">
                                        {(rowData) => {
                                            return <>{numberWithCommas(rowData.train_pkm.toFixed(0))}</>
                                        }}
                                    </Cell>
                                </Column>

                                <Column flexGrow={1} minWidth={30} sortable>
                                    <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}>CO<sub>2</sub> Emissions</HeaderCell>
                                    <Cell style={{ fontSize: '12px' }} dataKey="co2_emissions">
                                        {(rowData) => {
                                            return <>{numberWithCommas(rowData.co2_emissions.toFixed(0))}</>
                                        }}
                                    </Cell>
                                </Column>
                            </Table>
                            </>

                        </div>
                        <div className="column" style={{ float: 'left', width: '40%', textAlign: 'left' }}>
                            <div className="column" style={{ float: 'left', width: '100%', textAlign: 'left', height: '400px' }}>
                                <div ref={el => this.mapContainerCatchmentAreas = el} style={{ width: '100%', height: '100%' }}>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{ paddingTop: '1%' }}>
                        <div className="column" style={{ float: 'left', width: '30%', textAlign: 'left' }}>

                        </div>

                        <div ref={el => this.mapContainerCo2 = el} style={{ width: '100%', height: '100%' }}>
                        </div>
                    </div>
                </div>
                <div className="section-mobility-patterns" style={{marginBottom: '3%',  marginLeft: '2%', marginRight: '2%',borderRadius: '30px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}>
                    <div className="row">
                        <div className="column" style={{ float: 'left', width: '30%', textAlign: 'left' }}>
                            <div className="row" style={{ textAlign: 'left', paddingBottom: '1%' }}>
                                <h2>{this.state.name_place} City</h2>
                                <h1 style={{marginBottom: '20px', fontSize: '50px'}}>Mobility Emissions</h1>
                            </div>
                            <div style={{marginBottom: '20px'}}>
                            <h1 style={{fontSize: '30px'}}>{numberWithCommas(this.state.total_co2_emissions.toFixed(0))} t</h1>
                            <h3>Total CO<sub>2</sub> emissions</h3>
                            </div>
                            <div style={{marginBottom: '20px'}}>
                            <h1 style={{fontSize: '30px'}}>{((this.state.car_co2_emissions/this.state.total_co2_emissions)*100).toFixed(0)}%</h1>
                            <h3>Car Traffic Contribution</h3>
                            <p className="label" style={{fontSize: '20px'}}>{((this.state.emissions_by_traffic.toFixed(0)/this.state.total_co2_emissions)*100).toFixed(0)}%
                            of car emission are caused<br/> by idling cars</p>

                            </div>
                            <div style={{marginBottom: '20px'}}>
                            <h1 style={{fontSize: '30px'}}>{((this.state.train_co2_emissions.toFixed(0)/this.state.total_co2_emissions)*100).toFixed(0)}%</h1>
                            <h3>Trains Traffic Contribution</h3>
                            </div>
                        </div>
                        <div className="column" style={{ float: 'left', width: '70%', textAlign: 'left', height: '400px' }}>
                            <div ref={el => this.mapContainerTraffic = el} style={{ width: '100%', height: '50vh' }} />
                        </div>
                    </div>
                    <div className="row" style={{ paddingTop: '1%' }}>
                        <div className="column" style={{ float: 'left', width: '30%', textAlign: 'left' }}>

                        </div>

                        <div ref={el => this.mapContainerCo2 = el} style={{ width: '100%', height: '100%' }}>
                        </div>
                    </div>
                </div>
                <div className="section-mobility-patterns" style={{marginBottom: '3%',  marginLeft: '2%', marginRight: '2%',borderRadius: '30px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}>
                    <div className="row" style={{ textAlign: 'left', paddingBottom: '1%' }}>
                        <h2>Traffic Index</h2>
                        <h3>Based on October 2022 Data</h3>
                    </div>
                    <div className="row" style={{ height: '400px' }}>
                        <Line data={dataBar_TrafficPattern} options={trafficPattern} />
                    </div>
                </div>
                <div className="row" style={{ paddingLeft: '2%', paddingRight: '2%', height: '650px' }}>
                    <div className="section-mobility-patterns" style={{ float: 'left', width: '50%', marginRight: '1%', borderRadius: '30px', padding: '3%', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}>
                        <div className="row" style={{ textAlign: 'left', paddingBottom: '1%' }}>
                            <h2>Number of Trips (Weekdays)</h2>
                        </div>
                        <div className="row" style={{ height: '480px' }}>
                            <Bar data={dataNumTripsWeekdays} options={optionsNumTripsWeekdays} />
                        </div>
                    </div>
                    <div className="section-mobility-patterns" style={{ float: 'right', width: '36%', borderRadius: '30px', padding: '3%', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}>
                        <div className="row" style={{ textAlign: 'left', paddingBottom: '1%' }}>
                            <h2>Modal Split Distribution</h2>
                        </div>
                        <div className="row" style={{ height: '480px' }}>
                            <Pie data={dataPieMS} options={{ maintainAspectRatio: false }}/>
                        </div>
                    </div>
                </div>

                <div className="section-mobility-patterns" style={{marginBottom: '3%',  marginLeft: '2%', marginRight: '2%',borderRadius: '30px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}>
                    <div className="row" style={{ textAlign: 'left', paddingBottom: '1%' }}>
                        <h2>Mobility Patterns by Year</h2>
                    </div>
                    <div className="row">
                        <div className="column" style={{ float: 'left', width: '20%', textAlign: 'left' }}>
                            <Form onClick={this.changeToTrips.bind(this)}>
                                <Button className="sub-sections-btn" disabled={(this.state.current_mobility_pattern === 'trips')}>Number of Trips</Button>
                            </Form>
                            <Form onClick={this.changeToPKm.bind(this)}>
                                <Button className="sub-sections-btn" disabled={(this.state.current_mobility_pattern === 'pkm')}>Passenger Km</Button>
                            </Form>
                            <Form onClick={this.changeToCo2.bind(this)}>
                                <Button className="sub-sections-btn" disabled={(this.state.current_mobility_pattern === 'co2')}>CO2 Emissions</Button>
                            </Form>
                        </div>
                        <div className="column" style={{ float: 'left', width: '80%', textAlign: 'left', height: '400px' }}>
                            {
                                current_mobility_pattern === 'trips' ?
                                    <Line data={dataBar_WvsW} options={numTripsPerDay} />
                                    :
                                    <>
                                        {
                                            current_mobility_pattern === 'pkm' ?
                                                <Line data={dataBar_TraveledKm} options={kmPerDay} />
                                                :
                                                <Line data={dataBar_CO2} options={CO2_options} />
                                        }
                                    </>
                            }

                        </div>
                    </div>
                </div>

                {/* <div className="section-mobility-patterns" style={{marginBottom: '3%',  marginLeft: '2%', marginRight: '2%',borderRadius: '30px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}>
                    <div className="row" style={{ textAlign: 'left', paddingBottom: '1%' }}>
                        <h2>Companies' Potential in Winterthur</h2>
                    </div>
                    <div className="row">
                    <img src={require('../../assets/potential.png')} style={{width: '80%'}}alt="Logo ÖV42 by 42hacks"/>

                    </div>
                </div> */}

                <div className="section-mobility-patterns" style={{ height: '1300px', marginLeft: '2%', marginRight: '2%', borderRadius: '30px 30px 0px 0px',  boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'  }}>
                    <div className="row" style={{ paddingBottom: '1%', height: '100px' }}>
                        
                        <div className="column" style={{ float: 'left', width: '30%', textAlign: 'left'}}>
                            <h2>Top 15 MIV Hotspots Potential</h2>
                            <h3>Based on October 2023 Data</h3>
                        </div>
                        <div className="column" style={{ float: 'right', width: '70%', textAlign: 'right' }}>
                            <Link to={'/co2_insights/5-in-5/{0}'.format(this.props.params['*'])} target="_blank" style={{ textDecoration: 'none' }}>
                                <Button variant="info" className="submit-5in5-btn" style={{ fontSize: '13px' }}>MIV Potential View</Button>
                            </Link>

                        </div>
                    </div>
                    <div className="row" style={{marginBottom: '10px'}}>
                        <Table
                            loading={this.state.loading_5in5}
                            isTree
                            defaultExpandAllRows={false}
                            rowKey="id"
                            autoHeight={false}
                            height={750}
                            fillHeight={true}
                            data={this.state.dataList}
                        >
                            <Column flexGrow={1} minWidth={80}>
                                <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}>#</HeaderCell>
                                <Cell style={{ fontSize: '12px' }}>
                                    {(rowData) => {
                                        return <>{"position" in rowData ? rowData.position + 1 : ''}</>
                                    }}
                                </Cell>
                            </Column>

                            <Column flexGrow={2.2} minWidth={150} fullText>
                                <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}>Place<br />
                                    <span style={{ fontSize: '10px' }}>PLZ Code</span></HeaderCell>
                                <Cell dataKey="place" style={{ fontSize: '12px' }}>
                                    {rowData => {
                                        return <Button style={{
                                            background: 'none',
                                            color: 'inherit',
                                            border: 'none',
                                            padding: 0,
                                            font: 'inherit',
                                            cursor: 'pointer',
                                            outline: 'inherit',
                                        }} onClick={() => {
                                            window.open(URL_FRONT + 'tile/' + rowData.tileId, "_blank", "noreferrer");
                                        }}>{rowData.place === null ? '-' : rowData.place}
                                            <br />
                                            <span style={{ fontSize: '10px' }}>{rowData.PLZ}</span></Button>
                                    }
                                    }
                                </Cell>
                            </Column>

                            <Column flexGrow={1.8} minWidth={100}>
                                <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}>Employees</HeaderCell>
                                <Cell style={{ fontSize: '12px' }}>
                                    {(rowData) => {
                                        return <>{rowData.measurements === 'all' ? numberWithCommas(rowData.employees?.toFixed(0)) : ''}</>;
                                    }}
                                </Cell>
                            </Column>

                            {!this.state.switch_state ? <Column flexGrow={1.8} minWidth={100}>
                                <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}>Companies</HeaderCell>
                                <Cell style={{ fontSize: '12px' }}>
                                    {(rowData) => {
                                        return <>{rowData.measurements === 'all' ? numberWithCommas(rowData.n_companies?.toFixed(0)) : ''}</>;
                                    }}
                                </Cell>
                            </Column> : <></>}

                            <Column flexGrow={1.5} minWidth={150}>
                                <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}>PKm<br />
                                    <span style={{ fontSize: '10px' }}>Daily Traffic</span></HeaderCell>
                                <Cell style={{ fontSize: '12px' }}>
                                    {(rowData) => {
                                        if (rowData.measurements === 'all') {
                                            return <>
                                                {numberWithCommas(rowData.metric?.toFixed(0))}
                                                <br />
                                                <span style={{ fontSize: '10px', fontWeight: 600 }}>Cum.: {numberWithCommas(rowData.metric_cum.toFixed(0))}</span>
                                            </>
                                        } else {
                                            return <></>
                                        }
                                    }}
                                </Cell>
                            </Column>

                            <Column flexGrow={1.5} minWidth={150}>
                                <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}>PKm Pct. (%)<br />
                                    <span style={{ fontSize: '10px' }}>Daily Traffic</span></HeaderCell>
                                <Cell style={{ fontSize: '12px' }}>
                                    {(rowData) => {
                                        if (rowData.measurements === 'all') {
                                            return <>
                                                {(rowData.metric_pctg * 100).toFixed(2)}%
                                                <br />
                                                <span style={{ fontSize: '10px', fontWeight: 600 }}>Cum.: {(rowData.metric_cum_pctg * 100).toFixed(2)}%</span>
                                            </>
                                        } else {
                                            return <></>
                                        }
                                    }}
                                </Cell>
                            </Column>

                            <Column flexGrow={2.5} minWidth={130}>
                                <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}>Measures</HeaderCell>
                                <Cell style={{ fontSize: '12px' }}>
                                    {(rowData) => {
                                        if (rowData.measurements === 'all') {
                                            return <div className="dot" style={{ backgroundColor: '#0BA53D' }}><a style={{ paddingLeft: '15px' }}>All Measurements</a></div>;
                                        } else if (rowData.measurements === 'last_mile') {
                                            return <div className="dot" style={{ backgroundColor: '#FF0054' }}><a style={{ paddingLeft: '15px' }}>Last Mile</a></div>;
                                        } else if (rowData.measurements === 'bike') {
                                            return <div className="dot" style={{ backgroundColor: '#9E0059' }}><a style={{ paddingLeft: '15px' }}>Bicycle Culture</a></div>;
                                        } else if (rowData.measurements === 'behavior') {
                                            return <div className="dot" style={{ backgroundColor: '#390099' }}><a style={{ paddingLeft: '15px' }}>Behaviour Change</a></div>;
                                        } else if (rowData.measurements === 'working') {
                                            return <div className="dot" style={{ backgroundColor: '#FF5400' }}><a style={{ paddingLeft: '15px' }}>Working Policy</a></div>;
                                        } else if (rowData.measurements === 'car_pooling') {
                                            return <div className="dot" style={{ backgroundColor: '#1AB5B2' }}><a style={{ paddingLeft: '15px' }}>Car pooling</a></div>;
                                        }
                                    }}
                                </Cell>
                            </Column>

                            <Column flexGrow={2} minWidth={140}>
                                <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}>ÖV Quality<br />
                                    <span style={{ fontSize: '10px' }}>Before &#8594; After</span></HeaderCell>
                                <Cell style={{ fontSize: '12px' }}>
                                    {(rowData) => {
                                        if (rowData.measurements === 'all') {
                                            return <>
                                                {(rowData.ov_before * 100).toFixed(0)}%  &#8594; {(rowData.ov_after * 100).toFixed(0)}%
                                                <br />
                                                <ProgressBar
                                                    completed={(rowData.ov_after * 100).toFixed(0)}
                                                    maxCompleted={100}
                                                    bgColor="#0BA53D"
                                                    height="15px"
                                                    labelAlignment="outside"
                                                    isLabelVisible={false}
                                                    labelColor="#0BA53D"
                                                />
                                            </>;
                                        } else if (rowData.measurements === 'last_mile') {
                                            return <>
                                                {(rowData.ov_before * 100).toFixed(0)}%  &#8594; {(rowData.ov_after * 100).toFixed(0)}%
                                                <br /><ProgressBar
                                                    completed={(rowData.ov_after * 100).toFixed(0)}
                                                    maxCompleted={100}
                                                    bgColor="#FF0054"
                                                    height="15px"
                                                    labelAlignment="outside"
                                                    isLabelVisible={false}
                                                    labelColor="#FF0054"
                                                /></>;
                                        } else if (rowData.measurements === 'bike') {
                                            return <>
                                                {(rowData.ov_before * 100).toFixed(0)}%  &#8594; {(rowData.ov_after * 100).toFixed(0)}%
                                                <br /><ProgressBar
                                                    completed={(rowData.ov_after * 100).toFixed(0)}
                                                    maxCompleted={100}
                                                    bgColor="#9E0059"
                                                    height="15px"
                                                    labelAlignment="outside"
                                                    isLabelVisible={false}
                                                    labelColor="#9E0059"
                                                /></>;
                                        } else if (rowData.measurements === 'behavior') {
                                            return <>
                                                {(rowData.ov_before * 100).toFixed(0)}%  &#8594; {(rowData.ov_after * 100).toFixed(0)}%
                                                <br /><ProgressBar
                                                    completed={(rowData.ov_after * 100).toFixed(0)}
                                                    maxCompleted={100}
                                                    bgColor="#390099"
                                                    height="15px"
                                                    labelAlignment="outside"
                                                    isLabelVisible={false}
                                                    labelColor="#390099"
                                                /></>;
                                        } else if (rowData.measurements === 'working') {
                                            return <>
                                                {(rowData.ov_before * 100).toFixed(0)}%  &#8594; {(rowData.ov_after * 100).toFixed(0)}%
                                                <br /><ProgressBar
                                                    completed={(rowData.ov_after * 100).toFixed(0)}
                                                    maxCompleted={100}
                                                    bgColor="#FF5400"
                                                    height="15px"
                                                    labelAlignment="outside"
                                                    isLabelVisible={false}
                                                    labelColor="#FF5400"
                                                /></>;
                                        } else if (rowData.measurements === 'car_pooling') {
                                            return <>
                                                {(rowData.ov_before * 100).toFixed(0)}%  &#8594; {(rowData.ov_after * 100).toFixed(0)}%
                                                <br /><ProgressBar
                                                    completed={(rowData.ov_after * 100).toFixed(0)}
                                                    maxCompleted={100}
                                                    bgColor="#1AB5B2"
                                                    height="15px"
                                                    labelAlignment="outside"
                                                    isLabelVisible={false}
                                                    labelColor="#1AB5B2"
                                                /></>;
                                        }
                                    }}
                                </Cell>
                            </Column>

                            {/* <Column flexGrow={2} minWidth={120}>
                                    <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}>Improvement</HeaderCell>
                                    <Cell style={{ fontSize: '12px' }}>
                                        {(rowData) => {
                                            return <b style={{ color: '#027f80' }}>+{(rowData.improvement * 100).toFixed(0)}%</b>;
                                        }}
                                    </Cell>
                                </Column> */}

                            {/* <Column flexGrow={2.5} minWidth={150}>
                                    <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}>Conversion Rate</HeaderCell>
                                    <Cell style={{ fontSize: '12px' }}>
                                        {(rowData) => {
                                            return <>{(rowData.convertion_rate * 100).toFixed(0)}%</>;
                                        }}
                                    </Cell>
                                </Column> */}

                            <Column flexGrow={2} minWidth={120}>
                                <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}> Potential <br />
                                    <span style={{ fontSize: '10px' }}>Shifting PKm</span></HeaderCell>
                                <Cell style={{ fontSize: '12px' }}>
                                    {(rowData) => {
                                        return <>{numberWithCommas(rowData.potential?.toFixed(0))}</>;
                                    }}
                                </Cell>
                            </Column>

                            <Column flexGrow={2} minWidth={120}>
                                <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}>MIV Reduction </HeaderCell>
                                <Cell style={{ fontSize: '12px' }}>
                                    {(rowData) => {
                                        return <>{numberWithCommas(rowData.people_shifting.toFixed(0))} <i>({(rowData.shift_of_metrics * 100).toFixed(2)}%)</i></>;
                                    }}
                                </Cell>
                            </Column>

                            <Column flexGrow={2.5} minWidth={150}>
                                <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}>People Shifting<br />
                                    <span style={{ fontSize: '10px' }}>Shifting PKm</span></HeaderCell>
                                <Cell style={{ fontSize: '12px' }}>
                                    {(rowData) => {
                                        return <>{numberWithCommas(rowData.real_people_shifting.toFixed(0))} <i>({(rowData.shift_of_employees * 100).toFixed(2)}%)</i></>;
                                    }}
                                </Cell>
                            </Column>

                            <Column flexGrow={2} minWidth={120}>
                                <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}>Modal Split<br />
                                    <span style={{ fontSize: '10px' }}>Before &#8594; After</span></HeaderCell>
                                <Cell style={{ fontSize: '12px' }}>
                                    {(rowData) => {
                                        return <>{(rowData.ms_before * 100).toFixed(0)}% &#8594; {(rowData.ms_after * 100).toFixed(0)}%
                                            <br />
                                            <span style={{ fontSize: '10px', fontWeight: 600, color: '#027f80' }}>{(rowData.ms_improvement * 100).toFixed(0)}% Increase</span></>;
                                    }}
                                </Cell>
                            </Column>

                            <Column flexGrow={2.5} minWidth={200}>
                                <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}>Total Traffic Shift <br />
                                    <span style={{ fontSize: '10px' }}>Individual in the municipality</span></HeaderCell>
                                <Cell style={{ fontSize: '12px' }}>
                                    {(rowData) => {
                                        if ("position" in rowData) {
                                            return <>{(rowData.swiss_level_shift * 1000).toFixed(2)} &#8240;</>
                                        } else {
                                            return <></>
                                        }
                                    }}
                                </Cell>
                            </Column>

                            <Column flexGrow={2.5} minWidth={200}>
                                <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}>MIV Traffic Shift <br />
                                    <span style={{ fontSize: '10px' }}>Cumulative in the municipality</span></HeaderCell>
                                <Cell style={{ fontSize: '12px' }}>
                                    {(rowData) => {
                                        if ("position" in rowData) {
                                            return <>
                                                {(rowData.swiss_level_shift_cumulative * 100).toFixed(2) + '%'}
                                                <br />
                                                <span style={{ fontSize: '10px', fontWeight: 600, color: '#027f80' }}>{(rowData.swiss_level_new_trains_metric_cumulative * 100).toFixed(0)}% New PKm</span>
                                            </>;
                                        } else {
                                            return <></>
                                        }
                                    }}
                                </Cell>
                            </Column>

                            <Column flexGrow={2.5} minWidth={160}>
                                <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}>B2B<br />
                                    <span style={{ fontSize: '10px' }}>Revenue Potential</span>
                                </HeaderCell>
                                <Cell style={{ fontSize: '12px' }}>
                                    {(rowData) => {
                                        if ("position" in rowData) {
                                            return <>{numberWithCommas(rowData.revenue_potential.toFixed(0))}
                                                <br />
                                                <span style={{ fontSize: '10px', fontWeight: 600 }}>Cum.: {numberWithCommas(rowData.revenue_potential_cum.toFixed(0))}</span>
                                            </>
                                        }
                                        else {
                                            return <></>
                                        }
                                    }}
                                </Cell>
                            </Column>

                        </Table>
                    </div>
                    <div className="row">
                        <div className="column" style={{ float: 'left', width: '20%', textAlign: 'left', height: '400px' }}>
                            <img src={require('../../assets/img/behavior.png')} alt="BEHAVIOUR" className='img-measure' />
                            <h3>Measure</h3>
                            <h2>BEHAVIOUR</h2>
                            <p style={{ paddingRight: '20%' }}>Behaviour Change simulates what would happen if people who currently drive on routes with excellent car-free alternatives shifted to public transport. All public transport connections that are as fast as the car and require no more than one transfer qualify as excellent car-free alternatives.
                            </p>
                        </div>
                        <div className="column" style={{ float: 'left', width: '20%', textAlign: 'left', height: '400px' }}>
                            <img src={require('../../assets/img/working.png')} alt="WORKING POLICY" className='img-measure' />
                            <h3>Measure</h3>
                            <h2>WORKING POLICY</h2>
                            <p style={{ paddingRight: '20%' }}>Working Policy simulates what would happen if employers recognized long commutes as working time. All uninterrupted train trips of at least 20 minutes qualify for this measure. Shorter train trips and bus and tram connections are not considered productive working time.</p>
                        </div>
                        <div className="column" style={{ float: 'left', width: '20%', textAlign: 'left', height: '400px' }}>
                            <img src={require('../../assets/img/bike.png')} alt="BICYCLE CULTURE" className='img-measure' />
                            <h3>Measure</h3>
                            <h2>BICYCLE CULTURE</h2>
                            <p style={{ paddingRight: '20%' }}>Bicycle culture assumes that car drivers within a 5 km radius could potentially shift to cycling if decent cycling infrastructures are in place. </p>
                        </div>
                        <div className="column" style={{ float: 'left', width: '20%', textAlign: 'left', height: '400px' }}>
                            <img src={require('../../assets/img/lastmile.png')} alt="LAST MILE" className='img-measure' />
                            <h3>Measure</h3>
                            <h2>LAST MILE</h2>
                            <p style={{ paddingRight: '20%' }}>The Last-Mile measure simulates how many current car drivers would have equally fast car-free alternatives if mobility offers would bridge the last-mile from the nearest train station to their destination.</p>
                        </div>
                        <div className="column" style={{ float: 'left', width: '20%', textAlign: 'left', height: '400px' }}>
                            <img src={require('../../assets/img/car.png')} alt="CAR POOLING" className='img-measure' />
                            <h3>Measure</h3>
                            <h2>CAR POOLING</h2>
                            <p style={{ paddingRight: '20%' }}>Carpooling simulates how many current car drivers could potentially carpool because they drive the same route. The measure assumes that two people who currently drive identical routes would share a car.</p>

                        </div>
                    </div>
                </div>
                <div className="footer-section" style={{ height: '90px' }}>
                <img src={require('../../assets/img/logo-white.png')} alt="Logo ÖV42 by 42hacks" className='footer-logo-img'/>
                </div>

            </div>

        )
    }
}


export default (props) => (
    // eslint-disable-next-line react/jsx-pascal-case
    <CO2_Report_Screen
        {...props}
        params={useParams()}
    />
); 