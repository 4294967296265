import React from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '../../css/map-drop-point.css';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';
import Chart from 'chart.js/auto';
import { IconContext } from "react-icons";
import { BsLayersHalf } from "react-icons/bs";
import Select from 'react-select';
import AustriaRecommendationNavBarComponent from "../../../components/AustriaRecommendationNavBarComponent";
import Slider from 'rc-slider';
import { useParams } from "react-router-dom";
import { RiFullscreenFill, RiFullscreenExitFill } from "react-icons/ri";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ExportExcel from "../../../utils/ExportExcel";
import { Line } from 'react-chartjs-2';
import DatePicker from "react-multi-date-picker";
import InputIcon from "react-multi-date-picker/components/input_icon"

import "react-multi-date-picker/styles/colors/teal.css"


mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const URL = process.env.REACT_APP_URL_SERVER_BACK;

let no_data_modal = false;
let hideLines = false;
let hideCircles = false;
let hideDestination = false;

let geohash_ids = [];
let polygonID = [];

const _ = require('lodash');

let generated_info = false;
function numberWithCommas(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
}

const lines_geojson = {
	'type': 'FeatureCollection',
	'features': [
		{
			'type': 'Feature',
			'geometry': {
				"type": "LineString",
				"coordinates": [
				]
			}
		}
	]
};

class HideElementsComponent extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			hideNavs: false,
			mapa: this.props.map,
		}
	}

	hideComponent() {
		let newHideNavs = !this.state.hideNavs;
		this.setState({ hideNavs: newHideNavs })
		this.props.updateField("hideNavs", newHideNavs);
	}

	render() {

		const { hideNavs } = this.state;

		return (
			<div>
				{hideNavs ? <>
					<div className='map-close-navs'>
						<div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
								<div>
									<RiFullscreenExitFill />
								</div>
							</IconContext.Provider>
						</div>
					</div>
				</> : <>
					<div className='map-close-navs'>
						<div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
								<div>
									<RiFullscreenFill />
								</div>
							</IconContext.Provider>
						</div>
					</div>
				</>}
			</div>
		)
	}
}

//Layer Tyles Selection - Side Menu
class LayerTylesComponent extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			showOptions: false,
			mapa: this.props.map,
		}
		this.hideComponent = this.hideComponent.bind(this);
		this.handleTyleEvent = this.handleTyleEvent.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (this.props.map !== prevProps.map) {
			this.setState({ mapa: this.props.map })
		}
	}

	hideComponent(name) {
		this.setState({ showOptions: !this.state.showOptions });
	}

	handleTyleEvent(event) {
		switch (event) {
			case "default":
				this.props.handleTyle("mapbox://styles/mapbox/light-v10");
				break;
			case "outdoors":
				this.props.handleTyle("mapbox://styles/mapbox/outdoors-v11");
				break;
			case "satellite":
				this.props.handleTyle("mapbox://styles/mapbox/satellite-streets-v11");
				break;
			case "streets":
				this.props.handleTyle("mapbox://styles/mapbox/streets-v11");
				break;
			case "dark":
				this.props.handleTyle("mapbox://styles/mapbox/dark-v10");
				break;
			default:
				// eslint-disable-next-line no-unused-expressions
				null;
		}
		this.props.handleCoord(this.state.mapa.getCenter(), this.state.mapa.getZoom());
	}

	render() {
		const { showOptions } = this.state;

		return (
			<div>
				{showOptions ? <>
					<div id='layer-options' className='map-layer-options-tyles'>
						<div className='map-layer-option-dark' onClick={() => this.handleTyleEvent("dark")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../../assets/img/Dark.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Dark</p>
						</div>
						<div className='map-layer-option-streets' onClick={() => this.handleTyleEvent("streets")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../../assets/img/Streets.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Streets</p>
						</div>
						<div className='map-layer-option-satellite' onClick={() => this.handleTyleEvent("satellite")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../../assets/img/Satellite.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Satellite</p>
						</div>
						<div className='map-layer-option-outdoors' onClick={() => this.handleTyleEvent("outdoors")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../../assets/img/Outdoors.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Outdoors</p>
						</div>
						<div className='map-layer-option-default' onClick={() => this.handleTyleEvent("default")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../../assets/img/White.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Default</p>
						</div>
					</div>
				</> : <></>}
				<div className='map-layer-tyles'>
					<div className='map-layer-tyles-inner' onClick={() => this.hideComponent("showOptions")}>
						<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
							<div>
								<BsLayersHalf />
							</div>
						</IconContext.Provider>
					</div>
				</div>
			</div>
		)
	}
}

class GraphComponent extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			graphParam: this.props.graphParam,
			respWvsW: this.handleFetchDataWsW(this.props.graphParam),
			respModalPKM: this.handleFetchDataModalPKM(this.props.graphParam),
			respModalTrips: this.handleFetchDataModalTrips(this.props.graphParam),
			respTraveledKm: this.handleFetchDataTraveledKm(this.props.graphParam),
			loadingpkm: true,
			loadingmspkm: true,
			loadingmstrips: true,
			loadingtrips: true
		}
	}

	componentDidMount() {
		this.handleFetchDataWsW = this.handleFetchDataWsW.bind(this);
		this.handleFetchDataModalPKM = this.handleFetchDataModalPKM.bind(this);
		this.handleFetchDataModalTrips = this.handleFetchDataModalTrips.bind(this);
		this.handleFetchDataTraveledKm = this.handleFetchDataTraveledKm.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (this.props.graphParam !== prevProps.graphParam && this.props.graphParam!=='') {
			this.setState({ graphParam: this.props.graphParam });
			this.handleFetchDataWsW(this.props.graphParam);
			this.handleFetchDataModalPKM(this.props.graphParam);
			this.handleFetchDataModalTrips(this.props.graphParam);
			this.handleFetchDataTraveledKm(this.props.graphParam);
		}
	}

	async handleFetchDataWsW(param) {
			this.setState({loadingtrips: true})
			fetch(URL + 'geohash_od/geohash7_metric_chart/' + param+'/trips')
				.then((res) => res.json())
				.then((json) => {
					let date = [];
					let train = [];
					let car = [];
					let walking = [];

					for (var i = 0; i < json.length; i++) {
						date.push(json[i].date)
						train.push(json[i].train_trips)
						car.push(json[i].car_trips)
						walking.push(json[i].walking_trips)
					}
					this.setState({ respWvsW: {'date': date, 'train_trips': train, 'car_trips': car, 'walking_trips': walking}, loadingtrips: false });
				})
	}

	async handleFetchDataModalTrips(param) {
		this.setState({loadingmstrips: true})

			fetch(URL + 'geohash_od/geohash7_ms_chart/' + param+'/trips')
				.then((res) => res.json())
				.then((json) => {
					let date = [];
					let train = [];
					let car = [];
					let walking = [];

					for (var i = 0; i < json.length; i++) {
						date.push(json[i].date)
						train.push((json[i].ms_trains*100).toFixed(1))
						car.push((json[i].ms_cars*100).toFixed(1))
						walking.push((json[i].ms_walking*100).toFixed(1))
					}
					this.setState({ respModalTrips: {'date': date, 'ms_trains': train, 'ms_cars': car, 'ms_walking': walking}, loadingmstrips: false });
				})
	}

	async handleFetchDataModalPKM(param) {
		this.setState({loadingmspkm: true})

		fetch(URL + 'geohash_od/geohash7_ms_chart/' + param+'/pkm')
		.then((res) => res.json())
				.then((json) => {
					let date = [];
					let train = [];
					let car = [];
					let walking = [];

					for (var i = 0; i < json.length; i++) {
						date.push(json[i].date)
						train.push((json[i].ms_trains*100).toFixed(1))
						car.push((json[i].ms_cars*100).toFixed(1))
						walking.push((json[i].ms_walking*100).toFixed(1))
					}
					this.setState({ respModalPKM: {'date': date, 'ms_trains': train, 'ms_cars': car, 'ms_walking': walking}, loadingmspkm: false });
				})
	}

	async handleFetchDataTraveledKm(param) {
		this.setState({loadingpkm: true})

			fetch(URL + 'geohash_od/geohash7_metric_chart/' + param+'/pkm')
				.then((res) => res.json())
				.then((json) => {
					let date = [];
					let train = [];
					let car = [];
					let walking = [];

					for (var i = 0; i < json.length; i++) {
						date.push(json[i].date)
						train.push(json[i].train_pkm)
						car.push(json[i].car_pkm)
						walking.push(json[i].walking_pkm)
					}
					this.setState({ respTraveledKm: {'date': date, 'train_pkm': train, 'car_pkm': car, 'walking_pkm': walking}, loadingpkm: false });
				})
	}

	render() {
		const { respWvsW } = this.state;
		const { respModalPKM } = this.state;
		const { respModalTrips } = this.state;
		const { respTraveledKm } = this.state;

		const dataBar_WvsW = {
			labels: respWvsW['date'],
			datasets: [{
				label: 'Train Trips',
				data: respWvsW['train_trips'],
				backgroundColor: [
					'rgba(247, 137, 72, 0.2)'
				],
				borderColor: [
					'rgb(247, 137, 72)'
				],
				borderWidth: 1,
				fill: true,
				lineTension: 1
			},
			{
				label: 'Car Trips',
				data: respWvsW['car_trips'],
				backgroundColor: [
					'rgba(2,127,128,0.2)'
				],
				borderColor: [
					'rgb(2,127,128)'
				],
				borderWidth: 1,
				fill: true,
				lineTension: 1
			},
			{
				label: 'Walking Trips',
				data: respWvsW['walking_trips'],
				backgroundColor: [
					'rgba(128, 2, 63, 0.2)'
				],
				borderColor: [
					'rgb(128, 2, 63)'
				],
				borderWidth: 1,
				fill: true,
				lineTension: 1
			},
			]
		};

		const dataBar_ModalPKM = {
			labels: respModalPKM['date'],
			datasets: [{
				label: 'Modal Split Trains',
				data: respModalPKM['ms_trains'],
				backgroundColor: [
					'rgba(247, 137, 72, 0.2)'
				],
				borderColor: [
					'rgb(247, 137, 72)'
				],
				borderWidth: 1,
				fill: true,
				lineTension: 1
			},
			{
				label: 'Modal Split Cars',
				data: respModalPKM['ms_cars'],
				backgroundColor: [
					'rgba(2,127,128,0.2)'
				],
				borderColor: [
					'rgb(2,127,128)'
				],
				borderWidth: 1,
				fill: true,
				lineTension: 1
			},
			{
				label: 'Modal Split Walking',
				data: respModalPKM['ms_walking'],
				backgroundColor: [
					'rgba(128, 2, 63, 0.2)'
				],
				borderColor: [
					'rgb(128, 2, 63)'
				],
				borderWidth: 1,
				fill: true,
				lineTension: 1
			},
			]
			
		};

		const dataBar_ModalTrips = {
			labels: respModalTrips['date'],
			datasets: [{
				label: 'Modal Split Trains',
				data: respModalTrips['ms_trains'],
				backgroundColor: [
					'rgba(247, 137, 72, 0.2)'
				],
				borderColor: [
					'rgb(247, 137, 72)'
				],
				borderWidth: 1,
				fill: true,
				lineTension: 1
			},
			{
				label: 'Modal Split Cars',
				data: respModalTrips['ms_cars'],
				backgroundColor: [
					'rgba(2,127,128,0.2)'
				],
				borderColor: [
					'rgb(2,127,128)'
				],
				borderWidth: 1,
				fill: true,
				lineTension: 1
			},
			{
				label: 'Modal Split Walking',
				data: respModalTrips['ms_walking'],
				backgroundColor: [
					'rgba(128, 2, 63, 0.2)'
				],
				borderColor: [
					'rgb(128, 2, 63)'
				],
				borderWidth: 1,
				fill: true,
				lineTension: 1
			},
			]
		}

		const dataBar_TraveledKm = {
			labels: respTraveledKm['date'],
			datasets: [{
				label: 'Train PKm',
				data: respTraveledKm['train_pkm'],
				backgroundColor: [
					'rgba(247, 137, 72, 0.2)'
				],
				borderColor: [
					'rgb(247, 137, 72)'
				],
				borderWidth: 1,
				fill: true,
				lineTension: 1,
			},
			{
				label: 'Car PKm',
				data: respTraveledKm['car_pkm'],
				backgroundColor: [
					'rgba(2,127,128,0.2)'
				],
				borderColor: [
					'rgb(2,127,128)'
				],
				borderWidth: 1,
				fill: true,
				lineTension: 1
			},
			{
				label: 'Walking PKm',
				data: respTraveledKm['walking_pkm'],
				backgroundColor: [
					'rgba(128, 2, 63, 0.2)'
				],
				borderColor: [
					'rgb(128, 2, 63)'
				],
				borderWidth: 1,
				fill: true,
				lineTension: 1
			},
			]
		};

		const options = {
			xAxes: [{
				type: 'time',
				ticks: {
					autoSkip: true,
					maxTicksLimit: 10
				}
			}],
			plugins: {
				title: {
					display: true,
					text: 'Percentage of Trains\' Modal Split (PKm) per Month'
				},
				legend: {
					position: 'bottom',
				},
			},
		};

		const options_trips = {
			xAxes: [{
				type: 'time',
				ticks: {
					autoSkip: true,
					maxTicksLimit: 10
				}
			}],
			plugins: {
				title: {
					display: true,
					text: 'Percentage of Trains\' Modal Split (Trips) per Month'
				},
				legend: {
					position: 'bottom',
				},
			},
		};

		const kmPerDay = {
			xAxes: [{
				type: 'time',
				ticks: {
					autoSkip: true,
					maxTicksLimit: 10
				}
			}],
			plugins: {
				legend: {
					position: 'bottom',
				},
				title: {
					display: true,
					text: 'Km per Month'
				}
			},
		};

		const numTripsPerDay = {
			xAxes: [{
				type: 'time',
				ticks: {
					autoSkip: true,
					maxTicksLimit: 10
				}
			}],
			plugins: {
				legend: {
					position: 'bottom',
				},
				title: {
					display: true,
					text: 'Number of Trips per Month'
				}
			},
		};

		return (
			<div>
					<div className="modal-accordion">
						<div className="modal-accordion-inner" style={{height: '400px'}}>
							<Tabs
								defaultActiveKey="Traveledkm"
								id="uncontrolled-tab-example"
								className="mb-3"
							>
								<Tab eventKey="Traveledkm" title="Passenger Km">
									{
										this.state.loadingpkm ?
										<div className="data_insights_loader_austria">
														<div className="loader"></div>
													</div>
										:
										<Line data={dataBar_TraveledKm} options={kmPerDay} />
									}
								</Tab>
								<Tab eventKey="modalsplitpkm" title="Modal Split based on Passenger Km">
								{
										this.state.loadingmspkm ?
										<div className="data_insights_loader_austria">
														<div className="loader"></div>
													</div>
										:
										<Line data={dataBar_ModalPKM} options={options} />
									}
								</Tab>
								<Tab eventKey="modalsplittrips" title="Modal Split based on Trips">
								{
										this.state.loadingmstrips ?
										<div className="data_insights_loader_austria">
														<div className="loader"></div>
													</div>
										:
										<Line data={dataBar_ModalTrips} options={options_trips} />
									}
								</Tab>
								<Tab eventKey="weekdayvsweekends" title="Number of Trips">
								{
										this.state.loadingtrips ?
										<div className="data_insights_loader_austria">
														<div className="loader"></div>
													</div>
										:
										<Line data={dataBar_WvsW} options={numTripsPerDay} />
									}
								</Tab>
							</Tabs>
						</div>
					</div>
			</div>
		)
	}
}

class BtnsComponent extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			mapa: this.props.map,
			state_geohash_ids: this.props.state_geohash_ids,
			generatedTyles: false,
			loading: false,
			selectedPKm: 'trips',

			percentage_type: 'trips',

			geometries: [],
			value_calendar: '30/09/2023',
			str_geohash_ids: ''
		}
	}

	componentDidMount() {
		this.resetView = this.resetView.bind(this);
		this.handleHideLines = this.handleHideLines.bind(this);
		this.handleSquareDestinations = this.handleSquareDestinations.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (this.props.map !== prevProps.map) {
			this.setState({ mapa: this.props.map });
		}
		if (this.props.coord !== prevProps.coord) {
			this.setState({ coord: this.props.coord });
		}
	}

	demoAsyncCall(date, str_geohash_ids) {
		let data;
		this.setState({ loading: true });

		let date_format = '{0}-{1}-{2}'.format(date.split('/')[2],date.split('/')[1],date.split('/')[0])

		fetch(URL+'geohash_od/geohash7_ods_municipalities_geometries/{0}/{1}'.format(str_geohash_ids, date_format))
		.then((res) => res.json())
		.then((json) => {
			data = json;
		})
		.then(() => { 
			this.state.mapa.getSource('data-austria-tyle').setData(lines_geojson);
			this.state.mapa.flyTo({zoom:9});

			this.state.mapa.getSource('data-swiss-tyle').setData(data) })
		.then(() => {
			this.setState({ generatedTyles: true, loading: false, str_geohash_ids: str_geohash_ids});
		})
	}

	handleFetchDataCoord() {
		this.setState({ loading: true });
		let str_geohash_ids = ''
		str_geohash_ids = geohash_ids[0]

		if(geohash_ids.length>1){
for(let i=1; i<geohash_ids.length; i++ ){
	str_geohash_ids += ","+geohash_ids[i];
}
		}

		this.demoAsyncCall(this.state.value_calendar, str_geohash_ids)

		this.setState({ generatedTyles: !this.state.generatedTyles });
		generated_info = true;
		hideLines = false;
		hideDestination = false;
	}

	resetView() {
		polygonID.forEach(element => this.state.mapa.setFeatureState({
			source: 'data-austria-tyle',
			id: element,
			generateId: true
		}, {
			clicked: false
		}));

		this.state.mapa.getSource('data-swiss-destinations').setData(lines_geojson);
		this.state.mapa.getSource('data-swiss-tyle').setData(lines_geojson);
		this.state.mapa.getSource('data-austria-tyle').setData(URL+'geohash_od/geohash7_grid');
		this.props.map.setLayoutProperty('data-swiss-tyle-labels', 'visibility', 'none');

		this.setState({ generatedTyles: false, geometries: [], str_geohash_ids: ''});

		this.props.updateFields({state_geohash_ids: [], str_geohash_ids: ''})

		no_data_modal = false;
		generated_info = false;
		polygonID = [];
	}

	handleMonthYear(event) {
		this.setState({ value_calendar: "{0}/{1}/{2}".format(event.day, event.month.number, event.year) });
	}

	handleHideLines() {
		let temp = !hideLines;
		hideLines = temp;
		if (temp) {
			this.state.mapa.setLayoutProperty('swiss-destinations', 'visibility', 'none');
		}
		else {
			this.state.mapa.setLayoutProperty('swiss-destinations', 'visibility', 'visible');
		}
	}

	handleVisualization(event) {
		this.setState({ selectedPKm: event.target.attributes.getNamedItem('id').value })
		//this.demoAsyncCall(this.state.value_od_dates.value, point_geojson.features[0].geometry.coordinates[0], point_geojson.features[0].geometry.coordinates[1], this.state.min_distance_km, this.state.max_distance_km, this.state.n_trips, this.state.min_distance_to_train, this.state.max_distance_to_train, event.target.attributes.getNamedItem('id').value, this.state.selection_distribution);

	}

	handleSquareDestinations() {
		let temp = !hideDestination;
		hideDestination = temp;
		if (temp) {
			this.props.map.setLayoutProperty('data-swiss-tyle-labels', 'visibility', 'none');
			this.props.map.setLayoutProperty('data-swiss-tyle', 'visibility', 'none');
			this.props.map.setLayoutProperty('outline-data-swiss-tyle', 'visibility', 'none');
		} else {
			this.props.map.setLayoutProperty('data-swiss-tyle-labels', 'visibility', 'visible');
			this.props.map.setLayoutProperty('data-swiss-tyle', 'visibility', 'visible');
			this.props.map.setLayoutProperty('outline-data-swiss-tyle', 'visibility', 'visible');
		}
	}

	handleDistribution(event) {

		let current_selection_distribution = this.state.selection_distribution;

		if (event.target.attributes.getNamedItem('id').value === 'general') {
			current_selection_distribution[0] = (!current_selection_distribution[0])

			if (current_selection_distribution[0]) {
				current_selection_distribution = [true, false, false, false]
			} else {
				current_selection_distribution = [false, true, true, true]
			}
		} else if (event.target.attributes.getNamedItem('id').value === 'lastmile') {
			current_selection_distribution[1] = (!current_selection_distribution[1])
		} else if (event.target.attributes.getNamedItem('id').value === 'bike') {
			current_selection_distribution[2] = (!current_selection_distribution[2])
		} else if (event.target.attributes.getNamedItem('id').value === 'working') {
			current_selection_distribution[3] = (!current_selection_distribution[3])
		}

		this.setState({ selection_distribution: current_selection_distribution })
		//this.demoAsyncCall(this.state.value_od_dates.value, point_geojson.features[0].geometry.coordinates[0], point_geojson.features[0].geometry.coordinates[1], this.state.min_distance_km, this.state.max_distance_km, this.state.n_trips, this.state.min_distance_to_train, this.state.max_distance_to_train, this.state.selectedPKm, current_selection_distribution);
	}


	render() {
		const { loading } = this.state;
		const { generatedTyles } = this.state;


		if (loading) { // if your component doesn't have to wait for an async action, remove this block 
			return (
				<div className="bg-loader">
					<div className="pos-center-brain-loader">
						<div className="circle-loading-brain">
						</div>
						<svg className='brain-loader' x="0px" y="0px" viewBox="0 0 496 496">
							<path d="M40,449.472V364.28L58.424,352H88v-16H53.576L24,355.72v93.752C14.704,452.784,8,461.584,8,472c0,13.232,10.768,24,24,24				s24-10.768,24-24C56,461.584,49.288,452.784,40,449.472z M32,480c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8s8,3.584,8,8				C40,476.416,36.416,480,32,480z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M46.528,320H88v-16H46.528c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24				C34.416,336,43.216,329.288,46.528,320z M16,312c0-4.416,3.584-8,8-8s8,3.584,8,8c0,4.416-3.584,8-8,8S16,316.416,16,312z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M24,272c10.416,0,19.216-6.712,22.528-16H88v-16H46.528c-3.312-9.296-12.112-16-22.528-16c-13.232,0-24,10.768-24,24				S10.768,272,24,272z M24,240c4.416,0,8,3.584,8,8s-3.584,8-8,8s-8-3.584-8-8C16,243.584,19.584,240,24,240z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M56 272h32v16H56Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M56 208h32v16H56Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M0 176h88v16H0Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M60.688,160H88v-16H67.312L48,124.688v-14.16C57.288,107.216,64,98.416,64,88c0-13.232-10.768-24-24-24S16,74.768,16,88				c0,10.416,6.704,19.216,16,22.528v20.784L60.688,160z M40,80c4.416,0,8,3.584,8,8s-3.584,8-8,8s-8-3.584-8-8S35.584,80,40,80z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M144 67.312L144 88L160 88L160 60.688L131.312 32L0 32L0 48L124.688 48z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M176 35.312L176 88L192 88L192 28.688L163.312 0L0 0L0 16L156.688 16z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M208 56h16v32H208Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M304 0h16v88H304Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M240,46.528V88h16V59.312l16,16V88h16V68.688L262.392,43.08C268.192,38.696,272,31.816,272,24c0-13.232-10.768-24-24-24				s-24,10.768-24,24C224,34.416,230.704,43.216,240,46.528z M248,16c4.416,0,8,3.584,8,8s-3.584,8-8,8s-8-3.584-8-8				S243.584,16,248,16z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M352,51.312L371.312,32h78.16c2.416,6.776,7.752,12.112,14.528,14.528v110.16L444.688,176H408v16h43.312L480,163.312				V46.528c9.288-3.312,16-12.112,16-22.528c0-13.232-10.768-24-24-24c-10.416,0-19.216,6.712-22.528,16h-84.784L336,44.688V88h16				V51.312z M472,16c4.416,0,8,3.584,8,8s-3.584,8-8,8c-4.416,0-8-3.584-8-8S467.584,16,472,16z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M414.08,97.392l17.92,17.92v9.376L412.688,144H408v16h11.312L448,131.312v-22.624L419.312,80h-4.784				c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24C401.904,112,410.416,105.968,414.08,97.392z				 M392,96c-4.416,0-8-3.584-8-8s3.584-8,8-8c4.416,0,8,3.584,8,8S396.416,96,392,96z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M408 208h88v16H408Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M480,449.472V268.688L451.312,240H408v16h36.688L464,275.312v41.376L451.312,304H408v16h36.688L464,339.312v110.16				c-9.296,3.312-16,12.112-16,22.528c0,13.232,10.768,24,24,24s24-10.768,24-24C496,461.584,489.288,452.784,480,449.472z M472,480				c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8c4.416,0,8,3.584,8,8C480,476.416,476.416,480,472,480z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M408 272h32v16H408Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M448,364.688L419.312,336H408v16h4.688L432,371.312V416h-80v-8h-16v24h96v12.688L412.688,464h-14.16				c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24c10.416,0,19.216-6.712,22.528-16h20.784				L448,451.312V364.688z M376,480c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8c4.416,0,8,3.584,8,8C384,476.416,380.416,480,376,480z				" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M160,408h-16v8h-41.472c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24				c10.416,0,19.216-6.712,22.528-16H160V408z M80,432c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8s8,3.584,8,8				C88,428.416,84.416,432,80,432z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M304 408h16v88H304Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M272 408h16v88H272Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M240,420.688l-16,16V408h-16v41.472c-9.296,3.312-16,12.112-16,22.528c0,13.232,10.768,24,24,24s24-10.768,24-24				c0-7.816-3.808-14.696-9.608-19.08L256,427.312V408h-16V420.688z M216,480c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8s8,3.584,8,8				C224,476.416,220.416,480,216,480z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M192 408L176 408L176 428.688L152 452.688L152 496L168 496L168 459.312L192 435.312z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M336,392h16v-16h24v-24h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16				v-16h-16v-24h-24v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-3.312				L120,140.688V144h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v24h24v16h16v-16h16				v16h16v-16h16v16h16v-16h16v16h16v-16h16v16h16v-16h16v16h16v-16h16V392z M136,360V147.312L147.312,136H360v224H136z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M318.888,211.048l13.96-13.96l-33.936-33.936l-13.96,13.96c-4.16-2.184-8.488-3.976-12.952-5.384V152h-48v19.728				c-4.472,1.408-8.8,3.2-12.952,5.384l-13.96-13.96l-33.936,33.936l13.96,13.96c-2.176,4.16-3.976,8.488-5.384,12.952H152v48				h19.728c1.408,4.472,3.2,8.8,5.384,12.952l-13.96,13.96l33.936,33.936l13.96-13.96c4.16,2.176,8.48,3.976,12.952,5.384V344h48				v-19.728c4.472-1.408,8.8-3.2,12.952-5.384l13.96,13.96l33.936-33.936l-13.96-13.96c2.176-4.152,3.976-8.48,5.384-12.952H344v-48				h-19.728C322.864,219.528,321.072,215.2,318.888,211.048z M328,256h-16.224l-1.416,6.224c-1.6,7.016-4.368,13.672-8.216,19.792				l-3.408,5.408l11.496,11.496l-11.312,11.312l-11.496-11.496l-5.408,3.408c-6.12,3.848-12.776,6.616-19.792,8.216L256,311.776V328				h-16v-16.224l-6.224-1.416c-7.016-1.6-13.672-4.368-19.792-8.216l-5.408-3.408l-11.496,11.496l-11.312-11.312l11.496-11.496				l-3.408-5.408c-3.848-6.12-6.616-12.776-8.216-19.792L184.224,256H168v-16h16.224l1.416-6.224				c1.6-7.016,4.368-13.672,8.216-19.792l3.408-5.408l-11.496-11.496l11.312-11.312l11.496,11.488l5.408-3.408				c6.12-3.848,12.776-6.616,19.792-8.216l6.224-1.408V168h16v16.224l6.224,1.416c7.016,1.6,13.672,4.368,19.792,8.216l5.408,3.408				l11.496-11.488l11.312,11.312l-11.496,11.496l3.408,5.408c3.848,6.12,6.616,12.776,8.216,19.792l1.416,6.216H328V256z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M248,208c-22.056,0-40,17.944-40,40c0,22.056,17.944,40,40,40c22.056,0,40-17.944,40-40C288,225.944,270.056,208,248,208				z M248,272c-13.232,0-24-10.768-24-24s10.768-24,24-24s24,10.768,24,24S261.232,272,248,272z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
						</svg>
					</div>
				</div>
			)
		}
		else {
			return (
				<div>
					{this.state.generatedTyles ?
						<>
							<GraphComponent graphParam={this.state.str_geohash_ids}/>
						</>
						: <></>
					}
					<div className="map-month-year-picker">
					<DatePicker 
  containerClassName="custom-container"
					value={this.state.value_calendar}
					onChange={this.handleMonthYear.bind(this)}
					format="DD/MM/YYYY"
  className="teal"
  render={<InputIcon/>}
  minDate="01/11/2019"
  maxDate="30/09/2023"
  highlightToday={false}
/>					</div>
					<div className="map-btn-drop-point">
						<div className="map-btn-drop-point-inner">
							<h1>Mobility Trends <br/>Region: Graz, South Vienna</h1>
							<p className="label">Select a tile (GeoHash 7) of the options displayed<br/> on the map, choose on the desire date to evaluate and click on <i>Generate matrix</i>.</p>
							<div className="row">
								<div className="column-1-eq">
									<Form onClick={this.handleFetchDataCoord.bind(this)}>
										<Button className="generate-btn" variant="info" disabled={(this.props.state_geohash_ids.length===0 || generatedTyles)}>Generate matrix</Button>
									</Form>
								</div>
								<div className="column-2-eq">
									<Form onClick={this.resetView.bind(this)}>
										<Button className="reset-btn" variant="info" disabled={(!generatedTyles)}>Reset matrix</Button>
									</Form>
								</div>
							</div>
						</div>
					</div>
					{no_data_modal ?
						<>
							<div className="modal-alert">
								<div className="modal-alert-inner">
									<h1>No data available</h1>
									<p>Please click on <i>Reset matrix</i> and try a new point within Austria</p>
								</div>
							</div>
						</>
						: <></>
					}
				</div>
			)
		}
	}
}

class Austria_MobilityTrends extends React.Component {

	// Set up states for updating map 
	constructor(props) {
		super(props);
		this.state = {
			lng: 15.97663,
			lat: 47.63031,
			zoom: 8,
			mapita: 9,
			tyle: 'mapbox://styles/mapbox/satellite-streets-v11',
			coord: this.props.params,
			hideNavs: false,
			state_geohash_ids: [],
			graphParam: ''
		}
	}

	//Update several fields
	updateFields(list) {
		this.setState(list);
	}

	//Update map on component update
	updateField(card, value) {
		this.setState({
			...this.state,
			[card]: value
		});
	}

	//Update style
	handleTyle(value) {
		this.setState({ tyle: value }, this.componentDidMount);
	}

	//Update coordinates
	handleCoord(center, zoom) {
		const { lng, lat } = center;
		this.setState({ zoom: zoom, lat: lat, lng: lng })
	}

	componentDidMount() {
		// Set up map 
		const map = new mapboxgl.Map({
			container: this.mapContainer,
			style: this.state.tyle,
			center: [this.state.lng, this.state.lat],
			zoom: this.state.zoom,
		});

		map.on('load', () => {

			// Add zoom and rotation controls to the map.
			map.addControl(new mapboxgl.NavigationControl({ showCompass: false, showZoom: false }), 'top-left');
			map.addControl(new MapboxGeocoder({ accessToken: mapboxgl.accessToken, mapboxgl: mapboxgl, marker: false }), 'top-left');

			//Tyle visualization
			//Add shapes to the map (Tile 1km)
			map.addSource('data-austria-tyle', {
				'type': 'geojson',
				'data': URL+'geohash_od/geohash7_grid',
				'generateId': true
			});

			map.addLayer({
				'id': 'data-austria-tyle',
				'type': 'fill',
				'source': 'data-austria-tyle',
				'paint': {
					'fill-color': [
						'case',
						['boolean', ['feature-state', 'clicked'], false],
						'#db770b', // if selected true, paint in pink
						'#017380', // else paint
					],
					'fill-opacity': [
						'case',
						['boolean', ['feature-state', 'clicked'], false],
						0.2, // if selected true, paint in pink
						0.4, // else paint
					]
				}
			});

			// Add a black outline around the polygon
			map.addLayer({
				'id': 'outline-data-austria-tyle',
				'type': 'line',
				'source': 'data-austria-tyle',
				'paint': {
					'line-color': [
						'case',
						['boolean', ['feature-state', 'clicked'], false],
						'#db770b', // if selected true, paint in pink
						'#017380', // else paint
					],
					'line-width': 2
				}
			});

			// add lines to the map
			map.addSource('data-swiss-destinations', {
				'type': 'geojson',
				'data': lines_geojson,
				'generateId': true,
				'lineMetrics': true
			});

			map.addLayer({
				'id': 'swiss-destinations',
				'type': 'line',
				'source': 'data-swiss-destinations',
				'layout': {
					// Make the layer visible by default.
					'visibility': 'visible'
				},
				'paint': {
					'line-color': '#027f80',
					'line-width': 2,
				}
			});

			//Tyle visualization
			//Add shapes to the map
			map.addSource('data-swiss-tyle', {
				'type': 'geojson',
				'data': lines_geojson,
				'generateId': true
			});

			map.addLayer({
				'id': 'data-swiss-tyle',
				'type': 'fill',
				'source': 'data-swiss-tyle',
				'layout': {},
				'paint': {
					'fill-color': '#9d1f6c',
					'fill-opacity': 0.4
				}
			});

			// Add a black outline around the polygon
			map.addLayer({
				'id': 'outline-data-swiss-tyle',
				'type': 'line',
				'source': 'data-swiss-tyle',
				'layout': {},
				'paint': {
					'line-color': '#9d1f6c',
					'line-width': 2
				}
			});

			map.addLayer({
				'id': 'data-swiss-tyle-labels',
				'type': 'symbol',
				'source': 'data-swiss-tyle',
				"minzoom": 10,
				'layout': {
					'text-field': ['get', 'total_trips'],
					'text-font': [
						'Open Sans Bold',
						'Arial Unicode MS Bold'
					],
					'text-size': 20,
					'text-transform': 'uppercase',
					'text-letter-spacing': 0.05,
					'text-offset': [0, 1],
					'visibility': 'visible'
				}
			});

		});

		this.setState({
			mapita: map,
			zoom: map.getZoom(),
			center: map.getCenter()
		});

		map.on('click', 'data-swiss-tyle', (e) => {
			let num_passengers_car_wd = e.features[0].properties.car_trips;
			let num_passengers_train_wd = e.features[0].properties.train_trips;
			let num_passengers_walking_wd = e.features[0].properties.walking_trips;
			let num_passengers_wd_total = e.features[0].properties.total_trips;

			let num_passengers_car_wd_str = '';
			let num_passengers_train_wd_str = '';
			let num_passengers_wd_total_str = '';
			let num_passengers_walking_wd_str = '';

			(typeof num_passengers_car_wd == 'number' & num_passengers_car_wd > 0) ? num_passengers_car_wd = (num_passengers_car_wd).toFixed(0) : num_passengers_car_wd = '-';
			(num_passengers_car_wd !== '-') ? num_passengers_car_wd_str = numberWithCommas(num_passengers_car_wd) : num_passengers_car_wd_str = '-';

			(typeof num_passengers_train_wd == 'number' & num_passengers_train_wd > 0) ? num_passengers_train_wd = (num_passengers_train_wd).toFixed(0) : num_passengers_train_wd = '-';
			(num_passengers_train_wd !== '-') ? num_passengers_train_wd_str = numberWithCommas(num_passengers_train_wd) : num_passengers_train_wd_str = '-';

			(typeof num_passengers_walking_wd == 'number' & num_passengers_walking_wd > 0) ? num_passengers_walking_wd = (num_passengers_walking_wd).toFixed(0) : num_passengers_walking_wd = '-';
			(num_passengers_walking_wd !== '-') ? num_passengers_walking_wd_str = numberWithCommas(num_passengers_walking_wd) : num_passengers_walking_wd_str = '-';

			(typeof num_passengers_wd_total == 'number' & num_passengers_wd_total > 0) ? num_passengers_wd_total = (num_passengers_wd_total).toFixed(0) : num_passengers_wd_total = '-';
			if (num_passengers_wd_total < (Number(num_passengers_train_wd) + Number(num_passengers_car_wd)) & num_passengers_wd_total !== '-') {
				num_passengers_wd_total = Number(num_passengers_train_wd) + Number(num_passengers_car_wd);
			}
			(num_passengers_wd_total !== '-') ? num_passengers_wd_total_str = numberWithCommas(num_passengers_wd_total) : num_passengers_wd_total_str = '-';

			let html_name = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">' + e.features[0].properties.name + '</span></p>'
			let html = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Passengers per day</span></p><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle;"><thead><tr><th>&#x1f46a; &#128664;</th><th>&#x1f46a; &#x1f686;</th><th>&#x1f46a;  &#x1f6b6;</th><th>Total</th></tr></thead><tbody><tr><td>' + num_passengers_car_wd_str + '</td><td>' + num_passengers_train_wd_str + '</td><td>' + num_passengers_walking_wd_str + '</td><td>' + num_passengers_wd_total_str + '</td></tr></tbody></table>';

			new mapboxgl.Popup()
				.setLngLat(e.lngLat)
				.setHTML(html_name + html).addTo(map);

		});

		map.on('click', 'data-austria-tyle', (e) => {

			if (e.features.length > 0) {
				if (!polygonID.includes(e.features[0].id)) {
					map.setFeatureState({
						source: 'data-austria-tyle',
						id: e.features[0].id,
					}, {
						clicked: true
					});

					polygonID.push(e.features[0].id)
					geohash_ids.push(e.features[0].properties.geohash_id)

					this.setState({state_geohash_ids: geohash_ids})
				}else{
					
					map.removeFeatureState({
						source: "data-austria-tyle",
						id: e.features[0].id
					});

					const index = polygonID.indexOf(e.features[0].id);
					if (index > -1) { // only splice array when item is found
						polygonID.splice(index, 1); // 2nd parameter means remove one item only
						geohash_ids.splice(index, 1);
					}
					this.setState({state_geohash_ids: geohash_ids})
				}
			}
		});

		// Change the cursor to a pointer when the mouse is over the places layer.
		map.on('mouseenter', 'data-austria-tyle', () => {
			map.getCanvas().style.cursor = 'pointer';

		});

		// Change it back to a pointer when it leaves.
		map.on('mouseleave', 'data-austria-tyle', () => {
			map.getCanvas().style.cursor = '';
		});

		// Fullscreen map 
		//map.addControl(new mapboxgl.FullscreenControl());
	}

	render() {
		const { hideNavs } = this.state;

		return (
			<div>
				<div ref={el => this.mapContainer = el} style={{ width: '100%', height: '100vh' }}>
				</div>
				<AustriaRecommendationNavBarComponent />
				<HideElementsComponent map={this.state.mapita} updateField={(card, field, value) => this.updateField(card, field, value)} />
				<div style={{ display: hideNavs ? 'none' : 'block' }}>
					<BtnsComponent map={this.state.mapita} updateFields={(list) => this.updateFields(list)} coord={this.state.coord} state_geohash_ids={this.state.state_geohash_ids}/>
				</div>
				<LayerTylesComponent map={this.state.mapita} tyle={this.state.tyle} handleTyle={(value) => this.handleTyle(value)} handleCoord={(value1, value2) => this.handleCoord(value1, value2)} />
			</div>
		)
	}
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => (
	// eslint-disable-next-line react/jsx-pascal-case
	<Austria_MobilityTrends
		{...props}
		params={useParams()}
	/>
); 