/* eslint-disable react/style-prop-object */
import React from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import { IconContext } from "react-icons";
import { IoTrashOutline } from "react-icons/io5";
import axios from 'axios';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { BsLayersHalf } from "react-icons/bs";
import { Form, Button } from 'react-bootstrap';
import Chart from 'chart.js/auto';
import { Line } from 'react-chartjs-2';
import Xarrow from "react-xarrows";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import '../../components/layer-tyles.css';
import '../css/map-tabs-boostrap-graphs.css';
import '../css/map-potential-routes.css';
import RecommendationNavBarComponent from "../../components/RecommendationNavBarComponent";
import { RiFullscreenFill, RiFullscreenExitFill } from "react-icons/ri";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const URL = process.env.REACT_APP_URL_SERVER_BACK;

function numberWithCommas(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

let URL_points = [];

let URL_lines = {
	'type': 'FeatureCollection',
	'features': [
		{
			'type': 'Feature',
			'geometry': {
				"type": "LineString",
				"coordinates": [
				]
			}
		}
	]
};

let generated_line = "";

class HideElementsComponent extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			hideNavs: false,
			mapa: this.props.map,
		}
	}

	hideComponent() {
		let newHideNavs = !this.state.hideNavs;
		this.setState({ hideNavs: newHideNavs })
		this.props.updateField("hideNavs", newHideNavs);
	}

	render() {

		const { hideNavs } = this.state;

		return (
			<div>
				{hideNavs ? <>
					<div className='map-close-navs'>
						<div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
								<div>
									<RiFullscreenExitFill />
								</div>
							</IconContext.Provider>
						</div>
					</div>
				</> : <>
					<div className='map-close-navs'>
						<div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
								<div>
									<RiFullscreenFill />
								</div>
							</IconContext.Provider>
						</div>
					</div>
				</>}
			</div>
		)
	}
}

//Layer Tyles Selection - Side Menu
class LayerTylesComponent extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			showOptions: false,
			mapa: this.props.map,
		}
		this.hideComponent = this.hideComponent.bind(this);
		this.handleTyleEvent = this.handleTyleEvent.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (this.props.map !== prevProps.map) {
			this.setState({ mapa: this.props.map })
		}
	}

	hideComponent(name) {
		switch (name) {
			case "showOptions":
				this.setState({ showOptions: !this.state.showOptions });
				break;
			default:
				// eslint-disable-next-line no-unused-expressions
				null;
		}
	}

	handleTyleEvent(event) {
		switch (event) {
			case "default":
				this.props.handleTyle("mapbox://styles/mapbox/light-v10");
				break;
			case "outdoors":
				this.props.handleTyle("mapbox://styles/mapbox/outdoors-v11");
				break;
			case "satellite":
				this.props.handleTyle("mapbox://styles/mapbox/satellite-streets-v11");
				break;
			case "streets":
				this.props.handleTyle("mapbox://styles/mapbox/streets-v11");
				break;
			case "dark":
				this.props.handleTyle("mapbox://styles/mapbox/dark-v10");
				break;
			default:
				// eslint-disable-next-line no-unused-expressions
				null;
		}
		this.props.handleCoord(this.state.mapa.getCenter(), this.state.mapa.getZoom());
	}

	render() {
		const { showOptions } = this.state;

		return (
			<div>
				{showOptions ? <>
					<div id='layer-options' className='map-layer-options-tyles'>
						<div className='map-layer-option-dark' onClick={() => this.handleTyleEvent("dark")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/Dark.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Dark</p>
						</div>
						<div className='map-layer-option-streets' onClick={() => this.handleTyleEvent("streets")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/Streets.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Streets</p>
						</div>
						<div className='map-layer-option-satellite' onClick={() => this.handleTyleEvent("satellite")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/Satellite.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Satellite</p>
						</div>
						<div className='map-layer-option-outdoors' onClick={() => this.handleTyleEvent("outdoors")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/Outdoors.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Outdoors</p>
						</div>
						<div className='map-layer-option-default' onClick={() => this.handleTyleEvent("default")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/White.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Default</p>
						</div>
					</div>
				</> : <></>}
				<div className='map-layer-tyles'>
					<div className='map-layer-tyles-inner' onClick={() => this.hideComponent("showOptions")}>
						<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
							<div>
								<BsLayersHalf />
							</div>
						</IconContext.Provider>
					</div>
				</div>
			</div>
		)
	}
}

class PotentialRoutesInfo extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			mapa: this.props.map,
			showRoutes: this.props.showRoutes,
			resp_data_insights: '',
			resp_station_insights: '',
			selected_station_select_react: ({
				"value": "",
				"label": ''
			}),
			value_od_dates: ({
				"value": "2022-08",
				"label": '2022-08'
			}),
			dates_trips_: this.getOptionsDate(URL + 'db_od_trips'),
			value_means_transport: ({
				"value": "Zug",
				"label": 'Zug'
			}),
			value_agency: ({
				"value": "",
				"label": ""
			}),
			day_of_week: 'weekdays',
			isGenerated: false,
			means_transport_: this.getOptionsMeansTransport(URL + 'potential_routes/means_transport'),
			array_selected_stations: this.props.array_selected_stations,
			selected_route_agency: this.props.selected_route_agency,
			selected_stations: [],
			selected_options_agency: [],
			selected_option_line: [],
			aggregation: 'plz',
			routes_list: [],
			modalSplitApiResp: [],
			loading: false,
			selected_type_table: [
				{
					"value": "modal_split",
					"label": 'Modal Split - Trains'
				},
				{
					"value": "trips_weekdays",
					"label": 'Number of Trips'
				},
				{
					"value": "train_pkm",
					"label": 'Passenger PKm'
				}
			],
			fullScreen: false,
			selected_type_table_current: (
				{
					"value": "modal_split",
					"label": 'Modal Split - Trains'
				}),
			hasAgency: false,
			resp_evaluate_company: ''
		}
		this.fetchStations();
	}

	fetchStations() {
		let data;
		fetch(URL + 'potential_routes/routes_template/stations', {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ means_of_transport: 'Zug' })
		})
			.then((res) => res.json())
			.then((json) => {
				data = json;
			}).then(() => { URL_points = data })
	}
	
	loadOptions = (inputValue, callback) => {
		setTimeout(() => {
			fetch(URL + 'potential_routes/search_engine', {
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({ query: inputValue })
			})
				.then((resp) => {
					return resp.json();
				})
				.then((data) => {
					const tempArray = [];
					if (data) {
						if (data.length) {
							data.forEach((element) => {
								tempArray.push(element);
							});
						}
					}
					callback(tempArray);
				})
				.catch((error) => {
					console.log(error, "catch the hoop");
				});
		}, 1000);
	};



	async handleMeansTransport(event) {
		this.setState({ value_means_transport: event })

		let data;
		fetch(URL + 'potential_routes/routes_template/stations', {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ means_of_transport: event.value })
		})
			.then((res) => res.json())
			.then((json) => {
				data = json;
			}).then(() => { this.state.mapa.getSource('data-circles-stations').setData(data); })
	}

	componentDidMount() {
		this.showRoutes = this.showRoutes.bind(this);
		this.deleteElem = this.deleteElem.bind(this);
		this.stationInsightsHandler = this.stationInsightsHandler.bind(this);
		this.handleMonthYear = this.handleMonthYear.bind(this);
		this.handleDayOfWeek = this.handleDayOfWeek.bind(this);
		this.handleOptionLineRoute = this.handleOptionLineRoute.bind(this);
		this.handleOptionAgency = this.handleOptionAgency.bind(this);
		this.handleTypeofTable = this.handleTypeofTable.bind(this);
		this.handleFullScreenAnalysis = this.handleFullScreenAnalysis.bind(this);
		this.resetPlatform = this.resetPlatform.bind(this);
		this.handleEvaluateCompany = this.handleEvaluateCompany.bind(this);
	}

	async getOptionRouteLines(agency_id) {
		fetch(URL + 'potential_routes/search_engine/routes', {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ agency_id: agency_id, query: "a" })
		})
			.then((res) => res.json())
			.then((json) => {
				this.setState({ routes_list: json });
			})
	}

	async getOptionsMeansTransport(url) {
		const res = await axios.get(url)
		const data = res.data
		const options = data.map(d => ({
			"value": d.mean_of_transport,
			"label": d.mean_of_transport
		}))
		this.setState({ means_transport_: options });
	}

	async getOptionsDate(url) {
		const res = await axios.get(url)
		const data = res.data
		const options = data.map(d => ({
			"value": d._id.date.toString().split('T')[0].slice(0, -3),
			"label": d._id.date.toString().split('T')[0].slice(0, -3)
		}))
		this.setState({ dates_trips_: options });
		//this.setState({value_od_dates: options[options.length-1]});
	}

	getOptions() {
		if (this.state.array_selected_stations.length > 0) {
			const data = this.state.array_selected_stations
			const options = data.map(d => ({
				"value": d.stop_id,
				"label": d['Stop name']
			}))
			this.setState({ selected_stations: options })
		}
		else {
			this.setState({ selected_stations: [] })
		}
	}

	handleEvaluateCompany() {

		let body = {
			"agency_id": this.state.selected_options_agency.value,
			"day_of_week": this.state.day_of_week,
			"filter_level": this.state.aggregation
		}


		this.setState({ loading: true })
		fetch(URL + 'potential_routes/transport_company', {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(body)
		})
			.then((res) => res.json())
			.then((json) => {
				this.setState({ resp_evaluate_company: json[0] });
			}).then(() => this.setState({ loading: false }))
	}

	async stationInsightsHandler(event) {
		this.fetchHandler(event);
		this.fetchHandler(event);
	}

	async fetchHandler(event) {
		let id_stationSelected = event.value;
		let stop_list = [];

		if (this.state.selected_station_select_react.label === '') {
			this.setState({ selected_station_select_react: event });
		}

		this.state.array_selected_stations.map(selected_stations => (
			stop_list.push(selected_stations.stop_id)
		))

		fetch(URL + 'potential_routes/insights_per_station', {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ date: this.state.value_od_dates.value + "-01", stop_list: stop_list, selected_station_id: id_stationSelected, day_of_week: this.state.day_of_week, filter_level: this.state.aggregation })
		})
			.then((res) => res.json())
			.then((json) => {
				this.setState({ resp_station_insights: json });
			})
	}

	componentDidUpdate(prevProps) {
		if (this.props.map !== prevProps.map) {
			this.setState({ mapa: this.props.map });
		}
		if (this.props.array_selected_stations !== prevProps.array_selected_stations) {
			this.setState({ array_selected_stations: this.props.array_selected_stations });
		}
		if (this.props.showRoutes !== prevProps.showRoutes) {
			this.setState({ showRoutes: this.props.showRoutes });
		}
		if (this.props.selected_route_agency !== prevProps.selected_route_agency) {
			this.setState({ selected_route_agency: this.props.selected_route_agency });
			this.handleOptionLineRoute(this.props.selected_route_agency);
		}
	}

	handleFullScreenAnalysis() {
		let fullscreen = !this.state.fullScreen
		this.setState({ fullScreen: fullscreen })
		if (!fullscreen) {
			this.setState({ resp_station_insights: '' })
		}
	}

	async handleFecthDataRoute() {
		let stop_list = [];
		let data;
		this.state.array_selected_stations.map(selected_stations => (
			stop_list.push(selected_stations.stop_id)
		))

		fetch(URL + 'potential_routes/evaluate_route', {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ date: this.state.value_od_dates.value + "-01", stop_list: stop_list, day_of_week: this.state.day_of_week, filter_level: this.state.aggregation })
		})
			.then((res) => res.json())
			.then((json) => {
				data = json;
				this.setState({ resp_data_insights: json });
			}).then(() => { this.state.mapa.getSource('data-lines-generated-routes').setData(data); this.getOptions() })
			.then(() => {
				fetch(URL + 'potential_routes/checked_geometries', {
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({ stop_list: stop_list, filter_level: this.state.aggregation })
				}).then((res) => res.json())
					.then((json) => {
						data = json;
					}).then(() => {
						this.state.mapa.getSource('data-swiss-tile-plz').setData(data);
						this.setState({ loading: true })
					})
					.then(() => {
						fetch(URL + 'potential_routes/route_modal_split', {
							method: 'POST',
							headers: {
								'Accept': 'application/json',
								'Content-Type': 'application/json'
							},
							body: JSON.stringify({ stop_list: stop_list, filter_level: this.state.aggregation, day_of_week: this.state.day_of_week })
						})
							.then((res) => res.json())
							.then((json) => {
								data = json;
								this.setState({ modalSplitApiResp: json.features[0].properties, loading: false });
							})
					});
			})

		this.state.mapa.setLayoutProperty('data-circles-stations', 'visibility', 'none');
		this.state.mapa.getSource('data-lines-routes').setData(URL + "potential_routes/routes_template/empty");
		this.setState({ isGenerated: true })
	}

	async handleFecthDataRouteDateChanged(date_month_year) {
		let stop_list = [];
		let data;
		this.state.array_selected_stations.map(selected_stations => (
			stop_list.push(selected_stations.stop_id)
		))
		fetch(URL + 'potential_routes/evaluate_route', {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ date: date_month_year + "-01", stop_list: stop_list, day_of_week: this.state.day_of_week })
		})
			.then((res) => res.json())
			.then((json) => {
				data = json;
				this.setState({ resp_data_insights: json });

			}).then(() => { this.state.mapa.getSource('data-lines-generated-routes').setData(data); this.getOptions() })
			.then(() => {
				fetch(URL + 'potential_routes/checked_geometries', {
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({ stop_list: stop_list, filter_level: this.state.aggregation })
				}).then((res) => res.json())
					.then((json) => {
						data = json;
					}).then(() => { this.state.mapa.getSource('data-swiss-tile-plz').setData(data) });
			})

		this.state.mapa.getSource('data-circles-stations').setData(URL + "potential_routes/routes_template/empty");
		this.state.mapa.getSource('data-lines-routes').setData(URL + "potential_routes/routes_template/empty");
	}

	async stationInsightsHandlerDateChanged(event, date_month_year) {
		this.fetchHandlerDateChanged(event, date_month_year);
		this.fetchHandlerDateChanged(event, date_month_year);
	}

	async fetchHandlerDateChanged(event, date_month_year) {
		let id_stationSelected = event.value;
		let stop_list = [];

		if (this.state.selected_station_select_react.label === '') {
			this.setState({ selected_station_select_react: event });
		}

		this.state.array_selected_stations.map(selected_stations => (
			stop_list.push(selected_stations.stop_id)
		))
		fetch(URL + 'potential_routes/insights_per_station', {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ date: date_month_year + "-01", stop_list: stop_list, selected_station_id: id_stationSelected, day_of_week: this.state.day_of_week, filter_level: this.state.aggregation })
		})
			.then((res) => res.json())
			.then((json) => {
				this.setState({ resp_station_insights: json });
			})
	}

	showRoutes() {
		if (this.state.array_selected_stations.length > 0) {
			this.setState({ showRoutes: true })
		}
		else {
			this.setState({ showRoutes: false })
		}
	}

	deleteElem(id_elem) {
		let elem = this.props.deleteElem(id_elem);

		//Lines - Routes
		//Add shapes to the map
		this.state.mapa.setFeatureState({
			source: 'data-circles-stations',
			id: elem.circle_map_id,
		}, {
			clicked: false
		});
		this.showRoutes();
	}

	resetPlatform() {
		this.state.mapa.getSource('data-lines-routes').setData(URL_lines);
		this.state.mapa.getSource('data-swiss-tile-plz').setData(URL_lines);
		this.state.mapa.setLayoutProperty('data-circles-stations', 'visibility', 'visible');

		//this.state.mapa.getSource('data-circles-stations').setData(URL_points);
		this.state.mapa.getSource('data-lines-generated-routes').setData(URL + "potential_routes/routes_template/empty");
		this.handleMeansTransport(this.state.value_means_transport)
		this.state.array_selected_stations.forEach(element => this.state.mapa.setFeatureState({
			source: 'data-circles-stations',
			id: element.circle_map_id,
			generateId: true
		}, {
			clicked: false
		}));
		this.props.updateFields({ array_selected_stations: [] })
		this.setState({ resp_data_insights: '', resp_evaluate_company: '' })
		this.setState({ isGenerated: false, hasAgency: false, showRoutes: false })
		this.setState({
			selected_station_select_react: ({
				"value": "",
				"label": ''
			}),
			modalSplitApiResp: []
		})
		this.setState({ resp_station_insights: '' })
		this.handleOptionAgency([]);
		this.setState({ selected_option_line: null })
		this.state.mapa.getSource('data-lines-routes').setData(URL_lines);
		this.state.mapa.setLayoutProperty('data-circles-stations', 'visibility', 'visible');
	}

	handleMonthYear(event) {
		this.setState({ value_od_dates: event });
		if (this.state.array_selected_stations.length !== 0 && this.state.isGenerated) {
			this.handleFecthDataRouteDateChanged(event.value)
		}
		if (this.state.selected_station_select_react.value !== '') {
			this.stationInsightsHandlerDateChanged(this.state.selected_station_select_react, event.value)
		}
	}

	handleOptionLineRoute(event) {
		this.setState({ selected_option_line: event })
		if (event != null) {
			this.setState({ showRoutes: true })
			fetch(URL + "potential_routes/routes/" + event.value)
				.then((res) => res.json())
				.then((json) => {
					let array_complete = []
					json.features[0].properties.stop_name.map((stop, index) =>
						array_complete.push({
							'Stop name': stop,
							'stop_id': json.features[0].properties.stops[index]
						})
					);
					this.setState({ array_selected_stations: array_complete })
				}
				).then(() => {
					this.state.mapa.getSource('data-lines-routes').setData(URL + "potential_routes/routes_template/empty");
					this.state.mapa.setLayoutProperty('data-circles-stations', 'visibility', 'none');
				})
				.then(() => {

					let stop_list = [];
					let data;
					this.state.array_selected_stations.map(selected_stations => (
						stop_list.push(selected_stations.stop_id)
					))

					fetch(URL + 'potential_routes/evaluate_route', {
						method: 'POST',
						headers: {
							'Accept': 'application/json',
							'Content-Type': 'application/json'
						},
						body: JSON.stringify({ date: this.state.value_od_dates.value + "-01", stop_list: stop_list, day_of_week: this.state.day_of_week, filter_level: this.state.aggregation })
					})
						.then((res) => res.json())
						.then((json) => {
							data = json;
						}).then(() => { this.state.mapa.getSource('data-lines-generated-routes').setData(data) })
						.then(() => {
							fetch(URL + 'potential_routes/checked_geometries', {
								method: 'POST',
								headers: {
									'Accept': 'application/json',
									'Content-Type': 'application/json'
								},
								body: JSON.stringify({ stop_list: stop_list, filter_level: this.state.aggregation })
							}).then((res) => res.json())
								.then((json) => {
									data = json;
								}).then(() => {
									this.state.mapa.getSource('data-swiss-tile-plz').setData(data);
								})

						})
				})
		}
		else {
			if (this.state.hasAgency) {
				let selected_agencies_ids = this.state.selected_options_agency.value;
				let data;
				this.setState({ showRoutes: false })

				this.getOptionRouteLines(selected_agencies_ids)

				fetch(URL + 'potential_routes/routes_template/routes', {
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({ "agency_id": selected_agencies_ids })
				})
					.then((res) => res.json())
					.then((json) => {
						data = json;
					}).then(() => { URL_points = data }).then(() => { this.state.mapa.getSource('data-lines-routes').setData(data); this.getOptions() })
				this.state.mapa.getSource('data-lines-generated-routes').setData(URL_lines);
				this.state.mapa.getSource('data-swiss-tile-plz').setData(URL_lines);
				this.state.mapa.setLayoutProperty('data-circles-stations', 'visibility', 'none');
			}
		}
	}

	handleOptionAgency(event) {
		if (event === null) {
			this.setState({ hasAgency: false })
			this.state.mapa.getSource('data-lines-routes').setData(URL_lines);
			this.setState({ selected_options_agency: event });
		} else {
			this.setState({ hasAgency: true })
			this.state.mapa.setLayoutProperty('data-circles-stations', 'visibility', 'none');
			this.setState({ selected_options_agency: event })

			let selected_agencies_ids = event.value;
			let data;

			this.getOptionRouteLines(selected_agencies_ids)

			fetch(URL + 'potential_routes/routes_template/routes', {
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({ "agency_id": selected_agencies_ids })
			})
				.then((res) => res.json())
				.then((json) => {
					data = json;
				}).then(() => { URL_points = data }).then(() => { this.state.mapa.getSource('data-lines-routes').setData(data); this.getOptions() })
		}
	}

	nameStation(id_elem) {
		const target = this.state.array_selected_stations.find(e => e.stop_id === id_elem);
		return target['Stop name'];
	}

	handleDayOfWeek(event) {
		this.setState({ day_of_week: event.target.attributes.getNamedItem('id').value });

		if (this.state.array_selected_stations.length !== 0 && this.state.isGenerated) {
			this.handleFecthDataRouteDayOfWeekChanged(event.target.attributes.getNamedItem('id').value)
		}
		if (this.state.selected_station_select_react.value !== '') {
			this.stationInsightsHandlerDayOfWeekChanged(event.target.attributes.getNamedItem('id').value)
		}
	}

	handleAggregation(event) {
		this.setState({ aggregation: event.target.attributes.getNamedItem('id').value });
	}

	handleTypeofTable(event) {
		this.setState({ selected_type_table_current: event });
	}

	async handleFecthDataRouteDayOfWeekChanged(day_of_week) {
		let stop_list = [];
		let data;
		this.state.array_selected_stations.map(selected_stations => (
			stop_list.push(selected_stations.stop_id)
		))
		fetch(URL + 'potential_routes/evaluate_route', {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ date: this.state.value_od_dates.value + "-01", stop_list: stop_list, day_of_week: day_of_week, filter_level: this.state.aggregation })
		})
			.then((res) => res.json())
			.then((json) => {
				data = json;
				this.setState({ resp_data_insights: json });

			}).then(() => { this.state.mapa.getSource('data-lines-generated-routes').setData(data); this.getOptions() })
		this.state.mapa.getSource('data-circles-stations').setData(URL + "potential_routes/routes_template/empty");
		this.state.mapa.getSource('data-lines-routes').setData(URL + "potential_routes/routes_template/empty");
	}

	async stationInsightsHandlerDayOfWeekChanged(date_month_year) {
		this.fetchHandlerDayOfWeekChanged(date_month_year);
		this.fetchHandlerDayOfWeekChanged(date_month_year);
	}

	async fetchHandlerDayOfWeekChanged(day_of_week) {
		let id_stationSelected = this.state.selected_station_select_react.value;
		let stop_list = [];

		if (this.state.selected_station_select_react.label === '') {
			this.setState({ selected_station_select_react: this.state.selected_station_select_react });
		}

		this.state.array_selected_stations.map(selected_stations => (
			stop_list.push(selected_stations.stop_id)
		))
		fetch(URL + 'potential_routes/insights_per_station', {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ date: this.state.value_od_dates.value + "-01", stop_list: stop_list, selected_station_id: id_stationSelected, day_of_week: day_of_week, filter_level: this.state.aggregation })
		})
			.then((res) => res.json())
			.then((json) => {
				this.setState({ resp_station_insights: json });
			})
	}

	render() {

		const { showRoutes } = this.state;
		const { resp_data_insights } = this.state;
		const { resp_station_insights } = this.state;
		const { value_od_dates } = this.state;
		const { value_means_transport } = this.state;
		const { selected_options_agency } = this.state;
		const { selected_option_line } = this.state;
		const { array_selected_stations } = this.state;
		const { loading } = this.state;
		const { modalSplitApiResp } = this.state;
		const { selected_type_table_current } = this.state;
		const { fullScreen } = this.state;
		const { hasAgency } = this.state;
		const { resp_evaluate_company } = this.state;

		const table_in_name = this.state.resp_station_insights?.stations_entering?.map((station, index) =>
			<td key={index}><h4 className="table-data">{this.nameStation(station[0])}</h4></td>
		);

		const table_in_number = this.state.resp_station_insights?.stations_entering?.map((station, index) =>
			<td key={index}><h5 className="incoming">{numberWithCommas(station[1].toFixed(0))}</h5></td>
		);

		const table_in_empty = this.state.resp_station_insights?.stations_entering?.map((station, index) =>
			<td key={index}><h4 className="blank">blank</h4></td>
		);

		const table_out_name = this.state.resp_station_insights?.stations_leaving?.map((station, index) =>
			<td key={index}><h4 className="table-data">{this.nameStation(station[0])}</h4></td>
		);

		const table_out_number = this.state.resp_station_insights?.stations_leaving?.map((station, index) =>
			<td key={index}><h5 className="departure">{numberWithCommas(station[1].toFixed(0))}</h5></td>
		);

		const table_out_empty = this.state.resp_station_insights?.stations_leaving?.map((station, index) =>
			<td key={index}><h4 className="blank">blank</h4></td>
		);

		const selectDate = {
			menuList: styles => {
				return {
					...styles,
					maxHeight: 150,
					borderWidth: 0,
					boxShadow: '0 0 10px 2px rgb(0 0 0 / 10%)',
				};
			}
		};

		const selectStyles = {
			menuList: styles => {
				return {
					...styles,
					maxHeight: 150
				};
			}
		};

		const dataBar_company1 = {
			labels: resp_evaluate_company['date'],
			datasets: [{
				label: 'Modal split for Trains',
				data: resp_evaluate_company['modal_split'],
				backgroundColor: [
					'rgba(238, 99, 194, 0.2)'
				],
				borderColor: [
					'rgb(238, 99, 194)'
				],
				borderWidth: 1
			}
			]
		};

		const dataBar_company3 = {
			labels: resp_evaluate_company['date'],
			datasets: [{
				label: 'Total of PKm',
				data: resp_evaluate_company['total_pkm'],
				backgroundColor: [
					'rgba(18, 148, 209, 0.2)'
				],
				borderColor: [
					'rgb(18, 148, 209)'
				],
				borderWidth: 1
			},
			{
				label: 'Trains\' PKm',
				data: resp_evaluate_company['train_pkm'],
				backgroundColor: [
					'rgba(24, 46, 111, 0.2)'
				],
				borderColor: [
					'rgb(24, 46, 111)'
				],
				borderWidth: 1
			},
			{
				label: 'Cars\' PKm',
				data: resp_evaluate_company['car_pkm'],
				backgroundColor: [
					'rgba(2, 127, 128, 0.2)'
				],
				borderColor: [
					'rgb(2, 127, 128)'
				],
				borderWidth: 1
			}
			]
		};

		const dataBar_company2 = {
			labels: resp_evaluate_company['date'],
			datasets: [{
				label: 'Train trips',
				data: resp_evaluate_company['train_trips'],

				backgroundColor: [
					'rgba(214, 104, 26, 0.2)'
				],
				borderColor: [
					'rgb(214, 104, 26)'
				],
				borderWidth: 1
			},
			{
				label: 'Car Trips',
				data: resp_evaluate_company['car_trips'],
				backgroundColor: [
					'rgba(31, 133, 29, 0.2)'
				],
				borderColor: [
					'rgb(31, 133, 29)'
				],
				borderWidth: 1
			},
			{
				label: 'Total Trips',
				data: resp_evaluate_company['total_trips'],
				backgroundColor: [
					'rgba(84, 29, 133, 0.2)'
				],
				borderColor: [
					'rgb(84, 29, 133)'
				],
				borderWidth: 1
			}
			]
		};

		const dataBar1 = {
			labels: modalSplitApiResp['date'],
			datasets: [{
				label: 'Modal split for Trains',
				data: modalSplitApiResp['modal_split'],
				backgroundColor: [
					'rgba(238, 99, 194, 0.2)'
				],
				borderColor: [
					'rgb(238, 99, 194)'
				],
				borderWidth: 1
			}
			]
		};

		const options1 = {
			xAxes: [{
				type: 'time',
				ticks: {
					autoSkip: true,
					maxTicksLimit: 10
				}
			}],
			plugins: {
				title: {
					display: true,
					text: 'Percentage of Trains\' Modal Split per Month'
				},
				legend: {
					position: 'bottom',
				},
			},
		};

		const dataBar3 = {
			labels: modalSplitApiResp['date'],
			datasets: [{
				label: 'Total of PKm',
				data: modalSplitApiResp['total_pkm'],
				backgroundColor: [
					'rgba(18, 148, 209, 0.2)'
				],
				borderColor: [
					'rgb(18, 148, 209)'
				],
				borderWidth: 1
			},
			{
				label: 'Train PKm',
				data: modalSplitApiResp['train_pkm'],
				backgroundColor: [
					'rgba(2, 127, 128, 0.2)'
				],
				borderColor: [
					'rgb(2, 127, 128)'
				],
				borderWidth: 1
			}
			]
		};

		const options2 = {
			xAxes: [{
				type: 'time',
				ticks: {
					autoSkip: true,
					maxTicksLimit: 10
				}
			}],
			plugins: {
				title: {
					display: true,
					text: 'Number of trips - Cars, Trains, and Total'
				},
				legend: {
					position: 'bottom',
				},
			},
		};

		const dataBar2 = {
			labels: modalSplitApiResp['date'],
			datasets: [{
				label: 'Train trips',
				data: modalSplitApiResp['train_trips_weekdays'],

				backgroundColor: [
					'rgba(214, 104, 26, 0.2)'
				],
				borderColor: [
					'rgb(214, 104, 26)'
				],
				borderWidth: 1
			},
			{
				label: 'Car Trips',
				data: modalSplitApiResp['car_trips_weekdays'],
				backgroundColor: [
					'rgba(31, 133, 29, 0.2)'
				],
				borderColor: [
					'rgb(31, 133, 29)'
				],
				borderWidth: 1
			},
			{
				label: 'Total Trips',
				data: modalSplitApiResp['total_trips_weekdays'],
				backgroundColor: [
					'rgba(84, 29, 133, 0.2)'
				],
				borderColor: [
					'rgb(84, 29, 133)'
				],
				borderWidth: 1
			}
			]
		};

		const options3 = {
			xAxes: [{
				type: 'time',
				ticks: {
					autoSkip: true,
					maxTicksLimit: 10
				}
			}],
			plugins: {
				title: {
					display: true,
					text: 'Passenger PKm - Trains and Total'
				},
				legend: {
					position: 'bottom',
				},
			},
		};

		return (
			<div>
				{fullScreen ?
					<>
						<div className="modal-full-screen-insigths">
							<div className="modal-full-screen-insigths-inner">
								<div className='exit-btn'>
									<Form onClick={this.handleFullScreenAnalysis.bind(this)}>
										<Button variant="info" className="reset-btn">Exit Full Screen</Button>
									</Form>
								</div>
								<div>
									<h1>Select a station to see the upcoming and outgoing traffic to that station</h1>
									<h2>Disclamer: If two stations share the same postal code, the numbers of passengers will be the same</h2>

									{resp_station_insights !== '' ? <>
										<div className="station_insights_fs">
											<div className="row">
												<div className="column_fs_15">
													<h4 className="title-h4">Passengers per day</h4>
													<table className="table_insights">
														<tbody>
															<tr>
																<td><h5 className="incoming">Arriving</h5></td>
															</tr>
															<tr>
																<td><h5>{numberWithCommas(resp_station_insights.total_in.toFixed(0))}</h5></td>
															</tr>
														</tbody>
													</table>
												</div>
												<div className="column_70">
													<div className="search-table-outter wrapper">
														<table className="search-table inner">
															<tbody>
																<tr>
																	{table_in_name}
																</tr>
																<tr>
																	{table_in_number}
																</tr>
																<tr>
																	{table_in_empty}
																</tr>
																<tr>
																	{table_in_empty}
																</tr>
															</tbody>
														</table>
													</div>
													<div>
														<table className="table_select" >
															<tbody>
																<tr>
																	<td><Select className='station' styles={selectStyles} options={this.state.selected_stations} placeholder='Please select a station' onChange={this.stationInsightsHandler.bind(this)} defaultValue={this.state.selected_station_select_react} /></td>
																</tr>
															</tbody>
														</table>
													</div>
													<div className="search-table-outter wrapper">
														<table className="search-table inner">
															<tbody>
																<tr>
																	{table_out_empty}
																</tr>
																<tr>
																	{table_out_empty}
																</tr>
																<tr>
																	{table_out_name}
																</tr>
																<tr>
																	{table_out_number}
																</tr>
															</tbody>
														</table>
													</div>
												</div>
												<div className="column_fs_15">
													<h4 className="title-h4">Passengers per day</h4>
													<table className="table_insights">
														<tbody>
															<tr>
																<td><h5 className="departure">Departing</h5></td>
															</tr>
															<tr>
																<td><h5>{numberWithCommas(resp_station_insights.total_out.toFixed(0))}</h5></td>
															</tr>
														</tbody>
													</table>
												</div>
											</div>
										</div>
									</> : <>
										{resp_data_insights !== '' ? <>
											<table className="table_select-before" >
												<tbody>
													<tr>
														<td><Select className='detail_station' styles={selectStyles} options={this.state.selected_stations} placeholder='Please select a station' onChange={this.stationInsightsHandler.bind(this)} /></td>
													</tr>
												</tbody>
											</table>
										</>
											:
											<></>}
									</>}
								</div>
							</div>
						</div>
					</> :
					<>
						<div className="modal-potential-routes-buttons">
							<div className="modal-potential-routes-buttons-inner">
								<p className='label' htmlFor="Filter based on">Filters based on</p>
								<div className="row">
									<Form onChange={this.handleDayOfWeek.bind(this)}>
										<div className="column-1-eq">
											<Form.Check inline label=" Weekdays" name="type_analysis" type='radio' id='weekdays' defaultChecked={true} />
										</div>
										<div className="column-2-eq">
											<Form.Check inline label=" Weekends" name="type_analysis" type='radio' id='weekend' />
										</div>
									</Form>
								</div>
								<p className='label' htmlFor="Aggregate by">Aggregate by</p>
								<div className="row">
									<Form onChange={this.handleAggregation.bind(this)}>
										<div className="column-1-eq">
											<Form.Check inline label=" Postal Code" name="type_aggre" type='radio' id='plz' defaultChecked={true} />
										</div>
										<div className="column-2-eq">
											<Form.Check inline label=" Tile" name="type_aggre" type='radio' id='tile' />
										</div>
									</Form>
								</div>
								<div className="row">
									<div className="column-1-eqr">
										{!showRoutes ?
											<Form onClick={this.handleEvaluateCompany.bind(this)}>
												<Button variant="info" className="generate-btn" disabled={(!hasAgency)}>Evaluate company</Button>
											</Form>
											:
											<Form onClick={this.handleFecthDataRoute.bind(this)}>
												<Button variant="info" className="generate-btn" disabled={(array_selected_stations.length <= 0)}>Evaluate route</Button>
											</Form>
										}
									</div>
									<div className="column-2-eqr">
										<Form onClick={this.resetPlatform.bind(this)}>
											<Button variant="info" className="reset-btn">Reset</Button>
										</Form>
									</div>
								</div>
							</div>
						</div>
						<div className="modal-potential-routes-filter-agency">
							<div className="modal-potential-routes-filter-agency-inner">
								<div className='column-total'>
									<h1>Potential of routes</h1>
									<div className="row">
										<h4>Filter by type of station</h4>
										<div className="row">
											<Select className='dates' options={this.state.means_transport_} onChange={this.handleMeansTransport.bind(this)} defaultValue={value_means_transport} styles={selectDate} />
										</div>
									</div>
									<div className="row">
										<h4>Filter by agency</h4>
										<div className="row">
											<AsyncSelect noOptionsMessage={({inputValue}) => !inputValue ? "Write an agency name" : "No results found"}  className='agency' placeholder='Select an agency...' styles={selectDate} cacheOptions loadOptions={this.loadOptions} value={selected_options_agency} onChange={this.handleOptionAgency.bind(this)} isOptionDisabled={() => this.state.selected_options_agency.length >= 4} />
										</div>
									</div>
									<div className="row">
										<h4>Filter by line/route</h4>
										<div className="row">
											<Select className='route' isClearable={true} placeholder='Select a route...' options={this.state.routes_list} styles={selectDate} value={selected_option_line} onChange={this.handleOptionLineRoute.bind(this)} />
										</div>
									</div>
								</div>
							</div>
						</div>
						{(!showRoutes)
							?
							<>
								<div className="modal-potential-routes">
									<div className="modal-potential-routes-inner">
										{resp_evaluate_company !== '' ?
											<>
												{loading ?
													<div className="data_insights_loader">
														<div className="loader"></div>
													</div>
													: <>
														<br />
														<br />
														<br />
														<br />
														<Select className='typeoftable' styles={selectStyles} options={this.state.selected_type_table} placeholder='Please select data to visualize...' onChange={this.handleTypeofTable.bind(this)} defaultValue={this.state.selected_type_table_current} />
														<br />
														{selected_type_table_current.value === 'modal_split' ?
															<Line data={dataBar_company1} options={options1} height="200px"/>
															:
															<>
																{selected_type_table_current.value === 'trips_weekdays' ?
																	<Line data={dataBar_company2} options={options2} height="200px"/>
																	:
																	<>
																		{selected_type_table_current.value === 'train_pkm' ?
																			<Line data={dataBar_company3} options={options3} height="200px"/>
																			:
																			<>
																			</>
																		}
																	</>
																}
															</>
														}
													</>}
											</> :
											<>
												<p className="no-data">Please select at least two (2) stations or an agency <br /> name and click on the button <i>Evaluate</i></p>
											</>
										}
									</div>
								</div>
							</>
							:
							<div className="modal-potential-routes">
								<div className="modal-potential-routes-inner">
									<Tabs
										defaultActiveKey="routeGenerated"
										id="uncontrolled-tab-example"
										className="mb-3"
									>
										<Tab eventKey="routeGenerated" title=" Visualization">
											{showRoutes ? <>
												<div className="fixed-box-modal">
													<div className="timeline" >
														<div>
															{this.state.array_selected_stations.map(selected_stations => (
																<div className="container right" key={selected_stations.stop_id}>
																	<div className="content">
																		<div className="row">
																			<div className="column-1">
																				<p>{selected_stations['Stop name']}</p>
																			</div>
																			<div className="column-2">
																				<div className="deleteElement" onClick={() => this.deleteElem(selected_stations.stop_id)}>
																					<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
																						<div>
																							<IoTrashOutline />
																						</div>
																					</IconContext.Provider>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															))}
														</div>
													</div>
												</div>
											</> : <>
												<p className="no-data">Please select at least two (2) stations and click on <i>Evaluate</i></p>
											</>}
										</Tab>
										<Tab eventKey="routeInfo" title=" Route's Insights">
											{resp_data_insights !== '' ? <>
												<div className="data_insights">
													<div className="row">
														<div className="column-1">
															<h4>Car passengers</h4>
														</div>
														<div className="column-2">
															<h4>{numberWithCommas(resp_data_insights.features[0].properties.car_trips_weekdays.toFixed(0))}</h4>
														</div>
													</div>
													<div className="row">
														<div className="column-1">
															<h4>Train passengers</h4>
														</div>
														<div className="column-2">
															<h4>{numberWithCommas(resp_data_insights.features[0].properties.train_trips_weekdays.toFixed(0))}</h4>
														</div>
													</div>
													<div className="row">
														<div className="column-1">
															<h4>Total passengers per day</h4>
														</div>
														<div className="column-2">
															<h4>{numberWithCommas(resp_data_insights.features[0].properties.total_trips_weekdays.toFixed(0))}</h4>
														</div>
													</div>
													<hr />
													<div className="row">
														<div className="column-1">
															<h4>Expected modal split - Trains</h4>
														</div>
														<div className="column-2">
															<h4>{numberWithCommas((resp_data_insights.features[0].properties.modal_split * 100).toFixed(0))}%</h4>
														</div>
													</div>
													<div className="row">
														<div className="column-1">
															<h4>Passenger kilometers - Trains</h4>
														</div>
														<div className="column-2">
															<h4>{numberWithCommas((resp_data_insights.features[0].properties.train_pkm).toFixed(0))}</h4>
														</div>
													</div>
													<div className="row">
														<div className="column-1">
															<h4>Passenger kilometers in the route</h4>
														</div>
														<div className="column-2">
															<h4>{numberWithCommas((resp_data_insights.features[0].properties.total_pkm).toFixed(0))}</h4>
														</div>
													</div>
												</div>
											</> : <>
												<p className="no-data">Please click on Evaluate</p>
											</>}
										</Tab>
										<Tab eventKey="stationInfo" title=" Insights per Station">
											{(resp_station_insights !== '') ? <>
												<div className="station_insights">
													<div className="row">
														<div className="column_15">
															<h4 className="title-h4">Passengers per day</h4>
															<table className="table_in_and_out">
																<tbody>
																	<tr>
																		<td><h5 className="incoming">Arriving</h5></td>
																	</tr>
																	<tr>
																		<td><h5>{numberWithCommas(resp_station_insights.total_in.toFixed(0))}</h5></td>
																	</tr>
																</tbody>
															</table>
														</div>
														<div className="column_70">
															<table className="table_in_and_out">
																<tbody>
																	{resp_station_insights.stations_entering.length > 0 ? <>
																		<tr className="tr-name">
																			<td><h4>{this.nameStation(resp_station_insights.stations_entering[0][0])}</h4></td>
																			<td></td>
																			<td></td>
																			<td></td>
																		</tr>
																		<tr>
																			<td><h5 className="incoming" id='incoming_1'>{numberWithCommas(resp_station_insights.stations_entering[0][1].toFixed(0))}</h5>
																			</td>
																			<td></td>
																			<td></td>
																			<td></td>
																		</tr>
																	</> : <>
																		<tr className="tr-name">
																			<td><h4 className="blank">blank</h4></td>
																			<td></td>
																			<td></td>
																			<td></td>
																		</tr>
																		<tr>
																			<td><h5 className="blank" id='incoming_1'>blank</h5></td>
																			<td></td>
																			<td></td>
																			<td></td>
																		</tr>
																	</>}
																	{resp_station_insights.stations_entering.length > 1 ? <>
																		<tr className="tr-name">
																			<td></td>
																			<td><h4>{this.nameStation(resp_station_insights.stations_entering[1][0])}</h4></td>
																			<td></td>
																			<td></td>
																		</tr>
																		<tr>
																			<td></td>
																			<td><h5 className="incoming" id='incoming_2'>{numberWithCommas(resp_station_insights.stations_entering[1][1].toFixed(0))}</h5></td>
																			<td></td>
																			<td></td>
																		</tr>
																	</> : <>
																		<tr className="tr-name">
																			<td></td>
																			<td><h4 className="blank">blank</h4></td>
																			<td></td>
																			<td></td>
																		</tr>
																		<tr>
																			<td></td>
																			<td><h5 className="blank" id='incoming_2'>blank</h5></td>
																			<td></td>
																			<td></td>
																		</tr>
																	</>}
																	{resp_station_insights.stations_entering.length > 2 ? <>
																		<tr className="tr-name">
																			<td></td>
																			<td></td>
																			<td><h4>{this.nameStation(resp_station_insights.stations_entering[2][0])}</h4></td>
																			<td></td>
																		</tr>
																		<tr>
																			<td></td>
																			<td></td>
																			<td><h5 className="incoming" id='incoming_3'>{numberWithCommas(resp_station_insights.stations_entering[2][1].toFixed(0))}</h5></td>
																			<td></td>
																		</tr>
																	</> : <>
																		<tr className="tr-name">
																			<td></td>
																			<td></td>
																			<td><h4 className="blank">blank</h4></td>
																			<td></td>
																		</tr>
																		<tr>
																			<td></td>
																			<td></td>
																			<td><h5 className="blank" id='incoming_2'>blank</h5></td>
																			<td></td>
																		</tr>
																	</>}
																	{resp_station_insights.stations_entering.length > 3 ? <>
																		<tr className="tr-name">
																			<td></td>
																			<td></td>
																			<td></td>
																			<td><h4>{this.nameStation(resp_station_insights.stations_entering[3][0])}</h4></td>
																		</tr>
																		<tr>
																			<td id="incoming_1_end"></td>
																			<td id="incoming_2_end"></td>
																			<td id="incoming_3_end"></td>
																			<td><h5 className="incoming">{numberWithCommas(resp_station_insights.stations_entering[3][1].toFixed(0))}</h5></td>
																		</tr>
																	</> : <>
																		<tr className="tr-name">
																			<td></td>
																			<td></td>
																			<td></td>
																			<td><h4 className="blank">blank</h4></td>
																		</tr>
																		<tr>
																			<td id="incoming_1_end"></td>
																			<td id="incoming_2_end"></td>
																			<td id="incoming_3_end"></td>
																			<td><h5 className="blank">blank</h5></td>
																		</tr>
																	</>}
																</tbody>
															</table>

															{resp_station_insights.stations_entering.length > 0 ? <>
																<Xarrow start="incoming_1" end="incoming_1_end" color='#5B5B5B' strokeWidth={2} tailSize={2} endAnchor="bottom" startAnchor="bottom" />
															</> : <></>}
															{resp_station_insights.stations_entering.length > 1 ? <>
																<Xarrow start="incoming_2" end="incoming_2_end" color='#5B5B5B' strokeWidth={2} tailSize={2} endAnchor="bottom" startAnchor="bottom" />
															</> : <></>}
															{resp_station_insights.stations_entering.length > 2 ? <>
																<Xarrow start="incoming_3" end="incoming_3_end" color='#5B5B5B' strokeWidth={2} tailSize={2} endAnchor="bottom" startAnchor="bottom" />
															</> : <></>}

															{resp_station_insights.stations_leaving.length > 0 ? <>
																<Xarrow start="incoming_4_end" end="incoming_4" color='#5B5B5B' strokeWidth={2} tailSize={2}
																	endAnchor="top" startAnchor="top" />
															</> : <></>}
															{resp_station_insights.stations_leaving.length > 1 ? <>
																<Xarrow start="incoming_5_end" end="incoming_5" color='#5B5B5B' strokeWidth={2} tailSize={2} endAnchor="top" startAnchor="top" />
															</> : <></>}
															{resp_station_insights.stations_leaving.length > 2 ? <>
																<Xarrow start="incoming_6_end" end="incoming_6" color='#5B5B5B' strokeWidth={2} tailSize={2} endAnchor="top" startAnchor="top" />
															</> : <></>}

															<div>
																<table className="table_select" >
																	<tbody>
																		<tr>
																			<td><Select className='station_analysis' styles={selectStyles} options={this.state.selected_stations} placeholder='Please select a station' onChange={this.stationInsightsHandler.bind(this)} defaultValue={this.state.selected_station_select_react} /></td>
																		</tr>
																	</tbody>
																</table>
															</div>

															<table className="table_in_and_out">
																<tbody>
																	{resp_station_insights.stations_leaving.length > 3 ? <>
																		<tr className="tr-name">
																			<td id="incoming_4_end"></td>
																			<td id="incoming_5_end"></td>
																			<td id="incoming_6_end"></td>
																			<td><h4>{this.nameStation(resp_station_insights.stations_leaving[3][0])}</h4></td>
																		</tr>
																		<tr>
																			<td></td>
																			<td></td>
																			<td></td>
																			<td><h5 className="departure">{resp_station_insights.stations_leaving[3][1].toFixed(0)}</h5></td>
																		</tr>
																	</> : <>
																		<tr className="tr-name">
																			<td id="incoming_4_end"></td>
																			<td id="incoming_5_end"></td>
																			<td id="incoming_6_end"></td>
																			<td><h4 className="blank">blank</h4></td>
																		</tr>
																		<tr>
																			<td></td>
																			<td></td>
																			<td></td>
																			<td><h5 className="blank">blank</h5></td>
																		</tr>
																	</>}
																	{resp_station_insights.stations_leaving.length > 2 ? <>
																		<tr className="tr-name">
																			<td></td>
																			<td></td>
																			<td><h4 id='incoming_6'>{this.nameStation(resp_station_insights.stations_leaving[2][0])}</h4></td>
																			<td></td>
																		</tr>
																		<tr>
																			<td></td>
																			<td></td>
																			<td><h5 className="departure">{resp_station_insights.stations_leaving[2][1].toFixed(0)}</h5></td>
																			<td></td>
																		</tr>
																	</> : <>
																		<tr className="tr-name">
																			<td></td>
																			<td></td>
																			<td><h4 className="blank">blank</h4></td>
																			<td></td>
																		</tr>
																		<tr>
																			<td></td>
																			<td></td>
																			<td><h5 className="blank">blank</h5></td>
																			<td></td>
																		</tr>
																	</>}
																	{resp_station_insights.stations_leaving.length > 1 ? <>
																		<tr className="tr-name">
																			<td></td>
																			<td><h4 id='incoming_5'>{this.nameStation(resp_station_insights.stations_leaving[1][0])}</h4></td>
																			<td></td>
																			<td></td>
																		</tr>
																		<tr>
																			<td></td>
																			<td><h5 className="departure">{resp_station_insights.stations_leaving[1][1].toFixed(0)}</h5></td>
																			<td></td>
																			<td></td>
																		</tr>
																	</> : <>
																		<tr className="tr-name">
																			<td></td>
																			<td><h4 className="blank">blank</h4></td>
																			<td></td>
																			<td></td>
																		</tr>
																		<tr>
																			<td></td>
																			<td><h5 className="blank">blank</h5></td>
																			<td></td>
																			<td></td>
																		</tr>
																	</>}
																	{resp_station_insights.stations_leaving.length > 0 ? <>
																		<tr className="tr-name">
																			<td><h4 id='incoming_4'>{this.nameStation(resp_station_insights.stations_leaving[0][0])}</h4></td>
																			<td></td>
																			<td></td>
																			<td></td>
																		</tr>
																		<tr>
																			<td><h5 className="departure">{resp_station_insights.stations_leaving[0][1].toFixed(0)}</h5></td>
																			<td></td>
																			<td></td>
																			<td></td>
																		</tr>
																	</> : <>
																		<tr className="tr-name">
																			<td><h4 className="blank">blank</h4></td>
																			<td></td>
																			<td></td>
																			<td></td>
																		</tr>
																		<tr>
																			<td><h5 className="blank">blank</h5></td>
																			<td></td>
																			<td></td>
																			<td></td>
																		</tr>
																	</>}
																</tbody>
															</table>
														</div>
														<div className="column_15">
															<h4 className="title-h4">Passengers per day</h4>
															<table className="table_in_and_out">
																<tbody>
																	<tr>
																		<td><h5 className="departure">Departing</h5></td>
																	</tr>
																	<tr>
																		<td><h5>{numberWithCommas(resp_station_insights.total_out.toFixed(0))}</h5></td>
																	</tr>
																</tbody>
															</table>
														</div>
													</div>
												</div>
												<Form onClick={this.handleFullScreenAnalysis.bind(this)}>
													<Button variant="info" className="generate-btn">Full Screen Analysis</Button>
												</Form>
											</> : <>
												{resp_data_insights !== '' ? <>
													<p className="no-data">Select a station to see the upcoming and outgoing traffic to that station</p>
													<p className="no-data-italic">Disclamer: If two stations share the same postal code, the numbers of passengers will be the same </p>
													<table className="table_select" >
														<tbody>
															<tr>
																<td><Select className='estacion_analysis' styles={selectStyles} options={this.state.selected_stations} placeholder='Please select a station' onChange={this.stationInsightsHandler.bind(this)} /></td>
															</tr>
														</tbody>
													</table>
													<Form onClick={this.handleFullScreenAnalysis.bind(this)}>
														<Button variant="info" className="generate-btn">Full Screen Analysis</Button>
													</Form>
												</> :
													< p className="no-data">Please click on Evaluate</p>}
											</>}
										</Tab>
										<Tab eventKey="modalsplit" title=" Metrics Over Time">
											{resp_data_insights !== '' ? <>
												{loading ?
													<div className="data_insights_loader">
														<div className="loader"></div>
													</div>
													: <>
														<br />
														<br />
														<Select className='typeoftable' styles={selectStyles} options={this.state.selected_type_table} placeholder='Please select data to visualize...' onChange={this.handleTypeofTable.bind(this)} defaultValue={this.state.selected_type_table_current} />
														<br />
														{selected_type_table_current.value === 'modal_split' ?
															<Line data={dataBar1} options={options1} height="200px"/>
															:
															<>
																{selected_type_table_current.value === 'trips_weekdays' ?
																	<Line data={dataBar2} options={options2} height="200px"/>
																	:
																	<>
																		{selected_type_table_current.value === 'train_pkm' ?
																			<Line data={dataBar3} options={options3} height="200px"/>
																			:
																			<>
																			</>
																		}
																	</>
																}
															</>
														}
													</>}
											</> : <>
												<p className="no-data">Please click on <i>Evaluate</i></p>
											</>}
										</Tab>
									</Tabs>
								</div>
							</div>
						}

					</>}
				<div className="map-month-year-picker">
					<Select className='dates' options={this.state.dates_trips_} onChange={this.handleMonthYear.bind(this)} styles={selectDate} defaultValue={value_od_dates} />
				</div>
			</div >
		)
	}
}

//Map - Potential of existing and new routes
class MappPotentialRoutes extends React.Component {

	// Set up states for updating map 
	constructor(props) {
		super(props);
		this.state = {
			lng: 8.536524,
			lat: 46.844411,
			zoom: 7,
			mapita: 9,
			tyle: 'mapbox://styles/mapbox/light-v10',
			month_year: '2022-08',
			hasOriginDestination: false,
			array_selected_stations: [],
			showRoutes: false,
			hideNavs: false,
			selected_route_agency: ''
		}
		this.handleTyle = this.handleTyle.bind(this);
		this.deleteElem = this.deleteElem.bind(this);
	}

	//Update several fields
	updateFields(list) {
		this.setState(list);
	}

	//Update map on component update
	updateField(card, value) {
		this.setState({
			...this.state,
			[card]: value
		});
	}

	//Update coordinates
	handleCoord(center, zoom) {
		const { lng, lat } = center;
		this.setState({ zoom: zoom, lat: lat, lng: lng })
	}

	//Update style
	handleTyle(value) {
		this.setState({ tyle: value }, this.componentDidMount);
	}

	//Delete element on the array of selected stations
	deleteElem(id_elem) {

		const target = this.state.array_selected_stations.find(e => e.stop_id === id_elem);
		const index = this.state.array_selected_stations.indexOf(target);
		if (index > -1) { // only splice array when item is found
			let array_without_elem = this.state.array_selected_stations
			array_without_elem.splice(index, 1) // 2nd parameter means remove one item only
			this.setState({ array_selected_stations: array_without_elem });
		}
		return target;
	}

	componentDidMount() {
		// Set up map 
		const map = new mapboxgl.Map({
			container: this.mapContainer,
			style: this.state.tyle,
			center: [this.state.lng, this.state.lat],
			zoom: this.state.zoom,
		});

		map.on('load', () => {
			// Add zoom and rotation controls to the map.
			map.addControl(new mapboxgl.NavigationControl({ showCompass: false, showZoom: false }), 'top-left');
			map.addControl(new MapboxGeocoder({ accessToken: mapboxgl.accessToken, mapboxgl: mapboxgl, marker: false }), 'top-left');

			//Add shape to the mqp
			// Geometry for plz and tiles
			map.addSource('data-swiss-tile-plz', {
				'type': 'geojson',
				'data': URL_lines,
				'generateId': true

			});

			map.addLayer({
				'id': 'data-swiss-tile-plz',
				'type': 'fill',
				'source': 'data-swiss-tile-plz',
				'layout': {
				},
				'paint': {
					'fill-color': '#027F80',
					'fill-opacity': 0.4
				}
			});

			// Add a black outline around the polygon
			map.addLayer({
				'id': 'outline-data-swiss-tile-plz',
				'type': 'line',
				'source': 'data-swiss-tile-plz',
				'layout': {
				},
				'paint': {
					'line-color': '#434343',
					'line-width': 1
				}
			});

			//Lines - Routes
			//Add shapes to the map
			map.addSource('data-lines-routes', {
				'type': 'geojson',
				'data': URL_lines,
				'generateId': true

			});
			map.addLayer({
				'id': 'data-lines-routes',
				'type': 'line',
				'source': 'data-lines-routes',
				'layout': {
				},
				'paint': {
					'line-color': [
						'case',
						['boolean', ['feature-state', 'clicked'], false],
						'#eb34b1', // if selected true, paint in pink
						['get', 'color'] // else paint
					],
					'line-width': 3
				},
			});

			//Lines - Generated Route
			//Add shapes to the map
			map.addSource('data-lines-generated-routes', {
				'type': 'geojson',
				'data': {
					"type": "Feature",
					"properties": {},
					"geometry": generated_line
				},
				'generateId': true

			});
			map.addLayer({
				'id': 'data-lines-generated-routes',
				'type': 'line',
				'source': 'data-lines-generated-routes',
				'layout': {
				},
				'paint': {
					'line-color': '#eb34b1',
					'line-width': 3
				},
			});

			//Circles - Stations
			//Add shapes to the map
			map.addSource('data-circles-stations', {
				'type': 'geojson',
				'data': URL_points,
				'generateId': true

			});
			map.addLayer({
				'id': 'data-circles-stations',
				'type': 'circle',
				'source': 'data-circles-stations',
				'layout': {
				},
				'paint': {
					'circle-radius': 3,
					"circle-stroke-width": 1,
					"circle-stroke-color": [
						'case',
						['boolean', ['feature-state', 'clicked'], false],
						'#3aeb34', // if selected true, paint in pink
						'#eb34b1' // else paint
					],
					'circle-color': [
						'case',
						['boolean', ['feature-state', 'clicked'], false],
						'#3aeb34', // if selected true, paint in pink
						'#eb34b1' // else paint
					],
					"circle-opacity": 0.5,
				},
			});
		});

		this.setState({
			mapita: map,
			zoom: map.getZoom(),
		});

		// Create a popup, but don't add it to the map yet.
		const popupHover = new mapboxgl.Popup({
			closeButton: false,
			closeOnClick: false
		});

		// Change the cursor to a pointer when the mouse is over the places layer.
		map.on('mouseenter', 'data-circles-stations', (e) => {
			map.getCanvas().style.cursor = 'pointer';
			let stop_name = e.features[0].properties['Stop name']

			let html = '<h4 style="margin-block-start:0px; margin-block-end:0px;">Stop Name</h4><p style="margin-block-start:0px; margin-block-end: 0em;">' + stop_name + '</p>';
			popupHover
				.setLngLat(e.lngLat)
				.setHTML(html).addTo(map);
		});

		//Click function - Add to Array
		map.on('click', 'data-circles-stations', (e) => {
			map.getCanvas().style.cursor = 'pointer';
			let circleID = e.features[0].id;

			map.setFeatureState({
				source: 'data-circles-stations',
				id: circleID,
			}, {
				clicked: true
			});

			let current_array = this.state.array_selected_stations;

			let elem_id = e.features[0].properties.stop_id;
			const target = this.state.array_selected_stations.find(e => e.stop_id === elem_id);
			const index = this.state.array_selected_stations.indexOf(target);
			if (index === -1) {
				let elems = e.features[0].properties
				elems['circle_map_id'] = circleID
				current_array.push(elems)
				if (current_array.length > 1) {
					this.updateFields({ showRoutes: true, array_selected_stations: current_array })
				}
				else {
					this.updateFields({ showRoutes: false, array_selected_stations: current_array })
				}
			}
		});

		// Change it back to a pointer when it leaves.
		map.on('mouseleave', 'data-circles-stations', () => {
			map.getCanvas().style.cursor = '';
			popupHover.remove();
		});

		//ROUTES FOR COMPANIES

		// Change the cursor to a pointer when the mouse is over the places layer.
		map.on('mouseenter', 'data-lines-routes', (e) => {
			map.getCanvas().style.cursor = 'pointer';
		});

		// Change it back to a pointer when it leaves.
		map.on('mouseleave', 'data-lines-routes', () => {
			map.getCanvas().style.cursor = '';
		});

		//Click function - Add to Array
		map.on('click', 'data-lines-routes', (e) => {
			map.getCanvas().style.cursor = 'pointer';

			let dict = {
				"label": e.features[0].properties.route_short_name,
				"value": e.features[0].properties.route_id
			}

			this.updateFields({ selected_route_agency: dict })
		});
	}

	render() {
		const { hideNavs } = this.state;

		return (
			<div>
				<div ref={el => this.mapContainer = el} style={{ width: '100%', height: '100vh' }}>
				</div>
				<RecommendationNavBarComponent />
				<HideElementsComponent map={this.state.mapita} updateField={(card, field, value) => this.updateField(card, field, value)} />
				<div style={{ display: hideNavs ? 'none' : 'block' }}>
						<PotentialRoutesInfo map={this.state.mapita} array_selected_stations={this.state.array_selected_stations} showRoutes={this.state.showRoutes} deleteElem={(id_elem) => this.deleteElem(id_elem)} updateFields={(list) => this.updateFields(list)} selected_route_agency={this.state.selected_route_agency} />
					</div>
				<LayerTylesComponent map={this.state.mapita} tyle={this.state.tyle} handleTyle={(value) => this.handleTyle(value)} handleCoord={(value1, value2) => this.handleCoord(value1, value2)} />
			</div>
		)
	}
}

export default MappPotentialRoutes;
