import { withAuthenticationRequired } from "@auth0/auth0-react";
import LayerMenuComponent from './../components/LayerMenu'
import React from "react";

export const ProtectedRoute = ({ component }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <div className="pos-center">
        <div className="loader"></div>
      </div>
    ),
  });

  return(
    <div>
      <Component />
      <LayerMenuComponent />
    </div>
  )
};