import React from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '../css/map-drop-point.css';
import { Form, Button } from 'react-bootstrap';
import { IconContext } from "react-icons";
import { BsLayersHalf } from "react-icons/bs";
import RecommendationNavBarComponent from "../../components/RecommendationNavBarComponent";
import { RiFullscreenFill, RiFullscreenExitFill } from "react-icons/ri";
import { useParams } from "react-router-dom";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ExportExcel from "../../utils/ExportExcel";
import { Line } from 'react-chartjs-2';
import { VscGraphLine } from "react-icons/vsc";
import Select from 'react-select';
import axios from 'axios';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const URL = process.env.REACT_APP_URL_SERVER_BACK;

let generatedTiles = false;
let typeOfTile = 'tile1km';
let dayofweek = 'weekdays';

const delay = ms => new Promise(res => setTimeout(res, ms));

let tile_empty = {
	'type': 'FeatureCollection',
	'features': [
		{
			'type': 'Feature',
			'geometry': {
				"type": "LineString",
				"coordinates": [
				]
			}
		}
	]
};

function getMax(arr) {
	var max = 0;
	for (var i = 0; i < arr.length; i++) {
		if (parseInt(arr[i]) > parseInt(max))
			max = arr[i];
	}
	return max;
}

let tile_1km_geojson = tile_empty;
let tile_1km_visibility = 'visible';
let tile_100m_geojson = tile_empty;
let tile_100m_visibility = 'none';
let tile_building_geojson = tile_empty;
let tile_building_visibility = 'none';

const _ = require('lodash');

function numberWithCommas(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
}

class HideElementsComponent extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			hideNavs: false,
			mapa: this.props.map,
		}
	}

	hideComponent() {
		let newHideNavs = !this.state.hideNavs;
		this.setState({ hideNavs: newHideNavs })
		this.props.updateField("hideNavs", newHideNavs);
	}

	render() {

		const { hideNavs } = this.state;

		return (
			<div>
				{hideNavs ? <div className='map-close-navs'>
						<div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
								<div>
									<RiFullscreenExitFill />
								</div>
							</IconContext.Provider>
						</div>
					</div> : <div className='map-close-navs'>
						<div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
								<div>
									<RiFullscreenFill />
								</div>
							</IconContext.Provider>
						</div>
					</div>}
			</div>
		)
	}
}

const lines_geojson = {
	'type': 'FeatureCollection',
	'features': [
		{
			'type': 'Feature',
			'geometry': {
				"type": "LineString",
				"coordinates": [
				]
			}
		}
	]
};

const point_geojson = {
	'type': 'FeatureCollection',
	'features': [
		{
			'type': 'Feature',
			'geometry': {
				'type': 'Point',
				'coordinates': [8.251313, 46.924143]
			}
		}
	]
};

//Layer Tyles Selection - Side Menu
class LayerTylesComponent extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			showOptions: false,
			mapa: this.props.map,
		}
		this.hideComponent = this.hideComponent.bind(this);
		this.handleTyleEvent = this.handleTyleEvent.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (this.props.map !== prevProps.map) {
			this.setState({ mapa: this.props.map })
		}
	}

	hideComponent(name) {
		this.setState({ showOptions: !this.state.showOptions });
	}

	handleTyleEvent(event) {
		switch (event) {
			case "default":
				this.props.handleTyle("mapbox://styles/mapbox/light-v10");
				break;
			case "outdoors":
				this.props.handleTyle("mapbox://styles/mapbox/outdoors-v11");
				break;
			case "satellite":
				this.props.handleTyle("mapbox://styles/mapbox/satellite-streets-v11");
				break;
			case "streets":
				this.props.handleTyle("mapbox://styles/mapbox/streets-v11");
				break;
			case "dark":
				this.props.handleTyle("mapbox://styles/mapbox/dark-v10");
				break;
			default:
				// eslint-disable-next-line no-unused-expressions
				null;
		}
		this.props.handleCoord(this.state.mapa.getCenter(), this.state.mapa.getZoom());
	}

	render() {
		const { showOptions } = this.state;

		return (
			<div>
				{showOptions ? <div id='layer-options' className='map-layer-options-tyles'>
						<div className='map-layer-option-dark' onClick={() => this.handleTyleEvent("dark")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/Dark.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Dark</p>
						</div>
						<div className='map-layer-option-streets' onClick={() => this.handleTyleEvent("streets")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/Streets.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Streets</p>
						</div>
						<div className='map-layer-option-satellite' onClick={() => this.handleTyleEvent("satellite")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/Satellite.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Satellite</p>
						</div>
						<div className='map-layer-option-outdoors' onClick={() => this.handleTyleEvent("outdoors")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/Outdoors.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Outdoors</p>
						</div>
						<div className='map-layer-option-default' onClick={() => this.handleTyleEvent("default")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/White.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Default</p>
						</div>
					</div> : <></>}
				<div className='map-layer-tyles'>
					<div className='map-layer-tyles-inner' onClick={() => this.hideComponent("showOptions")}>
						<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
							<div>
								<BsLayersHalf />
							</div>
						</IconContext.Provider>
					</div>
				</div>
			</div>
		)
	}
}

class GraphComponent extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			mapa: this.props.map,
			graphParam: this.props.graphParam,
			take_od: 0,
			respModalPKM: [],
			respModalTrips: [],
			respTraveledKm: [],
			showGraph: false
		}
	}

	componentDidMount() {
		this.handleFetchDataModalPKM = this.handleFetchDataModalPKM.bind(this);
		this.handleFetchDataModalTrips = this.handleFetchDataModalTrips.bind(this);
		this.handleFetchDataTraveledKm = this.handleFetchDataTraveledKm.bind(this);
		this.handleGraphComponent = this.handleGraphComponent.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (this.props.map !== prevProps.map) {
			this.setState({ mapa: this.props.map });
		}
		if (this.props.graphParam !== prevProps.graphParam) {
			this.setState({ graphParam: this.props.graphParam });
			if (this.props.graphParam === '') {
				this.setState({ showGraph: false })
			} else {
				this.handleFetchDataModalPKM(this.props.graphParam);
				this.handleFetchDataModalTrips(this.props.graphParam);
				this.handleFetchDataTraveledKm(this.props.graphParam);
			}
		}
	}

	async handleFetchDataModalTrips(param) {
		fetch(URL + 'tile_od/modal_split_trips/' + param)
			.then((res) => res.json())
			.then((json) => {
				for (var i = 0; i < json.modal_split_trains.length; i++) {
					json.modal_split_trains[i] = json.modal_split_trains[i] * 100
				}
				return json
			})
			.then((json) => {
				this.setState({ respModalTrips: json });
			})
	}

	async handleFetchDataModalPKM(param) {
		fetch(URL + 'tile_od/modal_split_pkm/' + param)
			.then((res) => res.json())
			.then((json) => {
				for (var i = 0; i < json.modal_split.length; i++) {
					json.modal_split[i] = json.modal_split[i] * 100
				}
				return json
			})
			.then((json) => {
				this.setState({ respModalPKM: json });
			})
	}

	async handleFetchDataTraveledKm(param) {
		fetch(URL + 'tile_od/traveled_km/' + param)
			.then((res) => res.json())
			.then((json) => {
				this.setState({ respTraveledKm: json });
			})
	}

	getExcelPKm() {
		var all = [];
		var length = this.state.respTraveledKm['date']?.length
		if (length !== 0 || length !== 'undefined') {
			for (var i = 0; i < length; i++) {
				all.push({
					"Date": this.state.respTraveledKm['date'][i],
					"Passenger Km Car": this.state.respTraveledKm['traveled_km_cars'][i],
					"Passenger Km Train": this.state.respTraveledKm['traveled_km_trains'][i]
				})
			}
		}
		return all;
	}

	handleGraphComponent() {
		this.setState({ showGraph: !this.state.showGraph });
	}

	getExcelModalTrips() {
		let all = [];
		var length = this.state.respModalTrips['date']?.length
		if (length !== 0 || length !== 'undefined') {
			for (var i = 0; i < length; i++) {
				all.push({
					"Date": this.state.respModalTrips['date'][i],
					"Modal Split Trains": (this.state.respModalTrips['modal_split_trains'][i]) + '%',
				})
			}
		}
		return all;
	}

	getExcelModalPKM() {
		var all = [];
		var length = this.state.respModalPKM['date']?.length
		if (length !== 0 || length !== 'undefined') {
			for (var i = 0; i < length; i++) {
				all.push({
					"Date": this.state.respModalPKM['date'][i],
					"Modal Split Trains": (this.state.respModalPKM['modal_split'][i]) + '%',
				})
			}
		}
		return all;
	}

	render() {
		const { respModalPKM } = this.state;
		const { respModalTrips } = this.state;
		const { showGraph } = this.state;
		const { respTraveledKm } = this.state;

		const ExcelExportDataModalTrips = this.getExcelModalTrips();
		const ExcelExportDataModalPKM = this.getExcelModalPKM();
		const ExcelExportDataPKm = this.getExcelPKm();

		const dataBar_ModalPKM = {
			labels: respModalPKM['date'],
			datasets: [{
				label: 'Modal Split for Trains',
				data: respModalPKM['modal_split'],
				backgroundColor: [
					'rgba(17, 148, 209, 0.2)'
				],
				borderColor: [
					'rgb(17, 148, 209)'
				],
				borderWidth: 1
			},
			]
		};

		const dataBar_ModalTrips = {
			labels: respModalTrips['date'],
			datasets: [{
				label: 'Modal Split for Trains',
				data: respModalTrips['modal_split_trains'],
				backgroundColor: [
					'rgba(221, 138, 230, 0.2)'
				],
				borderColor: [
					'rgb(221, 138, 230)'
				],
				borderWidth: 1
			},
			]
		}

		const dataBar_TraveledKm = {
			labels: respTraveledKm['date'],
			datasets: [{
				label: 'Traveled Km by Trains',
				data: respTraveledKm['traveled_km_trains'],
				backgroundColor: [
					'rgba(247, 137, 72, 0.2)'
				],
				borderColor: [
					'rgb(247, 137, 72)'
				],
				borderWidth: 1
			},
			{
				label: 'Traveled Km by Cars',
				data: respTraveledKm['traveled_km_cars'],
				backgroundColor: [
					'rgba(2,127,128,0.2)'
				],
				borderColor: [
					'rgb(2,127,128)'
				],
				borderWidth: 1
			},
			]
		};


		const options = {
			xAxes: [{
				type: 'time',
				ticks: {
					autoSkip: true,
					maxTicksLimit: 10
				}
			}],
			plugins: {
				title: {
					display: true,
					text: 'Percentage of Trains\' Modal Split (PKm) per Month'
				},
				legend: {
					position: 'bottom',
				},
			},
		};

		const options_trips = {
			xAxes: [{
				type: 'time',
				ticks: {
					autoSkip: true,
					maxTicksLimit: 10
				}
			}],
			plugins: {
				title: {
					display: true,
					text: 'Percentage of Trains\' Modal Split (Trips) per Month'
				},
				legend: {
					position: 'bottom',
				},
			},
		};

		const kmPerDay = {
			xAxes: [{
				type: 'time',
				ticks: {
					autoSkip: true,
					maxTicksLimit: 10
				}
			}],
			plugins: {
				legend: {
					position: 'bottom',
				},
				title: {
					display: true,
					text: 'Km per Month'
				}
			},
		};

		return (
			<div>
				<div className='map-graph-button-modal-hotspot'>
					<div className='map-graph-button-modal-hotspot-inner' onClick={() => this.handleGraphComponent("showGraph")}>
						<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
							<div>
								<VscGraphLine />
							</div>
						</IconContext.Provider>
					</div>
				</div>
				{(showGraph && this.state.graphParam !== '') ? <div className="modal-graph-drop-point-tile">
						<div className="modal-graph-drop-point-tile-inner">
							<Tabs
								defaultActiveKey="modalsplitpkm"
								id="uncontrolled-tab-example"
								className="mb-3"
							>
								<Tab eventKey="modalsplitpkm" title="Trains' Modal Split (PKm)">
									<Line data={dataBar_ModalPKM} options={options} />
									<ExportExcel excelData={ExcelExportDataModalPKM} fileName={'Drop a point: Modal Split (PKm) - Tile'} />
								</Tab>
								<Tab eventKey="modalsplittrips" title="Trains' Modal Split (Trips)">
									<Line data={dataBar_ModalTrips} options={options_trips} />
									<ExportExcel excelData={ExcelExportDataModalTrips} fileName={'Drop a point: Modal Split (Trips) - Tile'} />
								</Tab>
								<Tab eventKey="Traveledkm" title="Passenger Km">
									<Line data={dataBar_TraveledKm} options={kmPerDay} />
									<ExportExcel excelData={ExcelExportDataPKm} fileName={'Drop a point: PassengerKm  - Tile'} />
								</Tab>
							</Tabs>
						</div>
					</div> : <></>}
			</div>
		)
	}
}

class BtnsComponent extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			mapa: this.props.map,
			generatedTyles: false,
			loading: false,
			coord: this.props.coord,
			type_of_tile: '1km',
			hasResponse: false,
			day_week: 'weekdays',
			categories_tiles: this.getOptionTypeBuilding(URL + 'areas_to_focus/building_categories'),
			current_category_: this.props.current_category_,
			showSucessWindow: false
		}
		this.handleFetchDataCoordParam()
	}

	async getOptionTypeBuilding(url) {
		const res = await axios.get(url)
		const data = res.data
		const options = data.map(d => ({
			"value": d.category,
			"label": (d.category === null ? null : d.category.split('_').join(' '))
		}))
		this.setState({ categories_tiles: options });
	}

	componentDidMount() {
		this.resetView = this.resetView.bind(this);
		this.handleVisualization = this.handleVisualization.bind(this);
		this.handleFetchDataCoordParam = this.handleFetchDataCoordParam.bind(this);
		this.handleCategoryTiles = this.handleCategoryTiles.bind(this);
	}

	handleFetchDataCoordParam() {
		if (this.state.coord['*'] !== "") {
			generatedTiles = true
			if (this.state.coord['*'].toString().indexOf(',') > -1) {
				this.setState({ loading: true });
				point_geojson.features[0].geometry.coordinates = [parseFloat(this.state.coord['*'].split(',')[0]), parseFloat(this.state.coord['*'].split(',')[1])];
				this.setState({ generatedTyles: !this.state.generatedTyles });
				this.handleVisualizationCoord(parseFloat(this.state.coord['*'].split(',')[0]), parseFloat(this.state.coord['*'].split(',')[1]), '1km', 'weekdays');
			} else {
				this.setState({ loading: true });
				this.setState({ generatedTyles: !this.state.generatedTyles });
				this.handleVisualizationTileID(parseInt(this.state.coord['*']), '1km', 'weekdays');
			}
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.map !== prevProps.map) {
			this.setState({ mapa: this.props.map });
		}
		if (this.props.coord !== prevProps.coord) {
			this.setState({ coord: this.props.coord });
		}
	}

	handleVisualizationTileID(tileID, type_of_tile, day_week) {
		let id_DF = tileID

		fetch(URL + 'areas_to_focus/tile/' + id_DF)
			.then((res) => res.json())
			.then((json) => {
				const tyle_corner = {
					center: [((json.features[0].geometry.coordinates[0][1][0] + json.features[0].geometry.coordinates[0][2][0]) / 2), ((json.features[0].geometry.coordinates[0][2][1] + json.features[0].geometry.coordinates[0][3][1]) / 2)],
					zoom: 14.5,
					pitch: 0,
					bearing: 0
				}
				this.props.handleCoordMoveToTyle(((json.features[0].geometry.coordinates[0][1][0] - json.features[0].geometry.coordinates[0][2][0]) / 2), ((json.features[0].geometry.coordinates[0][2][1] - json.features[0].geometry.coordinates[0][3][1]) / 2), 14.5, json)
				this.props.map.flyTo({
					...tyle_corner, // Fly to the selected target
					duration: 2000, // Animate over 2 seconds
					essential: false // This animation is considered essential with
					//respect to prefers-reduced-motion
				})
				this.props.updateFields({ graphParam: '' + id_DF })

				this.props.updateField('tileId_mdb', json.features[0].properties.tileId)
				let coordTemp = [((json.features[0].geometry.coordinates[0][1][0] + json.features[0].geometry.coordinates[0][2][0]) / 2), ((json.features[0].geometry.coordinates[0][2][1] + json.features[0].geometry.coordinates[0][3][1]) / 2)];
				this.props.updateField("coord", coordTemp)
				this.handlerTypeTile(type_of_tile)
				this.setState({ hasResponse: true })
				tile_1km_geojson = URL + 'areas_to_focus/tile/' + id_DF;
				point_geojson.features[0].geometry.coordinates = [(json.features[0].geometry.coordinates[0][1][0] + json.features[0].geometry.coordinates[0][2][0]) / 2, ((json.features[0].geometry.coordinates[0][2][1] + json.features[0].geometry.coordinates[0][3][1]) / 2)];
				//this.props.map.getSource('data-swiss-tyle').setData(json);

				return json;
			}).then((json_past)=>{
				fetch(URL+'areas_to_focus/category_tile/'+id_DF)
				.then((res) => res.json())
				.then((json) =>{
					if(json.building!=={}){
						this.props.updateFields({current_category_: {'label': json.building, 'value': json.building}})
					}
				})

				return json_past;
			}).then((json) => {
				tile_building_geojson = URL + 'areas_to_focus/return_buildings/' + id_DF + '/all';
				tile_100m_geojson = URL + 'areas_to_focus/100m_tile_grid/' + day_week + '/' + id_DF;

				this.props.map.getSource('data-swiss-tyle-100m').setData(tile_100m_geojson);
				this.props.map.getSource('data-swiss-buldings-tile').setData(tile_building_geojson);
				this.props.updateFields({ graphParam: '' + id_DF })
				generatedTiles = true

				this.props.map.getSource('data-swiss-tyle-100m').setData({
					'type': 'FeatureCollection',
					'features': [
						{
							'type': 'Feature',
							'geometry': {
								'type': 'Point',
								'coordinates': [(json.features[0].geometry.coordinates[0][1][0] + json.features[0].geometry.coordinates[0][2][0]) / 2, ((json.features[0].geometry.coordinates[0][2][1] + json.features[0].geometry.coordinates[0][3][1]) / 2)]
							}
						}
					]
				})
			})
	}

	handleVisualizationCoord(x, y, type_of_tile, day_week) {
		let id_DF = 0

		fetch(URL + 'areas_to_focus/tile/coord/' + x + ',' + y)
			.then((res) => res.json())
			.then((json) => id_DF = json.tileId)
			.then(() => {
				fetch(URL + 'areas_to_focus/tile/' + id_DF)
					.then((res) => res.json())
					.then((json) => {
						const tyle_corner = {
							center: [((json.features[0].geometry.coordinates[0][1][0] + json.features[0].geometry.coordinates[0][2][0]) / 2), ((json.features[0].geometry.coordinates[0][2][1] + json.features[0].geometry.coordinates[0][3][1]) / 2)],
							zoom: 14.5,
							pitch: 0,
							bearing: 0
						}
						this.props.handleCoordMoveToTyle(((json.features[0].geometry.coordinates[0][1][0] + json.features[0].geometry.coordinates[0][2][0]) / 2), ((json.features[0].geometry.coordinates[0][2][1] + json.features[0].geometry.coordinates[0][3][1]) / 2), 14.5, json)
						this.props.map.flyTo({
							...tyle_corner, // Fly to the selected target
							duration: 2000, // Animate over 2 seconds
							essential: false // This animation is considered essential with
							//respect to prefers-reduced-motion
						})
						this.props.updateFields({ graphParam: '' + id_DF })

						this.props.updateField('tileId_mdb', json.features[0].properties.tileId)
						let coordTemp = [((json.features[0].geometry.coordinates[0][1][0] + json.features[0].geometry.coordinates[0][2][0]) / 2), ((json.features[0].geometry.coordinates[0][2][1] + json.features[0].geometry.coordinates[0][3][1]) / 2)];
						this.props.updateField("coord", coordTemp)
						this.handlerTypeTile(type_of_tile)
						this.setState({ hasResponse: true })
						
					}).then(()=>{
						fetch(URL+'areas_to_focus/category_tile/'+id_DF)
						.then((res) => res.json())
						.then((json) =>{
							console.log(json.building, 'llego')
							if(json.building!=={}){
								this.props.updateFields({current_category_: {'label': json.building, 'value': json.building}})
							}
						})
					})
			}).then(() => {
				tile_building_geojson = URL + 'areas_to_focus/return_buildings/' + id_DF + '/all';
				tile_100m_geojson = URL + 'areas_to_focus/100m_tile_grid/' + this.state.day_week + '/' + id_DF;
				tile_1km_geojson = URL + 'areas_to_focus/tile/' + id_DF;
				this.props.map.getSource('data-swiss-tyle').setData(tile_1km_geojson);
				this.props.map.getSource('data-swiss-tyle-100m').setData(tile_100m_geojson);
				this.props.map.getSource('data-swiss-buldings-tile').setData(tile_building_geojson);
				this.props.updateFields({ graphParam: '' + id_DF })
				generatedTiles = true
			})
	}

	handleVisualization() {

		let id_DF = 0

		fetch(URL + 'areas_to_focus/tile/coord/' + point_geojson.features[0].geometry.coordinates[0] + ',' + point_geojson.features[0].geometry.coordinates[1])
			.then((res) => res.json())
			.then((json) => id_DF = json.tileId)
			.then(() => {
				fetch(URL + 'areas_to_focus/tile/' + id_DF)
					.then((res) => res.json())
					.then((json) => {
						const tyle_corner = {
							center: [((json.features[0].geometry.coordinates[0][1][0] + json.features[0].geometry.coordinates[0][2][0]) / 2), ((json.features[0].geometry.coordinates[0][2][1] + json.features[0].geometry.coordinates[0][3][1]) / 2)],
							zoom: 14.5,
							pitch: 0,
							bearing: 0
						}
						this.props.handleCoordMoveToTyle(((json.features[0].geometry.coordinates[0][1][0] + json.features[0].geometry.coordinates[0][2][0]) / 2), ((json.features[0].geometry.coordinates[0][2][1] + json.features[0].geometry.coordinates[0][3][1]) / 2), 14.5, json)
						this.props.map.flyTo({
							...tyle_corner, // Fly to the selected target
							duration: 2000, // Animate over 2 seconds
							essential: false // This animation is considered essential with
							//respect to prefers-reduced-motion
						})
						this.props.map.getSource('data-swiss-tyle').setData(json);
						this.props.updateField('tileId_mdb', json.features[0].properties.tileId)
						let coordTemp = [((json.features[0].geometry.coordinates[0][1][0] + json.features[0].geometry.coordinates[0][2][0]) / 2), ((json.features[0].geometry.coordinates[0][2][1] + json.features[0].geometry.coordinates[0][3][1]) / 2)];
						this.props.updateField("coord", coordTemp)
						this.handlerTypeTile(this.state.type_of_tile)
						this.setState({ hasResponse: true })
						tile_1km_geojson = URL + 'areas_to_focus/tile/' + id_DF;
					}).then(()=>{
						fetch(URL+'areas_to_focus/category_tile/'+id_DF)
						.then((res) => res.json())
						.then((json) =>{
							if(json.building!=={}){
								this.props.updateFields({current_category_: {'label': json.building, 'value': json.building}})
							}
						})
					});
			}).then(() => {
				tile_building_geojson = URL + 'areas_to_focus/return_buildings/' + id_DF + '/all';
				tile_100m_geojson = URL + 'areas_to_focus/100m_tile_grid/' + this.state.day_week + '/' + id_DF;

				this.props.map.getSource('data-swiss-tyle-100m').setData(tile_100m_geojson);
				this.props.map.getSource('data-swiss-buldings-tile').setData(tile_building_geojson);
				this.props.updateFields({ graphParam: '' + id_DF })
				generatedTiles = true
			})
	}

	handlerTypeTile(event) {
		typeOfTile = event;
		if (typeOfTile === 'tile100m') {
			this.props.map.setLayoutProperty('data-swiss-tyle', 'visibility', 'none');
			this.props.map.setLayoutProperty('outline-data-swiss-tyle', 'visibility', 'none');
			this.props.map.setLayoutProperty('data-swiss-tyle-100m', 'visibility', 'visible');
			this.props.map.setLayoutProperty('outline-data-swiss-tyle-100m', 'visibility', 'visible');
			this.props.map.setLayoutProperty('data-swiss-tyle-100m-labels', 'visibility', 'visible');
			this.props.map.setLayoutProperty('data-swiss-buldings-tile', 'visibility', 'none');
			this.props.map.setLayoutProperty('outline-data-swiss-buldings-tile', 'visibility', 'none');

			tile_1km_visibility = 'none';
			tile_100m_visibility = 'visible';
			tile_building_visibility = 'none';
		}
		else if (typeOfTile === 'tile1km') {
			this.props.map.setLayoutProperty('data-swiss-tyle', 'visibility', 'visible');
			this.props.map.setLayoutProperty('outline-data-swiss-tyle', 'visibility', 'visible');
			this.props.map.setLayoutProperty('data-swiss-tyle-100m', 'visibility', 'none');
			this.props.map.setLayoutProperty('outline-data-swiss-tyle-100m', 'visibility', 'none');
			this.props.map.setLayoutProperty('data-swiss-tyle-100m-labels', 'visibility', 'none');
			this.props.map.setLayoutProperty('data-swiss-buldings-tile', 'visibility', 'none');
			this.props.map.setLayoutProperty('outline-data-swiss-buldings-tile', 'visibility', 'none');

			tile_1km_visibility = 'visible';
			tile_100m_visibility = 'none';
			tile_building_visibility = 'none';
		}
		else if (typeOfTile === 'tilebulding') {
			this.props.map.setLayoutProperty('data-swiss-buldings-tile', 'visibility', 'visible');
			this.props.map.setLayoutProperty('outline-data-swiss-buldings-tile', 'visibility', 'visible');
			this.props.map.setLayoutProperty('data-swiss-tyle', 'visibility', 'none');
			this.props.map.setLayoutProperty('outline-data-swiss-tyle', 'visibility', 'none');
			this.props.map.setLayoutProperty('data-swiss-tyle-100m', 'visibility', 'none');
			this.props.map.setLayoutProperty('outline-data-swiss-tyle-100m', 'visibility', 'none');
			this.props.map.setLayoutProperty('data-swiss-tyle-100m-labels', 'visibility', 'none');

			tile_1km_visibility = 'none';
			tile_100m_visibility = 'none';
			tile_building_visibility = 'visible';
		}
	}

	handler_type_tile(event) {
		typeOfTile = event.target.attributes.getNamedItem('id').value;
		if (typeOfTile === 'tile100m') {
			this.props.map.setLayoutProperty('data-swiss-tyle', 'visibility', 'none');
			this.props.map.setLayoutProperty('outline-data-swiss-tyle', 'visibility', 'none');
			this.props.map.setLayoutProperty('data-swiss-tyle-100m', 'visibility', 'visible');
			this.props.map.setLayoutProperty('outline-data-swiss-tyle-100m', 'visibility', 'visible');
			this.props.map.setLayoutProperty('data-swiss-tyle-100m-labels', 'visibility', 'visible');
			this.props.map.setLayoutProperty('data-swiss-buldings-tile', 'visibility', 'none');
			this.props.map.setLayoutProperty('outline-data-swiss-buldings-tile', 'visibility', 'none');

			tile_1km_visibility = 'none';
			tile_100m_visibility = 'visible';
			tile_building_visibility = 'none';
		}
		else if (typeOfTile === 'tile1km') {
			this.props.map.setLayoutProperty('data-swiss-tyle', 'visibility', 'visible');
			this.props.map.setLayoutProperty('outline-data-swiss-tyle', 'visibility', 'visible');
			this.props.map.setLayoutProperty('data-swiss-tyle-100m', 'visibility', 'none');
			this.props.map.setLayoutProperty('outline-data-swiss-tyle-100m', 'visibility', 'none');
			this.props.map.setLayoutProperty('data-swiss-tyle-100m-labels', 'visibility', 'none');
			this.props.map.setLayoutProperty('data-swiss-buldings-tile', 'visibility', 'none');
			this.props.map.setLayoutProperty('outline-data-swiss-buldings-tile', 'visibility', 'none');

			tile_1km_visibility = 'visible';
			tile_100m_visibility = 'none';
			tile_building_visibility = 'none';
		}
		else if (typeOfTile === 'tilebulding') {
			this.props.map.setLayoutProperty('data-swiss-buldings-tile', 'visibility', 'visible');
			this.props.map.setLayoutProperty('outline-data-swiss-buldings-tile', 'visibility', 'visible');
			this.props.map.setLayoutProperty('data-swiss-tyle', 'visibility', 'none');
			this.props.map.setLayoutProperty('outline-data-swiss-tyle', 'visibility', 'none');
			this.props.map.setLayoutProperty('data-swiss-tyle-100m', 'visibility', 'none');
			this.props.map.setLayoutProperty('outline-data-swiss-tyle-100m', 'visibility', 'none');
			this.props.map.setLayoutProperty('data-swiss-tyle-100m-labels', 'visibility', 'none');

			tile_1km_visibility = 'none';
			tile_100m_visibility = 'none';
			tile_building_visibility = 'visible';
		}
	}

	resetView() {
		//TODO
		this.props.map.getSource('data-swiss-buldings-tile').setData(lines_geojson);
		this.props.map.getSource('data-swiss-tyle-100m').setData(lines_geojson);
		this.props.map.getSource('data-swiss-tyle').setData(lines_geojson);
		this.props.updateFields({ graphParam: '' })

		this.setState({ hasResponse: false });

		const tyle_corner = {
			center: [8.251313, 46.924143],
			zoom: 7,
			pitch: 0,
			bearing: 0
		}

		this.props.map.flyTo({
			...tyle_corner, // Fly to the selected target
			duration: 2000, // Animate over 2 seconds
			essential: false // This animation is considered essential with
			//respect to prefers-reduced-motion
		})

		generatedTiles = false

	}

	handleCategoryTiles(event){
		this.props.updateFields({ current_category_: event });
	}

	//TODO
	resetCategory(){

	}

	assignCategory(){
		let id_DF = 0;

		console.log(point_geojson.features[0].geometry.coordinates[0] + ',' + point_geojson.features[0].geometry.coordinates[1])
		fetch(URL + 'areas_to_focus/tile/coord/' + point_geojson.features[0].geometry.coordinates[0] + ',' + point_geojson.features[0].geometry.coordinates[1])
			.then((res) => res.json())
			.then((json) => id_DF = json.tileId)
			.then(() => {
				console.log(id_DF, this.props.building_id)

				let body = {}
				if(this.props.building_geometry===''){
					body = {
						"tile_id": id_DF,
						"building": this.props.current_category_.value,
						"geometry": this.props.building_geometry,
					}
				}else{
					body = {
						"tile_id": id_DF,
						"building": this.props.current_category_.value,
						"building_id": this.props.building_id
					}
				}

				fetch(URL+'areas_to_focus/category_tile', {
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(body)
				}).then(async ()=>{
					this.setState({showSucessWindow: true})
					await delay(5000);
					this.setState({showSucessWindow: false})
				})
			})
	
	}

	render() {
		const { loading } = this.state;
		const { hasResponse } = this.state;
		const { categories_tiles } = this.state;
		const { showSucessWindow } = this.state;

		const selectStyles = {
            menuList: styles => {
                return {
                    ...styles,
                    maxHeight: 150,
                };
            }
        };

		if (loading) { // if your component doesn't have to wait for an async action, remove this block 
			return (
				<div className="bg-loader">
					<div className="pos-center-brain-loader">
						<div className="circle-loading-brain">
						</div>
						<svg className='brain-loader' x="0px" y="0px" viewBox="0 0 496 496">
							<path d="M40,449.472V364.28L58.424,352H88v-16H53.576L24,355.72v93.752C14.704,452.784,8,461.584,8,472c0,13.232,10.768,24,24,24				s24-10.768,24-24C56,461.584,49.288,452.784,40,449.472z M32,480c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8s8,3.584,8,8				C40,476.416,36.416,480,32,480z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M46.528,320H88v-16H46.528c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24				C34.416,336,43.216,329.288,46.528,320z M16,312c0-4.416,3.584-8,8-8s8,3.584,8,8c0,4.416-3.584,8-8,8S16,316.416,16,312z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M24,272c10.416,0,19.216-6.712,22.528-16H88v-16H46.528c-3.312-9.296-12.112-16-22.528-16c-13.232,0-24,10.768-24,24				S10.768,272,24,272z M24,240c4.416,0,8,3.584,8,8s-3.584,8-8,8s-8-3.584-8-8C16,243.584,19.584,240,24,240z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M56 272h32v16H56Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M56 208h32v16H56Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M0 176h88v16H0Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M60.688,160H88v-16H67.312L48,124.688v-14.16C57.288,107.216,64,98.416,64,88c0-13.232-10.768-24-24-24S16,74.768,16,88				c0,10.416,6.704,19.216,16,22.528v20.784L60.688,160z M40,80c4.416,0,8,3.584,8,8s-3.584,8-8,8s-8-3.584-8-8S35.584,80,40,80z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M144 67.312L144 88L160 88L160 60.688L131.312 32L0 32L0 48L124.688 48z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M176 35.312L176 88L192 88L192 28.688L163.312 0L0 0L0 16L156.688 16z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M208 56h16v32H208Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M304 0h16v88H304Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M240,46.528V88h16V59.312l16,16V88h16V68.688L262.392,43.08C268.192,38.696,272,31.816,272,24c0-13.232-10.768-24-24-24				s-24,10.768-24,24C224,34.416,230.704,43.216,240,46.528z M248,16c4.416,0,8,3.584,8,8s-3.584,8-8,8s-8-3.584-8-8				S243.584,16,248,16z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M352,51.312L371.312,32h78.16c2.416,6.776,7.752,12.112,14.528,14.528v110.16L444.688,176H408v16h43.312L480,163.312				V46.528c9.288-3.312,16-12.112,16-22.528c0-13.232-10.768-24-24-24c-10.416,0-19.216,6.712-22.528,16h-84.784L336,44.688V88h16				V51.312z M472,16c4.416,0,8,3.584,8,8s-3.584,8-8,8c-4.416,0-8-3.584-8-8S467.584,16,472,16z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M414.08,97.392l17.92,17.92v9.376L412.688,144H408v16h11.312L448,131.312v-22.624L419.312,80h-4.784				c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24C401.904,112,410.416,105.968,414.08,97.392z				 M392,96c-4.416,0-8-3.584-8-8s3.584-8,8-8c4.416,0,8,3.584,8,8S396.416,96,392,96z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M408 208h88v16H408Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M480,449.472V268.688L451.312,240H408v16h36.688L464,275.312v41.376L451.312,304H408v16h36.688L464,339.312v110.16				c-9.296,3.312-16,12.112-16,22.528c0,13.232,10.768,24,24,24s24-10.768,24-24C496,461.584,489.288,452.784,480,449.472z M472,480				c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8c4.416,0,8,3.584,8,8C480,476.416,476.416,480,472,480z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M408 272h32v16H408Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M448,364.688L419.312,336H408v16h4.688L432,371.312V416h-80v-8h-16v24h96v12.688L412.688,464h-14.16				c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24c10.416,0,19.216-6.712,22.528-16h20.784				L448,451.312V364.688z M376,480c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8c4.416,0,8,3.584,8,8C384,476.416,380.416,480,376,480z				" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M160,408h-16v8h-41.472c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24				c10.416,0,19.216-6.712,22.528-16H160V408z M80,432c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8s8,3.584,8,8				C88,428.416,84.416,432,80,432z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M304 408h16v88H304Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M272 408h16v88H272Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M240,420.688l-16,16V408h-16v41.472c-9.296,3.312-16,12.112-16,22.528c0,13.232,10.768,24,24,24s24-10.768,24-24				c0-7.816-3.808-14.696-9.608-19.08L256,427.312V408h-16V420.688z M216,480c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8s8,3.584,8,8				C224,476.416,220.416,480,216,480z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M192 408L176 408L176 428.688L152 452.688L152 496L168 496L168 459.312L192 435.312z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M336,392h16v-16h24v-24h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16				v-16h-16v-24h-24v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-3.312				L120,140.688V144h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v24h24v16h16v-16h16				v16h16v-16h16v16h16v-16h16v16h16v-16h16v16h16v-16h16v16h16v-16h16V392z M136,360V147.312L147.312,136H360v224H136z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M318.888,211.048l13.96-13.96l-33.936-33.936l-13.96,13.96c-4.16-2.184-8.488-3.976-12.952-5.384V152h-48v19.728				c-4.472,1.408-8.8,3.2-12.952,5.384l-13.96-13.96l-33.936,33.936l13.96,13.96c-2.176,4.16-3.976,8.488-5.384,12.952H152v48				h19.728c1.408,4.472,3.2,8.8,5.384,12.952l-13.96,13.96l33.936,33.936l13.96-13.96c4.16,2.176,8.48,3.976,12.952,5.384V344h48				v-19.728c4.472-1.408,8.8-3.2,12.952-5.384l13.96,13.96l33.936-33.936l-13.96-13.96c2.176-4.152,3.976-8.48,5.384-12.952H344v-48				h-19.728C322.864,219.528,321.072,215.2,318.888,211.048z M328,256h-16.224l-1.416,6.224c-1.6,7.016-4.368,13.672-8.216,19.792				l-3.408,5.408l11.496,11.496l-11.312,11.312l-11.496-11.496l-5.408,3.408c-6.12,3.848-12.776,6.616-19.792,8.216L256,311.776V328				h-16v-16.224l-6.224-1.416c-7.016-1.6-13.672-4.368-19.792-8.216l-5.408-3.408l-11.496,11.496l-11.312-11.312l11.496-11.496				l-3.408-5.408c-3.848-6.12-6.616-12.776-8.216-19.792L184.224,256H168v-16h16.224l1.416-6.224				c1.6-7.016,4.368-13.672,8.216-19.792l3.408-5.408l-11.496-11.496l11.312-11.312l11.496,11.488l5.408-3.408				c6.12-3.848,12.776-6.616,19.792-8.216l6.224-1.408V168h16v16.224l6.224,1.416c7.016,1.6,13.672,4.368,19.792,8.216l5.408,3.408				l11.496-11.488l11.312,11.312l-11.496,11.496l3.408,5.408c3.848,6.12,6.616,12.776,8.216,19.792l1.416,6.216H328V256z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M248,208c-22.056,0-40,17.944-40,40c0,22.056,17.944,40,40,40c22.056,0,40-17.944,40-40C288,225.944,270.056,208,248,208				z M248,272c-13.232,0-24-10.768-24-24s10.768-24,24-24s24,10.768,24,24S261.232,272,248,272z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
						</svg>
					</div>
				</div>
			)
		}
		else {
			return (
				<div>
					<div className="map-btn-drop-point-ov-quality">
						<div className="map-btn-drop-point-ov-quality-inner">
							<h1>Compute the ÖV Quality <br /> for an specific Point</h1>
							<p className="label">Place the circle on the desired coordinates. Click on <i>Analyze ÖV Quality</i> to visualize the öv quality of the origin tiles of the point (set as destination).</p>
							<div className="row">
								<div className="column-1-50">
									<Form onClick={this.handleVisualization.bind(this)}>
										<Button variant="info" disabled={hasResponse} className="generate-btn">Evaluate tile</Button>
									</Form>
								</div>
								<div className="column-2-50">
									<Form onClick={this.resetView.bind(this)}>
										<Button variant="info" disabled={!hasResponse} className="reset-btn">Reset tile</Button>
									</Form>
								</div>
							</div>
						</div>
					</div>
					{hasResponse ?
						<>
							<div className="map-category-tile">
								<div className="map-category-tile-inner">
									<h1>Assigned Category</h1>
									<Select className='locations' styles={selectStyles} options={categories_tiles} onChange={this.handleCategoryTiles.bind(this)} value={this.props.current_category_}/>
									<br/>
									<div className="row">
									<Form onClick={this.assignCategory.bind(this)}>
										<Button variant="info" disabled={this.props.building_geometry===''} className="generate-btn">Assign Category</Button>
									</Form>
							</div>
								</div>
							</div>
							<div className="drop-point-hotspot-params">
								<div className="drop-point-hotspot-params-inner">
									<div className="visualization-tile">
										<h1>Visualization Tile Type</h1>
										<Form onChange={this.handler_type_tile.bind(this)}>
											<div className="row">
												<div className="column-3-1">
													<Form.Check inline className='check-label' label=" 1km Tile" name="type_analysis" type='radio' id='tile1km' defaultChecked={true} />
												</div>
												<div className="column-3-2">
													<Form.Check inline className='check-label' label=" 100m Grid" name="type_analysis" type='radio' id='tile100m' />
												</div>
												<div className="column-3-3">
													<Form.Check inline className='check-label' label=" Buildings" name="type_analysis" type='radio' id='tilebulding' />
												</div>
											</div>
										</Form>
									</div>
								</div>
							</div>
							{showSucessWindow ? 
							<>
							<div className="map-success-assigned-category" style={{right: '0'}}>
								<div className="map-success-assigned-category-inner">
									<h2>Category has been assigned successfully!</h2>
								</div>
								</div>
							</>
							:<></>}
						</>
						:
						<></>}
				</div>
			)
		}
	}
}

class MappDropPointHotspot extends React.Component {

	// Set up states for updating map 
	constructor(props) {
		super(props);
		this.state = {
			lng: 8.251313,
			lat: 46.924143,
			zoom: 7,
			mapita: 9,
			tyle: 'mapbox://styles/mapbox/satellite-streets-v11',
			hideNavs: false,
			coord: this.props.params,
			graphParam: '',
			building_geometry: '',
			building_id: '',
			circleID: '',
			current_category_: ({
				label: 'None',
				value: 'none'
			})
		}
	}

	//Update several fields
	updateFields(list) {
		this.setState(list);
	}

	//Update map on component update
	updateField(card, value) {
		this.setState({
			...this.state,
			[card]: value
		});
	}

	//Update style
	handleTyle(value) {
		this.setState({ tyle: value }, this.componentDidMount);
	}

	//Update coordinates
	handleCoord(center, zoom) {
		const { lng, lat } = center;
		this.setState({ zoom: zoom, lat: lat, lng: lng })
	}

	//Update coordinates
	handleCoordMoveToTyle(lng, lat, zoom) {
		this.setState({ zoom: zoom, lat: lat, lng: lng })
	}

	componentDidMount() {
		// Set up map 
		const map = new mapboxgl.Map({
			container: this.mapContainer,
			style: this.state.tyle,
			center: [this.state.lng, this.state.lat],
			zoom: this.state.zoom,
		});

		map.on('load', () => {

			// Add zoom and rotation controls to the map.
			map.addControl(new mapboxgl.NavigationControl({ showCompass: false, showZoom: false }), 'top-left');
			map.addControl(new MapboxGeocoder({ accessToken: mapboxgl.accessToken, mapboxgl: mapboxgl, marker: false }), 'top-left');

			// Add circle 
			map.addSource('point', {
				'type': 'geojson',
				'data': point_geojson
			});

			map.addLayer({
				'id': 'point',
				'type': 'circle',
				'source': 'point',
				'paint': {
					'circle-radius': 10,
					'circle-color': '#e32a8d' // blue color
				}
			});

			//Add shapes to the map (Tile 100m)
			map.addSource('data-swiss-tyle-100m', {
				'type': 'geojson',
				'data': tile_100m_geojson,
				'generateId': true
			});

			map.addLayer({
				'id': 'data-swiss-tyle-100m',
				'type': 'fill',
				'source': 'data-swiss-tyle-100m',
				'layout': {
					'visibility': tile_100m_visibility
				},
				'paint': {
					'fill-color': ['get', 'color'],
					'fill-opacity': [
						'case',
						['boolean', ['feature-state', 'clicked'], false],
						1, // if selected true, paint in pink
						0.4, // else paint
					]
				}
			});

			// Add a black outline around the polygon
			map.addLayer({
				'id': 'outline-data-swiss-tyle-100m',
				'type': 'line',
				'source': 'data-swiss-tyle-100m',
				'layout': {
					'visibility': tile_100m_visibility
				},
				'paint': {
					'line-color': ['get', 'color'],
					'line-width': 1
				}
			});

			map.addLayer({
				'id': 'data-swiss-tyle-100m-labels',
				'type': 'symbol',
				'source': 'data-swiss-tyle-100m',
				"minzoom": 13,
				'layout': {
					'text-field': ['get', 'percentage'],
					'text-font': [
						'Open Sans Bold',
						'Arial Unicode MS Bold'
					],
					'text-size': 13,
					'text-transform': 'uppercase',
					'text-letter-spacing': 0.05,
					'text-offset': [0, 1],
					'visibility': tile_100m_visibility
				},
				'paint': {
					"text-color": "#ffffff"
				}
			});

			//Add shapes to the map (Buildings within a tyle)
			map.addSource('data-swiss-buldings-tile', {
				'type': 'geojson',
				'data': tile_building_geojson,
				'generateId': true
			});

			map.addLayer({
				'id': 'data-swiss-buldings-tile',
				'type': 'fill',
				'source': 'data-swiss-buldings-tile',
				'layout': {
					'visibility': tile_building_visibility
				},
				'paint': {
					'fill-color': [
						'case',
						['boolean', ['feature-state', 'clicked'], false],
						'#eb34b1',
						['get', 'color']
					],
					'fill-opacity': 0.3
				}
			});

			// Add a black outline around the polygon
			map.addLayer({
				'id': 'outline-data-swiss-buldings-tile',
				'type': 'line',
				'source': 'data-swiss-buldings-tile',
				'layout': {
					'visibility': tile_building_visibility
				},
				'paint': {
					'line-color': [
						'case',
						['boolean', ['feature-state', 'clicked'], false],
						'#eb34b1',
						['get', 'color']
					],
					'line-width': 1
				}
			});

			//Add shapes to the map (Tile 1km)
			map.addSource('data-swiss-tyle', {
				'type': 'geojson',
				'data': tile_1km_geojson,
				'generateId': true
			});

			map.addLayer({
				'id': 'data-swiss-tyle',
				'type': 'fill',
				'source': 'data-swiss-tyle',
				'layout': {
					'visibility': tile_1km_visibility
				},
				'paint': {
					'fill-color': '#9d1f6c',
					'fill-opacity': 0.15
				}
			});

			// Add a black outline around the polygon
			map.addLayer({
				'id': 'outline-data-swiss-tyle',
				'type': 'line',
				'source': 'data-swiss-tyle',
				'layout': {
					'visibility': tile_1km_visibility
				},
				'paint': {
					'line-color': '#9d1f6c',
					'line-width': 1
				}
			});

		});

		this.setState({
			mapita: map,
			zoom: map.getZoom(),
			center: map.getCenter()
		});

		map.on('click', 'data-swiss-buldings-tile', (e) => {
			map.getCanvas().style.cursor = 'pointer';
			let circleID = e.features[0].id;

			if(this.state.circleID!=='')
			{
				map.setFeatureState({
					source: 'data-swiss-buldings-tile',
					id: this.state.circleID,
				}, {
					clicked: false
				});
			}

			map.setFeatureState({
				source: 'data-swiss-buldings-tile',
				id: circleID,
			}, {
				clicked: true
			});
			let category = ''
			e.features[0].properties.amenity !== 'null' ? category = e.features[0].properties.amenity : category = e.features[0].properties.building;
			this.setState({circleID: circleID, building_id: e.features[0].properties.id, building_geometry: e.features[0].geometry, current_category_: ({label: category, value: category})})
		})

		map.on('click', 'data-swiss-tyle', (e) => {
			let resp_data = ''
			fetch(URL + 'areas_to_focus/tile_traffic/' + e.features[0].properties.tileId)
				.then((res) => res.json())
				.then((json) => {
					resp_data = json[0]
					//let date = (resp_data.date).split('T')[0]

					let total_trips_weekdays_tiles = resp_data.total_trips_weekdays_tiles;
					let total_trips_weekends_tiles = resp_data.total_trips_weekends_tiles;
					let train_trips_weekdays_tiles = resp_data.train_trips_weekdays_tiles;
					let train_trips_weekends_tiles = resp_data.train_trips_weekends_tiles;
					let car_trips_weekdays_tiles = resp_data.car_trips_weekdays_tiles;
					let car_trips_weekends_tiles = resp_data.car_trips_weekends_tiles;

					let car_pkm = 0;
					let train_pkm = 0;
					let total_pkm = 0;
					if (dayofweek === 'weekdays') {
						car_pkm = resp_data.car_pkm;
						train_pkm = resp_data.train_pkm;
						total_pkm = resp_data.total_pkm;
					} else {
						car_pkm = resp_data.car_pkm_weekends;
						train_pkm = resp_data.train_pkm_weekends;
						total_pkm = resp_data.total_pkm_weekends;
					}

					let total_trips_weekdays_tiles_str = '';
					let total_trips_weekends_tiles_str = '';
					let train_trips_weekdays_tiles_str = '';
					let train_trips_weekends_tiles_str = '';
					let car_trips_weekdays_tiles_str = '';
					let car_trips_weekends_tiles_str = '';

					let car_pkm_str = '';
					let train_pkm_str = '';
					let total_pkm_str = '';

					(typeof total_trips_weekdays_tiles == 'number' & total_trips_weekdays_tiles > 0) ? total_trips_weekdays_tiles = (total_trips_weekdays_tiles).toFixed(0) : total_trips_weekdays_tiles = '-';
					(total_trips_weekdays_tiles !== '-') ? total_trips_weekdays_tiles_str = numberWithCommas(total_trips_weekdays_tiles) : total_trips_weekdays_tiles_str = '-';

					(typeof total_trips_weekends_tiles == 'number' & total_trips_weekends_tiles > 0) ? total_trips_weekends_tiles = (total_trips_weekends_tiles).toFixed(0) : total_trips_weekends_tiles = '-';
					(total_trips_weekends_tiles !== '-') ? total_trips_weekends_tiles_str = numberWithCommas(total_trips_weekends_tiles) : total_trips_weekends_tiles_str = '-';

					(typeof train_trips_weekdays_tiles == 'number' & train_trips_weekdays_tiles > 0) ? train_trips_weekdays_tiles = (train_trips_weekdays_tiles).toFixed(0) : train_trips_weekdays_tiles = '-';
					(train_trips_weekdays_tiles !== '-') ? train_trips_weekdays_tiles_str = numberWithCommas(train_trips_weekdays_tiles) : train_trips_weekdays_tiles_str = '-';

					(typeof train_trips_weekends_tiles == 'number' & train_trips_weekends_tiles > 0) ? train_trips_weekends_tiles = (train_trips_weekends_tiles).toFixed(0) : train_trips_weekends_tiles = '-';
					(train_trips_weekends_tiles !== '-') ? train_trips_weekends_tiles_str = numberWithCommas(train_trips_weekends_tiles) : train_trips_weekends_tiles_str = '-';

					(typeof car_trips_weekdays_tiles == 'number' & car_trips_weekdays_tiles > 0) ? car_trips_weekdays_tiles = (car_trips_weekdays_tiles).toFixed(0) : car_trips_weekdays_tiles = '-';
					(car_trips_weekdays_tiles !== '-') ? car_trips_weekdays_tiles_str = numberWithCommas(car_trips_weekdays_tiles) : car_trips_weekdays_tiles_str = '-';

					(typeof car_trips_weekends_tiles == 'number' & car_trips_weekends_tiles > 0) ? car_trips_weekends_tiles = (car_trips_weekends_tiles).toFixed(0) : car_trips_weekends_tiles = '-';
					(car_trips_weekends_tiles !== '-') ? car_trips_weekends_tiles_str = numberWithCommas(car_trips_weekends_tiles) : car_trips_weekends_tiles_str = '-';

					(typeof car_pkm == 'number' & car_pkm > 0) ? car_pkm = (car_pkm).toFixed(0) : car_pkm = '-';
					(car_pkm !== '-') ? car_pkm_str = numberWithCommas(car_pkm) : car_pkm_str = '-';

					(typeof train_pkm == 'number' & train_pkm > 0) ? train_pkm = (train_pkm).toFixed(0) : train_pkm = '-';
					(train_pkm !== '-') ? train_pkm_str = numberWithCommas(train_pkm) : train_pkm_str = '-';

					(typeof total_pkm == 'number' & total_pkm > 0) ? total_pkm = (total_pkm).toFixed(0) : total_pkm = '-';
					(total_pkm !== '-') ? total_pkm_str = numberWithCommas(total_pkm) : total_pkm_str = '-';

					let html_modal = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Modal Split</span></p><p style="margin-block-end: 0em;"><span style="font-weight: semibold;">No data available</span></p>';

					let cars_percentage = 0;
					let trains_percentage = 0;
					let pct_traffic_below_750m_to_station = 0;
					let pct_traffic_above_750m_to_station = 0;

					if (dayofweek === 'weekdays') {
						cars_percentage = (Number(resp_data.modal_split_weekdays_cars) * 100).toFixed(0);
						trains_percentage = (Number(resp_data.modal_split_weekdays_trains) * 100).toFixed(0);
						pct_traffic_below_750m_to_station = (resp_data.pct_traffic_below_750m_to_station * 100).toFixed(1)
						pct_traffic_above_750m_to_station = (resp_data.pct_traffic_above_750m_to_station * 100).toFixed(1)
					} else {
						cars_percentage = (Number(resp_data.modal_split_weekends_cars) * 100).toFixed(0);
						trains_percentage = (Number(resp_data.modal_split_weekends_trains) * 100).toFixed(0);
						pct_traffic_below_750m_to_station = (resp_data.pct_traffic_below_750m_to_station_weekends * 100).toFixed(1)
						pct_traffic_above_750m_to_station = (resp_data.pct_traffic_above_750m_to_station_weekends * 100).toFixed(1)
					}

					let avg_trip = resp_data.avg_trip_length.toFixed(1);

					let other_percentage = (Number(100) - (Number(cars_percentage) + Number(trains_percentage))).toFixed(0);

					if ((total_trips_weekdays_tiles !== '-') & (car_trips_weekdays_tiles !== '-') & (train_trips_weekdays_tiles !== '-')) {
						html_modal = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Modal Split</span></p><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle; width: 100%;"><thead><tr><th></th><th style="width:50%; text-align: start;">Car/Bus</th><th style="width:50%; text-align: end;"><span style="float:right; position:relative, width:50%">Trains</span></th></tr></thead><tbody><tr><th></th><th style="width:50%; text-align: start; font-weight: normal;">' + cars_percentage + '%</th><th style="width:50%; text-align: end;"><span style="float:right; position:relative, width:50%; font-weight: normal;">' + trains_percentage + '%</span></th></tr></tbody></table><span class="block" style="display: block;height: 20px;color: #fff;font-size: 1em;float: left;background-color: #F78948;position: relative;overflow: hidden;width:' + cars_percentage + '%"></span><span class="block" style="display:block; height:20px; color:#fff; font-size:1em; float:left; background-color:#2f3338; position:relative; overflow:hidden; width:' + other_percentage + '%;"></span><span class="block" style="display:block; height:20px; color:#fff; font-size:1em; float:left; background-color:#0fabab; position:relative; overflow:hidden; width:' + trains_percentage + '%;"></span>';
					}

					let html = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Average Trip Length: </span>' + avg_trip + 'km </p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Trips per day</span></p><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle;"><thead><tr><th></th><th>&#x1f46a; &#128664;</th><th>&#x1f46a; &#x1f686;</th><th>Total</th></tr></thead><tbody><tr><th>Weekdays</th><td>' + car_trips_weekdays_tiles_str + '</td><td>' + train_trips_weekdays_tiles_str + '</td><td>' + total_trips_weekdays_tiles_str + '</td></tr><tr><th>Weekends</th><td>' + car_trips_weekends_tiles_str + '</td><td>' + train_trips_weekends_tiles_str + '</td><td>' + total_trips_weekends_tiles_str + '</td></tr></tbody></table><span style="font-weight: bold;">Passenger Km per month</span><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle;"><thead><tr><th>Cars</th><th>Trains</th><th>Total</th></tr></thead><tbody><tr><td>' + car_pkm_str + ' </td><td>' + train_pkm_str + ' </td><td><span>' + total_pkm_str + ' </span></td></tr></tbody></table><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Percentage of traffic below 750m to the nearest train station: </span>' + pct_traffic_below_750m_to_station + '% </p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Percentage of traffic above 750m to the nearest train station: </span>' + pct_traffic_above_750m_to_station + '% </p>'
					new mapboxgl.Popup()
						.setLngLat(e.lngLat)
						.setHTML(html + html_modal).addTo(map);
				})

		});

		let polygonID = null;

		map.on('click', 'data-swiss-buldings-tile', (e) => {

			console.log(e.features[0].properties)

			let properties = e.features[0].properties

			let html = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Building with no category</span></p>'

			if (properties.amenity !== 'null') {
				html = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Type of building:</span></p><p style="margin-block-end: 0em;"><span style="font-weight: semibold;">' + properties.amenity + '</span></p>'
			}
			else if (properties.building !== 'null') {
				html = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Type of building:</span></p><p style="margin-block-end: 0em;"><span style="font-weight: semibold;">' + properties.building + '</span></p>'
			}

			new mapboxgl.Popup()
				.setLngLat(e.lngLat)
				.setHTML(html).addTo(map);

		})

		map.on('click', 'data-swiss-tyle-100m', (e) => {
			let resp_data = '';
			let maxValue = 0;
			let agg_html = '';

			if (e.features.length > 0) {
				if (typeof polygonID === 'number') {
					map.removeFeatureState({
						source: "data-swiss-tyle-100m",
						id: polygonID
					});
				}
				polygonID = e.features[0].id;

				map.setFeatureState({
					source: 'data-swiss-tyle-100m',
					id: polygonID,
				}, {
					clicked: true
				});
			}

			fetch(URL + 'areas_to_focus/100m_tile_traffic/' + dayofweek + '/' + e.features[0].properties.tile_Id)
				.then((res) => res.json())
				.then((json) => {
					resp_data = json;
					maxValue = getMax(resp_data.counts);

					for (let i = 0; i < resp_data.dates.length; i++) {
						let current_elem = resp_data.dates[i];
						let hour_slider = (current_elem.split('T')[1]).lastIndexOf(':');
						let hour = (current_elem.split('T')[1]).slice(0, hour_slider);
						let percentage = ((resp_data.counts[i] / maxValue) * 100).toFixed(0);

						agg_html += '<tr><td>' + hour + '</td><td style="margin: 0em; font-size:10px, font-weight:lighter; width:55%"><span class="block" style="display: block;height: 20px;color: black;font-size: 1em;float: left;background-color: rgba(247, 137, 72, 0.5); border: 1px solid #F78948; position: relative;overflow: hidden;width:' + percentage + '%"></span><td><td>' + resp_data.counts[i] + '</td></tr>';
					}
					let html = ''
					if (resp_data.total_counts === 0) {
						html = '<div><p style="margin-block-end: 0em;"><span style="font-weight: bold;">No data available for this tile</span></p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">People in the tile during the day:</span> ' + (resp_data.total_counts).toFixed(0) + '</p></div>'
					}
					else {
						html = '<div><p style="margin-block-end: 0em;"><span style="font-weight: bold;">People in the tile during the day:</span> ' + (resp_data.total_counts).toFixed(0) + '</p><br/><div><p style="margin-block-end: 0em;"><span style="font-weight: bold;">People\'s daily distribution by work/sleep hours</span></p><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle;"><tbody><tr><th>22:00 ~ 04:00 → </th><td>' + (resp_data.quartiles[0]).toFixed(0) + '</td></tr><tr><th>07:00 ~ 17:00 → </th><td>' + (resp_data.quartiles[1]).toFixed(0) + '</td></tr></tbody></table><br/><p style="margin-block-end: 0em;"><span style="font-weight: bold;">People\'s daily distribution by hours</span></p><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle; width: 100%;">' + agg_html + '</table></div>'
					}

					new mapboxgl.Popup()
						.setLngLat(e.lngLat)
						.setHTML(html).addTo(map);
				})
		});

		//On click move the point
		map.on('click', (event) => {
			const coords = event.lngLat;
			this.setState({ coords: coords })
			if (!generatedTiles) {
				// Print the coordinates of where the point had
				// finished being dragged to on the map.

				map.getCanvas().style.cursor = '';

				// Unbind mouse/touch events
				map.off('mousemove', onMove);
				map.off('touchmove', onMove);

				point_geojson.features[0].geometry.coordinates = [coords.lng, coords.lat];
				map.getSource('point').setData(point_geojson);
			}
		});

		map.on('click', 'swiss-1km-tile', (e) => {

			let resp_data = e.features[0].properties

			let total_trips_weekdays_tiles = resp_data.total_trips_weekdays_tiles
			let duration_car_text = resp_data.duration_car_text
			let duration_train_text = resp_data.duration_train_text
			let n_trains = resp_data.n_trains
			let geodesic_distance_m = (resp_data.geodesic_distance_m).toFixed(0)

			let time_in_trains = (resp_data.time_in_trains / 60).toFixed(0);
			let time_in_bus = (resp_data.time_in_bus / 60).toFixed(0);

			let html = '<table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle;"><tbody><tr><th>Total trips during weekdays per day</th></tr><tr><td>' + total_trips_weekdays_tiles + '</td></tr><tr><th>Duration in car</th></tr><tr><td>' + duration_car_text + '</td><tr><th>Duration in public transport</th></tr><tr><td>' + duration_train_text + '</td><tr><th>Number of öv vehicles</th></tr><tr><td>' + n_trains + '</td><tr><th>Distance in meters</th></tr><tr><td>' + numberWithCommas(geodesic_distance_m) + '</td></tr><th>Time in a train</th></tr><tr><td>' + time_in_trains + ' min</td><tr><th>Time in a bus</th></tr><tr><td>' + time_in_bus + ' min</td></tr></tbody></table>';

			new mapboxgl.Popup()
				.setLngLat(e.lngLat)
				.setHTML(html).addTo(map);
		});

		// When the cursor enters a feature in
		// the point layer, prepare for dragging.
		map.on('mouseenter', 'point', () => {
			map.setPaintProperty('point', 'circle-color', '#9e2e6a');
			map.getCanvas().style.cursor = 'move';
		});

		map.on('mouseleave', 'point', () => {
			map.setPaintProperty('point', 'circle-color', '#9e2e6a');
			map.getCanvas().style.cursor = '';
		});

		function onMove(e) {
			const coords = e.lngLat;
			// Set a UI indicator for dragging.
			map.getCanvas().style.cursor = 'grabbing';

			// Update the Point feature in `geojson` coordinates
			// and call setData to the source layer `point` on it.
			point_geojson.features[0].geometry.coordinates = [coords.lng, coords.lat];
			map.getSource('point').setData(point_geojson);
		}

		function onUp(e) {
			const coords = e.lngLat;
			// Print the coordinates of where the point had
			// finished being dragged to on the map.

			map.getCanvas().style.cursor = '';

			// Unbind mouse/touch events
			map.off('mousemove', onMove);
			map.off('touchmove', onMove);

			point_geojson.features[0].geometry.coordinates = [coords.lng, coords.lat];
			map.getSource('point').setData(point_geojson);
		}

		map.on('mousedown', 'point', (e) => {
			// Prevent the default map drag behavior.
			e.preventDefault();

			map.getCanvas().style.cursor = 'grab';

			map.on('mousemove', onMove);
			map.once('mouseup', onUp);
		});

		map.on('touchstart', 'point', (e) => {
			if (e.points.length !== 1) return;

			// Prevent the default map drag behavior.
			e.preventDefault();

			map.on('touchmove', onMove);
			map.once('touchend', onUp);
		});

		// Fullscreen map 
		//map.addControl(new mapboxgl.FullscreenControl());
	}

	render() {
		const { hideNavs } = this.state;

		return (
			<div>
				<div ref={el => this.mapContainer = el} style={{ width: '100%', height: '100vh' }}>
				</div>
				<RecommendationNavBarComponent />
				<HideElementsComponent map={this.state.mapita} updateField={(card, field, value) => this.updateField(card, field, value)} />
				<div style={{ display: hideNavs ? 'none' : 'block' }}>

						<GraphComponent map={this.state.mapita} graphParam={this.state.graphParam} />
						<BtnsComponent current_category_={this.state.current_category_} building_geometry={this.state.building_geometry} building_id={this.state.building_id} map={this.state.mapita} updateField={(field, value) => this.updateField(field, value)} updateFields={(list) => this.updateFields(list)} coord={this.state.coord} handleCoordMoveToTyle={(value1, value2, value3) => this.handleCoordMoveToTyle(value1, value2, value3)} />
					</div>
				<LayerTylesComponent map={this.state.mapita} tyle={this.state.tyle} handleTyle={(value) => this.handleTyle(value)} handleCoord={(value1, value2) => this.handleCoord(value1, value2)} />
			</div>
		)
	}
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => (
	<MappDropPointHotspot
		{...props}
		params={useParams()}
	/>
); 