/* eslint-disable react/style-prop-object */
import React from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import { IconContext } from "react-icons";
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { BsLayersHalf } from "react-icons/bs";
import RecommendationNavBarComponent from "../../components/RecommendationNavBarComponent";
import { RiFullscreenFill, RiFullscreenExitFill } from "react-icons/ri";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';
import Select from 'react-select';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Chart from 'chart.js/auto';
import { Line } from 'react-chartjs-2';

import '../../components/layer-tyles.css';
import '../css/regional-demand.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const URL = process.env.REACT_APP_URL_SERVER_BACK;

let current_mode = 'draw_polygon';

let draw = new MapboxDraw({
	displayControlsDefault: false,
	// Select which mapbox-gl-draw control buttons to add to the map.
	controls: {
		polygon: (current_mode === 'draw_polygon' ? true : false),
		trash: (current_mode ? true : false)
	},
	// Set mapbox-gl-draw to draw by default.
	// The user does not have to click the polygon control button first.
	defaultMode: current_mode
});

function numberWithCommas(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

let URL_lines = {
	'type': 'FeatureCollection',
	'features': [
		{
			'type': 'Feature',
			'geometry': {
				"type": "LineString",
				"coordinates": [
				]
			}
		}
	]
};

class HideElementsComponent extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			hideNavs: false,
			mapa: this.props.map,
		}
	}

	hideComponent() {
		let newHideNavs = !this.state.hideNavs;
		this.setState({ hideNavs: newHideNavs })
		this.props.updateField("hideNavs", newHideNavs);
	}

	render() {

		const { hideNavs } = this.state;

		return (
			<div>
				{hideNavs ? <>
					<div className='map-close-navs'>
						<div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
								<div>
									<RiFullscreenExitFill />
								</div>
							</IconContext.Provider>
						</div>
					</div>
				</> : <>
					<div className='map-close-navs'>
						<div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
								<div>
									<RiFullscreenFill />
								</div>
							</IconContext.Provider>
						</div>
					</div>
				</>}
			</div>
		)
	}
}

//Layer Tyles Selection - Side Menu
class LayerTylesComponent extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			showOptions: false,
			mapa: this.props.map,
		}
		this.hideComponent = this.hideComponent.bind(this);
		this.handleTyleEvent = this.handleTyleEvent.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (this.props.map !== prevProps.map) {
			this.setState({ mapa: this.props.map })
		}
	}

	hideComponent(name) {
		switch (name) {
			case "showOptions":
				this.setState({ showOptions: !this.state.showOptions });
				break;
			default:
				// eslint-disable-next-line no-unused-expressions
				null;
		}
	}

	handleTyleEvent(event) {
		switch (event) {
			case "default":
				this.props.handleTyle("mapbox://styles/mapbox/light-v10");
				break;
			case "outdoors":
				this.props.handleTyle("mapbox://styles/mapbox/outdoors-v11");
				break;
			case "satellite":
				this.props.handleTyle("mapbox://styles/mapbox/satellite-streets-v11");
				break;
			case "streets":
				this.props.handleTyle("mapbox://styles/mapbox/streets-v11");
				break;
			case "dark":
				this.props.handleTyle("mapbox://styles/mapbox/dark-v10");
				break;
			default:
				// eslint-disable-next-line no-unused-expressions
				null;
		}
		this.props.handleCoord(this.state.mapa.getCenter(), this.state.mapa.getZoom());
	}

	render() {
		const { showOptions } = this.state;

		return (
			<div>
				{showOptions ? <>
					<div id='layer-options' className='map-layer-options-tyles'>
						<div className='map-layer-option-dark' onClick={() => this.handleTyleEvent("dark")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/Dark.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Dark</p>
						</div>
						<div className='map-layer-option-streets' onClick={() => this.handleTyleEvent("streets")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/Streets.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Streets</p>
						</div>
						<div className='map-layer-option-satellite' onClick={() => this.handleTyleEvent("satellite")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/Satellite.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Satellite</p>
						</div>
						<div className='map-layer-option-outdoors' onClick={() => this.handleTyleEvent("outdoors")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/Outdoors.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Outdoors</p>
						</div>
						<div className='map-layer-option-default' onClick={() => this.handleTyleEvent("default")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/White.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Default</p>
						</div>
					</div>
				</> : <></>}
				<div className='map-layer-tyles'>
					<div className='map-layer-tyles-inner' onClick={() => this.hideComponent("showOptions")}>
						<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
							<div>
								<BsLayersHalf />
							</div>
						</IconContext.Provider>
					</div>
				</div>
			</div>
		)
	}
}

class BtnModal extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			mapa: this.props.map,
			origin_geojson: this.props.origin_geojson,
			destination_geojson: this.props.destination_geojson,
			current_stage: this.props.current_stage,
			isGeometrySelected: this.props.isGeometrySelected,
			dates_trips_: this.getOptionsDate(URL + 'db_od_trips'),
			response_od_flow: '',
			loading: false,
			value_od_dates: ({
				"value": "2022-08",
				"label": '2022-08'
			}),
			value_filtering_scope: ({
				"value": "plz",
				"label": 'Postal Codes'
			}),
			data_filtering_scope_: [
				{ value: 'bfs', label: 'Municipalities' },
				{ value: 'plz', label: 'Postal Codes' },
				{ value: 'tiles', label: 'Tiles' }
			],
			respWvsW: {},
			respModalPKM: {},
			respModalTrips: {},
			respTraveledKm: {},
			respCO2: {},
			respTopOD: [],
			top_by: 'cars'
		}
	}

	async getOptionsDate(url) {
		const res = await axios.get(url)
		const data = res.data
		const options = data.map(d => ({
			"value": d._id.date.toString().split('T')[0].slice(0, -3),
			"label": d._id.date.toString().split('T')[0].slice(0, -3)
		}))
		this.setState({ dates_trips_: options });
		//this.setState({ value_od_dates: options[options.length - 1] });
	}

	async handleTopODContributors(date, origin, destination, filtering_scope, top_by) {
		fetch(URL + 'od_analyzer/graph_top_destinations', {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ filtering_scope: filtering_scope, destination_geometry: destination, origin_geometry: origin, date: date, tob_by: top_by })
		}).then((res) => res.json())
			.then((json) => {
				this.setState({ respTopOD: json });
			})
	}

	componentDidUpdate(prevProps) {
		if (this.props.map !== prevProps.map) {
			this.setState({ mapa: this.props.map });
		}
		if (this.props.origin_geojson !== prevProps.origin_geojson) {
			this.setState({ origin_geojson: this.props.origin_geojson });
		}
		if (this.props.destination_geojson !== prevProps.destination_geojson) {
			this.setState({ destination_geojson: this.props.destination_geojson });
		}
		if (this.props.current_stage !== prevProps.current_stage) {
			this.setState({ current_stage: this.props.current_stage });
		}
		if (this.props.isGeometrySelected !== prevProps.isGeometrySelected) {
			this.setState({ isGeometrySelected: this.props.isGeometrySelected });
		}
		if (this.props.current_stage === 'final' && (this.props.origin_geojson !== prevProps.origin_geojson || this.props.destination_geojson !== prevProps.destination_geojson || this.props.filtering_scope !== prevProps.filtering_scope)) {
			this.handleFetchData('od_analyzer/graph_weekdays_vs_weekends', this.props.origin_geojson.geometry, this.props.destination_geojson.geometry, this.state.value_filtering_scope.value)
			this.handleFetchData('od_analyzer/graph_modal_split_pkm', this.props.origin_geojson.geometry, this.props.destination_geojson.geometry, this.state.value_filtering_scope.value)
			this.handleFetchData('od_analyzer/graph_modal_split_trips', this.props.origin_geojson.geometry, this.props.destination_geojson.geometry, this.state.value_filtering_scope.value)
			this.handleFetchData('od_analyzer/graph_traveled_km', this.props.origin_geojson.geometry, this.props.destination_geojson.geometry, this.state.value_filtering_scope.value)
			this.handleFetchData('od_analyzer/graph_co2_emissions', this.props.origin_geojson.geometry, this.props.destination_geojson.geometry, this.state.value_filtering_scope.value)
			this.handleTopODContributors(this.state.value_od_dates.value, this.props.origin_geojson.geometry, this.props.destination_geojson.geometry, this.state.value_filtering_scope.value, this.state.top_by)
		}
		else if (this.props.origin_geojson !== prevProps.origin_geojson || this.props.destination_geojson !== prevProps.destination_geojson || this.props.filtering_scope !== prevProps.filtering_scope) {
			this.setState({ origin_geojson: this.props.origin_geojson, destination_geojson: this.props.destination_geojson, filtering_scope: this.props.filtering_scope });
		}
	}

	handleMonthYear(event) {
		this.setState({ value_od_dates: event });
	}

	handleFilteringScope(event) {
		this.setState({ value_filtering_scope: event });
		this.props.updateFields({ value_filtering_scope: event.value })

		if(this.state.current_stage === 'final'){
			this.handleEvaluateRegion()
		}else{
			if (this.state.origin_geojson.length > 0) {
				let body = {
					"geometry": this.state.origin_geojson.geometry,
					"date": this.state.value_od_dates.value,
					"filtering_scope": event.value
				}
	
				fetch(URL + 'od_analyzer/geometry', {
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(body)
				}).then((res) => res.json())
					.then((json) => {
						this.props.map.getSource('data-origin-polygon').setData(json);
					})
			}
	
			if (this.state.destination_geojson.length > 0) {
				let body = {
					"geometry": this.state.destination_geojson.geometry,
					"date": this.state.value_od_dates.value,
					"filtering_scope": event.value
				}
	
				fetch(URL + 'od_analyzer/geometry', {
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(body)
				}).then((res) => res.json())
					.then((json) => {
						this.props.map.getSource('data-destination-polygon').setData(json);
					})
			}
		}
	}

	handleOriginSelection() {
		let body = {
			"geometry": this.state.origin_geojson.geometry,
			"date": this.state.value_od_dates.value,
			"filtering_scope": this.state.value_filtering_scope.value
		}

		fetch(URL + 'od_analyzer/geometry', {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(body)
		}).then((res) => res.json())
			.then((json) => {
				this.props.map.getSource('data-origin-polygon').setData(json);
			}).then(() => {
				draw.deleteAll()
				this.props.updateFields({ current_stage: 'destination', isGeometrySelected: false })
				current_mode = 'draw_polygon'
				draw.changeMode('draw_polygon', { controls: { polygon: true, trash: false } });
			})
	}

	handleDestinationSelection() {
		let body = {
			"geometry": this.state.destination_geojson.geometry,
			"date": this.state.value_od_dates.value,
			"filtering_scope": this.state.value_filtering_scope.value
		}

		fetch(URL + 'od_analyzer/geometry', {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(body)
		}).then((res) => res.json())
			.then((json) => {
				this.props.map.getSource('data-destination-polygon').setData(json);
			}).then(() => {
				draw.deleteAll()
				this.props.updateFields({ current_stage: 'params', isGeometrySelected: false })
				current_mode = 'default'
			})


	}

	async handleFetchData(url, origin, destination, filtering_scope) {
		fetch(URL + url, {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ filtering_scope: filtering_scope, destination_geometry: destination, origin_geometry: origin })
		}).then((res) => res.json())
			.then((json) => {
				if (url.split('/')[1] === 'graph_weekdays_vs_weekends') {
					this.setState({ respWvsW: json })
				} else if (url.split('/')[1] === 'graph_modal_split_pkm') {
					this.setState({ respModalPKM: json })
				} else if (url.split('/')[1] === 'graph_modal_split_trips') {
					this.setState({ respModalTrips: json })
				} else if (url.split('/')[1] === 'graph_traveled_km') {
					this.setState({ respTraveledKm: json })
				} else if (url.split('/')[1] === 'graph_co2_emissions') {
					this.setState({ respCO2: json })
				}
			})
	}

	componentDidMount() {
		this.handleOriginSelection = this.handleOriginSelection.bind(this);
		this.handleDeleteDestination = this.handleDeleteDestination.bind(this);
		this.handleDeleteOrigin = this.handleDeleteOrigin.bind(this);
		this.handleFilteringScope = this.handleFilteringScope.bind(this);
		this.handleFetchData = this.handleFetchData.bind(this);
	}

	handleDeleteOrigin() {
		this.props.map.getSource('data-origin-polygon').setData(URL_lines);
		draw.deleteAll()
		this.props.updateFields({ current_stage: 'origin', isGeometrySelected: false })
		current_mode = 'draw_polygon'
		draw.changeMode('draw_polygon', { controls: { polygon: true, trash: false } });
	}

	handleDeleteDestination() {
		this.props.map.getSource('data-destination-polygon').setData(URL_lines);
		draw.deleteAll()
		this.props.updateFields({ current_stage: 'destination', isGeometrySelected: false })
		current_mode = 'draw_polygon'
		draw.changeMode('draw_polygon', { controls: { polygon: true, trash: false } });
	}

	handleEvaluateRegion() {
		let body = {
			"origin_geometry": this.state.origin_geojson.geometry,
			"destination_geometry": this.state.destination_geojson.geometry,
			"date": this.state.value_od_dates.value,
			"filtering_scope": this.state.value_filtering_scope.value
		}

		let data = []

		this.props.updateFields({ value_filtering_scope: this.state.value_filtering_scope.value })
		this.setState({ loading: true });
		fetch(URL + 'od_analyzer/geometries', {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(body)
		}).then((res) => res.json())
			.then((json) => {
				data = json;
				this.props.map.getSource('data-geometries-polygon').setData(data);
				this.props.map.getSource('data-destination-polygon').setData(URL_lines);
				this.props.map.getSource('data-origin-polygon').setData(URL_lines);
			}).then(() => {
				fetch(URL + 'od_analyzer/od_flow', {
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(body)
				}).then((res) => res.json())
					.then((json) => {
						this.handleFetchData('od_analyzer/graph_weekdays_vs_weekends', this.props.origin_geojson.geometry, this.props.destination_geojson.geometry, this.state.value_filtering_scope.value)
						this.handleFetchData('od_analyzer/graph_modal_split_pkm', this.props.origin_geojson.geometry, this.props.destination_geojson.geometry, this.state.value_filtering_scope.value)
						this.handleFetchData('od_analyzer/graph_modal_split_trips', this.props.origin_geojson.geometry, this.props.destination_geojson.geometry, this.state.value_filtering_scope.value)
						this.handleFetchData('od_analyzer/graph_traveled_km', this.props.origin_geojson.geometry, this.props.destination_geojson.geometry, this.state.value_filtering_scope.value)
						this.handleFetchData('od_analyzer/graph_co2_emissions', this.props.origin_geojson.geometry, this.props.destination_geojson.geometry, this.state.value_filtering_scope.value)
						this.handleTopODContributors(this.state.value_od_dates.value, this.props.origin_geojson.geometry, this.props.destination_geojson.geometry, this.state.value_filtering_scope.value, this.state.top_by)

						this.setState({ response_od_flow: json, loading: false, current_stage: 'final' })
					})
			})
	}

	handleReset() {
		current_mode = 'draw_polygon'
		draw.changeMode('draw_polygon', { controls: { polygon: true, trash: false } });

		this.setState({
			response_od_flow: '', loading: false, value_od_dates: ({
				"value": "2022-08",
				"label": '2022-08'
			}),
			value_filtering_scope: ({
				"value": "plz",
				"label": 'Postal Codes'
			}),
			respWvsW: {},
			respModalPKM: {},
			respModalTrips: {},
			respTraveledKm: {},
			respCO2: {},
			top_by: 'cars'
		});

		this.props.updateFields({ origin_geojson: [], destination_geojson: [], isGeometrySelected: false, current_stage: 'origin', value_filtering_scope: "plz" })

		draw.deleteAll();

		this.props.map.getSource('data-geometries-polygon').setData(URL_lines);
		this.props.map.getSource('data-origin-polygon').setData(URL_lines);
		this.props.map.getSource('data-destination-polygon').setData(URL_lines);
		current_mode = 'draw_polygon'
	}

	schemaType(event) {
		this.setState({ top_by: event.target.attributes.getNamedItem('id').value })
		this.handleTopODContributors(this.state.value_od_dates.value, this.props.origin_geojson.geometry, this.props.destination_geojson.geometry, this.state.value_filtering_scope.value, event.target.attributes.getNamedItem('id').value)
	}

	render() {

		const { isGeometrySelected } = this.state;
		const { value_filtering_scope } = this.state;
		const { value_od_dates } = this.state;
		const { loading } = this.state;

		const listODTrips = this.state.respTopOD?.origin?.map((trip, index) =>
			<tr key={index}>
				{(this.state.value_filtering_scope.value !== 'tiles') ?
					<>
						<td className="table-data-od">{this.state.respTopOD?.origin[index]}~{this.state.respTopOD?.destination[index]}</td>
						<td className="table-data-od">{this.state.respTopOD?.origin_name[index]}</td>
						<td className="table-data-od">{this.state.respTopOD?.destination_name[index]}</td>
					</> :
					<>
						<td className="table-data-od">{this.state.respTopOD?.origin[index]}</td>
						<td className="table-data-od">{this.state.respTopOD?.destination[index]}</td>
					</>
				}
				<td className="table-data-od">{numberWithCommas(this.state.respTopOD?.pkm_cars[index]?.toFixed(0))}</td>
				<td className="table-data-od">{numberWithCommas(this.state.respTopOD?.pkm_trains[index]?.toFixed(0))}</td>
			</tr>
		);

		const selectDate = {
			menuList: styles => {
				return {
					...styles,
					maxHeight: 150,
					borderWidth: 0,
					boxShadow: '0 0 10px 2px rgb(0 0 0 / 10%)',
				};
			}
		};

		const { respWvsW } = this.state;
		const { respModalPKM } = this.state;
		const { respModalTrips } = this.state;
		const { respTraveledKm } = this.state;
		const { respCO2 } = this.state;
		const { current_stage } = this.state;

		const dataBar_WvsW = {
			labels: respWvsW['date'],
			datasets: [{
				label: 'Weekdays',
				data: respWvsW['total_trips_weekdays'],
				backgroundColor: [
					'rgba(2, 128, 6,0.2)'
				],
				borderColor: [
					'rgb(2, 128, 6)'

				],
				borderWidth: 1
			},
			{
				label: 'Weekends',
				data: respWvsW['total_trips_weekend'],
				backgroundColor: [
					'rgba(128, 2, 63, 0.2)'
				],
				borderColor: [
					'rgb(128, 2, 63)'
				],
				borderWidth: 1
			},
			]
		};

		const dataBar_ModalPKM = {
			labels: respModalPKM['date'],
			datasets: [{
				label: 'Modal Split for Trains',
				data: respModalPKM['modal_split'],
				backgroundColor: [
					'rgba(17, 148, 209, 0.2)'
				],
				borderColor: [
					'rgb(17, 148, 209)'
				],
				borderWidth: 1
			},
			]
		};

		const dataBar_ModalTrips = {
			labels: respModalTrips['date'],
			datasets: [{
				label: 'Modal Split for Trains',
				data: respModalTrips['modal_split_trains'],
				backgroundColor: [
					'rgba(221, 138, 230, 0.2)'
				],
				borderColor: [
					'rgb(221, 138, 230)'
				],
				borderWidth: 1
			},
			]
		}

		const dataBar_TraveledKm = {
			labels: respTraveledKm['date'],
			datasets: [{
				label: 'Traveled Km by Trains',
				data: respTraveledKm['traveled_km_trains'],
				backgroundColor: [
					'rgba(247, 137, 72, 0.2)'
				],
				borderColor: [
					'rgb(247, 137, 72)'
				],
				borderWidth: 1
			},
			{
				label: 'Traveled Km by Cars',
				data: respTraveledKm['traveled_km_cars'],
				backgroundColor: [
					'rgba(2,127,128,0.2)'
				],
				borderColor: [
					'rgb(2,127,128)'
				],
				borderWidth: 1
			},
			]
		};

		const options = {
			xAxes: [{
				type: 'time',
				ticks: {
					autoSkip: true,
					maxTicksLimit: 10
				}
			}],
			plugins: {
				title: {
					display: true,
					text: 'Percentage of Trains\' Modal Split (PKm) per Month'
				},
				legend: {
					position: 'bottom',
				},
			},
		};

		const options_trips = {
			xAxes: [{
				type: 'time',
				ticks: {
					autoSkip: true,
					maxTicksLimit: 10
				}
			}],
			plugins: {
				title: {
					display: true,
					text: 'Percentage of Trains\' Modal Split (Trips) per Month'
				},
				legend: {
					position: 'bottom',
				},
			},
		};

		const kmPerDay = {
			xAxes: [{
				type: 'time',
				ticks: {
					autoSkip: true,
					maxTicksLimit: 10
				}
			}],
			plugins: {
				legend: {
					position: 'bottom',
				},
				title: {
					display: true,
					text: 'Km per Month'
				}
			},
		};

		const numTripsPerDay = {
			xAxes: [{
				type: 'time',
				ticks: {
					autoSkip: true,
					maxTicksLimit: 10
				}
			}],
			plugins: {
				legend: {
					position: 'bottom',
				},
				title: {
					display: true,
					text: 'Number of Trips per Month'
				}
			},
		};

		const dataBar_CO2 = {
			labels: respCO2['date'],
			datasets: [{
				label: 'Tons of CO2 Emitted by Cars',
				data: respCO2['tons_of_co2_car_pkm'],
				backgroundColor: [
					'rgba(227,42,141,0.2)'
				],
				borderColor: [
					'rgb(227,42,141)'
				],
				borderWidth: 1
			},
			{
				label: 'Tons of CO2 Emitted by Trains',
				data: respCO2['tons_of_co2_train_pkm'],
				backgroundColor: [
					'rgba(31, 43, 177,0.2)'
				],
				borderColor: [
					'rgb(31, 43, 177)'
				],
				borderWidth: 1
			},
			]
		};

		const CO2_options = {
			xAxes: [{
				type: 'time',
				ticks: {
					autoSkip: true,
					maxTicksLimit: 10
				}
			}],
			plugins: {
				legend: {
					position: 'bottom',
				},
				title: {
					display: true,
					text: 'Tons of CO2 Emitted per Month'
				}
			},
		};

		if (loading) { // if your component doesn't have to wait for an async action, remove this block 
			return (
				<div className="bg-loader">
					<div className="pos-center-brain-loader">
						<div className="circle-loading-brain">
						</div>
						<svg className='brain-loader' x="0px" y="0px" viewBox="0 0 496 496">
							<path d="M40,449.472V364.28L58.424,352H88v-16H53.576L24,355.72v93.752C14.704,452.784,8,461.584,8,472c0,13.232,10.768,24,24,24				s24-10.768,24-24C56,461.584,49.288,452.784,40,449.472z M32,480c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8s8,3.584,8,8				C40,476.416,36.416,480,32,480z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M46.528,320H88v-16H46.528c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24				C34.416,336,43.216,329.288,46.528,320z M16,312c0-4.416,3.584-8,8-8s8,3.584,8,8c0,4.416-3.584,8-8,8S16,316.416,16,312z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M24,272c10.416,0,19.216-6.712,22.528-16H88v-16H46.528c-3.312-9.296-12.112-16-22.528-16c-13.232,0-24,10.768-24,24				S10.768,272,24,272z M24,240c4.416,0,8,3.584,8,8s-3.584,8-8,8s-8-3.584-8-8C16,243.584,19.584,240,24,240z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M56 272h32v16H56Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M56 208h32v16H56Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M0 176h88v16H0Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M60.688,160H88v-16H67.312L48,124.688v-14.16C57.288,107.216,64,98.416,64,88c0-13.232-10.768-24-24-24S16,74.768,16,88				c0,10.416,6.704,19.216,16,22.528v20.784L60.688,160z M40,80c4.416,0,8,3.584,8,8s-3.584,8-8,8s-8-3.584-8-8S35.584,80,40,80z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M144 67.312L144 88L160 88L160 60.688L131.312 32L0 32L0 48L124.688 48z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M176 35.312L176 88L192 88L192 28.688L163.312 0L0 0L0 16L156.688 16z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M208 56h16v32H208Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M304 0h16v88H304Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M240,46.528V88h16V59.312l16,16V88h16V68.688L262.392,43.08C268.192,38.696,272,31.816,272,24c0-13.232-10.768-24-24-24				s-24,10.768-24,24C224,34.416,230.704,43.216,240,46.528z M248,16c4.416,0,8,3.584,8,8s-3.584,8-8,8s-8-3.584-8-8				S243.584,16,248,16z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M352,51.312L371.312,32h78.16c2.416,6.776,7.752,12.112,14.528,14.528v110.16L444.688,176H408v16h43.312L480,163.312				V46.528c9.288-3.312,16-12.112,16-22.528c0-13.232-10.768-24-24-24c-10.416,0-19.216,6.712-22.528,16h-84.784L336,44.688V88h16				V51.312z M472,16c4.416,0,8,3.584,8,8s-3.584,8-8,8c-4.416,0-8-3.584-8-8S467.584,16,472,16z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M414.08,97.392l17.92,17.92v9.376L412.688,144H408v16h11.312L448,131.312v-22.624L419.312,80h-4.784				c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24C401.904,112,410.416,105.968,414.08,97.392z				 M392,96c-4.416,0-8-3.584-8-8s3.584-8,8-8c4.416,0,8,3.584,8,8S396.416,96,392,96z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M408 208h88v16H408Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M480,449.472V268.688L451.312,240H408v16h36.688L464,275.312v41.376L451.312,304H408v16h36.688L464,339.312v110.16				c-9.296,3.312-16,12.112-16,22.528c0,13.232,10.768,24,24,24s24-10.768,24-24C496,461.584,489.288,452.784,480,449.472z M472,480				c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8c4.416,0,8,3.584,8,8C480,476.416,476.416,480,472,480z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M408 272h32v16H408Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M448,364.688L419.312,336H408v16h4.688L432,371.312V416h-80v-8h-16v24h96v12.688L412.688,464h-14.16				c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24c10.416,0,19.216-6.712,22.528-16h20.784				L448,451.312V364.688z M376,480c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8c4.416,0,8,3.584,8,8C384,476.416,380.416,480,376,480z				" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M160,408h-16v8h-41.472c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24				c10.416,0,19.216-6.712,22.528-16H160V408z M80,432c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8s8,3.584,8,8				C88,428.416,84.416,432,80,432z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M304 408h16v88H304Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M272 408h16v88H272Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M240,420.688l-16,16V408h-16v41.472c-9.296,3.312-16,12.112-16,22.528c0,13.232,10.768,24,24,24s24-10.768,24-24				c0-7.816-3.808-14.696-9.608-19.08L256,427.312V408h-16V420.688z M216,480c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8s8,3.584,8,8				C224,476.416,220.416,480,216,480z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M192 408L176 408L176 428.688L152 452.688L152 496L168 496L168 459.312L192 435.312z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M336,392h16v-16h24v-24h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16				v-16h-16v-24h-24v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-3.312				L120,140.688V144h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v24h24v16h16v-16h16				v16h16v-16h16v16h16v-16h16v16h16v-16h16v16h16v-16h16v16h16v-16h16V392z M136,360V147.312L147.312,136H360v224H136z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M318.888,211.048l13.96-13.96l-33.936-33.936l-13.96,13.96c-4.16-2.184-8.488-3.976-12.952-5.384V152h-48v19.728				c-4.472,1.408-8.8,3.2-12.952,5.384l-13.96-13.96l-33.936,33.936l13.96,13.96c-2.176,4.16-3.976,8.488-5.384,12.952H152v48				h19.728c1.408,4.472,3.2,8.8,5.384,12.952l-13.96,13.96l33.936,33.936l13.96-13.96c4.16,2.176,8.48,3.976,12.952,5.384V344h48				v-19.728c4.472-1.408,8.8-3.2,12.952-5.384l13.96,13.96l33.936-33.936l-13.96-13.96c2.176-4.152,3.976-8.48,5.384-12.952H344v-48				h-19.728C322.864,219.528,321.072,215.2,318.888,211.048z M328,256h-16.224l-1.416,6.224c-1.6,7.016-4.368,13.672-8.216,19.792				l-3.408,5.408l11.496,11.496l-11.312,11.312l-11.496-11.496l-5.408,3.408c-6.12,3.848-12.776,6.616-19.792,8.216L256,311.776V328				h-16v-16.224l-6.224-1.416c-7.016-1.6-13.672-4.368-19.792-8.216l-5.408-3.408l-11.496,11.496l-11.312-11.312l11.496-11.496				l-3.408-5.408c-3.848-6.12-6.616-12.776-8.216-19.792L184.224,256H168v-16h16.224l1.416-6.224				c1.6-7.016,4.368-13.672,8.216-19.792l3.408-5.408l-11.496-11.496l11.312-11.312l11.496,11.488l5.408-3.408				c6.12-3.848,12.776-6.616,19.792-8.216l6.224-1.408V168h16v16.224l6.224,1.416c7.016,1.6,13.672,4.368,19.792,8.216l5.408,3.408				l11.496-11.488l11.312,11.312l-11.496,11.496l3.408,5.408c3.848,6.12,6.616,12.776,8.216,19.792l1.416,6.216H328V256z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M248,208c-22.056,0-40,17.944-40,40c0,22.056,17.944,40,40,40c22.056,0,40-17.944,40-40C288,225.944,270.056,208,248,208				z M248,272c-13.232,0-24-10.768-24-24s10.768-24,24-24s24,10.768,24,24S261.232,272,248,272z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
						</svg>
					</div>
				</div>
			)
		}
		else {
			return (
				<div>
					{current_stage === 'final' ?
						<>
							<div className="modal-regional-demand-graph">
								<div className="modal-regional-demand-graph-inner">
									<Tabs
										defaultActiveKey="modalsplittrips"
										id="uncontrolled-tab-example"
										className="mb-3"
									>
										<Tab eventKey="traveledkm" title="PKm">
											<Line data={dataBar_TraveledKm} options={kmPerDay} />
										</Tab>
										<Tab eventKey="modalsplitpkm" title="Modal Split (PKm)">
											<Line data={dataBar_ModalPKM} options={options} />
										</Tab>
										<Tab eventKey="modalsplittrips" title="Modal Split (Trips)">
											<Line data={dataBar_ModalTrips} options={options_trips} />
										</Tab>
										<Tab eventKey="weekdayvsweekends" title="Number of Trips">
											<Line data={dataBar_WvsW} options={numTripsPerDay} />
										</Tab>
										<Tab eventKey="co2" title="CO2 Emissions">
											<Line data={dataBar_CO2} options={CO2_options} />
										</Tab>
										<Tab eventKey="topOD" title="Top ODs">
											<div className="topOD-scroll">
											<div className="row">
												<Form onChange={this.schemaType.bind(this)}>
													<div className="column-half-50">
														<Form.Check inline label=" Evaluate based on Cars Pkm" name="type" type='radio' id='cars' defaultChecked={true} />
													</div>
													<div className="column-half-50">
														<Form.Check inline label=" Evaluate based on Trains PKm" name="type" type='radio' id='trains' />
													</div>
												</Form>
											</div>
											<div className="row">
												<table className="top-od-regional">
													<thead>
														<tr key='headers'>
															{(this.state.value_filtering_scope.value !== 'tiles') ?
																<>
																	<th className="table-title-od">Identifier</th>
																</> :
																<></>
															}
															<th className="table-title-od">Origin</th>
															<th className="table-title-od">Destination</th>
															<th className="table-title-od">PKm Cars</th>
															<th className="table-title-od">PKm Trains</th>
														</tr>
													</thead>
													<tbody>
														{listODTrips}
													</tbody>
												</table>
											</div>
											</div>
										</Tab>
									</Tabs>
								</div>
							</div>
						</>
						:
						<></>
					}
					{this.state.response_od_flow !== '' ?
						<>
							<div className="modal-regional-demand-result">
								<div className="modal-regional-demand-result-inner">
									<h1>Regional Demand Analysis per Month</h1>
									<div className="row-parameters">
										<div className="column-60-per">
											<p className="label"><b>Trains Trips weekdays</b></p>
										</div>
										<div className="column-40-per">
											<p className="label"><b>{numberWithCommas(this.state.response_od_flow.train_trips_weekdays.toFixed(0))}</b></p>
										</div>
									</div>
									<div className="row-parameters">
										<div className="column-60-per">
											<p className="label"><b>Cars Trips weekdays</b></p>
										</div>
										<div className="column-40-per">
											<p className="label"><b>{numberWithCommas(this.state.response_od_flow.car_trips_weekdays.toFixed(0))}</b></p>
										</div>
									</div>
									<div className="row-parameters">
										<div className="column-60-per">
											<p className="label"><b>Total Trips weekdays</b></p>
										</div>
										<div className="column-40-per">
											<p className="label"><b>{numberWithCommas(this.state.response_od_flow.total_trips_weekdays.toFixed(0))}</b></p>
										</div>
									</div>
									<div className="row-parameters">
										<div className="column-60-per">
											<p className="label"><b>Trains Trips weekend</b></p>
										</div>
										<div className="column-40-per">
											<p className="label"><b>{numberWithCommas(this.state.response_od_flow.train_trips_weekend.toFixed(0))}</b></p>
										</div>
									</div>
									<div className="row-parameters">
										<div className="column-60-per">
											<p className="label"><b>Cars Trips weekend</b></p>
										</div>
										<div className="column-40-per">
											<p className="label"><b>{numberWithCommas(this.state.response_od_flow.car_trips_weekend.toFixed(0))}</b></p>
										</div>
									</div>
									<div className="row-parameters">
										<div className="column-60-per">
											<p className="label"><b>Total Trips weekend</b></p>
										</div>
										<div className="column-40-per">
											<p className="label"><b>{numberWithCommas(this.state.response_od_flow.total_trips_weekend.toFixed(0))}</b></p>
										</div>
									</div>
									<div className="row-parameters">
										<div className="column-60-per">
											<p className="label"><b>Total PKm</b></p>
										</div>
										<div className="column-40-per">
											<p className="label"><b>{numberWithCommas(this.state.response_od_flow.total_pkm_weekdays.toFixed(0))}</b></p>
										</div>
									</div>
								</div>
							</div>
						</>
						:
						<></>
					}
					<div className="modal-regional-demand-type">
						<div className="modal-regional-demand-type-inner">
							<h1>Regional Demand</h1>
							<p className="label"><b>Select division scope</b></p>
							<Select className='filtering_scope' options={this.state.data_filtering_scope_} onChange={this.handleFilteringScope.bind(this)} styles={selectDate} defaultValue={value_filtering_scope} />
						</div>
					</div>
					{this.state.current_stage === 'origin' ?
						<div className="modal-regional-demand-step1">
							<div className="modal-regional-demand-step1-inner">
								<p className="label">On the map, draw a polygon that will represent the <b>origin</b> of the region you would like to evaluate. Double-click when you draw the final vertex to complete the polygon.</p>
								<p className="label"><i>To delete your polygon and draw a new one, use the draw tools on the bottom right corner of the map.</i></p>
								<div className="row-parameters">
									{isGeometrySelected ?
										<>
											<div className="column-1-50">
												<Form onClick={this.handleDeleteOrigin.bind(this)}>
													<Button className="reset-btn" variant="info">Delete geometry</Button>
												</Form>
											</div>
											<div className="column-2-50">
												<Form onClick={this.handleOriginSelection.bind(this)}>
													<Button className="generate-btn" variant="info">Select origin</Button>
												</Form>
											</div>
										</>
										:
										<>
											<Form onClick={this.handleOriginSelection.bind(this)}>
												<Button className="generate-btn" variant="info" disabled={(!isGeometrySelected)}>Select origin</Button>
											</Form>
										</>
									}
								</div>
							</div>
						</div>
						:
						<>
							{this.state.current_stage === 'destination' ?
								<div className="modal-regional-demand-step2">
									<div className="modal-regional-demand-step2-inner">
										<p className="label">On the map, draw a polygon that will represent the <b>destination</b> of the region you would like to evaluate. Double-click when you draw the final vertex to complete the polygon.</p>
										<p className="label"><i>To delete your polygon and draw a new one, use the draw tools on the bottom right corner of the map.</i></p>
										<div className="row-parameters">
											{isGeometrySelected ?
												<>
													<div className="column-1-50">
														<Form onClick={this.handleDeleteDestination.bind(this)}>
															<Button className="reset-btn" variant="info">Delete geometry</Button>
														</Form>
													</div>

													<div className="column-2-50">
														<Form onClick={this.handleDestinationSelection.bind(this)}>
															<Button className="generate-btn" variant="info">Select destination</Button>
														</Form>
													</div>
												</>
												:
												<>
													<Form onClick={this.handleDestinationSelection.bind(this)}>
														<Button className="generate-btn" variant="info" disabled={(!isGeometrySelected)}>Select destination</Button>
													</Form>
												</>
											}

											<div>
											</div>
										</div>
									</div>
								</div>
								:
								<div>
									<div className="modal-regional-demand-step3">
										<div className="modal-regional-demand-step3-inner">
											<p className="label"><b>Select month/year</b></p>
											<Select className='dates' options={this.state.dates_trips_} onChange={this.handleMonthYear.bind(this)} styles={selectDate} defaultValue={value_od_dates} />
											<p className="label">The selected date will affect the general statistics shown (modal split, passenger km and number of trips by car and by train). However, a graph showing the distribution through time will be also displayed.</p>
											<div className="row-parameters">
												{this.state.response_od_flow !== '' ?
													<>
														<div className="column-1-50">
															<Form onClick={this.handleReset.bind(this)}>
																<Button className="reset-btn" variant="info">Restart the view</Button>
															</Form>
														</div>
														<div className="column-2-50">
															<Form onClick={this.handleEvaluateRegion.bind(this)}>
																<Button className="generate-btn" variant="info">Evaluate region</Button>
															</Form>
														</div>
													</>
													:
													<>
														<Form onClick={this.handleEvaluateRegion.bind(this)}>
															<Button className="generate-btn" variant="info">Evaluate region</Button>
														</Form>
													</>
												}
												<div>
												</div>
											</div>
										</div>
									</div>
								</div>
							}
						</>
					}
				</div>
			)
		}
	}
}

//Map - Potential of existing and new routes
class MappRegionalDemand extends React.Component {

	// Set up states for updating map 
	constructor(props) {
		super(props);
		this.state = {
			lng: 8.536524,
			lat: 46.844411,
			zoom: 7,
			mapita: 9,
			tyle: 'mapbox://styles/mapbox/light-v10',
			hideNavs: false,
			current_stage: 'origin',
			origin_geojson: [],
			isGeometrySelected: false,
			destination_geojson: [],
			value_filtering_scope: 'plz',
		}
	}

	//Update several fields
	updateFields(list) {
		this.setState(list);
	}

	//Update map on component update
	updateField(card, value) {
		this.setState({
			...this.state,
			[card]: value
		});
	}

	//Update coordinates
	handleCoord(center, zoom) {
		const { lng, lat } = center;
		this.setState({ zoom: zoom, lat: lat, lng: lng })
	}

	//Update style
	handleTyle(value) {
		this.setState({ tyle: value }, this.componentDidMount);
	}

	componentDidMount() {
		// Set up map 
		const map = new mapboxgl.Map({
			container: this.mapContainer,
			style: this.state.tyle,
			center: [this.state.lng, this.state.lat],
			zoom: this.state.zoom,
		});

		map.on('load', () => {

			//Origin Polygon
			map.addSource('data-origin-polygon', {
				'type': 'geojson',
				'data': URL_lines,
				'generateId': true

			});

			map.addLayer({
				'id': 'data-origin-polygon',
				'type': 'fill',
				'source': 'data-origin-polygon',
				'layout': {
				},
				'paint': {
					'fill-color': '#E32A8D',
					'fill-opacity': 0.5
				}
			});

			map.addLayer({
				'id': 'outline-origin-polygon',
				'type': 'line',
				'source': 'data-origin-polygon',
				'layout': {},
				'paint': {
					'line-color': '#454545',
					'line-width': 0.8
				}
			});

			//Destination Polygon
			map.addSource('data-destination-polygon', {
				'type': 'geojson',
				'data': URL_lines,
				'generateId': true

			});

			map.addLayer({
				'id': 'data-destination-polygon',
				'type': 'fill',
				'source': 'data-destination-polygon',
				'layout': {
				},
				'paint': {
					'fill-color': '#027f80',
					'fill-opacity': 0.5
				}
			});


			map.addLayer({
				'id': 'outline-destination-polygon',
				'type': 'line',
				'source': 'data-destination-polygon',
				'layout': {},
				'paint': {
					'line-color': '#454545',
					'line-width': 0.8
				}
			});


			//Geometries Polygon
			map.addSource('data-geometries-polygon', {
				'type': 'geojson',
				'data': URL_lines,
				'generateId': true

			});

			map.addLayer({
				'id': 'data-geometries-polygon',
				'type': 'fill',
				'source': 'data-geometries-polygon',
				'layout': {
				},
				'paint': {
					'fill-color': [
						'match', ['get', 'aggregation_order'],
						'destination', '#027f80',
						'#E32A8D'],
					'fill-opacity': 0.5
				}
			});

			map.addLayer({
				'id': 'outline-geometries-polygon',
				'type': 'line',
				'source': 'data-geometries-polygon',
				'layout': {},
				'paint': {
					'line-color': '#454545',
					'line-width': 0.8
				}
			});

			map.addControl(draw, 'bottom-right')
			// Add zoom and rotation controls to the map.
			map.addControl(new mapboxgl.NavigationControl({ showCompass: false, showZoom: false }), 'top-left');
			map.addControl(new MapboxGeocoder({ accessToken: mapboxgl.accessToken, mapboxgl: mapboxgl, marker: false }), 'top-left');

		});

		this.setState({
			mapita: map,
			zoom: map.getZoom(),
		});

		map.on('draw.create', (e) => {
			const data = draw.getAll();
			if (data.features.length === 1) {

				let body = {
					"geometry": data.features[0].geometry,
					"date": '2022-08',
					"filtering_scope": this.state.value_filtering_scope
				}

				fetch(URL + 'od_analyzer/geometry', {
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(body)
				}).then((res) => res.json())
					.then((json) => {
						current_mode = 'default'
						if (this.state.current_stage === 'origin') {
							this.setState({ origin_geojson: data.features[0], isGeometrySelected: true })
							map.getSource('data-origin-polygon').setData(json);
						} else {
							this.setState({ destination_geojson: data.features[0], isGeometrySelected: true })
							map.getSource('data-destination-polygon').setData(json);
						}
					})


			}
		});

		map.on('draw.delete', (e) => {
			const data = draw.getAll();
			console.log('hi')
			draw.deleteAll()
			if (data.features.length === 0) {
				if (this.state.current_stage === 'origin') {
					this.setState({ origin_geojson: [], isGeometrySelected: false })
				} else {
					this.setState({ destination_geojson: [], isGeometrySelected: false })
				}
				map.getSource('data-origin-polygon').setData(URL_lines);
				current_mode = 'draw_polygon'
			}
		});

		map.on('draw.update', (e) => {
			const data = draw.getAll();
			console.log(data.features.length, data.features[0].geometry)

			if (data.features.length === 1) {
				if (data.features[0].geometry.coordinates.length > 0) {
					let body = {
						"geometry": data.features[0].geometry,
						"date": '2022-08',
						"filtering_scope": this.state.value_filtering_scope
					}

					fetch(URL + 'od_analyzer/geometry', {
						method: 'POST',
						headers: {
							'Accept': 'application/json',
							'Content-Type': 'application/json'
						},
						body: JSON.stringify(body)
					}).then((res) => res.json())
						.then((json) => {
							current_mode = 'default'
							if (e.type === 'draw.update') {
								if (this.state.current_stage === 'origin') {
									this.setState({ origin_geojson: data.features[0], isGeometrySelected: true })
									map.getSource('data-origin-polygon').setData(json);
								} else {
									this.setState({ destination_geojson: data.features[0], isGeometrySelected: true })
									map.getSource('data-destination-polygon').setData(json);
								}
							}
						})
				}
				else {
					if (this.state.current_stage === 'origin') {
						this.setState({ origin_geojson: [], isGeometrySelected: false })
						map.getSource('data-origin-polygon').setData(URL_lines);
					} else {
						this.setState({ destination_geojson: [], isGeometrySelected: false })
						map.getSource('data-destination-polygon').setData(URL_lines);
					}
					current_mode = 'draw_polygon'
				}
			} else if (data.features.length === 0) {
				if (this.state.current_stage === 'origin') {
					this.setState({ origin_geojson: [], isGeometrySelected: false })
					map.getSource('data-origin-polygon').setData(URL_lines);
				} else {
					this.setState({ destination_geojson: [], isGeometrySelected: false })
					map.getSource('data-destination-polygon').setData(URL_lines);
				}
				current_mode = 'draw_polygon'
			}
		});
	}

	render() {
		const { hideNavs } = this.state;

		return (
			<div>
				<div ref={el => this.mapContainer = el} style={{ width: '100%', height: '100vh' }}>
				</div>
				<RecommendationNavBarComponent />
				<HideElementsComponent map={this.state.mapita} updateField={(card, field, value) => this.updateField(card, field, value)} />
				<div style={{ display: hideNavs ? 'none' : 'block' }}>
						<BtnModal map={this.state.mapita} updateFields={(list) => this.updateFields(list)} origin_geojson={this.state.origin_geojson} destination_geojson={this.state.destination_geojson} current_stage={this.state.current_stage} isGeometrySelected={this.state.isGeometrySelected} value_filtering_scope={this.state.value_filtering_scope} />
					</div>
				<LayerTylesComponent map={this.state.mapita} tyle={this.state.tyle} handleTyle={(value) => this.handleTyle(value)} handleCoord={(value1, value2) => this.handleCoord(value1, value2)} />
			</div>
		)
	}
}

export default MappRegionalDemand;