import React from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import { IconContext } from "react-icons";
import RecommendationNavBarComponentBLS from "../../../components/RecommendationNavBarComponentBLS";
import axios from 'axios';
import Select from 'react-select';
import '../../css/dashboard_5_in_5.css';
import { Table, Column, HeaderCell, Cell } from 'rsuite-table';
import ReactPaginate from 'react-paginate';
import ProgressBar from "@ramonak/react-progress-bar";
import { IoSettingsOutline } from "react-icons/io5";
import Slider from 'rc-slider';
import { Form, Button } from 'react-bootstrap';
import { RxCross2 } from "react-icons/rx";
import Chart from 'chart.js/auto';
import { Line } from 'react-chartjs-2';
import { Link } from "react-router-dom";
import ExportExcel from "../../../utils/ExportExcel";
import { useParams } from "react-router-dom";

import "mapbox-gl-compare/dist/mapbox-gl-compare.css";
import '../../css/react-paginate.css';
import 'rsuite-table/dist/css/rsuite-table.css'; // or 'rsuite-table/dist/css/rsuite-table.css'

const _ = require('lodash');

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const URL = process.env.REACT_APP_URL_SERVER_BACK;
const URL_FRONT = process.env.REACT_APP_URL_SERVER_FRONT;


function numberWithCommas(x) {
    if (typeof x !== "undefined") {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");

    }
    else {
        return 0
    }
}


//Dashboard: 5-in-5 BLS
class Dashboard_5_in_5_BLS extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            kantons_: this.getOptionsKantons(URL + 'what_if/kanton_list'),
            current_value_k: {
                label: 'BLS Region',
                value: 'CO33'
            },
            limit: 10,
            current_page: 0,
            max_page: 5,
            dataList: [],
            loading: true,
            switch_state: false,
            state_settings: false,

            employees_num: 200,
            num_biggest_employee: 200,
            biggest_employee: 'sum_200',
            pkm_or_trips: 'pkm',
            agg_traffic: 'employeers',
            valueTopEmployees: 100000,
            min_distance_train_station: 0,
            max_distance_train_station: 35000,
            showDetails: false,
            rowDataSelected: [],
            data_graph: [],
            data_graph_traveled: [],
            dataMSPrediction: [],

            loading_details: false,
            carousel_num: 1,

            categories_: this.getCategories(URL + 'tile_potential_employer/categories'),
            current_value_category: {
                label: 'All',
                value: 'all'
            },

            //Map
            lng: 8.251313,
            lat: 46.924143,
            zoom: 13.5,
            map: [],
            tyle: 'mapbox://styles/mapbox/light-v10',

            behaviour: true,
            last_mile: true,
            bike: true,
            working: true,
            car_pooling: true,

            cr_behavior: 0.3,
            cr_last_mile: 0.3,
            cr_bike: 0.3,
            cr_working: 0.3,
            cr_car_pooling: 0.15,
            n_measures: 100,
            commuter_effect_trips: 0,
            commuter_effect_pkm: 0,
            total_cum_switzerland: 0,
            total_revenue_view: 0,
            json_map: {},
            search_company_name: '',
            value_excel: [],
            total_switzerland_view: {
                "n_companies": 0,
                "metric": 0,
                "pkm_cars": 0,
                "pkm_trains": 0,
                "employees": 0,
                "shifted_metric": 0,
                "traffic_pctg": 0,
                "national_traffic_pctg": 0,
                "shifted_national_traffic": 0,
                "reduced_car_traffic": 0,
                "increased_train_traffic": 0,
                "tons_co2": 0,
                "mallorca_flights": 0
            }
        }
        this.fetchInitialData('sum_200', 'all', 200, 0, 35000, 'CO33', 0, 'pkm', 'employeers', true, true, true, true, true, 0.3, 0.3, 0.3, 0.3, 0.15, 100, '');
    }

    componentDidMount() {
        if (document.getElementById('comparison-container')) {
            const map = new mapboxgl.Map({
                container: 'map-container',
                style: this.state.tyle,
                center: [this.state.lng, this.state.lat],
                zoom: this.state.zoom
            });

            map.on('load', () => {
                map.addSource('point', {
                    'type': 'geojson',
                    'data': this.state.json_map
                });

                map.addLayer({
                    'id': 'point',
                    'type': 'circle',
                    'source': 'point',
                    'paint': {
                        'circle-radius': 8,
                        'circle-color': ['get', 'color'] // blue color
                    }
                });
            })

            map.on('click', 'point', (e) => {

                let html = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">' + (e.features[0].properties.company_name === undefined ? 'Name not available' : e.features[0].properties.company_name) + '</span><br/><span style="font-weight: bold;">Building Category: </span>' + e.features[0].properties.main_category.charAt(0).toUpperCase() + e.features[0].properties.main_category.slice(1) + '<br/><span style="font-weight: bold;">Number of employees: </span>' + numberWithCommas(e.features[0].properties.total_employees) + '</p>';

                new mapboxgl.Popup()
                    .setLngLat(e.lngLat)
                    .setHTML(html).addTo(map);
            })

            this.setState({ mapita: map });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if ((this.state.loading_details !== prevState.loading_details && prevState.loading_details === true) ||
            (this.state.carousel_num !== prevState.carousel_num && this.state.carousel_num === 1)) {
            const map = new mapboxgl.Map({
                container: 'map-container',
                style: this.state.tyle,
                center: [this.state.lng, this.state.lat],
                zoom: this.state.zoom
            });

            map.on('load', () => {
                map.addSource('point', {
                    'type': 'geojson',
                    'data': URL + 'tile_potential_employer/employers/' + this.state.rowDataSelected.tileId + '/' + this.state.current_value_category.value
                });

                map.addLayer({
                    'id': 'point',
                    'type': 'circle',
                    'source': 'point',
                    'paint': {
                        'circle-radius': 8,
                        'circle-color': ['get', 'color']
                    }
                });
            })

            map.on('click', 'point', (e) => {

                let html = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">' + (e.features[0].properties.company_name === undefined ? 'Name not available' : e.features[0].properties.company_name) + '</span><br/><span style="font-weight: bold;">Building Category: </span>' + e.features[0].properties.main_category.charAt(0).toUpperCase() + e.features[0].properties.main_category.slice(1) + '<br/><span style="font-weight: bold;">Number of employees: </span>' + numberWithCommas(e.features[0].properties.total_employees) + '</p>';

                new mapboxgl.Popup()
                    .setLngLat(e.lngLat)
                    .setHTML(html).addTo(map);
            })

            console.log('entro 2', map)

            this.setState({ mapita: map });
        }
    }

    async getCategories(url) {
        const res = await axios.get(url)
        const data = res.data
        this.setState({ categories_: data });
    }

    async getOptionsKantons(url) {
        const res = await axios.get(url)
        const data = res.data
        const options = data.map(d => ({
            "value": d._id,
            "label": d._id
        }))
        this.setState({ kantons_: options });
    }

    handleBuildingCategory(event) {
        this.setState({ current_value_category: event });

        this.fetchInitialData((this.state.switch_state ? 'max' : this.state.biggest_employee), event.value, this.state.employees_num, this.state.min_distance_train_station, this.state.max_distance_train_station, (this.state.current_value_k === null ? 'all' : this.state.current_value_k.value), this.state.current_page, this.state.pkm_or_trips, this.state.agg_traffic, this.state.behaviour, this.state.last_mile, this.state.bike, this.state.working, this.state.car_pooling, this.state.cr_behavior, this.state.cr_last_mile, this.state.cr_bike, this.state.cr_working, this.state.cr_car_pooling, this.state.n_measures, this.state.search_company_name)
    }

    handleKanton(event) {
        this.setState({ current_value_k: event });
        this.fetchInitialData((this.state.switch_state ? 'max' : this.state.biggest_employee), this.state.current_value_category.value, this.state.employees_num, this.state.min_distance_train_station, this.state.max_distance_train_station, (event === null ? 'all' : event.value), this.state.current_page, this.state.pkm_or_trips, this.state.agg_traffic, this.state.behaviour, this.state.last_mile, this.state.bike, this.state.working, this.state.car_pooling, this.state.cr_behavior, this.state.cr_last_mile, this.state.cr_bike, this.state.cr_working, this.state.cr_car_pooling, this.state.n_measures, this.state.search_company_name)
    }

    handleAggTraffic(event) {
        this.setState({ agg_traffic: event.target.attributes.getNamedItem('id').value, loading_potentials: true });
    }

    hideComponent() {
        let currentStateSettings = !this.state.state_settings;
        this.setState({ state_settings: currentStateSettings })
    }

    fetchInitialData(filter, current_value_category, num_employees, min_train_station, max_train_station, kanton, page, pkm_or_trips, agg_traffic, behaviour, last_mile, bike, working, car_pooling, cr_behavior, cr_last_mile, cr_bike, cr_working, cr_car_pooling, n_measures, search_company_name) {
        this.setState({ current_page: 0 })
        this.setState({ loading: true })

        let activated = []

        if (behaviour) {
            activated.push('behavior')
        }
        if (last_mile) {
            activated.push('last_mile')
        }
        if (bike) {
            activated.push('bike')
        }
        if (working) {
            activated.push('working')
        }
        if (car_pooling) {
            activated.push('car_pooling')
        }

        let body = {
            "filter": filter + '_' + current_value_category,
            "conversion_rates": { "behavior": cr_behavior, "last_mile": cr_last_mile, "bike": cr_bike, "working": cr_working, "car_pooling": cr_car_pooling },
            "active_options": activated,
            "n_employees": num_employees,
            "distance_to_station_range": [min_train_station, max_train_station],
            "kanton": kanton,
            "page": 1,
            "metric": pkm_or_trips,
            "type": (agg_traffic === 'both' ? '' : '_' + agg_traffic),
            "n_measures": n_measures,
            "query": search_company_name
        }

        let commuter_effect_trips = 0
        let commuter_effect_pkm = 0

        fetch(URL + 'tile_potential_employer/top_100_dashboard_pages', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body),
        })
            .then((res) => res.json())
            .then((json) => {
                commuter_effect_trips = json.commuter_effect_trips;
                commuter_effect_pkm = json.commuter_effect_pkm;
                this.setState({
                    max_page: json.pages,
                    commuter_effect_trips: json.commuter_effect_trips, commuter_effect_pkm: json.commuter_effect_pkm
                })

                return json.pages;
            }).then((max_page) => {
                body = {
                    "filter": filter + '_' + current_value_category,
                    "conversion_rates": { "behavior": cr_behavior, "last_mile": cr_last_mile, "bike": cr_bike, "working": cr_working, "car_pooling": cr_car_pooling },
                    "active_options": activated,
                    "n_employees": num_employees,
                    "distance_to_station_range": [min_train_station, max_train_station],
                    "kanton": kanton,
                    "page": 1,
                    "metric": pkm_or_trips,
                    "type": (agg_traffic === 'both' ? '' : '_' + agg_traffic),
                    "n_measures": n_measures,
                    "commuter_effect_trips": commuter_effect_trips,
                    "commuter_effect_pkm": commuter_effect_pkm, 
                    "query": search_company_name
                }
                fetch(URL + 'tile_potential_employer/top_100_dashboard_summary', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(body),
                })
                    .then((res) => res.json())
                    .then((json) => {
                        this.setState({
                            total_switzerland_view: json
                        })
                    }).then(() => {
                        body = {
                            "filter": filter + '_' + current_value_category,
                            "conversion_rates": { "behavior": cr_behavior, "last_mile": cr_last_mile, "bike": cr_bike, "working": cr_working, "car_pooling": cr_car_pooling },
                            "active_options": activated,
                            "n_employees": num_employees,
                            "distance_to_station_range": [min_train_station, max_train_station],
                            "kanton": kanton,
                            "page": 1,
                            "metric": pkm_or_trips,
                            "type": (agg_traffic === 'both' ? '' : '_' + agg_traffic),
                            "n_measures": n_measures,
                            "commuter_effect_trips": commuter_effect_trips,
                            "commuter_effect_pkm": commuter_effect_pkm,
                            "query": search_company_name
                        }

                        fetch(URL + 'tile_potential_employer/top_100_dashboard', {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(body),
                        })
                            .then((res) => res.json())
                            .then((json) => {
                                this.setState({ dataList: json })
                            }).then(() => {
                                this.setState({ loading: false })
                            }).then(()=>{
                                fetch(URL + 'tile_potential_employer/top_100_dashboard_excel', {
                                    method: 'POST',
                                    headers: {
                                        'Accept': 'application/json',
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify(body),
                                })
                                    .then((res) => res.json())
                                    .then((json) => {
                                        this.setState({ value_excel: json,  })
                                    })
                            })
                    })
            })
    }

    //TODO
    fetchInitialDataMoveBetweenPages(filter, current_value_category, num_employees, min_train_station, max_train_station, kanton, page, pkm_or_trips, agg_traffic, behavior, last_mile, bike, working, car_pooling, cr_behavior, cr_last_mile, cr_bike, cr_working, cr_car_pooling, n_measures, search_company_name) {
        this.setState({ loading: true })

        let activated = []

        if (behavior) {
            activated.push('behavior')
        }
        if (last_mile) {
            activated.push('last_mile')
        }
        if (bike) {
            activated.push('bike')
        }
        if (working) {
            activated.push('working')
        }
        if (car_pooling) {
            activated.push('car_pooling')
        }

        let body = {
            "filter": filter + '_' + current_value_category,
            "conversion_rates": { "behavior": cr_behavior, "last_mile": cr_last_mile, "bike": cr_bike, "working": cr_working, "car_pooling": cr_car_pooling },
            "active_options": activated,
            "n_employees": num_employees,
            "distance_to_station_range": [min_train_station, max_train_station],
            "kanton": kanton,
            "page": (page + 1),
            "metric": pkm_or_trips,
            "type": (agg_traffic === 'both' ? '' : '_' + agg_traffic),
            "n_measures": n_measures,
            "commuter_effect_trips": this.state.commuter_effect_trips,
            "commuter_effect_pkm": this.state.commuter_effect_pkm,
            "query": search_company_name
        }

        fetch(URL + 'tile_potential_employer/top_100_dashboard', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body),
        })
            .then((res) => res.json())
            .then((json) => {
                this.setState({ dataList: json })
            }).then(() => {
                this.setState({ loading: false })
            })
    }

    handleTypeEmployer() {
        let currentState = !this.state.switch_state
        this.setState({ search_company_name: '', switch_state: currentState, num_biggest_employee: (!currentState ? 3000 : 0), biggest_employee: (!currentState ? 'sum_3000' : 'sum_0'), showDetails: false, employees_num: 0 })
        this.fetchInitialData((currentState ? 'max' : this.state.biggest_employee), this.state.current_value_category.value, 0, this.state.min_distance_train_station, this.state.max_distance_train_station, (this.state.current_value_k === null ? 'all' : this.state.current_value_k.value), this.state.current_page, this.state.pkm_or_trips, this.state.agg_traffic, this.state.behaviour, this.state.last_mile, this.state.bike, this.state.working, this.state.car_pooling, this.state.cr_behavior, this.state.cr_last_mile, this.state.cr_bike, this.state.cr_working, this.state.cr_car_pooling, this.state.n_measures, '')
    }

    handleHeight(event) {
        this.setState({ pkm_or_trips: event.target.attributes.getNamedItem('id').value });
    }

    handlePageClick(event) {
        this.setState({ current_page: event.selected })
        this.fetchInitialDataMoveBetweenPages((this.state.switch_state ? 'max' : this.state.biggest_employee), this.state.current_value_category.value, this.state.employees_num, this.state.min_distance_train_station, this.state.max_distance_train_station, (this.state.current_value_k === null ? 'all' : this.state.current_value_k.value), event.selected, this.state.pkm_or_trips, this.state.agg_traffic, this.state.behaviour, this.state.last_mile, this.state.bike, this.state.working, this.state.car_pooling, this.state.cr_behavior, this.state.cr_last_mile, this.state.cr_bike, this.state.cr_working, this.state.cr_car_pooling, this.state.n_measures, this.state.search_company_name)
    }

    handleRowSelection(tileId) {
        window.open(URL_FRONT + 'potential-tile-employer/' + tileId + ',' + (this.state.switch_state ? 'max' : this.state.biggest_employee) + '_' + this.state.current_value_category.value + ',' + this.state.employees_num + ',' + this.state.min_distance_train_station + ',' + this.state.max_distance_train_station + ',' + (this.state.current_value_k === null ? 'all' : this.state.current_value_k.value), "_blank", "noreferrer");
    }

    handleUpdate() {
        this.fetchInitialData((this.state.switch_state ? 'max' : this.state.biggest_employee), this.state.current_value_category.value, this.state.employees_num, this.state.min_distance_train_station, this.state.max_distance_train_station, (this.state.current_value_k === null ? 'all' : this.state.current_value_k.value), this.state.current_page, this.state.pkm_or_trips, this.state.agg_traffic, this.state.behaviour, this.state.last_mile, this.state.bike, this.state.working, this.state.car_pooling, this.state.cr_behavior, this.state.cr_last_mile, this.state.cr_bike, this.state.cr_working, this.state.cr_car_pooling, this.state.n_measures, this.state.search_company_name)
    }

    handleNumEmployeesInCompany(event) {
        let minEmployees = 0;

        if (event <= 3000) {
            minEmployees = event;

            if (event === 250) {
                minEmployees = 200;
            } else if (event === 400) {
                minEmployees = 300;
            } else if (event === 550) {
                minEmployees = 500;
            }
        }

        this.setState({ num_biggest_employee: event, valueMinEmployees: minEmployees, biggest_employee: 'sum_' + minEmployees });

        if (this.state.employees_num < minEmployees && event >= 3000) {
            this.setState({ employees_num: minEmployees });
        }
    }

    handleDistanceTrainStation(event) {
        this.setState({ min_distance_train_station: event[0], max_distance_train_station: event[1] });
    }

    handleMeasuresActivated(event) {
        let temp = !(this.state[event.target.attributes.getNamedItem('id').value]);

        if (event.target.attributes.getNamedItem('id').value === 'behaviour') {
            this.setState({ behaviour: temp })
        }

        if (event.target.attributes.getNamedItem('id').value === 'last_mile') {
            this.setState({ last_mile: temp })
        }

        if (event.target.attributes.getNamedItem('id').value === 'bike') {
            this.setState({ bike: temp })
        }

        if (event.target.attributes.getNamedItem('id').value === 'working') {
            this.setState({ working: temp })
        }

        if (event.target.attributes.getNamedItem('id').value === 'car_pooling') {
            this.setState({ car_pooling: temp })
        }
    }

    handleNumEmployees(event) {
        this.setState({ employees_num: event });
    }

    hideDetails() {
        this.setState({ showDetails: false });
    }

    handleCRBehaviour(event) {
        this.setState({ cr_behavior: Number(event.target.value) })
    }

    handleCRLastMile(event) {
        this.setState({ cr_last_mile: Number(event.target.value) })
    }

    handleCRBike(event) {
        this.setState({ cr_bike: Number(event.target.value) })
    }

    handleCRWorking(event) {
        this.setState({ cr_working: Number(event.target.value) })
    }

    handleCRCarPooling(event) {
        this.setState({ cr_car_pooling: Number(event.target.value) })
    }

    handleNumMeasures(event) {
        this.setState({ n_measures: Number(event.target.value) })
    }

    handleSearchCompanyName(event){
        this.setState({ search_company_name: event.target.value })
    }

    getExcel(){
        var name_traffic = (this.state.pkm_or_trips==='pkm'? 'Pkm Daily Traffic' : 'Trips Daily Traffic');
        var name_traffic_pct = (this.state.pkm_or_trips==='pkm'? 'Pkm Pct. (%)' : 'Trips Pct. (%)');
        var name_potential = (this.state.pkm_or_trips==='pkm'? 'Potential Shifting Pkm' : 'Potential Shifting Trips');

        var all = [];
		var json = this.state.value_excel;
		if (json.length !== 0 || json.length !== 'undefined') {
            for (var i = 0; i < json.length; i++) {
                all.push({
                    "#": json[i].position,
                    "URL": 'https://www.ov42.com/tile/'+ json[i].tileId,
                    "Place":json[i].place,
                    "PLZ": json[i].PLZ,
                    "Num. Company": json[i].n_companies.toFixed(0),
                    "Num. Employees": json[i].employees.toFixed(0),
                    [name_traffic]:json[i].metric?.toFixed(0),
                    [name_traffic_pct]:(json[i].metric_cum_pctg * 100).toFixed(2)+'%',
                    "Measures":'All Measures',
                    "ÖV Quality Before": (json[i].ov_before * 100).toFixed(0)+'%',
                    "ÖV Quality After": (json[i].ov_after * 100).toFixed(0)+'%',
                    [name_potential]: json[i].potential?.toFixed(0),
                    "MIV Reduction": json[i].people_shifting.toFixed(0),
                    "MIV Reduction Pct. (%)": (json[i].shift_of_metrics * 100).toFixed(2)+'%',
                    "People Shifting": json[i].real_people_shifting.toFixed(0),
                    "Modal Split Before": (json[i].ms_before * 100).toFixed(0)+'%',
                    "Modal Split After": (json[i].ms_after * 100).toFixed(0)+'%',
                    "Modal Split Increase": (json[i].ms_improvement * 100).toFixed(0)+'%',
                    "Total Traffic Shift (%)": (json[i].swiss_level_shift * 100).toFixed(4)+'%',
                    "B2B - Revenue Potential": json[i].revenue_potential_cum.toFixed(0),
                })

                for (var j = 0; j < json[i].children.length; j++) {
                    all.push({
                        "Measures":json[i].children[j].measurements,
                        "ÖV Quality Before": (json[i].children[j].ov_before * 100).toFixed(0)+'%',
                        "ÖV Quality After": (json[i].children[j].ov_after * 100).toFixed(0)+'%',
                        [name_potential]: json[i].children[j].potential?.toFixed(0),
                        "MIV Reduction": json[i].children[j].people_shifting.toFixed(0),
                        "MIV Reduction Pct. (%)": (json[i].children[j].shift_of_metrics * 100).toFixed(2)+'%',
                        "People Shifting": json[i].children[j].real_people_shifting.toFixed(0),
                        "Modal Split Before": (json[i].children[j].ms_before * 100).toFixed(0)+'%',
                        "Modal Split After": (json[i].children[j].ms_after * 100).toFixed(0)+'%',
                        "Modal Split Increase": (json[i].children[j].ms_improvement * 100).toFixed(0)+'%',
                    })
                }
            }
        }

        return all;
    }

    render() {
        const { data_graph } = this.state;
        const { data_graph_traveled } = this.state;
        const { agg_traffic } = this.state;
        const { pkm_or_trips } = this.state;
        const { dataMSPrediction } = this.state;

        const { behaviour } = this.state;
        const { last_mile } = this.state;
        const { bike } = this.state;
        const { working } = this.state;
        const { car_pooling } = this.state;



        const selectStyles = {
            menuList: styles => {
                return {
                    ...styles,
                    maxHeight: 150,
                };
            }
        };

        const marks = {
            0: '0',
            100: '100',
            250: '200',
            400: '300',
            550: '500',
            1000: '1\'000',
            2000: '2\'000',
            3000: '3\'000'
        }

        const ExcelExportData = this.getExcel();

        const optionMSPrediction = {
            xAxes: [{
                type: 'time',
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 10
                }
            }],
            yAxes: [{
                stacked: true,
            }],
            plugins: {
                title: {
                    display: false,
                    text: 'Modal Split Prediction based on Measures'
                },
                legend: {
                    position: 'bottom',
                },
            },
            maintainAspectRatio: false
        };

        const dataLine = {
            labels: data_graph['date'],
            datasets: [{
                label: 'Trains\' Modal Split',
                data: data_graph['modal_split'],
                backgroundColor: [
                    'rgba(2, 128, 6,0.2)'
                ],
                borderColor: [
                    'rgb(2, 128, 6)'

                ],
                borderWidth: 1
            }
            ]
        };

        const options = {
            xAxes: [{
                type: 'time',
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 10
                }
            }],
            plugins: {
                title: {
                    display: true,
                    text: 'Percentage of Trains\' Modal Split (PKm) per Month'
                },
                legend: {
                    position: 'bottom',
                },
            },
            maintainAspectRatio: false
        };

        const kmPerDay = {
            xAxes: [{
                type: 'time',
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 10
                }
            }],
            plugins: {
                legend: {
                    position: 'bottom',
                },
                title: {
                    display: true,
                    text: 'Kilometers per Month'
                }
            },
            maintainAspectRatio: false
        };

        const dataLine_TraveledKm = {
            labels: data_graph_traveled['date'],
            datasets: [{
                label: 'Traveled Km by Trains',
                data: data_graph_traveled['traveled_km_trains'],
                backgroundColor: [
                    'rgba(247, 137, 72, 0.2)'
                ],
                borderColor: [
                    'rgb(247, 137, 72)'
                ],
                borderWidth: 1
            },
            {
                label: 'Traveled Km by Cars',
                data: data_graph_traveled['traveled_km_cars'],
                backgroundColor: [
                    'rgba(2,127,128,0.2)'
                ],
                borderColor: [
                    'rgb(2,127,128)'
                ],
                borderWidth: 1
            },
            ]
        };

        return (
            <>
                <RecommendationNavBarComponentBLS />
                <div className="switch-change-div" style={{ top: '0px', left: '300px' }}>
                    <div className="switch-change-div-inner">
                        <div className="column-33">
                            <p className="label">All Employers in Tile</p>
                        </div>
                        <div className="column-33">
                            <label className="switch">
                                <input type="checkbox" onChange={this.handleTypeEmployer.bind(this)} value={this.state.switch_state} />
                                <span className="slider round"></span>
                            </label>
                        </div>
                        <div className="column-33">
                            <p className="label">Biggest Employer in Tile</p>
                        </div>
                    </div>
                </div>
                <div className="tile-category-div" style={{ left: '0px' }}>
                    <Select className='categories' placeholder="Filter by Building Category..." isClearable={false} options={this.state.categories_} onChange={this.handleBuildingCategory.bind(this)} styles={selectStyles} defaultValue={this.state.current_value_category} />
                </div>
                <div className="dashboard-5-in-5">
                    <div className="dashboard-5-in-5-inner">
                        {/* <div className="kanton-employer-div">
                            <Select className='kanton' placeholder="Viewing all the country" isClearable={true} options={this.state.kantons_} onChange={this.handleKanton.bind(this)} styles={selectStyles} defaultValue={this.state.current_value_k} />
                        </div> */}

                        <div className="dashboard-table-frame">
                            <h1>5-in-5: Top Measures Dashboard</h1>
                            <div className="row" style={{ height: '55px' }}>
                                <div className='column-20' style={{ float: 'left', width: '75%', padding: '0px' }}>
                                </div>
                                <div className='column-20' style={{ float: 'right', width: '5%', padding: '0px' }}>
                                    <div className='settings-dashboard-btn-inner' onClick={() => this.hideComponent()}>
                                        <IconContext.Provider value={{ color: "#5B5B5B", size: '1em' }}>
                                            <div>
                                                <IoSettingsOutline />
                                            </div>
                                        </IconContext.Provider>
                                    </div>
                                </div>
                                <div className='column-20' style={{ float: 'right', width: '10%', padding: '0px' }}>
                                    <Link to={'/BLS/potential-employers-3d/{0},{1},{2},{3},{4},{5},{6},{7},{8},{9},{10},{11},{12}'
                                    .format(this.state.behaviour, this.state.bike, this.state.last_mile, 
                                    this.state.working, this.state.car_pooling, this.state.min_distance_train_station, 
                                    this.state.max_distance_train_station, this.state.agg_traffic, this.state.pkm_or_trips,
                                    (this.state.current_value_k === null ? 'no_kanton' : this.state.current_value_k.value), 
                                    this.state.current_value_category.value, (this.state.switch_state ? 'max' : this.state.biggest_employee),
                                    this.state.employees_num)} target="_blank" style={{ textDecoration: 'none' }}>
										<Button className="co2-3dview-btn" variant="info">3D View</Button>
									</Link>
                                </div>
                                <div className='column-20' style={{ float: 'right', width: '20%', padding: '0px' }}>
                                <ExportExcel excelData={ExcelExportData} fileName={'Switzerland - 5in5 - Excel Export'} />
                                </div>
                            </div>

                            <div className="row" style={{ height: '200px', paddingTop: '1%' }}>

<div className='column-20' style={{ float: 'left', width: '30%', padding: '0px' }}>
    <div className='settings-dashboard-btn-inner' style={{ width: '90%', height: '150px' }}>
        <h2 style={{ paddingLeft: '25px', paddingRight: '25px', paddingTop: '10px', fontSize: '15px', marginBottom: '0px', fontWeight: '500', textAlign: 'left' }}>
        The selected tiles encompass {numberWithCommas(this.state.total_switzerland_view.n_companies)} companies with {numberWithCommas(this.state.total_switzerland_view.employees)} employees, generating {numberWithCommas(this.state.total_switzerland_view.metric.toFixed(0))} daily {this.state.agg_traffic==='employeers'? 'commuting ':''}{this.state.pkm_or_trips === 'trips' ? 'trips' : 'PKms'}. 
            <br/><br />This amounts to {(this.state.total_switzerland_view.traffic_pctg*100).toFixed(1)}% of the total traffic in the selected region, or {(this.state.total_switzerland_view.national_traffic_pctg*100).toFixed(1)}% of total national traffic.
        </h2>
    </div>
</div>
<div className='column-20' style={{ float: 'left', width: '70%', padding: '0px' }}>
    <div className='settings-dashboard-btn-inner' style={{ width: '100%', height: '150px' }}>
        <div className='column-20' style={{ float: 'left', width: '20%', padding: '0px' }}>
            <h2 style={{ paddingLeft: '25px', paddingRight: '25px', paddingTop: '15px', fontSize: '15px', marginBottom: '0px', fontWeight: '500', textAlign: 'left' }}>
                If all suggested measures are implemented in the selected region, we could potentially:</h2>
        </div>
        <div className='column-20' style={{ float: 'left', width: '27%', padding: '0px' }}>
            <div className='settings-dashboard-btn-inner' style={{ width: '90%', height: '130px' }}>
                <h2 style={{ paddingLeft: '25px', paddingRight: '25px', fontSize: '15px', marginBottom: '0px', fontWeight: '500', textAlign: 'left' }}>
                    <b>Reduce car traffic by</b></h2>
                <h2 style={{ paddingLeft: '25px', paddingRight: '25px', fontSize: '70px', marginBottom: '0px', fontWeight: '500', textAlign: 'left', lineHeight: '50px' }}>
                    <b>{(this.state.total_switzerland_view.reduced_car_traffic*100).toFixed(1)}%</b></h2>
                <h2 style={{ paddingLeft: '25px', paddingRight: '25px', fontSize: '10px', marginBottom: '0px', fontWeight: '500', textAlign: 'left' }}>
                    This equals {(this.state.total_switzerland_view.shifted_national_traffic*100).toFixed(1)}% of national combined traffic</h2>
            </div>
        </div>
        <div className='column-20' style={{ float: 'left', width: '26%', padding: '0px' }}>
            <div className='settings-dashboard-btn-inner' style={{ width: '90%', height: '130px' }}>
            <h2 style={{ paddingLeft: '25px', paddingRight: '25px', fontSize: '15px', marginBottom: '0px', fontWeight: '500', textAlign: 'left' }}>
                    <b>Public Transport Increase</b></h2>
                <h2 style={{ paddingLeft: '25px', paddingRight: '25px', color: '#027f80', fontSize: '70px', marginBottom: '0px', fontWeight: '500', textAlign: 'left', lineHeight: '50px' }}>
                    <b>+{(this.state.total_switzerland_view.increased_train_traffic*100).toFixed(0)}%</b></h2>
                <h2 style={{ paddingLeft: '25px', paddingRight: '25px', fontSize: '10px', marginBottom: '0px', fontWeight: '500', textAlign: 'left' }}>
                {this.state.pkm_or_trips === 'trips' ? 'Trip Based' : 'PKm Based'}</h2>
            </div>
        </div>
        <div className='column-20' style={{ float: 'left', width: '26%', padding: '0px' }}>
            <div className='settings-dashboard-btn-inner' style={{ width: '90%', height: '130px' }}>
            <h2 style={{ paddingLeft: '25px', paddingRight: '25px', fontSize: '15px', marginBottom: '0px', fontWeight: '500', textAlign: 'left' }}>
                    <b>CO<sub>2</sub> reduction by</b></h2>
                <h2 style={{ paddingLeft: '25px', paddingRight: '25px', fontSize: '27px', marginBottom: '0px', fontWeight: '500', textAlign: 'left', lineHeight: '5px' }}>
                    <b>{numberWithCommas(this.state.total_switzerland_view?.tons_co2?.toFixed(0))}</b></h2>
                <h2 style={{ paddingLeft: '25px', paddingRight: '25px', fontSize: '10px', marginBottom: '0px', fontWeight: '500', textAlign: 'left' }}>
                Tones</h2>
                <h2 style={{ paddingLeft: '25px', paddingRight: '25px', fontSize: '27px', marginBottom: '0px', fontWeight: '500', textAlign: 'left', lineHeight: '5px' }}>
                    <b>{numberWithCommas(this.state.total_switzerland_view?.mallorca_flights?.toFixed(0))}</b></h2>
                <h2 style={{ paddingLeft: '25px', paddingRight: '25px', fontSize: '10px', marginBottom: '0px', fontWeight: '500', textAlign: 'left' }}>
                Mallorca Flights</h2>
            </div>
        </div>
    </div>
</div>
</div>

                            {
                                this.state.state_settings
                                    ?
                                    <>
                                        <div className="row" style={{ height: '60px' }}>
                                            <div className='column-20' style={{ float: 'right', width: '30%', padding: '0px' }}>
                                                <p className='label-text' style={{ marginTop: '0px' }}><b>Number of Employees within Tile</b></p>
                                                <div className="column-especial">
                                                    <Slider min={0} max={60000} step={100} value={this.state.employees_num} onChange={_.debounce(this.handleNumEmployees.bind(this), 33)} />
                                                </div>
                                                <p className='label-text' style={{ marginTop: '20px' }}>Showing tiles with minimum {numberWithCommas(Number(this.state.employees_num))} employees in all companies</p>
                                            </div>
                                            <div className='column-20 slider-center' style={{ float: 'right', width: '40%', padding: '0px' }}>
                                                <p className='label-text' style={{ marginTop: '0px' }}><b>Number of Employees in a Company</b></p>
                                                <Slider disabled={this.state.switch_state} dots min={0} max={3000} marks={marks} step={null} value={this.state.num_biggest_employee} onChange={_.debounce(this.handleNumEmployeesInCompany.bind(this), 33)} />
                                            </div>
                                            <div className='column-20' style={{ float: 'right', width: '30%', padding: '0px' }}>
                                                <p className='label-text' style={{ marginTop: '0px' }}><b>Distance to Train Station</b></p>
                                                <div className="column-especial">
                                                    <Slider range draggableTrack min={0} max={35000} step={100} value={[this.state.min_distance_train_station, this.state.max_distance_train_station]} onChange={_.debounce(this.handleDistanceTrainStation.bind(this), 33)} />
                                                </div>
                                                <p className='label-text' style={{ marginTop: '20px' }}>Showing tiles between {numberWithCommas(this.state.min_distance_train_station)}m and {numberWithCommas(this.state.max_distance_train_station)}m to the train station</p>
                                            </div>
                                        </div>
                                        <div className="row" style={{ height: '40px' }}>
                                            <div className='column-20' style={{ float: 'right', width: '40%', padding: '0px' }}>
                                                <p className='label-text' style={{ marginTop: '0px' }}><b>Measure the potential based on</b></p>
                                                <Form onChange={this.handleHeight.bind(this)}>
                                                    <div className="row">
                                                        <div className="column-1-50">
                                                            <Form.Check inline label=" Number of trips" name="type_aggregation" type='radio' id='trips' defaultChecked={(pkm_or_trips === 'trips')} />
                                                        </div>
                                                        <div className="column-1-50">
                                                            <Form.Check inline label=" Passenger Kilometers (PKm)" name="type_aggregation" type='radio' id='pkm' defaultChecked={(pkm_or_trips === 'pkm')} />
                                                        </div>
                                                    </div>
                                                </Form>
                                            </div>
                                            <div className='column-20' style={{ float: 'right', width: '20%', padding: '0px' }}>
                                                <p className='label-text' style={{ marginTop: '0px' }}><b>Number of measures</b></p>
                                                <input style={{ width: '90%' }} type="number" value={this.state.n_measures} onChange={this.handleNumMeasures.bind(this)} />
                                            </div>
                                            <div className='column-20' style={{ float: 'right', width: '40%', padding: '0px' }}>
                                                <p className='label-text' style={{ marginTop: '0px' }}><b>Traffic to Take into Consideration</b></p>
                                                <Form onChange={this.handleAggTraffic.bind(this)}>
                                                    <div className="row">
                                                        <div className="column-33">
                                                            <Form.Check inline label=" Employees" name="type_aggregation" type='radio' id='employeers' defaultChecked={(agg_traffic === 'employeers')} />
                                                        </div>
                                                        <div className="column-33">
                                                            <Form.Check inline label=" Leisure/Visitors" name="type_aggregation" type='radio' id='category' defaultChecked={(agg_traffic === 'category')} />
                                                        </div>
                                                        <div className="column-33">
                                                            <Form.Check inline label=" Both" name="type_aggregation" type='radio' id='both' defaultChecked={(agg_traffic === 'both')} />
                                                        </div>
                                                    </div>
                                                </Form>
                                            </div>
                                        </div>
                                        <div className="row" style={{ height: '50px' }}>
                                            <p className='label-text' style={{ marginTop: '0px' }}><b>Conversion Rate per Measure</b></p>
                                            <div className="row" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
                                                <Form>
                                                    <div className="column-33" style={{ width: '1.5%' }}>
                                                        <Form.Check onClick={this.handleMeasuresActivated.bind(this)} inline label="" name="type_analysis" type='checkbox' id='behaviour' defaultChecked={(behaviour)} />
                                                    </div>
                                                    <div className="column-33" style={{ width: '9%' }}>
                                                        <p className='label-text' style={{ marginTop: '0px', fontSize: '14px' }}>Behaviour Change</p>
                                                    </div>
                                                    <div className="column-33" style={{ width: '9%', marginRight: '0.5%' }}>
                                                        <input style={{ width: '90%' }} type="number" value={this.state.cr_behavior} onChange={this.handleCRBehaviour.bind(this)} />
                                                    </div>
                                                    <div className="column-33" style={{ width: '1.5%' }}>
                                                        <Form.Check onClick={this.handleMeasuresActivated.bind(this)} inline label="" name="type_analysis" type='checkbox' id='bike' defaultChecked={(bike)} />
                                                    </div>
                                                    <div className="column-33" style={{ width: '9%' }}>
                                                        <p className='label-text' style={{ marginTop: '0px', fontSize: '14px' }}>Bicycle Culture</p>
                                                    </div>
                                                    <div className="column-33" style={{ width: '9%', marginRight: '0.5%' }}>
                                                        <input style={{ width: '90%' }} type="number" value={this.state.cr_bike} onChange={this.handleCRBike.bind(this)} />
                                                    </div>
                                                    <div className="column-33" style={{ width: '1.5%' }}>
                                                        <Form.Check onClick={this.handleMeasuresActivated.bind(this)} inline label="" name="type_analysis" type='checkbox' id='last_mile' defaultChecked={(last_mile)} />
                                                    </div>
                                                    <div className="column-33" style={{ width: '9%' }}>
                                                        <p className='label-text' style={{ marginTop: '0px', fontSize: '14px' }}>Last Mile</p>
                                                    </div>
                                                    <div className="column-33" style={{ width: '9%', marginRight: '0.5%' }}>
                                                        <input style={{ width: '90%' }} type="number" value={this.state.cr_last_mile} onChange={this.handleCRLastMile.bind(this)} />
                                                    </div>
                                                    <div className="column-33" style={{ width: '1.5%' }}>
                                                        <Form.Check onClick={this.handleMeasuresActivated.bind(this)} inline label="" name="type_analysis" type='checkbox' id='working' defaultChecked={(working)} />
                                                    </div>
                                                    <div className="column-33" style={{ width: '9%' }}>
                                                        <p className='label-text' style={{ marginTop: '0px', fontSize: '14px' }}>Working Time</p>
                                                    </div>
                                                    <div className="column-33" style={{ width: '9%', marginRight: '0.5%' }}>
                                                        <input style={{ width: '90%' }} type="number" value={this.state.cr_working} onChange={this.handleCRWorking.bind(this)} />
                                                    </div>
                                                    <div className="column-33" style={{ width: '1.5%' }}>
                                                        <Form.Check onClick={this.handleMeasuresActivated.bind(this)} inline label="" name="type_analysis" type='checkbox' id='car_pooling' defaultChecked={(car_pooling)} />
                                                    </div>
                                                    <div className="column-33" style={{ width: '9%' }}>
                                                        <p className='label-text' style={{ marginTop: '0px', fontSize: '14px' }}>Car Pooling</p>
                                                    </div>
                                                    <div className="column-33" style={{ width: '9%', marginRight: '0.5%' }}>
                                                        <input style={{ width: '90%' }} type="number" value={this.state.cr_car_pooling} onChange={this.handleCRCarPooling.bind(this)} />
                                                    </div>
                                                </Form>
                                            </div>
                                        </div>
                                        {/* <div className="row" style={{ height: '40px' }}>
                                            <div className='column-50' style={{ margin: 'auto', width: '40%', padding: '0px' }}>
                                                <p className='label-text' style={{ marginTop: '0px' }}><b>Search Company by Key Words <sub>Example: Kantonsspital</sub></b></p>
                                                <input style={{ width: '90%' }} type="text" value={this.state.search_company_name} disabled={(!this.state.switch_state)? "disabled" : ""} onChange={this.handleSearchCompanyName.bind(this)} />
                                            </div>
                                            <div className='column-50' style={{ float: 'right', width: '40%', padding: '0px' }}>
                                                <Form onChange={this.handleAggTraffic.bind(this)}>
                                                </Form>
                                            </div>
                                        </div> */}
                                        <div className="row" style={{ height: '50px' }}>
                                            <Form onClick={this.handleUpdate.bind(this)}>
                                                <Button variant="info" className="generate-btn">Update</Button>
                                            </Form>
                                        </div>
                                    </>
                                    :
                                    <></>
                            }

                            <Table
                                isTree
                                loading={this.state.loading}
                                defaultExpandAllRows={false}
                                rowKey="id"
                                autoHeight={false}
                                height={310}
                                fillHeight={true}
                                data={this.state.dataList}
                            >
                                <Column flexGrow={1} minWidth={80}>
                                    <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}>#</HeaderCell>
                                    <Cell style={{ fontSize: '12px' }}>
                                        {(rowData) => {
                                            return <>{"position" in rowData ? rowData.position + 1 : ''}</>
                                        }}
                                    </Cell>
                                </Column>

                                <Column flexGrow={2.2} minWidth={150} fullText>
                                    <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}>Place<br />
                                        <span style={{ fontSize: '10px' }}>PLZ Code</span></HeaderCell>
                                    <Cell dataKey="place" style={{ fontSize: '12px' }}>
                                        {rowData => {
                                            return <Button style={{
                                                background: 'none',
                                                color: 'inherit',
                                                border: 'none',
                                                padding: 0,
                                                font: 'inherit',
                                                cursor: 'pointer',
                                                outline: 'inherit',
                                            }} onClick={() => {
                                                window.open(URL_FRONT + 'tile/'+ rowData.tileId , "_blank", "noreferrer");
                                            }}>{rowData.place === null ? '-' : rowData.place}
                                                <br />
                                                <span style={{ fontSize: '10px' }}>{rowData.PLZ}</span></Button>
                                        }
                                        }
                                    </Cell>
                                </Column>

                                <Column flexGrow={1.8} minWidth={100}>
                                    <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}>Employees</HeaderCell>
                                    <Cell style={{ fontSize: '12px' }}>
                                        {(rowData) => {
                                            return <>{rowData.measurements === 'all' ? numberWithCommas(rowData.employees?.toFixed(0)) : ''}</>;
                                        }}
                                    </Cell>
                                </Column>

                                {!this.state.switch_state ? <Column flexGrow={1.8} minWidth={100}>
                                    <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}>Companies</HeaderCell>
                                    <Cell style={{ fontSize: '12px' }}>
                                        {(rowData) => {
                                            return <>{rowData.measurements === 'all' ? numberWithCommas(rowData.n_companies?.toFixed(0)) : ''}</>;
                                        }}
                                    </Cell>
                                </Column> : <></>}

                                <Column flexGrow={1.5} minWidth={150}>
                                    <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}>{this.state.pkm_or_trips === 'trips' ? 'Trips' : 'PKm'}<br />
                                        <span style={{ fontSize: '10px' }}>Daily Traffic</span></HeaderCell>
                                    <Cell style={{ fontSize: '12px' }}>
                                        {(rowData) => {
                                            if (rowData.measurements === 'all') {
                                                return <>
                                                    {numberWithCommas(rowData.metric?.toFixed(0))}
                                                    <br />
                                                    <span style={{ fontSize: '10px', fontWeight: 600 }}>Cum.: {numberWithCommas(rowData.metric_cum.toFixed(0))}</span>
                                                </>
                                            } else {
                                                return <></>
                                            }
                                        }}
                                    </Cell>
                                </Column>

                                <Column flexGrow={1.5} minWidth={150}>
                                    <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}>{this.state.pkm_or_trips === 'trips' ? 'Trips Pct. (%)' : 'PKm Pct. (%)'}<br />
                                        <span style={{ fontSize: '10px' }}>Daily Traffic</span></HeaderCell>
                                    <Cell style={{ fontSize: '12px' }}>
                                        {(rowData) => {
                                            if (rowData.measurements === 'all') {
                                                return <>
                                                    {(rowData.metric_pctg * 100).toFixed(2)}%
                                                    <br />
                                                    <span style={{ fontSize: '10px', fontWeight: 600 }}>Cum.: {(rowData.metric_cum_pctg * 100).toFixed(2)}%</span>
                                                </>
                                            } else {
                                                return <></>
                                            }
                                        }}
                                    </Cell>
                                </Column>

                                <Column flexGrow={2.5} minWidth={130}>
                                    <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}>Measures</HeaderCell>
                                    <Cell style={{ fontSize: '12px' }}>
                                        {(rowData) => {
                                            if (rowData.measurements === 'all') {
                                                return <div className="dot" style={{ backgroundColor: '#0BA53D' }}><a style={{ paddingLeft: '15px' }}>All Measurements</a></div>;
                                            } else if (rowData.measurements === 'last_mile') {
                                                return <div className="dot" style={{ backgroundColor: '#FF0054' }}><a style={{ paddingLeft: '15px' }}>Last Mile</a></div>;
                                            } else if (rowData.measurements === 'bike') {
                                                return <div className="dot" style={{ backgroundColor: '#9E0059' }}><a style={{ paddingLeft: '15px' }}>Bicycle Culture</a></div>;
                                            } else if (rowData.measurements === 'behavior') {
                                                return <div className="dot" style={{ backgroundColor: '#390099' }}><a style={{ paddingLeft: '15px' }}>Behaviour Change</a></div>;
                                            } else if (rowData.measurements === 'working') {
                                                return <div className="dot" style={{ backgroundColor: '#FF5400' }}><a style={{ paddingLeft: '15px' }}>Working Policy</a></div>;
                                            } else if (rowData.measurements === 'car_pooling') {
                                                return <div className="dot" style={{ backgroundColor: '#1AB5B2' }}><a style={{ paddingLeft: '15px' }}>Car pooling</a></div>;
                                            }
                                        }}
                                    </Cell>
                                </Column>

                                <Column flexGrow={2} minWidth={140}>
                                    <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}>ÖV Quality<br />
                                        <span style={{ fontSize: '10px' }}>Before &#8594; After</span></HeaderCell>
                                    <Cell style={{ fontSize: '12px' }}>
                                        {(rowData) => {
                                            if (rowData.measurements === 'all') {
                                                return <>
                                                    {(rowData.ov_before * 100).toFixed(0)}%  &#8594; {(rowData.ov_after * 100).toFixed(0)}%
                                                    <br />
                                                    <ProgressBar
                                                        completed={(rowData.ov_after * 100).toFixed(0)}
                                                        maxCompleted={100}
                                                        bgColor="#0BA53D"
                                                        height="15px"
                                                        labelAlignment="outside"
                                                        isLabelVisible={false}
                                                        labelColor="#0BA53D"
                                                    />
                                                </>;
                                            } else if (rowData.measurements === 'last_mile') {
                                                return <>
                                                    {(rowData.ov_before * 100).toFixed(0)}%  &#8594; {(rowData.ov_after * 100).toFixed(0)}%
                                                    <br /><ProgressBar
                                                        completed={(rowData.ov_after * 100).toFixed(0)}
                                                        maxCompleted={100}
                                                        bgColor="#FF0054"
                                                        height="15px"
                                                        labelAlignment="outside"
                                                        isLabelVisible={false}
                                                        labelColor="#FF0054"
                                                    /></>;
                                            } else if (rowData.measurements === 'bike') {
                                                return <>
                                                    {(rowData.ov_before * 100).toFixed(0)}%  &#8594; {(rowData.ov_after * 100).toFixed(0)}%
                                                    <br /><ProgressBar
                                                        completed={(rowData.ov_after * 100).toFixed(0)}
                                                        maxCompleted={100}
                                                        bgColor="#9E0059"
                                                        height="15px"
                                                        labelAlignment="outside"
                                                        isLabelVisible={false}
                                                        labelColor="#9E0059"
                                                    /></>;
                                            } else if (rowData.measurements === 'behavior') {
                                                return <>
                                                    {(rowData.ov_before * 100).toFixed(0)}%  &#8594; {(rowData.ov_after * 100).toFixed(0)}%
                                                    <br /><ProgressBar
                                                        completed={(rowData.ov_after * 100).toFixed(0)}
                                                        maxCompleted={100}
                                                        bgColor="#390099"
                                                        height="15px"
                                                        labelAlignment="outside"
                                                        isLabelVisible={false}
                                                        labelColor="#390099"
                                                    /></>;
                                            } else if (rowData.measurements === 'working') {
                                                return <>
                                                    {(rowData.ov_before * 100).toFixed(0)}%  &#8594; {(rowData.ov_after * 100).toFixed(0)}%
                                                    <br /><ProgressBar
                                                        completed={(rowData.ov_after * 100).toFixed(0)}
                                                        maxCompleted={100}
                                                        bgColor="#FF5400"
                                                        height="15px"
                                                        labelAlignment="outside"
                                                        isLabelVisible={false}
                                                        labelColor="#FF5400"
                                                    /></>;
                                            } else if (rowData.measurements === 'car_pooling') {
                                                return <>
                                                    {(rowData.ov_before * 100).toFixed(0)}%  &#8594; {(rowData.ov_after * 100).toFixed(0)}%
                                                    <br /><ProgressBar
                                                        completed={(rowData.ov_after * 100).toFixed(0)}
                                                        maxCompleted={100}
                                                        bgColor="#1AB5B2"
                                                        height="15px"
                                                        labelAlignment="outside"
                                                        isLabelVisible={false}
                                                        labelColor="#1AB5B2"
                                                    /></>;
                                            }
                                        }}
                                    </Cell>
                                </Column>

                                {/* <Column flexGrow={2} minWidth={120}>
                                    <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}>Improvement</HeaderCell>
                                    <Cell style={{ fontSize: '12px' }}>
                                        {(rowData) => {
                                            return <b style={{ color: '#027f80' }}>+{(rowData.improvement * 100).toFixed(0)}%</b>;
                                        }}
                                    </Cell>
                                </Column> */}

                                {/* <Column flexGrow={2.5} minWidth={150}>
                                    <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}>Conversion Rate</HeaderCell>
                                    <Cell style={{ fontSize: '12px' }}>
                                        {(rowData) => {
                                            return <>{(rowData.convertion_rate * 100).toFixed(0)}%</>;
                                        }}
                                    </Cell>
                                </Column> */}

                                <Column flexGrow={2} minWidth={120}>
                                    <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}> Potential <br />
                                        <span style={{ fontSize: '10px' }}>Shifting {this.state.pkm_or_trips === 'trips' ? 'Trips' : 'PKm'}</span></HeaderCell>
                                    <Cell style={{ fontSize: '12px' }}>
                                        {(rowData) => {
                                            return <>{numberWithCommas(rowData.potential?.toFixed(0))}</>;
                                        }}
                                    </Cell>
                                </Column>

                                <Column flexGrow={2} minWidth={120}>
                                    <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}>MIV Reduction </HeaderCell>
                                    <Cell style={{ fontSize: '12px' }}>
                                        {(rowData) => {
                                            return <>{numberWithCommas(rowData.people_shifting.toFixed(0))} <i>({(rowData.shift_of_metrics * 100).toFixed(2)}%)</i></>;
                                        }}
                                    </Cell>
                                </Column>

                                <Column flexGrow={2.5} minWidth={150}>
                                    <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}>People Shifting<br />
                                        <span style={{ fontSize: '10px' }}>Shifting {this.state.pkm_or_trips === 'trips' ? 'Trips' : 'PKm'}</span></HeaderCell>
                                    <Cell style={{ fontSize: '12px' }}>
                                        {(rowData) => {
                                            return <>{numberWithCommas(rowData.real_people_shifting.toFixed(0))} <i>({(rowData.shift_of_employees * 100).toFixed(2)}%)</i></>;
                                        }}
                                    </Cell>
                                </Column>

                                <Column flexGrow={2} minWidth={120}>
                                    <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}>Modal Split<br />
                                        <span style={{ fontSize: '10px' }}>Before &#8594; After</span></HeaderCell>
                                    <Cell style={{ fontSize: '12px' }}>
                                        {(rowData) => {
                                            return <>{(rowData.ms_before * 100).toFixed(0)}% &#8594; {(rowData.ms_after * 100).toFixed(0)}%
                                                <br />
                                                <span style={{ fontSize: '10px', fontWeight: 600, color: '#027f80' }}>{(rowData.ms_improvement * 100).toFixed(0)}% Increase</span></>;
                                        }}
                                    </Cell>
                                </Column>

                                <Column flexGrow={2.5} minWidth={150}>
                                    <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}>Total Traffic Shift <br />
                                        <span style={{ fontSize: '10px' }}>Individual {this.state.current_value_k === null ? 'in Switzerland' : 'in the canton'}</span></HeaderCell>
                                    <Cell style={{ fontSize: '12px' }}>
                                        {(rowData) => {
                                            if ("position" in rowData) {
                                                return <>{(rowData.swiss_level_shift * 1000).toFixed(2)} &#8240;</>
                                            } else {
                                                return <></>
                                            }
                                        }}
                                    </Cell>
                                </Column>

                                <Column flexGrow={2.5} minWidth={150}>
                                    <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}>MIV Traffic Shift <br />
                                        <span style={{ fontSize: '10px' }}>Cumulative {this.state.current_value_k === null ? 'in Switzerland' : 'in the canton'}</span></HeaderCell>
                                    <Cell style={{ fontSize: '12px' }}>
                                    {(rowData) => {
                                            if ("position" in rowData) {
                                                return <>
                                                {(rowData.swiss_level_shift_cumulative * 100).toFixed(2) + '%'}
                                                <br />
                                                <span style={{ fontSize: '10px', fontWeight: 600, color: '#027f80' }}>{(rowData.swiss_level_new_trains_metric_cumulative * 100).toFixed(0)}% New {this.state.pkm_or_trips === 'trips' ? 'Trips' : 'PKm'}</span>
                                                </>;
                                            } else {
                                                return <></>
                                            }
                                        }}
                                    </Cell>
                                </Column>

                                <Column flexGrow={2.5} minWidth={160}>
                                    <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}>B2B<br />
                                        <span style={{ fontSize: '10px' }}>Revenue Potential</span>
                                    </HeaderCell>
                                    <Cell style={{ fontSize: '12px' }}>
                                        {(rowData) => {
                                            if ("position" in rowData) {
                                                return <>{numberWithCommas(rowData.revenue_potential.toFixed(0))}
                                                    <br />
                                                    <span style={{ fontSize: '10px', fontWeight: 600 }}>Cum.: {numberWithCommas(rowData.revenue_potential_cum.toFixed(0))}</span>
                                                </>
                                            }
                                            else {
                                                return <></>
                                            }
                                        }}
                                    </Cell>
                                </Column>

                            </Table>

                            <div style={{ paddingTop: 20, paddingLeft: 20, paddingRight: 20, paddingBottom: 20 }}>
                                <div style={{ margin: '0 auto', width: '60vw' }}>
                                    <ReactPaginate
                                        className="react-paginate"
                                        breakLabel="..."
                                        nextLabel="next >"
                                        pageRangeDisplayed={3}
                                        forcePage={this.state.current_page}
                                        onPageChange={this.handlePageClick.bind(this)}
                                        pageCount={this.state.max_page}
                                        previousLabel="< previous"
                                        renderOnZeroPageCount={null}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.showDetails ?
                    <div className="panel-detail">
                        <div className="panel-detail-inner">
                            <div className="row" style={{ justifyItems: 'end' }}>
                                <div style={{ width: '1.2em' }} onClick={() => {
                                    this.setState({ showDetails: false, rowDataSelected: [] })
                                }}>
                                    <IconContext.Provider value={{ color: "#5B5B5B", size: '1em' }}>
                                        <div>
                                            <RxCross2 />
                                        </div>
                                    </IconContext.Provider>
                                </div>
                            </div>
                            {
                                this.state.loading_details ?
                                    <div className="data_insights_loader">
                                        <div className="loader"></div>
                                    </div>
                                    :
                                    <>
                                        <div className="row" style={{ justifyContent: 'start' }}>
                                            <h1>{this.state.rowDataSelected.place}</h1>
                                            <h2>{numberWithCommas(this.state.rowDataSelected.employees?.toFixed(0))} employees {this.state.switch_state ? '' : 'in ' + this.state.rowDataSelected.n_companies + ' companies '}generating {numberWithCommas(this.state.rowDataSelected.metric?.toFixed(0))}{this.state.pkm_or_trips === 'trips' ? ' Trips' : 'PKm'}</h2>
                                        </div>

                                        <div className="row" style={{ paddingLeft: '20px', paddingRight: '20px', height: '280px' }}>
                                            {
                                                this.state.carousel_num === 1 ?
                                                    <div className="row" style={{ paddingTop: '10px', paddingLeft: '20px', paddingRight: '20px', height: '270px' }}>
                                                        <div className="map-container" id="map-container" />
                                                    </div>
                                                    :
                                                    <>
                                                        {this.state.carousel_num === 2 ?
                                                            <Line data={dataLine} options={options} />
                                                            :
                                                            <Line data={dataLine_TraveledKm} options={kmPerDay} />
                                                        }
                                                    </>
                                            }
                                        </div>

                                        <div className="row" style={{ paddingLeft: '30%', paddingRight: '30%', height: '40px' }}>
                                            <div className="column-33">
                                                <Button variant="info" disabled={this.state.carousel_num === 1} className="generate-btn" onClick={() => { this.setState({ carousel_num: 1 }) }}>1</Button>
                                            </div>
                                            <div className="column-33">
                                                <Button variant="info" disabled={this.state.carousel_num === 2} className="generate-btn" onClick={() => { this.setState({ carousel_num: 2 }) }}>2</Button>
                                            </div>
                                            <div className="column-33">
                                                <Button variant="info" disabled={this.state.carousel_num === 3} className="generate-btn" onClick={() => { this.setState({ carousel_num: 3 }) }}>3</Button>
                                            </div>
                                        </div>

                                        <div className="row" style={{ justifyContent: 'start' }}>
                                            <h3>Modal Split Prediction</h3>
                                        </div>

                                        <div className="row" style={{ paddingLeft: '20px', paddingRight: '20px', height: '280px' }}>
                                            <Line data={dataMSPrediction} options={optionMSPrediction} />

                                        </div>

                                        {/* <div className="row" style={{ justifyContent: 'start' }}>
                                            <h3>Analyze Tile</h3>
                                        </div> */}

                                    </>
                            }
                        </div>
                    </div>
                    :
                    <>
                    </>}
            </>
        )
    }
}

export default Dashboard_5_in_5_BLS;