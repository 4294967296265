import React from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '../css/map-drop-point.css';
import { Form, Button } from 'react-bootstrap';
import { IconContext } from "react-icons";
import { BsLayersHalf } from "react-icons/bs";
import RecommendationNavBarComponent from "../../components/RecommendationNavBarComponent";
import { RiFullscreenFill, RiFullscreenExitFill } from "react-icons/ri";
import Chart from 'chart.js/auto';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Line } from 'react-chartjs-2';
import { VscGraphLine } from "react-icons/vsc";
import axios from 'axios';
import Select from 'react-select';
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const URL = process.env.REACT_APP_URL_SERVER_BACK;
const delay = ms => new Promise(res => setTimeout(res, ms));

const _ = require('lodash');
let current_checkbox = false;

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
}

// Make visible the tile

class HideElementsComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            hideNavs: false,
            mapa: this.props.map,
        }
    }

    hideComponent() {
        let newHideNavs = !this.state.hideNavs;
        this.setState({ hideNavs: newHideNavs })
        this.props.updateField("hideNavs", newHideNavs);
    }

    render() {

        const { hideNavs } = this.state;

        return (
            <div>
                {hideNavs ? <>
                    <div className='map-close-navs'>
                        <div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
                            <IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
                                <div>
                                    <RiFullscreenExitFill />
                                </div>
                            </IconContext.Provider>
                        </div>
                    </div>
                </> : <>
                    <div className='map-close-navs'>
                        <div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
                            <IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
                                <div>
                                    <RiFullscreenFill />
                                </div>
                            </IconContext.Provider>
                        </div>
                    </div>
                </>}
            </div>
        )
    }
}

let lines_geojson = {
    'type': 'FeatureCollection',
    'features': [
        {
            'type': 'Feature',
            'geometry': {
                "type": "LineString",
                "coordinates": [
                ]
            }
        }
    ]
};

let stations_geojson = {
    'type': 'FeatureCollection',
    'features': [
        {
            'type': 'Feature',
            'geometry': {
                "type": "LineString",
                "coordinates": [
                ]
            }
        }
    ]
};

//Layer Tyles Selection - Side Menu
class LayerTylesComponent extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            showOptions: false,
            mapa: this.props.map,
        }
        this.hideComponent = this.hideComponent.bind(this);
        this.handleTyleEvent = this.handleTyleEvent.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.map !== prevProps.map) {
            this.setState({ mapa: this.props.map })
        }
    }

    hideComponent(name) {
        this.setState({ showOptions: !this.state.showOptions });
    }

    handleTyleEvent(event) {
        switch (event) {
            case "default":
                this.props.handleTyle("mapbox://styles/mapbox/light-v10");
                break;
            case "outdoors":
                this.props.handleTyle("mapbox://styles/mapbox/outdoors-v11");
                break;
            case "satellite":
                this.props.handleTyle("mapbox://styles/mapbox/satellite-streets-v11");
                break;
            case "streets":
                this.props.handleTyle("mapbox://styles/mapbox/streets-v11");
                break;
            case "dark":
                this.props.handleTyle("mapbox://styles/mapbox/dark-v10");
                break;
            default:
                // eslint-disable-next-line no-unused-expressions
                null;
        }
        this.props.handleCoord(this.state.mapa.getCenter(), this.state.mapa.getZoom());
    }

    render() {
        const { showOptions } = this.state;

        return (
            <div>
                {showOptions ? <>
                    <div id='layer-options' className='map-layer-options-tyles'>
                        <div className='map-layer-option-dark' onClick={() => this.handleTyleEvent("dark")}>
                            <IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
                                <div>
                                    <img src={require('../../assets/img/Dark.png')} alt="Default tyle" className='img-tyle' />
                                </div>
                            </IconContext.Provider>
                            <p className='label'>Dark</p>
                        </div>
                        <div className='map-layer-option-streets' onClick={() => this.handleTyleEvent("streets")}>
                            <IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
                                <div>
                                    <img src={require('../../assets/img/Streets.png')} alt="Default tyle" className='img-tyle' />
                                </div>
                            </IconContext.Provider>
                            <p className='label'>Streets</p>
                        </div>
                        <div className='map-layer-option-satellite' onClick={() => this.handleTyleEvent("satellite")}>
                            <IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
                                <div>
                                    <img src={require('../../assets/img/Satellite.png')} alt="Default tyle" className='img-tyle' />
                                </div>
                            </IconContext.Provider>
                            <p className='label'>Satellite</p>
                        </div>
                        <div className='map-layer-option-outdoors' onClick={() => this.handleTyleEvent("outdoors")}>
                            <IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
                                <div>
                                    <img src={require('../../assets/img/Outdoors.png')} alt="Default tyle" className='img-tyle' />
                                </div>
                            </IconContext.Provider>
                            <p className='label'>Outdoors</p>
                        </div>
                        <div className='map-layer-option-default' onClick={() => this.handleTyleEvent("default")}>
                            <IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
                                <div>
                                    <img src={require('../../assets/img/White.png')} alt="Default tyle" className='img-tyle' />
                                </div>
                            </IconContext.Provider>
                            <p className='label'>Default</p>
                        </div>
                    </div>
                </> : <></>}
                <div className='map-layer-tyles'>
                    <div className='map-layer-tyles-inner' onClick={() => this.hideComponent("showOptions")}>
                        <IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
                            <div>
                                <BsLayersHalf />
                            </div>
                        </IconContext.Provider>
                    </div>
                </div>
            </div>
        )
    }
}

class GraphComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            mapa: this.props.map,
            graphParam: this.props.graphParam,
            take_od: 0,
            respModalPKM: [],
            respModalTrips: [],
            showGraph: false,
            key_array: [],
            datasetMSPKm: [],
            datasetMSTrips: [],
            excelData: [],
            coord: this.props.coord,
            respPkmC: [],
            datasetPKmC: [],
            respPkmT: [],
            datasetPKmT: [],
            loading: this.props.loading
        }
    }

    componentDidMount() {
        this.handleFetchDataModalPKM = this.handleFetchDataModalPKM.bind(this);
        this.handleFetchDataModalTrips = this.handleFetchDataModalTrips.bind(this);
        this.handleGraphComponent = this.handleGraphComponent.bind(this);
        this.handleFetchDataExcel = this.handleFetchDataExcel.bind(this);
        this.handleFetchDataPKM = this.handleFetchDataPKM.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.map !== prevProps.map) {
            this.setState({ mapa: this.props.map });
        }
        if (this.props.coord !== prevProps.coord) {
            this.setState({ coord: this.props.coord });
        }
        if (this.props.graphParam !== prevProps.graphParam) {
            this.setState({ graphParam: this.props.graphParam });
            if (this.props.graphParam.length === 0) {
                this.setState({
                    respModalPKM: [],
                    respModalTrips: [],
                    showGraph: false,
                    key_array: [],
                    datasetMSPKm: [],
                    datasetMSTrips: [],
                    excelData: []
                })
            } else {
                this.setState({ showGraph: true })
                this.handleFetchDataModalPKM(this.props.graphParam);
                this.handleFetchDataPKM(this.props.graphParam);
                this.handleFetchDataModalTrips(this.props.graphParam);
                this.handleFetchDataExcel(this.props.graphParam);
            }
        }
    }

    async handleFetchDataPKM(param) {
        fetch(URL + 'tile_od_agg/traveled_km',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ tile_list: param })
            })
            .then((res) => res.json())
            .then((json) => {
                let keys = Object.keys(json)
                this.setState({ key_array: keys })

                let current_PKm_C = []
                let current_PKm_T = []

                for (var key_index = 0; key_index < keys.length; key_index++) {

                    let arrayCurrent_PKm_C = json[keys[key_index]]['traveled_km_cars']
                    let arrayCurrent_PKm_T = json[keys[key_index]]['traveled_km_trains']


                    for (var i = 0; i < json[keys[key_index]]['traveled_km_cars'].length; i++) {
                        arrayCurrent_PKm_C[i] = (Number(json[keys[key_index]]['traveled_km_cars'].at(i)));
                        arrayCurrent_PKm_T[i] = (Number(json[keys[key_index]]['traveled_km_trains'].at(i)));
                    }

                    if (json[keys[key_index]]['color'] === '255, 0, 110') {
                        current_PKm_C.push({
                            label: keys[key_index],
                            data: arrayCurrent_PKm_C,
                            backgroundColor: [
                                'rgba(' + json[keys[key_index]]['color'] + ', 0.2)'
                            ],
                            borderColor: [
                                'rgb(' + json[keys[key_index]]['color'] + ')'
                            ]
                        })

                        current_PKm_T.push({
                            label: keys[key_index],
                            data: arrayCurrent_PKm_T,
                            backgroundColor: [
                                'rgba(' + json[keys[key_index]]['color'] + ', 0.2)'
                            ],
                            borderColor: [
                                'rgb(' + json[keys[key_index]]['color'] + ')'
                            ]
                        })
                    } else {
                        if (keys[key_index] === 'average') {
                            current_PKm_C.push({
                                label: keys[key_index],
                                data: arrayCurrent_PKm_C,
                                backgroundColor: [
                                    'rgba(' + json[keys[key_index]]['color'] + ', 0.2)'
                                ],
                                borderColor: [
                                    'rgb(' + json[keys[key_index]]['color'] + ')'
                                ],
                                borderWidth: 1
                            })

                            current_PKm_T.push({
                                label: keys[key_index],
                                data: arrayCurrent_PKm_T,
                                backgroundColor: [
                                    'rgba(' + json[keys[key_index]]['color'] + ', 0.2)'
                                ],
                                borderColor: [
                                    'rgb(' + json[keys[key_index]]['color'] + ')'
                                ],
                                borderWidth: 1
                            })
                        }
                        else {
                            current_PKm_C.push({
                                label: keys[key_index],
                                data: arrayCurrent_PKm_C,
                                backgroundColor: [
                                    'rgba(' + json[keys[key_index]]['color'] + ', 0.2)'
                                ],
                                borderColor: [
                                    'rgb(' + json[keys[key_index]]['color'] + ')'
                                ],
                                borderWidth: 1,
                                hidden: true
                            })

                            current_PKm_T.push({
                                label: keys[key_index],
                                data: arrayCurrent_PKm_T,
                                backgroundColor: [
                                    'rgba(' + json[keys[key_index]]['color'] + ', 0.2)'
                                ],
                                borderColor: [
                                    'rgb(' + json[keys[key_index]]['color'] + ')'
                                ],
                                borderWidth: 1,
                                hidden: true
                            })
                        }
                    }
                }

                this.setState({ datasetPKmC: current_PKm_C });
                this.setState({ datasetPKmT: current_PKm_T });

                return json
            })
            .then((json) => {
                this.setState({ respPkmC: json });
                this.setState({ respPkmT: json });

            })
    }

    async handleFetchDataModalTrips(param) {
        fetch(URL + 'tile_od_agg/modal_split_trips',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ tile_list: param })
            })
            .then((res) => res.json())
            .then((json) => {
                let keys = Object.keys(json)
                this.setState({ key_array: keys })

                let current_MS = []
                for (var key_index = 0; key_index < keys.length; key_index++) {

                    let arrayCurrentMS = json[keys[key_index]]['modal_split_trains']

                    for (var i = 0; i < json[keys[key_index]]['modal_split_trains'].length; i++) {
                        arrayCurrentMS[i] = (Number(json[keys[key_index]]['modal_split_trains'].at(i)) * 100);
                    }

                    if (json[keys[key_index]]['color'] === '255, 0, 110') {
                        current_MS.push({
                            label: keys[key_index],
                            data: arrayCurrentMS,
                            backgroundColor: [
                                'rgba(' + json[keys[key_index]]['color'] + ', 0.2)'
                            ],
                            borderColor: [
                                'rgb(' + json[keys[key_index]]['color'] + ')'
                            ]
                        })
                    } else if (keys[key_index] === 'average') {
                        current_MS.push({
                            label: keys[key_index],
                            data: arrayCurrentMS,
                            backgroundColor: [
                                'rgba(' + json[keys[key_index]]['color'] + ', 0.2)'
                            ],
                            borderColor: [
                                'rgb(' + json[keys[key_index]]['color'] + ')'
                            ],
                            borderWidth: 1
                        })
                    } else {
                        current_MS.push({
                            label: keys[key_index],
                            data: arrayCurrentMS,
                            backgroundColor: [
                                'rgba(' + json[keys[key_index]]['color'] + ', 0.2)'
                            ],
                            borderColor: [
                                'rgb(' + json[keys[key_index]]['color'] + ')'
                            ],
                            borderWidth: 1,
                            hidden: true
                        })
                    }
                }

                this.setState({ datasetMSTrips: current_MS });
                return json
            })
            .then((json) => {
                this.setState({ respModalTrips: json });
            }).then(() => this.props.updateFields({ loading: false }))
    }

    async handleFetchDataModalPKM(param) {
        fetch(URL + 'tile_od_agg/modal_split_pkm',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ tile_list: param })
            })
            .then((res) => res.json())
            .then((json) => {
                let keys = Object.keys(json)
                this.setState({ key_array: keys })

                let current_MS = []
                for (let key_index of keys) {

                    let arrayCurrentMS = json[key_index]['modal_split']

                    for (let i = 0; i < json[key_index]['modal_split'].length; i++) {
                        arrayCurrentMS[i] = (Number(json[key_index]['modal_split'].at(i)) * 100);
                    }

                    if (json[key_index]['color'] === '255, 0, 110') {
                        current_MS.push({
                            label: key_index,
                            data: arrayCurrentMS,
                            backgroundColor: [
                                'rgba(' + json[key_index]['color'] + ', 0.2)'
                            ],
                            borderColor: [
                                'rgb(' + json[key_index]['color'] + ')'
                            ]
                        })
                    } else if (key_index === 'average') {
                        current_MS.push({
                            label: key_index,
                            data: arrayCurrentMS,
                            backgroundColor: [
                                'rgba(' + json[key_index]['color'] + ', 0.2)'
                            ],
                            borderColor: [
                                'rgb(' + json[key_index]['color'] + ')'
                            ],
                            borderWidth: 1
                        })
                    } else {
                        current_MS.push({
                            label: key_index,
                            data: arrayCurrentMS,
                            backgroundColor: [
                                'rgba(' + json[key_index]['color'] + ', 0.2)'
                            ],
                            borderColor: [
                                'rgb(' + json[key_index]['color'] + ')'
                            ],
                            borderWidth: 1,
                            hidden: true
                        })
                    }
                }

                this.setState({ datasetMSPKm: current_MS });
                return json
            })
            .then((json) => {
                this.setState({ respModalPKM: json });
            })
    }

    fetchDetails(url) {
        let coords = url.split(/[/]+/).pop()

        const tyle_corner = {
            center: [coords.split(",")[0], coords.split(",")[1]],
            zoom: 14.5,
            pitch: 0,
            bearing: 0
        }
        this.props.map.flyTo({
            ...tyle_corner, // Fly to the selected target
            duration: 2000, // Animate over 2 seconds
            essential: false // This animation is considered essential with
            //respect to prefers-reduced-motion
        })
    }

    handleFetchDataExcel(param) {
        fetch(URL + 'tile_od_agg/return_agg_table',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ tile_list: param })
            })
            .then((res) => res.json())
            .then((json) => {
                let valuesCorrect = []

                for (let i = 0; i < json.tileId.length; i++) {
                    valuesCorrect.push({
                        "tileId": json.tileId[i],
                        "modal_split_before": json['modal_split_before (april 2019- june 2019)'][i],
                        "modal_split_after": json['modal_split_after (april 2023- june 2023)'][i],
                        "modal_split_ratio": json['Modal Split Ratio (percentage)'][i],
                        "average_pkm_per_month_before": json['average pkm per month before (april 2019- june 2019)'][i],
                        "average_pkm_per_month_after": json['average pkm per month after (april 2023- june 2023)'][i],
                        "PKM difference": json['PKM difference'][i],
                        "total_pkm_last_12_months": json['total_pkm_last_12_months'][i],
                        "train_pkm_last_12_months": json['train_pkm_last_12_months'][i],
                        "car_pkm_last_12_months": json['car_pkm_last_12_months'][i],
                        "tile_url": json['tile_url'][i],
                        "PLZ_name": json['PLZ name'][i],
                        "Gemeinde name": json['Gemeinde name'][i],
                        "color": json["color"][i]
                    })
                }

                this.setState({ excelData: valuesCorrect })
            })
    }

    handleGraphComponent() {
        this.setState({ showGraph: !this.state.showGraph });
    }

    render() {
        const { respModalPKM } = this.state;

        const { datasetPKmT } = this.state;
        const { datasetPKmC } = this.state;
        const { datasetMSPKm } = this.state;
        const { datasetMSTrips } = this.state;
        const { showGraph } = this.state;

        const { loading } = this.props;

        const dataBar_ModalPKM = {
            labels: (respModalPKM[this.state.key_array[1]]?.date.lenght>0 ? respModalPKM[this.state.key_array[1]]?.date:respModalPKM[this.state.key_array[2]]?.date ),
            datasets: datasetMSPKm
        };

        const dataBar_ModalTrips = {
            labels: (respModalPKM[this.state.key_array[1]]?.date.lenght>0 ? respModalPKM[this.state.key_array[1]]?.date:respModalPKM[this.state.key_array[2]]?.date ),
            datasets: datasetMSTrips
        }

        const dataBar_PKmC = {
            labels: (respModalPKM[this.state.key_array[1]]?.date.lenght>0 ? respModalPKM[this.state.key_array[1]]?.date:respModalPKM[this.state.key_array[2]]?.date ),
            datasets: datasetPKmC
        };

        const dataBar_PKmT = {
            labels: (respModalPKM[this.state.key_array[1]]?.date.lenght>0 ? respModalPKM[this.state.key_array[1]]?.date:respModalPKM[this.state.key_array[2]]?.date ),
            datasets: datasetPKmT
        };

        const options = {
            xAxes: [{
                type: 'time',
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 10
                }
            }],
            plugins: {
                title: {
                    display: true,
                    text: 'Percentage of Trains\' Modal Split (PKm) per Month'
                },
                legend: {
                    position: 'bottom',
                },
            },
        };

        const options_pkmC = {
            xAxes: [{
                type: 'time',
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 10
                }
            }],
            plugins: {
                title: {
                    display: true,
                    text: 'Monthly PKm by Car'
                },
                legend: {
                    position: 'bottom',
                },
            },
        };

        const options_pkmT = {
            xAxes: [{
                type: 'time',
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 10
                }
            }],
            plugins: {
                title: {
                    display: true,
                    text: 'Monthly PKm by Train'
                },
                legend: {
                    position: 'bottom',
                },
            },
        };

        const options_trips = {
            xAxes: [{
                type: 'time',
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 10
                }
            }],
            plugins: {
                title: {
                    display: true,
                    text: 'Percentage of Trains\' Modal Split (Trips) per Month'
                },
                legend: {
                    position: 'bottom',
                },
            },
        };

        const listTopOrigins = this.state.excelData?.map((data_point, index) =>
            <tr className="data" key={index} onClick={() => this.fetchDetails(data_point?.tile_url)} style={{ backgroundColor: 'rgba('+data_point?.color+', 0.1)' }}>
                <td className="table-data">{(data_point?.tileId)}</td>
                <td className="table-data">{numberWithCommas((data_point?.modal_split_before * 100)?.toFixed(0))}%</td>
                <td className="table-data">{numberWithCommas((data_point?.modal_split_after * 100)?.toFixed(0))}%</td>
                <td className="table-data">{(data_point?.modal_split_ratio)?.toFixed(0)}%</td>
                <td className="table-data">{numberWithCommas((data_point?.average_pkm_per_month_before)?.toFixed(0))}</td>
                <td className="table-data">{numberWithCommas((data_point?.average_pkm_per_month_after)?.toFixed(0))}</td>
            </tr>
        );

        if (loading) {
            return (<div></div>)
        } else {
            return (
                <div>
                    <div className='map-graph-button-winner-loser'>
                        <div className='map-graph-button-winner-loser-inner' onClick={() => this.handleGraphComponent("showGraph")}>
                            <IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
                                <div>
                                    <VscGraphLine />
                                </div>
                            </IconContext.Provider>
                        </div>
                    </div>
                    {(showGraph && this.state.graphParam.length > 0) ? <>
                        <div className="modal-graph-tile-selector-ms">
                            <div className="modal-graph-tile-selector-ms-inner">
                                <Tabs
                                    defaultActiveKey="modalsplitpkm"
                                    id="uncontrolled-tab-example"
                                    className="mb-3"
                                >
                                    <Tab eventKey="modalsplitpkm" title="Modal Split (PKm)">
                                        <Line data={dataBar_ModalPKM} options={options} />
                                    </Tab>
                                    <Tab eventKey="modalsplittrips" title="Modal Split (Trips)">
                                        <Line data={dataBar_ModalTrips} options={options_trips} />
                                    </Tab>
                                    <Tab eventKey="graphPKmCar" title="PKm by Car">
                                        <Line data={dataBar_PKmC} options={options_pkmC} />
                                    </Tab>
                                    <Tab eventKey="graphPKmTrain" title="PKm by Train">
                                        <Line data={dataBar_PKmT} options={options_pkmT} />
                                    </Tab>
                                    <Tab eventKey="ExcelView" title="Tiles' Ranking">
                                        <div className="tile-selector-excel-inner">
                                            <br />
                                            <table className='table-center-marketing'>
                                                <thead>
                                                    <tr className="title">
                                                        <th className="table-title-marketing">Tile Name</th>
                                                        <th className="table-title-marketing">ModalSplit 2019</th>
                                                        <th className="table-title-marketing">ModalSplit 2023</th>
                                                        <th className="table-title-marketing">ModalSplit Ratio</th>
                                                        <th className="table-title-marketing">Average PKm 2019</th>
                                                        <th className="table-title-marketing">Average PKm  2023</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {listTopOrigins}
                                                </tbody>
                                            </table>
                                        </div>
                                        <br />
                                    </Tab>
                                </Tabs>
                                <Link to={'/drop-a-point/' + this.props.coord['*'].split(',')[0] + ',' + this.props.coord['*'].split(',')[1]} target="_blank" style={{ textDecoration: 'none' }}>
                                    <Button disabled={this.props.coord.length === 0} variant="info" className="co2-3dview-btn">Drop a point view</Button>
                                </Link>
                            </div>
                        </div>
                    </> : <></>}
                </div>
            )
        }

    }
}

class BtnsComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            mapa: this.props.map,
            generatedTyles: false,
            loading: this.props.loading,
            array_selected_tiles: this.props.array_selected_tiles,
            min_distance_km: 0,
            max_distance_km: 400,
            n_trips: 0,
            value_od_dates: ({
                "value": "2022-08",
                "label": '2022-08'
            }),
            coord: this.props.coord,
            tag_options: this.getOptionsTags(URL + 'winners_vs_losers/return_hypothesis_tags'),
            selected_tag: ({
                "value": "lastmile",
                "label": 'lastmile'
            }),
            dates_trips_: this.getOptionsDate(URL + 'db_od_trips'),
            current_checkbox: false,
            current_url: '',
            current_comment: '',
            current_station: this.props.current_station,
            new_tag: false,
            new_tag_name: '',
            openAI_response: false,
            openAI_text: '',
            validated_hypothesis: 'No hypothesis validated for this tile'
        }
        this.handleFetchDataCoordParam()
    }

    handleFetchDataCoordParam() {
        if (this.state.coord['*'] !== "") {
                this.setState({ generatedTyles: !this.state.generatedTyles });
                this.handleVisualization(parseFloat(this.state.coord['*'].split(',')[0]), parseFloat(this.state.coord['*'].split(',')[1]));
        }
    }

    handleMonthYear(event) {
        this.setState({ value_od_dates: event });
        let id_DF = 0
        let element_found = false;

        fetch(URL + 'areas_to_focus/tile/coord/' + parseFloat(this.state.coord['*'].split(',')[0]) + ',' + parseFloat(this.state.coord['*'].split(',')[1]))
            .then((res) => res.json())
            .then((json) => id_DF = json.tileId)
            .then(() => {
                fetch(URL + 'winners_vs_losers/return_hypothesis/' + id_DF)
                    .then((res) => res.json())
                    .then((json) => {
                        if (json.length === 0) {
                            if ('frequency' === event.value) {
                                this.props.map.getSource('data-circles-stations').setData(URL + 'winners_vs_losers/return_stops_in_tile/' + id_DF);
                                this.props.map.setLayoutProperty('data-circles-stations', 'visibility', 'visible');

                                this.setState({ validated_hypothesis: 'No hypothesis validated for this tile' });
                            }
                        } else {
                            let current_hypothesis = ''
                            let array_dates = []

                            for (let category of json) {
                                array_dates.push("(" + category.inflection_date.toString().split('T')[0].slice(0, -3) + ")" + category.tag)

                                if (category.tag === this.state.selected_tag.value && category.inflection_date.toString().split('T')[0].slice(0, -3) === event.value) {
                                    if ('frequency' === event.value) {
                                        this.props.map.getSource('data-circles-stations').setData(URL + 'winners_vs_losers/return_stops_in_tile/' + id_DF);
                                        this.props.map.setLayoutProperty('data-circles-stations', 'visibility', 'visible');
                                        this.setState({
                                            selected_tag: ({
                                                "value": category.tag,
                                                "label": category.tag
                                            }),
                                            current_comment: category.comment,
                                            current_station: category.stop_id,
                                            current_url: category.source_link,
                                            openAI_text: category.openai_response,
                                            openAI_response: true
                                        })
                                        current_checkbox = category.correlates
                                    }
                                    else {
                                        this.props.map.setLayoutProperty('data-circles-stations', 'visibility', 'none');

                                        this.setState({
                                            selected_tag: ({
                                                "value": category.tag,
                                                "label": category.tag
                                            }),
                                            current_comment: category.comment,
                                            current_station: 0,
                                            current_url: category.source_link,
                                            openAI_response: false
                                        })

                                        current_checkbox = category.correlates
                                    }
                                    element_found = true;
                                }
                            }

                            array_dates = array_dates.sort()

                            for (let category of array_dates) {
                                let elem = category.split(')')

                                if (current_hypothesis === '') {
                                    current_hypothesis += elem[1] + elem[0] + ')'
                                } else {
                                    current_hypothesis += ' ~ ' + elem[1] + elem[0] + ')'
                                }
                            }

                            this.setState({ validated_hypothesis: current_hypothesis })
                        }

                        if (!element_found) {
                            this.props.map.setLayoutProperty('data-circles-stations', 'visibility', 'none');

                            let new_date = {
                                "value": event.value,
                                "label": event.value
                            }
                            this.setState({
                                value_od_dates: new_date,
                                current_comment: '',
                                current_station: 0,
                                current_url: '',
                                openAI_response: false
                            })
                        }
                    })

            })
    }

    handleTagOptions(event) {
        this.setState({ selected_tag: event });
        let id_DF = 0
        let element_found = false;

        fetch(URL + 'areas_to_focus/tile/coord/' + parseFloat(this.state.coord['*'].split(',')[0]) + ',' + parseFloat(this.state.coord['*'].split(',')[1]))
            .then((res) => res.json())
            .then((json) => id_DF = json.tileId)
            .then(() => {
                fetch(URL + 'winners_vs_losers/return_hypothesis/' + id_DF)
                    .then((res) => res.json())
                    .then((json) => {
                        if (json.length === 0) {
                            if ('frequency' === event.value) {
                                this.props.map.getSource('data-circles-stations').setData(URL + 'winners_vs_losers/return_stops_in_tile/' + id_DF);
                                this.props.map.setLayoutProperty('data-circles-stations', 'visibility', 'visible');

                                this.setState({ validated_hypothesis: 'No hypothesis validated for this tile' });
                            }
                        } else {
                            let current_hypothesis = ''
                            let array_dates = []

                            for (let category of json) {
                                array_dates.push("(" + category.inflection_date.toString().split('T')[0].slice(0, -3) + ")" + category.tag)

                                if (category.tag === event.value && category.inflection_date.toString().split('T')[0].slice(0, -3) === this.state.value_od_dates.value) {
                                    if ('frequency' === event.value) {
                                        this.props.map.getSource('data-circles-stations').setData(URL + 'winners_vs_losers/return_stops_in_tile/' + id_DF);
                                        this.props.map.setLayoutProperty('data-circles-stations', 'visibility', 'visible');
                                        this.setState({
                                            value_od_dates: ({
                                                "value": category.inflection_date.toString().split('T')[0].slice(0, -3),
                                                "label": category.inflection_date.toString().split('T')[0].slice(0, -3)
                                            }),
                                            selected_tag: ({
                                                "value": category.tag,
                                                "label": category.tag
                                            }),
                                            current_comment: category.comment,
                                            current_station: category.stop_id,
                                            current_url: category.source_link,
                                            openAI_text: category.openai_response,
                                            openAI_response: true
                                        })
                                    }
                                    else {
                                        this.props.map.setLayoutProperty('data-circles-stations', 'visibility', 'none');

                                        let new_date = {
                                            "value": category.inflection_date.toString().split('T')[0].slice(0, -3),
                                            "label": category.inflection_date.toString().split('T')[0].slice(0, -3)
                                        }

                                        this.setState({
                                            value_od_dates: new_date,
                                            selected_tag: ({
                                                "value": category.tag,
                                                "label": category.tag
                                            }),
                                            current_comment: category.comment,
                                            current_station: 0,
                                            current_url: category.source_link,
                                            openAI_response: false
                                        })

                                    }
                                    element_found = true;
                                    this.setState({current_checkbox: category.correlates})
                                }
                            }

                            array_dates = array_dates.sort()

                            for (let category of array_dates) {
                                let elem = category.split(')')

                                if (current_hypothesis === '') {
                                    current_hypothesis += elem[1] + elem[0] + ')'
                                } else {
                                    current_hypothesis += ' ~ ' + elem[1] + elem[0] + ')'
                                }
                            }

                            this.setState({ validated_hypothesis: current_hypothesis })
                        }

                        if (!element_found) {
                            this.props.map.setLayoutProperty('data-circles-stations', 'visibility', 'none');

                            let new_date = {
                                "value": this.state.value_od_dates.value,
                                "label": this.state.value_od_dates.value
                            }
                            this.setState({
                                value_od_dates: new_date,
                                current_comment: '',
                                current_station: 0,
                                current_url: '',
                                openAI_response: false
                            })
                        }
                    })

            })
    }

    handleNewTag() {
        this.setState({ new_tag: true })
    }

    handleNewTagFalse() {
        this.setState({ new_tag: false })
    }

    handleCurrentURL(event) {
        this.setState({ current_url: event.target.value })
    }

    handleCheckboxHypothesis() {
        this.setState({ current_checkbox: !this.state.current_checkbox });
    }

    async getOptionsDate(url) {
        const res = await axios.get(url)
        const data = res.data
        const options = data.map(d => ({
            "value": d._id.date.toString().split('T')[0].slice(0, -3),
            "label": d._id.date.toString().split('T')[0].slice(0, -3)
        }))
        this.setState({ dates_trips_: options });
    }

    handleCurrentComment(event) {
        this.setState({ current_comment: event.target.value })
    }

    async getOptionsTags(url) {
        const res = await axios.get(url)
        const data = res.data
        const options = data.tags.map(d => ({
            "value": d,
            "label": d
        }))
        this.setState({ tag_options: options });
    }

    componentDidUpdate(prevProps) {
        if (this.props.map !== prevProps.map) {
            this.setState({ mapa: this.props.map });
        }
        if (this.props.coord !== prevProps.coord) {
            this.setState({ coord: this.props.coord });
        }
    }

    handleSaveDetails() {

        let id_DF = 0

        fetch(URL + 'areas_to_focus/tile/coord/' + parseFloat(this.state.coord['*'].split(',')[0]) + ',' + parseFloat(this.state.coord['*'].split(',')[1]))
            .then((res) => res.json())
            .then((json) => id_DF = json.tileId)
            .then(() => {

                let body = {}

                if (this.state.selected_tag.value === 'frequency') {
                    body = {
                        "inflection_date": this.state.value_od_dates.value,
                        "tag": this.state.selected_tag.value,
                        "comment": this.state.current_comment,
                        "source_link": this.state.current_url,
                        "correlates": this.state.current_checkbox,
                        "stop_id": this.state.current_station
                    }
                }
                else {
                    body = {
                        "inflection_date": this.state.value_od_dates.value,
                        "tag": this.state.selected_tag.value,
                        "comment": this.state.current_comment,
                        "source_link": this.state.current_url,
                        "correlates": this.state.current_checkbox
                    }
                }

                fetch(URL + 'winners_vs_losers/return_hypothesis/' + id_DF,
                    {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(body)
                    })
                    .then((res) => res.json())
                    .then((json) => {

                        console.log('se guardo exitosamente', json.saved_status)
                    }).then(() => {
                        fetch(URL + 'winners_vs_losers/return_hypothesis/' + id_DF)
                            .then((res) => res.json())
                            .then((json) => {
                                let current_hypothesis = ''
                                let array_dates = []

                                for (let category of json) {
                                    array_dates.push("(" + category.inflection_date.toString().split('T')[0].slice(0, -3) + ")" + category.tag)
                                    if (current_hypothesis === '') {
                                        current_hypothesis += category.tag + "(" + category.inflection_date.toString().split('T')[0].slice(0, -3) + ")"
                                    } else {
                                        current_hypothesis += ' ~ ' + category.tag + "(" + category.inflection_date.toString().split('T')[0].slice(0, -3) + ")"
                                    }
                                }

                                array_dates = array_dates.sort()

                                for (let category of array_dates) {
                                    let elem = category.split(')')

                                    if (current_hypothesis === '') {
                                        current_hypothesis += elem[1] + elem[0] + ')'
                                    } else {
                                        current_hypothesis += ' ~ ' + elem[1] + elem[0] + ')'
                                    }
                                }

                                this.setState({ validated_hypothesis: current_hypothesis })

                            })
                    })
            })

    }

    handleAddNewTag() {

        let id_DF = 0

        fetch(URL + 'areas_to_focus/tile/coord/' + parseFloat(this.state.coord['*'].split(',')[0]) + ',' + parseFloat(this.state.coord['*'].split(',')[1]))
            .then((res) => res.json())
            .then((json) => id_DF = json.tileId)
            .then(() => {

                let
                    body = {
                        "inflection_date": '2022-08',
                        "tag": this.state.new_tag_name,
                        "comment": '',
                        "source_link": '',
                        "correlates": ''
                    }

                fetch(URL + 'winners_vs_losers/return_hypothesis/' + id_DF,
                    {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(body)
                    })
                    .then((res) => res.json())
                    .then(() => {
                        this.setState({ new_tag_name: '', new_tag: false })
                    })
            })

    }

    handleNewTagName(event) {
        this.setState({ new_tag_name: event.target.value })
    }

    async handleVisualization(coordX, coordY) {
        let tileList = []
        let id_DF = 0

        fetch(URL + 'tile_od_agg/center_to_neighbors',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(
                    { "center_coordinates": [coordX, coordY] })
            })
            .then((res) => res.json())
            .then(async (json) => {

                json.features.map((tile) => tileList.push(tile.properties.tileId));

                this.props.updateFields({ graphParam: tileList })

                const tyle_corner = {
                    center: [((json.features[0].geometry.coordinates[0][1][0] + json.features[0].geometry.coordinates[0][2][0]) / 2) + 0.03, ((json.features[0].geometry.coordinates[0][2][1] + json.features[0].geometry.coordinates[0][3][1]) / 2) + 0.002],
                    zoom: 13.2,
                    pitch: 0,
                    bearing: 0
                }

                await delay(2000);

                this.props.map.flyTo({
                    ...tyle_corner, // Fly to the selected target
                    duration: 2000, // Animate over 2 seconds
                    essential: false // This animation is considered essential with
                    //respect to prefers-reduced-motion
                })

                this.setState({ generatedTyles: true })
                lines_geojson = json
                this.props.map.getSource('data-swiss-tyle').setData(json);
            })
            .then(() => {
                this.props.map.setLayoutProperty('data-swiss-tyle', 'visibility', 'visible');
                this.props.map.setLayoutProperty('outline-data-swiss-tyle', 'visibility', 'visible');
                this.props.map.setLayoutProperty('data-swiss-tyle-labels', 'visibility', 'visible');
            })
            .then(() => {
                fetch(URL + 'areas_to_focus/tile/coord/' + parseFloat(this.state.coord['*'].split(',')[0]) + ',' + parseFloat(this.state.coord['*'].split(',')[1]))
                    .then((res) => res.json())
                    .then((json) => id_DF = json.tileId)
                    .then(() => {
                        this.props.map.getSource('data-circles-stations').setData(URL + 'winners_vs_losers/return_stops_in_tile/' + id_DF);
                        stations_geojson = URL + 'winners_vs_losers/return_stops_in_tile/' + id_DF
                    }).then(() => {
                        fetch(URL + 'winners_vs_losers/return_hypothesis/' + id_DF)
                            .then((res) => res.json())
                            .then((json) => {
                                if (json.length === 0) {
                                    this.setState({ validated_hypothesis: 'No hypothesis validated for this tile' })
                                } else {
                                    let current_hypothesis = ''
                                    let array_dates = []

                                    for (let category of json) {
                                        array_dates.push("(" + category.inflection_date.toString().split('T')[0].slice(0, -3) + ")" + category.tag)

                                        if (category.tag === 'lastmile' && category.inflection_date.toString().split('T')[0].slice(0, -3) === '2022-08') {
                                            this.setState({
                                                value_od_dates: ({
                                                    "value": category.inflection_date.toString().split('T')[0].slice(0, -3),
                                                    "label": category.inflection_date.toString().split('T')[0].slice(0, -3)
                                                }),
                                                selected_tag: ({
                                                    "value": category.tag,
                                                    "label": category.tag
                                                }),
                                                current_comment: category.comment,
                                                current_station: category.stop_id,
                                                current_checkbox: category.correlates,
                                                current_url: category.source_link
                                            })

                                            console.log(this.state.current_checkbox, category.correlates)
                                        }
                                    }

                                    array_dates = array_dates.sort()

                                    for (let category of array_dates) {
                                        let elem = category.split(')')

                                        if (current_hypothesis === '') {
                                            current_hypothesis += elem[1] + elem[0] + ')'
                                        } else {
                                            current_hypothesis += ' ~ ' + elem[1] + elem[0] + ')'
                                        }
                                    }

                                    this.setState({ validated_hypothesis: current_hypothesis })
                                }
                            }).then(()=> this.props.updateFields({ loading: false }))
                    })
            })
    }

    render() {
        const { loading } = this.props;
        const { new_tag } = this.state;

        const { new_tag_name } = this.state;
        const { current_comment } = this.state;
        const { current_url } = this.state;
        const { tag_options } = this.state;
        const { dates_trips_ } = this.state;

        const selectDate = {
            menuList: styles => {
                return {
                    ...styles,
                    maxHeight: 140,
                    borderWidth: 0,
                    boxShadow: '0 0 10px 2px rgb(0 0 0 / 10%)',
                };
            }
        };

        if (loading) { // if your component doesn't have to wait for an async action, remove this block 
            return (
                <div className="bg-loader">
                    <div className="pos-center-brain-loader">
                        <div className="circle-loading-brain">
                        </div>
                        <svg className='brain-loader' x="0px" y="0px" viewBox="0 0 496 496">
                            <path d="M40,449.472V364.28L58.424,352H88v-16H53.576L24,355.72v93.752C14.704,452.784,8,461.584,8,472c0,13.232,10.768,24,24,24				s24-10.768,24-24C56,461.584,49.288,452.784,40,449.472z M32,480c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8s8,3.584,8,8				C40,476.416,36.416,480,32,480z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M46.528,320H88v-16H46.528c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24				C34.416,336,43.216,329.288,46.528,320z M16,312c0-4.416,3.584-8,8-8s8,3.584,8,8c0,4.416-3.584,8-8,8S16,316.416,16,312z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M24,272c10.416,0,19.216-6.712,22.528-16H88v-16H46.528c-3.312-9.296-12.112-16-22.528-16c-13.232,0-24,10.768-24,24				S10.768,272,24,272z M24,240c4.416,0,8,3.584,8,8s-3.584,8-8,8s-8-3.584-8-8C16,243.584,19.584,240,24,240z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M56 272h32v16H56Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M56 208h32v16H56Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M0 176h88v16H0Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M60.688,160H88v-16H67.312L48,124.688v-14.16C57.288,107.216,64,98.416,64,88c0-13.232-10.768-24-24-24S16,74.768,16,88				c0,10.416,6.704,19.216,16,22.528v20.784L60.688,160z M40,80c4.416,0,8,3.584,8,8s-3.584,8-8,8s-8-3.584-8-8S35.584,80,40,80z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M144 67.312L144 88L160 88L160 60.688L131.312 32L0 32L0 48L124.688 48z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M176 35.312L176 88L192 88L192 28.688L163.312 0L0 0L0 16L156.688 16z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M208 56h16v32H208Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M304 0h16v88H304Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M240,46.528V88h16V59.312l16,16V88h16V68.688L262.392,43.08C268.192,38.696,272,31.816,272,24c0-13.232-10.768-24-24-24				s-24,10.768-24,24C224,34.416,230.704,43.216,240,46.528z M248,16c4.416,0,8,3.584,8,8s-3.584,8-8,8s-8-3.584-8-8				S243.584,16,248,16z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M352,51.312L371.312,32h78.16c2.416,6.776,7.752,12.112,14.528,14.528v110.16L444.688,176H408v16h43.312L480,163.312				V46.528c9.288-3.312,16-12.112,16-22.528c0-13.232-10.768-24-24-24c-10.416,0-19.216,6.712-22.528,16h-84.784L336,44.688V88h16				V51.312z M472,16c4.416,0,8,3.584,8,8s-3.584,8-8,8c-4.416,0-8-3.584-8-8S467.584,16,472,16z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M414.08,97.392l17.92,17.92v9.376L412.688,144H408v16h11.312L448,131.312v-22.624L419.312,80h-4.784				c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24C401.904,112,410.416,105.968,414.08,97.392z				 M392,96c-4.416,0-8-3.584-8-8s3.584-8,8-8c4.416,0,8,3.584,8,8S396.416,96,392,96z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M408 208h88v16H408Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M480,449.472V268.688L451.312,240H408v16h36.688L464,275.312v41.376L451.312,304H408v16h36.688L464,339.312v110.16				c-9.296,3.312-16,12.112-16,22.528c0,13.232,10.768,24,24,24s24-10.768,24-24C496,461.584,489.288,452.784,480,449.472z M472,480				c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8c4.416,0,8,3.584,8,8C480,476.416,476.416,480,472,480z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M408 272h32v16H408Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M448,364.688L419.312,336H408v16h4.688L432,371.312V416h-80v-8h-16v24h96v12.688L412.688,464h-14.16				c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24c10.416,0,19.216-6.712,22.528-16h20.784				L448,451.312V364.688z M376,480c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8c4.416,0,8,3.584,8,8C384,476.416,380.416,480,376,480z				" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M160,408h-16v8h-41.472c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24				c10.416,0,19.216-6.712,22.528-16H160V408z M80,432c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8s8,3.584,8,8				C88,428.416,84.416,432,80,432z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M304 408h16v88H304Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M272 408h16v88H272Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M240,420.688l-16,16V408h-16v41.472c-9.296,3.312-16,12.112-16,22.528c0,13.232,10.768,24,24,24s24-10.768,24-24				c0-7.816-3.808-14.696-9.608-19.08L256,427.312V408h-16V420.688z M216,480c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8s8,3.584,8,8				C224,476.416,220.416,480,216,480z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M192 408L176 408L176 428.688L152 452.688L152 496L168 496L168 459.312L192 435.312z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M336,392h16v-16h24v-24h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16				v-16h-16v-24h-24v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-3.312				L120,140.688V144h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v24h24v16h16v-16h16				v16h16v-16h16v16h16v-16h16v16h16v-16h16v16h16v-16h16v16h16v-16h16V392z M136,360V147.312L147.312,136H360v224H136z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M318.888,211.048l13.96-13.96l-33.936-33.936l-13.96,13.96c-4.16-2.184-8.488-3.976-12.952-5.384V152h-48v19.728				c-4.472,1.408-8.8,3.2-12.952,5.384l-13.96-13.96l-33.936,33.936l13.96,13.96c-2.176,4.16-3.976,8.488-5.384,12.952H152v48				h19.728c1.408,4.472,3.2,8.8,5.384,12.952l-13.96,13.96l33.936,33.936l13.96-13.96c4.16,2.176,8.48,3.976,12.952,5.384V344h48				v-19.728c4.472-1.408,8.8-3.2,12.952-5.384l13.96,13.96l33.936-33.936l-13.96-13.96c2.176-4.152,3.976-8.48,5.384-12.952H344v-48				h-19.728C322.864,219.528,321.072,215.2,318.888,211.048z M328,256h-16.224l-1.416,6.224c-1.6,7.016-4.368,13.672-8.216,19.792				l-3.408,5.408l11.496,11.496l-11.312,11.312l-11.496-11.496l-5.408,3.408c-6.12,3.848-12.776,6.616-19.792,8.216L256,311.776V328				h-16v-16.224l-6.224-1.416c-7.016-1.6-13.672-4.368-19.792-8.216l-5.408-3.408l-11.496,11.496l-11.312-11.312l11.496-11.496				l-3.408-5.408c-3.848-6.12-6.616-12.776-8.216-19.792L184.224,256H168v-16h16.224l1.416-6.224				c1.6-7.016,4.368-13.672,8.216-19.792l3.408-5.408l-11.496-11.496l11.312-11.312l11.496,11.488l5.408-3.408				c6.12-3.848,12.776-6.616,19.792-8.216l6.224-1.408V168h16v16.224l6.224,1.416c7.016,1.6,13.672,4.368,19.792,8.216l5.408,3.408				l11.496-11.488l11.312,11.312l-11.496,11.496l3.408,5.408c3.848,6.12,6.616,12.776,8.216,19.792l1.416,6.216H328V256z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M248,208c-22.056,0-40,17.944-40,40c0,22.056,17.944,40,40,40c22.056,0,40-17.944,40-40C288,225.944,270.056,208,248,208				z M248,272c-13.232,0-24-10.768-24-24s10.768-24,24-24s24,10.768,24,24S261.232,272,248,272z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div>
                    <div className="modal-hypothesis-list-ms">
                        <div className="modal-hypothesis-ms-list-inner">
                            <h1>Hypothesis Validated</h1>
                            <p>{this.state.validated_hypothesis}</p>
                        </div>
                    </div>
                    <div className="modal-hypothesis-ms">
                        <div className="modal-hypothesis-ms-inner">
                            <div className="row-parameters">
                                <div className="column-1-55">
                                    <h1>Modal Split Hypothesis</h1>
                                </div>
                                <div className="column-2-40">
                                    <Form onClick={this.handleSaveDetails.bind(this)}>
                                        <Button className="generate-btn" variant="info">Save details</Button>
                                    </Form>
                                </div>
                                <div className="column-3-5">
                                    <Form onClick={this.handleNewTag.bind(this)}>
                                        <Button className="co2-3dview-btn" variant="info">+</Button>
                                    </Form>
                                </div>
                            </div>
                            <div className="row-parameters">
                                <div className="column-1-55">
                                    <p className="label">Tag Selected</p>
                                    <Select className='tags' options={tag_options} onChange={this.handleTagOptions.bind(this)} styles={selectDate} defaultValue={this.state.selected_tag} />
                                </div>
                                <div className="column-2-45">
                                    <p className="label">Date (Inflection Point)</p>
                                    <Select className='dates' options={dates_trips_} onChange={this.handleMonthYear.bind(this)} styles={selectDate} value={this.state.value_od_dates} />
                                </div>
                            </div>
                            <div className="row-parameters">
                                <div className="column-1-20p10">
                                    <p className="label-2">Comment</p>
                                </div>
                                <div className="column-2-80p10">
                                    <textarea style={{ resize: 'none' }} rows="3" cols="51" type="text" value={current_comment} onChange={this.handleCurrentComment.bind(this)} />
                                </div>
                            </div>
                            <div className="row-parameters">
                                <div className="column-1-20p">
                                    <p className="label-2">Source URL</p>
                                </div>
                                <div className="column-2-80p">
                                    <input type="text" size="60" value={current_url} onChange={this.handleCurrentURL.bind(this)} />
                                </div>
                            </div>
                            <div className="row-parameters">
                                <div className="column-1-90">
                                    <p className="label">Does this hypothesis correlates with the behaviour observe on the tile's Modal Split Graph?</p>
                                </div>
                                    <Form onChange={this.handleCheckboxHypothesis.bind(this)}>
                                        <div className="column-2-10">
                                        <Form.Check inline label="" name="type_analysis" type='checkbox' id='yes' defaultChecked={(this.state.current_checkbox)} />
                                        </div>
                                    </Form>
                            </div>
                        </div>
                    </div>
                    {new_tag ?
                        <>
                            <div className="modal-hypothesis-ms">
                                <div className="modal-hypothesis-ms-inner">
                                    <h1>Add a New Tag</h1>
                                    <p className="label-text">Create a new category of Hypothesis to answer the question: <br /> <i>Why did modal split changed during the past 4 years?</i></p>
                                    <p className="label-text">Note: This tag will be enable for all tiles and by default <br /> will be set as <i>"False"</i></p>
                                    <div className="btn-row">
                                        <div className="column-1-20p">
                                            <p className="label-2">Name of new tag</p>
                                        </div>
                                        <div className="column-2-80p">
                                            <input type="text" size="51" value={new_tag_name} onChange={this.handleNewTagName.bind(this)} />
                                        </div>
                                    </div>
                                    <div className="btn-row">
                                        <div className="column-1-55">
                                            <Form onClick={this.handleNewTagFalse.bind(this)}>
                                                <Button className="reset-btn" variant="info">Cancel</Button>
                                            </Form>
                                        </div>
                                        <div className="column-2-45">
                                            <Form onClick={this.handleAddNewTag.bind(this)}>
                                                <Button className="generate-btn" variant="info">Add tag</Button>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <></>
                    }
                    {false ?
                        <>
                            <div className="modal-openAI-response">
                                <div className="modal-openAI-response-inner">
                                    <p className="label">{this.state.openAI_text}</p>
                                </div>
                            </div>
                        </> :
                        <></>}
                </div>
            )
        }
    }
}

class MapWinnersLosersDetail extends React.Component {

    // Set up states for updating map 
    constructor(props) {
        super(props);
        this.state = {
            lng: 8.251313,
            lat: 46.924143,
            zoom: 7,
            mapita: 9,
            tyle: 'mapbox://styles/mapbox/satellite-streets-v11',
            hideNavs: false,
            graphParam: [],
            array_selected_tiles: [],
            tilesIds: [],
            coord: this.props.params,
            current_station: 0,
            loading: true
        }
    }

    //Update several fields
    updateFields(list) {
        this.setState(list);
    }

    //Update map on component update
    updateField(card, value) {
        this.setState({
            ...this.state,
            [card]: value
        });
    }

    //Update style
    handleTyle(value) {
        this.setState({ tyle: value }, this.componentDidMount);
    }

    //Update coordinates
    handleCoord(center, zoom) {
        const { lng, lat } = center;
        this.setState({ zoom: zoom, lat: lat, lng: lng })
    }

    //Update coordinates
    handleCoordMoveToTyle(lng, lat, zoom) {
        this.setState({ zoom: zoom, lat: lat, lng: lng })
    }

    componentDidMount() {
        // Set up map 
        const map = new mapboxgl.Map({
            container: this.mapContainer,
            style: this.state.tyle,
            center: [this.state.lng, this.state.lat],
            zoom: this.state.zoom,
        });

        map.on('load', () => {

            // Add zoom and rotation controls to the map.
            map.addControl(new mapboxgl.NavigationControl({ showCompass: false, showZoom: false }), 'top-left');
            map.addControl(new MapboxGeocoder({ accessToken: mapboxgl.accessToken, mapboxgl: mapboxgl, marker: false }), 'top-left');

            //Tyle visualization
            //Add shapes to the map
            map.addSource('data-swiss-tyle', {
                'type': 'geojson',
                'data': lines_geojson,
                'generateId': true
            });

            map.addLayer({
                'id': 'data-swiss-tyle',
                'type': 'fill',
                'source': 'data-swiss-tyle',
                'layout': {},
                'paint': {
                    'fill-color': ['get', 'color'],
                    'fill-opacity': 0.4
                }
            });

            // Add a black outline around the polygon
            map.addLayer({
                'id': 'outline-data-swiss-tyle',
                'type': 'line',
                'source': 'data-swiss-tyle',
                'layout': {},
                'paint': {
                    'line-color': ['get', 'color'],
                    'line-width': 1
                }
            });

            map.addLayer({
                'id': 'data-swiss-tyle-labels',
                'type': 'symbol',
                'source': 'data-swiss-tyle',
                "minzoom": 11,
                'layout': {
                    'text-field': ['get', 'total_trips_weekdays_tiles'],
                    'text-font': [
                        'Open Sans Bold',
                        'Arial Unicode MS Bold'
                    ],
                    'text-size': 15,
                    'text-transform': 'uppercase',
                    'text-letter-spacing': 0.05,
                    'text-offset': [0, 1],
                    'visibility': 'visible'
                }
            });

            //Circles - Stations
            //Add shapes to the map
            map.addSource('data-circles-stations', {
                'type': 'geojson',
                'data': stations_geojson,
                'generateId': true

            });
            map.addLayer({
                'id': 'data-circles-stations',
                'type': 'circle',
                'source': 'data-circles-stations',
                'layout': {
                    'visibility': 'none'
                },
                'paint': {
                    'circle-radius': 4,
                    "circle-stroke-width": 1,
                    "circle-stroke-color": [
                        'case',
                        ['boolean', ['feature-state', 'clicked'], false],
                        '#0012de', // if selected true, paint in pink
                        '#FF8C00' // else paint
                    ],
                    'circle-color': [
                        'case',
                        ['boolean', ['feature-state', 'clicked'], false],
                        '#0012de', // if selected true, paint in pink
                        '#FF8C00' // else paint
                    ],
                    "circle-opacity": 1,
                },
            });
        });

        this.setState({
            mapita: map,
            zoom: map.getZoom(),
            center: map.getCenter()
        });

        map.on('click', 'data-swiss-tyle', (e) => {
            //TODO
        });

        // Create a popup, but don't add it to the map yet.
        const popupHover = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false
        });

        //Click function - Add to Array
        map.on('click', 'data-circles-stations', (e) => {
            map.getCanvas().style.cursor = 'pointer';

            let circleID = e.features[0].id;

            map.setFeatureState({
                source: 'data-circles-stations',
                id: circleID,
            }, {
                clicked: true
            });

            let elem_id = e.features[0].properties.stop_id;
            this.updateFields({ current_station: elem_id })

        });

        // Change the cursor to a pointer when the mouse is over the places layer.
        map.on('mouseenter', 'data-circles-stations', (e) => {
            map.getCanvas().style.cursor = 'pointer';
            let stop_name = e.features[0].properties['Stop name']

            let html = '<h4 style="margin-block-start:0px; margin-block-end:0px;">Stop Name</h4><p style="margin-block-start:0px; margin-block-end: 0em;">' + stop_name + '</p>';
            popupHover
                .setLngLat(e.lngLat)
                .setHTML(html).addTo(map);
        });

        // Change it back to a pointer when it leaves.
        map.on('mouseleave', 'data-circles-stations', () => {
            map.getCanvas().style.cursor = '';
            popupHover.remove();
        });
    }

    render() {
        const { hideNavs } = this.state;

        return (
            <div>
                <div ref={el => this.mapContainer = el} style={{ width: '100%', height: '100vh' }}>
                </div>
                <RecommendationNavBarComponent />
                <HideElementsComponent map={this.state.mapita} updateField={(card, field, value) => this.updateField(card, field, value)} />
                <div style={{ display: hideNavs ? 'none' : 'block' }}>

                        <BtnsComponent map={this.state.mapita} updateField={(field, value) => this.updateField(field, value)} updateFields={(list) => this.updateFields(list)} handleCoordMoveToTyle={(value1, value2, value3) => this.handleCoordMoveToTyle(value1, value2, value3)} coord={this.state.coord} current_station={this.state.current_station} loading={this.state.loading} />
                        <GraphComponent map={this.state.mapita} graphParam={this.state.graphParam} coord={this.state.coord} loading={this.state.loading} />
                    </div>
                <LayerTylesComponent map={this.state.mapita} tyle={this.state.tyle} handleTyle={(value) => this.handleTyle(value)} handleCoord={(value1, value2) => this.handleCoord(value1, value2)} />
            </div>
        )
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => (
    <MapWinnersLosersDetail
        {...props}
        params={useParams()}
    />
); 