import React from "react";
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import MappUtilizationRoad from './views/Maps/Map-Utilization-Road';
import MappUtilizationRail from './views/Maps/Map-Utilization-Rail';
import MappAccessibility from './views/Maps/Map-Accessibility';
import MappTrafficCounters from './views/Maps/Map-Traffic-Counters';
import { MapRecommendation3DSwisscom } from './views/Maps/Map-Recommendation-3D-Swisscom';
import MappRecommendationSwisscom22 from './views/Maps/Map-Recommendation-Swisscom-22';
import MappRecommendationHeatmap from './views/Maps/Map-Recommendation-Heatmap';
import MappInsightsGraph from './views/Maps/Map-Insights-Graph';
import MappPotentialRoutes from './views/Maps/Map-Potential-of-Routes';
import MappPoint_to_Point from './views/Maps/Map-Point-to-Point-Travel-Time';
import MappTime_to_OV from './views/Maps/Map-Time-to-OV';
import MapCO2_3D from './views/Maps/Map-CO2-3D';
import MappDropPoint from './views/Maps/Map-Drop-Point';
import MapWhatIf_MS_3D from './views/Maps/Map-WhatIf_MS_3D';
import MapWhatIfPotential from './views/Maps/Map-WhatIf-Potential';
import MapWhatIfPotentialBicycle from './views/Maps/Map-WhatIf-Potential-Bicycle';
import MappAreas2Focus from './views/Maps/Map-Areas2Focus';
import MappEmployers from './views/Maps/Map-Employers';
import MappBuhler from './views/Maps/Map-Buhler';
import MappSimulation from './views/Maps/Map-Simulation';
import MappMarketing from './views/Maps/Map-Marketing';
import MappDropPointTiles from './views/Maps/Map-Drop-Point-Tiles';
import AccessDenied from './views/AccessDenied';
import MapB2B_3D from './views/Maps/Map-B-to-B-3D';
import MappDropPointHotspot from './views/Maps/Map-Drop-Point-Hotspot';
import MapPotential_3D from './views/Maps/Map-Potential-3D';
import MapTraffic3D from './views/Maps/Map-Traffic-3D';
import MapPotential2D from './views/Maps/Map-Potential-2D';
import MappTileComparison from './views/Maps/Map-Tile-Comparison';
import MappPotentialCorridors from './views/Maps/Map-Potential-of-Corridors';
import DashboardODMatrix from './views/Maps/Dashboard-OD-Matrix';
import MappDropPointZones from './views/Maps/Map-Drop-Point-Zones';
import Login from './views/Login';
import Menu from "./views/Menu";

import { ProtectedRoute } from './utils/ProtectedRoute';
import { ProtectedRouteWOMenu } from "./utils/ProtectedRouteWOMenu";
import MappRoutes from "./views/Maps/Map-Routes";
import DashboardHotspotsPotential from "./views/Maps/Dashboard-Hotspots-Potential";
import MapEmployerAnalyzer from "./views/Maps/Map-Employer-Analyzer";
import MapEmployerAnalyzer_3D from "./views/Maps/Map-Employer-Analyzer-3D";
import MappRegionalDemand from "./views/Maps/Map-Regional-Demand";
import MappTileSelector from "./views/Maps/Map-Tiles-Selector";
import MapWinnersLosers from "./views/Maps/Map-Winners-Losers";
import MapWinnersLosersDetail from "./views/Maps/Map-Winners-Losers-Detail";
import MappRegionalAnalysis from "./views/Maps/Map-Regional-Analysis";
import MapHeatmap3D from "./views/Maps/Map-Heatmap-3D";
import MapPotentialEmployer_3D from "./views/Maps/Map-Potential-Employer-3D";
import MapPotentialEmployer2D from "./views/Maps/Map-Potential-Employer-2D";

import Dashboard_5_in_5 from "./views/Dashboards/Dashboard_5_in_5";
import Dashboard_Traffic_Categories from "./views/Dashboards/Dashboard_Traffic_Categories";
import Dashboard_MIV_Hotspots from "./views/Dashboards/Dashboard_MIV_Hotspots";

import MappUpload_Data from './views/Maps/Mapp-Upload-Data';
import Sankey from "./views/Maps/Sankey";


//AUSTRIA COCKPIT
import Austria_Menu from "./views/Menu_Austria";
import Austria_MappRecommendationHeatmap from "./views/Maps/Austria/Map-Recommendation-Heatmap";
import Austria_MappDropPoint from "./views/Maps/Austria/Map-Drop-Point";
import Austria_MapTraffic_3D from "./views/Maps/Austria/Map-Traffic-3D";
import Austria_MapPotentialEmployers_3D from "./views/Maps/Austria/Map-Potential-Employer-3D";
import { Austria_ProtectedRoute } from "./utils/Austria_ProtectedRoute";
import Austria_Dashboard_5_in_5 from "./views/Maps/Austria/Dashboard_5_in_5";
import Austria_Dashboard_MIV_Hotspots from "./views/Maps/Austria/Dashboard_MIV_Hotspots"
import Austria_MobilityTrends from "./views/Maps/Austria/Map-Mobility-Trends";

//CO2 COKPIT
import CO2_Welcome_Screen from "./views/CO2_Cockpit/Welcome_Screen";
import CO2_Report_Screen from "./views/CO2_Cockpit/Report_Screen";
import Dashboard_5_in_5_Cities from "./views/CO2_Cockpit/Dahsboard-5in5-City";

//Hilti
import Menu_Hilti from "./views/Menu_Hilti";
import Hilti_MappBuhler from './views/Maps/Hilti/Map-Buhler';
import Hilti_MapEmployerAnalyzer_3D from './views/Maps/Hilti/Map-Employer-Analyzer-3D';
import Hilti_MapEmployerAnalyzer from './views/Maps/Hilti/Map-Employer-Analyzer';
import Hilti_MapB2B_3D from './views/Maps/Hilti/Map-B-to-B-3D';
import { Hilti_ProtectedRoute } from './utils/Hilti_ProtectedRoute';

//Tile Drawing
import TileDraw from './views/Maps/Tile-Draw';

//BLS - SOB
import Dashboard_5_in_5_BLS from "./views/Maps/BLS/Dashboard-5in5-BLS";
import MapPotential_3D_BLS from './views/Maps/BLS/Map-Potential-Employer-3D';

import Dashboard_5_in_5_SOB from "./views/Maps/SOB/Dashboard-5in5-SOB";
import MapPotential_3D_SOB from './views/Maps/SOB/Map-Potential-Employer-3D';

import { components } from "react-select";
import MapB2BBus from "./views/Maps/Map-b2b-bus";

function App() {

  return (
    <div className="App">

      <Router history={createBrowserHistory}>
        <Routes>
          <Route path='/' element={<Login />} />
          <Route path='/tile/*' element={<TileDraw/>}/>
          <Route path='/access-denied' element={<AccessDenied />} />
          <Route
            path="/menu"
            element={<ProtectedRouteWOMenu component={Menu} />}
          />
          <Route
            path="/od-insights/*"
            element={<ProtectedRoute component={MappRecommendationHeatmap} />}
          />
          <Route
            path="/traffic-counters/*"
            element={<ProtectedRoute component={MappTrafficCounters} />}
          />
          <Route
            path="/recommendation-swisscom/*"
            element={<ProtectedRoute component={MapRecommendation3DSwisscom} />}
          />
          <Route
            path="/co2-emission/*"
            element={<ProtectedRoute component={MapCO2_3D} />}
          />
          <Route
            path="/acessibility/*"
            element={<ProtectedRoute component={MappAccessibility} />}
          />
          <Route
            path="/road-utilization/*"
            element={<ProtectedRoute component={MappUtilizationRoad} />}
          />
          <Route
            path="/rail-utilization/*"
            element={<ProtectedRoute component={MappUtilizationRail} />}
          />
          <Route
            path="/modal-split22/*"
            element={<ProtectedRoute component={MappRecommendationSwisscom22} />}
          />
          <Route
            path="/graph-insights/*"
            element={<ProtectedRoute component={MappInsightsGraph} />}
          />
          <Route
            path="/potential-routes/*"
            element={<ProtectedRoute component={MappPotentialRoutes} />}
          />
          <Route
            path="/point-to-point-travel-time/*"
            element={<ProtectedRoute component={MappPoint_to_Point} />}
          />
          <Route
            path="/time-to-ov/*"
            element={<ProtectedRoute component={MappTime_to_OV} />}
          />
          <Route
            path='/drop-a-point/*'
            element={<ProtectedRoute component={MappDropPoint} />}
          />
          <Route
            path='/what-if-MS/*'
            element={<ProtectedRoute component={MapWhatIf_MS_3D} />}
          />
          < Route
            path='/what-if-potential/*'
            element={<ProtectedRoute component={MapWhatIfPotential} />}
          />
          < Route
            path='/what-if-potential-bicycle/*'
            element={<ProtectedRoute component={MapWhatIfPotentialBicycle} />}
          />
          <Route
            path='/hotspot-analysis/*'
            element={<ProtectedRoute component={MappAreas2Focus} />}
          />
          <Route
            path='/employers-tile-analysis'
            element={<ProtectedRoute component={MappEmployers} />}
          />
          <Route
            path='/bus-potential/*'
            element={<ProtectedRoute component={MappBuhler} />}
          />
          <Route
            path='/simulation'
            element={<ProtectedRoute component={MappSimulation} />}
          />
          <Route
            path='/marketing/*'
            element={<ProtectedRoute component={MappMarketing} />}
          />
          <Route
            path='/drop-a-point-tiles'
            element={<ProtectedRoute component={MappDropPointTiles} />} />
          <Route
            path='/roads/*'
            element={<ProtectedRoute component={MappRoutes} />}
          />
          <Route
            path='/b-to-b-3D/*'
            element={<ProtectedRoute component={MapB2B_3D} />}
          />
          <Route
            path='/dashboard-hotspot'
            element={<ProtectedRoute component={DashboardHotspotsPotential} />} />
          <Route
            path='/employer-analyzer'
            element={<ProtectedRoute component={MapEmployerAnalyzer} />} />
          <Route
            path='/employer-analyzer-3D'
            element={<ProtectedRoute component={MapEmployerAnalyzer_3D} />} />
          <Route
            path='/regional-demand'
            element={<ProtectedRoute component={MappRegionalDemand} />} />
          <Route
            path='/tile-analysis/*'
            element={<ProtectedRoute component={MappDropPointHotspot} />} />
          <Route
            path='/tiles-selector/*'
            element={<ProtectedRoute component={MappTileSelector} />} />
          <Route
            path='/winners-losers'
            element={<ProtectedRoute component={MapWinnersLosers} />} />
          <Route
            path='/winners-losers-detail/*'
            element={<ProtectedRoute component={MapWinnersLosersDetail} />} />
          <Route
            path='/regional-analysis/*'
            element={<ProtectedRoute component={MappRegionalAnalysis} />} />
          <Route path='/potential-3d'
            element={<ProtectedRoute component={MapPotential_3D} />} />
          <Route
            path='/traffic-3d'
            element={<ProtectedRoute component={MapTraffic3D} />} />
          <Route
            path='/potential-tile/*'
            element={<ProtectedRoute component={MapPotential2D} />} />
            <Route 
            path='/tile-comparison'
            element={<ProtectedRoute component={MappTileComparison}/>}/>
            <Route 
            path='/heatmap-3d'
            element={<ProtectedRoute component={MapHeatmap3D}/>}/>
             <Route 
            path='/od-matrix'
            element={<ProtectedRoute component={DashboardODMatrix}/>}/>
            <Route 
            path='/potential-corridors'
            element={<ProtectedRoute component={MappPotentialCorridors}/>}/>
            <Route 
            path='/potential-employers-3d/*'
            element={<ProtectedRoute component={MapPotentialEmployer_3D} />} />
            <Route 
            path='/potential-tile-employer/*'
            element= {<ProtectedRoute component={MapPotentialEmployer2D}/>}/>
            <Route 
            path='/5-in-5'
            element= {<ProtectedRouteWOMenu component={Dashboard_5_in_5}/>}/>
            <Route 
            path='/tile-categories-dashboard'
            element= {<ProtectedRouteWOMenu component={Dashboard_Traffic_Categories}/>}/>
            <Route
            path='/miv-hotspots'
            element= {<ProtectedRouteWOMenu component={Dashboard_MIV_Hotspots}/>}
            />
 <Route
            path='/zones-categorization/*'
            element={<ProtectedRoute component={MappDropPointZones} />}
          />
          <Route
            path="/austria/menu"
            element={<ProtectedRouteWOMenu component={Austria_Menu} />}
          />
          <Route
            path="/austria/od-insights"
            element={<Austria_ProtectedRoute component={Austria_MappRecommendationHeatmap} />}
          />
          <Route
            path='/austria/drop-a-point/*'
            element={<Austria_ProtectedRoute component={Austria_MappDropPoint} />}
          />
          <Route
            path='/austria/traffic-3d'
            element={<Austria_ProtectedRoute component={Austria_MapTraffic_3D} />}
          />
          <Route
          path= 'austria/potential-employers-3d/*'
          element={<Austria_ProtectedRoute component={Austria_MapPotentialEmployers_3D}/>}
          />
          <Route
          path= 'austria/5-in-5'
          element={<ProtectedRouteWOMenu component={Austria_Dashboard_5_in_5}/>}
          />
          <Route
          path= 'austria/mobility-trends/*'
          element={<Austria_ProtectedRoute component={Austria_MobilityTrends}/>}
          />
           <Route
            path='/austria/miv-hotspots'
            element= {<ProtectedRouteWOMenu component={Austria_Dashboard_MIV_Hotspots}/>}
            />
            <Route
            path='/co2_insights'
            element={<ProtectedRouteWOMenu component={CO2_Welcome_Screen}/>}
            />
            <Route
            path='/co2_insights_report/*'
            element={<ProtectedRouteWOMenu component={CO2_Report_Screen}/>}
            />
            <Route 
            path='/co2_insights/5-in-5/*'
            element= {<ProtectedRouteWOMenu component={Dashboard_5_in_5_Cities}/>}/>
               <Route
            path="/hilti/menu"
            element={<ProtectedRouteWOMenu component={Menu_Hilti} />}
          />
           <Route
            path='/hilti/employer-analyzer'
            element={<Hilti_ProtectedRoute component={Hilti_MapEmployerAnalyzer} />} />
          <Route
            path='/hilti/employer-analyzer-3D'
            element={<Hilti_ProtectedRoute component={Hilti_MapEmployerAnalyzer_3D} />} />
            <Route
            path='/hilti/bus-potential/*'
            element={<Hilti_ProtectedRoute component={Hilti_MappBuhler} />}
          />
           <Route
            path='/hilti/b-to-b-3D/*'
            element={<Hilti_ProtectedRoute component={Hilti_MapB2B_3D} />}
          />
           <Route
            path='/upload-data'
            element={<ProtectedRoute component={MappUpload_Data} />}
          />
          <Route
            path="/BLS"
            element={<ProtectedRouteWOMenu component={Dashboard_5_in_5_BLS} />}
          />
           <Route
            path="/SOB"
            element={<ProtectedRouteWOMenu component={Dashboard_5_in_5_SOB} />}
          />
          <Route
            path="/SOB/potential-employers-3d/*"
            element={<ProtectedRouteWOMenu component={MapPotential_3D_SOB} />}
          />
 <Route
            path="/BLS/potential-employers-3d/*"
            element={<ProtectedRouteWOMenu component={MapPotential_3D_BLS} />}
          />

<Route
            path="/b2b-bus-ai"
            element={<ProtectedRoute component={MapB2BBus} />}
          />
<Route
            path="/sankey-diagram"
            element={<ProtectedRouteWOMenu component={Sankey} />}
          />
        </Routes>
      </Router>

    </div>

  );
}
export default App;
