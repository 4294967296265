import React from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import axios from 'axios';
import { IconContext } from "react-icons";
import { BsLayersHalf } from "react-icons/bs";
import RecommendationNavBarComponent from "../../components/RecommendationNavBarComponent";
import Slider from 'rc-slider';
import { Button, Form } from 'react-bootstrap';
import '../css/map-areas2focus.css';
import Select from 'react-select';
import { RiFullscreenFill, RiFullscreenExitFill } from "react-icons/ri";

//Sorting filter

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const URL = process.env.REACT_APP_URL_SERVER_BACK;
const URL_FRONT = process.env.REACT_APP_URL_SERVER_FRONT;

const _ = require('lodash');

function numberWithCommas(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

let tile_empty = {
	'type': 'FeatureCollection',
	'features': [
		{
			'type': 'Feature',
			'geometry': {
				"type": "LineString",
				"coordinates": [
				]
			}
		}
	]
};
let top_tile_geojson = tile_empty;
let bottom_tile_geojson = tile_empty;

class HideElementsComponent extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			hideNavs: false,
			mapa: this.props.map,
		}
	}

	hideComponent() {
		let newHideNavs = !this.state.hideNavs;
		this.setState({ hideNavs: newHideNavs })
		this.props.updateField("hideNavs", newHideNavs);
	}

	render() {

		const { hideNavs } = this.state;

		return (
			<div>
				{hideNavs ? <>
					<div className='map-close-navs'>
						<div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
								<div>
									<RiFullscreenExitFill />
								</div>
							</IconContext.Provider>
						</div>
					</div>
				</> : <>
					<div className='map-close-navs'>
						<div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
								<div>
									<RiFullscreenFill />
								</div>
							</IconContext.Provider>
						</div>
					</div>
				</>}
			</div>
		)
	}
}

//Layer Tyles Selection - Side Menu
class LayerTylesComponent extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			showOptions: false,
			mapa: this.props.map,
		}
		this.hideComponent = this.hideComponent.bind(this);
		this.handleTyleEvent = this.handleTyleEvent.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (this.props.map !== prevProps.map) {
			this.setState({ mapa: this.props.map })
		}
	}

	hideComponent(name) {
		switch (name) {
			case "showOptions":
				this.setState({ showOptions: !this.state.showOptions });
				break;
			default:
				// eslint-disable-next-line no-unused-expressions
				null;
		}
	}

	handleTyleEvent(event) {
		switch (event) {
			case "default":
				this.props.handleTyle("mapbox://styles/mapbox/light-v10");
				break;
			case "outdoors":
				this.props.handleTyle("mapbox://styles/mapbox/outdoors-v11");
				break;
			case "satellite":
				this.props.handleTyle("mapbox://styles/mapbox/satellite-streets-v11");
				break;
			case "streets":
				this.props.handleTyle("mapbox://styles/mapbox/streets-v11");
				break;
			case "dark":
				this.props.handleTyle("mapbox://styles/mapbox/dark-v10");
				break;
			default:
				// eslint-disable-next-line no-unused-expressions
				null;
		}
		this.props.handleCoord(this.state.mapa.getCenter(), this.state.mapa.getZoom());
	}

	render() {
		const { showOptions } = this.state;

		return (
			<div>
				{showOptions ? <>
					<div id='layer-options' className='map-layer-options-tyles'>
						<div className='map-layer-option-dark' onClick={() => this.handleTyleEvent("dark")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/Dark.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Dark</p>
						</div>
						<div className='map-layer-option-streets' onClick={() => this.handleTyleEvent("streets")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/Streets.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Streets</p>
						</div>
						<div className='map-layer-option-satellite' onClick={() => this.handleTyleEvent("satellite")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/Satellite.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Satellite</p>
						</div>
						<div className='map-layer-option-outdoors' onClick={() => this.handleTyleEvent("outdoors")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/Outdoors.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Outdoors</p>
						</div>
						<div className='map-layer-option-default' onClick={() => this.handleTyleEvent("default")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/White.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Default</p>
						</div>
					</div>
				</> : <></>}
				<div className='map-layer-tyles'>
					<div className='map-layer-tyles-inner' onClick={() => this.hideComponent("showOptions")}>
						<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
							<div>
								<BsLayersHalf />
							</div>
						</IconContext.Provider>
					</div>
				</div>
			</div>
		)
	}
}

class BtnsComponent extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			mapa: this.props.map,
			category_building: this.props.category_building,
			generatedTyles: false,
			distance_km: 0.85,
			percentage: 1000000,
			top_x: 500,
			loading: false,
			current_checkbox: 'off',
			bike_filter: 'off',
			categories: this.getCategories(),
			current_categories: [],

		}
		this.handleTopTilesInitial(100000, 0.85, 100, 'off', 'off')
	}

	async getCategories() {
        const res = await axios.get(URL + 'winners_vs_losers/return_hypothesis_tags')
        const data = res.data
        const options = data.tags.map(d => ({
            "value": d,
            "label": d
        }))
        this.setState({ categories: options, current_categories: options });
    }

	componentDidMount() {
		this.handleTopTiles = this.handleTopTiles.bind(this);
		this.distanceToTrainHandler = this.distanceToTrainHandler.bind(this);
	}

	handleVisualization() {
		this.setState({ loading: true })
		let array_tag = []

		for(let tags of this.state.current_categories){
			array_tag.push(tags.value)
		}

		this.handleTopTiles(this.state.percentage, this.state.distance_km, this.state.top_x, this.state.current_checkbox, array_tag);
	}

	handleTopTilesInitial(percentage, distance_km, top_x, current_checkbox, bike_filter) {

		fetch(URL + 'winners_vs_losers/top_winners', {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				"distance_station_m": distance_km * 1000,
				"total_pkm": percentage,
				"n_top": top_x,
				"regional_filter": current_checkbox,
				"tag_filter": []
			}),
		}).then((res) => res.json())
			.then((json) => {
				top_tile_geojson = json;
			})
			.then(() => {
				fetch(URL + 'winners_vs_losers/top_losers', {
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						"distance_station_m": distance_km * 1000,
						"total_pkm": percentage,
						"n_top": top_x,
						"regional_filter": current_checkbox,
						"tag_filter": []
					}),
				}).then((res) => res.json())
					.then((json) => {
						bottom_tile_geojson = json;
					})
			})
			.then(() => {
				this.props.map.getSource('data-swiss-bottom-tyles').setData(bottom_tile_geojson);
				this.props.map.getSource('data-swiss-top-tyles').setData(top_tile_geojson);
			})

	}

	//TODO
	handleTopTiles(percentage, distance_km, top_x, current_checkbox, array_tag) {

		fetch(URL + 'winners_vs_losers/top_winners', {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				"distance_station_m": distance_km * 1000,
				"total_pkm": percentage,
				"n_top": top_x,
				"regional_filter": current_checkbox,
				"tag_filter": array_tag
			}),
		}).then((res) => res.json())
			.then((json) => {
				this.props.map.getSource('data-swiss-top-tyles').setData(json);
			})
			.then(() => {
				fetch(URL + 'winners_vs_losers/top_losers', {
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						"distance_station_m": distance_km * 1000,
						"total_pkm": percentage,
						"n_top": top_x,
						"regional_filter": current_checkbox,
						"tag_filter": array_tag
					}),
				}).then((res) => res.json())
					.then((json) => {
						this.props.map.getSource('data-swiss-bottom-tyles').setData(json);
					})
			})
			.then(() => {
			}).then(() => {
				this.setState({ loading: false })
			})

	}

	handleTopXTiles(event) {
		this.setState({ top_x: event })
	}

	distanceToTrainHandler(event) {
		this.setState({ distance_km: event })
	}

	totalPKmHandler(event) {
		this.setState({ percentage: event })
	}

	handleTypeTrend(event) {
		this.setState({current_checkbox: event.target.attributes.getNamedItem('id').value})
	}

	handleCategories(event){
		this.setState({ current_categories: event })
	}

	componentDidUpdate(prevProps) {
		if (this.props.map !== prevProps.map) {
			this.setState({ mapa: this.props.map });
		}
	}

	render() {
		const { loading } = this.state;
		const { current_checkbox } = this.state;

		const { categories } = this.state;
		const { current_categories } = this.state;

		const selectDate = {
            menuList: styles => {
                return {
                    ...styles,
                    maxHeight: 140,
                    borderWidth: 0,
                    boxShadow: '0 0 10px 2px rgb(0 0 0 / 10%)',
                };
            }
        };

		if (loading) { // if your component doesn't have to wait for an async action, remove this block 
			return (
				<div className="bg-loader">
					<div className="pos-center-brain-loader">
						<div className="circle-loading-brain">
						</div>
						<svg className='brain-loader' x="0px" y="0px" viewBox="0 0 496 496">
							<path d="M40,449.472V364.28L58.424,352H88v-16H53.576L24,355.72v93.752C14.704,452.784,8,461.584,8,472c0,13.232,10.768,24,24,24				s24-10.768,24-24C56,461.584,49.288,452.784,40,449.472z M32,480c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8s8,3.584,8,8				C40,476.416,36.416,480,32,480z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M46.528,320H88v-16H46.528c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24				C34.416,336,43.216,329.288,46.528,320z M16,312c0-4.416,3.584-8,8-8s8,3.584,8,8c0,4.416-3.584,8-8,8S16,316.416,16,312z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M24,272c10.416,0,19.216-6.712,22.528-16H88v-16H46.528c-3.312-9.296-12.112-16-22.528-16c-13.232,0-24,10.768-24,24				S10.768,272,24,272z M24,240c4.416,0,8,3.584,8,8s-3.584,8-8,8s-8-3.584-8-8C16,243.584,19.584,240,24,240z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M56 272h32v16H56Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M56 208h32v16H56Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M0 176h88v16H0Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M60.688,160H88v-16H67.312L48,124.688v-14.16C57.288,107.216,64,98.416,64,88c0-13.232-10.768-24-24-24S16,74.768,16,88				c0,10.416,6.704,19.216,16,22.528v20.784L60.688,160z M40,80c4.416,0,8,3.584,8,8s-3.584,8-8,8s-8-3.584-8-8S35.584,80,40,80z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M144 67.312L144 88L160 88L160 60.688L131.312 32L0 32L0 48L124.688 48z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M176 35.312L176 88L192 88L192 28.688L163.312 0L0 0L0 16L156.688 16z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M208 56h16v32H208Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M304 0h16v88H304Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M240,46.528V88h16V59.312l16,16V88h16V68.688L262.392,43.08C268.192,38.696,272,31.816,272,24c0-13.232-10.768-24-24-24				s-24,10.768-24,24C224,34.416,230.704,43.216,240,46.528z M248,16c4.416,0,8,3.584,8,8s-3.584,8-8,8s-8-3.584-8-8				S243.584,16,248,16z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M352,51.312L371.312,32h78.16c2.416,6.776,7.752,12.112,14.528,14.528v110.16L444.688,176H408v16h43.312L480,163.312				V46.528c9.288-3.312,16-12.112,16-22.528c0-13.232-10.768-24-24-24c-10.416,0-19.216,6.712-22.528,16h-84.784L336,44.688V88h16				V51.312z M472,16c4.416,0,8,3.584,8,8s-3.584,8-8,8c-4.416,0-8-3.584-8-8S467.584,16,472,16z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M414.08,97.392l17.92,17.92v9.376L412.688,144H408v16h11.312L448,131.312v-22.624L419.312,80h-4.784				c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24C401.904,112,410.416,105.968,414.08,97.392z				 M392,96c-4.416,0-8-3.584-8-8s3.584-8,8-8c4.416,0,8,3.584,8,8S396.416,96,392,96z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M408 208h88v16H408Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M480,449.472V268.688L451.312,240H408v16h36.688L464,275.312v41.376L451.312,304H408v16h36.688L464,339.312v110.16				c-9.296,3.312-16,12.112-16,22.528c0,13.232,10.768,24,24,24s24-10.768,24-24C496,461.584,489.288,452.784,480,449.472z M472,480				c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8c4.416,0,8,3.584,8,8C480,476.416,476.416,480,472,480z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M408 272h32v16H408Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M448,364.688L419.312,336H408v16h4.688L432,371.312V416h-80v-8h-16v24h96v12.688L412.688,464h-14.16				c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24c10.416,0,19.216-6.712,22.528-16h20.784				L448,451.312V364.688z M376,480c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8c4.416,0,8,3.584,8,8C384,476.416,380.416,480,376,480z				" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M160,408h-16v8h-41.472c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24				c10.416,0,19.216-6.712,22.528-16H160V408z M80,432c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8s8,3.584,8,8				C88,428.416,84.416,432,80,432z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M304 408h16v88H304Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M272 408h16v88H272Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M240,420.688l-16,16V408h-16v41.472c-9.296,3.312-16,12.112-16,22.528c0,13.232,10.768,24,24,24s24-10.768,24-24				c0-7.816-3.808-14.696-9.608-19.08L256,427.312V408h-16V420.688z M216,480c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8s8,3.584,8,8				C224,476.416,220.416,480,216,480z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M192 408L176 408L176 428.688L152 452.688L152 496L168 496L168 459.312L192 435.312z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M336,392h16v-16h24v-24h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16				v-16h-16v-24h-24v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-3.312				L120,140.688V144h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v24h24v16h16v-16h16				v16h16v-16h16v16h16v-16h16v16h16v-16h16v16h16v-16h16v16h16v-16h16V392z M136,360V147.312L147.312,136H360v224H136z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M318.888,211.048l13.96-13.96l-33.936-33.936l-13.96,13.96c-4.16-2.184-8.488-3.976-12.952-5.384V152h-48v19.728				c-4.472,1.408-8.8,3.2-12.952,5.384l-13.96-13.96l-33.936,33.936l13.96,13.96c-2.176,4.16-3.976,8.488-5.384,12.952H152v48				h19.728c1.408,4.472,3.2,8.8,5.384,12.952l-13.96,13.96l33.936,33.936l13.96-13.96c4.16,2.176,8.48,3.976,12.952,5.384V344h48				v-19.728c4.472-1.408,8.8-3.2,12.952-5.384l13.96,13.96l33.936-33.936l-13.96-13.96c2.176-4.152,3.976-8.48,5.384-12.952H344v-48				h-19.728C322.864,219.528,321.072,215.2,318.888,211.048z M328,256h-16.224l-1.416,6.224c-1.6,7.016-4.368,13.672-8.216,19.792				l-3.408,5.408l11.496,11.496l-11.312,11.312l-11.496-11.496l-5.408,3.408c-6.12,3.848-12.776,6.616-19.792,8.216L256,311.776V328				h-16v-16.224l-6.224-1.416c-7.016-1.6-13.672-4.368-19.792-8.216l-5.408-3.408l-11.496,11.496l-11.312-11.312l11.496-11.496				l-3.408-5.408c-3.848-6.12-6.616-12.776-8.216-19.792L184.224,256H168v-16h16.224l1.416-6.224				c1.6-7.016,4.368-13.672,8.216-19.792l3.408-5.408l-11.496-11.496l11.312-11.312l11.496,11.488l5.408-3.408				c6.12-3.848,12.776-6.616,19.792-8.216l6.224-1.408V168h16v16.224l6.224,1.416c7.016,1.6,13.672,4.368,19.792,8.216l5.408,3.408				l11.496-11.488l11.312,11.312l-11.496,11.496l3.408,5.408c3.848,6.12,6.616,12.776,8.216,19.792l1.416,6.216H328V256z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M248,208c-22.056,0-40,17.944-40,40c0,22.056,17.944,40,40,40c22.056,0,40-17.944,40-40C288,225.944,270.056,208,248,208				z M248,272c-13.232,0-24-10.768-24-24s10.768-24,24-24s24,10.768,24,24S261.232,272,248,272z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
						</svg>
					</div>
				</div>
			)
		}
		else {
			return (
				<div>
					<div className="modal-winners-losers-filters">
						<div className="modal-winners-losers-filters-inner">
							<h1>Winners vs. Losers</h1>
							<h2>Filter by total PKm</h2>
							<div className="slider-distance">
								<Slider min={0} max={15000000} step={100} defaultValue={this.state.percentage} onChange={_.debounce(this.totalPKmHandler.bind(this), 33)} />
							</div>
							<p className='label'>Showing tiles with minimum {numberWithCommas(Number(this.state.percentage))} PKm per month</p>
							<h2>Filter by distance to the train station</h2>
							<div className="slider-distance">
								<Slider min={0} max={35} step={0.01} defaultValue={this.state.distance_km} onChange={_.debounce(this.distanceToTrainHandler.bind(this), 33)} />
							</div>
							<p className='label'>Show trips that start at minimum {this.state.distance_km}km from the nearest train station</p>
							<h2>Filter by number of tiles</h2>
							<div className="slider-distance">
								<Slider min={10} max={3000} step={10} defaultValue={this.state.top_x} onChange={_.debounce(this.handleTopXTiles.bind(this), 33)} />
							</div>
							<p className='label'>Show top {this.state.top_x} tiles for winners and losers categories</p>
							<h2>Filter by type of tile trend</h2>
							<div className="row">
								<Form onChange={this.handleTypeTrend.bind(this)}>
									<div className="column-33">
									<Form.Check inline label="None" name="type_analysis" type='radio' id='off' defaultChecked={(current_checkbox==='off')} />
									</div>
									<div className="column-33">
									<Form.Check inline label="Regional" name="type_analysis" type='radio' id='regional' defaultChecked={(current_checkbox==='regional')} />
									</div>
									<div className="column-33">
									<Form.Check inline label="Isolated" name="type_analysis" type='radio' id='isolated' defaultChecked={(current_checkbox==='isolated')} />
									</div>
								</Form>
							</div>
							<h2>Filter by category</h2>
							<div className="row-padding">
							<Select className="basic-multi-select" menuPosition="fixed" isMulti options={categories} onChange={this.handleCategories.bind(this)} styles={selectDate} defaultValue={current_categories} />
							</div>
							<Form onClick={this.handleVisualization.bind(this)}>
								<Button className="generate-btn" variant="info">Calculate</Button>
							</Form>
						</div>
					</div>
				</div>
			)
		}
	}
}
class MapWinnersLosers extends React.Component {

	// Set up states for updating map 
	constructor(props) {
		super(props);
		this.state = {
			lng: 8.251313,
			lat: 46.924143,
			zoom: 7,
			mapita: 9,
			tyle: 'mapbox://styles/mapbox/satellite-streets-v11',
			coord: [],
			hideNavs: false
		}
	}

	//Update several fields
	updateFields(list) {
		this.setState(list);
	}

	//Update map on component update
	updateField(card, value) {
		this.setState({
			...this.state,
			[card]: value
		});
	}

	//Update style
	handleTyle(value) {
		this.setState({ tyle: value }, this.componentDidMount);
	}

	//Update coordinates
	handleCoord(center, zoom) {
		const { lng, lat } = center;
		this.setState({ zoom: zoom, lat: lat, lng: lng })
	}

	//Update coordinates
	handleCoordMoveToTyle(lng, lat, zoom) {
		this.setState({ zoom: zoom, lat: lat, lng: lng })
	}

	componentDidMount() {
		// Set up map 
		const map = new mapboxgl.Map({
			container: this.mapContainer,
			style: this.state.tyle,
			center: [this.state.lng, this.state.lat],
			zoom: this.state.zoom,
		});

		map.on('load', () => {

			// Add zoom and rotation controls to the map.
			map.addControl(new mapboxgl.NavigationControl({ showCompass: false, showZoom: false }), 'top-left');
			map.addControl(new MapboxGeocoder({ accessToken: mapboxgl.accessToken, mapboxgl: mapboxgl, marker: false }), 'top-left');

			//Add shapes to the map (Top tiles - 1kmx1km)
			map.addSource('data-swiss-top-tyles', {
				'type': 'geojson',
				'data': top_tile_geojson,
				'generateId': true
			});

			map.addLayer({
				'id': 'data-swiss-top-tyles',
				'type': 'fill',
				'source': 'data-swiss-top-tyles',
				'layout': {
					'visibility': 'visible'
				},
				'paint': {
					'fill-color': '#03C200',
					'fill-opacity': 0.15
				}
			});

			map.addLayer({
				'id': 'outline-data-swiss-top-tyles',
				'type': 'line',
				'source': 'data-swiss-top-tyles',
				'layout': {
					'visibility': 'visible'
				},
				'paint': {
					'line-color': [
                        'case',
						['==', ['string', ['get', 'has_data']], 'has_data'],
                        '#9d02f7',
                        ['==', ['string', ['get', 'has_data']], 'correlate'],
                        '#02f3f7',
                        '#03C200'
                    ],
					'line-width': 2
				}
			});

			//Add shapes to the map (Top tiles - 1kmx1km)
			map.addSource('data-swiss-bottom-tyles', {
				'type': 'geojson',
				'data': bottom_tile_geojson,
				'generateId': true
			});

			map.addLayer({
				'id': 'data-swiss-bottom-tyles',
				'type': 'fill',
				'source': 'data-swiss-bottom-tyles',
				'layout': {
					'visibility': 'visible'
				},
				'paint': {
					'fill-color': '#C90000',
					'fill-opacity': 0.15
				}
			});

			map.addLayer({
				'id': 'outline-data-swiss-bottom-tyles',
				'type': 'line',
				'source': 'data-swiss-bottom-tyles',
				'layout': {
					'visibility': 'visible'
				},
				'paint': {
					'line-color': [
                        'case',
						['==', ['string', ['get', 'has_data']], 'has_data'],
                        '#9d02f7',
                        ['==', ['string', ['get', 'has_data']], 'correlate'],
                        '#02f3f7',
                        '#C90000'
                    ],
					'line-width': 2
				}
			});
		});

		this.setState({
			mapita: map,
			zoom: map.getZoom(),
			center: map.getCenter()
		});

		map.on('click', 'data-swiss-top-tyles', (e) => {
			let resp_data = ''
			let coords = e.lngLat

			fetch(URL + 'winners_vs_losers/detail/' + e.features[0].properties.tileId + '/winner')
				.then((res) => res.json())
				.then((json) => {
					resp_data = json[0]
					//let date = (resp_data.date).split('T')[0]

					let plz_name = resp_data['PLZ name'];
					let closest_station = resp_data['closest station name'];
					let distance_station = (resp_data['distance to closest station (m)'] / 1000).toFixed(0);
					let modal_split_before = (resp_data['modal_split_before (april 2019- june 2019)'] * 100).toFixed(0)
					let modal_split_after = (resp_data['modal_split_after (april 2023- june 2023)'] * 100).toFixed(0)
					let modal_split_ratio = (resp_data['Modal Split Ratio (percentage)']).toFixed(0)
					let hypothesis = (resp_data['hypothesis'])

					let categories = 'Currently, no hypothesis'
					if(hypothesis.length>0){
						for(let hypo of hypothesis){
							if(categories==='Currently, no hypothesis'){
								categories = hypo
							}else{
								categories += ' ~ '+hypo
							}
						}
					}

					let car_pkm_last_12_months = numberWithCommas((resp_data['car_pkm_last_12_months']).toFixed(0))
					let train_pkm_last_12_months = numberWithCommas((resp_data['train_pkm_last_12_months']).toFixed(0))
					let total_pkm_last_12_months = numberWithCommas((resp_data['total_pkm_last_12_months']).toFixed(0))

					let html_modal = '<span style="font-weight: bold;">Passenger Km per month</span><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle; padding-bottom:10px;"><thead><tr><th>Cars</th><th>Trains</th><th>Total</th></tr></thead><tbody><tr><td>' + car_pkm_last_12_months + ' </td><td>' + train_pkm_last_12_months + ' </td><td><span>' + total_pkm_last_12_months + ' </span></td></tr></tbody></table><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Hypothesis Categories</span></p><p style="margin-block-end: 0em;">'+ categories +'</p>';

					let details_url = URL_FRONT + 'winners-losers-detail/' + coords.lng + ',' + coords.lat;
					let html_button = '<a href="' + details_url + '" target="_blank"><button style="background-color: #0c8a8a;color: white;border: 1px solid #e4e4e4;padding: 8px;border-radius: 6px;cursor: pointer; padding-top:10px;">See Details</button></a>';

					let html = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">' + plz_name + '</span></p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Nearest station: </span>' + closest_station + '</p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Distance to station: </span>' + distance_station + 'km</p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Montly Modal Split</span></p><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle; width: 100%;"><thead><tr><th></th><th style="width:50%; text-align: start;">Before (2019)</th><th style="width:50%; text-align: end;"><span style="float:right; position:relative, width:50%">After (2023)</span></th></tr></thead><tbody><tr><th></th><th style="width:50%; text-align: start; font-weight: normal;">' + modal_split_before + '%</th><th style="width:50%; text-align: end;"><span style="float:right; position:relative, width:50%; font-weight: normal;">' + modal_split_after + '%</span></th></tr></tbody></table><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Modal Split Ratio: </span>' + modal_split_ratio + '%</p>'
					new mapboxgl.Popup()
						.setLngLat(e.lngLat)
						.setHTML(html + html_modal + html_button).addTo(map);
				})
		});

		map.on('click', 'data-swiss-bottom-tyles', (e) => {
			let resp_data = ''

			let coords = e.lngLat

			fetch(URL + 'winners_vs_losers/detail/' + e.features[0].properties.tileId + '/loser')
				.then((res) => res.json())
				.then((json) => {
					resp_data = json[0]
					//let date = (resp_data.date).split('T')[0]

					let plz_name = resp_data['PLZ name'];
					let closest_station = resp_data['closest station name'];
					let distance_station = (resp_data['distance to closest station (m)'] / 1000).toFixed(0);
					let modal_split_before = (resp_data['modal_split_before (april 2019- june 2019)'] * 100).toFixed(0)
					let modal_split_after = (resp_data['modal_split_after (april 2023- june 2023)'] * 100).toFixed(0)
					let modal_split_ratio = (resp_data['Modal Split Ratio (percentage)']).toFixed(0)
					let hypothesis = (resp_data['hypothesis'])

					let categories = 'Currently, no hypothesis'
					if(hypothesis.length>0){
						for(let hypo of hypothesis){
							if(categories==='Currently, no hypothesis'){
								categories = hypo
							}else{
								categories += ' ~ '+hypo
							}
						}
					}

					let car_pkm_last_12_months = numberWithCommas((resp_data['car_pkm_last_12_months']).toFixed(0))
					let train_pkm_last_12_months = numberWithCommas((resp_data['train_pkm_last_12_months']).toFixed(0))
					let total_pkm_last_12_months = numberWithCommas((resp_data['total_pkm_last_12_months']).toFixed(0))

					let html_modal = '<span style="font-weight: bold;">Passenger Km per month</span><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle; padding-bottom:10px;"><thead><tr><th>Cars</th><th>Trains</th><th>Total</th></tr></thead><tbody><tr><td>' + car_pkm_last_12_months + ' </td><td>' + train_pkm_last_12_months + ' </td><td><span>' + total_pkm_last_12_months + ' </span></td></tr></tbody></table><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Hypothesis Categories</span></p><p style="margin-block-end: 0em;">'+ categories +'</p>';

					let details_url = URL_FRONT + 'winners-losers-detail/' + coords.lng + ',' + coords.lat;
					let html_button = '<a href="' + details_url + '" target="_blank"><button style="background-color: #0c8a8a;color: white;border: 1px solid #e4e4e4;padding: 8px;border-radius: 6px;cursor: pointer; padding-top:10px;">See Details</button></a>';

					let html = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">' + plz_name + '</span></p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Nearest station: </span>' + closest_station + '</p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Distance to station: </span>' + distance_station + 'km</p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Montly Modal Split</span></p><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle; width: 100%;"><thead><tr><th></th><th style="width:50%; text-align: start;">Before (2019)</th><th style="width:50%; text-align: end;"><span style="float:right; position:relative, width:50%">After (2023)</span></th></tr></thead><tbody><tr><th></th><th style="width:50%; text-align: start; font-weight: normal;">' + modal_split_before + '%</th><th style="width:50%; text-align: end;"><span style="float:right; position:relative, width:50%; font-weight: normal;">' + modal_split_after + '%</span></th></tr></tbody></table><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Modal Split Ratio: </span>' + modal_split_ratio + '%</p>'
					new mapboxgl.Popup()
						.setLngLat(e.lngLat)
						.setHTML(html + html_modal + html_button).addTo(map);
				})
		});
	}

	render() {
		const { hideNavs } = this.state;

		return (
			<div>
				<div ref={el => this.mapContainer = el} style={{ width: '100%', height: '100vh' }}>
				</div>
				<RecommendationNavBarComponent />
				<HideElementsComponent map={this.state.mapita} updateField={(card, field, value) => this.updateField(card, field, value)} />
				<div style={{ display: hideNavs ? 'none' : 'block' }}>
						<BtnsComponent map={this.state.mapita} coord={this.state.coord} handleCoordMoveToTyle={(value1, value2, value3) => this.handleCoordMoveToTyle(value1, value2, value3)} updateField={(field, value) => this.updateField(field, value)} />
					</div>
				<LayerTylesComponent map={this.state.mapita} tyle={this.state.tyle} handleTyle={(value) => this.handleTyle(value)} handleCoord={(value1, value2) => this.handleCoord(value1, value2)} />
			</div>
		)
	}
}

export default MapWinnersLosers;
