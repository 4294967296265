import React from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import { IconContext } from "react-icons";
import RecommendationNavBarComponent from "../../components/RecommendationNavBarComponent";
import axios from 'axios';
import Select from 'react-select';
import '../css/dashboard_5_in_5.css';
import { IoSettingsOutline } from "react-icons/io5";
import { Form, Button } from 'react-bootstrap';
import { Table, Column, HeaderCell, Cell } from 'rsuite-table';
import ReactPaginate from 'react-paginate';
import Slider from 'rc-slider';

import '../css/react-paginate.css';
import 'rsuite-table/dist/css/rsuite-table.css'; // or 'rsuite-table/dist/css/rsuite-table.css'

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const URL = process.env.REACT_APP_URL_SERVER_BACK;
const URL_FRONT = process.env.REACT_APP_URL_SERVER_FRONT;

const _ = require('lodash');

function numberWithCommas(x) {
    if (typeof x !== "undefined") {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");

    }
    else {
        return 0
    }
}

//Dashboard: Traffic Categories
class Dashboard_Traffic_Categories extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            kantons_: this.getOptionsKantons(URL + 'what_if/kanton_list'),
            current_value_k: null,
            limit: 10,
            current_page: 0,
            max_page: 5,
            dataList: [],
            loading: true,
            filter_pkm_trips: 'pkm',
            num_pkm_trips: 100000,
            max_value_pkm_trips: 300000,
            num_tiles: 0,
            total_traffic: 0,
            switch_state: false,
            type_building_: this.getOptionTypeBuilding(URL + 'tile_potential_leisure/building_categories'),
            current_value_type_building: null,
            min_distance_train_station: 0,
            max_distance_train_station: 35000,
            state_settings: false,
            sortColumn: 'main_category',
            sortType: 'asc'
        }
        this.fetchInitialData(false, 'pkm', 100000, 0, 35000, 'all', 'all', 0)
    }

    handleUpdate() {
        this.fetchInitialData(this.state.switch_state, this.state.filter_pkm_trips, this.state.num_pkm_trips, this.state.min_distance_train_station, this.state.max_distance_train_station, (this.state.current_value_k === null ? 'all' : this.state.current_value_k.value), (this.state.current_value_type_building === null ? 'all' : this.state.current_value_type_building.value), 0);
    }

    handleDistanceTrainStation(event) {
        this.setState({ min_distance_train_station: event[0], max_distance_train_station: event[1] });
    }

    handleMinPkmTrips(event) {
        this.setState({ num_pkm_trips: event });
    }

    handlePkmTrips(event) {
        this.setState({ filter_pkm_trips: event.target.attributes.getNamedItem('id').value });
        let current_num_pkm_trips = 0;
        if (event.target.attributes.getNamedItem('id').value === 'trips') {
            current_num_pkm_trips = 10000;
            this.setState({ num_pkm_trips: 10000, max_value_pkm_trips: 30000 })
        } else {
            current_num_pkm_trips = 100000;
            this.setState({ num_pkm_trips: 100000, max_value_pkm_trips: 300000 })
        }
        this.fetchInitialData(this.state.switch_state, event.target.attributes.getNamedItem('id').value, current_num_pkm_trips, this.state.min_distance_train_station, this.state.max_distance_train_station, (this.state.current_value_k === null ? 'all' : this.state.current_value_k.value), (this.state.current_value_type_building === null ? 'all' : this.state.current_value_type_building.value), 0);
    }

    async getOptionTypeBuilding(url) {
        const res = await axios.get(url)
        const data = res.data
        const options = data.map(d => ({
            "value": d.category,
            "label": (d.category === null ? null : d.category.split('_').join(' '))
        }))
        this.setState({ type_building_: options });
    }

    async getOptionsKantons(url) {
        const res = await axios.get(url)
        const data = res.data
        const options = data.map(d => ({
            "value": d._id,
            "label": d._id
        }))
        this.setState({ kantons_: options });
    }
    handleCategory(event) {
        this.setState({ current_value_type_building: event, current_page: 0 });
        this.fetchInitialData(this.state.switch_state, this.state.filter_pkm_trips, this.state.num_pkm_trips, this.state.min_distance_train_station, this.state.max_distance_train_station, (this.state.current_value_k === null ? 'all' : this.state.current_value_k.value), (event === null ? 'all' : event.value), 0);
    }

    handleKanton(event) {
        this.setState({ current_value_k: event, current_page: 0 });
        this.fetchInitialData(this.state.switch_state, this.state.filter_pkm_trips, this.state.num_pkm_trips, this.state.min_distance_train_station, this.state.max_distance_train_station, (event === null ? 'all' : event.value), (this.state.current_value_type_building === null ? 'all' : this.state.current_value_type_building.value), 0);
    }

    fetchInitialData(switch_state, filter, trips_pkm, min_distance_train_station, max_distance_train_station, kanton, category, current_page) {
        this.setState({ loading: true })

        fetch(URL + 'tile_potential_leisure/{0}/{1}/{2},{3}/{4}/{5}/total_vs_filter_dashboard'.format(filter, trips_pkm, min_distance_train_station, max_distance_train_station, kanton, category))
            .then((res) => res.json())
            .then((json) => {
                if (filter === 'pkm') {
                    this.setState({ num_tiles: json.n_tiles, total_traffic: json.current_pkm / json.total_pkm })
                } else {
                    this.setState({ num_tiles: json.n_tiles, total_traffic: json.current_trips / json.total_trips })
                }
            }).then(() => {
                let url_complement = ''
                if (switch_state) {
                    url_complement = 'tile_potential_leisure/{0}/{1}/{2},{3}/{4}/category_overview'.format(filter, trips_pkm, min_distance_train_station, max_distance_train_station, kanton)
                } else {
                    url_complement = 'tile_potential_leisure/{0}/{1}/{2},{3}/{4}/{5}/{6}/category_dashboard'.format(filter, trips_pkm, min_distance_train_station, max_distance_train_station, kanton, category, (current_page + 1))
                }
                return url_complement;
            }).then((url_complement) => {
                fetch(URL + url_complement)
                    .then((res) => res.json())
                    .then((json) => {
                        this.setState({ dataList: json })
                    })
                    .then(() => {
                        this.setState({ loading: false })
                    }).then(() => {
                        fetch(URL + 'tile_potential_leisure/{0}/{1}/{2},{3}/{4}/{5}/category_dashboard_pages'.format(filter, trips_pkm, min_distance_train_station, max_distance_train_station, kanton, category))
                            .then((res) => res.json())
                            .then((json) => {
                                this.setState({ max_page: json.pages })
                            })
                    })
            })
    }

    handleTypeView() {
        let currentState = !this.state.switch_state
        this.setState({ switch_state: currentState })
        if (currentState) {
            this.setState({ current_value_type_building: null });
            this.fetchInitialData(currentState, this.state.filter_pkm_trips, this.state.num_pkm_trips, this.state.min_distance_train_station, this.state.max_distance_train_station, (this.state.current_value_k === null ? 'all' : this.state.current_value_k.value), 'all', this.state.current_page);
        }
        this.fetchInitialData(currentState, this.state.filter_pkm_trips, this.state.num_pkm_trips, this.state.min_distance_train_station, this.state.max_distance_train_station, (this.state.current_value_k === null ? 'all' : this.state.current_value_k.value), (this.state.current_value_type_building === null ? 'all' : this.state.current_value_type_building.value), this.state.current_page);
    }

    hideComponent() {
        let currentStateSettings = !this.state.state_settings;
        this.setState({ state_settings: currentStateSettings })
    }

    handlePageClick(event) {
        this.setState({ current_page: event.selected })
        this.fetchInitialData(this.state.switch_state, this.state.filter_pkm_trips, this.state.num_pkm_trips, this.state.min_distance_train_station, this.state.max_distance_train_station, (this.state.current_value_k === null ? 'all' : this.state.current_value_k.value), (this.state.current_value_type_building === null ? 'all' : this.state.current_value_type_building.value), event.selected);
    }

    handleRowSelection(event) {
        window.open(URL_FRONT + 'tile-analysis/' + event.tileId, "_blank", "noreferrer");
    }

    handleSortColumn(sortColumn, sortType) {
        this.setState({ sortType: sortType, sortColumn: sortColumn })
        this.getData(sortColumn, sortType)
    }

    getData(sortColumn, sortType) {
        let data = this.state.dataList;
        if (sortColumn && sortType) {
            return data.sort((a, b) => {
                let x = a[sortColumn];
                let y = b[sortColumn];

                if (typeof x === 'string') {
                    x = x.charCodeAt();
                }
                if (typeof y === 'string') {
                    y = y.charCodeAt();
                }
                if (sortType === 'asc') {
                    return x - y;
                } else {
                    return y - x;
                }
            });
        }
        return data;
    };

    render() {
        const selectStyles = {
            menuList: styles => {
                return {
                    ...styles,
                    maxHeight: 100,
                };
            }
        };

        return (
            <>
                <RecommendationNavBarComponent />
                <div className="switch-change-div" style={{ top: '0px', left: '560px' }}>
                    <div className="switch-change-div-inner">
                        <div className="column-33">
                            <p className="label">Tile Analysis</p>
                        </div>
                        <div className="column-33">
                            <label className="switch">
                                <input type="checkbox" onChange={this.handleTypeView.bind(this)} value={this.state.switch_state} />
                                <span className="slider round"></span>
                            </label>
                        </div>
                        <div className="column-33">
                            <p className="label">Category Analysis</p>
                        </div>
                    </div>
                </div>
                <div className="dashboard-5-in-5">
                    <div className="dashboard-5-in-5-inner">
                        <div className="kanton-employer-div">
                            <Select className='kanton' placeholder="Filter by Canton..." isClearable={true} options={this.state.kantons_} onChange={this.handleKanton.bind(this)} styles={selectStyles} defaultValue={this.state.current_value_k} />
                        </div>

                        <div className="kanton-employer-div" style={{ left: '260px', width: '300px' }}>
                            <Select className='tile-category' placeholder="Filter by Category..." isClearable={true} isDisabled={this.state.switch_state} options={this.state.type_building_} onChange={this.handleCategory.bind(this)} styles={selectStyles} value={this.state.current_value_type_building} />
                        </div>

                        <div className="dashboard-table-frame">
                            <h2 style={{ marginTop: '2%' }}>Top Traffic based on Tile Categories - {this.state.filter_pkm_trips === 'pkm' ? 'PKm' : 'Trips'}</h2>
                            <div className="row" style={{ height: '50px' }}>
                                <div className='column-20' style={{ float: 'left', width: '90%', padding: '0px' }}>
                                    <h3>Showing {numberWithCommas(this.state.num_tiles)} hotspots which represents {(this.state.total_traffic * 100).toFixed(2)}% of total traffic for {this.state.current_value_k === null ? 'Switzerland' : this.state.current_value_k.label}</h3>
                                </div>
                                <div className='column-20' style={{ float: 'right', width: '10%', padding: '0px' }}>
                                    <div className='settings-dashboard-btn-inner' onClick={() => this.hideComponent()}>
                                        <IconContext.Provider value={{ color: "#5B5B5B", size: '1em' }}>
                                            <div>
                                                <IoSettingsOutline />
                                            </div>
                                        </IconContext.Provider>
                                    </div>
                                </div>
                            </div>
                            {
                                this.state.state_settings
                                    ?
                                    <div className="row" style={{ height: '80px' }}>
                                        <div className='column-20' style={{ float: 'right', width: '33%', padding: '0px' }}>
                                            <div className="column-especial">
                                                <Slider range draggableTrack min={0} max={35000} step={100} value={[this.state.min_distance_train_station, this.state.max_distance_train_station]} onChange={_.debounce(this.handleDistanceTrainStation.bind(this), 33)} />
                                            </div>
                                            <p className='label-text'>Showing tiles between {numberWithCommas(this.state.min_distance_train_station)}m and {numberWithCommas(this.state.max_distance_train_station)}m to the train station</p>
                                        </div>
                                        <div className='column-20' style={{ float: 'right', width: '33%', padding: '0px' }}>
                                            <div className="column-especial">
                                                <Slider draggableTrack min={0} max={this.state.max_value_pkm_trips} step={100} value={this.state.num_pkm_trips} onChange={_.debounce(this.handleMinPkmTrips.bind(this), 33)} />
                                            </div>
                                            <p className='label-text'>Showing tiles with at least {numberWithCommas(this.state.num_pkm_trips)}{this.state.filter_pkm_trips === 'pkm' ? 'PKm' : ' trips'}</p>
                                        </div>
                                        <div className='column-20' style={{ float: 'right', width: '33%', padding: '0px' }}>
                                            <Form onChange={this.handlePkmTrips.bind(this)}>
                                                <p className='label-text'><b>Filter by..</b>.</p>
                                                <div className="row">
                                                    <div className="column-1-50">
                                                        <Form.Check inline label=" Num. of trips" name="type_aggregation" type='radio' id='trips' defaultChecked={(this.state.filter_pkm_trips === 'trips')} />
                                                    </div>
                                                    <div className="column-1-50">
                                                        <Form.Check inline label=" Passenger Km" name="type_aggregation" type='radio' id='pkm' defaultChecked={(this.state.filter_pkm_trips === 'pkm')} />
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                        <div className="column-20" style={{ paddingTop: '10px' }}>
                                            <Form onClick={this.handleUpdate.bind(this)}>
                                                <Button variant="info" className="generate-btn">Update</Button>
                                            </Form>
                                        </div>
                                    </div>
                                    :
                                    <></>
                            }
                            <div className="row">
                                {this.state.switch_state ?
                                    <>
                                        <Table
                                            loading={this.state.loading}
                                            defaultExpandAllRows={false}
                                            autoHeight={true}
                                            rowKey="tileId"
                                            height={310}
                                            data={this.getData(this.state.sortColumn, this.state.sortType)}
                                            sortColumn={this.state.sortColumn}
                                            sortType={this.state.sortType}
                                            onSortColumn={(sortColumn, sortType) => this.handleSortColumn(sortColumn, sortType)}
                                        >
                                            <Column flexGrow={1.6} sortable>
                                                <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}>Main Category</HeaderCell>
                                                <Cell dataKey='main_category' style={{ fontSize: '14px' }}>
                                                    {(rowData) => {
                                                        return <Button
                                                            style={{
                                                                background: 'none',
                                                                color: 'inherit',
                                                                border: 'none',
                                                                padding: 0,
                                                                font: 'inherit',
                                                                cursor: 'pointer',
                                                                outline: 'inherit',
                                                            }}
                                                            onClick={() => {
                                                                this.setState({ switch_state: false, current_value_type_building: ({ 'label': rowData.main_category.split('_').join(' '), 'value': rowData.main_category }) });
                                                                this.fetchInitialData(false, this.state.filter_pkm_trips, this.state.num_pkm_trips, this.state.min_distance_train_station, this.state.max_distance_train_station, 'all', rowData.main_category, 0);
                                                            }}>
                                                            {rowData.main_category === null ? null : rowData.main_category.split('_').join(' ')}
                                                        </Button>
                                                    }}
                                                </Cell>
                                            </Column>

                                            <Column flexGrow={2} sortable>
                                                <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}>Total {this.state.filter_pkm_trips === 'pkm' ? 'PKm' : 'Trips'}</HeaderCell>
                                                <Cell dataKey={this.state.filter_pkm_trips} style={{ fontSize: '14px' }}>
                                                    {(rowData) => {
                                                        return <>{numberWithCommas(rowData[this.state.filter_pkm_trips]?.toFixed(0))}</>;
                                                    }}
                                                </Cell>
                                            </Column>

                                            <Column flexGrow={2} sortable>
                                                <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}>Traffic Contribution(%)</HeaderCell>
                                                <Cell dataKey='ptcg' style={{ fontSize: '14px' }}>
                                                    {(rowData) => {
                                                        return <>{(rowData.ptcg * 100).toFixed(2)}%</>;
                                                    }}
                                                </Cell>
                                            </Column>

                                        </Table>
                                    </>
                                    :
                                    <>
                                        <Table
                                            loading={this.state.loading}
                                            defaultExpandAllRows={false}
                                            autoHeight={true}
                                            rowKey="tileId"
                                            height={310}
                                            onRowClick={this.handleRowSelection.bind(this)}
                                            data={this.state.dataList}
                                        >

                                            <Column flexGrow={1}>
                                                <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}>Tile Identifier</HeaderCell>
                                                <Cell dataKey="tileId" style={{ fontSize: '14px' }} />
                                            </Column>

                                            <Column flexGrow={1.6}>
                                                <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}>PLZ Name</HeaderCell>
                                                <Cell style={{ fontSize: '14px' }}>
                                                    {(rowData) => {
                                                        return <>{rowData.name} ({rowData.PLZ})</>
                                                    }}
                                                </Cell>
                                            </Column>

                                            <Column flexGrow={1.6}>
                                                <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}>Main Category</HeaderCell>
                                                <Cell style={{ fontSize: '14px' }}>
                                                    {(rowData) => {
                                                        return <>{rowData.main_category === null ? null : rowData.main_category.split('_').join(' ')}</>
                                                    }}
                                                </Cell>
                                            </Column>

                                            <Column flexGrow={2}>
                                                <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}>Total {this.state.filter_pkm_trips === 'pkm' ? 'PKm' : 'Trips'}</HeaderCell>
                                                <Cell style={{ fontSize: '14px' }}>
                                                    {(rowData) => {
                                                        return <>{numberWithCommas(rowData[this.state.filter_pkm_trips]?.toFixed(0))}</>;
                                                    }}
                                                </Cell>
                                            </Column>

                                            <Column flexGrow={2}>
                                                <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}>Individual Generated Traffic(%)</HeaderCell>
                                                <Cell style={{ fontSize: '14px' }}>
                                                    {(rowData) => {
                                                        return <>{(rowData.ptcg * 100).toFixed(2)}%</>;
                                                    }}
                                                </Cell>
                                            </Column>

                                            <Column flexGrow={2}>
                                                <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}>Cumulative Generated Traffic(%)</HeaderCell>
                                                <Cell style={{ fontSize: '14px' }}>
                                                    {(rowData) => {
                                                        return <>{(rowData.ptcg_cummulative * 100).toFixed(2)}%</>;
                                                    }}
                                                </Cell>
                                            </Column>
                                        </Table>
                                    </>
                                }

                                {!this.state.switch_state ?
                                    <>
                                        <div style={{ padding: 20 }}>
                                            <div style={{ margin: '0 auto', width: '60vw' }}>
                                                <ReactPaginate
                                                    className="react-paginate"
                                                    breakLabel="..."
                                                    nextLabel="next >"
                                                    pageRangeDisplayed={3}
                                                    forcePage={this.state.current_page}
                                                    onPageChange={this.handlePageClick.bind(this)}
                                                    pageCount={this.state.max_page}
                                                    previousLabel="< previous"
                                                    renderOnZeroPageCount={null}
                                                />
                                            </div>
                                        </div>
                                    </>
                                    : <></>}

                            </div>

                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Dashboard_Traffic_Categories;