import React from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import Slider from 'rc-slider';
import '../../css/map-recommendation.css';
import { IconContext } from "react-icons";
import axios from 'axios';
import { VscGraphLine } from "react-icons/vsc";
import Select from 'react-select';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import Chart from 'chart.js/auto';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { BsLayersHalf } from "react-icons/bs";
import { Form } from 'react-bootstrap';
import { Line } from 'react-chartjs-2';
import { RiFullscreenFill, RiFullscreenExitFill } from "react-icons/ri";
import AustriaRecommendationNavBarComponent from "../../../components/AustriaRecommendationNavBarComponent";

import '../../../components/layer-tyles.css';
import '../../css/mapbox-gl-geocoder.css';
import '../../css/map-recommendation-deckgl.css';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const URL = process.env.REACT_APP_URL_SERVER_BACK;

const _ = require('lodash');

function numberWithCommas(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

let URL_line ={
	'type': 'FeatureCollection',
	'features': [
		{
			'type': 'Feature',
			'geometry': {
				"type": "LineString",
				"coordinates": [
				]
			}
		}
	]
};

//Layer Tyles Selection - Side Menu
class LayerTylesComponent extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			showOptions: false,
			mapa: this.props.map,
		}
		this.hideComponent = this.hideComponent.bind(this);
		this.handleTyleEvent = this.handleTyleEvent.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (this.props.map !== prevProps.map) {
			this.setState({ mapa: this.props.map })
		}
	}

	hideComponent(name) {
		switch (name) {
			case "showOptions":
				this.setState({ showOptions: !this.state.showOptions });
				break;
			default:
				// eslint-disable-next-line no-unused-expressions
				null;
		}
	}

	handleTyleEvent(event) {
		switch (event) {
			case "default":
				this.props.handleTyle("mapbox://styles/mapbox/light-v10");
				break;
			case "outdoors":
				this.props.handleTyle("mapbox://styles/mapbox/outdoors-v11");
				break;
			case "satellite":
				this.props.handleTyle("mapbox://styles/mapbox/satellite-streets-v11");
				break;
			case "streets":
				this.props.handleTyle("mapbox://styles/mapbox/streets-v11");
				break;
			case "dark":
				this.props.handleTyle("mapbox://styles/mapbox/dark-v10");
				break;
			default:
				// eslint-disable-next-line no-unused-expressions
				null;
		}
		this.props.handleCoord(this.state.mapa.getCenter(), this.state.mapa.getZoom());
	}

	render() {
		const { showOptions } = this.state;

		return (
			<div>
				{showOptions ? <>
					<div id='layer-options' className='map-layer-options-tyles'>
						<div className='map-layer-option-dark' onClick={() => this.handleTyleEvent("dark")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../../assets/img/Dark.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Dark</p>
						</div>
						<div className='map-layer-option-streets' onClick={() => this.handleTyleEvent("streets")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../../assets/img/Streets.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Streets</p>
						</div>
						<div className='map-layer-option-satellite' onClick={() => this.handleTyleEvent("satellite")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../../assets/img/Satellite.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Satellite</p>
						</div>
						<div className='map-layer-option-outdoors' onClick={() => this.handleTyleEvent("outdoors")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../../assets/img/Outdoors.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Outdoors</p>
						</div>
						<div className='map-layer-option-default' onClick={() => this.handleTyleEvent("default")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../../assets/img/White.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Default</p>
						</div>
					</div>
				</> : <></>}
				<div className='map-layer-tyles'>
					<div className='map-layer-tyles-inner' onClick={() => this.hideComponent("showOptions")}>
						<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
							<div>
								<BsLayersHalf />
							</div>
						</IconContext.Provider>
					</div>
				</div>
			</div>
		)
	}
}

class HideElementsComponent extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			hideNavs: false,
			mapa: this.props.map,
		}
	}

	hideComponent() {
		let newHideNavs = !this.state.hideNavs;
		this.setState({ hideNavs: newHideNavs })
		this.props.updateField("hideNavs", newHideNavs);
	}

	render() {

		const { hideNavs } = this.state;

		return (
			<div>
				{hideNavs ? <>
					<div className='map-close-navs'>
						<div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
								<div>
									<RiFullscreenExitFill />
								</div>
							</IconContext.Provider>
						</div>
					</div>
				</> : <>
					<div className='map-close-navs'>
						<div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
								<div>
									<RiFullscreenFill />
								</div>
							</IconContext.Provider>
						</div>
					</div>
				</>}
			</div>
		)
	}
}

let abortController = new AbortController();
let signal = abortController.signal;

class GraphComponent extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			mapa: this.props.map,
			hasInfo: this.props.hasInfo,
			graphParam: this.props.graphParam,
			take_od: 0,
			respWvsW: this.handleFetchDataWsW(this.props.graphParam),
			respModalPKM: this.handleFetchDataModalPKM(this.props.graphParam),
			respModalTrips: this.handleFetchDataModalTrips(this.props.graphParam),
			respTraveledKm: this.handleFetchDataTraveledKm(this.props.graphParam, 0),
			respCO2: this.handleFetchDataCO2(this.props.graphParam, 0),
			type: 'trains',
			respTopOD: this.handleTopODContributors('pkm', 'trains', this.props.month_year),
			origin_name: '',
			destination_name: '',
			pkm_trips: 'pkm'
		}
	}

	componentDidMount() {
		this.handleFetchDataWsW = this.handleFetchDataWsW.bind(this);
		this.handleFetchDataModalPKM = this.handleFetchDataModalPKM.bind(this);
		this.handleFetchDataModalTrips = this.handleFetchDataModalTrips.bind(this);
		this.handleFetchDataTraveledKm = this.handleFetchDataTraveledKm.bind(this);
		this.handleFetchDataCO2 = this.handleFetchDataCO2.bind(this);
		this.handleAggregation = this.handleAggregation.bind(this);
		this.handleGraphComponent = this.handleGraphComponent.bind(this);
		this.handleTopODContributors = this.handleTopODContributors.bind(this);
		this.schemaType = this.schemaType.bind(this);

	}

	componentDidUpdate(prevProps) {
		if (this.props.map !== prevProps.map) {
			this.setState({ mapa: this.props.map });
		}
		if (this.props.hasInfo !== prevProps.hasInfo) {
			this.setState({ hasInfo: this.props.hasInfo });
		}
		if (this.props.month_year !== prevProps.month_year) {
			this.handleTopODContributors(this.state.pkm_trips, this.state.type, this.props.month_year);
		}
		if (this.props.graphParam !== prevProps.graphParam) {
			abortController.abort();
			abortController = new AbortController();
			signal = abortController.signal;
			this.setState({ graphParam: this.props.graphParam });
			this.handleFetchDataWsW(this.props.graphParam);
			this.handleFetchDataModalPKM(this.props.graphParam);
			this.handleFetchDataModalTrips(this.props.graphParam);
			this.handleFetchDataTraveledKm(this.props.graphParam, this.aggregation);
			this.handleFetchDataCO2(this.props.graphParam, this.aggregation);
			this.handleTopODContributors(this.state.pkm_trips, this.state.type, this.props.month_year);
		}
	}

	async handleFetchDataWsW(param) {
		if (typeof param !== "undefined") {
			fetch(URL + 'graph_weekdays_vs_weekends/' + param, { signal })
				.then((res) => res.json())
				.then((json) => {
					this.setState({ respWvsW: json });
					this.handleName(json['destination'], 'destination');
					this.handleName(json['origin'], 'origin');
				})
		}
	}

	async handleFetchDataModalTrips(param) {
		fetch(URL + 'graph_modal_split_trips/' + param, { signal })
			.then((res) => res.json())
			.then((json) => {
				for (var i = 0; i < json.modal_split_trains.length; i++) {
					json.modal_split_trains[i] = json.modal_split_trains[i] * 100
				}
				return json
			})
			.then((json) => {
				this.setState({ respModalTrips: json });
				this.handleName(json['destination'], 'destination');
				this.handleName(json['origin'], 'origin');
			})
	}

	async handleFetchDataModalPKM(param) {
		fetch(URL + 'graph_modal_split_pkm/' + param, { signal })
			.then((res) => res.json())
			.then((json) => {
				for (var i = 0; i < json.modal_split.length; i++) {
					json.modal_split[i] = json.modal_split[i] * 100
				}
				return json
			})
			.then((json) => {
				this.setState({ respModalPKM: json });
				this.handleName(json['destination'], 'destination');
				this.handleName(json['origin'], 'origin');
			})
	}

	async handleName(id, startEndPoint) {
		if (typeof id !== "undefined" && id !== 'empty') {
			let division = this.state.graphParam.split(',')[0]
			fetch(URL + 'division_name/{0}/{1}'.format(division, id))
				.then((res) => res.json())
				.then((res) => { (startEndPoint === 'origin' ? this.setState({ origin_name: res[0] }) : this.setState({ destination_name: res[0] })) })
		}
	}

	async handleFetchDataCO2(param, aggregationParam) {
		let take_od = 0
		if (typeof aggregationParam === 'undefined') {
			take_od = this.state.take_od;
		}
		else {
			take_od = aggregationParam;
		}
		fetch(URL + 'graph_co2_emissions/' + param + ',' + take_od, { signal })
			.then((res) => res.json())
			.then((json) => {
				this.setState({ respCO2: json });
				this.handleName(json['destination'], 'destination');
				this.handleName(json['origin'], 'origin');
			})
	}

	async handleTopODContributors(pkmtrips,type, date) {
		fetch(URL +'od_insights/top_destinations/empty,empty,'+type+','+pkmtrips+','+date, { signal })
			.then((res) => res.json())
			.then((json) => {
				this.setState({ respTopOD: json });
			})
	}

	async handleFetchDataTraveledKm(param, aggregationParam) {
		let take_od = 0
		if (typeof aggregationParam === 'undefined') {
			take_od = this.state.take_od;
		}
		else {
			take_od = aggregationParam;
		}
		fetch(URL + 'graph_traveled_km/' + param + ',' + take_od, { signal })
			.then((res) => res.json())
			.then((json) => {
				this.setState({ respTraveledKm: json });
				this.handleName(json['destination'], 'destination');
				this.handleName(json['origin'], 'origin');
			})
	}

	handleAggregation() {
		let aggregation = 0;
		if (this.state.take_od === 1) { this.setState({ take_od: aggregation }); }
		else {
			aggregation = 1;
			this.setState({ take_od: aggregation });
		}

		this.handleFetchDataTraveledKm(this.state.graphParam, aggregation);
	}

	handleGraphComponent(name) {

		this.setState({ showGraph: !this.state.showGraph });
	}

	schemaType(event) {
		this.setState({ type: event.target.attributes.getNamedItem('id').value })
		this.handleTopODContributors(this.state.pkm_trips, event.target.attributes.getNamedItem('id').value, this.props.month_year);
	}

	schemaTypePKmTrips(event) {
		this.setState({ pkm_trips: event.target.attributes.getNamedItem('id').value })
		this.handleTopODContributors(event.target.attributes.getNamedItem('id').value, this.state.type, this.props.month_year);
	}

	render() {
		const { respWvsW } = this.state;
		const { respModalPKM } = this.state;
		const { respModalTrips } = this.state;
		const { respTraveledKm } = this.state;
		const { showGraph } = this.state;
		const { origin_name } = this.state;
		const { destination_name } = this.state;
		const { respCO2 } = this.state;

		const listODTrips = this.state.respTopOD?.origin?.map((trip, index) =>
			<tr key={index}>
				<td className="table-data-od" style={{ fontSize: '12px' }}>{this.state.respTopOD?.origin[index]}~{this.state.respTopOD?.destination[index]}</td>
				<td className="table-data-od" style={{ fontSize: '12px' }}>{this.state.respTopOD?.origin_name[index]}</td>
				<td className="table-data-od" style={{ fontSize: '12px' }}>{this.state.respTopOD?.destination_name[index]}</td>
				<td className="table-data-od" style={{ fontSize: '12px' }}>{numberWithCommas(this.state.respTopOD?.metric_trains[index]?.toFixed(0))}</td>
				<td className="table-data-od" style={{ fontSize: '12px' }}>{numberWithCommas(this.state.respTopOD?.metric_cars[index]?.toFixed(0))}</td>
				<td className="table-data-od" style={{ fontSize: '12px' }}>{numberWithCommas(this.state.respTopOD?.metric_walking[index]?.toFixed(0))}</td>
			</tr>
		);


		const dataBar_WvsW = {
			labels: respWvsW['date'],
			datasets: [{
				label: 'Weekdays',
				data: respWvsW['total_trips_weekdays'],
				backgroundColor: [
					'rgba(2, 128, 6,0.2)'
				],
				borderColor: [
					'rgb(2, 128, 6)'

				],
				borderWidth: 1
			},
			{
				label: 'Weekends',
				data: respWvsW['total_trips_weekend'],
				backgroundColor: [
					'rgba(128, 2, 63, 0.2)'
				],
				borderColor: [
					'rgb(128, 2, 63)'
				],
				borderWidth: 1
			},
			]
		};

		const dataBar_ModalPKM = {
			labels: respModalPKM['date'],
			datasets: [{
				label: 'Modal Split for Trains',
				data: respModalPKM['modal_split'],
				backgroundColor: [
					'rgba(17, 148, 209, 0.2)'
				],
				borderColor: [
					'rgb(17, 148, 209)'
				],
				borderWidth: 1
			},
			]
		};

		const dataBar_ModalTrips = {
			labels: respModalTrips['date'],
			datasets: [{
				label: 'Modal Split for Trains',
				data: respModalTrips['modal_split_trains'],
				backgroundColor: [
					'rgba(221, 138, 230, 0.2)'
				],
				borderColor: [
					'rgb(221, 138, 230)'
				],
				borderWidth: 1
			},
			]
		}

		const dataBar_TraveledKm = {
			labels: respTraveledKm['date'],
			datasets: [{
				label: 'Traveled Km by Trains',
				data: respTraveledKm['traveled_km_trains'],
				backgroundColor: [
					'rgba(247, 137, 72, 0.2)'
				],
				borderColor: [
					'rgb(247, 137, 72)'
				],
				borderWidth: 1
			},
			{
				label: 'Traveled Km by Cars',
				data: respTraveledKm['traveled_km_cars'],
				backgroundColor: [
					'rgba(2,127,128,0.2)'
				],
				borderColor: [
					'rgb(2,127,128)'
				],
				borderWidth: 1
			},
			]
		};

		const options = {
			xAxes: [{
				type: 'time',
				ticks: {
					autoSkip: true,
					maxTicksLimit: 10
				}
			}],
			plugins: {
				title: {
					display: true,
					text: 'Daily Percentage of Trains\' Modal Split (PKm)'
				},
				legend: {
					position: 'bottom',
				},
			},
		};

		const options_trips = {
			xAxes: [{
				type: 'time',
				ticks: {
					autoSkip: true,
					maxTicksLimit: 10
				}
			}],
			plugins: {
				title: {
					display: true,
					text: 'Daily Percentage of Trains\' Modal Split (Trips)'
				},
				legend: {
					position: 'bottom',
				},
			},
		};

		const kmPerDay = {
			xAxes: [{
				type: 'time',
				ticks: {
					autoSkip: true,
					maxTicksLimit: 10
				}
			}],
			plugins: {
				legend: {
					position: 'bottom',
				},
				title: {
					display: true,
					text: 'Daily Traveled Kilometers'
				}
			},
		};

		const numTripsPerDay = {
			xAxes: [{
				type: 'time',
				ticks: {
					autoSkip: true,
					maxTicksLimit: 10
				}
			}],
			plugins: {
				legend: {
					position: 'bottom',
				},
				title: {
					display: true,
					text: 'Daily Number of Trips'
				}
			},
		};

		const dataBar_CO2 = {
			labels: respCO2['date'],
			datasets: [{
				label: 'Tons of CO₂ Emitted by Cars',
				data: respCO2['tons_of_co2_car_pkm'],
				backgroundColor: [
					'rgba(227,42,141,0.2)'
				],
				borderColor: [
					'rgb(227,42,141)'
				],
				borderWidth: 1
			},
			{
				label: 'Tons of CO₂ Emitted by Trains',
				data: respCO2['tons_of_co2_train_pkm'],
				backgroundColor: [
					'rgba(31, 43, 177,0.2)'
				],
				borderColor: [
					'rgb(31, 43, 177)'
				],
				borderWidth: 1
			},
			]
		};

		const CO2_options = {
			xAxes: [{
				type: 'time',
				ticks: {
					autoSkip: true,
					maxTicksLimit: 10
				}
			}],
			plugins: {
				legend: {
					position: 'bottom',
				},
				title: {
					display: true,
					text: 'Daily Tons of CO₂ Emitted'
				}
			},
		};

		return (
			<div>
				<div className='map-3d-button-modal' style={{ bottom: '460px' }}>
					<div className='map-3d-button-modal-inner' onClick={() => this.handleGraphComponent("showGraph")}>
						<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
							<div>
								<VscGraphLine />
							</div>
						</IconContext.Provider>
					</div>
				</div>
				{showGraph ? <>
					<div className="modal-accordion">
						<div className="modal-accordion-inner" style={{  height: '390px' }}>
							<Tabs
								defaultActiveKey="toplocations"
								id="uncontrolled-tab-example"
								className="mb-3"
							>
								{/* <Tab eventKey="Traveledkm" title="Passenger Km">
									{respTraveledKm['origin'] !== 'empty' && respTraveledKm['destination'] === 'empty' ? <>
										<h4>Origin: {origin_name}</h4>
									</> : <>
									</>}
									{respTraveledKm['destination'] !== 'empty' && respTraveledKm['origin'] === 'empty' ? <>
										<h4>Destination: {destination_name}</h4>
									</> : <>
									</>}
									{respTraveledKm['destination'] !== 'empty' && respTraveledKm['origin'] !== 'empty' ? <>
										<h4>Origin: {origin_name} ~ Destination: {destination_name}</h4>
									</> : <>
									</>}
									<Line data={dataBar_TraveledKm} options={kmPerDay} />
								</Tab>
								<Tab eventKey="modalsplitpkm" title="Modal Split (PKm)">
									{respModalPKM['origin'] !== 'empty' && respModalPKM['destination'] === 'empty' ? <>
										<h4>Origin: {origin_name}</h4>
									</> : <>
									</>}
									{respModalPKM['destination'] !== 'empty' && respModalPKM['origin'] === 'empty' ? <>
										<h4>Destination: {destination_name}</h4>
									</> : <>
									</>}
									{respModalPKM['destination'] !== 'empty' && respModalPKM['origin'] !== 'empty' ? <>
										<h4>Origin: {origin_name} ~ Destination: {destination_name}</h4>
									</> : <>
									</>}
									<Line data={dataBar_ModalPKM} options={options} />
								</Tab>
								<Tab eventKey="modalsplittrips" title="Modal Split (Trips)">
									{respModalTrips['origin'] !== 'empty' && respModalTrips['destination'] === 'empty' ? <>
										<h4>Origin: {origin_name}</h4>
									</> : <>
									</>}
									{respModalTrips['destination'] !== 'empty' && respModalTrips['origin'] === 'empty' ? <>
										<h4>Destination: {destination_name}</h4>
									</> : <>
									</>}
									{respModalTrips['destination'] !== 'empty' && respModalTrips['origin'] !== 'empty' ? <>
										<h4>Origin: {origin_name} ~ Destination: {destination_name}</h4>
									</> : <>
									</>}
									<Line data={dataBar_ModalTrips} options={options_trips} />
								</Tab>
								<Tab eventKey="weekdayvsweekends" title="Number of Trips">
									{respWvsW['origin'] !== 'empty' && respWvsW['destination'] === 'empty' ? <>
										<h4>Origin: {origin_name}</h4>
									</> : <>
									</>}
									{respWvsW['destination'] !== 'empty' && respWvsW['origin'] === 'empty' ? <>
										<h4>Destination: {destination_name}</h4>
									</> : <>
									</>}
									{respWvsW['destination'] !== 'empty' && respWvsW['origin'] !== 'empty' ? <>
										<h4>Origin: {origin_name} ~ Destination: {destination_name}</h4>
									</> : <>
									</>}
									<Line data={dataBar_WvsW} options={numTripsPerDay} />
								</Tab>
								<Tab eventKey="co2" title="CO₂ Emissions">
									{respCO2['origin'] !== 'empty' && respCO2['destination'] === 'empty' ? <>
										<h4>Origin: {origin_name}</h4>
									</> : <>
									</>}
									{respCO2['destination'] !== 'empty' && respCO2['origin'] === 'empty' ? <>
										<h4>Destination: {destination_name}</h4>
									</> : <>
									</>}
									{respCO2['destination'] !== 'empty' && respCO2['origin'] !== 'empty' ? <>
										<h4>Origin: {origin_name} ~ Destination: {destination_name}</h4>
									</> : <>
									</>}
									<Line data={dataBar_CO2} options={CO2_options} />
								</Tab> */}
								<Tab eventKey="toplocations" title="Top Origin-Destinations">
									{(respModalTrips['destination'] !== 'empty' && respModalTrips['origin'] !== 'empty')
										?
										<><p className="no-data">Not possible to compute a calculation, when origin and destination are selected</p></>
										:
										<>
										<div className="row">
												<Form onChange={this.schemaTypePKmTrips.bind(this)}>
													<div className="column-3">
														<Form.Check inline label=" Sort based Passenger Km (PKm)" name="type" type='radio' id='pkm' defaultChecked={true} />
													</div>
													<div className="column-3">
														<Form.Check inline label=" Sort based on Number of trips" name="type" type='radio' id='trips' />
													</div>
												</Form>
											</div>

											<div className="row">
												<Form onChange={this.schemaType.bind(this)}>
													<div className="column-33">
														<Form.Check inline label=" Sort based on Cars" name="type" type='radio' id='cars' />
													</div>
													<div className="column-33">
														<Form.Check inline label=" Sort based on Trains" name="type" type='radio' id='trains' defaultChecked={true} />
													</div>
													<div className="column-33">
														<Form.Check inline label=" Sort based on Walking" name="type" type='radio' id='walking' />
													</div>
												</Form>
											</div>
											<div className="map-card-insight-analyzer-modal-inner-scroll" style={{ width: '695px', height: '300px'}}>

											<div className="row">
												<table className="top-od" style={{paddingTop: '20px'}}>
													<thead>
														<tr key='headers'>
															<th className="table-title-od" style={{ fontSize: '14px' }}>GeoHash-6 ID</th>
															<th className="table-title-od" style={{ fontSize: '14px' }}>Origin</th>
															<th className="table-title-od" style={{ fontSize: '14px' }}>Destination</th>
															<th className="table-title-od" style={{ fontSize: '14px' }}>{this.state.pkm_trips==='pkm'? 'PKm' : 'Trips'} Trains</th>
															<th className="table-title-od" style={{ fontSize: '14px' }}>{this.state.pkm_trips==='pkm'? 'PKm' : 'Trips'} Cars</th>
															<th className="table-title-od" style={{ fontSize: '14px' }}>{this.state.pkm_trips==='pkm'? 'PKm' : 'Trips'} Walking</th>
														</tr>
													</thead>
													<tbody>
														{listODTrips}
													</tbody>
												</table>
											</div>
											</div>
										</>
									}
								</Tab>
							</Tabs>
						</div>
					</div>
				</> : <></>}
			</div>
		)
	}
}


//Mobility Insights Analyzer Component - Dropdown Menu and Radio Btn
class MobilityInsightsAnalyzerComponent extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			mapa: this.props.map,
			min_distance_km: 0,
			max_distance_km: 525,

			min_modal: 10,
			max_modal: 100,

			min_num_trips: 0,
			max_num_trips: 1427,

			zoom: this.props.zoom,

			zones_: [{
				"value": "Wien_sud",
				"label": 'Wien Sud'
			},
			{
				"value": "FeldKirchen_bei_Graz",
				"label": 'FeldKirchen bei Graz'
			}],
			db_od_trips_: [{
				"value": '2023-09-18',
				"label": '2023-09-18'
			},
			{
				"value": '2023-09-19',
				"label": '2023-09-19'
			},
			{
				"value": '2023-09-20',
				"label": '2023-09-20'
			},
			{
				"value": '2023-09-21',
				"label": '2023-09-21'
			},
			{
				"value": '2023-09-22',
				"label": '2023-09-22'
			},
			{
				"value": '2023-09-23',
				"label": '2023-09-23'
			},
			{
				"value": '2023-09-24',
				"label": '2023-09-24'
			}],

			value_od_trips: ({
				"value": '2023-09-18',
				"label": '2023-09-18'
			}),

			origin_or_destination: 'origin',
			zone_region: null
		}
this.handleData('2023-09-18', 'origin', null, 0, 525, 0, 1427, 10, 100);
	}

	componentDidMount() {
		this.handleMonthYear = this.handleMonthYear.bind(this);
		this.modalSplitHandler = this.modalSplitHandler.bind(this);
	}

	// async getOptionsDate(url) {
	// 	const res = await axios.get(url)
	// 	const data = res.data
	// 	const options = data.map(d => ({
	// 		"value": d._id.date.toString().split('T')[0].slice(0, -3),
	// 		"label": d._id.date.toString().split('T')[0].slice(0, -3)
	// 	}))
	// 	this.setState({ db_od_trips_: options });
	// 	this.setState({ value_od_trips: options[options.length - 1] });
	// }

	componentDidUpdate(prevProps) {
		if (this.props.map !== prevProps.map) {
			this.setState({ mapa: this.props.map })
		}
	}

	distanceKilometerHandler(event) {
		this.setState({ min_distance_km: event[0], max_distance_km: event[1] })
		this.handleData(this.state.value_od_trips.value, this.state.origin_or_destination, this.state.zone_region, event[0], event[1], this.state.min_num_trips, this.state.max_num_trips, this.state.min_modal, this.state.max_modal);
	}

	numTripsHandler(event) {
		this.setState({ min_num_trips: event[0], max_num_trips: event[1] })
		this.handleData(this.state.value_od_trips.value, this.state.origin_or_destination, this.state.zone_region, this.state.min_distance_km, this.state.max_distance_km, event[0], event[1], this.state.min_modal, this.state.max_modal);
	}

	modalSplitHandler(event) {
		this.setState({ min_modal: event[0], max_modal: event[1] })
		this.handleData(this.state.value_od_trips.value, this.state.origin_or_destination, this.state.zone_region, this.state.min_distance_km, this.state.max_distance_km, this.state.min_num_trips, this.state.max_num_trips, event[0], event[1]);
	}

	handleOriginDestination(event){
		this.setState({ origin_or_destination: event})
		this.handleData(this.state.value_od_trips.value, event, this.state.zone_region, this.state.min_distance_km, this.state.max_distance_km, this.state.min_num_trips, this.state.max_num_trips, this.state.min_modal, this.state.max_modal);
	}

	handleRegion(event){
		this.setState({ zone_region: event})
		this.handleData(this.state.value_od_trips.value, this.state.origin_or_destination, event, this.state.min_distance_km, this.state.max_distance_km, this.state.min_num_trips, this.state.max_num_trips, this.state.min_modal, this.state.max_modal);
	}

	handleData(date, origin_destination, zone_region, min_distance_km, max_distance_km, min_num_trips, max_num_trips, min_ms, max_ms){
		let or = 'all';
		let des = 'all';

		if(zone_region!==null){
			if(origin_destination==='origin'){
				or = zone_region.value
			} else {
				des = zone_region.value
			}
		}

		fetch(URL + 'od_insights/od_insights_geohash6', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
				"date" : date, 
				 "origin":or,
				 "destination":des,
				 "distance_km":[min_distance_km,max_distance_km],
				 "num_trips":[min_num_trips,max_num_trips],
				 "ms_trains":[min_ms,max_ms]
			 }),
        })
            .then((res) => res.json())
            .then((json) => {
				this.state.mapa.getSource('data-swiss-recommendation').setData(json);
			});
		
	}

	handleMonthYear(event) {
		this.setState({ value_od_trips: event })
		this.props.updateField("month_year", event.value);
		this.handleData(event.value, this.state.origin_or_destination, this.state.zone_region, this.state.min_distance_km, this.state.max_distance_km, this.state.min_num_trips, this.state.max_num_trips, this.state.min_modal, this.state.max_modal);
		}

	render() {

		const { origin_or_destination } = this.state;

		const selectStyles = {
			menuList: styles => {
				return {
					...styles,
					maxHeight: 100
				};
			}
		};

		const selectDate = {
			menuList: styles => {
				return {
					...styles,
					maxHeight: 150,
					borderWidth: 0,
					boxShadow: '0 0 10px 2px rgb(0 0 0 / 10%)',
				};
			}
		};

		return (
			<div>
				<div className="map-card-insight-analyzer-modal">
					<div className="map-card-insight-analyzer-modal-inner" style={{ height: '230px' }}>
						<h1>Mobility Insights Analyzer</h1>
							<div>
								<p className='label'>Distance in km</p>
								<div className='insight-analyzer-modal-slider-inner'>
									<Slider range draggableTrack min={0} max={525} step={5} defaultValue={[this.state.min_distance_km, this.state.max_distance_km]} onChange={_.debounce(this.distanceKilometerHandler.bind(this), 33)} />
								</div>
								<p className='label-selected-value'>Showing routes with a distance<br /> between <span>{this.state.min_distance_km}km</span> and <span>{numberWithCommas(this.state.max_distance_km)} </span>km</p>
							</div>
							<div>
								<p className='label'>Number of trips</p>
								<div className='insight-analyzer-modal-slider-inner'>
									<Slider range draggableTrack min={0} max={1427} step={10} defaultValue={[this.state.min_num_trips, this.state.max_num_trips]} onChange={_.debounce(this.numTripsHandler.bind(this), 33)} />
								</div>
								<p className='label-selected-value'>Showing number of trips<br /> between <span>{numberWithCommas(this.state.min_num_trips)}</span> and <span>{numberWithCommas(this.state.max_num_trips)} </span> per day</p>
							</div>
							<div>
								<p className='label'>Modal split for trains</p>
								<div className='insight-analyzer-modal-slider-inner'>
									<Slider range draggableTrack min={0} max={100} step={1} defaultValue={[this.state.min_modal, this.state.max_modal]} onChange={_.debounce(this.modalSplitHandler.bind(this), 33)} />
								</div>
								<p className='label-selected-value'>Showing trips with train's modal split <br /> between <span>{this.state.min_modal}%</span> and <span>{this.state.max_modal}%</span></p>
							</div>
						</div>
				</div>

				<div className='mobility-insights-origin-destionation'style={{ bottom: '260px' }} >
					<div className='mobility-insights-origin-destionation-inner' style={{ height: '150px' }}>
						<h1>Search for a Region</h1>
						<div>
							<div>
							<p className='label' htmlFor="Destination">Set region as...</p>
								<Form onChange={this.handleOriginDestination.bind(this)}>
									<div className="row">
										<div className="column-1-50">
											<Form.Check inline label=" Origin" name="type_aggregation" type='radio' id='origin' defaultChecked={(origin_or_destination === 'origin')} />
										</div>
										<div className="column-1-50">
											<Form.Check inline label=" Destination" name="type_aggregation" type='radio' id='destination' defaultChecked={(origin_or_destination === 'destination')} />
										</div>
									</div>
								</Form>
							</div>
							<div>
								<p className='label' htmlFor="Destination">Select Region...</p>
								<Select className='locations' value={this.state.zone_region} isClearable={true} options={this.state.zones_} onChange={this.handleRegion.bind(this)} styles={selectStyles} />
							</div>
						</div>
					</div>
				</div>

				<div className="map-month-year-picker" style={{width: '350px'}}>
					<Select className='dates' options={this.state.db_od_trips_} onChange={this.handleMonthYear.bind(this)} styles={selectDate} defaultValue={this.state.value_od_trips} />
				</div>
			</div>
		)
	}
}

//Map - Mobility insights Austria
class Austria_MappRecommendationHeatmap extends React.Component {

	// Set up states for updating map 
	constructor(props) {
		super(props);
		this.state = {
			lng: 13.42176,
			lat: 47.64365,
			zoom: 6.5,
			mapita: 9,
			tyle: 'mapbox://styles/mapbox/light-v10',
			selection: 'bfs',
			day_week: 'days',
			month_year: '2023-09-18',
			hasOriginDestination: false,
			graphParam: 'bfs,empty,empty',
			currentDestination: 'empty',
			origin: 0,
			destination: 0,
			hideNavs: false
		}
		this.handleTyle = this.handleTyle.bind(this);
	}

	//Update several fields
	updateFields(list) {
		this.setState(list);
	}

	//Update style
	handleTyle(value) {
		this.setState({ tyle: value }, this.componentDidMount);
	}

	//Update map on component update
	updateField(card, value) {
		this.setState({
			...this.state,
			[card]: value
		});
	}

	//Update coordinates
	handleCoord(center, zoom) {
		const { lng, lat } = center;
		this.setState({ zoom: zoom, lat: lat, lng: lng })
	}

	componentDidMount() {
		// Set up map 
		const map = new mapboxgl.Map({
			container: this.mapContainer,
			style: this.state.tyle,
			center: [this.state.lng, this.state.lat],
			zoom: this.state.zoom,
		});

		map.on('load', () => {
			// Add zoom and rotation controls to the map.
			map.addControl(new mapboxgl.NavigationControl({ showCompass: false, showZoom: false }), 'top-left');
			map.addControl(new MapboxGeocoder({ accessToken: mapboxgl.accessToken, mapboxgl: mapboxgl, marker: false }), 'top-left');			

			//Add lines to the map
			map.addSource('data-swiss-recommendation', {
				'type': 'geojson',
				'data': URL_line,
				'generateId': true,
				'lineMetrics': true
			});

			map.addLayer({
				'id': 'swiss-recommendation',
				'type': 'line',
				'source': 'data-swiss-recommendation',
				'layout': {
					// Make the layer visible by default.
					'visibility': 'visible'
				},
				'paint': {
					'line-color': ['get', 'color'],
					'line-width': ['get', 'line_width'],
				}
			});

		});

		this.setState({
			mapita: map,
			zoom: map.getZoom(),
		});

		// When a click event occurs on a feature in the places layer, open a popup at the
		// location of the feature, with description HTML from its properties.
		map.on('click', 'swiss-recommendation', (e) => {

			let c0 = e.features[0].properties._c0;


			// let origin_lat = e.features[0]._geometry.coordinates[0][1];
			// 			let origin_long = e.features[0]._geometry.coordinates[0][0];
			// 			let destination_lat = e.features[0]._geometry.coordinates[1][1];
			// 			let destination_long = e.features[0]._geometry.coordinates[1][0];

			fetch(URL + "od_insights/detail/" + c0)
				.then((res) => res.json())
				.then((json) => {
						//let google_maps_url = 'https://www.google.com/maps/dir/' + origin_lat + ',' + origin_long + '/' + destination_lat + ',' + destination_long;

						//let html_button = '<a href="' + google_maps_url + '" target="_blank"><button style="background-color: #0c8a8a;color: white;border: 1px solid #e4e4e4;padding: 8px;border-radius: 6px;cursor: pointer;">Google Maps Route</button></a>';

						let cars_percentage = (json[0].properties.ms_car_trips*100).toFixed(0);
						let trains_percentage = (json[0].properties.ms_train_trips*100).toFixed(0);
						let walking_percentage = (json[0].properties.ms_walking_trips*100).toFixed(0);

						let other_percentage = (Number(100) - (Number(cars_percentage) + Number(trains_percentage) + Number(walking_percentage))).toFixed(0);

						let html_modal = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Modal Split</span></p><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle; width: 100%;"><thead><tr><th></th><th style="width:50%; text-align: start; color:#F78948">Car</th><th style="float:right; position:relative, width:50%; color:#BF3A8C;">Walking</th><th style="width:50%; text-align: end;"><span style="float:right; position:relative, width:50%; color:#0fabab;">Trains</span></th></tr></thead><tbody><tr><th></th><th style="width:50%; text-align: start; font-weight: normal;">' + cars_percentage + '%</th><th style="width:50%; text-align: end;"><span style="float:right; position:relative, width:50%; font-weight: normal;">' + walking_percentage + '%</span></th><th style="width:50%; text-align: end;"><span style="float:right; position:relative, width:50%; font-weight: normal;">' + trains_percentage + '%</span></th></tr></tbody></table><span class="block" style="display: block;height: 20px;color: #fff;font-size: 1em;float: left;background-color: #F78948;position: relative;overflow: hidden;width:' + cars_percentage + '%"></span><span class="block" style="display:block; height:20px; color:#fff; font-size:1em; float:left; background-color:#2f3338; position:relative; overflow:hidden; width:' + other_percentage + '%;"></span><span class="block" style="display:block; height:20px; color:#fff; font-size:1em; float:left; background-color:#BF3A8C; position:relative; overflow:hidden; width:' + walking_percentage + '%;"></span><span class="block" style="display:block; height:20px; color:#fff; font-size:1em; float:left; background-color:#0fabab; position:relative; overflow:hidden; width:' + trains_percentage + '%;"></span>';

						let html = '<h3 style="margin-block-start:0px; margin-block-end:0px;">' + json[0].properties.origin_name + ' ~ ' + json[0].properties.destination_name + '</h3><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Daily Passengers</span></p><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle;"><thead><tr><th>Trains</th><th>Cars</th><th>Walk</th><th>Total</th></tr></thead><tbody><tr><td>' + json[0].properties.train_trips + '</td><td>' + json[0].properties.car_trips + '</td><td>' + json[0].properties.walking_trips + '</td><td>' + json[0].properties.total_trips + '</td></tr></tbody></table>';
						let html_pkm = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Daily PKm</span></p><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle;"><thead><tr><th>Trains</th><th>Cars</th><th>Walk</th><th>Total</th></tr></thead><tbody><tr><td>' + (json[0].properties.train_pkm).toFixed(0) + '</td><td>' + (json[0].properties.car_pkm).toFixed(0) + '</td><td>' + (json[0].properties.walking_pkm).toFixed(0) + '</td><td>' + (json[0].properties.total_pkm).toFixed(0) + '</td></tr></tbody></table>';
						new mapboxgl.Popup()
							.setLngLat(e.lngLat)
							.setHTML(html + html_pkm+html_modal).addTo(map);
				})
		});

		// Change the cursor to a pointer when the mouse is over the places layer.
		map.on('mouseenter', 'swiss-recommendation', () => {
			map.getCanvas().style.cursor = 'pointer';

		});

		// Change it back to a pointer when it leaves.
		map.on('mouseleave', 'swiss-recommendation', () => {
			map.getCanvas().style.cursor = '';
		});

		// Change the cursor to a pointer when the mouse is over the places layer.
		map.on('mouseenter', 'data-swiss-origin', () => {
			map.getCanvas().style.cursor = 'pointer';
		});

		// Change it back to a pointer when it leaves.
		map.on('mouseleave', 'data-swiss-origin', () => {
			map.getCanvas().style.cursor = '';
		});

	}

	render() {
		const { hideNavs } = this.state;

		return (
			<div>
				<div ref={el => this.mapContainer = el} style={{ width: '100%', height: '100vh' }}>
				</div>
				<AustriaRecommendationNavBarComponent />
				<HideElementsComponent map={this.state.mapita} updateField={(card, field, value) => this.updateField(card, field, value)} />
				<div style={{ display: hideNavs ? 'none' : 'block' }}>
					<MobilityInsightsAnalyzerComponent map={this.state.mapita} zoom={this.state.zoom} {...this.state} updateField={(card, field, value) => this.updateField(card, field, value)} updateFields={(list) => this.updateFields(list)} />
					<GraphComponent map={this.state.mapita} hasInfo={this.state.hasOriginDestination} selection={this.state.selection} graphParam={this.state.graphParam} month_year={this.state.month_year} />
				</div>
				<LayerTylesComponent map={this.state.mapita} tyle={this.state.tyle} handleTyle={(value) => this.handleTyle(value)} handleCoord={(value1, value2) => this.handleCoord(value1, value2)} />
			</div>
		)
	}
}

export default Austria_MappRecommendationHeatmap;