import React from "react";
import Plot from 'react-plotly.js';  // Import Plotly component
import { createContext, useContext } from 'react';
import { useParams, useLocation } from "react-router-dom";

const LocationContext = createContext();
let data_body = '';

const URL = process.env.REACT_APP_URL_SERVER_BACK;

function LocationProvider({ children }) {
  const location = useLocation();
  data_body = location.state;
  return (
    <LocationContext.Provider value={location}>
      {children}
    </LocationContext.Provider>
  );
}

class Sankey extends React.Component {

    static contextType = LocationContext;

  constructor(props) {
    super(props);
    this.state = {
      nodes_links: {
        nodes: {},
        links: {}
      },
      layout: {
        title: "B2B - Bus Sankey Diagram",
        font: {
          size: 20
        },
        // Adjust margins to maximize space usage
          height: window.innerHeight, // Set to window height dynamically
          width: window.innerWidth,   // Set to window width dynamically
      }
    };
  }

  componentDidMount() {
    // Fetch data after component mounts
    this.handleData();
  }

  handleData() {
    fetch(URL + 'bus_ai/sankey_diagram', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data_body)
    })
    .then((res) => res.json())
    .then((json) => {
      // Update the state with fetched data
      this.setState({ nodes_links: json });
    })
    .catch((error) => {
      console.error("Error fetching Sankey data:", error);
    });
  }

  render() {
    console.log(data_body);

    return (
      <div style={{ width: "100%", height: "100vh" }}>
        <LocationProvider>
          {/* Render Plotly Sankey diagram */}
          <div>
            ignhignf
          </div>
          <Plot
            data={[{
                type: "sankey",
                orientation: "h",
                node: {
                  pad: 50,
                  thickness: 20,
                  line: {
                    color: "black",
                    width: 0.5
                  },
                 label: this.state.nodes_links.nodes?.label,
                    },
              
                link: this.state.nodes_links.links
              }]}
            layout={this.state.layout}
            style={{ width: "100%", height: "100%" }}
          />
        </LocationProvider>
      </div>
    );
  }
}

export default Sankey;
