import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Navigate } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

import './css/login.css';


const Login = () => {
  const { loginWithRedirect, isAuthenticated, error, user } = useAuth0();

  const handleLogin = async () => {
    await loginWithRedirect({
      prompt: "login",
      appState: {
        returnTo: "/menu",
      },
    });
  };

  if (isAuthenticated) {
    const userType = user?.email?.split('@')[1];

    console.log(userType)

    if (userType.includes('oebb')) {
      return <Navigate to="/austria/menu" />;
    } 
    else if(userType.includes('bls')) {
      return <Navigate to="/BLS" />;
    }
    else if(userType.includes('sob')) {
      return <Navigate to="/SOB" />;
    }
    else if (user?.email === 'daniel.oehry@hilti.com') {
      return <Navigate to="/hilti/menu" />;
    } else {
      return <Navigate to="/menu" />;
    }
  }
  else if (error) {
    return <Navigate to='/access-denied' />;
  }
  else {
    return (
      <div className="main-div">
        <div className="main-div-inner">
          <img src={require('../assets/img/ov42-logo-main.png')} alt="Logo ÖV42 by 42hacks" className='image-logo' />
          <p className="motto">42% modal split by AI-powered smart mobility</p>
          <div className="row">
            <button className='btn-login' onClick={handleLogin}>Login</button>
          </div>
          <p className="powered-by">Powered by <img src={require('../assets/img/swisscom-logo.png')} alt="Swisscom" className='image-swisscom' /> <span className="swisscom-font"></span></p>
        </div>
      </div>
    );
  }
};

export default Login;