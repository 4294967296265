import React from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { Form, Button } from 'react-bootstrap';
import '../css/co2_cockpit.css'

const lines_geojson = {
    'type': 'FeatureCollection',
    'features': [
        {
            'type': 'Feature',
            'geometry': {
                "type": "LineString",
                "coordinates": [
                ]
            }
        }
    ]
};


//CO2_Welcome_Screen - Main view
class CO2_Welcome_Screen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            lng: 8.251313,
            lat: 46.924143,
            zoom: 7,
            mapita: 9,
            tyle: 'mapbox://styles/mapbox/light-v11'
        }
    }

    componentDidMount() {
        // Set up map 
        const map = new mapboxgl.Map({
            container: this.mapContainer,
            style: this.state.tyle,
            center: [this.state.lng, this.state.lat],
            zoom: this.state.zoom,
            interactive: false

        });

        map.on('load', () => {
            // Add zoom and rotation controls to the map.
            map.addControl(new mapboxgl.NavigationControl({ showCompass: false, showZoom: false }), 'top-left');

            map.addSource('data-municipality-selected', {
                'type': 'geojson',
                'data': lines_geojson,
                'generateId': true
            });

            map.addLayer({
                'id': 'data-municipality-selected',
                'type': 'fill',
                'source': 'data-municipality-selected',
                'layout': {},
                'paint': {
                    'fill-color': '#53b8ae',
                    'fill-opacity': 0.4
                }
            });

            // Add a black outline around the polygon
            map.addLayer({
                'id': 'outline-data-municipality-selected',
                'type': 'line',
                'source': 'data-municipality-selected',
                'layout': {},
                'paint': {
                    'line-color': '#53b8ae',
                    'line-width': 1
                }
            });



        });

        this.setState({
            mapita: map,
            zoom: map.getZoom(),
        });
    }


    loadOptions = (inputValue, callback) => {
        setTimeout(() => {
            fetch(URL + 'potential_routes/search_engine', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ query: inputValue })
            })
                .then((resp) => {
                    return resp.json();
                })
                .then((data) => {
                    const tempArray = [];
                    if (data) {
                        if (data.length) {
                            data.forEach((element) => {
                                tempArray.push(element);
                            });
                        }
                    }
                    callback(tempArray);
                })
                .catch((error) => {
                    console.log(error, "catch the hoop");
                });
        }, 1000);
    };

    render() {
        const selectDate = {
            menuList: styles => {
                return {
                    ...styles,
                    maxHeight: 150,
                    borderWidth: 0,
                    boxShadow: '0 0 10px 2px rgb(0 0 0 / 10%)',
                };
            }
        };

        return (
            <div>
                <div className="header-co2-insights">
                    <div className="header-co2-insights-inner">
                        <div className="row-1">
                            <div className="column-40">
                                <div className="row" style={{ justifyContent: 'center', paddingTop: '20vh' }}>
                                    <div className='image-main-menu'>
                                        <img src={require('../../assets/img/logo-42hacks-mobility.png')} alt="Logo Mobility by 42hacks" style={{ height: '15vh' }} />
                                    </div>
                                </div>
                                <div className="row" style={{ justifyContent: 'center' }}>
                                    <p className="label">
                                        Empowering of cities and regions with actionable <br />data and  insights to reduce global emissions
                                    </p>
                                </div>
                                <div className="row" style={{ justifyContent: 'center',  paddingTop: '10vh', paddingLeft: '50px', paddingRight: '50px'}}>
                                {/* <AsyncSelect noOptionsMessage={({inputValue}) => !inputValue ? "Write an agency name" : "No results found"}  className='agency' placeholder='Select an agency...' styles={selectDate} cacheOptions loadOptions={this.loadOptions} value={selected_options_agency} onChange={this.handleOptionAgency.bind(this)} isOptionDisabled={() => this.state.selected_options_agency.length >= 4} /> */}
                                <AsyncSelect noOptionsMessage={({ inputValue }) => !inputValue ? "Write the name of a municipality" : "No results found"} className='municipalities' placeholder='Write the name of a municipality...' styles={selectDate} cacheOptions isLoading={true} loadOptions={this.loadOptions} />
                            </div>
                            <div className="row" style={{ justifyContent: 'left', textAlign: 'left', paddingLeft: '50px', paddingRight: '50px'}}>
                                <p className="label-small">Explore Cities and Municipalities...</p>                           
                                     </div>
                                     <div className="row" style={{ justifyContent: 'center', paddingTop: '10vh' }}>
                            <Form>
										<Button variant="info" className="submit-btn">Explore </Button>
									</Form>
                            </div>
                            </div>
                            
                            <div className="column-60">
                                <div ref={el => this.mapContainer = el} style={{ width: '100%', height: '90vh' }}>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export default CO2_Welcome_Screen;