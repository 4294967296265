import React from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import '../css/map-recommendation.css';
import {IconContext} from "react-icons";
import axios from 'axios';
import Select from 'react-select';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import Chart from 'chart.js/auto';
import { BsLayersHalf } from "react-icons/bs";
import { Bar } from 'react-chartjs-2';
import RecommendationNavBarComponent from "../../components/RecommendationNavBarComponent";

import '../../components/layer-tyles.css';
import '../css/mapbox-gl-geocoder.css';
import '../css/map-recommendation-deckgl.css';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const URL = process.env.REACT_APP_URL_SERVER_BACK;

const _ = require('lodash');

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
}

let URL_line = URL+"train_vs_cars/2022-07,plz/days,0,0,0,-2000,2000,0,400000,220,182000,0,100,1,0,1,10";
let URL_selected_line = URL+"heatmap_t_vs_c/plz_heatmap/2022-07,bfs/empty,empty";


//Layer Tyles Selection - Side Menu
class LayerTylesComponent extends React.Component{

	constructor(props){
		super(props)
		this.state ={
			showOptions: false,
			mapa: this.props.map,
		}
		this.hideComponent = this.hideComponent.bind(this);
		this.handleTyleEvent = this.handleTyleEvent.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (this.props.map !== prevProps.map) {
		  this.setState({mapa: this.props.map})
		}
	}

	hideComponent(name)
	{
		switch (name) {
			case "showOptions":
			  this.setState({ showOptions: !this.state.showOptions });
			  break;
			default:
			  // eslint-disable-next-line no-unused-expressions
			  null;
		}
	}

	handleTyleEvent(event) {
		switch (event) {
			case "default":
				this.props.handleTyle("mapbox://styles/mapbox/light-v10");
				break;
			case "outdoors":
				this.props.handleTyle("mapbox://styles/mapbox/outdoors-v11");
				break;
			case "satellite":
				this.props.handleTyle("mapbox://styles/mapbox/satellite-streets-v11");
				break;
			case "streets":
				this.props.handleTyle("mapbox://styles/mapbox/streets-v11");
				break;
			case "dark":
				this.props.handleTyle("mapbox://styles/mapbox/dark-v10");
				break;
			default:
				// eslint-disable-next-line no-unused-expressions
				null;
		}
		this.props.handleCoord(this.state.mapa.getCenter(), this.state.mapa.getZoom());
	}

	render(){
		const { showOptions } = this.state;

		return(
			<div>
				{showOptions ? <>
				<div id='layer-options' className='map-layer-options-tyles'>
					<div className='map-layer-option-dark' onClick={() => this.handleTyleEvent("dark")}>
						<IconContext.Provider value={{ color: "#5B5B5B", size:'1.6em'}}>
							<div>
								<img src={require('../../assets/img/Dark.png')} alt="Default tyle" className='img-tyle'/>
                        	</div>
                    	</IconContext.Provider>
						<p className='label'>Dark</p>
					</div>
					<div className='map-layer-option-streets' onClick={() => this.handleTyleEvent("streets")}>
						<IconContext.Provider value={{ color: "#5B5B5B", size:'1.6em'}}>
							<div>
								<img src={require('../../assets/img/Streets.png')} alt="Default tyle" className='img-tyle'/>
                        	</div>
                    	</IconContext.Provider>
						<p className='label'>Streets</p>
					</div>
					<div className='map-layer-option-satellite' onClick={() => this.handleTyleEvent("satellite")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size:'1.6em'}}>
                        	<div>
								<img src={require('../../assets/img/Satellite.png')} alt="Default tyle" className='img-tyle'/>
                        	</div>
                   		 	</IconContext.Provider>
							<p className='label'>Satellite</p>
					</div>
					<div className='map-layer-option-outdoors' onClick={() => this.handleTyleEvent("outdoors")}>
						<IconContext.Provider value={{ color: "#5B5B5B", size:'1.6em'}}>
							<div>
								<img src={require('../../assets/img/Outdoors.png')} alt="Default tyle" className='img-tyle'/>
                        	</div>
                    	</IconContext.Provider>
						<p className='label'>Outdoors</p>
					</div>
					<div className='map-layer-option-default' onClick={() => this.handleTyleEvent("default")}>
						<IconContext.Provider value={{ color: "#5B5B5B", size:'1.6em'}}>
                        	<div>
								<img src={require('../../assets/img/White.png')} alt="Default tyle" className='img-tyle'/>
                        	</div>
                    	</IconContext.Provider>
						<p className='label'>Default</p>
					</div>
				</div>
				</> : <></>}
				<div className='map-layer-tyles'>
                    <div className='map-layer-tyles-inner' onClick={() => this.hideComponent("showOptions")}>
					<IconContext.Provider value={{ color: "#5B5B5B", size:'1.8em'}}>
                        <div>
                         <BsLayersHalf />
                        </div>
                    </IconContext.Provider>
                </div>
			</div>
		</div>
		)
	}
}

class InsightsComponent extends React.Component
{
	constructor(props){
		super(props)
		this.state ={
			mapa: this.props.map,
			hasOriginDestination: this.props.hasOriginDestination,
			origin: this.props.origin,
			destination: this.props.destination,
			currentC0: this.props.currentC0,
			month_year: this.props.month_year,
			day_of_week: this.props.day_of_week,
			hour_of_day: this.props.hour_of_day,
			percentile_array: [],
			resp: '',
		}
	}

	componentDidMount(){
		this.handleFetchData = this.handleFetchData.bind(this);
	}

	componentDidUpdate(prevProps) {
		if(this.props.map !== prevProps.map) {
		  this.setState({mapa: this.props.map});
		}
		if(this.props.hasInfo !== prevProps.hasInfo) {
			this.setState({hasInfo: this.props.hasInfo});
		}
		if(this.props.origin !== prevProps.origin || this.props.destination !== prevProps.destination || this.props.currentC0 !== prevProps.currentC0
		|| this.props.month_year !== prevProps.month_year || this.props.day_of_week !== prevProps.day_of_week || this.props.hour_of_day !== prevProps.hour_of_day){
			this.setState({graphParam: this.props.graphParam});
			this.handleFetchData(this.props.origin, this.props.destination, this.props.currentC0, this.props.month_year, this.props.day_of_week, this.props.hour_of_day);
		}
	}

	async handleFetchData(origin, destination, currentC0, month_year, day_of_week, hour_of_day){
		if(origin !==0 && destination !==0){
			if(currentC0 !== 0 && day_of_week !=='' && hour_of_day !== 0)
			{
				let data;
				fetch(URL + 'point-to-point/insights', {
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({ _c0: currentC0, date: month_year, day_of_week: day_of_week, hour_of_day: hour_of_day, divisions: 'stops' })
				}).then((res) => res.json())
				.then((json) => { data = json;
				}).then(() => { console.log('stops', data); this.state.mapa.getSource('data-swiss-selected-circles').setData(data) });

				fetch(URL + 'point-to-point/insights', {
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({ _c0: currentC0, date: month_year, day_of_week: day_of_week, hour_of_day: hour_of_day, divisions: 'routes' })
				}).then((res) => res.json())
				.then((json) => { 
					data = json;
					console.log('lines',data)	
				}).then(() => { this.state.mapa.getSource('data-swiss-selected-line').setData(data); this.state.mapa.getSource('data-swiss-recommendation').setData(URL+"heatmap_t_vs_c/plz_heatmap/2022-07,bfs/empty,empty") });
			
				fetch(URL + 'point-to-point/trips', {
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({ _c0: currentC0, date: month_year, day_of_week: day_of_week, hour_of_day: hour_of_day})
				})
				.then((res) => res.json())
				.then((json) => {
					if(json.length !== 0){this.setState({resp: json, percentile_array: [json[0].travel_time_p1, json[0].travel_time_p25, json[0].travel_time_p50, json[0].travel_time_p75, json[0].travel_time_p99]}); }
					else{ this.setState({resp: ''}); }
			})
			}
			
		}else{
			this.setState({resp: ''});
		}
			
	}

	render(){
		const { resp } = this.state;

		const dataBar_Percentile = {
			labels: ['1', '25', '50', '75', '99'],
			datasets: [{
			  label: 'Travelling time in minutes',
			  data: this.state.percentile_array,
			  backgroundColor: [
				'rgba(247, 137, 72, 0.2)'
			  ],
			  borderColor: [
				'rgb(247, 137, 72)'
			  ],
			  borderWidth: 1
			},
		  ]
		};

		const options = {
			xAxes: [{
				type: 'Percentile',
				ticks: {
					autoSkip: true,
					maxTicksLimit: 4
				}
			}],
			plugins: {
				legend: {
				  position: 'bottom',
				},
				title: {
					display: true,
					text: 'Percentile for Travel Time',
				  },
			},
		};

		return(
				<div>
					<div className='map-p-t-p-insights'>
						<div className='map-p-t-p-insights-inner'>
							<h3>Point-to-Point Travel Time Insights</h3>
							{resp!=='' ? <>
								<h4>Origin: {resp[0]['from_id']} ~ Destination: {resp[0]['to_id']}</h4>
								<div className="p-t-p-table-insigths">
										<div className="row">
											<div className="column-1">
												<h4>Total trip time</h4>
											</div>
											<div className="column-2">
												<h4>{numberWithCommas(resp[0].total_time.toFixed(0))} minutes</h4>
											</div>
										</div>
										<div className="row">
											<div className="column-1">
												<h4>Initial waiting time</h4>
											</div>
											<div className="column-2">
												<h4>{numberWithCommas(resp[0].access_time.toFixed(0))}  minutes</h4>
											</div>
										</div>
										<div className="row">
											<div className="column-1">
												<h4>Total waiting time</h4>
											</div>
											<div className="column-2">
												<h4>{numberWithCommas(resp[0].wait_times.reduce((partialSum, a) => partialSum + a, 0))} minutes</h4>
											</div>
										</div>
									</div>
									<Bar data= {dataBar_Percentile} options={options}/>
								</> : <>
									<p className="no-data">Please select an origin, destination, day of the week and an hour of the day</p>
							</>}
						</div>
					</div>
				</div>
		)
	}
}

//Mobility Insights Analyzer Component - Dropdown Menu and Radio Btn
class MobilityInsightsAnalyzerComponent extends React.Component{

	constructor(props) {
		super(props)
    	this.state = {
			mapa: this.props.map,
            division: 'plz',
            origin: 0,
            destination: 0,
			zoom: this.props.zoom,
			hasDayOfWeek: true,
			selected_day_of_week: '',
			selected_hour_of_day: 0,
			current_c0: 0,
			plz_: this.getOptionsPLZ(URL+'plz-list'),
			db_od_trips_: this.getOptionsDate(URL+'db_od_trips'),
			value_od_trips: ({
				"value" : "2022-07",
				"label" : '2022-07'
			  }),
			options_day_of_week_: [({
				"value" : "Mon",
				"label" : 'Monday'
			}),
			({
				"value" : "Wed",
				"label" : 'Wednesday'
			}),
			({
				"value" : "Fri",
				"label" : 'Friday'
			}),
			({
				"value" : "Sun",
				"label" : 'Sunday'
			})],
			options_hour_of_day_: [],
			options_hour_of_day_weekdays_: [({
				"value" : 7,
				"label" : '7:00 am'
			}),
			({
				"value" : 12,
				"label" : '12:00 pm'
			}),
			({
				"value" : 17,
				"label" : '5:00 pm'
			}),
			({
				"value" : 20,
				"label" : '8:00 pm'
			})],
			options_hour_of_day_weekends_: [({
				"value" : 10,
				"label" : '10:00 am'
			}),
			({
				"value" : 17,
				"label" : '5:00 pm'
			})],
			  valueClear: ({
				"value" : "",
				"label" : ''
			  })
    	}
	}

	componentDidMount(){
		this.handleChange = this.handleChange.bind(this);
		this.handleChangeOrigin = this.handleChangeOrigin.bind(this);
		this.handleChangeDestination = this.handleChangeDestination.bind(this);
		this.handleChangeDayOfWeek = this.handleChangeDayOfWeek.bind(this);
		this.handleChangeHourOfDay = this.handleChangeHourOfDay.bind(this);
		this.handleMonthYear = this.handleMonthYear.bind(this);
	}

	async getOptionsPLZ(url){
		const res = await axios.get(url)
		const data = res.data
		const options = data.map(d => ({
		  "value" : d._id.PLZ,
		  "label" : d._id.Name +" ("+d._id.PLZ+")"
		}))
		this.setState({plz_: options})
	}

	async getOptionsDate(url){
		const res = await axios.get(url)
		const data = res.data
		const options = data.map(d => ({
		  "value" : d._id.date.toString().split('T')[0].slice(0,-3),
		  "label" : d._id.date.toString().split('T')[0].slice(0,-3)
		}))
		this.setState({db_od_trips_: options});
		//this.setState({value_od_trips: options[options.length-1]});
	}

	componentDidUpdate(prevProps) {
		if (this.props.map !== prevProps.map) {
		  this.setState({mapa: this.props.map})
		}
	  }

	handleChange(e)
    {
		URL_line=URL+'train_vs_cars/{0},plz/days,0,{1},{2},-2000,2000,0,400000,220,182000,0,100,1,0,1,10'.format(this.state.value_od_trips.value, this.state.origin, this.state.destination);
		this.state.mapa.getSource('data-swiss-recommendation').setData(URL_line);
	}


    handleChangeOrigin(event) 
    {
		let origin = 0;
		event == null ? origin = 0 : origin = event.value;
		this.setState({origin: origin})
		let c0_selected = 'empty';
		if(this.state.destination===0 && origin===0){
			URL_line=URL+'train_vs_cars/{0},plz/days,0,{1},{2},-2000,2000,0,400000,220,182000,0,100,1,0,1,10'.format(this.state.value_od_trips.value, origin, this.state.destination);
			this.setState({current_c0: 'empty'});
		}else{
			URL_line=URL+'train_vs_cars/{0},plz/days,0,{1},{2},-2000,2000,0,400000,50,182000,0,100,1,0,1,10'.format(this.state.value_od_trips.value, origin, this.state.destination);
			if(this.state.destination!==0 && origin!==0){
				fetch(URL_line)
					.then((res) => res.json())
					.then((json) => {
						if (json.features[0].hasOwnProperty('properties')){
							this.setState({current_c0: json.features[0].properties._c0});
							c0_selected = json.features[0].properties._c0;
						}
				})
			}
		}
		this.state.mapa.getSource('data-swiss-recommendation').setData(URL_line);

		let hasInfo = false;
		(this.state.destination === 0 || origin === 0) ? hasInfo = false : hasInfo = true;
		
		this.props.updateFields({hasOriginDestination: hasInfo, origin: origin, destination: this.state.destination, currentC0: c0_selected, month_year: this.state.value_od_trips.value, day_of_week: this.state.selected_day_of_week, hour_of_day: this.state.selected_hour_of_day})
    }
    
    handleChangeDestination(event)
    {
		let destination = 0;
		event == null ? destination = 0 : destination = event.value;
		this.setState({destination: destination})
		let c0_selected = 'empty';
		if(destination===0 && this.state.origin===0){
			URL_line=URL+'train_vs_cars/{0},plz/days,0,{1},{2},-2000,2000,0,400000,220,182000,0,100,1,0,1,10'.format(this.state.value_od_trips.value, this.state.origin, destination);
			this.setState({current_c0: 'empty'});
		}
		else{
			URL_line=URL+'train_vs_cars/{0},plz/days,0,{1},{2},-2000,2000,0,400000,50,182000,0,100,1,0,1,10'.format(this.state.value_od_trips.value, this.state.origin, destination);
			if(destination!==0 && this.state.origin!==0){
				fetch(URL_line)
					.then((res) => res.json())
					.then((json) => {
						if (json.features[0].hasOwnProperty('properties')){
							console.log('current c0 found', json.features[0].properties._c0)
							this.setState({current_c0: json.features[0].properties._c0});
							c0_selected = json.features[0].properties._c0;
						}
				})
			}
		}
		this.state.mapa.getSource('data-swiss-recommendation').setData(URL_line);

		let hasInfo = false;
		(destination === 0 || this.state.origin === 0) ? hasInfo = false : hasInfo = true;

		this.props.updateFields({hasOriginDestination: hasInfo, origin: this.state.origin, destination: destination, currentC0: c0_selected, month_year: this.state.value_od_trips.value, day_of_week: this.state.selected_day_of_week, hour_of_day: this.state.selected_hour_of_day})
    }

	handleMonthYear(event)
	{
		this.setState({value_od_trips: event});
		this.props.updateField("month_year", event.value);
	}

	handleChangeDayOfWeek(event){
		let value;
		if(event!==null){
			this.setState({hasDayOfWeek: false})
			if(event.value!=='Sun'){
				this.setState({options_hour_of_day_: this.state.options_hour_of_day_weekdays_})
			}
			else{
				this.setState({options_hour_of_day_: this.state.options_hour_of_day_weekends_});
			}
			this.setState({selected_day_of_week: event.value})
			value = event.value;

		}
		else{
			this.setState({hasDayOfWeek: true});
			this.setState({selected_day_of_week: ''});
			value = '';

		}
		this.props.updateFields({hasOriginDestination: this.state.hasInfo, origin: this.state.origin, destination: this.state.destination, currentC0: this.state.current_c0, month_year: this.state.value_od_trips.value, day_of_week: value, hour_of_day: this.state.selected_hour_of_day})

	}

	handleChangeHourOfDay(event){
		let value;
		if(event!==null){
			this.setState({selected_hour_of_day: event.value})
			value = event.value;
		}else{
			this.setState({selected_hour_of_day: 0})
			value = 0;
		}
		this.props.updateFields({hasOriginDestination: this.state.hasInfo, origin: this.state.origin, destination: this.state.destination, currentC0: this.state.current_c0, month_year: this.state.value_od_trips.value, day_of_week: this.state.selected_day_of_week, hour_of_day: value})
	}

	render(){

		const selectStyles = {
			menuList: styles => {
			  return {
				...styles,
				maxHeight: 100
			  };
			}
		  };

		const selectDate = {
			menuList: styles => {
			  return {
				...styles,
				maxHeight: 150,
				borderWidth: 0,
				boxShadow: '0 0 10px 2px rgb(0 0 0 / 10%)',
			  };
			}
		  };


		return(
        <div>
		<div className='point-to-point-origin-destionation'>
          <div className='point-to-point-origin-destionation-inner'>
            <h1>Search for an specific trip</h1>
					<div>
						<div>
							<p className='label' htmlFor="Origin">Origin</p>
							<Select className='locations' isClearable={true} options={this.state.plz_} onChange={this.handleChangeOrigin.bind(this)} styles={selectStyles} />
						</div>
						<div>
							<p className='label' htmlFor="Destination">Destination</p>
							<Select className='locations' isClearable={true} options={this.state.plz_} onChange={this.handleChangeDestination.bind(this)} styles={selectStyles} />
						</div>
					</div>  
          </div>
        </div>
		<div className='point-to-point-dayweek-time'>
          <div className='point-to-point-dayweek-time-inner'>
            <h1>Filtering Scope</h1>
					<div>
						<div>
							<p className='label' htmlFor="Day of the week">Day of the week</p>
							<Select className='locations' isClearable={true} options={this.state.options_day_of_week_} onChange={this.handleChangeDayOfWeek.bind(this)} styles={selectStyles} />
						</div>
						<div>
							<p className='label' htmlFor="Hour of the day">Hour of the day</p>
							<Select className='locations' isClearable={true} options={this.state.options_hour_of_day_} isDisabled={this.state.hasDayOfWeek} onChange={this.handleChangeHourOfDay.bind(this)} styles={selectStyles} />
						</div>
					</div>  
          </div>
        </div>

		<div className="map-month-year-picker">
			<Select className='dates' options={this.state.db_od_trips_} onChange={this.handleMonthYear.bind(this)} styles={selectDate} defaultValue={this.state.value_od_trips}/>
		</div>
	</div>
		)
	}
}

//Map - Point to point Travel Time Insights
class MappPoint_to_Point extends React.Component{

	// Set up states for updating map 
	constructor(props){
		super(props);
		this.state = {
			lng:8.251313,
			lat: 46.924143,
			zoom: 7,
			mapita:9,
			tyle: 'mapbox://styles/mapbox/light-v10',
			selection: 'plz',
			day_week: 'days',
			month_year: '2022-07',
			hasOriginDestination: false,
			graphParam: 'empty,empty',
			currentDestination: 'empty',
			currentC0: 'empty',
			origin: 0,
			destination: 0,
			day_of_week: null, 
			hour_of_day: null
		}
		this.handleTyle = this.handleTyle.bind(this);
		URL_line = URL+"train_vs_cars/2022-07,plz/days,0,0,0,-2000,2000,0,400000,220,182000,0,100,1,0,1,10";
	}


	//Update several fields
	updateFields(list) {
		this.setState(list);
	}

	//Update map on component update
	updateField(card, value) {
		this.setState({
            ...this.state,
            [card]: value
        });
	}
	
	//Update style
	handleTyle(value) {
		this.setState({tyle: value}, this.componentDidMount);
	}

	//Update coordinates
    handleCoord(center, zoom){
        const {lng, lat} = center;
        this.setState({zoom: zoom, lat: lat, lng: lng})
    }

	componentDidMount() {
		// Set up map 
		const map = new mapboxgl.Map({
			container: this.mapContainer,
			style: this.state.tyle,
			center: [this.state.lng, this.state.lat],
			zoom: this.state.zoom, 
		});

		map.on('load', () => {
			// Add zoom and rotation controls to the map.
			map.addControl(new mapboxgl.NavigationControl({showCompass: false, showZoom: false}), 'top-left');
			map.addControl( new MapboxGeocoder({ accessToken: mapboxgl.accessToken, mapboxgl: mapboxgl, marker: false}), 'top-left' );
			
			// Initial lines
			map.addSource('data-swiss-recommendation', {
				'type': 'geojson',
				'data': URL_line,
				'generateId': true,	
				'lineMetrics': true
			});
			map.addLayer({
				'id': 'swiss-recommendation',
				'type': 'line',
				'source': 'data-swiss-recommendation',
				'layout': {},
				'paint': {
				'line-color': ['get', 'color'],
				'line-width': ['get', 'line_width'],
				}
				});

			// Selected Line
			map.addSource('data-swiss-selected-line', {
				'type': 'geojson',
				'data': URL_selected_line,
				'generateId': true,	
				'lineMetrics': true
			});
			map.addLayer({
				'id': 'swiss-selected-line',
				'type': 'line',
				'source': 'data-swiss-selected-line',
				'layout': {},
				'paint': {
				'line-color': '#0FABAB',
				'line-width': 2,
				}
				});
			
			// Selected Stops
			map.addSource('data-swiss-selected-circles', {
				'type': 'geojson',
				'data': URL_selected_line,
				'generateId': true,	
				'lineMetrics': true
			});
			map.addLayer({
				'id': 'swiss-selected-circles',
				'type': 'circle',
				'source': 'data-swiss-selected-circles',
				'layout': {},
				'paint': {
					'circle-radius': 3,
					"circle-stroke-width": 1,
					"circle-stroke-color": '#eb34b1',
					'circle-color': '#eb34b1',
					"circle-opacity": 0.5,
				},
				});
		});

		this.setState({
			mapita: map,
			zoom: map.getZoom(),
		  });
				  
	// When a click event occurs on a feature in the places layer, open a popup at the
	// location of the feature, with description HTML from its properties.
	map.on('click', 'swiss-recommendation', (e) => {

		fetch(URL+"train_vs_cars/detail/"+this.state.month_year+','+this.state.selection+"/"+e.features[0].properties._c0)
			.then((res) => res.json())
				.then((json) => {
		// Copy coordinates array.
		const coordinates = json.geometry.coordinates.slice();
		// Ensure that if the map is zoomed out such that multiple
		// copies of the feature are visible, the popup appears
		// over the copy being pointed to.
		  while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
		  coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
		  }

		  let num_passengers_car_wd = json.properties.car_trips_weekdays;
		  let num_passengers_car_we = json.properties.car_trips_weekend;
		  let num_passengers_train_wd = json.properties.train_trips_weekdays;
		  let num_passengers_train_we = json.properties.train_trips_weekend;
		  let num_passengers_we_total = json.properties.total_trips_weekend;
		  let num_passengers_wd_total = json.properties.total_trips_weekdays;

		  let num_passengers_car_wd_str ='';
		  let num_passengers_car_we_str ='';
		  let num_passengers_train_wd_str ='';
		  let num_passengers_train_we_str ='';
		  let num_passengers_we_total_str ='';
		  let num_passengers_wd_total_str ='';	  

		  (typeof num_passengers_car_wd == 'number' & num_passengers_car_wd>0) ? num_passengers_car_wd = (num_passengers_car_wd).toFixed(0) : num_passengers_car_wd = '-';
		  (num_passengers_car_wd !== '-') ? num_passengers_car_wd_str = numberWithCommas(num_passengers_car_wd): num_passengers_car_wd_str = '-';
		  
		  (typeof num_passengers_car_we == 'number' & num_passengers_car_we>0) ? num_passengers_car_we = (num_passengers_car_we).toFixed(0) : num_passengers_car_we = '-';
		  (num_passengers_car_we !== '-') ? num_passengers_car_we_str = numberWithCommas(num_passengers_car_we): num_passengers_car_we_str = '-';
		  
		  (typeof num_passengers_train_wd == 'number' & num_passengers_train_wd>0) ? num_passengers_train_wd = (num_passengers_train_wd).toFixed(0) : num_passengers_train_wd = '-';
		  (num_passengers_train_wd !== '-') ? num_passengers_train_wd_str = numberWithCommas(num_passengers_train_wd): num_passengers_train_wd_str = '-';

		  (typeof num_passengers_train_we == 'number' & num_passengers_train_we>0) ? num_passengers_train_we = (num_passengers_train_we).toFixed(0) : num_passengers_train_we = '-';
		  (num_passengers_train_we !== '-') ? num_passengers_train_we_str = numberWithCommas(num_passengers_train_we): num_passengers_train_we_str = '-';

		  (typeof num_passengers_wd_total == 'number' & num_passengers_wd_total>0) ? num_passengers_wd_total = (num_passengers_wd_total).toFixed(0) : num_passengers_wd_total = '-';
		  if(num_passengers_wd_total < (num_passengers_train_wd+num_passengers_car_wd) & num_passengers_wd_total !== '-'){
			num_passengers_wd_total = Number(num_passengers_train_wd) + Number(num_passengers_car_wd);
		  }
		  (num_passengers_wd_total !== '-') ? num_passengers_wd_total_str = numberWithCommas(num_passengers_wd_total): num_passengers_wd_total_str = '-';

		  (typeof num_passengers_we_total == 'number' & num_passengers_we_total>0) ? num_passengers_we_total = (num_passengers_we_total).toFixed(0) : num_passengers_we_total = '-';
		  (num_passengers_we_total !== '-') ? num_passengers_we_total_str = numberWithCommas(num_passengers_we_total): num_passengers_we_total_str = '-';

            let end_station = json.properties.destination_name;
            let start_station = json.properties.origin_name;

			let html_id_plz = '';
			if(this.state.selection === 'plz')
			{
				html_id_plz = '<h4 style="margin-block-start:0px; margin-block-end:0px;"> Postal code: '+json.properties.origin+' ~ '+json.properties.destination+'</h4>';
			}

			let html_modal = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Modal Split</span></p><p style="margin-block-end: 0em;"><span style="font-weight: semibold;">No data available</span></p>';

			let cars_percentage = 0;
			let trains_percentage = 0;
			if(this.state.day_week === 'days')
			{
				cars_percentage = (Number(json.properties.modal_split_cars_weekdays)*100).toFixed(0);
				trains_percentage = (Number(json.properties.modal_split_trains_weekdays)*100).toFixed(0);
			}
			else {
				cars_percentage = (Number(json.properties.modal_split_cars_weekends)*100).toFixed(0);
				trains_percentage = (Number(json.properties.modal_split_trains_weekends)*100).toFixed(0);
			}

			let other_percentage = (Number(100) - (Number(cars_percentage) + Number(trains_percentage))).toFixed(0);
			
			if((num_passengers_wd_total !== '-') & (num_passengers_car_wd !== '-') & (num_passengers_train_wd !== '-'))
			{
				html_modal = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Modal Split</span></p><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle; width: 100%;"><thead><tr><th></th><th style="width:50%; text-align: start;">Car/Bus</th><th style="width:50%; text-align: end;"><span style="float:right; position:relative, width:50%">Trains</span></th></tr></thead><tbody><tr><th></th><th style="width:50%; text-align: start; font-weight: normal;">'+cars_percentage+'%</th><th style="width:50%; text-align: end;"><span style="float:right; position:relative, width:50%; font-weight: normal;">'+trains_percentage+'%</span></th></tr></tbody></table><span class="block" style="display: block;height: 20px;color: #fff;font-size: 1em;float: left;background-color: #F78948;position: relative;overflow: hidden;width:'+cars_percentage+'%"></span><span class="block" style="display:block; height:20px; color:#fff; font-size:1em; float:left; background-color:#2f3338; position:relative; overflow:hidden; width:'+other_percentage+'%;"></span><span class="block" style="display:block; height:20px; color:#fff; font-size:1em; float:left; background-color:#0fabab; position:relative; overflow:hidden; width:'+trains_percentage+'%;"></span>';
			}

			let html = '<h3 style="margin-block-start:0px; margin-block-end:0px;">'+start_station+' ~ '+end_station+'</h3>'+html_id_plz+'<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Passengers per day</span></p><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle;"><thead><tr><th></th><th>&#x1f46a; &#128664;</th><th>&#x1f46a; &#x1f686;</th><th>Total</th></tr></thead><tbody><tr><th>Weekdays</th><td>'+num_passengers_car_wd_str+'</td><td>'+num_passengers_train_wd_str+'</td><td>'+num_passengers_wd_total_str+'</td></tr><tr><th>Weekends</th><td>'+num_passengers_car_we_str+'</td><td>'+num_passengers_train_we_str+'</td><td>'+num_passengers_we_total_str+'</td></tr></tbody></table>';
			new mapboxgl.Popup()
		  		.setLngLat(e.lngLat)
		  		.setHTML(html+html_modal).addTo(map);
			})
		});
		
		// Change the cursor to a pointer when the mouse is over the places layer.
		map.on('mouseenter', 'swiss-recommendation', () => {
		map.getCanvas().style.cursor = 'pointer';

		});
		
		// Change it back to a pointer when it leaves.
		map.on('mouseleave', 'swiss-recommendation', () => {
		map.getCanvas().style.cursor = '';
		});


		map.on('click', 'swiss-selected-circles', (e) => {

			console.log('event',e.features[0].properties)

				let start_station = e.features[0].properties.stop_name
				let html_waiting =''
				if(typeof e.features[0].properties.waiting_time !== 'undefined' ){
					let html_id_plz = e.features[0].properties.waiting_time
					html_waiting = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Waiting time</span></p>'+html_id_plz+' minutes';	
				}

				let html = '<h2 style="margin-block-start:0px; margin-block-end:0px;">Station</h2><h3 style="margin-block-start:0px; margin-block-end:0px;">'+start_station+'</h3>';
				new mapboxgl.Popup()
					  .setLngLat(e.lngLat)
					  .setHTML(html+html_waiting).addTo(map);
			});
			
			// Change the cursor to a pointer when the mouse is over the places layer.
			map.on('mouseenter', 'swiss-selected-circles', () => {
			map.getCanvas().style.cursor = 'pointer';
	
			});
			
			// Change it back to a pointer when it leaves.
			map.on('mouseleave', 'swiss-selected-circles', () => {
			map.getCanvas().style.cursor = '';
			});

			map.on('click', 'swiss-selected-circles', (e) => {

			console.log('event',e.features[0].properties)

				let start_station = e.features[0].properties.stop_name
				let html_waiting =''
				if(typeof e.features[0].properties.waiting_time !== 'undefined' ){
					let html_id_plz = e.features[0].properties.waiting_time
					html_waiting = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Waiting time</span></p>'+html_id_plz+' minutes';	
				}

				let html = '<h2 style="margin-block-start:0px; margin-block-end:0px;">Station</h2><h3 style="margin-block-start:0px; margin-block-end:0px;">'+start_station+'</h3>';
				new mapboxgl.Popup()
					  .setLngLat(e.lngLat)
					  .setHTML(html+html_waiting).addTo(map);
			});
			
			// Change the cursor to a pointer when the mouse is over the places layer.
			map.on('mouseenter', 'swiss-selected-circles', () => {
			map.getCanvas().style.cursor = 'pointer';
	
			});
			
			// Change it back to a pointer when it leaves.
			map.on('mouseleave', 'swiss-selected-circles', () => {
			map.getCanvas().style.cursor = '';
			});


			//Lines
			map.on('click', 'swiss-selected-line', (e) => {

				console.log('event',e.features[0].properties)
	
					let start_station = e.features[0].properties.agency_name
					
					let html_id_plz = e.features[0].properties.travel_time
					let html_waiting = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Travel time</span></p>'+html_id_plz+' minutes';	
	
					let html = '<h2 style="margin-block-start:0px; margin-block-end:0px;">Agency Name</h2><h3 style="margin-block-start:0px; margin-block-end:0px;">'+start_station+'</h3>';
					new mapboxgl.Popup()
						  .setLngLat(e.lngLat)
						  .setHTML(html+html_waiting).addTo(map);
				});
				
				// Change the cursor to a pointer when the mouse is over the places layer.
				map.on('mouseenter', 'swiss-selected-line', () => {
				map.getCanvas().style.cursor = 'pointer';
		
				});
				
				// Change it back to a pointer when it leaves.
				map.on('mouseleave', 'swiss-selected-line', () => {
				map.getCanvas().style.cursor = '';
				});
		

	}

	render(){
		return(
			<div>
				<div ref = {el => this.mapContainer = el} style = {{width:'100%',height:'100vh'}}>
				</div>
				<RecommendationNavBarComponent/>
				<MobilityInsightsAnalyzerComponent map = {this.state.mapita} zoom ={this.state.zoom} {...this.state} updateField={(card, field, value) => this.updateField(card, field, value)} updateFields={(list) => this.updateFields(list)}/>
                <LayerTylesComponent map = {this.state.mapita} tyle ={this.state.tyle} handleTyle={(value) => this.handleTyle(value)} handleCoord={(value1, value2) => this.handleCoord(value1, value2)}/>
				<InsightsComponent map={this.state.mapita} hasOriginDestination={this.state.hasOriginDestination} origin={this.state.origin} destination={this.state.destination} currentC0={this.state.currentC0} month_year={this.state.month_year} day_of_week={this.state.day_of_week} hour_of_day={this.state.hour_of_day}/>
			</div>
		)
	}
}

export default MappPoint_to_Point;