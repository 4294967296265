import React from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import axios from 'axios';
import { IconContext } from "react-icons";
import { BsLayersHalf } from "react-icons/bs";
import RecommendationNavBarComponent from "../../components/RecommendationNavBarComponent";
import Chart from 'chart.js/auto';
import { Bubble } from 'react-chartjs-2';
import Slider from 'rc-slider';
import { Button, Form } from 'react-bootstrap';
import '../css/map-areas2focus.css';
import Select from 'react-select';
import { Link } from "react-router-dom";
import { RiFullscreenFill, RiFullscreenExitFill } from "react-icons/ri";


mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const URL = process.env.REACT_APP_URL_SERVER_BACK;

let typeOfTile = 'tile1km';
let dayofweek = 'weekdays';

const _ = require('lodash');

function getMax(arr) {
	var max = 0;
	for (var i = 0; i < arr.length; i++) {
		if (parseInt(arr[i]) > parseInt(max))
			max = arr[i];
	}
	return max;
}

function numberWithCommas(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
}

let tile_empty = {
	'type': 'FeatureCollection',
	'features': [
		{
			'type': 'Feature',
			'geometry': {
				"type": "LineString",
				"coordinates": [
				]
			}
		}
	]
};

let tile_1km_geojson = tile_empty;
let tile_1km_visibility = 'visible';
let tile_100m_geojson = tile_empty;
let tile_100m_visibility = 'none';
let tile_building_geojson = tile_empty;
let tile_building_visibility = 'none';
let top_tile_geojson = tile_empty;
let top_tile_visibility = 'visible';


class HideElementsComponent extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			hideNavs : false,
			mapa: this.props.map,
		}
	}

	hideComponent() {
		let newHideNavs = !this.state.hideNavs;
		this.setState({hideNavs: newHideNavs})
		this.props.updateField("hideNavs", newHideNavs);
	}

	render() {

		const { hideNavs } = this.state;

		return (
			<div>
				{hideNavs ? <>
					<div className='map-close-navs'>
						<div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
								<div>
									<RiFullscreenExitFill />
								</div>
							</IconContext.Provider>
						</div>
					</div>
				</> : <>
					<div className='map-close-navs'>
						<div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
								<div>
									<RiFullscreenFill />
								</div>
							</IconContext.Provider>
						</div>
					</div>
				</>}
			</div>
		)
	}
}

//Layer Tyles Selection - Side Menu
class LayerTylesComponent extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			showOptions: false,
			mapa: this.props.map,
		}
		this.hideComponent = this.hideComponent.bind(this);
		this.handleTyleEvent = this.handleTyleEvent.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (this.props.map !== prevProps.map) {
			this.setState({ mapa: this.props.map })
		}
	}

	hideComponent(name) {
		switch (name) {
			case "showOptions":
				this.setState({ showOptions: !this.state.showOptions });
				break;
			default:
				// eslint-disable-next-line no-unused-expressions
				null;
		}
	}

	handleTyleEvent(event) {
		switch (event) {
			case "default":
				this.props.handleTyle("mapbox://styles/mapbox/light-v10");
				break;
			case "outdoors":
				this.props.handleTyle("mapbox://styles/mapbox/outdoors-v11");
				break;
			case "satellite":
				this.props.handleTyle("mapbox://styles/mapbox/satellite-streets-v11");
				break;
			case "streets":
				this.props.handleTyle("mapbox://styles/mapbox/streets-v11");
				break;
			case "dark":
				this.props.handleTyle("mapbox://styles/mapbox/dark-v10");
				break;
			default:
				// eslint-disable-next-line no-unused-expressions
				null;
		}
		this.props.handleCoord(this.state.mapa.getCenter(), this.state.mapa.getZoom());
	}

	render() {
		const { showOptions } = this.state;

		return (
			<div>
				{showOptions ? <>
					<div id='layer-options' className='map-layer-options-tyles'>
						<div className='map-layer-option-dark' onClick={() => this.handleTyleEvent("dark")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/Dark.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Dark</p>
						</div>
						<div className='map-layer-option-streets' onClick={() => this.handleTyleEvent("streets")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/Streets.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Streets</p>
						</div>
						<div className='map-layer-option-satellite' onClick={() => this.handleTyleEvent("satellite")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/Satellite.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Satellite</p>
						</div>
						<div className='map-layer-option-outdoors' onClick={() => this.handleTyleEvent("outdoors")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/Outdoors.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Outdoors</p>
						</div>
						<div className='map-layer-option-default' onClick={() => this.handleTyleEvent("default")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/White.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Default</p>
						</div>
					</div>
				</> : <></>}
				<div className='map-layer-tyles'>
					<div className='map-layer-tyles-inner' onClick={() => this.hideComponent("showOptions")}>
						<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
							<div>
								<BsLayersHalf />
							</div>
						</IconContext.Provider>
					</div>
				</div>
			</div>
		)
	}
}

class BtnsComponent extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			mapa: this.props.map,
			category_building: this.props.category_building,
			generatedTyles: false,
			distance_km: 400,
			showInfo: false,
			dataOVQvsTraffic: this.handleFetchData(1000, 35000, 1000000, 4000000, 'country', 'all', 0, 1, 'all', 0, 1, 0.8, "weekdays"),
			max_y: 4000000,
			max_x: 35000,
			min_x: 1000,
			min_y: 1000000,
			aggregation: 'country',
			min_modal: 0,
			max_modal: 100,
			min_pct_traffic: 0,
			max_pct_traffic: 100,
			isPLZ: false,
			isCountry: true,
			plz_: this.getOptionsPLZ(URL + 'plz-list'),
			kantons_: this.getOptionsKantons(URL + 'what_if/kanton_list'),
			type_building_: this.getOptionTypeBuilding(URL + 'areas_to_focus/building_categories'),
			current_value_k: ({
				"value": "SG",
				"label": 'SG'
			}),
			current_value_plz: ({
				"value": "9000",
				"label": 'St. Gallen (9000)'
			}),
			current_value_type_building: ({
				"value": "all",
				"label": 'All'
			}),
			current_pkm: 0,
			total_pkm: 0,
			current_trips: 0,
			total_trips: 0,
			percentage_pkm: 0,
			percentage_trips: 0,
			total_percentage_pkm: 0,
			total_percentage_trips: 0,
			percentage: 80,
			day_week: 'weekdays'
		}
		this.handleTopTiles(1000, 35000, 1000000, 4000000, 'country', "all", 0, 1, 'all', 0, 1, 0.8, "weekdays");
		this.handlePKMandTrips(1000, 35000, 1000000, 4000000, 'country', 'all', 0, 1, 'all', 0, 1, 0.8, "weekdays")
	}

	componentDidMount() {
		this.hideComponent = this.hideComponent.bind(this);
		this.handleFetchData = this.handleFetchData.bind(this);
		this.handler_y_axis = this.handler_y_axis.bind(this);
		this.handler_x_axis = this.handler_x_axis.bind(this);
		this.handler_percentages_pkm = this.handler_percentages_pkm.bind(this);
		this.handleAdjustGraph = this.handleAdjustGraph.bind(this);
		this.handler_modal_split = this.handler_modal_split.bind(this);
		this.handler_type_selection = this.handler_type_selection.bind(this);
		this.handleNumAggr = this.handleNumAggr.bind(this);
		this.handler_type_tile = this.handler_type_tile.bind(this);
		this.handleTopTiles = this.handleTopTiles.bind(this);
		this.handler_type_tile_no_event = this.handler_type_tile_no_event.bind(this);
		this.handleTypeBuilding = this.handleTypeBuilding.bind(this);
		this.handlePKMandTrips = this.handlePKMandTrips.bind(this);
		this.schemaDayWeekHandle = this.schemaDayWeekHandle.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (this.props.map !== prevProps.map) {
			this.setState({ mapa: this.props.map });
		}
		if (this.props.category_building !== prevProps.category_building) {
			this.setState({ category_building: this.props.category_building });
		}
	}

	async getOptionsPLZ(url) {
		const res = await axios.get(url)
		const data = res.data
		const options = data.map(d => ({
			"value": d._id.PLZ,
			"label": d._id.Name + " (" + d._id.PLZ + ")"
		}))
		this.setState({ plz_: options })
	}

	async getOptionTypeBuilding(url) {
		const res = await axios.get(url)
		const data = res.data
		const options = data.map(d => ({
			"value": d.category,
			"label": (d.category === null ? null : d.category.split('_').join(' '))
		}))
		this.setState({ type_building_: options });
	}

	async getOptionsKantons(url) {
		const res = await axios.get(url)
		const data = res.data
		const options = data.map(d => ({
			"value": d._id,
			"label": d._id
		}))
		this.setState({ kantons_: options });
	}

	async handleFetchData(min_x, max_x, min_y, max_y, aggregation, num_agg, min_modal, max_modal, category, min_pct_traffic, max_pct_traffic, percentage, day_week) {
		fetch(URL + 'areas_to_focus/chart', {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				"range_x": [min_x, max_x],
				"range_y": [min_y, max_y],
				"aggregation": aggregation,
				"num_agg": num_agg,
				"modal_split": [min_modal, max_modal],
				"pct_traffic_below_750m_to_station": [min_pct_traffic, max_pct_traffic],
				"category": category,
				"pctg_total_pkm": percentage,
				"day_of_week": day_week
			}),
		}).then((res) => res.json())
			.then((json) => {
				this.setState({ dataOVQvsTraffic: json });
				this.props.updateField("coord", [])
			});
	}

	handlePKMandTrips(min_x, max_x, min_y, max_y, aggregation, num_agg, min_modal, max_modal, category, min_pct_traffic, max_pct_traffic, percentage, day_week) {
		fetch(URL + 'areas_to_focus/total_vs_filtered', {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				"range_x": [min_x, max_x],
				"range_y": [min_y, max_y],
				"aggregation": aggregation,
				"num_agg": num_agg,
				"modal_split": [min_modal, max_modal],
				"pct_traffic_below_750m_to_station": [min_pct_traffic, max_pct_traffic],
				"category": category,
				"pctg_total_pkm": percentage,
				"top_n": 100,
				"day_of_week": day_week
			}),
		}).then((res) => res.json())
			.then((json) => {
				this.setState({ current_pkm: numberWithCommas(json.current_pkm.toFixed(0)), total_pkm: numberWithCommas(json.total_pkm.toFixed(0)), percentage_pkm: ((json.current_pkm / json.total_pkm * 100).toFixed(1)), current_trips: numberWithCommas(json.current_trips.toFixed(0)), total_trips: numberWithCommas(json.total_trips.toFixed(0)), percentage_trips: ((json.current_trips / json.total_trips * 100).toFixed(1)), total_percentage_pkm: ((json.pkm_before_percentage / json.total_pkm * 100).toFixed(1)), total_percentage_trips: ((json.trips_before_percentage / json.total_trips * 100).toFixed(1)) });
			});
	}

	handleTypeBuilding(event) {
		if (event == null) {
			this.setState({
				current_value_type_building: ({
					"value": "all",
					"label": 'All'
				})
			})
			this.props.updateField('category_building', 'all')
		}
		else {
			this.setState({ current_value_type_building: event })
			this.props.updateField('category_building', event.value)
		}
	}

	handler_type_selection(event) {
		if (event.target.attributes.getNamedItem('id').value === 'plz') {
			this.setState({ aggregation: event.target.attributes.getNamedItem('id').value, isPLZ: true, isCountry: false })
		}
		else {
			if (event.target.attributes.getNamedItem('id').value === 'kanton') {
				this.setState({ aggregation: event.target.attributes.getNamedItem('id').value, isPLZ: false, isCountry: false })
			}
			else if (event.target.attributes.getNamedItem('id').value === 'country') {
				this.setState({ aggregation: event.target.attributes.getNamedItem('id').value, isPLZ: false, isCountry: true })
			}
		}
	}


	handleTopTiles(min_x, max_x, min_y, max_y, aggregation, num_agg, min_modal, max_modal, category, min_pct_traffic, max_pct_traffic, percentage, day_week) {
		fetch(URL + 'top_areas_to_focus/tiles', {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				"range_x": [min_x, max_x],
				"range_y": [min_y, max_y],
				"aggregation": aggregation,
				"num_agg": num_agg,
				"modal_split": [min_modal, max_modal],
				"top_n": 100,
				"category": category,
				"pctg_total_pkm": percentage,
				"pct_traffic_below_750m_to_station": [min_pct_traffic, max_pct_traffic],
				"day_of_week": day_week
			}),
		}).then((res) => res.json())
			.then((json) => {
				this.props.map.getSource('data-swiss-top-tyles').setData(json);
				top_tile_geojson = json;
			})
	}

	schemaDayWeekHandle(event) {
		this.setState({ day_week: event.target.attributes.getNamedItem('id').value })
		dayofweek = event.target.attributes.getNamedItem('id').value
	}

	handler_y_axis(event) {
		this.setState({ min_y: event[0], max_y: event[1] })
	}

	handler_x_axis(event) {
		this.setState({ min_x: event[0], max_x: event[1] })
	}

	handler_percentages_pkm(event) {
		this.setState({ percentage: event })
	}

	handler_modal_split(event) {
		this.setState({ min_modal: event[0], max_modal: event[1] })
	}

	handler_pct_traffic_750_sation(event) {
		this.setState({ min_pct_traffic: event[0], max_pct_traffic: event[1] })
	}

	handleNumAggr(event) {
		if (event == null && this.state.isPLZ === false) {
			this.setState({
				current_value_k: ({
					"value": "SG",
					"label": 'SG'
				})
			})
		}
		else if (event == null && this.state.isPLZ === true) {
			this.setState({
				current_value_plz: ({
					"value": "9000",
					"label": 'St. Gallen (9000)'
				})
			})
		}
		else {
			if (this.state.isPLZ === true) {
				this.setState({ current_value_plz: event })
			}
			else {
				this.setState({ current_value_k: event })
			}
		}
	}

	handleAdjustGraph() {
		this.handleFetchData(this.state.min_x, this.state.max_x, this.state.min_y, this.state.max_y, this.state.aggregation, (this.state.aggregation === 'plz' ? this.state.current_value_plz.value : (this.state.aggregation === 'kanton' ? this.state.current_value_k.value : 'all')), (this.state.min_modal / 100), (this.state.max_modal / 100), this.props.category_building, (this.state.min_pct_traffic / 100), (this.state.max_pct_traffic / 100), (this.state.percentage / 100), this.state.day_week)
		this.handleTopTiles(this.state.min_x, this.state.max_x, this.state.min_y, this.state.max_y, this.state.aggregation, (this.state.aggregation === 'plz' ? this.state.current_value_plz.value : (this.state.aggregation === 'kanton' ? this.state.current_value_k.value : 'all')), (this.state.min_modal / 100), (this.state.max_modal / 100), this.props.category_building, (this.state.min_pct_traffic / 100), (this.state.max_pct_traffic / 100), (this.state.percentage / 100), this.state.day_week)
		this.handlePKMandTrips(this.state.min_x, this.state.max_x, this.state.min_y, this.state.max_y, this.state.aggregation, (this.state.aggregation === 'plz' ? this.state.current_value_plz.value : (this.state.aggregation === 'kanton' ? this.state.current_value_k.value : 'all')), (this.state.min_modal / 100), (this.state.max_modal / 100), this.props.category_building, (this.state.min_pct_traffic / 100), (this.state.max_pct_traffic / 100), (this.state.percentage / 100), this.state.day_week)
		this.handle_type_tile_adjust_chart();
	}

	hideComponent() {
		this.setState({ showInfo: !this.state.showInfo });
	}

	handleIdTyle(id) {

		let id_DF = this.state.dataOVQvsTraffic.tileId[id]

		fetch(URL + 'areas_to_focus/tile/' + id_DF)
			.then((res) => res.json())
			.then((json) => {
				const tyle_corner = {
					center: [((json.features[0].geometry.coordinates[0][1][0] + json.features[0].geometry.coordinates[0][2][0]) / 2), ((json.features[0].geometry.coordinates[0][2][1] + json.features[0].geometry.coordinates[0][3][1]) / 2)],
					zoom: 14.5,
					pitch: 0,
					bearing: 0
				}
				this.props.handleCoordMoveToTyle(((json.features[0].geometry.coordinates[0][1][0] + json.features[0].geometry.coordinates[0][2][0]) / 2), ((json.features[0].geometry.coordinates[0][2][1] + json.features[0].geometry.coordinates[0][3][1]) / 2), 14.5, json)
				this.props.map.flyTo({
					...tyle_corner, // Fly to the selected target
					duration: 2000, // Animate over 2 seconds
					essential: false // This animation is considered essential with
					//respect to prefers-reduced-motion
				})
				this.props.map.getSource('data-swiss-tyle').setData(json);
				this.props.updateField('tileId_mdb', json.features[0].properties.tileId)
				let coordTemp = [((json.features[0].geometry.coordinates[0][1][0] + json.features[0].geometry.coordinates[0][2][0]) / 2), ((json.features[0].geometry.coordinates[0][2][1] + json.features[0].geometry.coordinates[0][3][1]) / 2)];
				this.props.updateField("coord", coordTemp)

				this.props.map.setLayoutProperty('data-swiss-top-tyles', 'visibility', 'none');
				this.props.map.setLayoutProperty('outline-data-swiss-top-tyles', 'visibility', 'none');
				tile_1km_geojson = URL + 'areas_to_focus/tile/' + id_DF;
				this.handler_type_tile_no_event();
			});

		tile_100m_geojson = URL + 'areas_to_focus/100m_tile_grid/' + this.state.day_week + '/' + id_DF;
		this.props.map.getSource('data-swiss-tyle-100m').setData(tile_100m_geojson);

		let category = (this.state.current_value_type_building.value).replace('+')
		tile_building_geojson = URL + 'areas_to_focus/return_buildings/' + id_DF + '/' + category;

		this.props.map.getSource('data-swiss-buldings-tile').setData(tile_building_geojson);

	}

	handler_type_tile(event) {
		typeOfTile = event.target.attributes.getNamedItem('id').value;
		if (typeOfTile === 'tile100m') {
			this.props.map.setLayoutProperty('data-swiss-tyle', 'visibility', 'none');
			this.props.map.setLayoutProperty('outline-data-swiss-tyle', 'visibility', 'none');
			this.props.map.setLayoutProperty('data-swiss-tyle-100m', 'visibility', 'visible');
			this.props.map.setLayoutProperty('outline-data-swiss-tyle-100m', 'visibility', 'visible');
			this.props.map.setLayoutProperty('data-swiss-tyle-100m-labels', 'visibility', 'visible');
			this.props.map.setLayoutProperty('data-swiss-top-tyles', 'visibility', 'none');
			this.props.map.setLayoutProperty('outline-data-swiss-top-tyles', 'visibility', 'none');
			this.props.map.setLayoutProperty('data-swiss-buldings-tile', 'visibility', 'none');
			this.props.map.setLayoutProperty('outline-data-swiss-buldings-tile', 'visibility', 'none');

			tile_1km_visibility = 'none';
			tile_100m_visibility = 'visible';
			tile_building_visibility = 'none';
			top_tile_visibility = 'none';
		}
		else if (typeOfTile === 'tile1km') {
			this.props.map.setLayoutProperty('data-swiss-tyle', 'visibility', 'visible');
			this.props.map.setLayoutProperty('outline-data-swiss-tyle', 'visibility', 'visible');
			this.props.map.setLayoutProperty('data-swiss-tyle-100m', 'visibility', 'none');
			this.props.map.setLayoutProperty('outline-data-swiss-tyle-100m', 'visibility', 'none');
			this.props.map.setLayoutProperty('data-swiss-tyle-100m-labels', 'visibility', 'none');
			this.props.map.setLayoutProperty('data-swiss-buldings-tile', 'visibility', 'none');
			this.props.map.setLayoutProperty('outline-data-swiss-buldings-tile', 'visibility', 'none');

			tile_1km_visibility = 'visible';
			tile_100m_visibility = 'none';
			tile_building_visibility = 'none';
			top_tile_visibility = 'none';
		}
		else if (typeOfTile === 'tilebulding') {
			this.props.map.setLayoutProperty('data-swiss-buldings-tile', 'visibility', 'visible');
			this.props.map.setLayoutProperty('outline-data-swiss-buldings-tile', 'visibility', 'visible');
			this.props.map.setLayoutProperty('data-swiss-tyle', 'visibility', 'none');
			this.props.map.setLayoutProperty('outline-data-swiss-tyle', 'visibility', 'none');
			this.props.map.setLayoutProperty('data-swiss-tyle-100m', 'visibility', 'none');
			this.props.map.setLayoutProperty('outline-data-swiss-tyle-100m', 'visibility', 'none');
			this.props.map.setLayoutProperty('data-swiss-tyle-100m-labels', 'visibility', 'none');

			tile_1km_visibility = 'none';
			tile_100m_visibility = 'none';
			tile_building_visibility = 'visible';
			top_tile_visibility = 'none';
		}
	}

	handle_type_tile_adjust_chart() {
		this.props.map.setLayoutProperty('data-swiss-top-tyles', 'visibility', 'visible');
		this.props.map.setLayoutProperty('outline-data-swiss-top-tyles', 'visibility', 'visible');
		this.props.updateField("coord", [])

		tile_1km_geojson = tile_empty;
		tile_100m_geojson = tile_empty;
		tile_building_geojson = tile_empty;
		this.props.map.getSource('data-swiss-tyle-100m').setData(tile_100m_geojson);
		this.props.map.getSource('data-swiss-tyle').setData(tile_1km_geojson);
		this.props.map.getSource('data-swiss-buldings-tile').setData(tile_building_geojson);

		tile_1km_visibility = 'visible';
		top_tile_visibility = 'visible';
	}

	handler_type_tile_no_event() {
		if (typeOfTile === 'tile100m') {
			this.props.map.setLayoutProperty('data-swiss-tyle', 'visibility', 'none');
			this.props.map.setLayoutProperty('outline-data-swiss-tyle', 'visibility', 'none');
			this.props.map.setLayoutProperty('data-swiss-tyle-100m', 'visibility', 'visible');
			this.props.map.setLayoutProperty('outline-data-swiss-tyle-100m', 'visibility', 'visible');
			this.props.map.setLayoutProperty('data-swiss-tyle-100m-labels', 'visibility', 'visible');
			this.props.map.setLayoutProperty('data-swiss-top-tyles', 'visibility', 'none');
			this.props.map.setLayoutProperty('outline-data-swiss-top-tyles', 'visibility', 'none');
			this.props.map.setLayoutProperty('data-swiss-buldings-tile', 'visibility', 'none');
			this.props.map.setLayoutProperty('outline-data-swiss-buldings-tile', 'visibility', 'none');

			tile_1km_visibility = 'none';
			tile_100m_visibility = 'visible';
			tile_building_visibility = 'none';
			top_tile_visibility = 'none';
		}
		else if (typeOfTile === 'tile1km') {
			this.props.map.setLayoutProperty('data-swiss-tyle', 'visibility', 'visible');
			this.props.map.setLayoutProperty('outline-data-swiss-tyle', 'visibility', 'visible');
			this.props.map.setLayoutProperty('data-swiss-tyle-100m', 'visibility', 'none');
			this.props.map.setLayoutProperty('outline-data-swiss-tyle-100m', 'visibility', 'none');
			this.props.map.setLayoutProperty('data-swiss-tyle-100m-labels', 'visibility', 'none');
			this.props.map.setLayoutProperty('data-swiss-buldings-tile', 'visibility', 'none');
			this.props.map.setLayoutProperty('outline-data-swiss-buldings-tile', 'visibility', 'none');

			tile_1km_visibility = 'visible';
			tile_100m_visibility = 'none';
			tile_building_visibility = 'none';
			top_tile_visibility = 'none';

		}
		else if (typeOfTile === 'tilebulding') {
			this.props.map.setLayoutProperty('data-swiss-buldings-tile', 'visibility', 'visible');
			this.props.map.setLayoutProperty('outline-data-swiss-buldings-tile', 'visibility', 'visible');
			this.props.map.setLayoutProperty('data-swiss-tyle', 'visibility', 'none');
			this.props.map.setLayoutProperty('outline-data-swiss-tyle', 'visibility', 'none');
			this.props.map.setLayoutProperty('data-swiss-tyle-100m', 'visibility', 'none');
			this.props.map.setLayoutProperty('outline-data-swiss-tyle-100m', 'visibility', 'none');
			this.props.map.setLayoutProperty('data-swiss-tyle-100m-labels', 'visibility', 'none');

			tile_1km_visibility = 'none';
			tile_100m_visibility = 'none';
			tile_building_visibility = 'visible';
			top_tile_visibility = 'none';
		}
	}

	render() {

		const { dataOVQvsTraffic } = this.state;
		const { current_pkm } = this.state;
		const { current_trips } = this.state;
		const { total_pkm } = this.state;
		const { total_trips } = this.state;
		const { percentage_pkm } = this.state;
		const { percentage_trips } = this.state;
		const { total_percentage_pkm } = this.state;
		const { total_percentage_trips } = this.state;

		const selectStyles = {
			menuList: styles => {
				return {
					...styles,
					maxHeight: 100
				};
			}
		};

		const graphic1 = {
			labels: dataOVQvsTraffic['distance_to_zug_m'],
			datasets: [{
				label: 'Tiles',
				data: dataOVQvsTraffic['total_pkm'],
				backgroundColor: dataOVQvsTraffic["color"],
				borderColor: '#000000',
				borderWidth: 0.3
			}
			]
		};

		const optionsGraphic1 = {
			scales: {
				x: {
					title: {
						display: true,
						text: 'Distance to train station (m)'
					}
				},
				y: {
					title: {
						display: true,
						text: ' PKm per month'
					}
				},
			},
			plugins: {
				legend: {
					display: false,
				},
				tooltip: {
					callbacks: {
						title: (tooltipItem, data) => {
							let index = tooltipItem[0]?.dataIndex;
							if (dataOVQvsTraffic['building_class'][index] === 'yes' || dataOVQvsTraffic['building_class'][index] === null) {
								return "Category: mixed";
							}
							else {
								return "Category: " + dataOVQvsTraffic['building_class'][index];
							}
						}
					}
				},
			},
			onClick: (event, elements) => {
				if (elements.length > 0) {
					this.handleIdTyle(elements[0].index)
				}
			}
		};

		return (
			<div>
				<div className="map-areas2focus-pkm-value">
					<div className="map-areas2focus-pkm-value-inner">
						<h1>Showing {current_pkm} PKm per month</h1>
						<h3>Total {total_pkm} PKm per month</h3>
						<h4>{percentage_pkm}% out of {total_percentage_pkm}% of the PKm per month</h4>
						<hr />
						<h1>Showing {current_trips} trips per day</h1>
						<h3>Total {total_trips} trips per day</h3>
						<h4>{percentage_trips}% out of {total_percentage_trips}% of the trips per day</h4>
					</div>
				</div>
				<div className="map-graph-ovq-vs-traffic">
					<div className="map-graph-ovq-vs-traffic-inner">
						<h1>Distance to train station (m) vs. PKm per month</h1>
						<p className="label">Number of filtered tiles: {dataOVQvsTraffic['color']?.length}</p>
						<Bubble data={graphic1} options={optionsGraphic1} height={"220%"} />
						<div className="visualization-tile">
							<h1>Visualization Tile Type</h1>
							<Form onChange={this.handler_type_tile.bind(this)}>
								<div className="row">
									<div className="column-3-1">
										<Form.Check inline className='check-label' label=" 1km tile" name="type_analysis" type='radio' id='tile1km' defaultChecked={true} />
									</div>
									<div className="column-3-2">
										<Form.Check inline className='check-label' label=" 100m tiles' grid" name="type_analysis" type='radio' id='tile100m' />
									</div>
									<div className="column-3-3">
										<Form.Check inline className='check-label' label=" Buildings within the tile" name="type_analysis" type='radio' id='tilebulding' />
									</div>
								</div>
							</Form>
						</div>
						<div className="row-parameters">
							<div className="column-33">
								<Link to={'/drop-a-point/' + this.props.coord[0] + ',' + this.props.coord[1]} target="_blank" style={{ textDecoration: 'none' }}>
									<Button disabled={this.props.coord.length === 0} variant="info" className="generate-btn">Drop a Point View</Button>
								</Link>
							</div>
							<div className="column-33">
								<Link to={'/tile-analysis/' + this.props.coord[0] + ',' + this.props.coord[1]} target="_blank" style={{ textDecoration: 'none' }}>
									<Button disabled={this.props.coord.length === 0} variant="info" className="generate-btn">Tile Analysis</Button>
								</Link>
							</div>
							<div className="column-33">
								<Link to={'/marketing/' + this.props.coord[0] + ',' + this.props.coord[1]} target="_blank" style={{ textDecoration: 'none' }}>
									<Button disabled={this.props.coord.length === 0} variant="info" className="generate-btn">Demographic Analysis</Button>
								</Link>
							</div>
							
						</div>
					</div>
				</div>
				<div className="map-filter-for-graph-axis">
					<div className="map-filter-for-graph-axis-inner">
						<h1 className='label'>Type of division</h1>
						<Form onChange={this.handler_type_selection.bind(this)}>
							<div className="row">
								<div className="column-3">
									<Form.Check inline label=" Country" name="type_analysis" type='radio' id='country' defaultChecked={true} />
								</div>
								<div className="column-3">
									<Form.Check inline label=" Cantons" name="type_analysis" type='radio' id='kanton' />
								</div>
								<div className="column-3">
									<Form.Check inline label=" Postal code" name="type_analysis" type='radio' id='plz' />
								</div>
							</div>
						</Form>

						<h1>Location of the tiles</h1>
						{(this.state.isPLZ && !this.state.isCountry) &&
							<Select className='locations' isClearable={true} options={this.state.plz_} onChange={this.handleNumAggr.bind(this)} styles={selectStyles} defaultValue={this.state.current_value_plz} />
						}
						{(!this.state.isPLZ && !this.state.isCountry) &&
							<Select className='locations' isClearable={true} options={this.state.kantons_} onChange={this.handleNumAggr.bind(this)} styles={selectStyles} defaultValue={this.state.current_value_k} />
						}
						{(!this.state.isPLZ && this.state.isCountry) &&
							<Select className='locations' isClearable={true} isDisabled={true} styles={selectStyles} />
						}
						<h1>Type of building</h1>
						<Select className='buildings' isClearable={true} styles={selectStyles} options={this.state.type_building_} onChange={this.handleTypeBuilding.bind(this)} />

						<h1>Analyze hotspot by</h1>
						<div className="row">
							<Form onChange={this.schemaDayWeekHandle.bind(this)}>
								<div className="column-2">
									<Form.Check inline label=" Weekdays" name="type_analysis" type='radio' id='weekdays' defaultChecked={true} />
								</div>
								<div className="column-2">
									<Form.Check inline label=" Weekends" name="type_analysis" type='radio' id='weekends' />
								</div>
							</Form>
						</div>

						<h1>Size of the graph's x-axis </h1>
						<div className="insight-analyzer-modal-slider-inner">
							<Slider range draggableTrack min={0} max={35000} step={10} defaultValue={[this.state.min_x, this.state.max_x]} onChange={_.debounce(this.handler_x_axis.bind(this), 33)}></Slider>
						</div>
						<p className='label-selected-value'>Showing tiles between <span>{numberWithCommas(this.state.min_x)}</span>m and <span>{numberWithCommas(this.state.max_x)}</span>m to the train station</p>

						<h1>Size of the graph's y-axis </h1>
						<div className="insight-analyzer-modal-slider-inner">
							<Slider range draggableTrack min={0} max={38000000} step={10} defaultValue={[this.state.min_y, this.state.max_y]} onChange={_.debounce(this.handler_y_axis.bind(this), 33)}></Slider>
						</div>
						<p className='label-selected-value'>Showing tiles between <span>{numberWithCommas(this.state.min_y)}</span> and <span>{numberWithCommas(this.state.max_y)}</span> pkm per month</p>

						<h1>Modal split for trains</h1>
						<div className="insight-analyzer-modal-slider-inner">
							<Slider range draggableTrack min={0} max={100} step={1} defaultValue={[this.state.min_modal, this.state.max_modal]} onChange={_.debounce(this.handler_modal_split.bind(this), 33)}></Slider>
						</div>
						<p className='label-selected-value'>Showing tiles with train's modal split between <span>{this.state.min_modal}</span>% and <span>{this.state.max_modal}</span>%</p>

						<h1>Percentage of traffic below 750m <br /> to the nearest train station</h1>
						<div className="insight-analyzer-modal-slider-inner">
							<Slider range draggableTrack min={0} max={100} step={1} defaultValue={[this.state.min_pct_traffic, this.state.max_pct_traffic]} onChange={_.debounce(this.handler_pct_traffic_750_sation.bind(this), 33)}></Slider>
						</div>
						<p className='label-selected-value'>Showing tiles with <span>{this.state.min_pct_traffic}</span>% and <span>{this.state.max_pct_traffic}</span>% of the traffic 750m to nearest train station</p>

						<h1>Percentage of total PKm</h1>
						<div className="insight-analyzer-modal-slider-inner">
							<Slider draggableTrack defaultValue={this.state.percentage} onChange={_.debounce(this.handler_percentages_pkm.bind(this), 33)}></Slider>
						</div>
						<p className='label-selected-value'>Showing tiles that contribute to <span>{this.state.percentage}</span>% of the total PKm</p>

						<Form onClick={this.handleAdjustGraph.bind(this)}>
							<Button variant="info" className="generate-btn">Adjust Graph</Button>
						</Form>
					</div>
				</div>
			</div>
		)
	}
}
class MappAreas2Focus extends React.Component {

	// Set up states for updating map 
	constructor(props) {
		super(props);
		this.state = {
			lng: 8.251313,
			lat: 46.924143,
			zoom: 7,
			mapita: 9,
			tyle: 'mapbox://styles/mapbox/satellite-streets-v11',
			tileId_mdb: 0,
			coord: [],
			category_building: 'all',
			hideNavs: false
		}
	}

	//Update several fields
	updateFields(list) {
		this.setState(list);
	}

	//Update map on component update
	updateField(card, value) {
		this.setState({
			...this.state,
			[card]: value
		});
	}

	//Update style
	handleTyle(value) {
		this.setState({ tyle: value }, this.componentDidMount);
	}

	//Update coordinates
	handleCoord(center, zoom) {
		const { lng, lat } = center;
		this.setState({ zoom: zoom, lat: lat, lng: lng })
	}

	//Update coordinates
	handleCoordMoveToTyle(lng, lat, zoom) {
		this.setState({ zoom: zoom, lat: lat, lng: lng })
	}

	componentDidMount() {
		// Set up map 
		const map = new mapboxgl.Map({
			container: this.mapContainer,
			style: this.state.tyle,
			center: [this.state.lng, this.state.lat],
			zoom: this.state.zoom,
		});

		map.on('load', () => {

			// Add zoom and rotation controls to the map.
			map.addControl(new mapboxgl.NavigationControl({ showCompass: false, showZoom: false }), 'top-left');
			map.addControl(new MapboxGeocoder({ accessToken: mapboxgl.accessToken, mapboxgl: mapboxgl, marker: false }), 'top-left');

			//Tyle visualization
			//Add shapes to the map (Tile 1km)
			map.addSource('data-swiss-tyle', {
				'type': 'geojson',
				'data': tile_1km_geojson,
				'generateId': true
			});

			map.addLayer({
				'id': 'data-swiss-tyle',
				'type': 'fill',
				'source': 'data-swiss-tyle',
				'layout': {
					'visibility': tile_1km_visibility
				},
				'paint': {
					'fill-color': '#9d1f6c',
					'fill-opacity': 0.15
				}
			});

			// Add a black outline around the polygon
			map.addLayer({
				'id': 'outline-data-swiss-tyle',
				'type': 'line',
				'source': 'data-swiss-tyle',
				'layout': {
					'visibility': tile_1km_visibility
				},
				'paint': {
					'line-color': '#9d1f6c',
					'line-width': 1
				}
			});

			//Add shapes to the map (Buildings within a tyle)
			map.addSource('data-swiss-buldings-tile', {
				'type': 'geojson',
				'data': tile_building_geojson,
				'generateId': true
			});

			map.addLayer({
				'id': 'data-swiss-buldings-tile',
				'type': 'fill',
				'source': 'data-swiss-buldings-tile',
				'layout': {
					'visibility': tile_building_visibility
				},
				'paint': {
					'fill-color': ['get', 'color'],
					'fill-opacity': 0.3
				}
			});

			// Add a black outline around the polygon
			map.addLayer({
				'id': 'outline-data-swiss-buldings-tile',
				'type': 'line',
				'source': 'data-swiss-buldings-tile',
				'layout': {
					'visibility': tile_building_visibility
				},
				'paint': {
					'line-color': ['get', 'color'],
					'line-width': 1
				}
			});

			//Add shapes to the map (Top tiles - 1kmx1km)
			map.addSource('data-swiss-top-tyles', {
				'type': 'geojson',
				'data': top_tile_geojson,
				'generateId': true
			});

			map.addLayer({
				'id': 'data-swiss-top-tyles',
				'type': 'fill',
				'source': 'data-swiss-top-tyles',
				'layout': {
					'visibility': top_tile_visibility
				},
				'paint': {
					'fill-color': '#9d1f6c',
					'fill-opacity': 0.15
				}
			});

			map.addLayer({
				'id': 'outline-data-swiss-top-tyles',
				'type': 'line',
				'source': 'data-swiss-top-tyles',
				'layout': {
					'visibility': top_tile_visibility
				},
				'paint': {
					'line-color': '#9d1f6c',
					'line-width': 1
				}
			});

			//Add shapes to the map (Tile 100m)
			map.addSource('data-swiss-tyle-100m', {
				'type': 'geojson',
				'data': tile_100m_geojson,
				'generateId': true
			});

			map.addLayer({
				'id': 'data-swiss-tyle-100m',
				'type': 'fill',
				'source': 'data-swiss-tyle-100m',
				'layout': {
					'visibility': tile_100m_visibility
				},
				'paint': {
					'fill-color': ['get', 'color'],
					'fill-opacity': [
						'case',
						['boolean', ['feature-state', 'clicked'], false],
						1, // if selected true, paint in pink
						0.4, // else paint
					]
				}
			});

			// Add a black outline around the polygon
			map.addLayer({
				'id': 'outline-data-swiss-tyle-100m',
				'type': 'line',
				'source': 'data-swiss-tyle-100m',
				'layout': {
					'visibility': tile_100m_visibility
				},
				'paint': {
					'line-color': ['get', 'color'],
					'line-width': 1
				}
			});

			map.addLayer({
				'id': 'data-swiss-tyle-100m-labels',
				'type': 'symbol',
				'source': 'data-swiss-tyle-100m',
				"minzoom": 13,
				'layout': {
					'text-field': ['get', 'percentage'],
					'text-font': [
						'Open Sans Bold',
						'Arial Unicode MS Bold'
					],
					'text-size': 13,
					'text-transform': 'uppercase',
					'text-letter-spacing': 0.05,
					'text-offset': [0, 1],
					'visibility': tile_100m_visibility
				},
				'paint': {
					"text-color": "#ffffff"
				}
			});
		});

		this.setState({
			mapita: map,
			zoom: map.getZoom(),
			center: map.getCenter()
		});

		map.on('click', 'data-swiss-tyle', (e) => {
			let resp_data = ''
			fetch(URL + 'areas_to_focus/tile_traffic/' + e.features[0].properties.tileId)
				.then((res) => res.json())
				.then((json) => {
					resp_data = json[0]
					//let date = (resp_data.date).split('T')[0]

					let total_trips_weekdays_tiles = resp_data.total_trips_weekdays_tiles;
					let total_trips_weekends_tiles = resp_data.total_trips_weekends_tiles;
					let train_trips_weekdays_tiles = resp_data.train_trips_weekdays_tiles;
					let train_trips_weekends_tiles = resp_data.train_trips_weekends_tiles;
					let car_trips_weekdays_tiles = resp_data.car_trips_weekdays_tiles;
					let car_trips_weekends_tiles = resp_data.car_trips_weekends_tiles;

					let car_pkm = 0;
					let train_pkm = 0;
					let total_pkm = 0;
					if (dayofweek === 'weekdays') {
						car_pkm = resp_data.car_pkm;
						train_pkm = resp_data.train_pkm;
						total_pkm = resp_data.total_pkm;
					} else {
						car_pkm = resp_data.car_pkm_weekends;
						train_pkm = resp_data.train_pkm_weekends;
						total_pkm = resp_data.total_pkm_weekends;
					}

					let total_trips_weekdays_tiles_str = '';
					let total_trips_weekends_tiles_str = '';
					let train_trips_weekdays_tiles_str = '';
					let train_trips_weekends_tiles_str = '';
					let car_trips_weekdays_tiles_str = '';
					let car_trips_weekends_tiles_str = '';

					let car_pkm_str = '';
					let train_pkm_str = '';
					let total_pkm_str = '';

					(typeof total_trips_weekdays_tiles == 'number' & total_trips_weekdays_tiles > 0) ? total_trips_weekdays_tiles = (total_trips_weekdays_tiles).toFixed(0) : total_trips_weekdays_tiles = '-';
					(total_trips_weekdays_tiles !== '-') ? total_trips_weekdays_tiles_str = numberWithCommas(total_trips_weekdays_tiles) : total_trips_weekdays_tiles_str = '-';

					(typeof total_trips_weekends_tiles == 'number' & total_trips_weekends_tiles > 0) ? total_trips_weekends_tiles = (total_trips_weekends_tiles).toFixed(0) : total_trips_weekends_tiles = '-';
					(total_trips_weekends_tiles !== '-') ? total_trips_weekends_tiles_str = numberWithCommas(total_trips_weekends_tiles) : total_trips_weekends_tiles_str = '-';

					(typeof train_trips_weekdays_tiles == 'number' & train_trips_weekdays_tiles > 0) ? train_trips_weekdays_tiles = (train_trips_weekdays_tiles).toFixed(0) : train_trips_weekdays_tiles = '-';
					(train_trips_weekdays_tiles !== '-') ? train_trips_weekdays_tiles_str = numberWithCommas(train_trips_weekdays_tiles) : train_trips_weekdays_tiles_str = '-';

					(typeof train_trips_weekends_tiles == 'number' & train_trips_weekends_tiles > 0) ? train_trips_weekends_tiles = (train_trips_weekends_tiles).toFixed(0) : train_trips_weekends_tiles = '-';
					(train_trips_weekends_tiles !== '-') ? train_trips_weekends_tiles_str = numberWithCommas(train_trips_weekends_tiles) : train_trips_weekends_tiles_str = '-';

					(typeof car_trips_weekdays_tiles == 'number' & car_trips_weekdays_tiles > 0) ? car_trips_weekdays_tiles = (car_trips_weekdays_tiles).toFixed(0) : car_trips_weekdays_tiles = '-';
					(car_trips_weekdays_tiles !== '-') ? car_trips_weekdays_tiles_str = numberWithCommas(car_trips_weekdays_tiles) : car_trips_weekdays_tiles_str = '-';

					(typeof car_trips_weekends_tiles == 'number' & car_trips_weekends_tiles > 0) ? car_trips_weekends_tiles = (car_trips_weekends_tiles).toFixed(0) : car_trips_weekends_tiles = '-';
					(car_trips_weekends_tiles !== '-') ? car_trips_weekends_tiles_str = numberWithCommas(car_trips_weekends_tiles) : car_trips_weekends_tiles_str = '-';

					(typeof car_pkm == 'number' & car_pkm > 0) ? car_pkm = (car_pkm).toFixed(0) : car_pkm = '-';
					(car_pkm !== '-') ? car_pkm_str = numberWithCommas(car_pkm) : car_pkm_str = '-';

					(typeof train_pkm == 'number' & train_pkm > 0) ? train_pkm = (train_pkm).toFixed(0) : train_pkm = '-';
					(train_pkm !== '-') ? train_pkm_str = numberWithCommas(train_pkm) : train_pkm_str = '-';

					(typeof total_pkm == 'number' & total_pkm > 0) ? total_pkm = (total_pkm).toFixed(0) : total_pkm = '-';
					(total_pkm !== '-') ? total_pkm_str = numberWithCommas(total_pkm) : total_pkm_str = '-';

					let html_modal = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Modal Split</span></p><p style="margin-block-end: 0em;"><span style="font-weight: semibold;">No data available</span></p>';

					let cars_percentage = 0;
					let trains_percentage = 0;
					let pct_traffic_below_750m_to_station = 0;
					let pct_traffic_above_750m_to_station = 0;

					if (dayofweek === 'weekdays') {
						cars_percentage = (Number(resp_data.modal_split_weekdays_cars) * 100).toFixed(0);
						trains_percentage = (Number(resp_data.modal_split_weekdays_trains) * 100).toFixed(0);
						pct_traffic_below_750m_to_station = (resp_data.pct_traffic_below_750m_to_station * 100).toFixed(1)
						pct_traffic_above_750m_to_station = (resp_data.pct_traffic_above_750m_to_station * 100).toFixed(1)
					} else {
						cars_percentage = (Number(resp_data.modal_split_weekends_cars) * 100).toFixed(0);
						trains_percentage = (Number(resp_data.modal_split_weekends_trains) * 100).toFixed(0);
						pct_traffic_below_750m_to_station = (resp_data.pct_traffic_below_750m_to_station_weekends * 100).toFixed(1)
						pct_traffic_above_750m_to_station = (resp_data.pct_traffic_above_750m_to_station_weekends * 100).toFixed(1)
					}

					let avg_trip = resp_data.avg_trip_length.toFixed(1);

					let other_percentage = (Number(100) - (Number(cars_percentage) + Number(trains_percentage))).toFixed(0);

					if ((total_trips_weekdays_tiles !== '-') & (car_trips_weekdays_tiles !== '-') & (train_trips_weekdays_tiles !== '-')) {
						html_modal = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Modal Split</span></p><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle; width: 100%;"><thead><tr><th></th><th style="width:50%; text-align: start;">Car/Bus</th><th style="width:50%; text-align: end;"><span style="float:right; position:relative, width:50%">Trains</span></th></tr></thead><tbody><tr><th></th><th style="width:50%; text-align: start; font-weight: normal;">' + cars_percentage + '%</th><th style="width:50%; text-align: end;"><span style="float:right; position:relative, width:50%; font-weight: normal;">' + trains_percentage + '%</span></th></tr></tbody></table><span class="block" style="display: block;height: 20px;color: #fff;font-size: 1em;float: left;background-color: #F78948;position: relative;overflow: hidden;width:' + cars_percentage + '%"></span><span class="block" style="display:block; height:20px; color:#fff; font-size:1em; float:left; background-color:#2f3338; position:relative; overflow:hidden; width:' + other_percentage + '%;"></span><span class="block" style="display:block; height:20px; color:#fff; font-size:1em; float:left; background-color:#0fabab; position:relative; overflow:hidden; width:' + trains_percentage + '%;"></span>';
					}

					let html = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Average Trip Length: </span>' + avg_trip + 'km </p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Trips per day</span></p><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle;"><thead><tr><th></th><th>&#x1f46a; &#128664;</th><th>&#x1f46a; &#x1f686;</th><th>Total</th></tr></thead><tbody><tr><th>Weekdays</th><td>' + car_trips_weekdays_tiles_str + '</td><td>' + train_trips_weekdays_tiles_str + '</td><td>' + total_trips_weekdays_tiles_str + '</td></tr><tr><th>Weekends</th><td>' + car_trips_weekends_tiles_str + '</td><td>' + train_trips_weekends_tiles_str + '</td><td>' + total_trips_weekends_tiles_str + '</td></tr></tbody></table><span style="font-weight: bold;">Passenger Km per month</span><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle;"><thead><tr><th>Cars</th><th>Trains</th><th>Total</th></tr></thead><tbody><tr><td>' + car_pkm_str + ' </td><td>' + train_pkm_str + ' </td><td><span>' + total_pkm_str + ' </span></td></tr></tbody></table><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Percentage of traffic below 750m to the nearest train station: </span>' + pct_traffic_below_750m_to_station + '% </p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Percentage of traffic above 750m to the nearest train station: </span>' + pct_traffic_above_750m_to_station + '% </p>'
					new mapboxgl.Popup()
						.setLngLat(e.lngLat)
						.setHTML(html + html_modal).addTo(map);
				})

		});


		map.on('click', 'data-swiss-top-tyles', (e) => {
			let resp_data = ''
			let tileId = e.features[0].properties.tileId;
			fetch(URL + 'areas_to_focus/tile_traffic/' + e.features[0].properties.tileId)
				.then((res) => res.json())
				.then((json) => {
					resp_data = json[0]
					//let date = (resp_data.date).split('T')[0]

					let total_trips_weekdays_tiles = resp_data.total_trips_weekdays_tiles;
					let total_trips_weekends_tiles = resp_data.total_trips_weekends_tiles;
					let train_trips_weekdays_tiles = resp_data.train_trips_weekdays_tiles;
					let train_trips_weekends_tiles = resp_data.train_trips_weekends_tiles;
					let car_trips_weekdays_tiles = resp_data.car_trips_weekdays_tiles;
					let car_trips_weekends_tiles = resp_data.car_trips_weekends_tiles;

					let car_pkm = 0;
					let train_pkm = 0;
					let total_pkm = 0;
					if (dayofweek === 'weekdays') {
						car_pkm = resp_data.car_pkm;
						train_pkm = resp_data.train_pkm;
						total_pkm = resp_data.total_pkm;
					} else {
						car_pkm = resp_data.car_pkm_weekends;
						train_pkm = resp_data.train_pkm_weekends;
						total_pkm = resp_data.total_pkm_weekends;
					}


					let total_trips_weekdays_tiles_str = '';
					let total_trips_weekends_tiles_str = '';
					let train_trips_weekdays_tiles_str = '';
					let train_trips_weekends_tiles_str = '';
					let car_trips_weekdays_tiles_str = '';
					let car_trips_weekends_tiles_str = '';

					let car_pkm_str = '';
					let train_pkm_str = '';
					let total_pkm_str = '';

					(typeof total_trips_weekdays_tiles == 'number' & total_trips_weekdays_tiles > 0) ? total_trips_weekdays_tiles = (total_trips_weekdays_tiles).toFixed(0) : total_trips_weekdays_tiles = '-';
					(total_trips_weekdays_tiles !== '-') ? total_trips_weekdays_tiles_str = numberWithCommas(total_trips_weekdays_tiles) : total_trips_weekdays_tiles_str = '-';

					(typeof total_trips_weekends_tiles == 'number' & total_trips_weekends_tiles > 0) ? total_trips_weekends_tiles = (total_trips_weekends_tiles).toFixed(0) : total_trips_weekends_tiles = '-';
					(total_trips_weekends_tiles !== '-') ? total_trips_weekends_tiles_str = numberWithCommas(total_trips_weekends_tiles) : total_trips_weekends_tiles_str = '-';

					(typeof train_trips_weekdays_tiles == 'number' & train_trips_weekdays_tiles > 0) ? train_trips_weekdays_tiles = (train_trips_weekdays_tiles).toFixed(0) : train_trips_weekdays_tiles = '-';
					(train_trips_weekdays_tiles !== '-') ? train_trips_weekdays_tiles_str = numberWithCommas(train_trips_weekdays_tiles) : train_trips_weekdays_tiles_str = '-';

					(typeof train_trips_weekends_tiles == 'number' & train_trips_weekends_tiles > 0) ? train_trips_weekends_tiles = (train_trips_weekends_tiles).toFixed(0) : train_trips_weekends_tiles = '-';
					(train_trips_weekends_tiles !== '-') ? train_trips_weekends_tiles_str = numberWithCommas(train_trips_weekends_tiles) : train_trips_weekends_tiles_str = '-';

					(typeof car_trips_weekdays_tiles == 'number' & car_trips_weekdays_tiles > 0) ? car_trips_weekdays_tiles = (car_trips_weekdays_tiles).toFixed(0) : car_trips_weekdays_tiles = '-';
					(car_trips_weekdays_tiles !== '-') ? car_trips_weekdays_tiles_str = numberWithCommas(car_trips_weekdays_tiles) : car_trips_weekdays_tiles_str = '-';

					(typeof car_trips_weekends_tiles == 'number' & car_trips_weekends_tiles > 0) ? car_trips_weekends_tiles = (car_trips_weekends_tiles).toFixed(0) : car_trips_weekends_tiles = '-';
					(car_trips_weekends_tiles !== '-') ? car_trips_weekends_tiles_str = numberWithCommas(car_trips_weekends_tiles) : car_trips_weekends_tiles_str = '-';

					(typeof car_pkm == 'number' & car_pkm > 0) ? car_pkm = (car_pkm).toFixed(0) : car_pkm = '-';
					(car_pkm !== '-') ? car_pkm_str = numberWithCommas(car_pkm) : car_pkm_str = '-';

					(typeof train_pkm == 'number' & train_pkm > 0) ? train_pkm = (train_pkm).toFixed(0) : train_pkm = '-';
					(train_pkm !== '-') ? train_pkm_str = numberWithCommas(train_pkm) : train_pkm_str = '-';

					(typeof total_pkm == 'number' & total_pkm > 0) ? total_pkm = (total_pkm).toFixed(0) : total_pkm = '-';
					(total_pkm !== '-') ? total_pkm_str = numberWithCommas(total_pkm) : total_pkm_str = '-';

					let html_modal = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Modal Split</span></p><p style="margin-block-end: 0em;"><span style="font-weight: semibold;">No data available</span></p>';

					let cars_percentage = 0;
					let trains_percentage = 0;
					let pct_traffic_below_750m_to_station = 0;
					let pct_traffic_above_750m_to_station = 0;

					if (dayofweek === 'weekdays') {
						cars_percentage = (Number(resp_data.modal_split_weekdays_cars) * 100).toFixed(0);
						trains_percentage = (Number(resp_data.modal_split_weekdays_trains) * 100).toFixed(0);
						pct_traffic_below_750m_to_station = (resp_data.pct_traffic_below_750m_to_station * 100).toFixed(1)
						pct_traffic_above_750m_to_station = (resp_data.pct_traffic_above_750m_to_station * 100).toFixed(1)
					} else {
						cars_percentage = (Number(resp_data.modal_split_weekends_cars) * 100).toFixed(0);
						trains_percentage = (Number(resp_data.modal_split_weekends_trains) * 100).toFixed(0);
						pct_traffic_below_750m_to_station = (resp_data.pct_traffic_below_750m_to_station_weekends * 100).toFixed(1)
						pct_traffic_above_750m_to_station = (resp_data.pct_traffic_above_750m_to_station_weekends * 100).toFixed(1)
					}

					let avg_trip = resp_data.avg_trip_length.toFixed(1);


					let other_percentage = (Number(100) - (Number(cars_percentage) + Number(trains_percentage))).toFixed(0);

					if ((total_trips_weekdays_tiles !== '-') & (car_trips_weekdays_tiles !== '-') & (train_trips_weekdays_tiles !== '-')) {
						html_modal = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Modal Split</span></p><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle; width: 100%;"><thead><tr><th></th><th style="width:50%; text-align: start;">Car/Bus</th><th style="width:50%; text-align: end;"><span style="float:right; position:relative, width:50%">Trains</span></th></tr></thead><tbody><tr><th></th><th style="width:50%; text-align: start; font-weight: normal;">' + cars_percentage + '%</th><th style="width:50%; text-align: end;"><span style="float:right; position:relative, width:50%; font-weight: normal;">' + trains_percentage + '%</span></th></tr></tbody></table><span class="block" style="display: block;height: 20px;color: #fff;font-size: 1em;float: left;background-color: #F78948;position: relative;overflow: hidden;width:' + cars_percentage + '%"></span><span class="block" style="display:block; height:20px; color:#fff; font-size:1em; float:left; background-color:#2f3338; position:relative; overflow:hidden; width:' + other_percentage + '%;"></span><span class="block" style="display:block; height:20px; color:#fff; font-size:1em; float:left; background-color:#0fabab; position:relative; overflow:hidden; width:' + trains_percentage + '%;"></span>';
					}

					let html = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Average Trip Length: </span>' + avg_trip + 'km </p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Trips per day</span></p><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle;"><thead><tr><th></th><th>&#x1f46a; &#128664;</th><th>&#x1f46a; &#x1f686;</th><th>Total</th></tr></thead><tbody><tr><th>Weekdays</th><td>' + car_trips_weekdays_tiles_str + '</td><td>' + train_trips_weekdays_tiles_str + '</td><td>' + total_trips_weekdays_tiles_str + '</td></tr><tr><th>Weekends</th><td>' + car_trips_weekends_tiles_str + '</td><td>' + train_trips_weekends_tiles_str + '</td><td>' + total_trips_weekends_tiles_str + '</td></tr></tbody></table><span style="font-weight: bold;">Passenger Km per month</span><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle;"><thead><tr><th>Cars</th><th>Trains</th><th>Total</th></tr></thead><tbody><tr><td>' + car_pkm_str + ' </td><td>' + train_pkm_str + ' </td><td><span>' + total_pkm_str + ' </span></td></tr></tbody></table><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Percentage of traffic below 750m to the nearest train station: </span>' + pct_traffic_below_750m_to_station + '% </p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Percentage of traffic above 750m to the nearest train station: </span>' + pct_traffic_above_750m_to_station + '% </p>'
					new mapboxgl.Popup()
						.setLngLat(e.lngLat)
						.setHTML(html + html_modal).addTo(map);
				}).then(() => {
					fetch(URL + 'areas_to_focus/tile/' + tileId)
						.then((res) => res.json())
						.then((json) => {
							tile_1km_geojson = URL + 'areas_to_focus/tile/' + tileId;
							map.getSource('data-swiss-tyle').setData(json);
							this.updateField('tileId_mdb', json.features[0].properties.tileId);
							let coord1 = (json.features[0].geometry.coordinates[0][1][0] + json.features[0].geometry.coordinates[0][2][0]) / 2;
							let coord2 = (json.features[0].geometry.coordinates[0][2][1] + json.features[0].geometry.coordinates[0][3][1]) / 2;
							this.setState({ coord: [coord1, coord2] })

							tile_100m_geojson = URL + 'areas_to_focus/100m_tile_grid/' + dayofweek + '/' + tileId;
							map.getSource('data-swiss-tyle-100m').setData(tile_100m_geojson);

							tile_building_geojson = URL + 'areas_to_focus/return_buildings/' + tileId + '/' + this.state.category_building;
							map.getSource('data-swiss-buldings-tile').setData(tile_building_geojson);

							map.setLayoutProperty('data-swiss-top-tyles', 'visibility', 'none');
							map.setLayoutProperty('outline-data-swiss-top-tyles', 'visibility', 'none');
						})
				})
		});

		let polygonID = null;

		map.on('click', 'data-swiss-buldings-tile', (e) => {

			let properties = e.features[0].properties

			let html = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Building with no category</span></p>'

			if (properties.amenity !== 'null') {
				html = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Type of building:</span></p><p style="margin-block-end: 0em;"><span style="font-weight: semibold;">' + properties.amenity + '</span></p>'
			}
			else if (properties.building !== 'null') {
				html = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Type of building:</span></p><p style="margin-block-end: 0em;"><span style="font-weight: semibold;">' + properties.building + '</span></p>'
			}



			new mapboxgl.Popup()
				.setLngLat(e.lngLat)
				.setHTML(html).addTo(map);

		})

		map.on('click', 'data-swiss-tyle-100m', (e) => {
			let resp_data = '';
			let maxValue = 0;
			let agg_html = '';

			if (e.features.length > 0) {
				if (typeof polygonID === 'number') {
					map.removeFeatureState({
						source: "data-swiss-tyle-100m",
						id: polygonID
					});
				}
				polygonID = e.features[0].id;

				map.setFeatureState({
					source: 'data-swiss-tyle-100m',
					id: polygonID,
				}, {
					clicked: true
				});
			}

			fetch(URL + 'areas_to_focus/100m_tile_traffic/' + dayofweek + '/' + e.features[0].properties.tile_Id)
				.then((res) => res.json())
				.then((json) => {
					resp_data = json;
					maxValue = getMax(resp_data.counts);

					for (let i = 0; i < resp_data.dates.length; i++) {
						let current_elem = resp_data.dates[i];
						let hour_slider = (current_elem.split('T')[1]).lastIndexOf(':');
						let hour = (current_elem.split('T')[1]).slice(0, hour_slider);
						let percentage = ((resp_data.counts[i] / maxValue) * 100).toFixed(0);

						agg_html += '<tr><td>' + hour + '</td><td style="margin: 0em; font-size:10px, font-weight:lighter; width:55%"><span class="block" style="display: block;height: 20px;color: black;font-size: 1em;float: left;background-color: rgba(247, 137, 72, 0.5); border: 1px solid #F78948; position: relative;overflow: hidden;width:' + percentage + '%"></span><td><td>' + resp_data.counts[i] + '</td></tr>';
					}
					let html = ''
					if (resp_data.total_counts === 0) {
						html = '<div><p style="margin-block-end: 0em;"><span style="font-weight: bold;">No data available for this tile</span></p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">People in the tile during the day:</span> ' + (resp_data.total_counts).toFixed(0) + '</p></div>'
					}
					else {
						html = '<div><p style="margin-block-end: 0em;"><span style="font-weight: bold;">People in the tile during the day:</span> ' + (resp_data.total_counts).toFixed(0) + '</p><br/><div><p style="margin-block-end: 0em;"><span style="font-weight: bold;">People\'s daily distribution by work/sleep hours</span></p><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle;"><tbody><tr><th>22:00 ~ 04:00 → </th><td>' + (resp_data.quartiles[0]).toFixed(0) + '</td></tr><tr><th>07:00 ~ 17:00 → </th><td>' + (resp_data.quartiles[1]).toFixed(0) + '</td></tr></tbody></table><br/><p style="margin-block-end: 0em;"><span style="font-weight: bold;">People\'s daily distribution by hours</span></p><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle; width: 100%;">' + agg_html + '</table></div>'
					}

					new mapboxgl.Popup()
						.setLngLat(e.lngLat)
						.setHTML(html).addTo(map);
				})
		});
	}

	render() {
		const { hideNavs } = this.state;

		return (
			<div>
				<div ref={el => this.mapContainer = el} style={{ width: '100%', height: '100vh' }}>
				</div>
				<RecommendationNavBarComponent />
				<HideElementsComponent map={this.state.mapita} updateField={(card, field, value) => this.updateField(card, field, value)}/>
				<div style={{ display: hideNavs ? 'none' : 'block' }}>
				<BtnsComponent map={this.state.mapita} coord={this.state.coord} handleCoordMoveToTyle={(value1, value2, value3) => this.handleCoordMoveToTyle(value1, value2, value3)} updateField = {(field, value) => this.updateField(field, value)} category_building={this.state.category_building} />
				</div>
				<LayerTylesComponent map={this.state.mapita} tyle={this.state.tyle} handleTyle={(value) => this.handleTyle(value)} handleCoord={(value1, value2) => this.handleCoord(value1, value2)} />
			</div>
		)
	}
}

export default MappAreas2Focus;
