import React from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '../css/map-drop-point.css';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';
import Chart from 'chart.js/auto';
import { IconContext } from "react-icons";
import { BsLayersHalf } from "react-icons/bs";
import Select from 'react-select';
import RecommendationNavBarComponent from "../../components/RecommendationNavBarComponent";
import Slider from 'rc-slider';
import { useParams } from "react-router-dom";
import { RiFullscreenFill, RiFullscreenExitFill } from "react-icons/ri";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ExportExcel from "../../utils/ExportExcel";
import { Line } from 'react-chartjs-2';
import { VscGraphLine } from "react-icons/vsc";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const URL = process.env.REACT_APP_URL_SERVER_BACK;

let no_data_modal = false;
let hideLines = false;
let hideCircles = false;
let hideDestination = false;

const _ = require('lodash');

let generated_info = false;
function numberWithCommas(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
}

const lines_geojson = {
	'type': 'FeatureCollection',
	'features': [
		{
			'type': 'Feature',
			'geometry': {
				"type": "LineString",
				"coordinates": [
				]
			}
		}
	]
};

const point_geojson = {
	'type': 'FeatureCollection',
	'features': [
		{
			'type': 'Feature',
			'geometry': {
				'type': 'Point',
				'coordinates': [8.251313, 46.924143]
			}
		}
	]
};

class HideElementsComponent extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			hideNavs: false,
			mapa: this.props.map,
		}
	}

	hideComponent() {
		let newHideNavs = !this.state.hideNavs;
		this.setState({ hideNavs: newHideNavs })
		this.props.updateField("hideNavs", newHideNavs);
	}

	render() {

		const { hideNavs } = this.state;

		return (
			<div>
				{hideNavs ? <>
					<div className='map-close-navs'>
						<div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
								<div>
									<RiFullscreenExitFill />
								</div>
							</IconContext.Provider>
						</div>
					</div>
				</> : <>
					<div className='map-close-navs'>
						<div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
								<div>
									<RiFullscreenFill />
								</div>
							</IconContext.Provider>
						</div>
					</div>
				</>}
			</div>
		)
	}
}

//Layer Tyles Selection - Side Menu
class LayerTylesComponent extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			showOptions: false,
			mapa: this.props.map,
		}
		this.hideComponent = this.hideComponent.bind(this);
		this.handleTyleEvent = this.handleTyleEvent.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (this.props.map !== prevProps.map) {
			this.setState({ mapa: this.props.map })
		}
	}

	hideComponent(name) {
		this.setState({ showOptions: !this.state.showOptions });
	}

	handleTyleEvent(event) {
		switch (event) {
			case "default":
				this.props.handleTyle("mapbox://styles/mapbox/light-v10");
				break;
			case "outdoors":
				this.props.handleTyle("mapbox://styles/mapbox/outdoors-v11");
				break;
			case "satellite":
				this.props.handleTyle("mapbox://styles/mapbox/satellite-streets-v11");
				break;
			case "streets":
				this.props.handleTyle("mapbox://styles/mapbox/streets-v11");
				break;
			case "dark":
				this.props.handleTyle("mapbox://styles/mapbox/dark-v10");
				break;
			default:
				// eslint-disable-next-line no-unused-expressions
				null;
		}
		this.props.handleCoord(this.state.mapa.getCenter(), this.state.mapa.getZoom());
	}

	render() {
		const { showOptions } = this.state;

		return (
			<div>
				{showOptions ? <>
					<div id='layer-options' className='map-layer-options-tyles'>
						<div className='map-layer-option-dark' onClick={() => this.handleTyleEvent("dark")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/Dark.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Dark</p>
						</div>
						<div className='map-layer-option-streets' onClick={() => this.handleTyleEvent("streets")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/Streets.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Streets</p>
						</div>
						<div className='map-layer-option-satellite' onClick={() => this.handleTyleEvent("satellite")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/Satellite.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Satellite</p>
						</div>
						<div className='map-layer-option-outdoors' onClick={() => this.handleTyleEvent("outdoors")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/Outdoors.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Outdoors</p>
						</div>
						<div className='map-layer-option-default' onClick={() => this.handleTyleEvent("default")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/White.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Default</p>
						</div>
					</div>
				</> : <></>}
				<div className='map-layer-tyles'>
					<div className='map-layer-tyles-inner' onClick={() => this.hideComponent("showOptions")}>
						<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
							<div>
								<BsLayersHalf />
							</div>
						</IconContext.Provider>
					</div>
				</div>
			</div>
		)
	}
}

class GraphComponent extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			mapa: this.props.map,
			graphParam: this.props.graphParam,
			take_od: 0,
			respModalPKM: [],
			respModalTrips: [],
			respTraveledKm: [],
			showGraph: false
		}
	}

	componentDidMount() {
		this.handleFetchDataModalPKM = this.handleFetchDataModalPKM.bind(this);
		this.handleFetchDataModalTrips = this.handleFetchDataModalTrips.bind(this);
		this.handleFetchDataTraveledKm = this.handleFetchDataTraveledKm.bind(this);
		this.handleGraphComponent = this.handleGraphComponent.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (this.props.map !== prevProps.map) {
			this.setState({ mapa: this.props.map });
		}
		if (this.props.graphParam !== prevProps.graphParam) {
			this.setState({ graphParam: this.props.graphParam });
			if(this.props.graphParam===''){
				this.setState({showGraph: false})
			}else{
				this.setState({showGraph: true})
				this.handleFetchDataModalPKM(this.props.graphParam);
				this.handleFetchDataModalTrips(this.props.graphParam);
				this.handleFetchDataTraveledKm(this.props.graphParam);
			}
		}
	}

	async handleFetchDataModalTrips(param) {
			fetch(URL + 'tile_od/modal_split_trips/' + param)
				.then((res) => res.json())
				.then((json) => {
					for (var i = 0; i < json.modal_split_trains.length; i++) {
						json.modal_split_trains[i] = json.modal_split_trains[i] * 100
					}
					return json
				})
				.then((json) => {
					this.setState({ respModalTrips: json });
				})
	}

	async handleFetchDataModalPKM(param) {
			fetch(URL + 'tile_od/modal_split_pkm/' + param)
				.then((res) => res.json())
				.then((json) => {
					for (var i = 0; i < json.modal_split.length; i++) {
						json.modal_split[i] = json.modal_split[i] * 100
					}
					return json
				})
				.then((json) => {
					this.setState({ respModalPKM: json });
				})
	}

	async handleFetchDataTraveledKm(param) {
		fetch(URL + 'tile_od/traveled_km/' + param)
			.then((res) => res.json())
			.then((json) => {
				this.setState({ respTraveledKm: json });
			})
}

	getExcelPKm() {
		var all = [];
		var length = this.state.respTraveledKm['date']?.length
		if (length !== 0 || length !== 'undefined') {
			for (var i = 0; i < length; i++) {
				all.push({
					"Date": this.state.respTraveledKm['date'][i],
					"Passenger Km Car": this.state.respTraveledKm['traveled_km_cars'][i],
					"Passenger Km Train": this.state.respTraveledKm['traveled_km_trains'][i]
				})
			}
		}
		return all;
	}

	handleGraphComponent() {
		this.setState({ showGraph: !this.state.showGraph });
	}

	getExcelModalTrips() {
		var all = [];
		var length = this.state.respModalTrips['date']?.length
		if (length !== 0 || length !== 'undefined') {
			for (var i = 0; i < length; i++) {
				all.push({
					"Date": this.state.respModalTrips['date'][i],
					"Modal Split Trains": (this.state.respModalTrips['modal_split_trains'][i]) + '%',
				})
			}
		}
		return all;
	}

	getExcelModalPKM() {
		var all = [];
		var length = this.state.respModalPKM['date']?.length
		if (length !== 0 || length !== 'undefined') {
			for (var i = 0; i < length; i++) {
				all.push({
					"Date": this.state.respModalPKM['date'][i],
					"Modal Split Trains": (this.state.respModalPKM['modal_split'][i]) + '%',
				})
			}
		}
		return all;
	}

	render() {
		const { respModalPKM } = this.state;
		const { respModalTrips } = this.state;
		const { showGraph } = this.state;
		const { respTraveledKm } = this.state;

		const ExcelExportDataModalTrips = this.getExcelModalTrips();
		const ExcelExportDataModalPKM = this.getExcelModalPKM();
		const ExcelExportDataPKm = this.getExcelPKm();

		const dataBar_ModalPKM = {
			labels: respModalPKM['date'],
			datasets: [{
				label: 'Modal Split for Trains',
				data: respModalPKM['modal_split'],
				backgroundColor: [
					'rgba(17, 148, 209, 0.2)'
				],
				borderColor: [
					'rgb(17, 148, 209)'
				],
				borderWidth: 1
			},
			]
		};

		const dataBar_ModalTrips = {
			labels: respModalTrips['date'],
			datasets: [{
				label: 'Modal Split for Trains',
				data: respModalTrips['modal_split_trains'],
				backgroundColor: [
					'rgba(221, 138, 230, 0.2)'
				],
				borderColor: [
					'rgb(221, 138, 230)'
				],
				borderWidth: 1
			},
			]
		}

		const dataBar_TraveledKm = {
			labels: respTraveledKm['date'],
			datasets: [{
				label: 'Traveled Km by Trains',
				data: respTraveledKm['traveled_km_trains'],
				backgroundColor: [
					'rgba(247, 137, 72, 0.2)'
				],
				borderColor: [
					'rgb(247, 137, 72)'
				],
				borderWidth: 1
			},
			{
				label: 'Traveled Km by Cars',
				data: respTraveledKm['traveled_km_cars'],
				backgroundColor: [
					'rgba(2,127,128,0.2)'
				],
				borderColor: [
					'rgb(2,127,128)'
				],
				borderWidth: 1
			},
			]
		};


		const options = {
			xAxes: [{
				type: 'time',
				ticks: {
					autoSkip: true,
					maxTicksLimit: 10
				}
			}],
			plugins: {
				title: {
					display: true,
					text: 'Percentage of Trains\' Modal Split (PKm) per Month'
				},
				legend: {
					position: 'bottom',
				},
			},
		};

		const options_trips = {
			xAxes: [{
				type: 'time',
				ticks: {
					autoSkip: true,
					maxTicksLimit: 10
				}
			}],
			plugins: {
				title: {
					display: true,
					text: 'Percentage of Trains\' Modal Split (Trips) per Month'
				},
				legend: {
					position: 'bottom',
				},
			},
		};

		const kmPerDay = {
			xAxes: [{
				type: 'time',
				ticks: {
					autoSkip: true,
					maxTicksLimit: 10
				}
			}],
			plugins: {
				legend: {
					position: 'bottom',
				},
				title: {
					display: true,
					text: 'Km per Month'
				}
			},
		};

		return (
			<div>
				<div className='map-graph-button-modal'>
					<div className='map-graph-button-modal-inner' onClick={() => this.handleGraphComponent("showGraph")}>
						<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
							<div>
								<VscGraphLine />
							</div>
						</IconContext.Provider>
					</div>
				</div>
				{ (showGraph && this.state.graphParam!=='') ? <>
					<div className="modal-graph-drop-point">
						<div className="modal-graph-drop-point-inner">
							<Tabs
								defaultActiveKey="modalsplitpkm"
								id="uncontrolled-tab-example"
								className="mb-3"
							>
								<Tab eventKey="modalsplitpkm" title="Trains' Modal Split (PKm)">
										<Line data={dataBar_ModalPKM} options={options} />
										<ExportExcel excelData={ExcelExportDataModalPKM} fileName={'Drop a point: Modal Split (PKm) - Tile'} />
								</Tab>
								<Tab eventKey="modalsplittrips" title="Trains' Modal Split (Trips)">
										<Line data={dataBar_ModalTrips} options={options_trips} />
										<ExportExcel excelData={ExcelExportDataModalTrips} fileName={'Drop a point: Modal Split (Trips) - Tile'} />
								</Tab>
								<Tab eventKey="Traveledkm" title="Passenger Km">
										<Line data={dataBar_TraveledKm} options={kmPerDay} />
										<ExportExcel excelData={ExcelExportDataPKm} fileName={'Drop a point: PassengerKm  - Tile'} />
								</Tab>
							</Tabs>
						</div>
					</div>
				</> : <></>}
			</div>
		)
	}
}

class BtnsComponent extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			mapa: this.props.map,
			generatedTyles: false,
			loading: false,
			value_od_dates: ({
				"value": "2022-08",
				"label": '2022-08'
			}),
			dates_trips_: this.getOptionsDate(URL + 'db_od_trips'),
			min_distance_km: 0,
			max_distance_km: 400,
			percentage: 100,
			percentage_type: 'trips',
			n_trips: 0,
			coord: this.props.coord,
			min_distance_to_train: 0,
			max_distance_to_train: 35,
			total_pkm: 0,
			current_pkm: 0,
			total_trips: 0,
			current_trips: 0,
			percentage_pkm: 100,
			percentage_trips: 100
		}
		this.handleFetchDataCoordParam()
	}

	componentDidMount() {
		this.handleFetchDataCoord = this.handleFetchDataCoord.bind(this);
		this.handleFetchDataCoordParam = this.handleFetchDataCoordParam.bind(this);
		this.resetView = this.resetView.bind(this);
		this.distanceSliderHandler = this.distanceSliderHandler.bind(this);
		this.handleHideLines = this.handleHideLines.bind(this);
		this.handleSquareDestinations = this.handleSquareDestinations.bind(this);
		this.handleHideCircles = this.handleHideCircles.bind(this);
		this.percentageSliderHandler = this.percentageSliderHandler.bind(this);
		this.percentageTypeHandler = this.percentageTypeHandler.bind(this);
		this.numTripsHandler = this.numTripsHandler.bind(this);
		this.calculateBtnHandler = this.calculateBtnHandler.bind(this);
		this.distanceToTrainHandler = this.distanceToTrainHandler.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (this.props.map !== prevProps.map) {
			this.setState({ mapa: this.props.map });
		}
		if (this.props.coord !== prevProps.coord) {
			this.setState({ coord: this.props.coord });
		}
	}

	calculateBtnHandler() {
		this.demoAsyncCall(this.state.value_od_dates.value, point_geojson.features[0].geometry.coordinates[0], point_geojson.features[0].geometry.coordinates[1], this.state.min_distance_km, this.state.max_distance_km, this.state.percentage_type, this.state.percentage, this.state.n_trips, this.state.min_distance_to_train, this.state.max_distance_to_train);
	}

	distanceSliderHandler(event) {
		this.setState({ min_distance_km: event[0], max_distance_km: event[1] });
	}

	percentageSliderHandler(event) {
		this.setState({ percentage: event });
	}

	percentageTypeHandler(event) {
		this.setState({ percentage_type: event.target.attributes.getNamedItem('id').value })
		if (this.state.generatedTyles) {
			this.demoAsyncCall(this.state.value_od_dates.value, point_geojson.features[0].geometry.coordinates[0], point_geojson.features[0].geometry.coordinates[1], this.state.min_distance_km, this.state.max_distance_km, event.target.attributes.getNamedItem('id').value, this.state.percentage, this.state.n_trips, this.state.min_distance_to_train, this.state.max_distance_to_train);
		}
	}

	async getOptionsDate(url) {
		const res = await axios.get(url)
		const data = res.data
		const options = data.map(d => ({
			"value": d._id.date.toString().split('T')[0].slice(0, -3),
			"label": d._id.date.toString().split('T')[0].slice(0, -3)
		}))
		this.setState({ dates_trips_: options });
		//this.setState({ value_od_dates: options[options.length - 1] });
	}

	demoAsyncCall(date, lng, lat, min_distance_km, max_distance_km, percentage_type, percentage, n_trips, min_distance_to_train, max_distance_to_train) {
		let data;
		fetch(URL + 'tile_od/{0}/{1},{2}/{3},{4}/{5}/{6}/{7}/{8}/{9}'.format(date, lng, lat, min_distance_km * 1000, max_distance_km * 1000, n_trips, percentage_type, percentage / 100, min_distance_to_train * 1000, max_distance_to_train * 1000))
			.then((res) => res.json())
			.then((json) => {
				data = json;
				console.log(data)
			}).then(() => {
				if (data[0]?.hasOwnProperty('error')) {
					no_data_modal = true;
				} else {
					this.props.map.getSource('data-swiss-destinations').setData(data)
				}
			}).then(() => this.setState({ loading: false })).then(() => {
				fetch(URL + 'tile_od/tile_geometries/{0}/{1},{2}/{3},{4}/{5}/{6}/{7}/{8}/{9}'.format(date, lng, lat, min_distance_km * 1000, max_distance_km * 1000, n_trips, percentage_type, percentage / 100, min_distance_to_train * 1000, max_distance_to_train * 1000))
					.then((res) => res.json())
					.then((json) => {
						data = json;
					}).then(() => { this.state.mapa.getSource('data-swiss-tyle').setData(data) })
			})
			.then(() => {
				fetch(URL + 'tile_od/concentric_circles/{0}/{1},{2}'.format(date, lng, lat))
					.then((res) => res.json())
					.then((json) => {
						data = json;
					}).then(() => { this.state.mapa.getSource('data-concentric-circle').setData(data) })
			}).then(() => this.setState({ loading: false }))
			.then(() => {
				fetch(URL + 'total_vs_filtered', {
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						"coordinates": [lng, lat],
						"date": date,
						"distance": [min_distance_km * 1000, max_distance_km * 1000],
						"gt_n_trips": n_trips,
						"distance_to_train": [min_distance_to_train * 1000, max_distance_to_train * 1000],
						"traffic_pct": percentage / 100,
						"filter_by": percentage_type
					})
				})
					.then((res) => res.json())
					.then((json) => {
						data = json;
						this.setState({ total_pkm: numberWithCommas((json.total_pkm).toFixed(0)), current_pkm: numberWithCommas((json.current_pkm).toFixed(0)), total_trips: numberWithCommas((json.total_trips).toFixed(0)), current_trips: numberWithCommas((json.current_trips).toFixed(0)), percentage_pkm: (((json.current_pkm / json.total_pkm) * 100).toFixed(1)), percentage_trips: (((json.current_trips / json.total_trips) * 100).toFixed(1)) })
					})
					.then(() => {
						if (hideLines) {
							this.props.map.setLayoutProperty('swiss-destinations', 'visibility', 'none');
						} else {
							this.props.map.setLayoutProperty('swiss-destinations', 'visibility', 'visible');
						}

						if (hideCircles) {
							this.props.map.setLayoutProperty('data-concentric-circle', 'visibility', 'none');
							this.props.map.setLayoutProperty('data-concentric-circle-labels', 'visibility', 'none');
						} else {
							this.props.map.setLayoutProperty('data-concentric-circle', 'visibility', 'visible');
							this.props.map.setLayoutProperty('data-concentric-circle-labels', 'visibility', 'visible');
						}

						if (hideDestination) {
							this.props.map.setLayoutProperty('data-swiss-tyle-labels', 'visibility', 'none');
							this.props.map.setLayoutProperty('data-swiss-tyle', 'visibility', 'none');
							this.props.map.setLayoutProperty('outline-data-swiss-tyle', 'visibility', 'none');
						} else {
							this.props.map.setLayoutProperty('data-swiss-tyle-labels', 'visibility', 'visible');
							this.props.map.setLayoutProperty('data-swiss-tyle', 'visibility', 'visible');
							this.props.map.setLayoutProperty('outline-data-swiss-tyle', 'visibility', 'visible');
						}

						const tyle_corner = {
							center: [lng, lat],
							zoom: 10,
							pitch: 0,
							bearing: 0
						}


						this.props.map.flyTo({
							...tyle_corner, // Fly to the selected target
							duration: 2000, // Animate over 2 seconds
							essential: false // This animation is considered essential with
							//respect to prefers-reduced-motion
						})
					}).then(()=>{
						fetch(URL+'areas_to_focus/tile/coord/'+ lng + ',' + lat)
		.then((res) => res.json())
		.then((json) => this.props.updateFields({ graphParam: ''+json.tileId })
		)
					})
			});
	}

	distanceToTrainHandler(event) {
		this.setState({ min_distance_to_train: event[0], max_distance_to_train: event[1] })
	}

	handleFetchDataCoord() {
		this.setState({ loading: true });
		this.demoAsyncCall(this.state.value_od_dates.value, point_geojson.features[0].geometry.coordinates[0], point_geojson.features[0].geometry.coordinates[1], this.state.min_distance_km, this.state.max_distance_km, this.state.percentage_type, this.state.percentage, this.state.n_trips, this.state.min_distance_to_train, this.state.max_distance_to_train);
		this.setState({ generatedTyles: !this.state.generatedTyles });
		generated_info = true;
		hideLines = false;
		hideDestination = false;
		hideCircles = false;
	}

	handleFetchDataCoordParam() {
		if (this.state.coord['*'] !== "") {
			this.setState({ loading: true });
			this.demoAsyncCall(this.state.value_od_dates.value, parseFloat(this.state.coord['*'].split(',')[0]), parseFloat(this.state.coord['*'].split(',')[1]), this.state.min_distance_km, this.state.max_distance_km, this.state.percentage_type, this.state.percentage, this.state.n_trips, this.state.min_distance_to_train, this.state.max_distance_to_train);
			this.setState({ generatedTyles: !this.state.generatedTyles });
			generated_info = true;
			hideLines = false;
			hideCircles = false;
			hideDestination = false;

			point_geojson.features[0].geometry.coordinates = [parseFloat(this.state.coord['*'].split(',')[0]), parseFloat(this.state.coord['*'].split(',')[1])];
			//this.props.map.getSource('point').setData(point_geojson);
		}
	}

	resetView() {
		this.state.mapa.getSource('data-swiss-destinations').setData(lines_geojson);
		this.state.mapa.getSource('data-concentric-circle').setData(lines_geojson);
		this.state.mapa.getSource('data-swiss-tyle').setData(lines_geojson);
		this.props.map.setLayoutProperty('data-concentric-circle', 'visibility', 'none');
		this.props.map.setLayoutProperty('data-concentric-circle-labels', 'visibility', 'none');
		this.props.map.setLayoutProperty('data-swiss-tyle-labels', 'visibility', 'none');


		this.props.updateFields({ graphParam: '' })
		this.setState({ generatedTyles: false })

		no_data_modal = false;
		generated_info = false;
	}

	handleMonthYear(event) {
		this.setState({ value_od_dates: event });
	}

	handleHideCircles() {
		let temp = !hideCircles;
		hideCircles = temp;
		if (temp) {
			this.props.map.setLayoutProperty('data-concentric-circle', 'visibility', 'none');
			this.props.map.setLayoutProperty('data-concentric-circle-labels', 'visibility', 'none');
		}
		else {
			this.props.map.setLayoutProperty('data-concentric-circle', 'visibility', 'visible');
			this.props.map.setLayoutProperty('data-concentric-circle-labels', 'visibility', 'visible');
		}
	}

	handleHideLines() {
		let temp = !hideLines;
		hideLines = temp;
		if (temp) {
			this.state.mapa.setLayoutProperty('swiss-destinations', 'visibility', 'none');
		}
		else {
			this.state.mapa.setLayoutProperty('swiss-destinations', 'visibility', 'visible');
		}
	}

	handleSquareDestinations() {
		let temp = !hideDestination;
		hideDestination = temp;
		if (temp) {
			this.props.map.setLayoutProperty('data-swiss-tyle-labels', 'visibility', 'none');
			this.props.map.setLayoutProperty('data-swiss-tyle', 'visibility', 'none');
			this.props.map.setLayoutProperty('outline-data-swiss-tyle', 'visibility', 'none');
		} else {
			this.props.map.setLayoutProperty('data-swiss-tyle-labels', 'visibility', 'visible');
			this.props.map.setLayoutProperty('data-swiss-tyle', 'visibility', 'visible');
			this.props.map.setLayoutProperty('outline-data-swiss-tyle', 'visibility', 'visible');
		}
	}

	numTripsHandler(event) {
		this.setState({ n_trips: event });
	}

	render() {
		const { loading } = this.state;
		const { generatedTyles } = this.state;
		const { value_od_dates } = this.state;
		const { current_pkm } = this.state;
		const { total_pkm } = this.state;
		const { current_trips } = this.state;
		const { total_trips } = this.state;
		const { percentage_pkm } = this.state;
		const { percentage_trips } = this.state;

		const selectDate = {
			menuList: styles => {
				return {
					...styles,
					maxHeight: 150,
					borderWidth: 0,
					boxShadow: '0 0 10px 2px rgb(0 0 0 / 10%)',
				};
			}
		};

		if (loading) { // if your component doesn't have to wait for an async action, remove this block 
			return (
				<div className="bg-loader">
					<div className="pos-center">
						<div className="loader"></div>
					</div>
				</div>
			)
		}
		else {
			return (
				<div>
					{this.state.generatedTyles ?
						<>
							<div className="map-drop-point-pkm-value">
								<div className="map-drop-point-pkm-value-inner">
									<h1>Showing {current_pkm} PKm per day</h1>
									<h3>Total {total_pkm} PKm per day</h3>
									<h4>{percentage_pkm}% of the PKm per day</h4>
									<hr />
									<h1>Showing {current_trips} trips per day</h1>
									<h3>Total {total_trips} trips per day</h3>
									<h4>{percentage_trips}% of the trips per day</h4>
								</div>
							</div>
						</>
						: <></>
					}
					<div className="map-month-year-picker">
						<Select className='dates' options={this.state.dates_trips_} onChange={this.handleMonthYear.bind(this)} styles={selectDate} defaultValue={value_od_dates} />
					</div>
					<div className="map-btn-drop-point">
						<div className="map-btn-drop-point-inner">
							<h1>Compute Origin-Destination <br /> Matrix for a Point</h1>
							<p className="label">Place the circle on the desired coordinates. Click on <i>Generate matrix</i> to visualize the all destinations of selected coordinate</p>
							<div className="row">
								<div className="column-1-eq">
									<Form onClick={this.handleFetchDataCoord.bind(this)}>
										<Button className="generate-btn" variant="info" disabled={(generatedTyles)}>Generate matrix</Button>
									</Form>
								</div>
								<div className="column-2-eq">
									<Form onClick={this.resetView.bind(this)}>
										<Button className="reset-btn" variant="info" disabled={(!generatedTyles)}>Reset matrix</Button>
									</Form>
								</div>
							</div>
						</div>
						<div className="slider-modal">
							<div className="slider-modal-inner">
								<h1>Filter by distance in Km</h1>
								<div className="slider-distance">
									<Slider range min={0} max={400} step={0.5} defaultValue={[this.state.min_distance_km, this.state.max_distance_km]} onChange={_.debounce(this.distanceSliderHandler.bind(this), 33)} />
								</div>
								<p className='label'>Show trips between {this.state.min_distance_km}km and {this.state.max_distance_km}km</p>
								<div className="row-parameters">
									<div className="column-33">
										<Form onChange={this.handleHideLines.bind(this)}>
											<Form.Check
												disabled={!generated_info}
												type="switch"
												id="custom-switch"
												label="Route (Lines)"
												defaultChecked
											/>
										</Form>
									</div>
									<div className="column-33">
										<Form onChange={this.handleHideCircles.bind(this)}>
											<Form.Check
												disabled={!generated_info}
												type="switch"
												id="custom-switch"
												label="Heatmap"
												defaultChecked
											/>
										</Form>
									</div>
									<div className="column-33">
										<Form onChange={this.handleSquareDestinations.bind(this)}>
											<Form.Check
												disabled={!generated_info}
												type="switch"
												id="custom-switch"
												label="Destinations"
												defaultChecked
											/>
										</Form>
									</div>
								</div>
							</div>
						</div>
						<div className="slider-2-modal">
							<div className="slider-2-modal-inner">
								<h1>Filter by percentage</h1>
								<div className="slider-distance">
									<Slider min={0} max={100} step={1} defaultValue={this.state.percentage} onChange={_.debounce(this.percentageSliderHandler.bind(this), 60)} disabled={!this.state.generatedTyles} />
								</div>
								<p className='label'>Show {this.state.percentage}% of {this.state.percentage_type === "trips" ? "trips" : "trips based on PKm"}</p>
								<div className="row">
									<Form onChange={this.percentageTypeHandler.bind(this)}>
										<div className="column-1-eq">
											<Form.Check inline label=" Number of trips" name="type_analysis" type='radio' id='trips' defaultChecked={true} disabled={!this.state.generatedTyles} />
										</div>
										<div className="column-2-eq">
											<Form.Check inline label=" Passenger Km" name="type_analysis" type='radio' id='pkm' disabled={!this.state.generatedTyles} />
										</div>
									</Form>
								</div>
								<br />
								<h1>Filter by number of trips <br /> within a tile</h1>
								<div className="slider-distance">
									<Slider min={0} max={60} step={1} defaultValue={this.state.n_trips} onChange={_.debounce(this.numTripsHandler.bind(this), 33)} disabled={!this.state.generatedTyles} />
								</div>
								<p className='label-2'>Filter tiles that have more than  <br /> {this.state.n_trips} trips per day</p>
								<h1>Filter by distance to the <br /> train station</h1>
								<div className="slider-distance">
									<Slider range draggableTrack min={0} max={35} step={0.01} defaultValue={[this.state.min_distance_to_train, this.state.max_distance_to_train]} onChange={_.debounce(this.distanceToTrainHandler.bind(this), 33)} disabled={!this.state.generatedTyles} />
								</div>
								<p className='label-2'>Show trips that start between {this.state.min_distance_to_train}km and <br />{this.state.max_distance_to_train}km to the nearest train station</p>
								<Form onClick={this.calculateBtnHandler.bind(this)}>
									<Button className="generate-btn" variant="info" disabled={!this.state.generatedTyles}>Calculate</Button>
								</Form>
							</div>
						</div>
					</div>
					{no_data_modal ?
						<>
							<div className="modal-alert">
								<div className="modal-alert-inner">
									<h1>No data available</h1>
									<p>Please click on <i>Reset matrix</i> and try a new point within Switzerland</p>
								</div>
							</div>
						</>
						: <></>
					}
				</div>
			)
		}
	}
}

class MappDropPoint extends React.Component {

	// Set up states for updating map 
	constructor(props) {
		super(props);
		this.state = {
			lng: 8.251313,
			lat: 46.924143,
			zoom: 7,
			mapita: 9,
			tyle: 'mapbox://styles/mapbox/satellite-streets-v11',
			coord: this.props.params,
			hideNavs: false,
			graphParam: ''
		}
	}

	//Update several fields
	updateFields(list) {
		this.setState(list);
	}

	//Update map on component update
	updateField(card, value) {
		this.setState({
			...this.state,
			[card]: value
		});
	}

	//Update style
	handleTyle(value) {
		this.setState({ tyle: value }, this.componentDidMount);
	}

	//Update coordinates
	handleCoord(center, zoom) {
		const { lng, lat } = center;
		this.setState({ zoom: zoom, lat: lat, lng: lng })
	}

	componentDidMount() {
		// Set up map 
		const map = new mapboxgl.Map({
			container: this.mapContainer,
			style: this.state.tyle,
			center: [this.state.lng, this.state.lat],
			zoom: this.state.zoom,
		});

		map.on('load', () => {

			// Add zoom and rotation controls to the map.
			map.addControl(new mapboxgl.NavigationControl({ showCompass: false, showZoom: false }), 'top-left');
			map.addControl(new MapboxGeocoder({ accessToken: mapboxgl.accessToken, mapboxgl: mapboxgl, marker: false }), 'top-left');

			//Circles concentric
			//Add shapes to the map
			map.addSource('data-concentric-circle', {
				'type': 'geojson',
				'data': lines_geojson,
				'generateId': true

			});
			map.addLayer({
				'id': 'data-concentric-circle',
				'type': 'fill',
				'source': 'data-concentric-circle',
				'layout': {
					// Make the layer visible by default.
					'visibility': 'visible'
				},
				'paint': {
					'fill-color': [
						'match', ['get', 'percentile'],
						'0-5', '#602437',
						'5-15', '#8a2846',
						'15-50', '#e05780',
						'50++', '#ffc2d4',
						'black'],
					'fill-opacity': 0.5,
				},
			});

			map.addLayer({
				'id': 'data-concentric-circle-labels',
				'type': 'symbol',
				'source': 'data-concentric-circle',
				"minzoom": 10,
				'layout': {
					'text-field': ['get', 'percentile'],
					'text-font': [
						'Open Sans Bold',
						'Arial Unicode MS Bold'
					],
					'text-size': 15,
					'text-transform': 'uppercase',
					'text-letter-spacing': 0.05,
					'text-offset': [0, 1],
					'visibility': 'visible'
				}
			});

			//Tyle visualization
			//Add shapes to the map
			map.addSource('data-swiss-tyle', {
				'type': 'geojson',
				'data': lines_geojson,
				'generateId': true
			});

			map.addLayer({
				'id': 'data-swiss-tyle',
				'type': 'fill',
				'source': 'data-swiss-tyle',
				'layout': {},
				'paint': {
					'fill-color': '#53b8ae',
					'fill-opacity': 0.4
				}
			});

			// Add a black outline around the polygon
			map.addLayer({
				'id': 'outline-data-swiss-tyle',
				'type': 'line',
				'source': 'data-swiss-tyle',
				'layout': {},
				'paint': {
					'line-color': '#53b8ae',
					'line-width': 1
				}
			});

			map.addLayer({
				'id': 'data-swiss-tyle-labels',
				'type': 'symbol',
				'source': 'data-swiss-tyle',
				"minzoom": 10,
				'layout': {
					'text-field': ['get', 'total_trips_weekdays_tiles'],
					'text-font': [
						'Open Sans Bold',
						'Arial Unicode MS Bold'
					],
					'text-size': 15,
					'text-transform': 'uppercase',
					'text-letter-spacing': 0.05,
					'text-offset': [0, 1],
					'visibility': 'visible'
				}
			});

			// Add circle 
			map.addSource('point', {
				'type': 'geojson',
				'data': point_geojson
			});

			map.addLayer({
				'id': 'point',
				'type': 'circle',
				'source': 'point',
				'paint': {
					'circle-radius': 10,
					'circle-color': '#e32a8d' // blue color
				}
			});

			// add lines to the map
			map.addSource('data-swiss-destinations', {
				'type': 'geojson',
				'data': lines_geojson,
				'generateId': true,
				'lineMetrics': true
			});

			map.addLayer({
				'id': 'swiss-destinations',
				'type': 'line',
				'source': 'data-swiss-destinations',
				'layout': {
					// Make the layer visible by default.
					'visibility': 'visible'
				},
				'paint': {
					'line-color': '#027f80',
					'line-width': 2,
				}
			});

		});

		this.setState({
			mapita: map,
			zoom: map.getZoom(),
			center: map.getCenter()
		});

		// When a click event occurs on a feature in the places layer, open a popup at the
		// location of the feature, with description HTML from its properties.
		map.on('click', 'data-concentric-circle', (e) => {
			let total_trips_weekdays = numberWithCommas((e.features[0].properties.total_trips_weekdays * 365).toFixed(0));
			let car_trips_weekdays = numberWithCommas((e.features[0].properties.car_trips_weekdays * 365).toFixed(0));
			let train_trips_weekdays = numberWithCommas((e.features[0].properties.train_trips_weekdays * 365).toFixed(0));

			let total_pkm_weekdays = numberWithCommas((e.features[0].properties.total_pkm_weekdays * 365).toFixed(0));
			let car_pkm_weekdays = numberWithCommas((e.features[0].properties.car_pkm_weekdays * 365).toFixed(0));
			let train_pkm_weekdays = numberWithCommas((e.features[0].properties.train_pkm_weekdays * 365).toFixed(0));

			let percentile = e.features[0].properties.percentile
			if (percentile === '0-5') {
				percentile = '0km to 5km'
			} else if (percentile === '5-15') {
				percentile = '5km to 15km'
			} else if (percentile === '15-50') {
				percentile = '15km to 50km'
			} else if (percentile === '50++') {
				percentile = '50+km'
			}

			let html = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Within this range of distance: </span><br/>' + percentile + '</p><p style="margin-block-end: 0em; margin-top:1em"><span style="font-weight: bold;">Number trips in weekdays per year</span><br/></p><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle;"><thead><tr><th>Cars &#128664;</th><th>Trains &#x1f686;</th><th>Total</th></tr></thead><tbody><tr><td>' + car_trips_weekdays + '</td><td>' + train_trips_weekdays + '</td><td>' + total_trips_weekdays + '</td></tr></tbody></table><p style="margin-block-end: 0em; margin-top:1em"><span style="font-weight: bold;">Pkm in weekdays per year</span><br/></p><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle;"><thead><tr><th>Cars &#128664;</th><th>Trains &#x1f686;</th><th>Total</th></tr></thead><tbody><tr><td>' + car_pkm_weekdays + '</td><td>' + train_pkm_weekdays + '</td><td>' + total_pkm_weekdays + '</td></tr></tbody></table>';
			new mapboxgl.Popup()
				.setLngLat(e.lngLat)
				.setHTML(html).addTo(map);
		});

		//Tyle information
		// When a click event occurs on a feature in the places layer, open a popup at the
		// location of the feature, with description HTML from its properties.
		map.on('click', 'data-swiss-tyle', (e) => {
			console.log(e.features[0])

			let num_passengers_car_wd = e.features[0].properties.car_trips_weekdays_tiles;
			let num_passengers_car_we = e.features[0].properties.car_trips_weekends_tiles;
			let num_passengers_train_wd = e.features[0].properties.train_trips_weekdays_tiles;
			let num_passengers_train_we = e.features[0].properties.train_trips_weekends_tiles;
			let num_passengers_we_total = e.features[0].properties.total_trips_weekends_tiles;
			let num_passengers_wd_total = e.features[0].properties.total_trips_weekdays_tiles;

			let num_passengers_car_wd_str = '';
			let num_passengers_car_we_str = '';
			let num_passengers_train_wd_str = '';
			let num_passengers_train_we_str = '';
			let num_passengers_we_total_str = '';
			let num_passengers_wd_total_str = '';

			(typeof num_passengers_car_wd == 'number' & num_passengers_car_wd > 0) ? num_passengers_car_wd = (num_passengers_car_wd).toFixed(0) : num_passengers_car_wd = '-';
			(num_passengers_car_wd !== '-') ? num_passengers_car_wd_str = numberWithCommas(num_passengers_car_wd) : num_passengers_car_wd_str = '-';

			(typeof num_passengers_car_we == 'number' & num_passengers_car_we > 0) ? num_passengers_car_we = (num_passengers_car_we).toFixed(0) : num_passengers_car_we = '-';
			(num_passengers_car_we !== '-') ? num_passengers_car_we_str = numberWithCommas(num_passengers_car_we) : num_passengers_car_we_str = '-';

			(typeof num_passengers_train_wd == 'number' & num_passengers_train_wd > 0) ? num_passengers_train_wd = (num_passengers_train_wd).toFixed(0) : num_passengers_train_wd = '-';
			(num_passengers_train_wd !== '-') ? num_passengers_train_wd_str = numberWithCommas(num_passengers_train_wd) : num_passengers_train_wd_str = '-';

			(typeof num_passengers_train_we == 'number' & num_passengers_train_we > 0) ? num_passengers_train_we = (num_passengers_train_we).toFixed(0) : num_passengers_train_we = '-';
			(num_passengers_train_we !== '-') ? num_passengers_train_we_str = numberWithCommas(num_passengers_train_we) : num_passengers_train_we_str = '-';

			(typeof num_passengers_wd_total == 'number' & num_passengers_wd_total > 0) ? num_passengers_wd_total = (num_passengers_wd_total).toFixed(0) : num_passengers_wd_total = '-';
			if (num_passengers_wd_total < (Number(num_passengers_train_wd) + Number(num_passengers_car_wd)) & num_passengers_wd_total !== '-') {
				num_passengers_wd_total = Number(num_passengers_train_wd) + Number(num_passengers_car_wd);
			}
			(num_passengers_wd_total !== '-') ? num_passengers_wd_total_str = numberWithCommas(num_passengers_wd_total) : num_passengers_wd_total_str = '-';

			(typeof num_passengers_we_total == 'number' & num_passengers_we_total > 0) ? num_passengers_we_total = (num_passengers_we_total).toFixed(0) : num_passengers_we_total = '-';
			(num_passengers_we_total !== '-') ? num_passengers_we_total_str = numberWithCommas(num_passengers_we_total) : num_passengers_we_total_str = '-';

			let html = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Passengers per day</span></p><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle;"><thead><tr><th></th><th>&#x1f46a; &#128664;</th><th>&#x1f46a; &#x1f686;</th><th>Total</th></tr></thead><tbody><tr><th>Weekdays</th><td>' + num_passengers_car_wd_str + '</td><td>' + num_passengers_train_wd_str + '</td><td>' + num_passengers_wd_total_str + '</td></tr><tr><th>Weekends</th><td>' + num_passengers_car_we_str + '</td><td>' + num_passengers_train_we_str + '</td><td>' + num_passengers_we_total_str + '</td></tr></tbody></table>';
			new mapboxgl.Popup()
				.setLngLat(e.lngLat)
				.setHTML(html).addTo(map);

		});

		//On click move the point
		map.on('click', (event) => {
			const coords = event.lngLat;
			if (!generated_info) {
				// Print the coordinates of where the point had
				// finished being dragged to on the map.

				map.getCanvas().style.cursor = '';

				// Unbind mouse/touch events
				map.off('mousemove', onMove);
				map.off('touchmove', onMove);

				point_geojson.features[0].geometry.coordinates = [coords.lng, coords.lat];
				map.getSource('point').setData(point_geojson);
			}
		});

		// Change the cursor to a pointer when the mouse is over the places layer.
		map.on('mouseenter', 'data-swiss-tyle', () => {
			map.getCanvas().style.cursor = 'pointer';

		});

		// Change it back to a pointer when it leaves.
		map.on('mouseleave', 'data-swiss-tyle', () => {
			map.getCanvas().style.cursor = '';
		});

		// When the cursor enters a feature in
		// the point layer, prepare for dragging.
		map.on('mouseenter', 'point', () => {
			map.setPaintProperty('point', 'circle-color', '#9e2e6a');
			map.getCanvas().style.cursor = 'move';
		});

		map.on('mouseleave', 'point', () => {
			map.setPaintProperty('point', 'circle-color', '#9e2e6a');
			map.getCanvas().style.cursor = '';
		});

		function onMove(e) {
			const coords = e.lngLat;
			// Set a UI indicator for dragging.
			map.getCanvas().style.cursor = 'grabbing';

			// Update the Point feature in `geojson` coordinates
			// and call setData to the source layer `point` on it.
			point_geojson.features[0].geometry.coordinates = [coords.lng, coords.lat];
			map.getSource('point').setData(point_geojson);
		}

		function onUp(e) {
			const coords = e.lngLat;
			// Print the coordinates of where the point had
			// finished being dragged to on the map.

			map.getCanvas().style.cursor = '';

			// Unbind mouse/touch events
			map.off('mousemove', onMove);
			map.off('touchmove', onMove);

			point_geojson.features[0].geometry.coordinates = [coords.lng, coords.lat];
			map.getSource('point').setData(point_geojson);
		}

		map.on('mousedown', 'point', (e) => {
			// Prevent the default map drag behavior.
			e.preventDefault();

			map.getCanvas().style.cursor = 'grab';

			map.on('mousemove', onMove);
			map.once('mouseup', onUp);
		});

		map.on('touchstart', 'point', (e) => {
			if (e.points.length !== 1) return;

			// Prevent the default map drag behavior.
			e.preventDefault();

			map.on('touchmove', onMove);
			map.once('touchend', onUp);
		});

		// Fullscreen map 
		//map.addControl(new mapboxgl.FullscreenControl());
	}

	render() {
		const { hideNavs } = this.state;

		return (
			<div>
				<div ref={el => this.mapContainer = el} style={{ width: '100%', height: '100vh' }}>
				</div>
				<RecommendationNavBarComponent />
				<HideElementsComponent map={this.state.mapita} updateField={(card, field, value) => this.updateField(card, field, value)} />
				<div style={{ display: hideNavs ? 'none' : 'block' }}>
						<GraphComponent map={this.state.mapita} graphParam={this.state.graphParam}/>
						<BtnsComponent map={this.state.mapita} updateFields={(list) => this.updateFields(list)} coord={this.state.coord} />
					</div>
				<LayerTylesComponent map={this.state.mapita} tyle={this.state.tyle} handleTyle={(value) => this.handleTyle(value)} handleCoord={(value1, value2) => this.handleCoord(value1, value2)} />
			</div>
		)
	}
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => (
	<MappDropPoint
		{...props}
		params={useParams()}
	/>
); 