import React from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import { IconContext } from "react-icons";
import RecommendationNavBarComponent from "../../components/RecommendationNavBarComponent";
import axios from 'axios';
import Select from 'react-select';
import '../css/map-routes.css';
import { BiExit } from "react-icons/bi";
import { MdChevronLeft, MdChevronRight } from "react-icons/md"
import { Link } from "react-router-dom";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const URL = process.env.REACT_APP_URL_SERVER_BACK;


function numberWithCommas(x) {
    if (typeof x !== "undefined") {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");

    }
    else {
        return 0
    }
}


class MainMenu extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        return (
            <div>
                <Link to="/menu" style={{ textDecoration: 'none' }}>
                    <div className='map-close-navs'>
                        <div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
                            <IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
                                <div>
                                    <BiExit />
                                </div>
                            </IconContext.Provider>
                        </div>
                    </div>
                </Link>
            </div>
        )
    }
}


//DashboardHotspotsPotential
class DashboardHotspotsPotential extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            response: [],
            aggregation_types_: [{
                "value": "trips",
                "label": 'Number of Trips'
            },
            {
                "value": "pkm",
                "label": 'Passenger Km'
            }],
            current_aggregation: {
                "value": "trips",
                "label": 'Number of Trips'
            },
            num_item_1: 0,
            num_item_2: 1,
            num_item_3: 2,
        }
        this.handleAggregationType({
            "value": "trips",
            "label": 'Number of Trips'
        });
    }

    handleAggregationType(event) {

        let value = event.value;

        this.setState({ current_aggregation: event })

        fetch(URL + 'bus_potential/buhler/top_list/' + value)
            .then((res) => res.json())
            .then((json) => {
                console.log(json)
                this.setState({ response: json });
            })
    }

    hanndleScrollLeft(){
        if(this.state.num_item_1 !== 0){
            this.setState({num_item_1: this.state.num_item_1-1, num_item_2: this.state.num_item_2-1, num_item_3: this.state.num_item_3-1})
        }
    }

    hanndleScrollRight(){
        if(this.state.num_item_3 !== this.state.response.length-1){
            this.setState({num_item_1: this.state.num_item_1+1, num_item_2: this.state.num_item_2+1, num_item_3: this.state.num_item_3+1})
        }
    }

    getCompanyName(code) {
        if (code === 'buhler') {
            return 'Bühler Group AG'
        } else if (code === 'rolex') {
            return 'Rolex'
        } else if (code === 'omega') {
            return 'Omega SA'
        } else if (code === 'kantonsspital-right') {
            return 'St. Gallen Kantonsspital'
        } else if (code === 'leica') {
            return 'Leica Geosystems AG'
        } else if (code === 'helvetia') {
            return 'Helvetia Versicherung'
        } else if (code === 'mobiliar') {
            return 'Die Mobiliar'
        } else if (code === 'HILTI') {
            return 'Hilti AG'
        } else if (code === 'logistic_zentrum') {
            return 'Logistik Zentrum Post'
        } else if (code === 'post-finance') {
            return 'Post Finance'
        } else if(code === 'hotelplan_suisse'){
            return 'Hotelplan Suisse'
        }
    }

    render() {
        const selectStyles = {
            menuList: styles => {
                return {
                    ...styles,
                    maxHeight: 100,
                };
            }
        };

        const { response } = this.state;
        const { current_aggregation } = this.state;

        return (
            <div className="dashboard-potential">
                <div className="dashboard-potential-inner">
                    <div className="dashboard-interactions">
                        <div className="dashboard-interactions-inner">
                            <h1>Top Hotspots</h1>
                            <h2>What are the most important measures around traffic hotspots?</h2>
                            <div className='select_dashboard'>
                                <Select isClearable={false} styles={selectStyles} options={this.state.aggregation_types_} onChange={this.handleAggregationType.bind(this)} defaultValue={this.state.current_aggregation} />
                            </div>
                        </div>
                    </div>
                    <div className="dashboard-companies">
                        <div className="dashboard-companies-inner">
                            <div className="row">
                            <div className="column-dash-2">
                                    <div className="item-menu">
                                        <div className="item-menu-inner">
                                            <ul className="menu_dashboard_titles">
                                                <li className="list-empty-padding">
                                                   
                                                </li>
                                                <li>
                                                    <p className="graph-label-data-company">Marketing Oportunity</p>
                                                </li>
                                                <li>
                                                    <p className="graph-label-data-company">Last-Mile</p>
                                                </li>
                                                <li>
                                                    <p className="graph-label-data-company">New Work Policy</p>
                                                </li>
                                                <li>
                                                    <p className="graph-label-data-company">Bike Culture</p>
                                                </li>
                                                <li>
                                                    <p className="graph-label-data-company">Shuttle Bus</p>
                                                </li>
                                                <li>
                                                    <p className="graph-label-data-company-potential">Potential</p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="column-dash-5">
                                    <div className='modal-move-between-elements'>
                                        <div className='modal-move-between-elements-inner' onClick={() => this.hanndleScrollLeft()}>
                                            <IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
                                                <div>
                                                    <MdChevronLeft />
                                                </div>
                                            </IconContext.Provider>
                                        </div>
                                    </div>
                                </div>
                                <div className="column-dash-331">
                                    <div className="item-menu">
                                        <div className="item-menu-inner">
                                            <ul className="price">
                                                <li>
                                                    <ul className="two">
                                                        <li className="two-elements">
                                                            {(response[this.state.num_item_1]?.expected_revenue / 1000000)?.toFixed(1)}M CHF
                                                        </li>
                                                        <li className="two-elements">
                                                            {numberWithCommas((response[this.state.num_item_1]?.tons_co2_per_year_potential)?.toFixed(0))}Tons
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li className="header">
                                                    <Link to={"/bus-potential/" + response[this.state.num_item_1]?.company} style={{ textDecoration: 'none' }}>
                                                        <p className="dashboard-item-title">{this.getCompanyName(response[this.state.num_item_1]?.company)}</p>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <img src={require('../../assets/img/graphs/' + response[this.state.num_item_1]?.company + '.png')} alt="graph" className='graph-img' />
                                                </li>
                                                <li>
                                                    <p className="graph-label-data-company">{(current_aggregation.value === 'pkm') ? numberWithCommas((response[this.state.num_item_1]?.marketing_potential_pkm)?.toFixed(0)) : numberWithCommas((response[this.state.num_item_1]?.marketing_potential_trips)?.toFixed(0))}</p>
                                                </li>
                                                <li>
                                                    <p className="graph-label-data-company">{(current_aggregation.value === 'pkm') ? numberWithCommas((response[this.state.num_item_1]?.last_mile_potential_pkm)?.toFixed(0)) : numberWithCommas((response[this.state.num_item_1]?.last_mile_potential_trips)?.toFixed(0))}</p>
                                                </li>
                                                <li>
                                                    <p className="graph-label-data-company">{(current_aggregation.value === 'pkm') ? numberWithCommas((response[this.state.num_item_1]?.work_policy_potential_pkm)?.toFixed(0)) : numberWithCommas((response[this.state.num_item_1]?.work_policy_potential_trips)?.toFixed(0))}</p>
                                                </li>
                                                <li>
                                                    <p className="graph-label-data-company">{(current_aggregation.value === 'pkm') ? numberWithCommas((response[this.state.num_item_1]?.bike_culture_potential_pkm)?.toFixed(0)) : numberWithCommas((response[this.state.num_item_1]?.bike_culture_potential_trips)?.toFixed(0))}</p>
                                                </li>
                                                <li>
                                                    <p className="graph-label-data-company">{(current_aggregation.value === 'pkm') ? numberWithCommas((response[this.state.num_item_1]?.bus_potential_pkm)?.toFixed(0)) : numberWithCommas((response[this.state.num_item_1]?.bus_potential_trips)?.toFixed(0))}</p>
                                                </li>
                                                <li>
                                                    <p className="graph-label-data-company-potential">{(current_aggregation.value === 'pkm') ? numberWithCommas((response[this.state.num_item_1]?.total_potential_pkm)?.toFixed(0)) : numberWithCommas((response[this.state.num_item_1]?.total_potential_trips)?.toFixed(0))}</p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="column-dash-332">
                                    <div className="item-menu">
                                        <div className="item-menu-inner">
                                            <ul className="price">
                                                <li>
                                                    <ul className="two">
                                                        <li className="two-elements">
                                                            {(response[this.state.num_item_2]?.expected_revenue / 1000000)?.toFixed(1)}M CHF
                                                        </li>
                                                        <li className="two-elements">
                                                            {numberWithCommas((response[this.state.num_item_2]?.tons_co2_per_year_potential)?.toFixed(0))}Tons
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li className="header">
                                                    <Link to={"/bus-potential/" + response[this.state.num_item_2]?.company} style={{ textDecoration: 'none' }}>
                                                        <p className="dashboard-item-title">{this.getCompanyName(response[this.state.num_item_2]?.company)}</p>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <img src={require('../../assets/img/graphs/' + response[this.state.num_item_2]?.company + '.png')} alt="graph" className='graph-img' />
                                                </li>
                                                <li>
                                                    <p className="graph-label-data-company">{(current_aggregation.value === 'pkm') ? numberWithCommas((response[this.state.num_item_2]?.marketing_potential_pkm)?.toFixed(0)) : numberWithCommas((response[this.state.num_item_2]?.marketing_potential_trips)?.toFixed(0))}</p>
                                                </li>
                                                <li>
                                                    <p className="graph-label-data-company">{(current_aggregation.value === 'pkm') ? numberWithCommas((response[this.state.num_item_2]?.last_mile_potential_pkm)?.toFixed(0)) : numberWithCommas((response[this.state.num_item_2]?.last_mile_potential_trips)?.toFixed(0))}</p>
                                                </li>
                                                <li>
                                                    <p className="graph-label-data-company">{(current_aggregation.value === 'pkm') ? numberWithCommas((response[this.state.num_item_2]?.work_policy_potential_pkm)?.toFixed(0)) : numberWithCommas((response[this.state.num_item_2]?.work_policy_potential_trips)?.toFixed(0))}</p>
                                                </li>
                                                <li>
                                                    <p className="graph-label-data-company">{(current_aggregation.value === 'pkm') ? numberWithCommas((response[this.state.num_item_2]?.bike_culture_potential_pkm)?.toFixed(0)) : numberWithCommas((response[this.state.num_item_2]?.bike_culture_potential_trips)?.toFixed(0))}</p>
                                                </li>
                                                <li>
                                                    <p className="graph-label-data-company">{(current_aggregation.value === 'pkm') ? numberWithCommas((response[this.state.num_item_2]?.bus_potential_pkm)?.toFixed(0)) : numberWithCommas((response[this.state.num_item_2]?.bus_potential_trips)?.toFixed(0))}</p>
                                                </li>
                                                <li>
                                                    <p className="graph-label-data-company-potential">{(current_aggregation.value === 'pkm') ? numberWithCommas((response[this.state.num_item_2]?.total_potential_pkm)?.toFixed(0)) : numberWithCommas((response[this.state.num_item_2]?.total_potential_trips)?.toFixed(0))}</p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="column-dash-333">
                                    <div className="item-menu">
                                        <div className="item-menu-inner">
                                            <ul className="price">
                                                <li>
                                                    <ul className="two">
                                                        <li className="two-elements">
                                                            {(response[this.state.num_item_3]?.expected_revenue / 1000000)?.toFixed(1)}M CHF
                                                        </li>
                                                        <li className="two-elements">
                                                            {numberWithCommas((response[this.state.num_item_3]?.tons_co2_per_year_potential)?.toFixed(0))}Tons
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li className="header">
                                                    <Link to={"/bus-potential/" + response[this.state.num_item_3]?.company} style={{ textDecoration: 'none' }}>
                                                        <p className="dashboard-item-title">{this.getCompanyName(response[this.state.num_item_3]?.company)}</p>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <img src={require('../../assets/img/graphs/' + response[this.state.num_item_3]?.company + '.png')} alt="graph" className='graph-img' />
                                                </li>
                                                <li>
                                                    <p className="graph-label-data-company">{(current_aggregation.value === 'pkm') ? numberWithCommas((response[this.state.num_item_3]?.marketing_potential_pkm)?.toFixed(0)) : numberWithCommas((response[this.state.num_item_3]?.marketing_potential_trips)?.toFixed(0))}</p>
                                                </li>
                                                <li>
                                                    <p className="graph-label-data-company">{(current_aggregation.value === 'pkm') ? numberWithCommas((response[this.state.num_item_3]?.last_mile_potential_pkm)?.toFixed(0)) : numberWithCommas((response[this.state.num_item_3]?.last_mile_potential_trips)?.toFixed(0))}</p>
                                                </li>
                                                <li>
                                                    <p className="graph-label-data-company">{(current_aggregation.value === 'pkm') ? numberWithCommas((response[this.state.num_item_3]?.work_policy_potential_pkm)?.toFixed(0)) : numberWithCommas((response[this.state.num_item_3]?.work_policy_potential_trips)?.toFixed(0))}</p>
                                                </li>
                                                <li>
                                                    <p className="graph-label-data-company">{(current_aggregation.value === 'pkm') ? numberWithCommas((response[this.state.num_item_3]?.bike_culture_potential_pkm)?.toFixed(0)) : numberWithCommas((response[this.state.num_item_3]?.bike_culture_potential_trips)?.toFixed(0))}</p>
                                                </li>
                                                <li>
                                                    <p className="graph-label-data-company">{(current_aggregation.value === 'pkm') ? numberWithCommas((response[this.state.num_item_3]?.bus_potential_pkm)?.toFixed(0)) : numberWithCommas((response[this.state.num_item_3]?.bus_potential_trips)?.toFixed(0))}</p>
                                                </li>
                                                <li>
                                                    <p className="graph-label-data-company-potential">{(current_aggregation.value === 'pkm') ? numberWithCommas((response[this.state.num_item_3]?.total_potential_pkm)?.toFixed(0)) : numberWithCommas((response[this.state.num_item_3]?.total_potential_trips)?.toFixed(0))}</p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="column-dash-5">
                                    <div className='modal-move-between-elements'>
                                        <div className='modal-move-between-elements-inner' onClick={() => this.hanndleScrollRight()}>
                                            <IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
                                                <div>
                                                    <MdChevronRight />
                                                </div>
                                            </IconContext.Provider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <RecommendationNavBarComponent />
                    <MainMenu />
                </div>
            </div>
        )
    }
}

export default DashboardHotspotsPotential;