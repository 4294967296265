
import React from "react";
import { Map } from "react-map-gl";
import DeckGL, { GeoJsonLayer } from "deck.gl";
import { IconContext } from "react-icons";
import axios from 'axios';
import { Form } from 'react-bootstrap';
import RecommendationNavBarComponent from "../../components/RecommendationNavBarComponent";
import { RiFullscreenFill, RiFullscreenExitFill } from "react-icons/ri";
import { HiOutlineInformationCircle, HiInformationCircle } from "react-icons/hi";
import { Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Slider from 'rc-slider';

import '../css/map-recommendation-deckgl.css';

const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const URL = process.env.REACT_APP_URL_SERVER_BACK;
const URL_FRONT = process.env.REACT_APP_URL_SERVER_FRONT;

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
}

const _ = require('lodash');

let height = 'trips';
let height_length = 'absolute';
let selection_distribution = -1;
let data = [];

let color = true;
let color_bike = false;
let color_working = false;
let color_last_mile = false;

function getColors(f) {
  let color = '#ffffff'

  if (f === 'behaviour') { color = '#390099'; }
  else if (f === 'bike') { color = '#9E0059'; }
  else if (f === 'last_mile') { color = '#FF0054'; }
  else if (f === 'working') { color = '#FF5400'; }
  else if (f === 'radius') { color = '#9900ff'; }

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
  return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : [0, 0, 0];
}

function elevationBehaviour(f, height_based, height_length, color) {
  let height = 0;

  let behaviour = f.properties.potential_behavior_trips * 50;

  if (height_based === 'pkm') {
    if (height_length === 'percentage') {
      behaviour = f.properties.potential_behavior_pkm * 50;
    } else {
      behaviour = f.properties.absolute_behavior_pkm / 220;
    }
  } else {
    if (height_length === 'percentage') {
      behaviour = f.properties.potential_behavior_trips * 50;
    } else {
      behaviour = f.properties.absolute_behavior_trips / 220;
    }
  }

  if (height_length === 'absolute' && selection_distribution !== -1) {
    behaviour = behaviour / 10;
  }

  if (color) {
    height = behaviour;
  }

  return height;
}

function elevationBike(f, height_based, height_length, color, color_bike) {
  let height = 0;

  let behaviour = f.properties.potential_behavior_trips * 50;
  let bike = f.properties.potential_bike_trips * 50;

  if (height_based === 'pkm') {
    if (height_length === 'percentage') {
      behaviour = f.properties.potential_behavior_pkm * 50;
      bike = f.properties.potential_bike_pkm * 50;
    } else {
      behaviour = f.properties.absolute_behavior_pkm / 220;
      bike = f.properties.absolute_bike_pkm / 220;
    }
  } else {
    if (height_length === 'percentage') {
      behaviour = f.properties.potential_behavior_trips * 50;
      bike = f.properties.potential_bike_trips * 50;
    } else {
      behaviour = f.properties.absolute_behavior_trips / 220;
      bike = f.properties.absolute_bike_trips / 220;
    }
  }

  if (height_length === 'absolute' && selection_distribution !== -1) {
    behaviour = behaviour / 10;
    bike = bike / 10;
  }

  if (color_bike) {
    if (color) {
      height = bike + behaviour;
    } else {
      height = bike;
    }
  }

  return height;
}

function elevationLastMile(f, height_based, height_length, color, color_bike, color_last_mile) {
  let height = 0;

  let behaviour = f.properties.potential_behavior_trips * 50;
  let bike = f.properties.potential_bike_trips * 50;
  let last_mile = f.properties.potential_last_mile_trips * 50;

  if (height_based === 'pkm') {
    if (height_length === 'percentage') {
      behaviour = f.properties.potential_behavior_pkm * 50;
      bike = f.properties.potential_bike_pkm * 50;
      last_mile = f.properties.potential_last_mile_pkm * 50;
    } else {
      behaviour = f.properties.absolute_behavior_pkm / 220;
      bike = f.properties.absolute_bike_pkm / 220;
      last_mile = f.properties.absolute_last_mile_pkm / 220;
    }
  } else {
    if (height_length === 'percentage') {
      behaviour = f.properties.potential_behavior_trips * 50;
      bike = f.properties.potential_bike_trips * 50;
      last_mile = f.properties.potential_last_mile_trips * 50;
    } else {
      behaviour = f.properties.absolute_behavior_trips / 220;
      bike = f.properties.absolute_bike_trips / 220;
      last_mile = f.properties.absolute_last_mile_trips / 220;
    }
  }

  if (height_length === 'absolute' && selection_distribution !== -1) {
    behaviour = behaviour / 10;
    bike = bike / 10;
    last_mile = last_mile / 10;
  }

  if (color_last_mile) {
    if (color) {
      if (color_bike) {
        height = bike + behaviour + last_mile;
      } else {
        height = behaviour + last_mile;
      }
    }
    else if (color_bike) {
      height = bike + last_mile;
    }
    else {
      height = last_mile;
    }
  }

  return height;
}

function elevationWorking(f, height_based, height_length, color, color_bike, color_working, color_last_mile) {
  let height = 0;

  let behaviour = f.properties.potential_behavior_trips * 50;
  let bike = f.properties.potential_bike_trips * 50;
  let last_mile = f.properties.potential_last_mile_trips * 50;
  let working = f.properties.potential_working_trips * 50;

  if (height_based === 'pkm') {
    if (height_length === 'percentage') {
      behaviour = f.properties.potential_behavior_pkm * 50;
      bike = f.properties.potential_bike_pkm * 50;
      last_mile = f.properties.potential_last_mile_pkm * 50;
      working = f.properties.potential_working_pkm * 50;
    } else {
      behaviour = f.properties.absolute_behavior_pkm / 220;
      bike = f.properties.absolute_bike_pkm / 220;
      last_mile = f.properties.absolute_last_mile_pkm / 220;
      working = f.properties.absolute_working_pkm / 220;
    }
  } else {
    if (height_length === 'percentage') {
      behaviour = f.properties.potential_behavior_trips * 50;
      bike = f.properties.potential_bike_trips * 50;
      last_mile = f.properties.potential_last_mile_trips * 50;
      working = f.properties.potential_working_trips * 50;
    } else {
      behaviour = f.properties.absolute_behavior_trips / 220;
      bike = f.properties.absolute_bike_trips / 220;
      last_mile = f.properties.absolute_last_mile_trips / 220;
      working = f.properties.absolute_working_trips / 220;
    }
  }

  if (height_length === 'absolute' && selection_distribution !== -1) {
    behaviour = behaviour / 10;
    bike = bike / 10;
    last_mile = last_mile / 10;
    working = working / 10;
  }

  if (color_working) {
    if (color) {
      if (color_bike) {
        if (color_last_mile) {
          return bike + behaviour + last_mile + working;
        }
        else {
          return bike + behaviour + working;
        }
      }
      else if (color_last_mile) {
        return behaviour + last_mile + working;
      }
      else {
        return behaviour + working;
      }
    }
    else if (color_bike) {
      if (color_last_mile) {
        return bike + last_mile + working;
      }
      else {
        return bike + working;
      }
    }
    else if (color_last_mile) {
      return last_mile + working;
    }
    else {
      return working;
    }
  }

  return height;
}

function _renderLayers(data, selection_distribution, height_based, height_length, color, color_bike, color_working, color_last_mile, data_r, id_s) {
  const layer = [
    new GeoJsonLayer({
      id: id_s,
      data: data_r,
      opacity: 0.5,
      stroked: true,
      filled: true,
      extruded: true,
      wireframe: true,
      getElevation: 0,
      elevationScale: 100,
      getFillColor: getColors('radius'),
      getLineColor: getColors('radius'),
      getLineWidth: 100,
      pickable: false
    }),

    new GeoJsonLayer({
      id: selection_distribution + height_based + height_length + 'working' + color + color_bike + color_working + color_last_mile,
      data: data,
      opacity: 0.8,
      stroked: true,
      filled: true,
      extruded: true,
      wireframe: true,
      getElevation: f => elevationWorking(f, height_based, height_length, color, color_bike, color_working, color_last_mile),
      elevationScale: 100,
      getFillColor: getColors('working'),
      getLineColor: getColors('working'),
      getLineWidth: 100,
      pickable: true,
    }),

    new GeoJsonLayer({
      id: selection_distribution + height_based + height_length + 'last_mile' + color + color_bike + color_working + color_last_mile,
      data: data,
      opacity: 0.8,
      stroked: true,
      filled: true,
      extruded: true,
      wireframe: true,
      getElevation: f => elevationLastMile(f, height_based, height_length, color, color_bike, color_last_mile),
      elevationScale: 100,
      getFillColor: getColors('last_mile'),
      getLineColor: getColors('last_mile'),
      getLineWidth: 100,
      pickable: true,
    }),

    new GeoJsonLayer({
      id: selection_distribution + height_based + height_length + 'bike' + color + color_bike + color_working + color_last_mile,
      data: data,
      opacity: 0.8,
      stroked: true,
      filled: true,
      extruded: true,
      wireframe: true,
      getElevation: f => elevationBike(f, height_based, height_length, color, color_bike),
      elevationScale: 100,
      getFillColor: getColors('bike'),
      getLineColor: getColors('bike'),
      getLineWidth: 100,
      pickable: true,
    }),

    new GeoJsonLayer({
      id: selection_distribution + height_based + height_length + 'behaviour' + color + color_bike + color_working + color_last_mile,
      data: data,
      opacity: 0.8,
      stroked: true,
      filled: true,
      extruded: true,
      wireframe: true,
      getElevation: f => elevationBehaviour(f, height_based, height_length, color),
      elevationScale: 100,
      getFillColor: getColors('behaviour'),
      getLineColor: getColors('behaviour'),
      getLineWidth: 100,
      pickable: true,
    }),

  ];
  return layer;
}

const empty_geojson = {
  'type': 'FeatureCollection',
  'features': [
    {
      'type': 'Feature',
      'geometry': {
        "type": "LineString",
        "coordinates": [
        ]
      }
    }
  ]
};

class HideElementsComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hideNavs: false,
      mapa: this.props.map,
    }
  }

  hideComponent() {
    let newHideNavs = !this.state.hideNavs;
    this.setState({ hideNavs: newHideNavs })
    this.props.updateField("hideNavs", newHideNavs);
  }

  render() {

    const { hideNavs } = this.state;

    return (
      <div>
        {hideNavs ? <>
          <div className='map-close-navs'>
            <div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
              <IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
                <div>
                  <RiFullscreenExitFill />
                </div>
              </IconContext.Provider>
            </div>
          </div>
        </> : <>
          <div className='map-close-navs'>
            <div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
              <IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
                <div>
                  <RiFullscreenFill />
                </div>
              </IconContext.Provider>
            </div>
          </div>
        </>}
      </div>
    )
  }
}


class MapPotential_3D extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      hideNavs: false,
      selection_distribution: -1,
      color: true,
      color_last_mile: false,
      color_working: false,
      color_bike: false,
      tileId: '',
      loading: false,
      current_pkm: 0,
      total_pkm: 0,
      percentage_pkm: 0,
      current_trips: 0,
      total_trips: 0,
      percentage_trips: 0,
      data: [],
      data_stations: [],
      height_based: 'trips',
      height_length: 'absolute',
      id_station: 0,
      data_radius: [],
      behaviour_pannel: false,
      bike_pannel: false,
      last_mile_pannel: false,
      working_pannel: false
    }
    this.handleData();
  }

  componentDidMount() {
    this.handleAggregation = this.handleAggregation.bind(this);
    this.handleHeight = this.handleHeight.bind(this);
  }

  handlePotential(event) {

    let temp = false;
    let options = []

    if (event.target.attributes.getNamedItem('id').value === 'color') {
      temp = !this.state.color;
      this.setState({ color: temp })

      if (temp) { options.push('color') }
      if (this.state.color_bike) { options.push('color_bike') }
      if (this.state.color_last_mile) { options.push('color_last_mile') }
      if (this.state.color_working) { options.push('color_working') }

    }
    else if (event.target.attributes.getNamedItem('id').value === 'color_bike') {
      temp = !this.state.color_bike;
      this.setState({ color_bike: temp })

      if (temp) { options.push('color_bike') }
      if (this.state.color) { options.push('color') }
      if (this.state.color_last_mile) { options.push('color_last_mile') }
      if (this.state.color_working) { options.push('color_working') }

    }
    else if (event.target.attributes.getNamedItem('id').value === 'color_working') {
      temp = !this.state.color_working;
      this.setState({ color_working: temp })

      if (temp) { options.push('color_working') }
      if (this.state.color) { options.push('color') }
      if (this.state.color_last_mile) { options.push('color_last_mile') }
      if (this.state.color_bike) { options.push('color_bike') }

    }
    else if (event.target.attributes.getNamedItem('id').value === 'color_last_mile') {
      temp = !this.state.color_last_mile;
      this.setState({ color_last_mile: temp })

      if (temp) { options.push('color_last_mile') }
      if (this.state.color) { options.push('color') }
      if (this.state.color_bike) { options.push('color_bike') }
      if (this.state.color_working) { options.push('color_working') }
    }

    color = options.includes('color');
    color_bike = options.includes('color_bike');
    color_last_mile = options.includes('color_last_mile');
    color_working = options.includes('color_working');
    _renderLayers((this.state.selection_distribution === -1 ? this.state.data : this.state.data_stations), this.state.selection_distribution, this.state.height_based, this.state.height_length, options.includes('color'), options.includes('color_bike'), options.includes('color_working'), options.includes('color_last_mile'), empty_geojson, 0)
  }

  handleData() {
    this.setState({ loading: true })

    fetch(URL + 'tile_potential/total_vs_filter_3d')
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          current_pkm: numberWithCommas(json.current_pkm.toFixed(0)),
          total_pkm: numberWithCommas(json.total_pkm.toFixed(0)),
          percentage_pkm: (json.current_pkm / json.total_pkm * 100).toFixed(0),
          current_trips: numberWithCommas(json.current_trips.toFixed(0)),
          total_trips: numberWithCommas(json.total_trips.toFixed(0)),
          percentage_trips: (json.current_trips / json.total_trips * 100).toFixed(0)
        });
      }).then(() => {
        fetch(URL + 'tile_potential/0/potentials.geojson', {
          timeoutDuration: 1200000
        }).then((res) => res.json())
          .then((json) => {
            data = json;
            this.setState({ data: json });
          }).then(() => { this.setState({ loading: false }); })
      })

  }

  getTooltipClick(elem) {
    if (typeof (elem.object?.properties) !== 'undefined') {
      if (selection_distribution === -1) {
        window.open(URL_FRONT + 'potential-tile/' + elem.object.properties.tileId, "_blank", "noreferrer");
      } else {
        fetch(URL + 'tile_potential/stations_radius.geojson', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ "stop_id": elem.object.properties.stop_id, "radius": selection_distribution })
        }).then((res) => res.json())
          .then((json) => {
            this.setState({ id_station: elem.object.properties.stop_id, data_radius: json })
            return json;
          }).then((json) => {
            _renderLayers(data, selection_distribution, height, height_length, color, color_bike, color_working, color_last_mile, json, elem.object.properties.stop_id)
          })
      }
    }
  }

  initialview() {
    let lng = 8.28429379242023;
    let lat = 46.97463784853218;

    return {
      latitude: lat,
      longitude: lng,
      zoom: 7.5,
      maxZoom: 16,
      pitch: 50,
      bearing: 0
    };

  }

  handleMouseOutB() {
    this.setState({ behaviour_pannel: false })
  }

  handleMouseOverB() {
    this.setState({ behaviour_pannel: true })
  }

  handleMouseOutW() {
    this.setState({ working_pannel: false })
  }

  handleMouseOverW() {
    this.setState({ working_pannel: true })
  }

  handleMouseOutBi() {
    this.setState({ bike_pannel: false })
  }

  handleMouseOverBi() {
    this.setState({ bike_pannel: true })
  }

  handleMouseOutL() {
    this.setState({ last_mile_pannel: false })
  }

  handleMouseOverL() {
    this.setState({ last_mile_pannel: true })
  }

  handleAggregation(event) {
    this.setState({ selection_distribution: event });
    selection_distribution = event;

    if (event !== -1) {
      this.setState({ loading: true })
      fetch(URL + 'tile_potential/' + event + '/stations_potentials.geojson')
        .then((res) => res.json())
        .then((json) => {
          this.setState({ data_stations: json });
          this.setState({ id_station: 0, data_radius: empty_geojson })
          _renderLayers(json, this.state.selection_distribution, this.state.height_based, this.state.height_length, this.state.color, this.state.color_bike, this.state.color_working, this.state.color_last_mile, empty_geojson, 0)
        }).then(() => { this.setState({ loading: false }); })
    } else {
      this.setState({ id_station: 0, data_radius: empty_geojson })
      _renderLayers(this.state.data, this.state.selection_distribution, this.state.height_based, this.state.height_length, this.state.color, this.state.color_bike, this.state.color_working, this.state.color_last_mile, empty_geojson, 0)
    }
  }

  handleHeight(event) {
    this.setState({ height_based: event.target.attributes.getNamedItem('id').value });
    height = event.target.attributes.getNamedItem('id').value;
    _renderLayers((this.state.selection_distribution === -1 ? this.state.data : this.state.data_stations), this.state.selection_distribution, event.target.attributes.getNamedItem('id').value, this.state.height_length, this.state.color, this.state.color_bike, this.state.color_working, this.state.color_last_mile, empty_geojson, 0)
  }

  handleHeightLength(event) {
    this.setState({ height_length: event.target.attributes.getNamedItem('id').value });
    height_length = event.target.attributes.getNamedItem('id').value;
    _renderLayers((this.state.selection_distribution === -1 ? this.state.data : this.state.data_stations), this.state.selection_distribution, this.state.height_based, event.target.attributes.getNamedItem('id').value, this.state.color, this.state.color_bike, this.state.color_working, this.state.color_last_mile, empty_geojson, 0)
  }

  getTooltip({ object }) {
    return (
      object && {
        html: `\
          <div><b>Place</b></div>
          <div>${(selection_distribution !== -1 ? object?.properties['Stop name'] : object?.properties.name)} ${(selection_distribution === -1 ? '(' + object?.properties.PLZ + ')' : '')}</div>
          <div><b>Potential of Behavioural Change: </b>${(height === 'pkm') ?
            ((height_length === 'percentage') ? (object?.properties.potential_behavior_pkm * 100).toFixed(0) : numberWithCommas((object?.properties.absolute_behavior_pkm).toFixed(0))) :
            ((height_length === 'percentage') ? (object?.properties.potential_behavior_trips * 100).toFixed(0) : numberWithCommas((object?.properties.absolute_behavior_trips).toFixed(0)))}
          ${(height_length === 'percentage') ? '%' : ''}</div>
          <div><b>Potential of Last Mile: </b>${(height === 'pkm') ?
            ((height_length === 'percentage') ? (object?.properties.potential_last_mile_pkm * 100).toFixed(0) : numberWithCommas((object?.properties.absolute_last_mile_pkm).toFixed(0))) :
            ((height_length === 'percentage') ? (object?.properties.potential_last_mile_trips * 100).toFixed(0) : numberWithCommas((object?.properties.absolute_last_mile_trips).toFixed(0)))}
          ${(height_length === 'percentage') ? '%' : ''}</div>
          <div><b>Potential of Working Time: </b>${(height === 'pkm') ?
            ((height_length === 'percentage') ? (object?.properties.potential_working_pkm * 100).toFixed(0) : numberWithCommas((object?.properties.absolute_working_pkm).toFixed(0))) :
            ((height_length === 'percentage') ? (object?.properties.potential_working_trips * 100).toFixed(0) : numberWithCommas((object?.properties.absolute_working_trips).toFixed(0)))}
          ${(height_length === 'percentage') ? '%' : ''}</div>
          <div><b>Potential of Bicycle: </b>${(height === 'pkm') ?
            ((height_length === 'percentage') ? (object?.properties.potential_bike_pkm * 100).toFixed(0) : numberWithCommas((object?.properties.absolute_bike_pkm).toFixed(0))) :
            ((height_length === 'percentage') ? (object?.properties.potential_bike_trips * 100).toFixed(0) : numberWithCommas((object?.properties.absolute_bike_trips).toFixed(0)))}
          ${(height_length === 'percentage') ? '%' : ''}</div>
          `
      }
    );
  }

  updateField(card, value) {
    this.setState({
      ...this.state,
      [card]: value
    });
  }

  render() {
    const { viewState, controller = true } = this.props;
    const { hideNavs } = this.state;
    const { height_based } = this.state;
    const { height_length } = this.state;

    const { color } = this.state;
    const { color_bike } = this.state;
    const { color_last_mile } = this.state;
    const { color_working } = this.state;
    const { loading } = this.state;

    const { current_pkm } = this.state;
    const { total_pkm } = this.state;
    const { percentage_pkm } = this.state;

    const { current_trips } = this.state;
    const { total_trips } = this.state;
    const { percentage_trips } = this.state;

    const { tileId } = this.state;
    const { working_pannel } = this.state;
    const { bike_pannel } = this.state;
    const { behaviour_pannel } = this.state;
    const { last_mile_pannel } = this.state;

    const marks = {
      '-1': <strong>Hotspots</strong>,
      0: 'Stations',
      1: 'Station+1Km radius',
      2: 'Station+2Km radius',
      3: 'Station+3Km radius',
    };

    return (
      <div>
        <DeckGL
          layers={_renderLayers((this.state.selection_distribution === -1 ? this.state.data : this.state.data_stations), this.state.selection_distribution, this.state.height_based, this.state.height_length, this.state.color, this.state.color_bike, this.state.color_working, this.state.color_last_mile, this.state.data_radius, this.state.id_station)}
          initialViewState={this.initialview()}
          viewState={viewState}
          controller={controller}
          getTooltip={this.getTooltip}
          onClick={(element) => this.getTooltipClick(element)}
        >
          <Map reuseMaps mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN} mapStyle={'mapbox://styles/mapbox/streets-v11'} />
          <RecommendationNavBarComponent />
        </DeckGL>
        {loading ?
            <div className="bg-loader">
              <div className="pos-center-brain-loader">
                <div className="circle-loading-brain">
                </div>
                <svg className='brain-loader' x="0px" y="0px" viewBox="0 0 496 496">
                  <path d="M40,449.472V364.28L58.424,352H88v-16H53.576L24,355.72v93.752C14.704,452.784,8,461.584,8,472c0,13.232,10.768,24,24,24				s24-10.768,24-24C56,461.584,49.288,452.784,40,449.472z M32,480c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8s8,3.584,8,8				C40,476.416,36.416,480,32,480z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M46.528,320H88v-16H46.528c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24				C34.416,336,43.216,329.288,46.528,320z M16,312c0-4.416,3.584-8,8-8s8,3.584,8,8c0,4.416-3.584,8-8,8S16,316.416,16,312z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M24,272c10.416,0,19.216-6.712,22.528-16H88v-16H46.528c-3.312-9.296-12.112-16-22.528-16c-13.232,0-24,10.768-24,24				S10.768,272,24,272z M24,240c4.416,0,8,3.584,8,8s-3.584,8-8,8s-8-3.584-8-8C16,243.584,19.584,240,24,240z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M56 272h32v16H56Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M56 208h32v16H56Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M0 176h88v16H0Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M60.688,160H88v-16H67.312L48,124.688v-14.16C57.288,107.216,64,98.416,64,88c0-13.232-10.768-24-24-24S16,74.768,16,88				c0,10.416,6.704,19.216,16,22.528v20.784L60.688,160z M40,80c4.416,0,8,3.584,8,8s-3.584,8-8,8s-8-3.584-8-8S35.584,80,40,80z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M144 67.312L144 88L160 88L160 60.688L131.312 32L0 32L0 48L124.688 48z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M176 35.312L176 88L192 88L192 28.688L163.312 0L0 0L0 16L156.688 16z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M208 56h16v32H208Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M304 0h16v88H304Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M240,46.528V88h16V59.312l16,16V88h16V68.688L262.392,43.08C268.192,38.696,272,31.816,272,24c0-13.232-10.768-24-24-24				s-24,10.768-24,24C224,34.416,230.704,43.216,240,46.528z M248,16c4.416,0,8,3.584,8,8s-3.584,8-8,8s-8-3.584-8-8				S243.584,16,248,16z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M352,51.312L371.312,32h78.16c2.416,6.776,7.752,12.112,14.528,14.528v110.16L444.688,176H408v16h43.312L480,163.312				V46.528c9.288-3.312,16-12.112,16-22.528c0-13.232-10.768-24-24-24c-10.416,0-19.216,6.712-22.528,16h-84.784L336,44.688V88h16				V51.312z M472,16c4.416,0,8,3.584,8,8s-3.584,8-8,8c-4.416,0-8-3.584-8-8S467.584,16,472,16z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M414.08,97.392l17.92,17.92v9.376L412.688,144H408v16h11.312L448,131.312v-22.624L419.312,80h-4.784				c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24C401.904,112,410.416,105.968,414.08,97.392z				 M392,96c-4.416,0-8-3.584-8-8s3.584-8,8-8c4.416,0,8,3.584,8,8S396.416,96,392,96z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M408 208h88v16H408Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M480,449.472V268.688L451.312,240H408v16h36.688L464,275.312v41.376L451.312,304H408v16h36.688L464,339.312v110.16				c-9.296,3.312-16,12.112-16,22.528c0,13.232,10.768,24,24,24s24-10.768,24-24C496,461.584,489.288,452.784,480,449.472z M472,480				c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8c4.416,0,8,3.584,8,8C480,476.416,476.416,480,472,480z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M408 272h32v16H408Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M448,364.688L419.312,336H408v16h4.688L432,371.312V416h-80v-8h-16v24h96v12.688L412.688,464h-14.16				c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24c10.416,0,19.216-6.712,22.528-16h20.784				L448,451.312V364.688z M376,480c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8c4.416,0,8,3.584,8,8C384,476.416,380.416,480,376,480z				" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M160,408h-16v8h-41.472c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24				c10.416,0,19.216-6.712,22.528-16H160V408z M80,432c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8s8,3.584,8,8				C88,428.416,84.416,432,80,432z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M304 408h16v88H304Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M272 408h16v88H272Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M240,420.688l-16,16V408h-16v41.472c-9.296,3.312-16,12.112-16,22.528c0,13.232,10.768,24,24,24s24-10.768,24-24				c0-7.816-3.808-14.696-9.608-19.08L256,427.312V408h-16V420.688z M216,480c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8s8,3.584,8,8				C224,476.416,220.416,480,216,480z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M192 408L176 408L176 428.688L152 452.688L152 496L168 496L168 459.312L192 435.312z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M336,392h16v-16h24v-24h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16				v-16h-16v-24h-24v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-3.312				L120,140.688V144h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v24h24v16h16v-16h16				v16h16v-16h16v16h16v-16h16v16h16v-16h16v16h16v-16h16v16h16v-16h16V392z M136,360V147.312L147.312,136H360v224H136z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M318.888,211.048l13.96-13.96l-33.936-33.936l-13.96,13.96c-4.16-2.184-8.488-3.976-12.952-5.384V152h-48v19.728				c-4.472,1.408-8.8,3.2-12.952,5.384l-13.96-13.96l-33.936,33.936l13.96,13.96c-2.176,4.16-3.976,8.488-5.384,12.952H152v48				h19.728c1.408,4.472,3.2,8.8,5.384,12.952l-13.96,13.96l33.936,33.936l13.96-13.96c4.16,2.176,8.48,3.976,12.952,5.384V344h48				v-19.728c4.472-1.408,8.8-3.2,12.952-5.384l13.96,13.96l33.936-33.936l-13.96-13.96c2.176-4.152,3.976-8.48,5.384-12.952H344v-48				h-19.728C322.864,219.528,321.072,215.2,318.888,211.048z M328,256h-16.224l-1.416,6.224c-1.6,7.016-4.368,13.672-8.216,19.792				l-3.408,5.408l11.496,11.496l-11.312,11.312l-11.496-11.496l-5.408,3.408c-6.12,3.848-12.776,6.616-19.792,8.216L256,311.776V328				h-16v-16.224l-6.224-1.416c-7.016-1.6-13.672-4.368-19.792-8.216l-5.408-3.408l-11.496,11.496l-11.312-11.312l11.496-11.496				l-3.408-5.408c-3.848-6.12-6.616-12.776-8.216-19.792L184.224,256H168v-16h16.224l1.416-6.224				c1.6-7.016,4.368-13.672,8.216-19.792l3.408-5.408l-11.496-11.496l11.312-11.312l11.496,11.488l5.408-3.408				c6.12-3.848,12.776-6.616,19.792-8.216l6.224-1.408V168h16v16.224l6.224,1.416c7.016,1.6,13.672,4.368,19.792,8.216l5.408,3.408				l11.496-11.488l11.312,11.312l-11.496,11.496l3.408,5.408c3.848,6.12,6.616,12.776,8.216,19.792l1.416,6.216H328V256z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M248,208c-22.056,0-40,17.944-40,40c0,22.056,17.944,40,40,40c22.056,0,40-17.944,40-40C288,225.944,270.056,208,248,208				z M248,272c-13.232,0-24-10.768-24-24s10.768-24,24-24s24,10.768,24,24S261.232,272,248,272z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
            </div>
            :
            <div style={{ display: hideNavs ? 'none' : 'block' }}>
              <div className="map-potential-total-filtered-value" style={{ left: '0px'}}>
                <div className="map-potential-total-filtered-value-inner">
                  <h1>Showing {current_pkm} PKm per day</h1>
                  <h3>Total {total_pkm} PKm per day</h3>
                  <h4>{percentage_pkm}% of the PKm per day</h4>
                  <hr />
                  <h1>Showing {current_trips} trips per day</h1>
                  <h3>Total {total_trips} trips per day</h3>
                  <h4>{percentage_trips}% of the trips per day</h4>
                </div>
              </div>
              <div className='map-potential-3D'>
                <div className='map-potential-3D-inner'>
                  <h1>Hotspot potential</h1>
                  <h2>Aggregate by</h2>
                  <div className='potential-slider-padding'>
                    <Slider min={-1} max={3} marks={marks} step={null} defaultValue={this.state.selection_distribution} onChange={_.debounce(this.handleAggregation.bind(this), 33)} />
                  </div>
                  <h2>Measure the potential based on</h2>
                  <Form onChange={this.handleHeight.bind(this)}>
                    <div className="row">
                      <div className="column-1-50">
                        <Form.Check inline label=" Passenger Km" name="type_aggregation" type='radio' id='pkm' defaultChecked={(height_based === 'pkm')} />
                      </div>
                      <div className="column-2-50">
                        <Form.Check inline label=" Number of trips" name="type_aggregation" type='radio' id='trips' defaultChecked={(height_based === 'trips')} />
                      </div>
                    </div>
                  </Form>
                  <h2>Render heights based on</h2>
                  <Form onChange={this.handleHeightLength.bind(this)}>
                    <div className="row">
                      <div className="column-1-50">
                        <Form.Check inline label=" Percentage" name="type_aggregation" type='radio' id='percentage' defaultChecked={(height_length === 'percentage')} />
                      </div>
                      <div className="column-2-50">
                        <Form.Check inline label=" Absolute" name="type_aggregation" type='radio' id='absolute' defaultChecked={(height_length === 'absolute')} />
                      </div>
                    </div>
                  </Form>
                  <h2>Enable potential based on</h2>
                  <Form onChange={this.handlePotential.bind(this)}>
                    <div className="row">
                      <div className="column-enteprise-4-eq" style={{ width: '20px' }}>
                        <Form.Check inline label="" name="type_analysis" type='checkbox' id='color' defaultChecked={(color)} />
                      </div>
                      <div className="column-enteprise-4-eq" style={{ width: '110px', paddingLeft: '10px' }}
                        onMouseOver={this.handleMouseOverB.bind(this)} onMouseOut={this.handleMouseOutB.bind(this)}>
                        <p className="label">Behaviour Change</p>
                      </div>
                      <div className="column-enteprise-4-eq" style={{ width: '70px' }}>
                        <div className="square" style={{ backgroundColor: "#390099" }}></div>
                      </div>
                      <div className="column-enteprise-4-eq" style={{ width: '20px' }}>
                        <Form.Check inline label="" name="type_analysis" type='checkbox' id='color_last_mile' defaultChecked={(color_last_mile)} />
                      </div>
                      <div className="column-enteprise-4-eq" style={{ width: '50px', paddingLeft: '10px' }}
                        onMouseOver={this.handleMouseOverL.bind(this)} onMouseOut={this.handleMouseOutL.bind(this)}>
                        <p className="label">Last Mile</p>
                      </div>
                      <div className="column-enteprise-4-eq" style={{ width: '20px', paddingRight: '80px' }}>
                        <div className="square" style={{ backgroundColor: "#FF0054" }}></div>
                      </div>
                      <div className="column-enteprise-4-eq" style={{ width: '20px' }}>
                        <Form.Check inline label="" name="type_analysis" type='checkbox' id='color_bike' defaultChecked={(color_bike)} />
                      </div>
                      <div className="column-enteprise-4-eq" style={{ width: '40px', paddingLeft: '10px' }}
                        onMouseOver={this.handleMouseOverBi.bind(this)} onMouseOut={this.handleMouseOutBi.bind(this)}>
                        <p className="label">Bicycle</p>
                      </div>
                      <div className="column-enteprise-4-eq" style={{ width: '20px', paddingRight: '80px' }}>
                        <div className="square" style={{ backgroundColor: "#FF5400" }}></div>
                      </div>
                      <div className="column-enteprise-4-eq" style={{ width: '20px' }}>
                        <Form.Check inline label="" name="type_analysis" type='checkbox' id='color_working' defaultChecked={(color_working)} />
                      </div>
                      <div className="column-enteprise-4-eq" style={{ width: '80px', paddingLeft: '10px' }}
                        onMouseOver={this.handleMouseOverW.bind(this)} onMouseOut={this.handleMouseOutW.bind(this)}>
                        <p className="label">Working Time</p>
                      </div>
                      <div className="column-enteprise-4-eq" style={{ width: '10px' }}>
                        <div className="square" style={{ backgroundColor: "#9E0059" }}></div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
              {behaviour_pannel ?
                <>
                  <div className="modal-information" style={{ top: '320px', left: '20px', width: '350px' }}>
                    <div className="modal-information-inner">
                      <p className="label">(Trips with good ÖV - Trips with good ÖV * MS)Total Trips in the tile</p>
                      <hr />
                      <p className="label">Total Trips in the tile</p>
                    </div>
                  </div>
                </>
                :
                <></>
              }
              {last_mile_pannel ?
                <>
                  <div className="modal-information" style={{ top: '320px', left: '230px', width: '320px' }}>
                    <div className="modal-information-inner">
                      <p className="label">(Trips with good Last Mile - Trips with good Last Mile * MS)</p>
                      <hr />
                      <p className="label">Total Trips in the tile</p>
                    </div>
                  </div>
                </>
                :
                <></>
              }
              {working_pannel ?
                <>
                  <div className="modal-information" style={{ top: '320px', left: '380px', width: '300px' }}>
                    <div className="modal-information-inner">
                      <p className="label">Potential of trips 20min+ from work by ÖV, having the same time by car</p>
                    </div>
                  </div>
                </>
                :
                <></>
              }
              {bike_pannel ?
                <>
                  <div className="modal-information" style={{ top: '320px', left: '410px', width: '300px' }}>
                    <div className="modal-information-inner">
                      <p className="label">Potential of trips within a 5Km radius from their commuting (destination) place</p>
                    </div>
                  </div>
                </>
                :
                <></>
              }
              {tileId !== '' ?
                <>
                  <div className='map-potential-3D-btn'>
                    <div className='map-potential-3D-btn-inner'>
                      <Link to={'/potential-2d/' + this.state.tileId} target="_blank" style={{ textDecoration: 'none' }}>
                        <Button disabled={this.props.coord.length === 0} variant="info" className="generate-btn">Potential 2D</Button>
                      </Link>
                    </div>
                  </div>
                </>
                : <></>}
            </div>
          }
        <HideElementsComponent map={this.state.mapita} updateField={(card, field, value) => this.updateField(card, field, value)} />
      </div>
    );
  }
};

export default MapPotential_3D;
