import React from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import {IconContext} from "react-icons";
import axios from 'axios';
import Select from 'react-select';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { BsLayersHalf } from "react-icons/bs";
import { Form, Button } from 'react-bootstrap';
import Slider from 'rc-slider';
import RecommendationNavBarComponent from "../../components/RecommendationNavBarComponent";
import ExportExcel from "../../utils/ExportExcel";
import { RiFullscreenFill, RiFullscreenExitFill } from "react-icons/ri";

import '../css/map-recommendation.css';
import '../../components/layer-tyles.css';
import '../css/map-tabs-boostrap-graphs.css';
import '../css/mapbox-gl-geocoder.css';
import '../css/map-recommendation-deckgl.css';
import '../css/map-what-if.css'

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const URL = process.env.REACT_APP_URL_SERVER_BACK;

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
}

const _ = require('lodash');

let URL_lines = URL+"what_if/win_ms/null.geojson";

class HideElementsComponent extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			hideNavs : false,
			mapa: this.props.map,
		}
	}

	hideComponent() {
		let newHideNavs = !this.state.hideNavs;
		this.setState({hideNavs: newHideNavs})
		this.props.updateField("hideNavs", newHideNavs);
	}

	render() {

		const { hideNavs } = this.state;

		return (
			<div>
				{hideNavs ? <>
					<div className='map-close-navs'>
						<div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
								<div>
									<RiFullscreenExitFill />
								</div>
							</IconContext.Provider>
						</div>
					</div>
				</> : <>
					<div className='map-close-navs'>
						<div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
								<div>
									<RiFullscreenFill />
								</div>
							</IconContext.Provider>
						</div>
					</div>
				</>}
			</div>
		)
	}
}

//Layer Tyles Selection - Side Menu
class LayerTylesComponent extends React.Component{

	constructor(props){
		super(props)
		this.state ={
			showOptions: false,
			mapa: this.props.map,
		}
		this.hideComponent = this.hideComponent.bind(this);
		this.handleTyleEvent = this.handleTyleEvent.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (this.props.map !== prevProps.map) {
		  this.setState({mapa: this.props.map})
		}
	}

	hideComponent(name)
	{
		switch (name) {
			case "showOptions":
			  this.setState({ showOptions: !this.state.showOptions });
			  break;
			default:
			  // eslint-disable-next-line no-unused-expressions
			  null;
		}
	}

	handleTyleEvent(event) {
		switch (event) {
			case "default":
				this.props.handleTyle("mapbox://styles/mapbox/light-v10");
				break;
			case "outdoors":
				this.props.handleTyle("mapbox://styles/mapbox/outdoors-v11");
				break;
			case "satellite":
				this.props.handleTyle("mapbox://styles/mapbox/satellite-streets-v11");
				break;
			case "streets":
				this.props.handleTyle("mapbox://styles/mapbox/streets-v11");
				break;
			case "dark":
				this.props.handleTyle("mapbox://styles/mapbox/dark-v10");
				break;
			default:
				// eslint-disable-next-line no-unused-expressions
				null;
		}
		this.props.handleCoord(this.state.mapa.getCenter(), this.state.mapa.getZoom());
	}

	render(){
		const { showOptions } = this.state;

		return(
			<div>
				{showOptions ? <>
				<div id='layer-options' className='map-layer-options-tyles'>
					<div className='map-layer-option-dark' onClick={() => this.handleTyleEvent("dark")}>
						<IconContext.Provider value={{ color: "#5B5B5B", size:'1.6em'}}>
							<div>
								<img src={require('../../assets/img/Dark.png')} alt="Default tyle" className='img-tyle'/>
                        	</div>
                    	</IconContext.Provider>
						<p className='label'>Dark</p>
					</div>
					<div className='map-layer-option-streets' onClick={() => this.handleTyleEvent("streets")}>
						<IconContext.Provider value={{ color: "#5B5B5B", size:'1.6em'}}>
							<div>
								<img src={require('../../assets/img/Streets.png')} alt="Default tyle" className='img-tyle'/>
                        	</div>
                    	</IconContext.Provider>
						<p className='label'>Streets</p>
					</div>
					<div className='map-layer-option-satellite' onClick={() => this.handleTyleEvent("satellite")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size:'1.6em'}}>
                        	<div>
								<img src={require('../../assets/img/Satellite.png')} alt="Default tyle" className='img-tyle'/>
                        	</div>
                   		 	</IconContext.Provider>
							<p className='label'>Satellite</p>
					</div>
					<div className='map-layer-option-outdoors' onClick={() => this.handleTyleEvent("outdoors")}>
						<IconContext.Provider value={{ color: "#5B5B5B", size:'1.6em'}}>
							<div>
								<img src={require('../../assets/img/Outdoors.png')} alt="Default tyle" className='img-tyle'/>
                        	</div>
                    	</IconContext.Provider>
						<p className='label'>Outdoors</p>
					</div>
					<div className='map-layer-option-default' onClick={() => this.handleTyleEvent("default")}>
						<IconContext.Provider value={{ color: "#5B5B5B", size:'1.6em'}}>
                        	<div>
								<img src={require('../../assets/img/White.png')} alt="Default tyle" className='img-tyle'/>
                        	</div>
                    	</IconContext.Provider>
						<p className='label'>Default</p>
					</div>
				</div>
				</> : <></>}
				<div className='map-layer-tyles'>
                    <div className='map-layer-tyles-inner' onClick={() => this.hideComponent("showOptions")}>
					<IconContext.Provider value={{ color: "#5B5B5B", size:'1.8em'}}>
                        <div>
                         <BsLayersHalf />
                        </div>
                    </IconContext.Provider>
                </div>
			</div>
		</div>
		)
	}
}

//Mobility Insights Analyzer Component - Dropdown Menu and Radio Btn
class MobilityInsightsAnalyzerComponent extends React.Component{

	constructor(props) {
		super(props)
    	this.state = {
			mapa: this.props.map,
            division: 'bfs',
			zoom: this.props.zoom,
            municipalities_: this.getOptions(URL+'municipalities-list'),
			plz_: this.getOptionsPLZ(URL+'plz-list'),
            kantons_: this.getOptionsKantons(URL+'what_if/kanton_list'),
			db_od_trips_: this.getOptionsDate(URL+'db_od_trips'),
			current_operator: 0,
            percentageOptimization: 10,
            percentageOptimization_decimal: 0.10,
            percentageMS: 5,
            percentageMS_decimal: 0.05,
            allparams: true,
			value_od_trips: ({
				"value" : "2022-08",
				"label" : '2022-08'
			  }),
            value_location: ({
				"value" : null,
				"label" : null
			  }),
			  valueClear: ({
				"value" : "",
				"label" : ''
			  }),
            response_breakdown: [],
            clickedOn: false,
			sameOD: 1,
			sameBFS: "0"
    	}
		
	}

	componentDidMount(){
		this.schemaTypeSelectionHandle = this.schemaTypeSelectionHandle.bind(this);
		this.handleChangeLocation = this.handleChangeLocation.bind(this);
		this.handleMonthYear = this.handleMonthYear.bind(this);

        this.whatifgenerator = this.whatifgenerator.bind(this);
        this.percentageOptimizationSliderHandler = this.percentageOptimizationSliderHandler.bind(this);
        this.percentageMSSliderHandler = this.percentageMSSliderHandler.bind(this);
		this.schemaTypeSameOD = this.schemaTypeSameOD.bind(this);
		this.schemaTypeAggregationBFS = this.schemaTypeAggregationBFS.bind(this);
	}

    async getOptions(url){
		const res = await axios.get(url)
		const data = res.data
		const options = data.map(d => ({
		  "value" : d.id,
		  "label" : d.name
		}))
		this.setState({municipalities_: options})
	}

	async getOptionsPLZ(url){
		const res = await axios.get(url)
		const data = res.data
		const options = data.map(d => ({
		  "value" : d._id.PLZ,
		  "label" : d._id.Name +" ("+d._id.PLZ+")"
		}))
		this.setState({plz_: options})
	}

	async getOptionsDate(url){
		const res = await axios.get(url)
		const data = res.data
		const options = data.map(d => ({
		  "value" : d._id.date.toString().split('T')[0].slice(0,-3),
		  "label" : d._id.date.toString().split('T')[0].slice(0,-3)
		}))
		this.setState({db_od_trips_: options});
		this.setState({value_od_trips: options[options.length-1]});
	}

    async getOptionsKantons(url){
        const res = await axios.get(url)
		const data = res.data
		const options = data.map(d => ({
            "value" : d._id,
            "label" : d._id
		}))
		this.setState({kantons_: options});
    }

	componentDidUpdate(prevProps) {
		if (this.props.map !== prevProps.map) {
		  this.setState({mapa: this.props.map})
		}
	}

    handleChangeLocation(event) 
    {
        if(event===null){
            this.setState({value_location: ({
                "value" : null,
                "label" : null
              })})
              this.setState({allparams: true})
        }
        else{
            this.setState({value_location: event})
            if(event.value!==null && this.state.percentageMS>0 && this.state.percentageOptimization>0)
            {
                this.setState({allparams: false})
            }
            else{
                this.setState({allparams: true})
            }
        }
        
    }

    demoAsyncCall() {
        let data;
		console.log(this.state.sameBFS)
		if(this.state.sameBFS==="0"){
			fetch(URL+"what_if/win_ms", {
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					division: this.state.division,
					division_num: this.state.value_location.value,
					date: this.state.value_od_trips.value,
					ov_quality: "A",
					ms_win: this.state.percentageMS_decimal,
					ms_effort: this.state.percentageOptimization_decimal,
					same_od: this.state.sameOD
				 })
			}).then((res) => res.json())
			.then((json) => { data = json;
			}).then(() => { this.setState({response_breakdown: data.features})});
		}
		else{
			fetch(URL+"what_if/win_ms/top_bfs_list", {
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					division: this.state.division,
					division_num: this.state.value_location.value,
					date: this.state.value_od_trips.value,
					ov_quality: "A",
					ms_win: this.state.percentageMS_decimal,
					ms_effort: this.state.percentageOptimization_decimal,
					same_od: this.state.sameOD
				 })
			}).then((res) => res.json())
			.then((json) => { data = json;
			}).then(() => { this.setState({response_breakdown: data}); console.log(this.state.response_breakdown)});
		}
  }


    whatifgenerator(){
        this.setState({clickedOn: true, response_breakdown: []})
        URL_lines = URL+"what_if/win_ms/lines/{0},{1},{2},A,{3},{4},{5}.geojson".format(this.state.division,this.state.value_location.value,this.state.value_od_trips.value,this.state.percentageMS_decimal,this.state.percentageOptimization_decimal, this.state.sameOD)
        this.state.mapa.getSource('data-whatif-lines').setData(URL_lines);

        this.demoAsyncCall();
    }

    percentageMSSliderHandler(event){
        this.setState({percentageMS: event});
        this.setState({percentageMS_decimal: event/100})

        if((this.state.value_location.value!==null && event>0 && this.state.percentageOptimization>0) || this.state.division==='country')
        {
            this.setState({allparams: false})
        }
        else{
            this.setState({allparams: true})
        }
    }

    percentageOptimizationSliderHandler(event){
        this.setState({percentageOptimization: event});
        this.setState({percentageOptimization_decimal: event/100})

        if((this.state.value_location.value!==null && this.state.percentageMS>0 && event>0) || this.state.division==='country')
        {
            this.setState({allparams: false})
        }
        else{
            this.setState({allparams: true})
        }
    }

	schemaTypeSelectionHandle(event){
		this.setState({division: event.target.attributes.getNamedItem('id').value})
		this.setState({value_location: ({
            "value" : null,
            "label" : null
          })})


		if(event.target.attributes.getNamedItem('id').value === 'country'){
			this.setState({allparams: false})
		}
		else{
			this.setState({allparams: true})
		}
	}

	schemaTypeSameOD(event){
		this.setState({sameOD: event.target.attributes.getNamedItem('id').value})
	}

	schemaTypeAggregationBFS(event){
		this.setState({sameBFS: event.target.attributes.getNamedItem('id').value})
	}

	handleMonthYear(event)
	{
		this.setState({value_od_trips: event})
		this.props.updateField("month_year", event.value);

        if((this.state.value_location.value!==null && this.state.percentageMS>0 && this.state.percentageOptimization>0) || this.state.division==='country')
        {
            this.setState({allparams: false})
        }
        else{
            this.setState({allparams: true})
        }
	}

	getExcelDataOD() {
		var all = [];
		this.state.response_breakdown.map((trip, index) => 
				all.push({
					"Index": index, 
					"Origin": trip.properties?.origin_name,
					"Destination": trip.properties?.destination_name,
					"Current PKm": numberWithCommas(trip.properties?.pkm?.toFixed(0)*365),
					"Current PKm %": (trip.properties?.pkm_percentage*100)?.toFixed(1),
					"Change PKm": numberWithCommas((trip.properties?.cummulative_pkm)?.toFixed(0)*365),
					"Change PKm %": (trip.properties?.cum_pkm_with_effort*100)?.toFixed(1)
				})
			);
		return all;
	}

	getExcelDataBFS() {
		var all = [];
		this.state.response_breakdown.map((trip, index) => 
				all.push({
					"Index": index, 
					"Gemeindename": trip.Gemeindename,
					"Current PKm": numberWithCommas(trip.pkm?.toFixed(0)*365),
					"Current PKm %": (trip.pkm_percentage*100)?.toFixed(1),
					"Change PKm": numberWithCommas((trip.cummulative_pkm)?.toFixed(0)*365),
					"Change PKm %": (trip.cum_pkm_with_effort*100)?.toFixed(1)
				})
			);
		return all;
	}

	render(){

		const ExcelExportDataOD = (this.state.sameBFS==="0" ? this.getExcelDataOD() : this.getExcelDataBFS() );

        const listODTrips = this.state.response_breakdown.map((trip, index) =>
            <tr key={index}>
				<td className="table-data">{index}</td>
                <td className="table-data">{trip.properties?.origin_name} ~ {trip.properties?.destination_name}</td>
                <td className="table-data">{numberWithCommas(trip.properties?.pkm?.toFixed(0)*365)}</td>
                <td className="table-data">{(trip.properties?.pkm_percentage*100)?.toFixed(1)}%</td>
                <td className="table-data">{(trip.properties?.cum_pkm_with_effort*100)?.toFixed(1)}%</td>
				<td className="table-data">{numberWithCommas((trip.properties?.cummulative_pkm)?.toFixed(0)*365)}</td>
            </tr>
        );

		const listBFSTrips = this.state.response_breakdown.map((trip, index) =>
            <tr key={index}>
				<td className="table-data">{index}</td>
                <td className="table-data">{trip.Gemeindename}</td>
                <td className="table-data">{numberWithCommas(trip.pkm?.toFixed(0)*365)}</td>
                <td className="table-data">{(trip.pkm_percentage*100)?.toFixed(1)}%</td>
                <td className="table-data">{(trip.cum_pkm_with_effort*100)?.toFixed(1)}%</td>
				<td className="table-data">{numberWithCommas((trip.cummulative_pkm)?.toFixed(0)*365)}</td>
            </tr>
        );

		const selectStyles = {
			menuList: styles => {
			  return {
				...styles,
				maxHeight: 100
			  };
			}
		  };

		const selectDate = {
			menuList: styles => {
			  return {
				...styles,
				maxHeight: 150,
				borderWidth: 0,
				boxShadow: '0 0 10px 2px rgb(0 0 0 / 10%)',
			  };
			}
		  };

		return(
        <div>
            {this.state.clickedOn && 
            <div className='map-what-if-route-breakdown'>
                <div className='map-what-if-route-breakdown-inner'>
				<h1>Modal Split Optimization<br/> Breakdown per Year</h1>
                    <div className="map-what-if-route-breakdown-inner-scroll">
                        <table>
                        <thead>
                            <tr>
								<th className="table-tittle">Index</th>
								{ this.state.sameBFS==="0" && <th className="table-tittle">OD</th>}
								{ this.state.sameBFS==="1" && <th className="table-tittle">BFS</th>}
                                <th className="table-tittle">Current PKm</th>
                                <th className="table-tittle">Current PKm %</th>
                                <th className="table-tittle">Changed PKm %</th>
								<th className="table-tittle">Changed PKm</th>
                            </tr>
                        </thead>
                        <tbody>
							{ this.state.sameBFS==="0" && listODTrips}
							{ this.state.sameBFS==="1" && listBFSTrips}
                        </tbody>
                        </table>
                    </div>
                </div>
            </div>
            }
            
		<div className="map-what-if-potential-filters">
					<div className="map-what-if-potential-filters-inner">
						<h1>Filtering scope</h1>
							<div>
								<p className='label'>Type of division</p>
								<Form onChange={this.schemaTypeSelectionHandle.bind(this)}>
									<Form.Check inline label=" Country" name="type_analysis" type='radio' id='country' />
                                    <Form.Check inline label=" Cantons" name="type_analysis" type='radio' id='kanton' />
									<Form.Check inline label=" Municipalities" name="type_analysis" type='radio' id='bfs' defaultChecked={true}/>
									<Form.Check inline label=" Postal code" name="type_analysis" type='radio' id='plz' />
								</Form>
							</div>
                            <div>
				{(this.state.division==='bfs') &&
					<div>
                        <div>
                            <p className='label' htmlFor="location">Select location</p>
                            <Select className='locations' isClearable={true} options={this.state.municipalities_} onChange={this.handleChangeLocation.bind(this)} styles={selectStyles} />
                        </div>
                    </div>      			
				}
				{(this.state.division==='plz') &&
					<div>
						<div>
							<p className='label' htmlFor="location">Select location</p>
							<Select className='locations' isClearable={true} options={this.state.plz_} onChange={this.handleChangeLocation.bind(this)} styles={selectStyles} />
						</div>
					</div>  
				}
                {(this.state.division==='kanton') &&
					<div>
						<div>
							<p className='label' htmlFor="location">Select location</p>
							<Select className='locations' isClearable={true} options={this.state.kantons_} onChange={this.handleChangeLocation.bind(this)} styles={selectStyles} />
						</div>
					</div>  
				}
				{ (this.state.division==='country') &&
					<div>
						<div>
							<p className='label' htmlFor="location">Select location</p>
							<Select className='locations' isClearable={true} isDisabled={true} styles={selectStyles} />
						</div>
					</div>
				}
                            </div>
                            <div>
                            <p className='label'>Modal split to win</p>
                <div className="slider-centering">
                  <Slider min={0} max={100} step={1} defaultValue={this.state.percentageMS} onChange={_.debounce(this.percentageMSSliderHandler.bind(this),33)}/>
                  <p className='label'>Showing what will happen if we change {this.state.percentageMS}% <br/> of modal split {(this.state.value_location?.label===null? '': 'in '+this.state.value_location.label)}</p>
                </div>
                            </div>
                            <div>
                            <p className='label'>Optimization per OD</p>
                <div className="slider-centering">
                  <Slider min={0} max={100} step={1} defaultValue={this.state.percentageOptimization} onChange={_.debounce(this.percentageOptimizationSliderHandler.bind(this),33)}/>
                  <p className='label'>Showing what will happen if we change {this.state.percentageOptimization}% <br/> max in every OD route</p>
                </div>
                            </div>
							<div>
								<p className='label'>Enable same origin and destination</p>
								<div className="row">
								<Form onChange={this.schemaTypeSameOD.bind(this)}>
									<div className="column-1-eq">
										<Form.Check inline label=" Yes" name="same_od" type='radio' id='1' defaultChecked={true}/>
									</div>
									<div className="column-2-eq">
                                    	<Form.Check inline label=" No" name="same_od" type='radio' id='0' />
									</div>
								</Form>
								</div>
							</div>
							<div>
								<p className='label'>Aggregation by municipalities</p>
								<div className="row">
								<Form onChange={this.schemaTypeAggregationBFS.bind(this)}>
									<div className="column-1-eq">
										<Form.Check inline label=" Yes" name="same_bfs" type='radio' id='1' />
									</div>
									<div className="column-2-eq">
                                    	<Form.Check inline label=" No" name="same_bfs" type='radio' id='0' defaultChecked={true}/>
									</div>
								</Form>
								</div>
							</div>
							<div className="row">
							<div className="column-1-eq">
                  <Form>
                    <Button variant="info" disabled={this.state.allparams} className="generate-btn" onClick={this.whatifgenerator.bind(this)}>What if...?</Button>
                  </Form>   
				  </div>
				  <div className="column-2-eq">
					{!(this.state.response_breakdown.length === 0) &&
						<ExportExcel excelData={ExcelExportDataOD} fileName={'What if Analysis Export'}/>
					}
					{(this.state.response_breakdown.length === 0) &&
						<Button variant="info" disabled={true} className="generate-btn">Excel Export</Button>
					}
				  </div>
              </div>
					</div>
				</div>

		<div className="map-month-year-picker">
			<Select className='dates' options={this.state.db_od_trips_} onChange={this.handleMonthYear.bind(this)} styles={selectDate} defaultValue={this.state.value_od_trips}/>
		</div>
	</div>
		)
	}
}

//Map - What if: Potential Routes
class MapWhatIfPotential extends React.Component{

	// Set up states for updating map 
	constructor(props){
		super(props);
		this.state = {
			lng:8.251313,
			lat: 46.924143,
			zoom: 7,
			mapita:9,
			tyle: 'mapbox://styles/mapbox/light-v10',
			selection: 'bfs',
			month_year: '2022-08',
			hasOriginDestination: false,
			graphParam: 'bfs,empty,empty',
			currentDestination: 'empty',
			origin: 0,
			destination: 0,
			hideNavs: false
		}
		this.handleTyle = this.handleTyle.bind(this);
	}

	//Update several fields
	updateFields(list) {
		this.setState(list);
	}

	//Update map on component update
	updateField(card, value) {
		this.setState({
            ...this.state,
            [card]: value
        });
	}

	//Update coordinates
    handleCoord(center, zoom){
        const {lng, lat} = center;
        this.setState({zoom: zoom, lat: lat, lng: lng})
    }
	
	//Update style
	handleTyle(value) {
		this.setState({tyle: value}, this.componentDidMount);
	}

	componentDidMount() {
		// Set up map 
		const map = new mapboxgl.Map({
			container: this.mapContainer,
			style: this.state.tyle,
			center: [this.state.lng, this.state.lat],
			zoom: this.state.zoom, 
		});

		map.on('load', () => {
			// Add zoom and rotation controls to the map.
			map.addControl(new mapboxgl.NavigationControl({showCompass: false, showZoom: false}), 'top-left');
			map.addControl( new MapboxGeocoder({ accessToken: mapboxgl.accessToken, mapboxgl: mapboxgl, marker: false}), 'top-left' );

			// add lines to the map
			map.addSource('data-whatif-lines', {
				'type': 'geojson',
				'data': URL_lines,
				'generateId': true,	
				'lineMetrics': true
			});
            
			map.addLayer({
				'id': 'data-whatif-lines',
				'type': 'line',
				'source': 'data-whatif-lines',
				'layout': {},
				'paint': {
                    'line-color': '#31572c',
                    'line-width': 2,
                    // 'line-gradient' must be specified using an expression
                    // with the special 'line-progress' property
                    'line-gradient': [
                        'interpolate',
                        ['linear'],
                        ['line-progress'],
                        0,
                        '#057af0',
                        0.25,
                        '#0466c8',
                        0.50,
                        '#024fa1',
                        0.75,
                        '#00397a',
                        1,
                        '#001d52',
                        ]
                }
				});
			

			
		});

		this.setState({
			mapita: map,
			zoom: map.getZoom(),
		  });

        
          map.on('click', 'data-whatif-lines', (e) => {

            fetch(URL+'train_vs_cars/detail/'+this.state.month_year+',plz/'+e.features[0].properties._c0)
                .then((res) => res.json())
                    .then((json) => {
            // Copy coordinates array.
            const coordinates = json.geometry.coordinates.slice();
            // Ensure that if the map is zoomed out such that multiple
            // copies of the feature are visible, the popup appears
            // over the copy being pointed to.
              while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
              coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
              }
    
              let num_passengers_car_wd = json.properties.car_trips_weekdays;
              let num_passengers_car_we = json.properties.car_trips_weekend;
              let num_passengers_train_wd = json.properties.train_trips_weekdays;
              let num_passengers_train_we = json.properties.train_trips_weekend;
              let num_passengers_we_total = json.properties.total_trips_weekend;
              let num_passengers_wd_total = json.properties.total_trips_weekdays;
    
              let num_passengers_car_wd_str ='';
              let num_passengers_car_we_str ='';
              let num_passengers_train_wd_str ='';
              let num_passengers_train_we_str ='';
              let num_passengers_we_total_str ='';
              let num_passengers_wd_total_str ='';	  
    
              (typeof num_passengers_car_wd == 'number' & num_passengers_car_wd>0) ? num_passengers_car_wd = (num_passengers_car_wd).toFixed(0) : num_passengers_car_wd = '-';
              (num_passengers_car_wd !== '-') ? num_passengers_car_wd_str = numberWithCommas(num_passengers_car_wd): num_passengers_car_wd_str = '-';
              
              (typeof num_passengers_car_we == 'number' & num_passengers_car_we>0) ? num_passengers_car_we = (num_passengers_car_we).toFixed(0) : num_passengers_car_we = '-';
              (num_passengers_car_we !== '-') ? num_passengers_car_we_str = numberWithCommas(num_passengers_car_we): num_passengers_car_we_str = '-';
              
              (typeof num_passengers_train_wd == 'number' & num_passengers_train_wd>0) ? num_passengers_train_wd = (num_passengers_train_wd).toFixed(0) : num_passengers_train_wd = '-';
              (num_passengers_train_wd !== '-') ? num_passengers_train_wd_str = numberWithCommas(num_passengers_train_wd): num_passengers_train_wd_str = '-';
    
              (typeof num_passengers_train_we == 'number' & num_passengers_train_we>0) ? num_passengers_train_we = (num_passengers_train_we).toFixed(0) : num_passengers_train_we = '-';
              (num_passengers_train_we !== '-') ? num_passengers_train_we_str = numberWithCommas(num_passengers_train_we): num_passengers_train_we_str = '-';
    
              (typeof num_passengers_wd_total == 'number' & num_passengers_wd_total>0) ? num_passengers_wd_total = (num_passengers_wd_total).toFixed(0) : num_passengers_wd_total = '-';
              if(num_passengers_wd_total < (num_passengers_train_wd+num_passengers_car_wd) & num_passengers_wd_total !== '-'){
                num_passengers_wd_total = Number(num_passengers_train_wd) + Number(num_passengers_car_wd);
              }
              (num_passengers_wd_total !== '-') ? num_passengers_wd_total_str = numberWithCommas(num_passengers_wd_total): num_passengers_wd_total_str = '-';
    
              (typeof num_passengers_we_total == 'number' & num_passengers_we_total>0) ? num_passengers_we_total = (num_passengers_we_total).toFixed(0) : num_passengers_we_total = '-';
              (num_passengers_we_total !== '-') ? num_passengers_we_total_str = numberWithCommas(num_passengers_we_total): num_passengers_we_total_str = '-';
    
                let end_station = json.properties.destination_name;
                let start_station = json.properties.origin_name;
    
                let html_add =''
                let operators = ''
                if(typeof json.properties.duration_car_s == 'number')
                {
                    let time_car = (json.properties.duration_car_s/60).toFixed(1);
                    let time_train = (json.properties.train_travel_time_min).toFixed(1);
                    let stops = json.properties.n_stops_total.toFixed(0);
                    let num_trains = json.properties.n_trains.toFixed(1);
                    let waiting_min = json.properties.waiting_time_min.toFixed(1);
                    operators = json.properties.operators;
                    let difference = (time_car-time_train).toFixed(1);
                    let color;
                    (difference >= 0) ? color = '#409326' : color = '#C31A1A';
                    html_add = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Travel time</span></p><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle;"><thead><tr><th>&#128664;</th><th>&#x1f686;</th><th>&#128337;</th></tr></thead><tbody><tr><td>'+time_car+'min </td><td>'+time_train+'min </td><td><span style="color: '+color+'; font-weight: bold;">'+difference+'min </span></td></tr></tbody></table><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle;"><tbody><tr><td><span style="color: gray; font-size: 11px;">Stops: '+stops+'</span></td><td><span style="color: gray; font-size: 11px;">Trains: '+num_trains+'</span></td><td><span style="color: gray; font-size: 11px;">Waiting time: '+waiting_min+'min </span></td></tr></tbody></table>';
                }
    
                let html_operators = '';
                let html_id_plz = '';
                let html_recommendation = ''
                if(this.state.selection === 'plz')
                {
                    html_id_plz = '<h4 style="margin-block-start:0px; margin-block-end:0px;"> Postal code: '+json.properties.origin+' ~ '+json.properties.destination+'</h4>';
                    if(operators !== null){
                        html_operators = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Operators</span></p>'+operators+'</p>';
                    }
                    let recommendation = json.properties.recommendation;
                    html_recommendation = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Recommendation</span></p>'+recommendation+'</p>';	
                }
    
                let origin_lat = json.geometry.coordinates[0][1];
                let origin_long = json.geometry.coordinates[0][0];
                let destination_lat = json.geometry.coordinates[1][1];
                let destination_long = json.geometry.coordinates[1][0];
                let google_maps_url = 'https://www.google.com/maps/dir/'+origin_lat+','+origin_long+'/'+destination_lat+','+destination_long;
    
                let html_button = '<a href="'+google_maps_url+'" target="_blank"><button style="background-color: #0c8a8a;color: white;border: 1px solid #e4e4e4;padding: 8px;border-radius: 6px;cursor: pointer;">Google Maps Route</button></a>';
                let html_modal = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Modal Split</span></p><p style="margin-block-end: 0em;"><span style="font-weight: semibold;">No data available</span></p>';
    
                let cars_percentage = 0;
                let trains_percentage = 0;
                if(this.state.day_week === 'days')
                {
                    cars_percentage = (Number(json.properties.modal_split_cars_weekdays)*100).toFixed(0);
                    trains_percentage = (Number(json.properties.modal_split_trains_weekdays)*100).toFixed(0);
                }
                else {
                    cars_percentage = (Number(json.properties.modal_split_cars_weekends)*100).toFixed(0);
                    trains_percentage = (Number(json.properties.modal_split_trains_weekends)*100).toFixed(0);
                }
    
                let other_percentage = (Number(100) - (Number(cars_percentage) + Number(trains_percentage))).toFixed(0);
                
                if((num_passengers_wd_total !== '-') & (num_passengers_car_wd !== '-') & (num_passengers_train_wd !== '-'))
                {
                    html_modal = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Modal Split</span></p><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle; width: 100%;"><thead><tr><th></th><th style="width:50%; text-align: start;">Car/Bus</th><th style="width:50%; text-align: end;"><span style="float:right; position:relative, width:50%">Trains</span></th></tr></thead><tbody><tr><th></th><th style="width:50%; text-align: start; font-weight: normal;">'+cars_percentage+'%</th><th style="width:50%; text-align: end;"><span style="float:right; position:relative, width:50%; font-weight: normal;">'+trains_percentage+'%</span></th></tr></tbody></table><span class="block" style="display: block;height: 20px;color: #fff;font-size: 1em;float: left;background-color: #F78948;position: relative;overflow: hidden;width:'+cars_percentage+'%"></span><span class="block" style="display:block; height:20px; color:#fff; font-size:1em; float:left; background-color:#2f3338; position:relative; overflow:hidden; width:'+other_percentage+'%;"></span><span class="block" style="display:block; height:20px; color:#fff; font-size:1em; float:left; background-color:#0fabab; position:relative; overflow:hidden; width:'+trains_percentage+'%;"></span>';
                }
    
                let html = '<h3 style="margin-block-start:0px; margin-block-end:0px;">'+start_station+' ~ '+end_station+'</h3>'+html_id_plz+'<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Passengers per day</span></p><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle;"><thead><tr><th></th><th>&#x1f46a; &#128664;</th><th>&#x1f46a; &#x1f686;</th><th>Total</th></tr></thead><tbody><tr><th>Weekdays</th><td>'+num_passengers_car_wd_str+'</td><td>'+num_passengers_train_wd_str+'</td><td>'+num_passengers_wd_total_str+'</td></tr><tr><th>Weekends</th><td>'+num_passengers_car_we_str+'</td><td>'+num_passengers_train_we_str+'</td><td>'+num_passengers_we_total_str+'</td></tr></tbody></table>';
                new mapboxgl.Popup()
                      .setLngLat(e.lngLat)
                      .setHTML(html+html_modal+html_add+html_recommendation+html_operators+html_button).addTo(map);
                })
            });
            
            // Change the cursor to a pointer when the mouse is over the places layer.
            map.on('mouseenter', 'swiss-recommendation', () => {
            map.getCanvas().style.cursor = 'pointer';
    
            });
            
            // Change it back to a pointer when it leaves.
            map.on('mouseleave', 'swiss-recommendation', () => {
            map.getCanvas().style.cursor = '';
            });

	}

	render(){
		const { hideNavs } = this.state;

		return(
			<div>
				<div ref = {el => this.mapContainer = el} style = {{width:'100%',height:'100vh'}}>
				</div>
				<RecommendationNavBarComponent/>
				<HideElementsComponent map={this.state.mapita} updateField={(card, field, value) => this.updateField(card, field, value)}/>
				<div style={{ display: hideNavs ? 'none' : 'block' }}>
				<MobilityInsightsAnalyzerComponent map = {this.state.mapita} zoom ={this.state.zoom} {...this.state} updateField={(card, field, value) => this.updateField(card, field, value)} updateFields={(list) => this.updateFields(list)}/>
					</div>
                <LayerTylesComponent map = {this.state.mapita} tyle ={this.state.tyle} handleTyle={(value) => this.handleTyle(value)} handleCoord={(value1, value2) => this.handleCoord(value1, value2)}/>
			</div>
		)
	}
}

export default MapWhatIfPotential;