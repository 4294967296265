import React from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import '../css/map-recommendation.css';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '../css/mapbox-gl-geocoder.css';
import RecommendationNavBarComponent from "../../components/RecommendationNavBarComponent";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const URL = process.env.REACT_APP_URL_SERVER_BACK;

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
}

//Map- Mobility insights
class MappTrafficCounters extends React.Component{

	// Set up states for updating map 
	constructor(props){
		super(props);
		this.state = {
			lng:8.251313,
			lat: 46.924143,
			zoom: 7,
			mapita:9,
		}
	}

	//Update map on component update
	updateField(card, value) {
		this.setState({
            ...this.state,
            [card]: value
        });
	}

	componentDidMount() {

		// Set up map 
		const map = new mapboxgl.Map({
			container: this.mapContainer,
			style: 'mapbox://styles/mapbox/light-v10',
			center: [this.state.lng, this.state.lat],
			zoom: this.state.zoom, 
		});

		map.on('load', () => {

			// Add zoom and rotation controls to the map.
			map.addControl(new mapboxgl.NavigationControl({showCompass: false, showZoom: false}), 'top-left');
			map.addControl( new MapboxGeocoder({ accessToken: mapboxgl.accessToken, mapboxgl: mapboxgl, marker: false}), 'top-left' );
			
			// add lines to the map
			map.addSource('data-swiss-traffic-counters', {
				'type': 'geojson',
				'data': URL+"simulated-traffic-counters",
				'generateId': true,
				'lineMetrics': true
			});
			map.addLayer({
				'id': 'swiss-traffic-counters',
				'type': 'line',
				'source': 'data-swiss-traffic-counters',
				'layout': {},
				'paint': {
				'line-color': '#0e0eb5',
				'line-width': 2,
				}
				});
			
		});

		this.setState({
			mapita: map,
		  }, () => console.log(this.state));
		  
	// When a click event occurs on a feature in the places layer, open a popup at the
	// location of the feature, with description HTML from its properties.
	map.on('click', 'swiss-traffic-counters', (e) => {

			let cars = Number(e.features[0].properties.counts/22).toFixed(0);	  
			cars = numberWithCommas(cars);

			let html = '<h3 style="margin-block-start:0px; margin-block-end:0px;">Number of cars daily</h3><p style="margin-block-end: 0em;"><span>'+cars+'</span></p>';

				new mapboxgl.Popup()
		  		.setLngLat(e.lngLat)
		  		.setHTML(html).addTo(map);
		});
		
		// Change the cursor to a pointer when the mouse is over the places layer.
		map.on('mouseenter', 'swiss-traffic-counters', () => {
		map.getCanvas().style.cursor = 'pointer';
		});
		
		// Change it back to a pointer when it leaves.
		map.on('mouseleave', 'swiss-traffic-counters', () => {
		map.getCanvas().style.cursor = '';
		});

	}

	render(){
		return(
			<div>
				<div ref={el => this.mapContainer = el} style={{width:'100%',height:'100vh'}}>
				</div>
				<RecommendationNavBarComponent/>
			</div>
		)
	}
}

export default MappTrafficCounters;
