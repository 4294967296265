import React from "react";
import { IconContext } from "react-icons";
import { Link } from "react-router-dom";
import { FaCity } from "react-icons/fa";
import { BsBuilding } from "react-icons/bs";


import RecommendationNavBarComponentHilti from "../components/RecommendationNavBarComponentHilti";

import './css/menu.css'


//Menu - Main view
class Hilti_Menu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            current_page: 0,
            max_page: 1
        }
    }

    moveLeft() {
        let the_state = this.state.current_page;
        if (the_state > 0) {
            this.setState({ current_page: the_state - 1 })
        }
    }

    moveRight() {
        let the_state = this.state.current_page;
        if (the_state < this.state.max_page) {
            this.setState({ current_page: the_state + 1 })
        }
    }

    render() {
        return (
            <div className="main-div-menu">
                <div className="main-div-menu-inner">
                    <div className="row-1">
                        <div className='image-main-menu'>
                            <img src={require('../assets/img/logo-navbar.png')} alt="Logo ÖV42 by 42hacks" className='logo-img' />
                            <p className="powered-by-main-menu">Powered by <img src={require('../assets/img/swisscom-logo-navbar.png')} alt="swisscom" className='image-swisscom-main-menu' /> <span className="swisscom-font"></span></p>
                            <p className="title-welcome">Welcome to our Mobility Insights' Platform </p>
                        </div>
                    </div>
                    <div className="row" style={{ justifyContent: 'center' }}>
                        <Link to="/hilti/bus-potential" style={{ textDecoration: 'none' }}>
                            <div className="centered-item-menu">
                                <div className="item-menu-inner">
                                    <IconContext.Provider value={{ color: "#e32a8d", size: '2.2em' }}>
                                        <div>
                                            <BsBuilding />
                                        </div>
                                    </IconContext.Provider>
                                    <p className="title">B-to-B Potential</p>
                                    <p className="label">Utilizes AI algorithms to plan efficient routes for businesses in high-demand areas, saving resources for operators, enhancing passenger experience, and promoting sustainable mobility solutions.</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="row" style={{ justifyContent: 'center' }}>
                        <Link to='/hilti/employer-analyzer' style={{ textDecoration: 'none' }}>
                            <div className="centered-item-menu">
                                <div className="item-menu-inner">
                                    <IconContext.Provider value={{ color: "#027f80", size: '2.2em' }}>
                                        <div>
                                            <FaCity />
                                        </div>
                                    </IconContext.Provider>
                                    <p className="title">Employer Analyzer<sub><i>Beta</i></sub></p>
                                    <p className="label">Enable the selection of a company, one to all of its headquarters, to analyze how employees commute with a tile resolution of up to 100x100 meters. It provides an ÖV Quality analysis based on the selected headquarters.</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
                <RecommendationNavBarComponentHilti />
            </div>

        )
    }
}

export default Hilti_Menu;