import React, {useState} from 'react';
import {Map} from 'react-map-gl';
import DeckGL from '@deck.gl/react';
import {MapView} from '@deck.gl/core';
import { ArcLayer, PolygonLayer } from '@deck.gl/layers';
import Slider from 'rc-slider';
import axios from 'axios';
import { IconContext } from "react-icons";
import { HiOutlineCubeTransparent } from "react-icons/hi";
import Select from 'react-select';
import { Link } from "react-router-dom";
import { BsLayersHalf } from "react-icons/bs";

import '../css/map-recommendation-deckgl.css';

const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const URL = process.env.REACT_APP_URL_SERVER_BACK;

const _ = require('lodash');

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
}

function hexToRgb(hex) {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null;
  }

let MAPBOX_TYLE_STYLE = 'mapbox://styles/mapbox/light-v10';

//Layer Tyles Selection - Side Menu
class LayerTylesComponent extends React.Component{

	constructor(){
		super()
		this.state ={
			showOptions: false,
		}
		this.hideComponent = this.hideComponent.bind(this);
		this.handleTyleEvent = this.handleTyleEvent.bind(this);
	}

	hideComponent(name)
	{
		switch (name) {
			case "showOptions":
			  this.setState({ showOptions: !this.state.showOptions });
			  break;
			default:
			  // eslint-disable-next-line no-unused-expressions
			  null;
		}
	}

	handleTyleEvent(event) {
		switch (event) {
			case "default":
				MAPBOX_TYLE_STYLE = "mapbox://styles/mapbox/light-v10";
				break;
			case "outdoors":
				MAPBOX_TYLE_STYLE = "mapbox://styles/mapbox/outdoors-v11";
				break;
			case "satellite":
        MAPBOX_TYLE_STYLE = "mapbox://styles/mapbox/satellite-streets-v11";
				break;
			case "streets":
        MAPBOX_TYLE_STYLE = "mapbox://styles/mapbox/streets-v11";
				break;
			case "dark":
        MAPBOX_TYLE_STYLE = "mapbox://styles/mapbox/dark-v10";
				break;
			default:
				// eslint-disable-next-line no-unused-expressions
				null;
		}
	}

	render(){
		const { showOptions } = this.state;

		return(
			<div>
				{showOptions ? <>
				<div id='layer-options' className='map-layer-options-tyles'>
					<div className='map-layer-option-dark' onClick={() => this.handleTyleEvent("dark")}>
						<IconContext.Provider value={{ color: "#5B5B5B", size:'1.6em'}}>
							<div>
								<img src={require('../../assets/img/Dark.png')} alt="Default tyle" className='img-tyle'/>
                        	</div>
                    	</IconContext.Provider>
						<p className='label'>Dark</p>
					</div>
					<div className='map-layer-option-streets' onClick={() => this.handleTyleEvent("streets")}>
						<IconContext.Provider value={{ color: "#5B5B5B", size:'1.6em'}}>
							<div>
								<img src={require('../../assets/img/Streets.png')} alt="Default tyle" className='img-tyle'/>
                        	</div>
                    	</IconContext.Provider>
						<p className='label'>Streets</p>
					</div>
					<div className='map-layer-option-satellite' onClick={() => this.handleTyleEvent("satellite")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size:'1.6em'}}>
                        	<div>
								<img src={require('../../assets/img/Satellite.png')} alt="Default tyle" className='img-tyle'/>
                        	</div>
                   		 	</IconContext.Provider>
							<p className='label'>Satellite</p>
					</div>
					<div className='map-layer-option-outdoors' onClick={() => this.handleTyleEvent("outdoors")}>
						<IconContext.Provider value={{ color: "#5B5B5B", size:'1.6em'}}>
							<div>
								<img src={require('../../assets/img/Outdoors.png')} alt="Default tyle" className='img-tyle'/>
                        	</div>
                    	</IconContext.Provider>
						<p className='label'>Outdoors</p>
					</div>
					<div className='map-layer-option-default' onClick={() => this.handleTyleEvent("default")}>
						<IconContext.Provider value={{ color: "#5B5B5B", size:'1.6em'}}>
                        	<div>
								<img src={require('../../assets/img/White.png')} alt="Default tyle" className='img-tyle'/>
                        	</div>
                    	</IconContext.Provider>
						<p className='label'>Default</p>
					</div>
				</div>
				</> : <></>}
				<div className='map-layer-tyles'>
                    <div className='map-layer-tyles-inner' onClick={() => this.hideComponent("showOptions")}>
					<IconContext.Provider value={{ color: "#5B5B5B", size:'1.8em'}}>
                        <div>
                         <BsLayersHalf />
                        </div>
                    </IconContext.Provider>
                </div>
			</div>
		</div>
		)
	}
}

//RecommendationDeckGLNavBar NavBar Component - Dropdown Menu and Radio Btn
class RecommendationDeckGLNavBarComponent extends React.Component{

	render(){
		return(
			<div>
				<div className='map-navbar'>
                    <div className='map-navbar-inner'>
                        <div className='map-nabar-logo'>
                            <img src={require('../../assets/img/logo-navbar.png')} alt="Logo ÖV42 by 42hacks" className='logo-img'/>
                        </div>
                    </div>
				</div>
			</div>
		)
	}
}
let HEATMAP_TYPE = '_heat_map_swisscom_bfs/';
let URL_MAP_TYPE_RECOMMENDATION = 0;
let URL_MAP_DISTANCE_LOW = 0;
let URL_MAP_DISTANCE_HIGH = 290000;
let URL_MAP_TIME_LOW = -2000;
let URL_MAP_TIME_HIGH = 2000;
let URL_MAP_ORIGIN = 0;
let URL_MAP_DESTINATION = 0;

let URL_INSIGHTS = URL+"train_vs_cars_swisscom/0,"+URL_MAP_TIME_LOW+","+URL_MAP_TIME_HIGH+","+URL_MAP_DISTANCE_LOW+","+URL_MAP_DISTANCE_HIGH+","+URL_MAP_TYPE_RECOMMENDATION+","+URL_MAP_ORIGIN+","+URL_MAP_DESTINATION+".geojson";
let URL_HEATMAP_O = URL+'o'+HEATMAP_TYPE+'10000.geojson';
let URL_HEATMAP_D = URL+'d'+HEATMAP_TYPE+'10000.geojson';

const MAP_VIEW = new MapView({repeat: true});
const INITIAL_VIEW_STATE = {
  latitude: 46.924143,
  longitude: 8.251313,
  zoom: 7,
  pitch: 0,
  bearing: 0
};

function renderTooltip(info) {

  if( Object.keys(info).length !== 0 )
  {
    const {x, y, object, layer} = info;

    if(object !== undefined & layer.id ==='arcs-layer') 
    {
      let num_passengers_car_wd_str ='';
      let num_passengers_car_we_str ='';
      let num_passengers_train_wd_str ='';
      let num_passengers_train_we_str ='';
      let num_passengers_we_total_str ='';
      let num_passengers_wd_total_str ='';

		  let num_passengers_car_wd = object.properties.car_trips_weekdays_bfs;
		  let num_passengers_car_we = object.properties.car_trips_weekends_bfs;
		  let num_passengers_train_wd = object.properties.train_trips_weekdays_bfs;
		  let num_passengers_train_we = object.properties.train_trips_weekends_bfs;
		  let num_passengers_we_total = object.properties.total_trips_weekends_bfs;
		  let num_passengers_wd_total = object.properties.total_trips_weekdays_bfs;

      (typeof num_passengers_car_wd == 'number' & num_passengers_car_wd>0) ? num_passengers_car_wd = num_passengers_car_wd.toFixed(0) : num_passengers_car_wd = '-';
		  (num_passengers_car_wd !== '-') ? num_passengers_car_wd_str = numberWithCommas(num_passengers_car_wd): num_passengers_car_wd_str = '-';
		  
		  (typeof num_passengers_car_we == 'number' & num_passengers_car_we>0) ? num_passengers_car_we = num_passengers_car_we.toFixed(0) : num_passengers_car_we = '-';
		  (num_passengers_car_we !== '-') ? num_passengers_car_we_str = numberWithCommas(num_passengers_car_we): num_passengers_car_we_str = '-';
		  
		  (typeof num_passengers_train_wd == 'number' & num_passengers_train_wd>0) ? num_passengers_train_wd = num_passengers_train_wd.toFixed(0) : num_passengers_train_wd = '-';
		  (num_passengers_train_wd !== '-') ? num_passengers_train_wd_str = numberWithCommas(num_passengers_train_wd): num_passengers_train_wd_str = '-';

		  (typeof num_passengers_train_we == 'number' & num_passengers_train_we>0) ? num_passengers_train_we = num_passengers_train_we.toFixed(0) : num_passengers_train_we = '-';
		  (num_passengers_train_we !== '-') ? num_passengers_train_we_str = numberWithCommas(num_passengers_train_we): num_passengers_train_we_str = '-';

		  (typeof num_passengers_wd_total == 'number' & num_passengers_wd_total>0) ? num_passengers_wd_total = num_passengers_wd_total.toFixed(0) : num_passengers_wd_total = '-';
		  
      if(num_passengers_wd_total < (num_passengers_train_wd+num_passengers_car_wd) & num_passengers_wd_total !== '-')
      {num_passengers_wd_total = Number(num_passengers_train_wd) + Number(num_passengers_car_wd);}

		  (num_passengers_wd_total !== '-') ? num_passengers_wd_total_str = numberWithCommas(num_passengers_wd_total): num_passengers_wd_total_str = '-';

		  (typeof num_passengers_we_total == 'number' & num_passengers_we_total>0) ? num_passengers_we_total = num_passengers_we_total.toFixed(0) : num_passengers_we_total = '-';
		  (num_passengers_we_total !== '-') ? num_passengers_we_total_str = numberWithCommas(num_passengers_we_total): num_passengers_we_total_str = '-';

            let end_station = object.properties.destination_name;
            let start_station = object.properties.origin_name;
            let time_car = (object.properties.duration_car_s/60).toFixed(1);
            let time_train = (object.properties.train_travel_time_min).toFixed(1);
            let stops = object.properties.n_stops_total;
            let num_trains = object.properties.n_trains;
            let waiting_min = object.properties.waiting_time_min.toFixed(2);
			let recommendation = object.properties.recomendations;
			let difference = (time_car-time_train).toFixed(1);
			let color;
			(difference >= 0) ? color = '#409326' : color = '#C31A1A';

            let modalSplit = false;

				let cars_percentage = 0;
				let trains_percentage = 0;
				let other_percentage = 0;
            if((num_passengers_wd_total !== '-') & (num_passengers_car_wd !== '-') & (num_passengers_train_wd !== '-'))
			{
				cars_percentage = (num_passengers_car_wd/num_passengers_wd_total*100).toFixed(0);
				trains_percentage = (num_passengers_train_wd/num_passengers_wd_total*100).toFixed(0);
				other_percentage = (Number(100) - (Number(cars_percentage) + Number(trains_percentage))).toFixed(0);
                modalSplit = true;
			}

            let isPLCalculated = false;
            let p_l = 0;
            if((num_passengers_car_wd !== '-')){
				let earnings = (num_passengers_car_wd) * 0.035 * (0.43*(object.properties.distance_train_m/1000))
				let cost = (-870.80/60)*object.properties.travel_time_in_vehicles_min
			  	p_l = (earnings + cost).toFixed(2)
                isPLCalculated = true;
			}
      
      URL_HEATMAP_O = URL+'o'+HEATMAP_TYPE+object.id+'.geojson';
      URL_HEATMAP_D = URL+'d'+HEATMAP_TYPE+object.id+'.geojson';

      return (
        <div className="tooltip interactive" style={{left: x, top: y}}>
            <h3 style={{marginBlockEnd: 0+'px', marginBlockStart: 0+'px'}}>{start_station} ~ {end_station}</h3>
            <p style={{marginBlockEnd: 0+'em'}}>
                <span style={{fontWeight: 'bold'}}>Passengers per day</span>
            </p>
            <table style={{margin: 'auto', tableLayout: 'fixed', padding: 0+'px', textAlign: 'center', verticalAlign: 'middle'}}>
                <thead>
                    <tr>
                        <th></th>
                        <th>&#x1f46a; &#128664;</th>
                        <th>&#x1f46a; &#x1f686;</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>Weekdays</th>
                        <td>{num_passengers_car_wd_str}</td>
                        <td>{num_passengers_train_wd_str}</td>
                        <td>{num_passengers_wd_total_str}</td>
                    </tr>
                    <tr>
                        <th>Weekends</th>
                        <td>{num_passengers_car_we_str}</td>
                        <td>{num_passengers_train_we_str}</td>
                        <td>{num_passengers_we_total_str}</td>
                    </tr>
                </tbody>
            </table>
          	<p>
                <span style={{fontWeight: 'bold'}}>Modal Split</span>
            </p>
            {modalSplit ? 
            <>
                <table style={{margin: 'auto', tableLayout: 'fixed', padding: 0+'px', textAlign: 'center', verticalAlign: 'middle', width: '100%'}}>
                    <thead>
                        <tr>
                            <th></th>
                            <th style={{width: '50%', textAlign: 'start'}}>Car</th>
                            <th style={{width: '50%', textAlign: 'end'}}>
                                <span style={{float: 'right', position: 'relative', width: '50%'}}>Trains</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th></th>
                            <th style={{width: '50%', textAlign: 'start', fontWeight: 'normal'}}>{cars_percentage}%</th>
                            <th style={{width: '50%', textAlign: 'end'}}>
                                <span style={{float: 'right', position: 'relative', width: '50%', fontWeight: 'normal'}}>{trains_percentage}%</span>
                            </th>
                        </tr>
                    </tbody>
                </table>
                <span style={{display: 'block', height: 20+'px', fontSize: 1+'em', float: 'left', backgroundColor: '#F78948', position: 'relative', overflow: 'hidden', width: cars_percentage+'%'}}></span>
                <span style={{display: 'block', height: 20+'px', fontSize: 1+'em', float: 'left', backgroundColor: '#2f3338', position: 'relative', overflow: 'hidden', width: other_percentage+'%'}}></span>
                <span style={{display: 'block', height: 20+'px', fontSize: 1+'em', float: 'left', backgroundColor: '#0fabab', position: 'relative', overflow: 'hidden', width: trains_percentage+'%'}}></span>
            </>
            :
            <>
                <p style={{marginBlockStart: 10+'px'}}>
                    <span style={{fontWeight: 'semibold'}}>No data available</span>
                </p>
                <span style={{display: 'block', height: 20+'px', fontSize: 1+'em', float: 'left', backgroundColor: '#939696', position: 'relative', overflow: 'hidden', width: '100%'}}></span>
            </>}
            <p style={{marginBlockEnd: 0+'em', marginBlockStart: 3+'em'}}>
                <span style={{fontWeight: 'bold'}}>Travel time</span>
            </p>
            <table style={{margin: 'auto', tableLayout: 'fixed', padding: 0+'px', textAlign: 'center', verticalAlign: 'middle'}}>
                <thead>
                    <tr>
                        <th>&#128664;</th>
                        <th>&#x1f686;</th>
                        <th>&#128337;</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{time_car}min </td>
                        <td>{time_train}min </td>
                        <td><span style={{color: color, fontWeight: 'bold'}}>{difference}min </span></td>
                    </tr>
                </tbody>
            </table>
            <table style={{margin: 'auto', tableLayout: 'fixed', padding: 0+'px', textAlign: 'center', verticalAlign: 'middle'}}>
                <tbody>
                    <tr>
                        <td><span style={{color: 'gray', fontSize: 11+'px'}}>Stops: {stops}</span></td>
                        <td><span style={{color: 'gray', fontSize: 11+'px'}}>Trains: {num_trains}</span></td>
                        <td><span style={{color: 'gray', fontSize: 11+'px'}}>Waiting time: {waiting_min}min </span></td>
                    </tr>
                </tbody>
            </table>
            {isPLCalculated ? 
            <>
                <p style={{marginBlockStart: 0+'px'}}>
                    <span style={{marginBlockStart: 0+'px', fontWeight: 'bold'}}> P&amp;L: </span>
                    {p_l} CHF per trip
                </p>
            </>
            :
            <>
                <p style={{marginBlockStart: 0+'px'}}>
                    <span style={{marginBlockStart: 0+'px', fontWeight: 'bold'}}> P&amp;L: </span>
                    No data enough available
                </p>
            </>}
            <p style={{marginBlockEnd: 0+'em'}}>
                <span style={{fontWeight: 'bold'}}>Recommendation</span>
            </p>
            <p>{recommendation}</p>
        </div>
      );
    }
    else if(object !== undefined & (layer.id ==='origin-polygon-layer' || layer.id ==='destination-polygon-layer')) 
    {
        let num_trips = object.properties.trips;
        let num_trips_str = '';

		(typeof num_trips == 'number' & num_trips>0) ? num_trips = (num_trips/22).toFixed(0) : num_trips = '-';
		(num_trips !== '-') ? num_trips_str = numberWithCommas(num_trips): num_trips_str = '-';

      return (
        <div className="tooltip interactive_heatmap" style={{left: x, top: y}}>
            <p style={{marginBlockEnd: 0+'em'}}>
                <span style={{fontWeight: 'bold'}}>Source: </span> 
                { layer.id ==='destination-polygon-layer' ? <>Destination</>: <>Origin</> }
            </p>
            <p style={{marginBlockEnd: 0+'em'}}>
                <span style={{fontWeight: 'normal'}}>{num_trips_str} trips per day</span>
            </p>
        </div>
      );
    }
    else
    {   
      return null; 
    }
  }
  else
  {
    return null;
  }
}

class LegendInsightsComponent extends React.Component {
  render(){

		return(

			<div>
        <div className='map-layer-convention-3d-insights'>
                    <div className='map-layer-convention-3d-insights-inner'>
						<div className="row">
  						<div className="column-1">
							  <p className='label-convention'>200 trips</p>
  						</div>
							<div className="column-2" styles=''>
							  <div className='convention-linear-insights'></div>
  						</div>
  						<div className="column-3">
							  <p className='label-convention'>5,000+ trips</p>
  						</div>
						</div>
          </div>
				</div>
			</div>
		)
	}
}


//Mobility Insights Analyzer Component - Dropdown Menu and Radio Btn
class MobilityInsightsAnalyzerComponent extends React.Component{

	constructor(props) {
		super(props)
    	this.state = {
			mapa: this.props.map,
      division: 'bfs',
			bool_stop: 0,
			low_distance_km: 0,
			high_distance_km: 290,
			low_bound_diff: -40,
			high_bound_diff: 40,
      origin: 0,
      destination: 0,
			selectOptions: [
				{ value: '0', label: 'All' },
				{ value: '1', label: 'Keep it that way' },
				{ value: '2', label: 'Marketing strategy' },
				{ value: '3', label: 'Point-to-point' },
				{ value: '4', label: 'Close line' },
				{ value: '5', label: 'Reduce connection time' },
			  ],
			recommendation_filter: '0',
      municipalities_: this.getOptions(URL+'municipalities-list'),
    	}
	
		this.handleChange = this.handleChange.bind(this);
		this.schemaTypeSelectionHandle = this.schemaTypeSelectionHandle.bind(this);
		this.handleChangeRecommendation = this.handleChangeRecommendation.bind(this);
	}

  async getOptions(url){
		const res = await axios.get(url)
		const data = res.data
		const options = data.map(d => ({
		  "value" : d.id,
		  "label" : d.name
		}))
		this.setState({municipalities_: options})
	  }

	componentDidUpdate(prevProps) {
    if (this.props.map !== prevProps.map) {
		  this.setState({mapa: this.props.map})
		}
	  }

	handleChange(e){
    URL_INSIGHTS = URL+"train_vs_cars_swisscom/0,"+URL_MAP_TIME_LOW+","+URL_MAP_TIME_HIGH+","+URL_MAP_DISTANCE_LOW+","+URL_MAP_DISTANCE_HIGH+","+URL_MAP_TYPE_RECOMMENDATION+","+URL_MAP_ORIGIN+","+URL_MAP_DESTINATION+".geojson";
	};

	timeDifferenceHandler(event){
    this.setState({low_bound_diff: event[0], high_bound_diff: event[1]})
    
    let URL_MAP_TIME_HIGH = event[1];
		if(URL_MAP_TIME_HIGH===40)
		{
			URL_MAP_TIME_HIGH = 2000;
		}
		let URL_MAP_TIME_LOW = event[0];
		if(URL_MAP_TIME_LOW===-40)
		{
			URL_MAP_TIME_LOW = -2000;
		}

    URL_INSIGHTS = URL+"train_vs_cars_swisscom/0,"+URL_MAP_TIME_LOW+","+URL_MAP_TIME_HIGH+","+URL_MAP_DISTANCE_LOW+","+URL_MAP_DISTANCE_HIGH+","+URL_MAP_TYPE_RECOMMENDATION+","+URL_MAP_ORIGIN+","+URL_MAP_DESTINATION+".geojson";
	}

	distanceKilometerHandler(event)
  {
		this.setState({low_distance_km: event[0], high_distance_km: event[1]})
    URL_MAP_DISTANCE_LOW = (Number(event[0])*Number(1000)).toString();
    URL_MAP_DISTANCE_HIGH = (Number(event[1])*Number(1000)).toString();

    URL_INSIGHTS = URL+"train_vs_cars_swisscom/0,"+URL_MAP_TIME_LOW+","+URL_MAP_TIME_HIGH+","+URL_MAP_DISTANCE_LOW+","+URL_MAP_DISTANCE_HIGH+","+URL_MAP_TYPE_RECOMMENDATION+","+URL_MAP_ORIGIN+","+URL_MAP_DESTINATION+".geojson";
	}

	handleChangeRecommendation(event) 
  {
		this.setState({recommendation_filter: event.value});
    URL_MAP_TYPE_RECOMMENDATION = event.value.toString();
    URL_INSIGHTS = URL+"train_vs_cars_swisscom/0,"+URL_MAP_TIME_LOW+","+URL_MAP_TIME_HIGH+","+URL_MAP_DISTANCE_LOW+","+URL_MAP_DISTANCE_HIGH+","+URL_MAP_TYPE_RECOMMENDATION+","+URL_MAP_ORIGIN+","+URL_MAP_DESTINATION+".geojson";
  }

  handleChangeOrigin(event) 
  {
    this.setState({origin: event.value});
    URL_MAP_ORIGIN = event.value.toString();
    URL_INSIGHTS = URL+"train_vs_cars_swisscom/0,"+URL_MAP_TIME_LOW+","+URL_MAP_TIME_HIGH+","+URL_MAP_DISTANCE_LOW+","+URL_MAP_DISTANCE_HIGH+","+URL_MAP_TYPE_RECOMMENDATION+","+URL_MAP_ORIGIN+","+URL_MAP_DESTINATION+".geojson";
  
    URL_HEATMAP_O = URL+'o'+HEATMAP_TYPE+'10000.geojson';
    URL_HEATMAP_D = URL+'d'+HEATMAP_TYPE+'10000.geojson';
  }

  handleChangeDestination(event)
  {
    this.setState({destination: event.value});
    URL_MAP_DESTINATION = event.value.toString();
    URL_INSIGHTS = URL+"train_vs_cars_swisscom/0,"+URL_MAP_TIME_LOW+","+URL_MAP_TIME_HIGH+","+URL_MAP_DISTANCE_LOW+","+URL_MAP_DISTANCE_HIGH+","+URL_MAP_TYPE_RECOMMENDATION+","+URL_MAP_ORIGIN+","+URL_MAP_DESTINATION+".geojson";
    
    URL_HEATMAP_O = URL+'o'+HEATMAP_TYPE+'10000.geojson';
    URL_HEATMAP_D = URL+'d'+HEATMAP_TYPE+'10000.geojson';
  }

	schemaTypeSelectionHandle(event) 
  {
    URL_HEATMAP_O = URL+'o'+HEATMAP_TYPE+'10000.geojson';
    URL_HEATMAP_D = URL+'d'+HEATMAP_TYPE+'10000.geojson';
    this.setState({divisions: event.target.attributes.getNamedItem('id').value});
    if(event.target.attributes.getNamedItem('id').value === 'plz')
    {
      HEATMAP_TYPE = '_heat_map_swisscom_plz/';
    }
    else if(event.target.attributes.getNamedItem('id').value === 'bfs')
    {
      HEATMAP_TYPE = '_heat_map_swisscom_bfs/';
    }
	}

	render(){

		const selectStyles = {
			menuList: styles => {
			  return {
				...styles,
				maxHeight: 90
			  };
			}
		  };

		return(
			<div>
				<div className="map-card-insight-analyzer-modal-3d">
					<div className="map-card-insight-analyzer-modal-3d-inner">
						<h1>Mobility Insights Analyzer</h1>
              <div>
							  <p className='label' htmlFor="Recommendation type">Type of Recommendation</p>
							  <Select className='locations' options={this.state.selectOptions} onChange={this.handleChangeRecommendation.bind(this)} styles={selectStyles} />
							</div>
							<div>
								<p className='label'>Time difference</p>
								<div className='insight-analyzer-modal-slider-inner'>
									<Slider range draggableTrack min={-40} max={40} step={1} defaultValue={[this.state.low_bound_diff, this.state.high_bound_diff]} onChange={_.debounce(this.timeDifferenceHandler.bind(this),33)}/>
								</div>
								<p className='label-selected-value'>Showing trips with a time difference <br/> between <span>{this.state.low_bound_diff}</span> and <span>{this.state.high_bound_diff===40 ? "40+" : ""+this.state.high_bound_diff.toFixed(0)} </span> min</p>
							</div>
							<div>
								<p className='label'>Distance in km</p>
								<div className='insight-analyzer-modal-slider-inner'>
									<Slider range draggableTrack min={0} max={290} step={5} defaultValue={[this.state.low_distance_km, this.state.high_distance_km]} onChange={_.debounce(this.distanceKilometerHandler.bind(this),33)}/>
								</div>
								<p className='label-selected-value'>Showing routes with a distance<br/> between <span>{this.state.low_distance_km}km</span> and <span>{this.state.high_distance_km} </span>km</p>
							</div>
					</div>
				</div>

        <div className='mobility-insights-origin-destionation-3d'>
          <div className='mobility-insights-origin-destionation-3d-inner'>
            <h1>Search for an Specific Trip</h1>
            <div>
							  <p className='label' htmlFor="Origin">Origin</p>
							  <Select className='locations' options={this.state.municipalities_} onChange={this.handleChangeOrigin.bind(this)} styles={selectStyles} />
							</div>
              <div>
							  <p className='label' htmlFor="Destination">Destination</p>
							  <Select className='locations' options={this.state.municipalities_} onChange={this.handleChangeDestination.bind(this)} styles={selectStyles} />
							</div>
          </div>
        </div>

        <div className='map-2d-button-modal'>
					<div className='map-2d-button-modal-inner'>
					<Link to="/" style={{ textDecoration: 'none' }}>
					<IconContext.Provider value={{ color: "#5B5B5B", size:'1.8em'}}>
            <div>
              <HiOutlineCubeTransparent />
            </div>
          </IconContext.Provider>
						</Link>
          </div>
				</div>
        <LegendInsightsComponent />
			</div>
		)
	}
}

/* eslint-disable react/no-deprecated */
export function MapRecommendation3DSwisscom({
  data = URL_INSIGHTS,
}) {
  const [hoverInfo, setHoverInfo] = useState({});

  const hideTooltip = () => {
    setHoverInfo({});
  };
  const expandTooltip = info => {
    if (info.picked) {
      setHoverInfo(info);
    } else {
      setHoverInfo({});
    }
  };

  let isHovering = false;

  const layerProps = {
    data,
    pickable: true,
    getPosition: d => d.coordinates,
    onClick: !hoverInfo.objects && setHoverInfo
  };

  const layers = [
    new ArcLayer({
        ...layerProps,
        id: 'arcs-layer',
        data: URL_INSIGHTS,
        dataTransform: d => d.features.filter(f => {return true}),
        getSourcePosition: f => f.geometry.coordinates[0], // Source position
        getTargetPosition: f => f.geometry.coordinates[1], // Target position
        getSourceColor: f => hexToRgb(f.properties.color),
        getTargetColor: f => hexToRgb(f.properties.color),
        getWidth: 3,
        pickable: true
      }),
      new PolygonLayer({
        id: 'destination-polygon-layer',
        data: URL_HEATMAP_D,
        pickable: true,
        stroked: true,
        filled: true,
        wireframe: true,
        lineWidthMinPixels: 1,
        dataTransform: d => d.features.filter(f => {return true}),
        getPolygon: f => f.geometry.coordinates,
        getElevation: 1,
        getFillColor: f => hexToRgb(f.properties.color),
        getLineColor: [80, 80, 80],
        getLineWidth: 1,
        onHover: ({object}) => (isHovering = Boolean(object)),
        getCursor: ({isDragging}) => (isDragging ? 'grabbing' : (isHovering ? 'pointer' : 'grab'))
      }),
      new PolygonLayer({
        id: 'origin-polygon-layer',
        data: URL_HEATMAP_O,
        pickable: true,
        stroked: true,
        filled: true,
        wireframe: true,
        lineWidthMinPixels: 1,
        dataTransform: d => d.features.filter(f => {return true}),
        getPolygon: f => f.geometry.coordinates,
        getElevation: 1,
        getFillColor: f => hexToRgb(f.properties.color),
        getLineColor: [80, 80, 80],
        getLineWidth: 1,
        onHover: ({object}) => (isHovering = Boolean(object)),
        getCursor: ({isDragging}) => (isDragging ? 'grabbing' : (isHovering ? 'pointer' : 'grab'))
      }),
  ];

  return (
    <>
    <DeckGL layers={[layers]} views={MAP_VIEW} initialViewState={INITIAL_VIEW_STATE} 
      controller={true} onViewStateChange={hideTooltip} onClick={expandTooltip}>
      <Map reuseMaps mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN} mapStyle={MAPBOX_TYLE_STYLE} />
      {renderTooltip(hoverInfo)}
    </DeckGL>
    < RecommendationDeckGLNavBarComponent />
    < MobilityInsightsAnalyzerComponent />
    < LayerTylesComponent />
    </>
  );
}