import React from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { IconContext } from "react-icons";
import { BsLayersHalf } from "react-icons/bs";
import RecommendationNavBarComponent from "../../components/RecommendationNavBarComponent";
import axios from 'axios';
import Select from 'react-select';
import '../css/map-routes.css';
import { Form, Button } from 'react-bootstrap';
import { BiExit } from "react-icons/bi";
import { Link } from "react-router-dom";
import { RiFullscreenFill, RiFullscreenExitFill } from "react-icons/ri";
import ExportExcel from "../../utils/ExportExcel";
import { useParams } from "react-router-dom";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const URL = process.env.REACT_APP_URL_SERVER_BACK;
const URL_FRONT = process.env.REACT_APP_URL_SERVER_FRONT;

let loading = false;

let isOrigin = true;
let selected = false;

const delay = ms => new Promise(res => setTimeout(res, ms));

function numberWithCommas(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

let tile_empty = {
	'type': 'FeatureCollection',
	'features': [
		{
			'type': 'Feature',
			'geometry': {
				"type": "LineString",
				"coordinates": [
				]
			}
		}
	]
};

class MainMenu extends React.Component {
	constructor(props) {
		super(props)
		this.state = {

		}
	}


	render() {
		return (
			<div>
				<Link to="/menu" style={{ textDecoration: 'none' }}>
					<div className='map-close-navs'>
						<div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
								<div>
									<BiExit />
								</div>
							</IconContext.Provider>
						</div>
					</div>
				</Link>
			</div>
		)
	}
}


class HideElementsComponent extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			hideNavs: false,
			mapa: this.props.map,
		}
	}

	hideComponent() {
		let newHideNavs = !this.state.hideNavs;
		this.setState({ hideNavs: newHideNavs })
		this.props.updateField("hideNavs", newHideNavs);
	}

	render() {

		const { hideNavs } = this.state;

		return (
			<div>
				{hideNavs ? <>
					<div className='map-close-navs'>
						<div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
								<div>
									<RiFullscreenExitFill />
								</div>
							</IconContext.Provider>
						</div>
					</div>
				</> : <>
					<div className='map-close-navs'>
						<div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
								<div>
									<RiFullscreenFill />
								</div>
							</IconContext.Provider>
						</div>
					</div>
				</>}
			</div>
		)
	}
}

//Layer Tyles Selection - Side Menu
class LayerTylesComponent extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			showOptions: false,
			mapa: this.props.map,
		}
		this.hideComponent = this.hideComponent.bind(this);
		this.handleTyleEvent = this.handleTyleEvent.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (this.props.map !== prevProps.map) {
			this.setState({ mapa: this.props.map })
		}
	}

	hideComponent(name) {
		switch (name) {
			case "showOptions":
				this.setState({ showOptions: !this.state.showOptions });
				break;
			default:
				// eslint-disable-next-line no-unused-expressions
				null;
		}
	}

	handleTyleEvent(event) {
		switch (event) {
			case "default":
				this.props.handleTyle("mapbox://styles/mapbox/light-v10");
				break;
			case "outdoors":
				this.props.handleTyle("mapbox://styles/mapbox/outdoors-v11");
				break;
			case "satellite":
				this.props.handleTyle("mapbox://styles/mapbox/satellite-streets-v11");
				break;
			case "streets":
				this.props.handleTyle("mapbox://styles/mapbox/streets-v11");
				break;
			case "dark":
				this.props.handleTyle("mapbox://styles/mapbox/dark-v10");
				break;
			default:
				// eslint-disable-next-line no-unused-expressions
				null;
		}
		this.props.handleCoord(this.state.mapa.getCenter(), this.state.mapa.getZoom());
	}

	render() {
		const { showOptions } = this.state;

		return (
			<div>
				{showOptions ? <>
					<div id='layer-options' className='map-layer-options-tyles'>
						<div className='map-layer-option-dark' onClick={() => this.handleTyleEvent("dark")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/Dark.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Dark</p>
						</div>
						<div className='map-layer-option-streets' onClick={() => this.handleTyleEvent("streets")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/Streets.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Streets</p>
						</div>
						<div className='map-layer-option-satellite' onClick={() => this.handleTyleEvent("satellite")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/Satellite.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Satellite</p>
						</div>
						<div className='map-layer-option-outdoors' onClick={() => this.handleTyleEvent("outdoors")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/Outdoors.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Outdoors</p>
						</div>
						<div className='map-layer-option-default' onClick={() => this.handleTyleEvent("default")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/White.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Default</p>
						</div>
					</div>
				</> : <></>}
				<div className='map-layer-tyles'>
					<div className='map-layer-tyles-inner' onClick={() => this.hideComponent("showOptions")}>
						<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
							<div>
								<BsLayersHalf />
							</div>
						</IconContext.Provider>
					</div>
				</div>
			</div>
		)
	}
}

//Modal for filters
class FilterModalComponent extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			mapa: this.props.map,
			excel_data: this.props.excel_data,
			type_of_routes_: this.getOptionRoutes(URL + 'road_loads/network_options'),
			current_type_of_route: ({
				"value": "primary",
				"label": 'primary'
			}),
			type_of_division_: [{
				"value": "plz",
				"label": 'Postal Code'
			}, {
				"value": "1km_tiles",
				"label": '1Kmx1Km Tiles'
			}],
			current_type_of_division: ({
				"value": "plz",
				"label": 'Postal Code'
			}),
			type_od: 'origin',
			coord: this.props.coord
		}
		this.handleFetchDataCoordParam()
	}

	handleFetchDataCoordParam() {
		
        if (this.state.coord['*'] !== "") {
            loading = true;
			selected = true;
			this.handleMapping()
			loading = false;
        }
    }


	async handleMapping(){
		fetch(URL + 'road_loads/return_road_from_coord/' + parseFloat(this.state.coord['*'].split(',')[0]) + ',' + parseFloat(this.state.coord['*'].split(',')[1]) +'/10') 
		.then((res) => res.json())
		.then(async (json) => {
			this.props.updateField("u", json.features[0].properties.u);
			this.props.updateField("v", json.features[0].properties.v);

			await delay(5000);

			const tyle_corner = {
				center: [parseFloat(this.state.coord['*'].split(',')[0]), parseFloat(this.state.coord['*'].split(',')[1])],
				zoom: 10,
				pitch: 0,
				bearing: 0
			}


			this.props.map.flyTo({
				...tyle_corner, // Fly to the selected target
				duration: 2000, // Animate over 2 seconds
				essential: false // This animation is considered essential with
				//respect to prefers-reduced-motion
			})

			this.props.map.getSource('data-routes-od').setData(URL + 'road_loads/origins/plz/' + json.features[0].properties.u + ',' + json.features[0].properties.v)
			return json
		}).then((json) =>{
			this.props.map.getSource('data-swiss-routes-param').setData(json)
		})	
	}

	componentDidUpdate(prevProps) {
		if (this.props.excel_data !== prevProps.excel_data) {
			this.setState({ excel_data: this.props.excel_data })
		}
	}

	componentDidMount() {
		this.handleSelectType = this.handleSelectType.bind(this);
		this.handleODType = this.handleODType.bind(this);
		this.handleSelectDivision = this.handleSelectDivision.bind(this);
		this.handleFetchDataCoordParam = this.handleFetchDataCoordParam.bind(this);
	}

	handleODType(event) {
		this.setState({ type_od: event.target.attributes.getNamedItem('id').value });
		if (event.target.attributes.getNamedItem('id').value === 'origin') {
			isOrigin = true;
			this.props.map.getSource('data-routes-od').setData(URL + 'road_loads/origins/' + this.state.current_type_of_division.value + '/' + this.props.u + ',' + this.props.v)
		}
		else {
			isOrigin = false;
			this.props.map.getSource('data-routes-od').setData(URL + 'road_loads/destinations/' + this.state.current_type_of_division.value + '/' + this.props.u + ',' + this.props.v)
		}
		let isO = event.target.attributes.getNamedItem('id').value === 'origin'
		this.getInfo(this.state.current_type_of_division, isO);
	}

	async getOptionRoutes(url) {
		const res = await axios.get(url)
		const data = res.data
		const options = data.map(d => ({
			"value": d,
			"label": d.replace('-', ' ')
		}))
		this.setState({ type_of_routes_: options });
	}

	handleSelectDivision(event) {
		this.setState({ current_type_of_division: event });
		this.props.updateField("division", event.value);

		if (this.props.u !== '' && this.props.v !== '') {
			if (isOrigin) {
				this.props.map.getSource('data-routes-od').setData(URL + 'road_loads/origins/' + event.value + '/' + this.props.u + ',' + this.props.v)
			} else {
				this.props.map.getSource('data-routes-od').setData(URL + 'road_loads/destinations/' + event.value + '/' + this.props.u + ',' + this.props.v)
			}
			this.getInfo(event, isOrigin);
		}
	}

	handleSelectType(event) {
		this.setState({ current_type_of_route: event });
		this.props.map.getSource('data-swiss-routes').setData(URL + 'road_loads/network/' + event.value);
		this.props.map.getSource('data-routes-od').setData(tile_empty);
		this.props.updateField("u", '');
		selected = false;
		this.props.updateField("v", '');
		this.props.map.getSource('data-swiss-routes-param').setData(tile_empty)
	}

	getInfo(current_type_of_division, subdivision) {
		let flow_direction = (subdivision ? 'origins' : 'destinations')

		fetch(URL + 'road_loads/' + flow_direction + '/' + current_type_of_division.value + '/' + this.props.u + ',' + this.props.v)
			.then((res) => res.json())
			.then((json) => {
				this.props.updateFields({ excel_data: json })
			})
	}

	getExcel() {
		if (selected) {
			let all = [];
			if (this.state.current_type_of_division.value === 'plz') {
				this.props.excel_data.features?.map((trip, index) =>
					all.push({
						"Index": index,
						"PLZ Num.": trip.properties?.PLZ,
						"Place name (Ortschaftsname)": trip.properties?.Ortschaftsname,
						"Kanton Name": trip.properties?.Kanton,
						"Counts of vehicles": trip.properties?.counts,
						"Percentage of traffic": trip.properties?.percentage
					})
				);
			} else {
				this.props.excel_data.features?.map((trip, index) =>
					all.push({
						"Index": index,
						"Corner 1": trip.geometry?.coordinates[0][0][0] + ',' + trip.geometry?.coordinates[0][0][1],
						"Corner 2": trip.geometry?.coordinates[0][1][0] + ',' + trip.geometry?.coordinates[0][1][1],
						"Corner 3": trip.geometry?.coordinates[0][2][0] + ',' + trip.geometry?.coordinates[0][2][1],
						"Corner 4": trip.geometry?.coordinates[0][3][0] + ',' + trip.geometry?.coordinates[0][3][1],
						"Tile ID": trip.properties?.tileId,
						"PLZ Num.": trip.properties?.PLZ,
						"Place name (Ortschaftsname)": trip.properties?.Ortschaftsname,
						"Kanton Name": trip.properties?.Kanton,
						"Counts of vehicles": trip.properties?.counts,
						"Percentage of traffic": trip.properties?.percentage,
						"Tile URL": URL_FRONT + "tile-analysis/" + trip.properties?.tileId
					})
				);
			}
			return all;
		}
	}

	render() {

		const selectStyle = {
			menuList: styles => {
				return {
					...styles,
					maxHeight: 150,
					borderWidth: 0,
					boxShadow: '0 0 10px 2px rgb(0 0 0 / 10%)',
				};
			}
		};

		let ExcelExport = this.getExcel();
		if (loading) { // if your component doesn't have to wait for an async action, remove this block 
			return (
				<div className="bg-loader">
					<div className="pos-center-brain-loader">
						<div className="circle-loading-brain">
						</div>
						<svg className='brain-loader' x="0px" y="0px" viewBox="0 0 496 496">
							<path d="M40,449.472V364.28L58.424,352H88v-16H53.576L24,355.72v93.752C14.704,452.784,8,461.584,8,472c0,13.232,10.768,24,24,24				s24-10.768,24-24C56,461.584,49.288,452.784,40,449.472z M32,480c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8s8,3.584,8,8				C40,476.416,36.416,480,32,480z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M46.528,320H88v-16H46.528c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24				C34.416,336,43.216,329.288,46.528,320z M16,312c0-4.416,3.584-8,8-8s8,3.584,8,8c0,4.416-3.584,8-8,8S16,316.416,16,312z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M24,272c10.416,0,19.216-6.712,22.528-16H88v-16H46.528c-3.312-9.296-12.112-16-22.528-16c-13.232,0-24,10.768-24,24				S10.768,272,24,272z M24,240c4.416,0,8,3.584,8,8s-3.584,8-8,8s-8-3.584-8-8C16,243.584,19.584,240,24,240z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M56 272h32v16H56Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M56 208h32v16H56Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M0 176h88v16H0Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M60.688,160H88v-16H67.312L48,124.688v-14.16C57.288,107.216,64,98.416,64,88c0-13.232-10.768-24-24-24S16,74.768,16,88				c0,10.416,6.704,19.216,16,22.528v20.784L60.688,160z M40,80c4.416,0,8,3.584,8,8s-3.584,8-8,8s-8-3.584-8-8S35.584,80,40,80z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M144 67.312L144 88L160 88L160 60.688L131.312 32L0 32L0 48L124.688 48z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M176 35.312L176 88L192 88L192 28.688L163.312 0L0 0L0 16L156.688 16z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M208 56h16v32H208Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M304 0h16v88H304Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M240,46.528V88h16V59.312l16,16V88h16V68.688L262.392,43.08C268.192,38.696,272,31.816,272,24c0-13.232-10.768-24-24-24				s-24,10.768-24,24C224,34.416,230.704,43.216,240,46.528z M248,16c4.416,0,8,3.584,8,8s-3.584,8-8,8s-8-3.584-8-8				S243.584,16,248,16z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M352,51.312L371.312,32h78.16c2.416,6.776,7.752,12.112,14.528,14.528v110.16L444.688,176H408v16h43.312L480,163.312				V46.528c9.288-3.312,16-12.112,16-22.528c0-13.232-10.768-24-24-24c-10.416,0-19.216,6.712-22.528,16h-84.784L336,44.688V88h16				V51.312z M472,16c4.416,0,8,3.584,8,8s-3.584,8-8,8c-4.416,0-8-3.584-8-8S467.584,16,472,16z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M414.08,97.392l17.92,17.92v9.376L412.688,144H408v16h11.312L448,131.312v-22.624L419.312,80h-4.784				c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24C401.904,112,410.416,105.968,414.08,97.392z				 M392,96c-4.416,0-8-3.584-8-8s3.584-8,8-8c4.416,0,8,3.584,8,8S396.416,96,392,96z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M408 208h88v16H408Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M480,449.472V268.688L451.312,240H408v16h36.688L464,275.312v41.376L451.312,304H408v16h36.688L464,339.312v110.16				c-9.296,3.312-16,12.112-16,22.528c0,13.232,10.768,24,24,24s24-10.768,24-24C496,461.584,489.288,452.784,480,449.472z M472,480				c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8c4.416,0,8,3.584,8,8C480,476.416,476.416,480,472,480z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M408 272h32v16H408Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M448,364.688L419.312,336H408v16h4.688L432,371.312V416h-80v-8h-16v24h96v12.688L412.688,464h-14.16				c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24c10.416,0,19.216-6.712,22.528-16h20.784				L448,451.312V364.688z M376,480c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8c4.416,0,8,3.584,8,8C384,476.416,380.416,480,376,480z				" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M160,408h-16v8h-41.472c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24				c10.416,0,19.216-6.712,22.528-16H160V408z M80,432c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8s8,3.584,8,8				C88,428.416,84.416,432,80,432z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M304 408h16v88H304Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M272 408h16v88H272Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M240,420.688l-16,16V408h-16v41.472c-9.296,3.312-16,12.112-16,22.528c0,13.232,10.768,24,24,24s24-10.768,24-24				c0-7.816-3.808-14.696-9.608-19.08L256,427.312V408h-16V420.688z M216,480c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8s8,3.584,8,8				C224,476.416,220.416,480,216,480z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M192 408L176 408L176 428.688L152 452.688L152 496L168 496L168 459.312L192 435.312z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M336,392h16v-16h24v-24h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16				v-16h-16v-24h-24v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-3.312				L120,140.688V144h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v24h24v16h16v-16h16				v16h16v-16h16v16h16v-16h16v16h16v-16h16v16h16v-16h16v16h16v-16h16V392z M136,360V147.312L147.312,136H360v224H136z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M318.888,211.048l13.96-13.96l-33.936-33.936l-13.96,13.96c-4.16-2.184-8.488-3.976-12.952-5.384V152h-48v19.728				c-4.472,1.408-8.8,3.2-12.952,5.384l-13.96-13.96l-33.936,33.936l13.96,13.96c-2.176,4.16-3.976,8.488-5.384,12.952H152v48				h19.728c1.408,4.472,3.2,8.8,5.384,12.952l-13.96,13.96l33.936,33.936l13.96-13.96c4.16,2.176,8.48,3.976,12.952,5.384V344h48				v-19.728c4.472-1.408,8.8-3.2,12.952-5.384l13.96,13.96l33.936-33.936l-13.96-13.96c2.176-4.152,3.976-8.48,5.384-12.952H344v-48				h-19.728C322.864,219.528,321.072,215.2,318.888,211.048z M328,256h-16.224l-1.416,6.224c-1.6,7.016-4.368,13.672-8.216,19.792				l-3.408,5.408l11.496,11.496l-11.312,11.312l-11.496-11.496l-5.408,3.408c-6.12,3.848-12.776,6.616-19.792,8.216L256,311.776V328				h-16v-16.224l-6.224-1.416c-7.016-1.6-13.672-4.368-19.792-8.216l-5.408-3.408l-11.496,11.496l-11.312-11.312l11.496-11.496				l-3.408-5.408c-3.848-6.12-6.616-12.776-8.216-19.792L184.224,256H168v-16h16.224l1.416-6.224				c1.6-7.016,4.368-13.672,8.216-19.792l3.408-5.408l-11.496-11.496l11.312-11.312l11.496,11.488l5.408-3.408				c6.12-3.848,12.776-6.616,19.792-8.216l6.224-1.408V168h16v16.224l6.224,1.416c7.016,1.6,13.672,4.368,19.792,8.216l5.408,3.408				l11.496-11.488l11.312,11.312l-11.496,11.496l3.408,5.408c3.848,6.12,6.616,12.776,8.216,19.792l1.416,6.216H328V256z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M248,208c-22.056,0-40,17.944-40,40c0,22.056,17.944,40,40,40c22.056,0,40-17.944,40-40C288,225.944,270.056,208,248,208				z M248,272c-13.232,0-24-10.768-24-24s10.768-24,24-24s24,10.768,24,24S261.232,272,248,272z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
						</svg>
					</div>
				</div>
			)
		}
		else {
			return (
				<div>
					<div className="map-filter-roads-types">
						<div className="map-filter-roads-types-inner">
							<h1> Roads Loads</h1>
							<p className="label">Select the type of roads to evaluate</p>
							<Select className='dates' options={this.state.type_of_routes_} onChange={this.handleSelectType.bind(this)} styles={selectStyle} defaultValue={this.state.current_type_of_route} />
							<p className="label">Select the type division</p>
							<Select className='dates' options={this.state.type_of_division_} onChange={this.handleSelectDivision.bind(this)} styles={selectStyle} defaultValue={this.state.current_type_of_division} />
							<p className="label">Select if you want to check origin or destination</p>
							<div className="row">
								<Form onChange={this.handleODType.bind(this)}>
									<div className="column-1-eq">
										<Form.Check inline label=" Origin" name="type_analysis" type='radio' id='origin' defaultChecked={isOrigin} />
									</div>
									<div className="column-2-eq">
										<Form.Check inline label=" Destination" name="type_analysis" id='destination' type='radio' defaultChecked={!isOrigin}/>
									</div>
								</Form>
							</div>

							<div className="row">
								{
									selected ?
										<ExportExcel excelData={ExcelExport} fileName={'Roads Loads'} />
										:
										<Button variant="info" disabled={true} className="generate-btn">Excel Export</Button>
								}
							</div>
						</div>
					</div>
				</div>
			)
		}
	}
}

//Map Roads
class MappRoutes extends React.Component {

	// Set up states for updating map 
	constructor(props) {
		super(props);
		this.state = {
			lng: 9.151450,
			lat: 47.386677,
			zoom: 8,
			mapita: 9,
			tyle: 'mapbox://styles/mapbox/light-v10',
			u: '',
			v: '',
			division: 'plz',
			hideNavs: false,
			coord: this.props.params,
			excel_data: []
		}
	}

	//Update several fields
	updateFields(list) {
		this.setState(list);
	}

	//Update map on component update
	updateField(card, value) {
		this.setState({
			...this.state,
			[card]: value
		});
	}

	//Update style
	handleTyle(value) {
		this.setState({ tyle: value }, this.componentDidMount);
	}

	componentDidMount() {
		// Set up map 
		const map = new mapboxgl.Map({
			container: this.mapContainer,
			style: this.state.tyle,
			center: [this.state.lng, this.state.lat],
			zoom: this.state.zoom,
		});

		map.on('load', () => {

			// Add zoom and rotation controls to the map.
			map.addControl(new mapboxgl.NavigationControl({ showCompass: false, showZoom: false }), 'top-left');
			map.addControl(new MapboxGeocoder({ accessToken: mapboxgl.accessToken, mapboxgl: mapboxgl, marker: false }), 'top-left');

			//Tyle visualization
			//Add shapes to the map (Tile 1km)
			map.addSource('data-swiss-routes', {
				'type': 'geojson',
				'data': URL + 'road_loads/network/primary',
				'generateId': true
			});

			// Add a black outline around the polygon
			map.addLayer({
				'id': 'data-swiss-routes',
				'type': 'line',
				'source': 'data-swiss-routes',
				'layout': {},
				'paint': {
					'line-color': [
						'case',
						['boolean', ['feature-state', 'clicked'], false],
						'#fe7900', // if selected true, paint in pink
						'#9d1f6c' // else paint
					],
					'line-width': 3,
					'line-opacity': 1
				}
			});

			map.addSource('data-swiss-routes-param', {
				'type': 'geojson',
				'data': tile_empty,
				'generateId': true
			});

			// Add a black outline around the polygon
			map.addLayer({
				'id': 'data-swiss-routes-param',
				'type': 'line',
				'source': 'data-swiss-routes-param',
				'layout': {},
				'paint': {
					'line-color': '#fe7900',
					'line-width': 3
				}
			});

			//Add shapes to the map (Buildings within a tyle)
			map.addSource('data-routes-od', {
				'type': 'geojson',
				'data': tile_empty,
				'generateId': true
			});

			map.addLayer({
				'id': 'data-routes-od',
				'type': 'fill',
				'source': 'data-routes-od',
				'layout': {},
				'paint': {
					'fill-color': ['get', 'color'],
					'fill-opacity': 0.3
				}
			});

			// Add a black outline around the polygon
			map.addLayer({
				'id': 'outline-data-routes-od',
				'type': 'line',
				'source': 'data-routes-od',
				'layout': {},
				'paint': {
					'line-color': ['get', 'color'],
					'line-width': 1
				}
			});

			//Data Routes - OD: Percentage
			map.addLayer({
				'id': 'routes-od-labels',
				'type': 'symbol',
				'source': 'data-routes-od',
				"minzoom": 10,
				'layout': {
					'text-field': ['get', 'percentage_string'],
					'text-font': [
						'Open Sans Bold',
						'Arial Unicode MS Bold'
					],
					'text-size': 14,
					'text-transform': 'uppercase',
					'text-letter-spacing': 0.05,
					'text-offset': [0, 1]
				}
			});


			this.setState({
				mapita: map,
				zoom: map.getZoom(),
				center: map.getCenter()
			});

			// Change the cursor to a pointer when the mouse is over the places layer.
			map.on('mouseenter', 'data-swiss-routes', () => {
				map.getCanvas().style.cursor = 'pointer';
			});

			// Change it back to a pointer when it leaves.
			map.on('mouseleave', 'data-swiss-routes', () => {
				map.getCanvas().style.cursor = '';
			});

			let previousID = ''

			map.on('click', 'data-swiss-routes', (e) => {
				selected = true;
				let lineID = e.features[0].id;
				loading = true;

				if (previousID !== '') {
					map.setFeatureState({
						source: 'data-swiss-routes',
						id: previousID,
					}, {
						clicked: false
					});
				}

				previousID = lineID;

				map.setFeatureState({
					source: 'data-swiss-routes',
					id: lineID,
				}, {
					clicked: true
				});

				let properties = e.features[0].properties
				let geometry = e.features[0].geometry
				this.setState({ u: properties.u, v: properties.v })
				let flow_direction = (isOrigin ? 'origins' : 'destinations')


				fetch(URL + 'road_loads/' + flow_direction + '/' + this.state.division + '/' + properties.u + ',' + properties.v)
					.then((res) => res.json())
					.then((json) => {
						this.setState({ excel_data: json })
					}).then(() => {
						fetch(URL + "road_loads/pop_up/" + this.state.division + '/' + properties.u + ',' + properties.v)
							.then((res) => res.json())
							.then((json) => {
								console.log(geometry)

								let details_url = URL_FRONT + 'roads/' + geometry.coordinates[0][0] + ',' + geometry.coordinates[0][1];
								let html_button = '<a href="' + details_url + '" target="_blank"><button style="background-color: #0c8a8a;color: white;border: 1px solid #e4e4e4;padding: 8px;border-radius: 6px;cursor: pointer; padding-top:10px;">Fixed URL</button></a>';

								let html = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Counts of trips per day: </span>' + numberWithCommas((json[0].counts).toFixed(0)) + '</p>'

								if (isOrigin) {
									map.getSource('data-routes-od').setData(URL + 'road_loads/origins/' + this.state.division + '/' + properties.u + ',' + properties.v)
								} else {
									map.getSource('data-routes-od').setData(URL + 'road_loads/destinations/' + this.state.division + '/' + properties.u + ',' + properties.v)
								}
								map.getSource('data-swiss-routes-param').setData(tile_empty)

								new mapboxgl.Popup()
									.setLngLat(e.lngLat)
									.setHTML(html+html_button).addTo(map);
							})
					})
					loading = false
			})

			map.on('mouseenter', 'data-routes-od', () => {
				map.getCanvas().style.cursor = 'pointer';
			});

			// Change it back to a pointer when it leaves.
			map.on('mouseleave', 'data-routes-od', () => {
				map.getCanvas().style.cursor = '';
			});


			map.on('click', 'data-routes-od', (e) => {
				let properties = e.features[0].properties
				let html = ''
				if (this.state.division === 'plz') {
					html = '<div><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Name: </span>' + properties.Ortschaftsname + '</p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">PLZ: </span>' + properties.PLZ + '</p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Counts of vehicles: </span>' + properties.counts.toFixed(0) + '</p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Percentage of traffic: </span>' + (properties.percentage * 100).toFixed(1) + '%</p></div>'
				} else {
					html = '<div><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Tile ID: </span>' + properties.tileId + '</p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Counts of vehicles: </span>' + properties.counts.toFixed(0) + '</p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Percentage of traffic: </span>' + (properties.percentage * 100).toFixed(1) + '%</p></div>'
				}

				new mapboxgl.Popup()
					.setLngLat(e.lngLat)
					.setHTML(html).addTo(map);
			})

		})
	};

	render() {
		const { hideNavs } = this.state;

		return (
			<div>
				<div ref={el => this.mapContainer = el} style={{ width: '100%', height: '100vh' }}>
				</div>
				<RecommendationNavBarComponent />
				<MainMenu />
				<div style={{ display: hideNavs ? 'none' : 'block' }}>
						<FilterModalComponent updateFields={(list) => this.updateFields(list)} map={this.state.mapita} updateField={(card, field, value) => this.updateField(card, field, value)} u={this.state.u} v={this.state.v} excel_data={this.state.excel_data} coord={this.state.coord}/>
					</div>
				<LayerTylesComponent map={this.state.mapita} tyle={this.state.tyle} handleTyle={(value) => this.handleTyle(value)} handleCoord={(value1, value2) => this.handleCoord(value1, value2)} />
			</div>
		)
	}
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => (
    <MappRoutes
        {...props}
        params={useParams()}
    />
); 
