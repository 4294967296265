
import React from "react";
import { Map } from "react-map-gl";
import DeckGL, { GeoJsonLayer, PolygonLayer } from "deck.gl";
import { scaleThreshold } from "d3-scale";
import {IconContext} from "react-icons";
import axios from 'axios';
import RecommendationNavBarComponent from "../../components/RecommendationNavBarComponent";
import Select from 'react-select';
import Slider from 'rc-slider';
import { RiFullscreenFill, RiFullscreenExitFill } from "react-icons/ri";

import '../css/map-recommendation-deckgl.css';

const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const URL = process.env.REACT_APP_URL_SERVER_BACK;

const _ = require('lodash');

export const COLOR_SCALE = scaleThreshold()
.domain([0, 0.01, 0.05, 0.1, 0.15, 0.3, 0.7, 1, 3, 7, 10, 30, 10])
.range([
  [14, 91, 48],
  [13, 99, 52],
  [12, 107, 55],
  [12, 107, 55],
  // zero
  [71, 125, 51],
  [130, 143, 46],
  [248, 179, 36],
  [242, 124, 40],
  [235, 68, 44],
  [212, 50, 40],
  [188, 32, 35],
  [189, 0, 38],
  [128, 0, 38]
]);

export const COLOR_SCALE_INVERT = scaleThreshold()
.domain([ -3,-2.7,-2.4,-2,-1.8,-1.6,-1.4,-1,-0.8,-0.1,-0.01,-0.004,-0.001 ])
.range([
  [128, 0, 38],
  [189, 0, 38],
  [188, 32, 35],
  [212, 50, 40],
  [235, 68, 44],
  [242, 124, 40],
  [248, 179, 36],
  [130, 143, 46],
  [71, 125, 51],
  [12, 107, 55],
  [12, 107, 55],
  [13, 99, 52],
  [14, 91, 48],
]);

function numberWithCommas(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
}

function colorscale(value_aggregation_options, division, f){
   let value;
   let fraction;
   switch (value_aggregation_options) {
    case "inhabitants":
      value = f.properties.Tons_CO2_emissions_total_weekdays_per_habitant*2;
      break;
    case "weekdays":
      value = f.properties.Tons_CO2_emissions_total_weekdays/700;
      break;
    case "pkm":
      value = f.properties.pkm_total_weekdays/120000;
      break;
    case "saved_co2":
      value = 80-f.properties.CO2_saved_by_moving_modal_split_10;
      break;
    default:
      // eslint-disable-next-line no-unused-expressions
      null;
  }
  switch (division) {
    case "bfs":
      fraction = 0.09;
      break;
    case "plz":
      fraction = 0.5;
      break;
    case "kantons":
      fraction = 1.2;
      break;
    default:
      // eslint-disable-next-line no-unused-expressions
      null;
  }
  if(value_aggregation_options==='weekdays' && division==='kantons')
  {
    return COLOR_SCALE(value*fraction/500)
  }
  else if(value_aggregation_options==='pkm' && division==='kantons')
  {
    return COLOR_SCALE(value*fraction/2000)
  }
  else if(value_aggregation_options==='saved_co2' && division==='kantons')
  {
    console.log(value*fraction/12000)
    return COLOR_SCALE_INVERT(value*fraction/12000)
  }
  else{
    return COLOR_SCALE(value*fraction)
  }
}

const landCover = [
  [
    [5.756621,45.514933],
    [10.683990,45.514933],
    [5.756621,47.961350],
    [10.683990,47.961350]
  ]
];

const INITIAL_VIEW_STATE = {
  latitude: 46.924143,
  longitude: 8.251313,
  zoom: 7.2,
  maxZoom: 16,
  pitch: 45,
  bearing: 0
};


class HideElementsComponent extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			hideNavs : false,
			mapa: this.props.map,
		}
	}

	hideComponent() {
		let newHideNavs = !this.state.hideNavs;
		this.setState({hideNavs: newHideNavs})
		this.props.updateField("hideNavs", newHideNavs);
	}

	render() {

		const { hideNavs } = this.state;

		return (
			<div>
				{hideNavs ? <>
					<div className='map-close-navs'>
						<div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
								<div>
									<RiFullscreenExitFill />
								</div>
							</IconContext.Provider>
						</div>
					</div>
				</> : <>
					<div className='map-close-navs'>
						<div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
								<div>
									<RiFullscreenFill />
								</div>
							</IconContext.Provider>
						</div>
					</div>
				</>}
			</div>
		)
	}
}


class MapCO2_3D extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
			division_options_: [{
				"value" : "plz",
				"label" : 'Postal Code'
			  },
        {
          "value" : "bfs",
          "label" : 'Municipality'
        },
        {
          "value" : "kantons",
          "label" : 'Canton'
        }],
      class_options_: [{
          "value" : "All",
          "label" : 'All Classes'
        },
        {
          "value" : "A",
          "label" : 'A Class'
          },
          {
            "value" : "B",
            "label" : 'B Class'
          },
          {
            "value" : "C",
            "label" : 'C Class'
          },
          {
            "value" : "D",
            "label" : 'D Class'
          }],
      aggregation_options_: [{
            "value" : "inhabitants",
            "label" : 'Per habitant'
          },
          {
            "value" : "weekdays",
            "label" : 'Total during weekdays'
            },
            {
              "value" : "saved_co2",
              "label" : 'If modal split changed to ÖV'
              },
              {
                "value" : "pkm",
                "label" : 'Traffic'
                }
          ],
			value_division_options: ({
				"value" : "plz",
				"label" : 'Postal Code'
			  }),
      value_od_trips: ({
          "value" : "2022-06",
          "label" : '2022-06'
          }),
      value_class_options: ({
          "value" : "All",
          "label" : 'All Classes'
          }),
      value_location: ({
          "value" : null,
          "label" : null
        }),
      value_aggregation_options: ({
        "value" : "saved_co2",
        "label" : 'If modal split changed to ÖV'
      }),
      db_od_trips_: this.getOptionsDate(URL+'db_od_trips'),
      URL_CO2: URL+"co2_emissions/heatmap/2022-06-01/100/All/saved_co2/plz/0.1.geojson",
      showDescription: true,
      percentage: 100,
      effort: 10,
      effort_decimal: 0.1,
      kantons_: this.getOptionsKantons(URL+'what_if/kanton_list'),
      response_contributors: [],
      response_contributors_total: 0,
      hideNavs : false
    	}
  }

  _renderLayers() {
    const layer = [
      // only needed when using shadows - a plane for shadows to drop on
      new PolygonLayer({
        id: 'ground',
        data: landCover,
        stroked: false,
        getPolygon: f => f,
        getFillColor: [255, 255, 255, 1]
      }),
      new GeoJsonLayer({
        id: 'geojson',
        data: this.state.URL_CO2,
        opacity: 0.8,
        stroked: true,
        filled: true,
        extruded: true,
        wireframe: true,
        getElevation: f => ( this.state.value_aggregation_options.value==='inhabitants' ? f.properties.Tons_CO2_emissions_total_weekdays_per_habitant*100 : ( this.state.value_aggregation_options.value==='weekdays' ? ( this.state.value_division_options.value === 'kantons' ? f.properties.Tons_CO2_emissions_total_weekdays/1000 : f.properties.Tons_CO2_emissions_total_weekdays/3) : ( this.state.value_aggregation_options.value==='pkm' ? ( this.state.value_division_options.value === 'kantons' ? f.properties.pkm_total_weekdays/1500000*0.3 : f.properties.pkm_total_weekdays/15000) : ( this.state.value_division_options.value === 'kantons' ? f.properties.CO2_saved_by_moving_modal_split_10*0.01 : (this.state.value_division_options.value === 'plz' ? f.properties.CO2_saved_by_moving_modal_split_10*10/this.state.effort : f.properties.CO2_saved_by_moving_modal_split_10*1.5))))),
        elevationScale: 100,
        getFillColor: f => colorscale(this.state.value_aggregation_options.value,this.state.value_division_options.value, f),
        getLineColor: [0, 0, 0],
        getLineWidth: 100,
        pickable: true,
      })
    ];
    return [layer];
  }

  getTooltip({object}) {
    if(this.state.value_aggregation_options.value==='inhabitants'){
    return (
        object && {
          html: `\
      <div><b>Monthly CO<sub>2</sub> Emissions</b></div>
      <div><b>Name: ${object.properties.name}</b></div>
      <div><i>Number of inhabitants:</i> ${numberWithCommas(object.properties.inhabitants)}</div>
      <hr>
      <div><b>Average CO<sub>2</sub> Emitted Per Habitant</b></div>
      <div><i>Generated by trains:</i> ${(object.properties.Relative_tons_CO2_emissions_train_weekdays_per_habitant*100).toFixed(0)}% ${ ((object.properties.Relative_tons_CO2_emissions_train_weekdays_per_habitant) >= 0) ? 'more':'less'} than the average</div>
      <div><i>Generated by cars:</i> ${(object.properties.Relative_tons_CO2_emissions_car_weekdays_per_habitant*100).toFixed(0)}% ${ ((object.properties.Relative_tons_CO2_emissions_car_weekdays_per_habitant) >= 0) ? 'more':'less'} than the average</div>
      <div><i>Total Generated:</i> ${(object.properties.Relative_tons_CO2_emissions_total_weekdays_per_habitant*100).toFixed(0)}% ${ ((object.properties.Relative_tons_CO2_emissions_total_weekdays_per_habitant) >= 0) ? 'more':'less'} than the average</div>
      <hr>
      <div><b>CO<sub>2</sub> saved by using the train per habitant</b></div>
      <div>${(object.properties.Relative_tons_CO2_emissions_saved_weekdays_per_habitant*100).toFixed(0)}% ${ (object.properties.Relative_tons_CO2_emissions_saved_weekdays_per_habitant >= 0) ? 'more':'less'} than the average</div>
      `
        }
        );
      }
      else if(this.state.value_aggregation_options.value==='weekdays'){
        return (
          object && {
            html: `\
        <div><b>Monthly CO<sub>2</sub> Emissions</b></div>
        <div><b>Name: ${object.properties.name}</b></div>
        <div><i>Number of inhabitants:</i> ${numberWithCommas(object.properties.inhabitants)}</div>
        <hr>
        <div><b>Average CO<sub>2</sub> Emitted in Weekdays</b></div>
        <div><i>Generated by trains:</i> ${(object.properties.Relative_tons_CO2_emissions_train_weekdays*100).toFixed(0)}% ${ ((object.properties.Relative_tons_CO2_emissions_train_weekdays) >= 0) ? 'more':'less'} than the average</div>
        <div><i>Generated by cars:</i> ${(object.properties.Relative_tons_CO2_emissions_car_weekdays*100).toFixed(0)}% ${ ((object.properties.Relative_tons_CO2_emissions_car_weekdays) >= 0) ? 'more':'less'} than the average</div>
        <div><i>Total Generated:</i> ${(object.properties.Relative_tons_CO2_emissions_total_weekdays*100).toFixed(0)}% ${ ((object.properties.Relative_tons_CO2_emissions_total_weekdays) >= 0) ? 'more':'less'} than the average</div>
        <hr>
        <div><b>CO<sub>2</sub> saved by using the train in weekdays</b></div>
        <div>${(object.properties.Relative_tons_CO2_emissions_saved_weekdays*100).toFixed(0)}% ${ (object.properties.Relative_tons_CO2_emissions_saved_weekdays >= 0) ? 'more':'less'} than the average</div>
        `
          }
          );
      }
      else if(this.state.value_aggregation_options.value==='saved_co2'){
        return (
          object && {
            html: `\
        <div><b>Monthly CO<sub>2</sub> Emissions</b></div>
        <div><b>Name: ${object.properties.name}</b></div>
        <div><i>Number of inhabitants:</i> ${numberWithCommas(object.properties.inhabitants)}</div>
        <hr>
        <div><b>CO<sub>2</sub> saved if 10% modal split is shifted</b></div>
        <div>${numberWithCommas((object.properties.CO2_saved_by_moving_modal_split_10*100).toFixed(0))} tons</div>
        `
          }
          );
      }
      else{
        return (
          object && {
            html: `\
        <div><b>Monthly CO<sub>2</sub> Emissions</b></div>
        <div><b>Name: ${object.properties.name}</b></div>
        <div><i>Number of inhabitants:</i> ${numberWithCommas(object.properties.inhabitants)}</div>
        <hr>
        <center>
    <table>
  <tr>
    <th></th>
    <th>Weekdays</th>
    <th>Weekends</th>
  </tr>
  <tr>
    <th>Cars</th>
    <td>${object.properties.hasOwnProperty('pkm_cars_weekdays') ? (object.properties.pkm_cars_weekdays!==0 ? numberWithCommas((object.properties.pkm_cars_weekdays).toFixed(0)) :'-'): '-'}</td>
    <td>${object.properties.hasOwnProperty('pkm_cars_weekends') ? (object.properties.pkm_cars_weekends!==0 ? numberWithCommas((object.properties.pkm_cars_weekends).toFixed(0)) :'-'): '-'}</td>
  </tr>
  <tr>
    <th>Trains</th>
    <td>${object.properties.hasOwnProperty('pkm_train_weekdays') ? (object.properties.pkm_train_weekdays!==0 ? numberWithCommas((object.properties.pkm_train_weekdays).toFixed(0)) :'-'): '-'}</td>
    <td>${object.properties.hasOwnProperty('pkm_train_weekends') ? (object.properties.pkm_train_weekends!==0 ? numberWithCommas((object.properties.pkm_train_weekends).toFixed(0)) :'-'): '-'}</td>
  </tr>
  <tr>
    <th>Total</th>
    <td>${object.properties.hasOwnProperty('pkm_total_weekdays') ? (object.properties.pkm_total_weekdays!==0 ? numberWithCommas((object.properties.pkm_total_weekdays).toFixed(0)) :'-'): '-'}</td>
    <td>${object.properties.hasOwnProperty('pkm_total_weekends') ? (object.properties.pkm_total_weekends!==0 ? numberWithCommas((object.properties.pkm_total_weekends).toFixed(0)) :'-'): '-'}</td>
  </tr>
</table>
    </center>
        `
          }
          );
      }
  }

  async getOptionsDate(url){
		const res = await axios.get(url)
		const data = res.data
		const options = data.map(d => ({
		  "value" : d._id.date.toString().split('T')[0].slice(0,-3),
		  "label" : d._id.date.toString().split('T')[0].slice(0,-3)
		}))
		this.setState({db_od_trips_: options});
	}

  async getOptionsKantons(url){
    const res = await axios.get(url)
    const data = res.data
    const options = data.map(d => ({
        "value" : d._id,
        "label" : d._id
    }))
    this.setState({kantons_: options});
  }

	componentDidMount(){
    this.getTooltip = this.getTooltip.bind(this);
		this.handleDivision = this.handleDivision.bind(this);
    this.handleGroup = this.handleGroup.bind(this);
    this.percentageSliderHandler = this.percentageSliderHandler.bind(this);
    this.handleChangeLocation = this.handleChangeLocation.bind(this);
	}

  handleGroup(event){
    if(event.value === 'All'){
      this.setState({URL_CO2: URL+"co2_emissions/heatmap/2022-06-01/{0}/{1}/{2}/{3}/{4}.geojson".format(this.state.percentage, event.value, this.state.value_aggregation_options.value, this.state.value_division_options.value, this.state.effort_decimal)});
    }else{
      this.setState({URL_CO2: URL+"co2_emissions/heatmap/2022-06-01/{0}/{1}/{2}/{3}/{4}.geojson".format('100', event.value, this.state.value_aggregation_options.value, this.state.value_division_options.value, this.state.effort_decimal)});
    }
    this.setState({value_class_options: event});
  }

  handleAggregation(event){
    this.setState({value_aggregation_options: event});
    if(this.state.value_class_options.value === 'All'){
      this.setState({URL_CO2: URL+"co2_emissions/heatmap/2022-06-01/{0}/{1}/{2}/{3}/{4}.geojson".format(this.state.percentage, this.state.value_class_options.value, event.value, this.state.value_division_options.value, this.state.effort_decimal)});
    }else{
      this.setState({URL_CO2: URL+"co2_emissions/heatmap/2022-06-01/{0}/{1}/{2}/{3}/{4}.geojson".format('100', this.state.value_class_options.value, event.value, this.state.value_division_options.value, this.state.effort_decimal)});
    }
  }

  percentageSliderHandler(event){
    this.setState({percentage: event});
    this.setState({URL_CO2: URL+"co2_emissions/heatmap/2022-06-01/{0}/{1}/{2}/{3}/{4}.geojson".format(event, this.state.value_class_options.value, this.state.value_aggregation_options.value, this.state.value_division_options.value, this.state.effort_decimal)});    
  }

  effortSliderHandler(event){
    this.setState({effort: event, effort_decimal: event/100});
    this.setState({URL_CO2: URL+"co2_emissions/heatmap/2022-06-01/{0}/{1}/{2}/{3}/{4}.geojson".format(this.state.percentage, this.state.value_class_options.value, this.state.value_aggregation_options.value, this.state.value_division_options.value, event/100)});    
  }

	handleDivision(event)
	{

    if(event.value === 'plz'){
      this.setState({URL_CO2: URL+"co2_emissions/heatmap/2022-06-01/{0}/{1}/{2}/{3}/{4}.geojson".format(this.state.percentage, this.state.value_class_options.value, this.state.value_aggregation_options.value, event.value, this.state.effort_decimal)});    
    }else{
      this.setState({URL_CO2: URL+"co2_emissions/heatmap/2022-06-01/{0}/{1}/{2}/{3}/{4}.geojson".format(this.state.percentage, 'All', this.state.value_aggregation_options.value, event.value, this.state.effort_decimal), value_class_options: ({"value" : "All", "label" : 'All Classes' })});    
    }
    this.setState({value_division_options: event});
	}

  hideComponent(){
		this.setState({ showDescription: !this.state.showDescription });
    console.log(this.state.showDescription)
	}

  demoAsyncCall(event) {
    let data;

    fetch(URL+"co2_emissions/list_plz/{0}/{1}/{2}".format(event.value, this.state.percentage, (this.state.effort/100))).then((res) => res.json())
    .then((json) => { data = json; }).then(() => { this.setState({response_contributors: data}) })
    .then(()=>{
      fetch(URL+"co2_emissions/pkm_plz/{0}/{1}/{2}".format(event.value, this.state.percentage, (this.state.effort/100))).then((res) => res.json())
      .then((json)=>{this.setState({response_contributors_total: (json.total_car_pkm*30*12).toFixed(0)})})
    });
  }

  handleChangeLocation(event) 
    {
        if(event===null){
            this.setState({value_location: ({
                "value" : null,
                "label" : null
              })})
            this.setState({response_contributors: [], response_contributors_total: 0})
        }
        else{
            this.setState({value_location: event})
            if(event.value!==null)
            {
              this.setState({response_contributors: [], response_contributors_total: 0})
              this.demoAsyncCall(event);
            }
        }
        
    }


	updateField(card, value) {
		this.setState({
            ...this.state,
            [card]: value
        });
	}

  render() {
    const { viewState, controller = true } = this.props;
		const { hideNavs } = this.state;

    const { response_contributors_total } = this.state;

    const listContributors = this.state.response_contributors.map((trip, index) =>
            <tr key={index}>
				        <td className="table-data">{index}</td>
                <td className="table-data">{trip.name} ~ {trip.plz}</td>
                <td className="table-data">{numberWithCommas((trip.pkm_total_weekdays*30*12).toFixed(0))}</td>
                <td className="table-data">{numberWithCommas((trip.pkm_cars_weekdays*30*12).toFixed(0))}</td>
            </tr>
    );

    const selectDate = {
			menuList: styles => {
			  return {
				...styles,
				maxHeight: 150,
				borderWidth: 0,
				boxShadow: '0 0 10px 2px rgb(0 0 0 / 10%)',
			  };
			}
		  };

      const selectStyles = {
        menuList: styles => {
          return {
          ...styles,
          maxHeight: 100
          };
        }
        };

    return (
      <div>
        <DeckGL
          layers={this._renderLayers()}
          initialViewState={INITIAL_VIEW_STATE}
          viewState={viewState}
          controller={controller}
          getTooltip={this.getTooltip}
        >
        <Map reuseMaps mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN} mapStyle={'mapbox://styles/mapbox/light-v10'} />
        <RecommendationNavBarComponent/>
        </DeckGL>
        <HideElementsComponent map={this.state.mapita} updateField={(card, field, value) => this.updateField(card, field, value)}/>
        <div>
          { (this.state.value_division_options.value === 'plz') ? 
            <div className="map-group-picker">
              <Select className='group-class' onChange={this.handleGroup.bind(this)} options={this.state.class_options_} styles={selectDate} defaultValue={this.state.value_class_options}/>
            </div>
          :
            <></>
          }
          <div className="map-division-picker">
			      <Select className='division' onChange={this.handleDivision.bind(this)} options={this.state.division_options_} styles={selectDate} defaultValue={this.state.value_division_options}/>
		      </div>
		      <div className="map-month-year-picker">
			      <Select className='dates' isDisabled={true} options={this.state.db_od_trips_} styles={selectDate} defaultValue={this.state.value_od_trips}/>
		      </div>
	      </div>
        
        <div style={{ display: hideNavs ? 'none' : 'block' }}>

        {this.showDescription ? <> 
				<div className='map-layer-option-default-space'>
					
				</div>
				</> : <></>}
        <div className='map-layer-aggreation-type'>
            <div className='map-layer-aggreation-type-inner'>
              <h1>Change type of data aggregation</h1>
              <Select className='group-aggregation' onChange={this.handleAggregation.bind(this)} options={this.state.aggregation_options_} styles={selectDate} defaultValue={this.state.value_aggregation_options}/>
            </div>
          </div>
        { (this.state.value_class_options.value ==='All') ? 
            <div className='map-layer-slider-percentage'>
            <div className='map-layer-slider-percentage-inner'>
              <h1>Filter by percentage of trips</h1>
              <Slider min={0} max={100} step={1} defaultValue={this.state.percentage} onChange={_.debounce(this.percentageSliderHandler.bind(this),33)}/>
              { (this.state.value_division_options.value === 'plz') ? 
                <p className='label'>Showing postal codes that contribute to {this.state.percentage}% <br/>of passenger km in weekdays in Switzerland</p>
                :
                <>
                { (this.state.value_division_options.value === 'bfs') ? 
                <p className='label'>Showing municipalities that contribute to {this.state.percentage}% <br/>of passenger km in weekdays in Switzerland</p>
                :
                <p className='label'>Showing cantons that contribute to {this.state.percentage}% <br/>of passenger km in weekdays in Switzerland</p>
                }
                </>
              }
              <h1>Filter by Optimization Effort</h1>
              <Slider min={0} max={100} step={1} defaultValue={this.state.effort} onChange={_.debounce(this.effortSliderHandler.bind(this),33)}/>
              { (this.state.value_division_options.value === 'plz') ? 
                <p className='label'>Showing what will happen if we change {this.state.effort}% <br/>max in every postal code route</p>
                :
                <>
                { (this.state.value_division_options.value === 'bfs') ? 
                <p className='label'>Showing what will happen if we change {this.state.effort}% <br/>max in every municipality route</p>
                :
                <p className='label'>Showing what will happen if we change {this.state.effort}% <br/>max in every Canton route</p>
                }
                </>
              }
            </div>
          </div>
          :
            <></>
          }
          { (this.state.value_division_options.value === 'plz') ? 
          <>
          <div className='map-layer-slider-select-kanton'>
            <div className='map-layer-slider-select-kanton-inner'>
              <h1>Select a Canton</h1>
							<Select className='locations' isClearable={true} options={this.state.kantons_} onChange={this.handleChangeLocation.bind(this)} styles={selectStyles} />
            </div>
          </div>
          <div className="map-top-x-contributors">
            <div className="map-top-x-contributors-inner">
              <h1>Top contributors </h1>
              <div className="map-top-x-contributors-inner-scroll">
                      {listContributors.length===0 ?
                      <>
                        <h1>
                          Please select a Canton to see the <br/> top flow contributors
                        </h1>
                      </>
                      :
                      <table>
                        <thead>
                            <tr>
								                <th className="table-tittle">Index</th>
								                <th className="table-tittle">Name</th>
                                <th className="table-tittle">Car PKm per weekday</th>
                                <th className="table-tittle">Total PKm per weekday</th>
                            </tr>
                        </thead>
                        <tbody>
                            {listContributors}
                            <tr>
                              <th className="table-tittle"></th>
                              <th className="table-tittle"></th>
                              <th className="table-tittle">Total</th>
                              <th className="table-tittle">{numberWithCommas(response_contributors_total)}</th>
                            </tr>
                        </tbody>
                        </table>
                      }
                        
                    </div>
            </div>
          </div>
          </>
          :
          <></>
        }
					</div>
      </div>
    );
  }
};


export default MapCO2_3D;

