import React from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '../css/map-drop-point.css';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';
import { IconContext } from "react-icons";
import { BsLayersHalf } from "react-icons/bs";
import { RiFullscreenFill, RiFullscreenExitFill } from "react-icons/ri";
import Select from 'react-select';
import RecommendationNavBarComponent from "../../components/RecommendationNavBarComponent";
import Slider from 'rc-slider';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import 'mapbox-gl/dist/mapbox-gl.css';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const URL = process.env.REACT_APP_URL_SERVER_BACK;
const delay = ms => new Promise(res => setTimeout(res, ms));

let hide1kmTiles = false;
let hide100mTiles = false;
let hideBusRoutes = false;
let no_data = false;
let employer = '-'
let color_1km = 'color'

const _ = require('lodash');

function numberWithCommas(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function getMax(arr) {
	var max = 0;
	for (var i = 0; i < arr.length; i++) {
		if (parseInt(arr[i]) > parseInt(max))
			max = arr[i];
	}
	return max;
}

const lines_geojson = {
	'type': 'FeatureCollection',
	'features': [
		{
			'type': 'Feature',
			'geometry': {
				"type": "LineString",
				"coordinates": [
				]
			}
		}
	]
};

//Layer Tyles Selection - Side Menu
class LayerTylesComponent extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			showOptions: false,
			mapa: this.props.map,
		}
		this.hideComponent = this.hideComponent.bind(this);
		this.handleTyleEvent = this.handleTyleEvent.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (this.props.map !== prevProps.map) {
			this.setState({ mapa: this.props.map })
		}
	}

	hideComponent(name) {
		this.setState({ showOptions: !this.state.showOptions });
	}

	handleTyleEvent(event) {
		switch (event) {
			case "default":
				this.props.handleTyle("mapbox://styles/mapbox/light-v10");
				break;
			case "outdoors":
				this.props.handleTyle("mapbox://styles/mapbox/outdoors-v11");
				break;
			case "satellite":
				this.props.handleTyle("mapbox://styles/mapbox/satellite-streets-v11");
				break;
			case "streets":
				this.props.handleTyle("mapbox://styles/mapbox/streets-v11");
				break;
			case "dark":
				this.props.handleTyle("mapbox://styles/mapbox/dark-v10");
				break;
			default:
				// eslint-disable-next-line no-unused-expressions
				null;
		}
		this.props.handleCoord(this.state.mapa.getCenter(), this.state.mapa.getZoom());
	}

	render() {
		const { showOptions } = this.state;

		return (
			<div>
				{showOptions ? <>
					<div id='layer-options' className='map-layer-options-tyles'>
						<div className='map-layer-option-dark' onClick={() => this.handleTyleEvent("dark")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/Dark.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Dark</p>
						</div>
						<div className='map-layer-option-streets' onClick={() => this.handleTyleEvent("streets")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/Streets.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Streets</p>
						</div>
						<div className='map-layer-option-satellite' onClick={() => this.handleTyleEvent("satellite")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/Satellite.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Satellite</p>
						</div>
						<div className='map-layer-option-outdoors' onClick={() => this.handleTyleEvent("outdoors")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/Outdoors.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Outdoors</p>
						</div>
						<div className='map-layer-option-default' onClick={() => this.handleTyleEvent("default")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/White.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Default</p>
						</div>
					</div>
				</> : <></>}
				<div className='map-layer-tyles'>
					<div className='map-layer-tyles-inner' onClick={() => this.hideComponent("showOptions")}>
						<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
							<div>
								<BsLayersHalf />
							</div>
						</IconContext.Provider>
					</div>
				</div>
			</div>
		)
	}
}

class HideElementsComponent extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			hideNavs: false,
			mapa: this.props.map,
		}
	}

	hideComponent() {
		let newHideNavs = !this.state.hideNavs;
		this.setState({ hideNavs: newHideNavs })
		this.props.updateField("hideNavs", newHideNavs);
	}

	render() {

		const { hideNavs } = this.state;

		return (
			<div>
				{hideNavs ? <>
					<div className='map-close-navs'>
						<div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
								<div>
									<RiFullscreenExitFill />
								</div>
							</IconContext.Provider>
						</div>
					</div>
				</> : <>
					<div className='map-close-navs'>
						<div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
								<div>
									<RiFullscreenFill />
								</div>
							</IconContext.Provider>
						</div>
					</div>
				</>}
			</div>
		)
	}
}

class BtnsComponent extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			mapa: this.props.map,
			initial_company: this.props.initial_company,
			generatedTyles1Km: true,
			loading: false,

			total_pkm: 0,
			current_pkm: 0,
			percentage_pkm: 0,

			distance_min_train_station: 0,
			distance_max_train_station: 5,
			distance_min_buhler: 0,
			distance_max_buhler: 100,
			min_num_trips_per_tile: 0,
			max_num_trips_per_tile: 2100,
			num_changes_ov: 'all',
			num_buses: 5,
			num_capacity: 200,
			convertion_rate: 0.5,
			max_distance_travel: 120,

			cat1_num_changes: 2,
			cat2_num_changes: 2,
			cat3_num_changes: 1,
			lastMileOffer: 'yes',
			cat1_min_percentage: 200,
			cat1_max_percentage: 1000,
			cat2_min_percentage: 130,
			cat2_max_percentage: 200,
			cat3_min_percentage: 0,
			cat3_max_percentage: 130,
			bike_radius: 5000,
			max_min_than_car: 10,

			minutes_in_train: 20,
			current_modal_split: 0,
			selectedPKm: 'trips',

			green_cat_bike: 5000,
			yellow_cat_bike: 10000,
			selection_distribution: [true, false, false, false],

			green_pkm: 0,
			green_trips: 0,
			red_pkm: 0,
			red_trips: 0,
			yellow_pkm: 0,
			yellow_trips: 0,
			percentage_pkm_red: 0,
			percentage_pkm_yellow: 0,
			percentage_pkm_green: 0,
			percentage_trips_green: 0,
			percentage_trips_yellow: 0,
			percentage_trips_red: 0,
			current_percentage_pkm_red: 0,
			current_percentage_pkm_yellow: 0,
			current_percentage_pkm_green: 0,
			current_percentage_trips_green: 0,
			current_percentage_trips_yellow: 0,
			current_percentage_trips_red: 0,
			maximum_percentage_difference: 100,

			percentage_pkm_statusquo_green: 0,
			percentage_pkm_statusquo_yellow: 0,
			percentage_pkm_statusquo_red: 0,

			percentage_trips_statusquo_green: 0,
			percentage_trips_statusquo_yellow: 0,
			percentage_trips_statusquo_red: 0,

			data_1km: []
		}
		this.handleTilesGenerator()
	}

	componentDidMount() {
		this.handleHide1kmTile = this.handleHide1kmTile.bind(this);
		this.handleHide100mTile = this.handleHide100mTile.bind(this);

		this.handleCat1NumChanges = this.handleCat1NumChanges.bind(this);
		this.handleCat2NumChanges = this.handleCat2NumChanges.bind(this);
		this.handleCat3NumChanges = this.handleCat3NumChanges.bind(this);
		this.handleBikeRadius = this.handleBikeRadius.bind(this);
		this.handleCat1MinPercentage = this.handleCat1MinPercentage.bind(this);
		this.handleCat1MaxPercentage = this.handleCat1MaxPercentage.bind(this);
		this.handleCat2MinPercentage = this.handleCat2MinPercentage.bind(this);
		this.handleCat2MaxPercentage = this.handleCat2MaxPercentage.bind(this);
		this.handleCat3MinPercentage = this.handleCat3MinPercentage.bind(this);
		this.handleCat3MaxPercentage = this.handleCat3MaxPercentage.bind(this);
		this.handleMaxMinutesCar = this.handleMaxMinutesCar.bind(this);
		this.handleTilesGenerator = this.handleTilesGenerator.bind(this);
		this.handleVisualization = this.handleVisualization.bind(this);
		this.handleDistribution = this.handleDistribution.bind(this);

		this.handleGreenCatBike = this.handleGreenCatBike.bind(this);
		this.handleYellowCatBike = this.handleYellowCatBike.bind(this);
		this.handleMinInTrains = this.handleMinInTrains.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (this.props.map !== prevProps.map) {
			this.setState({ mapa: this.props.map });
		}
	}


	handleVisualization(event) {
		this.setState({ selectedPKm: event.target.attributes.getNamedItem('id').value })
	}

	demoAsyncCall(min_dist_station, max_dist_station, min_dist_buhler, max_dist_buhler, changes_ov, min_num_trips, max_num_trips, max_distance_travel, num_buses, num_capacity, convertion_rate, maximum_percentage_difference, selection_distribution) {

		let options = []

		if (selection_distribution[0]) {
			options.push('color')
		}
		if (selection_distribution[1]) {
			options.push('color_last_mile')
		}
		if (selection_distribution[2]) {
			options.push('color_bike')
		}
		if (selection_distribution[3]) {
			options.push('color_working')
		}

		const counts = {};
		selection_distribution.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });

		let data;

		let min_trips = ((parseInt(min_num_trips) > 5) ? parseInt(min_num_trips) : 5)
		let body = { employer: this.state.current_employer.value, distance_to_station: [min_dist_station, max_dist_station], distance_to_origin: [min_dist_buhler, max_dist_buhler], changes_in_ov: changes_ov, trips_per_tile: [min_num_trips, max_num_trips], maximum_travel_distance: max_distance_travel, num_buses: num_buses, bus_capacity: num_capacity, conversion_rate: convertion_rate, maximum_percentage_difference: maximum_percentage_difference, options: options }
		let body_1km_colors = { employer: this.state.current_employer.value, distance_to_station: [min_dist_station, max_dist_station], distance_to_origin: [min_dist_buhler, max_dist_buhler], changes_in_ov: changes_ov, trips_per_tile: [min_num_trips, max_num_trips], maximum_travel_distance: max_distance_travel, num_buses: num_buses, bus_capacity: num_capacity, conversion_rate: convertion_rate, last_mile: (this.state.lastMileOffer === 'yes' ? true : false), bike_radius: this.state.bike_radius, threshold_duration_percentage_green: [this.state.cat3_min_percentage, this.state.cat3_max_percentage], threshold_duration_percentage_yellow: [this.state.cat2_min_percentage, this.state.cat2_max_percentage], threshold_duration_percentage_red: [this.state.cat1_min_percentage, this.state.cat1_max_percentage], max_difference_green: this.state.max_min_than_car, max_changes_green: this.state.cat3_num_changes, max_changes_yellow: this.state.cat2_num_changes, min_changes_red: this.state.cat1_num_changes, visited_tiles: [], short_bike_radius: this.state.green_cat_bike, medium_bike_radius: this.state.yellow_cat_bike, minutes_in_train: this.state.minutes_in_train * 60, maximum_percentage_difference: maximum_percentage_difference, options: options }


		let url_1km_tile = 'tile_potential/filter_1km_tiles';
		let url_total_vs_filter = 'tile_potential/total_vs_filter_2d';
		let url_100m_tile = 'tile_potential/100m_tiles';

		fetch(URL + url_total_vs_filter, {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(body_1km_colors)
		}).then((res) => res.json())
			.then((json) => {
				data = json;
			}).then(() => {
				this.setState({
					total_pkm: numberWithCommas((data.total_pkm).toFixed(0)), current_pkm: numberWithCommas((data.current_pkm).toFixed(0)), percentage_pkm: (((data.current_pkm / data.total_pkm) * 100).toFixed(0)), data_1km: data,  current_modal_split: (data.total_modal_split*100).toFixed(0)
				});


				if (counts.true === 1 && this.state.selection_distribution[0]) {
					this.setState({
						green_pkm: ((data.green_pkm).toFixed(0)), green_trips: ((data.green_trips).toFixed(0)), red_pkm: ((data.red_pkm).toFixed(0)), red_trips: ((data.red_trips).toFixed(0)), yellow_pkm: ((data.yellow_pkm).toFixed(0)), yellow_trips: ((data.yellow_trips).toFixed(0)),
						percentage_pkm_green: (((data.green_pkm / data.current_pkm) * 100).toFixed(0)), percentage_pkm_yellow: (((data.yellow_pkm / data.current_pkm) * 100).toFixed(0)), percentage_pkm_red: (((data.red_pkm / data.current_pkm) * 100).toFixed(0)),
						percentage_trips_green: (((data.green_trips / data.current_trips) * 100).toFixed(0)), percentage_trips_red: (((data.red_trips / data.current_trips) * 100).toFixed(0)), percentage_trips_yellow: (((data.yellow_trips / data.current_trips) * 100).toFixed(0))
					});
				}
				else if (counts.true === 1 && this.state.selection_distribution[2]) {
					this.setState({
						green_pkm: ((data.bike_green_pkm).toFixed(0)), green_trips: ((data.bike_green_trips).toFixed(0)), red_pkm: ((data.bike_red_pkm).toFixed(0)), red_trips: ((data.bike_red_trips).toFixed(0)), yellow_pkm: ((data.bike_yellow_pkm).toFixed(0)), yellow_trips: ((data.bike_yellow_trips).toFixed(0)),
						percentage_pkm_green: (((data.bike_green_pkm / data.current_pkm) * 100).toFixed(0)), percentage_pkm_yellow: (((data.bike_yellow_pkm / data.current_pkm) * 100).toFixed(0)), percentage_pkm_red: (((data.bike_red_pkm / data.current_pkm) * 100).toFixed(0)),
						percentage_trips_green: (((data.bike_green_trips / data.current_trips) * 100).toFixed(0)), percentage_trips_red: (((data.bike_red_trips / data.current_trips) * 100).toFixed(0)), percentage_trips_yellow: (((data.bike_yellow_trips / data.current_trips) * 100).toFixed(0))
					});
				}
				else if (counts.true === 1 && this.state.selection_distribution[1]) {
					this.setState({
						green_pkm: ((data.last_mile_green_pkm).toFixed(0)), green_trips: ((data.last_mile_green_trips).toFixed(0)), red_pkm: ((data.last_mile_red_pkm).toFixed(0)), red_trips: ((data.last_mile_red_trips).toFixed(0)), yellow_pkm: ((data.last_mile_yellow_pkm).toFixed(0)), yellow_trips: ((data.last_mile_yellow_trips).toFixed(0)),
						percentage_pkm_green: (((data.last_mile_green_pkm / data.current_pkm) * 100).toFixed(0)), percentage_pkm_yellow: (((data.last_mile_yellow_pkm / data.current_pkm) * 100).toFixed(0)), percentage_pkm_red: (((data.last_mile_red_pkm / data.current_pkm) * 100).toFixed(0)),
						percentage_trips_green: (((data.last_mile_green_trips / data.current_trips) * 100).toFixed(0)), percentage_trips_red: (((data.last_mile_red_trips / data.current_trips) * 100).toFixed(0)), percentage_trips_yellow: (((data.last_mile_yellow_trips / data.current_trips) * 100).toFixed(0))
					});
				}
				else if (counts.true === 1 && this.state.selection_distribution[3]) {
					this.setState({
						green_pkm: ((data.working_green_pkm).toFixed(0)), green_trips: ((data.working_green_trips).toFixed(0)), red_pkm: ((data.working_red_pkm).toFixed(0)), red_trips: ((data.working_red_trips).toFixed(0)), yellow_pkm: ((data.working_yellow_pkm).toFixed(0)), yellow_trips: ((data.working_yellow_trips).toFixed(0)),
						percentage_pkm_green: (((data.working_green_pkm / data.current_pkm) * 100).toFixed(0)), percentage_pkm_yellow: (((data.working_yellow_pkm / data.current_pkm) * 100).toFixed(0)), percentage_pkm_red: (((data.working_red_pkm / data.current_pkm) * 100).toFixed(0)),
						percentage_trips_green: (((data.working_green_trips / data.current_trips) * 100).toFixed(0)), percentage_trips_red: (((data.working_red_trips / data.current_trips) * 100).toFixed(0)), percentage_trips_yellow: (((data.working_yellow_trips / data.current_trips) * 100).toFixed(0))
					});
				}
				else if (counts.true > 1) {
					this.setState({
						green_pkm: ((data.combined_green_pkm).toFixed(0)), green_trips: ((data.combined_green_trips).toFixed(0)), red_pkm: ((data.combined_red_pkm).toFixed(0)), red_trips: ((data.combined_red_trips).toFixed(0)), yellow_pkm: ((data.combined_yellow_pkm).toFixed(0)), yellow_trips: ((data.combined_yellow_trips).toFixed(0)),
						percentage_pkm_green: (((data.combined_green_pkm / data.current_pkm) * 100).toFixed(0)), percentage_pkm_yellow: (((data.combined_yellow_pkm / data.current_pkm) * 100).toFixed(0)), percentage_pkm_red: (((data.combined_red_pkm / data.current_pkm) * 100).toFixed(0)),
						percentage_trips_green: (((data.combined_green_trips / data.current_trips) * 100).toFixed(0)), percentage_trips_red: (((data.combined_red_trips / data.current_trips) * 100).toFixed(0)), percentage_trips_yellow: (((data.combined_yellow_trips / data.current_trips) * 100).toFixed(0))
					});
				}

			})
			.then(() => {

						fetch(URL + url_100m_tile, {
							method: 'POST',
							headers: {
								'Accept': 'application/json',
								'Content-Type': 'application/json'
							},
							body: JSON.stringify(body)
						})
							.then((res) => res.json())
							.then((json) => {
								data = json;
							}).then(() => { this.state.mapa.getSource('data-swiss-100m-tile').setData(data) })
							.then(() => {
								fetch(URL + url_1km_tile, {
									method: 'POST',
									headers: {
										'Accept': 'application/json',
										'Content-Type': 'application/json'
									},
									body: JSON.stringify(body_1km_colors)
								})
									.then((res) => res.json())
									.then((json) => {
										data = json;
									}).then(() => {
										this.state.mapa.getSource('data-swiss-1km-tile').setData(data)
										this.state.mapa.getSource('data-swiss-buhler-center').setData(URL + 'tile_potential/center_tile/' + employer);
										this.props.map.setPaintProperty('swiss-1km-tile', 'fill-color', ['get', color_1km]);
										this.props.map.setPaintProperty('outline-swiss-1km-tile', 'line-color', ['get', color_1km]);
									})
							})
							.then(() => {
								this.props.map.setLayoutProperty('swiss-1km-tile', 'visibility', 'visible');
								this.props.map.setLayoutProperty('outline-swiss-1km-tile', 'visibility', 'visible');
								this.props.map.setLayoutProperty('swiss-100m-tile', 'visibility', 'visible');
								this.props.map.setLayoutProperty('outline-swiss-100m-tile', 'visibility', 'visible');
								this.props.map.setLayoutProperty('swiss-buhler-center', 'visibility', 'visible');
								this.props.map.setLayoutProperty('outline-swiss-buhler-center', 'visibility', 'visible');
							})
							.then(() => {
								fetch(URL + url_total_vs_filter, {
									method: 'POST',
									headers: {
										'Accept': 'application/json',
										'Content-Type': 'application/json'
									},
									body: JSON.stringify(body_1km_colors)
								}).then((res) => res.json())
									.then((json) => {
										data = json;
									}).then(() => {

										this.setState({
											total_pkm: numberWithCommas((data.total_pkm).toFixed(0)), current_pkm: numberWithCommas((data.current_pkm).toFixed(0)), percentage_pkm: (((data.current_pkm / data.total_pkm) * 100).toFixed(0)), data_bus: data,  current_modal_split: (data.total_modal_split*100).toFixed(0)
										});

										if (counts.true === 1 && this.state.selection_distribution[0]) {
											this.setState({
												green_pkm: ((data.green_pkm).toFixed(0)), green_trips: ((data.green_trips).toFixed(0)), red_pkm: ((data.red_pkm).toFixed(0)), red_trips: ((data.red_trips).toFixed(0)), yellow_pkm: ((data.yellow_pkm).toFixed(0)), yellow_trips: ((data.yellow_trips).toFixed(0)),
												current_percentage_pkm_green: (((data.green_pkm / data.current_pkm) * 100).toFixed(0)), current_percentage_pkm_yellow: (((data.yellow_pkm / data.current_pkm) * 100).toFixed(0)), current_percentage_pkm_red: (((data.red_pkm / data.current_pkm) * 100).toFixed(0)),
												current_percentage_trips_green: (((data.green_trips / data.current_trips) * 100).toFixed(0)), current_percentage_trips_red: (((data.red_trips / data.current_trips) * 100).toFixed(0)), current_percentage_trips_yellow: (((data.yellow_trips / data.current_trips) * 100).toFixed(0))
											});
										}
										else if (counts.true === 1 && this.state.selection_distribution[2]) {
											this.setState({
												green_pkm: ((data.bike_green_pkm).toFixed(0)), green_trips: ((data.bike_green_trips).toFixed(0)), red_pkm: ((data.bike_red_pkm).toFixed(0)), red_trips: ((data.bike_red_trips).toFixed(0)), yellow_pkm: ((data.bike_yellow_pkm).toFixed(0)), yellow_trips: ((data.bike_yellow_trips).toFixed(0)),
												current_percentage_pkm_green: (((data.bike_green_pkm / data.current_pkm) * 100).toFixed(0)), current_percentage_pkm_yellow: (((data.bike_yellow_pkm / data.current_pkm) * 100).toFixed(0)), current_percentage_pkm_red: (((data.bike_red_pkm / data.current_pkm) * 100).toFixed(0)),
												current_percentage_trips_green: (((data.bike_green_trips / data.current_trips) * 100).toFixed(0)), current_percentage_trips_red: (((data.bike_red_trips / data.current_trips) * 100).toFixed(0)), current_percentage_trips_yellow: (((data.bike_yellow_trips / data.current_trips) * 100).toFixed(0))
											});
										}
										else if (counts.true === 1 && this.state.selection_distribution[1]) {
											this.setState({
												green_pkm: ((data.last_mile_green_pkm).toFixed(0)), green_trips: ((data.last_mile_green_trips).toFixed(0)), red_pkm: ((data.last_mile_red_pkm).toFixed(0)), red_trips: ((data.last_mile_red_trips).toFixed(0)), yellow_pkm: ((data.last_mile_yellow_pkm).toFixed(0)), yellow_trips: ((data.last_mile_yellow_trips).toFixed(0)),
												current_percentage_pkm_green: (((data.last_mile_green_pkm / data.current_pkm) * 100).toFixed(0)), current_percentage_pkm_yellow: (((data.last_mile_yellow_pkm / data.current_pkm) * 100).toFixed(0)), current_percentage_pkm_red: (((data.last_mile_red_pkm / data.current_pkm) * 100).toFixed(0)),
												current_percentage_trips_green: (((data.last_mile_green_trips / data.current_trips) * 100).toFixed(0)), current_percentage_trips_red: (((data.last_mile_red_trips / data.current_trips) * 100).toFixed(0)), current_percentage_trips_yellow: (((data.last_mile_yellow_trips / data.current_trips) * 100).toFixed(0))
											});
										}
										else if (counts.true === 1 && this.state.selection_distribution[3]) {
											this.setState({
												green_pkm: ((data.working_green_pkm).toFixed(0)), green_trips: ((data.working_green_trips).toFixed(0)), red_pkm: ((data.working_red_pkm).toFixed(0)), red_trips: ((data.working_red_trips).toFixed(0)), yellow_pkm: ((data.working_yellow_pkm).toFixed(0)), yellow_trips: ((data.working_yellow_trips).toFixed(0)),
												current_percentage_pkm_green: (((data.working_green_pkm / data.current_pkm) * 100).toFixed(0)), current_percentage_pkm_yellow: (((data.working_yellow_pkm / data.current_pkm) * 100).toFixed(0)), current_percentage_pkm_red: (((data.working_red_pkm / data.current_pkm) * 100).toFixed(0)),
												current_percentage_trips_green: (((data.working_green_trips / data.current_trips) * 100).toFixed(0)), current_percentage_trips_red: (((data.working_red_trips / data.current_trips) * 100).toFixed(0)), current_percentage_trips_yellow: (((data.working_yellow_trips / data.current_trips) * 100).toFixed(0))
											});
										} else if (counts.true > 1) {
											this.setState({
												green_pkm: ((data.combined_green_pkm).toFixed(0)), green_trips: ((data.combined_green_trips).toFixed(0)), red_pkm: ((data.combined_red_pkm).toFixed(0)), red_trips: ((data.combined_red_trips).toFixed(0)), yellow_pkm: ((data.combined_yellow_pkm).toFixed(0)), yellow_trips: ((data.combined_yellow_trips).toFixed(0)),
												current_percentage_pkm_green: (((data.combined_green_pkm / data.current_pkm) * 100).toFixed(0)), current_percentage_pkm_yellow: (((data.combined_yellow_pkm / data.current_pkm) * 100).toFixed(0)), current_percentage_pkm_red: (((data.combined_red_pkm / data.current_pkm) * 100).toFixed(0)),
												current_percentage_trips_green: (((data.combined_green_trips / data.current_trips) * 100).toFixed(0)), current_percentage_trips_red: (((data.combined_red_trips / data.current_trips) * 100).toFixed(0)), current_percentage_trips_yellow: (((data.combined_yellow_trips / data.current_trips) * 100).toFixed(0))
											});
										}

									}).then(() => { this.setState({ loading: false, generatedBusRoute: true, generatedTyles1Km: false }) });
							})
					})
	}

	handle1kmTiles(min_dist_station, max_dist_station, min_dist_buhler, max_dist_buhler, changes_ov, min_num_trips, max_num_trips, max_distance_travel, num_buses, num_capacity, convertion_rate, employer, maximum_percentage_difference, selection_distribution) {

		let url_1km_tile = 'tile_potential/filter_1km_tiles';
		let url_total_vs_filter = 'tile_potential/total_vs_filter_2d';
		let url_100m_tile = 'tile_potential/100m_tiles';

		let options = []

		if (selection_distribution[0]) {
			options.push('color')
		}
		if (selection_distribution[1]) {
			options.push('color_last_mile')
		}
		if (selection_distribution[2]) {
			options.push('color_bike')
		}
		if (selection_distribution[3]) {
			options.push('color_working')
		}

		const counts = {};
		selection_distribution.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });

		let data;
		let body = { origin: employer, distance_to_station: [min_dist_station, max_dist_station], distance_to_origin: [min_dist_buhler, max_dist_buhler], changes_in_ov: changes_ov, trips_per_tile: [min_num_trips, max_num_trips], maximum_travel_distance: max_distance_travel, num_buses: num_buses, bus_capacity: num_capacity, conversion_rate: convertion_rate, maximum_percentage_difference: maximum_percentage_difference, options: options }
		let body_1km_colors = { origin: employer, distance_to_station: [min_dist_station, max_dist_station], distance_to_origin: [min_dist_buhler, max_dist_buhler], changes_in_ov: changes_ov, trips_per_tile: [min_num_trips, max_num_trips], maximum_travel_distance: max_distance_travel, num_buses: num_buses, bus_capacity: num_capacity, conversion_rate: convertion_rate, last_mile: (this.state.lastMileOffer === 'yes' ? true : false), bike_radius: this.state.bike_radius, threshold_duration_percentage_green: [this.state.cat3_min_percentage, this.state.cat3_max_percentage], threshold_duration_percentage_yellow: [this.state.cat2_min_percentage, this.state.cat2_max_percentage], threshold_duration_percentage_red: [this.state.cat1_min_percentage, this.state.cat1_max_percentage], max_difference_green: this.state.max_min_than_car, max_changes_green: this.state.cat3_num_changes, max_changes_yellow: this.state.cat2_num_changes, min_changes_red: this.state.cat1_num_changes, visited_tiles: [], short_bike_radius: this.state.green_cat_bike, medium_bike_radius: this.state.yellow_cat_bike, minutes_in_train: this.state.minutes_in_train * 60, maximum_percentage_difference: maximum_percentage_difference, options: options }

		fetch(URL + url_1km_tile, {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(body_1km_colors)
		})
			.then((res) => res.json())
			.then((json) => {
				data = json;
			}).then(async () => {
				await delay(5000);
				this.props.map.getSource('data-swiss-1km-tile').setData(data);
				this.props.map.setPaintProperty('swiss-1km-tile', 'fill-color', ['get', color_1km]);
				this.props.map.setPaintProperty('outline-swiss-1km-tile', 'line-color', ['get', color_1km]);
			})
			.then(() => {
				fetch(URL + url_total_vs_filter, {
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(body_1km_colors)
				}).then((res) => res.json())
					.then((json) => {
						data = json;
					}).then(() => {
						this.setState({
							total_pkm: numberWithCommas((data.total_pkm).toFixed(0)), current_pkm: numberWithCommas((data.current_pkm).toFixed(0)), percentage_pkm: (((data.current_pkm / data.total_pkm) * 100).toFixed(0)), data_1km: data,  current_modal_split: (data.total_modal_split*100).toFixed(0)
						});

						this.setState({
							percentage_pkm_statusquo_green: (((data.green_pkm / data.current_pkm) * 100).toFixed(0)),
							percentage_pkm_statusquo_yellow: (((data.yellow_pkm / data.current_pkm) * 100).toFixed(0)),
							percentage_pkm_statusquo_red: (((data.red_pkm / data.current_pkm) * 100).toFixed(0)),

							percentage_trips_statusquo_green: (((data.green_trips / data.current_trips) * 100).toFixed(0)),
							percentage_trips_statusquo_yellow: (((data.yellow_trips / data.current_trips) * 100).toFixed(0)),
							percentage_trips_statusquo_red: (((data.red_trips / data.current_trips) * 100).toFixed(0))
						});

						if (counts.true === 1 && this.state.selection_distribution[0]) {

							this.setState({
								green_pkm: ((data.green_pkm).toFixed(0)), green_trips: ((data.green_trips).toFixed(0)), red_pkm: ((data.red_pkm).toFixed(0)), red_trips: ((data.red_trips).toFixed(0)), yellow_pkm: ((data.yellow_pkm).toFixed(0)), yellow_trips: ((data.yellow_trips).toFixed(0)),
								percentage_pkm_green: (((data.green_pkm / data.current_pkm) * 100).toFixed(0)), percentage_pkm_yellow: (((data.yellow_pkm / data.current_pkm) * 100).toFixed(0)), percentage_pkm_red: (((data.red_pkm / data.current_pkm) * 100).toFixed(0)),
								percentage_trips_green: (((data.green_trips / data.current_trips) * 100).toFixed(0)), percentage_trips_red: (((data.red_trips / data.current_trips) * 100).toFixed(0)), percentage_trips_yellow: (((data.yellow_trips / data.current_trips) * 100).toFixed(0))
							});
							color_1km = 'color'
						}
						else if (counts.true === 1 && this.state.selection_distribution[2]) {
							this.setState({
								green_pkm: ((data.bike_green_pkm).toFixed(0)), green_trips: ((data.bike_green_trips).toFixed(0)), red_pkm: ((data.bike_red_pkm).toFixed(0)), red_trips: ((data.bike_red_trips).toFixed(0)), yellow_pkm: ((data.bike_yellow_pkm).toFixed(0)), yellow_trips: ((data.bike_yellow_trips).toFixed(0)),
								percentage_pkm_green: (((data.bike_green_pkm / data.current_pkm) * 100).toFixed(0)), percentage_pkm_yellow: (((data.bike_yellow_pkm / data.current_pkm) * 100).toFixed(0)), percentage_pkm_red: (((data.bike_red_pkm / data.current_pkm) * 100).toFixed(0)),
								percentage_trips_green: (((data.bike_green_trips / data.current_trips) * 100).toFixed(0)), percentage_trips_red: (((data.bike_red_trips / data.current_trips) * 100).toFixed(0)), percentage_trips_yellow: (((data.bike_yellow_trips / data.current_trips) * 100).toFixed(0))
							});
							color_1km = 'color_bike'
						}
						else if (counts.true === 1 && this.state.selection_distribution[1]) {
							this.setState({
								green_pkm: ((data.last_mile_green_pkm).toFixed(0)), green_trips: ((data.last_mile_green_trips).toFixed(0)), red_pkm: ((data.last_mile_red_pkm).toFixed(0)), red_trips: ((data.last_mile_red_trips).toFixed(0)), yellow_pkm: ((data.last_mile_yellow_pkm).toFixed(0)), yellow_trips: ((data.last_mile_yellow_trips).toFixed(0)),
								percentage_pkm_green: (((data.last_mile_green_pkm / data.current_pkm) * 100).toFixed(0)), percentage_pkm_yellow: (((data.last_mile_yellow_pkm / data.current_pkm) * 100).toFixed(0)), percentage_pkm_red: (((data.last_mile_red_pkm / data.current_pkm) * 100).toFixed(0)),
								percentage_trips_green: (((data.last_mile_green_trips / data.current_trips) * 100).toFixed(0)), percentage_trips_red: (((data.last_mile_red_trips / data.current_trips) * 100).toFixed(0)), percentage_trips_yellow: (((data.last_mile_yellow_trips / data.current_trips) * 100).toFixed(0))
							});
							color_1km = 'color_last_mile'
						}
						else if (counts.true === 1 && this.state.selection_distribution[3]) {
							this.setState({
								green_pkm: ((data.working_green_pkm).toFixed(0)), green_trips: ((data.working_green_trips).toFixed(0)), red_pkm: ((data.working_red_pkm).toFixed(0)), red_trips: ((data.working_red_trips).toFixed(0)), yellow_pkm: ((data.working_yellow_pkm).toFixed(0)), yellow_trips: ((data.working_yellow_trips).toFixed(0)),
								percentage_pkm_green: (((data.working_green_pkm / data.current_pkm) * 100).toFixed(0)), percentage_pkm_yellow: (((data.working_yellow_pkm / data.current_pkm) * 100).toFixed(0)), percentage_pkm_red: (((data.working_red_pkm / data.current_pkm) * 100).toFixed(0)),
								percentage_trips_green: (((data.working_green_trips / data.current_trips) * 100).toFixed(0)), percentage_trips_red: (((data.working_red_trips / data.current_trips) * 100).toFixed(0)), percentage_trips_yellow: (((data.working_yellow_trips / data.current_trips) * 100).toFixed(0))
							});
							color_1km = 'color_working'
						}
						else if (counts.true > 1) {
							this.setState({
								green_pkm: ((data.combined_green_pkm).toFixed(0)), green_trips: ((data.combined_green_trips).toFixed(0)), red_pkm: ((data.combined_red_pkm).toFixed(0)), red_trips: ((data.combined_red_trips).toFixed(0)), yellow_pkm: ((data.combined_yellow_pkm).toFixed(0)), yellow_trips: ((data.combined_yellow_trips).toFixed(0)),
								percentage_pkm_green: (((data.combined_green_pkm / data.current_pkm) * 100).toFixed(0)), percentage_pkm_yellow: (((data.combined_yellow_pkm / data.current_pkm) * 100).toFixed(0)), percentage_pkm_red: (((data.combined_red_pkm / data.current_pkm) * 100).toFixed(0)),
								percentage_trips_green: (((data.combined_green_trips / data.current_trips) * 100).toFixed(0)), percentage_trips_red: (((data.combined_red_trips / data.current_trips) * 100).toFixed(0)), percentage_trips_yellow: (((data.combined_yellow_trips / data.current_trips) * 100).toFixed(0))
							});
							color_1km = 'color_combined'
						}

						this.props.map.setPaintProperty('swiss-1km-tile', 'fill-color', ['get', color_1km]);
						this.props.map.setPaintProperty('outline-swiss-1km-tile', 'line-color', ['get', color_1km]);
					})
					.then(() => { this.setState({ loading: false }) })
			})
			.then(() => {
				fetch(URL + url_100m_tile, {
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(body)
				})
					.then((res) => res.json())
					.then((json) => {
						data = json;
					}).then(() => {
						this.state.mapa.getSource('data-swiss-100m-tile').setData(data);
						this.state.mapa.getSource('data-swiss-buhler-center').setData(URL + 'tile_potential/center_tile/' + employer)
					})
			})
			.then(() => {
				this.state.mapa.setLayoutProperty('swiss-1km-tile', 'visibility', 'visible');
				this.state.mapa.setLayoutProperty('outline-swiss-1km-tile', 'visibility', 'visible');
				this.state.mapa.setLayoutProperty('swiss-1km-tile-labels', 'visibility', 'visible');
				this.props.map.setLayoutProperty('swiss-100m-tile', 'visibility', 'visible');
				this.props.map.setLayoutProperty('outline-swiss-100m-tile', 'visibility', 'visible');
				this.setState({ generatedBusRoute: false })
			})
	}

	handleTilesGenerator() {
		this.setState({ loading: true });

		if (this.state.initial_company['*'] !== "") {
			employer = parseInt(this.state.initial_company['*']);
		}

		this.handle1kmTiles(this.state.distance_min_train_station * 1000, this.state.distance_max_train_station * 1000, this.state.distance_min_buhler * 1000, this.state.distance_max_buhler * 1000, this.state.num_changes_ov, parseInt(this.state.min_num_trips_per_tile), this.state.max_num_trips_per_tile, parseInt(this.state.max_distance_travel), parseInt(this.state.num_buses), parseInt(this.state.num_capacity), parseFloat(this.state.convertion_rate), employer, this.state.maximum_percentage_difference, this.state.selection_distribution)

		this.setState({ generatedTyles1Km: true });
		hide1kmTiles = false;
	}

	handleHide1kmTile() {
		let temp = !hide1kmTiles;
		hide1kmTiles = temp;
		if (temp) {
			this.state.mapa.setLayoutProperty('swiss-1km-tile', 'visibility', 'none');
			this.state.mapa.setLayoutProperty('outline-swiss-1km-tile', 'visibility', 'none');
			this.state.mapa.setLayoutProperty('swiss-1km-tile-labels', 'visibility', 'none');
		}
		else {
			this.state.mapa.setLayoutProperty('swiss-1km-tile', 'visibility', 'visible');
			this.state.mapa.setLayoutProperty('outline-swiss-1km-tile', 'visibility', 'visible');
			this.state.mapa.setLayoutProperty('swiss-1km-tile-labels', 'visibility', 'visible');
		}
	}

	handleHide100mTile() {
		let temp = !hide100mTiles;
		hide100mTiles = temp;
		if (temp) {
			this.state.mapa.setLayoutProperty('swiss-100m-tile', 'visibility', 'none');
			this.state.mapa.setLayoutProperty('outline-swiss-100m-tile', 'visibility', 'none');
		}
		else {
			this.state.mapa.setLayoutProperty('outline-swiss-100m-tile', 'visibility', 'visible');
			this.state.mapa.setLayoutProperty('swiss-100m-tile', 'visibility', 'visible');
		}
	}

	handleMaxMinutesCar(event) {
		this.setState({ max_min_than_car: Number(event.target.value) })
	}

	handleBikeRadius(event) {
		this.setState({ bike_radius: Number(event.target.value) });
	}

	handleCat1NumChanges(event) {
		this.setState({ cat1_num_changes: Number(event.target.value) })
	}

	handleCat2NumChanges(event) {
		this.setState({ cat2_num_changes: Number(event.target.value) })
	}

	handleCat3NumChanges(event) {
		this.setState({ cat3_num_changes: Number(event.target.value) })
	}

	handleCat1MinPercentage(event) {
		this.setState({ cat1_min_percentage: Number(event.target.value) })
	}

	handleCat1MaxPercentage(event) {
		this.setState({ cat1_max_percentage: Number(event.target.value) })
	}

	handleCat2MinPercentage(event) {
		this.setState({ cat2_min_percentage: Number(event.target.value) })
	}

	handleGreenCatBike(event) {
		this.setState({ green_cat_bike: Number(event.target.value) })
	}

	handleMinInTrains(event) {
		this.setState({ minutes_in_train: Number(event.target.value) })
	}

	handleYellowCatBike(event) {
		this.setState({ yellow_cat_bike: Number(event.target.value) })
	}

	handleCat2MaxPercentage(event) {
		this.setState({ cat2_max_percentage: Number(event.target.value) })
	}

	handleCat3MinPercentage(event) {
		this.setState({ cat3_min_percentage: Number(event.target.value) })
	}

	handleCat3MaxPercentage(event) {
		this.setState({ cat3_max_percentage: Number(event.target.value) })
	}

	handleConvertionRate(event) {
		this.setState({ convertion_rate: event.target.value })
	}

	handleMaxDistanceTravel(event) {
		this.setState({ max_distance_travel: Number(event.target.value) })
	}

	handleDistribution(event) {

		let current_selection_distribution = this.state.selection_distribution;

		if (event.target.attributes.getNamedItem('id').value === 'general') {
			current_selection_distribution[0] = (!current_selection_distribution[0])
		} else if (event.target.attributes.getNamedItem('id').value === 'lastmile') {
			current_selection_distribution[1] = (!current_selection_distribution[1])
		} else if (event.target.attributes.getNamedItem('id').value === 'bike') {
			current_selection_distribution[2] = (!current_selection_distribution[2])
		} else if (event.target.attributes.getNamedItem('id').value === 'working') {
			current_selection_distribution[3] = (!current_selection_distribution[3])
		}

		const counts = {};
		current_selection_distribution.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });

		if (counts.true > 1 && current_selection_distribution[0]) {
			if (event.target.attributes.getNamedItem('id').value === 'general') {
				current_selection_distribution = [true, false, false, false]
			} else {
				if (event.target.attributes.getNamedItem('id').value === 'lastmile') {
					current_selection_distribution = [false, true, false, false]
				} else if (event.target.attributes.getNamedItem('id').value === 'bike') {
					current_selection_distribution = [false, false, true, false]
				} else if (event.target.attributes.getNamedItem('id').value === 'working') {
					current_selection_distribution = [false, false, false, true]
				}
			}
		}

		this.setState({ selection_distribution: current_selection_distribution })

		let data_bus = this.state.data_bus;

		if (this.state.generatedBusRoute) {
			if (counts.true === 1 && current_selection_distribution[0]) {
				this.setState({
					green_pkm: ((data_bus.green_pkm).toFixed(0)), green_trips: ((data_bus.green_trips).toFixed(0)), red_pkm: ((data_bus.red_pkm).toFixed(0)), red_trips: ((data_bus.red_trips).toFixed(0)), yellow_pkm: ((data_bus.yellow_pkm).toFixed(0)), yellow_trips: ((data_bus.yellow_trips).toFixed(0)),
					current_percentage_pkm_green: (((data_bus.green_pkm / data_bus.current_pkm) * 100).toFixed(0)), current_percentage_pkm_yellow: (((data_bus.yellow_pkm / data_bus.current_pkm) * 100).toFixed(0)), current_percentage_pkm_red: (((data_bus.red_pkm / data_bus.current_pkm) * 100).toFixed(0)),
					current_percentage_trips_green: (((data_bus.green_trips / data_bus.current_trips) * 100).toFixed(0)), current_percentage_trips_red: (((data_bus.red_trips / data_bus.current_trips) * 100).toFixed(0)), current_percentage_trips_yellow: (((data_bus.yellow_trips / data_bus.current_trips) * 100).toFixed(0))
				});
			}
			else if (counts.true === 1 && current_selection_distribution[2]) {
				this.setState({
					green_pkm: ((data_bus.bike_green_pkm).toFixed(0)), green_trips: ((data_bus.bike_green_trips).toFixed(0)), red_pkm: ((data_bus.bike_red_pkm).toFixed(0)), red_trips: ((data_bus.bike_red_trips).toFixed(0)), yellow_pkm: ((data_bus.bike_yellow_pkm).toFixed(0)), yellow_trips: ((data_bus.bike_yellow_trips).toFixed(0)),
					current_percentage_pkm_green: (((data_bus.bike_green_pkm / data_bus.current_pkm) * 100).toFixed(0)), current_percentage_pkm_yellow: (((data_bus.bike_yellow_pkm / data_bus.current_pkm) * 100).toFixed(0)), current_percentage_pkm_red: (((data_bus.bike_red_pkm / data_bus.current_pkm) * 100).toFixed(0)),
					current_percentage_trips_green: (((data_bus.bike_green_trips / data_bus.current_trips) * 100).toFixed(0)), current_percentage_trips_red: (((data_bus.bike_red_trips / data_bus.current_trips) * 100).toFixed(0)), current_percentage_trips_yellow: (((data_bus.bike_yellow_trips / data_bus.current_trips) * 100).toFixed(0))
				});
			}
			else if (counts.true === 1 && current_selection_distribution[1]) {
				this.setState({
					green_pkm: ((data_bus.last_mile_green_pkm).toFixed(0)), green_trips: ((data_bus.last_mile_green_trips).toFixed(0)), red_pkm: ((data_bus.last_mile_red_pkm).toFixed(0)), red_trips: ((data_bus.last_mile_red_trips).toFixed(0)), yellow_pkm: ((data_bus.last_mile_yellow_pkm).toFixed(0)), yellow_trips: ((data_bus.last_mile_yellow_trips).toFixed(0)),
					current_percentage_pkm_green: (((data_bus.last_mile_green_pkm / data_bus.current_pkm) * 100).toFixed(0)), current_percentage_pkm_yellow: (((data_bus.last_mile_yellow_pkm / data_bus.current_pkm) * 100).toFixed(0)), current_percentage_pkm_red: (((data_bus.last_mile_red_pkm / data_bus.current_pkm) * 100).toFixed(0)),
					current_percentage_trips_green: (((data_bus.last_mile_green_trips / data_bus.current_trips) * 100).toFixed(0)), current_percentage_trips_red: (((data_bus.last_mile_red_trips / data_bus.current_trips) * 100).toFixed(0)), current_percentage_trips_yellow: (((data_bus.last_mile_yellow_trips / data_bus.current_trips) * 100).toFixed(0))
				});
			}
			else if (counts.true === 1 && current_selection_distribution[3]) {
				this.setState({
					green_pkm: ((data_bus.working_green_pkm).toFixed(0)), green_trips: ((data_bus.working_green_trips).toFixed(0)), red_pkm: ((data_bus.working_red_pkm).toFixed(0)), red_trips: ((data_bus.working_red_trips).toFixed(0)), yellow_pkm: ((data_bus.working_yellow_pkm).toFixed(0)), yellow_trips: ((data_bus.working_yellow_trips).toFixed(0)),
					current_percentage_pkm_green: (((data_bus.working_green_pkm / data_bus.current_pkm) * 100).toFixed(0)), current_percentage_pkm_yellow: (((data_bus.working_yellow_pkm / data_bus.current_pkm) * 100).toFixed(0)), current_percentage_pkm_red: (((data_bus.working_red_pkm / data_bus.current_pkm) * 100).toFixed(0)),
					current_percentage_trips_green: (((data_bus.working_green_trips / data_bus.current_trips) * 100).toFixed(0)), current_percentage_trips_red: (((data_bus.working_red_trips / data_bus.current_trips) * 100).toFixed(0)), current_percentage_trips_yellow: (((data_bus.working_yellow_trips / data_bus.current_trips) * 100).toFixed(0))
				});
			}
			else if (counts.true > 1) {
				this.setState({
					green_pkm: ((data_bus.combined_green_pkm).toFixed(0)), green_trips: ((data_bus.combined_green_trips).toFixed(0)), red_pkm: ((data_bus.combined_red_pkm).toFixed(0)), red_trips: ((data_bus.combined_red_trips).toFixed(0)), yellow_pkm: ((data_bus.combined_yellow_pkm).toFixed(0)), yellow_trips: ((data_bus.combined_yellow_trips).toFixed(0)),
					current_percentage_pkm_green: (((data_bus.combined_green_pkm / data_bus.current_pkm) * 100).toFixed(0)), current_percentage_pkm_yellow: (((data_bus.combined_yellow_pkm / data_bus.current_pkm) * 100).toFixed(0)), current_percentage_pkm_red: (((data_bus.combined_red_pkm / data_bus.current_pkm) * 100).toFixed(0)),
					current_percentage_trips_green: (((data_bus.combined_green_trips / data_bus.current_trips) * 100).toFixed(0)), current_percentage_trips_red: (((data_bus.combined_red_trips / data_bus.current_trips) * 100).toFixed(0)), current_percentage_trips_yellow: (((data_bus.combined_yellow_trips / data_bus.current_trips) * 100).toFixed(0))
				});
			}
		} else {
			this.setState({ loading: true });
			this.handle1kmTiles(this.state.distance_min_train_station * 1000, this.state.distance_max_train_station * 1000, this.state.distance_min_buhler * 1000, this.state.distance_max_buhler * 1000, this.state.num_changes_ov, parseInt(this.state.min_num_trips_per_tile), this.state.max_num_trips_per_tile, parseInt(this.state.max_distance_travel), parseInt(this.state.num_buses), parseInt(this.state.num_capacity), parseFloat(this.state.convertion_rate), parseInt(this.state.initial_company['*']), this.state.maximum_percentage_difference, current_selection_distribution)

			this.setState({ generatedTyles1Km: true });
			hide1kmTiles = false;
		}
	}

	handleMaxPercentageDifference(event) {
		this.setState({ maximum_percentage_difference: event });
	}

	render() {
		const { loading } = this.state;
		const { generatedTyles1Km } = this.state;
		const { current_pkm } = this.state;
		const { total_pkm } = this.state;
		const { percentage_pkm } = this.state;

		const { percentage_pkm_red } = this.state;
		const { percentage_pkm_yellow } = this.state;
		const { percentage_pkm_green } = this.state;
		const { percentage_trips_red } = this.state;
		const { percentage_trips_yellow } = this.state;
		const { percentage_trips_green } = this.state;
		const { current_percentage_pkm_red } = this.state;
		const { current_percentage_pkm_yellow } = this.state;
		const { current_percentage_pkm_green } = this.state;
		const { current_percentage_trips_red } = this.state;
		const { current_percentage_trips_yellow } = this.state;
		const { current_percentage_trips_green } = this.state;

		const { red_pkm } = this.state;
		const { green_pkm } = this.state;
		const { yellow_pkm } = this.state;

		const { green_trips } = this.state;
		const { red_trips } = this.state;
		const { yellow_trips } = this.state;

		const { selectedPKm } = this.state;
		const { selection_distribution } = this.state;

		const { percentage_pkm_statusquo_green } = this.state;
		const { percentage_pkm_statusquo_yellow } = this.state;
		const { percentage_pkm_statusquo_red } = this.state;

		const { percentage_trips_statusquo_green } = this.state;
		const { percentage_trips_statusquo_yellow } = this.state;
		const { percentage_trips_statusquo_red } = this.state;


		const selectDate = {
			menuList: styles => {
				return {
					...styles,
					maxHeight: 150,
					borderWidth: 0,
					boxShadow: '0 0 10px 2px rgb(0 0 0 / 10%)',
				};
			}
		};

		if (loading) { // if your component doesn't have to wait for an async action, remove this block 
			return (
				<div className="bg-loader">
					<div className="pos-center-brain-loader">
						<div className="circle-loading-brain">
						</div>
						<svg className='brain-loader' x="0px" y="0px" viewBox="0 0 496 496">
							<path d="M40,449.472V364.28L58.424,352H88v-16H53.576L24,355.72v93.752C14.704,452.784,8,461.584,8,472c0,13.232,10.768,24,24,24				s24-10.768,24-24C56,461.584,49.288,452.784,40,449.472z M32,480c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8s8,3.584,8,8				C40,476.416,36.416,480,32,480z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M46.528,320H88v-16H46.528c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24				C34.416,336,43.216,329.288,46.528,320z M16,312c0-4.416,3.584-8,8-8s8,3.584,8,8c0,4.416-3.584,8-8,8S16,316.416,16,312z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M24,272c10.416,0,19.216-6.712,22.528-16H88v-16H46.528c-3.312-9.296-12.112-16-22.528-16c-13.232,0-24,10.768-24,24				S10.768,272,24,272z M24,240c4.416,0,8,3.584,8,8s-3.584,8-8,8s-8-3.584-8-8C16,243.584,19.584,240,24,240z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M56 272h32v16H56Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M56 208h32v16H56Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M0 176h88v16H0Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M60.688,160H88v-16H67.312L48,124.688v-14.16C57.288,107.216,64,98.416,64,88c0-13.232-10.768-24-24-24S16,74.768,16,88				c0,10.416,6.704,19.216,16,22.528v20.784L60.688,160z M40,80c4.416,0,8,3.584,8,8s-3.584,8-8,8s-8-3.584-8-8S35.584,80,40,80z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M144 67.312L144 88L160 88L160 60.688L131.312 32L0 32L0 48L124.688 48z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M176 35.312L176 88L192 88L192 28.688L163.312 0L0 0L0 16L156.688 16z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M208 56h16v32H208Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M304 0h16v88H304Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M240,46.528V88h16V59.312l16,16V88h16V68.688L262.392,43.08C268.192,38.696,272,31.816,272,24c0-13.232-10.768-24-24-24				s-24,10.768-24,24C224,34.416,230.704,43.216,240,46.528z M248,16c4.416,0,8,3.584,8,8s-3.584,8-8,8s-8-3.584-8-8				S243.584,16,248,16z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M352,51.312L371.312,32h78.16c2.416,6.776,7.752,12.112,14.528,14.528v110.16L444.688,176H408v16h43.312L480,163.312				V46.528c9.288-3.312,16-12.112,16-22.528c0-13.232-10.768-24-24-24c-10.416,0-19.216,6.712-22.528,16h-84.784L336,44.688V88h16				V51.312z M472,16c4.416,0,8,3.584,8,8s-3.584,8-8,8c-4.416,0-8-3.584-8-8S467.584,16,472,16z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M414.08,97.392l17.92,17.92v9.376L412.688,144H408v16h11.312L448,131.312v-22.624L419.312,80h-4.784				c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24C401.904,112,410.416,105.968,414.08,97.392z				 M392,96c-4.416,0-8-3.584-8-8s3.584-8,8-8c4.416,0,8,3.584,8,8S396.416,96,392,96z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M408 208h88v16H408Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M480,449.472V268.688L451.312,240H408v16h36.688L464,275.312v41.376L451.312,304H408v16h36.688L464,339.312v110.16				c-9.296,3.312-16,12.112-16,22.528c0,13.232,10.768,24,24,24s24-10.768,24-24C496,461.584,489.288,452.784,480,449.472z M472,480				c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8c4.416,0,8,3.584,8,8C480,476.416,476.416,480,472,480z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M408 272h32v16H408Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M448,364.688L419.312,336H408v16h4.688L432,371.312V416h-80v-8h-16v24h96v12.688L412.688,464h-14.16				c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24c10.416,0,19.216-6.712,22.528-16h20.784				L448,451.312V364.688z M376,480c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8c4.416,0,8,3.584,8,8C384,476.416,380.416,480,376,480z				" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M160,408h-16v8h-41.472c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24				c10.416,0,19.216-6.712,22.528-16H160V408z M80,432c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8s8,3.584,8,8				C88,428.416,84.416,432,80,432z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M304 408h16v88H304Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M272 408h16v88H272Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M240,420.688l-16,16V408h-16v41.472c-9.296,3.312-16,12.112-16,22.528c0,13.232,10.768,24,24,24s24-10.768,24-24				c0-7.816-3.808-14.696-9.608-19.08L256,427.312V408h-16V420.688z M216,480c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8s8,3.584,8,8				C224,476.416,220.416,480,216,480z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M192 408L176 408L176 428.688L152 452.688L152 496L168 496L168 459.312L192 435.312z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M336,392h16v-16h24v-24h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16				v-16h-16v-24h-24v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-3.312				L120,140.688V144h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v24h24v16h16v-16h16				v16h16v-16h16v16h16v-16h16v16h16v-16h16v16h16v-16h16v16h16v-16h16V392z M136,360V147.312L147.312,136H360v224H136z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M318.888,211.048l13.96-13.96l-33.936-33.936l-13.96,13.96c-4.16-2.184-8.488-3.976-12.952-5.384V152h-48v19.728				c-4.472,1.408-8.8,3.2-12.952,5.384l-13.96-13.96l-33.936,33.936l13.96,13.96c-2.176,4.16-3.976,8.488-5.384,12.952H152v48				h19.728c1.408,4.472,3.2,8.8,5.384,12.952l-13.96,13.96l33.936,33.936l13.96-13.96c4.16,2.176,8.48,3.976,12.952,5.384V344h48				v-19.728c4.472-1.408,8.8-3.2,12.952-5.384l13.96,13.96l33.936-33.936l-13.96-13.96c2.176-4.152,3.976-8.48,5.384-12.952H344v-48				h-19.728C322.864,219.528,321.072,215.2,318.888,211.048z M328,256h-16.224l-1.416,6.224c-1.6,7.016-4.368,13.672-8.216,19.792				l-3.408,5.408l11.496,11.496l-11.312,11.312l-11.496-11.496l-5.408,3.408c-6.12,3.848-12.776,6.616-19.792,8.216L256,311.776V328				h-16v-16.224l-6.224-1.416c-7.016-1.6-13.672-4.368-19.792-8.216l-5.408-3.408l-11.496,11.496l-11.312-11.312l11.496-11.496				l-3.408-5.408c-3.848-6.12-6.616-12.776-8.216-19.792L184.224,256H168v-16h16.224l1.416-6.224				c1.6-7.016,4.368-13.672,8.216-19.792l3.408-5.408l-11.496-11.496l11.312-11.312l11.496,11.488l5.408-3.408				c6.12-3.848,12.776-6.616,19.792-8.216l6.224-1.408V168h16v16.224l6.224,1.416c7.016,1.6,13.672,4.368,19.792,8.216l5.408,3.408				l11.496-11.488l11.312,11.312l-11.496,11.496l3.408,5.408c3.848,6.12,6.616,12.776,8.216,19.792l1.416,6.216H328V256z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M248,208c-22.056,0-40,17.944-40,40c0,22.056,17.944,40,40,40c22.056,0,40-17.944,40-40C288,225.944,270.056,208,248,208				z M248,272c-13.232,0-24-10.768-24-24s10.768-24,24-24s24,10.768,24,24S261.232,272,248,272z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
						</svg>
					</div>
				</div>
			)
		}
		else {
			return (
				<div>
					<div className="modal-potential-tile-params">
						<div className="modal-potential-tile-params-inner">
							<div className="row-parameters">
								<div className="column-1-55"><h2>Tile ÖV Quality Analysis</h2></div>
								<div className="column-2-45">
									<Form onClick={this.handleTilesGenerator.bind(this)}>
										<Button className="generate-btn" variant="info">Generate 1Km Tiles</Button>
									</Form>
								</div>
							</div>
							<br />
							<Tabs
								defaultActiveKey="category3"
								id="uncontrolled-tab-example"
								className="mb-3">
								<Tab eventKey="category3" title="Green">
									<div className="row-parameters-2">
										<div className="column-1-40"><p className="label-2">Max. percentage longer than a car trip:</p></div>
										<div className="column-2-60"><input type="number" value={this.state.cat3_max_percentage} onChange={this.handleCat3MaxPercentage.bind(this)} /></div>
									</div>
									<p className="label">OR</p>
									<div className="row-parameters-2">
										<div className="column-1-40"><p className="label-2">Max. time in minutes longer than a car trip:</p></div>
										<div className="column-2-60"><input type="number" value={this.state.max_min_than_car} onChange={this.handleMaxMinutesCar.bind(this)} /></div>
									</div>
									<hr />
									<div className="row-parameters">
										<div className="column-1-40"><p className="label-2">Max. number of öv changes:</p></div>
										<div className="column-2-60"><input type="number" value={this.state.cat3_num_changes} onChange={this.handleCat3NumChanges.bind(this)} /></div>
									</div>
								</Tab>
								<Tab eventKey="category2" title="Yellow">
									<div className="row-parameters-2">
										<div className="column-1-40"><p className="label-2">Min. percentage longer than a car trip:</p></div>
										<div className="column-2-60"><input type="number" value={this.state.cat2_min_percentage} onChange={this.handleCat2MinPercentage.bind(this)} /></div>
									</div>
									<div className="row-parameters-2">
										<div className="column-1-40"><p className="label-2">Max. percentage longer than a car trip:</p></div>
										<div className="column-2-60"><input type="number" value={this.state.cat2_max_percentage} onChange={this.handleCat2MaxPercentage.bind(this)} /></div>
									</div>
									<hr />
									<div className="row-parameters">
										<div className="column-1-40"><p className="label-2">Max. number of öv changes:</p></div>
										<div className="column-2-60"><input type="number" value={this.state.cat2_num_changes} onChange={this.handleCat2NumChanges.bind(this)} /></div>
									</div>
								</Tab>
								<Tab eventKey="category1" title="Red">
									<div className="row-parameters-2">
										<div className="column-1-40"><p className="label-2">Min. percentage longer than a car trip:</p></div>
										<div className="column-2-60"><input type="number" value={this.state.cat1_min_percentage} onChange={this.handleCat1MinPercentage.bind(this)} /></div>
									</div>
									<hr />
									<div className="row-parameters">
										<div className="column-1-40"><p className="label-2">Max. number of öv changes:</p></div>
										<div className="column-2-60"><input type="number" value={this.state.cat1_num_changes} onChange={this.handleCat1NumChanges.bind(this)} /></div>
									</div>
								</Tab>
								<Tab eventKey="lastmile" title="Last Mile">
									<p className="label-2">Would you like to analyze last mile offer? </p>
									<p className="label">By enabling this feature, each trip will be calculated to the nearest train station to the destination. From there, a direct bus will be used to complete the journey to the destination. The fastest car travel time for the final mile will also be considered.</p>
									<hr />
									<p className="label-2">Distance in meters to destination:</p>
									<input type="number" value={this.state.bike_radius} onChange={this.handleBikeRadius.bind(this)} />
								</Tab>
								<Tab eventKey="bicycle" title="Cycling Distance">
									<p className="label">The <i>Green Radius</i> represents the maximum distance covered by the 'Green Category', which indicates the best locations for performing last mile trips by bike. The <i>Yellow Radius</i> signifies the maximum distance for the 'Yellow Category', representing good but not optimal locations for biking the last mile.</p>
									<hr />
									<div className="row-parameters-2">
										<div className="column-1-40"><p className="label-2">Green radius:</p></div>
										<div className="column-2-60"><input type="number" value={this.state.green_cat_bike} onChange={this.handleGreenCatBike.bind(this)} /></div>
									</div>
									<div className="row-parameters-2">
										<div className="column-1-40"><p className="label-2">Yellow Radius:</p></div>
										<div className="column-2-60"><input type="number" value={this.state.yellow_cat_bike} onChange={this.handleYellowCatBike.bind(this)} /></div>
									</div>
								</Tab>
								<Tab eventKey="working" title="Working Time">
									<p className="label-2">Enable your employees to work during the trip</p>
									<p className="label">This feature highlights train travel times exceeding a specified number in green, enabling employees to identify suitable origins for working during their journey.</p>

									<div className="row-parameters-2">
										<div className="column-1-40"><p className="label-2">Minimun number of minutes:</p></div>
										<div className="column-2-60"><input type="number" value={this.state.minutes_in_train} onChange={this.handleMinInTrains.bind(this)} /></div>
									</div>
									<div className="row-parameters-special">
										<div className="column-1-40"><p className="label-2">Max. percentage difference:</p></div>
										<div className="column-2-60"><Slider min={0} max={300} step={5} defaultValue={this.state.maximum_percentage_difference} onChange={_.debounce(this.handleMaxPercentageDifference.bind(this), 33)} /></div>
									</div>
									<p className='label'>Train will take {this.state.maximum_percentage_difference}% more time to reach the destination</p>
								</Tab>
							</Tabs>
						</div>
					</div>
					{generatedTyles1Km ?
						<>
							<div className="map-b2b-modal-split" style={{ top: "590px" }}>
								<div className="map-b2b-modal-split-inner">
									<div className="row">
										<h1>Current train's modal split: {this.state.current_modal_split}%</h1>
									</div>
								</div>
							</div>
							<div className="map-data-from" style={{ top: "640px" }}>
								<div className="map-data-from-inner">
									<div className="row">
										<p>Data computed based on Swisscom and own routing algorithm</p>
									</div>
								</div>
							</div>
							<div className="map-potential-btn-drop-point-info-display">
								<div className="map-potential-btn-drop-point-info-display-inner">
									<h2>Visualize contribution percentage by</h2>
									<Form onChange={this.handleVisualization.bind(this)}>
										<div className="row">
											<div className="column-1-eq">
												<Form.Check inline label=" Passenger Km" name="type_analysis" type='radio' id='pkm' defaultChecked={(selectedPKm === 'pkm')} />
											</div>
											<div className="column-2-eq">
												<Form.Check inline label=" Number of Trips" name="type_analysis" type='radio' id='trips' defaultChecked={(selectedPKm === 'trips')} />
											</div>
										</div>
									</Form>
								</div>
							</div>
							<div className="map-potential-btn-drop-point-info-category">
								<div className="map-potential-btn-drop-point-info-category-inner">
									<h2>Simulate Green Mobility Potential</h2>
									<Form onChange={this.handleDistribution.bind(this)}>
										<div className="row">
											<div className="column-headquarters-2">
												<Form.Check inline label="Status Quo" name="type_analysis" type='checkbox' id='general' defaultChecked={(selection_distribution[0])} />
											</div>
											<div className="column-headquarters-3">
												<Form.Check inline label="Last-Mile Optimization" name="type_analysis" type='checkbox' id='lastmile' defaultChecked={(selection_distribution[1])} />
											</div>
											<div className="column-headquarters-1">
												<Form.Check inline label="Bicycle" name="type_analysis" type='checkbox' id='bike' defaultChecked={(selection_distribution[2])} />
											</div>
											<div className="column-headquarters-4">
												<Form.Check inline label="Working Time" name="type_analysis" type='checkbox' id='working' defaultChecked={(selection_distribution[3])} />
											</div>
										</div>
									</Form>
								</div>
							</div>
							{(selectedPKm === 'pkm') ?
								<>
									{(selection_distribution[0] === true)
										?
										<>
											<div className="map-potential-btn-tile-potential-resp-1">
												<div className="map-btn-tile-potential-resp-1-inner">
													<div className="row-parameters-3">
														<h3>{percentage_pkm_green}% PKm</h3>
														<h4>{numberWithCommas(green_pkm)} PKm</h4>
														<h5>Great ÖV Quality</h5>
													</div>
												</div>
											</div>
											<div className="map-potential-btn-tile-potential-resp-2">
												<div className="map-btn-tile-potential-resp-2-inner">
													<div className="row-parameters-3">
														<h3>{percentage_pkm_yellow}% PKm</h3>
														<h4>{numberWithCommas(yellow_pkm)} PKm</h4>
														<h5>Decent ÖV Quality</h5>
													</div>
												</div>
											</div>
											<div className="map-potential-btn-tile-potential-resp-3">
												<div className="map-btn-tile-potential-resp-3-inner">
													<div className="row-parameters-3">
														<h3>{percentage_pkm_red}% PKm</h3>
														<h4>{numberWithCommas(red_pkm)} PKm</h4>
														<h5>Bad ÖV Quality</h5>
													</div>
												</div>
											</div>
										</>
										:
										<>
											<div className="map-potential-btn-tile-potential-resp-1">
												<div className="map-btn-tile-potential-resp-1-inner">
													<div className="row-parameters-3">
														<h3>{percentage_pkm_green}% PKm</h3>
														<h5>Great ÖV Quality</h5>
														<h4>&#123; Before: {percentage_pkm_statusquo_green}% PKm &#125;</h4>
													</div>
												</div>
											</div>
											<div className="map-potential-btn-tile-potential-resp-2">
												<div className="map-btn-tile-potential-resp-2-inner">
													<div className="row-parameters-3">
														<h3>{percentage_pkm_yellow}% PKm</h3>
														<h5>Decent ÖV Quality</h5>
														<h4>&#123; Before: {percentage_pkm_statusquo_yellow}% PKm &#125;</h4>
													</div>
												</div>
											</div>
											<div className="map-potential-btn-tile-potential-resp-3">
												<div className="map-btn-tile-potential-resp-3-inner">
													<div className="row-parameters-3">
														<h3>{percentage_pkm_red}% PKm</h3>
														<h5>Bad ÖV Quality</h5>
														<h4>&#123; Before: {percentage_pkm_statusquo_red}% PKm &#125;</h4>
													</div>
												</div>
											</div>
										</>
									}
								</>
								:
								<>
									{(selection_distribution[0] === true)
										?
										<>
											<div className="map-potential-btn-tile-potential-resp-1">
												<div className="map-btn-tile-potential-resp-1-inner">
													<div className="row-parameters-3">
														<h3>{percentage_trips_green}% Trips</h3>
														<h4>{numberWithCommas(green_trips)} Trips</h4>
														<h5>Great ÖV Quality</h5>
													</div>
												</div>
											</div>
											<div className="map-potential-btn-tile-potential-resp-2">
												<div className="map-btn-tile-potential-resp-2-inner">
													<div className="row-parameters-3">
														<h3>{percentage_trips_yellow}% Trips</h3>
														<h4>{numberWithCommas(yellow_trips)} Trips</h4>
														<h5>Decent ÖV Quality</h5>
													</div>
												</div>
											</div>
											<div className="map-potential-btn-tile-potential-resp-3">
												<div className="map-btn-tile-potential-resp-3-inner">
													<div className="row-parameters-3">
														<h3>{percentage_trips_red}% Trips</h3>
														<h4>{numberWithCommas(red_trips)} Trips</h4>
														<h5>Bad ÖV Quality</h5>
													</div>
												</div>
											</div>
										</>
										:
										<>
											<div className="map-potential-btn-tile-potential-resp-1">
												<div className="map-btn-tile-potential-resp-1-inner">
													<div className="row-parameters-3">
														<h3>{percentage_trips_green}% Trips</h3>
														<h5>Great ÖV Quality</h5>
														<h4>&#123; Before: {percentage_trips_statusquo_green}% PKm &#125;</h4>
													</div>
												</div>
											</div>
											<div className="map-potential-btn-tile-potential-resp-2">
												<div className="map-btn-tile-potential-resp-2-inner">
													<div className="row-parameters-3">
														<h3>{percentage_trips_yellow}% Trips</h3>
														<h5>Decent ÖV Quality</h5>
														<h4>&#123; Before: {percentage_trips_statusquo_yellow}% PKm &#125;</h4>
													</div>
												</div>
											</div>
											<div className="map-potential-btn-tile-potential-resp-3">
												<div className="map-btn-tile-potential-resp-3-inner">
													<div className="row-parameters-3">
														<h3>{percentage_trips_red}% Trips</h3>
														<h5>Bad ÖV Quality</h5>
														<h4>&#123; Before: {percentage_trips_statusquo_red}% PKm &#125;</h4>
													</div>
												</div>
											</div>
										</>
									}
								</>
							}
						</>
						: <></>
					}
					{no_data ?
						<>
							<div className="modal-alert">
								<div className="modal-alert-inner">
									<h1>No data available</h1>
									<p>Please modify the current parameters and click again on <i>Evaluate potential</i></p>
								</div>
							</div>
						</>
						: <></>
					}
				</div>
			)
		}
	}
}

class MapPotentialEmployer2D extends React.Component {

	// Set up states for updating map 
	constructor(props) {
		super(props);
		this.state = {
			lng: 8.251313,
			lat: 46.924143,
			zoom: 7,
			mapita: 9,
			tyle: 'mapbox://styles/mapbox/light-v10',
			hideNavs: false,
			initial_company: this.props.params,
		}
	}

	//Update several fields
	updateFields(list) {
		this.setState(list);
	}

	updateField(card, value) {
		this.setState({
			...this.state,
			[card]: value
		});
	}

	//Update style
	handleTyle(value) {
		this.setState({ tyle: value }, this.componentDidMount);
	}

	//Update coordinates
	handleCoord(center, zoom) {
		const { lng, lat } = center;
		this.setState({ zoom: zoom, lat: lat, lng: lng })
	}

	componentDidMount() {
		// Set up map 
		const map = new mapboxgl.Map({
			container: this.mapContainer,
			style: this.state.tyle,
			center: [this.state.lng, this.state.lat],
			zoom: this.state.zoom,
		});

		map.on('load', () => {

			// Add zoom and rotation controls to the map.
			map.addControl(new mapboxgl.NavigationControl({ showCompass: false, showZoom: false }), 'top-left');
			map.addControl(new MapboxGeocoder({ accessToken: mapboxgl.accessToken, mapboxgl: mapboxgl, marker: false }), 'top-left');

			//Circles concentric
			//Add shapes to the map
			map.addSource('data-swiss-1km-tile', {
				'type': 'geojson',
				'data': lines_geojson,
				'generateId': true
			});

			map.addSource('data-swiss-100m-tile', {
				'type': 'geojson',
				'data': lines_geojson,
				'generateId': true
			});

			map.addSource('data-swiss-buhler-center', {
				'type': 'geojson',
				'data': URL + 'tile_potential/center_tile/' + employer,
				'generateId': true
			});

			//Tyle visualization - 1km

			map.addLayer({
				'id': 'swiss-1km-tile',
				'type': 'fill',
				'source': 'data-swiss-1km-tile',
				'layout': {},
				'paint': {
					'fill-color': ['get', color_1km],
					'fill-opacity': 0.4
				}
			});

			map.addLayer({
				'id': 'outline-swiss-1km-tile',
				'type': 'line',
				'source': 'data-swiss-1km-tile',
				'layout': {},
				'paint': {
					'line-color': ['get', color_1km],
					'line-width': 1
				}
			});

			map.addLayer({
				'id': 'swiss-1km-tile-labels',
				'type': 'symbol',
				'source': 'data-swiss-1km-tile',
				"minzoom": 11,
				'layout': {
					'text-field': ['get', 'total_trips_weekdays_tiles'],
					'text-font': [
						'Open Sans Bold',
						'Arial Unicode MS Bold'
					],
					'text-size': 14,
					'text-transform': 'uppercase',
					'text-letter-spacing': 0.05,
					'text-offset': [0, 1],
					'visibility': 'visible'
				}
			});


			//Tyle visualization - 100m

			map.addLayer({
				'id': 'swiss-100m-tile',
				'type': 'fill',
				'source': 'data-swiss-100m-tile',
				'layout': {},
				'paint': {
					'fill-color': '#9E2E6A',
					'fill-opacity': 0.4
				}
			});

			map.addLayer({
				'id': 'outline-swiss-100m-tile',
				'type': 'line',
				'source': 'data-swiss-100m-tile',
				'layout': {},
				'paint': {
					'line-color': '#9E2E6A',
					'line-width': 1
				}
			});

			//Buhler center
			map.addLayer({
				'id': 'swiss-buhler-center',
				'type': 'fill',
				'source': 'data-swiss-buhler-center',
				'layout': {
					'visibility': 'none'
				},
				'paint': {
					'fill-color': '#1E26BB',
					'fill-opacity': 0.4
				}
			});

			map.addLayer({
				'id': 'outline-swiss-buhler-center',
				'type': 'line',
				'source': 'data-swiss-buhler-center',
				'layout': {
					'visibility': 'none'
				},
				'paint': {
					'line-color': '#1E26BB',
					'line-width': 1
				}
			});

		});

		this.setState({
			mapita: map,
			zoom: map.getZoom(),
			center: map.getCenter(),
		});

		// Fullscreen map 
		//map.addControl(new mapboxgl.FullscreenControl());
		map.on('click', 'swiss-1km-tile', (e) => {

			let resp_data = e.features[0].properties

			let total_trips_weekdays_tiles = (resp_data.total_trips_weekdays_tiles).toFixed(0)
			let duration_car_text = resp_data.duration_car_text
			let duration_train_text = resp_data.duration_train_text
			let n_trains = resp_data.n_trains
			let geodesic_distance_m = (resp_data.geodesic_distance_m).toFixed(0)

			let time_in_trains = (resp_data.time_in_trains).toFixed(0);

			let html = '<table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle;"><tbody><tr><th>Total trips during weekdays per day</th></tr><tr><td>' + total_trips_weekdays_tiles + '</td></tr><tr><th>Duration in car</th></tr><tr><td>' + duration_car_text + '</td><tr><th>Duration in public transport</th></tr><tr><td>' + duration_train_text + '</td><tr><th>Number of öv vehicles</th></tr><tr><td>' + n_trains + '</td><tr><th>Distance in meters</th></tr><tr><td>' + numberWithCommas(geodesic_distance_m) + '</td></tr><th>Time in a train</th></tr><tr><td>' + time_in_trains + ' min</td></tbody></table>';

			new mapboxgl.Popup()
				.setLngLat(e.lngLat)
				.setHTML(html).addTo(map);
		});

		map.on('click', 'swiss-buhler-center', (e) => {

			let resp_data = JSON.parse(e.features[0].properties.counts)
			//let resp_date = e.features[0].properties.date
			let maxValue = 0
			let agg_html = ''

			maxValue = getMax(resp_data)
			for (let i = 0; i < resp_data.length; i++) {
				let percentage = ((resp_data[i] / maxValue) * 100).toFixed(0)
				agg_html = agg_html + '<tr><td>' + i + ':00</td><td style="margin: 0em; font-size:10px, font-weight:lighter; width:55%"><span class="block" style="display: block;height: 20px;color: black;font-size: 1em;float: left;background-color: rgba(247, 137, 72, 0.5); border: 1px solid #F78948; position: relative;overflow: hidden;width:' + percentage + '%"></span><td><td>' + resp_data[i] + '</td></tr>'
			}

			let html = '<div><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Distribution of people during a day</span></p><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle; width: 100%;">' + agg_html + '</table></div>';

			new mapboxgl.Popup()
				.setLngLat(e.lngLat)
				.setHTML(html).addTo(map);
		});

		// Change the cursor to a pointer when the mouse is over the places layer.
		map.on('mouseenter', 'swiss-buhler-center', () => {
			map.getCanvas().style.cursor = 'pointer';
		});

		// Change it back to a pointer when it leaves.
		map.on('mouseleave', 'swiss-buhler-center', () => {
			map.getCanvas().style.cursor = '';
		});
	}

	render() {
		const { hideNavs } = this.state;

		return (
			<div>
				<div ref={el => this.mapContainer = el} style={{ width: '100%', height: '100vh' }}>
				</div>
				<HideElementsComponent map={this.state.mapita} updateField={(card, field, value) => this.updateField(card, field, value)} />
					<div style={{ display: hideNavs ? 'none' : 'block' }}>
						<BtnsComponent map={this.state.mapita} updateFields={(list) => this.updateFields(list)} initial_company={this.state.initial_company} />
					</div>
				<LayerTylesComponent map={this.state.mapita} tyle={this.state.tyle} handleTyle={(value) => this.handleTyle(value)} handleCoord={(value1, value2) => this.handleCoord(value1, value2)} />
				<RecommendationNavBarComponent />
			</div>
		)
	}
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => (
	<MapPotentialEmployer2D
		{...props}
		params={useParams()}
	/>
); 