/* eslint-disable react/style-prop-object */
import React from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import { IconContext } from "react-icons";
import Slider from 'rc-slider';
import axios from 'axios';
import Select from 'react-select';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { BsLayersHalf } from "react-icons/bs";
import '../../components/layer-tyles.css';
import '../css/map-tabs-boostrap-graphs.css';
import '../css/map-potential-routes.css';
import RecommendationNavBarComponent from "../../components/RecommendationNavBarComponent";

const _ = require('lodash');

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const URL = process.env.REACT_APP_URL_SERVER_BACK;

let URL_points = fetchStations();

function fetchStations() {
	let data;
	fetch(URL + 'potential_routes/routes_template/stations', {
		method: 'POST',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({ means_of_transport: 'Zug' })
	})
		.then((res) => res.json())
		.then((json) => {
			data = json;
		}).then(() => { URL_points = data })
}

//Layer Tyles Selection - Side Menu
class LayerTylesComponent extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			showOptions: false,
			mapa: this.props.map,
		}
		this.hideComponent = this.hideComponent.bind(this);
		this.handleTyleEvent = this.handleTyleEvent.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (this.props.map !== prevProps.map) {
			this.setState({ mapa: this.props.map })
		}
	}

	hideComponent(name) {
		this.setState({ showOptions: !this.state.showOptions });
	}

	handleTyleEvent(event) {
		switch (event) {
			case "default":
				this.props.handleTyle("mapbox://styles/mapbox/light-v10");
				break;
			case "outdoors":
				this.props.handleTyle("mapbox://styles/mapbox/outdoors-v11");
				break;
			case "satellite":
				this.props.handleTyle("mapbox://styles/mapbox/satellite-streets-v11");
				break;
			case "streets":
				this.props.handleTyle("mapbox://styles/mapbox/streets-v11");
				break;
			case "dark":
				this.props.handleTyle("mapbox://styles/mapbox/dark-v10");
				break;
			default:
				// eslint-disable-next-line no-unused-expressions
				null;
		}
		this.props.handleCoord(this.state.mapa.getCenter(), this.state.mapa.getZoom());
	}

	render() {
		const { showOptions } = this.state;

		return (
			<div>
				{showOptions ? <>
					<div id='layer-options' className='map-layer-options-tyles'>
						<div className='map-layer-option-dark' onClick={() => this.handleTyleEvent("dark")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/Dark.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Dark</p>
						</div>
						<div className='map-layer-option-streets' onClick={() => this.handleTyleEvent("streets")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/Streets.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Streets</p>
						</div>
						<div className='map-layer-option-satellite' onClick={() => this.handleTyleEvent("satellite")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/Satellite.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Satellite</p>
						</div>
						<div className='map-layer-option-outdoors' onClick={() => this.handleTyleEvent("outdoors")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/Outdoors.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Outdoors</p>
						</div>
						<div className='map-layer-option-default' onClick={() => this.handleTyleEvent("default")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/White.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Default</p>
						</div>
					</div>
				</> : <></>}
				<div className='map-layer-tyles'>
					<div className='map-layer-tyles-inner' onClick={() => this.hideComponent("showOptions")}>
						<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
							<div>
								<BsLayersHalf />
							</div>
						</IconContext.Provider>
					</div>
				</div>
			</div>
		)
	}
}

//Component - Time to Input
class TimeToComponent extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			mapa: this.props.map,
			id_station: this.props.current_station,
			minTo: 0,
			value_od_dates: ({
				"value" : "2022-08",
				"label" : '2022-08'
			  }),
			dates_trips_: this.getOptionsDate(URL+'db_od_trips')
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.map !== prevProps.map) {
			this.setState({ mapa: this.props.map })
		}
		if (this.props.current_station !== prevProps.current_station) {
			this.setState({ id_station: this.props.current_station })
		}
	}

	componentDidMount() {
		this.handleChangeMinTo = this.handleChangeMinTo.bind(this);
	}

	handleChangeMinTo(event) {
		this.setState({minTo: event})

		if(event !== 0){
			let URL_heatmap =URL+'time-to-ov/{0}/{1}/{2}'.format(this.state.value_od_dates.value,this.state.id_station,event);
			this.state.mapa.getSource('data-lines-routes').setData(URL_heatmap);
		}
	}

	handleMonthYear(event){
		this.setState({ value_od_dates: event });
		if(this.state.array_selected_stations.length !==0){
			this.handleFecthDataRouteDateChanged(event.value)
		}
		console.log(this.state.selected_station_select_react)
		if(this.state.selected_station_select_react.value!==''){
			this.stationInsightsHandlerDateChanged(this.state.selected_station_select_react, event.value)
		}
	}

	async getOptionsDate(url){
		const res = await axios.get(url)
		const data = res.data
		const options = data.map(d => ({
		  "value" : d._id.date.toString().split('T')[0].slice(0,-3),
		  "label" : d._id.date.toString().split('T')[0].slice(0,-3)
		}))
		this.setState({dates_trips_: options});
		this.setState({value_od_dates: options[options.length-1]});
	}

	render() {

		const { value_od_dates } = this.state;


		const selectDate = {
			menuList: styles => {
			  return {
				...styles,
				maxHeight: 150,
				borderWidth: 0,
				boxShadow: '0 0 10px 2px rgb(0 0 0 / 10%)',
			  };
			}
		  };

		return (
			<div>
				<div className="map-month-year-picker">
					<Select className='dates' options={this.state.dates_trips_} onChange={this.handleMonthYear.bind(this)} styles={selectDate} defaultValue={value_od_dates}/>
				</div>
				<div className="modal-slider-accessability">
					<div className="modal-slider-accessability-inner">
						<h1>Potential of routes</h1>
						<div className='insight-analyzer-modal-slider-inner'>
							<Slider min={0} max={60} step={1} defaultValue={this.state.minTo} onChange={_.debounce(this.handleChangeMinTo.bind(this),33)}/>
						</div>
						<p className='label'>Showing accesability from <span>{this.state.minTo}</span> minutes from a train station</p>
					</div>
				</div>
			</div>
		)
	}
}


//Map - Time to Öv
class MappTime_to_OV extends React.Component {

	// Set up states for updating map 
	constructor(props) {
		super(props);
		this.state = {
			lng:8.251313,
			lat: 46.924143,
			zoom: 7,
			mapita: 9,
			tyle: 'mapbox://styles/mapbox/light-v10',
			month_year: '2022-08',
			current_station: 0,
			current_selected_id: -1
		}
		this.handleTyle = this.handleTyle.bind(this);
	}

	//Update several fields
	updateFields(list) {
		this.setState(list);
	}

	//Update map on component update
	updateField(card, value) {
		this.setState({
			...this.state,
			[card]: value
		});
	}

	//Update coordinates
    handleCoord(center, zoom){
        const {lng, lat} = center;
        this.setState({zoom: zoom, lat: lat, lng: lng})
    }

	//Update style
	handleTyle(value) {
		this.setState({ tyle: value }, this.componentDidMount);
	}

	componentDidMount() {
		// Set up map 
		const map = new mapboxgl.Map({
			container: this.mapContainer,
			style: this.state.tyle,
			center: [this.state.lng, this.state.lat],
			zoom: this.state.zoom,
		});

		map.on('load', () => {
			// Add zoom and rotation controls to the map.
			map.addControl(new mapboxgl.NavigationControl({ showCompass: false, showZoom: false }), 'top-left');
			map.addControl(new MapboxGeocoder({ accessToken: mapboxgl.accessToken, mapboxgl: mapboxgl, marker: false }), 'top-left');

			//Circles - Stations
			//Add shapes to the map
			map.addSource('data-circles-stations', {
				'type': 'geojson',
				'data': URL_points,
				'generateId': true

			});
			map.addLayer({
				'id': 'data-circles-stations',
				'type': 'circle',
				'source': 'data-circles-stations',
				'layout': {
				},
				'paint': {
					'circle-radius': 3,
					"circle-stroke-width": 1,
					"circle-stroke-color": [
						'case',
						['boolean', ['feature-state', 'clicked'], false],
						'#eb34b1', // if selected true, paint in pink
						['get', 'color'] // else paint
					],
					'circle-color': [
						'case',
						['boolean', ['feature-state', 'clicked'], false],
						'#eb34b1', // if selected true, paint in pink
						['get', 'color'] // else paint
					],
					"circle-opacity": 0.5,
				},
			});

			//Polygon
			//Add shapes to the map
			map.addSource('data-lines-routes', {
				'type': 'geojson',
				'data': URL_points,
				'generateId': true

			});

			map.addLayer({
				'id': 'data-lines-routes-fill',
				'type': 'fill',
				'source': 'data-lines-routes', // reference the data source
				'layout': {},
				'paint': {
				'fill-color': ['get', 'color'], // blue color fill
				'fill-opacity': 0.5
				}
			});
		
		});

		this.setState({
			mapita: map,
			zoom: map.getZoom(),
		});


		//Click function - Add to Array
		map.on('click', 'data-circles-stations', (e) => {
			map.getCanvas().style.cursor = 'pointer';
			let circleID = e.features[0].id;

			if (this.state.current_selected_id !==-1){
				map.setFeatureState({
					source: 'data-circles-stations',
					id: this.state.current_selected_id,
				}, {
					clicked: false
				});
			}

			map.setFeatureState({
				source: 'data-circles-stations',
				id: circleID,
			}, {
				clicked: true
			});

			this.setState({current_station: e.features[0].properties.stop_id , current_selected_id: circleID})
		});

	}

	render() {
		return (
			<div>
				<div ref={el => this.mapContainer = el} style={{ width: '100%', height: '100vh' }}>
				</div>
				<RecommendationNavBarComponent />
                <LayerTylesComponent map = {this.state.mapita} tyle ={this.state.tyle} handleTyle={(value) => this.handleTyle(value)} handleCoord={(value1, value2) => this.handleCoord(value1, value2)}/>
				<TimeToComponent map={this.state.mapita} current_station={this.state.current_station} />
			</div>
		)
	}
}

export default MappTime_to_OV;
