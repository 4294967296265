import React from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '../css/map-drop-point.css';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';
import {IconContext} from "react-icons";
import { BsLayersHalf } from "react-icons/bs";
import Select from 'react-select';
import RecommendationNavBarComponent from "../../components/RecommendationNavBarComponent";
import Slider from 'rc-slider';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const URL = process.env.REACT_APP_URL_SERVER_BACK;

let no_data_modal = false;
const _ = require('lodash');

let generated_info = false;
function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
}

const lines_geojson = {
    'type': 'FeatureCollection',
    'features': [
    {
        'type': 'Feature',
        'geometry': {
            "type": "LineString",
            "coordinates": [
            ]
        }
    }
    ]
};

//Layer Tyles Selection - Side Menu
class LayerTylesComponent extends React.Component{

	constructor(props){
		super(props)
		this.state ={
			showOptions: false,
			mapa: this.props.map,
		}
		this.hideComponent = this.hideComponent.bind(this);
		this.handleTyleEvent = this.handleTyleEvent.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (this.props.map !== prevProps.map) {
		  this.setState({mapa: this.props.map})
		}
	}

	hideComponent(name)
	{
		this.setState({ showOptions: !this.state.showOptions });
	}

	handleTyleEvent(event) {
		switch (event) {
			case "default":
				this.props.handleTyle("mapbox://styles/mapbox/light-v10");
				break;
			case "outdoors":
				this.props.handleTyle("mapbox://styles/mapbox/outdoors-v11");
				break;
			case "satellite":
				this.props.handleTyle("mapbox://styles/mapbox/satellite-streets-v11");
				break;
			case "streets":
				this.props.handleTyle("mapbox://styles/mapbox/streets-v11");
				break;
			case "dark":
				this.props.handleTyle("mapbox://styles/mapbox/dark-v10");
				break;
			default:
				// eslint-disable-next-line no-unused-expressions
				null;
		}
        this.props.handleCoord(this.state.mapa.getCenter(), this.state.mapa.getZoom());
	}

	render(){
		const { showOptions } = this.state;

		return(
			<div>
				{showOptions ? <>
				<div id='layer-options' className='map-layer-options-tyles'>
					<div className='map-layer-option-dark' onClick={() => this.handleTyleEvent("dark")}>
						<IconContext.Provider value={{ color: "#5B5B5B", size:'1.6em'}}>
							<div>
								<img src={require('../../assets/img/Dark.png')} alt="Default tyle" className='img-tyle'/>
                        	</div>
                    	</IconContext.Provider>
						<p className='label'>Dark</p>
					</div>
					<div className='map-layer-option-streets' onClick={() => this.handleTyleEvent("streets")}>
						<IconContext.Provider value={{ color: "#5B5B5B", size:'1.6em'}}>
							<div>
								<img src={require('../../assets/img/Streets.png')} alt="Default tyle" className='img-tyle'/>
                        	</div>
                    	</IconContext.Provider>
						<p className='label'>Streets</p>
					</div>
					<div className='map-layer-option-satellite' onClick={() => this.handleTyleEvent("satellite")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size:'1.6em'}}>
                        	<div>
								<img src={require('../../assets/img/Satellite.png')} alt="Default tyle" className='img-tyle'/>
                        	</div>
                   		 	</IconContext.Provider>
							<p className='label'>Satellite</p>
					</div>
					<div className='map-layer-option-outdoors' onClick={() => this.handleTyleEvent("outdoors")}>
						<IconContext.Provider value={{ color: "#5B5B5B", size:'1.6em'}}>
							<div>
								<img src={require('../../assets/img/Outdoors.png')} alt="Default tyle" className='img-tyle'/>
                        	</div>
                    	</IconContext.Provider>
						<p className='label'>Outdoors</p>
					</div>
					<div className='map-layer-option-default' onClick={() => this.handleTyleEvent("default")}>
						<IconContext.Provider value={{ color: "#5B5B5B", size:'1.6em'}}>
                        	<div>
								<img src={require('../../assets/img/White.png')} alt="Default tyle" className='img-tyle'/>
                        	</div>
                    	</IconContext.Provider>
						<p className='label'>Default</p>
					</div>
				</div>
				</> : <></>}
				<div className='map-layer-tyles'>
                    <div className='map-layer-tyles-inner' onClick={() => this.hideComponent("showOptions")}>
					<IconContext.Provider value={{ color: "#5B5B5B", size:'1.8em'}}>
                        <div>
                         <BsLayersHalf />
                        </div>
                    </IconContext.Provider>
                </div>
			</div>
		</div>
		)
	}
}

class BtnsComponent extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			mapa: this.props.map,
            generatedTyles: false,
            loading: false,
            value_od_dates: ({
				"value" : "2022-08",
				"label" : '2022-08'
			  }),
			dates_trips_: this.getOptionsDate(URL+'db_od_trips'),
            distance_km: 400,
			hideLines: false,
			hideCircles: false,
            value_location: ({
                "value" : null,
                "label" : null
              }),
            employers_: this.getOptionsEmployers(URL+'what_if/employers_list'),
            coord_lat: null,
            coord_lng: null
		}
	}

	componentDidMount() {
		this.handleFetchDataCoord = this.handleFetchDataCoord.bind(this);
        this.resetView = this.resetView.bind(this);
        this.distanceSliderHandler = this.distanceSliderHandler.bind(this);
		this.handleHideLines = this.handleHideLines.bind(this);
		this.handleHideCircles = this.handleHideCircles.bind(this);
        this.handleChangeEmployer = this.handleChangeEmployer.bind(this);
    }

    componentDidUpdate(prevProps) {
		if(this.props.map !== prevProps.map) {
		  this.setState({mapa: this.props.map});
		}
	}

    async getOptionsEmployers(url){
        const res = await axios.get(url)
        const data = res.data
        const options = data.map(d => ({
            "value" : d.coord_lat+','+d.coord_lng,
            "label" : d.name
        }))
        this.setState({employers_: options});
    }

    handleChangeEmployer(event){
        if(event===null){
            this.setState({value_location: ({
                "value" : null,
                "label" : null
              })})
            this.setState({coord_lat: null, coord_lng: null})
        }
        else{
            this.setState({value_location: event})
            if(event.value!==null)
            {
              this.setState({coord_lat: event.value?.split(',')[1], coord_lng: event.value?.split(',')[0]})
            }
        }
    }

    distanceSliderHandler(event){
        this.setState({distance_km: event});
        if(this.state.generatedTyles){
            this.demoAsyncCall(this.state.value_od_dates.value, this.state.coord_lng, this.state.coord_lat, event);
        }
    }

    async getOptionsDate(url){
		const res = await axios.get(url)
		const data = res.data
		const options = data.map(d => ({
		  "value" : d._id.date.toString().split('T')[0].slice(0,-3),
		  "label" : d._id.date.toString().split('T')[0].slice(0,-3)
		}))
		this.setState({dates_trips_: options});
		this.setState({value_od_dates: options[options.length-1]});
	}

    demoAsyncCall(date,lat,lng, distance_km) {
        let data;
        console.log(this.state.mapa)
        fetch(URL+'tile_od/{0}/{1},{2}/{3}'.format(date, lng, lat, distance_km*1000))
            .then((res) =>res.json())
            .then((json) => { 
                data = json;
            }).then(() => 
            {
                if(data[0]?.hasOwnProperty('error')){
                no_data_modal = true;
                }else{
                this.state.mapa.getSource('data-swiss-destinations').setData(data)
                }

                const tyle_corner = {
                    center: [lng, lat],
                    zoom: 16,
                    pitch: 0,
                    bearing: 0
                }                
                this.props.map.flyTo({
                    ...tyle_corner, // Fly to the selected target
                    duration: 2000, // Animate over 2 seconds
                    essential: false // This animation is considered essential with
                    //respect to prefers-reduced-motion
                    })

        }).then(() => this.setState({ loading: false })).then(()=>{
			fetch(URL+'tile_od/center/{0}/{1},{2}'.format(date, lng, lat))
			.then((res) =>res.json())
			.then((json) => { 
				data = json;
			}).then(() => {this.state.mapa.getSource('data-swiss-tyle').setData(data)})})
			.then(()=>{
				fetch(URL+'tile_od/concentric_circles/{0}/{1},{2}'.format(date, lng, lat))
				.then((res) =>res.json())
				.then((json) => { 
					data = json;
				}).then(() => {this.state.mapa.getSource('data-concentric-circle').setData(data)})}).then(() => this.setState({ loading: false }));
      }

    handleFetchDataCoord()
    {
        this.setState({loading: true});
        this.demoAsyncCall(this.state.value_od_dates.value, this.state.coord_lng, this.state.coord_lat, this.state.distance_km);
        this.setState({generatedTyles: !this.state.generatedTyles});
		generated_info= true;
    }
    
    resetView(){
        this.state.mapa.getSource('data-swiss-destinations').setData(lines_geojson);
		this.state.mapa.getSource('data-concentric-circle').setData(lines_geojson);
		this.state.mapa.getSource('data-swiss-tyle').setData(lines_geojson);

        this.setState({generatedTyles: false})
		console.log(this.state.generatedTyles)
        no_data_modal = false;
		generated_info = false;
    }

    handleMonthYear(event){
		this.setState({ value_od_dates: event });
	}

	handleHideCircles(){
		let temp = !this.state.hideCircles;
		this.setState({hideCircles: temp})
		if(temp){
			this.props.map.setLayoutProperty('data-concentric-circle', 'visibility', 'none');
			this.props.map.setLayoutProperty('data-concentric-circle-labels', 'visibility', 'none');
		}
		else{
			this.props.map.setLayoutProperty('data-concentric-circle', 'visibility', 'visible');
			this.props.map.setLayoutProperty('data-concentric-circle-labels', 'visibility', 'visible');
		}
	}

	handleHideLines(){
		let temp = !this.state.hideLines;
		this.setState({hideLines: temp})
		if(temp){
			this.state.mapa.setLayoutProperty('swiss-destinations', 'visibility', 'none');
		}
		else{
			this.state.mapa.setLayoutProperty('swiss-destinations', 'visibility', 'visible');
		}
	}

	render() {
        const { loading } = this.state;
        const { generatedTyles } = this.state;
        const { value_od_dates } = this.state;

        const selectStyles = {
            menuList: styles => {
              return {
              ...styles,
              maxHeight: 100
              };
            }
            };

        const selectDate = {
			menuList: styles => {
			  return {
				...styles,
				maxHeight: 150,
				borderWidth: 0,
				boxShadow: '0 0 10px 2px rgb(0 0 0 / 10%)',
			  };
			}
		  };

        if(loading) { // if your component doesn't have to wait for an async action, remove this block 
            return(
                <div className="bg-loader">
                    <div className="pos-center">
                        <div className="loader"></div>
                    </div>
                </div>
            )
        }
        else{
            return (
                <div>
                    <div className='map-layer-slider-select-employer'>
                        <div className='map-layer-slider-select-employer-inner'>
                         <h1>Select a Employer</h1>
							<Select className='locations' isClearable={true} options={this.state.employers_} onChange={this.handleChangeEmployer.bind(this)} styles={selectStyles} />
                        </div>
                    </div>
                    <div className="map-month-year-picker">
					    <Select className='dates' options={this.state.dates_trips_} onChange={this.handleMonthYear.bind(this)} styles={selectDate} defaultValue={value_od_dates}/>
				    </div>
                    <div className="map-btn-drop-point">
                        <div className="map-btn-drop-point-inner">
                            <h1>Compute Origin-Destination <br/> Matrix for a Point</h1>
                            <p className="label">Place the circle on the desired coordinates. Click on <i>Generate matrix</i> to visualize the all destinations of selected coordinate</p>
                            <div className="row">
                                <div className="column-1-eq">
                                    <Form onClick={this.handleFetchDataCoord.bind(this)}>
                                        <Button className="generate-btn" variant="info" disabled={(generatedTyles)}>Generate matrix</Button>
                                    </Form>
                                </div>
                                <div className="column-2-eq">
                                    <Form onClick={this.resetView.bind(this)}>
                                        <Button className="reset-btn" variant="info" disabled={(!generatedTyles)}>Reset matrix</Button>
                                    </Form>
                                </div>
                            </div>
                        </div>
                        <div className="slider-modal">
                            <div className="slider-modal-inner">
                                <h1>Filter by distance in Km</h1>
                                <div className="slider-distance">
                                    <Slider min={0} max={400} step={0.5} defaultValue={this.state.distance_km} onChange={_.debounce(this.distanceSliderHandler.bind(this),33)}/>
                                </div>
                                <p className='label'>Show trips with less than {this.state.distance_km}km</p>
								<div className="row">
									<div className="column-1-40">
										<Form onChange={this.handleHideLines.bind(this)}>
      									<Form.Check 
											disabled={!generated_info}
        									type="switch"
        									id="custom-switch"
       	 									label="Show line routes"
											defaultChecked
      									/>
    									</Form>
									</div>
									<div className="column-2-60">
										<Form onChange={this.handleHideCircles.bind(this)}>
      									<Form.Check
											disabled={!generated_info}
        									type="switch"
        									id="custom-switch"
       	 									label="Show concentric heatmap"
											defaultChecked
      									/>
    									</Form>
									</div>
								</div>
                            </div>
                        </div>
                    </div>
                    
                    { no_data_modal ?
                    <>
                    <div className="modal-alert">
                        <div className="modal-alert-inner">
                            <h1>No data available</h1>
                            <p>Please click on <i>Reset matrix</i> and try a new point within Switzerland</p>
                        </div>
                    </div>
                    </>
                    : <></>
                    }
                </div>
            )
        }
	}
}

class MappEmployers extends React.Component{

	// Set up states for updating map 
	constructor(props){
		super(props);
		this.state = {
			lng:8.251313,
			lat: 46.924143,
            zoom: 7,
			mapita:9,
            tyle: 'mapbox://styles/mapbox/light-v10'
		}
	}

    //Update several fields
	updateFields(list) {
		this.setState(list);
	}

    //Update style
	handleTyle(value) {
		this.setState({tyle: value}, this.componentDidMount);
	}

    //Update coordinates
    handleCoord(center, zoom){
        const {lng, lat} = center;
        this.setState({zoom: zoom, lat: lat, lng: lng})
    }

	componentDidMount() {
		// Set up map 
		const map = new mapboxgl.Map({
			container: this.mapContainer,
			style: this.state.tyle,
			center: [this.state.lng, this.state.lat],
			zoom: this.state.zoom,
		});

		map.on('load', () => {

			// Add zoom and rotation controls to the map.
			map.addControl(new mapboxgl.NavigationControl({showCompass: false, showZoom: false}), 'top-left');
			map.addControl( new MapboxGeocoder({ accessToken: mapboxgl.accessToken, mapboxgl: mapboxgl, marker: false}), 'top-left' );

			//Circles concentric
			//Add shapes to the map
			map.addSource('data-concentric-circle', {
				'type': 'geojson',
				'data': lines_geojson,
				'generateId': true

			});
			map.addLayer({
				'id': 'data-concentric-circle',
				'type': 'fill',
				'source': 'data-concentric-circle',
				'layout': {
					// Make the layer visible by default.
					'visibility': 'visible'
				},
				'paint': {
					'fill-color': [
					'match', ['get', 'percentile'], 
                     '0-5', '#602437',
					 '5-15', '#8a2846',
					 '15-50', '#e05780',    
					 '50++', '#ffc2d4',                                                              
                     'black'],
					'fill-opacity': 0.5,
				},
			});

			map.addLayer({
				'id': 'data-concentric-circle-labels',
				'type': 'symbol',
				'source': 'data-concentric-circle',
				"minzoom": 10,
				'layout': {
					'text-field': ['get', 'percentile'],
					'text-font': [
						'Open Sans Bold',
						'Arial Unicode MS Bold'
					],
					'text-size': 15,
					'text-transform': 'uppercase',
					'text-letter-spacing': 0.05,
					'text-offset': [0, 1],
					'visibility': 'visible'
				}
			});

			//Tyle visualization
				//Add shapes to the map
				map.addSource('data-swiss-tyle', {
				'type': 'geojson',
				'data': lines_geojson,
				'generateId': true
				});

				map.addLayer({
				'id': 'data-swiss-tyle',
				'type': 'fill',
				'source': 'data-swiss-tyle',
				'layout': {},
				'paint': {
					'fill-color': '#53b8ae',
					'fill-opacity': 0.5
				}
				});

				// Add a black outline around the polygon
				map.addLayer({
				'id': 'outline-data-swiss-tyle',
				'type': 'line',
				'source': 'data-swiss-tyle',
				'layout': {},
				'paint': {
				'line-color': '#53b8ae',
				'line-width': 1
				}});

				// add lines to the map
			 	map.addSource('data-swiss-destinations', {
					'type': 'geojson',
					'data': lines_geojson,
					'generateId': true,	
					'lineMetrics': true
				});
	
				map.addLayer({
					'id': 'swiss-destinations',
					'type': 'line',
					'source': 'data-swiss-destinations',
					'layout': {
						// Make the layer visible by default.
						'visibility': 'visible'
					},
					'paint': {
					'line-color': '#027f80',
					'line-width': 2,
					}
				});
			
		});

		this.setState({
			mapita: map,
			zoom: map.getZoom(),
            center: map.getCenter()
		  });

        // When a click event occurs on a feature in the places layer, open a popup at the
	    // location of the feature, with description HTML from its properties.
		map.on('click', 'data-concentric-circle', (e) => {
			let total_trips_weekdays = numberWithCommas((e.features[0].properties.total_trips_weekdays*365).toFixed(0));
			let car_trips_weekdays = numberWithCommas((e.features[0].properties.car_trips_weekdays*365).toFixed(0));
			let train_trips_weekdays = numberWithCommas((e.features[0].properties.train_trips_weekdays*365).toFixed(0));

			let total_pkm_weekdays = numberWithCommas((e.features[0].properties.total_pkm_weekdays*365).toFixed(0));
			let car_pkm_weekdays = numberWithCommas((e.features[0].properties.car_pkm_weekdays*365).toFixed(0));
			let train_pkm_weekdays = numberWithCommas((e.features[0].properties.train_pkm_weekdays*365).toFixed(0));

			let percentile = e.features[0].properties.percentile
			if(percentile==='0-5'){
				percentile = '0km to 5km'
			}else if(percentile==='5-15'){
				percentile = '10km to 25km'
			}else if(percentile==='15-50'){
				percentile = '25km to 50km'
			}else if(percentile==='50++'){
				percentile = '50+km'
			}
		  
			  let html = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Within this range of distance: </span><br/>'+percentile+'</p><p style="margin-block-end: 0em; margin-top:1em"><span style="font-weight: bold;">Number trips in weekdays per year</span><br/></p><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle;"><thead><tr><th>Cars &#128664;</th><th>Trains &#x1f686;</th><th>Total</th></tr></thead><tbody><tr><td>'+car_trips_weekdays+'</td><td>'+train_trips_weekdays+'</td><td>'+total_trips_weekdays+'</td></tr></tbody></table><p style="margin-block-end: 0em; margin-top:1em"><span style="font-weight: bold;">Pkm in weekdays per year</span><br/></p><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle;"><thead><tr><th>Cars &#128664;</th><th>Trains &#x1f686;</th><th>Total</th></tr></thead><tbody><tr><td>'+car_pkm_weekdays+'</td><td>'+train_pkm_weekdays+'</td><td>'+total_pkm_weekdays+'</td></tr></tbody></table>';
			  new mapboxgl.Popup()
					.setLngLat(e.lngLat)
					.setHTML(html).addTo(map);
		  });



	    map.on('click', 'data-swiss-tyle', (e) => {
          let pkm_day = numberWithCommas(e.features[0].properties.pkm_day.toFixed(0));
		  let pkm_month = numberWithCommas(e.features[0].properties.pkm_month.toFixed(0));
		  let pkm_year = numberWithCommas(e.features[0].properties.pkm_year.toFixed(0));
		  //let percentage_for_plz= (e.features[0].properties.percentage_for_plz*100).toFixed(2);
        
			let html = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Passenger Km</span></p><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle;"><tbody><tr><th>PKm per day: </th><td>'+pkm_day+'</td></tr><tr><th>PKm per month: </th><td>'+pkm_month+'</td></tr><tr><th>PKm per year: </th><td>'+pkm_year+'</td></tr></tbody></table>';
			new mapboxgl.Popup()
		  		.setLngLat(e.lngLat)
		  		.setHTML(html).addTo(map);
		});

		//Tyle information
		// When a click event occurs on a feature in the places layer, open a popup at the
	    // location of the feature, with description HTML from its properties.
	    map.on('click', 'swiss-destinations', (e) => {
			let num_passengers_car_wd = e.features[0].properties.car_trips_weekdays_tiles;
			let num_passengers_car_we = e.features[0].properties.car_trips_weekends_tiles;
			let num_passengers_train_wd = e.features[0].properties.train_trips_weekdays_tiles;
			let num_passengers_train_we = e.features[0].properties.train_trips_weekends_tiles;
			let num_passengers_we_total = e.features[0].properties.total_trips_weekends_tiles;
			let num_passengers_wd_total = e.features[0].properties.total_trips_weekdays_tiles;
  
			let num_passengers_car_wd_str ='';
			let num_passengers_car_we_str ='';
			let num_passengers_train_wd_str ='';
			let num_passengers_train_we_str ='';
			let num_passengers_we_total_str ='';
			let num_passengers_wd_total_str ='';	  
  
			(typeof num_passengers_car_wd == 'number' & num_passengers_car_wd>0) ? num_passengers_car_wd = (num_passengers_car_wd).toFixed(0) : num_passengers_car_wd = '-';
			(num_passengers_car_wd !== '-') ? num_passengers_car_wd_str = numberWithCommas(num_passengers_car_wd): num_passengers_car_wd_str = '-';
			
			(typeof num_passengers_car_we == 'number' & num_passengers_car_we>0) ? num_passengers_car_we = (num_passengers_car_we).toFixed(0) : num_passengers_car_we = '-';
			(num_passengers_car_we !== '-') ? num_passengers_car_we_str = numberWithCommas(num_passengers_car_we): num_passengers_car_we_str = '-';
			
			(typeof num_passengers_train_wd == 'number' & num_passengers_train_wd>0) ? num_passengers_train_wd = (num_passengers_train_wd).toFixed(0) : num_passengers_train_wd = '-';
			(num_passengers_train_wd !== '-') ? num_passengers_train_wd_str = numberWithCommas(num_passengers_train_wd): num_passengers_train_wd_str = '-';
  
			(typeof num_passengers_train_we == 'number' & num_passengers_train_we>0) ? num_passengers_train_we = (num_passengers_train_we).toFixed(0) : num_passengers_train_we = '-';
			(num_passengers_train_we !== '-') ? num_passengers_train_we_str = numberWithCommas(num_passengers_train_we): num_passengers_train_we_str = '-';
  
			(typeof num_passengers_wd_total == 'number' & num_passengers_wd_total>0) ? num_passengers_wd_total = (num_passengers_wd_total).toFixed(0) : num_passengers_wd_total = '-';
			if(num_passengers_wd_total < (num_passengers_train_wd+num_passengers_car_wd) & num_passengers_wd_total !== '-'){
			  num_passengers_wd_total = Number(num_passengers_train_wd) + Number(num_passengers_car_wd);
			}
			(num_passengers_wd_total !== '-') ? num_passengers_wd_total_str = numberWithCommas(num_passengers_wd_total): num_passengers_wd_total_str = '-';
  
			(typeof num_passengers_we_total == 'number' & num_passengers_we_total>0) ? num_passengers_we_total = (num_passengers_we_total).toFixed(0) : num_passengers_we_total = '-';
			(num_passengers_we_total !== '-') ? num_passengers_we_total_str = numberWithCommas(num_passengers_we_total): num_passengers_we_total_str = '-';
		  
			  let html = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Passengers per day</span></p><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle;"><thead><tr><th></th><th>&#x1f46a; &#128664;</th><th>&#x1f46a; &#x1f686;</th><th>Total</th></tr></thead><tbody><tr><th>Weekdays</th><td>'+num_passengers_car_wd_str+'</td><td>'+num_passengers_train_wd_str+'</td><td>'+num_passengers_wd_total_str+'</td></tr><tr><th>Weekends</th><td>'+num_passengers_car_we_str+'</td><td>'+num_passengers_train_we_str+'</td><td>'+num_passengers_we_total_str+'</td></tr></tbody></table>';
			  new mapboxgl.Popup()
					.setLngLat(e.lngLat)
					.setHTML(html).addTo(map);
			
		  });

		// Change the cursor to a pointer when the mouse is over the places layer.
		map.on('mouseenter', 'swiss-destinations', () => {
		map.getCanvas().style.cursor = 'pointer';

		});
		
		// Change it back to a pointer when it leaves.
		map.on('mouseleave', 'swiss-destinations', () => {
		map.getCanvas().style.cursor = '';
		});
				
		// Fullscreen map 
		//map.addControl(new mapboxgl.FullscreenControl());
	}

	render(){
		return(
			<div>
				<div ref={el => this.mapContainer = el} style={{width:'100%',height:'100vh'}}>
				</div>
				<RecommendationNavBarComponent/>
                <LayerTylesComponent map = {this.state.mapita} tyle ={this.state.tyle} handleTyle={(value) => this.handleTyle(value)} handleCoord={(value1, value2) => this.handleCoord(value1, value2)}/>
                <BtnsComponent map={this.state.mapita} updateFields={(list) => this.updateFields(list)}/>
			</div>
		)
	}
}

export default MappEmployers;