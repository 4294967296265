import React from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import Slider from 'rc-slider';
import '../css/map-recommendation.css';
import {IconContext} from "react-icons";
import axios from 'axios';
import Select from 'react-select';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { BsLayersHalf } from "react-icons/bs";
import { Form } from 'react-bootstrap';
import RecommendationNavBarComponent from "../../components/RecommendationNavBarComponent";

import '../../components/layer-tyles.css';
import '../css/mapbox-gl-geocoder.css';
import '../css/map-recommendation-deckgl.css';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const URL = process.env.REACT_APP_URL_SERVER_BACK;

const _ = require('lodash');

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
}

//Layer Tyles Selection - Side Menu
class LayerTylesComponent extends React.Component{

	constructor(props){
		super(props)
		this.state ={
			showOptions: false,
			mapa: this.props.map,
		}
		this.hideComponent = this.hideComponent.bind(this);
		this.handleTyleEvent = this.handleTyleEvent.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (this.props.map !== prevProps.map) {
		  this.setState({mapa: this.props.map})
		}
	}

	hideComponent(name)
	{
		this.setState({ showOptions: !this.state.showOptions });
	}

	handleTyleEvent(event) {
		switch (event) {
			case "default":
				this.props.handleTyle("mapbox://styles/mapbox/light-v10");
				break;
			case "outdoors":
				this.props.handleTyle("mapbox://styles/mapbox/outdoors-v11");
				break;
			case "satellite":
				this.props.handleTyle("mapbox://styles/mapbox/satellite-streets-v11");
				break;
			case "streets":
				this.props.handleTyle("mapbox://styles/mapbox/streets-v11");
				break;
			case "dark":
				this.props.handleTyle("mapbox://styles/mapbox/dark-v10");
				break;
			default:
				// eslint-disable-next-line no-unused-expressions
				null;
		}
	}

	render(){
		const { showOptions } = this.state;

		return(
			<div>
				{showOptions ? <>
				<div id='layer-options' className='map-layer-options-tyles'>
					<div className='map-layer-option-dark' onClick={() => this.handleTyleEvent("dark")}>
						<IconContext.Provider value={{ color: "#5B5B5B", size:'1.6em'}}>
							<div>
								<img src={require('../../assets/img/Dark.png')} alt="Default tyle" className='img-tyle'/>
                        	</div>
                    	</IconContext.Provider>
						<p className='label'>Dark</p>
					</div>
					<div className='map-layer-option-streets' onClick={() => this.handleTyleEvent("streets")}>
						<IconContext.Provider value={{ color: "#5B5B5B", size:'1.6em'}}>
							<div>
								<img src={require('../../assets/img/Streets.png')} alt="Default tyle" className='img-tyle'/>
                        	</div>
                    	</IconContext.Provider>
						<p className='label'>Streets</p>
					</div>
					<div className='map-layer-option-satellite' onClick={() => this.handleTyleEvent("satellite")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size:'1.6em'}}>
                        	<div>
								<img src={require('../../assets/img/Satellite.png')} alt="Default tyle" className='img-tyle'/>
                        	</div>
                   		 	</IconContext.Provider>
							<p className='label'>Satellite</p>
					</div>
					<div className='map-layer-option-outdoors' onClick={() => this.handleTyleEvent("outdoors")}>
						<IconContext.Provider value={{ color: "#5B5B5B", size:'1.6em'}}>
							<div>
								<img src={require('../../assets/img/Outdoors.png')} alt="Default tyle" className='img-tyle'/>
                        	</div>
                    	</IconContext.Provider>
						<p className='label'>Outdoors</p>
					</div>
					<div className='map-layer-option-default' onClick={() => this.handleTyleEvent("default")}>
						<IconContext.Provider value={{ color: "#5B5B5B", size:'1.6em'}}>
                        	<div>
								<img src={require('../../assets/img/White.png')} alt="Default tyle" className='img-tyle'/>
                        	</div>
                    	</IconContext.Provider>
						<p className='label'>Default</p>
					</div>
				</div>
				</> : <></>}
				<div className='map-layer-tyles'>
                    <div className='map-layer-tyles-inner' onClick={() => this.hideComponent("showOptions")}>
					<IconContext.Provider value={{ color: "#5B5B5B", size:'1.8em'}}>
                        <div>
                         <BsLayersHalf />
                        </div>
                    </IconContext.Provider>
                </div>
			</div>
		</div>
		)
	}
}

class LegendInsightsComponent extends React.Component {
    render(){
          return(
              <div>
                <div className='map-layer-convention-insights'>
                    <div className='map-layer-convention-insights-inner'>
                    	<div className="row">
                            <div className="column-1">
                                <p className='label-convention'>200 trips</p>
                            </div>
                              <div className="column-2" styles=''>
                                <div className='convention-linear-insights'></div>
                            </div>
                            <div className="column-3">
                                <p className='label-convention'>5,000+ trips</p>
                            </div>
                        </div>
                	</div>
                  </div>
              </div>
          )
      }
  }

//Mobility Insights Analyzer Component - Dropdown Menu and Radio Btn
class MobilityInsightsAnalyzerComponent extends React.Component{

	constructor(props) {
		super(props)
    	this.state = {
			mapa: this.props.map,
            division: 'bfs',
			low_distance_km: 0,
			high_distance_km: 290,
			low_bound_diff: -40,
			high_bound_diff: 40,
			low_num_trips: 500,
			high_num_trips: 2000,
            origin: 0,
            destination: 0,
			selectOptions: [
				{ value: '0', label: 'All' },
				{ value: '1', label: 'Keep it that way' },
				{ value: '2', label: 'Marketing strategy' },
				{ value: '3', label: 'Point-to-point' },
				{ value: '4', label: 'Close line' },
				{ value: '5', label: 'Reduce connection time' },
			  ],
			recommendation_filter: '0',
			zoom: this.props.zoom,
			municipalities_: this.getOptions(URL+'municipalities-list'),
    	}
	
		this.handleChange = this.handleChange.bind(this);
		this.handleChangeRecommendation = this.handleChangeRecommendation.bind(this);
		this.schemaTypeSelectionHandle = this.schemaTypeSelectionHandle.bind(this);
	}

    async getOptions(url){
		const res = await axios.get(url)
		const data = res.data
		const options = data.map(d => ({
		  "value" : d.id,
		  "label" : d.name
		}))
		this.setState({municipalities_: options})
	}

	componentDidUpdate(prevProps) {
		if (this.props.map !== prevProps.map) {
		  this.setState({mapa: this.props.map})
		}
	  }

	handleChange(e)
    {
		let adaptedValues = this.adaptValues(this.state.low_bound_diff,this.state.high_bound_diff,this.state.low_distance_km,this.state.high_distance_km,this.state.high_num_trips,this.state.divisions)

		let url=URL+'train_vs_cars_22/{0},{1},{2},{3},{4},{5},{6},{7},{8},{9}'.format(this.state.recommendation_filter, this.state.origin, this.state.destination, adaptedValues[0], adaptedValues[1], adaptedValues[2], adaptedValues[3], this.state.low_num_trips, adaptedValues[4], adaptedValues[5]);
        this.state.mapa.getSource('data-swiss-recommendation').setData(url);
	};

    timeDifferenceHandler(event)
    {
        this.setState({low_bound_diff: event[0], high_bound_diff: event[1]})

		let adaptedValues = this.adaptValues(event[0],event[1],this.state.low_distance_km,this.state.high_distance_km,this.state.high_num_trips,this.state.divisions)

		let url=URL+'train_vs_cars_22/{0},{1},{2},{3},{4},{5},{6},{7},{8},{9}'.format(this.state.recommendation_filter, this.state.origin, this.state.destination, adaptedValues[0], adaptedValues[1], adaptedValues[2], adaptedValues[3], this.state.low_num_trips, adaptedValues[4], adaptedValues[5]);
        this.state.mapa.getSource('data-swiss-recommendation').setData(url);
    }
    
    distanceKilometerHandler(event)
    {
        this.setState({low_distance_km: event[0], high_distance_km: event[1]})

		let adaptedValues = this.adaptValues(this.state.low_bound_diff,this.state.high_bound_diff,event[0],event[1],this.state.high_num_trips,this.state.divisions)

		let url=URL+'train_vs_cars_22/{0},{1},{2},{3},{4},{5},{6},{7},{8},{9}'.format(this.state.recommendation_filter, this.state.origin, this.state.destination, adaptedValues[0], adaptedValues[1], adaptedValues[2], adaptedValues[3], this.state.low_num_trips, adaptedValues[4], adaptedValues[5]);
        this.state.mapa.getSource('data-swiss-recommendation').setData(url);
    }
    
    handleChangeRecommendation(event) 
    {
		let recommendations;
		event == null ? recommendations = 0 : recommendations = event.value;

		let adaptedValues = this.adaptValues(this.state.low_bound_diff,this.state.high_bound_diff,this.state.low_distance_km,this.state.high_distance_km,this.state.high_num_trips,this.state.divisions)

		let url=URL+'train_vs_cars_22/{0},{1},{2},{3},{4},{5},{6},{7},{8},{9}'.format(recommendations, this.state.origin, this.state.destination, adaptedValues[0], adaptedValues[1], adaptedValues[2], adaptedValues[3], this.state.low_num_trips, adaptedValues[4], adaptedValues[5]);
        this.state.mapa.getSource('data-swiss-recommendation').setData(url);

		this.setState({recommendation_filter: recommendations})
    }
    
    handleChangeOrigin(event) 
    {
		let origin;
		event == null ? origin = 0 : origin = event.value;

		let adaptedValues = this.adaptValues(this.state.low_bound_diff,this.state.high_bound_diff,this.state.low_distance_km,this.state.high_distance_km,this.state.high_num_trips,this.state.divisions)

		let url=URL+'train_vs_cars_22/{0},{1},{2},{3},{4},{5},{6},{7},{8},{9}'.format(this.state.recommendation_filter, origin, this.state.destination, adaptedValues[0], adaptedValues[1], adaptedValues[2], adaptedValues[3], this.state.low_num_trips, adaptedValues[4], adaptedValues[5]);
        this.state.mapa.getSource('data-swiss-recommendation').setData(url);

		this.setState({origin: origin})
    }
    
    handleChangeDestination(event)
    {
		let destination;
		event == null ? destination = 0 : destination = event.value;

		let adaptedValues = this.adaptValues(this.state.low_bound_diff,this.state.high_bound_diff,this.state.low_distance_km,this.state.high_distance_km,this.state.high_num_trips,this.state.divisions)
		
		let url=URL+'train_vs_cars_22/{0},{1},{2},{3},{4},{5},{6},{7},{8},{9}'.format(this.state.recommendation_filter, this.state.origin, destination, adaptedValues[0], adaptedValues[1], adaptedValues[2], adaptedValues[3], this.state.low_num_trips, adaptedValues[4], adaptedValues[5]);
        this.state.mapa.getSource('data-swiss-recommendation').setData(url);

		this.setState({destination: destination})
    }

	numTripsHandler(event)
	{
		this.setState({low_num_trips: event[0], high_num_trips: event[1]})
		
		let adaptedValues = this.adaptValues(this.state.low_bound_diff,this.state.high_bound_diff,this.state.low_distance_km,this.state.high_distance_km,event[1],this.state.divisions)
		
		let url=URL+'train_vs_cars_22/{0},{1},{2},{3},{4},{5},{6},{7},{8},{9}'.format(this.state.recommendation_filter, this.state.origin, this.state.destination, adaptedValues[0], adaptedValues[1], adaptedValues[2], adaptedValues[3], event[0], adaptedValues[4], adaptedValues[5]);
        this.state.mapa.getSource('data-swiss-recommendation').setData(url);
	}

	adaptValues(time_low, time_high, distance_low, distance_high, num_trips, filtering_scope)
	{
		let URL_MAP_TIME_LOW = time_low;
		if(URL_MAP_TIME_LOW===-40)
		{
			URL_MAP_TIME_LOW = -2000;
		}
		let URL_MAP_TIME_HIGH = time_high;
		if(URL_MAP_TIME_HIGH===40)
		{
			URL_MAP_TIME_HIGH = 2000;
		}

		let URL_MAP_HIGH_NUM_TRIPS = num_trips;
		if (URL_MAP_HIGH_NUM_TRIPS === 10000)
		{
			URL_MAP_TIME_HIGH = 182000;
		}

		let FILTERING_SCOPE = 0;
		if (filtering_scope === 'plz')
		{
			FILTERING_SCOPE = 1;
		}

		let URL_MAP_DISTANCE_LOW = (Number(distance_low)*Number(1000)).toString();
        let URL_MAP_DISTANCE_HIGH = (Number(distance_high)*Number(1000)).toString();

		return [URL_MAP_TIME_LOW, URL_MAP_TIME_HIGH, URL_MAP_DISTANCE_LOW, URL_MAP_DISTANCE_HIGH, URL_MAP_HIGH_NUM_TRIPS, FILTERING_SCOPE];
	}

	schemaTypeSelectionHandle(event) 
	{
		this.setState({divisions: event.target.attributes.getNamedItem('id').value})
		this.props.updateField("selection", event.target.attributes.getNamedItem('id').value);
		this.selection = event.target.attributes.getNamedItem('id').value;

		let adaptedValues = this.adaptValues(this.state.low_bound_diff,this.state.high_bound_diff,this.state.low_distance_km,this.state.high_distance_km,this.state.high_num_trips,event.target.attributes.getNamedItem('id').value)

		let url=URL+'train_vs_cars_22/{0},{1},{2},{3},{4},{5},{6},{7},{8},{9}'.format(this.state.recommendation_filter, this.state.origin, this.state.destination, adaptedValues[0], adaptedValues[1], adaptedValues[2], adaptedValues[3], this.state.low_num_trips, adaptedValues[4], adaptedValues[5]);
        this.state.mapa.getSource('data-swiss-recommendation').setData(url);
	}

	render(){

		const selectStyles = {
			menuList: styles => {
			  return {
				...styles,
				maxHeight: 100
			  };
			}
		  };

		const selectStylesTypeRecommendation = {
			menuList: styles => {
			  return {
				...styles,
				maxHeight: 150
			  };
			}
		  };

		return(
            <div>
				<div className="map-card-insight-analyzer-modal">
					<div className="map-card-insight-analyzer-modal-inner">
						<h1>Mobility Insights Analyzer</h1>
              <div>
							  <p className='label' htmlFor="Recommendation type">Type of Recommendation</p>
							  <Select className='locations' isClearable={true} options={this.state.selectOptions} onChange={this.handleChangeRecommendation.bind(this)} styles={selectStylesTypeRecommendation} />
							</div>
							<div>
								<p className='label'>Time difference</p>
								<div className='insight-analyzer-modal-slider-inner'>
									<Slider range draggableTrack min={-40} max={40} step={1} defaultValue={[this.state.low_bound_diff, this.state.high_bound_diff]} onChange={_.debounce(this.timeDifferenceHandler.bind(this),33)}/>
								</div>
								<p className='label-selected-value'>Showing trips with a time difference <br/> between <span>{this.state.low_bound_diff}</span> and <span>{this.state.high_bound_diff===40 ? "40+" : ""+this.state.high_bound_diff.toFixed(0)} </span> min</p>
							</div>
							<div>
								<p className='label'>Distance in km</p>
								<div className='insight-analyzer-modal-slider-inner'>
									<Slider range draggableTrack min={0} max={290} step={5} defaultValue={[this.state.low_distance_km, this.state.high_distance_km]} onChange={_.debounce(this.distanceKilometerHandler.bind(this),33)}/>
								</div>
								<p className='label-selected-value'>Showing routes with a distance<br/> between <span>{this.state.low_distance_km}km</span> and <span>{this.state.high_distance_km} </span>km</p>
							</div>
							<div>
								<p className='label'>Number of trips</p>
								<div className='insight-analyzer-modal-slider-inner'>
									<Slider range draggableTrack min={0} max={10000} step={10} defaultValue={[this.state.low_num_trips, this.state.high_num_trips]} onChange={_.debounce(this.numTripsHandler.bind(this),33)}/>
								</div>
								<p className='label-selected-value'>Showing number of trips<br/> between <span>{numberWithCommas(this.state.low_num_trips)}</span> and <span>{this.state.high_num_trips===10000 ? "10,000+" : ""+numberWithCommas(this.state.high_num_trips)} </span> per day</p>
							</div>
							<div>
								<p className='label'>Type of division</p>
								<Form onChange={this.schemaTypeSelectionHandle.bind(this)}>
									<Form.Check inline label=" Municipalities" name="type_analysis" type='radio' id='bfs' defaultChecked={true}/>
									<Form.Check inline label=" Postal code" name="type_analysis" type='radio' id='plz' />
								</Form>
							</div>
					</div>
				</div>

        <div className='mobility-insights-origin-destionation'>
          <div className='mobility-insights-origin-destionation-inner'>
            <h1>Search for an Specific Trip</h1>
            	<div>
					<p className='label' htmlFor="Origin">Origin</p>
					<Select className='locations' isClearable={true} options={this.state.municipalities_} onChange={this.handleChangeOrigin.bind(this)} styles={selectStyles} />
				</div>
            	<div>
					<p className='label' htmlFor="Destination">Destination</p>
					<Select className='locations' isClearable={true} options={this.state.municipalities_} onChange={this.handleChangeDestination.bind(this)} styles={selectStyles} />
				</div>
          </div>
        </div>
			</div>
		)
	}
}

//Map - Mobility insights
class MappRecommendationSwisscom22 extends React.Component{

	// Set up states for updating map 
	constructor(props){
		super(props);
		this.state = {
			lng:8.251313,
			lat: 46.924143,
			zoom: 7,
			mapita:9,
			tyle: 'mapbox://styles/mapbox/light-v10',
			selection: 'bfs',
		}
		this.handleTyle = this.handleTyle.bind(this);
	}

	//Update map on component update
	updateField(card, value) {
		this.setState({
            ...this.state,
            [card]: value
        });
	}
	
	//Update style
	handleTyle(value) {
		this.setState({tyle: value}, this.componentDidMount);
	}

	componentDidMount() {

		// Set up map 
		const map = new mapboxgl.Map({
			container: this.mapContainer,
			style: this.state.tyle,
			center: [this.state.lng, this.state.lat],
			zoom: this.state.zoom, 
		});

		map.on('load', () => {
			// Add zoom and rotation controls to the map.
			map.addControl(new mapboxgl.NavigationControl({showCompass: false, showZoom: false}), 'top-left');
			map.addControl( new MapboxGeocoder({ accessToken: mapboxgl.accessToken, mapboxgl: mapboxgl, marker: false}), 'top-left' );
			
			// add lines to the map
			map.addSource('data-swiss-recommendation', {
				'type': 'geojson',
				'data': URL+"train_vs_cars_22/0,0,0,-2000,2000,0,290000,500,2000,0",
				'generateId': true,
				'lineMetrics': true
			});
			map.addLayer({
				'id': 'swiss-recommendation',
				'type': 'line',
				'source': 'data-swiss-recommendation',
				'layout': {},
				'paint': {
				'line-color': ['get', 'color'],
				'line-width': 2,
				}
				});
			
		});

		this.setState({
			mapita: map,
			zoom: map.getZoom(),
		  });
		  
	// When a click event occurs on a feature in the places layer, open a popup at the
	// location of the feature, with description HTML from its properties.
	map.on('click', 'swiss-recommendation', (e) => {

		fetch(URL+"train_vs_cars_22/detail/"+e.features[0].properties.index)
			.then((res) => res.json())
				.then((json) => {

		// Copy coordinates array.
		const coordinates = json.geometry.coordinates.slice();
		// Ensure that if the map is zoomed out such that multiple
		// copies of the feature are visible, the popup appears
		// over the copy being pointed to.
		  while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
		  coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
		  }

		  let num_passengers_car_wd;
		  let num_passengers_car_we;
		  let num_passengers_train_wd;
		  let num_passengers_train_we;
		  let num_passengers_we_total;
		  let num_passengers_wd_total;

		  let num_passengers_car_wd_str ='';
		  let num_passengers_car_we_str ='';
		  let num_passengers_train_wd_str ='';
		  let num_passengers_train_we_str ='';
		  let num_passengers_we_total_str ='';
		  let num_passengers_wd_total_str ='';

		  if(this.state.selection === 'plz'){
			num_passengers_car_wd = json.properties.car_trips_weekdays_plz;
			num_passengers_car_we = json.properties.car_trips_weekdens_plz;
			num_passengers_train_wd = json.properties.train_trips_weekdays_plz;
			num_passengers_train_we = json.properties.train_trips_weekdens_plz;
			num_passengers_we_total = json.properties.total_trips_weekdens_plz;
			num_passengers_wd_total = json.properties.total_trips_weekdays_plz;
		  }
		  else{
			num_passengers_car_wd = json.properties.car_trips_weekdays_bfs;
			num_passengers_car_we = json.properties.car_trips_weekends_bfs;
			num_passengers_train_wd = json.properties.train_trips_weekdays_bfs;
			num_passengers_train_we = json.properties.train_trips_weekends_bfs;
			num_passengers_we_total = json.properties.total_trips_weekends_bfs;
			num_passengers_wd_total = json.properties.total_trips_weekdays_bfs;
		  }		  

		  (typeof num_passengers_car_wd == 'number' & num_passengers_car_wd>0) ? num_passengers_car_wd = (num_passengers_car_wd*2).toFixed(0) : num_passengers_car_wd = '-';
		  (num_passengers_car_wd !== '-') ? num_passengers_car_wd_str = numberWithCommas(num_passengers_car_wd): num_passengers_car_wd_str = '-';
		  
		  (typeof num_passengers_car_we == 'number' & num_passengers_car_we>0) ? num_passengers_car_we = (num_passengers_car_we*2).toFixed(0) : num_passengers_car_we = '-';
		  (num_passengers_car_we !== '-') ? num_passengers_car_we_str = numberWithCommas(num_passengers_car_we): num_passengers_car_we_str = '-';
		  
		  (typeof num_passengers_train_wd == 'number' & num_passengers_train_wd>0) ? num_passengers_train_wd = (num_passengers_train_wd*2).toFixed(0) : num_passengers_train_wd = '-';
		  (num_passengers_train_wd !== '-') ? num_passengers_train_wd_str = numberWithCommas(num_passengers_train_wd): num_passengers_train_wd_str = '-';

		  (typeof num_passengers_train_we == 'number' & num_passengers_train_we>0) ? num_passengers_train_we = (num_passengers_train_we*2).toFixed(0) : num_passengers_train_we = '-';
		  (num_passengers_train_we !== '-') ? num_passengers_train_we_str = numberWithCommas(num_passengers_train_we): num_passengers_train_we_str = '-';

		  (typeof num_passengers_wd_total == 'number' & num_passengers_wd_total>0) ? num_passengers_wd_total = (num_passengers_wd_total*2).toFixed(0) : num_passengers_wd_total = '-';
		  if(num_passengers_wd_total < (num_passengers_train_wd+num_passengers_car_wd) & num_passengers_wd_total !== '-'){
			num_passengers_wd_total = Number(num_passengers_train_wd) + Number(num_passengers_car_wd);
		  }
		  (num_passengers_wd_total !== '-') ? num_passengers_wd_total_str = numberWithCommas(num_passengers_wd_total): num_passengers_wd_total_str = '-';

		  (typeof num_passengers_we_total == 'number' & num_passengers_we_total>0) ? num_passengers_we_total = (num_passengers_we_total*2).toFixed(0) : num_passengers_we_total = '-';
		  (num_passengers_we_total !== '-') ? num_passengers_we_total_str = numberWithCommas(num_passengers_we_total): num_passengers_we_total_str = '-';

            let end_station = json.properties.destination_name;
            let start_station = json.properties.origin_name;
            let time_car = (json.properties.duration_car_s/60).toFixed(1);
            let time_train = (json.properties.train_travel_time_min).toFixed(1);
            let stops = json.properties.n_stops_total;
            let num_trains = json.properties.n_trains;
            let waiting_min = json.properties.waiting_time_min.toFixed(2);
			let recommendation = json.properties.recomendations;
			let difference = (time_car-time_train).toFixed(1);
			let color;
			let operators = json.properties.operators;
			(difference >= 0) ? color = '#409326' : color = '#C31A1A';
			
			let html_modal = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Modal Split</span></p><p style="margin-block-end: 0em;"><span style="font-weight: semibold;">No data available</span></p>';

			if((num_passengers_wd_total !== '-') & (num_passengers_car_wd !== '-') & (num_passengers_train_wd !== '-'))
			{
				let total = num_passengers_wd_total;
				if(Number(num_passengers_wd_total) < (Number(num_passengers_car_wd)+Number(num_passengers_train_wd))){
					total = Number(num_passengers_car_wd) + Number(num_passengers_train_wd);
				}
				let cars_percentage = (num_passengers_car_wd/total*100).toFixed(0);
				let trains_percentage = (num_passengers_train_wd/total*100).toFixed(0);
				let other_percentage = (Number(100) - (Number(cars_percentage) + Number(trains_percentage))).toFixed(0);
				html_modal = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Modal Split</span></p><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle; width: 100%;"><thead><tr><th></th><th style="width:50%; text-align: start;">Car/Bus</th><th style="width:50%; text-align: end;"><span style="float:right; position:relative, width:50%">Trains</span></th></tr></thead><tbody><tr><th></th><th style="width:50%; text-align: start; font-weight: normal;">'+cars_percentage+'%</th><th style="width:50%; text-align: end;"><span style="float:right; position:relative, width:50%; font-weight: normal;">'+trains_percentage+'%</span></th></tr></tbody></table><span class="block" style="display: block;height: 20px;color: #fff;font-size: 1em;float: left;background-color: #F78948;position: relative;overflow: hidden;width:'+cars_percentage+'%"></span><span class="block" style="display:block; height:20px; color:#fff; font-size:1em; float:left; background-color:#2f3338; position:relative; overflow:hidden; width:'+other_percentage+'%;"></span><span class="block" style="display:block; height:20px; color:#fff; font-size:1em; float:left; background-color:#0fabab; position:relative; overflow:hidden; width:'+trains_percentage+'%;"></span>';
			}

			let p_l_html = '<p style="margin-block-start:0px;"><span style="font-weight: bold; margin-block-start:0px;"> P&amp;L: </span>No data enough available</p>';
			
			if((num_passengers_car_wd !== '-')){
				let earnings = (num_passengers_car_wd) * 0.035 * (0.43*(json.properties.distance_train_m/1000))
				let cost = (-870.80/60)*json.properties.travel_time_in_vehicles_min
			  	let p_l = (earnings + cost).toFixed(2)
				p_l_html = '<p style="margin-block-start:0px;"><span style="font-weight: bold; margin-block-start:0px;"> P&amp;L: </span>'+p_l+' CHF per trip</p>';
			}

			let html = '<h3 style="margin-block-start:0px; margin-block-end:0px;">'+start_station+' ~ '+end_station+'</h3><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Passengers per day</span></p><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle;"><thead><tr><th></th><th>&#x1f46a; &#128664;</th><th>&#x1f46a; &#x1f686;</th><th>Total</th></tr></thead><tbody><tr><th>Weekdays</th><td>'+num_passengers_car_wd_str+'</td><td>'+num_passengers_train_wd_str+'</td><td>'+num_passengers_wd_total_str+'</td></tr><tr><th>Weekends</th><td>'+num_passengers_car_we_str+'</td><td>'+num_passengers_train_we_str+'</td><td>'+num_passengers_we_total_str+'</td></tr></tbody></table>';
			let html_add = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Travel time</span></p><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle;"><thead><tr><th>&#128664;</th><th>&#x1f686;</th><th>&#128337;</th></tr></thead><tbody><tr><td>'+time_car+'min </td><td>'+time_train+'min </td><td><span style="color: '+color+'; font-weight: bold;">'+difference+'min </span></td></tr></tbody></table><table style="margin:auto; table-layout:fixed; padding:0px; text-align:center; vertical-align:middle;"><tbody><tr><td><span style="color: gray; font-size: 11px;">Stops: '+stops+'</span></td><td><span style="color: gray; font-size: 11px;">Trains: '+num_trains+'</span></td><td><span style="color: gray; font-size: 11px;">Waiting time: '+waiting_min+'min </span></td></tr></tbody></table>'+p_l_html+'<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Recommendation</span></p>'+recommendation+'</p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Operators</span></p>'+operators+'</p>';

				new mapboxgl.Popup()
		  		.setLngLat(e.lngLat)
		  		.setHTML(html+html_modal+html_add).addTo(map);
				});
		});
		
		// Change the cursor to a pointer when the mouse is over the places layer.
		map.on('mouseenter', 'swiss-recommendation', () => {
		map.getCanvas().style.cursor = 'pointer';
		});
		
		// Change it back to a pointer when it leaves.
		map.on('mouseleave', 'swiss-recommendation', () => {
		map.getCanvas().style.cursor = '';
		});

	}

	render(){
		return(
			<div>
				<div ref = {el => this.mapContainer = el} style = {{width:'100%',height:'100vh'}}>
				</div>
				<RecommendationNavBarComponent/>
				<MobilityInsightsAnalyzerComponent map = {this.state.mapita} zoom ={this.state.zoom} {...this.state} updateField={(card, field, value) => this.updateField(card, field, value)}/>
                <LegendInsightsComponent />
                <LayerTylesComponent map = {this.state.mapita} tyle ={this.state.tyle} handleTyle={(value) => this.handleTyle(value)}/>
			</div>
		)
	}
}

export default MappRecommendationSwisscom22;