
import React from "react";
import { Map } from "react-map-gl";
import DeckGL, { GeoJsonLayer } from "deck.gl";
import { scaleThreshold } from "d3-scale";
import { IconContext } from "react-icons";
import axios from 'axios';
import { Form } from 'react-bootstrap';
import RecommendationNavBarComponent from "../../components/RecommendationNavBarComponent";
import { RiFullscreenFill, RiFullscreenExitFill } from "react-icons/ri";
import { useParams } from "react-router-dom";

import '../css/map-recommendation-deckgl.css';

const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const URL = process.env.REACT_APP_URL_SERVER_BACK;

function numberWithCommas(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
}

function elevationTiles(selection_distribution, f){
  return selection_distribution==='co2' ? (f.properties.co2_emmitted * 5000) : (selection_distribution==='trips' ? (f.properties.total_trips_weekdays_tiles*1.2) : (selection_distribution==='pkm' ? (f.properties.pkm) : (f.properties.modal_split)))
}

let data2d = []

// function getColors(selection_distribution, f){
//   return (selection_distribution==='co2' ? COLOR_SCALE_1(f.properties.co2_emmitted * 5000) : (selection_distribution==='trips' ? COLOR_SCALE_2(f.properties.total_trips_weekdays_tiles * 1) : (selection_distribution==='pkm' ? COLOR_SCALE_2(f.properties.pkm) : COLOR_SCALE_3(f.properties.modal_split))))
// }

function getColors(f){
  for(let data of data2d?.features){
    if(data?.properties?.tileId === f.properties.tileId){
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(data?.properties?.color);
      return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : [0,0,0];
    }
  }
}

export const COLOR_SCALE_1 = scaleThreshold()
  .domain([0, 10, 40, 120, 200, 290, 380, 450, 560, 600, 720, 800, 923])
  .range([
    [14, 91, 48],
    [13, 99, 52],
    [12, 107, 55],
    [12, 107, 55],
    [71, 125, 51],
    [130, 143, 46],
    [248, 179, 36],
    [242, 124, 40],
    [235, 68, 44],
    [212, 50, 40],
    [188, 32, 35],
    [189, 0, 38],
    [128, 0, 38]
  ]);

  export const COLOR_SCALE_2 = scaleThreshold()
  .domain([0, 2, 6, 8, 10, 25, 50, 72, 90, 130, 157, 170, 300])
  .range([
    [128, 0, 38],
    [189, 0, 38],
    [188, 32, 35],
    [212, 50, 40],
    [235, 68, 44],
    [242, 124, 40],
    [248, 179, 36],
    [130, 143, 46],
    [71, 125, 51],
    [12, 107, 55],
    [12, 107, 55],
    [13, 99, 52],
    [14, 91, 48]
  ]);

  export const COLOR_SCALE_3 = scaleThreshold()
  .domain([0, 4, 9, 15, 24, 29, 35, 41, 45, 48, 50, 55, 60,])
  .range([
    [128, 0, 38],
    [189, 0, 38],
    [188, 32, 35],
    [212, 50, 40],
    [235, 68, 44],
    [242, 124, 40],
    [248, 179, 36],
    [130, 143, 46],
    [71, 125, 51],
    [12, 107, 55],
    [12, 107, 55],
    [13, 99, 52],
    [14, 91, 48],
  ]);

class HideElementsComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hideNavs: false,
      mapa: this.props.map,
    }
  }

  hideComponent() {
    let newHideNavs = !this.state.hideNavs;
    this.setState({ hideNavs: newHideNavs })
    this.props.updateField("hideNavs", newHideNavs);
  }

  render() {

    const { hideNavs } = this.state;

    return (
      <div>
        {hideNavs ? <>
          <div className='map-close-navs'>
            <div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
              <IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
                <div>
                  <RiFullscreenExitFill />
                </div>
              </IconContext.Provider>
            </div>
          </div>
        </> : <>
          <div className='map-close-navs'>
            <div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
              <IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
                <div>
                  <RiFullscreenFill />
                </div>
              </IconContext.Provider>
            </div>
          </div>
        </>}
      </div>
    )
  }
}


class MapB2B_3D extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hideNavs: false,
      params: this.props.params,
      company_img: this.companyImage(),
      CO2_metrics: this.co2Metrics(),
      selection_distribution: 'co2',
      current_co2_per_day: 0,
      current_pkm: 0,
      current_trips: 0,
      total_co2_per_day: 0,
      total_pkm: 0,
      total_trips: 0,
      current_modal_split: 0,
      total_modal_split: 0,
      URL_addition: 'bus_potential/buhler/co2.geojson',
      data: this.handleData(this.props.params)
    }
  }

  componentDidMount() {
    this.handleAggregation = this.handleAggregation.bind(this);
    this.getTooltip = this.getTooltip.bind(this);
  }

  handleData(params){
    let param_split = params['*'].split(',')

    let body_1km_colors = { employer: param_split[0], distance_to_station: [parseInt(param_split[1]), parseInt(param_split[2])], distance_to_origin: [parseInt(param_split[3]), parseInt(param_split[4])], changes_in_ov: param_split[5], trips_per_tile: [parseInt(param_split[6]), parseInt(param_split[7])], maximum_travel_distance: parseInt(param_split[8]), num_buses: parseInt(param_split[9]), bus_capacity: parseInt(param_split[10]), conversion_rate: Number(param_split[11]), last_mile: (param_split[12] === 'true' ? true : false), bike_radius: parseInt(param_split[13]), threshold_duration_percentage_green: [parseInt(param_split[14]), parseInt(param_split[15])], threshold_duration_percentage_yellow: [parseInt(param_split[16]), parseInt(param_split[17])], threshold_duration_percentage_red: [parseInt(param_split[18]), parseInt(param_split[19])], max_difference_green: parseInt(param_split[20]), max_changes_green: parseInt(param_split[21]), max_changes_yellow: parseInt(param_split[22]), min_changes_red: parseInt(param_split[23]), visited_tiles: [], short_bike_radius: parseInt(param_split[25]), medium_bike_radius: parseInt(param_split[26]), minutes_in_train: parseInt(param_split[27]), maximum_percentage_difference: 100, options: ["color"] };

    let url_add_on = 'bus_potential/buhler/co2.geojson';

		if (param_split[0] === 'HILTI') {
			url_add_on = 'bus_potential_international/buhler/co2.geojson';
		}

      fetch(URL + 'bus_potential/buhler/tile1km', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body_1km_colors)
      }).then((res) => res.json())
      .then((json) => {
        data2d = json
      })
      .then(() =>{
        fetch(URL + url_add_on, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(body_1km_colors)
        }).then((res) => res.json())
        .then((json) => {
          this.setState({ data: json });
          })
      })

  }

  handleAggregation(event) {
    this.setState({ selection_distribution: event.target.attributes.getNamedItem('id').value });
  }

  co2Metrics() {

    let data = [];
    let param_split = this.props.params['*'].split(',')

    let url_add_on = 'bus_potential/buhler/total_vs_filter';

		if (param_split[0] === 'HILTI') {
			url_add_on = 'bus_potential_international/buhler/total_vs_filter';
		}

    let body_1km_colors = { employer: param_split[0], distance_to_station: [parseInt(param_split[1]), parseInt(param_split[2])], distance_to_origin: [parseInt(param_split[3]), parseInt(param_split[4])], changes_in_ov: param_split[5], trips_per_tile: [parseInt(param_split[6]), parseInt(param_split[7])], maximum_travel_distance: parseInt(param_split[8]), num_buses: parseInt(param_split[9]), bus_capacity: parseInt(param_split[10]), conversion_rate: Number(param_split[11]), last_mile: (param_split[12] === 'true' ? true : false), bike_radius: parseInt(param_split[13]), threshold_duration_percentage_green: [parseInt(param_split[14]), parseInt(param_split[15])], threshold_duration_percentage_yellow: [parseInt(param_split[16]), parseInt(param_split[17])], threshold_duration_percentage_red: [parseInt(param_split[18]), parseInt(param_split[19])], max_difference_green: parseInt(param_split[20]), max_changes_green: parseInt(param_split[21]), max_changes_yellow: parseInt(param_split[22]), min_changes_red: parseInt(param_split[23]), visited_tiles: [], short_bike_radius: parseInt(param_split[25]), medium_bike_radius: parseInt(param_split[26]), minutes_in_train: parseInt(param_split[27]), maximum_percentage_difference: 100, options: ["color"] };

    fetch(URL + url_add_on, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body_1km_colors)
    }).then((res) => res.json())
      .then((json) => {
        data = json
        this.setState({ current_co2_per_day: json.current_co2_per_day, current_pkm: json.current_pkm, current_trips: json.current_trips, total_co2_per_day: json.total_co2_per_day, total_pkm: json.total_pkm, total_trips: json.total_trips, total_modal_split: json.total_modal_split, current_modal_split: json.current_modal_split })
      })
    return data;
  }

  companyImage() {
    let param_split = this.props.params['*'].split(',');
    let img_file = param_split[0]+'.png'

    return img_file
  }

  initialview() {
    let lat = 0;
    let lng = 0;
    let param_split = this.props.params['*'].split(',');

    if (param_split[0] === 'buhler') {
      lng = 9.148515;
      lat = 47.558582;
    } else if (param_split[0] === 'omega') {
      lng = 7.260259;
      lat = 47.243837;
    } else if (param_split[0] === 'rolex') {
      lng = 7.289475;
      lat = 47.261366;
    } else if (param_split[0] === 'kantonsspital-right') {
      lng = 9.089045;
      lat = 47.530028;
    } else if (param_split[0] === 'helvetia') {
      lng = 9.0764902358;
      lat = 47.532793424;
    } else if (param_split[0] === 'mobiliar') {
      lng = 7.435545817655105;
      lat = 47.14086426991423;
    } else if (param_split[0] === 'leica') {
      lng = 9.61806284095735;
      lat = 47.40928281507626;
    } else if (param_split[0] === 'HILTI') {
      lng = 9.524259;
      lat = 47.179846;
    }else if(param_split[0] === 'logistic_zentrum'){
			lng = 8.073873; 
			lat = 47.389768;
		}else if(param_split[0] === 'post-finance'){
			lng = 7.940943;
			lat = 47.291786;
		}else if (param_split[0] === 'hotelplan_suisse'){
      lng = 8.5592859;
      lat= 47.4317053;
    }else if (param_split[0] === 'csl_behring'){
			lng = 7.4612892;
			lat = 46.961891;
		}else if(param_split[0] === 'griesser'){
      lng = 8.9024098;
			lat = 47.4857886;
    }else if(param_split[0] === 'bcge'){
			lng = 6.1117784;
			lat = 46.1869241;
		} else if(param_split[0] === 'clienia'){
			lng = 8.719604;
			lat = 47.269392;
		} else if(param_split[0] === 'sunrise'){
			lng = 8.556656;
			lat = 47.422150;
		} else if(param_split[0] === 'lonza'){
			lng = 7.883609;
			lat = 46.295895;
		} else if(param_split[0] === 'spital_grabs'){
			lng = 9.448457;
			lat = 47.173781;
		} else if(param_split[0]==='stadler_bussnang'){
			lng = 9.08673007;
			lat = 47.5626709;
		}else if(param_split[0]==='spital_musnterlingen'){
			lng = 9.2362901;
			lat = 47.630952;
		}else if(param_split[0]==='alpamare'){
			lng = 8.79107655;  
			lat = 47.1993365;
		}else if (param_split[0]==='metrohm'){
			lng = 9.269632342;  
			lat = 47.38815420;
		}else if (param_split[0]==='huber_suhner'){
			lng = 9.27231417668476;  
			lat = 47.3849555060061;
		}else if (param_split[0]==='herisau_psychiatrie'){
			lng = 9.264371939783794;  
			lat = 47.38596245189104; 
		}else if (param_split[0]==='spital_herisau'){
			lng = 9.282888127635493;  
			lat = 47.38816745839015; 
		} else if (param_split[0] === 'spaeter') {
			lng = 7.634707558792136;
			lat = 47.55645178733879;
		}  else if (param_split[0] === 'ems_chemie'){
			lng = 9.430719798262317;
			lat = 46.82862317289573;
		} else if (param_split[0]==='grand_resort_bad_ragaz'){
        lng =  9.503806257114343;
        lat= 47.00028814815079;
    } else if (param_split[0]==='hitachi_baden'){
      lng =  8.302843165007765;
      lat= 47.4797513489626;
  } else if (param_split[0]==='hitachi_zurich'){
    lng =  8.536087766928608;
    lat= 47.41501273259961;
} else if (param_split[0]==='post_verteilzentrum'){
  lng =  7.8138063819916805;
  lat= 47.316859785640695;
} else if (param_split[0]==='confiseur_laderach_ag'){
  lng =  9.087182987470017;
  lat= 47.06262335719511;
}


    return {
      latitude: lat,
      longitude: lng,
      zoom: 8.6,
      maxZoom: 16,
      pitch: 30,
      bearing: 0
    };

  }
//   getFillColor: f => getColors(selection_distribution, f),
  _renderLayers(selection_distribution) {
    const layer = [
      new GeoJsonLayer({
        id: selection_distribution,
        data: this.state.data,
        opacity: 0.8,
        stroked: true,
        filled: true,
        extruded: true,
        wireframe: true,
        getElevation: f => elevationTiles(selection_distribution, f),
        elevationScale: 100,
        getFillColor: f => getColors(f),
        getLineColor: [0, 0, 0],
        getLineWidth: 100,
        pickable: true,
      })
    ];
    return layer;
  }

  getTooltip({ object }) {
    return (
      object && {
        html: `\
      <div><b>Postal Code</b></div>
      <div>${object.properties.plz_name} (${object.properties.PLZ})</div>
      <div><b>Daily CO<sub>2</sub> Emissions</b></div>
      <div>${object.properties.co2_emmitted} tons</div>
      <div><b>Number of trips</b></div>
      <div>${object.properties.total_trips_weekdays_tiles} per day/weekdays</div>
      <div><b>Trains' Modal Split</b></div>
      <div>${(object.properties.modal_split).toFixed(0)}%</div>
      <div><b>Passenger Km</b></div>
      <div>${numberWithCommas((object.properties.pkm).toFixed(0))}</div>
      `
      }
    );
  }

  updateField(card, value) {
    this.setState({
      ...this.state,
      [card]: value
    });
  }

  render() {
    const { viewState, controller = true } = this.props;
    const { hideNavs } = this.state;
    const { selection_distribution } = this.state;

    const { current_co2_per_day } = this.state;
    const { current_pkm } = this.state;
    const { current_trips } = this.state;
    const { total_co2_per_day } = this.state;
    const { total_pkm } = this.state;
    const { total_trips } = this.state;
    const { current_modal_split } = this.state;
    const { total_modal_split } = this.state;

    return (
      <div>
        <DeckGL
          layers={this._renderLayers(this.state.selection_distribution)}
          initialViewState={this.initialview()}
          viewState={viewState}
          controller={controller}
          getTooltip={this.getTooltip}
        >
          <Map reuseMaps mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN} mapStyle={'mapbox://styles/mapbox/light-v10'} />
          <RecommendationNavBarComponent />
          <div style={{ display: hideNavs ? 'none' : 'block' }}>

            <div className='map-modal-enterprise'>
              <div className='map-modal-enterprise-inner'>
                <img src={require('../../assets/img/companies_b2b/' + this.state.company_img)} alt="Logo of the selected enterprise" className='logo-img' />
                <h1>Welcome to your CO<sub>2</sub> Analysis Platform!</h1>
                <p className="label">Each tile represents the CO<sub>2</sub> footprint generated by employees moving <br/> from that 1kmx1km tile to the Headquarters.</p>
              </div>
            </div>
            <div className="map-modal-enterprise-distribution">
              <div className="map-modal-enterprise-distribution-inner">
                <h2>Enable metrics based on</h2>
                <Form onChange={this.handleAggregation.bind(this)}>
                  <div className="row">
                    <div className="column-enteprise-4-eq">
                      <Form.Check inline label=" CO2 emitted" name="type_analysis" type='radio' id='co2' defaultChecked={(selection_distribution === 'co2')} />
                    </div>
                    <div className="column-enteprise-4-eq">
                      <Form.Check inline label=" Number of trips" name="type_analysis" type='radio' id='trips' defaultChecked={(selection_distribution === 'trips')} />
                    </div>
                    <div className="column-enteprise-4-eq">
                      <Form.Check inline label=" Passenger Km" name="type_analysis" type='radio' id='pkm' defaultChecked={(selection_distribution === 'pkm')} />
                    </div>
                    <div className="column-enteprise-4-eq">
                      <Form.Check inline label=" Modal split" name="type_analysis" type='radio' id='modalsplit' defaultChecked={(selection_distribution === 'modalsplit')} />
                    </div>
                  </div>
                </Form>

              </div>
            </div>
            {selection_distribution === 'co2' ?
              <div className="map-modal-enterprise-total-filtered">
                <div className="map-modal-enterprise-total-filtered-inner">
                  <h4>Based on the filters selected on the previous view:</h4>
                  <h1>Showing {current_co2_per_day.toFixed(2)} tons CO<sub>2</sub> emitted per day</h1>
                  <h3>Total {total_co2_per_day.toFixed(2)} tons CO<sub>2</sub> emitted per day</h3>
                </div>
              </div>
              : <>
                {selection_distribution === 'trips' ?
                  <div className="map-modal-enterprise-total-filtered">
                    <div className="map-modal-enterprise-total-filtered-inner">
                      <h4>Based on the filters selected on the previous view:</h4>
                      <h1>Showing {numberWithCommas(current_trips.toFixed(0))} trips per day</h1>
                      <h3>Total {numberWithCommas(total_trips.toFixed(0))} trips per day</h3>
                    </div>
                  </div>
                  : <>
                  {selection_distribution === 'pkm' ?
                  <div className="map-modal-enterprise-total-filtered">
                  <div className="map-modal-enterprise-total-filtered-inner">
                    <h4>Based on the filters selected on the previous view:</h4>
                    <h1>Showing {numberWithCommas(current_pkm.toFixed(0))} Passenger Km per day</h1>
                    <h3>Total {numberWithCommas(total_pkm.toFixed(0))} Passenger Km per day</h3>
                  </div>
                </div>
                  : <>
                    <div className="map-modal-enterprise-total-filtered">
                      <div className="map-modal-enterprise-total-filtered-inner">
                        <h4>Based on the filters selected on the previous view:</h4>
                        <h1>Trains' modal split is on average {(current_modal_split*100).toFixed(1)}%</h1>
                        <h3>For the whole company, the trains' modal split is {(total_modal_split*100).toFixed(1)}%</h3>
                      </div>
                    </div>
                  </>
                }
                  </>
                }
              </>
            }
          </div>
        </DeckGL>
        <HideElementsComponent map={this.state.mapita} updateField={(card, field, value) => this.updateField(card, field, value)} />
      </div>
    );
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => (
  // eslint-disable-next-line react/jsx-pascal-case
  <MapB2B_3D
    {...props}
    params={useParams()}
  />
); 
