import React from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import { IconContext } from "react-icons";
import RecommendationNavBarComponent from "../../components/RecommendationNavBarComponent";
import axios from 'axios';
import Select from 'react-select';
import '../css/dashboard_5_in_5.css';
import { Table, Column, HeaderCell, Cell } from 'rsuite-table';
import ReactPaginate from 'react-paginate';
import ProgressBar from "@ramonak/react-progress-bar";
import { IoSettingsOutline } from "react-icons/io5";
import { MdOutlineDoubleArrow } from "react-icons/md";
import Slider from 'rc-slider';
import { Form, Button } from 'react-bootstrap';
import { RxCross2 } from "react-icons/rx";
import Chart from 'chart.js/auto';
import { Line } from 'react-chartjs-2';
import Compare from 'mapbox-gl-compare';

import "mapbox-gl-compare/dist/mapbox-gl-compare.css";
import '../css/react-paginate.css';
import 'rsuite-table/dist/css/rsuite-table.css'; // or 'rsuite-table/dist/css/rsuite-table.css'

const _ = require('lodash');

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const URL = process.env.REACT_APP_URL_SERVER_BACK;
const URL_FRONT = process.env.REACT_APP_URL_SERVER_FRONT;

function numberWithCommas(x) {
    if (typeof x !== "undefined") {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");

    }
    else {
        return 0
    }
}

let lines_geojson = {
    'type': 'FeatureCollection',
    'features': [
        {
            'type': 'Feature',
            'geometry': {
                "type": "LineString",
                "coordinates": [
                ]
            }
        }
    ]
};

//Dashboard: MIV Hotspots
class Dashboard_MIV_Hotspots extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            kantons_: this.getOptionsKantons(URL + 'what_if/kanton_list'),
            current_value_k: null,
            limit: 10,
            current_page: 0,
            max_page: 5,
            dataList: [],
            loading: true,
            switch_state: false,
            state_settings: false,

            employees_num: 0,
            num_biggest_employee: 0,
            biggest_employee: 'sum_0',
            pkm_or_trips: 'trips',
            agg_traffic: 'both',
            valueTopEmployees: 100000,
            min_distance_train_station: 0,
            max_distance_train_station: 35000,
            showDetails: false,
            rowDataSelected: [],
            data_graph: [],
            data_graph_traveled: [],

            loading_details: false,
            carousel_num: 1,

            categories_: this.getCategories(URL + 'tile_potential_employer/categories'),
            current_value_category: {
                label: 'All',
                value: 'all'
            },

            //Map
            lng: 8.251313,
            lat: 46.924143,
            zoom: 13.5,
            map: null,
            tyle: 'mapbox://styles/mapbox/light-v10',

            behaviour: true,
            last_mile: true,
            bike: true,
            working: true,
            car_pooling: true,

            cr_behavior: 0.2,
            cr_last_mile: 0.2,
            cr_bike: 0.3,
            cr_working: 0.3,
            cr_car_pooling: 0.05,
            n_measures: 100,
            commuter_effect_trips: 0,
            commuter_effect_pkm: 0,
            total_cum_switzerland: 0,
            total_revenue_view: 0,
            total_cars_pkm_trips: 0,
            contribution_cars_cum: 0
        }
        this.fetchInitialData('sum_0', 'all', 0, 0, 35000, 'all', 0, 'trips', 'both', true, true, true, true, true, 0.2, 0.2, 0.3, 0.3, 0.05, 100);
    }

    componentDidMount() {
        
    }

    async getCategories(url) {
        const res = await axios.get(url)
        const data = res.data
        this.setState({ categories_: data });
    }

    async getOptionsKantons(url) {
        const res = await axios.get(url)
        const data = res.data
        const options = data.map(d => ({
            "value": d._id,
            "label": d._id
        }))
        this.setState({ kantons_: options });
    }

    handleBuildingCategory(event) {
        this.setState({ current_value_category: event });

        this.fetchInitialData((this.state.switch_state ? 'max' : this.state.biggest_employee), event.value, this.state.employees_num, this.state.min_distance_train_station, this.state.max_distance_train_station, (this.state.current_value_k === null ? 'all' : this.state.current_value_k.value), this.state.current_page, this.state.pkm_or_trips, this.state.agg_traffic, this.state.behaviour, this.state.last_mile, this.state.bike, this.state.working, this.state.car_pooling, this.state.cr_behavior, this.state.cr_last_mile, this.state.cr_bike, this.state.cr_working, this.state.cr_car_pooling, this.state.n_measures)
    }

    handleKanton(event) {
        this.setState({ current_value_k: event });
        this.fetchInitialData((this.state.switch_state ? 'max' : this.state.biggest_employee), this.state.current_value_category.value, this.state.employees_num, this.state.min_distance_train_station, this.state.max_distance_train_station, (event === null ? 'all' : event.value), this.state.current_page, this.state.pkm_or_trips, this.state.agg_traffic, this.state.behaviour, this.state.last_mile, this.state.bike, this.state.working, this.state.car_pooling, this.state.cr_behavior, this.state.cr_last_mile, this.state.cr_bike, this.state.cr_working, this.state.cr_car_pooling, this.state.n_measures)
    }

    handleAggTraffic(event) {
        this.setState({ agg_traffic: event.target.attributes.getNamedItem('id').value, loading_potentials: true });
    }

    hideComponent() {
        let currentStateSettings = !this.state.state_settings;
        this.setState({ state_settings: currentStateSettings })
    }

    fetchInitialData(filter, current_value_category, num_employees, min_train_station, max_train_station, kanton, page, pkm_or_trips, agg_traffic, behaviour, last_mile, bike, working, car_pooling, cr_behavior, cr_last_mile, cr_bike, cr_working, cr_car_pooling, n_measures) {
        this.setState({ current_page: 0 })
        this.setState({ loading: true })

        let body = {
            "filter": filter + '_' + current_value_category,
            "n_employees": num_employees,
            "distance_to_station_range": [min_train_station, max_train_station],
            "kanton": kanton,
            "metric": pkm_or_trips,
            "type": (agg_traffic === 'both' ? '' : '_' + agg_traffic),
            "n_measures": n_measures
        }

        let commuter_effect_trips = 0
        let commuter_effect_pkm = 0

        fetch(URL + 'tile_potential_employer/top_100_hotspots_pages', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body),
        })
            .then((res) => res.json())
            .then((json) => {
                commuter_effect_trips = json.commuter_effect_trips;
                commuter_effect_pkm = json.commuter_effect_pkm;

                this.setState({
                    max_page: json.pages,
                    commuter_effect_trips: json.commuter_effect_trips, commuter_effect_pkm: json.commuter_effect_pkm
                })

                return  json.pages;
            })
            .then((max_pages)=>{

                body = {
                    "filter": filter + '_' + current_value_category,
                    "n_employees": num_employees,
                    "distance_to_station_range": [min_train_station, max_train_station],
                    "kanton": kanton,
                    "page": max_pages,
                    "metric": pkm_or_trips,
                    "type": (agg_traffic === 'both' ? '' : '_' + agg_traffic),
                    "n_measures": n_measures,
                    "commuter_effect_trips": commuter_effect_trips,
                    "commuter_effect_pkm": commuter_effect_pkm
                }

                fetch(URL + 'tile_potential_employer/top_100_hotspots', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(body),
                })
                    .then((res) => res.json())
                    .then((json) => {
                        this.setState({ total_cum_switzerland: (json[json.length-1].contribution_cum*100).toFixed(2), 
                            contribution_cars_cum: (json[json.length-1].contribution_cars_cum*100).toFixed(2)}) })
                        .then(() => {
                            body = {
                                "filter": filter + '_' + current_value_category,
                                "n_employees": num_employees,
                                "distance_to_station_range": [min_train_station, max_train_station],
                                "kanton": kanton,
                                "page": 1,
                                "metric": pkm_or_trips,
                                "type": (agg_traffic === 'both' ? '' : '_' + agg_traffic),
                                "n_measures": n_measures,
                                "commuter_effect_trips": commuter_effect_trips,
                                "commuter_effect_pkm": commuter_effect_pkm
                            }
            
                            fetch(URL + 'tile_potential_employer/top_100_hotspots', {
                                method: 'POST',
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify(body),
                            })
                                .then((res) => res.json())
                                .then((json) => {
                                    this.setState({ dataList: json })
                                }).then(() => {
                                    this.setState({ loading: false })
                                })
                        })
            })
            
    }

    fetchInitialDataMoveBetweenPages(filter, current_value_category, num_employees, min_train_station, max_train_station, kanton, page, pkm_or_trips, agg_traffic, behavior, last_mile, bike, working, car_pooling, cr_behavior, cr_last_mile, cr_bike, cr_working, cr_car_pooling, n_measures) {
        this.setState({ loading: true })

        let activated = []

        if (behavior) {
            activated.push('behavior')
        }
        if (last_mile) {
            activated.push('last_mile')
        }
        if (bike) {
            activated.push('bike')
        }
        if (working) {
            activated.push('working')
        }
        if (car_pooling) {
            activated.push('car_pooling')
        }

        let body = {
            "filter": filter + '_' + current_value_category,
            "active_options": activated,
            "n_employees": num_employees,
            "distance_to_station_range": [min_train_station, max_train_station],
            "kanton": kanton,
            "page": (page + 1),
            "metric": pkm_or_trips,
            "type": (agg_traffic === 'both' ? '' : '_' + agg_traffic),
            "n_measures": n_measures,
            "commuter_effect_trips": this.state.commuter_effect_trips,
            "commuter_effect_pkm": this.state.commuter_effect_pkm
        }

        fetch(URL + 'tile_potential_employer/top_100_hotspots', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body),
        })
            .then((res) => res.json())
            .then((json) => {
                this.setState({ dataList: json })
            }).then(() => {
                this.setState({ loading: false })
            })
    }

    handleTypeEmployer() {
        let currentState = !this.state.switch_state
        this.setState({ switch_state: currentState, num_biggest_employee: (!currentState ? 3000 : 0), biggest_employee: (!currentState ? 'sum_3000' : 'sum_0'), showDetails: false, employees_num: 0})
        this.fetchInitialData((currentState ? 'max' : this.state.biggest_employee), this.state.current_value_category.value, 0, this.state.min_distance_train_station, this.state.max_distance_train_station, (this.state.current_value_k === null ? 'all' : this.state.current_value_k.value), this.state.current_page, this.state.pkm_or_trips, this.state.agg_traffic, this.state.behaviour, this.state.last_mile, this.state.bike, this.state.working, this.state.car_pooling, this.state.cr_behavior, this.state.cr_last_mile, this.state.cr_bike, this.state.cr_working, this.state.cr_car_pooling, this.state.n_measures)
    }

    handleHeight(event) {
        this.setState({ pkm_or_trips: event.target.attributes.getNamedItem('id').value });
    }

    handlePageClick(event) {
        this.setState({ current_page: event.selected })
        this.fetchInitialDataMoveBetweenPages((this.state.switch_state ? 'max' : this.state.biggest_employee), this.state.current_value_category.value, this.state.employees_num, this.state.min_distance_train_station, this.state.max_distance_train_station, (this.state.current_value_k === null ? 'all' : this.state.current_value_k.value), event.selected, this.state.pkm_or_trips, this.state.agg_traffic, this.state.behaviour, this.state.last_mile, this.state.bike, this.state.working, this.state.car_pooling, this.state.cr_behavior, this.state.cr_last_mile, this.state.cr_bike, this.state.cr_working, this.state.cr_car_pooling, this.state.n_measures)
    }

    handleRowSelection(tileId) {
        window.open(URL_FRONT + 'potential-tile-employer/' + tileId + ',' + (this.state.switch_state ? 'max' : this.state.biggest_employee) + '_' + this.state.current_value_category.value + ',' + this.state.employees_num + ',' + this.state.min_distance_train_station + ',' + this.state.max_distance_train_station + ',' + (this.state.current_value_k === null ? 'all' : this.state.current_value_k.value), "_blank", "noreferrer");
    }

    handleUpdate() {
        console.log(this.state.behaviour, this.state.last_mile, this.state.bike, this.state.working, this.state.car_pooling)
        this.fetchInitialData((this.state.switch_state ? 'max' : this.state.biggest_employee), this.state.current_value_category.value, this.state.employees_num, this.state.min_distance_train_station, this.state.max_distance_train_station, (this.state.current_value_k === null ? 'all' : this.state.current_value_k.value), this.state.current_page, this.state.pkm_or_trips, this.state.agg_traffic, this.state.behaviour, this.state.last_mile, this.state.bike, this.state.working, this.state.car_pooling, this.state.cr_behavior, this.state.cr_last_mile, this.state.cr_bike, this.state.cr_working, this.state.cr_car_pooling, this.state.n_measures)
    }

    handleNumEmployeesInCompany(event) {
        let minEmployees = 0;

        if (event <= 3000) {
            minEmployees = event;

            if (event === 250) {
                minEmployees = 200;
            } else if (event === 400) {
                minEmployees = 300;
            } else if (event === 550) {
                minEmployees = 500;
            }
        }

        this.setState({ num_biggest_employee: event, valueMinEmployees: minEmployees, biggest_employee: 'sum_' + minEmployees });

        if (this.state.employees_num < minEmployees && event >= 3000) {
            this.setState({ employees_num: minEmployees });
        }
    }

    handleDistanceTrainStation(event) {
        this.setState({ min_distance_train_station: event[0], max_distance_train_station: event[1] });
    }

    handleMeasuresActivated(event) {
        let temp = !(this.state[event.target.attributes.getNamedItem('id').value]);

        if (event.target.attributes.getNamedItem('id').value === 'behaviour') {
            this.setState({ behaviour: temp })
        }

        if (event.target.attributes.getNamedItem('id').value === 'last_mile') {
            this.setState({ last_mile: temp })
        }

        if (event.target.attributes.getNamedItem('id').value === 'bike') {
            this.setState({ bike: temp })
        }

        if (event.target.attributes.getNamedItem('id').value === 'working') {
            this.setState({ working: temp })
        }

        if (event.target.attributes.getNamedItem('id').value === 'car_pooling') {
            this.setState({ car_pooling: temp })
        }
    }

    handleNumEmployees(event) {
        this.setState({ employees_num: event });
    }

    hideDetails() {
        this.setState({ showDetails: false });
    }

    handleCRBehaviour(event) {
        this.setState({ cr_behavior: Number(event.target.value) })
    }

    handleCRLastMile(event) {
        this.setState({ cr_last_mile: Number(event.target.value) })
    }

    handleCRBike(event) {
        this.setState({ cr_bike: Number(event.target.value) })
    }

    handleCRWorking(event) {
        this.setState({ cr_working: Number(event.target.value) })
    }

    handleCRCarPooling(event) {
        this.setState({ cr_car_pooling: Number(event.target.value) })
    }

    handleNumMeasures(event) {
        this.setState({ n_measures: Number(event.target.value) })
    }

    render() {
        const { data_graph } = this.state;
        const { data_graph_traveled } = this.state;
        const { agg_traffic } = this.state;
        const { pkm_or_trips } = this.state;

        const { behaviour } = this.state;
        const { last_mile } = this.state;
        const { bike } = this.state;
        const { working } = this.state;
        const { car_pooling } = this.state;



        const selectStyles = {
            menuList: styles => {
                return {
                    ...styles,
                    maxHeight: 150,
                };
            }
        };

        const marks = {
            0: '0',
            100: '100',
            250: '200',
            400: '300',
            550: '500',
            1000: '1\'000',
            2000: '2\'000',
            3000: '3\'000'
        }

        const dataMSPrediction = {
            labels: [2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028],
            datasets: [{
                label: 'Current Modal Split',
                data: [25, 24, 23, 24, 24.5],
                backgroundColor: [
                    'rgba(210,231,212)'
                ],
                borderColor: [
                    'rgb(2, 128, 6)'

                ],
                borderWidth: 2,
                fill: true,
                stack: 'Stack 0'
            },
            {
                label: 'Expected Modal Split',
                data: [null, null, null, null, 0, 25, 25.3, 26, 26.4, 27],
                backgroundColor: [
                    'rgba(210,231,212)'
                ],
                borderColor: [
                    'rgb(2, 128, 6)'

                ],
                borderWidth: 2,
                borderDash: [10, 5],
                fill: true,
                stack: 'Stack 0'
            },

            {
                label: 'Behaviour Change',
                data: [null, null, null, null, 0, 1, 1.2, 1.4, 2, 2.3],
                backgroundColor: [
                    'rgba(57,0,153,0.5)'
                ],
                borderColor: [
                    'rgb(57,0,153)'

                ],
                borderWidth: 2,
                fill: true,
                stack: 'Stack 0'
            },
            {
                label: 'Last Mile',
                data: [null, null, null, null, 0, 1.3, 1.3, 1.3, 1.4, 1.5],
                backgroundColor: [
                    'rgba(255,0,84,0.5)'
                ],
                borderColor: [
                    'rgb(255,0,84)'

                ],
                borderWidth: 2,
                fill: true,
                stack: 'Stack 0'
            },
            {
                label: 'Bicycle Culture',
                data: [null, null, null, null, 0, 1.1, 1.15, 1.2, 1.4, 1.5],
                backgroundColor: [
                    'rgba(158,0,89,0.5)'
                ],
                borderColor: [
                    'rgb(158,0,89)'

                ],
                borderWidth: 2,
                fill: true,
                stack: 'Stack 0'
            },
            {
                label: 'Working Policy',
                data: [null, null, null, null, 0, 2, 2.3, 2.6, 2.9, 3],
                backgroundColor: [
                    'rgba(255,84,0,0.5)'
                ],
                borderColor: [
                    'rgb(255,84,0)'

                ],
                borderWidth: 2,
                fill: true,
                stack: 'Stack 0'
            },
            {
                label: 'Car pooling',
                data: [null, null, null, null, 0, 1, 1.5, 1.8, 1.9, 1.95],
                backgroundColor: [
                    'rgba(26,181,178,0.5)'
                ],
                borderColor: [
                    'rgb(26,181,178)'

                ],
                borderWidth: 2,
                fill: true,
                stack: 'Stack 0'
            }
            ]
        };

        const optionMSPrediction = {
            xAxes: [{
                type: 'time',
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 10
                }
            }],
            yAxes: [{
                stacked: true,
            }],
            plugins: {
                title: {
                    display: false,
                    text: 'Modal Split Prediction based on Measures'
                },
                legend: {
                    position: 'bottom',
                },
            },
            maintainAspectRatio: false
        };

        const dataLine = {
            labels: data_graph['date'],
            datasets: [{
                label: 'Trains\' Modal Split',
                data: data_graph['modal_split'],
                backgroundColor: [
                    'rgba(2, 128, 6,0.2)'
                ],
                borderColor: [
                    'rgb(2, 128, 6)'

                ],
                borderWidth: 1
            }
            ]
        };

        const options = {
            xAxes: [{
                type: 'time',
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 10
                }
            }],
            plugins: {
                title: {
                    display: true,
                    text: 'Percentage of Trains\' Modal Split (PKm) per Month'
                },
                legend: {
                    position: 'bottom',
                },
            },
            maintainAspectRatio: false
        };

        const kmPerDay = {
            xAxes: [{
                type: 'time',
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 10
                }
            }],
            plugins: {
                legend: {
                    position: 'bottom',
                },
                title: {
                    display: true,
                    text: 'Kilometers per Month'
                }
            },
            maintainAspectRatio: false
        };

        const dataLine_TraveledKm = {
            labels: data_graph_traveled['date'],
            datasets: [{
                label: 'Traveled Km by Trains',
                data: data_graph_traveled['traveled_km_trains'],
                backgroundColor: [
                    'rgba(247, 137, 72, 0.2)'
                ],
                borderColor: [
                    'rgb(247, 137, 72)'
                ],
                borderWidth: 1
            },
            {
                label: 'Traveled Km by Cars',
                data: data_graph_traveled['traveled_km_cars'],
                backgroundColor: [
                    'rgba(2,127,128,0.2)'
                ],
                borderColor: [
                    'rgb(2,127,128)'
                ],
                borderWidth: 1
            },
            ]
        };

        return (
            <>
                <RecommendationNavBarComponent />
                <div className="switch-change-div" style={{ top: '0px', left: '560px' }}>
                    <div className="switch-change-div-inner">
                        <div className="column-33">
                            <p className="label">All Employers in Tile</p>
                        </div>
                        <div className="column-33">
                            <label className="switch">
                                <input type="checkbox" onChange={this.handleTypeEmployer.bind(this)} value={this.state.switch_state} />
                                <span className="slider round"></span>
                            </label>
                        </div>
                        <div className="column-33">
                            <p className="label">Biggest Employer in Tile</p>
                        </div>
                    </div>
                </div>

                <div className="tile-category-div">
                    <Select className='categories' placeholder="Filter by Building Category..." isClearable={false} options={this.state.categories_} onChange={this.handleBuildingCategory.bind(this)} styles={selectStyles} defaultValue={this.state.current_value_category} />
                </div>
                <div className="dashboard-5-in-5">
                    <div className="dashboard-5-in-5-inner">
                        <div className="kanton-employer-div">
                            <Select className='kanton' placeholder="Filter by Canton..." isClearable={true} options={this.state.kantons_} onChange={this.handleKanton.bind(this)} styles={selectStyles} defaultValue={this.state.current_value_k} />
                        </div>



                        <div className="dashboard-table-frame">
                            <h1>Top Hotspots MIV</h1>
                            <div className="row" style={{ height: '55px' }}>
                                <div className='column-20' style={{ float: 'left', width: '90%', padding: '0px' }}>
                                <h2 style={{ fontSize: '20px'}}>Top {this.state.n_measures} MIV-Hotspots contribute {this.state.total_cum_switzerland}% to total traffic contribution with {this.state.contribution_cars_cum}% of Cars' {this.state.pkm_or_trips}</h2>
                                </div>
                                <div className='column-20' style={{ float: 'right', width: '10%', padding: '0px' }}>
                                    <div className='settings-dashboard-btn-inner' onClick={() => this.hideComponent()}>
                                        <IconContext.Provider value={{ color: "#5B5B5B", size: '1em' }}>
                                            <div>
                                                <IoSettingsOutline />
                                            </div>
                                        </IconContext.Provider>
                                    </div>
                                </div>
                            </div>

                            {
                                this.state.state_settings
                                    ?
                                    <>
                                        <div className="row" style={{ height: '60px' }}>
                                            <div className='column-20' style={{ float: 'right', width: '30%', padding: '0px' }}>
                                                <p className='label-text' style={{ marginTop: '0px' }}><b>Number of Employees within Tile</b></p>
                                                <div className="column-especial">
                                                    <Slider min={0} max={60000} step={100} value={this.state.employees_num} onChange={_.debounce(this.handleNumEmployees.bind(this), 33)} />
                                                </div>
                                                <p className='label-text' style={{ marginTop: '20px' }}>Showing tiles with minimum {numberWithCommas(Number(this.state.employees_num))} employees in all companies</p>
                                            </div>
                                            <div className='column-20 slider-center' style={{ float: 'right', width: '40%', padding: '0px' }}>
                                                <p className='label-text' style={{ marginTop: '0px' }}><b>Number of Employees in a Company</b></p>
                                                <Slider disabled={this.state.switch_state} dots min={0} max={3000} marks={marks} step={null} value={this.state.num_biggest_employee} onChange={_.debounce(this.handleNumEmployeesInCompany.bind(this), 33)} />
                                            </div>
                                            <div className='column-20' style={{ float: 'right', width: '30%', padding: '0px' }}>
                                                <p className='label-text' style={{ marginTop: '0px' }}><b>Distance to Train Station</b></p>
                                                <div className="column-especial">
                                                    <Slider range draggableTrack min={0} max={35000} step={100} value={[this.state.min_distance_train_station, this.state.max_distance_train_station]} onChange={_.debounce(this.handleDistanceTrainStation.bind(this), 33)} />
                                                </div>
                                                <p className='label-text' style={{ marginTop: '20px' }}>Showing tiles between {numberWithCommas(this.state.min_distance_train_station)}m and {numberWithCommas(this.state.max_distance_train_station)}m to the train station</p>
                                            </div>
                                        </div>
                                        <div className="row" style={{ height: '40px' }}>
                                            <div className='column-20' style={{ float: 'right', width: '50%', padding: '0px' }}>
                                                <p className='label-text' style={{ marginTop: '0px' }}><b>Measure the potential based on</b></p>
                                                <Form onChange={this.handleHeight.bind(this)}>
                                                    <div className="row">
                                                        <div className="column-1-50">
                                                            <Form.Check inline label=" Number of trips" name="type_aggregation" type='radio' id='trips' defaultChecked={(pkm_or_trips === 'trips')} />
                                                        </div>
                                                        <div className="column-1-50">
                                                            <Form.Check inline label=" Passenger Kilometers (PKm)" name="type_aggregation" type='radio' id='pkm' defaultChecked={(pkm_or_trips === 'pkm')} />
                                                        </div>
                                                    </div>
                                                </Form>
                                            </div>
                                            <div className='column-20' style={{ float: 'right', width: '50%', padding: '0px' }}>
                                                <p className='label-text' style={{ marginTop: '0px' }}><b>Number of measures</b></p>
                                                <input style={{ width: '90%' }} type="number" value={this.state.n_measures} onChange={this.handleNumMeasures.bind(this)} />
                                            </div>
                                        </div>

                                        <div className="row" style={{ height: '50px' }}>
                                            <Form onClick={this.handleUpdate.bind(this)}>
                                                <Button variant="info" className="generate-btn">Update</Button>
                                            </Form>
                                        </div>
                                    </>
                                    :
                                    <></>
                            }

                            <Table
                                isTree
                                loading={this.state.loading}
                                defaultExpandAllRows={false}
                                rowKey="id"
                                autoHeight={false}
                                height={310}
                                fillHeight={true}
                                data={this.state.dataList}
                            >
                                 <Column flexGrow={1} minWidth={80}>
                                    <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}>#</HeaderCell>
                                    <Cell style={{ fontSize: '12px' }}>
                                        {(rowData) => {
                                            return <>{rowData.category === 'All' ? rowData.id+1 : ''}</>
                                        }}
                                    </Cell>
                                </Column>

                                <Column flexGrow={2.2} minWidth={150} fullText>
                                    <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}>Place
                                    <br/>
                                                <span style={{ fontSize: '10px'}}>PLZ Code</span></HeaderCell>
                                    <Cell dataKey="place" style={{ fontSize: '12px' }}>
                                        {rowData => {
                                            return <Button style={{
                                                background: 'none',
                                                color: 'inherit',
                                                border: 'none',
                                                padding: 0,
                                                font: 'inherit',
                                                cursor: 'pointer',
                                                outline: 'inherit',
                                            }} onClick={() => {
                                                window.open(URL_FRONT + 'potential-tile-employer/' + rowData.tileId + ',' + (this.state.switch_state ? 'max' : this.state.biggest_employee) + '_' + this.state.current_value_category.value + ',' + this.state.employees_num + ',' + this.state.min_distance_train_station + ',' + this.state.max_distance_train_station + ',' + (this.state.current_value_k === null ? 'all' : this.state.current_value_k.value), "_blank", "noreferrer");
                                            }}>{rowData.place === null ? '-' : rowData.place}
                                            <br/>
                                            <span style={{ fontSize: '10px' }}>{rowData.PLZ}</span>
                                            </Button>
                                        }
                                        }
                                    </Cell>
                                </Column>

                                <Column flexGrow={1.8} minWidth={100}>
                                    <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}>Total {this.state.pkm_or_trips === 'trips' ? 'Trips' : 'PKm'}</HeaderCell>
                                    <Cell style={{ fontSize: '12px' }}>
                                        {(rowData) => {
                                            return <>{rowData.category === 'All' ? numberWithCommas(rowData.total_metric?.toFixed(0)) : ''}</>;
                                        }}
                                    </Cell>
                                </Column>

                                <Column flexGrow={1.5} minWidth={170}>
                                    <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}>Number of Employees</HeaderCell>
                                    <Cell style={{ fontSize: '12px' }}>
                                        {(rowData) => {
                                            return <>{rowData.category === 'All' ? numberWithCommas(rowData.total_employees?.toFixed(0)) : ''}</>;
                                        }}
                                    </Cell>
                                </Column>

                                <Column flexGrow={2.5} minWidth={40}>
                                    <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}>Category</HeaderCell>
                                    <Cell style={{ fontSize: '12px' }}>
                                        {(rowData) => {
                                            if (rowData.category === 'All') {
                                                return <div className="dot" style={{ backgroundColor: '#00CE41' }}><a style={{ paddingLeft: '15px' }}>All Categories</a></div>;
                                            } else if (rowData.category === 'Employees') {
                                                return <div className="dot" style={{ backgroundColor: '#BF0040' }}><a style={{ paddingLeft: '15px' }}>Employees</a></div>;
                                            } else if (rowData.category === 'Ausbildung') {
                                                return <div className="dot" style={{ backgroundColor: '#D00076' }}><a style={{ paddingLeft: '15px' }}>Ausbildung</a></div>;
                                            } else if (rowData.category === 'Heime') {
                                                return <div className="dot" style={{ backgroundColor: '#4A01C9' }}><a style={{ paddingLeft: '15px' }}>Heime</a></div>;
                                            } else if (rowData.category === 'Hotel') {
                                                return <div className="dot" style={{ backgroundColor: '#FF722B' }}><a style={{ paddingLeft: '15px' }}>Hotel</a></div>;
                                            } else if (rowData.category === 'Kinos') {
                                                return <div className="dot" style={{ backgroundColor: '#43D0CD' }}><a style={{ paddingLeft: '15px' }}>Kinos</a></div>;
                                            } else if (rowData.category === 'Museen / Events') {
                                                return <div className="dot" style={{ backgroundColor: '#F71212' }}><a style={{ paddingLeft: '15px' }}>Museen / Events</a></div>;
                                            } else if (rowData.category === 'Spitäler') {
                                                return <div className="dot" style={{ backgroundColor: '#9B52C4' }}><a style={{ paddingLeft: '15px' }}>Spitäler</a></div>;
                                            } else if (rowData.category === 'Einkaufen') {
                                                return <div className="dot" style={{ backgroundColor: '#1AB5B2' }}><a style={{ paddingLeft: '15px' }}>Einkaufen</a></div>;
                                            } else if (rowData.category === 'Bahnhof') {
                                                return <div className="dot" style={{ backgroundColor: '#FEBA00' }}><a style={{ paddingLeft: '15px' }}>Bahnhof</a></div>;
                                            } else if (rowData.category === 'Andere') {
                                                return <div className="dot" style={{ backgroundColor: '#529E00' }}><a style={{ paddingLeft: '15px' }}>Andere</a></div>;
                                            }
                                        }}
                                    </Cell>
                                </Column>

                                <Column flexGrow={2} minWidth={120}>
                                    <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}>Modal Split MIV
                                    </HeaderCell>
                                    <Cell style={{ fontSize: '12px' }}>
                                        {(rowData) => {
                                            if (rowData.category === 'All') {
                                                return <>
                                                    {(rowData.modal_split_MIV * 100).toFixed(0)}%
                                                    <br />
                                                    <ProgressBar
                                                        completed={(rowData.modal_split_MIV * 100).toFixed(0)}
                                                        maxCompleted={100}
                                                        bgColor="#0BA53D"
                                                        height="15px"
                                                        labelAlignment="outside"
                                                        isLabelVisible={false}
                                                        labelColor="#0BA53D"
                                                    />
                                                </>;
                                            } else {
                                                return <></>;
                                            }
                                        }}
                                    </Cell>
                                </Column>

                                <Column flexGrow={1.5} minWidth={130}>
                                    <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}>Traffic Pct. <br />
                                    <span style={{ fontSize: '10px' }}>Within the Tile</span>
                                    </HeaderCell>
                                    <Cell style={{ fontSize: '12px' }}>
                                        {(rowData) => {
                                            if(rowData.category === 'Employees'){
                                                return <>
                                                {(rowData.contribution * 100).toFixed(0)}%
                                                <br />
                                                <ProgressBar
                                                    completed={(rowData.contribution * 100).toFixed(0)}
                                                    maxCompleted={100}
                                                    bgColor="#BF0040"
                                                    height="15px"
                                                    labelAlignment="outside"
                                                    isLabelVisible={false}
                                                    labelColor="#0BA53D"
                                                />
                                                </>;
                                            }  else if(rowData.category === 'Ausbildung'){
                                                return <>
                                                {(rowData.contribution * 100).toFixed(0)}%
                                                <br />
                                                <ProgressBar
                                                    completed={(rowData.contribution * 100).toFixed(0)}
                                                    maxCompleted={100}
                                                    bgColor="#D00076"
                                                    height="15px"
                                                    labelAlignment="outside"
                                                    isLabelVisible={false}
                                                    labelColor="#0BA53D"
                                                />
                                                </>;
                                            } else if(rowData.category === 'Heime'){
                                                return <>
                                                {(rowData.contribution * 100).toFixed(0)}%
                                                <br />
                                                <ProgressBar
                                                    completed={(rowData.contribution * 100).toFixed(0)}
                                                    maxCompleted={100}
                                                    bgColor="#4A01C9"
                                                    height="15px"
                                                    labelAlignment="outside"
                                                    isLabelVisible={false}
                                                    labelColor="#0BA53D"
                                                />
                                                </>;
                                            } else if(rowData.category === 'Hotel'){
                                                return <>
                                                {(rowData.contribution * 100).toFixed(0)}%
                                                <br />
                                                <ProgressBar
                                                    completed={(rowData.contribution * 100).toFixed(0)}
                                                    maxCompleted={100}
                                                    bgColor="#FF722B"
                                                    height="15px"
                                                    labelAlignment="outside"
                                                    isLabelVisible={false}
                                                    labelColor="#0BA53D"
                                                />
                                                </>;
                                            } else if(rowData.category === 'Kinos'){
                                                return <>
                                                {(rowData.contribution * 100).toFixed(0)}%
                                                <br />
                                                <ProgressBar
                                                    completed={(rowData.contribution * 100).toFixed(0)}
                                                    maxCompleted={100}
                                                    bgColor="#43D0CD"
                                                    height="15px"
                                                    labelAlignment="outside"
                                                    isLabelVisible={false}
                                                    labelColor="#0BA53D"
                                                />
                                                </>;
                                            } else if(rowData.category === 'Museen / Events'){
                                                return <>
                                                {(rowData.contribution * 100).toFixed(0)}%
                                                <br />
                                                <ProgressBar
                                                    completed={(rowData.contribution * 100).toFixed(0)}
                                                    maxCompleted={100}
                                                    bgColor="#F71212"
                                                    height="15px"
                                                    labelAlignment="outside"
                                                    isLabelVisible={false}
                                                    labelColor="#0BA53D"
                                                />
                                                </>;
                                            } else if(rowData.category === 'Spitäler'){
                                                return <>
                                                {(rowData.contribution * 100).toFixed(0)}%
                                                <br />
                                                <ProgressBar
                                                    completed={(rowData.contribution * 100).toFixed(0)}
                                                    maxCompleted={100}
                                                    bgColor="#9B52C4"
                                                    height="15px"
                                                    labelAlignment="outside"
                                                    isLabelVisible={false}
                                                    labelColor="#0BA53D"
                                                />
                                                </>;
                                            } else if(rowData.category === 'Bahnhof'){
                                                return <>
                                                {(rowData.contribution * 100).toFixed(0)}%
                                                <br />
                                                <ProgressBar
                                                    completed={(rowData.contribution * 100).toFixed(0)}
                                                    maxCompleted={100}
                                                    bgColor="#FEBA00"
                                                    height="15px"
                                                    labelAlignment="outside"
                                                    isLabelVisible={false}
                                                    labelColor="#0BA53D"
                                                />
                                                </>;
                                            } else if(rowData.category === 'Andere'){
                                                return <>
                                                {(rowData.contribution * 100).toFixed(0)}%
                                                <br />
                                                <ProgressBar
                                                    completed={(rowData.contribution * 100).toFixed(0)}
                                                    maxCompleted={100}
                                                    bgColor="#529E00"
                                                    height="15px"
                                                    labelAlignment="outside"
                                                    isLabelVisible={false}
                                                    labelColor="#0BA53D"
                                                />
                                                </>;
                                            } else if(rowData.category === 'Einkaufen'){
                                                return <>
                                                {(rowData.contribution * 100).toFixed(0)}%
                                                <br />
                                                <ProgressBar
                                                    completed={(rowData.contribution * 100).toFixed(0)}
                                                    maxCompleted={100}
                                                    bgColor="#1AB5B2"
                                                    height="15px"
                                                    labelAlignment="outside"
                                                    isLabelVisible={false}
                                                    labelColor="#0BA53D"
                                                />
                                                </>;
                                            } else{
                                                    return <>
                                                        {/* {(rowData.contribution * 100).toFixed(0)}%
                                                        <br />
                                                        <ProgressBar
                                                            completed={(rowData.contribution * 100).toFixed(0)}
                                                            maxCompleted={100}
                                                            bgColor="#0BA53D"
                                                            height="15px"
                                                            labelAlignment="outside"
                                                            isLabelVisible={false}
                                                            labelColor="#0BA53D"
                                                        /> */}
                                                    </>;
                                            }
                                        }}
                                    </Cell>
                                </Column>

                                <Column flexGrow={1.8} minWidth={100}>
                                    <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}>Cars' {this.state.pkm_or_trips === 'trips' ? 'Trips' : 'PKm'}</HeaderCell>
                                    <Cell style={{ fontSize: '12px' }}>
                                        {(rowData) => {
                                            if(rowData.category === 'All') {
                                                return <>{numberWithCommas(rowData.total_metric_cars.toFixed(0))}
                                                <br/>
                                                <span style={{ fontSize: '10px', fontWeight: 600 }}>Cum.: {numberWithCommas(rowData.total_metric_cars_cummulative.toFixed(0))}</span>
                                                </>
                                            }else {
                                                return <>{numberWithCommas(rowData.total_metric_cars.toFixed(0))}</>
                                            }
                                        }}
                                    </Cell>
                                </Column>

                                <Column flexGrow={2.5} minWidth={170}>
                                    <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}>Total Contribution Cars<br />
                                    <span style={{ fontSize: '10px' }}>Cumulative {this.state.current_value_k === null ? 'in Switzerland' : 'in the canton'}</span></HeaderCell>
                                    <Cell style={{ fontSize: '12px' }}>
                                        {(rowData) => {
                                            if (rowData.category === 'All')  {
                                                return <>{(rowData.contribution_cars * 100).toFixed(2)}%
                                                 <br/>
                                                <span style={{ fontSize: '10px', fontWeight: 600 }}>Cum.: {(rowData.contribution_cars_cum*100).toFixed(2)}%</span></>
                                            } else {
                                                return <></>
                                            }
                                        }}
                                    </Cell>
                                </Column>

                                <Column flexGrow={2.5} minWidth={180}>
                                    <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}>Total Traffic Contribution <br />
                                    <span style={{ fontSize: '10px' }}>Cumulative {this.state.current_value_k === null ? 'in Switzerland' : 'in the canton'}</span></HeaderCell>
                                    <Cell style={{ fontSize: '12px' }}>
                                        {(rowData) => {
                                            if (rowData.category === 'All')  {
                                                return <>{(rowData.contribution * 100).toFixed(2)}%
                                                 <br/>
                                                <span style={{ fontSize: '10px', fontWeight: 600 }}>Cum.: {(rowData.contribution_cum*100).toFixed(2)}%</span></>
                                            } else {
                                                return <></>
                                            }
                                        }}
                                    </Cell>
                                </Column>

                                <Column flexGrow={1} minWidth={50}>
                                    <HeaderCell style={{ background: '#F6F6F6', fontSize: '14px', fontWeight: 500 }}>...</HeaderCell>
                                    <Cell dataKey="tileId" style={{ fontSize: '12px' }}>
                                        {rowData => {
                                            return (rowData.category === 'All' ?
                                                <Button style={{
                                                    background: 'none',
                                                    color: 'inherit',
                                                    border: 'none',
                                                    padding: 0,
                                                    font: 'inherit',
                                                    cursor: 'pointer',
                                                    outline: 'inherit',
                                                }} onClick={() => {
                                                    this.setState({ showDetails: true, rowDataSelected: rowData, loading_details: true });

                                                    fetch(URL + 'areas_to_focus/tile/' + rowData.tileId)
                                                        .then((res) => res.json())
                                                        .then((json) => {
                                                            this.setState({
                                                                lng: ((json.features[0].geometry.coordinates[0][1][0] + json.features[0].geometry.coordinates[0][2][0]) / 2),
                                                                lat: ((json.features[0].geometry.coordinates[0][2][1] + json.features[0].geometry.coordinates[0][3][1]) / 2) + 0.0005
                                                            })
                                                        })
                                                        .then(() => {
                                                            fetch(URL + 'tile_od/modal_split_pkm/' + rowData.tileId)
                                                                .then((res) => res.json())
                                                                .then((json) => {
                                                                    for (var i = 0; i < json.modal_split.length; i++) {
                                                                        json.modal_split[i] = json.modal_split[i] * 100
                                                                    }
                                                                    return json
                                                                })
                                                                .then((json) => {
                                                                    this.setState({ data_graph: json });
                                                                }).then(() => {
                                                                    fetch(URL + 'tile_od/traveled_km/' + rowData.tileId)
                                                                        .then((res) => res.json())
                                                                        .then((json) => {
                                                                            this.setState({ data_graph_traveled: json });
                                                                        })
                                                                        .then(() => {
                                                                            this.setState({ loading_details: false })
                                                                        })
                                                                })
                                                        })
                                                }}>
                                                    <IconContext.Provider value={{ color: "#5B5B5B", size: '1em' }}>
                                                        <div>
                                                            <MdOutlineDoubleArrow />
                                                        </div>
                                                    </IconContext.Provider>
                                                </Button> : <></>)
                                        }
                                        }
                                    </Cell>
                                </Column>

                            </Table>

                            <div style={{ paddingTop: 20, paddingLeft: 20, paddingRight: 20, paddingBottom: 20 }}>
                                <div style={{ margin: '0 auto', width: '60vw' }}>
                                    <ReactPaginate
                                        className="react-paginate"
                                        breakLabel="..."
                                        nextLabel="next >"
                                        pageRangeDisplayed={3}
                                        forcePage={this.state.current_page}
                                        onPageChange={this.handlePageClick.bind(this)}
                                        pageCount={this.state.max_page}
                                        previousLabel="< previous"
                                        renderOnZeroPageCount={null}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.showDetails ?
                    <div className="panel-detail">
                        <div className="panel-detail-inner">
                            <div className="row" style={{ justifyItems: 'end' }}>
                                <div style={{ width: '1.2em' }} onClick={() => {
                                    this.setState({ showDetails: false, rowDataSelected: [] })
                                }}>
                                    <IconContext.Provider value={{ color: "#5B5B5B", size: '1em' }}>
                                        <div>
                                            <RxCross2 />
                                        </div>
                                    </IconContext.Provider>
                                </div>
                            </div>
                            {
                                this.state.loading_details ?
                                    <div className="data_insights_loader">
                                        <div className="loader"></div>
                                    </div>
                                    :
                                    <>
                                        <div className="row" style={{ justifyContent: 'start' }}>
                                            <h1>{this.state.rowDataSelected.place}</h1>
                                            <h2> Of {numberWithCommas(this.state.rowDataSelected.total_metric?.toFixed(0))}{this.state.pkm_or_trips === 'trips' ? ' trips' : 'PKm'}, {numberWithCommas(this.state.rowDataSelected.total_metric_cars?.toFixed(0))}{this.state.pkm_or_trips === 'trips' ? ' trips' : 'PKm'} are made by car</h2>
                                        </div>

                                        <div className="row" style={{ paddingLeft: '0px', paddingRight: '20px', height: '280px', paddingTop: '20px' }}>
                                            <h3>Historical Modal Split</h3>
                                            <Line data={dataLine} options={options} />
                                        </div>

                                        <div className="row" style={{ paddingLeft: '0px', paddingRight: '20px', height: '280px' }}>
                                            <h3>Historical Traveled PKm</h3>
                                            <Line data={dataLine_TraveledKm} options={kmPerDay} />
                                        </div>
                                    </>
                            }
                        </div>
                    </div>
                    :
                    <>
                    </>}
            </>
        )
    }
}

export default Dashboard_MIV_Hotspots;