
import React from "react";
import { Map } from "react-map-gl";
import DeckGL, { GeoJsonLayer, FlyToInterpolator } from "deck.gl";
import { IconContext } from "react-icons";
import axios from 'axios';
import { Form } from 'react-bootstrap';
import RecommendationNavBarComponent from "../../components/RecommendationNavBarComponent";
import { RiFullscreenFill, RiFullscreenExitFill } from "react-icons/ri";
import { Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Slider from 'rc-slider';
import Select from 'react-select';
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';

import '../css/map-recommendation-deckgl.css';

const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const URL = process.env.REACT_APP_URL_SERVER_BACK;
const URL_FRONT = process.env.REACT_APP_URL_SERVER_FRONT;

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
}

const _ = require('lodash');

function getColors(f) {
  let color = '#ffffff'

  if (f <= 50) { color = '#007f5f'; }
  else if (f < 50 && f <= 200) { color = '#7C8B3F'; }
  else if (f < 200 && f <= 400) { color = '#F8961E'; }
  else if (f < 400 && f <= 550) { color = '#CE561C'; }
  else { color = '#A4161A'; }

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
  return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : [0, 0, 0];
}

function _renderLayers(data, day_week, hour) {
  const layer = [
    new GeoJsonLayer({
      id: 'heatmap-' + day_week + hour,
      data: data,
      opacity: 0.5,
      stroked: true,
      filled: true,
      extruded: true,
      wireframe: true,
      getElevation: f => (f.properties.counts),
      elevationScale: 5,
      getFillColor: f => getColors(f.properties.counts),
      getLineColor: f => getColors(f.properties.counts),
      getLineWidth: 100,
      pickable: false
    }),
  ];
  return layer;
}

class HideElementsComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hideNavs: false,
      mapa: this.props.map,
    }
  }

  hideComponent() {
    let newHideNavs = !this.state.hideNavs;
    this.setState({ hideNavs: newHideNavs })
    this.props.updateField("hideNavs", newHideNavs);
  }

  render() {

    const { hideNavs } = this.state;

    return (
      <div>
        {hideNavs ? <>
          <div className='map-close-navs'>
            <div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
              <IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
                <div>
                  <RiFullscreenExitFill />
                </div>
              </IconContext.Provider>
            </div>
          </div>
        </> : <>
          <div className='map-close-navs'>
            <div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
              <IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
                <div>
                  <RiFullscreenFill />
                </div>
              </IconContext.Provider>
            </div>
          </div>
        </>}
      </div>
    )
  }
}


class MapHeatmap3D extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      hideNavs: false,
      tileId: '',
      loading: false,
      data: [],
      day_week: ({
        'value': '2023-10-10',
        'label': '2023-10-10'
      }),
      day_of_week_options: this.getOptionsDate(URL + 'tile_potential/heatmap_available_dates'),
      hour: 12,

      event_img: 'olma',
      event_name: 'OLMA',
      duration: '13.10.2023 - 22.10.2023',
      num_people: 'Unknow',
    }
    this.handleData('2023-10-10', 12);
    this._onViewStateChange = this._onViewStateChange.bind(this);
  }

  componentDidMount() {
    this.handleHeightDay = this.handleHeightDay.bind(this);
    this.handleHeightHour = this.handleHeightHour.bind(this);
  }

  async getOptionsDate(url) {
    const res = await axios.get(url)
    const data = res.data
    const options = data.map(d => ({
      "value": d.toString(),
      "label": d.toString()
    }))
    this.setState({ day_of_week_options: options });
  }

  handleData(date, hour) {
    // this.setState({ loading: true })

    fetch(URL + 'tile_potential/heatmap_counts/' + date + 'T' + String(hour).padStart(2, '0') + ':00:00.geojson')
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          data: json
        });
      })
    // .then(() => { this.setState({ loading: false }) })
  }

  initialview() {
    let coord = [47.45091518257127, 9.373115711313453];
    return {
      latitude: coord[0],
      longitude: coord[1],
      zoom: 12,
      maxZoom: 16,
      pitch: 50,
      bearing: 0,
      viewState: {
        latitude: 47.45091518257127,
        longitude: 9.373115711313453,
        zoom: 12,
        bearing: 0,
        pitch: 50
      }
    };
  }

  handleHeightDay(event) {
    this.setState({ day_week: event });

    fetch(URL + 'tile_potential/heatmap_counts/' + event.value + 'T' + String(this.state.hour).padStart(2, '0') + ':00:00.geojson')
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          data: json
        });
        return json;
      }).then((json) => {
        _renderLayers(json, event.value, this.state.hour)
      }).then(() => {

        let coord = [0, 0];

        let Bern = [46.96297924418938, 7.464304908569138];
        let Zurich = [47.38269244646747, 8.503802570344716];
        let St_Gallen = [47.4338013788637, 9.38559138030376];

        if(event.value.includes('2023-10')){
          coord = St_Gallen;
          this.setState({event_img: 'olma',
          event_name: 'OLMA',
          duration: '13.10.2023 - 22.10.2023',
          num_people: 'Unknow'})
        } else if(event.value.includes('2023-08') || event.value.includes('2023-07') || event.value === '2023-06-29'){
          coord = Zurich;
          if(event.value.includes('2023-07')){
            this.setState({event_img: 'coldplay',
            event_name: 'Coldplay Concert',
            duration: '01.07.2023 & 02.07.2023',
            num_people: '96\'000 attendees'})
          } else if(event.value.includes('2023-08')){
            this.setState({event_img: 'streetparade',
            event_name: 'Zürich\'s Streetparade',
            duration: '08.07.2023',
            num_people: '92\'000 attendees'})
          } 
          if(event.value ==='2023-07-06' || event.value ==='2023-07-07' || event.value ==='2023-07-08') {
            this.setState({event_img: 'zurifast',
            event_name: 'Zürifäst',
            duration: '07.07.2023 - 09.07.2023',
            num_people: '2\'000\'000 attendees'})
          }
          
        } else {
          coord = Bern;
          this.setState({event_img: 'rammstein',
          event_name: 'Rammstein Concert',
          duration: '18.06.2023',
          num_people: '96\'000 attendees'})
        }

        this.setState({
          viewState: {
            ...this.props.viewState,
            longitude: coord[1],
            latitude: coord[0],
            zoom: 11,
            bearing: 0,
            pitch: 50,
            transitionDuration: 2000,
            transitionInterpolator: new FlyToInterpolator()
          }
        });
      })
  }

  handleHeightHour(event) {
    this.setState({ hour: event })

    fetch(URL + 'tile_potential/heatmap_counts/' + this.state.day_week.value + 'T' + String(event).padStart(2, '0') + ':00:00.geojson')
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          data: json
        });
        return json;
      }).then((json) => {
        _renderLayers(json, this.state.day_week.value, event)
      })
  }

  getTooltip({ object }) {
    return (
      object && {
        html: `\
          <div><b>Number of people:</b> ${(object.properties?.counts)}</div>
          `
      }
    );
  }

  updateField(card, value) {
    this.setState({
      ...this.state,
      [card]: value
    });
  }

  _onViewStateChange({ viewState }) {
    this.setState({ viewState });
  }

  render() {
    const { controller = true } = this.props;
    const { viewState } = this.state;
    const { hideNavs } = this.state;
    const { loading } = this.state;

    const selectDate = {
      menuList: styles => {
        return {
          ...styles,
          maxHeight: 150,
          borderWidth: 0,
          boxShadow: '0 0 10px 2px rgb(0 0 0 / 10%)',
        };
      }
    };


    const marks = {
      0: '00:00',
      1: ' ',
      2: '02:00',
      3: ' ',
      4: '04:00',
      5: ' ',
      6: '06:00',
      7: ' ',
      8: '08:00',
      9: ' ',
      10: '10:00',
      11: ' ',
      12: '12:00',
      13: ' ',
      14: '14:00',
      15: ' ',
      16: '16:00',
      17: ' ',
      18: '18:00',
      19: ' ',
      20: '20:00',
      21: ' ',
      22: '22:00',
      23: ' '
    };

    return (
      <div>
        <DeckGL
          layers={_renderLayers(this.state.data, this.state.day_week, this.state.hour)}
          initialViewState={this.initialview()}
          onViewStateChange={this._onViewStateChange}
          viewState={viewState}
          controller={controller}
          getTooltip={this.getTooltip}
        >
          <Map reuseMaps mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN} mapStyle={'mapbox://styles/mapbox/light-v10'} />
          <RecommendationNavBarComponent />
        </DeckGL>
        {loading ?
          <div className="bg-loader">
            <div className="pos-center-brain-loader">
              <div className="circle-loading-brain">
              </div>
              <svg className='brain-loader' x="0px" y="0px" viewBox="0 0 496 496">
                <path d="M40,449.472V364.28L58.424,352H88v-16H53.576L24,355.72v93.752C14.704,452.784,8,461.584,8,472c0,13.232,10.768,24,24,24				s24-10.768,24-24C56,461.584,49.288,452.784,40,449.472z M32,480c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8s8,3.584,8,8				C40,476.416,36.416,480,32,480z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M46.528,320H88v-16H46.528c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24				C34.416,336,43.216,329.288,46.528,320z M16,312c0-4.416,3.584-8,8-8s8,3.584,8,8c0,4.416-3.584,8-8,8S16,316.416,16,312z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M24,272c10.416,0,19.216-6.712,22.528-16H88v-16H46.528c-3.312-9.296-12.112-16-22.528-16c-13.232,0-24,10.768-24,24				S10.768,272,24,272z M24,240c4.416,0,8,3.584,8,8s-3.584,8-8,8s-8-3.584-8-8C16,243.584,19.584,240,24,240z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M56 272h32v16H56Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M56 208h32v16H56Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M0 176h88v16H0Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M60.688,160H88v-16H67.312L48,124.688v-14.16C57.288,107.216,64,98.416,64,88c0-13.232-10.768-24-24-24S16,74.768,16,88				c0,10.416,6.704,19.216,16,22.528v20.784L60.688,160z M40,80c4.416,0,8,3.584,8,8s-3.584,8-8,8s-8-3.584-8-8S35.584,80,40,80z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M144 67.312L144 88L160 88L160 60.688L131.312 32L0 32L0 48L124.688 48z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M176 35.312L176 88L192 88L192 28.688L163.312 0L0 0L0 16L156.688 16z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M208 56h16v32H208Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M304 0h16v88H304Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M240,46.528V88h16V59.312l16,16V88h16V68.688L262.392,43.08C268.192,38.696,272,31.816,272,24c0-13.232-10.768-24-24-24				s-24,10.768-24,24C224,34.416,230.704,43.216,240,46.528z M248,16c4.416,0,8,3.584,8,8s-3.584,8-8,8s-8-3.584-8-8				S243.584,16,248,16z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M352,51.312L371.312,32h78.16c2.416,6.776,7.752,12.112,14.528,14.528v110.16L444.688,176H408v16h43.312L480,163.312				V46.528c9.288-3.312,16-12.112,16-22.528c0-13.232-10.768-24-24-24c-10.416,0-19.216,6.712-22.528,16h-84.784L336,44.688V88h16				V51.312z M472,16c4.416,0,8,3.584,8,8s-3.584,8-8,8c-4.416,0-8-3.584-8-8S467.584,16,472,16z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M414.08,97.392l17.92,17.92v9.376L412.688,144H408v16h11.312L448,131.312v-22.624L419.312,80h-4.784				c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24C401.904,112,410.416,105.968,414.08,97.392z				 M392,96c-4.416,0-8-3.584-8-8s3.584-8,8-8c4.416,0,8,3.584,8,8S396.416,96,392,96z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M408 208h88v16H408Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M480,449.472V268.688L451.312,240H408v16h36.688L464,275.312v41.376L451.312,304H408v16h36.688L464,339.312v110.16				c-9.296,3.312-16,12.112-16,22.528c0,13.232,10.768,24,24,24s24-10.768,24-24C496,461.584,489.288,452.784,480,449.472z M472,480				c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8c4.416,0,8,3.584,8,8C480,476.416,476.416,480,472,480z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M408 272h32v16H408Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M448,364.688L419.312,336H408v16h4.688L432,371.312V416h-80v-8h-16v24h96v12.688L412.688,464h-14.16				c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24c10.416,0,19.216-6.712,22.528-16h20.784				L448,451.312V364.688z M376,480c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8c4.416,0,8,3.584,8,8C384,476.416,380.416,480,376,480z				" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M160,408h-16v8h-41.472c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24				c10.416,0,19.216-6.712,22.528-16H160V408z M80,432c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8s8,3.584,8,8				C88,428.416,84.416,432,80,432z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M304 408h16v88H304Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M272 408h16v88H272Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M240,420.688l-16,16V408h-16v41.472c-9.296,3.312-16,12.112-16,22.528c0,13.232,10.768,24,24,24s24-10.768,24-24				c0-7.816-3.808-14.696-9.608-19.08L256,427.312V408h-16V420.688z M216,480c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8s8,3.584,8,8				C224,476.416,220.416,480,216,480z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M192 408L176 408L176 428.688L152 452.688L152 496L168 496L168 459.312L192 435.312z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M336,392h16v-16h24v-24h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16				v-16h-16v-24h-24v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-3.312				L120,140.688V144h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v24h24v16h16v-16h16				v16h16v-16h16v16h16v-16h16v16h16v-16h16v16h16v-16h16v16h16v-16h16V392z M136,360V147.312L147.312,136H360v224H136z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M318.888,211.048l13.96-13.96l-33.936-33.936l-13.96,13.96c-4.16-2.184-8.488-3.976-12.952-5.384V152h-48v19.728				c-4.472,1.408-8.8,3.2-12.952,5.384l-13.96-13.96l-33.936,33.936l13.96,13.96c-2.176,4.16-3.976,8.488-5.384,12.952H152v48				h19.728c1.408,4.472,3.2,8.8,5.384,12.952l-13.96,13.96l33.936,33.936l13.96-13.96c4.16,2.176,8.48,3.976,12.952,5.384V344h48				v-19.728c4.472-1.408,8.8-3.2,12.952-5.384l13.96,13.96l33.936-33.936l-13.96-13.96c2.176-4.152,3.976-8.48,5.384-12.952H344v-48				h-19.728C322.864,219.528,321.072,215.2,318.888,211.048z M328,256h-16.224l-1.416,6.224c-1.6,7.016-4.368,13.672-8.216,19.792				l-3.408,5.408l11.496,11.496l-11.312,11.312l-11.496-11.496l-5.408,3.408c-6.12,3.848-12.776,6.616-19.792,8.216L256,311.776V328				h-16v-16.224l-6.224-1.416c-7.016-1.6-13.672-4.368-19.792-8.216l-5.408-3.408l-11.496,11.496l-11.312-11.312l11.496-11.496				l-3.408-5.408c-3.848-6.12-6.616-12.776-8.216-19.792L184.224,256H168v-16h16.224l1.416-6.224				c1.6-7.016,4.368-13.672,8.216-19.792l3.408-5.408l-11.496-11.496l11.312-11.312l11.496,11.488l5.408-3.408				c6.12-3.848,12.776-6.616,19.792-8.216l6.224-1.408V168h16v16.224l6.224,1.416c7.016,1.6,13.672,4.368,19.792,8.216l5.408,3.408				l11.496-11.488l11.312,11.312l-11.496,11.496l3.408,5.408c3.848,6.12,6.616,12.776,8.216,19.792l1.416,6.216H328V256z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M248,208c-22.056,0-40,17.944-40,40c0,22.056,17.944,40,40,40c22.056,0,40-17.944,40-40C288,225.944,270.056,208,248,208				z M248,272c-13.232,0-24-10.768-24-24s10.768-24,24-24s24,10.768,24,24S261.232,272,248,272z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>
          </div>
          :
          <div style={{ display: hideNavs ? 'none' : 'block' }}>
            <div className='map-heatmap-3D'>
              <div className='map-heatmap-3D-inner'>
                <h1>Heatmap Distribution</h1>
                <h2>Select a day of the week</h2>
                <Select className='group-class' onChange={this.handleHeightDay.bind(this)} options={this.state.day_of_week_options} styles={selectDate} defaultValue={this.state.day_week} />
              </div>
            </div>
            <div className='map-event-details' style={{ top: '210px' }}>
              <div className='map-event-details-inner'>
              <img src={require('../../assets/img/events/' + this.state.event_img+'.png')} alt="Current Event" className='event-img' width={'90%'}/>
                <h2>Event: {this.state.event_name}</h2>
                <p className="label"><b>Duration:</b> {this.state.duration}</p>
                <p className="label"><b>Number of people:</b> {this.state.num_people}</p>
              </div>
            </div>
            <div className='map-heatmap-3D-bottom' style={{ bottom: '0px', right: '0px'}}>
              <div className='map-heatmap-3D-bottom-inner' style={{ bottom: '0px', right: '0px'}}>
                <h1>Heatmap Distribution</h1>
                <h2>Select an hour of the day</h2>
                <div className='slider-heatmap-3d'>
                  <Slider min={0} max={23} marks={marks} step={null} defaultValue={this.state.hour} onChange={_.debounce(this.handleHeightHour.bind(this), 10)} />
                </div>
              </div>
            </div>
          </div>
        }
        <HideElementsComponent map={this.state.mapita} updateField={(card, field, value) => this.updateField(card, field, value)} />
      </div>
    );
  }
};

export default MapHeatmap3D;
