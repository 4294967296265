
import React from "react";
import { Map } from "react-map-gl";
import DeckGL, { GeoJsonLayer } from "deck.gl";
import { IconContext } from "react-icons";
import axios from 'axios';
import { Form } from 'react-bootstrap';
import RecommendationNavBarComponentBLS from "../../../components/RecommendationNavBarComponentBLS";
import { RiFullscreenFill, RiFullscreenExitFill } from "react-icons/ri";
import { Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Slider from 'rc-slider';
import Select from 'react-select';
import { useParams } from "react-router-dom";

import '../../css/map-recommendation-deckgl.css';

const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const URL = process.env.REACT_APP_URL_SERVER_BACK;
const URL_FRONT = process.env.REACT_APP_URL_SERVER_FRONT;

function numberWithCommas(x) {
  if (typeof x !== "undefined") {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
  } else {
    return 0
  }
}

const _ = require('lodash');

let cat_building = 'all';
let height_length = 'absolute';

let color = true;
let color_bike = false;
let color_working = false;
let color_last_mile = false;
let color_car_pooling = false;

function getColors(f) {
  let color = '#ffffff'

  if (f === 'behavior') { color = '#390099'; }
  else if (f === 'bike') { color = '#9E0059'; }
  else if (f === 'last_mile') { color = '#FF0054'; }
  else if (f === 'working') { color = '#FF5400'; }
  else if (f === 'car_pooling') { color = '#1AB5B2'; }
  else if (f === 'radius') { color = '#9900ff'; }

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
  return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : [0, 0, 0];
}

function elevation(f, type, height_based, height_length, color, color_bike, color_last_mile, color_working, color_car_pooling, biggest_employee, agg_traffic) {
  let height = 0;
  if ((type === 'behavior' && color)) {
    height = f.properties[height_length + agg_traffic] * f.properties.heights['behavior' + agg_traffic]
  }
  else if (type === 'last_mile' && color_last_mile) {
    if (color) {
      height += f.properties[height_length + agg_traffic] * f.properties.heights['behavior' + agg_traffic]
    }
    height += f.properties[height_length + agg_traffic] * f.properties.heights['last_mile' + agg_traffic]
  }
  else if (type === 'bike' && color_bike) {
    if (color) {
      height += f.properties[height_length + agg_traffic] * f.properties.heights['behavior' + agg_traffic]
    }
    if (color_last_mile) {
      height += f.properties[height_length + agg_traffic] * f.properties.heights['last_mile' + agg_traffic]
    }
    height += f.properties[height_length + agg_traffic] * f.properties.heights['bike' + agg_traffic]
  }
  else if (type === 'working' && color_working) {
    if (color) {
      height += f.properties[height_length + agg_traffic] * f.properties.heights['behavior' + agg_traffic]
    }
    if (color_last_mile) {
      height += f.properties[height_length + agg_traffic] * f.properties.heights['last_mile' + agg_traffic]
    }
    if (color_bike) {
      height += f.properties[height_length + agg_traffic] * f.properties.heights['bike' + agg_traffic]
    }
    height += f.properties[height_length + agg_traffic] * f.properties.heights['working' + agg_traffic]
  }
  else if (type === 'car_pooling' && color_car_pooling) {
    if (color) {
      height += f.properties[height_length + agg_traffic] * f.properties.heights['behavior' + agg_traffic]
    }
    if (color_last_mile) {
      height += f.properties[height_length + agg_traffic] * f.properties.heights['last_mile' + agg_traffic]
    }
    if (color_bike) {
      height += f.properties[height_length + agg_traffic] * f.properties.heights['bike' + agg_traffic]
    }
    if (color_working) {
      height += f.properties[height_length + agg_traffic] * f.properties.heights['working' + agg_traffic]
    }
    height += f.properties[height_length + agg_traffic] * f.properties.heights['car_pooling' + agg_traffic]
  }

  if (height_length === 'potential') {
    height = height * 10000;
  }

  if (biggest_employee.includes('sum_') && height_length === 'absolute_') {
    height = height * 5;
  }

  if (height_based === 'pkm' && height_length === 'absolute_') {
    height = height / 5;
  }

  return height;
}

const empty_geojson = {
  'type': 'FeatureCollection',
  'features': [
    {
      'type': 'Feature',
      'geometry': {
        "type": "LineString",
        "coordinates": [
        ]
      }
    }
  ]
};

class HideElementsComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hideNavs: false,
      mapa: this.props.map,
    }
  }

  hideComponent() {
    let newHideNavs = !this.state.hideNavs;
    this.setState({ hideNavs: newHideNavs })
    this.props.updateField("hideNavs", newHideNavs);
  }

  render() {

    const { hideNavs } = this.state;

    return (
      <div>
        {hideNavs ?
          <div className='map-close-navs'>
            <div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
              <IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
                <div>
                  <RiFullscreenExitFill />
                </div>
              </IconContext.Provider>
            </div>
          </div> :
          <div className='map-close-navs'>
            <div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
              <IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
                <div>
                  <RiFullscreenFill />
                </div>
              </IconContext.Provider>
            </div>
          </div>
        }
      </div>
    )
  }
}


class MapPotential_3D_BLS extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      hideNavs: false,
      color: true,
      color_last_mile: false,
      color_working: false,
      color_bike: false,
      color_car_pooling: false,
      tileId: '',
      loading: true,
      current_pkm: 0,
      total_pkm: 0,
      percentage_pkm: 0,
      current_trips: 0,
      total_trips: 0,
      percentage_trips: 0,
      data_stations: [],
      height_based: 'trips',
      height_length: 'absolute',
      agg_traffic: 'both',
      id_station: 0,
      data_radius: [],
      behavior_pannel: false,
      bike_pannel: false,
      last_mile_pannel: false,
      working_pannel: false,
      car_pooling_pannel: false,
      employees_num: 0,
      num_biggest_employee: 0,
      biggest_employee: 'sum_0',
      valueTopEmployees: 60000,
      valueMinEmployees: 0,
      min_distance_train_station: 1000,
      max_distance_train_station: 35000,

      all_info_total_vs_filter_3d: {},
      potential_pct_total_pkm: 0,
      potential_pct_total_trips: 0,
      potential_total_pkm: 0,
      potential_total_trips: 0,
      total_employees: 0,
      num_hotspots: 0,
      kantons_: this.getOptionsKantons(URL + 'what_if/kanton_list'),
      current_value_k: null,
      current_employees_pkm: 0,
      current_employees_trips: 0,
      current_category_pkm: 0,
      current_category_trips: 0,
      total_companies: 0,

      categories_: this.getCategories(URL + 'tile_potential_employer/categories'),
      current_value_category: {
        label: 'All',
        value: 'all'
      },

      disable_behaviour: false,
      disable_bike: false,
      disable_last_mile: false,
      disable_working_time: false,
      disable_car_pooling: false,
      loading_potentials: true,

      params: this.props.params,
      data: this.handleData(),
    }
  }

  _renderLayers(data, height_based, height_length, color, color_bike, color_working, color_last_mile, color_car_pooling, biggest_employee, employees_num, category, agg_traffic) {
    const layer = [
      new GeoJsonLayer({
        id: height_based + height_length + 'car_pooling' + color + color_bike + color_working + color_last_mile + color_car_pooling + biggest_employee + employees_num + agg_traffic,
        data: data,
        opacity: 0.8,
        stroked: true,
        filled: true,
        extruded: true,
        wireframe: true,
        getElevation: f => elevation(f, 'car_pooling', height_based, height_length, color, color_bike, color_last_mile, color_working, color_car_pooling, biggest_employee + '_' + category, (agg_traffic === 'both' ? '' : '_' + agg_traffic)),
        getFillColor: getColors('car_pooling'),
        getLineColor: getColors('car_pooling'),
        getLineWidth: 100,
        pickable: true,
      }),

      new GeoJsonLayer({
        id: height_based + height_length + 'working' + color + color_bike + color_working + color_last_mile + color_car_pooling + biggest_employee + employees_num + agg_traffic,
        data: data,
        opacity: 0.8,
        stroked: true,
        filled: true,
        extruded: true,
        wireframe: true,
        getElevation: f => elevation(f, 'working', height_based, height_length, color, color_bike, color_last_mile, color_working, color_car_pooling, biggest_employee + '_' + category, (agg_traffic === 'both' ? '' : '_' + agg_traffic)),
        getFillColor: getColors('working'),
        getLineColor: getColors('working'),
        getLineWidth: 100,
        pickable: true,
      }),

      new GeoJsonLayer({
        id: height_based + height_length + 'bike' + color + color_bike + color_working + color_last_mile + color_car_pooling + biggest_employee + employees_num + agg_traffic,
        data: data,
        opacity: 0.8,
        stroked: true,
        filled: true,
        extruded: true,
        wireframe: true,
        getElevation: f => elevation(f, 'bike', height_based, height_length, color, color_bike, color_last_mile, color_working, color_car_pooling, biggest_employee + '_' + category, (agg_traffic === 'both' ? '' : '_' + agg_traffic)),
        getFillColor: getColors('bike'),
        getLineColor: getColors('bike'),
        getLineWidth: 100,
        pickable: true,
      }),

      new GeoJsonLayer({
        id: height_based + height_length + 'last_mile' + color + color_bike + color_working + color_last_mile + color_car_pooling + biggest_employee + employees_num + agg_traffic,
        data: data,
        opacity: 0.8,
        stroked: true,
        filled: true,
        extruded: true,
        wireframe: true,
        getElevation: f => elevation(f, 'last_mile', height_based, height_length, color, color_bike, color_last_mile, color_working, color_car_pooling, biggest_employee + '_' + category, (agg_traffic === 'both' ? '' : '_' + agg_traffic)),
        getFillColor: getColors('last_mile'),
        getLineColor: getColors('last_mile'),
        getLineWidth: 100,
        pickable: true,
      }),

      new GeoJsonLayer({
        id: height_based + height_length + 'behavior' + color + color_bike + color_working + color_last_mile + color_car_pooling + biggest_employee + employees_num + agg_traffic,
        data: data,
        opacity: 0.8,
        stroked: true,
        filled: true,
        extruded: true,
        wireframe: true,
        getElevation: f => elevation(f, 'behavior', height_based, height_length, color, color_bike, color_last_mile, color_working, color_car_pooling, biggest_employee + '_' + category, (agg_traffic === 'both' ? '' : '_' + agg_traffic)),
        getFillColor: getColors('behavior'),
        getLineColor: getColors('behavior'),
        getLineWidth: 100,
        pickable: true,
      }),
    ];
    return layer;
  }

  async getOptionsKantons(url) {
    const res = await axios.get(url)
    const data = res.data
    const options = data.map(d => ({
      "value": d._id,
      "label": d._id
    }))
    this.setState({ kantons_: options });


    if (this.props.params['*'] !== '') {
      let params = this.props.params['*'].split(',')
      let kanton_value = undefined;
      if (params[9] !== 'no_kanton') {
        for (let dict of options) {
          if (dict.value === params[9]) {
            kanton_value = dict;
          }
        }
      }
      else {
        kanton_value = null;
      }

      this.setState({
        current_value_k: kanton_value,
      })
    }

  }

  async getCategories(url) {
    const res = await axios.get(url)
    const data = res.data

    if (this.props.params['*'] !== '') {
      let params = this.props.params['*'].split(',')
      let category_value = undefined;
      for (let dict of data) {
        if (dict.value === params[10]) {
          category_value = dict;
        }
      }

      this.setState({
        current_value_category: category_value,
      })

      this.setState({ categories_: data });
    }
  }

  componentDidMount() {
    this.handleHeight = this.handleHeight.bind(this);
  }

  handlePotential(event) {

    let temp = false;
    let options = []

    if (event.target.attributes.getNamedItem('id').value === 'color') {
      temp = !this.state.color;
      this.setState({ color: temp })

      if (temp) { options.push('color') }
      if (this.state.color_bike) { options.push('color_bike') }
      if (this.state.color_last_mile) { options.push('color_last_mile') }
      if (this.state.color_working) { options.push('color_working') }
      if (this.state.color_car_pooling) { options.push('color_car_pooling') }
    }
    else if (event.target.attributes.getNamedItem('id').value === 'color_bike') {
      temp = !this.state.color_bike;
      this.setState({ color_bike: temp })

      if (temp) { options.push('color_bike') }
      if (this.state.color) { options.push('color') }
      if (this.state.color_last_mile) { options.push('color_last_mile') }
      if (this.state.color_working) { options.push('color_working') }
      if (this.state.color_car_pooling) { options.push('color_car_pooling') }
    }
    else if (event.target.attributes.getNamedItem('id').value === 'color_working') {
      temp = !this.state.color_working;
      this.setState({ color_working: temp })

      if (temp) { options.push('color_working') }
      if (this.state.color) { options.push('color') }
      if (this.state.color_last_mile) { options.push('color_last_mile') }
      if (this.state.color_bike) { options.push('color_bike') }
      if (this.state.color_car_pooling) { options.push('color_car_pooling') }
    }
    else if (event.target.attributes.getNamedItem('id').value === 'color_car_pooling') {
      temp = !this.state.color_car_pooling;
      this.setState({ color_car_pooling: temp })

      if (temp) { options.push('color_car_pooling') }
      if (this.state.color) { options.push('color') }
      if (this.state.color_last_mile) { options.push('color_last_mile') }
      if (this.state.color_bike) { options.push('color_bike') }
      if (this.state.color_working) { options.push('color_working') }
    }
    else if (event.target.attributes.getNamedItem('id').value === 'color_last_mile') {
      temp = !this.state.color_last_mile;
      this.setState({ color_last_mile: temp })

      if (temp) { options.push('color_last_mile') }
      if (this.state.color) { options.push('color') }
      if (this.state.color_bike) { options.push('color_bike') }
      if (this.state.color_working) { options.push('color_working') }
      if (this.state.color_car_pooling) { options.push('color_car_pooling') }
    }

    color = options.includes('color');
    color_bike = options.includes('color_bike');
    color_last_mile = options.includes('color_last_mile');
    color_working = options.includes('color_working');
    color_car_pooling = options.includes('color_car_pooling');

    let activated_categories = ''

    if (color) {
      activated_categories += 'behavior'
    }

    if (color_last_mile) {
      if (activated_categories !== '') {
        activated_categories += ','
      }
      activated_categories += 'last_mile'
    }

    if (color_bike) {
      if (activated_categories !== '') {
        activated_categories += ','
      }
      activated_categories += 'bike'
    }

    if (color_working) {
      if (activated_categories !== '') {
        activated_categories += ','
      }
      activated_categories += 'working'
    }

    if (color_car_pooling) {
      if (activated_categories !== '') {
        activated_categories += ','
      }
      activated_categories += 'car_pooling'
    }

    this.setState({ loading_potentials: true, loading: true });

    fetch(URL + 'tile_potential_employer_optimized/' + this.state.height_based + '/_' + this.state.agg_traffic + '/' + this.state.biggest_employee + '_' + this.state.current_value_category.value + '/' + this.state.employees_num + '/' + this.state.min_distance_train_station + ',' + this.state.max_distance_train_station + '/' + (this.state.current_value_k === null ? 'all' : this.state.current_value_k.value) + '/' + activated_categories + '/potentials.geojson', {
      timeoutDuration: 1200000
    }).then((res) => res.json())
      .then((json) => {
        this.setState({ data: json, loading: false });
        return json;
      }).then((data) => {
        this._renderLayers(data, this.state.height_based, this.state.height_length, color, color_bike, color_working, color_last_mile, color_car_pooling, !this.state.biggest_employee, this.state.employees_num, this.state.current_value_category.value, this.state.agg_traffic)
      }).then(() => {
        fetch(URL + 'tile_potential_employer/0/' + this.state.biggest_employee + '_' + this.state.current_value_category.value + '/' + this.state.height_based + '/' + this.state.employees_num + '/' + this.state.min_distance_train_station + ',' + this.state.max_distance_train_station + '/' + activated_categories + '/' + (this.state.current_value_k === null ? 'all' : this.state.current_value_k.value) + '/total_vs_filter_3d_optimized')
          .then((res) => res.json())
          .then((json) => {
            this.setState({
              all_info_total_vs_filter_3d: json,

              current_pkm: json.current_pkm.toFixed(0),
              total_pkm: json.total_pkm.toFixed(0),
              percentage_pkm: (json.current_pkm / json.total_pkm * 100).toFixed(0),

              current_trips: json.current_trips.toFixed(0),
              total_trips: json.total_trips.toFixed(0),
              percentage_trips: (json.current_trips / json.total_trips * 100).toFixed(0),

              potential_pct_total_pkm: json.current_behavior_pkm / json.total_pkm * 100,
              potential_pct_total_trips: json.current_behavior_trips / json.total_trips * 100,
              potential_total_pkm: json.current_behavior_pkm,
              potential_total_trips: json.current_behavior_trips,
              total_employees: json.total_employees,
              num_hotspots: json.total_tiles,
              current_employees_pkm: (json.current_employees_pkm / json.total_pkm * 100).toFixed(1),
              current_employees_trips: (json.current_employees_trips / json.total_trips * 100).toFixed(1),
              current_category_pkm: (json.current_category_pkm / json.total_pkm * 100).toFixed(1),
              current_category_trips: (json.current_category_trips / json.total_trips * 100).toFixed(1),
              total_companies: json.total_companies.toFixed(0),
              total_car_trains_pkm: json.total_car_trains_pkm,
              total_car_trains_trips: json.total_car_trains_trips,
            });

            let temp_potential_total_pkm = 0;
            let temp_potential_total_trips = 0;

            if (color) {
              temp_potential_total_pkm += json['current_behavior_pkm' + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)];
              temp_potential_total_trips += json['current_behavior_trips' + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)];
            }

            if (color_bike) {
              temp_potential_total_pkm += json['current_bike_pkm' + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)];
              temp_potential_total_trips += json['current_bike_trips' + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)];
            }

            if (color_last_mile) {
              temp_potential_total_pkm += json['current_last_mile_pkm' + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)];
              temp_potential_total_trips += json['current_last_mile_trips' + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)];
            }

            if (color_working) {
              temp_potential_total_pkm += json['current_working_pkm' + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)];
              temp_potential_total_trips += json['current_working_trips' + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)];
            }

            if (color_car_pooling) {
              temp_potential_total_pkm += json['current_car_pooling_pkm' + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)];
              temp_potential_total_trips += json['current_car_pooling_trips' + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)];
            }

            this.setState({
              potential_total_pkm: temp_potential_total_pkm, potential_total_trips: temp_potential_total_trips,
              potential_pct_total_pkm: (temp_potential_total_pkm / this.state.total_pkm * 100), potential_pct_total_trips: (temp_potential_total_trips / this.state.total_trips * 100),
              loading_potentials: false
            });

          });
      })

  }

  handleData() {
    this.setState({ loading: true, loading_potentials: true })
    let data = [];
    let params = this.props.params['*'].split(',')

    if (this.props.params['*'] !== '') {
      cat_building = params[10];

      color = (params[0] === 'true' ? true : false);
      color_bike = (params[1] === 'true' ? true : false);
      color_working = (params[3] === 'true' ? true : false);
      color_last_mile = (params[2] === 'true' ? true : false);
      color_car_pooling = (params[4] === 'true' ? true : false);

      let activated_categories = ''
      if (params[0] === 'true') {
        activated_categories += 'behavior'
      }
      if (params[2] === 'true') {
        if (activated_categories !== '') {
          activated_categories += ','
        }
        activated_categories += 'last_mile'
      }
      if (params[1] === 'true') {
        if (activated_categories !== '') {
          activated_categories += ','
        }
        activated_categories += 'bike'
      }
      if (params[3] === 'true') {
        if (activated_categories !== '') {
          activated_categories += ','
        }
        activated_categories += 'working'
      }
      if (params[4] === 'true') {
        if (activated_categories !== '') {
          activated_categories += ','
        }
        activated_categories += 'car_pooling'
      }
      fetch(URL + 'tile_potential_employer/0/' + params[11] + '_' + params[10] + '/' + params[8] + '/' + params[12] + '/' + params[5] + ',' + params[6] + '/' + activated_categories + '/' + (params[9] === 'no_kanton' ? 'all' : params[9]) + '/total_vs_filter_3d_optimized')
        .then((res) => res.json())
        .then((json) => {
          this.setState({
            all_info_total_vs_filter_3d: json,

            current_pkm: json.current_pkm.toFixed(0),
            total_pkm: json.total_pkm.toFixed(0),
            percentage_pkm: (json.current_pkm / json.total_pkm * 100).toFixed(0),

            current_trips: json.current_trips.toFixed(0),
            total_trips: json.total_trips.toFixed(0),
            percentage_trips: (json.current_trips / json.total_trips * 100).toFixed(0),

            potential_pct_total_pkm: json.current_behavior_pkm / json.total_pkm * 100,
            potential_pct_total_trips: json.current_behavior_trips / json.total_trips * 100,
            potential_total_pkm: json.current_behavior_pkm,
            potential_total_trips: json.current_behavior_trips,
            total_employees: json.total_employees,
            num_hotspots: json.total_tiles,
            current_employees_pkm: (json.current_employees_pkm / json.total_pkm * 100).toFixed(1),
            current_employees_trips: (json.current_employees_trips / json.total_trips * 100).toFixed(1),
            current_category_pkm: (json.current_category_pkm / json.total_pkm * 100).toFixed(1),
            current_category_trips: (json.current_category_trips / json.total_trips * 100).toFixed(1),
            total_companies: json.total_companies.toFixed(0),
            total_car_trains_pkm: json.total_car_trains_pkm,
            total_car_trains_trips: json.total_car_trains_trips,
          });

          let temp_potential_total_pkm = 0;
          let temp_potential_total_trips = 0;

          if (color) {
            temp_potential_total_pkm += json['current_behavior_pkm' + (params[7] === 'both' ? '' : '_' + params[7])];
            temp_potential_total_trips += json['current_behavior_trips' + (params[7] === 'both' ? '' : '_' + params[7])];
          }

          if (color_bike) {
            temp_potential_total_pkm += json['current_bike_pkm' + (params[7] === 'both' ? '' : '_' + params[7])];
            temp_potential_total_trips += json['current_bike_trips' + (params[7] === 'both' ? '' : '_' + params[7])];
          }

          if (color_last_mile) {
            temp_potential_total_pkm += json['current_last_mile_pkm' + (params[7] === 'both' ? '' : '_' + params[7])];
            temp_potential_total_trips += json['current_last_mile_trips' + (params[7] === 'both' ? '' : '_' + params[7])];
          }

          if (color_working) {
            temp_potential_total_pkm += json['current_working_pkm' + (params[7] === 'both' ? '' : '_' + params[7])];
            temp_potential_total_trips += json['current_working_trips' + (params[7] === 'both' ? '' : '_' + params[7])];
          }

          if (color_car_pooling) {
            temp_potential_total_pkm += json['current_car_pooling_pkm' + (params[7] === 'both' ? '' : '_' + params[7])];
            temp_potential_total_trips += json['current_car_pooling_trips' + (params[7] === 'both' ? '' : '_' + params[7])];
          }

          this.setState({
            potential_total_pkm: temp_potential_total_pkm, potential_total_trips: temp_potential_total_trips,
            potential_pct_total_pkm: (temp_potential_total_pkm / this.state.total_pkm * 100), potential_pct_total_trips: (temp_potential_total_trips / this.state.total_trips * 100),
          });
        }).then(() => {
          fetch(URL + 'tile_potential_employer_optimized/' + params[8] + '/_' + params[7] + '/' + params[11] + '_' + params[10] + '/' + params[12] + '/' + params[5] + ',' + params[6] + '/' + (params[9] === 'no_kanton' ? 'all' : params[9]) + '/' + activated_categories + '/potentials.geojson')
            .then((res) => res.json())
            .then((json) => {
              data = json;

              let current_num_biggest_employee = 0;

              if (params[11] === 'max') {
                current_num_biggest_employee = 3500;
              }
              else {
                if (params[11] === 'sum_200') {
                  current_num_biggest_employee = 250;
                } else if (params[11] === 'sum_300') {
                  current_num_biggest_employee = 400;
                } else if (params[11] === 'sum_500') {
                  current_num_biggest_employee = 550;
                } else {
                  current_num_biggest_employee = Number(params[11].split('_'))
                }
              }

              this.setState({
                color: (params[0] === 'true' ? true : false),
                color_last_mile: (params[2] === 'true' ? true : false),
                color_working: (params[3] === 'true' ? true : false),
                color_bike: (params[1] === 'true' ? true : false),
                color_car_pooling: (params[4] === 'true' ? true : false),

                min_distance_train_station: Number(params[5]),
                max_distance_train_station: Number(params[6]),
                agg_traffic: params[7],
                height_based: params[8],

                biggest_employee: params[11],
                num_biggest_employee: current_num_biggest_employee,
                employees_num: params[12]
              })
              this.setState({ data: json, loading_potentials: false, loading: false });
            })
        }).then(() => {
          this._renderLayers(data, params[8], this.state.height_length, (params[0] === 'true'), (params[1] === 'true'), (params[3] === 'true'), (params[2] === 'true'), (params[4] === 'true'), this.state.biggest_employee, this.state.employees_num, (params[9] !== 'no_kanton' ? params[9] : null), params[7])
        })
    }
    else {
      fetch(URL + 'tile_potential_employer/0/sum_0_all/trips/0/1000,35000/behavior/all/total_vs_filter_3d_optimized')
        .then((res) => res.json())
        .then((json) => {
          this.setState({
            all_info_total_vs_filter_3d: json,

            current_pkm: json.current_pkm.toFixed(0),
            total_pkm: json.total_pkm.toFixed(0),
            percentage_pkm: (json.current_pkm / json.total_pkm * 100).toFixed(0),

            current_trips: json.current_trips.toFixed(0),
            total_trips: json.total_trips.toFixed(0),
            percentage_trips: (json.current_trips / json.total_trips * 100).toFixed(0),

            potential_pct_total_pkm: json.current_behavior_pkm / json.total_pkm * 100,
            potential_pct_total_trips: json.current_behavior_trips / json.total_trips * 100,
            potential_total_pkm: json.current_behavior_pkm,
            potential_total_trips: json.current_behavior_trips,
            total_employees: json.total_employees,
            num_hotspots: json.total_tiles,
            current_employees_pkm: (json.current_employees_pkm / json.total_pkm * 100).toFixed(1),
            current_employees_trips: (json.current_employees_trips / json.total_trips * 100).toFixed(1),
            current_category_pkm: (json.current_category_pkm / json.total_pkm * 100).toFixed(1),
            current_category_trips: (json.current_category_trips / json.total_trips * 100).toFixed(1),
            total_companies: json.total_companies.toFixed(0),
            total_car_trains_pkm: json.total_car_trains_pkm,
            total_car_trains_trips: json.total_car_trains_trips,
          });

          let temp_potential_total_pkm = 0;
          let temp_potential_total_trips = 0;

          if (color) {
            temp_potential_total_pkm += json['current_behavior_pkm' + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)];
            temp_potential_total_trips += json['current_behavior_trips' + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)];
          }

          if (color_bike) {
            temp_potential_total_pkm += json['current_bike_pkm' + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)];
            temp_potential_total_trips += json['current_bike_trips' + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)];
          }

          if (color_last_mile) {
            temp_potential_total_pkm += json['current_last_mile_pkm' + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)];
            temp_potential_total_trips += json['current_last_mile_trips' + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)];
          }

          if (color_working) {
            temp_potential_total_pkm += json['current_working_pkm' + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)];
            temp_potential_total_trips += json['current_working_trips' + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)];
          }

          if (color_car_pooling) {
            temp_potential_total_pkm += json['current_car_pooling_pkm' + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)];
            temp_potential_total_trips += json['current_car_pooling_trips' + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)];
          }

          this.setState({
            potential_total_pkm: temp_potential_total_pkm, potential_total_trips: temp_potential_total_trips,
            potential_pct_total_pkm: (temp_potential_total_pkm / this.state.total_pkm * 100), potential_pct_total_trips: (temp_potential_total_trips / this.state.total_trips * 100),
          });
        }).then(() => {
          fetch(URL + 'tile_potential_employer_optimized/trips/_both/sum_0_all/0/1000,35000/all/behavior/potentials.geojson')
            .then((res) => res.json())
            .then((json) => {
              data = json;
              this.setState({ data: json, loading_potentials: false, loading: false });
            })
        })
    }

    return data;

  }

  getTooltipClick(elem) {
    if (typeof (elem.object?.properties) !== 'undefined') {
      window.open(URL_FRONT + 'potential-tile-employer/' + elem.object.properties.tileId + ',' + this.state.biggest_employee + '_' + this.state.current_value_category.value + ',' + this.state.employees_num + ',' + this.state.min_distance_train_station + ',' + this.state.max_distance_train_station + ',' + (this.state.current_value_k === null ? 'all' : this.state.current_value_k.value), "_blank", "noreferrer");
    }
  }

  initialview() {
    let lng = 8.28429379242023;
    let lat = 46.97463784853218;

    return {
      latitude: lat,
      longitude: lng,
      zoom: 7.5,
      maxZoom: 16,
      pitch: 50,
      bearing: 0
    };

  }

  handleMouseOutB() {
    this.setState({ behavior_pannel: false })
  }

  handleMouseOverB() {
    this.setState({ behavior_pannel: true })
  }

  handleMouseOutW() {
    this.setState({ working_pannel: false })
  }

  handleMouseOverW() {
    this.setState({ working_pannel: true })
  }

  handleMouseOutC() {
    this.setState({ car_pooling_pannel: false })
  }

  handleMouseOverC() {
    this.setState({ car_pooling_pannel: true })
  }

  handleMouseOutBi() {
    this.setState({ bike_pannel: false })
  }

  handleMouseOverBi() {
    this.setState({ bike_pannel: true })
  }

  handleMouseOutL() {
    this.setState({ last_mile_pannel: false })
  }

  handleMouseOverL() {
    this.setState({ last_mile_pannel: true })
  }

  handleHeight(event) {
    this.setState({ height_based: event.target.attributes.getNamedItem('id').value });

    let activated_categories = ''

    if (this.state.color) {
      activated_categories += 'behavior'
    }

    if (this.state.color_last_mile) {
      if (activated_categories !== '') {
        activated_categories += ','
      }
      activated_categories += 'last_mile'
    }

    if (this.state.color_bike) {
      if (activated_categories !== '') {
        activated_categories += ','
      }
      activated_categories += 'bike'
    }

    if (this.state.color_working) {
      if (activated_categories !== '') {
        activated_categories += ','
      }
      activated_categories += 'working'
    }

    if (this.state.color_car_pooling) {
      if (activated_categories !== '') {
        activated_categories += ','
      }
      activated_categories += 'car_pooling'
    }

    fetch(URL + 'tile_potential_employer_optimized/' + event.target.attributes.getNamedItem('id').value + '/_' + this.state.agg_traffic + '/' + this.state.biggest_employee + '_' + this.state.current_value_category.value + '/' + this.state.employees_num + '/' + this.state.min_distance_train_station + ',' + this.state.max_distance_train_station + '/' + (this.state.current_value_k === null ? 'all' : this.state.current_value_k.value) + '/' + activated_categories + '/potentials.geojson', {
      timeoutDuration: 1200000
    }).then((res) => res.json())
      .then((json) => {
        this.setState({ data: json });
        return json;
      }).then((data) => {
        this._renderLayers(data, event.target.attributes.getNamedItem('id').value, this.state.height_length, this.state.color, this.state.color_bike, this.state.color_working, this.state.color_last_mile, this.state.color_car_pooling, !this.state.biggest_employee, this.state.employees_num, this.state.current_value_category.value, this.state.agg_traffic)
      }).then(() => {
        fetch(URL + 'tile_potential_employer/0/' + this.state.biggest_employee + '_' + this.state.current_value_category.value + '/' + this.state.height_based + '/' + this.state.employees_num + '/' + this.state.min_distance_train_station + ',' + this.state.max_distance_train_station + '/' + activated_categories + '/' + (this.state.current_value_k === null ? 'all' : this.state.current_value_k.value) + '/total_vs_filter_3d_optimized')
          .then((res) => res.json())
          .then((json) => {
            this.setState({
              all_info_total_vs_filter_3d: json,

              current_pkm: json.current_pkm.toFixed(0),
              total_pkm: json.total_pkm.toFixed(0),
              percentage_pkm: (json.current_pkm / json.total_pkm * 100).toFixed(0),

              current_trips: json.current_trips.toFixed(0),
              total_trips: json.total_trips.toFixed(0),
              percentage_trips: (json.current_trips / json.total_trips * 100).toFixed(0),

              potential_pct_total_pkm: json.current_behavior_pkm / json.total_pkm * 100,
              potential_pct_total_trips: json.current_behavior_trips / json.total_trips * 100,
              potential_total_pkm: json.current_behavior_pkm,
              potential_total_trips: json.current_behavior_trips,
              total_employees: json.total_employees,
              num_hotspots: json.total_tiles,
              current_employees_pkm: (json.current_employees_pkm / json.total_pkm * 100).toFixed(1),
              current_employees_trips: (json.current_employees_trips / json.total_trips * 100).toFixed(1),
              current_category_pkm: (json.current_category_pkm / json.total_pkm * 100).toFixed(1),
              current_category_trips: (json.current_category_trips / json.total_trips * 100).toFixed(1),
              total_companies: json.total_companies.toFixed(0),
              total_car_trains_pkm: json.total_car_trains_pkm,
              total_car_trains_trips: json.total_car_trains_trips,
            });

            let temp_potential_total_pkm = 0;
            let temp_potential_total_trips = 0;

            if (color) {
              temp_potential_total_pkm += json['current_behavior_pkm' + (event.target.attributes.getNamedItem('id').value === 'both' ? '' : '_' + event.target.attributes.getNamedItem('id').value)];
              temp_potential_total_trips += json['current_behavior_trips' + (event.target.attributes.getNamedItem('id').value === 'both' ? '' : '_' + event.target.attributes.getNamedItem('id').value)];
            }

            if (color_bike) {
              temp_potential_total_pkm += json['current_bike_pkm' + (event.target.attributes.getNamedItem('id').value === 'both' ? '' : '_' + event.target.attributes.getNamedItem('id').value)];
              temp_potential_total_trips += json['current_bike_trips' + (event.target.attributes.getNamedItem('id').value === 'both' ? '' : '_' + event.target.attributes.getNamedItem('id').value)];
            }

            if (color_last_mile) {
              temp_potential_total_pkm += json['current_last_mile_pkm' + (event.target.attributes.getNamedItem('id').value === 'both' ? '' : '_' + event.target.attributes.getNamedItem('id').value)];
              temp_potential_total_trips += json['current_last_mile_trips' + (event.target.attributes.getNamedItem('id').value === 'both' ? '' : '_' + event.target.attributes.getNamedItem('id').value)];
            }

            if (color_working) {
              temp_potential_total_pkm += json['current_working_pkm' + (event.target.attributes.getNamedItem('id').value === 'both' ? '' : '_' + event.target.attributes.getNamedItem('id').value)];
              temp_potential_total_trips += json['current_working_trips' + (event.target.attributes.getNamedItem('id').value === 'both' ? '' : '_' + event.target.attributes.getNamedItem('id').value)];
            }

            if (color_car_pooling) {
              temp_potential_total_pkm += json['current_car_pooling_pkm' + (event.target.attributes.getNamedItem('id').value === 'both' ? '' : '_' + event.target.attributes.getNamedItem('id').value)];
              temp_potential_total_trips += json['current_car_pooling_trips' + (event.target.attributes.getNamedItem('id').value === 'both' ? '' : '_' + event.target.attributes.getNamedItem('id').value)];
            }

            this.setState({
              potential_total_pkm: temp_potential_total_pkm, potential_total_trips: temp_potential_total_trips,
              potential_pct_total_pkm: (temp_potential_total_pkm / this.state.total_pkm * 100), potential_pct_total_trips: (temp_potential_total_trips / this.state.total_trips * 100),
              loading: false
            });

          }).then(() => {

            this.setState({ loading_potentials: false });
          })
      })

  }

  handleAggTraffic(event) {
    this.setState({ agg_traffic: event.target.attributes.getNamedItem('id').value, loading_potentials: true });

    let activated_categories = ''

    if (this.state.color) {
      activated_categories += 'behavior'
    }

    if (this.state.color_last_mile) {
      if (activated_categories !== '') {
        activated_categories += ','
      }
      activated_categories += 'last_mile'
    }

    if (this.state.color_bike) {
      if (activated_categories !== '') {
        activated_categories += ','
      }
      activated_categories += 'bike'
    }

    if (this.state.color_working) {
      if (activated_categories !== '') {
        activated_categories += ','
      }
      activated_categories += 'working'
    }

    if (this.state.color_car_pooling) {
      if (activated_categories !== '') {
        activated_categories += ','
      }
      activated_categories += 'car_pooling'
    }

    fetch(URL + 'tile_potential_employer/0/' + this.state.biggest_employee + '_' + this.state.current_value_category.value + '/' + this.state.height_based + '/' + this.state.employees_num + '/' + this.state.min_distance_train_station + ',' + this.state.max_distance_train_station + '/' + activated_categories + '/' + (this.state.current_value_k === null ? 'all' : this.state.current_value_k.value) + '/total_vs_filter_3d_optimized')
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          all_info_total_vs_filter_3d: json,

          current_pkm: json.current_pkm.toFixed(0),
          total_pkm: json.total_pkm.toFixed(0),
          percentage_pkm: (json.current_pkm / json.total_pkm * 100).toFixed(0),

          current_trips: json.current_trips.toFixed(0),
          total_trips: json.total_trips.toFixed(0),
          percentage_trips: (json.current_trips / json.total_trips * 100).toFixed(0),

          potential_pct_total_pkm: json.current_behavior_pkm / json.total_pkm * 100,
          potential_pct_total_trips: json.current_behavior_trips / json.total_trips * 100,
          potential_total_pkm: json.current_behavior_pkm,
          potential_total_trips: json.current_behavior_trips,
          total_employees: json.total_employees,
          num_hotspots: json.total_tiles,
          current_employees_pkm: (json.current_employees_pkm / json.total_pkm * 100).toFixed(1),
          current_employees_trips: (json.current_employees_trips / json.total_trips * 100).toFixed(1),
          current_category_pkm: (json.current_category_pkm / json.total_pkm * 100).toFixed(1),
          current_category_trips: (json.current_category_trips / json.total_trips * 100).toFixed(1),
          total_companies: json.total_companies.toFixed(0),
          total_car_trains_pkm: json.total_car_trains_pkm,
          total_car_trains_trips: json.total_car_trains_trips,
        });

        let temp_potential_total_pkm = 0;
        let temp_potential_total_trips = 0;

        if (color) {
          temp_potential_total_pkm += json['current_behavior_pkm' + (event.target.attributes.getNamedItem('id').value === 'both' ? '' : '_' + event.target.attributes.getNamedItem('id').value)];
          temp_potential_total_trips += json['current_behavior_trips' + (event.target.attributes.getNamedItem('id').value === 'both' ? '' : '_' + event.target.attributes.getNamedItem('id').value)];
        }

        if (color_bike) {
          temp_potential_total_pkm += json['current_bike_pkm' + (event.target.attributes.getNamedItem('id').value === 'both' ? '' : '_' + event.target.attributes.getNamedItem('id').value)];
          temp_potential_total_trips += json['current_bike_trips' + (event.target.attributes.getNamedItem('id').value === 'both' ? '' : '_' + event.target.attributes.getNamedItem('id').value)];
        }

        if (color_last_mile) {
          temp_potential_total_pkm += json['current_last_mile_pkm' + (event.target.attributes.getNamedItem('id').value === 'both' ? '' : '_' + event.target.attributes.getNamedItem('id').value)];
          temp_potential_total_trips += json['current_last_mile_trips' + (event.target.attributes.getNamedItem('id').value === 'both' ? '' : '_' + event.target.attributes.getNamedItem('id').value)];
        }

        if (color_working) {
          temp_potential_total_pkm += json['current_working_pkm' + (event.target.attributes.getNamedItem('id').value === 'both' ? '' : '_' + event.target.attributes.getNamedItem('id').value)];
          temp_potential_total_trips += json['current_working_trips' + (event.target.attributes.getNamedItem('id').value === 'both' ? '' : '_' + event.target.attributes.getNamedItem('id').value)];
        }

        if (color_car_pooling) {
          temp_potential_total_pkm += json['current_car_pooling_pkm' + (event.target.attributes.getNamedItem('id').value === 'both' ? '' : '_' + event.target.attributes.getNamedItem('id').value)];
          temp_potential_total_trips += json['current_car_pooling_trips' + (event.target.attributes.getNamedItem('id').value === 'both' ? '' : '_' + event.target.attributes.getNamedItem('id').value)];
        }

        this.setState({
          potential_total_pkm: temp_potential_total_pkm, potential_total_trips: temp_potential_total_trips,
          potential_pct_total_pkm: (temp_potential_total_pkm / this.state.total_pkm * 100), potential_pct_total_trips: (temp_potential_total_trips / this.state.total_trips * 100),
          loading: false
        });

      }).then(() => {

        this.setState({ loading_potentials: false });
      })

    this._renderLayers(this.state.data, this.state.height_based, this.state.height_length, this.state.color, this.state.color_bike, this.state.color_working, this.state.color_last_mile, this.state.color_car_pooling, this.state.biggest_employee, this.state.employees_num, this.state.current_value_category.value, event.target.attributes.getNamedItem('id').value);
  }

  handleHeightLength(event) {
    this.setState({ height_length: event.target.attributes.getNamedItem('id').value });
    height_length = event.target.attributes.getNamedItem('id').value;
    this._renderLayers(this.state.data, this.state.height_based, event.target.attributes.getNamedItem('id').value, this.state.color, this.state.color_bike, this.state.color_working, this.state.color_last_mile, this.state.color_car_pooling, this.state.biggest_employee, this.state.employees_num, this.state.current_value_category.value, this.state.agg_traffic)
  }

  getTooltip({ object }) {
    return (
      object && {
        html: `\
          <div><b>Place</b></div>
          <div>${object?.properties.name}, ${object?.properties.PLZ}</div>
          <div><b>Total Potential: </b>${height_length === 'potential' ? (object?.properties['potential'] * 100)?.toFixed(0) + '%' : numberWithCommas((object?.properties['absolute']?.toFixed(0)))}</div>
          <hr/>
          <div><b>Biggest Employer</b></div>
          <div>${object?.properties['max_name_' + cat_building]}</div>
          <div><b>Number of employees: </b>${(object?.properties['max_' + cat_building] === undefined ? 'No data' : numberWithCommas((object?.properties['max_' + cat_building]).toFixed(0)))}</div>
          `
      }
    );
  }

  handleDistanceTrainStation(event) {
    this.setState({ min_distance_train_station: event[0], max_distance_train_station: event[1] });
  }

  handleNumEmployees(event) {
    this.setState({ employees_num: event });
  }

  handleNumEmployeesInCompany(event) {
    let minEmployees = 0;

    if (event <= 3000) {
      minEmployees = event;

      if (event === 250) {
        minEmployees = 200;
      } else if (event === 400) {
        minEmployees = 300;
      } else if (event === 550) {
        minEmployees = 500;
      }
    }

    console.log(minEmployees)

    this.setState({ num_biggest_employee: event, valueMinEmployees: minEmployees });

    if (this.state.employees_num < minEmployees && event >= 3000) {
      this.setState({ employees_num: minEmployees });
    }
  }

  handleBuildingCategory(event) {
    cat_building = event.value;
    this.setState({ current_value_category: event, loading: true });

    let activated_categories = ''
    if (this.state.color === 'true') {
      activated_categories += 'behavior'
    }
    if (this.state.color_last_mile === 'true') {
      if (activated_categories !== '') {
        activated_categories += ','
      }
      activated_categories += 'last_mile'
    }
    if (this.state.color_bike === 'true') {
      if (activated_categories !== '') {
        activated_categories += ','
      }
      activated_categories += 'bike'
    }
    if (this.state.color_working === 'true') {
      if (activated_categories !== '') {
        activated_categories += ','
      }
      activated_categories += 'working'
    }
    if (this.state.color_car_pooling === 'true') {
      if (activated_categories !== '') {
        activated_categories += ','
      }
      activated_categories += 'car_pooling'
    }

    fetch(URL + 'tile_potential_employer_optimized/' + this.state.height_based + '/_' + this.state.agg_traffic + '/' + this.state.biggest_employee + '_' + event.value + '/' + this.state.employees_num + '/' + this.state.min_distance_train_station + ',' + this.state.max_distance_train_station + '/' + (this.state.current_value_k === null ? 'all' : this.state.current_value_k.value) + '/' + activated_categories + '/potentials.geojson', {
      timeoutDuration: 1200000
    }).then((res) => res.json())
      .then((json) => {
        this.setState({ data: json });
        return json;
      }).then((data) => {
        this._renderLayers(data, this.state.height_based, this.state.height_length, this.state.color, this.state.color_bike, this.state.color_working, this.state.color_last_mile, this.state.color_car_pooling, !this.state.biggest_employee, this.state.employees_num, this.state.current_value_category.value, this.state.agg_traffic)
      })
      .then(() => {
        let activated_categories = ''

        if (this.state.color) {
          activated_categories += 'behavior'
        }

        if (this.state.color_last_mile) {
          if (activated_categories !== '') {
            activated_categories += ','
          }
          activated_categories += 'last_mile'
        }

        if (this.state.color_bike) {
          if (activated_categories !== '') {
            activated_categories += ','
          }
          activated_categories += 'bike'
        }

        if (this.state.color_working) {
          if (activated_categories !== '') {
            activated_categories += ','
          }
          activated_categories += 'working'
        }

        if (this.state.color_car_pooling) {
          if (activated_categories !== '') {
            activated_categories += ','
          }
          activated_categories += 'car_pooling'
        }
        fetch(URL + 'tile_potential_employer/0/' + this.state.biggest_employee + '_' + event.value + '/' + this.state.height_based + '/' + this.state.employees_num + '/' + this.state.min_distance_train_station + ',' + this.state.max_distance_train_station + '/' + activated_categories + '/' + (this.state.current_value_k === null ? 'all' : this.state.current_value_k.value) + '/total_vs_filter_3d_optimized')
          .then((res) => res.json())
          .then((json) => {
            this.setState({
              all_info_total_vs_filter_3d: json,

              current_pkm: json.current_pkm.toFixed(0),
              total_pkm: json.total_pkm.toFixed(0),
              percentage_pkm: (json.current_pkm / json.total_pkm * 100).toFixed(0),

              current_trips: json.current_trips.toFixed(0),
              total_trips: json.total_trips.toFixed(0),
              percentage_trips: (json.current_trips / json.total_trips * 100).toFixed(0),

              potential_pct_total_pkm: json.current_behavior_pkm / json.total_pkm * 100,
              potential_pct_total_trips: json.current_behavior_trips / json.total_trips * 100,
              potential_total_pkm: json.current_behavior_pkm,
              potential_total_trips: json.current_behavior_trips,
              total_employees: json.total_employees,
              num_hotspots: json.total_tiles,
              current_employees_pkm: (json.current_employees_pkm / json.total_pkm * 100).toFixed(1),
              current_employees_trips: (json.current_employees_trips / json.total_trips * 100).toFixed(1),
              current_category_pkm: (json.current_category_pkm / json.total_pkm * 100).toFixed(1),
              current_category_trips: (json.current_category_trips / json.total_trips * 100).toFixed(1),

              total_companies: json.total_companies.toFixed(0),
              total_car_trains_pkm: json.total_car_trains_pkm,
              total_car_trains_trips: json.total_car_trains_trips,
            });

            let temp_potential_total_pkm = 0;
            let temp_potential_total_trips = 0;

            if (color) {
              temp_potential_total_pkm += json['current_behavior_pkm' + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)];
              temp_potential_total_trips += json['current_behavior_trips' + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)];
            }

            if (color_bike) {
              temp_potential_total_pkm += json['current_bike_pkm' + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)];
              temp_potential_total_trips += json['current_bike_trips' + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)];
            }

            if (color_last_mile) {
              temp_potential_total_pkm += json['current_last_mile_pkm' + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)];
              temp_potential_total_trips += json['current_last_mile_trips' + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)];
            }

            if (color_working) {
              temp_potential_total_pkm += json['current_working_pkm' + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)];
              temp_potential_total_trips += json['current_working_trips' + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)];
            }

            if (color_car_pooling) {
              temp_potential_total_pkm += json['current_car_pooling_pkm' + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)];
              temp_potential_total_trips += json['current_car_pooling_trips' + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)];
            }

            this.setState({
              potential_total_pkm: temp_potential_total_pkm, potential_total_trips: temp_potential_total_trips,
              potential_pct_total_pkm: (temp_potential_total_pkm / this.state.total_pkm * 100), potential_pct_total_trips: (temp_potential_total_trips / this.state.total_trips * 100),
              loading: false
            });
          });
      });

  }

  handleKanton(event) {
    this.setState({ current_value_k: event, loading: true });

    let activated_categories = ''
    if (this.state.color) {
      activated_categories += 'behavior'
    }

    if (this.state.color_last_mile) {
      if (activated_categories !== '') {
        activated_categories += ','
      }
      activated_categories += 'last_mile'
    }

    if (this.state.color_bike) {
      if (activated_categories !== '') {
        activated_categories += ','
      }
      activated_categories += 'bike'
    }

    if (this.state.color_working) {
      if (activated_categories !== '') {
        activated_categories += ','
      }
      activated_categories += 'working'
    }

    if (this.state.color_car_pooling) {
      if (activated_categories !== '') {
        activated_categories += ','
      }
      activated_categories += 'car_pooling'
    }

    fetch(URL + 'tile_potential_employer_optimized/' + this.state.height_based + '/_' + this.state.agg_traffic + '/' + this.state.biggest_employee + '_' + this.state.current_value_category.value + '/' + this.state.employees_num + '/' + this.state.min_distance_train_station + ',' + this.state.max_distance_train_station + '/' + (event === null ? 'all' : event.value) + '/' + activated_categories + '/potentials.geojson', {
      timeoutDuration: 1200000
    }).then((res) => res.json())
      .then((json) => {
        this.setState({ data: json });
        return json;
      }).then((data) => {
        this._renderLayers(data, this.state.height_based, this.state.height_length, this.state.color, this.state.color_bike, this.state.color_working, this.state.color_last_mile, this.state.color_car_pooling, !this.state.biggest_employee, this.state.employees_num, this.state.current_value_category.value, this.state.agg_traffic)
      })
      .then(() => { this.setState({ loading: false }); })
      .then(() => {
        let activated_categories = ''

        if (this.state.color) {
          activated_categories += 'behavior'
        }

        if (this.state.color_last_mile) {
          if (activated_categories !== '') {
            activated_categories += ','
          }
          activated_categories += 'last_mile'
        }

        if (this.state.color_bike) {
          if (activated_categories !== '') {
            activated_categories += ','
          }
          activated_categories += 'bike'
        }

        if (this.state.color_working) {
          if (activated_categories !== '') {
            activated_categories += ','
          }
          activated_categories += 'working'
        }

        if (this.state.color_car_pooling) {
          if (activated_categories !== '') {
            activated_categories += ','
          }
          activated_categories += 'car_pooling'
        }
        fetch(URL + 'tile_potential_employer/0/' + this.state.biggest_employee + '_' + this.state.current_value_category.value + '/' + this.state.height_based + '/' + this.state.employees_num + '/' + this.state.min_distance_train_station + ',' + this.state.max_distance_train_station + '/' + activated_categories + '/' + (event === null ? 'all' : event.value) + '/total_vs_filter_3d_optimized')
          .then((res) => res.json())
          .then((json) => {
            this.setState({
              all_info_total_vs_filter_3d: json,

              current_pkm: json.current_pkm.toFixed(0),
              total_pkm: json.total_pkm.toFixed(0),
              percentage_pkm: (json.current_pkm / json.total_pkm * 100).toFixed(0),

              current_trips: json.current_trips.toFixed(0),
              total_trips: json.total_trips.toFixed(0),
              percentage_trips: (json.current_trips / json.total_trips * 100).toFixed(0),

              potential_pct_total_pkm: json.current_behavior_pkm / json.total_pkm * 100,
              potential_pct_total_trips: json.current_behavior_trips / json.total_trips * 100,
              potential_total_pkm: json.current_behavior_pkm,
              potential_total_trips: json.current_behavior_trips,
              total_employees: json.total_employees,
              num_hotspots: json.total_tiles,
              current_employees_pkm: (json.current_employees_pkm / json.total_pkm * 100).toFixed(1),
              current_employees_trips: (json.current_employees_trips / json.total_trips * 100).toFixed(1),
              current_category_pkm: (json.current_category_pkm / json.total_pkm * 100).toFixed(1),
              current_category_trips: (json.current_category_trips / json.total_trips * 100).toFixed(1),

              total_companies: json.total_companies.toFixed(0),
              total_car_trains_pkm: json.total_car_trains_pkm,
              total_car_trains_trips: json.total_car_trains_trips,
            });

            let temp_potential_total_pkm = 0;
            let temp_potential_total_trips = 0;

            if (color) {
              temp_potential_total_pkm += json['current_behavior_pkm' + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)];
              temp_potential_total_trips += json['current_behavior_trips' + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)];
            }

            if (color_bike) {
              temp_potential_total_pkm += json['current_bike_pkm' + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)];
              temp_potential_total_trips += json['current_bike_trips' + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)];
            }

            if (color_last_mile) {
              temp_potential_total_pkm += json['current_last_mile_pkm' + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)];
              temp_potential_total_trips += json['current_last_mile_trips' + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)];
            }

            if (color_working) {
              temp_potential_total_pkm += json['current_working_pkm' + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)];
              temp_potential_total_trips += json['current_working_trips' + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)];
            }

            if (color_car_pooling) {
              temp_potential_total_pkm += json['current_car_pooling_pkm' + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)];
              temp_potential_total_trips += json['current_car_pooling_trips' + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)];
            }

            this.setState({
              potential_total_pkm: temp_potential_total_pkm, potential_total_trips: temp_potential_total_trips,
              potential_pct_total_pkm: (temp_potential_total_pkm / this.state.total_pkm * 100), potential_pct_total_trips: (temp_potential_total_trips / this.state.total_trips * 100),
              loading: false
            });
          });
      });
  }

  updateField(card, value) {
    this.setState({
      ...this.state,
      [card]: value
    });
  }

  updatePotential() {
    this.setState({ loading: true });

    let activated_categories = ''

    if (this.state.color) {
      activated_categories += 'behavior'
    }

    if (this.state.color_last_mile) {
      if (activated_categories !== '') {
        activated_categories += ','
      }
      activated_categories += 'last_mile'
    }

    if (this.state.color_bike) {
      if (activated_categories !== '') {
        activated_categories += ','
      }
      activated_categories += 'bike'
    }

    if (this.state.color_working) {
      if (activated_categories !== '') {
        activated_categories += ','
      }
      activated_categories += 'working'
    }

    if (this.state.color_car_pooling) {
      if (activated_categories !== '') {
        activated_categories += ','
      }
      activated_categories += 'car_pooling'
    }
    fetch(URL + 'tile_potential_employer/0/' + this.state.biggest_employee + '_' + this.state.current_value_category.value + '/' + this.state.height_based + '/' + this.state.employees_num + '/' + this.state.min_distance_train_station + ',' + this.state.max_distance_train_station + '/' + activated_categories + '/' + (this.state.current_value_k === null ? 'all' : this.state.current_value_k.value) + '/total_vs_filter_3d_optimized')
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          all_info_total_vs_filter_3d: json,

          current_pkm: json.current_pkm.toFixed(0),
          total_pkm: json.total_pkm.toFixed(0),
          percentage_pkm: (json.current_pkm / json.total_pkm * 100).toFixed(0),

          current_trips: json.current_trips.toFixed(0),
          total_trips: json.total_trips.toFixed(0),
          percentage_trips: (json.current_trips / json.total_trips * 100).toFixed(0),

          potential_pct_total_pkm: json.current_behavior_pkm / json.total_pkm * 100,
          potential_pct_total_trips: json.current_behavior_trips / json.total_trips * 100,
          potential_total_pkm: json.current_behavior_pkm,
          potential_total_trips: json.current_behavior_trips,
          total_employees: json.total_employees,
          num_hotspots: json.total_tiles,
          current_employees_pkm: (json.current_employees_pkm / json.total_pkm * 100).toFixed(1),
          current_employees_trips: (json.current_employees_trips / json.total_trips * 100).toFixed(1),
          current_category_pkm: (json.current_category_pkm / json.total_pkm * 100).toFixed(1),
          current_category_trips: (json.current_category_trips / json.total_trips * 100).toFixed(1),
          total_companies: json.total_companies.toFixed(0),
          total_car_trains_pkm: json.total_car_trains_pkm,
          total_car_trains_trips: json.total_car_trains_trips,
        });

        let temp_potential_total_pkm = 0;
        let temp_potential_total_trips = 0;

        if (color) {
          temp_potential_total_pkm += json['current_behavior_pkm' + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)];
          temp_potential_total_trips += json['current_behavior_trips' + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)];
        }

        if (color_bike) {
          temp_potential_total_pkm += json['current_bike_pkm' + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)];
          temp_potential_total_trips += json['current_bike_trips' + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)];
        }

        if (color_last_mile) {
          temp_potential_total_pkm += json['current_last_mile_pkm' + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)];
          temp_potential_total_trips += json['current_last_mile_trips' + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)];
        }

        if (color_working) {
          temp_potential_total_pkm += json['current_working_pkm' + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)];
          temp_potential_total_trips += json['current_working_trips' + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)];
        }

        if (color_car_pooling) {
          temp_potential_total_pkm += json['current_car_pooling_pkm' + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)];
          temp_potential_total_trips += json['current_car_pooling_trips' + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)];
        }

        this.setState({
          potential_total_pkm: temp_potential_total_pkm, potential_total_trips: temp_potential_total_trips,
          potential_pct_total_pkm: (temp_potential_total_pkm / this.state.total_pkm * 100), potential_pct_total_trips: (temp_potential_total_trips / this.state.total_trips * 100),
          loading: false
        });

      })
  }

  handleUpdate() {
    this.setState({ loading: true })

    let alt_event = this.state.num_biggest_employee;

    if (this.state.num_biggest_employee > 3000) {
      alt_event = 'max'
      this.setState({ biggest_employee: 'max' });
    }
    else {
      if (this.state.num_biggest_employee === 250) {
        alt_event = 200;
      } else if (this.state.num_biggest_employee === 400) {
        alt_event = 300;
      } else if (this.state.num_biggest_employee === 550) {
        alt_event = 500;
      }
      this.setState({ biggest_employee: 'sum_' + alt_event });
    }

    alt_event = 'sum_' + alt_event;

    let activated_categories = ''
    if (this.state.color) {
      activated_categories += 'behavior'
    }
    if (this.state.color_last_mile) {
      if (activated_categories !== '') {
        activated_categories += ','
      }
      activated_categories += 'last_mile'
    }
    if (this.state.color_bike) {
      if (activated_categories !== '') {
        activated_categories += ','
      }
      activated_categories += 'bike'
    }
    if (this.state.color_working) {
      if (activated_categories !== '') {
        activated_categories += ','
      }
      activated_categories += 'working'
    }
    if (this.state.color_car_pooling) {
      if (activated_categories !== '') {
        activated_categories += ','
      }
      activated_categories += 'car_pooling'
    }

    fetch(URL + 'tile_potential_employer_optimized/' + this.state.height_based + '/_' + this.state.agg_traffic + '/' + alt_event + '_' + this.state.current_value_category.value + '/' + this.state.employees_num + '/' + this.state.min_distance_train_station + ',' + this.state.max_distance_train_station + '/' + (this.state.current_value_k === null ? 'all' : this.state.current_value_k.value) + '/' + activated_categories + '/potentials.geojson', {
      timeoutDuration: 1200000
    }).then((res) => res.json())
      .then((json) => {
        this.setState({ data: json });
        return json;
      }).then((data) => {
        this._renderLayers(data, this.state.height_based, this.state.height_length, this.state.color, this.state.color_bike, this.state.color_working, this.state.color_last_mile, this.state.color_car_pooling, empty_geojson, 0, alt_event, this.state.employees_num, this.state.current_value_category.value, this.state.agg_traffic)
      })
      .then(() => {
        fetch(URL + 'tile_potential_employer/0/' + alt_event + '_' + this.state.current_value_category.value + '/' + this.state.height_based + '/' + this.state.employees_num + '/' + this.state.min_distance_train_station + ',' + this.state.max_distance_train_station + '/' + activated_categories + '/' + (this.state.current_value_k === null ? 'all' : this.state.current_value_k.value) + '/total_vs_filter_3d_optimized')
          .then((res) => res.json())
          .then((json) => {
            this.setState({
              all_info_total_vs_filter_3d: json,

              current_pkm: json.current_pkm.toFixed(0),
              total_pkm: json.total_pkm.toFixed(0),
              percentage_pkm: (json.current_pkm / json.total_pkm * 100).toFixed(0),

              current_trips: json.current_trips.toFixed(0),
              total_trips: json.total_trips.toFixed(0),
              percentage_trips: (json.current_trips / json.total_trips * 100).toFixed(0),

              potential_pct_total_pkm: json.current_behavior_pkm / json.total_pkm * 100,
              potential_pct_total_trips: json.current_behavior_trips / json.total_trips * 100,
              potential_total_pkm: json.current_behavior_pkm,
              potential_total_trips: json.current_behavior_trips,
              total_employees: json.total_employees,
              num_hotspots: json.total_tiles,

              current_employees_pkm: (json.current_employees_pkm / json.total_pkm * 100).toFixed(1),
              current_employees_trips: (json.current_employees_trips / json.total_trips * 100).toFixed(1),
              current_category_pkm: (json.current_category_pkm / json.total_pkm * 100).toFixed(1),
              current_category_trips: (json.current_category_trips / json.total_trips * 100).toFixed(1),
              total_companies: json.total_companies.toFixed(0),
              total_car_trains_pkm: json.total_car_trains_pkm,
              total_car_trains_trips: json.total_car_trains_trips,
            });

            let temp_potential_total_pkm = 0;
            let temp_potential_total_trips = 0;

            if (color) {
              temp_potential_total_pkm += json['current_behavior_pkm' + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)];
              temp_potential_total_trips += json['current_behavior_trips' + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)];
            }

            if (color_bike) {
              temp_potential_total_pkm += json['current_bike_pkm' + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)];
              temp_potential_total_trips += json['current_bike_trips' + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)];
            }

            if (color_last_mile) {
              temp_potential_total_pkm += json['current_last_mile_pkm' + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)];
              temp_potential_total_trips += json['current_last_mile_trips' + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)];
            }

            if (color_working) {
              temp_potential_total_pkm += json['current_working_pkm' + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)];
              temp_potential_total_trips += json['current_working_trips' + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)];
            }

            if (color_car_pooling) {
              temp_potential_total_pkm += json['current_car_pooling_pkm' + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)];
              temp_potential_total_trips += json['current_car_pooling_trips' + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)];
            }

            this.setState({
              potential_total_pkm: temp_potential_total_pkm, potential_total_trips: temp_potential_total_trips,
              potential_pct_total_pkm: (temp_potential_total_pkm / this.state.total_pkm * 100), potential_pct_total_trips: (temp_potential_total_trips / this.state.total_trips * 100),
            });

          }).then(() => {
            this.setState({ loading: false })
          })
      })
  }

  render() {
    const { viewState, controller = true } = this.props;
    const { hideNavs } = this.state;
    const { height_based } = this.state;
    const { height_length } = this.state;
    const { agg_traffic } = this.state;

    const { color } = this.state;
    const { color_bike } = this.state;
    const { color_last_mile } = this.state;
    const { color_working } = this.state;
    const { color_car_pooling } = this.state;
    const { loading } = this.state;

    const { current_pkm } = this.state;
    const { total_pkm } = this.state;
    const { percentage_pkm } = this.state;

    const { current_trips } = this.state;
    const { total_trips } = this.state;
    const { percentage_trips } = this.state;

    const { working_pannel } = this.state;
    const { bike_pannel } = this.state;
    const { behavior_pannel } = this.state;
    const { last_mile_pannel } = this.state;
    const { car_pooling_pannel } = this.state;

    const { disable_behaviour } = this.state;
    const { disable_bike } = this.state;
    const { disable_last_mile } = this.state;
    const { disable_working_time } = this.state;
    const { disable_car_pooling } = this.state;

    const selectStyles = {
      menuList: styles => {
        return {
          ...styles,
          maxHeight: 100
        };
      }
    };

    const marks = {
      0: '0',
      100: '100',
      250: '200',
      400: '300',
      550: '500',
      1000: '1\'000',
      2000: '2\'000',
      3000: '3\'000',
      3500: {
        style: {
          fontSize: '10px',
        },
        label: <strong>Biggest Employer</strong>,
      },
    }

    return (
      <div>
        <DeckGL
          layers={this._renderLayers(this.state.data, this.state.height_based, this.state.height_length, this.state.color, this.state.color_bike, this.state.color_working, this.state.color_last_mile, this.state.color_car_pooling, this.state.biggest_employee, this.state.employees_num, this.state.current_value_category.value, this.state.agg_traffic)}
          initialViewState={this.initialview()}
          viewState={viewState}
          controller={controller}
          getTooltip={this.getTooltip}
          onClick={(element) => this.getTooltipClick(element)}
        >
          <Map reuseMaps mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN} mapStyle={'mapbox://styles/mapbox/streets-v11'} />
          <RecommendationNavBarComponentBLS />
        </DeckGL>
        {loading ?
          <div className="bg-loader">
            <div className="pos-center-brain-loader">
              <div className="circle-loading-brain">
              </div>
              <svg className='brain-loader' x="0px" y="0px" viewBox="0 0 496 496">
                <path d="M40,449.472V364.28L58.424,352H88v-16H53.576L24,355.72v93.752C14.704,452.784,8,461.584,8,472c0,13.232,10.768,24,24,24				s24-10.768,24-24C56,461.584,49.288,452.784,40,449.472z M32,480c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8s8,3.584,8,8				C40,476.416,36.416,480,32,480z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M46.528,320H88v-16H46.528c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24				C34.416,336,43.216,329.288,46.528,320z M16,312c0-4.416,3.584-8,8-8s8,3.584,8,8c0,4.416-3.584,8-8,8S16,316.416,16,312z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M24,272c10.416,0,19.216-6.712,22.528-16H88v-16H46.528c-3.312-9.296-12.112-16-22.528-16c-13.232,0-24,10.768-24,24				S10.768,272,24,272z M24,240c4.416,0,8,3.584,8,8s-3.584,8-8,8s-8-3.584-8-8C16,243.584,19.584,240,24,240z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M56 272h32v16H56Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M56 208h32v16H56Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M0 176h88v16H0Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M60.688,160H88v-16H67.312L48,124.688v-14.16C57.288,107.216,64,98.416,64,88c0-13.232-10.768-24-24-24S16,74.768,16,88				c0,10.416,6.704,19.216,16,22.528v20.784L60.688,160z M40,80c4.416,0,8,3.584,8,8s-3.584,8-8,8s-8-3.584-8-8S35.584,80,40,80z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M144 67.312L144 88L160 88L160 60.688L131.312 32L0 32L0 48L124.688 48z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M176 35.312L176 88L192 88L192 28.688L163.312 0L0 0L0 16L156.688 16z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M208 56h16v32H208Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M304 0h16v88H304Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M240,46.528V88h16V59.312l16,16V88h16V68.688L262.392,43.08C268.192,38.696,272,31.816,272,24c0-13.232-10.768-24-24-24				s-24,10.768-24,24C224,34.416,230.704,43.216,240,46.528z M248,16c4.416,0,8,3.584,8,8s-3.584,8-8,8s-8-3.584-8-8				S243.584,16,248,16z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M352,51.312L371.312,32h78.16c2.416,6.776,7.752,12.112,14.528,14.528v110.16L444.688,176H408v16h43.312L480,163.312				V46.528c9.288-3.312,16-12.112,16-22.528c0-13.232-10.768-24-24-24c-10.416,0-19.216,6.712-22.528,16h-84.784L336,44.688V88h16				V51.312z M472,16c4.416,0,8,3.584,8,8s-3.584,8-8,8c-4.416,0-8-3.584-8-8S467.584,16,472,16z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M414.08,97.392l17.92,17.92v9.376L412.688,144H408v16h11.312L448,131.312v-22.624L419.312,80h-4.784				c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24C401.904,112,410.416,105.968,414.08,97.392z				 M392,96c-4.416,0-8-3.584-8-8s3.584-8,8-8c4.416,0,8,3.584,8,8S396.416,96,392,96z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M408 208h88v16H408Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M480,449.472V268.688L451.312,240H408v16h36.688L464,275.312v41.376L451.312,304H408v16h36.688L464,339.312v110.16				c-9.296,3.312-16,12.112-16,22.528c0,13.232,10.768,24,24,24s24-10.768,24-24C496,461.584,489.288,452.784,480,449.472z M472,480				c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8c4.416,0,8,3.584,8,8C480,476.416,476.416,480,472,480z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M408 272h32v16H408Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M448,364.688L419.312,336H408v16h4.688L432,371.312V416h-80v-8h-16v24h96v12.688L412.688,464h-14.16				c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24c10.416,0,19.216-6.712,22.528-16h20.784				L448,451.312V364.688z M376,480c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8c4.416,0,8,3.584,8,8C384,476.416,380.416,480,376,480z				" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M160,408h-16v8h-41.472c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24				c10.416,0,19.216-6.712,22.528-16H160V408z M80,432c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8s8,3.584,8,8				C88,428.416,84.416,432,80,432z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M304 408h16v88H304Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M272 408h16v88H272Z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M240,420.688l-16,16V408h-16v41.472c-9.296,3.312-16,12.112-16,22.528c0,13.232,10.768,24,24,24s24-10.768,24-24				c0-7.816-3.808-14.696-9.608-19.08L256,427.312V408h-16V420.688z M216,480c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8s8,3.584,8,8				C224,476.416,220.416,480,216,480z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M192 408L176 408L176 428.688L152 452.688L152 496L168 496L168 459.312L192 435.312z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M336,392h16v-16h24v-24h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16v-16h-16v-16h16				v-16h-16v-24h-24v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-16v-16h-16v16h-3.312				L120,140.688V144h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v16h-16v16h16v24h24v16h16v-16h16				v16h16v-16h16v16h16v-16h16v16h16v-16h16v16h16v-16h16v16h16v-16h16V392z M136,360V147.312L147.312,136H360v224H136z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M318.888,211.048l13.96-13.96l-33.936-33.936l-13.96,13.96c-4.16-2.184-8.488-3.976-12.952-5.384V152h-48v19.728				c-4.472,1.408-8.8,3.2-12.952,5.384l-13.96-13.96l-33.936,33.936l13.96,13.96c-2.176,4.16-3.976,8.488-5.384,12.952H152v48				h19.728c1.408,4.472,3.2,8.8,5.384,12.952l-13.96,13.96l33.936,33.936l13.96-13.96c4.16,2.176,8.48,3.976,12.952,5.384V344h48				v-19.728c4.472-1.408,8.8-3.2,12.952-5.384l13.96,13.96l33.936-33.936l-13.96-13.96c2.176-4.152,3.976-8.48,5.384-12.952H344v-48				h-19.728C322.864,219.528,321.072,215.2,318.888,211.048z M328,256h-16.224l-1.416,6.224c-1.6,7.016-4.368,13.672-8.216,19.792				l-3.408,5.408l11.496,11.496l-11.312,11.312l-11.496-11.496l-5.408,3.408c-6.12,3.848-12.776,6.616-19.792,8.216L256,311.776V328				h-16v-16.224l-6.224-1.416c-7.016-1.6-13.672-4.368-19.792-8.216l-5.408-3.408l-11.496,11.496l-11.312-11.312l11.496-11.496				l-3.408-5.408c-3.848-6.12-6.616-12.776-8.216-19.792L184.224,256H168v-16h16.224l1.416-6.224				c1.6-7.016,4.368-13.672,8.216-19.792l3.408-5.408l-11.496-11.496l11.312-11.312l11.496,11.488l5.408-3.408				c6.12-3.848,12.776-6.616,19.792-8.216l6.224-1.408V168h16v16.224l6.224,1.416c7.016,1.6,13.672,4.368,19.792,8.216l5.408,3.408				l11.496-11.488l11.312,11.312l-11.496,11.496l3.408,5.408c3.848,6.12,6.616,12.776,8.216,19.792l1.416,6.216H328V256z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M248,208c-22.056,0-40,17.944-40,40c0,22.056,17.944,40,40,40c22.056,0,40-17.944,40-40C288,225.944,270.056,208,248,208				z M248,272c-13.232,0-24-10.768-24-24s10.768-24,24-24s24,10.768,24,24S261.232,272,248,272z" fill="white" stroke="#e32a8d" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>
          </div>
          :
          <div style={{ display: hideNavs ? 'none' : 'block' }}>
            <div className="kanton-employer-div">
              <Select className='kanton' placeholder="Filter by Canton..." isClearable={true} options={this.state.kantons_} onChange={this.handleKanton.bind(this)} styles={selectStyles} defaultValue={this.state.current_value_k} />
            </div>
            <div className="tile-category-div">
              <Select className='categories' placeholder="Filter by Building Category..." isClearable={false} options={this.state.categories_} onChange={this.handleBuildingCategory.bind(this)} styles={selectStyles} defaultValue={this.state.current_value_category} />
            </div>
            <div className="map-potential-total-filtered-value" style={{ top: 500 + 'px', left: '0px' }}>
              <div className="map-potential-total-filtered-value-inner" style={{ width: '250px', height: 'auto' }}>
                {height_based === 'pkm' ?
                  <>
                    <h1 style={{ color: '#027272' }}>Traffic based on Passenger Km <br />in Switzerland</h1>
                    <h1 style={{ color: '#5b5b5b' }}>Showing {numberWithCommas(current_pkm)} PKm per day</h1>
                    <h3 style={{ color: '#000000', fontWeight: '500' }}>Total {numberWithCommas(total_pkm)} PKm per day</h3>
                    <h4 style={{ color: '#000000' }}>{percentage_pkm}% of the PKm per day</h4>
                    <hr />
                    <h4 style={{ color: '#AB075E' }}>{this.state.current_employees_pkm}% PKm generated by employees</h4>
                    <h4 style={{ color: '#AB075E' }}>{this.state.current_category_pkm}% PKm generated by visitors</h4>
                  </>
                  :
                  <>
                    <h1 style={{ color: '#027272' }}>Traffic based on number of <br />trips in Switzerland</h1>
                    <h1 style={{ color: '#5b5b5b' }}>Showing {numberWithCommas(current_trips)} trips per day</h1>
                    <h3 style={{ color: '#000000', fontWeight: '500' }}>Total {numberWithCommas(total_trips)} trips per day</h3>
                    <h4 style={{ color: '#000000' }}>{percentage_trips}% of the trips per day</h4>
                    <hr />
                    <h4 style={{ color: '#AB075E' }}>{this.state.current_employees_trips}% trips generated by employees</h4>
                    <h4 style={{ color: '#AB075E' }}>{this.state.current_category_trips}% trips generated by visitors</h4>
                  </>
                }
              </div>
            </div>
            <div className="map-potential-total-filtered-value" style={{ top: 500 + 'px', left: '280px' }}>
              <div className="map-potential-total-filtered-value-inner" style={{ width: '420px', height: 'auto', minHeight: '145px' }}>
                {this.state.loading_potentials ?
                  <div className="data_insights_loader" style={{ padding: '5%', paddingLeft: '35%' }}>
                    <div className="loader"></div>
                  </div>
                  :
                  <>
                    {this.state.agg_traffic === 'both' || this.state.agg_traffic === 'employeers' ?
                      <h3 style={{ marginBottom: '0px', fontSize: '11.5px' }}>Potentials of {numberWithCommas(this.state.total_employees)} employees{this.state.agg_traffic === 'both' ? '+visitors' : ''} in {numberWithCommas(this.state.num_hotspots)} hotspots in {numberWithCommas(this.state.all_info_total_vs_filter_3d['total_companies'])} companies</h3>
                      :
                      <h3 style={{ marginBottom: '0px', fontSize: '11.5px' }}>Potentials of visitors in {numberWithCommas(this.state.num_hotspots)} hotspots in {numberWithCommas(this.state.all_info_total_vs_filter_3d['total_companies'])} companies</h3>
                    }
                    <table className="top-od-regional" style={{ paddingTop: "0px" }}>
                      <thead>
                        <tr key='headers'>
                          <th className="table-title-regional-od">Measures</th>
                          <th className="table-title-regional-od">{(height_based === 'pkm' ? 'Passenger Km' : 'Number of trips')}</th>
                          <th className="table-title-regional-od">Percentage</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="table-data-regional-od">
                            {/* <div className="square" style={{ backgroundColor: "#390099" }}></div> */}
                            Behaviour
                          </td>
                          <td className="table-data-regional-od">
                            {(this.state.color ?
                              (numberWithCommas(this.state.all_info_total_vs_filter_3d['current_behavior_' + height_based + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)]?.toFixed(0)))
                              : '-')}
                          </td>
                          <td className="table-data-regional-od">
                            {(this.state.color ?
                              (this.state.all_info_total_vs_filter_3d['current_behavior_' + height_based + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)] / this.state['total_' + height_based] * 100)?.toFixed(1) + '%'
                              : '-')}
                          </td>
                        </tr>
                        <tr>
                          <td className="table-data-regional-od">
                            {/* <div className="square" style={{ backgroundColor: "#390099" }}></div> */}
                            Bike
                          </td>
                          <td className="table-data-regional-od">
                            {(this.state.color_bike ?
                              ((numberWithCommas(this.state.all_info_total_vs_filter_3d['current_bike_' + height_based + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)]?.toFixed(0))))
                              : '-')}
                          </td>
                          <td className="table-data-regional-od">
                            {(this.state.color_bike ?
                              (this.state.all_info_total_vs_filter_3d['current_bike_' + height_based + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)] / this.state['total_' + height_based] * 100)?.toFixed(1) + '%'
                              : '-')}

                          </td>
                        </tr>
                        <tr>
                          <td className="table-data-regional-od">
                            {/* <div className="square" style={{ backgroundColor: "#390099" }}></div> */}
                            Last Mile
                          </td>
                          <td className="table-data-regional-od">
                            {(this.state.color_last_mile ?
                              ((numberWithCommas(this.state.all_info_total_vs_filter_3d['current_last_mile_' + height_based + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)]?.toFixed(0))))
                              : '-')}
                          </td>
                          <td className="table-data-regional-od">
                            {(this.state.color_last_mile ?

                              (this.state.all_info_total_vs_filter_3d['current_last_mile_' + height_based + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)] / this.state['total_' + height_based] * 100)?.toFixed(1) + '%'
                              : '-')}

                          </td>
                        </tr>
                        <tr>
                          <td className="table-data-regional-od">
                            {/* <div className="square" style={{ backgroundColor: "#390099" }}></div> */}
                            Working Time
                          </td>
                          <td className="table-data-regional-od">
                            {(this.state.color_working ?
                              ((numberWithCommas(this.state.all_info_total_vs_filter_3d['current_working_' + height_based + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)]?.toFixed(0))))
                              : '-')}

                          </td>
                          <td className="table-data-regional-od">
                            {(this.state.color_working ?
                              (this.state.all_info_total_vs_filter_3d['current_working_' + height_based + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)] / this.state['total_' + height_based] * 100)?.toFixed(1) + '%'
                              : '-')}

                          </td>
                        </tr>
                        <tr>
                          <td className="table-data-regional-od">
                            {/* <div className="square" style={{ backgroundColor: "#390099" }}></div> */}
                            Car Pooling
                          </td>
                          <td className="table-data-regional-od">
                            {(this.state.color_car_pooling ?
                              ((numberWithCommas(this.state.all_info_total_vs_filter_3d['current_car_pooling_' + height_based + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)]?.toFixed(0))))
                              : '-')}
                          </td>
                          <td className="table-data-regional-od">
                            {(this.state.color_car_pooling ?
                              (this.state.all_info_total_vs_filter_3d['current_car_pooling_' + height_based + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)] / this.state['total_' + height_based] * 100)?.toFixed(1) + '%'
                              : '-')}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <hr className="table-separation" />
                    <table className="top-od-regional" style={{ paddingTop: "0px" }}>
                      <thead>
                        <tr key='headers'>
                          <th className="table-title-regional-od">Total Commuting</th>
                          <td className="table-data-regional-od">{(numberWithCommas(this.state.all_info_total_vs_filter_3d['current_potential_' + height_based + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)]?.toFixed(0)))}
                            {(height_based === 'pkm' ? ' Pkm' : ' trips')}</td>
                          <td className="table-data-regional-od">
                            {(this.state.all_info_total_vs_filter_3d['current_potential_' + height_based + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)] / this.state['total_' + height_based] * 100).toFixed(1)}%
                          </td>
                        </tr>

                        {this.state.agg_traffic === 'both' || this.state.agg_traffic === 'employeers'
                          ?
                          <>
                            <tr>
                              <td className="table-data-regional-od">
                                {/* <div className="square" style={{ backgroundColor: "#390099" }}></div> */}
                                Leisure
                              </td>
                              <td className="table-data-regional-od">
                                {(numberWithCommas(this.state.all_info_total_vs_filter_3d['current_leisure_' + height_based]?.toFixed(0)))}
                                {(height_based === 'pkm' ? ' Pkm' : ' trips')}
                              </td>
                              <td className="table-data-regional-od">
                                {(this.state.all_info_total_vs_filter_3d['current_leisure_' + height_based] / this.state['total_' + height_based] * 100).toFixed(1)}%
                              </td>
                            </tr>

                            <tr key='headers-total-especial'>
                              <th className="table-title-regional-od">Total with Leisure</th>
                              <td className="table-data-regional-od">
                                {numberWithCommas((this.state.all_info_total_vs_filter_3d['current_leisure_' + height_based] + this.state.all_info_total_vs_filter_3d['current_potential_' + height_based + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)]).toFixed(0))}
                                {(height_based === 'pkm' ? ' Pkm' : ' trips')}</td>
                              <td className="table-data-regional-od">
                                {((this.state.all_info_total_vs_filter_3d['current_leisure_' + height_based] + this.state.all_info_total_vs_filter_3d['current_potential_' + height_based + (this.state.agg_traffic === 'both' ? '' : '_' + this.state.agg_traffic)]) / this.state['total_' + height_based] * 100).toFixed(1)}%
                              </td>
                            </tr></>
                          :
                          <>
                          </>
                        }
                      </thead>
                    </table>
                  </>
                }
              </div>
            </div>
            <div className='map-potential-3D'>
              <div className='map-potential-3D-inner' style={{ height: '160px' }}>
                <h1>Hotspot Potential Employers</h1>
                <div className="row">
                  <div className="column-1-50">
                    <h2>Measure the potential based on</h2>
                    <Form onChange={this.handleHeight.bind(this)}>
                      <div className="row">
                        <div className="column-1-50">
                          <Form.Check inline label=" Num. of trips" name="type_aggregation" type='radio' id='trips' defaultChecked={(height_based === 'trips')} />
                        </div>
                        <div className="column-1-50">
                          <Form.Check inline label=" Passenger Km" name="type_aggregation" type='radio' id='pkm' defaultChecked={(height_based === 'pkm')} />
                        </div>
                      </div>
                    </Form>
                  </div>
                  <div className="column-1-50">
                    <h2>Render heights based on</h2>
                    <Form onChange={this.handleHeightLength.bind(this)}>
                      <div className="row">
                        <div className="column-1-50">
                          <Form.Check inline label=" Percentage" name="type_aggregation" type='radio' id='potential' defaultChecked={(height_length === 'potential')} />
                        </div>
                        <div className="column-2-50">
                          <Form.Check inline label=" Absolute" name="type_aggregation" type='radio' id='absolute' defaultChecked={(height_length === 'absolute')} />
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
                <h2>Traffic to take into consideration</h2>
                <Form onChange={this.handleAggTraffic.bind(this)}>
                  <div className="row">
                    <div className="column-33">
                      <Form.Check inline label=" Employees" name="type_aggregation" type='radio' id='employeers' defaultChecked={(agg_traffic === 'employeers')} />
                    </div>
                    <div className="column-33">
                      <Form.Check inline label=" Leisure/Visitors" name="type_aggregation" type='radio' id='category' defaultChecked={(agg_traffic === 'category')} />
                    </div>
                    <div className="column-33">
                      <Form.Check inline label=" Both (Employees+Visitors)" name="type_aggregation" type='radio' id='both' defaultChecked={(agg_traffic === 'both')} />
                    </div>
                  </div>
                </Form>
                <h2>Enable potential based on</h2>
                <Form onChange={this.handlePotential.bind(this)}>
                  <div className="row">
                    <div className="column-enteprise-4-eq" style={{ width: '20px' }}>
                      <Form.Check inline label="" name="type_analysis" type='checkbox' id='color' checked={(color)} defaultChecked={(color)} disabled={disable_behaviour} />
                    </div>
                    <div className="column-enteprise-4-eq" style={{ width: '110px', paddingLeft: '10px' }}
                      onMouseOver={this.handleMouseOverB.bind(this)} onMouseOut={this.handleMouseOutB.bind(this)}>
                      <p className="label">Behaviour Change</p>
                    </div>
                    <div className="column-enteprise-4-eq" style={{ width: '30px' }}>
                      <div className="square" style={{ backgroundColor: "#390099" }}></div>
                    </div>
                    <div className="column-enteprise-4-eq" style={{ width: '20px' }}>
                      <Form.Check inline label="" name="type_analysis" type='checkbox' id='color_bike' checked={(color_bike)} defaultChecked={(color_bike)} disabled={disable_bike} />
                    </div>
                    <div className="column-enteprise-4-eq" style={{ width: '90px', paddingLeft: '10px' }}
                      onMouseOver={this.handleMouseOverBi.bind(this)} onMouseOut={this.handleMouseOutBi.bind(this)}>
                      <p className="label">Bicycle Culture</p>
                    </div>
                    <div className="column-enteprise-4-eq" style={{ width: '20px', paddingRight: '15px' }}>
                      <div className="square" style={{ backgroundColor: "#9E0059" }}></div>
                    </div>
                    <div className="column-enteprise-4-eq" style={{ width: '20px' }}>
                      <Form.Check inline label="" name="type_analysis" type='checkbox' id='color_last_mile' checked={(color_last_mile)} defaultChecked={(color_last_mile)} disabled={disable_last_mile} />
                    </div>
                    <div className="column-enteprise-4-eq" style={{ width: '50px', paddingLeft: '10px' }}
                      onMouseOver={this.handleMouseOverL.bind(this)} onMouseOut={this.handleMouseOutL.bind(this)}>
                      <p className="label">Last Mile</p>
                    </div>
                    <div className="column-enteprise-4-eq" style={{ width: '20px', paddingRight: '15px' }}>
                      <div className="square" style={{ backgroundColor: "#FF0054" }}></div>
                    </div>
                    <div className="column-enteprise-4-eq" style={{ width: '20px' }}>
                      <Form.Check inline label="" name="type_analysis" type='checkbox' id='color_working' checked={(color_working)} defaultChecked={(color_working)} disabled={disable_working_time} />
                    </div>
                    <div className="column-enteprise-4-eq" style={{ width: '80px', paddingLeft: '10px' }}
                      onMouseOver={this.handleMouseOverW.bind(this)} onMouseOut={this.handleMouseOutW.bind(this)}>
                      <p className="label">Working Time</p>
                    </div>
                    <div className="column-enteprise-4-eq" style={{ width: '10px', paddingRight: '20px' }}>
                      <div className="square" style={{ backgroundColor: "#FF5400" }}></div>
                    </div>
                    <div className="column-enteprise-4-eq" style={{ width: '20px' }}>
                      <Form.Check inline label="" name="type_analysis" type='checkbox' id='color_car_pooling' checked={(color_car_pooling)} disabled={disable_car_pooling} />
                    </div>
                    <div className="column-enteprise-4-eq" style={{ width: '70px', paddingLeft: '5px' }}
                      onMouseOver={this.handleMouseOverC.bind(this)} onMouseOut={this.handleMouseOutC.bind(this)}>
                      <p className="label">Car pooling</p>
                    </div>
                    <div className="column-enteprise-4-eq" style={{ width: '10px' }}>
                      <div className="square" style={{ backgroundColor: "#1AB5B2" }}></div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
            <div className='map-potential-3D' style={{ top: '270px' }}>
              <div className='map-potential-3D-inner' style={{ height: '210px' }}>
                <h2>Number of Employees within Tile</h2>
                <div className="row-input">
                  <div className="column-especial">
                    <Slider min={this.state.valueMinEmployees} max={this.state.valueTopEmployees} step={100} value={this.state.employees_num} onChange={_.debounce(this.handleNumEmployees.bind(this), 33)} />
                  </div>
                </div>
                <div className="row-input">
                  <p className='label-text' style={{ marginTop: '20px' }}>Showing tiles with minimum {numberWithCommas(Number(this.state.employees_num))} employees in all companies</p>
                </div>
                <h2 style={{ marginTop: '10px' }}>Number of Employees in a Company</h2>
                <div className="row-input">
                  <div className="column-especial">
                    <Slider dots min={0} max={3500} marks={marks} step={null} value={this.state.num_biggest_employee} onChange={_.debounce(this.handleNumEmployeesInCompany.bind(this), 33)} />
                  </div>
                  {/* <div className="column-2-20-especial">
                    <Form>
                      <Form.Check onChange={this.schemaNumEmployees.bind(this)}
                        type="switch"
                        id="custom-switch"
                        label="Filter by Biggest Employer"
                        checked={this.state.biggest_employee}
                      />
                    </Form>
                  </div> 
                  <div className="row-input">
                    <p className='label-text'>Showing tiles with minimum {numberWithCommas(Number(this.state.employees_num))} employees {(this.state.biggest_employee ? 'in the biggest employer' : 'in all companies')}</p>
                  </div> */}
                </div>
                <h2 style={{ marginTop: '40px' }}>Distance to main train station</h2>
                <div className="row-input">
                  <div className="column-especial">
                    <Slider range draggableTrack min={0} max={35000} step={100} value={[this.state.min_distance_train_station, this.state.max_distance_train_station]} onChange={_.debounce(this.handleDistanceTrainStation.bind(this), 33)} />
                  </div>
                  <div className="row-input">
                    <p className='label-text' style={{ marginTop: '10px' }}>Showing tiles between {numberWithCommas(this.state.min_distance_train_station)}m and {numberWithCommas(this.state.max_distance_train_station)}m to the train station</p>
                  </div>
                </div>
                <div className="row-input">
                  <Form onClick={this.handleUpdate.bind(this)}>
                    <Button variant="info" className="generate-btn">Update</Button>
                  </Form>
                </div>
              </div>
            </div>
            {behavior_pannel ?
              <div className="modal-information" style={{ top: '250px', left: '20px', width: '350px' }}>
                <div className="modal-information-inner">
                  <p className="label">(Trips with good ÖV - Trips with good ÖV * MS)Total Trips in the tile</p>
                  <hr />
                  <p className="label">Total Trips in the tile</p>
                </div>
              </div>
              :
              <></>
            }
            {last_mile_pannel ?
              <div className="modal-information" style={{ top: '250px', left: '200px', width: '320px' }}>
                <div className="modal-information-inner">
                  <p className="label">(Trips with good Last Mile - Trips with good Last Mile * MS)</p>
                  <hr />
                  <p className="label">Total Trips in the tile</p>
                </div>
              </div>
              :
              <></>
            }
            {working_pannel ?
              <div className="modal-information" style={{ top: '250px', left: '400px', width: '300px' }}>
                <div className="modal-information-inner">
                  <p className="label">Potential of trips 20min+ from work by ÖV, having the same time by car</p>
                </div>
              </div>
              :
              <></>
            }
            {car_pooling_pannel ?
              <div className="modal-information" style={{ top: '250px', left: '380px', width: '300px' }}>
                <div className="modal-information-inner">
                  <p className="label">Potential of trips with bad öV Quality adjust by number of employees and half the trips generated</p>
                </div>
              </div>
              :
              <></>
            }
            {bike_pannel ?
              <div className="modal-information" style={{ top: '250px', left: '280px', width: '300px' }}>
                <div className="modal-information-inner">
                  <p className="label">Potential of trips within a 5Km radius from their commuting (destination) place</p>
                </div>
              </div>
              :
              <></>
            }
          </div>
        }
        <HideElementsComponent map={this.state.mapita} updateField={(card, field, value) => this.updateField(card, field, value)} />
      </div>
    );
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => (
  // eslint-disable-next-line react/jsx-pascal-case
  <MapPotential_3D_BLS
    {...props}
    params={useParams()}
  />
); 