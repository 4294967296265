/* eslint-disable react/style-prop-object */
import React from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { RiFullscreenFill, RiFullscreenExitFill } from "react-icons/ri";
import { IconContext } from "react-icons";
import { BsLayersHalf } from "react-icons/bs";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Form, Button } from 'react-bootstrap';
import Select from 'react-select';
import { IoTrashOutline } from "react-icons/io5";
import axios from 'axios';

import '../../../components/layer-tyles.css';
import '../../css/employer-analyzer.css';
import RecommendationNavBarComponentHilti from "../../../components/RecommendationNavBarComponentHilti";

//Images - Tyles rendering
import catGreen from '../../../assets/img/pattern_tiles/cat1.png';
import catGreenYellow from '../../../assets/img/pattern_tiles/cat2.png';
import catYellow from '../../../assets/img/pattern_tiles/cat3.png';
import catYellowRed from '../../../assets/img/pattern_tiles/cat4.png';
import catRed from '../../../assets/img/pattern_tiles/cat5.png';
import catTriple from '../../../assets/img/pattern_tiles/cat6.png';
import catGreenRed from '../../../assets/img/pattern_tiles/cat7.png';
import catEmpty from '../../../assets/img/pattern_tiles/cat0.png';

import { useNavigate } from 'react-router-dom';
import { createContext, useContext } from 'react';

const NavigateContext = createContext();

function NavigateProvider({ children }) {
  const navigate = useNavigate();
  return (
    <NavigateContext.Provider value={navigate}>
      {children}
    </NavigateContext.Provider>
  );
}

const _ = require('lodash');

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
}

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const URL = process.env.REACT_APP_URL_SERVER_BACK;

const URL_lines = {
    'type': 'FeatureCollection',
    'features': [
        {
            'type': 'Feature',
            'geometry': {
                "type": "LineString",
                "coordinates": [
                ]
            }
        }
    ]
};

class HideElementsComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            hideNavs: false,
            mapa: this.props.map,
        }
    }

    hideComponent() {
        let newHideNavs = !this.state.hideNavs;
        this.setState({ hideNavs: newHideNavs })
        this.props.updateField("hideNavs", newHideNavs);
    }

    render() {

        const { hideNavs } = this.state;

        return (
            <div>
                {hideNavs ? <>
                    <div className='map-close-navs'>
                        <div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
                            <IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
                                <div>
                                    <RiFullscreenExitFill />
                                </div>
                            </IconContext.Provider>
                        </div>
                    </div>
                </> : <>
                    <div className='map-close-navs'>
                        <div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
                            <IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
                                <div>
                                    <RiFullscreenFill />
                                </div>
                            </IconContext.Provider>
                        </div>
                    </div>
                </>}
            </div>
        )
    }
}

//Layer Tyles Selection - Side Menu
class LayerTylesComponent extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            showOptions: false,
            mapa: this.props.map,
        }
        this.hideComponent = this.hideComponent.bind(this);
        this.handleTyleEvent = this.handleTyleEvent.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.map !== prevProps.map) {
            this.setState({ mapa: this.props.map })
        }
    }

    hideComponent() {
        this.setState({ showOptions: !this.state.showOptions });
    }

    handleTyleEvent(event) {
        switch (event) {
            case "default":
                this.props.handleTyle("mapbox://styles/mapbox/light-v10");
                break;
            case "outdoors":
                this.props.handleTyle("mapbox://styles/mapbox/outdoors-v11");
                break;
            case "satellite":
                this.props.handleTyle("mapbox://styles/mapbox/satellite-streets-v11");
                break;
            case "streets":
                this.props.handleTyle("mapbox://styles/mapbox/streets-v11");
                break;
            case "dark":
                this.props.handleTyle("mapbox://styles/mapbox/dark-v10");
                break;
            default:
                // eslint-disable-next-line no-unused-expressions
                null;
        }
        this.props.handleCoord(this.state.mapa.getCenter(), this.state.mapa.getZoom());
    }

    render() {
        const { showOptions } = this.state;

        return (
            <div>
                {showOptions ? <>
                    <div id='layer-options' className='map-layer-options-tyles'>
                        <div className='map-layer-option-dark' onClick={() => this.handleTyleEvent("dark")}>
                            <IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
                                <div>
                                    <img src={require('../../../assets/img/Dark.png')} alt="Default tyle" className='img-tyle' />
                                </div>
                            </IconContext.Provider>
                            <p className='label'>Dark</p>
                        </div>
                        <div className='map-layer-option-streets' onClick={() => this.handleTyleEvent("streets")}>
                            <IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
                                <div>
                                    <img src={require('../../../assets/img/Streets.png')} alt="Default tyle" className='img-tyle' />
                                </div>
                            </IconContext.Provider>
                            <p className='label'>Streets</p>
                        </div>
                        <div className='map-layer-option-satellite' onClick={() => this.handleTyleEvent("satellite")}>
                            <IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
                                <div>
                                    <img src={require('../../../assets/img/Satellite.png')} alt="Default tyle" className='img-tyle' />
                                </div>
                            </IconContext.Provider>
                            <p className='label'>Satellite</p>
                        </div>
                        <div className='map-layer-option-outdoors' onClick={() => this.handleTyleEvent("outdoors")}>
                            <IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
                                <div>
                                    <img src={require('../../../assets/img/Outdoors.png')} alt="Default tyle" className='img-tyle' />
                                </div>
                            </IconContext.Provider>
                            <p className='label'>Outdoors</p>
                        </div>
                        <div className='map-layer-option-default' onClick={() => this.handleTyleEvent("default")}>
                            <IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
                                <div>
                                    <img src={require('../../../assets/img/White.png')} alt="Default tyle" className='img-tyle' />
                                </div>
                            </IconContext.Provider>
                            <p className='label'>Default</p>
                        </div>
                    </div>
                </> : <></>}
                <div className='map-layer-tyles'>
                    <div className='map-layer-tyles-inner' onClick={() => this.hideComponent("showOptions")}>
                        <IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
                            <div>
                                <BsLayersHalf />
                            </div>
                        </IconContext.Provider>
                    </div>
                </div>
            </div>
        )
    }
}

class BtnsComponent extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            mapa: this.props.map,
            array_selected_headquaters: this.props.array_selected_headquaters,
            cat1_num_changes: 2,
            cat2_num_changes: 2,
            cat3_num_changes: 1,
            cat1_min_percentage: 200,
            cat1_max_percentage: 1000,
            cat2_min_percentage: 130,
            cat2_max_percentage: 200,
            cat3_min_percentage: 0,
            cat3_max_percentage: 130,
            max_min_than_car: 10,
            employers: this.getOptionsEmployers(),
            current_employer: null,
            canReset: false,
            all_employers_data: [],
            response_total: '',
            selection_distribution: 'general',
            total_trips: 0,
            total_pkm: 0,
            green_pkm: 0,
            green_trips: 0,
            yellow_pkm: 0,
            yellow_trips: 0,
            red_pkm: 0,
            red_trips: 0,
            selectedPKm: 'trips',
            green_cat_bike: 5000,
            yellow_cat_bike: 10000,

            percentage_pkm_green: 0,
            percentage_pkm_yellow: 0,
            percentage_pkm_red: 0,
            percentage_trips_green: 0,
            percentage_trips_red: 0,
            percentage_trips_yellow: 0,
            minutes_in_train: 20,
            body_url: '',
            traffic_mode: 'best_guess'
        }
    }

    componentDidMount() {

        this.handleCat1NumChanges = this.handleCat1NumChanges.bind(this);
        this.handleCat2NumChanges = this.handleCat2NumChanges.bind(this);
        this.handleCat3NumChanges = this.handleCat3NumChanges.bind(this);
        this.handleCat1MinPercentage = this.handleCat1MinPercentage.bind(this);
        this.handleCat1MaxPercentage = this.handleCat1MaxPercentage.bind(this);
        this.handleCat2MinPercentage = this.handleCat2MinPercentage.bind(this);
        this.handleCat2MaxPercentage = this.handleCat2MaxPercentage.bind(this);
        this.handleCat3MinPercentage = this.handleCat3MinPercentage.bind(this);
        this.handleCat3MaxPercentage = this.handleCat3MaxPercentage.bind(this);
        this.handleMaxMinutesCar = this.handleMaxMinutesCar.bind(this);
        this.handleYellowCatBike = this.handleYellowCatBike.bind(this);
        this.handleEmployers = this.handleEmployers.bind(this);
        this.handleEvaluate = this.handleEvaluate.bind(this);
        this.handleReset = this.handleReset.bind(this);
    }

    async getOptionsEmployers() {
        const res = await axios.get(URL + 'big_employer_analysis/employers')
        const data = res.data
        const options = [{value: 'Hilti AG', label: 'Hilti AG'},
        {value: 'Mobility Pact', label: 'Mobility Pact'}]
        this.setState({ employers: options, all_employers_data: data });
    }

    componentDidUpdate(prevProps) {
        if (this.props.map !== prevProps.map) {
            this.setState({ mapa: this.props.map });
        } else if (this.props.array_selected_headquaters !== prevProps.array_selected_headquaters) {
            this.setState({ array_selected_headquaters: this.props.array_selected_headquaters });
        }
    }
    handleGreenCatBike(event) {
        this.setState({ green_cat_bike: Number(event.target.value) })
    }

    handleYellowCatBike(event) {
        this.setState({ yellow_cat_bike: Number(event.target.value) })
    }

    handleCat1MinPercentage(event) {
        this.setState({ cat1_min_percentage: Number(event.target.value) })
    }

    handleMaxMinutesCar(event) {
        this.setState({ max_min_than_car: Number(event.target.value) })
    }

    handleCat1MaxPercentage(event) {
        this.setState({ cat1_max_percentage: Number(event.target.value) })
    }

    handleCat2MinPercentage(event) {
        this.setState({ cat2_min_percentage: Number(event.target.value) })
    }

    handleCat2MaxPercentage(event) {
        this.setState({ cat2_max_percentage: Number(event.target.value) })
    }

    handleCat3MinPercentage(event) {
        this.setState({ cat3_min_percentage: Number(event.target.value) })
    }

    handleCat3MaxPercentage(event) {
        this.setState({ cat3_max_percentage: Number(event.target.value) })
    }

    handleCat1NumChanges(event) {
        this.setState({ cat1_num_changes: Number(event.target.value) })
    }

    handleCat2NumChanges(event) {
        this.setState({ cat2_num_changes: Number(event.target.value) })
    }

    handleCat3NumChanges(event) {
        this.setState({ cat3_num_changes: Number(event.target.value) })
    }

    handleMinInTrains(event) {
        this.setState({ minutes_in_train: Number(event.target.value) })
    }

    handleReset() {
        this.setState({ canReset: false })
        this.setState({ body_url: '' })

        this.state.mapa.getSource('data-tiles-origins').setData(URL_lines);

        this.state.mapa.getSource('data-buldings-geometry').setData(URL_lines);

        this.state.mapa.setLayoutProperty('data-circles-headquarters', 'visibility', 'visible');
        this.state.mapa.setLayoutProperty('data-tiles-origins-general', 'visibility', 'none')
        this.state.mapa.setLayoutProperty('data-tiles-origins-lastmile', 'visibility', 'none')
        this.state.mapa.setLayoutProperty('data-tiles-origins-bike', 'visibility', 'none')
        this.state.mapa.setLayoutProperty('data-tiles-origins-combine', 'visibility', 'none')
        this.state.mapa.setLayoutProperty('data-tiles-origins-optimized_connection', 'visibility', 'none')

        this.state.mapa.setLayoutProperty('data-buldings-geometry', 'visibility', 'none');

        this.state.array_selected_headquaters.forEach(element => {
            this.state.mapa.setFeatureState({
                source: 'data-circles-headquarters',
                id: element.circle_map_id,
                generateId: true
            }, {
                clicked: false
            })
        });

        this.props.updateFields({ array_selected_headquaters: [], response_total: '' });
        this.setState({ current_employer: null })
        this.handleEmployers(null);
    }

    handleEmployers(event) {
        this.setState({ current_employer: event });

        this.state.array_selected_headquaters.forEach(element => {
            this.state.mapa.setFeatureState({
                source: 'data-circles-headquarters',
                id: element.circle_map_id,
                generateId: true
            }, {
                clicked: false
            })
        });

        this.props.updateFields({ array_selected_headquaters: [] });

        if (event != null) {
            let circle_ids = this.state.all_employers_data[event.value];

            let new_array = []

            circle_ids.forEach(element => {

                fetch(URL + 'big_employer_analysis/building_data/' + element)
                    .then((res) => res.json())
                    .then((json) => {
                        var features = this.state.mapa.querySourceFeatures('data-circles-headquarters', {
                            filter: ['==', 'osmid', json[0].osmid]
                        });

                        json[0]['circle_map_id'] = features[0].id
                        new_array.push(json[0]);

                        this.state.mapa.setFeatureState({
                            source: 'data-circles-headquarters',
                            id: json[0].circle_map_id,
                            generateId: true
                        }, {
                            clicked: true
                        });
                    }).then(() => this.props.updateFields({ array_selected_headquaters: new_array }))
            });
        }
    }

    handleEvaluate() {
        this.setState({ canReset: true })

        let headquaters_id = [];
        let data;
        this.state.array_selected_headquaters.map(selected_headquater => (
            headquaters_id.push(selected_headquater.osmid)
        ))

        this.setState({ body_url: ('{0}'.format()) })

        fetch(URL + 'big_employer_analysis/origins', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                circles_id: headquaters_id, threshold_duration_percentage_green: [this.state.cat3_min_percentage, this.state.cat3_max_percentage],
                threshold_duration_percentage_yellow: [this.state.cat2_min_percentage, this.state.cat2_max_percentage],
                threshold_duration_percentage_red: [this.state.cat1_min_percentage, this.state.cat1_max_percentage],
                max_difference_green: this.state.max_min_than_car, max_changes_green: this.state.cat3_num_changes,
                max_changes_yellow: this.state.cat2_num_changes, min_changes_red: this.state.cat1_num_changes,
                short_bike_radius: this.state.green_cat_bike, medium_bike_radius: this.state.yellow_cat_bike,
                minutes_in_train: this.state.minutes_in_train * 60, traffic_mode: this.state.traffic_mode
            })
        }).then((res) => res.json())
            .then((json) => {
                data = json;
            }).then(() => {
                this.state.mapa.getSource('data-tiles-origins').setData(data);
                this.state.mapa.setLayoutProperty('data-circles-headquarters', 'visibility', 'none');
                this.state.mapa.setLayoutProperty('outline-data-circles-headquarters', 'visibility', 'none');

                if (this.state.selection_distribution === 'general') {
                    this.state.mapa.setLayoutProperty('data-tiles-origins-general', 'visibility', 'visible')
                    this.state.mapa.setLayoutProperty('data-tiles-origins-lastmile', 'visibility', 'none')
                    this.state.mapa.setLayoutProperty('data-tiles-origins-bike', 'visibility', 'none')
                    this.state.mapa.setLayoutProperty('data-tiles-origins-working', 'visibility', 'none')
                    this.state.mapa.setLayoutProperty('data-tiles-origins-combine', 'visibility', 'none')
                    this.state.mapa.setLayoutProperty('data-tiles-origins-optimized_connection', 'visibility', 'none')
                } else if (this.state.selection_distribution === 'bike') {
                    this.state.mapa.setLayoutProperty('data-tiles-origins-general', 'visibility', 'none')
                    this.state.mapa.setLayoutProperty('data-tiles-origins-lastmile', 'visibility', 'none')
                    this.state.mapa.setLayoutProperty('data-tiles-origins-bike', 'visibility', 'visible')
                    this.state.mapa.setLayoutProperty('data-tiles-origins-working', 'visibility', 'none')
                    this.state.mapa.setLayoutProperty('data-tiles-origins-combine', 'visibility', 'none')
                    this.state.mapa.setLayoutProperty('data-tiles-origins-optimized_connection', 'visibility', 'none')
                } else if (this.state.selection_distribution === 'lastmile') {
                    this.state.mapa.setLayoutProperty('data-tiles-origins-general', 'visibility', 'none')
                    this.state.mapa.setLayoutProperty('data-tiles-origins-lastmile', 'visibility', 'visible')
                    this.state.mapa.setLayoutProperty('data-tiles-origins-bike', 'visibility', 'none')
                    this.state.mapa.setLayoutProperty('data-tiles-origins-working', 'visibility', 'none')
                    this.state.mapa.setLayoutProperty('data-tiles-origins-combine', 'visibility', 'none')
                    this.state.mapa.setLayoutProperty('data-tiles-origins-optimized_connection', 'visibility', 'none')
                } else if (this.state.selection_distribution === 'combine') {
                    this.state.mapa.setLayoutProperty('data-tiles-origins-general', 'visibility', 'none')
                    this.state.mapa.setLayoutProperty('data-tiles-origins-lastmile', 'visibility', 'none')
                    this.state.mapa.setLayoutProperty('data-tiles-origins-bike', 'visibility', 'none')
                    this.state.mapa.setLayoutProperty('data-tiles-origins-working', 'visibility', 'none')
                    this.state.mapa.setLayoutProperty('data-tiles-origins-optimized_connection', 'visibility', 'none')
                    this.state.mapa.setLayoutProperty('data-tiles-origins-combine', 'visibility', 'visible')
                } else if (this.state.selection_distribution === 'optimized_connection') {
                    this.state.mapa.setLayoutProperty('data-tiles-origins-general', 'visibility', 'none')
                    this.state.mapa.setLayoutProperty('data-tiles-origins-lastmile', 'visibility', 'none')
                    this.state.mapa.setLayoutProperty('data-tiles-origins-bike', 'visibility', 'none')
                    this.state.mapa.setLayoutProperty('data-tiles-origins-working', 'visibility', 'none')
                    this.state.mapa.setLayoutProperty('data-tiles-origins-combine', 'visibility', 'none')
                    this.state.mapa.setLayoutProperty('data-tiles-origins-optimized_connection', 'visibility', 'visible')
                }  else if (this.state.selection_distribution === 'working') {
                    this.state.mapa.setLayoutProperty('data-tiles-origins-general', 'visibility', 'none')
                    this.state.mapa.setLayoutProperty('data-tiles-origins-lastmile', 'visibility', 'none')
                    this.state.mapa.setLayoutProperty('data-tiles-origins-bike', 'visibility', 'none')
                    this.state.mapa.setLayoutProperty('data-tiles-origins-working', 'visibility', 'visible')
                    this.state.mapa.setLayoutProperty('data-tiles-origins-combine', 'visibility', 'none')
                    this.state.mapa.setLayoutProperty('data-tiles-origins-optimized_connection', 'visibility', 'none')
                }


            }).then(() => {
                fetch(URL + 'big_employer_analysis/buildings', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ circles_id: headquaters_id })
                }).then((res) => res.json())
                    .then((json) => {
                        data = json;
                    }).then(() => {
                        this.state.mapa.getSource('data-buldings-geometry').setData(data);
                        this.state.mapa.setLayoutProperty('data-buldings-geometry', 'visibility', 'visible')
                    })
                    .then(() => {
                        fetch(URL + 'big_employer_analysis/total', {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                circles_id: headquaters_id, threshold_duration_percentage_green: [this.state.cat3_min_percentage, this.state.cat3_max_percentage],
                                threshold_duration_percentage_yellow: [this.state.cat2_min_percentage, this.state.cat2_max_percentage],
                                threshold_duration_percentage_red: [this.state.cat1_min_percentage, this.state.cat1_max_percentage],
                                max_difference_green: this.state.max_min_than_car, max_changes_green: this.state.cat3_num_changes,
                                max_changes_yellow: this.state.cat2_num_changes, min_changes_red: this.state.cat1_num_changes,
                                short_bike_radius: this.state.green_cat_bike, medium_bike_radius: this.state.yellow_cat_bike,
                                minutes_in_train: this.state.minutes_in_train * 60, traffic_mode: this.state.traffic_mode
                            })
                        }).then((res) => res.json())
                            .then((json) => {
                                data = json;
                            }).then(() => {
                                this.setState({
                                    response_total: data,
                                });

                                let response_total = this.state.response_total;

                                if (this.state.selection_distribution === 'general') {
                                    this.setState({
                                        total_trips: response_total.total_trips,
                                        total_pkm: (response_total.total_pkm).toFixed(0),
                                        green_pkm: (response_total.green_pkm).toFixed(0),
                                        green_trips: response_total.green_trips,
                                        yellow_pkm: (response_total.yellow_pkm).toFixed(0),
                                        yellow_trips: response_total.yellow_trips,
                                        red_pkm: (response_total.red_pkm).toFixed(0),
                                        red_trips: response_total.red_trips,

                                        percentage_pkm_green: (((response_total.green_pkm / response_total.total_pkm) * 100).toFixed(0)),
                                        percentage_pkm_yellow: (((response_total.yellow_pkm / response_total.total_pkm) * 100).toFixed(0)),
                                        percentage_pkm_red: (((response_total.red_pkm / response_total.total_pkm) * 100).toFixed(0)),
                                        percentage_trips_green: (((response_total.green_trips / response_total.total_trips) * 100).toFixed(0)),
                                        percentage_trips_red: (((response_total.red_trips / response_total.total_trips) * 100).toFixed(0)),
                                        percentage_trips_yellow: (((response_total.yellow_trips / response_total.total_trips) * 100).toFixed(0))
                                    });
                                } else if (this.state.selection_distribution === 'bike') {
                                    this.setState({
                                        total_trips: response_total.total_trips,
                                        total_pkm: (response_total.total_pkm).toFixed(0),
                                        green_pkm: (response_total.bike_green_pkm).toFixed(0),
                                        green_trips: response_total.bike_green_trips,
                                        yellow_pkm: (response_total.bike_yellow_pkm).toFixed(0),
                                        yellow_trips: response_total.bike_yellow_trips,
                                        red_pkm: (response_total.bike_red_pkm).toFixed(0),
                                        red_trips: response_total.bike_red_trips,

                                        percentage_pkm_green: (((response_total.bike_green_pkm / response_total.total_pkm) * 100).toFixed(0)),
                                        percentage_pkm_yellow: (((response_total.bike_yellow_pkm / response_total.total_pkm) * 100).toFixed(0)),
                                        percentage_pkm_red: (((response_total.bike_red_pkm / response_total.total_pkm) * 100).toFixed(0)),
                                        percentage_trips_green: (((response_total.bike_green_trips / response_total.total_trips) * 100).toFixed(0)),
                                        percentage_trips_red: (((response_total.bike_red_trips / response_total.total_trips) * 100).toFixed(0)),
                                        percentage_trips_yellow: (((response_total.bike_yellow_trips / response_total.total_trips) * 100).toFixed(0))
                                    });
                                }
                                else if (this.state.selection_distribution === 'lastmile') {
                                    this.setState({
                                        total_trips: response_total.total_trips,
                                        total_pkm: (response_total.total_pkm).toFixed(0),
                                        green_pkm: (response_total.last_mile_green_pkm).toFixed(0),
                                        green_trips: response_total.last_mile_green_trips,
                                        yellow_pkm: (response_total.last_mile_yellow_pkm).toFixed(0),
                                        yellow_trips: response_total.last_mile_yellow_trips,
                                        red_pkm: (response_total.last_mile_red_pkm).toFixed(0),
                                        red_trips: response_total.last_mile_red_trips,

                                        percentage_pkm_green: (((response_total.last_mile_green_pkm / response_total.total_pkm) * 100).toFixed(0)),
                                        percentage_pkm_yellow: (((response_total.last_mile_yellow_pkm / response_total.total_pkm) * 100).toFixed(0)),
                                        percentage_pkm_red: (((response_total.last_mile_red_pkm / response_total.total_pkm) * 100).toFixed(0)),
                                        percentage_trips_green: (((response_total.last_mile_green_trips / response_total.total_trips) * 100).toFixed(0)),
                                        percentage_trips_red: (((response_total.last_mile_red_trips / response_total.total_trips) * 100).toFixed(0)),
                                        percentage_trips_yellow: (((response_total.last_mile_yellow_trips / response_total.total_trips) * 100).toFixed(0))
                                    });
                                } else if (this.state.selection_distribution === 'working') {
                                    this.setState({
                                        total_trips: response_total.total_trips,
                                        total_pkm: (response_total.total_pkm).toFixed(0),
                                        green_pkm: (response_total.working_green_pkm).toFixed(0),
                                        green_trips: response_total.working_green_trips,
                                        yellow_pkm: (response_total.working_yellow_pkm).toFixed(0),
                                        yellow_trips: response_total.working_yellow_trips,
                                        red_pkm: (response_total.working_red_pkm).toFixed(0),
                                        red_trips: response_total.working_red_trips,
                        
                                        percentage_pkm_green: (((response_total.working_green_pkm / response_total.total_pkm) * 100).toFixed(0)),
                                        percentage_pkm_yellow: (((response_total.working_yellow_pkm / response_total.total_pkm) * 100).toFixed(0)),
                                        percentage_pkm_red: (((response_total.working_red_pkm / response_total.total_pkm) * 100).toFixed(0)),
                                        percentage_trips_green: (((response_total.working_green_trips / response_total.total_trips) * 100).toFixed(0)),
                                        percentage_trips_red: (((response_total.working_red_trips / response_total.total_trips) * 100).toFixed(0)),
                                        percentage_trips_yellow: (((response_total.working_yellow_trips / response_total.total_trips) * 100).toFixed(0))
                                    });
                                } else if (this.state.selection_distribution === 'combine') {
                                    this.setState({
                                        total_trips: response_total.total_trips,
                                        total_pkm: (response_total.total_pkm).toFixed(0),
                                        green_pkm: (response_total.combined_green_pkm).toFixed(0),
                                        green_trips: response_total.combined_green_trips,
                                        yellow_pkm: (response_total.combined_yellow_pkm).toFixed(0),
                                        yellow_trips: response_total.combined_yellow_trips,
                                        red_pkm: (response_total.combined_red_pkm).toFixed(0),
                                        red_trips: response_total.combined_red_trips,
                        
                                        percentage_pkm_green: (((response_total.combined_green_pkm / response_total.total_pkm) * 100).toFixed(0)),
                                        percentage_pkm_yellow: (((response_total.combined_yellow_pkm / response_total.total_pkm) * 100).toFixed(0)),
                                        percentage_pkm_red: (((response_total.combined_red_pkm / response_total.total_pkm) * 100).toFixed(0)),
                                        percentage_trips_green: (((response_total.combined_green_trips / response_total.total_trips) * 100).toFixed(0)),
                                        percentage_trips_red: (((response_total.combined_red_trips / response_total.total_trips) * 100).toFixed(0)),
                                        percentage_trips_yellow: (((response_total.combined_yellow_trips / response_total.total_trips) * 100).toFixed(0))
                                    });
                                } else if (this.state.selection_distribution === 'optimized_connection') {
                                    this.setState({
                                        total_trips: response_total.total_trips,
                                        total_pkm: (response_total.total_pkm).toFixed(0),
                                        green_pkm: (response_total.optimized_connection_green_pkm).toFixed(0),
                                        green_trips: response_total.optimized_connection_green_trips,
                                        yellow_pkm: (response_total.optimized_connection_yellow_pkm).toFixed(0),
                                        yellow_trips: response_total.optimized_connection_yellow_trips,
                                        red_pkm: (response_total.optimized_connection_red_pkm).toFixed(0),
                                        red_trips: response_total.optimized_connection_red_trips,
                        
                                        percentage_pkm_green: (((response_total.optimized_connection_green_pkm / response_total.total_pkm) * 100).toFixed(0)),
                                        percentage_pkm_yellow: (((response_total.optimized_connection_yellow_pkm / response_total.total_pkm) * 100).toFixed(0)),
                                        percentage_pkm_red: (((response_total.optimized_connection_red_pkm / response_total.total_pkm) * 100).toFixed(0)),
                                        percentage_trips_green: (((response_total.optimized_connection_green_trips / response_total.total_trips) * 100).toFixed(0)),
                                        percentage_trips_red: (((response_total.optimized_connection_red_trips / response_total.total_trips) * 100).toFixed(0)),
                                        percentage_trips_yellow: (((response_total.optimized_connection_yellow_trips / response_total.total_trips) * 100).toFixed(0))
                                    });
                                }

                            })

                    });
            }
            );
    }

    handleVisualization(event) {
        this.setState({ selectedPKm: event.target.attributes.getNamedItem('id').value })
    }

    deleteElem(id_elem) {
        let elem = this.props.deleteElem(id_elem);

        //Delete shapes to the map
        this.state.mapa.setFeatureState({
            source: 'data-circles-headquarters',
            id: elem.circle_map_id,
        }, {
            clicked: false
        });
    }

    handleScheduleOptimization(event){
        this.setState({ schedule_optimization: event.target.attributes.getNamedItem('id').value })
    }

    handleTrafficMode(event){
        this.setState({ traffic_mode: event.target.attributes.getNamedItem('id').value })
    }

    handleDistribution(event) {
        this.setState({ selection_distribution: event.target.attributes.getNamedItem('id').value })

        if (event.target.attributes.getNamedItem('id').value === 'general') {
            this.state.mapa.setLayoutProperty('data-tiles-origins-general', 'visibility', 'visible')
            this.state.mapa.setLayoutProperty('data-tiles-origins-lastmile', 'visibility', 'none')
            this.state.mapa.setLayoutProperty('data-tiles-origins-bike', 'visibility', 'none')
            this.state.mapa.setLayoutProperty('data-tiles-origins-working', 'visibility', 'none')
            this.state.mapa.setLayoutProperty('data-tiles-origins-combine', 'visibility', 'none')
            this.state.mapa.setLayoutProperty('data-tiles-origins-optimized_connection', 'visibility', 'none')
        } else if (event.target.attributes.getNamedItem('id').value === 'bike') {
            this.state.mapa.setLayoutProperty('data-tiles-origins-general', 'visibility', 'none')
            this.state.mapa.setLayoutProperty('data-tiles-origins-lastmile', 'visibility', 'none')
            this.state.mapa.setLayoutProperty('data-tiles-origins-bike', 'visibility', 'visible')
            this.state.mapa.setLayoutProperty('data-tiles-origins-working', 'visibility', 'none')
            this.state.mapa.setLayoutProperty('data-tiles-origins-combine', 'visibility', 'none')
            this.state.mapa.setLayoutProperty('data-tiles-origins-optimized_connection', 'visibility', 'none')
        } else if (event.target.attributes.getNamedItem('id').value === 'lastmile') {
            this.state.mapa.setLayoutProperty('data-tiles-origins-general', 'visibility', 'none')
            this.state.mapa.setLayoutProperty('data-tiles-origins-lastmile', 'visibility', 'visible')
            this.state.mapa.setLayoutProperty('data-tiles-origins-bike', 'visibility', 'none')
            this.state.mapa.setLayoutProperty('data-tiles-origins-working', 'visibility', 'none')
            this.state.mapa.setLayoutProperty('data-tiles-origins-combine', 'visibility', 'none')
            this.state.mapa.setLayoutProperty('data-tiles-origins-optimized_connection', 'visibility', 'none')
        } else if (event.target.attributes.getNamedItem('id').value === 'working') {
            this.state.mapa.setLayoutProperty('data-tiles-origins-general', 'visibility', 'none')
            this.state.mapa.setLayoutProperty('data-tiles-origins-lastmile', 'visibility', 'none')
            this.state.mapa.setLayoutProperty('data-tiles-origins-bike', 'visibility', 'none')
            this.state.mapa.setLayoutProperty('data-tiles-origins-working', 'visibility', 'visible')
            this.state.mapa.setLayoutProperty('data-tiles-origins-combine', 'visibility', 'none')
            this.state.mapa.setLayoutProperty('data-tiles-origins-optimized_connection', 'visibility', 'none')
        } else if (event.target.attributes.getNamedItem('id').value === 'combine') {
            this.state.mapa.setLayoutProperty('data-tiles-origins-general', 'visibility', 'none')
            this.state.mapa.setLayoutProperty('data-tiles-origins-lastmile', 'visibility', 'none')
            this.state.mapa.setLayoutProperty('data-tiles-origins-bike', 'visibility', 'none')
            this.state.mapa.setLayoutProperty('data-tiles-origins-working', 'visibility', 'none')
            this.state.mapa.setLayoutProperty('data-tiles-origins-combine', 'visibility', 'visible')
            this.state.mapa.setLayoutProperty('data-tiles-origins-optimized_connection', 'visibility', 'none')
        } else if (event.target.attributes.getNamedItem('id').value === 'optimized_connection') {
            this.state.mapa.setLayoutProperty('data-tiles-origins-general', 'visibility', 'none')
            this.state.mapa.setLayoutProperty('data-tiles-origins-lastmile', 'visibility', 'none')
            this.state.mapa.setLayoutProperty('data-tiles-origins-bike', 'visibility', 'none')
            this.state.mapa.setLayoutProperty('data-tiles-origins-working', 'visibility', 'none')
            this.state.mapa.setLayoutProperty('data-tiles-origins-combine', 'visibility', 'none')
            this.state.mapa.setLayoutProperty('data-tiles-origins-optimized_connection', 'visibility', 'visible')
        }

        let response_total = this.state.response_total;

        if (event.target.attributes.getNamedItem('id').value === 'general') {
            this.setState({
                total_trips: response_total.total_trips,
                total_pkm: (response_total.total_pkm).toFixed(0),
                green_pkm: (response_total.green_pkm).toFixed(0),
                green_trips: response_total.green_trips,
                yellow_pkm: (response_total.yellow_pkm).toFixed(0),
                yellow_trips: response_total.yellow_trips,
                red_pkm: (response_total.red_pkm).toFixed(0),
                red_trips: response_total.red_trips,

                percentage_pkm_green: (((response_total.green_pkm / response_total.total_pkm) * 100).toFixed(0)),
                percentage_pkm_yellow: (((response_total.yellow_pkm / response_total.total_pkm) * 100).toFixed(0)),
                percentage_pkm_red: (((response_total.red_pkm / response_total.total_pkm) * 100).toFixed(0)),
                percentage_trips_green: (((response_total.green_trips / response_total.total_trips) * 100).toFixed(0)),
                percentage_trips_red: (((response_total.red_trips / response_total.total_trips) * 100).toFixed(0)),
                percentage_trips_yellow: (((response_total.yellow_trips / response_total.total_trips) * 100).toFixed(0))
            });
        } else if (event.target.attributes.getNamedItem('id').value === 'bike') {
            this.setState({
                total_trips: response_total.total_trips,
                total_pkm: (response_total.total_pkm).toFixed(0),
                green_pkm: (response_total.bike_green_pkm).toFixed(0),
                green_trips: response_total.bike_green_trips,
                yellow_pkm: (response_total.bike_yellow_pkm).toFixed(0),
                yellow_trips: response_total.bike_yellow_trips,
                red_pkm: (response_total.bike_red_pkm).toFixed(0),
                red_trips: response_total.bike_red_trips,

                percentage_pkm_green: (((response_total.bike_green_pkm / response_total.total_pkm) * 100).toFixed(0)),
                percentage_pkm_yellow: (((response_total.bike_yellow_pkm / response_total.total_pkm) * 100).toFixed(0)),
                percentage_pkm_red: (((response_total.bike_red_pkm / response_total.total_pkm) * 100).toFixed(0)),
                percentage_trips_green: (((response_total.bike_green_trips / response_total.total_trips) * 100).toFixed(0)),
                percentage_trips_red: (((response_total.bike_red_trips / response_total.total_trips) * 100).toFixed(0)),
                percentage_trips_yellow: (((response_total.bike_yellow_trips / response_total.total_trips) * 100).toFixed(0))
            });
        } else if (event.target.attributes.getNamedItem('id').value === 'lastmile') {
            this.setState({
                total_trips: response_total.total_trips,
                total_pkm: (response_total.total_pkm).toFixed(0),
                green_pkm: (response_total.last_mile_green_pkm).toFixed(0),
                green_trips: response_total.last_mile_green_trips,
                yellow_pkm: (response_total.last_mile_yellow_pkm).toFixed(0),
                yellow_trips: response_total.last_mile_yellow_trips,
                red_pkm: (response_total.last_mile_red_pkm).toFixed(0),
                red_trips: response_total.last_mile_red_trips,

                percentage_pkm_green: (((response_total.last_mile_green_pkm / response_total.total_pkm) * 100).toFixed(0)),
                percentage_pkm_yellow: (((response_total.last_mile_yellow_pkm / response_total.total_pkm) * 100).toFixed(0)),
                percentage_pkm_red: (((response_total.last_mile_red_pkm / response_total.total_pkm) * 100).toFixed(0)),
                percentage_trips_green: (((response_total.last_mile_green_trips / response_total.total_trips) * 100).toFixed(0)),
                percentage_trips_red: (((response_total.last_mile_red_trips / response_total.total_trips) * 100).toFixed(0)),
                percentage_trips_yellow: (((response_total.last_mile_yellow_trips / response_total.total_trips) * 100).toFixed(0))
            });
        } else if (event.target.attributes.getNamedItem('id').value === 'working') {
            this.setState({
                total_trips: response_total.total_trips,
                total_pkm: (response_total.total_pkm).toFixed(0),
                green_pkm: (response_total.working_green_pkm).toFixed(0),
                green_trips: response_total.working_green_trips,
                yellow_pkm: (response_total.working_yellow_pkm).toFixed(0),
                yellow_trips: response_total.working_yellow_trips,
                red_pkm: (response_total.working_red_pkm).toFixed(0),
                red_trips: response_total.working_red_trips,

                percentage_pkm_green: (((response_total.working_green_pkm / response_total.total_pkm) * 100).toFixed(0)),
                percentage_pkm_yellow: (((response_total.working_yellow_pkm / response_total.total_pkm) * 100).toFixed(0)),
                percentage_pkm_red: (((response_total.working_red_pkm / response_total.total_pkm) * 100).toFixed(0)),
                percentage_trips_green: (((response_total.working_green_trips / response_total.total_trips) * 100).toFixed(0)),
                percentage_trips_red: (((response_total.working_red_trips / response_total.total_trips) * 100).toFixed(0)),
                percentage_trips_yellow: (((response_total.working_yellow_trips / response_total.total_trips) * 100).toFixed(0))
            });
        } else if (event.target.attributes.getNamedItem('id').value === 'combine') {
            this.setState({
                total_trips: response_total.total_trips,
                total_pkm: (response_total.total_pkm).toFixed(0),
                green_pkm: (response_total.combined_green_pkm).toFixed(0),
                green_trips: response_total.combined_green_trips,
                yellow_pkm: (response_total.combined_yellow_pkm).toFixed(0),
                yellow_trips: response_total.combined_yellow_trips,
                red_pkm: (response_total.combined_red_pkm).toFixed(0),
                red_trips: response_total.combined_red_trips,

                percentage_pkm_green: (((response_total.combined_green_pkm / response_total.total_pkm) * 100).toFixed(0)),
                percentage_pkm_yellow: (((response_total.combined_yellow_pkm / response_total.total_pkm) * 100).toFixed(0)),
                percentage_pkm_red: (((response_total.combined_red_pkm / response_total.total_pkm) * 100).toFixed(0)),
                percentage_trips_green: (((response_total.combined_green_trips / response_total.total_trips) * 100).toFixed(0)),
                percentage_trips_red: (((response_total.combined_red_trips / response_total.total_trips) * 100).toFixed(0)),
                percentage_trips_yellow: (((response_total.combined_yellow_trips / response_total.total_trips) * 100).toFixed(0))
            });
        } else if (event.target.attributes.getNamedItem('id').value === 'optimized_connection') {
            this.setState({
                total_trips: response_total.total_trips,
                total_pkm: (response_total.total_pkm).toFixed(0),
                green_pkm: (response_total.optimized_connection_green_pkm).toFixed(0),
                green_trips: response_total.optimized_connection_green_trips,
                yellow_pkm: (response_total.optimized_connection_yellow_pkm).toFixed(0),
                yellow_trips: response_total.optimized_connection_yellow_trips,
                red_pkm: (response_total.optimized_connection_red_pkm).toFixed(0),
                red_trips: response_total.optimized_connection_red_trips,

                percentage_pkm_green: (((response_total.optimized_connection_green_pkm / response_total.total_pkm) * 100).toFixed(0)),
                percentage_pkm_yellow: (((response_total.optimized_connection_yellow_pkm / response_total.total_pkm) * 100).toFixed(0)),
                percentage_pkm_red: (((response_total.optimized_connection_red_pkm / response_total.total_pkm) * 100).toFixed(0)),
                percentage_trips_green: (((response_total.optimized_connection_green_trips / response_total.total_trips) * 100).toFixed(0)),
                percentage_trips_red: (((response_total.optimized_connection_red_trips / response_total.total_trips) * 100).toFixed(0)),
                percentage_trips_yellow: (((response_total.optimized_connection_yellow_trips / response_total.total_trips) * 100).toFixed(0))
            });
        }
    }
    static contextType = NavigateContext;

    handleClick = () => {
        let headquaters_id = [];
        this.state.array_selected_headquaters.map(selected_headquater => (
            headquaters_id.push(selected_headquater.osmid)
        ))

        this.context('/hilti/employer-analyzer-3D', { state: {
            circles_id: headquaters_id, threshold_duration_percentage_green: [this.state.cat3_min_percentage, this.state.cat3_max_percentage],
            threshold_duration_percentage_yellow: [this.state.cat2_min_percentage, this.state.cat2_max_percentage],
            threshold_duration_percentage_red: [this.state.cat1_min_percentage, this.state.cat1_max_percentage],
            max_difference_green: this.state.max_min_than_car, max_changes_green: this.state.cat3_num_changes,
            max_changes_yellow: this.state.cat2_num_changes, min_changes_red: this.state.cat1_num_changes,
            short_bike_radius: this.state.green_cat_bike, medium_bike_radius: this.state.yellow_cat_bike,
            minutes_in_train: this.state.minutes_in_train * 60, traffic_mode: this.state.traffic_mode
        } });
      }
    

    render() {

        const { selection_distribution } = this.state;
        const { selectedPKm } = this.state;

        const { green_pkm } = this.state;
        const { green_trips } = this.state;

        const { red_pkm } = this.state;
        const { red_trips } = this.state;

        const { yellow_pkm } = this.state;
        const { yellow_trips } = this.state;

        const { percentage_pkm_green } = this.state;
        const { percentage_pkm_yellow } = this.state;
        const { percentage_pkm_red } = this.state;

        const { percentage_trips_green } = this.state;
        const { percentage_trips_red } = this.state;
        const { percentage_trips_yellow } = this.state;

        const { traffic_mode } = this.state;

        const selectDate = {
            menuList: styles => {
                return {
                    ...styles,
                    maxHeight: 150,
                    borderWidth: 0,
                    boxShadow: '0 0 10px 2px rgb(0 0 0 / 10%)',
                };
            }
        };

        return (
            <div>
                {(this.state.canReset) ? <>
                    <div className="map-data-from" style={{ top: "340px" }}>
								<div className="map-data-from-inner">
									<div className="row">
										<p>Data computed based on Employer's Data and Google Maps routing algorithm </p>
									</div>
								</div>
							</div>
                    <div className="headquaters-visualization-percentage-modal">
                        <div className="headquaters-visualization-percentage-modal-inner" style={{width: '550px'}}>
                            <h2>Visualize contribution percentage by</h2>
                            <Form onChange={this.handleVisualization.bind(this)}>
                                <div className="row">
                                    <div className="column-1-eq">
                                        <Form.Check inline label=" Passenger Km" name="type_analysis" type='radio' id='pkm' defaultChecked={(selectedPKm === 'pkm')} />
                                    </div>
                                    <div className="column-2-eq">
                                        <Form.Check inline label=" Number of Trips" name="type_analysis" type='radio' id='trips' defaultChecked={(selectedPKm === 'trips')} />
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                    <div className="headquaters-visualization-type-modal">
                        <div className="headquaters-visualization-type-modal-inner" style={{width: '550px'}}>
                            <h2>Enable distribution by</h2>
                            <Form onChange={this.handleDistribution.bind(this)}>
                                <div className="row">
                                    <div className="column-headquarter-2" style={{width: '18%'}}>
                                        <Form.Check inline label=" Status Quo" name="type_analysis" type='radio' id='general' defaultChecked={(selection_distribution === 'general')} />
                                    </div>
                                    <div className="column-headquarter-3" style={{width: '16%'}}>
                                        <Form.Check inline label=" Last-Mile" name="type_analysis" type='radio' id='lastmile' defaultChecked={(selection_distribution === 'lastmile')} />
                                    </div>
                                    <div className="column-headquarter-4"  style={{width: '14%'}}>
                                        <Form.Check inline label=" Bicycle" name="type_analysis" type='radio' id='bike' defaultChecked={(selection_distribution === 'bike')} />
                                    </div>
                                    <div className="column-headquarter-4"  style={{width: '15%'}}>
                                        <Form.Check inline label=" Working" name="type_analysis" type='radio' id='working' defaultChecked={(selection_distribution === 'working')} />
                                    </div>
                                    <div className="column-headquarter-5"  style={{width: '17%'}}>
                                        <Form.Check inline label=" Combined" name="type_analysis" type='radio' id='combine' defaultChecked={(selection_distribution === 'optimized_connection')} />
                                    </div>
                                    <div className="column-headquarter-5"  style={{width: '18%'}}>
                                        <Form.Check inline label=" Schedule" name="type_analysis" type='radio' id='optimized_connection' defaultChecked={(selection_distribution === 'optimized_connection')} />
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                    {(selectedPKm === 'trips') ? <>
                        <div className="headquaters-btn-tile-potential-resp-1">
                            <div className="headquaters-btn-tile-potential-resp-1-inner">
                                <div className="row-parameters-3">
                                    <h3>{percentage_trips_green}% Trips</h3>
                                    <h4>{numberWithCommas(green_trips)} Trips</h4>
                                    <h5>Great ÖV Quality</h5>
                                </div>
                            </div>
                        </div>
                        <div className="headquaters-btn-tile-potential-resp-2">
                            <div className="headquaters-btn-tile-potential-resp-2-inner">
                                <div className="row-parameters-3">
                                    <h3>{percentage_trips_yellow}% Trips</h3>
                                    <h4>{numberWithCommas(yellow_trips)} Trips</h4>
                                    <h5>Decent ÖV Quality</h5>
                                </div>
                            </div>
                        </div>
                        <div className="headquaters-btn-tile-potential-resp-3">
                            <div className="headquaters-btn-tile-potential-resp-3-inner">
                                <div className="row-parameters-3">
                                    <h3>{percentage_trips_red}% Trips</h3>
                                    <h4>{numberWithCommas(red_trips)} Trips</h4>
                                    <h5>Bad ÖV Quality</h5>
                                </div>
                            </div>
                        </div>
                    </> : <>
                        <div className="headquaters-btn-tile-potential-resp-1">
                            <div className="headquaters-btn-tile-potential-resp-1-inner">
                                <div className="row-parameters-3">
                                    <h3>{percentage_pkm_green}% PKm</h3>
                                    <h4>{numberWithCommas(green_pkm)} PKm</h4>
                                    <h5>Great ÖV Quality</h5>
                                </div>
                            </div>
                        </div>
                        <div className="headquaters-btn-tile-potential-resp-2">
                            <div className="headquaters-btn-tile-potential-resp-2-inner">
                                <div className="row-parameters-3">
                                    <h3>{percentage_pkm_yellow}% PKm</h3>
                                    <h4>{numberWithCommas(yellow_pkm)} PKm</h4>
                                    <h5>Decent ÖV Quality</h5>
                                </div>
                            </div>
                        </div>
                        <div className="headquaters-btn-tile-potential-resp-3">
                            <div className="headquaters-btn-tile-potential-resp-3-inner">
                                <div className="row-parameters-3">
                                    <h3>{percentage_pkm_red}% PKm</h3>
                                    <h4>{numberWithCommas(red_pkm)} PKm</h4>
                                    <h5>Bad ÖV Quality</h5>
                                </div>
                            </div>
                        </div>
                    </>
                    }
                </> : <></>}
                <div className="modal-employers-analyzer">
                    <div className="modal-employers-analyzer-inner">
                        <div className="row-parameters">
                            <div className="column-1-55"><h2>Employers' Flow Analyzer</h2></div>
                            <div className="column-2-30">
                                <Form onClick={this.handleEvaluate.bind(this)}>
                                    <Button className="generate-btn" variant="info" disabled={!(this.state.array_selected_headquaters.length > 0)}>Evaluate</Button>
                                </Form>
                            </div>
                            <div className="column-3-15">
                                <Form onClick={this.handleReset.bind(this)}>
                                    <Button className="reset-btn" variant="info" disabled={!this.state.canReset}>Reset</Button>
                                </Form>
                            </div>
                        </div>
                        <br />
                        <div className="row-parameter-select">
                            <Select className='employers' isClearable={true} options={this.state.employers} value={this.state.current_employer} placeholder='Please select an employer' onChange={this.handleEmployers.bind(this)} styles={selectDate} />
                        </div>
                        <Form onChange={this.handleTrafficMode.bind(this)}>
                                <div className="row-parameter-select">
                                <p className="label-2">Car Travel Time</p>
                                    <div className="column-headquarter-2" style={{width: '25%'}}>
                                        <Form.Check inline label=" Optimistic" name="type_analysis" type='radio' id='optimistic' defaultChecked={(traffic_mode === 'optimistic')} />
                                    </div>
                                    <div className="column-headquarter-3" style={{width: '25%'}}>
                                        <Form.Check inline label=" Pessimistic" name="type_analysis" type='radio' id='pessimistic' defaultChecked={(traffic_mode === 'pessimistic')} />
                                    </div>
                                    <div className="column-headquarter-4"  style={{width: '25%'}}>
                                        <Form.Check inline label=" Best Guess" name="type_analysis" type='radio' id='best_guess' defaultChecked={(traffic_mode === 'best_guess')}/>
                                    </div>
                                    <div className="column-headquarter-4"  style={{width: '25%'}}>
                                        <Form.Check inline label=" Average" name="type_analysis" type='radio' id='average' defaultChecked={(traffic_mode === 'average')} />
                                    </div>
                                </div>
                            </Form>
                            <br/>
                        <Tabs
                            defaultActiveKey="category3"
                            id="uncontrolled-tab-example"
                            className="mb-3">
                            <Tab eventKey="category3" title="Green">
                            <p className="label">Based on the selected parameters, the green category vividly represents excellent public transport (ÖV Quality). This metric evaluates trams, trains, and buses collectively.</p>
                                <div className="row-parameters-2">
                                    <div className="column-1-40"><p className="label-2">Max. percentage longer than a car trip:</p></div>
                                    <div className="column-2-60"><input type="number" value={this.state.cat3_max_percentage} onChange={this.handleCat3MaxPercentage.bind(this)} /></div>
                                </div>
                                <p className="label">OR</p>
                                <div className="row-parameters-2">
                                    <div className="column-1-40"><p className="label-2">Max. time in minutes longer than a car trip:</p></div>
                                    <div className="column-2-60"><input type="number" value={this.state.max_min_than_car} onChange={this.handleMaxMinutesCar.bind(this)} /></div>
                                </div>
                                <hr />
                                <div className="row-parameters">
                                    <div className="column-1-40"><p className="label-2">Max. number of öv changes:</p></div>
                                    <div className="column-2-60"><input type="number" value={this.state.cat3_num_changes} onChange={this.handleCat3NumChanges.bind(this)} /></div>
                                </div>
                            </Tab>
                            <Tab eventKey="category2" title="Yellow">
                            <p className="label">The yellow category signifies decent public transport that has room for improvement (better or new last mile offers, end-to-end connections, and/or less number of öv changes).</p>
                                <div className="row-parameters-2">
                                    <div className="column-1-40"><p className="label-2">Min. percentage longer than a car trip:</p></div>
                                    <div className="column-2-60"><input type="number" value={this.state.cat2_min_percentage} onChange={this.handleCat2MinPercentage.bind(this)} /></div>
                                </div>
                                <div className="row-parameters-2">
                                    <div className="column-1-40"><p className="label-2">Max. percentage longer than a car trip:</p></div>
                                    <div className="column-2-60"><input type="number" value={this.state.cat2_max_percentage} onChange={this.handleCat2MaxPercentage.bind(this)} /></div>
                                </div>
                                <hr />
                                <div className="row-parameters">
                                    <div className="column-1-40"><p className="label-2">Max. number of öv changes:</p></div>
                                    <div className="column-2-60"><input type="number" value={this.state.cat2_num_changes} onChange={this.handleCat2NumChanges.bind(this)} /></div>
                                </div>
                            </Tab>
                            <Tab eventKey="category1" title="Red">
                            <p className="label">The red category denotes a bad public transport offer (öv quality). This could imply a considerable disparity in travel time compared to using private vehicles, a poor end-to-end offer or an extreme amount of public transport changes to complete the trips.</p>
                                <div className="row-parameters-2">
                                    <div className="column-1-40"><p className="label-2">Min. percentage longer than a car trip:</p></div>
                                    <div className="column-2-60"><input type="number" value={this.state.cat1_min_percentage} onChange={this.handleCat1MinPercentage.bind(this)} /></div>
                                </div>
                                <hr />
                                <div className="row-parameters">
                                    <div className="column-1-40"><p className="label-2">Max. number of öv changes:</p></div>
                                    <div className="column-2-60"><input type="number" value={this.state.cat1_num_changes} onChange={this.handleCat1NumChanges.bind(this)} /></div>
                                </div>
                            </Tab>
                            <Tab eventKey="bicycle" title="Cycling Distance">
                                <p className="label">The <i>Green Radius</i> represents the maximum distance covered by the 'Green Category', which indicates the best locations for performing last mile trips by bike. The <i>Yellow Radius</i> signifies the maximum distance for the 'Yellow Category', representing good but not optimal locations for biking the last mile.</p>
                                <hr />
                                <div className="row-parameters-2">
                                    <div className="column-1-40"><p className="label-2">Green radius:</p></div>
                                    <div className="column-2-60"><input type="number" value={this.state.green_cat_bike} onChange={this.handleGreenCatBike.bind(this)} /></div>
                                </div>
                                <div className="row-parameters-2">
                                    <div className="column-1-40"><p className="label-2">Yellow Radius:</p></div>
                                    <div className="column-2-60"><input type="number" value={this.state.yellow_cat_bike} onChange={this.handleYellowCatBike.bind(this)} /></div>
                                </div>
                            </Tab>
                            <Tab eventKey="working" title="Working Time">
                                <p className="label-3">Enable your employees to work during the trip</p>
                                <p className="label">By activating this feature, tiles with train travel times longer than the specified number will be highlighted in green. This helps identify origin points that provide sufficient travel time, allowing employees to work while on the train.</p>

                                <div className="row-parameters-2">
                                    <div className="column-1-40"><p className="label-2">Minimun number of minutes:</p></div>
                                    <div className="column-2-60"><input type="number" value={this.state.minutes_in_train} onChange={this.handleMinInTrains.bind(this)} /></div>
                                </div>
                            </Tab>
                        </Tabs>
                        <div className="row-headquarter-3dview">
                            <Button className="co2-3dview-btn" variant="info" onClick={this.handleClick} disabled={!(this.state.array_selected_headquaters.length > 0 && this.state.canReset)}>3D View Analysis</Button>
                        </div>
                    </div>
                </div>
                {this.state.array_selected_headquaters.length > 0 ? <>
                    <div className="modal-selected-headquaters">
                        <div className="modal-selected-headquaters-inner">
                            <div className="fixed-box-headquaters">
                                <div className="timeline-headquaters" >
                                    <div>
                                        {this.state.array_selected_headquaters.map(selected_headquaters => (
                                            <div className="container right" key={selected_headquaters.osmid}>
                                                <div className="content">
                                                    <div className="row">
                                                        <div className="column-1">
                                                            <p className="headquarters-employer">{selected_headquaters['employer']}</p>
                                                            <p className="headquarters-address">{selected_headquaters['address']}</p>
                                                        </div>
                                                        <div className="column-2">
                                                            <div className="deleteElement" onClick={() => this.deleteElem(selected_headquaters.circle_map_id)}>
                                                                <IconContext.Provider value={{ color: "#027f80", size: '1.6em' }}>
                                                                    <div>
                                                                        <IoTrashOutline />
                                                                    </div>
                                                                </IconContext.Provider>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </> : <>
                    <div className="modal-no-headquaters">
                        <div className="modal-no-headquaters-inner">
                            <p className="no-data"><i>Please a company or the headquaters you will like to analyze</i></p>
                        </div>
                    </div>
                </>}
            </div>
        )
    }
}

//Map - Employer Analyzer
class Hilti_MapEmployerAnalyzer extends React.Component {

    // Set up states for updating map 
    constructor(props) {
        super(props);
        this.state = {
            lng: 9.50261, 
			lat: 47.12555,
			zoom: 9.8,
			mapita: 9,
            tyle: 'mapbox://styles/mapbox/light-v10',
            hideNavs: false,
            array_selected_headquaters: []
        }
    }

    //Update several fields
    updateFields(list) {
        this.setState(list);
    }

    //Update map on component update
    updateField(card, value) {
        this.setState({
            ...this.state,
            [card]: value
        });
    }

    //Update style
    handleTyle(value) {
        this.setState({ tyle: value }, this.componentDidMount);
    }

    //Update coordinates
    handleCoord(center, zoom) {
        const { lng, lat } = center;
        this.setState({ zoom: zoom, lat: lat, lng: lng })
    }

    //Delete element on the array of selected stations
    deleteElem(id_elem) {

        const target = this.state.array_selected_headquaters.find(e => e.circle_map_id === id_elem);
        const index = this.state.array_selected_headquaters.indexOf(target);
        if (index > -1) { // only splice array when item is found
            let array_without_elem = this.state.array_selected_headquaters
            array_without_elem.splice(index, 1) // 2nd parameter means remove one item only
            this.setState({ array_selected_headquaters: array_without_elem });
        }
        return target;
    }

    componentDidMount() {
        // Set up map 
        const map = new mapboxgl.Map({
            container: this.mapContainer,
            style: this.state.tyle,
            center: [this.state.lng, this.state.lat],
            zoom: this.state.zoom,
        });

        map.on('load', () => {
            // Add zoom and rotation controls to the map.
            map.addControl(new mapboxgl.NavigationControl({ showCompass: false, showZoom: false }), 'top-left');
            map.addControl(new MapboxGeocoder({ accessToken: mapboxgl.accessToken, mapboxgl: mapboxgl, marker: false }), 'top-left');

            map.loadImage(catGreen, (error, image) => {
                if (error) throw error;
                map.addImage('cat1', image);
            });

            map.loadImage(catGreenYellow, (error, image) => {
                if (error) throw error;
                map.addImage('cat2', image);
            });

            map.loadImage(catYellow, (error, image) => {
                if (error) throw error;
                map.addImage('cat3', image);
            });

            map.loadImage(catYellowRed, (error, image) => {
                if (error) throw error;
                map.addImage('cat4', image);
            });

            map.loadImage(catRed, (error, image) => {
                if (error) throw error;
                map.addImage('cat5', image);
            });

            map.loadImage(catTriple, (error, image) => {
                if (error) throw error;
                map.addImage('cat6', image);
            });

            map.loadImage(catEmpty, (error, image) => {
                if (error) throw error;
                map.addImage('cat0', image);
            });

            map.loadImage(catGreenRed, (error, image) => {
                if (error) throw error;
                map.addImage('cat7', image);
            });

            //Circles - Stations
            //Add shapes to the map
            map.addSource('data-circles-headquarters', {
                'type': 'geojson',
                'data': URL + 'big_employer_analysis/destinations',
                'generateId': true
            });

            map.addLayer({
                'id': 'data-circles-headquarters',
                'type': 'fill',
                'source': 'data-circles-headquarters',
                'layout': {
                },
                'paint': {
                    'fill-color': [
                        'case',
                        ['boolean', ['feature-state', 'clicked'], false],
                        '#eb34b1', // else paint
                        ['get', 'color'] // if selected true, paint in pink
                    ],
                    "fill-opacity": 0.5,
                },
            });

            map.addLayer({
                'id': 'outline-data-circles-headquarters',
                'type': 'line',
                'source': 'data-circles-headquarters',
                'layout': {},
                'paint': {
                    'line-color': [
                        'case',
                        ['boolean', ['feature-state', 'clicked'], false],
                        '#eb34b1', // else paint
                        ['get', 'color'] // if selected true, paint in pink
                    ],
                    'line-width': 1
                }
            });

            map.addSource('data-tiles-origins', {
                'type': 'geojson',
                'data': URL_lines,
                'generateId': true
            });


            map.addLayer({
                'id': 'data-tiles-origins-general',
                'type': 'fill',
                'source': 'data-tiles-origins',
                'layout': {
                    'visibility': "visible"
                },
                'paint': {
                    "fill-pattern": [
                        'case',
                        ['==', ['string', ['get', 'fill_pattern']], 'catEmpty'],
                        'cat0',
                        ['==', ['string', ['get', 'fill_pattern']], 'catGreen'],
                        'cat1',
                        ['==', ['string', ['get', 'fill_pattern']], 'catGreenYellow'],
                        'cat2',
                        ['==', ['string', ['get', 'fill_pattern']], 'catYellow'],
                        'cat3',
                        ['==', ['string', ['get', 'fill_pattern']], 'catYellowRed'],
                        'cat4',
                        ['==', ['string', ['get', 'fill_pattern']], 'catRed'],
                        'cat5',
                        ['==', ['string', ['get', 'fill_pattern']], 'catTriple'],
                        'cat6',
                        ['==', ['string', ['get', 'fill_pattern']], 'catGreenRed'],
                        'cat7',
                        'cat0'
                    ],
                    'fill-opacity': 0.6
                }
            });

            map.addLayer({
                'id': 'data-tiles-origins-lastmile',
                'type': 'fill',
                'source': 'data-tiles-origins',
                'layout': {
                    'visibility': "none"
                },
                'paint': {
                    "fill-pattern": [
                        'case',
                        ['==', ['string', ['get', 'last_mile_fill_pattern']], 'catEmpty'],
                        'cat0',
                        ['==', ['string', ['get', 'last_mile_fill_pattern']], 'catGreen'],
                        'cat1',
                        ['==', ['string', ['get', 'last_mile_fill_pattern']], 'catGreenYellow'],
                        'cat2',
                        ['==', ['string', ['get', 'last_mile_fill_pattern']], 'catYellow'],
                        'cat3',
                        ['==', ['string', ['get', 'last_mile_fill_pattern']], 'catYellowRed'],
                        'cat4',
                        ['==', ['string', ['get', 'last_mile_fill_pattern']], 'catRed'],
                        'cat5',
                        ['==', ['string', ['get', 'last_mile_fill_pattern']], 'catTriple'],
                        'cat6',
                        ['==', ['string', ['get', 'last_mile_fill_pattern']], 'catGreenRed'],
                        'cat7',
                        'cat0'
                    ],
                    'fill-opacity': 0.6
                }
            });

            
            map.addLayer({
                'id': 'data-tiles-origins-optimized_connection',
                'type': 'fill',
                'source': 'data-tiles-origins',
                'layout': {
                    'visibility': "none"
                },
                'paint': {
                    "fill-pattern": [
                        'case',
                        ['==', ['string', ['get', 'optimized_connection_fill_pattern']], 'catEmpty'],
                        'cat0',
                        ['==', ['string', ['get', 'optimized_connection_fill_pattern']], 'catGreen'],
                        'cat1',
                        ['==', ['string', ['get', 'optimized_connection_fill_pattern']], 'catGreenYellow'],
                        'cat2',
                        ['==', ['string', ['get', 'optimized_connection_fill_pattern']], 'catYellow'],
                        'cat3',
                        ['==', ['string', ['get', 'optimized_connection_fill_pattern']], 'catYellowRed'],
                        'cat4',
                        ['==', ['string', ['get', 'optimized_connection_fill_pattern']], 'catRed'],
                        'cat5',
                        ['==', ['string', ['get', 'optimized_connection_fill_pattern']], 'catTriple'],
                        'cat6',
                        ['==', ['string', ['get', 'optimized_connection_fill_pattern']], 'catGreenRed'],
                        'cat7',
                        'cat0'
                    ],
                    'fill-opacity': 0.6
                }
            });

            map.addLayer({
                'id': 'data-tiles-origins-combine',
                'type': 'fill',
                'source': 'data-tiles-origins',
                'layout': {
                    'visibility': "none"
                },
                'paint': {
                    "fill-pattern": [
                        'case',
                        ['==', ['string', ['get', 'combined_fill_pattern']], 'catEmpty'],
                        'cat0',
                        ['==', ['string', ['get', 'combined_fill_pattern']], 'catGreen'],
                        'cat1',
                        ['==', ['string', ['get', 'combined_fill_pattern']], 'catGreenYellow'],
                        'cat2',
                        ['==', ['string', ['get', 'combined_fill_pattern']], 'catYellow'],
                        'cat3',
                        ['==', ['string', ['get', 'combined_fill_pattern']], 'catYellowRed'],
                        'cat4',
                        ['==', ['string', ['get', 'combined_fill_pattern']], 'catRed'],
                        'cat5',
                        ['==', ['string', ['get', 'combined_fill_pattern']], 'catTriple'],
                        'cat6',
                        ['==', ['string', ['get', 'combined_fill_pattern']], 'catGreenRed'],
                        'cat7',
                        'cat0'
                    ],
                    'fill-opacity': 0.6
                }
            });

            map.addLayer({
                'id': 'data-tiles-origins-bike',
                'type': 'fill',
                'source': 'data-tiles-origins',
                'layout': {
                    'visibility': "none"
                },
                'paint': {
                    "fill-pattern": [
                        'case',
                        ['==', ['string', ['get', 'bike_fill_pattern']], 'catEmpty'],
                        'cat0',
                        ['==', ['string', ['get', 'bike_fill_pattern']], 'catGreen'],
                        'cat1',
                        ['==', ['string', ['get', 'bike_fill_pattern']], 'catGreenYellow'],
                        'cat2',
                        ['==', ['string', ['get', 'bike_fill_pattern']], 'catYellow'],
                        'cat3',
                        ['==', ['string', ['get', 'bike_fill_pattern']], 'catYellowRed'],
                        'cat4',
                        ['==', ['string', ['get', 'bike_fill_pattern']], 'catRed'],
                        'cat5',
                        ['==', ['string', ['get', 'bike_fill_pattern']], 'catTriple'],
                        'cat6',
                        ['==', ['string', ['get', 'bike_fill_pattern']], 'catGreenRed'],
                        'cat7',
                        'cat0'
                    ],
                    'fill-opacity': 0.6
                }
            });

            map.addLayer({
                'id': 'data-tiles-origins-working',
                'type': 'fill',
                'source': 'data-tiles-origins',
                'layout': {
                    'visibility': "none"
                },
                'paint': {
                    "fill-pattern": [
                        'case',
                        ['==', ['string', ['get', 'working_fill_pattern']], 'catEmpty'],
                        'cat0',
                        ['==', ['string', ['get', 'working_fill_pattern']], 'catGreen'],
                        'cat1',
                        ['==', ['string', ['get', 'working_fill_pattern']], 'catGreenYellow'],
                        'cat2',
                        ['==', ['string', ['get', 'working_fill_pattern']], 'catYellow'],
                        'cat3',
                        ['==', ['string', ['get', 'working_fill_pattern']], 'catYellowRed'],
                        'cat4',
                        ['==', ['string', ['get', 'working_fill_pattern']], 'catRed'],
                        'cat5',
                        ['==', ['string', ['get', 'working_fill_pattern']], 'catTriple'],
                        'cat6',
                        ['==', ['string', ['get', 'working_fill_pattern']], 'catGreenRed'],
                        'cat7',
                        'cat0'
                    ],
                    'fill-opacity': 0.6
                }
            });

            map.addLayer({
                'id': 'outline-data-tiles-origins',
                'type': 'line',
                'source': 'data-tiles-origins',
                'layout': {},
                'paint': {
                    'line-color': '#ffffff',
                    'line-width': 1
                }
            });

            map.addLayer({
                'id': 'data-tiles-origins-labels',
                'type': 'symbol',
                'source': 'data-tiles-origins',
                "minzoom": 10.5,
                'layout': {
                    'text-field': ['get', 'trips'],
                    'text-font': [
                        'Open Sans Bold',
                        'Arial Unicode MS Bold'
                    ],
                    'text-size': 15,
                    'text-transform': 'uppercase',
                    'text-letter-spacing': 0.05,
                    'text-offset': [0, 1],
                    'visibility': 'visible'
                }
            });

            map.addSource('data-buldings-geometry', {
                'type': 'geojson',
                'data': URL_lines,
                'generateId': true
            });

            map.addLayer({
                'id': 'data-buldings-geometry',
                'type': 'fill',
                'source': 'data-buldings-geometry',
                'layout': {
                    'visibility': "visible"
                },
                'paint': {
                    'fill-color': '#9d1f6c',
                    'fill-opacity': 0.15
                }
            });

            // Add a black outline around the polygon
            map.addLayer({
                'id': 'outline-data-buldings-geometry',
                'type': 'line',
                'source': 'data-buldings-geometry',
                'layout': {
                    'visibility': 'visible'
                },
                'paint': {
                    'line-color': '#9d1f6c',
                    'line-width': 1
                }
            });

        });

        this.setState({
            mapita: map,
            zoom: map.getZoom(),
        });

        // Create a popup, but don't add it to the map yet.
        const popupHover = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false
        });

        // Change the cursor to a pointer when the mouse is over the places layer.
        map.on('mouseenter', 'data-circles-headquarters', (e) => {
            map.getCanvas().style.cursor = 'pointer';
            let properties = e.features[0].properties

            let html = '<h4 style="margin-block-start:0px; margin-block-end:0px;">' + properties.employer + '</h4><p style="margin-block-start:0px; margin-block-end: 0em;">' + properties.address + '</p><p style="margin-block-start:0px; margin-block-end: 0em;"><span style="font-weight: bold;">Trips: </span>' + numberWithCommas(properties.trips) + '</p>';
            popupHover
                .setLngLat(e.lngLat)
                .setHTML(html).addTo(map);
        });

        map.on('click', 'data-tiles-origins-general', (e) => {
            let properties = e.features[0].properties

            let html = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Tile number - ' + properties.origin + '</span></p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Trips: </span>' + properties.trips + '</p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Travel times in trains: </span>' + properties.duration_train_text + '</p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Travel time in cars: </span>' + properties.duration_car_text + '</p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Passenger Km: </span>' + numberWithCommas(properties.pkm.toFixed(0)) + '</p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Employer: </span>' + properties.employer + '</p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Address: </span>' + properties.destination + '</p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Improved Last Mile Time: </span>' + properties.duration_last_mile_text + '</p>';
            new mapboxgl.Popup()
                .setLngLat(e.lngLat)
                .setHTML(html).addTo(map);
        });

        map.on('click', 'data-tiles-origins-lastmile', (e) => {
            let properties = e.features[0].properties

            let html = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Tile number - ' + properties.origin + '</span></p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Trips: </span>' + properties.trips + '</p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Travel times in trains: </span>' + properties.duration_train_text + '</p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Travel time in cars: </span>' + properties.duration_car_text + '</p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Passenger Km: </span>' + numberWithCommas(properties.pkm.toFixed(0)) + '</p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Employer: </span>' + properties.employer + '</p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Address: </span>' + properties.destination + '</p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Improved Last Mile Time: </span>' + properties.duration_last_mile_text + '</p>';
            new mapboxgl.Popup()
                .setLngLat(e.lngLat)
                .setHTML(html).addTo(map);
        });

        map.on('click', 'data-tiles-origins-bike', (e) => {
            let properties = e.features[0].properties

            let html = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Tile number - ' + properties.origin + '</span></p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Trips: </span>' + properties.trips + '</p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Travel times in trains: </span>' + properties.duration_train_text + '</p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Travel time in cars: </span>' + properties.duration_car_text + '</p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Passenger Km: </span>' + numberWithCommas(properties.pkm.toFixed(0)) + '</p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Employer: </span>' + properties.employer + '</p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Address: </span>' + properties.destination + '</p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Improved Last Mile Time: </span>' + properties.duration_last_mile_text + '</p>';
            new mapboxgl.Popup()
                .setLngLat(e.lngLat)
                .setHTML(html).addTo(map);
        });

        map.on('click', 'data-tiles-origins-combine', (e) => {
            let properties = e.features[0].properties

            let html = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Tile number - ' + properties.origin + '</span></p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Trips: </span>' + properties.trips + '</p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Travel times in trains: </span>' + properties.duration_train_text + '</p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Travel time in cars: </span>' + properties.duration_car_text + '</p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Passenger Km: </span>' + numberWithCommas(properties.pkm.toFixed(0)) + '</p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Employer: </span>' + properties.employer + '</p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Address: </span>' + properties.destination + '</p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Improved Last Mile Time: </span>' + properties.duration_last_mile_text + '</p>';
            new mapboxgl.Popup()
                .setLngLat(e.lngLat)
                .setHTML(html).addTo(map);
        });

        map.on('click', 'data-tiles-origins-optimized_connection', (e) => {
            let properties = e.features[0].properties

            let html = '<p style="margin-block-end: 0em;"><span style="font-weight: bold;">Tile number - ' + properties.origin + '</span></p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Trips: </span>' + properties.trips + '</p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Travel times in trains: </span>' + properties.duration_train_text + '</p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Travel time in cars: </span>' + properties.duration_car_text + '</p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Passenger Km: </span>' + numberWithCommas(properties.pkm.toFixed(0)) + '</p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Employer: </span>' + properties.employer + '</p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Address: </span>' + properties.destination + '</p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Improved Last Mile Time: </span>' + properties.duration_last_mile_text + '</p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Duration for Optimized Connection: </span>' + properties.duration_optimized_connection_text + '</p>';
            new mapboxgl.Popup()
                .setLngLat(e.lngLat)
                .setHTML(html).addTo(map);
        });

        // Change it back to a pointer when it leaves.
        map.on('mouseleave', 'data-circles-headquarters', () => {
            map.getCanvas().style.cursor = '';
            popupHover.remove();
        });

    }

    render() {
        const { hideNavs } = this.state;

        return (
            <div>
                <NavigateProvider>
                <div ref={el => this.mapContainer = el} style={{ width: '100%', height: '100vh' }}>
                </div>
                <RecommendationNavBarComponentHilti />
                <HideElementsComponent map={this.state.mapita} updateField={(card, field, value) => this.updateField(card, field, value)} />
                <div style={{ display: hideNavs ? 'none' : 'block' }}>
                        <BtnsComponent map={this.state.mapita} updateFields={(list) => this.updateFields(list)} array_selected_headquaters={this.state.array_selected_headquaters} deleteElem={(id_elem) => this.deleteElem(id_elem)} updateField={(card, field, value) => this.updateField(card, field, value)} navigate={this.props.navigate}/>
                    </div>
                <LayerTylesComponent map={this.state.mapita} tyle={this.state.tyle} handleTyle={(value) => this.handleTyle(value)} handleCoord={(value1, value2) => this.handleCoord(value1, value2)} />
                </NavigateProvider>
            </div>
        )
    }
}

export default  Hilti_MapEmployerAnalyzer;