
import React from "react";
import { Map } from "react-map-gl";
import DeckGL, { GeoJsonLayer, PolygonLayer } from "deck.gl";
import { scaleThreshold } from "d3-scale";
import axios from 'axios';
import RecommendationNavBarComponent from "../../components/RecommendationNavBarComponent";
import Select from 'react-select';
import Slider from 'rc-slider';
import { Form, Button } from 'react-bootstrap';

import '../css/map-recommendation-deckgl.css';

const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const URL = process.env.REACT_APP_URL_SERVER_BACK;

const _ = require('lodash');

export const COLOR_SCALE = scaleThreshold()
.domain([0, 0.01, 0.05, 0.1, 0.15, 0.3, 70, 100, 300, 700, 1000, 3000, 10000])
.range([
  [128, 0, 38],
  [189, 0, 38],
  [188, 32, 35],
  [212, 50, 40],
  [235, 68, 44],
  [242, 124, 40],
  [248, 179, 36],
  [130, 143, 46],
  [71, 125, 51],
  [12, 107, 55],
  [12, 107, 55],
  [13, 99, 52],
  [14, 91, 48],
]);

function numberWithCommas(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const landCover = [
  [
    [5.756621,45.514933],
    [10.683990,45.514933],
    [5.756621,47.961350],
    [10.683990,47.961350]
  ]
];

const INITIAL_VIEW_STATE = {
  latitude: 46.924143,
  longitude: 8.251313,
  zoom: 7.2,
  maxZoom: 16,
  pitch: 45,
  bearing: 0
};

class MapWhatIf_MS_3D extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
			division_options_: [{
				"value" : "plz",
				"label" : 'Postal Code'
			  },
        {
          "value" : "bfs",
          "label" : 'Municipality'
        } ],
			value_division_options: ({
				"value" : "plz",
				"label" : 'Postal Code'
			  }),
      db_od_trips_: this.getOptionsDate(URL+'db_od_trips'),
      URL_CO2: URL+"what_if/ms/null.geojson",
      URL_CO2_Link: URL+"what_if/ms/null.geojson",
      showDescription: true,
      percentage: 5,
      percentage_decimal: 0.05,
      spatial_resolution_bfs_: this.getOptions(URL+'municipalities-list'),
      spatial_resolution_plz_: this.getOptionsPLZ(URL+'plz-list'),
      value_date: ({
				"value" : "2022-08",
				"label" : '2022-08'
			  }),
      value_division: ({
        "value" : "plz",
        "label" : 'Postal Code'
      }),
      isBFS: false,
      isOrigin: false,
      isDestination: false,
      resp: false,
      origin: 'all',
      destination: 'all',
      loading: false,
      hasLocation: false,
      response: '',
    	}
  }

  async getOptions(url){
		const res = await axios.get(url)
		const data = res.data
		const options = data.map(d => ({
		  "value" : d.id,
		  "label" : d.name
		}))
		this.setState({spatial_resolution_bfs_: options})
	}

  async getOptionsPLZ(url){
		const res = await axios.get(url)
		const data = res.data
		const options = data.map(d => ({
		  "value" : d._id.PLZ,
		  "label" : d._id.Name +" ("+d._id.PLZ+")"
		}))
		this.setState({spatial_resolution_plz_: options})
	}

  _renderLayers() {
    const layer = [
      // only needed when using shadows - a plane for shadows to drop on
      new PolygonLayer({
        id: 'ground',
        data: landCover,
        stroked: false,
        getPolygon: f => f,
        getFillColor: [255, 255, 255, 1]
      }),
      new GeoJsonLayer({
        id: 'geojson',
        data: this.state.URL_CO2,
        opacity: 0.8,
        stroked: true,
        filled: true,
        extruded: true,
        wireframe: true,
        getElevation: f => (f.properties.pkm_actual/500000),
        elevationScale: 100,
        getFillColor: f => COLOR_SCALE(f.properties.pkm_actual/500000),
        getLineColor: [0, 0, 0],
        getLineWidth: 100,
        pickable: true
      })
    ];
    return [layer];
  }

  getTooltip({object}) {
    return (
      object && {
        html: `\
    <div><b>${object.properties.name} (${(object.properties.id).toFixed(0)})</b></div>
    <hr>
    <div><b>Current Modal Split per Month</b></div>
    <div><i>Passenger Km:</i> ${ (object.properties.pkm_actual == null) ? 'No data available':numberWithCommas(object.properties.pkm_actual.toFixed(0))}</div>
    <div><i>Number of trips:</i> ${ (object.properties.trips_actual == null) ? 'No data available':numberWithCommas(object.properties.trips_actual.toFixed(0))}</div>
    <hr>
    <div><b>Modal Split by Shifting ${this.state.percentage}% per Month</b></div>
    <div><i>Passenger Km:</i> ${ (object.properties.pkm_change == null) ? 'No data available':numberWithCommas(object.properties.pkm_change.toFixed(0))}</div>
    <div><i>Number of trips:</i> ${ (object.properties.trips_change == null) ? 'No data available':numberWithCommas(object.properties.trips_change.toFixed(0))}</div>
    `
      }
      );
  }

  async getOptionsDate(url){
		const res = await axios.get(url)
		const data = res.data
		const options = data.map(d => ({
		  "value" : d._id.date.toString().split('T')[0].slice(0,-3),
		  "label" : d._id.date.toString().split('T')[0].slice(0,-3)
		}))
		this.setState({db_od_trips_: options});
	}

	componentDidMount(){
    this.getTooltip = this.getTooltip.bind(this);
    this.percentageSliderHandler = this.percentageSliderHandler.bind(this);
    this.handleAggregation = this.handleAggregation.bind(this);
    this.handleDestination = this.handleDestination.bind(this);
    this.handleOrigin = this.handleOrigin.bind(this);
	}

  handleDestination(event){
    let destination_event;
    if (event == null){
      this.setState({isDestination: false, destination: 'all'})
      destination_event = 'all';
      this.setState({URL_CO2_Link: URL+"what_if/ms/{0},{1},{2},{3},{4}.geojson".format((this.state.isBFS? 'bfs':'plz'),this.state.value_date.value, this.state.percentage_decimal, this.state.origin, 'all')});   
    }
    else{
      this.setState({isDestination: true, destination: 'all'})
      destination_event = event.value
      this.setState({URL_CO2_Link: URL+"what_if/ms/{0},{1},{2},{3},{4}.geojson".format((this.state.isBFS? 'bfs':'plz'),this.state.value_date.value, this.state.percentage_decimal, this.state.origin, event.value)});   
    }
    (((this.state.origin==='all' && destination_event==='all') || event==null)? this.setState({hasLocation: false}):this.setState({hasLocation: true}))
  }

  handleOrigin(event){
    let origin_event;
    if (event == null){
      this.setState({isOrigin: false, origin: 'all'})
      origin_event = 'all'
      this.setState({URL_CO2_Link: URL+"what_if/ms/{0},{1},{2},{3},{4}.geojson".format((this.state.isBFS? 'bfs':'plz'),this.state.value_date.value, this.state.percentage_decimal, 'all', this.state.destination)});   
    }
    else{
      this.setState({URL_CO2_Link: URL+"what_if/ms/{0},{1},{2},{3},{4}.geojson".format((this.state.isBFS? 'bfs':'plz'),this.state.value_date.value, this.state.percentage_decimal, event.value, this.state.destination)});   
      origin_event = event.value
      this.setState({isOrigin: true, origin: event.value})
    }
    (((origin_event==='all' && this.state.destination==='all') || event==null)? this.setState({hasLocation: false}):this.setState({hasLocation: true}))
  }

  demoAsyncCall() {
        let data;
        fetch(URL+"what_if/ms/{0},{1},{2},{3},{4}.geojson".format((this.state.isBFS? 'bfs':'plz'),this.state.value_date.value, this.state.percentage_decimal, this.state.origin, this.state.destination))
            .then((res) =>res.json())
            .then((json) => { 
                data = json;
            }).then(() => 
            {
              this.setState({response: data});
              //console.log(data.)
        }).then(() => this.setState({ loading: false }));
  }


  handleAggregation(event)
  {
    (event.value==='plz') ? this.setState({isBFS: false}) : this.setState({isBFS: true});
    this.setState({URL_CO2_Link: URL+"what_if/ms/{0},{1},{2},{3},{4}.geojson".format((event.value==='bfs'? 'bfs':'plz'),this.state.value_date.value, this.state.percentage_decimal, 'all', 'all')});   

  }

  percentageSliderHandler(event){
    this.setState({percentage: event});
    this.setState({percentage_decimal: event/100})
    this.setState({URL_CO2_Link: URL+"what_if/ms/{0},{1},{2},{3},{4}.geojson".format((this.state.isBFS? 'bfs':'plz'),this.state.value_date.value, event/100, this.state.origin, this.state.destination)});   
  }

  whatifgenerator(){
    this.setState({resp: true});
    if(this.state.origin==='all' && this.state.destination==='all')
    {
      this.setState({URL_CO2_Link: URL+"what_if/ms/{0},{1},{2},{3},{4}.geojson".format((this.state.isBFS? 'bfs':'plz'),this.state.value_date.value, this.state.percentage_decimal, 'all', 'all')});   
      this.setState({URL_CO2: URL+"what_if/ms/{0},{1},{2},{3},{4}.geojson".format((this.state.isBFS? 'bfs':'plz'),this.state.value_date.value, this.state.percentage_decimal, 'all', 'all')});   
    }
    else{
      this.setState({URL_CO2: this.state.URL_CO2_Link});
      this.setState({loading: true});
      this.demoAsyncCall();
    }
  }

  resetPlatform(){
    this.setState({resp: false});
    this.setState({URL_CO2_Link: URL+"what_if/ms/{0},{1},{2},{3},{4}.geojson".format((this.state.isBFS? 'bfs':'plz'),this.state.value_date.value, this.state.percentage_decimal, this.state.origin, this.state.destination), URL_CO2: URL+"what_if/ms/null.geojson"});   
  }

  render() {
    const { viewState, controller = true } = this.props;
    const { loading } = this.state;

    const selectDate = {
			menuList: styles => {
			  return {
				...styles,
				maxHeight: 130,
				borderWidth: 0,
				boxShadow: '0 0 10px 2px rgb(0 0 0 / 10%)',
			  };
			}
		  };

      if(loading) { // if your component doesn't have to wait for an async action, remove this block 
        return(
            <div className="bg-loader">
                <div className="pos-center">
                    <div className="loader"></div>
                </div>
            </div>
        )
    }
    else{
      return (
        <div>
          <DeckGL
            layers={this._renderLayers()}
            initialViewState={INITIAL_VIEW_STATE}
            viewState={viewState}
            controller={controller}
            getTooltip={this.getTooltip}
          >
          <Map reuseMaps mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN} mapStyle={'mapbox://styles/mapbox/light-v10'} />
          <RecommendationNavBarComponent/>
          </DeckGL>
          <div>
            <div className="map-division-picker">
              <Select className='division' onChange={this.handleAggregation.bind(this)} options={this.state.division_options_} styles={selectDate} defaultValue={this.state.value_division}/>
            </div>
            <div className="map-month-year-picker">
              <Select className='dates' options={this.state.db_od_trips_} styles={selectDate} defaultValue={this.state.value_date}/>
            </div>
          </div>
          <div className='map-layer-agg-division-ms'>
              <div className='map-layer-agg-division-ms-inner'>
              {(this.state.isBFS) &&
            <div>
              <div>
                <h1>Origin</h1>
                <Select className='locations' onChange={this.handleOrigin.bind(this)} isDisabled={this.state.isDestination} isClearable={true} options={this.state.spatial_resolution_bfs_} styles={selectDate} />
              </div>
                <div>
                  <h1>Destination</h1>
                  <Select className='locations' onChange={this.handleDestination.bind(this)} isDisabled={this.state.isOrigin} isClearable={true} options={this.state.spatial_resolution_bfs_} styles={selectDate} />
              </div>
            </div>      			
          }
          {(!this.state.isBFS) &&
            <div>
            <div>
              <h1>Origin</h1>
              <Select className='locations' onChange={this.handleOrigin.bind(this)} isDisabled={this.state.isDestination} isClearable={true} options={this.state.spatial_resolution_plz_} styles={selectDate} />
            </div>
              <div>
                <h1>Destination</h1>
                <Select className='locations' onChange={this.handleDestination.bind(this)} isDisabled={this.state.isOrigin} isClearable={true} options={this.state.spatial_resolution_plz_} styles={selectDate} />
            </div>
          </div>  
          }
                <h1>Modal split</h1>
                <div className="slider-centering">
                  <Slider min={-100} max={100} step={1} defaultValue={this.state.percentage} onChange={_.debounce(this.percentageSliderHandler.bind(this),33)}/>
                  <p className='label'>Showing what will happen if we change {this.state.percentage}% <br/> of modal split in Switzerland</p>
                </div>
                <div className="row">
                <div className="column-1-eq">
                  <Form onClick={this.whatifgenerator.bind(this)}>
                    <Button variant="info" disabled={this.state.resp} className="generate-btn" >What if...?</Button>
                  </Form>
                </div>
                <div className="column-2-eq">
                  <Form onClick={this.resetPlatform.bind(this)}>
                    <Button variant="info" disabled={!this.state.resp} className="reset-btn">Reset</Button>
                  </Form>
                </div>
              </div>
              </div>
            </div>
            {(this.state.resp && this.state.hasLocation) &&
            <div className="map-contributions">
            <div className="map-contributions-inner">
              <div>
              <h1>Top 3 flow contributors <br/> for this location</h1>
              <div className="table">
                <div className="row">
                  <div className="column-1-80">
                    {this.state.response.features[0].properties.name}
                  </div>
                  <div className="column-2-20">
                  {(this.state.response.features[0].properties.distribution*100).toFixed(0)}%
                  </div>
                </div>
                <div className="row">
                  <div className="column-1-80">
                    {this.state.response.features[1].properties.name}
                  </div>
                  <div className="column-2-20">
                  {(this.state.response.features[1].properties.distribution*100).toFixed(0)}%
                  </div>
                </div>
                <div className="row">
                  <div className="column-1-80">
                    {this.state.response.features[2].properties.name}
                  </div>
                  <div className="column-2-20">
                  {(this.state.response.features[2].properties.distribution*100).toFixed(0)}%
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>  
          }
        </div>
      );
    }
  }
};


export default MapWhatIf_MS_3D;
