import React from "react";
import './recommendation-navbar.css';
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";

const RecommendationNavBarComponent = () => {
	const { user, logout } = useAuth0();
  
	const logoutWithRedirect = () => {
	  logout({
		  returnTo: window.location.origin,
	  });
	};

	const hasName = user.name.includes("@")
  
	  return (
		<div>
		<div className='map-navbar'>
			<div className='map-navbar-inner'>
				<div className="map-welcome-user">
					{(hasName) ? 					
						<p className="label">Welcome back, 42Hacks</p>:
						<p className="label">Welcome back, {user.name}</p>}
					<button className="logout-btn" onClick={() => logoutWithRedirect()}>
					Logout
				  </button>
				</div>
				<div className='map-nabar-logo'>
					<Link to="/menu" style={{ textDecoration: 'none' }}>
						<img src={require('../assets/img/logo-navbar.png')} alt="Logo ÖV42 by 42hacks" className='logo-img'/>
						<p className="powered-by-navbar">Powered by <img src={require('../assets/img/swisscom-logo-navbar.png')} alt="Swisscom" className='image-swisscom-navbar'/> <span className="swisscom-font"></span></p>
					</Link>
				</div>
			</div>
		</div>
	</div>
	  );
  };
  
  export default RecommendationNavBarComponent;